import {
  ADD_SHIFT_SCHEDULE_RUN,
  CANCEL_SCHEDULE_RUN,
  COPY_SCHEDULE_RUN,
  DELETE_SHIFT_SCHEDULE_RUN,
  LOAD_SHIFT_SCHEDULE,
  LOAD_SHIFT_SCHEDULE_RUNS,
  PULL_SHIFT_SCHEDULE_RUN_CHANGES,
  RECALCULATE_SCHEDULE_RUN,
  SAVE_SHIFT_SCHEDULE_RUNS,
  SEND_SCHEDULE_RUN,
  STORE_SHIFT_SCHEDULE_RUN,
  STORE_SHIFT_SCHEDULE_RUNS,
  LOAD_ERROR_DESCRIPTION,
  SAVE_ERROR_DESCRIPTIONS,
  LOAD_SCHEDULE_STAFF_SYNC_STATE,
  STORE_SCHEDULE_STAFF_SYNC_STATE
} from './constants';
import { ShiftScheduleRun } from './types';

export function addScheduleRun(payload: ShiftScheduleRun) {
  return {
    payload,
    type: ADD_SHIFT_SCHEDULE_RUN,
  };
}

export function saveScheduleRuns(payload: ShiftScheduleRun[]) {
  return {
    payload,
    type: SAVE_SHIFT_SCHEDULE_RUNS,
  };
}

export function deleteScheduleRun(payload: any) {
  return {
    payload,
    type: DELETE_SHIFT_SCHEDULE_RUN,
  };
}

export function loadShiftScheduleRuns(scheduleId: number) {
  return {
    payload: scheduleId,
    type: LOAD_SHIFT_SCHEDULE_RUNS,
  };
}

export function loadShiftSchedule(payload: any) {
  return {
    payload,
    type: LOAD_SHIFT_SCHEDULE,
  };
}

export function pullShiftScheduleRunChanges(payload: any) {
  return {
    payload,
    type: PULL_SHIFT_SCHEDULE_RUN_CHANGES,
  };
}

export function storeSingleShiftScheduleRun(payload?: {
  runs: ShiftScheduleRun[];
  shiftScheduleName: string;
  shiftScheduleId: number;
}) {
  return {
    payload,
    type: STORE_SHIFT_SCHEDULE_RUN,
  };
}

export function storeShiftScheduleRuns(payload: any) {
  return {
    payload,
    type: STORE_SHIFT_SCHEDULE_RUNS,
  };
}

export function recalculateShiftScheduleRuns(payload: any) {
  return {
    payload,
    type: RECALCULATE_SCHEDULE_RUN,
  };
}

export function copyShiftScheduleRun(payload: any) {
  return {
    payload,
    type: COPY_SCHEDULE_RUN,
  };
}

export function sendScheduleRun(payload: any) {
  return {
    payload,
    type: SEND_SCHEDULE_RUN,
  };
}

export function cancelScheduleRun(payload: any) {
  return {
    payload,
    type: CANCEL_SCHEDULE_RUN,
  };
}

export function loadErrorDescription(){
  return {
    type: LOAD_ERROR_DESCRIPTION,
  }
}

export function saveErrorDescriptions(payload: any) {
  return {
    type: SAVE_ERROR_DESCRIPTIONS,
    payload
  }
}

export function loadStaffSyncState(payload: any) {
  return {
    payload,
    type: LOAD_SCHEDULE_STAFF_SYNC_STATE,
  };
}

export function storeScheduleStaffSyncState(payload: any) {
  return {
    payload,
    type: STORE_SCHEDULE_STAFF_SYNC_STATE,
  };
}