import React from 'react';
import styled from 'styled-components';
import IconButton from '../../components/IconButton';
import messages from './messages';

const Wrap = styled.div`
  display: flex;
  align-items: flex-end;
  height: 31px;
  ${IconButton} {
    padding-top: 5px;
    height: 25px;
  }
`;

export default params => {
  const { isOpen } = params.data;

  return (
    <Wrap>
      <IconButton
        icon={isOpen ? 'angle-up' : 'angle-down'}
        message={messages.workerType}
        onClick={() => params.onClickHandler(params.data.id)}
      />
    </Wrap>
  );
};
