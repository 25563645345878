import { call, takeLatest, put, select, all } from 'redux-saga/effects';

import { LOAD_LIST, REMOVE_SELECTED, REMOVE_SELECTED_SUC, ENABLE_SELECTED, CONFIRM_MASTER_PLAN_REPORT_RUNS, SMARTPROD_SOURCES_REPORT_RUNS } from './constants';
import { loadListSucAction, removeSelectedSucAction, loadListAction, listLoader } from './actions';
import { makeSelectors } from './selectors';
import { withUrl, api } from '../../utils/api';
import { toast } from 'react-toastify';

export default function makeSaga({ domainUrl = 'planningAreas', ...other }) {
  const { makeSelectSelectedRows } = makeSelectors(other);
  function* loadList(action) {
    yield put(listLoader(true))
    const response = yield call(api, withUrl(`/${domainUrl}/?deleted=${action.payload}`).disableLogCalls());
    if (response.isOk) {
      yield put(loadListSucAction(response.data));
    }
    yield put(listLoader(false))
  }

  function* removeSelected() {
    const selectedRows = yield select(makeSelectSelectedRows());
    if (domainUrl === "smartProdSource") {
      let payload = {
        "id": selectedRows[0].id,
        "smartProdInstance": selectedRows[0].smartProdInstance,
        "smartProdWarehouse": selectedRows[0].smartProdWarehouse,
        "startDate": selectedRows[0].startDate,
        "description": selectedRows[0].description,
        "deleted": !selectedRows[0].deleted,
        "omses": selectedRows[0].omses ? selectedRows[0].omses : [],
        "scheduleStartDate": selectedRows[0].scheduleStartDate,
        "schedulePeriod": selectedRows[0].schedulePeriod,
      }
      const data = yield all(selectedRows.map(item => call(api, withUrl(`/${domainUrl}/`).post(payload))));
      if (data.find(item => item !== false)) {
        yield put(loadListAction(false));
      }
    } else {
      const data = yield all(selectedRows.map(item => call(api, withUrl(`/${domainUrl}/${item.id}/delete`).post())));
      if (data.find(item => item !== false)) {
        yield put(loadListAction(false));
      }
    }
  }
  function* enableSelected() {
    const selectedRows = yield select(makeSelectSelectedRows());
    if (domainUrl === "smartProdSource") {
      let payload = {
        "id": selectedRows[0].id,
        "smartProdInstance": selectedRows[0].smartProdInstance,
        "smartProdWarehouse": selectedRows[0].smartProdWarehouse,
        "startDate": selectedRows[0].startDate,
        "description": selectedRows[0].description,
        "deleted": !selectedRows[0].deleted,
        "omses": selectedRows[0].omses ? selectedRows[0].omses : [],
        "scheduleStartDate": selectedRows[0].scheduleStartDate,
        "schedulePeriod": selectedRows[0].schedulePeriod,
      }
      yield all(selectedRows.map(item => call(api, withUrl(`/${domainUrl}/`).post(payload))));
      yield put(loadListAction(true));
    } else {
      yield all(selectedRows.map(item => call(api, withUrl(`/${domainUrl}/${item.id}/undelete`).post())));
      yield put(loadListAction(true));
    }
  }

  function* masterPlanReportRuns(action){
    const selectedRows = yield select(makeSelectSelectedRows());
    const masterPlanIds = selectedRows && selectedRows.map(data=>data.id);
    const idValues = masterPlanIds.toString()
     const url = `/masterPlan/multiple/report?startDate=${action.start}&endDate=${action.end ? action.end : ''}&ids=${idValues}`;
    const response = yield call(api, withUrl(url).post().asRawResponse());
    if (response.ok) {
      toast.info('Refreshed multiple MasterPlan report runs');
    }
  };

  function* smartProdSourcesRuns(action){
    const selectedRows = yield select(makeSelectSelectedRows());
    const smartProdIds = selectedRows && selectedRows.map(data=>data.id);
    const sidValues = smartProdIds.toString()
     const url = `/smartVolume/runs/?smartProdSourceIds=${sidValues}`;
    const response = yield call(api, withUrl(url).post().asRawResponse());
    if (response.ok) {
      toast.info('Refreshed multiple SmartVolume Runs');
    }
  };

  return function* defaultSaga() {
    yield takeLatest(LOAD_LIST, loadList);
    yield takeLatest(CONFIRM_MASTER_PLAN_REPORT_RUNS, masterPlanReportRuns)
    yield takeLatest(SMARTPROD_SOURCES_REPORT_RUNS, smartProdSourcesRuns )
    yield takeLatest(REMOVE_SELECTED_SUC, loadList);
    yield takeLatest(REMOVE_SELECTED, removeSelected);
    yield takeLatest(ENABLE_SELECTED, enableSelected);
  };
}
