import React, { useEffect, useState } from 'react';
import { Box, Grid, Popover,Tooltip } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import useLocalStorage from 'use-local-storage';

import Lb from 'components/Label';
import { ItemSelectable } from 'components/Menu/ItemSelectable';
import { CheckButton } from 'components/ToggleButton';
import ToolbarButton from 'components/ViewModePopover/button';
import messages from 'components/ViewModePopover/messages';
import HelpPoint from '../HelpPoint';

import { setViewModeAction } from './actions';
import { InputTypeValue, StoredSettings, ViewSettings } from './types';

const CheckboxWrap = styled.div`
  width: 36px;
  display: inline-block;
`;
const LabelWrap = styled.div`
  display: inline-block;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
`;
const Label = styled(Lb)`
  margin-top: 2px;
  margin-right: 10px;
  height: ${props => props.theme.grid.rowHeight};
  display: flex;
  align-items: center;
`;
const ButtonsSection = styled(props => <Box p={1} {...props}></Box>)`
  background-color: rgba(0, 0, 0, 0.05);

  h6 {
    margin-top: 2px;
    margin-bottom: 8px;
  }
`;

const useSettingsFromLocalStorage = () =>
  useLocalStorage<StoredSettings>('storedViewModeSettings', {
    inputType: 'volumeAndHours',
    checkboxMHE: true,
    checkboxLabourAvailable: true,
    checkboxRole: false,
  });

const ViewModePopover: React.FC = () => {
  const dispatch = useDispatch();
  const [storedValues, setStoredValues] = useSettingsFromLocalStorage();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  useEffect(() => {
    dispatch(setViewModeAction(storedValues));
  }, [storedValues]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'view-mode-popover' : undefined;

  return (
    <div>
      <ToolbarButton handleClick={handleClick} />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Box p={1} style={{ width: 400 }}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <ButtonsSection>              
                <h6>
                  <FormattedMessage {...messages.inputTypeTitle} />
                  <Tooltip title={<FormattedMessage {...messages.inputTypeTitleHelper} />}>
                 <span> <HelpPoint/></span>
                  </Tooltip>
                </h6>                
                <Grid container>
                  {[
                    { title: messages.inputTypeVolume, valueName: 'volume' as InputTypeValue },
                    { title: messages.inputTypeVolumeAndHours, valueName: 'volumeAndHours' as InputTypeValue },
                    { title: messages.inputTypeHours, valueName: 'hours' as InputTypeValue },
                  ].map(({ title, valueName }) => (
                    <Grid key={valueName} item xs="auto">
                      <CheckButton
                        name={valueName}
                        value={storedValues?.inputType === valueName}
                        onChange={() => setStoredValues({ ...storedValues, inputType: valueName })}
                      >
                        <FormattedMessage {...title} />
                      </CheckButton>
                    </Grid>
                  ))}
                </Grid>
              </ButtonsSection>
            </Grid>
            <Grid item xs={12}>
              <ButtonsSection>            
                <h6>
                  <FormattedMessage {...messages.planTitle} />
                  <Tooltip title={<FormattedMessage {...messages.planTitleHelper} />}>
                 <span> <HelpPoint/></span>
                  </Tooltip>
                </h6>             
                <Grid container>
                  <Grid item xs={12}>
                    <CheckboxWrap>
                      <ItemSelectable
                        isSelected={storedValues.checkboxMHE}
                        onClick={() => setStoredValues({ ...storedValues, checkboxMHE: !storedValues.checkboxMHE })}
                      />
                    </CheckboxWrap>
                    <LabelWrap
                      onClick={() => setStoredValues({ ...storedValues, checkboxMHE: !storedValues.checkboxMHE })}
                    >
                      <Label {...messages.checkboxMHE} />
                    </LabelWrap>
                  </Grid>
                  <Grid item xs={12}>
                    <CheckboxWrap>
                      <ItemSelectable
                        isSelected={storedValues.checkboxLabourAvailable}
                        onClick={() =>
                          setStoredValues({
                            ...storedValues,
                            checkboxLabourAvailable: !storedValues.checkboxLabourAvailable,
                          })
                        }
                      />
                    </CheckboxWrap>
                    <LabelWrap
                      onClick={() =>
                        setStoredValues({
                          ...storedValues,
                          checkboxLabourAvailable: !storedValues.checkboxLabourAvailable,
                        })
                      }
                    >
                      <Label {...messages.checkboxLabourAvailable} />
                    </LabelWrap>
                  </Grid>
                  <Grid item xs={12}>
                    <CheckboxWrap>
                      <ItemSelectable
                        isSelected={storedValues.checkboxRole}
                        onClick={() => setStoredValues({ ...storedValues, checkboxRole: !storedValues.checkboxRole })}
                      />
                    </CheckboxWrap>
                    <LabelWrap
                      onClick={() => setStoredValues({ ...storedValues, checkboxRole: !storedValues.checkboxRole })}
                    >
                      <Label {...messages.checkboxRole} />
                    </LabelWrap>
                  </Grid>
                </Grid>
              </ButtonsSection>
            </Grid>
          </Grid>
        </Box>
      </Popover>
    </div>
  );
};

export default ViewModePopover;
