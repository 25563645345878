import {
  INVOKE_SCHEDULE_STAFF_SYNC,
  LOAD_AVAILABLE_LABOUR_CATEGORIES,
  LOAD_AVAILABLE_PLANS,
  LOAD_AVAILABLE_UNPRODUCTIVE_ACTIVITIES,
  LOAD_DEFAULT_SCHEDULE_PARAMETERS,
  LOAD_SCHEDULE_STAFF,
  LOAD_SCHEDULE_STAFF_SETTINGS,
  LOAD_SCHEDULE_STAFF_SYNC_STATE,
  LOAD_SCHEDULE_STAFF_UNPRODUCTIVE_ACTIVITIES,
  LOAD_SHIFT_SCHEDULE,
  SAVE_SCHEDULE_STAFF,
  SAVE_SHIFT_SCHEDULE,
  STORE_AVAILABLE_LABOUR_CATEGORIES,
  STORE_AVAILABLE_PLANS,
  STORE_AVAILABLE_UNPRODUCTIVE_ACTIVITIES,
  STORE_DEFAULT_SCHEDULE_PARAMETERS,
  STORE_SCHEDULE_STAFF,
  STORE_SCHEDULE_STAFF_SETTINGS,
  STORE_SCHEDULE_STAFF_SYNC_STATE,
  STORE_SCHEDULE_STAFF_UNPRODUCTIVE_ACTIVITIES,
  STORE_SHIFT_SCHEDULE,
  STORE_SHIFT_SCHEDULE_GUI_STATE_ITEM,
  STORE_SHIFT_SCHEDULE_PERIOD_INDEX,
  RESET_STORE_SHIFT_SCHEDULE,
} from './constants';

export function storeShiftSchedule(payload) {
  return {
    payload,
    type: STORE_SHIFT_SCHEDULE,
  };
}

export function resetstoreShiftSchedule(payload) {
  return {
    payload,
    type: RESET_STORE_SHIFT_SCHEDULE,
  };
}

export function saveShiftSchedule(payload: any) {
  return {
    payload,
    type: SAVE_SHIFT_SCHEDULE,
  };
}

export function loadShiftSchedule(payload: any) {
  return {
    payload,
    type: LOAD_SHIFT_SCHEDULE,
  };
}

export function invokeStaffSync(payload: any) {
  return {
    payload,
    type: INVOKE_SCHEDULE_STAFF_SYNC,
  };
}

export function storeShiftScheduleEdit(payload: boolean) {
  return {
    payload: { field: 'edit', value: payload },
    type: STORE_SHIFT_SCHEDULE_GUI_STATE_ITEM,
  };
}

export function storePeriodIndexShiftScheduleAction(payload) {
  return {
    payload,
    type: STORE_SHIFT_SCHEDULE_PERIOD_INDEX,
  };
}

export function loadAvailablePlans(payload: any) {
  return {
    payload,
    type: LOAD_AVAILABLE_PLANS,
  };
}

export function saveScheduleStaff(payload: any) {
  return {
    payload,
    type: SAVE_SCHEDULE_STAFF,
  };
}

export function loadScheduleStaff(payload: any) {
  return {
    payload,
    type: LOAD_SCHEDULE_STAFF,
  };
}

export function loadScheduleStaffSettings(payload: any) {
  return {
    payload,
    type: LOAD_SCHEDULE_STAFF_SETTINGS,
  };
}

export function loadScheduleStaffUnproductiveActivities(payload: any) {
  return {
    payload,
    type: LOAD_SCHEDULE_STAFF_UNPRODUCTIVE_ACTIVITIES,
  };
}

export function loadAvailableUnproductiveActivities(payload: any) {
  return {
    payload,
    type: LOAD_AVAILABLE_UNPRODUCTIVE_ACTIVITIES,
  };
}

export function loadDefaultScheduleParameters(payload: any) {
  return {
    payload,
    type: LOAD_DEFAULT_SCHEDULE_PARAMETERS,
  };
}

export function loadStaffSyncState(payload: any) {
  return {
    payload,
    type: LOAD_SCHEDULE_STAFF_SYNC_STATE,
  };
}

export function storeScheduleStaff(payload: any) {
  return {
    payload,
    type: STORE_SCHEDULE_STAFF,
  };
}

export function storeScheduleStaffSettings(payload: any) {
  return {
    payload,
    type: STORE_SCHEDULE_STAFF_SETTINGS,
  };
}

export function storeScheduleStaffUnproductiveActivities(payload: any) {
  return {
    payload,
    type: STORE_SCHEDULE_STAFF_UNPRODUCTIVE_ACTIVITIES,
  };
}

export function storeAvailableUnproductiveActivities(payload: any) {
  return {
    payload,
    type: STORE_AVAILABLE_UNPRODUCTIVE_ACTIVITIES,
  };
}

export function storeDefaultScheduleParameters(payload: any) {
  return {
    payload,
    type: STORE_DEFAULT_SCHEDULE_PARAMETERS,
  };
}

export function storeScheduleStaffSyncState(payload: any) {
  return {
    payload,
    type: STORE_SCHEDULE_STAFF_SYNC_STATE,
  };
}

export function storeAvailablePlans(payload: any) {
  return {
    payload,
    type: STORE_AVAILABLE_PLANS,
  };
}

export function loadAvailableLabourCategories(payload: any) {
  return {
    payload,
    type: LOAD_AVAILABLE_LABOUR_CATEGORIES,
  };
}

export function storeAvailableLabourCategories(payload: any) {
  return {
    payload,
    type: STORE_AVAILABLE_LABOUR_CATEGORIES,
  };
}
