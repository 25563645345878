// @flow

/**
 *
 * DaysPickerCellEditor
 *
 */
import React from 'react';

import styled from 'styled-components';
import find from 'lodash/find';
import NativeSelect from '@material-ui/core/NativeSelect';
import { FormattedMessage } from 'react-intl';
import messages from './messages';

import './style.css';

const CellWrapper = styled.div`
  display: block;
  width: 100px;
  height: ${props => (props.eGridCell && props.eGridCell.clientHeight) || 26}px;
  width: ${props => (props.eGridCell && props.eGridCell.clientWidth) || 200}px;
  display: flex;
  padding-right: 10px;

  align-items: center;
  > * {
    margin-right: 5px;
  }
`;

type DropDownCellEditorType = {
  values?: Array<Object>,
  getValues?: Function,
  getValue?: Function,
  eGridCell: Object,
  value?: string | number,
  valueKey?: string,
  data: Object,
  placeholder?: string,
  setValue: Function,
  disableNoOpts: boolean,
  defaultValue: any,
  isEmpty: boolean,
  api: Object,
  node: Object,
  skipIndirect: boolean,
};

/* eslint-disable react/prefer-stateless-function */
class DropDownCellEditor extends React.Component<DropDownCellEditorType, any> {
  options = [];

  constructor(props: DropDownCellEditorType) {
    super(props);
    const { values, getValues, setValue, defaultValue, value } = this.props;
    this.options = values || (getValues && getValues(this.props)) || [];
    if (defaultValue && !value) {
      const defVal = find(this.options, { value: defaultValue });
      if (defVal) setValue(defVal.value);
    }
  }

  getValue = (value: string) => {
    const { valueKey } = this.props;
    if (!value) return null;

    const option = find(this.options, { value });
    return option && option[valueKey];
  };

  updateValue = (event: Object) => {
    const { setValue } = this.props;
    const { value } = event.target;
    setValue(this.getValue(value));
    this.props.api.redrawRows({ rowNodes: [this.props.node] });
  };

  isPopup() {
    return true;
  }

  render() {
    const {
      isEmpty = false,
      disableNoOpts = false,
      getValue,
      value,
      defaultValue = '',
      data,
      placeholder = '',
      skipIndirect = false,
    } = this.props;

    const val = (getValue && getValue(value)) || value || '';
    const disabled = disableNoOpts && this.options.length === 0;
    if (!data || (data.indirect && skipIndirect)) return <span />;

    if (disabled)
      return (
        <span style={{ color: '#bbb' }}>
          <FormattedMessage {...messages.noOpts} />
        </span>
      );

    return (
      <CellWrapper props={this.props}>
        <NativeSelect
          value={val}
          onChange={this.updateValue}
          className="dropDownCellEditor"
          classes={{
            selectMenu: 'dropDownCellEditor__selectMenu',
            select: 'dropDownCellEditor__selectMenu',
            root: 'dropDownCellEditor__root',
          }}
        >
          <option value="" disabled={!isEmpty} className="dropDownCellEditor__placeholder">
            {placeholder}
          </option>
          {this.options.map((option: Object) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </NativeSelect>
      </CellWrapper>
    );
  }
}

export default DropDownCellEditor;
