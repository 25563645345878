/*
 *
 * PlanResultPage reducer
 *
 */

import uniqid from 'uniqid';
import { fromJS } from 'immutable';
import { CHANGE_LOCALE } from 'components/LanguageProvider/constants';
import { SAVE_PA } from 'containers/PlanningAreaDetailPage/constants';
import {
  SAVE_PLAN_DONE,
  ADD_PLANNED_VOLUME_LINE,
  DELETE_PLANNED_VOLUME,
  SAVE_PLANNED_VOLUME_HEADER,
} from 'containers/PlanDetailPage/constants';
import { activitiesByPlan } from 'utils/api/calculations';
import {
  STORE_RESULTS,
  EDIT_MODE_CHANGE,
  OVERRIDE_EFFORT,
  OVERRIDE_PRODUCTION,
  OVERRIDE_VOLUME,
  OVERRIDES_SAVED,
  OVERRIDES_DISCARD,
  CLEAN,
  CLEAN_CALCULATION,
  FETCH_RESULTS,
  IS_EDITING,
  STORE_PLAN,
  SET_GRAPH_DATA_TREND,
  SET_GRAPH_DATA_ACTIVITY,
  SET_GRAPH_DATA_TREND_FILTERED,
  SET_GRAPH_DATA_ACTIVITY_FILTERED,
  STORE_CALCULATED_TABLES,
  STORE_CALCULATED_TABLES_DEF,
  STORE_GRAPH_DATA_TREND,
  REMOUNT_TABLES,
  SET_GRAPH_DATA_DILO,
  SET_GRAPH_DATA_DILO_FILTERED,
  TOGGLE_OVERRIDE_HELPER,
  RESULT_CALCULATION_STARTED,
  CLEAN_TREND_DATA,
  STORE_VOLUME_CP,
  STORE_VOLUME_RAW,
  STORE_RESULT_VOLUME_RAW,
  STORE_PLAN_STATS,
  STORE_HEADS_GRAPH_DATA,
  STORE_HEADS_GRAPH_FILTERED_DATA,
  MAPPING_OMS_CODES,
  TOGGLE_VOLUME_FORMULA,
  TOGGLE_VOLUME_VALUE,
  STORE_MASTER_PLAN,
  STORE_PLAN_BASIC_DETAILS,
  STORE_CALCULATED_RESULT_TABLES,
} from './constants';

const iState = fromJS({
  canShowOverrideHelper: false,
  showOverrideHelper: false,
  plan: null,
  masterPlan: null,
  activitiesByPlan: null,
  statistics: [],
  guiState: { edit: false },
  results: null,
  isEditing: false,
  graphDataTrend: false,
  graphDataTrendSettings: null,
  graphDataTrendFiltered: false,
  graphDataActivity: false,
  graphDataActivityFiltered: false,
  graphHeahs: false,
  table: null,
  tableKey: uniqid(),
});

export const initialState = iState.set('table', {
  activity: { colDef: false, data: false },
  mhe: { colDef: false, data: false },
  role: { colDef: false, data: false },
  settings: null,
  editing: false,
  calculationStatus: 'notStarted',
  mappingOMSCodes: false,
  showFormula: true,
  showValue: true,
});

function overrideKey(payload) {
  const {
    data,
    colDef: { field },
  } = payload;
  return `${field}_${data.activityId}`;
}

function isChanged(payload) {
  return Number(payload.oldValue) !== Number(payload.newValue);
}

const updateData = (type, field, srcField, state) => action => {
  if (isChanged(action.payload)) {
    return state.setIn(['overridesToBeSaved', type, overrideKey(action.payload)], action.payload);
  }
  return state;
};

function planResultPageReducer(state = initialState, action) {
  switch (action.type) {
    case RESULT_CALCULATION_STARTED:
      return state.setIn(['table', 'calculationStatus'], 'running');
    case TOGGLE_OVERRIDE_HELPER:
      return state.set('showOverrideHelper', action.payload === undefined ? true : action.payload);
    case REMOUNT_TABLES:
      return state.set('tableKey', uniqid());
    case SET_GRAPH_DATA_TREND:
      return state.set('graphDataTrend', action.payload);
    case SET_GRAPH_DATA_ACTIVITY:
      return state.set('graphDataActivity', action.payload);
    case SET_GRAPH_DATA_DILO:
      return state.set('graphDataDilo', action.payload);
    case STORE_PLAN: {
      return state.set('plan', action.payload).set('activitiesByPlan', activitiesByPlan(action.payload));
    }
    case STORE_MASTER_PLAN: {
      const masterPlanData = action.payload;
      const sortByDate = (a, b) => (a.validFrom > b.validFrom) ? 1 : ((b.validFrom > a.validFrom) ? -1 : 0);
      masterPlanData?.plans?.sort(sortByDate);
      const modifiedMasterPlanData = {...masterPlanData};
      const validPlanData = masterPlanData?.plans?.filter((mp) => mp.validFrom && mp.validTo);
      modifiedMasterPlanData.plans = validPlanData;
      return state.set('masterPlan', modifiedMasterPlanData);
    }
    case STORE_PLAN_BASIC_DETAILS:
      return state.set('planBasicDetails', action.payload);
    case STORE_PLAN_STATS: {
      return state.set('statistics', fromJS(action.payload));
    }
    // case STORE_VOLUME_CP: {
    //   return state.setIn(['plan', 'planningParameters', 'volumeCategoryParameters'], action.payload);
    // }
    case STORE_VOLUME_RAW: {
      return state.setIn(['plan', 'planningParameters', 'volumeCategoryParametersRaw'], action.payload);
    }
    case STORE_RESULT_VOLUME_RAW: {
      return state.set('masterPlanvolumeCategoryParametersRaw', action.payload);
    }
    case IS_EDITING:
      return state.set('isEditing', action.payload);
    case CLEAN_CALCULATION:
      return state.set('table', initialState.get('table'));
    case CLEAN_TREND_DATA:
      return state
        .set('graphDataTrendFiltered', initialState.get('graphDataTrendFiltered'))
        .set('graphDataTrend', initialState.get('graphDataTrend'))
        .set('graphDataTrendSettings', initialState.get('graphDataTrendSettings'));
    case CLEAN:
    case CHANGE_LOCALE:
    case SAVE_PLAN_DONE:
    case ADD_PLANNED_VOLUME_LINE:
    case DELETE_PLANNED_VOLUME:
    case SAVE_PLANNED_VOLUME_HEADER:
    case SAVE_PA:
      return initialState;
    case OVERRIDES_SAVED:
      return state.set('overridesToBeSaved', initialState.get('overridesToBeSaved'));
    case OVERRIDE_EFFORT:
      return updateData('effort', 'hoursEffort', 'effortSrc', state)(action);
    case OVERRIDE_PRODUCTION:
      return updateData('production', 'productivityRate', 'productivityRateSrc', state)(action);
    case OVERRIDE_VOLUME:
      return updateData('volume', 'volume', 'volumeSrc', state)(action);
    case EDIT_MODE_CHANGE: {
      const editing = action.payload === true;
      return state.setIn(['guiState', 'edit'], editing);
    }
    case OVERRIDES_DISCARD:
      return state.set('overridesToBeSaved', initialState.get('overridesToBeSaved'));
    case FETCH_RESULTS:
      return state.set('results', initialState.get('results'));
    case STORE_CALCULATED_TABLES_DEF: {
      const { activity, mhe, role } = action.payload;
      return state.setIn(['table', 'activity', 'colDef'], activity.colDef).setIn(['table', 'mhe', 'colDef'], mhe.colDef).setIn(['table', 'role', 'colDef'], role.colDef);
    }
    case STORE_CALCULATED_RESULT_TABLES:{
      return state.set('table', action.payload)
    }
    case STORE_CALCULATED_TABLES: {
      const {
        defsAndData: { activity, mhe, editing, isShift, byHour, role },
        settings,
      } = action.payload;
      let newState = state;
      newState = newState.setIn(['table', 'editing'], editing);
      newState = newState.setIn(['table', 'settings'], settings);
      newState = newState.setIn(['table', 'isShift'], isShift);
      newState = newState.setIn(['table', 'byHour'], byHour);

      if (mhe) {
        newState = newState
          .setIn(['table', 'mhe', 'colDef'], mhe.colDef)
          .setIn(['table', 'mhe', 'data'], mhe.data)
          .setIn(['table', 'mhe', 'totals'], mhe.totals);
      }

      if (role) {
        newState = newState
          .setIn(['table', 'role', 'colDef'], role.colDef)
          .setIn(['table', 'role', 'data'], role.data)
          .setIn(['table', 'role', 'totals'], role.totals);
      }

      return newState
        .setIn(['table', 'activity', 'colDef'], activity.colDef)
        .setIn(['table', 'activity', 'data'], activity.data)
        .setIn(['table', 'activity', 'totals'], activity.totals)
        .setIn(['table', 'calculationStatus'], 'finished')
        .set('canShowOverrideHelper', newState.getIn(['guiState', 'edit'])); // currently not used
    }
    case STORE_GRAPH_DATA_TREND:
      return state.set('graphDataTrend', action.payload.result).set('graphDataTrendSettings', action.payload.settings);

    case STORE_RESULTS: {
      return state.set('results', action.payload).set('overridesToBeSaved', initialState.get('overridesToBeSaved'));
    }
    case SET_GRAPH_DATA_TREND_FILTERED:
      return state.set('graphDataTrendFiltered', action.payload);
    case SET_GRAPH_DATA_ACTIVITY_FILTERED:
      return state.set('graphDataActivityFiltered', action.payload);
    case SET_GRAPH_DATA_DILO_FILTERED:
      return state.set('graphDataDiloFiltered', action.payload);
    case STORE_HEADS_GRAPH_DATA:
      return state.set('graphHeahs', action.payload);
    case STORE_HEADS_GRAPH_FILTERED_DATA:
      return state.set('graphHeahs', { ...state.get('graphHeahs'), ...action.payload });
    case MAPPING_OMS_CODES:
      return state.set('mappingOMSCodes', action.payload);  
    case TOGGLE_VOLUME_FORMULA:
      return state.set('showFormula', action.payload);
     case TOGGLE_VOLUME_VALUE:
      return state.set('showValue', action.payload);     
    default:
      return state;
  }
}

export default planResultPageReducer;
