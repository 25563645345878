/*
 * ResultMatrixActivitiesTable Messages
 *
 * This contains all the text for the ResultMatrixActivitiesTable component.
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  activities: {
    id: 'app.components.ResultMatrixActivitiesTable.activities',
    defaultMessage: 'Activities',
  },
  mhe: {
    id: 'app.components.ResultMatrixActivitiesTable.mhe',
    defaultMessage: 'Material Handling Equipment',
  },
  activity: {
    id: 'app.components.ResultMatrixActivitiesTable.activity',
    defaultMessage: 'Activity',
  },
  activityCustom: {
    id: 'app.components.ResultMatrixActivitiesTable.activityCustom',
    defaultMessage: 'Activity (custom)',
  },
  colProductivityRate: {
    id: 'app.components.ResultMatrixActivitiesTable.colProductivityRate',
    defaultMessage: 'Productivity Rate',
  },
  csv: {
    id: 'app.components.ResultMatrixActivitiesTable.csv',
    defaultMessage: 'CSV',
  },
  uom: {
    id: 'app.components.ResultMatrixActivitiesTable.uom',
    defaultMessage: 'UoM',
  },
  uomPerHour: {
    id: 'app.components.ResultMatrixActivitiesTable.uomPerHour',
    defaultMessage: 'UoM/h',
  },
  week: {
    id: 'app.components.ResultMatrixActivitiesTable.week',
    defaultMessage: 'Week',
  },
  month: {
    id: 'app.components.ResultMatrixActivitiesTable.month',
    defaultMessage: 'Month',
  },
  colVolume: {
    id: 'app.components.ResultMatrixActivitiesTable.colVolume',
    defaultMessage: 'Volume',
  },
  colEffort: {
    id: 'app.components.ResultMatrixActivitiesTable.colEffort',
    defaultMessage: 'Effort h',
  },
  colFte: {
    id: 'app.components.ResultMatrixActivitiesTable.colFte',
    defaultMessage: 'FTE MD',
  },
  result: {
    id: 'app.components.ResultMatrixActivitiesTable.result',
    defaultMessage: 'Result',
  },
  colSumVolume: {
    id: 'app.components.ResultMatrixActivitiesTable.colSumVolume',
    defaultMessage: 'Sum Volume',
  },
  colSumEffort: {
    id: 'app.components.ResultMatrixActivitiesTable.colSumEffort',
    defaultMessage: 'Sum Effort h',
  },
  colSumFte: {
    id: 'app.components.ResultMatrixActivitiesTable.colSumFte',
    defaultMessage: 'Sum FTE MD',
  },
  sum: {
    id: 'app.components.ResultMatrixActivitiesTable.sum',
    defaultMessage: 'Sum',
  },
  labourDemandSum: {
    id: 'app.components.ResultMatrixActivitiesTable.labourDemandSum',
    defaultMessage: 'Labour Demand Sum',
  },
  youCanOverrideThisCell: {
    id: 'app.components.ResultMatrixActivitiesTable.youCanOverrideThisCell',
    defaultMessage: 'Cell can be overridden. Click to edit.',
  },
  overrideStateCalc: {
    id: 'app.components.ResultMatrixActivitiesTable.overrideStateCalc',
    defaultMessage: 'This cell is calculated normal way.',
  },
  overrideStateOverrideCalc: {
    id: 'app.components.ResultMatrixActivitiesTable.overrideStateOverrideCalc',
    defaultMessage: 'This cell is calculated from a overridden value.',
  },
  overrideStateOverriden: {
    id: 'app.components.ResultMatrixActivitiesTable.overrideStateOverriden',
    defaultMessage: 'This cell is overridden directly.',
  },
  overrideStateOverridenMhe:{
    id: 'app.components.PlanResultPage.overrideStateOverridenMhe',
    defaultMessage: 'This cell is overridden using MHE Assistant.',
  },
  overrideStateOverridenUser:{
    id: 'app.components.PlanResultPage.overrideStateOverridenUser',
    defaultMessage: 'This cell is overridden by User.',
  },
  justEdited: {
    id: 'app.components.ResultMatrixActivitiesTable.justEdited',
    defaultMessage: 'Value is edited but not saved. You have save in order to make values recalculate.',
  },
  budget: {
    id: 'app.components.ResultMatrixActivitiesTable.budget',
    defaultMessage: 'Baseline',
  },
  planned: {
    id: 'app.components.ResultMatrixActivitiesTable.planned',
    defaultMessage: 'Planned',
  },
  forecast: {
    id: 'app.components.ResultMatrixActivitiesTable.forecast',
    defaultMessage: 'Forecast',
  },
  origPlanned: {
    id: 'app.components.ResultMatrixActivitiesTable.origPlanned',
    defaultMessage: 'Planned Base',
  },
  actuals: {
    id: 'app.components.ResultMatrixActivitiesTable.actuals',
    defaultMessage: 'Actuals',
  },
  notStarted: {
    id: 'app.components.ResultMatrixActivitiesTable.notStarted',
    defaultMessage: 'Please confirm selection to see data.',
  },
  running: {
    id: 'app.components.ResultMatrixActivitiesTable.running',
    defaultMessage: 'Calculating',
  },
  loading: {
    id: 'app.components.ResultMatrixActivitiesTable.loading',
    defaultMessage: 'Loading',
  },
  department: {
    id: 'app.components.ResultMatrixActivitiesTable.department',
    defaultMessage: 'Department',
  },
  customer: {
    id: 'app.components.ResultMatrixActivitiesTable.customer',
    defaultMessage: 'Customer',
  },
  allDepartments: {
    id: 'app.components.ResultMatrixActivitiesTable.allDepartments',
    defaultMessage: 'All Departments',
  },
});
