// @flow

import React, { useState } from 'react';
import { Field } from 'formik';

import AutocompleteSelect from 'components/AutocompleteSelect';
import Label from 'components/Label';
import Select from 'components/StyledSelect';
import UIAsync from 'components/UIAsync';

import messages from './messages';

type Props = {
  formatMessage: Function,
  token: string,
};

const variable = 'planningParameters.smartShiftExportSettings';
const kronosConf = `${variable}.smartShiftConfiguration`;

export default function KronosField(props: Props) {
  const { formatMessage, token, planningParametersId } = props;
  const hour = { value: 'HOUR', label: formatMessage(messages.HOUR) };
  const quarter = { value: 'QUARTER_OF_HOUR', label: formatMessage(messages.QUARTER_OF_HOUR) };
  const timeSpanList = [hour, quarter];
  const appendixProps = planningParametersId
    ? {
        urlAppendixCallback: () => `&planningParametersId=${planningParametersId}`,
      }
    : {};

  return (
    <>
      <div className="PlanInterfaces__Kronos">
        <Label {...messages.name} className="PlanInterfaces__Label" />
        <Field
          name={kronosConf}
          render={({ field, form }) => (
            <AutocompleteSelect
              entity="smartShiftConfigurations"
              maxMenuHeight={120}
              token={token}
              value={field.value}
              placeholder={messages.selectKronos}
              className="KronosField__select"
              name={kronosConf}
              setValue={kronos => {
                /* console.log('option', option)
                        const value = option && option.kronos || null */
                form.setFieldValue(kronosConf, kronos);
              }}
              {...appendixProps}
            />
          )}
        />
      </div>
      <div className="PlanInterfaces__Kronos">
        <Label {...messages.description} className="PlanInterfaces__Label" />
        <Field
          name={kronosConf}
          render={({ field }) => (
            <div className="PlanInterfaces__Kronos__Text">{field.value && field.value.description}</div>
          )}
        />
      </div>
      <div className="PlanInterfaces__Kronos">
        <Label {...messages.url} className="PlanInterfaces__Label" />
        <Field
          name={kronosConf}
          render={({ field }) => <div className="PlanInterfaces__Kronos__Text">{field.value && field.value.url}</div>}
        />
      </div>
      <div className="PlanInterfaces__Kronos">
        <Label {...messages.timeSpan} className="PlanInterfaces__Label" />
        <Field
          name={`${variable}.granularity`}
          render={({ field, form }) => (
            <Select
              className="KronosField__select"
              options={timeSpanList}
              onChange={option => {
                form.setFieldValue(`${variable}.granularity`, option.value);
              }}
              value={(field.value === 'QUARTER_OF_HOUR' && quarter) || hour}
            />
          )}
        />
      </div>
    </>
  );
}
