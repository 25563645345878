/*
 *
 * ListPage constants
 *
 */

export const DEFAULT_ACTION = 'app/ListPage/DEFAULT_ACTION';
export const LOAD_LIST = 'app/ListPage/LOAD_LIST';
export const LOAD_LIST_SUC = 'app/ListPage/LOAD_LIST_SUC';
export const REMOVE_SELECTED = 'app/ListPage/REMOVE_SELECTED';
export const ENABLE_SELECTED = 'app/ListPage/ENABLE_SELECTED';
export const REMOVE_SELECTED_SUC = 'app/ListPage/REMOVE_SELECTED_SUC';
export const ROW_SELECTED = 'app/ListPage/ROW_SELECTED';
export const CHANGE_FILTER = 'app/ListPage/CHANGE_FILTER';
export const CHANGE_VIEW = 'app/ListPage/CHANGE_VIEW';
export const MASTER_PLAN_REPORT_RUNS = 'app/ListPage/MASTER_PLAN_REPORT_RUNS';
export const RESET_MASTER_PLAN_REPORT_RUNS = 'app/ListPage/RESET_MASTER_PLAN_REPORT_RUNS';
export const CONFIRM_MASTER_PLAN_REPORT_RUNS = 'app/ListPage/CONFIRM_MASTER_PLAN_REPORT_RUNS';
export const SMARTPROD_SOURCES_REPORT_RUNS = 'app/ListPage/SMARTPROD_SOURCES_REPORT_RUNS';
export const LIST_LOADER = 'app/ListPage/LIST_LOADER';
