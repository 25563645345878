export const extractValues = mappings => Object.keys(mappings);

export const lookupValue = (mappings, key) => mappings[key];

export function lookupKey(mappings, name) {
  const keys = Object.keys(mappings);
  /* eslint-disable-next-line no-plusplus */
  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    if (mappings[key] === name) {
      return key;
    }
  }
  return null;
}
