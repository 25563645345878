/*
 *
 * LoginPage actions
 *
 */
import { createFormAction } from 'redux-form-saga';
import { STORE_TOKEN, LOGIN, RESET, STORE_USER, LOGOUT, LOAD_USER } from './constants';

export const login = createFormAction(LOGIN);
export const reset = createFormAction(RESET);

export function storeTokenAction(payload) {
  return {
    type: STORE_TOKEN,
    payload,
  };
}

export function storeUserAction(payload) {
  return {
    type: STORE_USER,
    payload,
  };
}
export function logoutAction() {
  return {
    type: LOGOUT,
  };
}

export function loadUserAction() {
  return {
    type: LOAD_USER,
  };
}
