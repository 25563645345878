import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { injectIntl } from 'react-intl';
import { Title, Wrapper } from 'components/ToggleSection';
import './style.css';

function STabs(props) {
  const {
    value,
    onChange,
    tabs,
    children,
    intl: { formatMessage },
  } = props;
  return (
    <Tabs
      value={value}
      indicatorColor="primary"
      textColor="primary"
      onChange={onChange}
      classes={{
        root: 'upload_list_history_tabs',
        indicator: 'ul_ht_indicator',
      }}
    >
      {tabs.map((tab, index) => (
        <Tab
          classes={{
            root: 'upload_list_history_tab',
          }}
          key={`sc_${index}`}
          label={formatMessage(tab.label)}
        />
      ))}
    </Tabs>
  );
}

export default injectIntl(STabs);
