import { toast } from 'react-toastify';
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';

import { api, withUrl } from '../../utils/api';
import { storeActivitiesAction, storeFromBSAction, storeUOMSAction } from './actions';
import { LOAD_ACTIVITIES, LOAD_FROM_BROWSERSTORAGE, LOAD_UOMS, SAVE_TO_BROWSERSTORAGE, SHOW_MESSAGE } from './constants';

function* loadUOMSSaga(action) {
  const result = yield call(api, withUrl(`/uoms/?expression=&pageSize=100000&planningParametersId=${action.payload}`));
  if (result.isOk) {
    yield put(storeUOMSAction(result.data.results));
  }
}

function* loadActivitiesSaga(action) {
  const result = yield call(
    api,
    withUrl(`/activities/?expression=&pageSize=100000&planningParametersId=${action.payload}`),
  );
  if (result.isOk) {
    yield put(storeActivitiesAction(result.data.results));
  }
}

function* showMessage() {
  yield call(wait, 250);
  toast.info('Data saved.');
}

function* saveToBSSaga(action) {
  const { key, value } = action.payload;
  if (localStorage) {
    saveToLocalstorage(key, value);
    yield put({ type: SHOW_MESSAGE });
  } else {
    toast.error('No browser storage available.');
  }
}

export function saveToLocalstorage(key, value) {
  if (localStorage) {
    if (value === undefined) {
      localStorage.removeItem(key);
    } else {
      localStorage.setItem(key, JSON.stringify(value));
    }
  }
}

export function loadFromLocalstorage(key, def) {
  if (localStorage) {
    const value = localStorage.getItem(key);
    if (value !== null && value !== undefined && value !== 'undefined') {
      return JSON.parse(value);
    }
    return def;
  }
  return undefined;
}

export function* loadFromBSSaga(action) {
  const { key, defaultValue } = action.payload;
  let result;
  if (localStorage) {
    result = loadFromLocalstorage(key, defaultValue);
    yield put(storeFromBSAction({ key, value: result }));
  } else {
    toast.error('No browser storage available.');
  }
  return result;
}

function wait(ms, result = true) {
  return new Promise(resolve => setTimeout(() => resolve(result), ms));
}

export default function* defaultSaga() {
  yield takeEvery(SAVE_TO_BROWSERSTORAGE, saveToBSSaga);
  yield takeEvery(LOAD_FROM_BROWSERSTORAGE, loadFromBSSaga);
  yield takeLatest(LOAD_UOMS, loadUOMSSaga);
  yield takeLatest(LOAD_ACTIVITIES, loadActivitiesSaga);
  yield takeLatest(SHOW_MESSAGE, showMessage);
}
