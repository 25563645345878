/*
 * ActivitySettingsIndirect Messages
 *
 * This contains all the text for the ActivitySettingsIndirect component.
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  header: {
    id: 'app.components.ActivitySettingsIndirect.header',
    defaultMessage: 'Effort based',
  },
  InDirectheader: {
    id: 'app.components.ActivitySettingsIndirect.InDirectheader',
    defaultMessage: 'Effort based (hours)',
  },
  activity: {
    id: 'app.components.ActivitySettingsIndirect.activity',
    defaultMessage: 'Activity',
  },
  activityCustom: {
    id: 'app.components.ActivitySettingsIndirect.activityCustom',
    defaultMessage: 'Activity (custom)',
  },
  customerId: {
    id: 'app.components.ActivitySettingsDirect.customerId',
    defaultMessage: 'Customer',
  },
  customerName: {
    id: 'app.components.ActivitySettingsDirect.customerName',
    defaultMessage: 'Customer',
  },
  all: {
    id: 'app.components.ActivitySettingsDirect.all',
    defaultMessage: 'All',
  },
  formula: {
    id: 'app.components.ActivitySettingsIndirect.formula',
    defaultMessage: 'Formula (effort)',
  },
  formulaHour: {
    id: 'app.components.ActivitySettingsIndirect.formulaHour',
    defaultMessage: 'Formula Effort (hours)',
  },
  isEffortForecast: {
    id: 'app.components.ActivitySettingsIndirect.effortForecast',
    defaultMessage: 'Effort Forecast',
  },
  isEffortForecastHour: {
    id: 'app.components.ActivitySettingsIndirect.isEffortForecastHour',
    defaultMessage: 'Effort Forecast (hours)',
  },
  omsCode: {
    id: 'app.components.ActivitySettingsIndirect.omsCode',
    defaultMessage: 'OMS Code',
  },
  omsName: {
    id: 'app.components.ActivitySettingsIndirect.omsName',
    defaultMessage: 'OMS Code',
  },
  effortPerHead: {
    id: 'app.components.ActivitySettingsIndirect.effortPerHead',
    defaultMessage: 'Effort Per Head',
  },
  effortPerHeadHour: {
    id: 'app.components.ActivitySettingsIndirect.effortPerHeadHour',
    defaultMessage: 'Effort Per Head (hours)',
  },
  formulaText: {
    id: 'app.components.ActivitySettingsIndirect.formulaText',
    defaultMessage: 'Formula',
  },
  action: {
    id: 'app.components.ActivitySettingsIndirect.action',
    defaultMessage: 'Action',
  },
  details: {
    id: 'app.components.ActivitySettingsIndirect.details',
    defaultMessage: 'Details',
  },
  delete: {
    id: 'app.components.ActivitySettingsIndirect.delete',
    defaultMessage: 'Delete',
  },
  detailsButtons: {
    id: 'app.components.ActivitySettingsIndirect.detailsButtons',
    defaultMessage: 'Details',
  },
  customName: {
    id: 'app.components.ActivitySettingsIndirect.customName',
    defaultMessage: 'Name',
  },
  departmentId: {
    id: 'app.components.ActivitySettingsIndirect.departmentId',
    defaultMessage: 'Department',
  },
  allDepartments: {
    id: 'app.components.ActivitySettingsIndirect.allDepartments',
    defaultMessage: 'All Departments',
  },
  comment: {
    id: 'app.components.ActivitySettingsIndirect.comment',
    defaultMessage: 'Comment',
  },
});
