/*
 * PlannedVolumeHeader Messages
 *
 * This contains all the text for the PlannedVolumeHeader component.
 */

import { defineMessages } from 'react-intl';

import { VOLUME_GRANULARITY_DAILY, VOLUME_GRANULARITY_WEEKLY } from '../../utils/constants';

export default defineMessages({
  startDate: {
    id: 'app.components.PlannedVolumeHeader.startDate',
    defaultMessage: 'Start Date',
  },
  endDate: {
    id: 'app.components.PlannedVolumeHeader.endDate',
    defaultMessage: 'End Date',
  },
  saveFromTo: {
    id: 'app.components.PlannedVolumeHeader.save',
    defaultMessage: 'Confirm',
  },
  setEndDate: {
    id: 'app.components.PlannedVolumeHeader.setEndDate',
    defaultMessage: 'Set End Date',
  },
  setStartDate: {
    id: 'app.components.PlannedVolumeHeader.setStartDate',
    defaultMessage: 'Set Start Date',
  },
  [VOLUME_GRANULARITY_WEEKLY]: {
    id: 'app.components.PlannedVolumeHeader.WEEKLY',
    defaultMessage: 'Weekly',
  },
  [VOLUME_GRANULARITY_DAILY]: {
    id: 'app.components.PlannedVolumeHeader.DAILY',
    defaultMessage: 'Daily',
  },
  granularity: {
    id: 'app.components.PlannedVolumeHeader.granularity',
    defaultMessage: 'Granularity',
  },
  startEndDatesRangeError: {
    id: 'app.components.PlannedVolumeHeader.startEndDatesRangeError',
    defaultMessage: 'Maximum range for date interval is 3 months',
  },
});
