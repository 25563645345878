/*
 * PlanDetailWorkzonePeriod Messages
 *
 * This contains all the text for the PlanDetailWorkzonePeriod component.
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  name: {
    id: 'app.components.PlanDetailWorkzonePeriod.name',
    defaultMessage: 'Name',
  },
  newName: {
    id: 'app.components.PlanDetailWorkzonePeriod.newName',
    defaultMessage: 'Name for new',
  },
  startTime: {
    id: 'app.components.PlanDetailWorkzonePeriod.startTime',
    defaultMessage: 'Start Time',
  },
  endTime: {
    id: 'app.components.PlanDetailWorkzonePeriod.endTime',
    defaultMessage: 'End Time',
  },
  breakTime: {
    id: 'app.components.PlanDetailWorkzonePeriod.breakTime',
    defaultMessage: 'Break Time',
  },
  minutes: {
    id: 'app.components.PlanDetailWorkzonePeriod.minutes',
    defaultMessage: '(minutes)',
  },
  add: {
    id: 'app.components.PlanDetailWorkzonePeriod.add',
    defaultMessage: 'Add',
  },
  required: {
    id: 'app.components.PlanDetailWorkzonePeriod.required',
    defaultMessage: 'Required',
  },
  setStartTime: {
    id: 'app.components.PlanDetailWorkzonePeriod.setStartTime',
    defaultMessage: 'Set Start Time',
  },
  setEndTime: {
    id: 'app.components.PlanDetailWorkzonePeriod.setEndTime',
    defaultMessage: 'Set End Time',
  },
  wzpConfirmDelete: {
    id: 'app.components.PlanDetailWorkzonePeriod.wzpConfirmDelete',
    defaultMessage: 'Delete workzone period?',
  },
});
