/* eslint-disable guard-for-in,no-restricted-syntax,no-plusplus */
import React from 'react';
import {push, replace} from 'connected-react-router';
import get from 'lodash/get';
import {FormattedMessage} from 'react-intl';
import {toast} from 'react-toastify';
import {call, put, select, takeLatest} from 'redux-saga/effects';
import {fetchEffortForecastForPlan} from 'containers/PlanningAreaDetailPage/saga';
import {
  addActivityLine,
  addAdjustmentLine,
  addLabourCategoryLine,
  addPeriod,
  addShiftSettings,
  addVolumeLine,
  copyPeriod,
  deleteLabourCategoryLine,
  deletePeriod,
  deleteShiftSettings,
  deleteWeeklyVolumes,
  diffPlanningParameters,
  doAddWzp,
  doDeleteWzp,
  extractDiffFieldsShallow,
  extractDiffValuesFromEffortForecast,
  savePlaningParametersFromTo,
  spreadIntoChildFieldIfNotEmpty,
  waitForSaveAll,
} from 'utils/commonDetailSaga';

import {isEqual} from "lodash";
import {api, convertEntityWithPlanningParametersFromApi, withUrl} from '../../utils/api';
import {loadActivitiesAction, loadUOMSAction, storeActivitiesByPlanAction} from '../App/actions';
import {PATHS} from '../App/constants';
import {CLOSE_MODAL} from '../UploadModal/constants';
import {
  planDetailsLoader,
  planDetailSmartProdVolumeRunsFromSaga,
  reloadPlanDetail,
  saveplanDetailMasterPlanDetails,
  saveplanDetailScheduleDetails,
  saveplanDetailSmartProdVolumeRuns,
  savePlanSmartCodeMappings,
  setPeriodIndexPlanAction,
  startPlanCopyAction,
  storePlan,
  storePlanEdit,
  storePlanLoading,
  planDetailsLoaderFlag,
  planDetailsOverviewFlag,
  planDetailSmartProdVolumeRuns,
  clearPlanMHEAction,
  storeRole,
  saveMasterPlanConflict,
  savePlanError,
  savePlanDone,
  saveomsChangeHistory,
} from './actions';
import {
  ADD_ACTIVITY_LINE,
  ADD_ADJUSTMENT_LINE,
  ADD_LABOUR_AVAILABILITY_CATEGORY_LINE,
  ADD_PERIOD_PLAN,
  ADD_PLANNED_VOLUME_LINE,
  ADD_SHIFT_SETTINGS_LINE,
  ADD_WZP,
  COPY_PERIOD_PLAN,
  COPY_PLAN,
  DELETE_ACTIVITY_LINES,
  DELETE_ADJUSTMENT_LINE,
  DELETE_LABOUR_AVAILABILITY_CATEGORY_LINE,
  DELETE_PERIOD_PLAN,
  DELETE_PLANNED_VOLUME,
  DELETE_SHIFT_SETTINGS_LINE,
  DELETE_WZP,
  LOAD_PLAN,
  LOAD_PLAN_DETAILS,
  LOAD_PLAN_OVERVIEW_DATA,
  NAVIGATE_PLAN_DETAIL,
  PLAN_DETAIL_SMARTPROD_SMARTVOLUME_RUNS,
  PLANDETAIL_MASTERPLANS,
  PLANDETAIL_SCHEDULES,
  RESET_PLAN_BUDGET,
  SAVE_ADJUSTMENT_OVERVIEW,
  SAVE_LABOUR_OVERVIEW,
  SAVE_MHE_OVERVIEW,
  SAVE_PLAN,
  SAVE_PLANNED_VOLUME_HEADER,
  SWITCH_TO_DISPLAY_PLAN,
  OMS_CHANGE_HISTORY,
} from './constants';
import messages from './messages';
import {
  selectGuiState,
  selectPeriodIdFromPlan,
  selectPlan,
  selectPlanningParametersId,
  selectPlanSmartCodeMappings,
} from './selectors';
import { selectUOMS } from '../PlanningAreaDetailPage/selectors';

export default function* defaultSaga() {
  yield takeLatest(SAVE_PLANNED_VOLUME_HEADER, savePlanPlaningParametersFromTo);
  yield takeLatest(ADD_PLANNED_VOLUME_LINE, addPlannedVolumeLine);
  yield takeLatest(ADD_ACTIVITY_LINE, addPlanActivityLine);
  yield takeLatest(ADD_ADJUSTMENT_LINE, addPlanAdjustmentLine)
  yield takeLatest(DELETE_ACTIVITY_LINES, deleteActivityLines);
  yield takeLatest(DELETE_ADJUSTMENT_LINE, deleteAdjustmentLines)
  yield takeLatest(DELETE_PLANNED_VOLUME, deletePlanWeeklyVolumes);
  yield takeLatest(COPY_PLAN, copyPlan);
  yield takeLatest(RESET_PLAN_BUDGET, resetPlanBudgetSaga);
  yield takeLatest(SAVE_PLAN, saveAllPlan);
  yield takeLatest(DELETE_WZP, doDeleteWzpPlan);
  yield takeLatest(ADD_WZP, doAddWzpPlan);
  yield takeLatest(LOAD_PLAN, doLoadPlanBasic);
  yield takeLatest(LOAD_PLAN_DETAILS, doLoadPlanDetails);
  yield takeLatest(LOAD_PLAN_OVERVIEW_DATA, doLoadPlanOverviewData)
  yield takeLatest(SWITCH_TO_DISPLAY_PLAN, doSwitchToDisplay);
  yield takeLatest(ADD_PERIOD_PLAN, addPlanPeriod);
  yield takeLatest(COPY_PERIOD_PLAN, copyPlanPeriod);
  yield takeLatest(DELETE_PERIOD_PLAN, deletePlanPeriod);
  yield takeLatest(ADD_LABOUR_AVAILABILITY_CATEGORY_LINE, addLabourAvailabilityCategoryLine);
  yield takeLatest(DELETE_LABOUR_AVAILABILITY_CATEGORY_LINE, deleteLabourAvailabilityCategoryLine);
  yield takeLatest(ADD_SHIFT_SETTINGS_LINE, addShiftSettingsLine);
  yield takeLatest(DELETE_SHIFT_SETTINGS_LINE, deleteShiftSettingsLine);
  yield takeLatest(CLOSE_MODAL, doOnCloseUploadModal);
  yield takeLatest(PLANDETAIL_MASTERPLANS, planDetailMasterPlanDetailsSaga)
  yield takeLatest(PLAN_DETAIL_SMARTPROD_SMARTVOLUME_RUNS, planDetailSmartProdVolumeRunsSaga )
  yield takeLatest(SAVE_LABOUR_OVERVIEW, saveLabourOverViewSaga)
  yield takeLatest(SAVE_MHE_OVERVIEW, saveMheOverViewSaga)
  yield takeLatest(SAVE_ADJUSTMENT_OVERVIEW, saveAdjustmentOverViewSaga)
  yield takeLatest(PLANDETAIL_SCHEDULES, planDetailSchedulessSaga)
  yield takeLatest(OMS_CHANGE_HISTORY, omsChangeHistory)
}

const newPlan = {
  name: '',
  description: '',
  deleted: false,
  planningParameters: {},
};

function* resetPlanBudgetSaga(action) {
  const dataWrapper = action.payload;
  if (dataWrapper) {
    yield call(waitForSaveAll, dataWrapper);
    const result = yield call(api, withUrl(`/plans/${dataWrapper.data.id}/resetBudget`).post());
    if (result.isOk) {
      yield call(reload);
      toast(<FormattedMessage {...messages.planBudgetRefresh} />);
    }
  }
}

function* doLoadPlanOverviewData(){
  yield put(planDetailsOverviewFlag(true));
  const plan = yield select(selectPlan);
  yield fetchRoles(plan);
    const effortForecast = yield fetchEffortForecastForPlan(plan);
    const labourOverViewData = yield fetchLabourOverview(plan);
    const labourOverrideData = [];
    const mheOverViewData = yield fetchmheOverview(plan);
    const mheOverrideData = [];
    const adjustmentOverviewData = yield fetchAdjustmentOverview(plan);
    const adjsutmentOverrideData = [];
    yield put(loadActivitiesAction(plan.planningParameters.id));
    yield put(loadUOMSAction(plan.planningParameters.id));
    yield getPlanMappings(plan.id)
    const planDetails = {
      ...plan,
      effortForecast,
      labourOverViewData,
      labourOverrideData,
      mheOverViewData,
      mheOverrideData,
      adjsutmentOverrideData,
      adjustmentOverviewData
    };
  yield put(storePlan(planDetails));
  yield put(planDetailsOverviewFlag(false));
  //yield put(planDetailSmartProdVolumeRunsFromSaga());
  //yield planDetailSmartProdVolumeRunsSaga(plan.planningParametersId);
}

function* doLoadPlanDetails(action){
  yield put(planDetailsLoaderFlag(true));
  const plan = action.payload.id ? yield call(fetchPlanDetails, action.payload.id) : newPlan;
  if (plan) {
    plan.reloadCount = action.payload.reloadCount === undefined ? 0 : action.payload.reloadCount + 1;
    yield put(storePlan(plan));
    if (action.payload.copy === true) {
      yield put(startPlanCopyAction());
    } else if (action.payload.edit === true || action.payload.edit === false) {
      yield put(storePlanEdit(action.payload.edit));
    }
    yield put(planDetailsLoaderFlag(false));
  }
  return plan;
}

function* doLoadPlanBasic(action){
  yield put(storePlanLoading(true));
  yield put(planDetailsLoader(true));
  const plan = action.payload.id ? yield call(fetchPlan, action.payload.id) : newPlan;
  yield put(planDetailsLoader(false));
  if (plan) {
    plan.reloadCount = action.payload.reloadCount === undefined ? 0 : action.payload.reloadCount + 1;
    yield put(storePlan(plan));
  }
  return plan;
}

function* doLoadPlan(action) {
  yield put(storePlanLoading(true));
  yield put(planDetailsLoader(true));
  const plan = action.payload.id ? yield call(fetchPlan, action.payload.id) : newPlan;
  yield put(planDetailsLoader(false));
  if (plan) {
    plan.reloadCount = action.payload.reloadCount === undefined ? 0 : action.payload.reloadCount + 1;
    yield put(storeActivitiesByPlanAction(plan));
    yield put(storePlan(plan));
    yield put(loadActivitiesAction(plan.planningParameters.id));
    yield put(loadUOMSAction(plan.planningParameters.id));
    if (action.payload.copy === true) {
      yield put(startPlanCopyAction());
    } else if (action.payload.edit === true || action.payload.edit === false) {
      yield put(storePlanEdit(action.payload.edit));
    }
    yield getPlanMappings(action.payload.id)
    //yield put(planDetailSmartProdVolumeRunsFromSaga())
    //yield planDetailSmartProdVolumeRunsSaga(plan.planningParametersId)
  }
  return plan;
}

function* doLoadPlanLabour(action) {
  yield put(planDetailsOverviewFlag(true));
  const plan = yield select(selectPlan);    
    const labourOverViewData = yield fetchLabourOverview(plan);
    const labourOverrideData = [];
    const mheOverViewData = yield fetchmheOverview(plan);
    const mheOverrideData = [];
    const adjustmentOverviewData = yield fetchAdjustmentOverview(plan);
    const adjsutmentOverrideData = [];
    const planDetails = {
      ...plan,
      labourOverViewData,
      labourOverrideData,
      mheOverViewData,
      mheOverrideData,
      adjsutmentOverrideData,
      adjustmentOverviewData
    };
  yield put(storePlan(planDetails));
  yield put(planDetailsOverviewFlag(false));
}

function* navigateToPlanDetail(action) {
  const plan = yield call(doLoadPlan, action);
  if (plan) {
    const navFunction = action.payload.replace === true ? replace : push;
    yield put(navFunction(PATHS.planDetail));
  }
}

function* deletePlanWeeklyVolumes(action) {
  const plan = yield select(selectPlan);
  const planningParametersId = get(plan, 'planningParameters.id');
  const res = yield call(deleteWeeklyVolumes, planningParametersId, action.payload.volumeCategory.id);
  if (res.isOk) {
    yield call(reload);
    toast(<FormattedMessage {...messages.planVolumesDeleted} />);
  }
}

function* fetchLabourOverview(plan) {
  const response = yield call(
    api,
    withUrl(`/planningParameters/${plan.planningParametersId}/labourAvailability`),
  );
  if(response.isOk){
    return response.data;
  }
}

function* fetchmheOverview(plan) {
  const response = yield call(
    api,
    withUrl(`/planningParameters/${plan.planningParametersId}/mheAvailability`),
  );
  if(response.isOk){
    return response.data;
  }
}

function* fetchAdjustmentOverview(plan) {
  const response = yield call(
    api,
    withUrl(`/planningParameters/${plan.planningParametersId}/activityForecastAdjustments`),
  );
  if(response.isOk){
    return response.data;
  }
}

function* fetchRoles(plan){
  const response = yield call(
    api,
    withUrl(`/roles/?expression=&planningParametersId=${plan.planningParametersId}`),
  );
  if(response.isOk){
    yield put(storeRole(response.data));
  }
}

export function* fetchPlan(id, logResponse) {
  // the plan could be big se logging might have performance impact
  // atm it can be optionally disabled
  const response = yield call(
    api,
    withUrl(`/plans/basic/${id}`)
      .andTitle('Loading plan')
      .disableLogCalls(logResponse || true),
  );
  if (response.isOk) {
    const data = convertEntityWithPlanningParametersFromApi(response.data);
    return data;
  }
  return null;
}

export function* fetchPlanDetails(id, logResponse) {
  const response = yield call(
    api,
    withUrl(`/plans/details/${id}`)
      .andTitle('Loading plan details')
      .disableLogCalls(logResponse || true),
  );
  if (response.isOk) {
    const data = convertEntityWithPlanningParametersFromApi(response.data);
    return data;

  }
  return null;
}

function* wrapWithPlanningParametersAndCall(action, functionToCall, toastMessage) {
  const dataWrapper = action.payload;
  dataWrapper.planningParametersId = yield select(selectPlanningParametersId);
  dataWrapper.periodId = yield select(selectPeriodIdFromPlan);
  const response = yield call(functionToCall, dataWrapper);
  if (response.isOk) {
    yield call(reload);
    if(action.type === "app/PlanDetailPage/DELETE_PERIOD_PLAN"){
      yield put(setPeriodIndexPlanAction(0));
    }
    if (toastMessage) {
      toast(toastMessage);
    }
  }
  return response;
}

function* savePlanPlaningParametersFromTo(action) {
  return yield call(wrapWithPlanningParametersAndCall, action, savePlaningParametersFromTo, 'Start/end time updated');
}

function* doDeleteWzpPlan(action) {
  return yield call(wrapWithPlanningParametersAndCall, action, doDeleteWzp);
}

function* doAddWzpPlan(action) {
  return yield call(wrapWithPlanningParametersAndCall, action, doAddWzp);
}

function* doSwitchToDisplay(action) {
  const dataWrapper = action.payload;
  if (dataWrapper) {
    const response = yield call(waitForSaveAll, dataWrapper);
    if (response && response.isOk) {
      yield put(storePlanEdit(false));
      yield call(reload);
    }
  }
}

function* addPlannedVolumeLine(action) {
  const planningParametersId = yield select(selectPlanningParametersId);
  const response = yield call(addVolumeLine, action, planningParametersId);
  if (response.isOk) {
    yield call(reload);
  }
}

function* addPlanActivityLine(action) {
  return yield call(wrapWithPlanningParametersAndCall, action, addActivityLine);
}

function* addPlanAdjustmentLine(action){
  return yield call(wrapWithPlanningParametersAndCall, action, addAdjustmentLine);
}

function* addPlanPeriod(action) {
  const result = yield call(wrapWithPlanningParametersAndCall, action, addPeriod);
  yield put(setPeriodIndexPlanAction(-1));
  if (result && result.data && result.data.returnMessage) {
    toast(result.data.returnMessage);
  }
}

function* copyPlanPeriod(action) {
  const result = yield call(wrapWithPlanningParametersAndCall, action, copyPeriod);
  yield put(setPeriodIndexPlanAction(-1));
  if (result && result.data && result.data.returnMessage) {
    toast(result.data.returnMessage);
  }
}

function* deletePlanPeriod(action) {
  yield put(setPeriodIndexPlanAction(0));
  return yield call(wrapWithPlanningParametersAndCall, action, deletePeriod);
}

function* deleteActivityLines(action) {
  const plan = yield select(selectPlan);
  const planningParametersId = get(plan, 'planningParameters.id');
  const paId = action.payload.id;
  const periodId = yield select(selectPeriodIdFromPlan);
  const url = `/planningParameters/${planningParametersId}/periods/${periodId}/activityParameters/${paId}/delete`;
  const response = yield call(api, withUrl(url).post().andTitle('Deleting activity line'));
  if (response.isOk) {
    yield call(reload);
    toast(<FormattedMessage {...messages.planActivityLineDeleted} />);
  }
}

function* deleteAdjustmentLines(action){
  const plan = yield select(selectPlan);
  const planningParametersId = get(plan, 'planningParameters.id');
  const paId = action.payload.id;
  const periodId = yield select(selectPeriodIdFromPlan);
  const url = `/planningParameters/${planningParametersId}/periods/${periodId}/activityAdjustments/${paId}/delete`;
  const response = yield call(api, withUrl(url).post().andTitle('Deleting activity line'));
  if (response.isOk) {
    yield call(reload);
    toast(<FormattedMessage {...messages.planAdjustmentLineDeleted} />);
  }
}

function* copyPlan(payload) {
  if (payload) {
    const { id, name, description } = payload;
    const url = `/plans/${id}/copy`;
    const response = yield call(api, withUrl(url).post({ name, description }).andTitle('Copying plan'));
    if (response.isOk) {
      yield put(reloadPlanDetail(response.data.id));
      toast(<FormattedMessage {...messages.planCopied} />);
    }
  } else {
    console.error('Data missing');
  }
}

function* saveLabourOverViewSaga(action){
  let response = ''
  if(action.granularity){
  const payloadToSend = { "labourAvailabilityGranularity":action.granularity }
  const Granularityurl = `/planningParameters/${action.id}`;
  response = yield call(api, withUrl(Granularityurl).post(payloadToSend).asRawResponse());
  }else{
  const url = `/planningParameters/${action.id}/labourAvailabilityOverrides`;
  response = yield call(api, withUrl(url).post(action.payload).asRawResponse());
  }
  if(response.ok){
    yield call(reload)
  }
}

function* saveMheOverViewSaga(action){
  let response = ''
  if(action.granularity){
  const payloadToSend = { "mheAvailabilityGranularity": action.granularity }
  const Granularityurl = `/planningParameters/${action.id}`;
  response = yield call(api, withUrl(Granularityurl).post(payloadToSend).asRawResponse());
  }else{
  const url = `/planningParameters/${action.id}/mheAvailabilityOverrides`;
  response = yield call(api, withUrl(url).post(action.payload).asRawResponse());
  }
  if(response.ok){
    yield call(reload)
  }
}

function* saveAdjustmentOverViewSaga(action){
  const url = `/planningParameters/${action.id}/activityForecastAdjustments`;
  const response = yield call(api, withUrl(url).post(action.payload).asRawResponse());
  if(response.ok){
    yield call(reload)
  }
}

function* saveAllPlan(action) {
  const guiState = yield select(selectGuiState);
  if (guiState.get('copy')) {
    return yield call(copyPlan, action.payload);
  }
  const values = action.payload;
  const saved = yield select(selectPlan);
  const mappings = yield select(selectPlanSmartCodeMappings);
  const uoms = yield select(selectUOMS);
  const { customers } = saved.planningArea;
  const { departments } = saved.planningParameters;
  const payload = {};
  spreadIntoChildFieldIfNotEmpty(payload, 'plan', extractDiffFieldsShallow(saved, values));
  spreadIntoChildFieldIfNotEmpty(payload, 'planningParameters', diffPlanningParameters(saved, values));
  // spreadIntoChildFieldIfNotEmpty(payload, 'test', extractDiffFieldsShallow(saved, values));
  // remove stored settings when changing plan
  // const user = yield select(makeSelectUserSimple());
  // saveToStorageById(user.user.login, saved && saved.id, undefined, SETTINGS_KEY);
  // saveToStorageById(user.user.login, saved && saved.id, undefined, COLUMN_SETTINGS_KEY);
  const periods = payload?.planningParameters?.periods;
  const customerMap = customers.reduce((map, customer) => {
    map[customer.id] = customer.code;
    return map;
  }, {});
  const departmentsMap = departments.reduce((map, department) => {
    map[department.id] = department.name;
    return map;
  }, {});
  const uomMap = uoms.reduce((map, uom) => {
    map[uom.id] = uom.name;
    return map;
  }, {});
  if (mappings) {
    periods?.forEach(period => {
      period?.activityParameters?.forEach(activity => {
        if (mappings[activity.activityParametersId] !== null && mappings[activity.activityParametersId] !== undefined) {
          getActivityMappedToSmartCodeMessage(
            mappings[activity.activityParametersId],
            activity,
            customerMap,
            uomMap,
            departmentsMap,
          );
        }
      });
    });
  }
  const isInsert = !saved.id;
  const url = isInsert ? `/plans/` : `/plans/${saved.id}/saveAll`;
  const response = yield call(
    api,
    withUrl(url)
      .post(isInsert ? { ...payload.plan, planningAreaId: values.planningArea.id } : payload)
      .andTitle('Saving plan'),
  );
  if (response.isOk) {
    if(payload && payload.planningParameters && payload.planningParameters.productivityRatePerShift === false || payload && payload.planningParameters && payload.planningParameters.productivityRatePerShift === true){
      yield put(clearPlanMHEAction());
    }
    const effortForecastToSave = extractDiffValuesFromEffortForecast(saved.effortForecast, values.effortForecast);
    if (effortForecastToSave && effortForecastToSave.oms && effortForecastToSave.oms.length) {
      yield call(
        api,
        withUrl(`/planningParameters/${values.planningParameters.id}/forecastPerOmsCode?jobsIncluded=false`)
          .post(effortForecastToSave)
          .asRawResponse(),
      );
    }
    if (
      !isEqual(saved.adjsutmentOverrideData, values.adjsutmentOverrideData) &&
      values.adjsutmentOverrideData !== undefined
    ) {
      // eslint-disable-next-line no-shadow
      const url = `/planningParameters/${values.planningParameters.id}/activityForecastAdjustments`;
      // eslint-disable-next-line no-shadow
      const response = yield call(api, withUrl(url).post(values.adjsutmentOverrideData).asRawResponse());
      if(!response.ok){
        return;
      }
    }
    if(!isEqual(saved.labourOverrideData, values.labourOverrideData) && values.labourOverrideData !== undefined){
      // eslint-disable-next-line no-shadow
      const url = `/planningParameters/${values.planningParametersId}/labourAvailabilityOverrides`;
      // eslint-disable-next-line no-shadow
      const response = yield call(api, withUrl(url).post(values.labourOverrideData).asRawResponse());
      if(!response.ok){
        return;
      }
    }

    if(!isEqual(saved.mheOverrideData, values.mheOverrideData) &&  values.mheOverrideData !== undefined){
      // eslint-disable-next-line no-shadow
      const url = `/planningParameters/${values.planningParameters.id}/mheAvailabilityOverrides`;
      // eslint-disable-next-line no-shadow
      const response = yield call(api, withUrl(url).post(values.mheOverrideData).asRawResponse());
      if(!response.ok){
        return;
      }
    }
    const plan = convertEntityWithPlanningParametersFromApi(response.data);
    yield getPlanMappings(plan.id);
    plan.effortForecast = yield fetchEffortForecastForPlan(plan);
    plan.labourOverViewData = yield fetchLabourOverview(plan);
    plan.labourOverrideData = [];
    plan.mheOverViewData = yield fetchmheOverview(plan);
    plan.mheOverrideData = [];
    plan.adjustmentOverviewData = yield fetchAdjustmentOverview(plan);
    //plan.adjustmentOverviewData = {...rowData};
    plan.adjsutmentOverrideData = [];
    plan.reloadCount = saved.reloadCount + 1;
    yield put(storePlan(plan));
    yield put(storeActivitiesByPlanAction(plan));
    yield put(loadActivitiesAction(plan.planningParameters.id));
    yield put(loadUOMSAction(plan.planningParameters.id));
    toast(<FormattedMessage {...messages.planSaved} />);
  } else if (response.errorType === 'MASTER_PLAN_IN_COLLISION') {
    yield put(saveMasterPlanConflict(response));
  } else if (response.errorType === 'PLAN_HAS_NO_FACILITY') {
    toast.error(messages.planHasNoFacility.defaultMessage);
    yield put(savePlanError(response));
  } else {
    yield put(savePlanError(response));
  }
  yield put(savePlanDone(response));
  return response;
}

function getActivityMappedToSmartCodeMessage(activityPath, activity, customers, uoms, departments) {
  const parts = activityPath?.activityPath.split('\\');
  if (activity.departmentId !== undefined) {

    parts[0] = departments[activity.departmentId] === undefined ? 'All Departments' : departments[activity.departmentId];
  }
  if (activity.customerId !== undefined) {
    parts[1] = customers[activity.customerId] === undefined ? 'All' : customers[activity.customerId];
  }
  if (activity.uomId) {
    parts[3] = uoms[activity.uomId];
  }
  const newPath = parts.join('/');
  const oldPath = activityPath?.activityPath.replaceAll('\\', '/');
  if (newPath !== oldPath) {
    toast.error(
      `Activity ${oldPath} is mapped with job code ${activityPath.jobCode}
` +
        '\n' +
        `You changing this activity to ${newPath} which would require to update SmartCode mapping`,
    );
  }
}

function* reloadLabour(id) {
  // eslint-disable-next-line no-unneeded-ternary
  const saved = yield select(selectPlan);
  const planId = id || saved.id;
  yield call(doLoadPlanLabour, reloadPlanDetail(planId, saved.reloadCount));
}

function* reload(id) {
  // eslint-disable-next-line no-unneeded-ternary
  const saved = yield select(selectPlan);
  const planId = id || saved.id;
  yield call(doLoadPlan, reloadPlanDetail(planId, saved.reloadCount));
}

function* addLabourAvailabilityCategoryLine(action) {
  return yield call(wrapWithPlanningParametersAndCall, action, addLabourCategoryLine);
}

function* deleteLabourAvailabilityCategoryLine(action) {
  return yield call(wrapWithPlanningParametersAndCall, action, deleteLabourCategoryLine);
}

function* addShiftSettingsLine(action) {
  return yield call(wrapWithPlanningParametersAndCall, action, addShiftSettings);
}

function* deleteShiftSettingsLine(action) {
  return yield call(wrapWithPlanningParametersAndCall, action, deleteShiftSettings);
}

function* doOnCloseUploadModal(action) {
  if (action && action.payload.doReload && action.payload.entity === 'plans') {
    yield call(reload);
  }
}

function* planDetailMasterPlanDetailsSaga(action) {
  const url = `/masterPlan/planId/${action.payload}`;
  const response = yield call(api, withUrl(url));
  if (response.isOk) {
    yield put(saveplanDetailMasterPlanDetails(response.data))
  }
}

function* planDetailSchedulessSaga(action){
  const url = `/plans/schedules/${action.payload}`;
  const response = yield call(api, withUrl(url));
  if (response.isOk) {
    yield put(saveplanDetailScheduleDetails(response.data))
  }
}

function* planDetailSmartProdVolumeRunsSaga(action) {
   const url = `/smartProdSource/planningParametersId/${action.payload}`;
  const response = yield call(api, withUrl(url));
  if (response.isOk) {
    yield put(saveplanDetailSmartProdVolumeRuns(response.data))
  }
}

function* getPlanMappings(planId) {
  const url = `/smartCode/planMappings/${planId}`;
  const response = yield call(api, withUrl(url));
  if (response.isOk) {
    yield put(savePlanSmartCodeMappings(response.data));
  }
}


function* omsChangeHistory(action){
  const url = `/omsChangeHistory/byPlanParameterId/${action.payload}`;
  const response = yield call(api, withUrl(url));
  if (response.isOk) {
    yield put(saveomsChangeHistory(response.data));
  }
}