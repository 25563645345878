// @flow

import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import AutocompleteSelect from 'components/AutocompleteSelect';
import ButtonWithDirtyCheck from 'components/ButtonWithDirtyCheck';
import Select from 'components/StyledSelect';
import { AddLineButton } from 'components/IconButton';
import { nameWithRCLabel } from 'utils/utils';

import messages from './messages';
import './style.css';

type PropsType = {
  departments: Array<Object>,
  token: string,
  onAdd: Function,
  planningParametersId: number,
};

function MheAddLine(props: PropsType) {
  const { departments, token, onAdd, planningParametersId } = props;
  const [department, setDepartment] = useState(null);
  const [mhe, setMhe] = useState(null);
  return (
    <div className="mheAddLine">
      <div className="mheAddLine__row">
        <div className="mheAddLine__label">
          <FormattedMessage {...messages.departmentId} />
        </div>
        <Select
          className="mheAddLine__select"
          options={(departments && departments.map(d => ({ value: d.id, label: d.name }))) || []}
          onChange={setDepartment}
          value={department}
        />
      </div>
      <div className="mheAddLine__row">
        <div className="mheAddLine__label">
          <FormattedMessage {...messages.mhe} />
        </div>
        <AutocompleteSelect
          className="mheAddLine__select"
          entity="mhes"
          maxMenuHeight={120}
          setValue={setMhe}
          value={mhe}
          token={token}
          getOptionLabel={nameWithRCLabel}
          urlAppendixCallback={() => `&planningParametersId=${planningParametersId}`}
        />
        <div className="mheAddLine__button">
          <AddLineButton
            disabled={!mhe}
            onClick={() => {
              onAdd({ mhe, departmentId: (department && department.value) || null });
              setMhe(null);
              setDepartment(null);
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default MheAddLine;
