import { fromJS } from 'immutable';
import { DEFAULT_GUI_STATE, handleCommonEventsForShiftSchedule } from 'utils/commonDetailReducer';
import {
  STORE_AVAILABLE_LABOUR_CATEGORIES,
  STORE_AVAILABLE_PLANS,
  STORE_AVAILABLE_UNPRODUCTIVE_ACTIVITIES,
  STORE_DEFAULT_SCHEDULE_PARAMETERS,
  STORE_SCHEDULE_STAFF,
  STORE_SCHEDULE_STAFF_SETTINGS,
  STORE_SCHEDULE_STAFF_SYNC_STATE,
  STORE_SCHEDULE_STAFF_UNPRODUCTIVE_ACTIVITIES,
} from './constants';

export const initialState = fromJS({
  availablePlans: [],
  availableUnproductiveActivities: [],
  defaultScheduleParameters: [],
  guiState: DEFAULT_GUI_STATE,
  shiftSchedule: {},
});

function shiftScheduleDetailPageReducer(state = initialState, action) {
  const commonResult = handleCommonEventsForShiftSchedule(state, action);
  if (commonResult) {
    return commonResult;
  }

  switch (action.type) {
    case STORE_AVAILABLE_PLANS:
      return state.set('availablePlans', action.payload);
    case STORE_AVAILABLE_LABOUR_CATEGORIES:
      return state.set('availableLabourCategories', action.payload);
    case STORE_SCHEDULE_STAFF_SETTINGS:
      return state.set('scheduleStaffSettings', action.payload);
    case STORE_SCHEDULE_STAFF_UNPRODUCTIVE_ACTIVITIES:
      return state.set('scheduleStaffUnproductiveActivities', action.payload);
    case STORE_AVAILABLE_UNPRODUCTIVE_ACTIVITIES:
      return state.set('availableUnproductiveActivities', action.payload);
    case STORE_DEFAULT_SCHEDULE_PARAMETERS:
      return state.set('defaultScheduleParameters', action.payload);
    case STORE_SCHEDULE_STAFF_SYNC_STATE:
      return state.set('scheduleStaffSyncState', action.payload);
    case STORE_SCHEDULE_STAFF:
      return state.set('scheduleStaff', action.payload);
    default:
      return state;
  }
}

export default shiftScheduleDetailPageReducer;
