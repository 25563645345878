import * as Yup from 'yup';

type RangeValidationParams = {
  months?: number;
  startDateField?: string;
  endDateField?: string;
  nameOfValidation: string;
};
export const addRangeValidation = ({
  months = 3,
  startDateField = 'startDate',
  endDateField = 'endDate',
  nameOfValidation,
}: RangeValidationParams): void => {
  Yup.addMethod(Yup.object, nameOfValidation, function (errorMessage) {
    return this.test(nameOfValidation, errorMessage, function (value) {
      const { createError } = this;
      const [startDate, endDate] = [value[startDateField], value[endDateField]];
      const invalidRange = startDate && endDate && startDate.plus({ months }).startOf('day') < endDate.startOf('day');
      return invalidRange ? createError({ path: nameOfValidation, message: errorMessage }) : true;
    });
  });
};
