import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl';
import messages from './messages';
import './style.css';

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#f9f1cc',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 500,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
    zIndex: 99999,
  },
}))(Tooltip);

const QuestionMark = styled.div`
  position: absolute;
  right: 4px;
  top: -4px;
`;

const ShiftImage = styled.div`
  background-image: url(${props => props.theme.image.ShiftProportional});
  background-size: contain;
  height: 120px;
`;

const List = styled.ul`
  padding-inline-start: 10px;
`;

const title = <React.Fragment>
  <FormattedMessage {...messages.shiftProportionalStart} /><br /><br />
  <FormattedMessage {...messages.example} /><br />
  <List>
    <li> <FormattedMessage {...messages.shiftProportionalList1} /></li>
    <li> <FormattedMessage {...messages.shiftProportionalList2} /></li>
    <li> <FormattedMessage {...messages.shiftProportionalList3} /></li>
  </List>
  <ShiftImage />
</React.Fragment>

export default function ShiftDialogProportionally() {
  return (
    <HtmlTooltip title={title}>
      <QuestionMark>
        <FontAwesomeIcon icon="question-circle" size="sm" />
      </QuestionMark>
    </HtmlTooltip>
  );
}
