import { ComponentWithDirtyCheck } from 'components/ButtonWithDirtyCheck';
import { DetailToolbarButtonsGroup } from 'components/DetailPageShared';
import { withDeleteDialog } from 'components/Dialog';
import IconButton, { SaveButton } from 'components/IconButton';
import { ButtonWrap } from 'components/ResultToolBar';
import { PATHS } from 'containers/App/constants';
import { ShiftScheduleRun } from 'containers/ShiftScheduleRunsPage/types';
import { FormikProps } from 'formik';
import moment from 'moment';
import * as React from 'react';
import { InjectedIntl, injectIntl } from 'react-intl';
import { matchPath } from 'react-router';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { withScrollWatch } from 'utils/scroll';

import { Title } from '../ShiftScheduleDetailPage/toolbar';
import messages from './messages';
import { ShiftScheduleRunDetailForm } from './types';
import { withConfirmDialog } from './withConfirmDialog';
import './style.css';

type ShiftScheduleRunDetailPageToolbarProps = {
  history: RouteComponentProps.history;
  match: { params: { scheduleId: number; id: number } };
  location: any;
  intl: InjectedIntl;
  fixed: boolean;
  activitiesNotInKronosExist: boolean;
  formik: FormikProps<ShiftScheduleRunDetailForm>;
  shiftScheduleRuns: ShiftScheduleRun[];
  sendScheduleRun: Function;
  cancelScheduleRun: Function;
  openConfirmDialog: Function;
};

const ShiftScheduleRunDetailPageToolbar: React.FC<ShiftScheduleRunDetailPageToolbarProps> = ({
  intl,
  formik,
  shiftScheduleRuns,
  activitiesNotInKronosExist,
  sendScheduleRun,
  cancelScheduleRun,
  openConfirmDialog,
  history,
  location,
  fixed,
  match,
}) => {
  const isCopyPath = !!matchPath(location.pathname, { path: PATHS.shiftScheduleRunDetailCopy, exact: true });
  const scheduleId = Number(match.params.scheduleId);
  const scheduleRunId = Number(match.params.id);
  const shiftScheduleRun = shiftScheduleRuns.find(s => s.id === scheduleRunId);
  const currentIndex = shiftScheduleRuns.findIndex(run => run.id === scheduleRunId) || 0;
  const nextIndex = currentIndex >= shiftScheduleRuns.length - 1 ? 0 : currentIndex + 1;
  const prevIndex = currentIndex === 0 ? shiftScheduleRuns.length - 1 : currentIndex - 1;
  const params = { scheduleId, runId: scheduleRunId };
  const isSendable = () =>
    !formik.dirty &&
    shiftScheduleRun?.status?.code === 'CALCULATED' &&
    !(shiftScheduleRun?.startDate < moment().format('YYYY-MM-DD'));

  const isCancelable = () =>
    shiftScheduleRun?.status?.code !== 'FAILED' && shiftScheduleRun?.status?.code !== 'SUCCESSFUL' && shiftScheduleRun?.status?.code !== 'SUCCESSFUL_WITH_WARNINGS';

  const handleSave = () => {
    formik.submitForm();
  };

  const handleCopy = () => {
    history.push(
      PATHS.shiftScheduleRunDetailCopy.replace(':id', scheduleRunId).replace(':scheduleId', scheduleId.toString()),
    );
  };

  const handleCancelRun = () => {
    cancelScheduleRun(params);
  };

  const goToPrevious = () => {
    history.push(
      PATHS.shiftScheduleRunDetailId
        .replace(':id', shiftScheduleRuns[prevIndex].id)
        .replace(':scheduleId', scheduleId.toString()),
    );
  };

  const goToNext = () => {
    history.push(
      PATHS.shiftScheduleRunDetailId
        .replace(':id', shiftScheduleRuns[nextIndex].id)
        .replace(':scheduleId', scheduleId.toString()),
    );
  };

  const goBack = () => {
    if (isCopyPath) {
      history.goBack();
    } else {
      history.push(PATHS.shiftScheduleRuns.replace(':id', scheduleId));
    }
  };

  const sendToEngine = () => {
    if (activitiesNotInKronosExist) {
      openConfirmDialog(() => sendScheduleRun(params), intl.formatMessage(messages.pinkActivities));
    } else {
      sendScheduleRun(params);
    }
  };

  return (
    <ButtonWrap fixed={fixed}>
      <IconButton id="back" onClick={goBack} icon="arrow-circle-left" tooltip={messages.back} />
      {fixed && <Title>{shiftScheduleRun?.name}</Title>}
      <DetailToolbarButtonsGroup>
        <SaveButton onClick={handleSave} disabled={!formik.dirty && !isCopyPath} />
        {!isCopyPath && (
          <>
            <ComponentWithDirtyCheck actionHandler={handleCancelRun}>
              {({ onClickHandler }) => (
                <IconButton onClick={onClickHandler} label={messages.cancel} disabled={!isCancelable() || isCopyPath} />
              )}
            </ComponentWithDirtyCheck>
            <ComponentWithDirtyCheck actionHandler={handleCopy}>
              {({ onClickHandler }) => (
                <IconButton
                  id="copy"
                  label={messages.copy}
                  tooltip={{ ...messages.copy }}
                  onClick={onClickHandler}
                  disabled={isCopyPath}
                />
              )}
            </ComponentWithDirtyCheck>
            <ComponentWithDirtyCheck actionHandler={sendToEngine}>
              {({ onClickHandler }) => (
                <IconButton
                  id="send-to-engine"
                  label={messages.sendToEngine}
                  tooltip={{ ...messages.sendToEngine }}
                  disabled={!isSendable() || isCopyPath}
                  onClick={onClickHandler}
                />
              )}
            </ComponentWithDirtyCheck>
            <ComponentWithDirtyCheck actionHandler={goToPrevious}>
              {({ onClickHandler }) => (
                <IconButton
                  id="go-to-previous"
                  label={messages.previous}
                  tooltip={{ ...messages.previous }}
                  onClick={onClickHandler}
                  disabled={isCopyPath}
                />
              )}
            </ComponentWithDirtyCheck>
            <ComponentWithDirtyCheck actionHandler={goToNext}>
              {({ onClickHandler }) => (
                <IconButton
                  id="go-to-next"
                  label={messages.next}
                  tooltip={{ ...messages.next }}
                  onClick={onClickHandler}
                  disabled={isCopyPath}
                />
              )}
            </ComponentWithDirtyCheck>
          </>
        )}
      </DetailToolbarButtonsGroup>
    </ButtonWrap>
  );
};

export default withScrollWatch(100)(
  compose(
    injectIntl,
    withRouter,
    withConfirmDialog,
    withDeleteDialog,
    // @ts-ignore
  )(ShiftScheduleRunDetailPageToolbar),
);
