/*
 * DeleteCellRenderer Messages
 *
 * This contains all the text for the DeleteCellRenderer component.
 */

import { defineMessages } from 'react-intl';

const scope = 'app.components.TimePicker';

export default defineMessages({
  okLabel: {
    id: `${scope}.okLabel`,
    defaultMessage: 'OK',
  },
  clearLabel: {
    id: `${scope}.clearLabel`,
    defaultMessage: 'CLEAR',
  },
  cancelLabel: {
    id: `${scope}.cancelLabel`,
    defaultMessage: 'CANCEL',
  },
  maxDateError: {
    id: `${scope}.maxDateError`,
    defaultMessage: 'Time should not be after maximum time',
  },
  minDateError: {
    id: `${scope}.minDateError`,
    defaultMessage: 'Time should not be before minimum time',
  },
  invalidDateMessage: {
    id: `${scope}.invalidDateMessage`,
    defaultMessage: 'Invalid Time Format',
  },
});
