/*
 * ActionColumn Messages
 *
 * This contains all the text for the ActionColumn component.
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  details: {
    id: 'app.components.ActionColumn.details',
    defaultMessage: 'Details',
  },
  result: {
    id: 'app.components.ActionColumn.result',
    defaultMessage: 'Result',
  },
  copy: {
    id: 'app.components.ActionColumn.copy',
    defaultMessage: 'Copy',
  },
  paDisabled: {
    id: 'app.components.ActionColumn.paDisabled',
    defaultMessage: 'Planning Area is disabled.',
  },
  forecast: {
    id: 'app.components.ActionColumn.forecast',
    defaultMessage: 'Forecast',
  },
  graph: {
    id: 'app.components.ActionColumn.graph',
    defaultMessage: 'Graph',
  }
});
