import * as React from 'react';
import { injectIntl } from 'react-intl';
import { compose } from 'redux';

import Table from 'components/Table/index';
import request from 'utils/request';

import messages from '../messages';
import AbstractTransferCostsTable, { AbstractTransferCostsTableProps } from './abstractTransferCostsTable';
import { ActivityTransferCostsDatasource } from './activityTransferCostsDatasource';
import { getInDepartment, getInFacility } from './utils';
import { ACTIVE_ENV } from '../../../utils/activeEnv';

class ActivityTransferCostsTable extends AbstractTransferCostsTable {
  private gridApi;

  private readonly datasource;

  constructor(props, context) {
    super(props, context);
    this.datasource = new ActivityTransferCostsDatasource(props.formik.values.id, props.dispatch);
  }

  public componentDidUpdate(
    prevProps: Readonly<AbstractTransferCostsTableProps>,
    prevState: Readonly<any>,
    snapshot?: any,
  ): void {
    // Force refresh the cache when plan is added/removed to enforce data reload
    if (prevProps.formik.values.plans.length !== this.props.formik.values.plans.length) {
      this.gridApi?.purgeServerSideCache();
    }

    // Same if hide blank has changed
    if (prevState.hideBlank !== this.state.hideBlank) {
      this.gridApi?.purgeServerSideCache();
    }

    // Purge after schedule update to fetch new data
    if (prevProps.formik.values.version !== this.props.formik.values.version) {
      this.gridApi?.purgeServerSideCache();
    }
  }

  public render() {
    super.beforeRender();
    this.updateTableData();

    const onGridReady = params => {
      this.gridApi = params.api;
    };

    return [
      this.hideBlankButton(true),
      <Table
        key="table"
        onModelUpdated={params => params.columnApi.autoSizeAllColumns()}
        singleClickEdit
        messages={messages}
        pagination
        paginationPageSize={20}
        cacheBlockSize={50}
        rowData={[]}
        columnDefs={this.getColumnDefs()}
        domLayout="autoHeight"
        onGridReady={onGridReady}
        rowModelType="serverSide"
        serverSideDatasource={this.datasource}
        showNoMatchOverlay
      />,
    ];
  }

  protected getFormikPath(): string {
    return 'activityTransferCostsMap';
  }

  /**
   * Since this table uses server side model, it is needed to manually sync client-side data changes
   */
  private updateTableData() {
    const { formik, idToFacilityCost, idToDepartmentCost } = this.props;

    this.datasource.activityTransferCostsMap = formik.values.activityTransferCostsMap;
    this.datasource.idToDepartmentCost = idToDepartmentCost;
    this.datasource.idToFacilityCost = idToFacilityCost;
    this.datasource.hideBlank = this.state.hideBlank;

    if (this.gridApi) {
      // Refresh all rows to sync changes in the total columns (from facility and department tables)
      if (!formik.values.lastUpdatedRow || !formik.values.lastUpdatedRow.data.smartShiftJobScheduleFrom) {
        this.datasource.updateParentCostsReferences();
        this.gridApi.refreshCells({
          columns: ['valueFrom', 'valueTo', 'totalFrom', 'totalTo'],
          enableCellChangeFlash: false,
          force: true,
        });
      } else {
        this.gridApi.refreshCells({
          columns: ['valueFrom', 'valueTo', 'totalFrom', 'totalTo'],
          enableCellChangeFlash: false,
          force: true,
          rowNodes: [formik.values.lastUpdatedRow],
        });
      }
    }
  }

  private getColumnDefs() {
    const { formatMessage } = this.props.intl;
    return [
      {
        children: [
          this.facilityFromColumn({
            filterParams: {
              applyButton: true,
              clearButton: true,
              valuesCallback: this.fetchValuesForFilter('facilityFrom'),
            },
          }),
          this.departmentFromColumn({
            filterParams: {
              applyButton: true,
              clearButton: true,
              valuesCallback: this.fetchValuesForFilter('departmentFrom'),
            },
          }),
          {
            cellClass: 'fromCellColor',
            colId: 'smartShiftJobScheduleFrom',
            field: 'smartShiftJobScheduleFrom',
            headerName: formatMessage(messages.smartShiftJob),
            valueGetter: this.activityGetter('From'),
            filterParams: {
              applyButton: true,
              clearButton: true,
              valuesCallback: this.fetchValuesForFilter('smartShiftJobScheduleFrom'),
            },
          },
          {
            cellClass: 'fromCellColor',
            colId: 'customerFrom',
            field: 'customerFrom',
            headerName: formatMessage(messages.activityCustomer),
            valueGetter: this.customerGetter('From'),
            filterParams: {
              applyButton: true,
              clearButton: true,
              valuesCallback: this.fetchValuesForFilter('customerFrom'),
            },
          },
        ],
        colId: 'from',
        headerName: formatMessage(messages.smartShiftFrom),
      },
      {
        children: [
          this.facilityToColumn({
            filterParams: {
              applyButton: true,
              clearButton: true,
              valuesCallback: this.fetchValuesForFilter('facilityTo'),
            },
          }),
          this.departmentToColumn({
            filterParams: {
              applyButton: true,
              clearButton: true,
              valuesCallback: this.fetchValuesForFilter('departmentTo'),
            },
          }),
          {
            cellClass: 'toCellColor',
            colId: 'smartShiftJobScheduleTo',
            field: 'smartShiftJobScheduleTo',
            headerName: formatMessage(messages.smartShiftJob),
            valueGetter: this.activityGetter('To'),
            filterParams: {
              applyButton: true,
              clearButton: true,
              valuesCallback: this.fetchValuesForFilter('smartShiftJobScheduleTo'),
            },
          },
          {
            cellClass: 'toCellColor',
            colId: 'customerTo',
            field: 'customerTo',
            headerName: formatMessage(messages.activityCustomer),
            valueGetter: this.customerGetter('To'),
            filterParams: {
              applyButton: true,
              clearButton: true,
              valuesCallback: this.fetchValuesForFilter('smartShiftJobScheduleTo'),
            },
          },
        ],
        colId: 'to',
        headerName: formatMessage(messages.smartShiftTo),
      },
      {
        children: [
          {
            ...this.valueFromColumn(),
            editable: params =>
              this.props.isEdit &&
              !getInFacility('disabled', params.data, 'From') &&
              !getInDepartment('disabled', params.data, 'From'),
            filterParams: {
              applyButton: true,
              clearButton: true,
              valuesCallback: this.fetchValuesForFilter('valueFrom'),
            },
          },
          {
            ...this.valueToColumn(),
            editable: params =>
              this.props.isEdit &&
              !getInFacility('disabled', params.data, 'To') &&
              !getInDepartment('disabled', params.data, 'To'),
            filterParams: {
              applyButton: true,
              clearButton: true,
              valuesCallback: this.fetchValuesForFilter('valueTo'),
            },
          },
        ],
        colId: 'values',
        headerName: formatMessage(messages.values),
      },
      {
        children: this.totalsColumns({}),
        colId: 'totals',
        headerName: formatMessage(messages.total),
      },
      this.statusColumn({
        filterParams: {
          applyButton: true,
          clearButton: true,
          values: ['Enabled', 'Disabled'],
        },
      }),
      this.commentColumn({
        filterParams: {
          applyButton: true,
          clearButton: true,
          valuesCallback: this.fetchValuesForFilter('comment'),
        },
      }),
    ];
  }

  private fetchValuesForFilter = filterName => (): Promise<any[]> => {
    // Do not filter by itself
    const filterModel = this.gridApi.getFilterModel();
    delete filterModel[filterName];
    const fetchOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.token}`
      },
      body: JSON.stringify({ filterModel }),
    };
    const { id } = this.props.formik.values;
    const fullUrl = `${ACTIVE_ENV.basePathBe}/schedules/${id}/filter?columnName=${filterName}`;
    return request(fullUrl, fetchOptions).then(data => {
      const map = data.map(i => (i === undefined ? null : i));

      // filter out duplicates by using Set
      return [...new Set(map)];
    });
  };
}

export default compose(
  injectIntl,
  // @ts-ignore
)(ActivityTransferCostsTable);
