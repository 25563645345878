// @flow
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Body, Content, Controls, Header, Mask, TableStyled, Wrap } from './styled';

export type ModalProps = {
  buttons: React$Node,
  children: React$Node,
  onClose: Function,
  title: Object,
  isOpen: boolean,
  CusomHeader?: React & Node,
};

type StateProps = {
  shown: boolean,
  visible: boolean,
};

export default class Modal extends React.PureComponent<ModalProps, StateProps> {
  state = { shown: false, visible: false };

  constructor(props: ModalProps) {
    super(props);
    this.state = { shown: props.isOpen, visible: props.isOpen };
  }

  timeouts = [];

  componentDidUpdate(prevProps: ModalProps) {
    if (prevProps.isOpen === false && this.props.isOpen === true) {
      this.timeouts.push(
        setTimeout(() => {
          this.setState(state => ({ ...state, visible: true }));
        }),
      );
      this.timeouts.push(
        setTimeout(() => {
          this.setState(state => ({ ...state, shown: true }));
        }, 60),
      );
    }
    if (prevProps.isOpen === true && this.props.isOpen === false) {
      this.setState(state => ({ ...state, shown: false }));
      this.timeouts.push(
        setTimeout(() => {
          this.setState(state => ({ ...state, visible: false }));
        }, 500),
      );
    }
  }

  componentWillUnmount() {
    this.timeouts.map(t => clearTimeout(t));
  }

  render() {
    const { title, CusomHeader, onClose, buttons, children } = this.props;
    if (!this.state.visible) {
      return null;
    }
    return (
      <Wrap shown={this.state.shown}>
        <Mask onClick={onClose} />
        <Content>
          {CusomHeader && CusomHeader}
          {title && (
            <Header>
              <FormattedMessage {...title} />
            </Header>
          )}
          <Body>{children}</Body>
          <Controls>{buttons}</Controls>
        </Content>
      </Wrap>
    );
  }
}
