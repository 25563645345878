import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators, compose } from 'redux';
import { createStructuredSelector } from 'reselect';

import { loadUserAction, logoutAction } from '../containers/LoginPage/actions';
import { makeSelectUserSimple } from '../containers/LoginPage/selectors';

/**
 *
 * Defined by UI
 */
export const PERMISSIONS = {
  VIEW_PLAN_DETAILS: 'VIEW_PLAN_DETAILS',
  VIEW_PLAN_IGNORE_RESTRICTIONS: 'VIEW_PLAN_IGNORE_RESTRICTIONS',

  EDIT_PLAN_DETAILS: 'EDIT_PLAN_DETAILS',
  EDIT_PLAN_IGNORE_RESTRICTIONS: 'EDIT_PLAN_IGNORE_RESTRICTIONS',

  VIEW_AREA_DETAILS: 'VIEW_AREA_DETAIL',
  VIEW_AREA_IGNORE_RESTRICTIONS: 'VIEW_AREA_IGNORE_RESTRICTIONS',

  VIEW_RESULT: 'VIEW_RESULT',
  VIEW_RESULT_IGNORE_RESTRICTIONS: 'VIEW_RESULT_IGNORE_RESTRICTIONS',

  EDIT_AREA_DETAILS: 'EDIT_AREA_DETAIL',
  EDIT_AREA_IGNORE_RESTRICTIONS: 'EDIT_AREA_IGNORE_RESTRICTIONS',

  EDIT_RESULT: 'EDIT_RESULT',
  EDIT_RESULT_IGNORE_RESTRICTIONS: 'EDIT_RESULT_IGNORE_RESTRICTIONS',

  DISABLE_PLAN: 'DISABLE_PLAN',
  DISABLE_PLAN_IGNORE_RESTRICTIONS: 'DISABLE_PLAN_IGNORE_RESTRICTIONS',

  DISABLE_AREA: 'DISABLE_AREA',
  DISABLE_AREA_IGNORE_RESTRICTIONS: 'DISABLE_AREA_IGNORE_RESTRICTIONS',

  ADD_AREA: 'DISABLE_AREA',
  ADD_PLAN: 'DISABLE_AREA',

  COPY_AREA: 'COPY_AREA',
  COPY_PLAN: 'COPY_PLAN',

  HISTORY_PLAN: 'HISTORY_PLAN',
  HISTORY_AREA: 'HISTORY_AREA',

  SIDEBAR: 'SIDEBAR',

  SAVE_TABLE_CONFIG: 'SAVE_TABLE_CONFIG',

  SEND_TO_KRONOS: 'SEND_TO_KRONOS',

  ADD_SCHEDULE: 'ADD_SCHEDULE',
  DISABLE_SCHEDULE: 'DISABLE_SCHEDULE',
  VIEW_SCHEDULES: 'VIEW_SCHEDULES',
  EDIT_SCHEDULE_INTERFACES: 'EDIT_SCHEDULE_INTERFACES',
  DOWNLOAD_SCHEDULE_REQUEST_FILE: 'DOWNLOAD_SCHEDULE_REQUEST_FILE',

  VIEW_MASTER_PLAN: 'VIEW_MASTER_PLAN',
  VIEW_MASTER_PLAN_ADMIN: 'VIEW_MASTER_PLAN_ADMIN',
  VIEW_FORECAST_SECTION: 'VIEW_FORECAST_SECTION',

  VIEW_SMART_PROD_SOURCE: 'VIEW_SMART_PROD_SOURCE',
};

/**
 *
 * Defined by User
 */
export const ROLES = {
  // All permissions
  ADMIN: { name: 'drep_admin', permissions: Object.keys(PERMISSIONS).map(key => PERMISSIONS[key]) },
  // Some permissions
  PLANNER: {
    name: 'drep_planner',
    permissions: [
      PERMISSIONS.VIEW_PLAN_DETAILS,
      PERMISSIONS.VIEW_RESULT,
      PERMISSIONS.EDIT_PLAN_DETAILS,
      PERMISSIONS.VIEW_AREA_DETAILS,
      PERMISSIONS.EDIT_AREA_DETAILS,
      PERMISSIONS.EDIT_RESULT,
      PERMISSIONS.DISABLE_PLAN,
      PERMISSIONS.DISABLE_AREA,
      PERMISSIONS.COPY_AREA,
      PERMISSIONS.COPY_PLAN,
      PERMISSIONS.ADD_PLAN,
      PERMISSIONS.ADD_AREA,
      PERMISSIONS.SAVE_TABLE_CONFIG,
      PERMISSIONS.SEND_TO_KRONOS,
      PERMISSIONS.SIDEBAR,
    ],
  },
  FORECAST_EDITOR: {
    name: 'smartplan_forecast_editor',
    permissions: [PERMISSIONS.VIEW_FORECAST_SECTION],
  },
  // None permissions
  VIEWER: {
    name: 'drep_planViewer',
    permissions: [PERMISSIONS.SAVE_TABLE_CONFIG, PERMISSIONS.VIEW_RESULT, PERMISSIONS.SIDEBAR],
  },
  AUDIT: { name: 'drep_audit', permissions: [PERMISSIONS.HISTORY_PLAN, PERMISSIONS.HISTORY_AREA] },
  SHIFT_FILLER: {
    name: 'drep_shift_filler',
    permissions: [
      PERMISSIONS.VIEW_SCHEDULES,
      PERMISSIONS.ADD_SCHEDULE,
      PERMISSIONS.DISABLE_SCHEDULE,
      PERMISSIONS.SIDEBAR,
    ],
  },
  SHIFT_INTERFACE_ADMIN: {
    name: 'drep_shift_interface_admin',
    permissions: [PERMISSIONS.EDIT_SCHEDULE_INTERFACES],
  },
  SHIFT_JSON_VIEWER: {
    name: 'drep_shift_json_viewer',
    permissions: [PERMISSIONS.DOWNLOAD_SCHEDULE_REQUEST_FILE],
  },
  SMARTPLAN_MASTER_PLAN_ADMIN: {
    name: 'smartplan_master_plan',
    permissions: [PERMISSIONS.VIEW_MASTER_PLAN],
  },
  SMARTPLAN_MASTER_PLAN_ADMIN_ROLE: {
    name: 'smartplan_master_plan_admin',
    permissions: [PERMISSIONS.VIEW_MASTER_PLAN_ADMIN],
  },
};

function addSpecialPermissions(permissions, roles) {
  // some permissions may come from combination of roles, so let's handle it
  if (!permissions.includes(PERMISSIONS.VIEW_SMART_PROD_SOURCE)) {
    const roleLabels = roles.map(r => r.role);
    if (roleLabels.includes(ROLES.PLANNER.name) && roleLabels.includes(ROLES.FORECAST_EDITOR.name)) {
      permissions.push(PERMISSIONS.VIEW_SMART_PROD_SOURCE);
    }
  }
}

export const extractPermissions = user => {
  let { permissions } = user;
  const { roles } = user.user;
  if (permissions === false) {
    permissions = [];
    const rolesMap = Object.values(ROLES);

    const getRole = item => rolesMap.find(role => role.name === item.role) || { permissions: [] };

    roles.forEach(item => {
      permissions = permissions.concat(getRole(item).permissions);
    });
    addSpecialPermissions(permissions, roles);
  }
  return permissions;
};

const hasPerm = (perm, props, mode = 'all' || 'oneOf') => {
  if (perm === undefined || perm === null) {
    return true;
  }
  const { user } = props;
  if (!user.user) {
    return false;
  }
  const permissions = extractPermissions(user);
  if (Array.isArray(perm)) {
    if (mode === 'all') {
      const result = perm.find(permCurrent => permissions.find(item => permCurrent === item) === undefined);
      return result === undefined;
    }
    if (mode === 'oneOf') {
      const result = perm.find(permCurrent => permissions.find(item => permCurrent === item) !== undefined);
      return result !== undefined;
    }
  }
  return permissions.find(item => perm === item) !== undefined;
};

export const hasOnePerm = (perm, user) => {
  if (!user.user) {
    return false;
  }
  const permissions = extractPermissions(user);
  return permissions.find(item => perm === item) !== undefined;
};

const mapStateToProps = () =>
  createStructuredSelector({
    user: makeSelectUserSimple(),
  });

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ loadUserAction, logoutAction }, dispatch);
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

function mergePerms(props, permToDisplay) {
  let perms = permToDisplay;
  if (typeof permToDisplay === 'function') {
    perms = permToDisplay(props);
  }
  return perms;
}

const withSecurity = permToDispley => WrapperComponent => {
  class Security extends React.Component {
    state = {};

    static getDerivedStateFromProps(props) {
      const perms = mergePerms(props, permToDispley);
      if (perms !== undefined && !hasPerm(perms, props)) {
        if (props.user.loaded) {
          // User is loaded and doesn't have permission so go to login no exceptions.
          props.logoutAction();
        }
      }
      return null;
    }

    componentDidMount() {
      if (!this.props.user.loaded) {
        this.props.loadUserAction();
      }
    }

    hasPerm = perm => hasPerm(perm, this.props);

    hasOneOfPerm = perm => hasPerm(perm, this.props, 'oneOf');

    render() {
      const perms = mergePerms(this.props, permToDispley);
      if (perms !== undefined && !this.hasPerm(perms)) {
        // We wait until user is loaded. He might be to see content but not just now.
        return null;
      }
      if (!this.props.user.loaded) {
        // Since this component is decorated with security we suppose there will be a usage of it. So without initialized user component will no be rendered right.
        return null;
      }
      return <WrapperComponent {...this.props} hasPerm={this.hasPerm} hasOneOfPerm={this.hasOneOfPerm} />;
    }
  }

  Security.propTypes = {
    user: PropTypes.object,
    history: PropTypes.object,
    logoutAction: PropTypes.func,
    loadUserAction: PropTypes.func,
  };

  return compose(withConnect, withRouter)(Security);
};

export default withSecurity;