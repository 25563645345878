/*
 * ResultMatrixColumnOptions Messages
 *
 * This contains all the text for the ResultMatrixColumnOptions component.
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  volume: {
    id: 'app.components.ResultMatrixColumnOptions.volume',
    defaultMessage: 'Volume',
  },
  effort: {
    id: 'app.components.ResultMatrixColumnOptions.effort',
    defaultMessage: 'Effort',
  },
  effortHours: {
    id: 'app.components.ResultMatrixColumnOptions.effortHours',
    defaultMessage: 'Effort (Hours)',
  },
  effortAvailable: {
    id: 'app.components.ResultMatrixColumnOptions.effortAvailable',
    defaultMessage: 'Available Hours',
  },
  ftes: {
    id: 'app.components.ResultMatrixColumnOptions.ftes',
    defaultMessage: "FTE 's",
  },
  ftesAvailable: {
    id: 'app.components.ResultMatrixColumnOptions.ftesAvailable',
    defaultMessage: "Available FTE",
  },
  productivityRate: {
    id: 'app.components.ResultMatrixColumnOptions.productivityRate',
    defaultMessage: 'Productivity rate',
  },
  productivityRateNew: {
    id: 'app.components.ResultMatrixColumnOptions.productivityRateNew',
    defaultMessage: 'PR',
  },
  uph: {
    id: 'app.components.ResultMatrixColumnOptions.uph',
    defaultMessage: 'UPH',
  },
  direct: {
    id: 'app.components.ResultMatrixColumnOptions.direct',
    defaultMessage: 'Direct',
  },
  indirect: {
    id: 'app.components.ResultMatrixColumnOptions.indirect',
    defaultMessage: 'Indirect',
  },
  budget: {
    id: 'app.components.ResultMatrixColumnOptions.budget',
    defaultMessage: 'Baseline',
  },
  planned: {
    id: 'app.components.ResultMatrixColumnOptions.planned',
    defaultMessage: 'Planned',
  },
  forecast: {
    id: 'app.components.ResultMatrixColumnOptions.forecast',
    defaultMessage: 'Forecast',
  },
  export: {
    id: 'app.components.ResultMatrixColumnOptions.export',
    defaultMessage: 'Export',
  },
  original: {
    id: 'app.components.ResultMatrixColumnOptions.original',
    defaultMessage: 'Planned Base',
  },
  actuals: {
    id: 'app.components.ResultMatrixColumnOptions.actuals',
    defaultMessage: 'Actuals',
  },
  heads: {
    id: 'app.components.ResultMatrixColumnOptions.heads',
    defaultMessage: 'Heads',
  },
  headsOpt: {
    id: 'app.components.ResultMatrixColumnOptions.headsOpt',
    defaultMessage: 'Heads (opt)',
  },
  numberOfStaff: {
    id: 'app.components.ResultMatrixColumnOptions.numberOfStaff',
    defaultMessage: '#Staff',
  },
  staffAvailable: {
    id: 'app.components.ResultMatrixColumnOptions.staffAvailable',
    defaultMessage: 'Available Staff',
  },
  labourDiscrepancy:{
    id: 'app.components.ResultMatrixColumnOptions.labourDiscrepancy',
    defaultMessage: 'Labour Discrepancy in %',
  },
  labDiscrepancy:{
    id: 'app.components.ResultMatrixColumnOptions.labDiscrepancy',
    defaultMessage: 'Lab. Discrepancy',
  },
  variance: {
    id: 'app.components.ResultMatrixColumnOptions.variance',
    defaultMessage: 'Variance',
  },
  effortAdjustment: {
    id: 'app.components.ResultMatrixColumnOptions.effortAdjustment',
    defaultMessage: 'Adjustments',
  }
});
