import { DateTime } from 'luxon';

export enum ModifyMultipleChangeType {
  MHE_SHORTFALL = 'MHE_SHORTFALL',
  START_DATE  = 'START_DATE',
}

export type MHERedistribute = {
  startDate: DateTime;
  endDate: DateTime;
  granularity: string;
  activityData: any;
  activitySameData: any;
  activityDifferentData: any;
  mheData?:any;
};
