import React from "react";
import { WarningText, WarningRedText } from 'components/FormikTextInput';
import { FormattedMessage } from "react-intl";


const Warnings = ({reportingRunError, smartCodeErrors = [], smartCodeErrorStatusFlag, messages, snowflakeStatus, validityWarnings, noManagementUnitsFlag, smartProdSourceList =[], intl, defaultCustomers}) => {
    return(
        <>
        {
            reportingRunError && <WarningText><FormattedMessage {...messages.reportingRunningError}/></WarningText>
        }
        {
            validityWarnings?.length > 0 && <WarningRedText><FormattedMessage {...messages.planValidityWarning}/></WarningRedText>
        }
        {/* {
            noManagementUnitsFlag === true && <WarningText><FormattedMessage {...messages.managementUnitWarning}/></WarningText>
        } */}
        {
            snowflakeStatus === false && smartProdSourceList?.length > 0 ? smartProdSourceList.map(s => {
                return <WarningText>{intl.formatMessage(messages.snowFlakeWarningText, { spi : s.smartProdInstance?.name, spw : s?.smartProdWarehouse?.smartProdWhId })}</WarningText>
            }) :
            snowflakeStatus === false && <WarningText><FormattedMessage {...messages.snowFlakeWarning}/></WarningText>
        }
        {
            smartCodeErrorStatusFlag && <WarningText><FormattedMessage {...messages.smartCodeStatusError}/></WarningText>
        }
        {
            smartCodeErrors?.map((sc)=>{
                return <WarningText>{sc}</WarningText>
            })
        }
        {
            defaultCustomers && defaultCustomers.length > 0 &&
                <WarningText>{`There is no historical data for this job code(s) ${defaultCustomers} for last 400 days. If this job code(s) is no longer used It's recommended to disable this job code(s) in SmartProductivity.`}</WarningText>
        }
        {
            (!reportingRunError &&  smartCodeErrors.length === 0 && !smartCodeErrorStatusFlag && snowflakeStatus === true ) && (validityWarnings?.length === 0) && (defaultCustomers?.length == 0) &&<WarningText><FormattedMessage {...messages.noWarningsAvailable}/></WarningText>
        }
        </>
    )
}

export default Warnings;