import styled from 'styled-components';

export default styled.div`
  margin: 10px 10px 5px;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  background-color: ${props => props.theme.color.greyDlgBg};
  box-shadow: ${props => props.theme.shadow.pageBox};
  > * {
    display: flex;
    > * {
      margin: 0 10px;
    }
  }
  > :first-child {
    margin-left: 0;
  }
`;
