const __awaiter =
  (this && this.__awaiter) ||
  function(thisArg, _arguments, P, generator) {
    function adopt(value) {
      return value instanceof P
        ? value
        : new P(function(resolve) {
            resolve(value);
          });
    }

    return new (P || (P = Promise))(function(resolve, reject) {
      function fulfilled(value) {
        try {
          step(generator.next(value));
        } catch (e) {
          reject(e);
        }
      }

      function rejected(value) {
        try {
          step(generator.throw(value));
        } catch (e) {
          reject(e);
        }
      }

      function step(result) {
        result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
      }

      step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
  };
Object.defineProperty(exports, '__esModule', { value: true });
exports.getWorker = exports.cleanPool = void 0;
/**
 * Handles workers and makes easy access via promises
 *
 */
let workerPool = [];

class WorkerWrapper {
  constructor(type, makeWorker) {
    this.start = data => {
      this.working = true;
      return new Promise((resolve, reject) => {
        this.worker.onmessage = event => {
          // @ts-ignore
          const {
            target: { type: messageType },
          } = event;
          if (messageType === this.type) {
            resolve(event.data);
          }
          this.working = false;
        };
        this.worker.onerror = event => {
          // @ts-ignore
          const {
            target: { type: messageType },
          } = event;
          if (messageType === this.type) {
            reject(event);
            this.working = false;
          }
        };
        this.worker.postMessage({ type: this.type, data });
      });
    };
    this.terminate = () => this.worker.terminate();
    this.isWorking = () => this.working;
    this.type = type;
    this.working = true;
    this.worker = makeWorker();
    // @ts-ignore
    this.worker.type = this.type;
  }
}

/**
 * When you are done clean the pool. So next calculation can utilise maximum number of web workers.
 */
exports.cleanPool = () => {
  workerPool.forEach(item => item.terminate());
  workerPool = [];
};
/**
 * Simply creates worker.
 */
exports.getWorker = (type, workerConstructor) =>
  __awaiter(void 0, void 0, void 0, function*() {
    return new Promise(resolve => {
      const tryToGetFreeWorker = () => {
        const freeworker = workerPool.find(item => item.type === type);
        if (freeworker) {
          resolve(freeworker);
        } else {
          const newWorker = new WorkerWrapper(type, workerConstructor);
          workerPool.push(newWorker);
          resolve(newWorker);
        }
      };
      tryToGetFreeWorker();
    });
  });
// # sourceMappingURL=workerAPI.js.map
