/*
 *
 * PlanDetailPage constants
 *
 */

export const NAVIGATE_MASTER_PLAN_DETAIL = 'app/MasterPlanDetailPage/NAVIGATE_MASTER_PLAN_DETAIL';
export const STORE_MASTER_PLAN = 'app/MasterPlanDetailPage/STORE_MASTER_PLAN_';
export const STORE_MASTER_PLAN_GUI_STATE_ITEM = 'app/MasterPlanDetailPage/STORE_MASTER_PLAN_GUI_STATE_ITEM';
export const SAVE_BASIC_INFO = 'app/MasterPlanDetailPage/SAVE_BASIC_INFO';
export const SAVE_BASIC_SETTINGS = 'app/MasterPlanDetailPage/SAVE_BASIC_SETTINGS';
export const SAVE_MASTER_PLANNED_VOLUME_HEADER = 'app/MasterPlanDetailPage/SAVE_MASTER_PLANNED_VOLUME_HEADER';
export const ADD_MASTER_PLANNED_VOLUME_LINE = 'app/MasterPlanDetailPage/ADD_MASTER_PLANNED_VOLUME_LINE';
export const ADD_ACTIVITY_LINE = 'app/MasterPlanDetailPage/ADD_ACTIVITY_LINE';
export const MODIFY_ACTIVITY_LINE = 'app/MasterPlanDetailPage/MODIFY_ACTIVITY_LINE';
export const DELETE_ACTIVITY_LINES = 'app/MasterPlanDetailPage/DELETE_ACTIVITY_LINES';
export const COPY_MASTER_PLAN = 'app/MasterPlanDetailPage/COPY_MASTER_PLAN_';
export const ADD_MHE_OPEN = 'app/MasterPlanDetailPage/ADD_MHE_OPEN';
export const START_NEW = 'app/MasterPlanDetailPage/START_NEW';
export const START_MASTER_PLAN_COPY = 'app/MasterPlanDetailPage/START_MASTER_PLAN_COPY';
export const SAVE_MASTER_PLAN = 'app/MasterPlanDetailPage/SAVE_ALL';
export const SAVE_MASTER_PLAN_DONE = 'app/MasterPlanDetailPage/SAVE_MASTER_PLAN_DONE';
export const SAVE_MASTER_PLAN_CONFLICT = 'app/MasterPlanDetailPage/SAVE_MASTER_PLAN_CONFLICT';
export const SUBMIT_ERROR = 'app/MasterPlanDetailPage/SUBMIT_ERROR';
export const CLEAR_MASTER_PLAN_CONFLICT = 'app/MasterPlanDetailPage/CLEAR_MASTER_PLAN_CONFLICT';
export const CLEAR_MASTER_PLAN = 'app/MasterPlanDetailPage/CLEAR_MASTER_PLAN_';
export const CLEAR_MASTER_PLAN_MHE = 'app/MasterPlanDetailPage/CLEAR_MASTER_PLAN_MHE';
export const ADD_WZP = 'app/MasterPlanDetailPage/ADD_WZP';
export const DELETE_WZP = 'app/MasterPlanDetailPage/DELETE_WZP';
export const LOAD_MASTER_PLAN = 'app/MasterPlanDetailPage/LOAD_MASTER_PLAN_';
export const SWITCH_TO_DISPLAY_MASTER_PLAN = 'app/MasterPlanDetailPage/SWITCH_TO_DISPLAY_MASTER_PLAN_';
export const TOGGLE_SECTION_MASTER_PLAN = 'app/MasterPlanDetailPage/TOGGLE_SECTION_MASTER_PLAN_';
export const STORE_MASTER_PLAN_PERIOD_INDEX = 'app/MasterPlanDetailPage/STORE_MASTER_PLAN_PERIOD_INDEX';
export const ADD_PERIOD_MASTER_PLAN = 'app/MasterPlanDetailPage/ADD_PERIOD_MASTER_PLAN_';
export const DELETE_PERIOD_MASTER_PLAN = 'app/MasterPlanDetailPage/DELETE_PERIOD_MASTER_PLAN_';
export const COPY_PERIOD_MASTER_PLAN = 'app/MasterPlanDetailPage/COPY_PERIOD_MASTER_PLAN_';
export const ADD_LABOUR_AVAILABILITY_CATEGORY_LINE = 'app/MasterPlanDetailPage/ADD_LABOUR_AVAILABILITY_CATEGORY_LINE';
export const DELETE_LABOUR_AVAILABILITY_CATEGORY_LINE =
  'app/MasterPlanDetailPage/DELETE_LABOUR_AVAILABILITY_CATEGORY_LINE';
export const ADD_SHIFT_SETTINGS_LINE = 'app/MasterPlanDetailPage/ADD_SHIFT_SETTINGS_LINE';
export const DELETE_SHIFT_SETTINGS_LINE = 'app/MasterPlanDetailPage/DELETE_SHIFT_SETTINGS_LINE';
