/*
 *
 * Adjustments reducer
 *
 */

import { fromJS } from 'immutable';
import { DAY_NAMES } from 'utils/calendar/constants';
import { TOGGLE_DAY } from './constants';

export const initialState = fromJS([...DAY_NAMES]);

function AdjustmentsReducer(state = initialState, action) {
  switch (action.type) {
    case TOGGLE_DAY:
      if (action.isActive !== undefined) {
        if (action.isActive) {
          if (state.includes(action.payload)) {
            return state;
          }
          return state.push(action.payload);
        }
        return state.filter(day => day !== action.payload);
      }
      return state.includes(action.payload) ? state.filter(day => day !== action.payload) : state.push(action.payload);
    default:
      return state;
  }
}

export default AdjustmentsReducer;
