/*
 * AdminUpload Messages
 *
 * This contains all the text for the AdminUpload component.
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  upload: {
    id: 'app.components.AdminUpload.upload',
    defaultMessage: 'Upload',
  },
  uploadStartDate: {
    id: 'app.components.AdminUpload.uploadStartDate',
    defaultMessage: 'Start date',
  },
  uploadEndDate: {
    id: 'app.components.AdminUpload.uploadEndDate',
    defaultMessage: 'End date',
  },
  selectFile: {
    id: 'app.components.AdminUpload.selectFile',
    defaultMessage: 'Please select file to Upload',
  },
  fileReady: {
    id: 'app.components.AdminUpload.fileReady',
    defaultMessage: 'Ready to upload file {name}',
  },
  dragFile: {
    id: 'app.components.AdminUpload.dragFile',
    defaultMessage: 'Click or drag file here',
  },
  dropFile: {
    id: 'app.components.AdminUpload.dropFile',
    defaultMessage: 'Drop file here',
  },
  rejectedFile: {
    id: 'app.components.AdminUpload.rejectedFile',
    defaultMessage: 'Only Excel xlsx files are supported',
  },
  cancel: {
    id: 'app.components.AdminUpload.cancel',
    defaultMessage: 'Cancel',
  },
});
