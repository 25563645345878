import React from "react";
import {
  Breadcrumbs as MUIBreadcrumbs,
  Link,
  Typography
} from "@material-ui/core";
import { forceCapitalize } from 'utils/utils';
import { constructNavigtionDetails, constructRouteTo, constructLinkName, constructShortLinkName, constructLabelName } from './utils';
import { useHistory, useLocation } from "react-router-dom";

const Breadcrumbs = (props) => {
  const { labelValues } = props;
  const history = useHistory();
  React.useEffect(() => {
    if (!history?.location?.pathname?.includes('/masterPlan') && !history?.location?.search?.includes('masterPlanId')) {
      sessionStorage.removeItem('masterPlanName')
    }
    if (!history?.location?.pathname?.includes('/schedules') && !history?.location?.search?.includes('schedules')) {
      sessionStorage.removeItem('scheduleName')
    }
  }, [history])
  const masterPlanId = history?.location?.state?.masterPlan?.id;
  const masterPlanName = history?.location?.state?.masterPlanInfo?.name;
  const { pathname } = useLocation();
  const pathnames = constructNavigtionDetails(pathname);
  const isCreationPlan = pathnames[pathnames?.length - 1] === 'insert' && pathnames[pathnames?.length - 2] === 'create';

  const creationName = (pathnames[0] === 'masterPlan' && pathnames[1] === 'plan') ? labelValues : pathnames[0] === 'masterPlan' ? 'Master Plan Creation' : pathnames[0] === 'area' ? 'Planning Area Creation' : pathnames[0] === 'plan' ? 'Plan Creation' : pathnames[0] === 'schedules' ? 'Schedule Creation' : pathnames[0] === 'smartProd' ? 'SmartProd Creation' : 'Creation';
  return (
    <MUIBreadcrumbs separator="›" aria-label="breadcrumb">
      {pathnames.length ? (
        <Link style={{cursor:'pointer'}} onClick={() => {
          if(props.createdFromMasterPlan){
            props.makeDirtyHandler("Homepage")
          }else{
            history.push("/")
          }
        }}>Home</Link>
      ) : (
        <Link href="/" style={{cursor:'pointer', color:'#3f51b5'}}> Home</Link>
      )}
      {pathnames?.map((name, index) => {

        let linkName = ((pathnames[1] === 'smartShiftJob' || pathnames[1] === 'staff') && index === 0) ? (props?.scheduleName || sessionStorage.getItem('scheduleName')): (pathnames[2] === 'Run Details' && index === 0) ? props?.scheduleName : ((pathnames[2] === 'statistics' || pathnames[2] === 'graphs') && index === 1) ? 'Plan Result' : name === 'runs' ? 'Runs' : labelValues;
        let lastName = !isNaN(Number(name)) ? constructLabelName(labelValues, history, pathnames, name, index) : (isNaN(Number(name))) ? forceCapitalize(name) : constructLabelName(labelValues, history, pathnames, name, index);
        lastName = lastName === 'Planfromplannigarea' ? 'Plan Creation' : lastName === 'Copyfromplannigarea' ? 'Planning Area Copy' : lastName === 'Planfromplan' ? 'Plan Copy' : lastName === 'Powerbi' ? 'Graph' : lastName === 'Planningareacreation' ? 'Planning Area Creation' : lastName === 'Plancreation' ? 'Plan Creation' : lastName === 'Schedulecreation' ? 'Schedule Creation' : lastName === 'Smartprodsourcecreation' ? 'SmartProd Source creation' : lastName === 'Smartprodsource' ? 'SmartProd Sources' : lastName === 'Masterplan' ? 'Master Plans' : lastName === 'Masterplancreation' ? 'Master Plan Creation' : lastName === 'Area' ? 'Planning Areas' : lastName === 'Plan' ? 'Plans' : lastName === 'Planfrommasterplancreation' ? 'Plan Creation' : lastName === 'Smartshiftjob' ? 'SmartShift Job Detail' : lastName === 'Staff' ? 'Staff Detail' : lastName;
        linkName = constructLinkName(linkName, name, index, history, pathnames);
        if (pathnames[0] === 'plan' && pathnames[1] === 'masterPlan' && pathnames[2] === 'forecast') {
          if (name === 'plan' && index === 0) linkName = `MP - ${history?.location?.state?.masterPlan?.name || sessionStorage.getItem('masterPlanName') || 'Master Plan Detail'}`;
        }
        let lastLabelValue = constructLabelName(labelValues, history, pathnames, name, index);
        const routeTo = constructRouteTo(pathname, pathnames, name, index, masterPlanId);
        const isLast = index === pathnames?.length - 1;
        return isCreationPlan && !isLast ?
          <Link style={{cursor:'pointer'}} title={labelValues ? labelValues : lastName} key={name} onClick={() => history.push(routeTo)}>
            constructShortLinkName({labelValues ? labelValues : lastName})
          </Link> :
          isCreationPlan ?
            <Typography title={(pathnames[0] === 'masterPlan' && pathnames[1] === 'plan' && name === 'plan') ? `${labelValues} Plan Creation` : creationName} key={name}>{constructShortLinkName((pathnames[0] === 'masterPlan' && pathnames[1] === 'plan' && name === 'plan') ? `${labelValues} Plan Creation` : creationName)}</Typography> :
            isLast ? (
              <Typography title={pathnames.length === 1 ? (lastLabelValue ? lastLabelValue : lastName) : lastName} style={{ fontWeight: 'bold' }} key={name}>{pathnames.length === 1 ? (lastLabelValue ? lastLabelValue : lastName) : lastName}</Typography>
            ) : (
              <Link style={{cursor:'pointer'}} title={linkName} key={name} onClick={() => {
                if(props.createdFromMasterPlan){
                  props.makeDirtyHandler(()=> history.push(routeTo))
                }else{
                  history.push(routeTo)
                }
              }}>
                {constructShortLinkName(linkName)}
              </Link>
            );
      })}
    </MUIBreadcrumbs>
  );
};

export default Breadcrumbs;