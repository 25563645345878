/**
 *
 * StaticSection
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const Wrapper = styled.div`
  margin: 10px;
  padding: 5px;
`;

export const Title = styled.div`
  font-size: ${props => props.theme.fontSize.title};
  font-weight: 700;
  margin-bottom: 8px;
  display: flex;
  cursor: pointer;
  > * {
    margin: 0px 5px;
  }
`;

class StaticSection extends React.Component {
  // constructor(props) {
  //   super(props);
  // }

  render() {
    const { subSection, subtitle = '' } = this.props;
    return (
      <Wrapper subSection={subSection} className={this.props.className}>
        <Title className="title">
          <FormattedMessage {...this.props.message} />
        </Title>
        <div>{this.props.children}</div>
      </Wrapper>
    );
  }
}

StaticSection.propTypes = {
  message: PropTypes.object,
  children: PropTypes.node,
  subSection: PropTypes.bool,
  className: PropTypes.string,
};

export default StaticSection;
