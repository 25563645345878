import { checkVariables } from 'utils/formulas';
import { getIn } from 'formik';
import directMessages from 'components/ActivitySettingsDirect/messages';
import {
  DOW_TRANS_ACTIVITY_AKA_WEEKLY,
  DOW_TRANS_VOLUME_CATEGORY_AKA_DAILY,
  VOLUME_GRANULARITY_DAILY,
  VOLUME_GRANULARITY_WEEKLY,
} from '../../utils/constants';

export const checkFormula = (props) => {

  const isDaily = props =>
    getIn(props, 'formikBag.values.planningParameters.dayTransformationType', DOW_TRANS_ACTIVITY_AKA_WEEKLY) ===
    DOW_TRANS_VOLUME_CATEGORY_AKA_DAILY &&
    getIn(props, 'formikBag.values.planningParameters.volumeGranularity', VOLUME_GRANULARITY_WEEKLY) ===
    VOLUME_GRANULARITY_DAILY;

  const errorsWarnings = [];
  function validateFormula(selfVar, formula, variableNames) {
    const {
      intl: { formatMessage },
      formikBag,
    } = props;
    if (!variableNames) return;
    const variablesOk = checkVariables(formula, variableNames, formatMessage, directMessages, selfVar);
    if (variablesOk !== true) {
      const errorMessage = formatMessage(directMessages.warningMessage, { warning: variablesOk });
      return errorMessage;
    }
    return false;
  }

  const activitiesVariableNames = (props, index) => {
    const vars = {};
    const {
      intl: { formatMessage },
    } = props;

    getIn(props.formikBag.values, `planningParameters.periods.${index}.apCalculated.indirect`, []).forEach(
      it => {
        vars[it.variableName] = formatMessage(directMessages.activityLabel, { name: it.activity && it.activity.name });
      },
    );
    getIn(props.formikBag.values, `planningParameters.periods.${index}.apCalculated.direct`, []).forEach(
      it => {
        vars[it.variableName] = formatMessage(directMessages.activityLabel, { name: it.activity && it.activity.name });
      },
    );
    return vars;
  };

  const volumeCategoryVariableNames = props => {
    const {
      intl: { formatMessage },
    } = props;
    const vars = {};
    const dataKey = isDaily(props) ? 'dailyRowData' : 'rowData';
    ['', 'Var'].forEach(postFix => {
      getIn(props.formikBag.values, `planningParameters.volumeCategoryParameters.${dataKey}${postFix}`, []).forEach(
        it => {
          vars[it.volumeCategory.variableName] = formatMessage(directMessages.vcLabel, {
            name: it.volumeCategory.name,
          });
        },
      );
    });
    return vars;
  };

  props?.formikBag?.values?.planningParameters?.periods?.map((ap, index) => {
    const vnDirect = { ...volumeCategoryVariableNames(props), ...activitiesVariableNames(props, index) };
    const vnInDirect = activitiesVariableNames(props, index);
    ap?.apCalculated?.direct?.map((direct) => {
      if (direct.parseError) {
        errorsWarnings.push(direct.parseError)
      }
      const warning = validateFormula(direct.variableName, JSON.parse(direct.formula), vnDirect);
      if (warning && !direct.isEffortForecast) {
        errorsWarnings.push(warning)
      }
    })
    ap?.apCalculated?.indirect?.map((indirect) => {
      if (indirect.parseError) {
        errorsWarnings.push(indirect.parseError)
      }
      const warning = validateFormula(indirect.variableName, JSON.parse(indirect.formula), vnInDirect);
      if (warning && !indirect.isEffortForecast) {
        errorsWarnings.push(warning)
      }
    })
  })
  return errorsWarnings && errorsWarnings.length > 0 ? errorsWarnings : [];
}
