import React from 'react';
import { connect, Field, FormikProps, getIn } from 'formik';
import { FormattedMessage, InjectedIntl, injectIntl } from 'react-intl';
import styled from 'styled-components';
import Button from 'components/Button';

import { ApiSmartProdSourceDTO } from 'types/drep-backend';

import messages from './messages';
import { SmartProdSourceForm } from './types';
import FacilitySelection from './FacilitySelection';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

const SelectButton = styled(Button)`
  margin-left: 10px;
  height: 20px;
`;

const ColumnWrapper = styled.div`
  flex-grow: ${props => (props.size ? props.size : 1)};
  display: grid;
  grid-template-columns: fit-content(40%) auto;
  grid-row-gap: ${props => props.theme.grid.rowGap};
  align-items: normal;
  font-size: 12px;
  > * {
    margin-right: 10px;
  }
  > span {
    white-space: nowrap;
  }
`;

const StyledField = styled(Field)`
  background-color: ${props => props.theme.color.grey5};
`;

const Value = styled.div`
  font-weight: bold;
  min-height: ${props => props.theme.grid.tinyRowHeight};
`;
const FacilityDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const FacilityAdd = styled.div`
grid-column: 1 / -1;
`
type BasicInfoProps = {
  entity: ApiSmartProdSourceDTO;
  isEdit: boolean;
  formik?: FormikProps<SmartProdSourceForm>;
  fetchNewFacilities?: any;
  entityRelations?: any;
};

const BasicInfo = (props: BasicInfoProps) => {
  const [facilityFlag, setFacilityFlag] = React.useState(false);
  const { entity, isEdit, formik, fetchNewFacilities, entityRelations } = props;
  if (!entity) return <span />;
  const field = (name: string) => (isEdit ? <StyledField name={name} id={name} /> : getIn(formik.values, name));
  const handleFacilityFlag = () => setFacilityFlag(false);
  const handleSelectFacilityFlag = () => {
    fetchNewFacilities();
    setFacilityFlag(true);
  }
  return (
    <Wrapper id="SmartProdSourceBasicInfo">
      <ColumnWrapper>
      <Value><FormattedMessage {...messages.facility} /></Value>
        <FacilityDiv>
        {formik?.values?.entity?.facility?.code} - {formik?.values?.entity?.facility?.name}
        {<SelectButton disabled={!isEdit || facilityFlag} onClick={handleSelectFacilityFlag}>Select</SelectButton>}
        </FacilityDiv>
        {facilityFlag && isEdit && <FacilityAdd><FacilitySelection entityRelations={entityRelations} fetchNewFacilities={fetchNewFacilities} handleFacilityFlag={handleFacilityFlag}/></FacilityAdd>}
        <Value><FormattedMessage {...messages.smartProdInstance} /></Value>
        {entity.smartProdInstance.name}
        <Value> <FormattedMessage {...messages.whid} /></Value>
        {entity.smartProdWarehouse.smartProdWhId}
        <Value><FormattedMessage {...messages.description} /></Value>
        {field('entity.description')}
      </ColumnWrapper>
    </Wrapper>
  );
};

// @ts-ignore
export default connect(injectIntl(BasicInfo));
