import React from "react";
import ToggleSection from 'components/ToggleSection';
import messages from "./messages";
import { WarningText } from 'components/FormikTextInput';
import { FormattedMessage } from 'react-intl';

const PlanningAreaWarning = ({ omsChangeHistory }) => {
    const warningTextDisplay = omsChangeHistory?.length > 0 ? messages.warningsAvailable : '';
    return (
        <ToggleSection subtitleToShow={warningTextDisplay} message={messages.warning}>
            {
                omsChangeHistory?.length > 0 &&
                <ToggleSection message={messages.omsChangeHistory}>
                    {
                        omsChangeHistory && omsChangeHistory.map((o) => {
                            return <WarningText>{`OMS Code Of ${o.jobCodeName} was changed. Please run SmartProd Run to assign the new ratio.`}</WarningText>
                        })
                    }
                </ToggleSection>
            }
            {
                omsChangeHistory?.length == 0 && <WarningText><FormattedMessage {...messages.noWarning} /></WarningText>
            }
        </ToggleSection>
    )
}

export default PlanningAreaWarning;