import React from 'react';
import { WarningText } from 'components/FormikTextInput';
import { parseDateTimeAndFormatToLocalString } from 'utils/dateTime';
import messages from '../../containers/SmartProdSourceDetailPage/messages';
import ToggleSection from 'components/ToggleSection';
import { Loading } from '../../containers/ForecastDetailPage/Loading';
import RatioWarnings from './RationWarnings';
import { FormattedMessage } from 'react-intl';
import { isEmpty } from 'lodash';
import { parseDateAndFormatToLocalString } from 'utils/dateTime';

const PlanDetailWarning = ({ isArea, planDetailMasterPlans, planDetailSmartRuns, ratioWarnings, smartProdRunsDetailLoader, ratioWarningsGroup, planRatioWarning, planDetailPage, activityWarnings, activityForecastListWaring, activityForecastListWaringZeroRatio, omsChangeHistory, defaultCustomers = [] }) => {
    const holidayWarnings = (planDetailSmartRuns && planDetailSmartRuns[0] && planDetailSmartRuns[0].warning) || [];
    const holidayWarningsHyperlinkId = planDetailSmartRuns && planDetailSmartRuns[0] && planDetailSmartRuns[0].warningWithHyperlink && planDetailSmartRuns[0].warningWithHyperlink.smartprodsourceId;
    const holidayWarningsHyperlinkName = planDetailSmartRuns && planDetailSmartRuns[0] && planDetailSmartRuns[0].warningWithHyperlink && planDetailSmartRuns[0].warningWithHyperlink.smartprodsourceHyperlink;
    const holidayWarningsFlag = holidayWarnings?.smartProdSourceWarning && planDetailPage;
    const activityWarningsFlag = activityWarnings?.length > 0 && planDetailPage;
    const holidayWarningsHyperlinkFlag = holidayWarningsHyperlinkId && holidayWarningsHyperlinkName && planDetailPage;
    const forcastErrors = ratioWarnings ? ratioWarnings : [];
    const masterPlanErrors = planDetailMasterPlans && planDetailMasterPlans.filter((mp) => (mp && mp.runs && mp.runs[0] && mp.runs[0].status === "ERROR")) || [];
    const SmartCodeErrors = planDetailMasterPlans && planDetailMasterPlans.filter((mp) => (mp && mp.smartCodeLogs && mp.smartCodeLogs[0] && mp.smartCodeLogs[0].status === "FAILED")) || [];
    const smartProdRunErrors = planDetailSmartRuns && planDetailSmartRuns.filter((mp) => (mp && mp.smartProdRuns && mp.smartProdRuns[0] && mp.smartProdRuns[0].status === "FAILED")) || [];
    const smartVolumeRunErrors = planDetailSmartRuns && planDetailSmartRuns.filter((mp) => (mp && mp.smartVolumeRuns && mp.smartVolumeRuns[0] && mp.smartVolumeRuns[0].status === "FAILED")) || [];
    const warningType = isArea ? messages.warningAvailablePlaningArea : messages.warningAvailablePlan;
    const hasratioWarningsGroup = isEmpty(ratioWarningsGroup);
    const warningTextDisplay = (masterPlanErrors.length > 0 || smartProdRunErrors.length > 0 || smartVolumeRunErrors.length > 0 || SmartCodeErrors.length > 0 || holidayWarningsFlag || activityWarningsFlag || holidayWarningsHyperlinkFlag || (activityForecastListWaring?.length > 0) || (activityForecastListWaringZeroRatio.length > 0) || !hasratioWarningsGroup || (planRatioWarning === false && planDetailPage) || (omsChangeHistory?.length > 0) && (defaultCustomers.length > 0)) ? warningType : '';
    const planRatioWarningFlag = !planDetailPage ? true : (planDetailPage === true && planRatioWarning === false ) ? false : true;
    const smartProdLink = () => {
        const url = `/smartProdSource/${holidayWarningsHyperlinkId}`
        return (
          <div>New holidays is assigned to <a href={url}>{holidayWarningsHyperlinkName}</a>.</div>
        )
      }
    const smartProdWarningLink = () => {
        const url = `/smartProdSource/${holidayWarnings?.smartProdSourceId?.id}`;
        const name = holidayWarnings?.smartProdSourceId?.name;
        return (holidayWarnings?.smartProdSourceId?.id && holidayWarnings?.smartProdSourceId?.name) ? <a href={url}>{` ${name}`}</a> : null;
    }  

    const constructDateRange = (dates) => {
        const sortByDate = (a,b) => (a.startDay > b.startDay) ? 1 : ((b.startDay > a.startDay) ? -1 : 0);
        const sortedDates = dates?.sort(sortByDate);
        const dateRanges = sortedDates?.map((d) => `${parseDateAndFormatToLocalString(d?.startDay)} - ${parseDateAndFormatToLocalString(d?.endDay)}`)
        if(dateRanges?.length === 1){
            return dateRanges;
        }
        const last = dateRanges?.pop();
        return dateRanges.join(', ') + ' and ' + last;
    }
    return (
        <>
            {
                (smartProdRunsDetailLoader === undefined || smartProdRunsDetailLoader === true) ? (
                    <ToggleSection message={messages.warning}>
                        <Loading message={messages.warningLoader} />
                    </ToggleSection>
                ) :
                    <ToggleSection subtitleToShow={warningTextDisplay} message={messages.warning}>
                        {
                            planDetailPage && activityWarnings && activityWarnings.map((aw)=>{
                                return <WarningText>{aw}</WarningText>
                            })
                        }
                        {
                            activityForecastListWaring && activityForecastListWaring.map((afw)=>{
                                return (
                                    <>
                                    <WarningText>{`Activity ${afw.activity} is displayed from different from period ${constructDateRange(afw.dateInterval)}. This will cause that forecast hours for this activity won't be used in result page. To resolve the issue you can use one of following options:`}</WarningText>
                                    <ul style={{marginLeft: '25px'}}>
                                        <li>Add this activity to default tab</li>
                                        <li>Remove this activity from mapping in SmartCode</li>
                                        <li>Create SmartProd run to calculate ratio for this activity using another data source period</li>
                                    </ul>
                                    </>
                                )
                            })
                        }
                        {
                            activityForecastListWaringZeroRatio && activityForecastListWaringZeroRatio.map((afwz)=>{
                                return <WarningText>{`Activity ${afwz.activity} is displayed from different from period ${constructDateRange(afwz.dateInterval)}. This activity will be shown on forecast but not on result page. No action required.`}</WarningText>
                            })
                        }
                        {
                            holidayWarningsHyperlinkFlag && (
                                <WarningText>{smartProdLink()}</WarningText>
                            )
                        }
                        {
                            planDetailPage && holidayWarnings && holidayWarnings?.smartProdSourceWarning && (
                                <WarningText>{holidayWarnings?.smartProdSourceWarning}{smartProdWarningLink()}</WarningText>
                            )
                        }
                        {
                            smartVolumeRunErrors && smartVolumeRunErrors.map((sme) => {
                                return <WarningText>{`Latest SmartVolume refresh failed at ${parseDateTimeAndFormatToLocalString(sme.smartVolumeRuns[0].dateTime)}.`}</WarningText>
                            })
                        }
                        {
                            masterPlanErrors && masterPlanErrors.map((mpe) => {
                                return <WarningText>{`Latest Master plan Reporting Run failed for ${mpe.name} at ${parseDateTimeAndFormatToLocalString(mpe.runs[0].dateTime)}.`}</WarningText>
                            })
                        }
                        {
                            SmartCodeErrors && SmartCodeErrors.map((sce) => {
                                return <WarningText>{`Latest SmartCode integration failed for Masterplan ${sce.name} at ${parseDateTimeAndFormatToLocalString(sce.smartCodeLogs[0].dateTime)}.`}</WarningText>
                            })
                        }
                        {
                            smartProdRunErrors && smartProdRunErrors.map((spe) => {
                                return <WarningText>{`Latest SmartProd run refresh failed for ${spe.smartProdRuns[0].name} at ${parseDateTimeAndFormatToLocalString(spe.smartProdRuns[0].dateTime)}.`}</WarningText>
                            })
                        }
                        {
                            planRatioWarning === false ?
                            <WarningText id="plan-ratio-warning">{`Ratio of some OMS codes isn't 100%. For details check warning section on forecasting page.`}</WarningText> : null
                        }
                        {
                            !hasratioWarningsGroup ?
                                 <RatioWarnings ratioWarningsGroup={ratioWarningsGroup} /> : null
                        }
                        {
                            omsChangeHistory?.length > 0 &&
                            <ToggleSection message={messages.omsChangeHistory}>
                                {
                                    omsChangeHistory && omsChangeHistory.map((o) => {
                                        return <WarningText>{`OMS Code Of ${o.jobCodeName} was changed. Please run SmartProd Run to assign the new ratio.`}</WarningText>
                                    })
                                }
                            </ToggleSection>
                        }
                        {
                            defaultCustomers && defaultCustomers.length > 0 &&
                                <WarningText>{`There is no historical data for this job code(s) ${defaultCustomers} for last 400 days. If this job code(s) is no longer used It's recommended to disable this job code(s) in SmartProductivity.`}</WarningText>
                        }
                        {
                            (smartProdRunErrors.length == 0 && smartVolumeRunErrors.length == 0 && masterPlanErrors.length == 0 && SmartCodeErrors.length === 0 && (hasratioWarningsGroup) && (planRatioWarningFlag) && (!holidayWarningsFlag) && (!activityWarningsFlag) && (!holidayWarningsHyperlinkFlag) && (activityForecastListWaring.length == 0)) && (activityForecastListWaringZeroRatio.length == 0) && (omsChangeHistory?.length == 0) && defaultCustomers?.length == 0 && <WarningText><FormattedMessage {...messages.noWarningsAvailable}/></WarningText>
                        }
                    </ToggleSection>
            }
        </>

    )
}

export default PlanDetailWarning;
