/**
 *
 * DayToHourTransformation
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { createStructuredSelector } from 'reselect';
import { bindActionCreators, compose } from 'redux';
import styled from 'styled-components';
import { getIn, connect as formikConnect } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cloneDeep from 'lodash/cloneDeep';
import hash from 'object-hash';
import { IndirectValueGetter } from 'components/ListGrid/utils';
import { ComponentWithDirtyCheck } from 'components/ButtonWithDirtyCheck';
import { DAY_NAMES } from 'utils/calendar/constants';
import { numericSetter, toNumber, percentageCellFormatterNull as percentageFormatter } from 'utils/utils';
import calendarMessages from 'utils/calendar/messages';
import injectReducer from 'utils/injectReducer';
import { checksumPercentageFormatter, checkSumValidationStyle, makeChecksumGetterByFilter } from 'utils/checksum';
import NameWithToolTip, { UOMNameWithToolTip } from 'components/NameWithToolTip';
import CustomerCellRenderer, { customerFilterParams } from 'components/CustomerCellRenderer';

import { groupByDepartmentProps } from 'components/Table/utils';
import makeSelectDayToHourTransformation from './selectors';
import reducer from './reducer';
import messages from './messages';
import {
  selectEditFromPa,
  selectFirstdayPA,
  selectOffDaysPA,
  selectPeriodIndexFromPa,
} from '../PlanningAreaDetailPage/selectors';
import { toggleDayAction } from './actions';

import {
  selectEditFromplan,
  selectFirstday,
  selectOffDays,
  selectPeriodIndexFromPlan,
} from '../PlanDetailPage/selectors';

import { copyDay } from '../DayToWzpTransformation/helpers/dayToWzp';
import { DeleteDialog, withDeleteDialog } from '../../components/Dialog';
import distributeOffHours from './helpers/distributeOffHours';
import TableControlled from '../TableControlled';
import { TABLE_DEFAULTS } from '../App/constants';
import { disableOffDays, sortByWeekStart } from '../DayToWzpTransformation/utils';

const Wrap = styled.div`
  .cell-gap {
    background-color: rgba(150, 150, 150, 0.44);
    text-align: right;
  }
`;

// box-shadow: ${props => (!props.value ? props.theme.shadow.button : 'inherit')};
export const DayTransformationToggleButtonStyled = styled.div`
  padding-top: 5px;
  padding-right: 10px;
  padding-bottom: 5px;
  padding-left: 10px;
  height: 36px;
  display: flex;
  align-items: center;
  min-width: 75px;
  background-color: ${props => (props.value ? props.theme.color.yellow : props.theme.color.grey1)};
  border: 0px none;
  border-image-width: 0px;
  :hover {
    cursor: ${props => (props.disabled ? 'inherit' : 'pointer')};
  }
  text-transform: capitalize;
  box-shadow: ${props => props.theme.shadow.button};
  position: relative;
  margin-bottom: 26px !important;
`;

export const DistributeOffHoursButtonStyled = styled(DayTransformationToggleButtonStyled)`
  background-color: ${props => (!props.disabled ? props.theme.color.yellow : props.theme.color.grey1)};
  margin-left: auto !important;
`;

export const DayTransformationCopyButtonWrapper = styled.div`
  position: absolute;
  bottom: -1.5em;
  overflow: hidden;
  margin-left: -10px;
  font-size: 80%;
  text-align: center;
  width: 100%;
`;

export const DayTransformationButtonsWrap = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 2px;
  > * {
    margin: 0 2px;
  }
`;

export const DayTransformationCopyButtonStyled = styled.span`
  background-color: ${props => props.theme.color.yellow};
  padding: 3px 6px;
  position: relative;
  top: -5px;
  transition: all 0.3s ease 0s;
  :hover {
    top: 0px;
  }
`;

const TableWithHeight = styled(TableControlled)`
  height: ${props => props.height}px;
`;

const departmentValueGetter = formatMessage => params => {
  if (params.data) {
    return params.data.department ? params.data.department.name : formatMessage(messages.allDepartments);
  }
  return null;
};

/* eslint-disable react/prefer-stateless-function */
export class DayToHourTransformation extends React.Component {
  state = {
    recalculationCount: 0,
  };

  shouldComponentUpdate(nextProps, nextState) {
    const buildGuiStateKey = props => {
      const key = [];
      key.push(props.edit);
      key.push(props.selectedDays.join(':'));
      key.push(props.deleteDialogOpen);
      key.push(props.periodIndex);
      key.push(props.firstDay);
      key.push(props.offDays ? props.offDays.toJS().join(':') : '');
      key.push(getIn(props.formik, 'values.reloadCount', 0));
      return key.join('-');
    };
    const shouldUpdate = buildGuiStateKey(this.props) !== buildGuiStateKey(nextProps);
    // console.log('DayToHoutTrans SCU', shouldUpdate);
    // console.log(nextProps);
    return shouldUpdate;
  }

  copyDayHandler = (fieldName, fromToDays) => {
    this.setState({ dialogType: 'copy' });
    this.props.openDeleteDialog(() => {
      const newValue = copyDay(cloneDeep(getIn(this.props.formik.values, fieldName)), fromToDays);
      this.props.formik.setFieldValue(fieldName, newValue);
    }, fromToDays);
  };

  distributeOffHoursHandler = () => {
    const index = this.props.periodIndex;
    const dataField = `planningParameters.periods.${index}.apCalculated.hourTransitions`;
    this.setState({ dialogType: 'dist' });
    this.props.openDeleteDialog(() => {
      const value = getIn(this.props.formik.values, dataField);
      const newValue = distributeOffHours(cloneDeep(value)); // without clone even initialValues are overwritten
      this.setState({ recalculationCount: this.state.recalculationCount + 1 });
      this.props.formik.setFieldValue(dataField, { headers: newValue.headers, rowData: newValue.rowData });
    });
  };

  componentDidMount() {
    disableOffDays(this.props.offdays, this.props.toggleDayAction);
  }

  componentDidUpdate(prevProps) {
    const { offdays } = this.props;
    const { offdays: prevOffDays } = prevProps;
    if (prevOffDays !== offdays) {
      disableOffDays(this.props.offdays, this.props.toggleDayAction);
    }
  }

  getRowKey = data => data.id;

  render() {
    const index = this.props.periodIndex;
    const {
      selectedDays,
      intl: { formatMessage },
    } = this.props;
    const dataField = `planningParameters.periods.${index}.apCalculated.hourTransitions`;
    const deleteDialogText =
      this.state.dialogType === 'copy'
        ? {
            text: messages.dayCopyConfirmationText,
            title: messages.dayCopyConfirmationTitle,
            confirmLabel: messages.dayCopyConfirmationYes,
          }
        : {
            text: messages.distributeOffHoursText,
            title: messages.distributeOffHoursTitle,
            confirmLabel: messages.distributeOffHoursYes,
          };
    const hourTransitions = getIn(this.props.formik.values, dataField);
    const { headers } = hourTransitions;
    // agGrid IS MUTATING row data - without cloneDeep formik's initial data gets overridden on paste
    const rowData = cloneDeep(hourTransitions.rowData);
    const values = [ 'STAFF_UNPRODUCTIVE', 'LOCATION_UNPRODUCTIVE' ];
    const filteredRowData = rowData && rowData.filter(item => item && item.activity && item.activity.productivityType && !values.includes(item.activity.productivityType));
    filteredRowData && filteredRowData.forEach((fd) => {
      const activityName = fd && fd.activity && fd.activity.name;
      const variableName = fd && fd.variableName
      const uomName = fd && fd.uom && fd.uom.name;
      fd.activityName = `${activityName} (${variableName})`;
      fd.uomName = uomName;
    })
    const colDefs = this.createColumnsDef(headers, this.props.edit, selectedDays);
    const totalRows = filteredRowData.length;
    const aproxHeight = Math.min(totalRows || 1, 15) * 12 + 200;
    // when colDefs changes, we need to completely refresh cell headers (including background) and there's
    // no way to force agGrid to refresh - tried all API methods (refreshHeaders, redrawRows()
    // so the only chance is use key of parent element - react will throw the dom away and ask
    // aggrid to recreate it. Defect 364855
    const guiStateKey = `${this.props.edit}_${index}_${selectedDays.join('-')}_${this.state.recalculationCount}`;
    const colDefKey = hash(colDefs);
    return (
      <Wrap key={guiStateKey}>
        <FormattedMessage {...messages.header} />
        <div key={colDefKey}>
          <DayTransformationButtonsWrap>
            {this.renderDaySwitchButtons((...args) => this.copyDayHandler(dataField, ...args))}

            <ComponentWithDirtyCheck actionHandler={e => this.distributeOffHoursHandler()}>
              {({ onClickHandler }) => (
                <DistributeOffHoursButtonStyled disabled={!this.props.edit} onClick={onClickHandler}>
                  <FormattedMessage {...messages.distributeOffHoursTitle} />
                </DistributeOffHoursButtonStyled>
              )}
            </ComponentWithDirtyCheck>
          </DayTransformationButtonsWrap>
          <TableWithHeight
            defaultConfig={this.props.edit ? TABLE_DEFAULTS.dayToHourEditConfig : TABLE_DEFAULTS.dayToHourConfig}
            showCOG={false}
            name={this.props.edit ? 'dayToHourEdit' : 'dayToHour'}
            columnDefs={colDefs}
            rowData={filteredRowData}
            height={aproxHeight}
            onGridReady={params => {
              // do nothing - suppress params.api.sizeColumnsToFit();
            }}
            onCellValueChanged={this.props.edit ? this.onCellValueChanged : null}
            pagination={false}
            getRowNodeId={this.getRowKey}
            deltaRowDataMode
            onPaste={this.props.edit ? this.onPaste: null}
            // Grouping
            {...groupByDepartmentProps(formatMessage, messages, departmentValueGetter(formatMessage))}
          />
        </div>
        <DeleteDialog {...this.props} {...deleteDialogText} />
      </Wrap>
    );
  }

  createColumnsDef(headers, editable, selectedDays) {
    const {
      intl,
      formik: {
        values: { planningArea, customers },
      },
    } = this.props;
    const cList = customers === undefined ? planningArea.customers : customers;
    return [
      {
        headerName: null,
        width: 100,
        colId: 'department',
        pinned: true,
        menuTabs: ['filterMenuTab'],
        valueGetter: departmentValueGetter(intl.formatMessage),
        rowGroup: true,
      },
      {
        headerName: intl.formatMessage(messages.activity),
        width: 160,
        colId: 'activityName',
        field: 'activityName',
        cellRendererFramework: NameWithToolTip('activity.regionalConfigurationName'),
        menuTabs: ['filterMenuTab'],
        pinned: true,
      },
      {
        headerName: intl.formatMessage(messages.customerId),
        width: 100,
        colId: 'customerId',
        field: 'customerId',
        menuTabs: ['filterMenuTab'],
        filterParams: customerFilterParams(cList, intl.formatMessage),
        cellRendererFramework: CustomerCellRenderer(cList),
      },
      {
        headerName: intl.formatMessage(messages.uom),
        width: 100,
        colId: 'uomName',
        field: 'uomName',
        menuTabs: ['filterMenuTab'],
        cellRendererFramework: UOMNameWithToolTip,
      },
      {
        headerName: intl.formatMessage(messages.indirect),
        field: 'activity.indirect',
        colId: 'activity.indirect',
        menuTabs: ['filterMenuTab'],
        valueGetter: IndirectValueGetter,
        width: 120,
        pinned: true,
      },
      ...headers
        .filter(day => selectedDays.includes(day.label))
        .map(day => ({
          headerName: intl.formatMessage(calendarMessages[day.label.toLowerCase()]),
          children: [
            ...day.children.map(wzpOrGap => ({
              headerName: wzpOrGap.label || '',
              headerClass: wzpOrGap.label ? undefined : 'cell-gap',
              headerTooltip: wzpOrGap.label,
              children: wzpOrGap.children.map(hour => ({
                headerName: hour.label,
                field: hour.field,
                colId: `${day.label}_${hour.field}`,
                width: 100,
                cellClass: wzpOrGap.label ? undefined : 'cell-gap',
                headerClass: wzpOrGap.label ? undefined : 'cell-gap',
                suppressMenu: true,
                valueFormatter: percentageFormatter,
                valueSetter: numericSetter,
                editable,
              })),
            })),
            {
              headerName: intl.formatMessage(messages.checkSum),
              colId: `${day.label}_checksum`,
              valueFormatter: checksumPercentageFormatter,
              valueGetter: makeChecksumGetterByFilter(key => key.startsWith(day.label)),
              cellStyle: checkSumValidationStyle,
              width: 140,
            },
          ],
        })),
    ];
  }

  renderDaySwitchButtons(copyDayHandler) {
    const {
      intl: { formatMessage },
    } = this.props;
    const showCopyToButtons = this.props.selectedDays.length === 1;
    const selectedDay = this.props.selectedDays[0];
    return sortByWeekStart(DAY_NAMES, this.props.firstDay).map(dayName => {
      const isDaySelected = this.props.selectedDays.includes(dayName);
      return (
        <DayTransformationToggleButtonStyled
          key={dayName}
          value={isDaySelected}
          onClick={() => {
            this.props.toggleDayAction(dayName);
          }}
        >
          {showCopyToButtons && !isDaySelected && this.props.edit
            ? this.renderDayCopyToButton(selectedDay, e => {
                e.stopPropagation();
                copyDayHandler({ dayFrom: selectedDay, dayTo: dayName });
              })
            : null}
          {formatMessage(calendarMessages[dayName.toLowerCase()])}
        </DayTransformationToggleButtonStyled>
      );
    });
  }

  renderDayCopyToButton(dayTo, onClickHandler) {
    return (
      <DayTransformationCopyButtonWrapper>
        <DayTransformationCopyButtonStyled key={dayTo} onClick={onClickHandler}>
          <FontAwesomeIcon icon="copy" size="xs" title="Copy to this day" />
        </DayTransformationCopyButtonStyled>
      </DayTransformationCopyButtonWrapper>
    );
  }

  onCellValueChanged = params => {
    const { setFieldValue } = this.props.formik;
    const index = this.props.periodIndex;
    if (setFieldValue) {
      const fieldName = `planningParameters.periods.${index}.apCalculated.hourTransitions.rowData.${params.data.index}.${
        params.colDef.field
      }`;
      setFieldValue(fieldName, toNumber(params.newValue));
    } else {
      console.warn('DayToHourTransformation setFieldValue property not set, cannot propagate value update', params);
    }
  };

  onPaste = data => {
    const { setFieldValue } = this.props.formik;
    const index = this.props.periodIndex;
    data.sort((a, b) => (a.index < b.index ? -1 : 1));
    if (setFieldValue) {
      const fieldName = `planningParameters.periods.${index}.apCalculated.hourTransitions.rowData`;
      setFieldValue(fieldName, data);
    } else {
      console.warn('DayToHourTransformation setFieldValue property not set, cannot propagate value update', data);
      console.log('DayToHourTransformation props are', this.props);
    }
  };
}

DayToHourTransformation.propTypes = {
  dispatch: PropTypes.func.isRequired,
  toggleDayAction: PropTypes.func,
  openDeleteDialog: PropTypes.func,
  edit: PropTypes.bool,
  periodIndex: PropTypes.number,
  selectedDays: PropTypes.array,
  offdays: PropTypes.object,
  intl: PropTypes.object,
  firstDay: PropTypes.string,
  formik: PropTypes.object,
};

const mapStateToPropsPlan = createStructuredSelector({
  selectedDays: makeSelectDayToHourTransformation(),
  edit: selectEditFromplan,
  periodIndex: selectPeriodIndexFromPlan,
  offdays: selectOffDays,
  firstDay: selectFirstday,
});

const mapStateToPropsPa = createStructuredSelector({
  selectedDays: makeSelectDayToHourTransformation(),
  edit: selectEditFromPa,
  periodIndex: selectPeriodIndexFromPa,
  offdays: selectOffDaysPA,
  firstDay: selectFirstdayPA,
});

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators({ toggleDayAction }, dispatch),
    dispatch,
  };
}

const withReducer = injectReducer({ key: 'dayToHourTransformation', reducer });

export const DayToHourTransformationPlan = compose(
  withReducer,
  injectIntl,
  connect(
    mapStateToPropsPlan,
    mapDispatchToProps,
  ),
  withDeleteDialog,
  formikConnect,
)(DayToHourTransformation);

export const DayToHourTransformationPa = compose(
  withReducer,
  injectIntl,
  connect(
    mapStateToPropsPa,
    mapDispatchToProps,
  ),
  withDeleteDialog,
  formikConnect,
)(DayToHourTransformation);
