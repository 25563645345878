/* eslint-disable no-restricted-syntax,guard-for-in,no-param-reassign,no-plusplus */
import { getIn } from 'formik';
import cloneDeep from 'lodash/cloneDeep';
import find from 'lodash/find';
import isEqual from 'lodash/isEqual';
import { DateTime, Duration } from 'luxon';
import hash from 'object-hash';
import { call, take } from 'redux-saga/effects';

import { EffortForecastTableData } from 'components/EffortForecast/types';

import { diffProductivityRate } from '../components/ProductivityRate/prodRateCaclulations';
import { SAVE_PLAN_DONE } from '../containers/PlanDetailPage/constants';
import { SAVE_PA_DONE } from '../containers/PlanningAreaDetailPage/constants';
import { api, apiError, formatDateToApiFormat, withUrl } from './api';
import { DAY_NAMES } from './calendar/constants';
import { textToFormula } from './formulas';
import { LOG_SHIFTS } from './logger';
import { toNumber } from './utils';
import { textToFormulaActivity } from 'utils/formulas';

export function* savePlaningParametersFromTo(dataWrapper) {
  if (dataWrapper) {
    yield call(waitForSaveAll, dataWrapper);
    const apiPayload = {
      startDay: formatDateToApiFormat(dataWrapper.data.startDay),
      endDay: formatDateToApiFormat(dataWrapper.data.endDay),
    };
    if (dataWrapper.data.volumeGranularity) {
      apiPayload.volumeGranularity = dataWrapper.data.volumeGranularity;
    }
    const url = `/planningParameters/${dataWrapper.planningParametersId}`;
    return yield call(
      api,
      withUrl(url).post().andBody(apiPayload).andTitle('Saving planned volume start date and end date'),
    );
  }
  return apiError('Start date and/or end date missing');
}

export function* waitForSaveAll(dataWrapper) {
  if (dataWrapper.waitForSaveAll) {
    // console.log('Waiting for save all');
    const action = yield take([SAVE_PLAN_DONE, SAVE_PA_DONE]);
    // console.log('Save all done with response, saving data', action.payload);
    return action.payload;
  }
  return { isOk: true, isForward: false };
}

export function* doDeleteWzp(dataWrapper) {
  if (dataWrapper) {
    yield call(waitForSaveAll, dataWrapper);
    const wzp = dataWrapper.data;
    // console.log('Deleting work zone period', wzp);
    const apiUrl = `/planningParameters/${dataWrapper.planningParametersId}/workZonePeriods/`;
    const wzpApiUrl = `${apiUrl}${wzp.id}`;
    return yield call(api, withUrl(`${wzpApiUrl}/delete`).post().andTitle('Deleting workzone period'));
  }
  return apiError('WZP details missing');
}

export function* doAddWzp(dataWrapper) {
  if (dataWrapper) {
    yield call(waitForSaveAll, dataWrapper);
    const wzp = dataWrapper.data;
    const apiUrl = `/planningParameters/${dataWrapper.planningParametersId}/workZonePeriods/`;
    return yield call(api, withUrl(apiUrl).put(wzpToApiFormat(wzp)).andTitle('Adding workzone period'));
  }
  return apiError('WZP details missing');
}

export const timeToApiFormat = value => (value ? value.toFormat('hh:mm:ss.SSS') : value);

function wzpToApiFormat(wzp) {
  const result = { name: wzp.name };
  if (wzp.startTime) {
    result.startTime = timeToApiFormat(wzp.startTime);
  }
  if (wzp.endTime) {
    result.endTime = timeToApiFormat(wzp.endTime);
  }
  return result;
}

function isDurationDifferent(d1, d2) {
  if ((!d1 && d2) || (d1 && !d2)) {
    return true;
  }
  if (!d1 && !d2) {
    return false;
  }
  return !d1.equals(d2);
}

export function getModifiedWzp(savedPeriod, updatedPeriod) {
  function isDifferentWzp(wzp1, wzp2) {
    // name
    if (wzp1.name) {
      if (wzp1.name.localeCompare(wzp2.name) !== 0) {
        return true;
      }
    } else if (wzp2.name) {
      return true;
    }
    const durationDiff =
      isDurationDifferent(wzp1.startTime, wzp2.startTime) || isDurationDifferent(wzp1.endTime, wzp2.endTime);
    return durationDiff || wzp1.breakTime !== wzp2.breakTime;
  }

  function toApiFormat(wzp) {
    const format = 'hh:mm:ss.SSS';
    const result = { name: wzp.name, breakTime: wzp.breakTime };
    if (wzp.id) {
      result.id = wzp.id;
    }
    if (wzp.startTime) {
      result.startTime = wzp.startTime.toFormat(format);
    }
    if (wzp.endTime) {
      result.endTime = wzp.endTime.toFormat(format);
    }
    return result;
  }

  if (savedPeriod && updatedPeriod) {
    const savedWzps = savedPeriod.workZonePeriods;
    const updatedWzps = updatedPeriod.workZonePeriods;
    if (savedWzps && updatedWzps) {
      const savedById = {};
      savedWzps.forEach(i => {
        savedById[i.id] = i;
      });
      const wzpPayload = [];
      for (const wzp of updatedWzps) {
        if (!wzp.id || isDifferentWzp(wzp, savedById[wzp.id])) {
          wzpPayload.push(toApiFormat(wzp));
        }
      }
      const currentIds = savedWzps.map(item => item.id);
      const updatedIds = updatedWzps.filter(i => i.id).map(item => item.id);
      const toDelete = currentIds.filter(i => !updatedIds.includes(i));
      if (wzpPayload.length > 0 || toDelete.length > 0) {
        const result = {};
        if (wzpPayload.length > 0) {
          result.workZonePeriods = wzpPayload;
        }
        if (toDelete.length > 0) {
          result.workZonePeriodIdsToDelete = toDelete;
        }
        return result;
      }
    }
  }
  return null;
}

export function getModifiedAdjsutmentParameters(savedPeriod, updatedPeriod){
  function isDifferentMheA(e1, e2) {
    if (!e1 || !e2) {
      return true;
    }
    if (e1.activityFrom && e2.activityFrom && e1.activityFrom !== e2.activityFrom) {
      return true;
    }
    if (e1.activityTo && e2.activityTo && e1.activityTo !== e2.activityTo) {
      return true;
    }
    return (
      e1.mondayRate !== e2.mondayRate ||
      e1.tuesdayRate !== e2.tuesdayRate ||
      e1.wednesdayRate !== e2.wednesdayRate ||
      e1.thursdayRate !== e2.thursdayRate ||
      e1.fridayRate !== e2.fridayRate ||
      e1.saturdayRate !== e2.saturdayRate ||
      e1.sundayRate !== e2.sundayRate
    );
  }

  function toApiFormat(mheA) {
    const { id, activityFrom, activityTo, mondayRate, tuesdayRate, wednesdayRate, thursdayRate, fridayRate, saturdayRate, sundayRate } = mheA;
    const result = {
      activityAdjustmentsId: id,
      activityFrom: activityFrom,
      activityTo: activityTo,
      mondayRate: Number(mondayRate),
      tuesdayRate: Number(tuesdayRate),
      wednesdayRate: Number(wednesdayRate),
      thursdayRate: Number(thursdayRate),
      fridayRate: Number(fridayRate),
      saturdayRate: Number(saturdayRate),
      sundayRate: Number(sundayRate)
    };
    // eslint-disable-next-line no-restricted-globals
    return result;
  }

  if (savedPeriod && updatedPeriod) {
    const savedA = savedPeriod.activityAdjustments;
    const updatedA = updatedPeriod.activityAdjustments;
    if (savedA && updatedA) {
      const savedById = {};
      savedA.forEach(i => {
        savedById[i.id] = i;
      });
      const payload = [];
      for (const mheA of updatedA) {
        // eslint-disable-next-line no-restricted-globals
        if (isNaN(mheA.id) || isDifferentMheA(mheA, savedById[mheA.id])) {
          payload.push(toApiFormat(mheA));
        }
      }
      const currentIds = savedA.map(item => item.id);
      const updatedIds = updatedA.filter(i => Number.isInteger(i.id)).map(item => item.id);
      const toDelete = currentIds.filter(i => !updatedIds.includes(i));
      if (payload.length > 0 || toDelete.length > 0) {
        const result = {};
        if (payload.length > 0) {
          result.activityAdjustments = payload;
        }
        if (toDelete.length > 0) {
          result.activityAdjustments = toDelete;
        }
        return result;
      }
    }
  }
  return null;
}

export function getModifiedMheAvailabilities(savedPeriod, updatedPeriod) {
  function isDifferentMheA(e1, e2) {
    if (!e1 || !e2) {
      return true;
    }
    if (e1.wzp && e2.wzp && e1.wzp.id !== e2.wzp.id) {
      return true;
    }
    if (e1.mhe && e2.mhe && e1.mhe.id !== e2.mhe.id) {
      return true;
    }
    return (
      e1.maintenanceDay !== e2.maintenanceDay ||
      e1.pieces !== e2.pieces ||
      e1.maintenanceRate !== e2.maintenanceRate ||
      e1.departmentId !== e2.departmentId
    );
  }

  function toApiFormat(mheA) {
    const { id, mhe, wzp, maintenanceRate, maintenanceDay, wzpId, ...theRest } = mheA;
    const result = {
      mheId: mhe.id,
      wzpId: wzp.id,
      maintenanceRate: maintenanceRate / 100,
      maintenanceDay: maintenanceDay.toUpperCase(),
      ...theRest,
    };
    // eslint-disable-next-line no-restricted-globals
    return isNaN(id) ? result : { id, ...result };
  }

  if (savedPeriod && updatedPeriod) {
    const savedMheA = savedPeriod.mheAvailabilities;
    const updatedMheA = updatedPeriod.mheAvailabilities;
    if (savedMheA && updatedMheA) {
      const savedById = {};
      savedMheA.forEach(i => {
        savedById[i.id] = i;
      });
      const payload = [];
      for (const mheA of updatedMheA) {
        // eslint-disable-next-line no-restricted-globals
        if (isNaN(mheA.id) || isDifferentMheA(mheA, savedById[mheA.id])) {
          payload.push(toApiFormat(mheA));
        }
      }
      const currentIds = savedMheA.map(item => item.id);
      const updatedIds = updatedMheA.filter(i => Number.isInteger(i.id)).map(item => item.id);
      const toDelete = currentIds.filter(i => !updatedIds.includes(i));
      if (payload.length > 0 || toDelete.length > 0) {
        const result = {};
        if (payload.length > 0) {
          result.mheAvailabilities = payload;
        }
        if (toDelete.length > 0) {
          result.mheAvailabilitiesToDelete = toDelete;
        }
        return result;
      }
    }
  }
  return null;
}

export function getModifiedTimeTransformations(savedPeriod, updatedPeriod) {
  const savedAp = savedPeriod?.apCalculated;
  const updatedAp = updatedPeriod?.apCalculated;
  if (savedAp && updatedAp) {
    const dayMap =
      savedAp.dayTransitions && updatedAp.dayTransitions
        ? getModifiedActivityDayTransformations(savedAp.dayTransitions, updatedAp.dayTransitions)
        : {};
    // console.log('Day transformation diff map', dayMap);
    const hourMap =
      savedAp.hourTransitions && updatedAp.hourTransitions
        ? getModifiedHourTransformations(savedAp.hourTransitions.rowData, updatedAp.hourTransitions.rowData)
        : {};
    // console.log('Hour transformation diff map', hourMap);
    const wzpMap =
      savedAp.wzpTransitions && updatedAp.wzpTransitions
        ? getModifiedWzpTransformations(savedAp.wzpTransitions.rowData, updatedAp.wzpTransitions.rowData)
        : {};
    const transformations = [];
    for (const activityParameter of updatedPeriod.activityParameters) {
      const activityParametersId = activityParameter.id;
      const item = { activityParametersId };
      const dayTransitions = dayMap[activityParametersId];
      spreadIntoChildFieldIfNotEmpty(item, 'dayTransitions', dayTransitions);
      const wzpTransitions = wzpMap[activityParametersId];
      spreadIntoChildFieldIfNotEmpty(item, 'wzpTransitions', wzpTransitions);
      const hourTransitions = hourMap[activityParametersId];
      spreadIntoChildFieldIfNotEmpty(item, 'hourTransitions', hourTransitions);
      if (Object.keys(item).length > 1) {
        transformations.push(item);
      }
    }
    // console.log('Time transformations', transformations);
    if (Object.keys(transformations).length > 0) {
      return { activityParameterTransitions: transformations };
    }
  }
  return null;
}

export function getModifiedDefaultFormula(saved, updated) {

  function extraDiffFormula(saved, updated, type){
    if(!isEqual(saved.defaultFormula, updated.defaultFormula)){
      return JSON.stringify({ r: textToFormulaActivity(updated.defaultFormula.trim()) })
    }
  }

  function volumeDiffForCategory(savedPp, updatedPp, index, categoryPostfix) {
    // planned week
    const updatedPlanWeek = getIn(updatedPp, ['volumeCategoryParameters', `rowData${categoryPostfix}`, index]);
    const savedPlanWeek = getIn(savedPp, ['volumeCategoryParameters', `rowData${categoryPostfix}`, index]);
    const planWeeksDefaultFormula = extraDiffFormula(savedPlanWeek, updatedPlanWeek, 'PLAN');
    // planned day
    const updatedPlanDay = getIn(updatedPp, ['volumeCategoryParameters', `dailyRowData${categoryPostfix}`, index]);
    const savedPlanDay = getIn(savedPp, ['volumeCategoryParameters', `dailyRowData${categoryPostfix}`, index]);
    const planDaysDefaultFormula = extraDiffFormula(savedPlanDay, updatedPlanDay, 'PLAN');
    if ((planDaysDefaultFormula || planDaysDefaultFormula === null) || (planWeeksDefaultFormula || planWeeksDefaultFormula === null)) {
      const row = {
        volumeCategoryId: getIn(updatedPp, [
          'volumeCategoryParameters',
          `rowData${categoryPostfix}`,
          index,
          'volumeCategory',
          'id',
        ]),
      };
      if(planDaysDefaultFormula || planDaysDefaultFormula === null) row.defaultFormula = planDaysDefaultFormula;
      if(planWeeksDefaultFormula || planWeeksDefaultFormula === null) row.defaultFormula = planWeeksDefaultFormula;
      return row;
    }
    return null;
  }
  function addVolumeDiffs(rows, savedPp, updatedPp) {
    const categoryPostfixes = ['', 'Var'];
    // iterate through all volume categories
    for (const categoryPostfix of categoryPostfixes) {
      const categoryArray = getIn(updatedPp, ['volumeCategoryParameters', `rowData${categoryPostfix}`], []);
      for (let i = 0; i < categoryArray.length; i++) {
        const catRow = volumeDiffForCategory(savedPp, updatedPp, i, categoryPostfix);
        if (catRow) {
          rows.push(catRow);
        }
      }
    }
  }

  const savedVcp = saved.planningParameters ? saved.planningParameters.volumeCategoryParameters : null;
  const updatedVcp = updated.planningParameters ? updated.planningParameters.volumeCategoryParameters : null;
  if (saved.planningParameters && updated.planningParameters) {
    const rows = [];
    addVolumeDiffs(
      rows,
      saved.planningParameters,
      updated.planningParameters,
      savedVcp?.dailyRowData,
      updatedVcp?.dailyRowData,
    );
    // console.log('Updated volumes', rows);
    return rows.length > 0 ? rows : null;
  }
  return null;
}

export function getModifiedVolumes(saved, updated) {
  function weeksFromRowDataDiff(diff, type, savedV, updatedV) {
    const weeks = [];
    if (diff && Object.keys(diff).length > 0) {
      for (const field of Object.keys(diff)) {
        if (field !== 'defaultFormula') {
          if (field.match(/^from[0-9]+/)) {
            const startDay = field.substr(4, 10);
            const formulaField = field.includes('_formula');
            if (formulaField) {
              const valueAlreadyModified = weeks.filter(w => w.startDay === startDay);
              if (valueAlreadyModified && valueAlreadyModified.length > 0) {
                weeks.forEach((w) => {
                  if (w.startDay === startDay) {
                    w.formula =!diff[field] ? null :  JSON.stringify({ r: textToFormulaActivity(diff[field].trim()) });
                  }
                })
              } else {
                weeks.push({ startDay, formula: !diff[field] ? null : JSON.stringify({ r: textToFormulaActivity(diff[field].trim()) }), type, volume: updatedV[field.split('_formula')[0]] });
              }
            } else {
              if(!updatedV){
                weeks.push({ startDay, volume: toNumber(diff[field]) || null, type });
              }else{
              weeks.push({ startDay, volume: toNumber(diff[field]) || null, type, formula: updatedV[`${field}_textF`] });
              }
            }
          }
        }
      }
    }
    return weeks.length > 0 ? weeks : null;
  }
  function daysFromRowDataDiff(diff, type, savedV, updatedV) {
    const days = [];
    if (diff && Object.keys(diff).length > 0) {
      for (const field of Object.keys(diff)) {
        if (field !== 'defaultFormula') {
          const formulaField = field.includes('_formula');
          if (formulaField) {
            const valueAlreadyModified = days.filter(d => d.day === field.split('_formula')[0]);
            if (valueAlreadyModified && valueAlreadyModified.length > 0) {
              days.forEach((d) => {
                if (d.day === field.split('_formula')[0]) {
                  d.formula = !diff[field] ? null : JSON.stringify({ r: textToFormulaActivity(diff[field].trim()) });
                }
              })
            } else {
              days.push({ day: field.split('_formula')[0], formula: !diff[field] ? null : JSON.stringify({ r: textToFormulaActivity(diff[field].trim()) }), type, volume: updatedV[field.split('_formula')[0]] });
            }
          } else {
            if(updatedV === undefined){
            days.push({ day: field, volume: toNumber(diff[field]) || null, type});
            }else{
            days.push({ day: field, volume: toNumber(diff[field]) || null, type, formula: updatedV[`${field}_textF`] });
            }
          }
        }
      }
    }
    return days.length > 0 ? days : null;
  }

  function extraDiffUOM(saved, updated, type){
    const currentUOM = saved && saved.uom && saved.uom.id;
    const updatedUOM = updated && updated.uom && updated.uom.id;
    if(currentUOM !== updatedUOM){
      return {id: updatedUOM, type : type}
    }
  }

  function volumeDiffForCategory(savedPp, updatedPp, index, categoryPostfix) {
    // planned week
    const updatedPlanWeek = getIn(updatedPp, ['volumeCategoryParameters', `rowData${categoryPostfix}`, index]);
    const savedPlanWeek = getIn(savedPp, ['volumeCategoryParameters', `rowData${categoryPostfix}`, index]);
    const planWeeks = weeksFromRowDataDiff(extractDiffFieldsShallow(savedPlanWeek, updatedPlanWeek), 'PLAN', savedPlanWeek, updatedPlanWeek);
    const planWeeksUOM = extraDiffUOM(savedPlanWeek, updatedPlanWeek, 'PLAN');
    // planned day
    const updatedPlanDay = getIn(updatedPp, ['volumeCategoryParameters', `dailyRowData${categoryPostfix}`, index]);
    const savedPlanDay = getIn(savedPp, ['volumeCategoryParameters', `dailyRowData${categoryPostfix}`, index]);
    const planDays = daysFromRowDataDiff(extractDiffFieldsShallow(savedPlanDay, updatedPlanDay), 'PLAN', savedPlanDay, updatedPlanDay);
    const planDaysUOM = extraDiffUOM(savedPlanDay, updatedPlanDay, 'PLAN');
    // actual week
    const updatedActWeek = getIn(updatedPp, ['actualsVolumeCategoryParameters', `rowData${categoryPostfix}`, index]);
    const savedActWeek = getIn(savedPp, ['actualsVolumeCategoryParameters', `rowData${categoryPostfix}`, index]);
    const actWeeks = weeksFromRowDataDiff(extractDiffFieldsShallow(savedActWeek, updatedActWeek), 'ACTUALS');
    const actWeeksUOM = extraDiffUOM(savedActWeek, updatedActWeek, 'ACTUALS');
    // actual day
    const updatedActDay = getIn(updatedPp, ['actualsVolumeCategoryParameters', `dailyRowData${categoryPostfix}`, index]);
    const savedActDay = getIn(savedPp, ['actualsVolumeCategoryParameters', `dailyRowData${categoryPostfix}`, index]);
    const actDays = daysFromRowDataDiff(extractDiffFieldsShallow(savedActDay, updatedActDay), 'ACTUALS');
    const actDaysUOM = extraDiffUOM(savedActDay, updatedActDay, 'ACTUALS');
    if (planWeeks || planDays || actWeeks || actDays || planWeeksUOM || planDaysUOM || actWeeksUOM || actDaysUOM) {
      const row = {
        volumeCategoryId: getIn(updatedPp, [
          'volumeCategoryParameters',
          `rowData${categoryPostfix}`,
          index,
          'volumeCategory',
          'id',
        ]),
      };
      if (planWeeks || actWeeks) {
        row.weeks = [...(planWeeks || []), ...(actWeeks || [])];
      }
      if (planDays || actDays) {
        row.days = [...(planDays || []), ...(actDays || [])];
      }
      if(planWeeksUOM) row.uom = planWeeksUOM;
      if(planDaysUOM) row.uom = planDaysUOM;
      if(actWeeksUOM) row.uom = actWeeksUOM;
      if(actDaysUOM) row.uom = actDaysUOM;
      row.planningParametersId = updatedPp?.planningParametersId;
      row.planId = updatedPp?.planId;
      return row;
    }
    return null;
  }
  function addVolumeDiffs(rows, savedPp, updatedPp) {
    const categoryPostfixes = ['', 'Var'];
    // iterate through all volume categories
    for (const categoryPostfix of categoryPostfixes) {
      if (Array.isArray(updatedPp)) {
        updatedPp.forEach((u, index) => {
          const categoryArray = getIn(updatedPp[index], ['volumeCategoryParameters', `rowData${categoryPostfix}`], []);
          for (let i = 0; i < categoryArray.length; i++) {
            const catRow = volumeDiffForCategory(savedPp[index], updatedPp[index], i, categoryPostfix);
            if (catRow) {
              rows.push(catRow);
            }
          }
        })
      } else {
        const categoryArray = getIn(updatedPp, ['volumeCategoryParameters', `rowData${categoryPostfix}`], []);
        for (let i = 0; i < categoryArray.length; i++) {
          const catRow = volumeDiffForCategory(savedPp, updatedPp, i, categoryPostfix);
          if (catRow) {
            rows.push(catRow);
          }
        }
      }
    }
  }

  const savedVcp = saved.planningParameters ? saved.planningParameters.volumeCategoryParameters : null;
  const updatedVcp = updated.planningParameters ? updated.planningParameters.volumeCategoryParameters : null;
  if (saved.planningParameters && updated.planningParameters) {
    const rows = [];
    addVolumeDiffs(
      rows,
      saved.planningParameters,
      updated.planningParameters,
      savedVcp?.dailyRowData,
      updatedVcp?.dailyRowData,
    );
    // console.log('Updated volumes', rows);
    return rows.length > 0 ? rows : null;
  }
  return null;
}

function convertMheToApiFormat(mhe) {
  const result = {
    mheId: mhe.mheId,
    transferRate: getTransitionRate(mhe),
  };
  if (mhe.id) {
    result.id = mhe.id;
  }
  return result;
}

function convertShiftToApiFormat(shift) {
  const result = {
    wzpId: shift.wzpId,
    productivityRate: shift.productivityRate,
  };
  if (shift.id) {
    result.id = shift.id;
  }
  return result;
}

function getTimePercentageRate(item) {
  if (item.timePercentage) {
    if (Number(item.timePercentage) !== 0) {
      return Number(item.timePercentage) / 100;
    }
  }
  return undefined;
}

function convertRoleToApiFormat(role) {
  return {
    roleId: role.roleId,
    timePercentage: getTimePercentageRate(role)
  }
}

export function getModifiedActivityParameters(savedPeriod, updatedPeriod) {
  const savedAp = savedPeriod?.apCalculated;
  const updatedAp = updatedPeriod?.apCalculated;
  const rows = [];
  for (const dirOrIndir of ['direct', 'indirect', 'unproductiveLocations', 'unproductiveStaff']) {
    if (savedAp && updatedAp && savedAp[dirOrIndir] && updatedAp[dirOrIndir]) {
      for (let i = 0; i < updatedAp[dirOrIndir].length; i++) {
        // console.log(`Comparing saved index ${i}`, savedAp[dirOrIndir][i]);
        // console.log(`Comparing updated index ${i}`, updatedAp[dirOrIndir][i]);
        const updatedRow = cloneDeep(updatedAp[dirOrIndir][i]);
        if (updatedRow.customerId && typeof updatedRow.customerId === 'object') {
          updatedRow.customerId = updatedRow.customerId.id;
        }
        const diff = extractDiffFieldsShallow(savedAp[dirOrIndir][i], updatedRow);
        // console.log(`Diff for index ${i}`, diff);
        // handle uom
        if (
          savedAp[dirOrIndir][i].uom &&
          updatedAp[dirOrIndir][i].uom &&
          savedAp[dirOrIndir][i].uom.id !== updatedAp[dirOrIndir][i].uom.id
        ) {
          diff.uomId = updatedAp[dirOrIndir][i].uom.id;
        }
        // handle mhe
        if (JSON.stringify(savedAp[dirOrIndir][i].mhes) !== JSON.stringify(updatedAp[dirOrIndir][i].mhes)) {
          diff.mheTransitions = updatedAp[dirOrIndir][i].mhes.map(convertMheToApiFormat);
        }
         // handle perShift
         if (JSON.stringify(savedAp[dirOrIndir][i].perShift) !== JSON.stringify(updatedAp[dirOrIndir][i].perShift)) {
          diff.shiftActivityProductivityRateDTOs = updatedAp[dirOrIndir][i].perShift.map(convertShiftToApiFormat);
        }
        // handle role
        if (JSON.stringify(savedAp[dirOrIndir][i].roles) !== JSON.stringify(updatedAp[dirOrIndir][i].roles)) {
          diff.roleActivities = updatedAp[dirOrIndir][i].roles.map(convertRoleToApiFormat);
        }
        // handle days array
        const equalDays = equalsArraysWithPlainValues(savedAp[dirOrIndir][i].days, updatedAp[dirOrIndir][i].days);
        if (!equalDays) {
          diff.days = updatedAp[dirOrIndir][i].days || [];
        }
        if (Object.keys(diff).length > 0) {
          const row = { activityParametersId: updatedAp[dirOrIndir][i].id, ...diff };
          rows.push(row);
        }
      }
    }
  }
  // console.log('Updated AP', rows);
  return rows.length > 0 ? { activityParameters: rows } : null;
}

function equalsArraysWithPlainValues(saved, updated) {
  if ((saved === undefined && updated === undefined) || (saved === null && updated === null) || saved === updated) {
    return true;
  }
  if (saved.length !== updated.length) {
    return false;
  }
  for (let i = 0; i < updated.length; i++) {
    if (saved[i] !== updated[i]) {
      return false;
    }
  }
  return true;
}

export function getModifiedShifts(savedPeriod, updatedPeriod) {
  const savedShifts = savedPeriod.shifts;
  const updatedShifts = updatedPeriod.shifts;
  const rows = [];
  if (savedShifts && updatedShifts) {
    for (let i = 0; i < updatedShifts.length; i++) {
      LOG_SHIFTS.d('Original shifts', savedShifts);
      // console.log('Updated shift:', updatedShifts[i]);
      const diff = extractDiffFieldsShallow(savedShifts[i], updatedShifts[i]);
      // handle days
      if (updatedShifts[i].days) {
        if (hash(updatedShifts[i].days) !== hash(savedShifts[i].days)) {
          diff.days = updatedShifts[i].days;
        }
      }
      // handle labourCategoryTransitions
      if (
        JSON.stringify(savedShifts[i].labourCategoryTransitions) !==
        JSON.stringify(updatedShifts[i].labourCategoryTransitions)
      ) {
        diff.labourCategoryTransitions = updatedShifts[i].labourCategoryTransitions.map(
          convertLabourCategoryTransitionToApiFormat,
        );
      }

      if (Object.keys(diff).length > 0) {
        const row = { id: updatedShifts[i].id, ...diff };
        rows.push(row);
      }
    }
  }
  // console.log('Updated shifts', rows);
  return rows.length > 0 ? { shifts: rows } : null;
}

function convertLabourCategoryTransitionToApiFormat(value) {
  return {
    labourCategoryId: value.labourCategory.id,
    departmentId: value.department && value.department.id,
    productivity: value.productivity,
    absenteerismRate: value.absenteeismRate,
    vacationRate: value.vacationRate,
    headCount: value.headCount,
    breakTime: value.breakTime,
    roleId: value.role && value.role.id,
  };
}

export function getModifiedLabourAvailability(savedPeriod, updatedPeriod) {
  const savedLACategories = savedPeriod.labourCategoryParameters || [];
  const updatedLACategories = updatedPeriod.labourCategoryParameters || [];
  const rows = [];
  const toApiFormat = it => ({
    ...it,
    absenteerismRate: it.absenteerismRate / 100,
    productivity: it.productivity / 100,
    vacationRate: it.vacationRate / 100,
  });

  if (savedLACategories && updatedLACategories) {
    for (let i = 0; i < updatedLACategories.length; i++) {
      const savedLACategory = savedLACategories[i] || {};
      const updatedLACategory = updatedLACategories[i];
      const diff = extractDiffFieldsShallow(savedLACategory, updatedLACategory);

      if (Object.keys(diff).length > 0) {
        rows.push({ labourCategoryId: updatedLACategory.labourCategory.id, ...toApiFormat(updatedLACategory) });
      }
    }
  }
  return rows.length > 0 ? { labourCategoryParameters: rows } : null;
}

const divideElementsBy100AndLowercaseKey = val =>
  Object.keys(val).reduce((p, c) => ({ ...p, [c.toLowerCase()]: val[c] / 100 }), {});

function getModifiedActivityDayTransformations(savedDt, updatedDt) {
  const result = {};
  for (let i = 0; i < updatedDt.length; i++) {
    const diff = extractDiffFieldsShallow(savedDt[i], updatedDt[i]);
    spreadIntoChildFieldIfNotEmpty(result, savedDt[i].id, divideElementsBy100AndLowercaseKey(diff));
  }
  return result;
}

function getModifiedHourTransformations(savedHt, updatedHt) {
  const result = {};
  for (let activityIndex = 0; activityIndex < updatedHt.length; activityIndex++) {
    for (const dayName of DAY_NAMES) {
      for (let hour = 0; hour < 24; hour++) {
        const fieldName = `${dayName}_${hour}`;
        if (savedHt[activityIndex][fieldName] !== updatedHt[activityIndex][fieldName]) {
          const activitySettingsId = savedHt[activityIndex].id;
          if (!result[activitySettingsId]) {
            result[activitySettingsId] = {};
          }
          const resultDayField = dayName.toLowerCase();
          if (!result[activitySettingsId][resultDayField]) {
            result[activitySettingsId][resultDayField] = {};
          }
          result[activitySettingsId][resultDayField][hour] = updatedHt[activityIndex][fieldName] / 100;
        }
      }
    }
  }
  return result;
}

function getModifiedWzpTransformations(savedWzt, updatedWzt) {
  const result = {};
  for (let i = 0; i < updatedWzt.length; i++) {
    const diff = extractDiffFieldsShallow(savedWzt[i], updatedWzt[i]);
    // console.log('Diffed WZPs', diff);
    if (Object.keys(diff).length > 0) {
      const changesForActivitySettings = [];
      for (const field of Object.keys(diff)) {
        // field name is in format e.g.: TUESDAY_6511
        const fieldParts = field.split('_');
        const dayName = fieldParts[0];
        const wzpId = Number(fieldParts[1]);
        wzpId && changesForActivitySettings.push({
          workZonePeriodId: wzpId,
          dayOfWeek: dayName,
          transferRate: Number(diff[field]) / 100,
        });
      }
      spreadIntoChildFieldIfNotEmpty(result, savedWzt[i].id, changesForActivitySettings);
    }
  }
  return result;
}

export function spreadIntoChildFieldIfNotEmpty(parent, field, value) {
  if (value && Object.keys(value).length > 0) {
    // eslint-disable-next-line no-param-reassign
    if (parent[field]) {
      parent[field] = { ...parent[field], ...value };
    } else {
      parent[field] = value;
    }
  }
}

function checkDepartments(old, newDept) {
  const departmentsToDelete = [];
  for (let i = 0, l = old.length; i < l; i++) {
    if (!find(newDept, { id: old[i].id })) {
      departmentsToDelete.push(old[i].id);
    }
  }
  if (departmentsToDelete.length > 0) {
    return { changes: true, departmentsToDelete };
  }

  if (old.length !== newDept.length) {
    return { changes: true, departmentsToDelete: null };
  }

  let hasChanges = false;

  old.forEach((item, index) => {
    if (newDept[index].facilityId !== item.facilityId) {
      hasChanges = true;
    }
  });

  return { changes: hasChanges, departmentsToDelete: null };
}

export function diffPlanningParameters(saved, updated) {
  if (saved && updated) {
    let ppDiff = {};
    if (saved.planningParameters && updated.planningParameters) {
      ppDiff = extractDiffFieldsShallow(saved.planningParameters, updated.planningParameters);

      // departments check if updated (why we just can't save all?)
      const newDpt = updated.planningParameters.departments || [];
      const oldDept = saved.planningParameters.departments || [];
      const deptResult = checkDepartments(oldDept, newDpt);
      if (deptResult.changes) {
        ppDiff.departments = newDpt;
        if (deptResult.departmentsToDelete) ppDiff.departmentsToDelete = deptResult.departmentsToDelete;
      }

      // currency
      const newCurrency = !saved.planningParameters.currency && updated.planningParameters.currency;
      const changedCurrency =
        saved.planningParameters.currency &&
        updated.planningParameters.currency &&
        saved.planningParameters.currency.id !== updated.planningParameters.currency.id;
      if (newCurrency || changedCurrency) {
        ppDiff.currencyId = updated.planningParameters.currency.id;
      }
      // time zone
      const newTimeZone = !saved.planningParameters.timezone && updated.planningParameters.timezone;
      const changedTimeZone =
        saved.planningParameters.timezone &&
        updated.planningParameters.timezone &&
        saved.planningParameters.timezone.id !== updated.planningParameters.timezone.id;
      if (newTimeZone || changedTimeZone) {
        ppDiff.timezoneId = updated.planningParameters.timezone.id;
      }
      // off days
      if (updated.planningParameters.offDays) {
        if (hash(updated.planningParameters.offDays) !== hash(saved.planningParameters.offDays)) {
          ppDiff.offDays = updated.planningParameters.offDays;
        }
      }
      // firstHourOfDay
      if (isDurationDifferent(updated.planningParameters.firstHourOfDay, saved.planningParameters.firstHourOfDay)) {
        ppDiff.firstHourOfDay = updated.planningParameters.firstHourOfDay.hours;
      }
      const volumes = getModifiedVolumes(saved, updated);
      const volumeCategoryParameters = getModifiedDefaultFormula(saved, updated);
      const periods = [];
      if (updated.planningParameters && updated.planningParameters.periods) {
        for (let i = 0; i < updated.planningParameters.periods.length; i++) {
          const savedPeriod = saved.planningParameters.periods[i];
          const updatedPeriod = updated.planningParameters.periods[i];
          const periodDiff = extractDiffFieldsShallow(savedPeriod, updatedPeriod);
          const wzpUpdate = getModifiedWzp(savedPeriod, updatedPeriod);
          const ttUpdate = getModifiedTimeTransformations(savedPeriod, updatedPeriod);
          const apUpdate = getModifiedActivityParameters(savedPeriod, updatedPeriod);
          const adjustmentUpdate = getModifiedAdjsutmentParameters(savedPeriod, updatedPeriod)
          const laUpdate = getModifiedLabourAvailability(savedPeriod, updatedPeriod);
          const shiftsUpdate = getModifiedShifts(savedPeriod, updatedPeriod);
          const mheAvailUpdate = getModifiedMheAvailabilities(savedPeriod, updatedPeriod);
          const vcDayTransitionsUpdate = getModifiedVcDayTransitions(savedPeriod, updatedPeriod);
          // console.log(`Comparing period id ${updatedPeriod.id} we have wzpUpdate || ttUpdate || apUpdate`, wzpUpdate, ttUpdate, apUpdate);
          if (
            wzpUpdate ||
            ttUpdate ||
            apUpdate ||
            laUpdate ||
            shiftsUpdate ||
            mheAvailUpdate ||
            adjustmentUpdate ||
            vcDayTransitionsUpdate ||
            Object.keys(periodDiff).length > 0
          ) {
            const period = {
              id: updatedPeriod.id,
              ...periodDiff,
              ...wzpUpdate,
              ...ttUpdate,
              ...apUpdate,
              ...laUpdate,
              ...shiftsUpdate,
              ...mheAvailUpdate,
              ...vcDayTransitionsUpdate,
              ...adjustmentUpdate,
            };
            // console.log('Adding period', period);
            periods.push(period);
          }
        }
      }
      spreadIntoChildFieldIfNotEmpty(ppDiff, 'periods', periods);
      spreadIntoChildFieldIfNotEmpty(ppDiff, 'volumes', volumes);
      spreadIntoChildFieldIfNotEmpty(ppDiff, 'volumeCategoryParameters', volumeCategoryParameters);
    }
    if (saved.actuals && updated.actuals && saved.actuals.formulas && updated.actuals.formulas) {
      const vcp = [];
      for (let i = 0; i < saved.actuals.formulas.length; i++) {
        if (saved.actuals.formulas[i].formulaText !== updated.actuals.formulas[i].formulaText) {
          try {
            const formula = textToFormula(updated.actuals.formulas[i].formulaText);
            vcp.push({
              volumeCategoryId: updated.actuals.formulas[i].volumeCategory.id,
              formula: JSON.stringify({ r: formula }),
            });
          } catch (err) {
            console.log(
              'Formula parsing error during save, update ignored',
              updated.actuals.formulas[i].formulaText,
              err.message,
            );
          }
        }
      }
      if (saved.planningParameters.activityForecastTable && updated.planningParameters.activityForecastTable) {
        const prodRateDiff = diffProductivityRate(
          saved.planningParameters.activityForecastTable,
          updated.planningParameters.activityForecastTable,
        );
        if (prodRateDiff.activityForecastListToSave.length) {
          ppDiff.activityForecastListToSave = prodRateDiff.activityForecastListToSave;
        }
      }
      if (vcp.length > 0) {
        ppDiff.volumeCategoryParameters = vcp;
      }
    }
    return ppDiff;
  }
  return {};
}

export function* addVolumeLine(action, planningParametersId) {
  const { payload } = action;
  if (payload) {
    yield call(waitForSaveAll, payload);
    if (payload.volumeCategoryParameters.length > 0) {
      const url = `/planningParameters/${planningParametersId}/volumeCategoryParameters/`;
      const response = yield call(api, withUrl(url).put(payload).andTitle('Adding volume line'));
      return response;
    }
  }
  return apiError('Category and/or uom not set');
}

export function* deleteWeeklyVolumes(planningParametersId, categoryId) {
  return yield call(
    api,
    withUrl(`/planningParameters/${planningParametersId}/volumeCategoryParameters/${categoryId}/delete`)
      .post()
      .andTitle('delete volumes'),
  );
}

function* addActivityRequestSaga(data) {
  const { planningParametersId, periodId, data: { departmentId, activity } = {} } = data;
  let url = `/planningParameters/${planningParametersId}/periods/${periodId}/activityParameters/`;
  let apiPayload = {};
  if (Array.isArray(activity)) {
    url += 'multiple/';
    apiPayload = {
      activityParameters: activity.map(item => ({
        activityId: item.id,
        departmentId,
        customerId: item.customer === 'all' ? null : item.customer,
        uomId: item.uom && item.uom.id,
        isEffortForecast: item.isEffortForecast,
      })),
    };
  } else {
    const { id, uomId, isEffortForecast } = activity;
    url += `?departmentId=${departmentId}&activityId=${id}&uomId=${uomId}&isEffortForecast=${isEffortForecast}`;
  }

  return yield call(api, withUrl(url).put(apiPayload).andTitle('Adding activity line'));
}

function* addAdjustmentRequestSaga(data) {
  const { planningParametersId, periodId } = data;
  let url = `/planningParameters/${planningParametersId}/periods/${periodId}/activityAdjustments/multiple/`;
  const activityAdjustments = [{
    activityTo: data.activityFrom.activityFromId,
    activityFrom: data.activityTo.activityToId,
    mondayRate: 0,
    tuesdayRate: 0,
    wednesdayRate: 0,
    thursdayRate: 0,
    fridayRate: 0,
    saturdayRate: 0,
    sundayRate: 0
    }]
  return yield call(api, withUrl(url).put({activityAdjustments : activityAdjustments}).andTitle('Adding Adjustment line'));
}

export function* addActivityLine(data) {
  if (data) {
    yield call(waitForSaveAll, data);
    const result = yield call(addActivityRequestSaga, data);
    return result;
  }
  return apiError('No activity provided');
}

export function* addAdjustmentLine(data) {
  if (data) {
    yield call(waitForSaveAll, data);
    const result = yield call(addAdjustmentRequestSaga, data);
    return result;
  }
  return apiError('No adjustment provided');
}

function getTransitionRate(item) {
  if (item.transitionRate) {
    if (Number(item.transitionRate) !== 0) {
      return Number(item.transitionRate) / 100;
    }
  }
  return undefined;
}

export function extractDiffFieldsShallow(saved, updated) {
  const result = {};
  if (saved && updated) {
    for (const field of Object.keys(updated)) {
      const newValue = updated[field];
      // handle dates and times
      if (newValue !== null && typeof newValue === 'object') {
        if (newValue instanceof DateTime && +newValue !== +saved[field]) {
          result[field] = formatDateToApiFormat(newValue);
        } else if (newValue instanceof Duration && +newValue !== +saved[field]) {
          result[field] = timeToApiFormat(newValue);
        }
      } else {
        // plain values
        if (newValue !== saved[field]) {
          result[field] = newValue;
        }
      }
    }
  }
  return result;
}

export function extractDiffFieldsDeep(saved, updated) {
  const result = {};
  if (saved && updated) {
    for (const field of Object.keys(updated)) {
      const newValue = updated[field];
      if (!isEqual(newValue, saved[field])) {
        result[field] = newValue;
      }
    }
  }
  return result;
}

export function extractDiffValuesFromEffortForecast(
  effortForecastInitial: EffortForecastTableData[],
  effortForecast: EffortForecastTableData[],
) {
  if (!effortForecastInitial || !effortForecast) {
    return {};
  }
  let diff = [];
  for (let i = 0; i < effortForecast.length; i++) {
    if (!isEqual(effortForecastInitial[i], effortForecast[i])) {
      const differentOmsDates = [];
      for (let j = 0; j < effortForecast[i].omsDates.length; j++) {
        if (!isEqual(effortForecastInitial[i].omsDates[j], effortForecast[i].omsDates[j])) {
          differentOmsDates.push(effortForecast[i].omsDates[j]);
        }
      }
      const differentActivityDates = [];
      for (let j = 0; j < effortForecast[i].activityDates.length; j++) {
        if (!isEqual(effortForecastInitial[i].activityDates[j], effortForecast[i].activityDates[j])) {
          differentActivityDates.push(effortForecast[i].activityDates[j]);
        }
      }
      diff.push({
        omsId: effortForecast[i].omsId,
        smartProdSourceId: effortForecast[i].smartProdSourceId,
        defaultType: effortForecast[i].defaultType,
        dates: differentOmsDates,
        activity: {
          activityForecastId: effortForecast[i].activityForecastId,
          dates: differentActivityDates.map(row => ({
            ...row,
            ratioOverride: row.ratioOverride !== null ? row.ratioOverride / 100.0 : row.ratioOverride,
          })),
        },
      });
    }
  }
  diff = diff.filter(row => row.dates.length > 0 || row.activity.dates.length > 0 || row.defaultType);
  const grouped = {};
  diff.forEach(row => {
    const key = `${row.smartProdSourceId}_${row.omsId}`;
    if (!grouped[key]) {
      grouped[key] = [];
    }
    grouped[key].push(row);
  });
  return {
    oms: Object.values(grouped).map(activities => ({
      omsId: activities[0].omsId,
      smartProdSourceId: activities[0].smartProdSourceId,
      dates: activities[0].dates,
      activities: activities.map(row => row.activity),
      defaultType: activities[0].defaultType
    })),
  };
}

export function* addPeriod(dataWrapper) {
  if (dataWrapper) {
    yield call(waitForSaveAll, dataWrapper);
    const url = `/planningParameters/${dataWrapper.planningParametersId}/periods/`;
    const apiPayload =
      dataWrapper.data && dataWrapper.data.periodId ? { sourcePeriodParametersId: dataWrapper.data.periodId } : {};
    const response = yield call(api, withUrl(url).put(apiPayload).andTitle('Adding period'));
    return response;
  }
  return apiError('No planning parameters provided');
}

export function* copyPeriod(dataWrapper) {
  if (dataWrapper) {
    yield call(waitForSaveAll, dataWrapper);
    const url = `/planningParameters/${dataWrapper.planningParametersId}/periods/`;
    const apiPayload =
      dataWrapper && dataWrapper.periodId ? { sourcePeriodParametersId: dataWrapper.periodId } : {};
    const response = yield call(api, withUrl(url).put(apiPayload).andTitle('Copying period'));
    return response;
  }
  return apiError('No planning parameters provided');
}

export function* deletePeriod(dataWrapper) {
  if (dataWrapper) {
    yield call(waitForSaveAll, dataWrapper);
    const url = `/planningParameters/${dataWrapper.planningParametersId}/periods/${dataWrapper.periodData.periodId}/delete`;
    const apiPayload = {};
    const response = yield call(api, withUrl(url).post(apiPayload).andTitle('Deleting period'));
    return response;
  }
  return apiError('No planning parameters provided');
}

export function* addLabourCategoryLine(dataWrapper) {
  if (dataWrapper) {
    yield call(waitForSaveAll, dataWrapper);
    const url = `/planningParameters/${dataWrapper.planningParametersId}/periods/${dataWrapper.periodId}/labourCategoryParameters/${dataWrapper.data.category.id}`;
    const apiPayload = {};
    const response = yield call(api, withUrl(url).put(apiPayload).andTitle('Adding worker type line'));
    return response;
  }
  return apiError('No worker type provided');
}

export function* deleteLabourCategoryLine(dataWrapper) {
  if (dataWrapper) {
    yield call(waitForSaveAll, dataWrapper);
    const url = `/planningParameters/${dataWrapper.planningParametersId}/periods/${dataWrapper.periodId}/labourCategoryParameters/${dataWrapper.payload.labourCategory.id}/delete`;
    const apiPayload = {};
    const response = yield call(api, withUrl(url).post(apiPayload).andTitle('Deleting worker type line'));
    return response;
  }
  return apiError('No worker type provided');
}

export function* addShiftSettings(dataWrapper) {
  if (dataWrapper) {
    yield call(waitForSaveAll, dataWrapper);
    const url = `/planningParameters/${dataWrapper.planningParametersId}/periods/${dataWrapper.periodId}/shifts/`;
    const apiPayload = { name: dataWrapper.data.newShiftName };
    const response = yield call(api, withUrl(url).put(apiPayload).andTitle('Adding shift settings line'));
    return response;
  }
  return apiError('No shift provided');
}

export function* deleteShiftSettings(dataWrapper) {
  if (dataWrapper) {
    yield call(waitForSaveAll, dataWrapper);
    const url = `/planningParameters/${dataWrapper.planningParametersId}/periods/${dataWrapper.periodId}/shifts/${dataWrapper.id}/delete`;
    const apiPayload = {};
    const response = yield call(api, withUrl(url).post(apiPayload).andTitle('Deleting shift settings line'));
    return response;
  }
  return apiError('No shift provided');
}

export function getModifiedVcDayTransitions(savedPeriod, updatedPeriod) {
  const rows = [];
  if (savedPeriod.volumeCategoryDayTransitions && updatedPeriod.volumeCategoryDayTransitions) {
    const updatedDt = updatedPeriod.volumeCategoryDayTransitions;
    const savedDt = savedPeriod.volumeCategoryDayTransitions;
    // console.log('Saved: ', savedPeriod.volumeCategoryDayTransitions);
    // console.log('Updated: ', updatedPeriod.volumeCategoryDayTransitions);
    for (let i = 0; i < updatedPeriod.volumeCategoryDayTransitions.length; i++) {
      const diff = extractDiffFieldsShallow(savedDt[i], updatedDt[i]);
      if (Object.keys(diff).length > 0) {
        rows.push({
          volumeCategoryId: updatedDt[i].volumeCategory.id,
          dayTransitions: divideElementsBy100AndLowercaseKey(diff),
        });
      }
    }
  }
  // console.log('Updated volumeCategoryDayTransitions', rows);
  return rows.length > 0 ? { volumeCategoryDayTransitions: rows } : null;
}
