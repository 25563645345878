/**
 * app.js
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

// Needed for redux-saga es6 generator support
import '@babel/polyfill';
import 'sanitize.css/sanitize.css';
import 'global.css';
// Load the favicon and the .htaccess file
/* eslint-disable import/no-unresolved, import/extensions */
import '!file-loader?name=[name].[ext]!./images/favicon.ico';
import 'file-loader?name=.htaccess!./.htaccess'; // eslint-disable-line import/extensions
// Import CSS reset and Global Styles
import './global-styles';
import 'flag-icon-css/css/flag-icon.min.css';

// Import all the third party stuff
import React from 'react';
// Import Azure stuff
import { InteractionType } from '@azure/msal-browser';
import { MsalAuthenticationTemplate, MsalProvider } from '@azure/msal-react';
import { LicenseManager } from 'ag-grid-enterprise';
import { ConnectedRouter } from 'connected-react-router/immutable';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';

// Import Language Provider
import LanguageProvider from 'components/LanguageProvider';
// Import root app
import App from 'containers/App';
import history from 'utils/history';

import configureStore from './configureStore';
import { msalInstance } from './utils/azure';
import { theme } from './utils/theme';
import { WithUser } from './utils/WithUser';

// Create redux store with history

const MOUNT_NODE = document.getElementById('app');

LicenseManager.setLicenseKey(
  'Comparex_AG_on_behalf_of_DHL_MultiApp_3Devs29_August_2019__MTU2NzAzMzIwMDAwMA==0a5463bd07f85d4ab068dc260b35caa0',
);

const store = configureStore({}, history);

const render = () => {
  ReactDOM.render(
    <Provider store={store}>
      <MsalProvider instance={msalInstance}>
        <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
          <WithUser>
            <LanguageProvider>
              <ConnectedRouter history={history}>
                <ThemeProvider theme={theme}>
                  <App />
                </ThemeProvider>
              </ConnectedRouter>
            </LanguageProvider>
          </WithUser>
        </MsalAuthenticationTemplate>
      </MsalProvider>
    </Provider>,
    MOUNT_NODE,
  );
};

// Chunked polyfill for browsers without Intl support
if (!window.Intl) {
  new Promise(resolve => {
    resolve(import('intl'));
  })
    .then(() => render())
    .catch(err => {
      throw err;
    });
} else {
  render();
}
