// @flow
import { fetchData } from 'utils/reduxApi';
import { withUrl, convertEntityWithPlanningParametersFromApi } from 'utils/api';
import { storeActivitiesByPlanAction } from 'containers/App/actions';
import { storePlanAction, storemasterPlanlanAction, storePlanBasicDetails } from './actions';

export async function fetchPlan(id: number, token: string, dispatch: Function) {
  const response = await fetchData(
    withUrl(`/plans/${id}`)
      .andTitle('Loading plan')
      .andToken(token),
    dispatch,
  );
  if (response.isOk) {
    const plan = convertEntityWithPlanningParametersFromApi(response.data);
    dispatch(storePlanAction(plan));
    dispatch(storeActivitiesByPlanAction(plan));
  }
}

export async function fetchMasterPlan(id: number, token: string, dispatch: Function) {
  const response = await fetchData(
    withUrl(`/masterPlan/${id}`)
      .andTitle('Loading master plan')
      .andToken(token),
    dispatch,
  );
  if (response.isOk) {
    dispatch(storemasterPlanlanAction(response.data));
    if (response.data?.plans?.length > 0) {
      const modifiedMasterPlanData = { ...response.data };
      const validPlanData = modifiedMasterPlanData?.plans?.filter((mp) => mp.validFrom && mp.validTo);
      modifiedMasterPlanData.plans = validPlanData;
      const plansData = await fetchPlansInformation(modifiedMasterPlanData, token, dispatch);
      dispatch(storePlanBasicDetails(plansData))
    }
  }
}

async function fetchPlansInformation(masterPlan, token, dispatch) {
  const result = [];
  for (const plan of masterPlan.plans) {
    const masterPlanResult = true;
    const url = `/plans/${plan.planId}`
    const response = await fetchData(withUrl(url).andToken(token), dispatch);
    if (response.isOk) {
      const plan = await convertEntityWithPlanningParametersFromApi(response.data, masterPlanResult);
      result.push(plan);
    }
  }
  return result;
}
