import { fromJS } from 'immutable';

import { STORE_SHIFT_SCHEDULE_RUN, STORE_SHIFT_SCHEDULE_RUNS, SAVE_ERROR_DESCRIPTIONS, STORE_SCHEDULE_STAFF_SYNC_STATE } from './constants';

export const initialState = fromJS({
  shiftScheduleRuns: [],
  shiftScheduleId: 0,
});

function shiftScheduleRunsPageReducer(state = initialState, action) {
  switch (action.type) {
    case STORE_SHIFT_SCHEDULE_RUN:
      const newRuns = state
        .get('shiftScheduleRuns')
        .slice()
        .map(run => (run.id === action.payload.id ? action.payload : run));
      return state.set('shiftScheduleRuns', newRuns);
    case STORE_SHIFT_SCHEDULE_RUNS:
      return state
        .set('shiftScheduleRuns', action.payload?.runs || [])
        .set('shiftScheduleName', action.payload?.scheduleName || '')
        .set('shiftScheduleId', action.payload?.shiftScheduleId);
     case SAVE_ERROR_DESCRIPTIONS: 
      return state.set('errorDescriptions', action.payload)   
     case STORE_SCHEDULE_STAFF_SYNC_STATE:
        return state.set('scheduleStaffSyncState', action.payload);   
    default:
      return state;
  }
}

export default shiftScheduleRunsPageReducer;
