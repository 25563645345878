import { defineMessages } from 'react-intl';

const scope = 'app.components.DatePicker';
export default defineMessages({
  maxDateError: {
    id: `${scope}.maxDateError`,
    defaultMessage: 'Date should not be after maximum date',
  },
  minDateError: {
    id: `${scope}.minDateError`,
    defaultMessage: 'Date should not be before minimum date',
  },
  invalidDateMessage: {
    id: `${scope}.invalidDateMessage`,
    defaultMessage: 'Invalid Date Format',
  },
  todayLabel: {
    id: `${scope}.todayLabel`,
    defaultMessage: 'Today',
  },
  okLabel: {
    id: `${scope}.okLabel`,
    defaultMessage: 'OK',
  },
  clearLabel: {
    id: `${scope}.clearLabel`,
    defaultMessage: 'CLEAR',
  },
  cancelLabel: {
    id: `${scope}.cancelLabel`,
    defaultMessage: 'CANCEL',
  },
});
