/*
 *
 * LoginPage reducer
 *
 */

import { fromJS } from 'immutable';
import {
  API_CALL_ENDED,
  API_CALL_STARTED,
  STORE_UOMS,
  STORE_ACTIVITIES,
  STORE_FROM_BROWSERSTORAGE,
  SAVE_TO_BROWSERSTORAGE,
  ACTIVITY_NAMES,
  STORE_ACTIVITIES_BY_PLAN,
  USER_VIEWS,
  SET_VIEW,
  STORE_BE_VERSION,
} from './constants';
import { alphaSort } from '../../utils/utils';
import { loadFromLocalstorage, saveToLocalstorage } from './saga';

const initialState = fromJS({
  runningApiCalls: {},
  uoms: [],
  activities: [],
  bs: {},
  activityNames: loadFromLocalstorage('activityNames', ACTIVITY_NAMES.custom),
  activitiesByPlan: null,
  view: USER_VIEWS.USER_VIEW,
  beVersion: '',
});

function globalReducer(state = initialState, action) {
  switch (action.type) {
    case SET_VIEW:
      return state.set('view', action.payload);
    case STORE_ACTIVITIES:
      return state.set('activities', action.payload);
    case STORE_UOMS:
      return state.set('uoms', action.payload);
    case API_CALL_ENDED:
      return state.deleteIn(['runningApiCalls', action.payload]);
    case API_CALL_STARTED:
      return state.setIn(['runningApiCalls', action.payload], true);
    case STORE_FROM_BROWSERSTORAGE:
    case SAVE_TO_BROWSERSTORAGE:
      return state.setIn(['bs', action.payload.key], fromJS(action.payload.value));
    case STORE_BE_VERSION:
      return state.set('beVersion', action.payload);
    default:
      return state;
  }
}

export default globalReducer;
