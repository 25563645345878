import { defineMessages } from 'react-intl';

export default defineMessages({
  back: {
    defaultMessage: 'Back',
    id: 'app.containers.ShiftScheduleRunDetailPage.back',
  },
  previous: {
    defaultMessage: 'Previous',
    id: 'app.containers.ShiftScheduleRunDetailPage.previous',
  },
  next: {
    defaultMessage: 'Next',
    id: 'app.containers.ShiftScheduleRunDetailPage.next',
  },
  sendToEngine: {
    defaultMessage: 'Send to Engine',
    id: 'app.containers.ShiftScheduleRunDetailPage.sendToEngine',
  },
  copy: {
    defaultMessage: 'Copy',
    id: 'app.containers.ShiftScheduleRunDetailPage.copy',
  },

  basicInfo: {
    defaultMessage: 'Basic Information',
    id: 'app.containers.ShiftScheduleRunDetailPage.basicInfo',
  },
  basicInformationDescription: {
    defaultMessage: 'Description',
    id: 'app.containers.ShiftScheduleRunDetailPage.basicInformationDescription',
  },
  basicInformationName: {
    defaultMessage: 'Name',
    id: 'app.containers.ShiftScheduleRunDetailPage.basicInformationName',
  },
  calculated: {
    defaultMessage: 'Calculated',
    id: 'app.containers.ShiftScheduleRunDetailPage.calculated',
  },
  calculatedBy: {
    defaultMessage: 'Calculated By',
    id: 'app.containers.ShiftScheduleRunDetailPage.calculatedBy',
  },
  confirmDirty: {
    defaultMessage: 'There are unsaved changes at this schedule. Do you want to leave the page without saving them?',
    id: 'app.containers.ShiftScheduleRunDetailPage.confirmDirty',
  },
  created: {
    defaultMessage: 'Created',
    id: 'app.containers.ShiftScheduleRunDetailPage.created',
  },
  createdBy: {
    defaultMessage: 'Created By',
    id: 'app.containers.ShiftScheduleRunDetailPage.createdBy',
  },
  deleteConfirmation: {
    defaultMessage: 'Are you sure? You are deleting schedule run - {name}',
    id: 'app.containers.ShiftScheduleRunDetailPage.deleteConfirmation',
  },
  effort: {
    defaultMessage: 'Effort Overview',
    id: 'app.containers.ShiftScheduleRunDetailPage.effort',
  },
  employeePreferencePenalty: {
    defaultMessage: 'Employee preference penalty',
    id: 'app.containers.ShiftScheduleRunDetailPage.parametersValues.employeePreferencePenalty',
  },
  endDate: {
    defaultMessage: 'End Date',
    id: 'app.containers.ShiftScheduleRunDetailPage.endDate',
  },
  facilities: {
    defaultMessage: 'Facilities',
    id: 'app.containers.ShiftScheduleDetailPage.basicInformationFacilities',
  },
  from: {
    defaultMessage: 'From',
    id: 'app.containers.ShiftScheduleRunDetailPage.from',
  },
  header: {
    defaultMessage: 'Run - {name}',
    id: 'app.containers.ShiftScheduleRunDetailPage.header',
  },
  copyHeader: {
    defaultMessage: 'Copy Run - {name}',
    id: 'app.containers.ShiftScheduleRunDetailPage.copyHeader',
  },

  maxNumberOfJobsPerShift: {
    defaultMessage: 'Max number of jobs per shift',
    id: 'app.containers.ShiftScheduleRunDetailPage.parametersValues.maxNumberOfJobsPerShift',
  },
  minJobSegmentLength: {
    defaultMessage: 'Minimum job segment length',
    id: 'app.containers.ShiftScheduleRunDetailPage.parametersValues.minJobSegmentLength',
  },
  minutes: {
    defaultMessage: 'minutes',
    id: 'app.containers.ShiftScheduleRunDetailPage.parametersValues.minutes',
  },
  name: {
    defaultMessage: 'Name',
    id: 'app.containers.ShiftScheduleRunDetailPage.name',
  },
  nameRequired: {
    defaultMessage: 'Name is required',
    id: 'app.containers.ShiftScheduleRunDetailPage.nameRequired',
  },
  parameterSet: {
    defaultMessage: 'Parameter Set',
    id: 'app.containers.ShiftScheduleRunDetailPage.parameterSet',
  },
  parameterSetRequired: {
    defaultMessage: 'Parameter Set is required',
    id: 'app.containers.ShiftScheduleRunDetailPage.parameterSetRequired',
  },
  parametersTableHeader: {
    defaultMessage: 'Parameters',
    id: 'app.containers.ShiftScheduleRunDetailPage.parametersValues.parametersTableHeader',
  },
  pinkActivities: {
    defaultMessage:
      'Some Activities are not defined in SmartShift.\nAre you sure that you want to send data to calculation?',
    id: 'app.containers.ShiftScheduleRunDetailPage.pinkActivities',
  },
  plan: {
    defaultMessage: 'Plan',
    id: 'app.containers.ShiftScheduleRunDetailPage.plan',
  },
  preferredWorkload: {
    defaultMessage: 'Workload over coverage/under coverage preferred',
    id: 'app.containers.ShiftScheduleRunDetailPage.parametersValues.preferredWorkload',
  },
  preferredWorkloadNote: {
    defaultMessage: 'We can allow 10min under/over coverage',
    id: 'app.containers.ShiftScheduleRunDetailPage.parametersValues.preferredWorkloadNote',
  },
  send: {
    defaultMessage: 'Send Anyway',
    id: 'app.containers.ShiftScheduleRunDetailPage.send',
  },
  sendDirectly: {
    defaultMessage: 'Send directly to engine',
    id: 'app.containers.ShiftScheduleRunDetailPage.sendDirectly',
  },
  cancel: {
    defaultMessage: 'Cancel',
    id: 'app.containers.ShiftScheduleRunDetailPage.cancel',
  },
  sent: {
    defaultMessage: 'Sent',
    id: 'app.containers.ShiftScheduleRunDetailPage.sent',
  },
  sentBy: {
    defaultMessage: 'Sent By',
    id: 'app.containers.ShiftScheduleRunDetailPage.sentBy',
  },
  startDate: {
    defaultMessage: 'Start Date',
    id: 'app.containers.ShiftScheduleRunDetailPage.startDate',
  },
  status: {
    defaultMessage: 'Status',
    id: 'app.containers.ShiftScheduleRunDetailPage.status',
  },
  detail: {
    defaultMessage: 'Detail',
    id: 'app.containers.ShiftScheduleRunDetailPage.detail',
  },
  status_calculated: {
    defaultMessage: 'Calculated',
    id: 'app.containers.ShiftScheduleRunDetailPage.status_calculated',
  },
  status_failed: {
    defaultMessage: 'Failed',
    id: 'app.containers.ShiftScheduleRunDetailPage.status_failed',
  },
  status_new: {
    defaultMessage: 'New',
    id: 'app.containers.ShiftScheduleRunDetailPage.status_new',
  },
  status_running: {
    defaultMessage: 'Running',
    id: 'app.containers.ShiftScheduleRunDetailPage.status_running',
  },
  status_sent_to_engine: {
    defaultMessage: 'Sent to Engine',
    id: 'app.containers.ShiftScheduleRunDetailPage.status_sent_to_engine',
  },
  status_successful: {
    defaultMessage: 'Successful',
    id: 'app.containers.ShiftScheduleRunDetailPage.status_successful',
  },
  tableHeader: {
    defaultMessage: 'Schedule Runs',
    id: 'app.containers.ShiftScheduleRunDetailPage.tableHeader',
  },
  timespan: {
    defaultMessage: 'Time Span',
    id: 'app.containers.ShiftScheduleRunDetailPage.timespan',
  },
  to: {
    defaultMessage: 'To',
    id: 'app.containers.ShiftScheduleRunDetailPage.to',
  },
  warning: {
    defaultMessage: 'Warning',
    id: 'app.containers.ShiftScheduleRunDetailPage.warning',
  },

  workloadOverCoveragePenalty: {
    defaultMessage: 'Workload over coverage penalty',
    id: 'app.containers.ShiftScheduleRunDetailPage.parametersValues.workloadOverCoveragePenalty',
  },
  workloadUnderCoveragePenalty: {
    defaultMessage: 'Workload under coverage penalty',
    id: 'app.containers.ShiftScheduleRunDetailPage.parametersValues.workloadUnderCoveragePenalty',
  },
  statusHeader: {
    defaultMessage: 'Status',
    id: 'app.containers.ShiftScheduleRunDetailPage.parametersValues.statusHeader',
  },
  log: {
    defaultMessage: 'Log',
    id: 'app.containers.ShiftScheduleRunDetailPage.parametersValues.log',
  },
  run_status_calculated: {
    defaultMessage: 'Calculated',
    id: 'app.containers.ShiftScheduleRunDetailPage.parametersValues.run_status_calculated',
  },
  run_status_new: {
    defaultMessage: 'New',
    id: 'app.containers.ShiftScheduleRunDetailPage.parametersValues.run_status_new',
  },
  run_status_running: {
    defaultMessage: 'Running',
    id: 'app.containers.ShiftScheduleRunDetailPage.parametersValues.run_status_running',
  },
  run_status_sending_to_engine: {
    defaultMessage: 'Sending to engine',
    id: 'app.containers.ShiftScheduleRunDetailPage.parametersValues.run_status_sending_to_engine',
  },
  run_status_sent_to_engine: {
    defaultMessage: 'Sent to engine',
    id: 'app.containers.ShiftScheduleRunDetailPage.parametersValues.run_status_sent_to_engine',
  },
  run_status_engine_info: {
    defaultMessage: 'Engine info',
    id: 'app.containers.ShiftScheduleRunDetailPage.parametersValues.run_status_engine_info',
  },
  run_status_successful: {
    defaultMessage: 'Successful',
    id: 'app.containers.ShiftScheduleRunDetailPage.parametersValues.run_status_successful',
  },
  run_status_successful_with_warnings: {
    defaultMessage: 'Successful with warnings',
    id: 'app.containers.ShiftScheduleRunDetailPage.parametersValues.run_status_successful_with_warnings',
  },
  run_status_failed: {
    defaultMessage: 'Failed',
    id: 'app.containers.ShiftScheduleRunDetailPage.parametersValues.run_status_failed',
  },
  kpi: {
    defaultMessage: "KPI's",
    id: 'app.containers.ShiftScheduleRunDetailPage.parametersValues.kpi',
  },
  nameEW: {
    defaultMessage: 'Error Code',
    id: 'app.containers.ShiftScheduleRunDetailPage.parametersValues.nameEW',
  },
  primaryObject: {
    defaultMessage: 'Primary Object',
    id: 'app.containers.ShiftScheduleRunDetailPage.parametersValues.primaryObject',
  },
  description: {
    defaultMessage: 'Description',
    id: 'app.containers.ShiftScheduleRunDetailPage.parametersValues.description',
  },
  secondaryObject: {
    defaultMessage: 'Secondary Object',
    id: 'app.containers.ShiftScheduleRunDetailPage.parametersValues.secondaryObject',
  },
  dateFrom: {
    defaultMessage: 'Date From',
    id: 'app.containers.ShiftScheduleRunDetailPage.parametersValues.dateFrom',
  },
  dateTo: {
    defaultMessage: 'Date To',
    id: 'app.containers.ShiftScheduleRunDetailPage.parametersValues.dateTo',
  },
  howToFix:{
    defaultMessage: 'How to Fix',
    id: 'app.containers.ShiftScheduleRunDetailPage.parametersValues.howToFix',
  }
});
