import React from 'react';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import Select from 'components/StyledSelect';
import Table from 'components/Table';

import { YES_NO_MAPPINGS } from './constants';
import messages from './messages';
import { extractValues, lookupKey, lookupValue } from './utils';

const gridParametersRow = {};

const rowHeight = () => 49;

const FRACTION_DIGITS_VALIDATION = 3;

const isNumeric = num => /^[0-9]{1,11}(?:\.[0-9]{0,3})?$/.test(num);

interface GroupParametersValuesTableProps {
  isEdit: boolean;
}

/* eslint-disable react/prefer-stateless-function */
class GroupParametersValuesTable extends React.PureComponent<GroupParametersValuesTableProps> {
  // eslint-disable-next-line react/state-in-constructor
  state = {
    parametersValues: [],
  };

  gridApi = null;

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.parametersValues, this.props.parametersValues)) {
      const { isEdit } = this.props;
      const colDefs = this.getColumnDefs(isEdit);
      if (this.gridApi) {
        this.gridApi.api.setColumnDefs(colDefs);
        this.gridApi.api.redrawRows();
      }
    }
  }

  getParametersValues = () => this.state.parametersValues;

  updateParametersValues = (name, parametersValues) => {
    this.setState({ parametersValues });
  };

  gridReady = api => {
    this.gridApi = api;
  };

  getColumnDefs = editable => [
    {
      colId: 'message',
      field: 'message',
      width: 270,
    },
    {
      cellEditorSelector(params) {
        if (params.data.valueType === 'BOOLEAN' || (params.data.choices && params.data.choices.length)) {
          return {
            component: 'agRichSelectCellEditor',
          };
        }
        return 'numericCellEditor';
      },
      cellEditorParams: params => ({
        values:
          params.data.choices && params.data.choices.length
            ? params.data.choices.map(c => c.second || c.first)
            : extractValues(YES_NO_MAPPINGS),
      }),
      valueFormatter(params) {
        if (params.data.valueType === 'BOOLEAN') {
          // convert code to value
          return lookupValue(YES_NO_MAPPINGS, params.value);
        }
        return null;
      },
      valueParser(params) {
        if (params.data.valueType === 'BOOLEAN') {
          // convert value to code
          return lookupKey(YES_NO_MAPPINGS, params.newValue);
        }
        return params.newValue;
      },
      colId: 'value',
      editable,
      field: 'value',
      cellRendererFramework: params => {
        if (params.data.valueType === 'BOOLEAN' || (params.data.choices && params.data.choices.length)) {
          if (this.props.isEdit) {
            return (
              <div className="ag-rich-select" style={{ width: '140px' }}>
                <div className="ag-rich-select-value">{params.value}</div>
              </div>
            );
          }
        }
        return <>{params.value}</>;
      },
      valueSetter: params => {
        if (params.data.choices && params.data.choices.length) {
          params.data.choices.forEach(c => {
            if ((c.second || c.first) === params.newValue) {
              this.props.setFieldValue(params.data, c.first);
            }
          });
          return true;
        }
        if (!isNumeric(params.newValue)) {
          return false;
        }
        const number = Number(params.newValue);
        const maxPercentage = params.data.message === 'Transfer Cost' ? 1000 : 100;
        if (params.data.unit && params.data.unit === '%' && (number < 0 || number > maxPercentage)) {
          return false;
        }
        if (
          params.data.maxValue !== undefined &&
          params.data.maxValue !== null &&
          (!Number.isInteger(number) || number > Number(params.data.maxValue))
        ) {
          return false;
        }
        if (
          params.data.minValue !== undefined &&
          params.data.minValue !== null &&
          (!Number.isInteger(number) || number < Number(params.data.minValue))
        ) {
          return false;
        }

        this.props.setFieldValue(params.data, params.newValue);
        return true;
      },
      valueGetter: params => {
        if (params.data.valueType === 'BOOLEAN') {
          return params.data.value === 1 ? 'Yes' : 'No';
        }
        if (params.data.choices && params.data.choices.length) {
          let value = '';
          params.data.choices.forEach(c => {
            if (c.first === params.data.value) {
              value = c.second || c.first;
            }
          });
          return value;
        }
        return params.data.unit && params.data.unit === '%'
          ? Number(params.data.value).toFixed(FRACTION_DIGITS_VALIDATION)
          : params.data.value;
      },
      width: 150,
    },
    {
      colId: 'unit',
      editable: false,
      field: 'unit',
      width: 78,
    },
  ];

  render() {
    const { isEdit } = this.props;
    return (
      <>
        <Table
          key={`group-parameters-values-table-editable-${isEdit}`}
          messages={messages}
          pagination={false}
          singleClickEdit
          columnDefs={this.getColumnDefs(isEdit)}
          rowData={this.props.parametersValues}
          rowStyle={gridParametersRow}
          onGridReady={this.gridReady}
          getRowHeight={rowHeight}
          headerHeight={0}
          domLayout="autoHeight"
          editable={isEdit}
        />
      </>
    );
  }
}

GroupParametersValuesTable.propTypes = {
  parametersValues: PropTypes.array,
  setFieldValue: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({});

const withConnect = connect(mapStateToProps);

export default injectIntl(withConnect(GroupParametersValuesTable));
