import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { connect as formikConnect, FormikProps } from 'formik';
import { InjectedIntl } from 'react-intl';
import Select from 'components/StyledSelect';
import styled from 'styled-components';

import { formatDateToApiFormat } from 'utils/api';
import { formatDate, generateDays, parseDate } from 'utils/dateTime';
import { useEffectDeepCompare } from 'utils/utils';
import { ApiSmartProdRunDTO } from 'types/drep-backend.d';

import messages from './messages';
import { ForecastForm } from './types';

const Checkbox = styled.input`
  margin-right: 5px;
  margin-left: 0;
`;

const DateValueSelect = ({ placeholder, options, selectedOptions, forecastEdit, disabled, onChange }) => {
  const formatOptionLabel = (option, { selectValue }) => {
    const { value, label } = option;
    return (
      <div style={{ display: 'flex' }}>
        <Checkbox
          disabled={!forecastEdit || disabled}
          type="checkbox"
          readOnly
          checked={selectValue.map(v => v.value).includes(value)}
        />
        <div>{label}</div>
      </div>
    );
  };

  const valueInvertingOnChange = newOptions => {
    onChange(options.filter(o => !newOptions.map(no => no.value).includes(o.value)));
  };

  const styles = {
    option: (provided, state) => ({
      ...provided,
      color: 'black',
      backgroundColor: state.isSelected ? 'none' : 'none',
    }),
    menu: provided => ({
      ...provided,
      width: 'auto',
    }),
    menuList: provided => ({
      ...provided,
      maxHeight: 240,
    }),
  };

  return (
    <Select
      placeholder={placeholder}
      controlShouldRenderValue={false}
      isMulti
      hideSelectedOptions={false}
      isClearable={false}
      closeMenuOnSelect={false}
      formatOptionLabel={formatOptionLabel}
      styles={styles}
      options={options}
      value={options.filter(o => selectedOptions.map(s => s.value).includes(o.value))}
      onChange={!forecastEdit || disabled ? () => {} : valueInvertingOnChange}
    />
  );
};

type Props = {
  intl?: InjectedIntl;
  formik?: FormikProps<ForecastForm>;
  dataType: 'dataSourceDates' | 'forecastDates';
  data: any;
  forecastEdit: boolean;
};

const defaultOptions = {
  monthsOptions: [],
  weekNumberOptions: [],
  weekdayOptions: [],
  yearOptions: [],
  datesOptions: [],
};

const TimeTransformationRunTableDateRangePicker: React.FC<Props> = ({ intl, formik, dataType, data, forecastEdit }) => {
  const dateValues = formik.values.timeTransformationRuns[data.id][dataType];
  const startDate = parseDate(dateValues.startDate);
  const endDate = parseDate(dateValues.endDate);

  const [days, setDays] = useState([]);
  const [options, setOptions] = useState(defaultOptions);
  const [filteredOptions, setFilteredOptions] = useState(defaultOptions);
  const [selectedOptions, setSelectedOptions] = useState(defaultOptions);

  useEffect(() => {
    setDays(startDate && endDate ? generateDays(startDate, endDate) : []);
  }, [dateValues.startDate, dateValues.endDate]);

  useEffect(() => {
    const sortByKey = (a, b) => {
      const x = a.sort;
      const y = b.sort;
      if (x < y) {
        return -1;
      }
      return x > y ? 1 : 0;
    };

    const dataSets = {
      monthLong: {},
      weekNumber: {},
      weekdayLong: {},
      year: {},
      dates: {},
    };
    days.reverse().forEach(date => {
      const monthKey = `${date.monthLong} (${date.year})`;
      const weekKey = `${date.weekNumber} (${date.year})`;
      dataSets.monthLong[monthKey] = {
        value: date,
        label: monthKey,
        year: date.year,
        sort: `${date.year} ${date.month}`,
      };
      dataSets.weekNumber[weekKey] = {
        label: weekKey,
        value: date,
        year: date.year,
        month: date.month,
        sort: date.ts,
      };
      dataSets.weekdayLong[date.weekdayLong] = {
        label: date.weekdayLong,
        value: date.weekday,
        year: date.year,
        weekNumber: date.weekNumber,
        sort: date.weekday,
      };
      dataSets.year[date.year] = { value: date, label: `${date.year}`, sort: date.year };
    });
    const allOptions = {
      monthsOptions: Object.values(dataSets.monthLong).sort(sortByKey),
      weekNumberOptions: Object.values(dataSets.weekNumber).sort(sortByKey),
      weekdayOptions: Object.values(dataSets.weekdayLong).sort(sortByKey),
      yearOptions: Object.values(dataSets.year).sort(sortByKey),
      datesOptions: days
        .map(date => ({
          label: `${formatDate(date)} (${date.weekdayShort})`,
          value: date,
          sort: date.ts,
        }))
        .sort(sortByKey),
    };
    setOptions(allOptions);
  }, [days]);

  const handleValueChange = attribute => newOptions => {
    if (attribute === 'yearOptions') {
      formik.setFieldValue(
        `timeTransformationRuns[${data.id}][${dataType}].years`,
        newOptions.map(o => o.value).map(formatDateToApiFormat),
      );
    } else if (attribute === 'weekNumberOptions') {
      formik.setFieldValue(
        `timeTransformationRuns[${data.id}][${dataType}].weeks`,
        newOptions.map(o => o.value).map(formatDateToApiFormat),
      );
    } else if (attribute === 'monthsOptions') {
      formik.setFieldValue(
        `timeTransformationRuns[${data.id}][${dataType}].months`,
        newOptions.map(o => o.value).map(formatDateToApiFormat),
      );
    } else if (attribute === 'weekdayOptions') {
      formik.setFieldValue(
        `timeTransformationRuns[${data.id}][${dataType}].daysOfWeek`,
        newOptions.map(o => o.value),
      );
    } else if (attribute === 'datesOptions') {
      formik.setFieldValue(
        `timeTransformationRuns[${data.id}][${dataType}].dates`,
        newOptions.map(o => o.value).map(formatDateToApiFormat),
      );
    }
  };

  useEffectDeepCompare(() => {
    if (options) {
      const selectedOptionsKeys = {
        monthsOptions: dateValues.months.reduce((result, value) => {
          const date = parseDate(value);
          result[`${date.monthLong} (${date.year})`] = true;
          return result;
        }, {}),
        weekNumberOptions: dateValues.weeks.reduce((result, value) => {
          const date = parseDate(value);
          result[`${date.weekNumber} (${date.year})`] = true;
          return result;
        }, {}),
        weekdayOptions: dateValues.daysOfWeek.reduce((result, value) => {
          result[value] = true;
          return result;
        }, {}),
        yearOptions: dateValues.years.reduce((result, value) => {
          const date = parseDate(value);
          result[date.year] = true;
          return result;
        }, {}),
        datesOptions: dateValues.dates.reduce((result, value) => {
          const date = parseDate(value);
          result[formatDate(date)] = true;
          return result;
        }, {}),
      };

      setFilteredOptions({
        yearOptions: options.yearOptions,
        monthsOptions: options.monthsOptions.filter(option => !selectedOptionsKeys.yearOptions[`${option.year}`]),
        weekNumberOptions: options.weekNumberOptions.filter(
          option => !selectedOptionsKeys.yearOptions[`${option.year}`],
        ),
        weekdayOptions: options.weekdayOptions,
        datesOptions: days
          .filter(
            day =>
              !selectedOptionsKeys.monthsOptions[`${day.monthLong} (${day.year})`] &&
              !selectedOptionsKeys.weekNumberOptions[`${day.weekNumber} (${day.year})`] &&
              !selectedOptionsKeys.weekdayOptions[`${day.weekday}`] &&
              !selectedOptionsKeys.yearOptions[`${day.year}`],
          )
          .map(day => ({ label: `${formatDate(day)} (${day.weekdayShort})`, value: day })),
      });

      setSelectedOptions({
        yearOptions: options.yearOptions.filter(option => !selectedOptionsKeys.yearOptions[`${option.value.year}`]),
        monthsOptions: options.monthsOptions.filter(
          option => !selectedOptionsKeys.monthsOptions[`${option.value.monthLong} (${option.value.year})`],
        ),
        weekNumberOptions: options.weekNumberOptions.filter(
          option => !selectedOptionsKeys.weekNumberOptions[`${option.value.weekNumber} (${option.value.year})`],
        ),
        weekdayOptions: options.weekdayOptions.filter(option => !selectedOptionsKeys.weekdayOptions[`${option.value}`]),
        datesOptions: options.datesOptions.filter(option => !selectedOptionsKeys.datesOptions[formatDate(option.value)]),
      });
    }
  }, [dateValues, options, days]);

  return (
    <Grid container spacing={1}>
      <Grid item xs>
        <DateValueSelect
          placeholder={intl.formatMessage(messages.smartProdRunsPlaceholderYear)}
          forecastEdit={forecastEdit}
          options={filteredOptions.yearOptions}
          selectedOptions={selectedOptions.yearOptions}
          onChange={handleValueChange('yearOptions')}
          disabled={false}
        />
      </Grid>
      <Grid item xs>
        <DateValueSelect
          placeholder={intl.formatMessage(messages.smartProdRunsPlaceholderMonths)}
          forecastEdit={forecastEdit}
          options={filteredOptions.monthsOptions}
          selectedOptions={selectedOptions.monthsOptions}
          onChange={handleValueChange('monthsOptions')}
          disabled={false}
        />
      </Grid>
      <Grid item xs>
        <DateValueSelect
          placeholder={intl.formatMessage(messages.smartProdRunsPlaceholderWeeks)}
          forecastEdit={forecastEdit}
          options={filteredOptions.weekNumberOptions}
          selectedOptions={selectedOptions.weekNumberOptions}
          onChange={handleValueChange('weekNumberOptions')}
          disabled={false}
        />
      </Grid>
      <Grid item xs={3}>
        <DateValueSelect
          placeholder={intl.formatMessage(messages.smartProdRunsPlaceholderDayOfWeek)}
          forecastEdit={forecastEdit}
          options={filteredOptions.weekdayOptions}
          selectedOptions={selectedOptions.weekdayOptions}
          onChange={handleValueChange('weekdayOptions')}
          disabled={false}
        />
      </Grid>
      <Grid item xs>
        <DateValueSelect
          placeholder={intl.formatMessage(messages.smartProdRunsPlaceholderDays)}
          forecastEdit={forecastEdit}
          options={filteredOptions.datesOptions}
          selectedOptions={selectedOptions.datesOptions}
          onChange={handleValueChange('datesOptions')}
          disabled={false}
        />
      </Grid>
    </Grid>
  );
};
export default formikConnect(TimeTransformationRunTableDateRangePicker);
