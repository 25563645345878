import React from 'react';
import styled from 'styled-components';
import { ExportButton, OverrideHelper, SentToKronos } from 'components/IconButton';

export const SectionTitle = styled.div`
  font-size: ${props => props.theme.fontSize.title};
  font-weight: 700;
  margin-bottom: 8px;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${ExportButton} {
    font-weight: normal;
    margin-left: 10px;
  }
  ${SentToKronos} {
    font-weight: normal;
    margin-left: auto;
  }
  ${OverrideHelper} {
    font-weight: normal;
    margin-left: 10px;
  }
`;

export const Wraptable = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  height: ${props => props.aproxHeight}px;
`;

export const LoadingWrap = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  background-color: rgba(247, 249, 251, 0.42);
  > span {
    padding: 10px;
    border-style: solid;
    border-width: 1px;
    border-color: ${props => props.theme.color.grey4};
    background-color: white;
    min-width: 200px;
    text-align: center;
  }
`;
