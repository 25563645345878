// @flow

import React from 'react';
import NamedSection from 'components/NamedSection';
import NamedFourSection from 'components/NamedSection/NameFourSection';
import { PlanDetailBasicInfo } from 'components/PlanDetailBasicInfo';
import { PlanDetailBasicSettings } from 'components/PlanDetailBasicSettings';
import { EditableList } from 'components/CdmEditableList';
import WorkingHours from 'components/WorkingHours';
import { FacilityItem, FacilityItemNoUnits } from 'components/CdmEditableList/FacilityItem';
import { ManagementItemNoUnits } from 'components/CdmEditableList/ManagementItem';
import { DetailPageSectionsWrapper, DetailPageFourSectionsWrapper } from 'components/DetailPageShared';
import { injectIntl } from 'react-intl';
import { getIn } from 'formik';
import { T_TYPE } from 'utils/constants';
import DepartmentItem from 'components/CdmEditableList/DepartmentItem';
import messages from './messages';

type Props = {
  editMode: boolean,
  formik?: Object,
};

class BasicSettings extends React.PureComponent<Props> {
  render() {
    const {
      formik = {},
      intl: { formatMessage },
      planDetailSchedulesData,
    } = this.props;

    const tTypeValue = formik.values && getIn(formik.values, 'planningParameters.transformationType', T_TYPE.WZP);
    const isShift = tTypeValue === T_TYPE.SHIFT;

    return (
      <>
      <DetailPageSectionsWrapper>
        <NamedSection message={messages.basicInfo}>
          <PlanDetailBasicInfo formik={formik} />
        </NamedSection>
        <NamedSection message={messages.basicSettings}>
          <PlanDetailBasicSettings planDetailSchedulesData={planDetailSchedulesData}/>
        </NamedSection>
        <NamedSection message={isShift ? messages.shifts : messages.workingHours}>
          <WorkingHours values={formik.values} formatMessage={formatMessage} />
        </NamedSection>
        </DetailPageSectionsWrapper>
        <DetailPageFourSectionsWrapper>
        <NamedFourSection message={messages.facilities}>
          <EditableList field="planningArea.facilities" isEdit={false} editComponent={FacilityItemNoUnits} />
        </NamedFourSection>
        <NamedFourSection message={messages.managementUnits}>
          <EditableList field="planningArea.managementUnits" isEdit={false} editComponent={ManagementItemNoUnits}/>
        </NamedFourSection>
        <NamedFourSection message={messages.customers}>
          <EditableList field="planningArea.customers" isEdit={false} />
        </NamedFourSection>
        <NamedFourSection message={messages.department}>
          <EditableList
            field="planningParameters.departments"
            isEdit={false}
            editComponent={DepartmentItem}
            delegateNewItemRender
          />
        </NamedFourSection>
      </DetailPageFourSectionsWrapper>
      </>
    );
  }
}

export default injectIntl(BasicSettings);
