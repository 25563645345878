import {FormikProps} from "formik";
import EnchantedMap from "utils/enchantedMap";
import {UNSET_COST} from "../constants";
import {TransferCostEntry} from "../types";

function parseCostValue(params): number | string {
  if (params.newValue === UNSET_COST) {
    return params.newValue;
  }

  if (params.newValue && !Number.isNaN(params.newValue)) {
    const num = parseInt(params.newValue, 0);
    if (num >= 0) {
      return num;
    }
  }
  return params.oldValue;
}

export function setComment(formik: FormikProps<any>, path: string, params): boolean {
  const targetMap = formik.values[path] as EnchantedMap<number, TransferCostEntry>;
  const cost = targetMap.setIfAbsent(params.data.id, params.data);
  cost.comment = params.newValue;
  formik.setFieldValue("lastUpdatedRow", params.node);
  formik.setFieldValue("changesCounter", (formik.values.changesCounter || 0) + 1);

  return true;
}

export function setCostValue(formik: FormikProps<any>, path: string, params, skipCounter = false): boolean {
  const newVal = parseCostValue(params);
  const field = params.colDef.field;
  const disabledField = field.endsWith("From") ? "disabledFrom" : "disabledTo";
  if (newVal !== params.oldValue || params.data[disabledField]) {
    const targetMap = formik.values[path] as EnchantedMap<number, TransferCostEntry>;
    const cost = targetMap.setIfAbsent(params.data.id, params.data);

    if (newVal !== UNSET_COST) {
      cost[params.colDef.field] = newVal;
    }
    cost[disabledField] = newVal === UNSET_COST;
    formik.setFieldValue("lastUpdatedRow", params.node);
    if (!skipCounter) {
      formik.setFieldValue("changesCounter", (formik.values.changesCounter || 0) + 1);
    }
  }

  return true;
}

/**
 * To keep ID field consistent regardless of real ids order (from vs to, might be "1 -> 2", or "2 -> 1"),
 * use a lesser value as a first one when generating a composite ID (i.e. always resulting "1_2").
 */
const lesserCostId = (c: TransferCostEntry, field: string) => {
  if (c[`${field}From`] < c[`${field}To`]) {
    return `${c[`${field}From`]}_${c[`${field}To`]}`;
  } else {
    return `${c[`${field}To`]}_${c[`${field}From`]}`;
  }
};

export const getFacilityCostId = (c: TransferCostEntry) => lesserCostId(c, "facility");

export const getDepartmentCostId = (c: TransferCostEntry) =>
  lesserCostId(c, "facility") + lesserCostId(c, "department");

/**
 * Get 'field' value of the parent facility transfer cost. Due to from/to ids can be in any order,
 * corresponding parent facility can be either as "facility from" or "facility to".
 *
 * For instance: from 'facility 1 department 1' to 'facility 2 department 2' may have parent
 * `facility 1 to facility 2` or `facility 2 to facility 1`
 *
 * @param field - target field name
 * @param transferCost - current transfer cost entry
 * @param suffix - whether current transfer cost entry is From/to
 */
export const getInFacility = (field, transferCost, suffix) => {
  const facilityTransferCost = transferCost.parentFacility;
  const facilitySuffix = transferCost.facilityFrom === facilityTransferCost?.facilityFrom ?
    suffix : reversedSuffix(suffix);

  return facilityTransferCost?.[field + facilitySuffix];
};

/**
 * Get 'field' value of the parent department transfer cost. Due to from/to ids can be in any order,
 * corresponding parent department can be either as "department from" or "department to".
 *
 * For instance: from 'facility 1 department 1' to 'facility 2 department 2' may have parent
 * `facility 1 to facility 2` or `facility 2 to facility 1`
 *
 * @param field - target field name
 * @param transferCost - current transfer cost entry
 * @param suffix - whether current transfer cost entry is From/to
 */
export const getInDepartment = (field, transferCost, suffix) => {
  const departmentTransferCost = transferCost.parentDepartment;
  const departmentSuffix = transferCost?.departmentFrom === departmentTransferCost?.departmentFrom ?
    suffix : reversedSuffix(suffix);

  return departmentTransferCost?.[field + departmentSuffix];
};

export const reversedSuffix = (suffix) => suffix === "From" ? "To" : "From";
