import React from "react";
import DialogDHL from 'components/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import { FormattedMessage } from "react-intl";

import messages from './messages';
import ActivityCheck from "./ActivityCheck";
import Button from 'components/Button';
import './style.css';

const DialogButton = ({ children, onClick, ...props }) => (
    <Button color="primary" variant="contained" onClick={onClick} {...props}>
        {children}
    </Button>
);

const DialogActivityCheck = ({ open, closeDialog, intl, handleAdd, addFlag, id, planId }) => {
    return (
        <DialogDHL
            open={!!open}
            maxWidth="lg"
            headerLabel={messages.activityCheckHeader}
            CustomButtons={
                <React.Fragment key={1}>
                    <DialogButton onClick={() => closeDialog()}>
                        <FormattedMessage {...messages.cancelButton} />
                    </DialogButton>
                    {
                        addFlag &&
                        <DialogButton onClick={() => handleAdd()}>
                            <FormattedMessage {...messages.confirmButton} />
                        </DialogButton>
                    }
                </React.Fragment>
            }
        >
            <div style={{overflow:'visible'}}>
            <ActivityCheck intl={intl} id={id} addFlag={addFlag} planId={planId}/>
            </div>
        </DialogDHL>
    )
}

export default DialogActivityCheck;