import React, { useState } from 'react';
import { connect as formikConnect } from 'formik';
import { injectIntl } from 'react-intl';
import { connect, useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators, compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';

import { ComponentWithDirtyCheck } from 'components/ButtonWithDirtyCheck';
import DialogActivityCheck from 'containers/MasterPlanDetailPage/DialogActivityCheck';
import { DeleteDialog } from 'components/Dialog';
import IconButton, { SaveButton } from 'components/IconButton';
import { TitleFixed } from 'components/PlanningAreaDetailToolbar';
import { ButtonsGroup, ButtonWrap } from 'components/ResultToolBar';
import UserViewToggleButton from 'components/ToggleButton/UserViewToggleButton';
import { PATHS } from 'containers/App/constants';
import { getToken } from 'containers/App/selectors';
import { storePlanEdit, switchPlanToDisplayMode, clearPlanAction } from 'containers/MasterPlanDetailPage/actions';
import { selectGuiState } from 'containers/MasterPlanDetailPage/selectors';
import { makeSelectTabsDomain } from 'containers/Tabs/selectors';
import { withUrl } from 'utils/api';
import { fetchData } from 'utils/reduxApi';
import { withScrollWatch } from 'utils/scroll';
import withSecurity, { PERMISSIONS } from 'utils/security';
import { dataToSearch } from 'utils/url';

import messages from './messages';

const ToolbarWrap = styled(ButtonWrap)`
  margin: 10px 10px 2px;
  padding: 10px;

  ${props =>
    props.fixed &&
    `
      top: 0px;
      z-index: ${props.theme.zIndex.overlayToolbar};
      margin: 0px;
      padding: 10px;
    `};

  :first-child {
    position: ${props => (props.fixed ? 'fixed' : 'absolute')};
    width: ${props => (props.fixed ? 'calc(100% - 75px)' : 'calc(100% - 30px)')};
  }
`;

const SaveAll = formikConnect(props => (
  <SaveButton
    type="submit"
    disabled={!props.formik.dirty}
    onClick={() => {
      setTimeout(props.formik.submitForm, 100);
    }}
  />
));

type Props = {
  plan: any,
  fixed: boolean,
  guiState: any,
  history: any,
  hasPerm: Function,
  switchPlanToDisplayMode: Function,
  storeDetailPageEdit: Function,
  loadPlanAction: Function,
  clearPlanAction: Function,
};

const MasterPlanDetailToolbar: React.FC<Props> = props => {
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [activityCheckOpen, setActivityDialogOpen] = useState(false);
  const token = useSelector(getToken);
  const dispatch = useDispatch();

  const calculatePermissions = () => {
    const { plan } = props;
    const guiState = props.guiState.toJS();
    const allButtons = !(guiState.isNew || guiState.copy);
    const planDeleted = plan && plan.deleted;
    const paDeleted = plan && plan.planningArea && plan.planningArea.deleted;
    const canCopy = !planDeleted && !paDeleted;
    const canEdit = props.hasPerm(PERMISSIONS.VIEW_MASTER_PLAN) && !planDeleted;
    const canUpload = canEdit;
    return { allButtons, canCopy, canEdit, canUpload, plan, planDeleted };
  };
  const { canEdit, planDeleted } = calculatePermissions();

  const disableMasterPlan = () => {
    if (planDeleted) {
      fetchData(withUrl(`/masterPlan/${props.plan.id}/undelete`).post().andToken(token), dispatch).then(response => {
        props.loadPlanAction({ id: props.plan.id, edit: false });
      });
    } else {
      fetchData(withUrl(`/masterPlan/${props.plan.id}/delete`).post().andToken(token), dispatch).then(() => {
        props.loadPlanAction({ id: props.plan.id, edit: false });
      });
    }
  };

  const handleOpenDeleteDialog = () => {
    setDeleteDialogOpen(true);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
  };

  const handleOpenActivityCheck = () => {
    setActivityDialogOpen(true);
  }

  const handleCloseActivityCheck = () => {
    setActivityDialogOpen(false);
  }

  const handleBack = () => {
    props.clearPlanAction()
    props.history.push(PATHS.masterPlanListNoReset.replace(':reset', false),{disabled: planDeleted})
  }
  const masterPlanId = props?.plan?.id;
  return (
    <ToolbarWrap fixed={props.fixed}>
      <IconButton
        id="plan-detail-back"
        icon="arrow-circle-left"
        tooltip={messages.back}
        onClick={handleBack}
      />
      <TitleFixed {...props} />

      <ButtonsGroup>
        <IconButton
          icon="vials"
          id="plan-detail-vials"
          tooltip={messages.result}
          disabled = {!(props?.plan?.plans?.filter((p)=> p.validFrom && p.validTo)?.length)}
          onClick={() =>
            props.history.push({
              pathname: PATHS.masterplanResult,
              search: dataToSearch({ masterPlanId }),
            })
          }
        />
        <IconButton
          icon="boxes"
          id="plan-detail-interfaces"
          tooltip={messages.interfaces}
          tooltipHelp={messages.interfacesTooltipText}
          onClick={() => props.history.push(PATHS.masterPlanInterfaces.replace(':id', props?.plan?.id))}
        />
        <IconButton message={messages.activityCheck} onClick={handleOpenActivityCheck} />
        <ComponentWithDirtyCheck actionHandler={handleOpenDeleteDialog}>
          {({ onClickHandler }) => (
            <IconButton message={planDeleted ? messages.enable : messages.disable} onClick={onClickHandler} />
          )}
        </ComponentWithDirtyCheck>
        <SaveAll />
        {canEdit &&
          (props.guiState.get('edit') === true && canEdit ? (
            <ComponentWithDirtyCheck actionHandler={props.switchPlanToDisplayMode}>
              {({ onClickHandler }) => (
                <IconButton
                  icon="eye"
                  id="plan-detail-edit"
                  tooltip={messages.display}
                  onClick={e => {
                    props.history.replace({
                      search: dataToSearch({ isEdit: false }),
                    });
                    onClickHandler(e);
                  }}
                />
              )}
            </ComponentWithDirtyCheck>
          ) : (
            <IconButton
              icon="edit"
              id="plan-detail-edit"
              tooltip={messages.edit}
              onClick={() => {
                props.history.replace({
                  search: dataToSearch({ isEdit: true }),
                });
                props.storeDetailPageEdit(true);
              }}
            />
          ))}
        <UserViewToggleButton />
      </ButtonsGroup>
      <DeleteDialog
        deleteDialogOpen={deleteDialogOpen}
        deleteDialogCallback={disableMasterPlan}
        closeDeleteDialog={handleCloseDeleteDialog}
        deleteDialogValues={{ planToDisable: props.plan.name }}
        confirmLabel={planDeleted ? messages.enableConfirmButton : messages.disableConfirmButton}
        title={planDeleted ? messages.enableTitle : messages.disableTitle}
      />
      <DialogActivityCheck
        open={activityCheckOpen}
        closeDialog={handleCloseActivityCheck}
        intl={props.intl}
        id={props?.plan?.id}
      />
    </ToolbarWrap>
  );
};

const mapStateToProps = createStructuredSelector({
  guiState: selectGuiState,
  tabsDomain: makeSelectTabsDomain(),
});

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators(
      {
        storeDetailPageEdit: storePlanEdit,
        switchPlanToDisplayMode,
        clearPlanAction,
      },
      dispatch,
    ),
    dispatch,
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withRouter,
  withConnect,
  withSecurity(),
  withScrollWatch(100),
  injectIntl,
)(MasterPlanDetailToolbar);
