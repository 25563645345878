import React  from "react";
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import messages from './messages';

const ColorCodeWrap =  styled.div`
margin: 0px 10px 5px 10px;
padding: 10px 0px;
background-color: ${props => props.theme.color.greyDlgBg};
box-shadow: ${props => props.theme.shadow.pageBox};
> * {
  display: flex;
  > * {
    margin: 0 10px;
  }
}
`;

const RedColorLabelSpan = styled.span`
color:#D40511;
font-size:14px;
padding-bottom:3px;
`;

const OrangeColorLabelSpan = styled.span`
color:#ffa500;
font-size:14px;
padding-bottom:3px;
`;

const BlackColorLabelSpan = styled.span`
color:#000;
font-size:14px;
`;

export default function MasterPlanColorCodeDetails() {
    return (
      <ColorCodeWrap>
       <RedColorLabelSpan><FormattedMessage {...messages.redColor} /></RedColorLabelSpan> 
       <OrangeColorLabelSpan> <FormattedMessage {...messages.orangeColor} /></OrangeColorLabelSpan>
       <BlackColorLabelSpan> <FormattedMessage {...messages.blackColor} /></BlackColorLabelSpan>
      </ColorCodeWrap>
    );
  }