import { createContext, ReactNode, useEffect, useState } from 'react';
import * as React from 'react';
import { InteractionStatus } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';

import { loginRequest, msalInstance, setActiveAccount } from './azure';
import { LOG_AZURE } from './logger';

type UserContextType = {
  user?: any;
  authorized?: boolean;
};

export const UserContext = createContext<UserContextType>({});

type WithUserProps = {
  children?: ReactNode;
};

export const WithUser = ({ children }: WithUserProps) => {
  const { instance, inProgress } = useMsal();
  const [state, setState] = useState<UserContextType | null>(null);

  const initState = async () => {
    const user = {};

    setState({ authorized: true, user });
  };

  useEffect(() => {
    if (!state && inProgress === InteractionStatus.None) {
      instance
        .acquireTokenSilent({ ...loginRequest, account: instance.getAllAccounts()[0] })
        .then(() => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          instance.setActiveAccount(instance.getAllAccounts()[0]);
          setActiveAccount(instance.getAllAccounts()[0]);
          initState();
        })
        .catch(e => {
          LOG_AZURE.w("Can't aquire token in WithUser, probably not logged in. Redirect.", e);
          msalInstance.loginRedirect(loginRequest).then(response => {
            LOG_AZURE.d('Login redirect response', response);
          });
        });
    }
  }, [inProgress, state, instance]);

  return state ? <UserContext.Provider value={{ ...state }}>{children}</UserContext.Provider> : null;
};
