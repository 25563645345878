import * as React from 'react';
import { Component } from 'react';
import { FormikProps } from 'formik';
import { isEqual } from 'lodash';
import { injectIntl } from 'react-intl';
import { compose } from 'redux';
import styled from 'styled-components';

import DeleteCellRenderer from 'components/DeleteCellRenderer';
import { DeleteDialog, withDeleteDialog } from 'components/Dialog';
import { FormikText, InlineLabel, InputError } from 'components/FormikTextInput';
import { ParameterGroup } from 'components/GroupParametersValuesTable/types';
import { AddButton } from 'components/IconButton';
import Table from 'components/Table';
import { NamedEntity, ParameterSet } from 'containers/ShiftScheduleDetailPage/types';
import { calculateStepsHeight } from 'containers/ShiftScheduleRunDetailPage/ScheduleRunSteps';
import { pushToFormikArray, removeFromFormikArray } from 'utils/utils';
import { ApiScheduleDTO } from 'types/drep-backend.d';

import messages from './messages';
import ScheduleParameterSetRenderer from './scheduleParameterSetRenderer';

interface ParameterSetsProps {
  formik: FormikProps<ApiScheduleDTO>;
  intl: any;
  parameterSets: NamedEntity[];
  dispatch: any;
  sets: ParameterSet[];
  defaultScheduleParameters: ParameterGroup[];
  scheduleId: number;
  openDeleteDialog: (any1, any2, any3) => any;
  hide: boolean;
  deleteDialogOpen: boolean;
  deleteScheduleParameterSet: (payload) => any;
  isEdit: boolean;
}

const GridRow = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: 120px 250px 70px;
  margin: 0 0 12px 2px;
  column-gap: 10px;
  input {
    background-color: ${props => props.theme.color.grey5};
  }
`;

class ParameterSetsSection extends Component<ParameterSetsProps, {}> {
  public state = {
    name: null,
    nameError: false,
    selectedName: -1,
  };

  private gridApi = null;

  public shouldComponentUpdate(
    nextProps: Readonly<ParameterSetsProps>,
    nextState: Readonly<any>,
    nextContext: any,
  ): boolean {
    return (
      this.props.deleteDialogOpen !== nextProps.deleteDialogOpen ||
      this.state.selectedName !== nextState.selectedName ||
      this.state.name !== nextState.name ||
      !isEqual(this.props.formik.values.parameterSets, nextProps.formik.values.parameterSets) ||
      this.props.isEdit !== nextProps.isEdit
    );
  }

  public render() {
    const {
      intl: { formatMessage },
      formik,
      sets,
      isEdit,
    } = this.props;
    const nameSetter = event => this.setState({ name: event.target.value, nameError: false });
    const onGridReady = gridApi => (this.gridApi = gridApi);
    const addNewItem = () => {
      if (this.state.name) {
        pushToFormikArray(this.props.formik, 'parameterSets', {
          default: false,
          groups: this.props.defaultScheduleParameters,
          name: this.state.name,
        });
        this.setState({ name: '', nameError: false });
      } else {
        this.setState({ nameError: true });
      }
    };

    const onSelectionChanged = row => {
      if (!row.event.defaultPrevented && row.data.name !== this.state.selectedName) {
        this.setState({ selectedName: row.data.name });
        row.api.redrawRows();
        row.api.resetRowHeights();
      }
    };

    const deleteTextFormat = value => ({ name: value.name });
    const deleteCallback = value => removeFromFormikArray(this.props.formik, 'parameterSets', i => i === value);

    const doCollapse = e => {
      e.preventDefault();
      e.stopPropagation();
      this.setState({ selectedName: -1 }, () => {
        this.gridApi.api.redrawRows();
        this.gridApi.api.resetRowHeights();
      });
    };

    const fullWidthRendererParams = {
      deleteScheduleParameterSet: this.props.deleteScheduleParameterSet,
      doCollapse,
      formik,
      isEdit: this.props.isEdit,
      openDeleteDialog: this.props.openDeleteDialog,
      scheduleId: this.props.scheduleId,
    };

    return [
      <>
        {isEdit ? (
          // tslint:disable-next-line:jsx-wrap-multiline
          <GridRow key="newItem">
            <InlineLabel {...messages.setName} />
            <FormikText key="name">
              <input
                onChange={nameSetter}
                placeholder={this.props.intl.formatMessage(messages.setNameDefault)}
                value={this.state.name}
              />
              {this.state.nameError && <InputError>{formatMessage(messages.setNameRequired)}</InputError>}
            </FormikText>

            <AddButton
              key="add-button"
              disabled={
                this.props.formik.isSubmitting ||
                !this.props.isEdit ||
                !this.state.name ||
                this.props.sets.some(parameterSet => parameterSet.name === this.state.name)
              }
              onClick={addNewItem}
            />
          </GridRow>
        ) : (
          ''
        )}
      </>,
      <Table
        key={`parameter-set-section-table-editable${isEdit}`}
        rowSelection={null}
        columnDefs={this.getColumnDefs(isEdit)}
        rowData={sets}
        domLayout="autoHeight"
        isFullWidthCell={this.isFullWidthCell(this)}
        fullWidthCellRendererFramework={ScheduleParameterSetRenderer}
        fullWidthCellRendererParams={fullWidthRendererParams}
        getRowHeight={this.getRowHeight(this)}
        onRowClicked={onSelectionChanged}
        onGridReady={onGridReady}
        messages={messages}
        pagination={false}
      />,
      <DeleteDialog
        {...this.props}
        key="del"
        text={messages.deleteParameterSetConfirmation}
        formatText={deleteTextFormat}
        deleteDialogCallback={deleteCallback}
      />,
    ];
  }

  private getRowHeight = self => row =>
    this.isFullWidthCell(self)(row)
      ? 1270 + (row.data.plans || []).length * 28 + calculateStepsHeight(row.data.steps || [])
      : 50;

  private isFullWidthCell = self => row => row.data.name === self.state.selectedName;

  private getColumnDefs(isEdit) {
    const { formatMessage } = this.props.intl;
    return [
      {
        cellRendererFramework: row => (
          <div>
            <span style={{ backgroundPosition: '0 4px', marginRight: '10px' }} className="ag-icon ag-icon-tree-closed" />
            {row.data.name}
          </div>
        ),
        colId: 'name',
        field: 'name',
        headerName: formatMessage(messages.name),
        width: 177,
      },
      {
        cellRendererFramework: row => (
          <div>
            <span style={{ backgroundPosition: '0 4px', marginRight: '100px' }} />
          </div>
        ),
        colId: '',
        field: '',
        width: 935,
      },
      {
        cellRendererFramework: DeleteCellRenderer,
        cellRendererParams: row => ({
          hide: !isEdit || row.data.default,
          onDelete: (payload, params, event) => {
            this.props.openDeleteDialog(this.props.deleteScheduleParameterSet, payload, params.data);
            event.preventDefault();
          },
        }),
        width: 44,
      },
    ];
  }
}

export default compose(
  injectIntl,
  withDeleteDialog,
  // @ts-ignore
)(ParameterSetsSection);
