/**
 *
 * TableControlled
 *
 */
import React from 'react';
import uniqid from 'uniqid';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose, bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import hash from 'object-hash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled, { css } from 'styled-components';
import { FormattedMessage } from 'react-intl';

import injectSaga from 'utils/injectSaga';
import injectReducer from 'utils/injectReducer';
import { DAEMON } from 'utils/constants';
import Table from 'components/Table';
import Menu from 'components/Menu';
import ItemSelectable from 'components/Menu/ItemSelectable';
import { makeSelectView } from 'containers/App/selectors';
import { USER_VIEWS, SAVE_PINNED_ONLY } from 'containers/App/constants';
import saga from './saga';
import { loadConfigFromFavAction, registerTableAction, setTableConfigAction, unregisterTableAction } from './actions';
import reducer from './reducer';
import { makeSelectTableControlled } from './selectors';

const MenuLocal = styled(Menu)`
  position: absolute;
  font-size: 105%;
  top: 2px;
  right: 3px;
  background-color: ${props => props.theme.color.yellow};
  box-shadow: ${props => props.theme.shadow.button};
`;

const globalLogging = false;

/* eslint-disable react/prefer-stateless-function */
class TableControlled extends React.Component {
  componentDidMount() {
    this.props.registerTableAction(this.props.name, this.props.defaultConfig);
    const { reset } = this.props.match.params;
    // default is reset
    if (reset === 'false') {
      // we make it global for tables hopefully there no other property reset needed
    } else {
      this.props.loadConfigFromFavAction(this.props.name);
    }
  }

  componentWillUnmount() {
    this.props.unregisterTableAction(this.props.name);
  }

  changeColumns = name => {
    this.log('Change columns name ', name);
    const tableConfig = this.extractTableConfig();
    const col = tableConfig.columnState.find(item => item.colId === name);
    col.hide = !col.hide;
    this.setTableConfig(tableConfig);
  };

  extractTableConfig = () => {
    if (this.columnApi && this.gridApi) {
      const columnState = this.columnApi.getColumnState();
      const sortModel = this.gridApi.getSortModel();
      const filterModel = this.gridApi.getFilterModel();
      const paginationModel = { currentPage: this.gridApi.paginationGetCurrentPage() };
      return { columnState, sortModel, filterModel, paginationModel };
    }
    return undefined;
  };

  log = (...args) => {
    if (this.props.logging === true || globalLogging) {
      console.log(...args);
    }
  };

  setTableConfig = tableConfig => {
    this.log(
      `TableControlled.setTableConfig: Setting config of controlled table ${this.props.name}. The config is`,
      tableConfig,
    );
    if (this.columnApi && this.gridApi && tableConfig) {
      const { columnState, sortModel, filterModel, paginationModel } = tableConfig;
      if (columnState) {
        this.log(
          `TableControlled.setTableConfig: Current column state ${this.props.name}`,
          this.columnApi.getColumnState(),
        );
        this.log(`TableControlled.setTableConfig: Setting new column state ${this.props.name}`, columnState);
        this.columnApi.setColumnState(columnState);
      }
      if (sortModel) {
        this.gridApi.setSortModel(sortModel);
      }
      if (filterModel) {
        const settedModel = this.gridApi.getFilterModel();
        const model = { ...filterModel, ...settedModel };
        this.gridApi.setFilterModel(model);
      }
      if (paginationModel && paginationModel.currentPage !== undefined) {
        this.gridApi.paginationGoToPage(paginationModel.currentPage);
      }
    }
  };

  getCOGMenuItems = () => {
    if (this.props.config === undefined) {
      return undefined;
    }
    const { columnState } = this.props.config;
    if (columnState) {
      return columnState
        .map(item => {
          if (item.pinned) {
            return undefined;
          }
          if (this.props.messages[item.colId] === undefined) {
            console.error(`Doesn't exist in ${this.props.name} messages: '${item.colId}'.`);
            return undefined;
          }
          return (
            <ItemSelectable
              key={`${item.colId}_${uniqid()}`}
              isSelected={!item.hide}
              onClick={() => this.changeColumns(item.colId)}
            >
              <FormattedMessage {...this.props.messages[item.colId]} />
            </ItemSelectable>
          );
        })
        .filter(item => item !== undefined);
    }
    return undefined;
  };

  onGridReady = params => {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;
    this.log('TableControlled: onGridReady, calling setConfig with this.getUpdateConfig()');
    this.setTableConfig(this.getUpdateConfig());
    if (this.props.defaultConfig === undefined) {
      // Commenting this will not fix it
      console.error(
        `You must include default config! Table '${this.props.name}' current config: '${JSON.stringify(
          this.extractTableConfig(),
        )}' take this output a put as default at least`,
      );
    }
  };

  getUpdateConfig = () => {
    let newConfig;
    const { saveOnlyPinned } = this.props;
    if (this.isUserView()) {
      newConfig = saveOnlyPinned
        ? this.mergeColumnStateToDefaultConfig(this.props.config, this.props.columnDefs)
        : this.props.config;
    } else {
      newConfig = saveOnlyPinned
        ? this.mergeColumnStateToDefaultConfig(this.props.defaultConfig, this.props.columnDefs)
        : this.props.defaultConfig;
    }
    return newConfig;
  };

  componentDidUpdate(prevProps) {
    this.log(`TableControlled CDU with props`, this.props);
    // TODO may cause trouble when editing
    if (this.gridApi && this.props.rowData !== prevProps.rowData) {
      this.log(`TableControlled CDU: Setting row data od controlled table ${this.props.name}`);
      this.gridApi.setRowData(this.props.rowData);
    }
    const current = this.extractTableConfig();
    // This is the tricky part when we want to apply changes from external source and from the grid it self as well
    if (current !== undefined && this.props.config !== undefined) {
      const { paginationModel: pm, ...otherCurrent } = current;
      const { paginationModel, ...otherProps } = this.props.config;
      if (hash(otherCurrent) !== hash(otherProps)) {
        this.log(
          'TableControlled: CDU detected change in config with updated props. Listing current and new:',
          otherCurrent,
          otherProps,
        );
        this.setTableConfig(this.getUpdateConfig());
      }
    }

    if (this.gridApi && this.props.columnDefs !== prevProps.columnDefs) {
      this.gridApi.refreshHeader();
    }
  }

  mergeColumnStateToDefaultConfig = (def, colDefs) => {
    const result = [];
    if (colDefs)
      colDefs.forEach(item => {
        getItem(item, result, current => {
          if (def && def.columnState) {
            const defItem = def.columnState.find(itemdef => itemdef.colId === current.colId) || current;
            return {
              colId: current.colId,
              hide: defItem.hide,
              aggFunc: null,
              width: defItem.width,
              pivotIndex: null,
              pinned: defItem.pinned ? 'left' : null,
              rowGroupIndex: defItem.rowGroupIndex,
            };
          }
          return current;
        });
      });
    // this.log('TableControlled: Merging config (1) to props.columnDefs (2). Got (3)', def, colDefs, result);
    return { ...def, columnState: result };
  };

  isUserView = () => this.props.view === USER_VIEWS.USER_VIEW;

  handleConfigChange = () => {
    const toSet = this.extractTableConfig();
    if (
      (toSet !== undefined && this.props.config === undefined) ||
      (this.props.config && toSet && hash(toSet) !== hash(this.props.config)) &&
      this.props.isMasterPlanResult !== true 
    ) {
      this.log(
        'TableControlled.handleConfigChange: Sending new config to reducer. Current from props (1), new/extracted (2)',
        this.props.config,
        toSet,
      );
      this.props.setTableConfigAction(this.props.name, toSet);
    }
  };

  render() {
    const { config, ...otherProps } = this.props;
    if (this.props.logging === true || globalLogging) {
      console.log(`TableControlled render ${this.props.name}`);
    }
    const { showCOG, onEmpty } = this.props;
    const isReady = this.props.rowData !== false;
    if (!isReady) {
      return onEmpty ? onEmpty(this.props) : null;
    }
    return (
      <Table
        {...otherProps}
        onFilterChanged={params => {
          this.handleConfigChange();
          if (this.props.onFilterChanged) {
            this.props.onFilterChanged(params);
          }
        }}
        onSortChanged={params => {
          this.handleConfigChange();
          if (this.props.onSortChanged) {
            this.props.onSortChanged(params);
          }
        }}
        onDisplayedColumnsChanged={params => {
          this.log('TableControlled: onDisplayedColumnsChanged');
          this.handleConfigChange();
          if (this.props.onDisplayedColumnsChanged) {
            this.props.onDisplayedColumnsChanged(params);
          }
        }}
        onColumnResized={params => {
          this.log('TableControlled: onColumnResized');
          this.handleConfigChange();
          if (this.props.onColumnResized) {
            this.props.onColumnResized(params);
          }
        }}
        onPaginationChanged={params => {
          this.handleConfigChange();
          if (this.props.onPaginationChanged) {
            this.props.onPaginationChanged(params);
          }
        }}
        onGridReady={params => {
          this.onGridReady(params);
          if (this.props.onGridReady) {
            this.props.onGridReady(params);
          }
        }}
        onPasteStart={() => {
          this.pasting = true;
        }}
        onPasteEnd={() => {
          this.pasting = false;
          if (this.props.onPaste) {
            const data = [];
            this.gridApi.forEachNode(node => {
              if (!node.group && node.data) {
                data.push(node.data);
              }
            });
            this.props.onPaste(data);
          }
        }}
        onCellValueChanged={params => {
          const pastingAndPasteHooked = this.pasting === true && this.props.onPaste;
          if (this.props.onCellValueChanged && !pastingAndPasteHooked) {
            // if onPaste is hooked grid doesn't send individual cell updates but whole data on paste end
            this.props.onCellValueChanged(params);
          }
        }}
      >
        {showCOG && (
          <MenuLocal header={<FontAwesomeIcon icon="cogs" size="lg" />} items={this.getCOGMenuItems()} itemsAlignRight />
        )}
      </Table>
    );
  }
}

TableControlled.defaultProps = {
  showCOG: true,
};

TableControlled.propTypes = {
  registerTableAction: PropTypes.func,
  unregisterTableAction: PropTypes.func,
  setTableConfigAction: PropTypes.func,
  onFilterChanged: PropTypes.func,
  onColumnResized: PropTypes.func,
  onSortChanged: PropTypes.func,
  onDisplayedColumnsChanged: PropTypes.func,
  loadConfigFromFavAction: PropTypes.func,
  onPaginationChanged: PropTypes.func,
  onGridReady: PropTypes.func,
  name: PropTypes.string.isRequired,
  config: PropTypes.object,
  match: PropTypes.object,
  columnDefs: PropTypes.oneOfType([PropTypes.bool, PropTypes.array]),
  rowData: PropTypes.oneOfType([PropTypes.bool, PropTypes.array]),
  defaultConfig: PropTypes.object.isRequired,
  messages: PropTypes.object,
  showCOG: PropTypes.bool,
  saveOnlyPinned: PropTypes.bool,
  view: PropTypes.string,
  onEmpty: PropTypes.func,
  onPaste: PropTypes.func,
  onCellValueChanged: PropTypes.func,
  logging: PropTypes.bool,
};

const saveOnlyPinned = name => SAVE_PINNED_ONLY.find(item => item === name) !== undefined;

const mapStateToProps = (state, props) =>
  createStructuredSelector({
    config: makeSelectTableControlled(props.name),
    view: makeSelectView(),
    saveOnlyPinned: () => saveOnlyPinned(props.name),
  });

export function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      registerTableAction,
      setTableConfigAction,
      unregisterTableAction,
      loadConfigFromFavAction,
    },
    dispatch,
  );
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export const withReducer = injectReducer({ key: 'tableControlled', reducer, mode: DAEMON });
const withSaga = injectSaga({ key: 'tableControlled', saga, mode: DAEMON });

function getItem(item, result, getter) {
  if (item.children === undefined) {
    result.push(getter(item));
  } else {
    item.children.forEach(subItem => getItem(subItem, result, getter));
  }
}

export default styled(compose(withConnect, withRouter, withReducer, withSaga)(TableControlled))`
  ${props =>
    (props.showCOG === undefined || props.showCOG === true) &&
    css`
      .ag-header-cell:last-child {
        padding-right: 40px;
      }
    `};
`;
