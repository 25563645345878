import IconButton from 'components/IconButton';
import { DateNoTimeCell } from 'components/ListGrid/utils';
import Table from 'components/Table';
import { PATHS } from 'containers/App/constants';
import { isEqual } from 'lodash';
import * as React from 'react';
import { Component } from 'react';
import { injectIntl } from 'react-intl';
import { compose } from 'redux';
import styled from 'styled-components';
import EnchantedMap from 'utils/enchantedMap';

import messages from './messages';
import { Facility, ScheduleStaff } from './types';
import { convertArrayToMapWithReduce } from './utils';

interface StaffTableProps {
  history: any;
  intl: any;
  scheduleId: number;
  staff: ScheduleStaff[];
  facilities: Facility[];
}

class StaffTable extends Component<StaffTableProps, {}> {
  private readonly facilityById: EnchantedMap<number, string>;

  constructor(props, context) {
    super(props, context);
    this.facilityById = convertArrayToMapWithReduce(
      this.props.facilities,
      item => item.id,
      item => item.name,
    );
  }

  public shouldComponentUpdate(nextProps: Readonly<StaffTableProps>, nextState: Readonly<{}>): boolean {
    return !isEqual(this.props.staff, nextProps.staff);
  }
  columnsToExport  = ['name', 'laborCategory', 'mainFacility', 'kronosId', 'validFrom', 'validTo'];
  public render() {
    return (
      <Table
        messages={messages}
        onModelUpdated={params => params.columnApi.autoSizeAllColumns()}
        pagination
        columnDefs={this.getColumnDefs()}
        rowData={this.props.staff || []}
        domLayout="autoHeight"
        defaultExportParams={{
          columnKeys: this.columnsToExport
        }}
      />
    );
  }

  private facilityGetter = value => this.facilityById.get(value?.data.facilityId);

  private getColumnDefs() {
    const SmallButton = styled(IconButton)`
      height: 20px;
    `;

    const goToDetail = id => () =>
      this.props.history.push(
        PATHS.shiftScheduleStaffDetailId.replace(':id', id).replace(':scheduleId', this.props.scheduleId.toString()),
      );
    const goToDetailButton = params => <SmallButton onClick={goToDetail(params.value)} label={messages.detail} />;

    return [
      {
        colId: 'name',
        field: 'name',
        menuTabs: ['filterMenuTab', 'generalMenuTab', 'columnsMenuTab'],
        headerName: this.props.intl.formatMessage(messages.activityName),
        valueGetter: value => `${value.data.lastName}, ${value.data.firstName}`,
        suppressSizeToFit: true,
      },
      {
        colId: 'laborCategory',
        field: 'laborCategory',
        menuTabs: ['filterMenuTab', 'generalMenuTab', 'columnsMenuTab'],
        headerName: this.props.intl.formatMessage(messages.laborCategory),
        suppressSizeToFit: true,
      },
      {
        colId: 'mainFacility',
        field: 'mainFacility',
        menuTabs: ['filterMenuTab', 'generalMenuTab', 'columnsMenuTab'],
        headerName: this.props.intl.formatMessage(messages.mainFacility),
        valueGetter: this.facilityGetter,
        suppressSizeToFit: true,
      },
      {
        colId: 'kronosId',
        field: 'kronosId',
        menuTabs: ['filterMenuTab', 'generalMenuTab', 'columnsMenuTab'],
        headerName: this.props.intl.formatMessage(messages.kronosId),
        suppressSizeToFit: true,
        minWidth: 200,
      },
      {
        cellRendererFramework: DateNoTimeCell,
        colId: 'validFrom',
        field: 'validFrom',
        menuTabs: ['filterMenuTab', 'generalMenuTab', 'columnsMenuTab'],
        headerName: this.props.intl.formatMessage(messages.validFrom),
        suppressSizeToFit: true,
      },
      {
        cellRendererFramework: DateNoTimeCell,
        colId: 'validTo',
        field: 'validTo',
        menuTabs: ['filterMenuTab', 'generalMenuTab', 'columnsMenuTab'],
        headerName: this.props.intl.formatMessage(messages.validTo),
        suppressSizeToFit: true,
      },
      {
        cellRendererFramework: goToDetailButton,
        colId: 'goToDetailButton',
        field: 'id',
        headerName: '',
        suppressMenu: true,
      },
    ];
  }
}

export default compose(
  injectIntl,
  // @ts-ignore
)(StaffTable);
