import { createSelector } from 'reselect';
import { ACTIVITY_NAMES } from './constants';

export const getToken = state => state.getIn(['user', 'token']);

const selectRoute = state => state.get('route');
const selectGlobal = state => state.get('global');

const makeSelectLocation = () =>
  createSelector(
    selectRoute,
    routeState => routeState.get('location').toJS(),
  );

const selectUOMS = state => state.getIn(['global', 'uoms']);

const selectView = state => state.getIn(['global', 'view']);
const selectBS = state => state.getIn(['global', 'bs']);
const selectBVersion = state => state.getIn(['global', 'beVersion']);
const makeSelectBS = key =>
  createSelector(
    selectBS,
    substate => substate && substate.get(key) && substate.get(key).toJS(),
  );
const selectActivities = state => state.getIn(['global', 'activities']);
const makeSelectView = () =>
  createSelector(
    selectView,
    substate => substate,
  );
const selectApiCallRuning = state => state.getIn(['global', 'runningApiCalls']);
const makeSelectRunningApiCalls = () =>
  createSelector(
    selectApiCallRuning,
    substate =>
      // return true;
      substate.size > 0,
  );
const makeSelectBeVersion = () =>
  createSelector(
    selectBVersion,
    substate => substate,
  );

export {
  makeSelectView,
  selectUOMS,
  makeSelectLocation,
  makeSelectRunningApiCalls,
  selectActivities,
  makeSelectBS,
  makeSelectBeVersion,
};
