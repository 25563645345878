/**
 *
 * DetailPageShared
 *
 */

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled, { css } from 'styled-components';

import { AddButton } from '../IconButton';

export const DetailToolbarButtonsWrap = styled.div`
  position: absolute;
  margin: 10px 15px 2px;
  padding: 10px;
  display: flex;
  left: 0px;
  right: 0px;
  justify-content: space-between;
  background-color: ${props => props.theme.color.greyDlgBg};
  box-shadow: ${props => props.theme.shadow.pageBox};
  ${props =>
    props.fixed &&
    css`
      margin-left: 0px;
      margin-top: 0px;
      z-index: ${props.theme.zIndex.overlayToolbar};
      position: fixed;
      top: 0px;
      left: 70px;
      right: 0px;
    `};
`;

export const DetailToolbarButtonsGroup = styled.div`
  display: flex;
  > * {
    margin-left: 10px;
  }
`;

export const DetailPageSectionsWrapper = styled.div`
  margin-top: 72px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const DetailPageFourSectionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const EditableListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  flex-wrap: wrap;
  ${AddButton} {
    top: -32px;
    position: absolute;
    right: 2px;
  }
`;
export const EditableListItemWrapper = styled.div`
  position: relative;
  display: grid;
  grid-row-gap: ${props => props.theme.grid.rowGap};
  grid-template-columns: 5em auto;
  grid-column-gap: 10px;
  margin: 4px 2px;
  padding: 2px;
  background-color: ${props => (props.dark ? props.theme.color.greyDlgBgSub : props.theme.color.white)};
  box-shadow: ${props => props.theme.shadow.pageBoxSlight};
`;

export const EditableListItemWrapperWzp = styled.div`
  position: relative;
  display: grid;
  grid-row-gap: ${props => props.theme.grid.rowGap};
  grid-template-columns: 5em auto;
  grid-column-gap: 10px;
  margin: 4px 2px;
  padding: 2px;
  top: 10px;
  background-color: ${props => (props.dark ? props.theme.color.greyDlgBgSub : props.theme.color.white)};
  box-shadow: ${props => props.theme.shadow.pageBoxSlight};
`;

export const EditableListDeleteButtonDiv = styled.div`
  position: absolute;
  padding: 2px;
  text-align: center;
  border: 0px none;
  right: 0px;
  top: -5px;
  height: 24px;
  :hover {
    cursor: pointer;
    color: ${props => props.theme.color.red};
  }
`;

export const EditableListDeleteButtonInlined = styled(EditableListDeleteButtonDiv)`
  position: relative;
  right: 0px;
  top: 0;
  height: 24px;
  :hover {
    cursor: pointer;
    color: ${props => props.theme.color.red};
  }
`;
export const EditableListDeleteIconButton = buttonProps => (
  <EditableListDeleteButtonDiv onClick={buttonProps.onClick}>
    <FontAwesomeIcon icon="times" />
  </EditableListDeleteButtonDiv>
);

export const EditableListDeleteIconButtonInlined = buttonProps => (
  <EditableListDeleteButtonInlined onClick={buttonProps.onClick}>
    <FontAwesomeIcon icon="times" />
  </EditableListDeleteButtonInlined>
);

export const cellEditDirtyMarker = setFieldValue => ({
  onCellEditingStarted: () => setFieldValue('planningParameters.cellEditing', true),
  onCellEditingStopped: () => setFieldValue('planningParameters.cellEditing', false),
});

export const SimpleHeader = props => <div>{props.displayName}</div>;
