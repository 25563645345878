/*
 * Dialog Messages
 *
 * This contains all the text for the Dialog component.
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  header: {
    id: 'app.components.Dialog.header',
    defaultMessage: 'This is the Dialog component !',
  },
  pageName: {
    id: 'app.components.Dialog.pageName',
    defaultMessage: 'Plan List',
  },
  masterPlanText: {
    id: 'app.components.Dialog.masterPlanText',
    defaultMessage: 'Below Master plans are associated with selected items',
  },
  dialogDeleteButtonConfirm: {
    id: 'app.components.Dialog.dialogDeleteButtonConfirm',
    defaultMessage: 'Delete',
  },
  dialogDeleteButtonCancel: {
    id: 'app.components.Dialog.dialogDeleteButtonCancel',
    defaultMessage: 'Cancel',
  },
  dialogDeleteText: {
    id: 'app.components.Dialog.dialogDeleteText',
    defaultMessage: 'Are you sure? You are deleting activity {name} ({variableName})',
  },
  dialogDeleteHeader: {
    id: 'app.components.Dialog.dialogDeleteHeader',
    defaultMessage: 'Delete Action',
  },
});
