// @flow

import React, { useEffect } from 'react';
import deburr from 'lodash/deburr';
import Autosuggest from 'react-autosuggest';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import Popper from '@material-ui/core/Popper';
import { makeStyles } from '@material-ui/core/styles';
import { injectIntl } from 'react-intl';
import { withUrl } from 'utils/api';
import debounce from 'lodash/debounce';
import omit from 'lodash/omit';
import find from 'lodash/find';

import { connect } from 'react-redux';
import { fetchData } from 'utils/reduxApi';

// Todo write input props
function renderInputComponent(inputProps: Object) {
  const { classes, inputRef = (node: Object) => {}, ref, ...other } = inputProps;

  return (
    <TextField
      fullWidth
      InputProps={{
        inputRef: (node: Object) => {
          ref(node);
          inputRef(node);
        },
        classes: {
          input: classes.input,
        },
      }}
      {...other}
    />
  );
}

function renderSuggestion(suggestion, { query, isHighlighted }) {
  return (
    <MenuItem selected={isHighlighted} component="div">
      <div>{suggestion.label}</div>
    </MenuItem>
  );
}

function getSuggestionValue(suggestion: Object): string {
  return suggestion.label;
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  container: {
    position: 'relative',
  },
  suggestionsContainerOpen: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
    maxHeight: '200px',
    overflowY: 'auto',
  },
  suggestion: {
    display: 'block',
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
  divider: {
    height: theme.spacing(2),
  },
}));

const equalFunc = item =>
  (item.id && ((a: Object, b: Object) => a.id === b.id)) || ((a, b) => JSON.stringify(a) === JSON.stringify(b));

type AsyncProps = {
  intl: Object,
  placeholder?: string,
  getOtherParams?: Function,
  setValueAfterLoad?: Function,
  entity: string, // part of url
  token: string,
  label: string, //
  options?: Array<Object>,
  setValue: Function,
  exclude?: Array<Object>,
  value?: any,
  hasMore?: boolean,
  className?: string,
  dispatch: Function,
};
function UIAsync(props: AsyncProps) {
  const {
    getOtherParams,
    entity,
    token,
    setValueAfterLoad,
    label,
    options,
    setValue,
    value,
    hasMore = true,
    className = '',
    dispatch,
  } = props;

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [inputValue, setInputValue] = React.useState('');

  const [suggestions, setSuggestions] = React.useState([]);
  const [filtered, setFilteredSuggestions] = React.useState([]);
  const [omitKeys, setOmitKeys] = React.useState(null);
  const [selected, setSelected] = React.useState(null);
  const [isChosen, setIsChosen] = React.useState(null);
  let chosen = isChosen;

  function shouldInclude(item) {
    const { exclude } = props;
    if (exclude) {
      for (const excludeItem of exclude) {
        if (equalFunc(item)(item, excludeItem)) {
          return false;
        }
      }
    }
    return true;
  }

  useEffect(() => {
    if (options) {
      if (JSON.stringify(options) !== JSON.stringify(suggestions)) {
        setSuggestions(options);
        setFilteredSuggestions(options);
        setSelectedValue();
      }
      return;
    }
    fetchOptions();
  }, [entity, token, getOtherParams, options]);

  useEffect(() => setSelectedValue(), [value]);

  function setSelectedValue() {
    if (!value) {
      saveSelected(null, false);
      return;
    }
    if (typeof value === 'string' || typeof value === 'number') {
      chosen = find(options, { value });
      if (chosen) saveSelected(chosen, false);
      return;
    }
    if (typeof value === 'object') {
      if ('id' in value && 'name' in value) {
        chosen = { label: value.name, value: value.id };
        saveSelected(chosen, false);
        return;
      }
      if ('value' in value && 'label' in value) {
        saveSelected(value, false);
      }
    }
  }

  async function fetchOptions(input: string = '') {
    const moreParams = (getOtherParams && getOtherParams()) || '';
    // Too early to load
    if (moreParams === false) {
      return options || [];
    }
    const resp = await fetchData(withUrl(`/${entity}/?expression=${input}${moreParams}`).andToken(token), dispatch);
    const opts =
      (resp.isOk && resp.data.results && resp.data.results.map(s => ({ ...s, label: s.name, value: s.id }))) || [];
    setSuggestions(opts);
    setFilteredSuggestions(opts);
    setOmitKeys(['label', 'value']);
    setSelectedValue();
    return opts;
  }

  const handleSuggestionsFetchRequested = ({ value = '', reason }) => {
    if (reason !== 'input-changed') return;

    if (chosen && chosen.label !== value) {
      chosen = null;
      setIsChosen(null);
    }

    const text = value.trim().toLowerCase();
    if (hasMore && entity) {
      fetchOptions(text);
      return;
    }

    if (text.length === 0) {
      setFilteredSuggestions(suggestions);
      return;
    }

    setFilteredSuggestions(suggestions.filter(s => s.label.toLowerCase().includes(text)));
  };

  function onChange(event, { newValue }) {
    setInputValue(newValue);
  }

  function saveSelected(value, runCallback = true) {
    chosen = value;
    setIsChosen(chosen);
    setInputValue((chosen && chosen.label) || '');
    const val = chosen ? (omitKeys && omit(chosen, omitKeys)) || chosen : null;
    if (runCallback) return setValue && setValue(val);
  }

  function findSuggestion() {
    if (chosen) {
      return;
    }
    const text = inputValue.toLowerCase().trim();
    if (text.length === 0 || filtered.length === 0) {
      return saveSelected(null);
    }
    if (filtered.length === 1) {
      chosen = filtered[0];
    } else {
      // filtering by include
      const fi = filtered.filter(s => s.label.toLowerCase().includes(text));
      const optsNum = fi.length;
      // if there is the only one option let's take it
      if (optsNum === 1) {
        chosen = fi[0];
      } else if (optsNum > 1) {
        // if there is more let's filter by euqal only one option let's take it
        const f = filtered.filter(s => s.label.toLowerCase() === text);
        // if we have option let's take it
        if (f.length > 0) chosen = f[0];
        // if no let's take first
        else chosen = fi[0];
      }
    }

    saveSelected(chosen);
  }

  const autosuggestProps = {
    renderInputComponent,
    suggestions: filtered,
    onSuggestionsFetchRequested: handleSuggestionsFetchRequested,
    getSuggestionValue,
    renderSuggestion,
  };
  return (
    <div className={`${classes.root} ${className}`}>
      <Autosuggest
        {...autosuggestProps}
        inputProps={{
          classes,
          id: 'react-autosuggest-simple',
          label: props.label,
          placeholder: props.placeholder,
          value: inputValue,
          onChange,
        }}
        theme={{
          container: classes.container,
          suggestionsContainerOpen: classes.suggestionsContainerOpen,
          suggestionsList: classes.suggestionsList,
          suggestion: classes.suggestion,
        }}
        renderSuggestionsContainer={options => (
          <Paper {...options.containerProps} square>
            {options.children}
          </Paper>
        )}
        shouldRenderSuggestions={() => true}
        onSuggestionSelected={(event, params) => {
          saveSelected(params.suggestion);
        }}
        onSuggestionsClearRequested={findSuggestion}
      />
    </div>
  );
}

export default connect()(injectIntl(UIAsync));
