/**
 *
 * MasterPlanMultipleReportRuns
 *
 */

import React from 'react';
import styled from 'styled-components';
import { DateTime } from 'luxon';
import messages from './messages';
import Lb from 'components/Label';
import DatePicker from 'components/DatePicker';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const Label = styled(Lb)`
  height: ${props => props.theme.grid.rowHeight};
  display: flex;
  align-items: center;
  margin-right: 30px;
`;

const StartDateContainer = styled.div`
display: flex;
margin-bottom: 15px;
margin-left: 10px;
`;

const EndDateContainer = styled.div`
display: flex;
margin-bottom: 15px;
margin-left: 13px;
`;

const ConfirmButton = withStyles({
  root: {
    borderRadius: 0,
    border: 0,
    height: 48,
    padding: '0 30px',
    color: '#FFF',
    backgroundColor: '#D40511',
    '&:hover': {
      backgroundColor: '#D40511',
      color: '#FFF',
    },
  },
})(Button);

const CancelButton = withStyles({
  root: {
    borderRadius: 0,
    border: 0,
    height: 48,
    padding: '0 30px',
    color: '#000',
    backgroundColor: '#FFCC00',
    '&:hover': {
      backgroundColor: '#FFCC00',
      color: '#000',
    },
  },
})(Button);

export const materialTheme = () =>
  createMuiTheme({
    overrides: {
      MuiDialogPaper: {
        overflowY: 'scroll'
      },
      MuiDialogTitle: {
        root: {
          paddingBottom: '5px'
        }
      },
      MuiRadio: {
        colorSecondary: {
          '&.Mui-checked': { color: '#3a74ce !important' }
        }
      },
      MuiButton: {
        root: {
          '&.Mui-disabled': { backgroundColor: 'rgb(153, 153, 153)' }
        }
      },
    },
  });

/* eslint-disable react/prefer-stateless-function */
const MasterPlanMultipleReportRuns = (props) => {
  const muiTheme = materialTheme();
  const formattedDate = (val) => {
    const newValue = new Date(val);
    const year = newValue.toLocaleString("default", { year: "numeric" });
    const month = newValue.toLocaleString("default", { month: "2-digit" });
    const day = newValue.toLocaleString("default", { day: "2-digit" });
    const formattedDate = year + "-" + month + "-" + day;
    return formattedDate;
  }
  const [startdate, setStartDate] = React.useState(DateTime.now());
  const [enddate, setEndDate] = React.useState();
  const [formatstartdate, setformatstartdate] = React.useState(formattedDate(startdate));
  const [formatenddate, setformatenddate] = React.useState();

  const handleCloseModal = () => {
    props.resetModal();
  };

  const handleConfirmDialog = () => {
    props.confirmModal(formatstartdate, formatenddate);
    props.resetModal();
  }

  const onDateChange = (dateType, value) => {
    if (dateType === 'startDay' && value.startOf('day')) {
      setStartDate(value);
    }
    if (dateType === 'endDay' && value.startOf('day')) {
      setEndDate(value);
    }
  };

  const onAcceptEndDate = value => {
    onDateChange('endDay', value);
    setformatenddate(formattedDate(value))

  };

  const onAcceptStartDate = value => {
    onDateChange('startDay', value);
    setformatstartdate(formattedDate(value))

  };
  return (
    <MuiThemeProvider theme={muiTheme}>
      <Dialog
        open={props.modalFlag}
        onBackdropClick={handleCloseModal}
      >
        <DialogTitle>Refresh multiple Master plan Report Runs</DialogTitle>
        <DialogContent>
          <StartDateContainer>
            <Label {...messages.uploadStartDate} />

            <DatePicker
              style={{ width: '90px' }}
              value={startdate ? startdate : null}
              onAccept={onAcceptStartDate}
            />
          </StartDateContainer>
          <EndDateContainer>
            <Label {...messages.uploadEndDate} />
            <DatePicker
              style={{ width: '90px' }}
              value={enddate ? enddate : null}
              onAccept={onAcceptEndDate}
              minDate={startdate}
              maxDate={startdate && startdate.plus({ days: 364 })}
            />
          </EndDateContainer>
        </DialogContent>
        <DialogActions>
          <CancelButton onClick={handleCloseModal}>Cancel</CancelButton>
          <ConfirmButton disabled={!(startdate)} onClick={handleConfirmDialog}>Confirm</ConfirmButton>
        </DialogActions>
      </Dialog>
    </MuiThemeProvider>
  );
};
export default MasterPlanMultipleReportRuns;
