/**
 *
 * DirtyDialog
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button/Button';
import { FormattedMessage } from 'react-intl';

import messages from './messages';
import DialogDHL from '../Dialog';

export const DirtyDialog = props =>
  props.isOpen ? ( // eslint-disable-line
    <DialogDHL
      open={props.isOpen}
      onCancel={props.close} // eslint-disable-line
      onConfirm={() => {
        !props.deleteConfirmation && props.saveHandler(); // eslint-disable-line
        props.actionHandler(!props.deleteConfirmation);
        props.close();
      }}
      confirmLabel={props.deleteConfirmation ? messages.delete : messages.saveFromTo}
      cancelLabel={messages.cancel}
      headerLabel={props.deleteConfirmation ? messages.headerDelete : messages.header}
      additionalButtons={
        props.deleteConfirmation ? null : (
          <Button
            onClick={() => {
              props.actionHandler(false);
              props.close();
            }}
            color="primary"
            variant="contained"
          >
            <FormattedMessage {...messages.dontSave} />
          </Button>
        )
      }
    >
      <FormattedMessage {...(props.deleteConfirmation ? messages.confirmDelete : messages.text)} />
    </DialogDHL>
  ) : null;

DirtyDialog.propTypes = {
  isOpen: PropTypes.bool,
  close: PropTypes.func,
  deleteConfirmation: PropTypes.bool,
  saveHandler: PropTypes.func,
  actionHandler: PropTypes.func,
};

export const withDirtyDialog = WrappedComponent =>
  class extends React.Component {
    // eslint-disable-line
    state = {
      isOpen: false,
      actionHandler: () => null,
      saveHandler: () => null,
      deleteConfirmation: false,
    };

    close = () =>
      this.setState({
        isOpen: false,
      });

    openDialog = (saveHandler, actionHandler) =>
      this.setState({
        isOpen: true,
        actionHandler,
        saveHandler,
        deleteConfirmation: false,
      });

    openDirtyDialogAsDeleteConfirm = (saveHandler, actionHandler) =>
      this.setState({
        isOpen: true,
        actionHandler,
        saveHandler,
        deleteConfirmation: true,
      });

    render() {
      return (
        <>
          <WrappedComponent
            {...this.props}
            openDirtyDialog={this.openDialog}
            openDirtyDialogAsDeleteConfirm={this.openDirtyDialogAsDeleteConfirm}
            closeDirtyDialog={this.close}
          />
          <DirtyDialog {...this.state} close={this.close} />
        </>
      );
    }
  };

export default DirtyDialog;
