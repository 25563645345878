import React from 'react';

import Dialog from '../Dialog';
import messages from './messages';

type Props = {
  visible: boolean;
  onConfirm: () => void;
  onCancel: () => void;
};

const OverwriteConfirmDialog: React.FC<Props> = ({ visible, onConfirm, onCancel }) => (
  <Dialog
    open={visible}
    headerLabel={messages.overwriteConfirmDialogHeader}
    confirmLabel={messages.overwriteConfirmDialogConfirm}
    cancelLabel={messages.overwriteConfirmDialogCancel}
    isTextContent
    onCancel={onCancel}
    onConfirm={onConfirm}
  />
);

export default OverwriteConfirmDialog;
