/*
 * PlanDetailActivitySettings Messages
 *
 * This contains all the text for the PlanDetailActivitySettings component.
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  saveFromTo: {
    id: 'app.components.PlanDetailActivitySettings.save',
    defaultMessage: 'Save',
  },
  add: {
    id: 'app.components.PlanDetailActivitySettings.add',
    defaultMessage: 'Add Line',
  },
  all: {
    id: 'app.components.PlanDetailActivitySettings.all',
    defaultMessage: 'All',
  },
  activity: {
    id: 'app.components.PlanDetailActivitySettings.activity',
    defaultMessage: 'Activity',
  },
  selectActivity: {
    id: 'app.components.PlanDetailActivitySettings.selectActivity',
    defaultMessage: 'select activity',
  },
  selectUOM: {
    id: 'app.components.PlanDetailActivitySettings.selectUOM',
    defaultMessage: 'Select Unit',
  },
  indirect: {
    id: 'app.components.PlanDetailActivitySettings.indirect',
    defaultMessage: 'indirect',
  },
  selectActivityTitle: {
    id: 'app.components.PlanDetailActivitySettings.selectActivityTitle',
    defaultMessage: 'Select Activity For',
  },
  tableHeaderLabel_id: {
    id: 'app.containers.PlanDetailActivitySettings.tableHeaderLabel_id',
    defaultMessage: 'Id',
  },
  tableHeaderLabel_name: {
    id: 'app.containers.PlanDetailActivitySettings.tableHeaderLabel_name',
    defaultMessage: 'Name',
  },
  tableHeaderLabel_regionalConfigurationName: {
    id: 'app.containers.PlanDetailActivitySettings.tableHeaderLabel_regionalConfigurationName',
    defaultMessage: 'Regional Configuration',
  },
  tableHeaderLabel_globalName: {
    id: 'app.containers.PlanDetailActivitySettings.tableHeaderLabel_globalName',
    defaultMessage: 'Global Name',
  },
  tableHeaderLabel_kronosCode: {
    id: 'app.containers.PlanDetailActivitySettings.tableHeaderLabel_kronosCode',
    defaultMessage: 'SmartShift Code',
  },
  tableHeaderLabel_variableName: {
    id: 'app.containers.PlanDetailActivitySettings.tableHeaderLabel_variableName',
    defaultMessage: 'Variable Name',
  },
  tableHeaderLabel_activityTypeId: {
    id: 'app.containers.PlanDetailActivitySettings.tableHeaderLabel_activityTypeId',
    defaultMessage: 'Activity Type Id',
  },
  tableHeaderLabel_activityTypeName: {
    id: 'app.containers.PlanDetailActivitySettings.tableHeaderLabel_activityTypeName',
    defaultMessage: 'Activity Type Name',
  },
  tableHeaderLabel_customer: {
    id: 'app.containers.PlanDetailActivitySettings.tableHeaderLabel_customer',
    defaultMessage: 'Customer',
  },
  tableHeaderLabel_uom: {
    id: 'app.containers.PlanDetailActivitySettings.tableHeaderLabel_uom',
    defaultMessage: 'Unit of Measure',
  },
  tableHeaderLabel_indirect: {
    id: 'app.components.PlanDetailActivitySettings.tableHeaderLabel_indirect',
    defaultMessage: 'Indirect',
  },
  tableHeaderLabel_productivityType: {
    id: 'app.components.PlanDetailActivitySettings.tableHeaderLabel_productivityType',
    defaultMessage: 'Productivity Type',
  },
  noWzpWarning: {
    id: 'app.components.PlanDetailActivitySettings.noWzpWarning',
    defaultMessage: 'Save at least one work zone period to add activities',
  },
  noShiftWarning: {
    id: 'app.components.PlanDetailActivitySettings.noShiftWarning',
    defaultMessage: 'Save at least one shift to add activities',
  },
  departmentPlaceholder: {
    id: 'app.components.PlanDetailActivitySettings.departmentPlaceholder',
    defaultMessage: 'All Departments',
  },
  selectCustomer: {
    id: 'app.components.PlanDetailActivitySettings.selectCustomer',
    defaultMessage: 'Select Customer',
  },
  omsCode: {
    id: 'app.components.PlanDetailActivitySettings.omsCode',
    defaultMessage: 'OMS Code',
  },
  yes: {
    id: 'app.components.PlanDetailActivitySettings.yes',
    defaultMessage: 'Yes',
  },
  no: {
    id: 'app.components.PlanDetailActivitySettings.no',
    defaultMessage: 'No',
  },
  DIRECT: {
    id: 'app.components.PlanDetailActivitySettings.DIRECT',
    defaultMessage: 'Direct',
  },
  INDIRECT_EFFORT: {
    id: 'app.components.PlanDetailActivitySettings.INDIRECT_EFFORT',
    defaultMessage: 'Indirect Effort',
  },
  LOCATION_UNPRODUCTIVE: {
    id: 'app.components.PlanDetailActivitySettings.LOCATION_UNPRODUCTIVE',
    defaultMessage: 'Location Unproductive',
  },
  STAFF_UNPRODUCTIVE: {
    id: 'app.components.PlanDetailActivitySettings.STAFF_UNPRODUCTIVE',
    defaultMessage: 'Staff Unproductive',
  },
});
