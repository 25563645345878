import mapValues from 'lodash/mapValues';
import flow from 'lodash/fp/flow';
import sum from 'lodash/fp/sum';
import values from 'lodash/fp/values';
import size from 'lodash/size';
import map from 'lodash/map';
import forEach from 'lodash/forEach';
import pick from 'lodash/pick';
import merge from 'lodash/merge';

const isOffHoursHeaderItem = headerItem => !headerItem.label;

const zeroValues = object => mapValues(object, () => 0);

const evenlyDistributeTotalToObjectValues = (onHoursObject, valueToDistribute) => {
  const totalOnAmount = flow(
    values,
    sum,
  )(onHoursObject);
  if (totalOnAmount > 0) {
    return mapValues(onHoursObject, value => value + (value / totalOnAmount) * valueToDistribute);
  }
  return mapValues(onHoursObject, () => valueToDistribute / size(onHoursObject));
};

export default function distributeOffHours(field) {
  const { headers, rowData } = field;
  // for each day, get headers without label
  const daysInfo = map(headers, header => ({
    label: header.label,
    offHours: header.children
      .filter(isOffHoursHeaderItem)
      .flatMap(period => period.children)
      .map(hour => hour.field),
    onHours: header.children
      .filter(headerItem => headerItem.label)
      .flatMap(period => period.children)
      .map(hour => hour.field),
  }));
  const newRowData = map(rowData, row => {
    let newRow = row;
    forEach(daysInfo, day => {
      // get all off hours values
      const offHoursObject = pick(row, day.offHours);
      const newOffHours = zeroValues(offHoursObject);
      // get all on hours values
      const onHoursObject = pick(row, day.onHours);
      const totalOffAmount =
        100 -
        flow(
          values,
          sum,
        )(onHoursObject);
      const newOnHours = evenlyDistributeTotalToObjectValues(onHoursObject, totalOffAmount);
      // set all off hours to 0
      // forEach(onHoursObject, onhv => {
      newRow = merge(newRow, newOffHours, newOnHours);
    });
    return newRow;
  });
  return { headers, rowData: newRowData };
}
