import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';

import { chunk, toNumber } from 'utils/utils';

import GroupParametersValuesTable from '../GroupParametersValuesTable';

const GridParametersGroupsRow = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: 550px 550px;
  column-gap: 10px;
`;

const GroupParametersValuesSectionLabel = styled.div`
  margin: 10px -10px;
  font-weight: bold;
`;

const ParameterGroupSection = styled.div`
  max-width: 500px;
  border: 0px none;
  margin: 15px 15px;
`;

interface ParametersGroupsProps {
  isEdit: boolean;
}

class ParametersGroups extends React.PureComponent<ParametersGroupsProps> {
  render() {
    const { groups, setParameterValue, isEdit } = this.props;
    return (
      <div>
        {groups.length !== 0 ? (
          <>
            {chunk(groups, 2).map((groupRow, rowIndex) => (
              <GridParametersGroupsRow key={rowIndex}>
                {groupRow.map((group, groupIndex) => (
                  <ParameterGroupSection key={groups[rowIndex * 2 + groupIndex].groupName}>
                    <GroupParametersValuesSectionLabel>{group.groupName}</GroupParametersValuesSectionLabel>
                    <GroupParametersValuesTable
                      parametersValues={groups[rowIndex * 2 + groupIndex].parametersValues}
                      setFieldValue={(parameterValueData, newValue) => {
                        setParameterValue(parameterValueData, toNumber(newValue));
                      }}
                      isEdit={isEdit}
                    />
                  </ParameterGroupSection>
                ))}
              </GridParametersGroupsRow>
            ))}
          </>
        ) : (
          ''
        )}
      </div>
    );
  }
}

ParametersGroups.propTypes = {
  groups: PropTypes.array,
  setParameterValue: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({});

const withConnect = connect(mapStateToProps);

export default injectIntl(withConnect(ParametersGroups));
