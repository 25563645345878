/*
 * CreateCopyPlanPaPage Messages
 *
 * This contains all the text for the CreateCopyPlanPaPage component.
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  headerMasterPlanInsert: {
    id: 'app.containers.CreateCopyPlanPaPage.headerMasterPlanInsert',
    defaultMessage: 'Create Master Plan',
  },
  errorNoId: {
    id: 'app.containers.CreateCopyPlanPaPage.errorNoId',
    defaultMessage: 'You must set id of {type} to make copy from it.',
  },
  basicInfo: {
    id: 'app.containers.CreateCopyPlanPaPage.basicInfo',
    defaultMessage: 'Basic information',
  },
  customers: {
    id: 'app.containers.CreateCopyPlanPaPage.customers',
    defaultMessage: 'Customers',
  },
  facilities: {
    id: 'app.containers.CreateCopyPlanPaPage.facilities',
    defaultMessage: 'Facilities',
  },
  managementUnits:{
    id: 'app.containers.CreateCopyPlanPaPage.managementUnits',
    defaultMessage: 'Management Units',
  },
  name: {
    id: 'app.containers.CreateCopyPlanPaPage.name',
    defaultMessage: 'Name',
  },
  description: {
    id: 'app.containers.CreateCopyPlanPaPage.description',
    defaultMessage: 'Description',
  },
  planningAreaPACopy: {
    id: 'app.containers.CreateCopyPlanPaPage.planningAreaPACopy',
    defaultMessage: 'Parent Planning Area',
  },
  planningAreaPAInsert: {
    id: 'app.containers.CreateCopyPlanPaPage.planningAreaPAInsert',
    defaultMessage: 'Parent Planning Area',
  },
  planningAreaPlanCopy: {
    id: 'app.containers.CreateCopyPlanPaPage.planningAreaPlanCopy',
    defaultMessage: 'Planning Area',
  },
  planningAreaPlanInsert: {
    id: 'app.containers.CreateCopyPlanPaPage.planningAreaPlanInsert',
    defaultMessage: 'Planning Area',
  },
  firstDayOfWeek: {
    id: 'app.containers.CreateCopyPlanPaPage.firstDayOfWeek',
    defaultMessage: 'Week Start Day',
  },
  transformationType: {
    id: 'app.containers.CreateCopyPlanPaPage.transformationTypes',
    defaultMessage: 'Transformation Type',
  },
  WZP: {
    id: 'app.containers.CreateCopyPlanPaPage.WZP',
    defaultMessage: 'Day to WZP',
  },
  SHIFT: {
    id: 'app.containers.CreateCopyPlanPaPage.SHIFT',
    defaultMessage: 'Day to SHIFT',
  },
  plansOptional:{
    id: 'app.containers.CreateCopyPlanPaPage.plansOptional',
    defaultMessage: 'Plan (Optional)',
  }
});
