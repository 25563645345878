import React from 'react';
import { connect as formikConnect, Field, Formik, FormikProps } from 'formik';
import { InjectedIntl, injectIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import ButtonWithDirtyCheck from 'components/ButtonWithDirtyCheck';
import { FormikTextInput } from 'components/FormikTextInput';
import Label from 'components/Label';
import Select from 'components/StyledSelect';
import { getToken } from 'containers/App/selectors';
import { EntityEntry, ForecastForm, SmartProdRunEnum, TimeTransformationTableRunEnum } from 'containers/ForecastDetailPage/types';
import { withUrl } from 'utils/api';
import { required } from 'utils/form/validations';
import { fetchData } from 'utils/reduxApi';
import { ApiCreateSmartProdRunDTO } from 'types/drep-backend.d';

import messages from './messages';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  min-height: 44px;

  > * {
    margin: 0 20px;
  }

  input {
    background-color: ${props => props.theme.color.grey5};
  }

  padding-bottom: 5px;
`;

const StyledSelect = styled(Select)`
  width: 12em;
  margin-top: 5px;
  margin-left: 12px;
`;

export interface NewRunType {
  id?: number;
  smartProdRunType: string;
  timeTransformation: boolean;
}

export type Props = {
  formik?: FormikProps<ForecastForm>;
  forecastEdit: boolean;
  planningParametersId: number;
  handleReloadData: () => void;
  allSmartProdSources: EntityEntry[];
  setRecentlyCreatedRun: (data: NewRunType) => void;
  intl: InjectedIntl;
};

const SmartProdRunsAddForm: React.FC<Props> = ({
  formik,
  planningParametersId,
  handleReloadData,
  allSmartProdSources,
  forecastEdit,
  setRecentlyCreatedRun,
  intl,
}) => {
  const token = useSelector(getToken);
  const dispatch = useDispatch();

  const initialValues = {
    name: '',
    smartProdSourceId: null,
    smartProdRunType: SmartProdRunEnum.REQUEST,
    timeTransformation: TimeTransformationTableRunEnum.NO
  };

  const typeOptions = [
    { value: SmartProdRunEnum.REQUEST, label: intl.formatMessage(messages.smartProdRunTypeRequest) },
    { value: SmartProdRunEnum.SCHEDULE, label: intl.formatMessage(messages.smartProdRunTypeSchedule) },
  ];

  const transformationTypeOptions = [
    { value: TimeTransformationTableRunEnum.YES, label: intl.formatMessage(messages.TimeTransformationTableRunYes) },
    { value: TimeTransformationTableRunEnum.NO, label: intl.formatMessage(messages.TimeTransformationTableRunNo) },
  ];

  const isFormValid = formikBag => formikBag.values.name && formikBag.values.smartProdSourceId;

  const handleAddNewEvent = async (formValues, actions) => {
    const body: ApiCreateSmartProdRunDTO = {
      smartProdSourceId: formValues.smartProdSourceId,
      name: formValues.name,
      planningParametersId,
      timeTransformation: formValues.timeTransformation ===  'YES' ? true : false,
    };
    const url =
      formValues.smartProdRunType === SmartProdRunEnum.SCHEDULE ? '/smartProdRun/schedule' : '/smartProdRun';
    const result = await fetchData(withUrl(url).put(body).andToken(token), dispatch);

    actions.setSubmitting(false);
    if (result.isOk) {
      actions.resetForm(initialValues);
      setRecentlyCreatedRun({ id: result.data.id, smartProdRunType: formValues.smartProdRunType, timeTransformation: formValues.timeTransformation === 'YES' ? true : false });
      handleReloadData();
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      isInitialValid
      enableReinitialize
      onSubmit={handleAddNewEvent}
      render={formikBag => (
        <Wrapper>
          <Label {...messages.smartProdRunsNewName} />
          <Field name="name" type="text" disabled={!forecastEdit} component={FormikTextInput} validate={[required]} />

          <Label {...messages.smartProdRunsSmartProd} />
          <Field
            name="smartProdSourceId"
            render={({ field, form }) => (
              <StyledSelect
                id="smartProdRunAddForm-smartProdSourceId"
                options={allSmartProdSources}
                isDisabled={!forecastEdit}
                onChange={option => {
                  form.setFieldValue(field.name, option.value);
                }}
                value={allSmartProdSources.find(o => o.value === field.value) || null}
              />
            )}
          />

          <Label {...messages.smartProdRunType} />
          <Field
            name="smartProdRunType"
            render={({ field, form }) => (
              <StyledSelect
                id="smartProdRunAddForm-smartProdRunType"
                options={typeOptions}
                isDisabled={!forecastEdit  || formikBag?.values?.timeTransformation === 'YES'}
                onChange={option => {
                  if(option.value === SmartProdRunEnum.SCHEDULE){
                    form.setFieldValue('timeTransformation', 'NO');
                  }
                  form.setFieldValue(field.name, option.value);
                }}
                value={typeOptions.find(o => o.value === field.value) || typeOptions[0]}
              />
            )}
          />
          <Label {...messages.timeTransformationTableRun} />
          <Field
            name="timeTransformation"
            render={({ field, form }) => (
              <StyledSelect
                id="smartProdRunAddForm-timeTransformation"
                options={transformationTypeOptions}
                isDisabled={!forecastEdit || (formikBag?.values?.smartProdRunType === SmartProdRunEnum.SCHEDULE)}
                onChange={option => {
                  form.setFieldValue(field.name, option.value);
                }}
                value={transformationTypeOptions.find(o => o.value === field.value) || transformationTypeOptions[0]}
              />
            )}
          />

          {forecastEdit && (
            <ButtonWithDirtyCheck
              dirty={formik.dirty}
              color="secondary"
              variant="contained"
              actionHandler={formikBag.submitForm}
              disabled={formikBag.isSubmitting || !formikBag.dirty || !isFormValid(formikBag)}
              message={messages.smartProdRunsAddButton}
            />
            // <Button
            //   onClick={formikBag.handleSubmit}
            //   color="secondary"
            //   variant="contained"
            //   disabled={formikBag.isSubmitting || !isFormValid(formikBag)}
            // >
            //   <FormattedMessage {...messages.smartProdRunsAddButton} />
            // </Button>
          )}
        </Wrapper>
      )}
    />
  );
};

// @ts-ignore
export default formikConnect(injectIntl(SmartProdRunsAddForm));
