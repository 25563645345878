/*
 *
 * Adjustments actions
 *
 */

import { TOGGLE_DAY } from './constants';

export function toggleDayAction(payload, isActive) {
  return {
    type: TOGGLE_DAY,
    payload,
    isActive,
  };
}
