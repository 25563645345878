// @flow
import React, { Fragment } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import uuid from 'uuid/v4';
import './style.css';

import debounce from 'lodash/debounce';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import find from 'lodash/find';
import messages from '../messages';
import SearchInput from './SearchInput';
import Checkbox from './Checkbox';

type Props = {
  values: Array<Object>,
  filterChangedCallback: Function,
  api: Object, // ag-grid api
  column: Object, // filter Column
  debounceMs?: number,
  suppressSearch?: boolean,
  clearButton?: boolean,
  applyButton?: boolean,
  clearAll?: boolean,
  valueGetter?: Function,
  colDef: Object,
  columns: Array<string>,
};

type ColumnProps = {
  headerName: string,
  colId: string,
  list: Array<Object>,
  origList: Array<Object>,
  search: string,
  selectAll: boolean,
  selected: Object,
  isFilterActive: boolean,
};

type StateProps = {
  columns: Array<ColumnProps>,
  activeTab: number,
};

class GroupFilter extends React.PureComponent<Props, StateProps> {
  allSelectedLength = 0;

  filterChangedCallback = () => {};

  colId = [];

  valGetter = {};

  valFormatter = {};

  filterValueGetter = {};

  keyCreator = {};

  colDefs = [];

  constructor(props: Props) {
    super(props);
    const {
      api,
      columns,
      colDef: { colId },
    } = this.props;

    if (!columns || columns.length === 0) return;

    const cols = api.gridCore.columnController.columnDefs;
    this.colDefs = columns.map((col: string) => {
      const cd = find(cols, { colId: col });
      if (!cd) {
        console.error(`Group Filter: unable to find colDef for ${col}`);
        return null;
      }
      return cd;
    });

    const filtersModel = api.getFilterModel();
    const model = (filtersModel && filtersModel[colId]) || null;

    this.state = {
      activeTab: 0,
      columns: columns.map((col, index) => this.getState(model && model[col], col, index)),
    };
  }

  hidePopup() {
    document.getElementsByTagName('body')[0].click();
  }

  getValue = (rowNode: Object, colId) => {
    const filterValueGetter = this.filterValueGetter[colId];
    const valGetter = this.valGetter[colId];
    const valFormatter = this.valFormatter[colId];
    const keyCreator = this.keyCreator[colId];
    if (!rowNode.data) return '';
    let val = rowNode.data && rowNode.data[colId];
    if (filterValueGetter) {
      val = filterValueGetter(rowNode);
    } else if (valGetter || valFormatter) {
      if (valGetter) val = valGetter(rowNode);
      if (valFormatter) val = valFormatter({ value: val });
    } else if (keyCreator) {
      val = keyCreator({ value: val });
    }
    return val;
  };

  doesFilterPass = (params: Object): boolean => {
    const { columns } = this.state;
    const activeFilters = columns.filter(c => c.isFilterActive);
    if (activeFilters.length === 0) return true;
    for (const filter of activeFilters) {
      const val = this.getValue(params, filter.colId);
      if (filter.selected.indexOf(`${val}`) < 0) return false;
    }
    return true;
  };

  getState = (filterModel?: Object | null, colId: string, tabNum: number) => {
    const { debounceMs, filterChangedCallback, applyButton } = this.props;
    if (!applyButton) {
      this.filterChangedCallback = debounceMs ? debounce(filterChangedCallback, debounceMs) : filterChangedCallback;
    }
    const colDef = this.colDefs[tabNum];
    if (!colDef) return;
    this.valFormatter[colId] = colDef.valueFormatter;
    this.filterValueGetter[colId] = colDef.filterValueGetter;
    this.keyCreator[colId] = colDef.keyCreator;

    let origList = [];

    if (this.props.values) origList = this.props.values.slice();
    else {
      const { api, doesRowPassOtherFilter } = this.props;
      const { getValue } = this;
      api.forEachNode(function (rowNode, index) {
        if (!doesRowPassOtherFilter(rowNode)) return;
        if (!rowNode.data) return;

        let val = getValue(rowNode, colId);
        if (typeof val === 'number') val = `${val}`;
        if (typeof val === 'string' && val.length > 0 && origList.indexOf(val) < 0) origList.push(val);
      });
    }

    origList.sort();
    this.allSelectedLength = origList.length;

    if (!filterModel) {
      const selected = origList;
      return {
        headerName: colDef.headerName,
        colId,
        list: origList,
        origList,
        selected,
        search: '',
        selectAll: true,
        isFilterActive: false,
      };
    }

    const { values = [], search = '' } = filterModel;
    const list = search ? this.filterList(search, origList) : origList;
    const selected = (Array.isArray(values) && values) || list;
    return {
      headerName: colDef.headerName,
      colId,
      list,
      origList,
      selected,
      search,
      selectAll: false,
      isFilterActive: true,
    };
  };

  isFilterActive() {
    return this.state.columns.filter(c => c.isFilterActive).length > 0;
  }

  getList(): Array<Object> {
    const { values = [] } = this.props;
    if (!Array.isArray(values)) {
      console.error(`values should be an array of objects getting ${values}`);
      return [];
    }
    return values;
  }

  renderItem = (value: Object) => {
    const { columns, activeTab } = this.state;
    const { selected } = columns[activeTab];
    const checked = (selected && selected.indexOf(value) >= 0) || false;
    return (
      <label className="ag-set-filter-item" onClick={this.toggleSelectItem(value)}>
        <Checkbox id={value} checked={checked} />
        <span className="ag-filter-value" style={{ whiteSpace: 'pre-wrap' }}>
          {value}
        </span>
      </label>
    );
  };

  onSearchInputChange = (e: Object) => {
    e.stopPropagation();
    e.preventDefault();
    const text = e.target.value;
    const { activeTab, columns } = this.state;
    const { search, origList, selectAll, selected } = columns[activeTab];
    if (text === search) return;

    const filteredList = this.filterList(text, origList);
    const newSelected = (selectAll && filteredList) || [];
    const newCols = columns.slice();
    newCols[activeTab] = {
      ...columns[activeTab],
      search: text,
      list: filteredList,
      selected: newSelected,
      isFilterActive: newSelected.length !== this.allSelectedLength,
    };
    this.setState({ columns: newCols }, this.filterChangedCallback);
  };

  filterList = (text: string, list: Array<string>): Array<string> => {
    const searchText = text.trim().toLowerCase();
    if (searchText === '') {
      return list;
    }
    return list.filter(value => value.toLowerCase().includes(searchText));
  };

  toggleSelectAll = (e: Object) => {
    e.stopPropagation();
    e.preventDefault();
    this.setState(state => {
      const { columns, activeTab } = state;
      const col = columns[activeTab];
      const selectAll = !col.selectAll;
      const isFilterActive = !selectAll || col.search !== '';
      const selected = selectAll ? col.list : [];
      const newColumns = columns.slice();
      newColumns[activeTab] = {
        ...col,
        selectAll,
        selected,
        isFilterActive,
      };
      return { columns: newColumns, activeTab };
    }, this.filterChangedCallback);
  };

  toggleSelectItem = (value: string) => (e: Object) => {
    e.stopPropagation();
    e.preventDefault();
    const { columns, activeTab } = this.state;
    const { selected } = columns[activeTab];
    const newSelected = selected.slice();
    const index = selected && selected.indexOf(value);
    if (index >= 0) {
      newSelected.splice(index, 1);
    } else {
      newSelected.push(value);
      newSelected.sort();
    }
    const selectedFilter = columns[activeTab];
    const isCustomerSelected = selectedFilter && selectedFilter.colId === "customer" && selectedFilter.selected;
    const isFilterActive = newSelected.length !== this.allSelectedLength;
    const isFilterActiveFlag = isCustomerSelected ? newSelected.length !== isCustomerSelected.length : isFilterActive;
    const newCols = columns.slice();
    newCols[activeTab] = {
      ...columns[activeTab],
      selected: newSelected,
      isFilterActive: isFilterActiveFlag ,
    };
    this.setState({ columns: newCols }, this.filterChangedCallback);
  };

  setModel = (model: Object) => {
    if (!model) return;
    const { filterChangedCallback, columns } = this.props;
    this.setState({
      columns: columns.map((col, index) => this.getState(model[col], col, index)),
    });
  };

  getModel = () => {
    const { columns } = this.state;
    const model = {};
    columns.map(col => {
      if (!col.isFilterActive) return;
      model[col.colId] = { filterType: 'set', values: col.selected, search: col.search };
    });
    return model;
  };

  clearAll = (e: Object) => {
    e.stopPropagation();
    e.preventDefault();
    const { filterChangedCallback } = this.props;
    const { columns } = this.state;
    const newCols = columns.map(col => ({
      ...col,
      list: col.origList,
      search: '',
      selectAll: true,
      selected: col.origList,
      isFilterActive: false,
    }));
    this.setState({ columns: newCols }, filterChangedCallback);
  };

  clearFilter = (e: Object) => {
    e.stopPropagation();
    e.preventDefault();

    const { filterChangedCallback } = this.props;
    const { columns, activeTab } = this.state;
    const col = columns[activeTab];
    const newCols = columns.slice();
    newCols[activeTab] = {
      ...col,
      list: col.origList,
      search: '',
      selectAll: true,
      selected: col.origList,
      isFilterActive: false,
    };
    this.setState({ columns: newCols }, filterChangedCallback);
  };

  applyFilter = (e: Object) => {
    e.stopPropagation();
    e.preventDefault();

    const { filterChangedCallback } = this.props;

    filterChangedCallback();
    this.hidePopup();
  };

  afterGuiAttached = () => {
    const {
      api,
      columns,
      colDef: { colId },
    } = this.props;

    let model = api.getFilterModel();
    if (!model) return;
    model = model[colId];
    if (!model || Object.keys(model).length === 0) return;
    this.setState({
      columns: columns.map((colId, index) => this.getState(model[colId], colId, index)),
    });
  };

  switchTab = (e, value) => {
    this.setState({ activeTab: value });
  };

  render() {
    const { suppressSearch, clearButton, clearAll = true, applyButton } = this.props;
    const { activeTab, columns } = this.state;
    const { list = [], selectAll, search } = columns[activeTab];
    return (
      <div className="ag-filter-body-wrapper ag-filter-body-wrapper-custom">
        <Tabs value={activeTab} onChange={this.switchTab} className="filter_tab_panel" aria-label="group filter">
          {columns.map(
            (col, index) =>
              col && (
                <Tab
                  label={col.headerName}
                  className={`filter_tab ${(index === activeTab && 'filter_tab_selected') || ''} ${
                    (col.isFilterActive && 'filter_is_active') || ''
                  }`}
                  key={`group_filter_tab_${index}`}
                />
              ),
          )}
        </Tabs>
        {!suppressSearch && (
          <div className="ag-input-text-wrapper ag-filter-header-container" id="ag-mini-filter">
            <SearchInput value={search} onChange={this.onSearchInputChange} />
          </div>
        )}
        <div className="ag-filter-header-container">
          <label id="selectAllContainer" onClick={this.toggleSelectAll}>
            <Checkbox id="selectAll" checked={selectAll} />
            <span className="ag-filter-value">
              (<FormattedMessage {...messages.select_all} />)
            </span>
          </label>
        </div>
        <div className="ag-set-filter-list" id="richList">
          <div className="ag-virtual-list-viewport">
            {list.map((value, index) => (
              <div key={`${value}-${uuid()}`}>{this.renderItem(value)}</div>
            ))}
          </div>
        </div>
        {(clearButton || applyButton) && (
          <div className="ag-filter-apply-panel" id="applyPanel">
            {clearAll && (
              <button type="button" id="clearButton" onClick={this.clearAll}>
                <FormattedMessage {...messages.clear_all} />
              </button>
            )}
            {clearButton && (
              <button type="button" id="clearButton" onClick={this.clearFilter}>
                <FormattedMessage {...messages.clear_tab} />
              </button>
            )}
            {applyButton && (
              <button type="button" id="applyButton" onClick={this.applyFilter}>
                <FormattedMessage {...messages.apply_filter} />
              </button>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default GroupFilter;
