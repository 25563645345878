/*
 * PlannedVolumeAddLine Messages
 *
 * This contains all the text for the PlannedVolumeAddLine component.
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  NewShiftPlaceholder: {
    id: 'app.components.ShiftAddLine.NewShiftPlaceholder',
    defaultMessage: 'New shift name',
  },
});
