import React from 'react';

export default function Item(props) {
  const { item, itemContext, getItemProps, getResizeProps } = props;
  const { left: leftResizeProps, right: rightResizeProps } = getResizeProps();
  return (
    <div {...getItemProps(item.itemProps)} data-tip data-for={`${item.id.split('_')[1]}`} title={undefined}>
      {itemContext.useResizeHandle ? <div {...leftResizeProps} /> : ''}

      <div className="rct-item-content" style={{ maxHeight: `${itemContext.dimensions.height}` }}>
        {itemContext.title}
      </div>

      {itemContext.useResizeHandle ? <div {...rightResizeProps} /> : ''}
    </div>
  );
}
