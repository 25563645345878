/**
 *
 * Adjustments
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FieldArray, getIn } from 'formik';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { bindActionCreators, compose } from 'redux';
import uuid from 'uuid/v4';
import { toast } from 'react-toastify';

import {
  selectEditFromplan,
  selectPeriodIndexFromPlan,
  selectPlanDepartments,
  makeSelectIsShiftFromPlan,
  selectOffDays
} from 'containers/PlanDetailPage/selectors';
import { makeSelectToken } from 'containers/LoginPage/selectors';
import TableControlled from 'containers/TableControlled';
import { TABLE_DEFAULTS } from 'containers/App/constants';
import { toNumber, numberFormat } from 'utils/utils';
import {
  selectEditFromPa,
  selectPeriodIndexFromPa,
  selectAreaDepartments,
  selectIsShiftFromPa,
  selectOffDaysPA,
} from 'containers/PlanningAreaDetailPage/selectors';
import { getCustomers } from '../CustomerCellRenderer';
import { SimpleHeader } from '../DetailPageShared';
import DeleteCellRenderer from '../DeleteCellRenderer';
import messages from './messages';
import AdjustmentAddLine from './AdjustmentAddLine';
import AdjustmentDays from './AdjustmentDays';
import { DayTransformationButtonsWrap } from 'containers/DayToHourTransformation';
import { DeleteDialog, withDeleteDialog } from '../../components/Dialog';

import { toggleDayAction } from './actions';
import { addAdjustmentsline, deleteAdjustmentLines } from 'containers/PlanDetailPage/actions';
import { addPaAdjustmentsline, deletePaAdjustmentLines } from 'containers/PlanningAreaDetailPage/actions';
import { disableOffDays, constructRowData, constructRowDataIndirect } from './utils';
import makeSelectAdjustments from './selectors';
import injectReducer from 'utils/injectReducer';
import { numericSetterAdjustment } from 'utils/utils';
import reducer from './reducer';

const StyledTable = styled(TableControlled)`
  height: ${props => props.height}px;
`;

const StyledDiv = styled.div`
margin-top: 15px;
`

/* eslint-disable react/prefer-stateless-function */
class Adjustments extends React.Component {
  state = { selectedMHE: null, departmentId: null };

  fieldPath = () => `planningParameters.periods.${this.props.periodIndex}.activityAdjustments`;

  wzpsPath = () => `planningParameters.periods.${this.props.periodIndex}.workZonePeriods`;

  shiftsPath = () => `planningParameters.periods.${this.props.periodIndex}.shifts`;

  activityParametersPath = () => `planningParameters.periods.${this.props.periodIndex}.activityParameters`;

  prepareValidShiftsForDay = (day, wzps) => {
    if (!wzps) {
      return wzps;
    }
    return wzps.filter(a => a.days && a.days.includes(day));
  };

  componentDidMount() {
    disableOffDays(this.props.offdays, this.props.toggleDayAction);
  }

  componentDidUpdate(prevProps) {
    const { offdays } = this.props;
    const { offdays: prevOffDays } = prevProps;
    if (prevOffDays !== offdays) {
      disableOffDays(this.props.offdays, this.props.toggleDayAction);
      if (this.gridApi) {
        //this.gridApi.refreshHeader();
      }
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const buildGuiStateKey = props => {
      const key = [];
      key.push(props.edit);
      key.push(props.selectedDays.join(':'));
      key.push(props.deleteDialogOpen);
      key.push(props.periodIndex);
      key.push(props.firstDay);
      key.push(props.offDays ? props.offDays.join(':') : '');
      key.push(getIn(props.formik, 'values.reloadCount', 0));
      return key.join('-');
    };
    const shouldUpdate = buildGuiStateKey(this.props) !== buildGuiStateKey(nextProps);
    return shouldUpdate;
  }

  currentArrayHelpers = null;

  columnDefs = (self, remove, selectedDays, type, activityParametersList, departments, customers, form, periodIndex) => {
    const { props } = self;
    const editable = props.edit;

    function numericSetterAdjustmentCheck(params) {
      if (params.newValue > 100) {
        toast.error('The adjustment value must not exceed 100');
        return false;
      }
      if (params.newValue < 0) {
        toast.error('The adjustment value must not below 0')
        return false;
      }
      if (params && params.newValue) {
        const numeric = toNumber(params.newValue);
        if (Number.isNaN(numeric)) {
          // parse error
          console.log(`Can't convert '${params.newValue}' to number`);
        } else {
          params.data[params.colDef.field] = numeric;
          return true;
        }
      }
      return false; // value was not changed
    }

    const handleCellStyle = (params) => {
      if(type == 'direct' && editable){
        return {backgroundColor: '#3a74ce', color: '#FFF'}
      }
      if (type === "indirect") {
        if (params.value > 100) {
          return { backgroundColor: 'red', color: '#FFF' }
        } else {
          return { backgroundColor: 'white', color: 'black' }
        }
      }
    }

    const handlepercentageCellFormatter = (params) => {
      if (type == 'direct') {
        const value = params.value ? numberFormat(toNumber(params.value).toFixed(2)) : 0;
        return value ? `${parseFloat(value).toFixed(2)}%` : value == 0 ? '0.00%' : diff;
      } else {
        const value = params.value ? numberFormat(toNumber(params.value).toFixed(2)) : 0;
        const diff = 100 - value;
        return diff ? `${diff.toFixed(2)}%` : diff == 0 ? '0.00%' : diff;
      }
    }
    const colDefs = [
      {
        headerName: props.intl.formatMessage(messages.oms),
        field: 'oms',
        colId: 'oms',
        menuTabs: ['filterMenuTab'],
      },
      {
        headerName: props.intl.formatMessage(messages.activityFrom),
        field: 'activityFromValue',
        colId: 'activityFromValue',
        menuTabs: ['filterMenuTab'],
      },
    ];
    if (type == 'direct') {
      colDefs.push({
        headerName: props.intl.formatMessage(messages.activityTo),
        field: 'activityToValue',
        colId: 'activityToValue',
        menuTabs: ['filterMenuTab'],
      })
    }
    if (selectedDays.includes('MONDAY')) {
      colDefs.push({
        headerName: props.intl.formatMessage(messages.mondayRate),
        children: [{
          headerName: type == 'direct' ? props.intl.formatMessage(messages.adjustments) : props.intl.formatMessage(messages.remaining),
          field: 'mondayRate',
          colId: 'mondayRate',
          editable: type == 'direct' && editable,
          menuTabs: ['filterMenuTab'],
          valueFormatter: handlepercentageCellFormatter,
          valueSetter: numericSetterAdjustmentCheck,
          cellStyle: handleCellStyle
        }]
      })
    }
    if (selectedDays.includes('TUESDAY')) {
      colDefs.push({
        headerName: props.intl.formatMessage(messages.tuesdayRate),
        children: [{
          headerName: type == 'direct' ? props.intl.formatMessage(messages.adjustments) : props.intl.formatMessage(messages.remaining),
          field: 'tuesdayRate',
          colId: 'tuesdayRate',
          width: 120,
          editable: type == 'direct' && editable,
          menuTabs: ['filterMenuTab'],
          valueFormatter: handlepercentageCellFormatter,
          valueSetter: numericSetterAdjustmentCheck,
          cellStyle: handleCellStyle
        }]
      })
    }
    if (selectedDays.includes('WEDNESDAY')) {
      colDefs.push({
        headerName: props.intl.formatMessage(messages.wednesdayRate),
        children: [{
          headerName: type == 'direct' ? props.intl.formatMessage(messages.adjustments) : props.intl.formatMessage(messages.remaining),
          field: 'wednesdayRate',
          colId: 'wednesdayRate',
          width: 120,
          editable: type == 'direct' && editable,
          menuTabs: ['filterMenuTab'],
          valueFormatter: handlepercentageCellFormatter,
          valueSetter: numericSetterAdjustmentCheck,
          cellStyle: handleCellStyle
        }]
      })
    }
    if (selectedDays.includes('THURSDAY')) {
      colDefs.push({
        headerName: props.intl.formatMessage(messages.thursdayRate),
        children: [{
          headerName: type == 'direct' ? props.intl.formatMessage(messages.adjustments) : props.intl.formatMessage(messages.remaining),
          field: 'thursdayRate',
          colId: 'thursdayRate',
          width: 120,
          editable: type == 'direct' && editable,
          menuTabs: ['filterMenuTab'],
          valueFormatter: handlepercentageCellFormatter,
          valueSetter: numericSetterAdjustmentCheck,
          cellStyle: handleCellStyle
        }]
      })
    }
    if (selectedDays.includes('FRIDAY')) {
      colDefs.push({
        headerName: props.intl.formatMessage(messages.fridayRate),
        children: [{
          headerName: type == 'direct' ? props.intl.formatMessage(messages.adjustments) : props.intl.formatMessage(messages.remaining),
          field: 'fridayRate',
          colId: 'fridayRate',
          width: 120,
          editable: type == 'direct' && editable,
          menuTabs: ['filterMenuTab'],
          valueFormatter: handlepercentageCellFormatter,
          valueSetter: numericSetterAdjustmentCheck,
          cellStyle: handleCellStyle
        }]
      })
    }
    if (selectedDays.includes('SATURDAY')) {
      colDefs.push({
        headerName: props.intl.formatMessage(messages.saturdayRate),
        children: [{
          headerName: type == 'direct' ? props.intl.formatMessage(messages.adjustments) : props.intl.formatMessage(messages.remaining),
          field: 'saturdayRate',
          colId: 'saturdayRate',
          width: 120,
          editable: type == 'direct' && editable,
          menuTabs: ['filterMenuTab'],
          valueFormatter: handlepercentageCellFormatter,
          valueSetter: numericSetterAdjustmentCheck,
          cellStyle: handleCellStyle
        }]
      })
    }
    if (selectedDays.includes('SUNDAY')) {
      colDefs.push({
        headerName: props.intl.formatMessage(messages.sundayRate),
        children: [{
          headerName: type == 'direct' ? props.intl.formatMessage(messages.adjustments) : props.intl.formatMessage(messages.remaining),
          field: 'sundayRate',
          colId: 'sundayRate',
          width: 120,
          editable: type == 'direct' && editable,
          menuTabs: ['filterMenuTab'],
          valueFormatter: handlepercentageCellFormatter,
          valueSetter: numericSetterAdjustmentCheck,
          cellStyle: handleCellStyle
        }]
      })
    }
    if (editable && type == 'direct') {
      colDefs.unshift({
        headerName: props.intl.formatMessage(messages.action),
        headerTooltip: props.intl.formatMessage(messages.action),
        field: 'delete',
        colId: 'delete',
        cellRendererFramework: DeleteCellRenderer,
        width: 80,
        cellRendererParams: {
          onDelete: payload => {
            const activityTo = activityParametersList && activityParametersList.filter((ap) => ap.id === payload.activityTo);
            const activityName = activityTo && activityTo[0] && activityTo[0].activity;
            return props.openDeleteDialog(props.deleteAdjustmentLines, payload, activityName)
          }
        },
        sortable: false,
        suppressMenu: true,
        pinned: true,
        headerComponentFramework: SimpleHeader,
      });
    }
    return colDefs;
  };

  renderFieldContent = arrayHelpers => {
    const {
      intl: { formatMessage },
      departments = [],
    } = this.props;
    this.currentArrayHelpers = arrayHelpers;
    const { push, form } = arrayHelpers;
    const customers = getCustomers(form)
    const activityParametersList = getIn(form.values, this.activityParametersPath(), []);
    const rowDataDirect = (getIn(form.values, this.fieldPath(), []) || []).map((it, index) => ({ ...it, index }));
    const rowData = constructRowData(rowDataDirect, activityParametersList, departments, customers, 'direct');
    const tempRowData = (getIn(form.values, this.fieldPath(), []) || []).map((it, index) => ({ ...it, index }));
    var rowDataIndirectTemp = constructRowDataIndirect(tempRowData);
    const rowDataIndirect = constructRowData(rowDataIndirectTemp, activityParametersList, departments, customers, 'indirect');
    const { reloadCount } = form.initialValues;
    const rowCount = rowData.length || 0;
    const rowIndirectCount = rowDataIndirect.length || 0;
    const aproxHeight = (rowCount > 10 ? 10 : rowCount) * 25 + 160;
    const aproxHeightIndirect = (rowIndirectCount > 10 ? 10 : rowIndirectCount) * 25 + 160;
    const tableKey = `${this.props.edit}_${this.props.selectedDays.length}_${this.props.periodIndex}_${activityParametersList}}`;
    const indirecttableKey = `Indirect_${this.props.edit}_${this.props.selectedDays.length}_${this.props.periodIndex}_${activityParametersList}`;

    const handleToggle = (day) => {
      this.props.toggleDayAction(day);
      if (this.gridApi) {
        this.gridApi.redrawRows();
      }
    }

    const handleAddAdjustments = (payload) => {
      this.props.addAdjustmentsline(payload)
    }
    return (
      <>
        {this.props.edit && (
          <AdjustmentAddLine
            handleAddAdjustments={handleAddAdjustments}
            key={reloadCount}
            departments={departments}
            activityParametersList={activityParametersList}
            customers={customers}
            rowData={rowData}
            props={this.props}
          />
        )}
        <DayTransformationButtonsWrap>
          <AdjustmentDays props={this.props} handleToggle={handleToggle} selectedDays={this.props.selectedDays} />
        </DayTransformationButtonsWrap>
        <FormattedMessage {...messages.direct} />
        <StyledTable
          key={tableKey}
          autosize
          pagination={false}
          height={aproxHeight}
          name={this.props.edit ? 'adjustmentsEditTable' : 'adjustmentsTable'}
          messages={messages}
          defaultConfig={
            this.props.edit ? TABLE_DEFAULTS.adjustmentsEditTable : TABLE_DEFAULTS.adjustmentsTable
          }
          columnDefs={this.columnDefs(this, this.deleteRow, this.props.selectedDays, 'direct', activityParametersList, departments, customers, form, this.props.periodIndex)}
          rowData={rowData}
          onCellValueChanged={cellInfo => this.props.edit ? this.handleCellValueChanged(cellInfo, form.setFieldValue, form.validateForm) : null}
          onPaste={data => this.props.edit ? this.onPaste(data, form.setFieldValue, form.validateForm) : null}
          getRowNodeId={data => data.id}
          onGridReady={params => this.onGridReady(params)}
          deltaRowDataMode
        />
        <StyledDiv><FormattedMessage {...messages.indirect} /></StyledDiv>
        <StyledTable
          key={indirecttableKey}
          autosize
          pagination={false}
          height={aproxHeightIndirect}
          name={this.props.edit ? 'adjustmentsIndirectEditTable' : 'adjustmentsIndirectTable'}
          messages={messages}
          defaultConfig={
            this.props.edit ? TABLE_DEFAULTS.adjustmentsIndirectEditTable : TABLE_DEFAULTS.adjustmentsIndirectTable
          }
          columnDefs={this.columnDefs(this, this.deleteRow, this.props.selectedDays, 'indirect', activityParametersList, departments, customers)}
          rowData={rowDataIndirect}
          getRowNodeId={data => data.id}
          onGridReady={params => this.onGridReady(params)}
          deltaRowDataMode
        />
      </>
    );
  };

  render() {
    return (
      <div>
        <FieldArray name={this.fieldPath()} validateOnChange render={this.renderFieldContent} />
        <DeleteDialog {...this.props} text={messages.dialogDeleteText} />
      </div>
    );
  }

  onGridReady = params => {
    this.gridApi = params.api;
  };

  handleCellValueChanged = (params, setFieldValue, validateForm) => {
    const path = this.fieldPath();
    const rowPath = `${path}.${params.data.index}`;
    setFieldValue(`${rowPath}.${params.colDef.field}`, params.newValue);
    validateForm();
    params.api.refreshCells({ force: true });
  };

  onPaste = (data, setFieldValue, validateForm) => {
    setFieldValue(this.fieldPath(), data);
    validateForm();
  };

  deleteRow = id => {
    const { remove, form } = this.currentArrayHelpers;
    const rowData = getIn(form.values, this.fieldPath());
    for (let i = 0; i < rowData.length; i++) {
      if (rowData[i].id === id) {
        remove(i);
        break;
      }
    }
  };
}

Adjustments.propTypes = {
  periodIndex: PropTypes.number,
  edit: PropTypes.bool,
  token: PropTypes.string,
};

// Plan
const mapPlanStateToProps = createStructuredSelector({
  edit: selectEditFromplan,
  periodIndex: selectPeriodIndexFromPlan,
  token: makeSelectToken(),
  departments: selectPlanDepartments,
  isShift: makeSelectIsShiftFromPlan(),
  selectedDays: makeSelectAdjustments(),
  offdays: selectOffDays,

});

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators({ toggleDayAction, addAdjustmentsline, deleteAdjustmentLines }, dispatch),
    dispatch,
  };
}

function mapPaDispatchToProps(dispatch) {
  return {
    ...bindActionCreators({ toggleDayAction, addAdjustmentsline: addPaAdjustmentsline, deleteAdjustmentLines: deletePaAdjustmentLines }, dispatch),
    dispatch,
  };
}
const withPlanConnect = connect(mapPlanStateToProps, mapDispatchToProps);

// Planning area
const mapPaStateToProps = createStructuredSelector({
  edit: selectEditFromPa,
  periodIndex: selectPeriodIndexFromPa,
  token: makeSelectToken(),
  departments: selectAreaDepartments,
  isShift: selectIsShiftFromPa,
  selectedDays: makeSelectAdjustments(),
  offdays: selectOffDaysPA,
});

const withReducer = injectReducer({ key: 'Adjustments', reducer });
const withPaConnect = connect(mapPaStateToProps, mapPaDispatchToProps);

export const PlanAdjustments = compose(withReducer, withPlanConnect, withDeleteDialog, injectIntl)(Adjustments);

export const PaAdjustments = compose(withReducer, withPaConnect, withDeleteDialog, injectIntl)(Adjustments);
