import React, { Fragment } from 'react';
import get from 'lodash/get';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';

const NameWithToolTip = (rcKey = 'regionalConfigurationName') => params => {
  const { data, valueFormatted, value } = params;
  if (!data) return <span />;

  const tip = get(data, rcKey);
  const val = <span>{valueFormatted || value}</span>;
  return (tip && <Tooltip title={tip}>{val}</Tooltip>) || val;
};

export default NameWithToolTip;

export const nameValueGetter = params => {
  if (params.data) {
    return `${params.data.activity.name} (${params.data.variableName})`;
  }
  return '';
};

export const GroupWithToolTip = (rcKey = 'regionalConfigurationName', vKey = 'activity') => params => {
  const { value, padding } = params;
  if (!value) return <span />;
  const data = value.split('||');
  if (data.length > 1) {
    return (
      <span style={{ padding: `${(padding && padding * 35) || 0}px` }}>
        <Tooltip title={data[1]}>
          <span>{data[0] || ''}</span>
        </Tooltip>
      </span>
    );
  }
  return <span />;
};

export const UOMWithToolTip = params => {
  const { value } = params;
  if (!value) return <span />;
  const tip = value.regionalConfigurationName;
  return (
    (tip && (
      <Tooltip title={tip}>
        <span>{value.name || ''}</span>
      </Tooltip>
    )) ||
    value.name ||
    value
  );
};

export const uomFilterParams = {
  valueGetter: params => {
    if (!params.data || !params.data.uom) return '';
    const { uom } = params.data;
    if (typeof uom === 'string') return uom;
    return `${uom.name} / ${uom.regionalConfigurationName}`;
  },
  applyButton: true,
  clearButton: true,
};

export const UOMNameWithToolTip = params => {
  const { value } = params;
  if (!value) return <span />;
  const tip = params.data && params.data.activity && params.data.activity.regionalConfigurationName;
  return (
    (tip && (
      <Tooltip title={tip}>
        <span>{value || ''}</span>
      </Tooltip>
    )) ||
    value.name ||
    value
  );
};
