/*
 * UploadModal Messages
 *
 * This contains all the text for the UploadModal component.
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  header: {
    id: 'app.containers.UploadModal.header',
    defaultMessage: 'This is UploadModal container !',
  },
  list: {
    id: 'app.containers.UploadModal.list',
    defaultMessage: 'List Of Uploads',
  },
  plansUploadsList: {
    id: 'app.containers.UploadModal.plansUploadsList',
    defaultMessage: 'Plan Uploads',
  },
  schedulesUploadsList: {
    id: 'app.containers.UploadModal.schedulesUploadsList',
    defaultMessage: 'Schedule Uploads',
  },
  smartProdSourceUploadsList: {
    id: 'app.containers.UploadModal.smartProdSourceUploadsList',
    defaultMessage: 'SmartProd Uploads',
  },
  planningAreasUploadsList: {
    id: 'app.containers.UploadModal.planningAreasUploadsList',
    defaultMessage: 'PA Uploads',
  },
  planningAreasBaseUploadsList: {
    id: 'app.containers.UploadModal.planningAreasBaseUploadsList',
    defaultMessage: 'PA (Baseline) Uploads',
  },
  forecastUploadsList: {
    id: 'app.containers.UploadModal.forecastUploadsList',
    defaultMessage: 'Forecast Uploads',
  },
  resultsUploadsList: {
    id: 'app.containers.UploadModal.resultsUploadsList',
    defaultMessage: 'Result Uploads',
  },
  listByUser: {
    id: 'app.containers.UploadModal.listByUser',
    defaultMessage: 'User Uploads',
  },
  newUpload: {
    id: 'app.containers.UploadModal.newUpload',
    defaultMessage: 'New Upload',
  },
  selectFile: {
    id: 'app.containers.UploadModal.selectFile',
    defaultMessage: 'Please select file to Upload',
  },
  fileReady: {
    id: 'app.containers.UploadModal.fileReady',
    defaultMessage: 'Ready to upload file {name}',
  },
  dragFile: {
    id: 'app.containers.UploadModal.dragFile',
    defaultMessage: 'Click or drag file here',
  },
  dropFile: {
    id: 'app.containers.UploadModal.dropFile',
    defaultMessage: 'Drop file here',
  },
  rejectedFile: {
    id: 'app.containers.UploadModal.rejectedFile',
    defaultMessage: 'Only Excel xlsx files are supported',
  },
  cancel: {
    id: 'app.containers.UploadModal.cancel',
    defaultMessage: 'Close',
  },
  upload: {
    id: 'app.containers.UploadModal.upload',
    defaultMessage: 'Upload',
  },
  doExport: {
    id: 'app.containers.UploadModal.doExport',
    defaultMessage: 'Export',
  },
  exportCurrent: {
    id: 'app.containers.UploadModal.exportCurrent',
    defaultMessage: 'Export Current',
  },
  fileName: {
    id: 'app.containers.UploadModal.fileName',
    defaultMessage: 'File Name',
  },
  created: {
    id: 'app.containers.UploadModal.created',
    defaultMessage: 'Created',
  },
  createdBy: {
    id: 'app.containers.UploadModal.createdBy',
    defaultMessage: 'Created By',
  },
  status: {
    id: 'app.containers.UploadModal.status',
    defaultMessage: 'Status',
  },
  recordsCreated: {
    id: 'app.containers.UploadModal.recordsCreated',
    defaultMessage: 'Records Created',
  },
  recordsFailed: {
    id: 'app.containers.UploadModal.recordsFailed',
    defaultMessage: 'Records Failed',
  },
  errorMessage: {
    id: 'app.containers.UploadModal.errorMessage',
    defaultMessage: 'Error Message',
  },
  fileUploaded: {
    id: 'app.containers.UploadModal.fileUploaded',
    defaultMessage: 'File uploaded',
  },
  basicSettings: {
    id: 'app.containers.UploadModal.basicSettings',
    defaultMessage: 'Basic Settings',
  },
  volumeCategory: {
    id: 'app.containers.UploadModal.volumeCategory',
    defaultMessage: 'Volume Category',
  },
  activitySettings: {
    id: 'app.containers.UploadModal.activitySettings',
    defaultMessage: 'Activity and Time Transformation',
  },
  availabilityShifts: {
    id: 'app.containers.UploadModal.availabilityShifts',
    defaultMessage: 'Availability and Shifts',
  },
  dailyProductivityRates: {
    id: 'app.containers.UploadModal.dailyProductivityRates',
    defaultMessage: 'Productivity Rates Daily',
  },
  weeklyProductivityRates: {
    id: 'app.containers.UploadModal.weeklyProductivityRates',
    defaultMessage: 'Productivity Rates Weekly',
  },
  dailyUPH: {
    id: 'app.containers.UploadModal.dailyUPH',
    defaultMessage: 'Units Per Hours Daily',
  },
  weeklyUPH: {
    id: 'app.containers.UploadModal.weeklyUPH',
    defaultMessage: 'Units Per Hours Weekly',
  },
  overridesActuals: {
    id: 'app.containers.UploadModal.overridesActuals',
    defaultMessage: 'Overrides and Actuals',
  },
  uploadIsRunning: {
    id: 'app.containers.UploadModal.uploadIsRunning',
    defaultMessage: 'Upload is still running. Please wait...',
  },
  uploadStarted: {
    id: 'app.containers.UploadModal.uploadStarted',
    defaultMessage: 'Transfering data, please wait...',
  },
});
