import * as React from 'react';
import { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Dialog from '@material-ui/core/Dialog';
import UIIconButton from '@material-ui/core/IconButton';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import IconButton from 'components/IconButton';
import { RunStep } from 'containers/ShiftScheduleRunsPage/types';
import { theme } from 'utils/theme';

import { formatDateTime } from '../../utils/dateTime';
import messages from './messages';

interface ScheduleRunStepsProps {
  steps: RunStep[];
}

const Container = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: 150px 400px 50px auto;
  margin: 0 0 12px 2px;
  column-gap: 10px;
  row-gap: 8px;
  align-items: start;
  overflow-y: scroll;
  max-height: 500px;
`;

const CloseButton = styled(UIIconButton)`
  font-size: 16px !important;
  width: 40px !important;
  position: relative;
  float: right;
`;
const Cell = styled.div`
  overflow: hidden;
`;
const MultilineCell = styled.div`
  white-space: pre-wrap;
`;
const Subject = styled.div`
  overflow: hidden;
  font-weight: bold;
`;

const ModalBody = styled.div`
  margin: 10px;
  white-space: pre-wrap;
`;

const statusColors = {
  NEW: '#393',
  RUNNING: '#393',
  CALCULATED: '#393',
  SENDING_TO_ENGINE: '#393',
  SENT_TO_ENGINE: '#393',
  ENGINE_INFO: '#ba2',
  SUCCESSFUL: '#393',
  FAILED: '#933',
  SUCCESSFUL_WITH_WARNINGS: '#ba2'
};

class ScheduleRunSteps extends Component<ScheduleRunStepsProps, {}> {
  state = {
    modalOpen: false,
    modalText: null,
  };

  public render() {
    const { steps } = this.props;

    return (
      <>
        <Container>{steps.map(step => this.renderStep(step))}</Container>
        <Dialog open={this.state.modalOpen} onClose={() => this.setState({ modalOpen: false })}>
          <div>
            <CloseButton
              key="close"
              aria-label="close"
              color="inherit"
              onClick={() => this.setState({ modalOpen: false })}
            >
              <FontAwesomeIcon icon="times" size="sm" />
            </CloseButton>
            <ModalBody>{this.state.modalText}</ModalBody>
          </div>
        </Dialog>
      </>
    );
  }

  private renderStep(step: RunStep) {
    const statusCode = step.status.toUpperCase();
    const color = statusColors[statusCode] || theme.color.black;
    const statusMessageCode = messages[`run_status_${statusCode.toLowerCase()}`];
    const { message } = step;
    return (
      <React.Fragment key={step.id}>
        <Cell style={{ color }}>{statusMessageCode ? <FormattedMessage {...statusMessageCode} /> : step.status}</Cell>
        <MultilineCell>{message}</MultilineCell>
        <IconButton
          onClick={() => {
            this.setState({ modalOpen: true, modalText: step.technicalMessage });
          }}
          label={messages.log}
        />
        <Cell>{formatDateTime(step.created)}</Cell>
      </React.Fragment>
    );
  }
}

export function calculateStepsHeight(steps: RunStep[]) {
  let height = 0;
  steps.forEach(step => {
    if (step.message) {
      height += step.message.split(/\r\n|\r|\n/).length * 28;
    } else {
      height += 28;
    }
  });
  return height > 500 ? 500 : height;
}

export default ScheduleRunSteps;
