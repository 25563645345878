/*
 * DirtyDialog Messages
 *
 * This contains all the text for the DirtyDialog component.
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  header: {
    id: 'app.components.DirtyDialog.header',
    defaultMessage: 'There are unsaved changes',
  },
  headerDelete: {
    id: 'app.components.DirtyDialog.headerDelete',
    defaultMessage: 'Item will be deleted',
  },
  cancel: {
    id: 'app.components.DirtyDialog.cancel',
    defaultMessage: 'Cancel',
  },
  saveFromTo: {
    id: 'app.components.DirtyDialog.save',
    defaultMessage: 'Save',
  },
  discard: {
    id: 'app.components.DirtyDialog.discard',
    defaultMessage: "Cancel",
  },
  dontSave: {
    id: 'app.components.DirtyDialog.dontSave',
    defaultMessage: "Don't save",
  },
  text: {
    id: 'app.components.DirtyDialog.text',
    defaultMessage: 'Some data is not saved. Do you want to save changes now?',
  },
  delete: {
    id: 'app.components.DirtyDialog.delete',
    defaultMessage: 'Delete',
  },
  confirmDelete: {
    id: 'app.components.DirtyDialog.confirmDelete',
    defaultMessage: 'Please confirm deleting this item',
  },
  dirtyForm: {
    id: 'app.components.DirtyDialog.dirtyForm',
    defaultMessage: 'You have unsaved date. Do you want to leave and discard them?',
  },
  emptyHeader:{
    id: 'app.components.DirtyDialog.emptyHeader',
    defaultMessage: ` `,
  },
  planningAreaText:{
    id: 'app.components.DirtyDialog.planningAreaText',
    defaultMessage: `Plan is going to be created out of this Planning Area and assigned to created Master Plan. Do you approve?`,
  }
});
