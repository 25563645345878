import React from 'react';
import { FormattedMessage } from 'react-intl';
import find from 'lodash/find';
import get from 'lodash/get';
import messages from './messages';

const CustomerCellRenderer = customers => params => {
  if (!params.data) return <span />;
  if (!params.value) return <FormattedMessage {...messages.all} />;
  const { value } = params;
  if (typeof value === 'object') return <span>{params.value.label}</span>;

  const customer = find(customers, { id: params.value });
  return <span>{(customer && customer.name) || ''}</span>;
};

export default CustomerCellRenderer;

export const customerFormat = (customers, formatMessage) => params => {
  if (!params.data) return null;
  if (!params.value || !customers) return formatMessage(messages.all);
  const customer = find(customers, { id: params.value });
  return (customer && customer.name) || '';
};

export const customerFilterParams = (customers, formatMessage) => ({
  valueGetter: params => {
    if (!params.data || !params.data.customerId) return formatMessage(messages.all);
    const customer = find(customers, { id: params.data.customerId });
    return (customer && customer.name) || '';
  },
  applyButton: true,
  clearButton: true,
});

export function getCustomers(formik) {
  let customers = get(formik, ['values', 'customers']);
  if (customers === undefined) customers = get(formik, ['values', 'planningArea', 'customers']);
  return customers;
}
