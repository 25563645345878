import { createSelector } from 'reselect';
import {
  FETCH_MESSAGES,
  FETCH_LANGUAGES,
  FAILED_MESSAGES,
  CHANGE_LOCALE,
  SAVE_MESSAGES,
  SAVE_LANGUAGES,
  LOCALE_KEY,
  ADD_LOCALE,
  LOCALE_ADDED,
} from './constants';

const savedLocale = localStorage && localStorage.getItem(LOCALE_KEY);
const DEFAULT_LOCALE = savedLocale || 'en';

export const getLocale = state => state.get('intl').locale || DEFAULT_LOCALE;
export const getLanguages = state => state.get('intl').languages;
export const getLocaleState = state => state.get('intl').locales;
const getUser = state => state.getIn(['user', 'user']);
const getMessages = state => state.get('intl').messages;

export const intlSelector = createSelector(
  getLocale,
  getMessages,
  getLanguages,
  getLocaleState,
  getUser,
  (locale, messages, languages, locales, user) => ({
    locale,
    messages: messages[locale],
    languages,
    localeAdded: locales[locale] || false,
    user,
  }),
);

export const fetchMessages = locale => ({
  type: FETCH_MESSAGES,
  locale,
});

export const fetchLanguages = () => ({
  type: FETCH_LANGUAGES,
});

export const changeLocale = locale => ({
  type: CHANGE_LOCALE,
  locale,
});

export const addLocale = locale => ({
  type: ADD_LOCALE,
  locale,
});

export const initialState = {
  locale: DEFAULT_LOCALE,
  languages: null,
  messages: {},
  locales: {},
};

function intlReducer(state = initialState, action) {
  switch (action.type) {
    case CHANGE_LOCALE:
      return { ...state, locale: action.locale };
    case LOCALE_ADDED:
      return { ...state, locales: { ...state.locales, [action.locale]: true } };
    case FETCH_MESSAGES: {
      const messages = { ...state.messages };
      const { locale } = action;
      if (!(locale in messages)) {
        messages[locale] = {
          status: 'loading',
        };
      } else {
        messages[locale] = { ...messages[locale], status: 'loaging' };
      }
      return { ...state, messages };
    }
    case SAVE_MESSAGES: {
      const messages = { ...state.messages };
      messages[action.locale] = { data: action.data || {}, status: 'loaded' };
      return { ...state, messages };
    }
    case FAILED_MESSAGES: {
      const messages = { ...state.messages };
      messages[action.locale] = { ...messages[action.locale], status: 'failed' };
      return { ...state, messages };
    }
    case SAVE_LANGUAGES:
      return { ...state, languages: action.data };

    default:
      return state;
  }
}

export default intlReducer;
