/*
 * SmartProdSourceListPage Messages
 *
 * This contains all the text for the SmartProdSourceListPage component.
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  header: {
    id: 'app.containers.SmartProdSourceListPage.header',
    defaultMessage: 'SmartProd Sources',
  },
  disabled: {
    id: 'app.containers.SmartProdSourceListPage.disabled',
    defaultMessage: 'Status',
  },
  updatedBy: {
    id: 'app.containers.SmartProdSourceListPage.updatedBy',
    defaultMessage: 'Modified By',
  },
  updated: {
    id: 'app.containers.SmartProdSourceListPage.updated',
    defaultMessage: 'Modified',
  },
  content: {
    id: 'app.containers.SmartProdSourceListPage.content',
    defaultMessage: 'SmartProd Sources',
  },
  add: {
    id: 'app.containers.SmartProdSourceListPage.add',
    defaultMessage: 'Add',
  },
  removeSelected: {
    id: 'app.containers.SmartProdSourceListPage.removeSelected',
    defaultMessage: 'Disable selected',
  },
  name: {
    id: 'app.containers.SmartProdSourceListPage.name',
    defaultMessage: 'Name',
  },
  id: {
    id: 'app.containers.SmartProdSourceListPage.id',
    defaultMessage: 'Id',
  },
  facilities: {
    id: 'app.containers.SmartProdSourceListPage.facilities',
    defaultMessage: 'Facilities',
  },
  customers: {
    id: 'app.containers.SmartProdSourceListPage.customers',
    defaultMessage: 'Customers',
  },
  managementUnits: {
    id: 'app.containers.SmartProdSourceListPage.managementUnits',
    defaultMessage: 'Management Units',
  },
  managementUnit: {
    id: 'app.containers.SmartProdSourceListPage.managementUnit',
    defaultMessage: 'Management Unit',
  },
  validFrom: {
    id: 'app.containers.SmartProdSourceListPage.validFrom',
    defaultMessage: 'Valid From',
  },
  validTo: {
    id: 'app.containers.SmartProdSourceListPage.validTo',
    defaultMessage: 'Valid To',
  },
  created: {
    id: 'app.containers.SmartProdSourceListPage.created',
    defaultMessage: 'Created',
  },
  createdBy: {
    id: 'app.containers.SmartProdSourceListPage.createdBy',
    defaultMessage: 'Created By',
  },
  action: {
    id: 'app.containers.SmartProdSourceListPage.action',
    defaultMessage: 'Actions',
  },
  deleteConfirmText: {
    id: 'app.containers.SmartProdSourceListPage.deleteConfirmText',
    defaultMessage: 'Are you sure? Disabling items: {items}',
  },
  enableConfirmText: {
    id: 'app.containers.SmartProdSourceListPage.enableConfirmText',
    defaultMessage: 'Are you sure? Enabling items: {items}',
  },
  enableConfirmButton: {
    id: 'app.containers.SmartProdSourceListPage.enableConfirmButton',
    defaultMessage: 'ENABLE',
  },
  enableTitle: {
    id: 'app.containers.SmartProdSourceListPage.enableTitle',
    defaultMessage: 'Enable Action',
  },
  disableConfirmButton: {
    id: 'app.containers.SmartProdSourceListPage.disableConfirmButton',
    defaultMessage: 'DISABLE',
  },
  disableTitle: {
    id: 'app.containers.SmartProdSourceListPage.disableTitle',
    defaultMessage: 'Disable Action',
  },
  saveTableConfig: {
    id: 'app.containers.SmartProdSourceListPage.saveTableConfig',
    defaultMessage: 'Save Current To User View',
  },
  defaultView: {
    id: 'app.containers.SmartProdSourceListPage.defaultView',
    defaultMessage: 'Default View',
  },
  userView: {
    id: 'app.containers.SmartProdSourceListPage.userView',
    defaultMessage: 'User View',
  },
  deleted: {
    id: 'app.containers.SmartProdSourceListPage.deleted',
    defaultMessage: 'Status',
  },
  area: {
    id: 'app.containers.SmartProdSourceListPage.area',
    defaultMessage: 'Planning Area',
  },
  facility: {
    id: 'app.containers.SmartProdSourceListPage.facility',
    defaultMessage: 'Facility',
  },
  smartProdInstance: {
    id: 'app.containers.SmartProdSourceListPage.smartProdInstance',
    defaultMessage: 'SmartProd Instance',
  },
  whId: {
    id: 'app.containers.SmartProdSourceListPage.whId',
    defaultMessage: 'WHID',
  },
  description: {
    id: 'app.containers.SmartProdSourceListPage.description',
    defaultMessage: 'Description',
  },
  startDate: {
    id: 'app.containers.SmartProdSourceListPage.startDate',
    defaultMessage: 'Start Date',
  },
  smartProdSourceListPage_Enabled: {
    id: 'app.containers.SmartProdSourceListPage.smartProdSourceListPage_Enabled',
    defaultMessage: 'Load enabled SmartProd Sources',
  },
  smartProdSourceListPage_Disabled: {
    id: 'app.containers.SmartProdSourceListPage.smartProdSourceListPage_Disabled',
    defaultMessage: 'Load disabled SmartProd Sources',
  },
  requestSmartVolumeForecast: {
    id: 'app.containers.SmartProdSourceListPage.requestSmartVolumeForecast',
    defaultMessage: 'Request SmartVolume forecast',
  }
});
