import { DateTime } from 'luxon';
import { ApiOmsCodeEffortPlanningParametersType, ApiProductivityRateForecastType } from "types/drep-backend";

export enum ModifyMultipleDialogType {
  EFFORT_FORECAST = 'EFFORT_FORECAST',
  PRODUCTIVITY_RATE = 'PRODUCTIVITY_RATE',
  ADJUSTMENTS_OVERVIEW = 'ADJUSTMENTS_OVERVIEW'
}

export enum ModifyMultipleDataSource {
  'OMS_CODE' = 0,
  'ACTIVITY' = 1,
}

export enum ModifyMultipleChangeType {
  PERCENTAGE = 'PERCENTAGE',
  VALUE = 'VALUE',
  DATA_SOURCE = 'DATA_SOURCE',
}

export type ModifyMultipleFormSettings = {
  startDate: DateTime;
  endDate: DateTime;
  changeType: ModifyMultipleChangeType;
  selectedData: { [key: number]: boolean };
  valueInput: string;
  percentageInput: string;
  dataTypeChangeEffortForecast: ApiOmsCodeEffortPlanningParametersType;
  dataTypeChangeProductivityRate: ApiProductivityRateForecastType;
};

export type ModifyMultipleFormSettingsAdjustments = {
  startDate: any;
  endDate: any;
  changeType: ModifyMultipleChangeType;
  selectedData: { [key: number]: boolean };
  valueInput: string;
  percentageInput: string;
  dataTypeChangeEffortForecast: ApiOmsCodeEffortPlanningParametersType;
  dataTypeChangeProductivityRate: ApiProductivityRateForecastType;
};
