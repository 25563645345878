import { SET_VIEW_MODE } from 'components/ViewModePopover/actions';

function viewModeSettingsReducer(state = {}, action) {
  switch (action.type) {
    case SET_VIEW_MODE:
      return { viewModeData: action.payload };
    default:
      return state;
  }
}

export default viewModeSettingsReducer;
