/* eslint-disable guard-for-in,no-restricted-syntax */
import React from 'react';
import { connect as formikConnect } from 'formik';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

function convertFormikErrorsToToast(formik) {
  const messages = [];
  const extractNameElements = node => {
    if (typeof node === 'string' && node) {
      messages.push(node);
    } else if (Array.isArray(node)) {
      for (const item of node) {
        extractNameElements(item);
      }
    } else if (typeof node === 'object') {
      for (const child in node) {
        extractNameElements(node[child]);
      }
    }
  };
  if (!formik.isValid) {
    extractNameElements(formik.errors);
    if (messages.length > 0) {
      toast.error(messages.join('. '));
    }
  }
}

class FormikErrorsPopup extends React.Component {
  currentSubmitCount = 0;

  render() {
    const { submitCount, isSubmitting, isValidating } = this.props.formik;
    if (isSubmitting && !isValidating) {
      // errors appear during form submission but after validation
      if (submitCount > this.currentSubmitCount) {
        convertFormikErrorsToToast(this.props.formik);
      }
      this.currentSubmitCount = submitCount;
    }
    return null;
  }
}

FormikErrorsPopup.propTypes = {
  formik: PropTypes.object,
};

export default formikConnect(FormikErrorsPopup);
