import { push } from 'connected-react-router';
import jscookie from 'js-cookie';
import moment from 'moment';
import { SubmissionError } from 'redux-form';
import { call, fork, put, select, take, takeLatest } from 'redux-saga/effects';

import {
  extractServiceTicket,
  loadCookie,
  loadUser,
  storeST,
  storeTokenToCookie,
} from '../../utils/api';
import { logoutRedirect } from '../../utils/azure';
import { PATHS } from '../App/constants';
import { login, logoutAction, reset } from './actions';
import { COOKIE_CAS_ST, COOKIE_CAS_TGT, LOAD_USER, LOGOUT } from './constants';
import { makeSelectToken } from './selectors';
import { ACTIVE_ENV } from '../../utils/activeEnv';

/**
 * We need to use take first since there could be multiple load user calls. takeLatest would cancel the previous which could lead to logout of user.
 *
 * @param pattern
 * @param saga
 * @param args
 * @returns {IterableIterator<*>}
 */
function* takeFirst(pattern, saga, ...args) {
  const task = yield fork(function* () {
    while (true) {
      const action = yield take(pattern);
      yield call(saga, ...args.concat(action));
    }
  });
  return task;
}

export default function* defaultSaga() {
  yield takeLatest(login.REQUEST, loginHandler);
  yield takeLatest(reset.REQUEST, resetHandler);
  yield takeLatest(LOGOUT, logoutHandler);
  yield takeFirst(LOAD_USER, loadUserHandler);
}

function* loadUserHandler() {
  const token = yield select(makeSelectToken());
  return yield call(loadUser, token); // may do forward to login page
}

function* logoutHandler() {
  const tgt = loadCookie(COOKIE_CAS_TGT);
  const st = loadCookie(COOKIE_CAS_ST);
  if (!process.env.SKIP_CAS_LOGIN) {
    yield call(clearTicketInBe, st);
    yield call(clearTgt, tgt);
  }
  clearCookie(COOKIE_CAS_ST);
  clearCookie(COOKIE_CAS_TGT);
  // yield put(push(PATHS.loginUser));
  logoutRedirect();
}

function* resetHandler(action) {
  function* onValidCredentials(tgt, serviceTicket) {
    const { username } = action.payload;
  }
  yield call(validateCredentials, action, onValidCredentials, reset);
}

function* clearTgt(ticket) {
  const { basePathCas } = ACTIVE_ENV;
  const time = moment().format();
  const data = { ticketGrantingTicketId: ticket, created_at: time, updated_at: time };
  const formBody = [];
  for (const property in data) {
    // eslint-disable-line
    const encodedKey = encodeURIComponent(property);
    const encodedValue = encodeURIComponent(data[property]);
    formBody.push(`${encodedKey}=${encodedValue}`);
  }
  fetch(`${basePathCas}/v1/tickets`, {
    method: 'DELETE',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
    body: formBody.join('&'),
  }).then(resultTicket => {
    console.log('Cleared cas tgc ', resultTicket);
  });
}

function* clearTicketInBe(token) {
  const fullUrl = `${ACTIVE_ENV.basePathBe}/logout`;
  const fetchOptions = {
    method: 'GET',
    headers: { ticket: token },
  };
  fetch(fullUrl, fetchOptions).then(response => {
    console.log('BE ticker cleared with response', response);
  });
}

function* extractTGT(action) {
  const { basePathCas } = ACTIVE_ENV;
  const details = action.payload;
  let formBody = [];
  for (const property in details) {
    // eslint-disable-line
    const encodedKey = encodeURIComponent(property);
    const encodedValue = encodeURIComponent(details[property]);
    formBody.push(`${encodedKey}=${encodedValue}`);
  }
  formBody = formBody.join('&');

  const TGT = yield call(() =>
    fetch(`${basePathCas}/v1/tickets`, {
      method: 'POST',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      body: formBody,
    }).then(response => {
      // console.log('Parsing TGT response', response);
      if (response.status >= 200 && response.status < 300) {
        const locationHeader = response.headers.get('Location');
        if (locationHeader) {
          const lastSlash = locationHeader.lastIndexOf('/');
          return locationHeader.substring(lastSlash + 1);
        }
      } else {
        throw response;
      }
      return null;
    }),
  );
  return TGT;
}

function* validateCredentials(action, onSuccess, formAction) {
  try {
    let tgt;
    let casResponse;
    const skipCas = process.env.SKIP_CAS_LOGIN;
    if (skipCas) {
      tgt = process.env.SKIP_CAS_LOGIN_TICKET || 'ST-8052-Ip5KnDiXrjfIaB0IM47L-cas';
      casResponse = {
        ok: true,
        text() {
          return tgt;
        },
      };
    } else {
      tgt = yield call(extractTGT, action);
      if (tgt) {
        casResponse = yield call(extractServiceTicket, action, tgt);
      }
    }
    if (casResponse && casResponse.ok) {
      const serviceTicket = yield casResponse.text();
      yield call(onSuccess, tgt, serviceTicket);
    } else {
      switch (casResponse.status) {
        case 404:
          throw new Error(`${casResponse.status}: ${casResponse.statusText} on ${casResponse.url}`);
        default:
          throw new Error(casResponse);
      }
    }
  } catch (err) {
    if (process.env.NODE_ENV !== 'production') console.error('Login error', err);
    const formError = new SubmissionError({
      // login: 'User with this login is not found', // specific field error
      _error: 'Login failed, please check your credentials and try again', // global form error
    });
    yield put(formAction.failure(formError));
  }
}

function* loginHandler(action) {
  function* onValidCredentials(tgt, serviceTicket) {
    storeTokenToCookie(tgt, COOKIE_CAS_TGT);
    yield call(storeST, serviceTicket);
    const response = yield call(loadUser, serviceTicket);
    if (response.isForward !== true) {
      if ((response.data.roles || []).length === 0) {
        // no role in DREP = quit
        yield put(logoutAction());
        throw new Error('No roles in SmartPlan');
      }
      yield put(push(PATHS.mainScreen));
    }
  }
  yield call(validateCredentials, action, onValidCredentials, login);
}

function clearCookie(cookieName) {
  jscookie.remove(cookieName, { path: '/' });
}
