import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { ErrorMessage, Field, Formik } from 'formik';
import { isEmpty } from 'lodash';
import { injectIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';
import styled from 'styled-components';
import * as Yup from 'yup';
import { DateTime } from 'luxon';
import BasePage from 'components/BasePage';
import { EditableList } from 'components/CdmEditableList';
import { FacilityItem } from 'components/CdmEditableList/FacilityItem';
import {ManagementItemNoUnits} from 'components/CdmEditableList/ManagementItem';
import { DetailToolbarButtonsWrap } from 'components/DetailPageShared';
import { FormikText, FormikTextInput, InputError } from 'components/FormikTextInput';
import { BackButton, SaveButton } from 'components/IconButton';
import Label from 'components/Label';
import NamedSection from 'components/NamedSection';
import { PATHS } from 'containers/App/constants';
import { getToken } from 'containers/App/selectors';
import { storePlanDetails } from 'containers/PlanDetailPage/actions';
import { selectMasterPlanFromPlan } from 'containers/PlanDetailPage/selectors';
import { withUrl } from 'utils/api';
import { required } from 'utils/form/validations';
import { fetchData } from 'utils/reduxApi';
import withSecurity, { PERMISSIONS } from 'utils/security';
import { ApiUpdateMasterPlanDTO } from 'types/drep-backend.d';
import AutocompleteSelect from 'components/AutocompleteSelect';

import messages from './messages';

const Form = styled.form``;

const ValidatePage = props => (
  <BasePage labelMessage={messages.headerMasterPlanInsert} labelValues={{ name: props.headerName || '' }}>
    <Form>{props.children}</Form>
  </BasePage>
);

ValidatePage.propTypes = {
  children: PropTypes.node,
  headerName: PropTypes.string,
};

const LabelLocal = styled(Label)`
  flex-basis: 35%;
`;

const StyledSelect = styled(AutocompleteSelect)`
  width: 12.5em;
  z-index: 1200;
`;

export const Section = styled(NamedSection)`
  max-width: none;
  min-height: calc(100% - 18px);
  input {
    background-color: ${props => props.theme.color.grey5};
    width: 100%;
  }
`;


export const TopPadding = styled.div`
  margin-top: 72px;
`;

Yup.addMethod(Yup.array, 'allItemsContainValue', function (errorMessage) {
  return this.test(`array-has-values`, errorMessage, function (value) {
    const { path, createError } = this;
    return !value || value.filter(x => !x.id).length !== 0 ? createError({ path, message: errorMessage }) : true;
  });
});

export const validationSchema = Yup.object().shape({
  name: Yup.string().required('Name is mandatory'),
  facilities: Yup.array().min(1, 'Facilities are mandatory').allItemsContainValue('Select a facility'),
  customers: Yup.array().min(1, 'Customers are mandatory').allItemsContainValue('Select a customer'),
  //managementUnits: Yup.array().min(1, 'Management Units are mandatory').allItemsContainValue('Select a management unit'),
  bothDatePartsRequired: Yup.object().when(['scheduleStartDay', 'scheduleStartTime'], {
    is: (val1, val2) => !!val1 !== !!val2,
    then: Yup.object().required('Both Schedule Start Date and Schedule Start Time should be specified'),
  }),
});

type Props = {
  history: { push: Function };
};

const CreateMasterPlanPage: React.FC<Props> = props => {
  const token = useSelector(getToken);
  const planDetails = useSelector(selectMasterPlanFromPlan);// @ts-ignore
  const facilitiesFromPlan = planDetails?.planningArea?.facilities ? planDetails?.planningArea?.facilities : [];// @ts-ignore
  const customersfromPlan = planDetails?.planningArea?.customers ? planDetails?.planningArea?.customers : [];// @ts-ignore
  const managementUnitsfromPlan =  planDetails?.planningArea?.managementUnits ? planDetails?.planningArea?.managementUnits : [];
  const INITIAL_VALUES = { name: '', description: '', facilities: facilitiesFromPlan, customers: customersfromPlan, managementUnits: managementUnitsfromPlan, planId: null };
  const dispatch = useDispatch();
  React.useEffect(() => {
    return () => {
      dispatch(storePlanDetails());
    }
  }, [])

  const handleSubmit = async (values, formik) => {
    const filterMU = values.managementUnits?.filter((mu) => mu.id) || [];
    const body: ApiUpdateMasterPlanDTO = {
      ...values,
      customers: values.customers.map(c => c.id),
      managementUnits: filterMU?.map(m => ({
        managementUnitId: m.id,
        ...m
      })),
      facilities: values.facilities.map(facility => ({
        facilityId: facility.id,
        ...facility,
      })),
    };
    const result = await fetchData(withUrl(`/masterPlan`).put(body).andToken(token), dispatch);
    if (result.isOk) {
      if (planDetails || values?.planId) {
        const plans = [];//@ts-ignore
        const newValue = new Date(DateTime.local());
        const year = newValue.toLocaleString("default", { year: "numeric" });
        const month = newValue.toLocaleString("default", { month: "2-digit" });
        const day = newValue.toLocaleString("default", { day: "2-digit" });
        const formattedDate = year + "-" + month + "-" + day;
        plans.push({ //@ts-ignore
          planId: planDetails?.id || values?.planId,
          validFrom: formattedDate,
          validTo: '2100-01-01'
        })
        const plansPayload = {
          plans: plans
        }

        const savePlan = await fetchData(withUrl(`/masterPlan/${result.data.id}`).post(plansPayload).andToken(token), dispatch);
        if (savePlan.isOk) {
          formik.resetForm(INITIAL_VALUES);
          props.history.push(`${PATHS.masterPlanDetail.replace(':id', result.data.id)}?isEdit=true`);
        }else{
          formik.resetForm(INITIAL_VALUES);
          props.history.push(`${PATHS.masterPlanDetail.replace(':id', result.data.id)}?isEdit=true`);
        }
      } else {
        formik.resetForm(INITIAL_VALUES);
        props.history.push(`${PATHS.masterPlanDetail.replace(':id', result.data.id)}?isEdit=true`);
      }

    }
    formik.setSubmitting(false);
  };

  return (
    <Formik
      onSubmit={handleSubmit}
      enableReinitialize
      validationSchema={validationSchema}
      initialValues={INITIAL_VALUES}
      render={formikBag => (
        <ValidatePage {...props}>
          <DetailToolbarButtonsWrap>
            <BackButton {...props} />
            <SaveButton onClick={() => formikBag.submitForm()} disabled={!isEmpty(formikBag.errors)} />
          </DetailToolbarButtonsWrap>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <div style={{ marginTop: 83 }} />
            </Grid>
            <Grid item xs={6}>
              <Section message={messages.basicInfo} noMaxWidth>
                <Grid container spacing={1}>
                  <Grid item xs={5}>
                    <LabelLocal {...messages.name} />
                  </Grid>
                  <Grid item xs={7}>
                    <Field name="name" component={FormikTextInput} validate={required} style={{ width: '100%' }} />
                  </Grid>
                  <Grid item xs={5}>
                    <LabelLocal {...messages.description} />
                  </Grid>
                  <Grid item xs={7}>
                    <Field name="description" component={FormikTextInput} style={{ width: '100%' }} />
                  </Grid>
                  <Grid item xs={5}>
                    <LabelLocal {...messages.plansOptional} />
                  </Grid>
                  <Grid item xs={7}>
                    <StyledSelect
                      key="plans"
                      entity="plans"
                      urlAppendixCallback={() => `&pageSize=1000&usedInMasterPlans=${true}`}
                      setValue={value => {
                        formikBag.setFieldValue('planId', value?.id);
                        formikBag.setFieldValue('facilities', value?.planningArea?.facilities);
                        formikBag.setFieldValue('customers', value?.planningArea?.customers);
                        formikBag.setFieldValue('managementUnits', value?.planningArea?.managementUnits);
                      }}
                      excludePlans={true}
                      token={token}
                      isClearable = {true}
                    />
                  </Grid>
                </Grid>
              </Section>
            </Grid>
            <Grid item xs={6} />
            <Grid item xs={4}>
              <Section message={messages.facilities} noMaxWidth>
                <EditableList formik={formikBag} field="facilities" isEdit ={!formikBag?.values?.planId} editComponent={FacilityItem} />
                <InputError>
                  <ErrorMessage name="facilities" />
                </InputError>
              </Section>
            </Grid>
            <Grid item xs={4}>
              <Section message={messages.customers} noMaxWidth>
                <EditableList formik={formikBag} field="customers" isEdit ={!formikBag?.values?.planId}/>
                <InputError>
                  <ErrorMessage name="customers" />
                </InputError>
              </Section>
            </Grid>
            <Grid item xs={4}>
              <Section message={messages.managementUnits} noMaxWidth>
                <EditableList formik={formikBag} field="managementUnits" isEdit ={!formikBag?.values?.planId} editComponent={ManagementItemNoUnits}/>
                <InputError>
                  <ErrorMessage name="managementUnits" />
                </InputError>
              </Section>
            </Grid>
          </Grid>
        </ValidatePage>
      )}
    />
  );
};

export default compose(
  injectIntl,
  withSecurity(() => PERMISSIONS.VIEW_MASTER_PLAN),
  // @ts-ignore
)(CreateMasterPlanPage);
