import React from 'react';
import PropTypes from 'prop-types';
import Color from 'color';
import * as echarts from 'echarts';
import ReactEcharts from 'echarts-for-react';
import { injectIntl } from 'react-intl';
import { compose } from 'redux';
import { withTheme } from 'styled-components';
import * as zrUtil from 'zrender/lib/core/util';

import { makeColors } from '../../utils/theme';

const getGrandientForColor = (color, switchAxes) =>
  new echarts.graphic.LinearGradient(switchAxes ? 1 : 0, 0, 0, switchAxes ? 0 : 1, [
    {
      offset: 0,
      color: Color(color).blacken(0.2).lighten(0.5).hex(),
    },
    {
      offset: 0.5,
      color: Color(color).blacken(0.2).hex(),
    },
    {
      offset: 1,
      color,
    },
  ]);

const colorToEmphasis = color => Color(color).lighten(0.3).hex();

const itemStyle = (color, switchAxes) => ({
  normal: {
    color: getGrandientForColor(color, switchAxes),
  },
  emphasis: {
    color: getGrandientForColor(colorToEmphasis(color, switchAxes)),
  },
});

class BarGraph extends React.PureComponent {
  colors = makeColors(this.props.colorCount ? this.props.colorCount + 5 : this.props.data.length + 5);

  dataLength = this.props.switchAxis ? this.props.axisX.length : this.props.data.length;

  height = 600 + (this.props.heightPerData ? this.props.heightPerData(this.dataLength) : 0);

  posList = [
    'left',
    'right',
    'top',
    'bottom',
    'inside',
    'insideTop',
    'insideLeft',
    'insideRight',
    'insideBottom',
    'insideTopLeft',
    'insideTopRight',
    'insideBottomLeft',
    'insideBottomRight',
  ];

  app = {
    configParameters: {
      rotate: {
        min: -90,
        max: 90,
      },
      align: {
        options: {
          left: 'left',
          center: 'center',
          right: 'right',
        },
      },
      verticalAlign: {
        options: {
          top: 'top',
          middle: 'middle',
          bottom: 'bottom',
        },
      },
      position: {
        options: zrUtil.reduce(
          this.posList,
          (map, pos) => {
            map[pos] = pos; // eslint-disable-line
            return map;
          },
          {},
        ),
      },
      distance: {
        min: 0,
        max: 100,
      },
    },
  };

  app = {
    config: {
      rotate: 90,
      align: 'left',
      verticalAlign: 'middle',
      position: 'insideBottom',
      distance: 15,
      onChange() {
        const labelOption = {
          normal: {
            rotate: this.app.config.rotate,
            align: this.app.config.align,
            verticalAlign: this.app.config.verticalAlign,
            position: this.app.config.position,
            distance: this.app.config.distance,
          },
        };
        this.myChart.setOption({
          series: [
            {
              label: labelOption,
            },
            {
              label: labelOption,
            },
            {
              label: labelOption,
            },
            {
              label: labelOption,
            },
          ],
        });
      },
    },
  };

  labelComplex = {
    normal: {
      show: true,
      position: this.app.config.position,
      distance: this.app.config.distance,
      align: this.app.config.align,
      verticalAlign: this.app.config.verticalAlign,
      rotate: this.app.config.rotate,
      formatter: '{c}  {name|{a}}',
      fontSize: 16,
      rich: {
        name: {
          textBorderColor: '#fff',
        },
      },
    },
  };

  labelNormal = {
    normal: {
      show: true,
      position: 'insideRight',
    },
  };

  makeOption = () => ({
    grid: {
      y:
        (this.props.spaceBeforeGraph &&
          this.props.spaceBeforeGraph(this.dataLength, this.props.data, this.props.axisX)) ||
        60,
      y2:
        (this.props.spaceAfterGraph && this.props.spaceAfterGraph(this.dataLength, this.props.data, this.props.axisX)) ||
        60,
      x:
        (this.props.spaceInfrontGraph &&
          this.props.spaceInfrontGraph(this.dataLength, this.props.data, this.props.axisX)) ||
        100,
      x2:
        (this.props.spaceTralingGraph &&
          this.props.spaceTralingGraph(this.dataLength, this.props.data, this.props.axisX)) ||
        100,
    },
    tooltip: this.getTooltip(),
    legend: {
      data: this.props.data.map(this.getName),
    },
    toolbox: {
      show: true,
      orient: 'vertical',
      top: 'center',
      itemGap: 30,
      right: '20px',
      feature: {
        mark: { show: true, title: 'Mark' },
        magicType: this.getMagicType(),
        dataZoom: {
          show: true,
          title: { zoom: 'Zoom Toggle', back: 'Zoom Back' },
          yAxisIndex: this.props.switchAxis ? undefined : false,
          xAxisIndex: this.props.switchAxis ? false : undefined,
        },
        restore: { show: true, title: 'Restore' },
        saveAsImage: { show: true, title: 'Save' },
      },
    },
    calculable: true,
    xAxis: this.getXAxis(),
    yAxis: this.getYAxis(),
    series: this.props.data.map((item, index) => ({
      type: 'bar',
      id: item.name,
      barGap: 0,
      label:
        (this.props.hasLabelComplex && this.labelComplex) ||
        (this.props.hasLabelNormal && this.labelNormal) ||
        (this.props.label && this.props.label(item, index)),
      data: item.data,
      name: this.getName(item),
      stack: item.stack,
      itemStyle: itemStyle(this.colors[item.colorIndex === undefined ? index : item.colorIndex], this.props.switchAxis),
    })),
  });

  getTooltip() {
    if (this.props.hasLabelComplex)
      return {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
      };
    return {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
      formatter: this.props.tooltipFormatter,
    };
  }

  getMagicType() {
    if (this.props.featureMagicTypeDisable) return undefined;
    return {
      show: true,
      type: ['line', 'bar', 'stack', 'tiled'],
      title: { line: 'Line', bar: 'Bar', stack: 'Stacked', tiled: 'Tiled' },
    };
  }

  getXAxis() {
    if (this.props.switchAxis)
      return [
        {
          type: 'value',
          axisLabel: {
            formatter: this.props.axisYFormatter,
          },
        },
      ];
    return [
      {
        type: 'category',
        axisTick: { show: false },
        data: this.props.axisX,
        axisLabel: {
          formatter: this.props.axisXFormatter,
        },
      },
    ];
  }

  getYAxis() {
    if (this.props.switchAxis)
      return [
        {
          type: 'category',
          axisTick: { show: false },
          data: this.props.axisX,
          axisLabel: {
            formatter: this.props.axisXFormatter,
          },
        },
      ];
    return [
      {
        type: 'value',
        axisLabel: {
          formatter: this.props.axisYFormatter,
        },
      },
    ];
  }

  getName = item => {
    if (this.props.seriesNameFormatter) {
      return this.props.seriesNameFormatter(item);
    }
    if (this.props.messages[item.name]) {
      return this.props.intl.formatMessage(this.props.messages[item.name]);
    }
    return item.name;
  };

  style = { height: this.height };

  onChartReady = chart => {
    const { onChartReady } = this.props;
    this.myChart = chart;
    onChartReady && onChartReady(chart);
  };

  render() {
    const { otherProps = {}, getOption } = this.props;
    const option = this.makeOption();
    getOption && getOption(option);
    return <ReactEcharts notMerge option={option} onChartReady={this.onChartReady} style={this.style} {...otherProps} />;
  }
}

BarGraph.propTypes = {
  heightPerData: PropTypes.func,
  hasLabelComplex: PropTypes.bool,
  hasLabelNormal: PropTypes.bool,
  axisX: PropTypes.array,
  data: PropTypes.array,
  messages: PropTypes.object,
  theme: PropTypes.object,
  intl: PropTypes.object,
  tooltipFormatter: PropTypes.func,
  seriesNameFormatter: PropTypes.func,
  switchAxis: PropTypes.bool,
  featureMagicTypeDisable: PropTypes.bool,
  axisYFormatter: PropTypes.any,
  axisXFormatter: PropTypes.any,
  spaceAfterGraph: PropTypes.func,
  spaceBeforeGraph: PropTypes.func,
  spaceTralingGraph: PropTypes.func,
  spaceInfrontGraph: PropTypes.func,
  colorCount: PropTypes.number,
};

export default compose(injectIntl, withTheme)(BarGraph);
