/*
 * SelectDialog Messages
 *
 * This contains all the text for the SelectDialog component.
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  confirm: {
    id: 'app.components.SelectDialog.confirm',
    defaultMessage: 'Confirm selected',
  },
  cancel: {
    id: 'app.components.SelectDialog.cancel',
    defaultMessage: 'Cancel',
  },
});
