import EnchantedMap from 'utils/enchantedMap';
import { ApiTransferCostDTO } from 'types/drep-backend.d';

import { TransferCostEntry } from '../types';
import { convertArrayToMap } from '../utils';

/**
 * Rename some transfer cost field, so it is inlined with labels on GUI
 */
export function mapTransferCostsFromAPI(costsSrc: ApiTransferCostDTO[]): EnchantedMap<number, TransferCostEntry> {
  return convertArrayToMap(
    (costsSrc || []).map(cost => ({
      smartShiftJobScheduleFrom: cost.smartShiftJobScheduleFrom,
      smartShiftJobScheduleTo: cost.smartShiftJobScheduleTo,
      comment: cost.comment,
      departmentFrom: cost.departmentFrom,
      departmentTo: cost.departmentTo,
      disabledFrom: cost.notAllowed,
      disabledTo: cost.notAllowedReversed,
      facilityFrom: cost.facilityFrom,
      facilityTo: cost.facilityTo,
      id: cost.id,
      valueFrom: cost.cost,
      valueTo: cost.costReversed,
    })),
  );
}

/**
 * Opposite operation to `mapTransferCostsFromAPI`
 * @see mapTransferCostsFromAPI
 */
export function mapTransferCostsToAPI(costs: TransferCostEntry[]): ApiTransferCostDTO[] {
  return costs.map(cost => ({
    smartShiftJobScheduleFrom: cost.smartShiftJobScheduleFrom,
    smartShiftJobScheduleTo: cost.smartShiftJobScheduleTo,
    comment: cost.comment,
    cost: cost.valueFrom,
    costReversed: cost.valueTo,
    departmentFrom: cost.departmentFrom,
    departmentTo: cost.departmentTo,
    facilityFrom: cost.facilityFrom,
    facilityTo: cost.facilityTo,
    id: cost.id,
    notAllowed: cost.disabledFrom,
    notAllowedReversed: cost.disabledTo,
  }));
}
