import React from 'react';
import styled from 'styled-components';
import Button from '../../components/Button';

export const Wrap = styled.div`
  opacity: 0;
  transition: ${props => props.theme.transitions.normal};
  opacity: 1;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: ${props => props.theme.zIndex.modal};
  display: flex;
  align-items: flex-start;
  justify-content: center;
  overflow-y: auto;
  margin: 0 !important;
`;
export const Content = styled.div`
  margin-top: 100px;
  display: inline-block;
  z-index: 1;
  background-color: ${props => props.theme.color.white};
  padding: 20px;
  border-radius: 10px;
`;
export const Mask = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  left: 0;
  z-index: 0;
  background-color: rgba(0, 0, 0, 0.51);
`;

export const ButtonsWrap = styled.div`
  display: flex;
  flex-direction: column;
  > * {
    margin-top: 5px;
  }
  margin-right: 10px;
  margin-left: 10px;
`;

export const TopWrap = styled.div`
  display: flex;
  flex-direction: row;
`;

export const NewWrap = styled.div`
  flex-grow: 2;
`;

export const Info = styled.div`
  color: #009b03;
  font-weight: normal;
`;

export const ExportSectionsStyledDiv = styled.div`
  display: grid;
  grid-template-columns: 3em auto;
  align-items: center;
`;

export const ButtonStyledForExport = styled(Button)`
  width: 10em;
  float: right;
`;

export const ExportSectionWrap = styled.div`
  overflow: auto;
`;
