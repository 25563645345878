/**
 *
 * PlanInterfaceToolBar
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import styled, { css } from 'styled-components';

import IconButton, { SaveButton } from 'components/IconButton';
import { withScrollWatch } from 'utils/scroll';

import { PATHS } from '../../containers/App/constants';
import withSecurity, { PERMISSIONS } from '../../utils/security';
import { Title } from '../PlanningAreaDetailToolbar';
import messages from './messages';

export const ButtonsWrap = styled.div`
  margin: 10px 10px 5px;
  padding: 15px;
  background-color: ${props => props.theme.color.greyDlgBg};
  box-shadow: ${props => props.theme.shadow.pageBox};
  display: flex;
  justify-content: space-between;
  ${SaveButton} {
    display: inline-flex;
    :hover {
      box-shadow: none;
    }
  }
  ${props =>
    props.fixed &&
    css`
      top: 0px;
      z-index: ${props.theme.zIndex.overlayToolbar};
      left: 70px;
      margin: 0px;
      padding: 10px;
    `};
  :first-child {
    position: ${props => (props.fixed ? 'fixed' : 'absolute')};
    width: ${props => (props.fixed ? 'calc(100% - 75px)' : 'calc(100% - 30px)')};
  }
  :nth-child(2) {
    margin-top: 80px;
  }
`;

const ButtonsGroup = styled.div`
  display: flex;
  > * {
    margin-left: 10px;
  }
`;

function Toolbar(props) {
  const { area = false, isEdit = false, fixed, backAlwaysVisibile, planId } = props;
  return (
    <ButtonsWrap fixed={fixed}>
      {(props.hasPerm(PERMISSIONS.VIEW_PLAN_DETAILS) || backAlwaysVisibile) && (
        <IconButton
          icon="arrow-circle-left"
          id="back"
          tooltip={messages.back}
          onClick={() => {
            if (props.onBack) {
              props.onBack();
            } else {
              let path = (area && PATHS.planningAreaDetail) || PATHS.planDetailId;
              path = path.replace(':id', planId);
              if (isEdit) path = `${path}?isEdit=true`;
              props.history.replace(path);
            }
          }}
        />
      )}
      {props.fixed && <Title>{props.title}</Title>}
      <ButtonsGroup>{props.children}</ButtonsGroup>
    </ButtonsWrap>
  );
}

Toolbar.propTypes = {
  history: PropTypes.object,
  title: PropTypes.string,
  fixed: PropTypes.bool,
  hasPerm: PropTypes.func,
  children: PropTypes.node,
  backAlwaysVisibile: PropTypes.bool,
};

export default withScrollWatch(70)(withSecurity()(withRouter(Toolbar)));
