import { DateTime } from 'luxon';

import { ParameterGroup, ParametersGroups } from 'components/GroupParametersValuesTable/types';
import { ParameterSet } from 'containers/ShiftScheduleDetailPage/types';
import { ApiUserDTO } from 'types/drep-backend.d';

export class ShiftScheduleRunStatus {
  public code: string;

  constructor(status) {
    this.code = status;
  }

  public isFailed = () => this.code === 'FAILED';

  public isEditable = () => this.code === 'CALCULATED' || this.code === 'NEW';

  public shouldPullChanges = () => this.code === 'RUNNING' || this.code === 'SENT_TO_ENGINE';

  public isCancelable = () => this.code !== 'FAILED' && this.code !== 'SUCCESSFUL';
}

export interface ShiftScheduleRunPlan {
  name: string;
  validFrom: DateTime;
  validTo: DateTime;
}

export interface ShiftScheduleRun extends ParametersGroups {
  id?: number;
  name: string;
  startDate: string;
  endDate: string;
  sentDate?: DateTime;
  granularity: string;
  calculatedDate?: DateTime;
  calculatedBy?: ApiUserDTO | null;
  sentBy?: ApiUserDTO | null;
  audit?: Audit;
  status?: ShiftScheduleRunStatus;
  plans: ShiftScheduleRunPlan[];
  scheduleId: number;
  csvFileId?: number;
  requestFileId?: number;
  errorLogFileId?: number;
  groups?: ParameterGroup[];
  sendDirectly?: boolean;
  parameterSetId: number;
  steps?: RunStep[];
}

export interface Audit {
  created: DateTime;
  createdBy: CreatedBy;
}

export interface CreatedBy {
  firstName: string;
  lastName: string;
}

export interface KpiObject {
  [index: string]: KpiItem[];
}

export interface KpiItem {
  [index: string]: any;
}

export interface RunStep {
  created: DateTime;
  id: number;
  message?: string;
  subject?: string;
  status?: string;
  technicalMessage?: string;
  kpi?: KpiObject;
  errors?: Array<Object>;
  warnings?: Array<Object>;
}

export interface ShiftScheduleRunsForm {
  shiftScheduleRuns: ShiftScheduleRun[];
  shiftScheduleName: string;
  selectPullingChanges: ShiftScheduleRun;
}

export interface ShiftScheduleRunsState {
  endDate: DateTime;
  granularity: any;
  name: string;
  nameError: boolean;
  parameterSet: ParameterSet;
  parameterSetError: boolean;
  sendDirectly: boolean;
  startDate: DateTime;
}
