/*
 *
 * LanguageProvider constants
 *
 */

export const CHANGE_LOCALE = 'app/LanguageProvider/CHANGE_LOCALE';
export const ADD_LOCALE = 'app/LanguageProvider/ADD_LOCALE';
export const LOCALE_ADDED = 'app/LanguageProvider/LOCALE_ADDED';
export const FETCH_MESSAGES = 'app/LanguageProvider/FETCH_MESSAGES';
export const FETCH_LANGUAGES = 'app/LanguageProvider/FETCH_LANGUAGES';
export const SAVE_MESSAGES = 'app/LanguageProvider/SAVE_MESSAGES';
export const SAVE_LANGUAGES = 'app/LanguageProvider/SAVE_LANGUAGES';
export const FAILED_MESSAGES = 'app/LanguageProvider/FAILED_MESSAGES';
export const LOCALE_KEY = 'locale';
