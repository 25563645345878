/*
 *
 * PlanResultPage constants
 *
 */

export const COL_SETTINGS_CHANGE = 'app/PlanResultPage/COL_SETTINGS_CHANGE';
export const STORE_RESULT_OPTIONS = 'app/PlanResultPage/STORE_RESULT_OPTIONS';
export const FETCH_RESULTS = 'app/PlanResultPage/FETCH_RESULTS';
export const LOAD_TREND_GRAPH = 'app/PlanResultPage/LOAD_TREND_GRAPH';
export const STORE_RESULTS = 'app/PlanResultPage/STORE_RESULTS';
export const OVERRIDE_EFFORT = 'app/PlanResultPage/OVERRIDE_EFFORT';
export const OVERRIDE_VOLUME = 'app/PlanResultPage/OVERRIDE_VOLUME';
export const OVERRIDE_PRODUCTION = 'app/PlanResultPage/OVERRIDE_PRODUCTION';
export const EDIT_MODE_CHANGE = 'app/PlanResultPage/EDIT_MODE_CHANGE';
export const OVERRIDES_SAVED = 'app/PlanResultPage/OVERRIDES_SAVED';
export const OVERRIDES_DISCARD = 'app/PlanResultPage/OVERRIDES_DISCARD';
export const CLEAN = 'app/PlanResultPage/CLEAN';
export const CLEAN_CALCULATION = 'app/PlanResultPage/CLEAN_CALCULATION';
export const CLEAN_TREND_DATA = 'app/PlanResultPage/CLEAN_TREND_DATA';

export const LOAD_PLAN = 'app/PlanResultPage/LOAD_PLAN';
export const STORE_PLAN_BASIC_DETAILS = 'app/PlanResultPage/STORE_PLAN_BASIC_DETAILS';
export const IS_EDITING = 'app/PlanResultPage/IS_EDITING';
export const STORE_PLAN = 'app/PlanResultPage/STORE_PLAN';
export const STORE_MASTER_PLAN = 'app/PlanResultPage/STORE_MASTER_PLAN';
export const LOAD_PLAN_STATS = 'app/PlanResultPage/LOAD_PLAN_STATS';
export const STORE_PLAN_STATS = 'app/PlanResultPage/STORE_PLAN_STATS';
export const SET_GRAPH_DATA_TREND = 'app/PlanResultPage/SET_GRAPH_DATA_TREND';
export const SET_GRAPH_DATA_TREND_FILTERED = 'app/PlanResultPage/SET_GRAPH_DATA_TREND_FILTERED';
export const SET_GRAPH_DATA_ACTIVITY = 'app/PlanResultPage/SET_GRAPH_DATA_ACTIVITY';
export const SET_GRAPH_DATA_DILO = 'app/PlanResultPage/SET_GRAPH_DATA_DILO';
export const LOAD_GRAPH_DATA_ACTIVITY = 'app/PlanResultPage/LOAD_GRAPH_DATA_ACTIVITY';
export const LOAD_GRAPH_DATA_DILO = 'app/PlanResultPage/LOAD_GRAPH_DATA_DILO';
export const SET_GRAPH_DATA_ACTIVITY_FILTERED = 'app/PlanResultPage/SET_GRAPH_DATA_ACTIVITY_FILTERED';
export const SET_GRAPH_DATA_DILO_FILTERED = 'app/PlanResultPage/SET_GRAPH_DATA_DILO_FILTERED';
export const STORE_CALCULATED_TABLES = 'app/PlanResultPage/STORE_CALCULATED_TABLES';
export const STORE_CALCULATED_RESULT_TABLES = 'app/PlanResultPage/STORE_CALCULATED_RESULT_TABLES';
export const STORE_CALCULATED_TABLES_DEF = 'app/PlanResultPage/STORE_CALCULATED_TABLES_DEF';
export const STORE_GRAPH_DATA_TREND = 'app/PlanResultPage/STORE_GRAPH_DATA_TREND';
export const REMOUNT_TABLES = 'app/PlanResultPage/REMOUNT_TABLES';
export const TOGGLE_OVERRIDE_HELPER = 'app/PlanResultPage/TOGGLE_OVERRIDE_HELPER';
export const FILTER_DATA_REQUEST = 'app/PlanResultPage/FILTER_DATA_REQUEST';
export const FILTER_DATA_SUCCESS = 'app/PlanResultPage/FILTER_DATA_SUCCESS';
export const FILTER_DATA_FAILURE = 'app/PlanResultPage/FILTER_DATA_FAILURE';
export const RESULT_CALCULATION_STARTED = 'app/PlanResultPage/RESULT_CALCULATION_STARTED';
export const FILTER_DATA = 'app/PlanResultPage/FILTER_DATA';
export const STORE_VOLUME_CP = 'app/PlanResultPage/STORE_VOLUME_CP';
export const STORE_VOLUME_RAW = 'app/PlanResultPage/STORE_VOLUME_RAW';
export const STORE_RESULT_VOLUME_RAW = 'app/PlanResultPage/STORE_RESULT_VOLUME_RAW';
export const STORE_HEADS_GRAPH_DATA = 'app/PlanResultPage/STORE_HEADS_GRAPH_DATA';
export const STORE_HEADS_GRAPH_FILTERED_DATA = 'app/PlanResultPage/STORE_HEADS_GRAPH_FILTERED_DATA';

export const GRANULARITY = { MONTH: 'MONTH', WEEK: 'WEEK', DAY: 'DAY', WZP: 'WZP', HOUR: 'HOUR' };
export const OVERRIDE_VALUE_TYPE = { VOLUME: 'VOLUME', EFFORT: 'EFFORT', PRODUCTIVITY_RATE: 'PRODUCTIVITY_RATE' };

export const FORM_NAME = 'resultPage';

export const PURPOSE = { ACTUAL: 'ACTUAL', OVERRIDE: 'OVERRIDE' };

export const MHE_T_NAME = 'mheResultTable';
export const ACTIVITY_T_NAME = 'activitesResultTable';

export const SETTINGS_KEY = 'result_page_activity_settings';
export const COLUMN_SETTINGS_KEY = 'result_page_column_activity_settings';
export const MAPPING_OMS_CODES = 'MAPPING_OMS_CODES';

export const TOGGLE_VOLUME_VALUE  = 'app/PlanResultPage/TOGGLE_VOLUME_VALUE';
export const TOGGLE_VOLUME_FORMULA= 'app/PlanResultPage/TOGGLE_VOLUME_FORMULA';