import * as React from 'react';
import { useEffect } from 'react';
import { FormikProps } from 'formik';
import { ColDef } from 'ag-grid-community';
import { FormattedMessage, injectIntl } from 'react-intl';
import styled from 'styled-components';
import hash from 'object-hash';
import {camelCase, startCase} from 'lodash';
import ButtonWithDirtyCheck from 'components/ButtonWithDirtyCheck';
import Select from 'components/StyledSelect';
import { DAY_NAMES } from 'utils/calendar/constants';

import calendarMessages from 'utils/calendar/messages';
import messages from './messages';
import { toNumber } from '../../utils/utils';
import { parseDateAndFormatToLocalString } from 'utils/dateTime';
import { createStructuredSelector } from 'reselect';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import AgTable from 'components/Table';
import {selectEditFromplan } from 'containers/PlanDetailPage/selectors';
import { selectEditFromPa} from 'containers/PlanningAreaDetailPage/selectors';

export interface mheAvailabilityOverviewProps {
  isEdit: boolean;
  intl: any;
  formik: FormikProps<any>;
  hasPerm: Function;
  save: Function;
  saveMheOverview: Function;
  granularityWeek: Function;
  offDays: any;
  firstDay: any;
  edit: boolean;
  periodIndex: number
}

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 5px 0;
  & > * {
    margin: 5px 5px;
  }
`;

const TableWithHeight = styled(AgTable)`
  height: ${props => props.height}px;

  .cell-editable{
    background-color: #3a74ce;
    color: #FFF;
  }

  .cell-overriddenPieces{
    font-weight: bold;
    color: ${props => props.theme.color.red};
  }

  .cell-editable.cell-overriddenPieces{
    font-weight: bold;
    color: ${props => props.theme.color.citrusOrange};
  }

  .cell-headCountEmpty{
    background-color: lightgray;
    border-right-color: coral;
  }
`;

const StyledSelect = styled(Select)`
  width: 200px;
`;

const mheAvailabilityOverview = ((props: mheAvailabilityOverviewProps) => {
  const { isEdit, periodIndex } = props;
  const storedGranularity =
    props.formik.initialValues?.planningParameters?.mheAvailabilityGranularity?.toLowerCase() || 'day';
  const [granularity, setGranularity] = React.useState(storedGranularity);
  const [columns, setColumns] = React.useState([]);

  const submitGranularity = async ({ waitForSaveAll }) => {
    if (waitForSaveAll) {
      await props.formik.submitForm();
    }
    const id = props.formik.initialValues.planningParametersId ? props.formik.initialValues.planningParametersId : props.formik.initialValues.planningParameters.id;
    props.saveMheOverview(props.formik.initialValues, id, granularity.toUpperCase());
  }

  useEffect(() => {
    const columns = createColumnsDef();
    var result = columns.reduce((unique, o) => {
      if (!unique.some(obj => obj.colId === o.colId)) {
        unique.push(o);
      }
      return unique;
    }, []);
    setColumns(result)
  }, [props.isEdit, props.formik.values.mheOverViewData?.rows])

  const createColumnsDef = () => {
    const handleKeyValue = (params) =>{
      if(params?.data === undefined){
        return '';
      }
      const wzpId = params.data && params.data.wzpId;
      const dayKey = params.data && params.data.maintenanceDay;
      const allWzp = props?.formik?.values?.planningParameters?.periods.map(p => p.workZonePeriods) || [];
      var flattened = [].concat.apply([],allWzp);
      var result = flattened?.reduce((unique, o) => {
        if (!unique.some(obj => obj.id === o.id)) {
          unique.push(o);
        }
        return unique;
      }, []);
      const wzpKey = result?.findIndex(wzp => wzp.id == wzpId)
      const maintenanceKey = params.data && params.data.maintenanceRate == 0 ? 0 : params.data.maintenanceRate * 100;
      if(dayKey === -1 || wzpKey === -1){
        return '';
      }
      //@ts-ignore
      return `${parseInt(maintenanceKey)}${dayKey}${wzpKey + 1}`
    }
    const colDefs: ColDef[] = [
      {
        colId: 'departmentName',
        field: 'departmentName',
        headerName: props.intl.formatMessage(messages.departmentName),
        headerTooltip: props.intl.formatMessage(messages.departmentName),
        width: 120,
        suppressSizeToFit: true,
        pinned: true,
      },
      {
        colId: 'mheName',
        field: 'mheName',
        headerName: props.intl.formatMessage(messages.name),
        headerTooltip: props.intl.formatMessage(messages.name),
        width: 120,
        suppressSizeToFit: true,
        pinned: true,
      },
      {
        colId: 'key',
        field: 'key',
        headerName: props.intl.formatMessage(messages.key),
        headerTooltip: props.intl.formatMessage(messages.key),
        valueGetter: handleKeyValue,
        menuTabs: ['filterMenuTab'],
        width: 80,
        suppressSizeToFit: true,
        pinned: true,
      },
    ];

    const shiftData = props.formik.values?.mheOverViewData?.rows;
    const granularityType = props.formik.values.planningParameters.mheAvailabilityGranularity;
    const handleValueFormatter = (params) => {
      const columnValue = params.data?.columns?.filter((data) => data.date === params.colDef.colId);
      const overriddenPiecesValue = columnValue[0]?.overriddenPieces >= 0 && columnValue[0]?.overriddenPieces;
      const pieces = columnValue[0]?.pieces >= 0 ? columnValue[0]?.pieces : '';
      let valueToDisplay = pieces;
      if ( columnValue && columnValue[0] &&  ("overriddenPieces" in columnValue[0])){
        if(columnValue[0].overriddenPieces === '' || columnValue[0].overriddenPieces === undefined){
          valueToDisplay = ''
        }else{
          valueToDisplay = columnValue[0].overriddenPieces;
        }
      }
      //const valueToDisplay = overriddenPiecesValue ? overriddenPiecesValue : pieces;
      return valueToDisplay
    }

    const handleValueSetter = (params) => {
      params.data.columns.forEach((data, index) => {
        if (data.date === params.colDef.colId) {
          const current = params.data.columns[index];
          let newValue = toNumber(params.newValue);
          if(newValue === ''){
            newValue = null;
          }
          if(newValue == current.pieces){
            newValue = current.pieces;
          }
          if (Number.isNaN(newValue)) {
            return false;
          }
          props.formik.setFieldValue(`mheOverViewData.rows[${params.node.childIndex}].columns[${index}].overriddenPieces`, newValue);
          params.data.columns[index] = { ...current, overriddenPieces: newValue }
        }
      });
      return true;
    }
    const handleClassValues = (params) => {
      const cssClasses = [];
      const headCountValue = params.data.columns.filter((data) => data.date === params.colDef.colId);
      const pieces = headCountValue[0]?.pieces// >=0 ? headCountValue[0]?.pieces : ''; 
      const overriddenPiecesValue = headCountValue[0]?.overriddenPieces >= 0 && headCountValue[0]?.overriddenPieces;
      if(props.isEdit){
        cssClasses.push('cell-editable');
      }
      if (headCountValue && headCountValue[0] && ("overriddenPieces" in headCountValue[0])) {
        headCountValue[0].overriddenPieces !==null && headCountValue[0].overriddenPieces !== undefined && overriddenPiecesValue !== pieces && cssClasses.push('cell-overriddenPieces');
      } else if (pieces >= 0) {
        //cssClasses.push('cell-editable');
      } else {
        cssClasses.push('cell-headCountEmpty');
      }
      return cssClasses.join(' ');
    }

    const handleCellEditing = (params) => {
      const headCountValue = params.data.columns.filter((data) => data.date === params.colDef.colId);
      const pieces = headCountValue[0]?.pieces;
      const overriddenPiecesValue = headCountValue[0]?.overriddenPieces;
      if ((Number.isInteger(overriddenPiecesValue) || Number.isInteger(pieces)) && props.isEdit) {
        return true;
      }
      return false;
    }

    const handleToolTop = (params) =>{
      const headCountValue = params.data.columns.filter((data) => data.date === params.colDef.colId);
      const overriddenPiecesValue = headCountValue[0]?.overriddenPieces;
      if(overriddenPiecesValue !== null && overriddenPiecesValue !== undefined){
        return 'Overridden value';
      }
    }

    shiftData && shiftData.forEach((data) => {
      data.columns.forEach((c) => {
        const rowFlag = granularityType == 'DAY' ? DAY_NAMES.includes(c.dayOfWeek) : true;
        if (rowFlag) {
          const dayOfWeek = props.intl.formatMessage(calendarMessages[`${c.dayOfWeek.toLowerCase()}Short`])
          colDefs.push({
            colId: `${c.date}`,
            sortable: false,
            suppressMenu: true,
            suppressMovable: true,
            headerName: `${granularityType == 'DAY' ? dayOfWeek : 'Week'} ${parseDateAndFormatToLocalString(c.date)}`,
            headerTooltip: `${granularityType == 'DAY' ? dayOfWeek : 'Week'} ${parseDateAndFormatToLocalString(c.date)}`,
            width: 120,
            pinned: false,
            valueGetter: handleValueFormatter,
            valueSetter: handleValueSetter,
            editable: props.isEdit && handleCellEditing,
            cellRendererParams: params => ({
              editable: props.isEdit && handleCellEditing(params),
            }),
            suppressSizeToFit: true,
            cellClass: handleClassValues,
            tooltipValueGetter: handleToolTop
          })
        }
      })
    })

    return colDefs;
  }
  const onCellValueChanged = (params) => {
    params.data.columns.forEach((data, index) => {
      if (data.date === params.colDef.colId) {
        const rowData = props.formik.values.mheOverViewData.rows[params.node.childIndex]
        const columnData = props.formik.values.mheOverViewData.rows[params.node.childIndex].columns[index];
        const currentData = props.formik.values.mheOverrideData && props.formik.values.mheOverrideData.overrides || [];
        const findIndex = currentData.findIndex(a => a.date === columnData.date && a.MheId === rowData.mheId)
        findIndex !== -1 && currentData.splice(findIndex , 1)

        if(columnData.overriddenPieces >=0){
        const newOverRideData = {
        MheId: rowData.mheId,
        departmentId: rowData.departmentId,
        wzpId: rowData.wzpId,
        maintenanceDay: rowData.maintenanceDay,
        maintenanceRate: rowData.maintenanceRate,
        date: columnData.date,
        pieces: columnData?.overriddenPieces
        }
        const newData = [...currentData, newOverRideData]
        const mheOverViewDataToSend = {
          granularity: props.formik.initialValues?.planningParameters?.mheAvailabilityGranularity,
          overrides: newData
        }
        props.formik.setFieldValue(`mheOverrideData`, mheOverViewDataToSend)
      }
      }
    });
  }

  const rowData = props.formik?.values?.mheOverViewData?.rows;
  const colDefKey = hash(columns);
  return (
    <div>
      <Row>
        <FormattedMessage {...messages.granularity} />
        {isEdit ? (
          <StyledSelect
            classNamePrefix="react-select"
            className="react-select-container"
            isClearable={false}
            options={[{ value: 'day' }, { value: 'week' }]}
            getOptionLabel={val => <FormattedMessage {...messages[val.value]} />}
            onChange={val => setGranularity(val.value)}
            value={{ value: granularity }}
          />
        ) : (
          <FormattedMessage {...messages[granularity]} />
        )}
        {isEdit && (
          <ButtonWithDirtyCheck
            actionHandler={submitGranularity}
            message={messages.confirm}
            disabled={storedGranularity === granularity}
          />
        )}
      </Row>
      <Row>
        <FormattedMessage {...messages.availableHeads} />
      </Row>
      <div key={colDefKey}>
        <TableWithHeight
          showCOG={false}
          applyColumnDefOrder
          pagination
          paginationPageSize={50}
          columnDefs={columns}
          rowData={rowData}
          domLayout="autoHeight"
          onCellValueChanged={onCellValueChanged}
        />
      </div>
    </div>
  );
})
  ;


const mapStateToPropsPlan = createStructuredSelector({
  edit: selectEditFromplan,
});

const mapStateToPropsPa = createStructuredSelector({
  edit: selectEditFromPa,
});

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators({ }, dispatch),
    dispatch,
  };
}

const withPlanConnect = connect(
  mapStateToPropsPlan,
  mapDispatchToProps,
);

const withPaConnect = connect(
  mapStateToPropsPa,
  mapDispatchToProps,
);

export const MHEAvailabilityOverviewPlan = compose(
  injectIntl,
  withPlanConnect,
)(mheAvailabilityOverview);

export const MHEAvailabilityOverviewPa = compose(
  injectIntl,
  withPaConnect,
)(mheAvailabilityOverview);