import { fetchData } from 'utils/reduxApi';
import { withUrl } from 'utils/api';

export const mheRedistributeAPI = async (
    token,
    dispatch,
    payload,
  ) => {
    const response = await fetchData(
      withUrl(`/plans/redistributeMhe`)
        .andToken(token)
        .post(payload),
      dispatch,
    );
    return response;
  };