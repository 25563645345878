export default function calculateLabourAvailabilityValuesExcel(params, data, granularity, key) {
  const department = params?.node?.key;
  const level = granularity;
  const currentDay = params?.column?.userProvidedColDef?.day;
  const currentHour = params?.column?.userProvidedColDef?.hour;
  const currentWzp = (level === 'HOUR') ? params?.column?.userProvidedColDef?.wzpExcel :params?.column?.userProvidedColDef?.wzp;
  const currentWeek = params?.column?.userProvidedColDef?.week;
  let sum = 0;
  if (department == 'All Departments') {
    return '';
  }
  if (level == 'MONTH' || level == 'WEEK') {
    data?.planAvailability?.labourCategories?.forEach((lc) => {
      lc?.periods?.forEach(lcchild => {
        if (lcchild.startDay == currentWeek) {
          lcchild?.availabilityCalculatedValuesForDepartmentsAndRoles?.forEach(lcad => {
            if (lcad?.department?.name == department) {
              sum = sum + lcad[key];
            }
          })
        }
      })
    })
  }
  if (level == 'DAY') {
    data?.planAvailability?.labourCategories?.forEach((lc) => {
      lc?.periods?.forEach(lcperiod => {
        lcperiod?.days?.forEach(lcchild => {
          if (lcchild.day === currentDay) {
            lcchild?.availabilityCalculatedValuesForDepartmentsAndRoles?.forEach(lcad => {
              if (lcad?.department?.name == department) {
                sum = sum + lcad[key];
              }
            })
          }
        })
      })
    })
  }
  if (level == 'HOUR') {
    data?.planAvailability?.labourCategories?.forEach((lc) => {
      lc?.periods?.forEach(lcperiod => {
        lcperiod?.days?.forEach(lcchild => {
          if (lcchild.day === currentDay) {
            lcchild?.wzps?.forEach((lcwzp) => {
              if (lcwzp?.workZonePeriodId == currentWzp) {
                lcwzp?.hours?.forEach((lchrs) => {
                  if (lchrs?.hourOfDay == currentHour) {
                    lchrs?.availabilityCalculatedValuesForDepartmentsAndRoles?.forEach(lcad => {
                      if (lcad?.department?.name == department) {
                        sum = sum + lcad[key];
                      }
                    })
                  }
                })
              }
            })
          }
        })
      })
    })
  }
  if (level == 'WZP') {
    data?.planAvailability?.labourCategories?.forEach((lc) => {
      lc?.periods?.forEach(lcperiod => {
        lcperiod?.days?.forEach(lcchild => {
          if (lcchild?.day === currentDay) {
            lcchild?.wzps?.forEach((lcwzp) => {
              if (lcwzp?.workZonePeriodId == currentWzp) {
                lcwzp?.availabilityCalculatedValuesForDepartmentsAndRoles?.forEach(lcad => {
                  if (lcad?.department?.name == department) {
                    sum = sum + lcad[key];
                  }
                })
              }
            })
          }
        })
      })
    })
  }
  return Number(sum).toFixed(3);
}

