/**
 *
 * EnvRibbon
 *
 */

import React from 'react';
import styled from 'styled-components';
import { ACTIVE_ENV } from '../../utils/activeEnv';

const EnvRibbonWrapper = styled.div`
  position: absolute;
  left: -5px;
  top: -5px;
  z-index: 99001;
  overflow: hidden;
  width: 110px;
  height: 110px;
  text-align: left;
  pointer-events: none;
`;

const EnvRibbonText = styled.div`
  font-size: 14px;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  font-weight: bold;
  line-height: 25px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg); /* Needed for Safari */
  width: 150px;
  display: block;
  background: ${props => props.color || '#4a3'};
  box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
  position: absolute;
  top: 29px;
  left: -31px;
`;

function EnvRibbon() {
  const ribbon = ACTIVE_ENV.label;
  const color = ACTIVE_ENV.ribbonColor;
  if (color) {
    return (
      <EnvRibbonWrapper>
        <EnvRibbonText color={color}>{ribbon}</EnvRibbonText>
      </EnvRibbonWrapper>
    );
  }
  return null;
}

EnvRibbon.propTypes = {};

export default EnvRibbon;
