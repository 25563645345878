import { Component } from 'react';
import * as React from 'react';
import { isEqual } from 'lodash';
import { DateTime } from 'luxon';
import { injectIntl } from 'react-intl';
import styled from 'styled-components';

import AutocompleteSelect from 'components/AutocompleteSelect';
import DatePicker from 'components/DatePicker';
import DeleteCellRenderer from 'components/DeleteCellRenderer';
import { InlineLabel } from 'components/FormikTextInput';
import { AddButton } from 'components/IconButton';
import Table from 'components/Table';
import { FormikJobDetailData } from 'containers/ShiftScheduleActivityDetailPage/types';
import { replaceFormikArrayAtIndex } from 'utils/utils';

import messages from './messages';

interface RestrictionsTableProps {
  formik: FormikJobDetailData;
  intl: any;
}

class TimeRestrictionsTable extends Component<RestrictionsTableProps, {}> {
  public state = {
    selectedPeriod: null,
  };

  private periodOptions = [
    {
      id: 'DAY',
      name: 'Day',
    },
    {
      id: 'WEEK',
      name: 'Week',
    },
    // {
    //   id: "SHIFT",
    //   name: "Shift",
    // },
  ];

  private selectablePeriodOptions = [];

  public shouldComponentUpdate(
    nextProps: Readonly<RestrictionsTableProps>,
    nextState: Readonly<{}>,
    nextContext: any,
  ): boolean {
    return !isEqual(nextProps.formik.values.job?.timeRestrictions, this.props.formik.values.job?.timeRestrictions);
  }

  public render() {
    const data = this.props.formik.values.job?.timeRestrictions || [];
    const setSelectedPeriod = value => this.setState({ selectedPeriod: value });

    const usedPeriods = [];
    for (let i = 0; i < data.length; i++) {
      const item = data[i];
      usedPeriods.push(item.period);
    }
    this.selectablePeriodOptions = [];
    for (let i = 0; i < this.periodOptions.length; i++) {
      const perOpt = this.periodOptions[i];
      if (usedPeriods.indexOf(perOpt.id) === -1) {
        this.selectablePeriodOptions.push(perOpt);
      }
    }

    const addNewItem = () => {
      if (this.state.selectedPeriod) {
        replaceFormikArrayAtIndex(
          this.props.formik,
          'job.timeRestrictions',
          {
            dateFrom: DateTime.local(),
            dateTo: null,
            limit: '0',
            period: this.state.selectedPeriod.id,
          },
          this.props.formik.values.job?.timeRestrictions?.length || 0,
        );
        setSelectedPeriod(null);
      }
    };

    const GridRow = styled.div`
      display: grid;
      grid-auto-flow: row;
      grid-template-columns: 43px 150px 70px;
      margin: 0 0 12px 2px;
      column-gap: 10px;
    `;

    const rowHeight = () => 49;

    return [
      <GridRow key="newItem">
        <InlineLabel {...messages.period} />
        <AutocompleteSelect
          key="period-select"
          preloadedData={this.selectablePeriodOptions}
          setValue={setSelectedPeriod}
        />
        <AddButton key="add-button" disabled={this.props.formik.isSubmitting} onClick={addNewItem} />
      </GridRow>,
      <Table
        key="table"
        messages={messages}
        pagination={false}
        columnDefs={this.getColumnDefs()}
        rowData={data}
        onGridReady={null}
        getRowHeight={rowHeight}
        domLayout="autoHeight"
      />,
    ];
  }

  private getColumnDefs() {
    const datePicker = (minDateField, isClearable?) => params => (
      <DatePicker
        value={params.data[params.colDef.field]}
        timePicker={false}
        clearable={isClearable}
        minDate={minDateField ? params.data[minDateField] : undefined}
        onAccept={val => {
          const id = this.props.formik.values.job.timeRestrictions.findIndex(i => i === params.data);
          this.props.formik.setFieldValue(`job.timeRestrictions[${id}].${params.colDef.field}`, val);
        }}
      />
    );

    return [
      {
        cellRendererFramework: DeleteCellRenderer,
        cellRendererParams: {
          onDelete: (row, params) => {
            const newArray = this.props.formik.values.job.timeRestrictions.filter(i => i.period !== params.data.period);
            this.props.formik.setFieldValue('job.timeRestrictions', newArray || []);
          },
        },
        colId: 'removeButton',
        field: 'id',
        headerName: '',
        width: 50,
      },
      {
        colId: 'period',
        field: 'period',
        headerName: this.props.intl.formatMessage(messages.period),
        valueGetter: params => {
          const { period } = params.data;
          return period ? this.periodOptions.find(p => p.id === period).name : '';
        },
        width: 100,
      },
      {
        colId: 'limit',
        editable: true,
        field: 'limit',
        headerName: this.props.intl.formatMessage(messages.limitTime),
        valueSetter: params => {
          this.props.formik.setFieldValue(`job.timeRestrictions[${params.node.id}].limit`, params.newValue);
          return true;
        },
        width: 110,
      },
      // {
      //   cellRendererFramework: datePicker(null),
      //   colId: "dateFrom",
      //   field: "dateFrom",
      //   headerName: this.props.intl.validFrom,
      //   width: 250,
      // },
      // {
      //   cellRendererFramework: datePicker("dateFrom", true),
      //   colId: "dateTo",
      //   field: "dateTo",
      //   headerName: this.props.intl.validTo,
      //   width: 250,
      // },
    ];
  }
}

// @ts-ignore
export default injectIntl(TimeRestrictionsTable);
