// @flow

/**
 *
 * SelectDialog
 *
 */

import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import Button from 'components/Button';
import Modal from 'components/Modal';
import messagesSelectDialog from './messages';
import { TableStyled } from './styled';

type SelectDialogProps = {
  hideColumns?: Array<any>,
  isOpen: boolean,
  data: Array<any>,
  onSelected: Function,
  onClose: Function,
  title: Object,
  messages: Object,
  messagesPrefix?: String,
  definitionInterceptor?: Function,
  children?: React$Node,
  departments?: Array<Object>,
  columnDefs: Array<Object>,
  frameworkComponents?: Object,
  departmentId?: string,
  onGridReady?: Function,
  validateData?: Function,
  showTable?: Boolean,
};

type SelectDialogStateProps = {
  validated: boolean,
  updatedRows: Array<Object>,
};

/* eslint-disable react/prefer-stateless-function */
class SelectDialog extends React.PureComponent<SelectDialogProps, SelectDialogStateProps> {
  state = { validated: false, updatedRows: [] };

  gridApi = {};

  validateData = (event: Object) => {
    const { validateData } = this.props;
    const { updatedRows } = this.state;
    if (event.type === 'cellValueChanged') {
      this.setState({ updatedRows: updatedRows.concat([event.node]) });
      if (!event.node.selected) return;
    }
    const rows = this.gridApi.getSelectedRows();
    if (rows.length === 0) {
      this.setState({ validated: false });
      return;
    }
    this.setState({ validated: validateData ? validateData(rows) : true });
  };

  clearUoms = () => {
    const { updatedRows } = this.state;
    updatedRows.map(node => node.setDataValue('uom', null));
  };

  componentDidUpdate(prevProps: SelectDialogProps, prevState: SelectDialogStateProps) {
    if (prevProps.isOpen !== this.props.isOpen && this.state.validated) {
      this.setState({ validated: false });
    }

    if (prevProps.departmentId !== this.props.departmentId) {
      this.clearUoms();
    }
  }

  render() {
    const {
      data,
      onSelected,
      onClose,
      messages,
      columnDefs,
      frameworkComponents,
      onGridReady,
      hideColumns,
      showTable = true,
    } = this.props;
    return (
      <Modal
        {...this.props}
        buttons={
          <>
            <Button onClick={onClose}>
              <FormattedMessage {...messagesSelectDialog.cancel} />
            </Button>
            <Button
              disabled={this.state.validated === false}
              onClick={() => {
                onSelected(this.gridApi.getSelectedRows());
                onClose();
              }}
            >
              <FormattedMessage {...messagesSelectDialog.confirm} />
            </Button>
          </>
        }
      >
        <TableStyled
          onGridReady={params => {
            this.gridApi = params.api;
            params.api.sizeColumnsToFit();
            onGridReady && onGridReady(params);
          }}
          hideColumns={hideColumns || []}
          suppressDragLeaveHidesColumns
          rowData={data}
          onSelectionChanged={this.validateData}
          visibility={(showTable && 'visible') || 'hidden'}
          messages={messages}
          messagesPrefix={this.props.messagesPrefix}
          definitionInterceptor={this.props.definitionInterceptor}
          rowSelection="multiple"
          rowMultiSelectWithClick
          columnDefs={columnDefs}
          frameworkComponents={frameworkComponents}
          onCellValueChanged={this.validateData}
          suppressRowClickSelection
          singleClickEdit
        />
      </Modal>
    );
  }
}

export default SelectDialog;
