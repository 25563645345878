import * as React from 'react';
import { FormikProps } from 'formik';
import { DateTime, Duration } from 'luxon';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import DatePicker from 'components/DatePicker';
import DaysPickerCellEditor from 'components/DaysPickerCellEditor';
import TimePicker from 'components/TimePicker';
import { deletePlanActivityLines } from 'containers/PlanDetailPage/actions';
import { deletePaActivityLines } from 'containers/PlanningAreaDetailPage/actions';
import { getWeekdays } from 'utils/calendar/localize';
import { numberCellFormatter } from 'utils/utils';

import { GuiState } from '../../containers/PlanDetailPage/types';
import { PlanDetailData } from '../../containers/PlanResultPage/calculation/types';
import { createUnproductiveSection } from './common';
import { messages } from './messages';

const getColumnDefs = (intl, guiState: GuiState, formik: FormikProps<PlanDetailData>) => {
  const isEdit = guiState.edit;
  const now = DateTime.local();
  const diffDays = Math.round(Math.abs(DateTime.now().diff(DateTime.local(3000, 12, 31), 'days').days));
  const maxDateValue=DateTime.now().plus({days:diffDays})
  const datePicker = (minDateField, isClearable?) => params =>
    params.value ? (
      <DatePicker
        value={params.value}
        timePicker={false}
        clearable={isClearable}
        minDate={minDateField ? params.data[minDateField] : undefined}
        maxDate={maxDateValue}
        onAccept={val => {
          formik.setFieldValue(
            `planningParameters.periods.${params.data.periodIndex}.apCalculated.unproductiveLocations.${
              params.data.index
            }.${params.colDef.field}`,
            val,
          );
        }}
      />
    ) : null;

  const timePicker = message => params =>
    params.data ? (
      <TimePicker
        onChange={val => {
          // console.log("SETTING " + `planningParameters.periods.${params.data.periodIndex}.${params.colDef.field}`, val)
          formik.setFieldValue(
            `planningParameters.periods.${params.data.periodIndex}.apCalculated.unproductiveLocations.${
              params.data.index
            }.${params.colDef.field}`,
            val,
          );
        }}
        value={
          params.value || Duration.fromObject({ hours: now.hour, minutes: now.minute, seconds: 0, milliseconds: 0 })
        }
        okLabel={intl.formatMessage(message)}
      />
    ) : null;

  const timeFormatter = isEdit
    ? undefined
    : params => {
        if (params.value) {
          return params.value.toFormat('h:mm');
        }
        return '';
      };

  const dateFormatter = isEdit
    ? undefined
    : params => {
        if (params.value) {
          return params.value.toLocaleString(DateTime.DATE_SHORT);
        }
        return '';
      };

  const colDefs: any[] = [
    {
      headerName: intl.formatMessage(messages.days),
      field: 'days',
      colId: 'days',
      width: isEdit ? 450 : 350,
      editable: isEdit,
      valueFormatter: params =>{
        return (params.data || params.value) ? Object.values(getWeekdays(intl.formatMessage, params.value, true)).join(', ') : null;
      },
      valueSetter: params => {
        if (params && params.newValue) {
          if (typeof params.newValue === 'string') {
            params.data[params.colDef.field] = params.newValue.split(',');
          } else {
            params.data[params.colDef.field] = params.newValue;
          }
          return true;
        }
        return false; // value was not changed
      },
      cellEditorFramework: DaysPickerCellEditor,
    },
    {
      cellRendererFramework: isEdit ? timePicker(messages.setStartTime) : undefined,
      valueFormatter: timeFormatter,
      colId: 'startTime',
      field: 'startTime',
      headerName: intl.formatMessage(messages.startTime),
      width: isEdit ? 110 : 90,
    },
    {
      cellRendererFramework: isEdit ? timePicker(messages.setEndTime) : undefined,
      valueFormatter: timeFormatter,
      colId: 'endTime',
      field: 'endTime',
      headerName: intl.formatMessage(messages.endTime),
      width: isEdit ? 110 : 90,
    },
    {
      colId: 'periodLength',
      editable: isEdit,
      field: 'periodLength',
      type: 'numericColumn',
      headerName: intl.formatMessage(messages.periodLength),
      valueFormatter: numberCellFormatter,
      width: isEdit ? 110 : 90,
    },
    {
      cellRendererFramework: isEdit ? datePicker(null) : undefined,
      valueFormatter: dateFormatter,
      colId: 'startDate',
      field: 'startDate',
      headerName: intl.formatMessage(messages.startDate),
      width: isEdit ? 180 : 90,
    },
    {
      cellRendererFramework: isEdit ? datePicker('startDate', true) : undefined,
      valueFormatter: dateFormatter,
      colId: 'endDate',
      field: 'endDate',
      headerName: intl.formatMessage(messages.endDate),
      width: isEdit ? 180 : 90,
    },
  ];
  return colDefs;
};

const dataMapping = activity => ({
  ...activity,
  startDate: activity.startDate || DateTime.local(),
  endDate: activity.endDate || DateTime.local(),
});

const config = {
  header: messages.locationHeader,
  dataElementName: 'unproductiveLocations',
  additionalColumns: getColumnDefs,
  customDataMapping: dataMapping,
  logging: false,
};

const withPlanConnect = connect(
  null,
  { deleteActivityLines: deletePlanActivityLines },
);
const withPaConnect = connect(
  null,
  { deleteActivityLines: deletePaActivityLines },
);

export const UnproductiveLocationPlan = injectIntl(withPlanConnect(createUnproductiveSection(config)));
export const UnproductiveLocationPa = injectIntl(withPaConnect(createUnproductiveSection(config)));
