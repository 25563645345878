import { defineMessages } from 'react-intl';

export default defineMessages({
  job: {
    defaultMessage: 'Job',
    id: 'app.containers.ShiftScheduleStaffDetailPage.job',
  },
  back: {
    defaultMessage: 'Back',
    id: 'app.containers.ShiftScheduleStaffDetailPage.back',
  },
  basicInfo: {
    defaultMessage: 'Basic Information',
    id: 'app.containers.ShiftScheduleStaffDetailPage.basicInfo',
  },
  confirmDirty: {
    defaultMessage: 'There are unsaved changes at this activity. Do you want to leave the page without saving them?',
    id: 'app.containers.ShiftScheduleStaffDetailPage.confirmDirty',
  },
  days: {
    defaultMessage: 'Days',
    id: 'app.components.ShiftScheduleStaffDetailPage.days',
  },
  duration: {
    defaultMessage: 'Duration (minutes)',
    id: 'app.containers.ShiftScheduleStaffDetailPage.duration',
  },
  firstName: {
    defaultMessage: 'First Name',
    id: 'app.containers.ShiftScheduleStaffDetailPage.firstName',
  },
  frequency: {
    defaultMessage: 'Frequency',
    id: 'app.containers.ShiftScheduleStaffDetailPage.frequency',
  },
  header: {
    defaultMessage: 'Staff Detail',
    id: 'app.containers.ShiftScheduleStaffDetailPage.header',
  },
  kronosId: {
    defaultMessage: 'SmartShift ID',
    id: 'app.containers.ShiftScheduleStaffDetailPage.kronosId',
  },
  laborCategory: {
    defaultMessage: 'Labor Category',
    id: 'app.containers.ShiftScheduleStaffDetailPage.laborCategory',
  },
  lastName: {
    defaultMessage: 'Last Name',
    id: 'app.containers.ShiftScheduleStaffDetailPage.lastName',
  },
  mainFacility: {
    defaultMessage: 'Main Facility',
    id: 'app.containers.ShiftScheduleStaffDetailPage.mainFacility',
  },
  next: {
    defaultMessage: 'Next',
    id: 'app.containers.ShiftScheduleStaffDetailPage.next',
  },
  occurrenceDay: {
    defaultMessage: 'Occurrence day',
    id: 'app.containers.ShiftScheduleStaffDetailPage.occurrenceDay',
  },
  occurrenceOrder: {
    defaultMessage: 'Occurrence order',
    id: 'app.containers.ShiftScheduleStaffDetailPage.occurrenceOrder',
  },
  periodLength: {
    defaultMessage: 'Period length',
    id: 'app.containers.ShiftScheduleStaffDetailPage.periodLength',
  },
  previous: {
    defaultMessage: 'Previous',
    id: 'app.containers.ShiftScheduleStaffDetailPage.previous',
  },
  primaryJob: {
    defaultMessage: 'Primary Job',
    id: 'app.containers.ShiftScheduleStaffDetailPage.primaryJob',
  },
  priority: {
    defaultMessage: 'Priority',
    id: 'app.containers.ShiftScheduleStaffDetailPage.priority',
  },
  proficiency: {
    defaultMessage: 'Proficiency',
    id: 'app.containers.ShiftScheduleStaffDetailPage.proficiency',
  },
  selectFrequency: {
    defaultMessage: 'Select Frequency',
    id: 'app.containers.ShiftScheduleStaffDetailPage.selectFrequency',
  },
  selectOccurrenceDay: {
    defaultMessage: 'Select Occurrence day',
    id: 'app.containers.ShiftScheduleStaffDetailPage.selectOccurrenceDay',
  },
  setStartTime: {
    defaultMessage: 'Set Start time',
    id: 'app.containers.ShiftScheduleStaffDetailPage.setStartTime',
  },
  staffJobSettings: {
    defaultMessage: 'Personal Job Settings',
    id: 'app.containers.ShiftScheduleStaffDetailPage.staffJobSettings',
  },
  startTime: {
    defaultMessage: 'Start time',
    id: 'app.containers.ShiftScheduleStaffDetailPage.startTime',
  },
  unproductiveJobs: {
    defaultMessage: 'Unproductive Jobs',
    id: 'app.containers.ShiftScheduleStaffDetailPage.unproductiveJobs',
  },
  unproductiveActivity: {
    defaultMessage: 'Activity',
    id: 'app.containers.ShiftScheduleStaffDetailPage.unproductiveActivity',
  },
  validFrom: {
    defaultMessage: 'Valid from',
    id: 'app.containers.ShiftScheduleStaffDetailPage.validFrom',
  },
  validTo: {
    defaultMessage: 'Valid to',
    id: 'app.containers.ShiftScheduleStaffDetailPage.validTo',
  },
  setEndTime: {
    defaultMessage: 'Set end time',
    id: 'app.containers.ShiftScheduleStaffDetailPage.setEndTime',
  },
  endTime: {
    defaultMessage: 'End time',
    id: 'app.containers.ShiftScheduleStaffDetailPage.endTime',
  },
});
