import { numberFormat, toNumber } from './utils';

export const makeChecksumGetterByFilter = filterFunction => params =>
  params.data
    ? Object.keys(params.data)
        .filter(filterFunction)
        .reduce((p, value) => p + toNumber(params.data[value]), 0)
    : null;

export const isInTolerance = value => {
  if (value) {
    return Math.abs(toNumber(value) - 100.0) < 0.01;
  }
  return undefined;
};

export const checksumPercentageFormatter = params => {
  const inTolerance = isInTolerance(params.value);
  if (inTolerance === true) {
    return '100%';
  }
  if (inTolerance === false) {
    return `${numberFormat(toNumber(params.value).toFixed(2))}%`;
  }
  return null;
};

export const checkSumValidationStyle = params => {
  const inTolerance = isInTolerance(params.value);
  if (inTolerance !== false) {
    return { backgroundColor: 'white', color: 'black' };
  }
  return { backgroundColor: 'red', color: 'white' };
};
