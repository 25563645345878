import React from 'react';
import styled from 'styled-components';
import IconButton from '../IconButton';
import messages from './messages';

const Wrap = styled.div`
  display: flex;
  align-items: flex-end;
  height: 31px;
  ${IconButton} {
    padding-top: 5px;
    height: 25px;
  }
`;

export default params => {
  if (!params.data) return <span />;
  const isOpen = params.data && params.data.isShiftOpen;

  return (
    <Wrap>
      <IconButton
        icon={isOpen ? 'angle-up' : 'angle-down'}
        message={messages.addUPHShiftButtonLabel}
        onClick={() => params.onClickHandler(params.data)}
      />
    </Wrap>
  );
};
