/*
 * AppConstants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here. We prefix them with 'yourproject/YourComponent' so we avoid
 * reducers accidentally picking up actions they shouldn't.
 *
 * Follow this format:
 * export const YOUR_ACTION_CONSTANT = 'yourproject/YourContainer/YOUR_ACTION_CONSTANT';
 */

import activityDirectEditTableConfig from './data/activityDirectEditTableConfig.json';
import activityDirectTableConfig from './data/activityDirectTableConfig.json';
import activityIndirectEditTableConfig from './data/activityIndirectEditTableConfig.json';
import activityIndirectTableConfig from './data/activityIndirectTableConfig.json';
import areaListTableConfig from './data/areaListTableConfig.json';
import baselineConfig from './data/baselineConfig.json';
import dayOfWeekVolumeCatTransformationTableConfig from './data/dayOfWeekVolumeCatTransformationTableConfig.json';
import dayToHourConfig from './data/dayToHourConfig.json';
import dayToHourEditConfig from './data/dayToHourEditConfig.json';
import dayToWzpConfig from './data/dayToWzpConfig.json';
import dayToWzpEditConfig from './data/dayToWzpEditConfig.json';
import labourAvailabilityCategoryTableConfig from './data/labourAvailabilityCategoryTableConfig.json';
import labourAvailabilityCategoryTableEditConfig from './data/labourAvailabilityCategoryTableEditConfig.json';
import labourAvailabilityOverviewConfig from './data/labourAvailabilityOverviewConfig.json'
import masterPlanListTableConfig from './data/masterPlanListTableConfig.json';
import mheAvailabilityTableConfig from './data/mheAvailabilityTableConfig.json';
import mheAvailabilityTableEditConfig from './data/mheAvailabilityTableEditConfig.json';
import mheTableConfig from './data/mheTableConfig.json';
import perShiftConfig from './data/perShiftConfig.json';
import perShiftEditConifg from './data/perShiftEditConfig.json';
import mheTableEditConfig from './data/mheTableEditConfig.json';
import planCalculationStatisticsTableConfig from './data/planCalculationStatisticsTableConfig.json';
import planListTableConfig from './data/planListTableConfig.json';
import plannedVolumeTableConfig from './data/plannedVolumeTableConfig.json';
import plannedVolumeTableEditConfigConfig from './data/plannedVolumeTableEditConfig.json';
import plannedVolumeTableVarConfig from './data/plannedVolumeVarTableConfig.json';
import plannedVolumeTableEditVarConfigConfig from './data/plannedVolumeTableVarEditConfig.json';
import resultMainTable from './data/resultMainTable.json';
import resultMHETable from './data/resultMHETable.json';
import shiftDetailLabourCategoryEditTableConfig from './data/shiftDetailLabourCategoryEditTableConfig.json';
import shiftDetailLabourCategoryTableConfig from './data/shiftDetailLabourCategoryTableConfig.json';
import shiftSettingsEditVolume from './data/shiftSettingsEditVolume.json';
import shiftSettingsTableConfig from './data/shiftSettingsTableConfig.json';
import shiftSettingsTableEditConfig from './data/shiftSettingsTableEditConfig.json';
import shiftSettingsVolume from './data/shiftSettingsVolume.json';
import shiftSettingsWZPEditVolume from './data/shiftSettingsWZPEditVolume.json';
import smartProdSourceListTableConfig from './data/smartProdSourceListTableConfig.json';
import smartProdSourceDetailsPlanConfiguration from './data/smartProdSourceDetailsPlanConfiguration.json';
import smartProdSourceDetailsPlanningAreaConfiguration from './data/smartProdSourceDetailsPlanningAreaConfiguration.json';
import transformationTableConfig from './data/transformationTableConfig.json';
import uploadsTableConfig from './data/uploadsTableConfig.json';
import wzpTableConfig from './data/wzpTableConfig.json';
import adjustmentsTable from './data/adjustmentsTable.json';
import adjustmentsEditTable from './data/adjustmentsEditTable.json';
import adjustmentsIndirectTable from './data/adjustmentsIndirectTable.json';
import adjustmentsIndirectEditTable from './data/adjustmentsIndirectEditTable.json';
import masterPlanLogs from './data/masterPlanLogs.json';

export const PATHS = {
  root: '/',
  mainScreen: '/main',
  planList: '/plan',
  planListNoReset: '/plan/reset/:reset',
  login: '/login/:mode',
  loginReset: '/login/reset',
  loginUser: '/login/user',
  planDetail: '/plan',
  planDetailId: '/plan/:id',
  masterplanplanDetailId: '/masterPlan/plan/:id',
  planResult: '/plan/result',
  masterplanplanResult: '/masterPlan/plan/result',
  masterplanResult: '/masterPlan/result',
  planningAreasList: '/area',
  planningAreasListNoReset: '/area/reset/:reset',
  planningAreaDetail: '/area/:id',
  masterplanplanningAreaDetail: '/masterPlan/area/:id',
  masterPlanList: '/masterPlan',
  masterPlanListNoReset: '/masterPlan/reset/:reset',
  masterPlanDetail: '/masterPlan/:id',
  masterPlanSmartCodeDetails: '/smartCode/:id',
  masterPlanInterfaces: '/masterPlan/:id/interfaces/',
  historyMenu: '/history',
  history: '/history/:type/:id',
  historyPlan: '/history/plan/:id',
  historyPlanningArea: '/history/area/:id',
  masterPlanhistoryPlanningArea: '/masterPlan/history/area/:id',
  masterPlanhistoryPlan: '/masterPlan/history/plan/:id',
  masterPlanplanhistoryPlan: '/masterPlan/plan/history/plan/:id',
  smartProdSourceList: '/smartProdSource',
  smartProdSourceListNoReset: '/smartProdSource/reset/:reset',
  smartProdSourceDetail: '/smartProdSource/:id',

  forecast: '/forecast/',
  masterPlanPAforecast: '/masterPlan/forecast/',

  shiftScheduleDetailId: '/schedules/:id',
  shiftScheduleRuns: '/schedules/:id/runs',
  shiftScheduleRunsPowerBi: '/schedules/:id/runs/powerbi',
  shiftScheduleInterfaces: '/schedules/:id/interfaces',
  shiftScheduleList: '/schedules',
  shiftScheduleListNoReset: '/schedules/reset/:reset',
  makeShiftSchedule: '/schedules/create/insert',
  kronosJobDetailId: '/schedules/:scheduleId/kronos/:id',
  smartShiftJobScheduleDetailId: '/schedules/:scheduleId/smartShiftJob/:id',
  shiftScheduleStaffDetailId: '/schedules/:scheduleId/staff/:id',
  shiftScheduleRunDetailId: '/schedules/:scheduleId/run/:id',
  shiftScheduleRunDetailCopy: '/schedules/:scheduleId/copyRun/:id',

  makeCopy: '/:type/create/:operation/:id',
  test: '/test',
  makeCopyPA: '/area/create/copy/:id',
  makeCopyPlan: '/plan/create/copy/:id',
  make: '/:type/create/:operation',
  makePlanningArea: '/area/create/insert',
  makePlan: '/plan/create/insert',
  makePlanFromMasterPlan: '/masterPlan/plan/create/insert',
  makeMasterPlan: '/masterPlan/create/insert',
  makeSmartProdSource: '/smartProdSource/create/insert',
  SmartProdSoureceDetail: '/smartProdSource/:id',
  planInterfaces: '/plan/:id/interfaces/',
  planGraphs: '/plan/:id/graphs/',
  masterPlanplanGraphs: '/masterPlan/plan/:id/graphs/',
  planningAreaInterfaces: '/area/:id/interfaces/',
  masterPlanplanningAreaInterfaces: '/masterPlan/area/:id/interfaces/',
  masterPlanplanInterfaces: '/masterPlan/plan/:id/interfaces/',
  planComputationStatistics: '/plan/:id/statistics/:paramsid',
  masterPlanplanComputationStatistics: '/masterPlan/plan/:id/statistics/:paramsid',
};

export const API_CALL_STARTED = 'app/App/API_CALL_STARTED';
export const API_CALL_ENDED = 'app/App/API_CALL_ENDED';
export const LOAD_UOMS = 'app/App/LOAD_UOMS';
export const LOAD_ACTIVITIES = 'app/App/LOAD_ACTIVITIES';
export const SHOW_MESSAGE = 'app/App/SHOW_MESSAGE';
export const STORE_UOMS = 'app/App/STORE_UOMS';
export const STORE_ACTIVITIES = 'app/App/STORE_ACTIVITIES';
export const SAVE_TO_BROWSERSTORAGE = 'app/App/SAVE_TO_BROWSERSTORAGE';
export const LOAD_FROM_BROWSERSTORAGE = 'app/App/LOAD_FROM_BROWSERSTORAGE';
export const STORE_FROM_BROWSERSTORAGE = 'app/App/STORE_FROM_BROWSERSTORAGE';
export const STORE_ACTIVITIES_BY_PLAN = 'app/App/STORE_ACTIVITIES_BY_PLAN';
export const SET_VIEW = 'app/App/SET_VIEW';
export const STORE_BE_VERSION = 'app/App/STORE_BE_VERSION';

export const LOCAL_STORAGE_KEYS = {
  PLAN_LIST_TABLE_CONFIG: 'PLAN_LIST_TABLE_CONFIG',
  MASTER_LIST_TABLE_CONFIG: 'MASTER_PLAN_LIST_TABLE_CONFIG',
  AREA_LIST_TABLE_CONFIG: 'AREA_LIST_TABLE_CONFIG',
  AREA_DETAIL_TABLE_CONFIG: 'AREA_DETAIL_TABLE_CONFIG',
  PLAN_DETAIL_TABLE_CONFIG: 'PLAN_DETAIL_TABLE_CONFIG',
  SHIFT_SCHEDULE_TABLE_CONFIG: 'SHIFT_SCHEDULE_TABLE_CONFIG',
  SMART_PROD_SOURCE_LIST_TABLE_CONFIG: 'SMART_PROD_SOURCE_LIST_TABLE_CONFIG',
};

export const USER_VIEWS = { USER_VIEW: 'userView', DEFAULT_VIEW: 'defaultView' };

export const ACTIVITY_NAMES = { custom: 'customNames', default: 'defaultNames' };

export const TABLE_DEFAULTS = {
  dayToWzpConfig,
  dayToWzpEditConfig,
  dayToHourEditConfig,
  labourAvailabilityCategoryTableConfig,
  labourAvailabilityCategoryTableEditConfig,
  labourAvailabilityOverviewConfig,
  dayToHourConfig,
  AREA_DEFAULT: areaListTableConfig,
  PLAN_DEFAULT: planListTableConfig,
  MASTER_PLAN_DEFAULT: masterPlanListTableConfig,
  smartProdSourceListTableConfig,
  plannedVolumeTableConfig,
  plannedVolumeTableVarConfig,
  plannedVolumeTableEditConfigConfig,
  plannedVolumeTableEditVarConfigConfig,
  activityDirectTableConfig,
  activityIndirectTableConfig,
  mheTableConfig,
  perShiftConfig,
  activityDirectEditTableConfig,
  activityIndirectEditTableConfig,
  mheTableEditConfig,
  perShiftEditConifg,
  wzpTableConfig,
  transformationTableConfig,
  dayOfWeekVolumeCatTransformationTableConfig,
  resultMHETable,
  resultMainTable,
  baselineConfig,
  shiftDetailLabourCategoryEditTableConfig,
  shiftDetailLabourCategoryTableConfig,
  uploadsTableConfig,
  mheAvailabilityTableConfig,
  mheAvailabilityTableEditConfig,
  planCalculationStatisticsTableConfig,
  shiftSettingsTableConfig,
  shiftSettingsTableEditConfig,
  shiftSettingsEditVolume,
  shiftSettingsVolume,
  shiftSettingsWZPEditVolume,
  smartProdSourceDetailsPlanConfiguration,
  smartProdSourceDetailsPlanningAreaConfiguration,
  adjustmentsEditTable,
  adjustmentsTable,
  adjustmentsIndirectEditTable,
  adjustmentsIndirectTable,
  masterPlanLogs,
};

export const SAVE_PINNED_ONLY = [
  'resultMainTable',
  'resultMHETable',
  'budgetVolumeTable',
  'budgetVolumeTableEdit',
  'budgetVolumeTableVar',
  'budgetVolumeTableVarEdit',
  'plannedVolumeTable',
  'plannedVolumeTableEdit',
  'plannedVolumeTableVar',
  'plannedVolumeTableVarEdit',
  'budgetVolume',
  'dayToWzpEdit',
  'dayToWzp',
  'dayToHourEdit',
  'dayToHour',
];

export const LOCAL_STORAGE = [];
