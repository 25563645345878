import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the Adjustments state domain
 */

const selectAdjustmentsDomain = state => state.get('Adjustments', initialState);

/**
 * Other specific selectors
 */

/**
 * Default selector used by Adjustments
 */

const makeSelectAdjustments = () =>
  createSelector(
    selectAdjustmentsDomain,
    substate => substate.toJS(),
  );

export default makeSelectAdjustments;
export { selectAdjustmentsDomain };
