/**
 *
 * MasterPlanSmartCodeDetails
 *
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { AppBar, Box, createMuiTheme, Tab, Tabs, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import * as TabPanel from 'redux-form';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import Button from 'components/Button';
import { FormattedMessage } from 'react-intl';
import { ACTIVE_ENV } from '../../utils/activeEnv';

import { getToken } from '../../containers/App/selectors';
import { withUrl } from '../../utils/api';
import { parseDateTimeAndFormatToLocalString } from '../../utils/dateTime';
import { fetchData } from '../../utils/reduxApi';
import ToggleSection from '../ToggleSection';
import messages from './messages';
import SmartCodeTable from './SmartCodeTable';
import IconButton from 'components/IconButton';

const Wrapper = styled.div`
  margin: 59px 10px 10px 10px;
  background-color: #fff;
  box-shadow: 0 2px 6px 1px rgb(0 0 0 / 15%);
  position: relative;
`;

const WrHeader = styled.div`
  //padding: 15px;
`;
const Title = styled.p`
  font-size: 12px;
  font-weight: 700;
  display: inline-block;
  margin: 0;
`;

const TitleValue = styled.p`
  font-size: 12px;
  font-weight: 300;
  display: inline-block;
  margin: 0;
`;

const WrTable = styled.div`
  margin: -15px;
  padding-top: 20px;
`;

const Space = styled.div`
  margin-left: 20px;
`;

export const materialTheme = () =>
  createMuiTheme({
    overrides: {
      MuiTab: {
        root: {
          minWidth: '76px !important',
          fontWeight: 'bold',
          backgroundColor: '#eeeeee',
          borderRadius: '10px 10px 0px 0px',
          boxShadow: 'rgb(0 0 0 / 15%) 0px 2px 6px 1px',
          // textTransform: Ca,
        },
      },
      MuiAppBar: {
        positionAbsolute: {
          top: '-58px',
          left: '-10px',
          paddingTop: '5px',
          paddingLeft: '5px',
        },
        colorPrimary: {
          backgroundColor: 'transparent',
        },
      },
      MuiButtonBase: {
        root: {
          color: '#000',
          marginTop: '5px',
          marginLeft: '5px',
          marginRight: '10px',
          border: 'none !important',
          '&.Mui-selected': {
            backgroundColor: 'rgb(255 255 255)',
            border: 'none',
          },
          '&:hover': { backgroundColor: 'rgb(255 204 2)' },
          '&:selected': { backgroundColor: 'rgb(255 204 2)', border: 'none' },
        },
      },
      MuiTabs: {
        root: {
          top: '-30px',
        },
        flexContainer: {
          borderRadius: '10px 10px 0px 0px',
          fontWeight: 'bold',
          backgroundColor: 'transparent',
        },
        fixed: {
          backgroundColor: 'transparent',
        },
      },
      MuiPaper: {
        elevation4: {
          boxShadow: 'none',
        },
        root: {
          boxShadow: 'none',
        },
      },
      MuiDialogTitle: {
        root: {
          paddingBottom: '5px',
        },
      },
      MuiRadio: {
        colorSecondary: {
          '&.Mui-checked': { color: '#3a74ce !important' },
        },
      },
      MuiButton: {
        root: {
          '&.Mui-disabled': { backgroundColor: 'rgb(153, 153, 153)' },
        },
      },
    },
  });
const MasterPlanSmartCodeDetails = props => {
  const dispatch = useDispatch();
  // const [headers, setHeaders] = useState(null);
  const [mappings, setMappings] = useState(null);
  // const [isSmartCodeTableExpanded, setIsSmartCodeTableExpanded] = useState(false);
  const token = useSelector(getToken);

  const muiTheme = materialTheme();
  /* eslint-disable react/prefer-stateless-function */
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [value, setValue] = useState(0);

  // eslint-disable-next-line no-shadow
  function TabPanel(props) {
    // eslint-disable-next-line no-shadow
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  function loadMappings() {
    fetchSmartCodeDetails(props.masterPlan.id);
  }

  function fetchSmartCodeDetails(masterPlanId) {
    return fetchData(withUrl(`/smartCode/mappings/${masterPlanId}`).andToken(token), dispatch).then(result => {
      if (result.isOk) {
        setMappings(result.data);
      }
    });
  }

  function renderTable(mapping) {
    if (mapping) {
      return <SmartCodeTable data={mapping}></SmartCodeTable>;
    }
    return <div>No Mappings for this header</div>;
  }

  const handleRefresh = async () => {
    const masterPlanUrl = `/masterPlan/sendMasterPlanToSmartCode?id=${props.masterPlan.id}`;
    const response = await fetchData(withUrl(masterPlanUrl).post().andToken(token).asRawResponse(), dispatch);
    if(response.isOk){
      toast.info('Refresh is in progress, it may take more than 30 minutes to complete.')
    }
  }

  const showSmartCode = (header) => {
    return `${ACTIVE_ENV?.smartCodeURL}${header?.headerId}`
  }

  // @ts-ignore
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };
  return (
    <MuiThemeProvider theme={muiTheme}>
      <Wrapper>
        <AppBar position="absolute" className="wr-smart-code-detail">
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="simple tabs example"
            indicatorColor="none"
            textColor="black"
          >
            {props.data ? props.data.map((header, index) => <Tab label={`Header ${header.headerId}`} />) : ''}
          </Tabs>
        </AppBar>
        <WrHeader>
          {props.data ? (
            props.data.map((header, index) => (
              <div>
                <TabPanel value={value} index={index}>
                  <Grid container spacing={1}>
                    <Grid xs={12}>
                    <Grid container xs={12} spacing={0}>
                      <IconButton
                        message={messages.refresh}
                        id="resfresh-schedule"
                        onClick={handleRefresh}
                      />
                      <Space/>
                      <Button>
                        <a href={showSmartCode(header)} target='_blank' style={{textDecoration: 'none', color: 'black'}} >
                          <FormattedMessage {...messages.showInSmartCode}/>
                        </a>
                      </Button>
                      </Grid>
                    </Grid>
                    <Grid xs={4}>
                      <Grid container xs={12} spacing={0}>
                        <Grid xs={5} sm={6}>
                          <Title>{props.intl.formatMessage(messages.smartCodeHeader)}</Title>
                        </Grid>
                        <Grid xs={5}>
                          <TitleValue>{header.headerId}</TitleValue>{' '}
                        </Grid>
                      </Grid>

                      <Grid container xs={12} spacing={0}>
                        <Grid xs={5} sm={6}>
                          <Title>{props.intl.formatMessage(messages.facilityHeader)}</Title>
                        </Grid>
                        <Grid xs={5}>
                          <TitleValue>{header.facility}</TitleValue>{' '}
                        </Grid>
                      </Grid>

                      <Grid container xs={12} spacing={0}>
                        <Grid xs={5} sm={6}>
                          <Title>{props.intl.formatMessage(messages.managementUnit)}</Title>
                        </Grid>
                        <Grid xs={5}>
                          <TitleValue>{header.managementUnit}</TitleValue>{' '}
                        </Grid>
                      </Grid>

                      <Grid container xs={12} spacing={0}>
                        <Grid xs={5} sm={6}>
                          <Title>{props.intl.formatMessage(messages.customerHeader)}</Title>
                        </Grid>
                        <Grid xs={5}>
                          <TitleValue>{header.customer}</TitleValue>{' '}
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid xs={4}>
                    <Grid container xs={12} spacing={0}>
                        <Grid xs={5} sm={6}>
                          <Title>{props.intl.formatMessage(messages.smartProdInstance)}</Title>
                        </Grid>
                        <Grid xs={5}>
                          <TitleValue>{header.smartProdInstance}</TitleValue>
                        </Grid>
                      </Grid>
                      <Grid container xs={12} spacing={0}>
                        <Grid xs={5} sm={6}>
                          <Title>{props.intl.formatMessage(messages.smartProdWHID)}</Title>
                        </Grid>
                        <Grid xs={5}>
                          <TitleValue>{header.smartProdWHID}</TitleValue>
                        </Grid>
                      </Grid>
                      <Grid container xs={12} spacing={0}>
                        <Grid xs={5} sm={6}>
                          <Title>{props.intl.formatMessage(messages.lastSynchronized)}</Title>
                        </Grid>
                        <Grid xs={5}>
                          <TitleValue>{parseDateTimeAndFormatToLocalString(header.lastSynchronized)}</TitleValue>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <WrTable>
                    <ToggleSection
                      message={messages.smartCodeMappingTitle}
                      className="padding-0"
                      expanded
                      // expanded={isSmartCodeTableExpanded}
                      // onToggle={loadMappings}
                    >
                      {renderTable(header.mappings)}
                    </ToggleSection>
                  </WrTable>
                </TabPanel>
              </div>
            ))
          ) : (
            <div></div>
          )}
        </WrHeader>
      </Wrapper>
    </MuiThemeProvider>
  );
};
export default MasterPlanSmartCodeDetails;
