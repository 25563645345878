import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import Menu from 'components/Menu';

export const WrapTopMenu = styled.div`
  background-image: url(${props => props.theme.image.menuGradient1});
  background-size: cover;
  background-position: center;
  height: 67px;
  display: flex;
  margin-top: 0px;
  flex-flow: row wrap;
  justify-content: flex-start;
  padding-left: 37px;
  align-items: center;
  position: relative;
`;

export const WrapBrands = styled.div`
  flex-grow: 1;
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const Brand = styled.div`
  background-image: url(${props => props.theme.image.brand});
  background-size: cover;
  background-position: center;
  width: 144px;
  height: 20px;
  margin-top: 20px;
  transition: all 4s ease-in-out;
`;

export const BrandText = styled(props => {
  const { className } = props;
  return (
    <div className={className} id="BrandText">
      <FormattedMessage {...props.labelMessage} values={props.labelValues} />
    </div>
  );
})`
  color: ${props => props.theme.color.red};
  font-weight: bold;
  margin-left: 18px;
  margin-top: 20px;
  font-size: 120%;
`;

export const WrapItems = styled.form`
  display: flex;
  height: 100%;
  flex-flow: row wrap;
  justify-content: flex-end;
  align-items: start;
  > * {
    margin-left: 20px;
  }
`;

export const TopMenu = styled(Menu)`
  margin-right: 50px;
  z-index: ${props => props.theme.zIndex.topMenu};
`;

export const RightNav = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
  margin-right: 10px;
`;
