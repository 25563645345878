import React from 'react';
import { FormattedMessage, InjectedIntl, injectIntl } from 'react-intl';
import styled from 'styled-components';
import { formatDate, formatTime } from 'utils/dateTime';
import Button from 'components/Button';

import { ApiSmartProdSourceDTO } from 'types/drep-backend';

import messages from './messages';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

const ColumnWrapper = styled.div`
  flex-grow: ${props => (props.size ? props.size : 1)};
  display: grid;
  grid-template-columns: fit-content(40%) auto;
  grid-row-gap: ${props => props.theme.grid.rowGap};
  align-items: normal;
  font-size: 12px;
  > * {
    margin-right: 10px;
  }
  > span {
    white-space: nowrap;
  }
`;

const RefreshNowButton = styled(Button)`
  margin: 10px;
  height: 28px;
  width:100px;
`;

const Value = styled.div`
  font-weight: bold;
  min-height: ${props => props.theme.grid.tinyRowHeight};
  width:130px;
`;

const ValueText = styled.div`
  min-height: ${props => props.theme.grid.tinyRowHeight};
`;

type SmartVolumeSchedulingProps = {
  entity: ApiSmartProdSourceDTO;
  RefreshSmartVolumeRuns: Function;
  hasRunningRun: boolean;
  initialRequestSmartVolumeForecast: boolean;
};

const SmartVolumeScheduling = (props: SmartVolumeSchedulingProps) => {
  const { entity, RefreshSmartVolumeRuns, hasRunningRun, initialRequestSmartVolumeForecast } = props;

  if (!entity) return <span />;

  return (
    <Wrapper id="SmartProdSourceSmartVolumeScheduling">
      <ColumnWrapper>
      <Value><FormattedMessage {...messages.scheduleStartDate} /></Value>
       <ValueText> {formatDate(entity.scheduleStartDate)}</ValueText>
        <Value> <FormattedMessage {...messages.scheduleTime} /></Value>
        <ValueText>{formatTime(entity.scheduleStartDate)}</ValueText>
        <Value><FormattedMessage {...messages.schedulePeriod} /></Value>
        <ValueText>{entity.schedulePeriod}</ValueText>
      </ColumnWrapper>
      <ColumnWrapper>
      <RefreshNowButton disabled={hasRunningRun || !initialRequestSmartVolumeForecast} onClick = {RefreshSmartVolumeRuns} >
              <FormattedMessage {...messages.refreshNow} />
      </RefreshNowButton>
      </ColumnWrapper>
    </Wrapper>
  );
};

export default injectIntl(SmartVolumeScheduling);
