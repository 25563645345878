/*
 * ProductivityRate Messages
 *
 * This contains all the text for the ResultBasicInfo component.
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  granularity: {
    id: 'app.components.ProductivityRate.granularity',
    defaultMessage: 'Granularity',
  },
  confirm: {
    id: 'app.components.ProductivityRate.confirm',
    defaultMessage: 'Confirm',
  },
  forecast: {
    id: 'app.components.ProductivityRate.forecast',
    defaultMessage: 'Forecast',
  },
  plan: {
    id: 'app.components.ProductivityRate.plan',
    defaultMessage: 'Plan',
  },
  backToForecast: {
    id: 'app.components.ProductivityRate.restoreForecast',
    defaultMessage: 'Restore Forecast',
  },
  modifyMultiple: {
    id: 'app.components.ProductivityRate.modifyMultiple',
    defaultMessage: 'Modify Multiple',
  },
  activityKey: {
    id: 'app.components.ProductivityRate.activityKey',
    defaultMessage: 'Facility/Customer/Department/Activity',
  },
  uom: {
    id: 'app.components.ProductivityRate.uom',
    defaultMessage: 'Unit of Measure',
  },
  week: {
    id: 'app.components.ProductivityRate.week',
    defaultMessage: 'Week',
  },
  day: {
    id: 'app.components.ProductivityRate.day',
    defaultMessage: 'Day',
  },
  noActivitiesWarning: {
    id: 'app.components.ProductivityRate.noActivitiesWarning',
    defaultMessage: 'First set up activities in Activity settings below',
  },
  shift: {
    id: 'app.components.ProductivityRate.shift',
    defaultMessage: 'Shift',
  }
});
