/*
 * CreateSmartProdPage Messages
 *
 * This contains all the text for the CreateSmartProdPage component.
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  headerPAInsert: {
    id: 'app.containers.CreateSmartProdPage.headerPAInsert',
    defaultMessage: 'Create SmartProd Source',
  },

  basicInfo: {
    id: 'app.containers.CreateSmartProdPage.basicInfo',
    defaultMessage: 'Basic information',
  },
  description: {
    id: 'app.containers.CreateSmartProdPage.description',
    defaultMessage: 'Description',
  },
  facility: {
    id: 'app.containers.CreateSmartProdPage.facility',
    defaultMessage: 'Facility',
  },
  smartProdInstance: {
    id: 'app.containers.CreateSmartProdPage.smartProdInstance',
    defaultMessage: 'SmartProd Instance',
  },
  whID: {
    id: 'app.containers.CreateSmartProdPage.whID',
    defaultMessage: 'WHID',
  },

});
