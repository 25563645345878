export class ApiOptions {
  constructor(url) {
    this.url = url;
    this.logCalls = true;
    this.method = 'GET';
    this.token = null;
    this.body = null;
    this.addToken = true;
    this.title = null;
    this.rawResponse = false;
    this.contentType = 'application/json; charset=utf-8';
    this.isFileDownload = false;
  }

  setFileDownload = isFileDownload => {
    this.isFileDownload = isFileDownload;
    return this;
  };

  andToken = token => {
    this.token = token;
    return this;
  };

  andBody = body => {
    this.body = JSON.stringify(body);
    return this;
  };

  andFile = file => {
    this.body = file;
    return this;
  };

  andTitle = title => {
    this.title = title;
    return this;
  };

  andContentType = contentType => {
    this.contentType = contentType;
    return this;
  };

  asRawResponse = () => {
    this.rawResponse = true;
    return this;
  };

  post = optionalBody => {
    this.method = 'POST';
    if (optionalBody) {
      this.body = JSON.stringify(optionalBody);
    }
    return this;
  };

  patch = optionalBody => {
    this.method = 'PATCH';
    if (optionalBody) {
      this.body = JSON.stringify(optionalBody);
    }
    return this;
  };

  put = optionalBody => {
    this.method = 'PUT';
    if (optionalBody) {
      this.body = JSON.stringify(optionalBody);
    }
    return this;
  };

  delete = () => {
    this.method = 'DELETE';
    return this;
  };

  disableLogCalls = logResponse => {
    this.logCalls = logResponse || false;
    return this;
  };
}
