import { Component } from 'react';
import * as React from 'react';
import { FormikProps } from 'formik';
import { InjectedIntl } from 'react-intl';
import styled from 'styled-components';

import { ComponentWithDirtyCheck } from 'components/ButtonWithDirtyCheck';
import IconButton from 'components/IconButton';
import EnchantedMap from 'utils/enchantedMap';

import { UNSET_COST } from '../constants';
import messages from '../messages';
import { ApiSchedule, TransferCostEntry } from '../types';
import { convertArrayToMapWithReduce } from '../utils';
import { CommentTooltipRenderer } from './commentTooltipRenderer';
import StatusCellRenderer from './statusCellRenderer';
import TotalCostWithTooltip from './totalCostWithTooltip';
import { getInDepartment, getInFacility, setComment, setCostValue } from './utils';

const HideButton = styled(IconButton)`
  display: inline-block;
  height: 28px;
  margin: 12px 0;
`;

export interface AbstractTransferCostsTableProps {
  dispatch?: any;
  formik: FormikProps<ApiSchedule>;
  intl: InjectedIntl;
  isEdit: boolean;
  idToFacilityCost: Map<any, TransferCostEntry>;
  idToDepartmentCost: Map<any, TransferCostEntry>;
  token?: string;
}

export default abstract class AbstractTransferCostsTable extends Component<AbstractTransferCostsTableProps, {}> {
  public state = {
    hideBlank: false,
  };

  protected facilityById: Map<number, string>;

  protected departmentById: Map<number, string>;

  protected smartShiftJobScheduleById: Map<number, string>;

  protected customerById: Map<number, string>;

  protected abstract getFormikPath(): string;

  protected beforeRender() {
    const { values } = this.props.formik;
    this.facilityById = convertArrayToMapWithReduce(
      values.facilities,
      item => item.id,
      item => item.name,
    );
    this.departmentById = convertArrayToMapWithReduce(
      values.departments,
      item => item.id,
      item => item.name,
    );
    this.smartShiftJobScheduleById = convertArrayToMapWithReduce(
      values.smartShiftJobSchedules,
      item => item.id,
      item => item.activity,
    );
    this.customerById = convertArrayToMapWithReduce(
      values.smartShiftJobSchedules,
      item => item.id,
      item => item.customer?.name || '',
    );
  }

  protected hideBlankButton(dirtyCheck = false) {
    const hideBlank = () => this.setState({ hideBlank: !this.state.hideBlank });
    const onReset = () => {
      // Cache of changed activity transfer costs must be reset
      this.props.formik.setFieldValue('activityTransferCostsMap', new EnchantedMap());
    };
    const label = this.state.hideBlank ? messages.showBlank : messages.hideBlank;

    if (dirtyCheck) {
      const inner = ({ onClickHandler }) => <HideButton onClick={onClickHandler} label={label} />;
      return (
        <ComponentWithDirtyCheck key="hide" actionHandler={hideBlank} onReset={onReset}>
          {inner}
        </ComponentWithDirtyCheck>
      );
    }
    return <HideButton key="hide" onClick={hideBlank} label={label} />;
  }

  protected valueFormatter = suffix => params => {
    if (!params.data) {
      return params.value;
    }
    const isDisabled =
      params.data[`disabled${suffix}`] ||
      getInFacility('disabled', params.data, suffix) ||
      getInDepartment('disabled', params.data, suffix);

    return isDisabled ? UNSET_COST : params.data[`value${suffix}`];
  };

  protected customerGetter = suffix => params =>
    params.data ? this.customerById.get(params.data[`smartShiftJobSchedule${suffix}`]) : '';

  protected activityGetter = suffix => params =>
    params.data ? this.smartShiftJobScheduleById.get(params.data[`smartShiftJobSchedule${suffix}`]) : '';

  protected facilityGetter = suffix => params =>
    params.data ? this.facilityById.get(params.data[`facility${suffix}`]) : '';

  protected departmentGetter = suffix => params =>
    params.data ? this.departmentById.get(params.data[`department${suffix}`]) : '';

  protected facilityFromColumn = (additional, header = messages.activityFacility) => ({
    cellClass: 'fromCellColor',
    colId: 'facilityFrom',
    field: 'facilityFrom',
    headerName: this.props.intl.formatMessage(header),
    valueGetter: this.facilityGetter('From'),
    ...additional,
  });

  protected facilityToColumn = (additional, header = messages.activityFacility) => ({
    cellClass: 'toCellColor',
    colId: 'facilityTo',
    field: 'facilityTo',
    headerName: this.props.intl.formatMessage(header),
    valueGetter: this.facilityGetter('To'),
    ...additional,
  });

  protected departmentFromColumn = additional => ({
    cellClass: 'fromCellColor',
    colId: 'departmentFrom',
    field: 'departmentFrom',
    headerName: this.props.intl.formatMessage(messages.activityDepartment),
    valueGetter: this.departmentGetter('From'),
    ...additional,
  });

  protected departmentToColumn = additional => ({
    cellClass: 'toCellColor',
    colId: 'departmentTo',
    field: 'departmentTo',
    headerName: this.props.intl.formatMessage(messages.activityDepartment),
    valueGetter: this.departmentGetter('To'),
    ...additional,
  });

  protected valueFromColumn = () => ({
    cellClass: 'fromCellColor',
    colId: 'valueFrom',
    field: 'valueFrom',
    headerName: this.props.intl.formatMessage(messages.from),
    valueFormatter: this.valueFormatter('From'),
    valueSetter: params => setCostValue(this.props.formik, this.getFormikPath(), params),
  });

  protected valueToColumn = () => ({
    cellClass: 'toCellColor',
    colId: 'valueTo',
    field: 'valueTo',
    headerName: this.props.intl.formatMessage(messages.to),
    valueFormatter: this.valueFormatter('To'),
    valueSetter: params => setCostValue(this.props.formik, this.getFormikPath(), params),
  });

  protected totalsColumns = sizing => [
    {
      cellRendererFramework: TotalCostWithTooltip,
      cellRendererParams: {
        smartShiftJobScheduleById: this.smartShiftJobScheduleById,
        departmentById: this.departmentById,
        facilityById: this.facilityById,
      },
      cellClass: 'fromCellColor',
      colId: 'totalFrom',
      field: 'totalFrom',
      filter: false,
      headerName: this.props.intl.formatMessage(messages.totalFrom),
      sortable: false,
      ...sizing,
    },
    {
      cellRendererFramework: TotalCostWithTooltip,
      cellRendererParams: {
        smartShiftJobScheduleById: this.smartShiftJobScheduleById,
        departmentById: this.departmentById,
        facilityById: this.facilityById,
      },
      cellClass: 'toCellColor',
      colId: 'totalTo',
      field: 'totalTo',
      filter: false,
      headerName: this.props.intl.formatMessage(messages.totalTo),
      sortable: false,
      ...sizing,
    },
  ];

  protected statusColumn = (additional = {}) => ({
    cellRendererFramework: StatusCellRenderer,
    cellRendererParams: {
      isEdit: this.props.isEdit,
    },
    colId: 'status',
    field: 'status',
    headerName: this.props.intl.formatMessage(messages.status),
    onCellClicked: params => {
      if (this.props.isEdit) {
        const isDisabled = params.data.disabledFrom && params.data.disabledTo;
        if (isDisabled) {
          params.node.setDataValue('valueFrom', params.data.valueFrom);
          params.node.setDataValue('valueTo', params.data.valueTo);
        } else {
          params.node.setDataValue('valueFrom', UNSET_COST);
          params.node.setDataValue('valueTo', UNSET_COST);
        }
      }
    },
    flex: 1,
    ...additional,
  });

  protected commentColumn = (additional = {}) => ({
    cellEditor: this.props.isEdit ? 'agLargeTextCellEditor' : undefined,
    cellEditorParams: {
      maxLength: 300,
    },
    headerName: this.props.intl.formatMessage(messages.comment),
    cellRendererFramework: !this.props.isEdit ? CommentTooltipRenderer : undefined,
    colId: 'comment',
    editable: this.props.isEdit,
    field: 'comment',
    valueSetter: params => setComment(this.props.formik, this.getFormikPath(), params),
    flex: 1,
    ...additional,
  });
}
