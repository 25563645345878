/*
 * SideBar Messages
 *
 * This contains all the text for the SideBar component.
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  home: {
    id: 'app.components.SideBar.home',
    defaultMessage: 'Home page',
  },
  planningAreas: {
    id: 'app.components.SideBar.planningAreas',
    defaultMessage: 'List of planning areas',
  },
  plans: {
    id: 'app.components.SideBar.plans',
    defaultMessage: 'List of plans',
  },
  shifts: {
    id: 'app.components.SideBar.shifts',
    defaultMessage: 'List of schedules',
  },
  about: {
    id: 'app.components.SideBar.about',
    defaultMessage: 'About page',
  },
  masterPlanList: {
    id: 'app.components.SideBar.masterPlans',
    defaultMessage: 'List of master plans',
  },
});
