import React from "react";
import DialogDHL from 'components/Dialog';
import DialogDHLWarning from 'components/Dialog/DialogWarning';
import DialogTitle from '@material-ui/core/DialogTitle';
import { FormattedMessage } from "react-intl";

import messages from './messages';
import IconButton from 'components/IconButton';
import styled from 'styled-components';

const StyledButton = styled(IconButton)`
background: none;
box-shadow: none;
display: flex;
flex-direction: row-reverse;
padding: 16px 10px;
`;

const Space = styled.div`
  margin-left: 20px;
`;

const DialogNoPlansWarning = ({ open, closeDialog }) => {
    return (
        <DialogDHLWarning
            style={{ width: '250px' }}
            open={!!open}
            maxWidth="xs"
            headerLabel={messages.emptyHeader}
            CustomButtons={
                <React.Fragment key={1}>
                </React.Fragment>
            }
        >
            <DialogTitle style={{padding: '8px 0px'}} id="noplanswarning">
                <StyledButton icon="times" onClick={() => closeDialog()} />
            </DialogTitle>
            <Space />
            <FormattedMessage {...messages.noPlansWarning} />
        </DialogDHLWarning>
    )
}

export default DialogNoPlansWarning;