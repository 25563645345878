import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import SmartProdRunsAddForm, { NewRunType } from 'containers/ForecastDetailPage/SmartProdRunsAddForm';
import SmartProdRunsTable from 'containers/ForecastDetailPage/SmartProdRunsTable';
import { EntityEntry, SmartProdRunEnum, SmartProdRunsType } from 'containers/ForecastDetailPage/types';

import messages from './messages';
import TimeTransformationRunTable from './TimeTransformationRunTable';

const SectionTitle = styled.div`
  font-size: ${props => props.theme.fontSize.title};
  font-weight: 700;
  margin-bottom: 8px;
  margin-top: 8px;
`;

type Props = {
  forecastEdit: boolean;
  data: SmartProdRunsType;
  allOmsCodes: EntityEntry[];
  allSmartProdSources: EntityEntry[];
  planningParametersId: number;
  handleSubmitPage: Function;
  handleReloadData: () => void;
  hasAdjustments: boolean;
  planDetails,
};

const SmartProdRuns: React.FC<Props> = ({
  forecastEdit,
  data,
  allOmsCodes,
  allSmartProdSources,
  planningParametersId,
  handleSubmitPage,
  handleReloadData,
  hasAdjustments,
  planDetails
}) => {
  const [recentlyCreatedRun, setRecentlyCreatedRun] = useState<NewRunType>(null);
  return (
    data &&
    allOmsCodes &&
    allSmartProdSources && (
      <div>
        <SmartProdRunsAddForm
          planningParametersId={planningParametersId}
          forecastEdit={forecastEdit}
          handleReloadData={handleReloadData}
          allSmartProdSources={allSmartProdSources}
          setRecentlyCreatedRun={setRecentlyCreatedRun}
        />
        <SmartProdRunsTable
          runType={SmartProdRunEnum.REQUEST}
          data={data}
          forecastEdit={forecastEdit}
          allOmsCodes={allOmsCodes}
          allSmartProdSources={allSmartProdSources}
          planningParametersId={planningParametersId}
          handleSubmitPage={handleSubmitPage}
          handleReloadData={handleReloadData}
          recentlyCreatedRun={
            ((recentlyCreatedRun?.smartProdRunType === SmartProdRunEnum.REQUEST) && (!recentlyCreatedRun.timeTransformation))  ? recentlyCreatedRun?.id : null
          }
          hasAdjustments={hasAdjustments}
        />
        <SectionTitle>
          <FormattedMessage {...messages.smartProdRunsScheduledRuns} />
        </SectionTitle>
        <SmartProdRunsTable
          runType={SmartProdRunEnum.SCHEDULE}
          data={data}
          forecastEdit={forecastEdit}
          allOmsCodes={allOmsCodes}
          allSmartProdSources={allSmartProdSources}
          planningParametersId={planningParametersId}
          handleSubmitPage={handleSubmitPage}
          handleReloadData={handleReloadData}
          recentlyCreatedRun={
            recentlyCreatedRun?.smartProdRunType === SmartProdRunEnum.REQUEST ? null : recentlyCreatedRun?.id
          }
          hasAdjustments={hasAdjustments}
        />
        <SectionTitle>
          <FormattedMessage {...messages.timeTransformationTableRun} />
        </SectionTitle>
        <TimeTransformationRunTable
          runType={SmartProdRunEnum.REQUEST}
          data={data}
          forecastEdit={forecastEdit}
          allOmsCodes={allOmsCodes}
          allSmartProdSources={allSmartProdSources}
          planningParametersId={planningParametersId}
          handleSubmitPage={handleSubmitPage}
          handleReloadData={handleReloadData}
          recentlyCreatedRun={
            ((recentlyCreatedRun?.smartProdRunType === SmartProdRunEnum.REQUEST) && (recentlyCreatedRun.timeTransformation))  ? recentlyCreatedRun?.id : null
          }
          hasAdjustments={hasAdjustments}
          planDetails={planDetails}
        />
      </div>
    )
  );
};

export default SmartProdRuns;
