import React from 'react';
import { Field } from 'formik';
import styled from 'styled-components';
import Select from 'components/StyledSelect';

const StyledSelect = styled(Select)`
  
  margin-top: 5px;
`;


const FacilitySelection = ({fetchNewFacilities, entityRelations, handleFacilityFlag}) => {
    return (
        <Field
            name="entity.facility"
            render={({ field, form }) => (
                <StyledSelect
                    id="facility"
                    options={entityRelations}
                    onInputChange={fetchNewFacilities}
                    onChange={option => {
                        form.setFieldValue(field.name, {id: option.id, code: option.code, name: option.name});
                        handleFacilityFlag();
                    }}
                    value={entityRelations.find(o => o.value === field.value) || null}
                />
            )}
        />
    )
}


export default FacilitySelection;