import { defineMessages } from 'react-intl';

export default defineMessages({
  scheduleStartDate: {
    id: 'app.containers.AutomaticRefreshForReportingSettings.scheduleStartDate',
    defaultMessage: 'Schedule Start Date',
  },
  scheduleTime: {
    id: 'app.containers.AutomaticRefreshForReportingSettings.scheduleTime',
    defaultMessage: 'Schedule Time',
  },
  schedulePeriod: {
    id: 'app.containers.AutomaticRefreshForReportingSettings.schedulePeriod',
    defaultMessage: 'Schedule Period (days)',
  },
  refreshNowButton: {
    id: 'app.containers.AutomaticRefreshForReportingSettings.refreshNowButton',
    defaultMessage: 'Refresh Now',
  },
  refreshStartDate: {
    id: 'app.containers.AutomaticRefreshForReportingSettings.refreshStartDate',
    defaultMessage: 'Report start date',
  },
  refreshDates: {
    id: 'app.containers.AutomaticRefreshForReportingSettings.refreshDates',
    defaultMessage: 'Report dates selection',
  },
  refreshStartDateSelect: {
    id: 'app.containers.AutomaticRefreshForReportingSettings.refreshStartDateSelect',
    defaultMessage: 'Report start date',
  },
  refreshEndDateSelect: {
    id: 'app.containers.AutomaticRefreshForReportingSettings.refreshEndDateSelect',
    defaultMessage: 'Report end date',
  },
  setDefaultButton: {
    id: 'app.containers.AutomaticRefreshForReportingSettings.setDefaultButton',
    defaultMessage: 'Set Default',
  },
  clearSchedulingButton: {
    id: 'app.containers.AutomaticRefreshForReportingSettings.clearScheduling',
    defaultMessage: 'Clear Schedule',
  },
  masterPlanHasNoAssignedPlans: {
    id: 'app.containers.MasterPlanDetailPage.masterPlanHasNoAssignedPlans',
    defaultMessage: "Can't create run, because no plan is assigned to this master plan",
  },
});
