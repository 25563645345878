/*
 * mheAvailabilityOverview Messages
 *
 * This contains all the text for the mheAvailabilityOverview component.
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  granularity: {
    id: 'app.components.mheAvailabilityOverview.granularity',
    defaultMessage: 'Granularity',
  },
  availableHeads: {
    id: 'app.components.mheAvailabilityOverview.availableHeads',
    defaultMessage: 'Available MHE (pieces)',
  },
  confirm: {
    id: 'app.components.mheAvailabilityOverview.confirm',
    defaultMessage: 'Confirm',
  },
  week: {
    id: 'app.components.mheAvailabilityOverview.week',
    defaultMessage: 'Week',
  },
  day: {
    id: 'app.components.mheAvailabilityOverview.day',
    defaultMessage: 'Day',
  },
  shift: {
    id: 'app.components.mheAvailabilityOverview.shift',
    defaultMessage: 'Shift',
  },
  labourCategory: {
    id: 'app.components.mheAvailabilityOverview.labourCategory',
    defaultMessage: 'Labour Category',
  },
  department: {
    id: 'app.components.mheAvailabilityOverview.department',
    defaultMessage: 'Department',
  },
  mheName:{
    id: 'app.components.mheAvailabilityOverview.mheName',
    defaultMessage: 'MHE',
  },
  name:{
    id: 'app.components.mheAvailabilityOverview.name',
    defaultMessage: 'Name',
  },
  wzpName:{
    id: 'app.components.mheAvailabilityOverview.wzpName',
    defaultMessage: 'WZP',
  },
  departmentName:{
    id: 'app.components.mheAvailabilityOverview.departmentName',
    defaultMessage: 'Department',
  },
  maintenanceDay:{
    id: 'app.components.mheAvailabilityOverview.maintenanceDay',
    defaultMessage: 'Maintenance Day',
  },
  maintenanceRate:{
    id: 'app.components.mheAvailabilityOverview.maintenanceRate',
    defaultMessage: 'Maintenance Rate',
  },
  key:{
      id: 'app.components.mheAvailabilityOverview.key',
      defaultMessage: 'Key',
  },
});
