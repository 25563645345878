import { createSelector } from 'reselect';

const selectUserDomain = state => state.get('user');

export const makeSelectUser = () =>
  createSelector(
    selectUserDomain,
    substate => substate.get('user'),
  );
// to utilize reselect
export const makeSelectUserSimple = () =>
  createSelector(
    selectUserDomain,
    substate => substate.toJS(),
  );
export const makeSelectToken = () =>
  createSelector(
    selectUserDomain,
    substate => substate.get('token'),
  );
