import { defineMessages } from 'react-intl';

const scope = 'app.containers.PlanGraphs';
export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: 'Plan {name} - Graphs',
  },
  content: {
    id: `${scope}.content`,
    defaultMessage: 'Plan Graphs',
  },
  basicInfo: {
    id: `${scope}.basicInfo`,
    defaultMessage: 'Basic Information',
  },
});
