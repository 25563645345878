import { isEqual } from 'lodash';
import { convertArrayToMap } from '../containers/ShiftScheduleDetailPage/utils';
import EnchantedMap from './enchantedMap';

interface ArrayDiffs<T> {
  deletedIds: any[];

  added: T[];

  modified: T[];
}

/**
 * Find the diffs between two arrays
 *
 * @param initialArray
 * @param newArray
 * @param idField name of id field, `id` by default
 * @param comparator custom comparator, which tells whether two objects are equal. Must return `true` when equal.
 * Used for detecting the modified objects. Optional, deep equality is used by default.
 */
export function findDiffs<T>(
  initialArray: T[],
  newArray: T[],
  idField = 'id',
  comparator: (left: T, right: T) => boolean = isEqual,
): ArrayDiffs<T> {
  const result: ArrayDiffs<T> = {
    added: [],
    deletedIds: [],
    modified: [],
  };

  const idToInitial = convertArrayToMap(initialArray || [], i => i[idField]);

  const idToNew = new Map<any, T>();
  newArray.forEach(item => {
    if (item[idField]) {
      idToNew.set(item[idField], item);
      if (!idToInitial.get(item[idField])) {
        result.added.push(item);
      }
    } else {
      result.added.push(item);
    }
  });

  // Find deleted and modified entities
  for (const id of idToInitial.keys()) {
    const newItem = idToNew.get(id);

    if (!newItem) {
      result.deletedIds.push(id);
    } else if (!comparator(idToInitial.get(id), newItem)) {
      result.modified.push(newItem);
    }
  }

  return result;
}

/**
 * Find overlaps in from-to dates between items in the given array
 */
export function findOverlaps<T>(items: T[]): T[] {
  const overlaps = [];
  // @ts-ignore
  items.sort((a, b) => (a.validFrom > b.validFrom ? 1 : -1));

  for (let i = 0; i < items.length; i++) {
    const left = items[i];
    for (let k = i + 1; k < items.length; k++) {
      const right = items[k];

      // @ts-ignore
      const rightFrom = right.validFrom;
      // @ts-ignore
      const leftTo = left.validTo;
      // @ts-ignore
      const leftFrom = left.validFrom;

      if (leftFrom.hasSame(rightFrom, 'day')) {
        overlaps.push(left);
        overlaps.push(right);
      } else {
        if (leftTo) {
          if (rightFrom <= leftTo) {
            overlaps.push(left);
            overlaps.push(right);
          }
        }
      }
    }
  }

  return overlaps;
}
