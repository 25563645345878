/**
 *
 * ResultBasicInfo
 *
 */

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { FormattedMessage, injectIntl } from 'react-intl';
import { parseDateAndFormatToLocalString } from 'utils/dateTime';
import messages from './messages';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

const ColumnWrapper = styled.div`
  flex-grow: ${props => (props.size ? props.size : 1)};
  display: grid;
  grid-row-gap: ${props => props.theme.grid.rowGap};
  grid-template-columns: fit-content(40%) auto;
  align-items: normal;
  font-size: 12px;
  > * {
    margin-right: 10px;
  }
  > span {
    white-space: nowrap;
  }
`;

const SmallTable = styled.table`
  display: inline-block;
  margin-top: -4px !important;
  font-weight: bold;
  th {
    font-weight: normal;
  }
  th,
  td {
    padding: 5px;
    text-align: left;
  }
`;

const Value = styled.div`
  font-weight: bold;
  min-height: ${props => props.theme.grid.tinyRowHeight};
`;
const Wrappable = styled(Value)`
  white-space: normal;
`;

function MasterPlanInterfaceBasicInfo(props) {
  const {
    masterPlan,
    intl: { formatMessage },
    planBasicDetails = [],
    isresultPage = false,
  } = props;
  const [planInfo, setPlanInfo] = useState('');

  useEffect(() => {

    let facilities = masterPlan.facilities;
    let customers = masterPlan.customers;
    const planName = masterPlan.name;
    const { description } = masterPlan;
    const plans = masterPlan.plans;
    const weekStartDays = [...new Set(planBasicDetails?.map((pbd) => pbd.planningParameters.firstDayOfWeek))];
    const countries = [...new Set(planBasicDetails?.map((pbd) => pbd.planningArea.country.name))];
    const regions = [...new Set(planBasicDetails?.map((pbd) => pbd.planningArea.country.regionName))];
    setPlanInfo({
      facilities,
      customers,
      planName,
      description,
      plans,
      countries,
      regions,
      weekStartDays
    });
  }, [masterPlan, planBasicDetails]);
  return (
    <Wrapper>
      <ColumnWrapper>
        <FormattedMessage {...messages.name} />
        <Value>{planInfo.planName}</Value>
        <FormattedMessage {...messages.description} />
        <Wrappable>{planInfo.description}</Wrappable>
        {
          isresultPage &&
          <>
            <FormattedMessage {...messages.weekStartDay} />
            <Value>{planInfo.weekStartDays && planInfo.weekStartDays.map(week => <div key={week}>{week}</div>)}</Value>
          </>
        }
      </ColumnWrapper>
      <ColumnWrapper size={2}>
        <FormattedMessage {...messages.customer} />
        <Value>{planInfo.customers && planInfo.customers.map(cust => <div key={cust.id}>{cust.name}</div>)}</Value>
        <FormattedMessage {...messages.facility} />
        <Value>{planInfo.facilities && planInfo.facilities.map(f => <div key={f.id}>{f.name}</div>)}</Value>
      </ColumnWrapper>
      {
        isresultPage &&
        <ColumnWrapper size={2}>
          <FormattedMessage {...messages.region} />
          <Value>{planInfo.regions && planInfo.regions.map(reg => <div key={reg}>{reg}</div>)}</Value>
          <FormattedMessage {...messages.country} />
          <Value>{planInfo.countries && planInfo.countries.map(con => <div key={con}>{con}</div>)}</Value>
        </ColumnWrapper>
      }
      <ColumnWrapper>
        <FormattedMessage {...messages.plans} />
        <SmallTable>
          <thead>
            <tr>
              <th>
                <FormattedMessage {...messages.name} />
              </th>
              <th>
                <FormattedMessage {...messages.validFrom} />
              </th>
              <th>
                <FormattedMessage {...messages.validTo} />
              </th>
            </tr>
          </thead>
          <tbody>
            {planInfo.plans && planInfo.plans.map((plan) => (
              <tr>
                <td>{plan.planName}</td>
                <td>{parseDateAndFormatToLocalString(plan.validFrom)}</td>
                <td>{parseDateAndFormatToLocalString(plan.validTo)}</td>
              </tr>
            ))}
          </tbody>
        </SmallTable>
      </ColumnWrapper>
    </Wrapper>
  );
}

export default injectIntl(MasterPlanInterfaceBasicInfo);
