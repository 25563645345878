import React from 'react';
import { withStyles, makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';
import { selectErrorDescriptions } from 'containers/ShiftScheduleRunsPage/selectors';

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#337AB7',
    color: 'white',
    maxWidth: 500,
    maxHeight: 250,
    fontSize: 12,
  },
}))(Tooltip);

const style = {
  color: '#337AB7',
  fontWeight: 'bold',
};

const buttonStyle = {
  backgroundColor: 'transparent'
}

const buttonHideStyle = {
  display: 'none'
}

const ScheduleRunRendererErrorsTooltip = (params) => {
  const errorDescriptions = useSelector(selectErrorDescriptions);
  const errorDesc = errorDescriptions && errorDescriptions.results && errorDescriptions.results.filter((error) => error.code === params.data.name);
  const noErrorData = errorDesc && errorDesc[0] && errorDesc && errorDesc[0].description;
  return (
    <div>
      <HtmlTooltip
        arrow
        placement="bottom"
        title={
          <React.Fragment>
            {noErrorData && <span>{noErrorData}</span>}
          </React.Fragment>
        }
      >
        <Button style={!noErrorData ? buttonHideStyle: buttonStyle}><FontAwesomeIcon style={style} icon="question-circle" size="sm" /></Button>
      </HtmlTooltip>
    </div>
  );
}

export default ScheduleRunRendererErrorsTooltip;
