import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the dayToHourTransformation state domain
 */

const selectDayToHourTransformationDomain = state => state.get('dayToHourTransformation', initialState);

/**
 * Other specific selectors
 */

/**
 * Default selector used by DayToHourTransformation
 */

const makeSelectDayToHourTransformation = () =>
  createSelector(
    selectDayToHourTransformationDomain,
    substate => substate.toJS(),
  );

export default makeSelectDayToHourTransformation;
export { selectDayToHourTransformationDomain };
