import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = (styles, height) =>
  makeStyles(theme => ({
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    chip: {
      background: styles.color.yellow,
      color: styles.color.black,
      fontSize: '0.7em',
      fontWeight: '500',
      height: '25px',
      borderRadius: '5px',
      margin: '2px',
    },

    select: {
      width: '100%',
      minHeight: '38px',
    },
    selectHeight: {
      width: '100%',
      height: `${height}px`,
    },
    value: {
      textTransform: 'lowercase',
      '&:first-letter': {
        textTransform: 'uppercase',
      },
    },

    values: {
      display: 'inline-block',
      '&:first-letter': {
        textTransform: 'uppercase',
      },
      '&:not(:last-child)::after': {
        content: "', '",
        whiteSpace: 'pre',
      },
    },
  }));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
