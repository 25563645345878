/*
 *
 * PlanDetailPage reducer
 *
 */

import { fromJS } from 'immutable';

import { SAVE_PA } from 'containers/PlanningAreaDetailPage/constants';

import { DEFAULT_GUI_STATE, handleCommonEventsForMasterPlan } from '../../utils/commonDetailReducer';
import {
  CLEAR_MASTER_PLAN,
  CLEAR_MASTER_PLAN_CONFLICT,
  SAVE_MASTER_PLAN,
  SAVE_MASTER_PLAN_CONFLICT,
  START_NEW,
  STORE_MASTER_PLAN,
  SUBMIT_ERROR,
} from './constants';

export const initialState = fromJS({
  masterPlan: null,
  guiState: DEFAULT_GUI_STATE,
  activityIdsMheOpen: [],
});

function planDetailPageReducer(state = initialState, action) {
  const commonResult = handleCommonEventsForMasterPlan(state, action);
  if (commonResult) {
    return commonResult;
  }
  switch (action.type) {
    case CLEAR_MASTER_PLAN:
      return state.set('masterPlan', null);
    case START_NEW:
      return initialState.setIn(['guiState', 'edit'], true).setIn(['guiState', 'isNew'], true);
    case SUBMIT_ERROR:
      return state.setIn(['guiState', 'submitError'], true);
    case SAVE_MASTER_PLAN:
      return state.setIn(['guiState', 'submitError'], false);
    case SAVE_MASTER_PLAN_CONFLICT:
      return state.setIn(['guiState', 'submitError'], false).setIn(['guiState', 'masterPlanConflict'], action.payload);
    case CLEAR_MASTER_PLAN_CONFLICT:
      return state.setIn(['guiState', 'masterPlanConflict'], null);
    default:
      return state;
  }
}

export default planDetailPageReducer;
