/*
 *
 * LoginPage constants
 *
 */

export const STORE_TOKEN = 'app/LoginPage/STORE_TOKEN';
export const STORE_USER = 'app/LoginPage/STORE_USER';
export const LOGIN = 'app/LoginPage/LOGIN';
export const RESET = 'app/LoginPage/RESET';
export const LOGOUT = 'app/LoginPage/LOGOUT';
export const LOAD_USER = 'app/LoginPage/LOAD_USER';

export const COOKIE_CAS_TGT = 'drep_CAS_TGT';
export const COOKIE_CAS_ST = 'drep_CAS_ST';
