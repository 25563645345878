import * as React from 'react';
import { useEffect, useState } from 'react';
import messages from './messages';
import { formatDateToApiFormat } from 'utils/api';
import { injectIntl } from 'react-intl';
import { DateTime, Duration } from 'luxon';
import CircularProgress from '@material-ui/core/CircularProgress';
import { GRANULARITY } from './calculation/types';
import { NamedEntity } from '../ShiftScheduleDetailPage/types';
import { parseDate } from 'utils/dateTime';
import { formatDate, parseTimes } from '../../utils/dateTime';
import Table from 'components/Table/index';
import { ACTIVE_ENV } from '../../utils/activeEnv';

interface UnproductiveActivitiesResultProps {
  intl: any;
  startDate?: DateTime;
  endDate?: DateTime;
  granularity?: GRANULARITY;
  planningParametersId?: number;
  token?: string;
}

enum DataState {
  NoInput,
  Loading,
  Error,
  Loaded,
}

interface TimedEntity {
  id: Number;
  startTime: Duration;
  endTime: Duration;
}
interface DayEntry {
  date: DateTime;
  activities: TimedEntity[];
  wzps: TimedEntity[];
}

interface ResponseData {
  activities: NamedEntity[];
  wzps: NamedEntity[];
  days: DayEntry[];
}

interface UnproductiveActivitiesResultState {
  dataState: DataState;
  data: ResponseData;
}

const INITIAL_STATE = {
  dataState: DataState.NoInput,
  data: null,
};

function parseDatesAndTimes(results: any): ResponseData {
  return {
    activities: results.activities || [],
    wzps: results.wzps || [],
    days: (results.days || []).map(it => ({
      date: parseDate(it.date),
      activities: (it.activities || []).map(a => parseTimes(a, ['startTime', 'endTime'])),
      wzps: (it.wzps || []).map(w => parseTimes(w, ['startTime', 'endTime'])),
    })),
  };
}

const UnproductiveActivitiesResult = injectIntl((props: UnproductiveActivitiesResultProps) => {
  const [state, setState] = useState<UnproductiveActivitiesResultState>(INITIAL_STATE);
  useEffect(() => {
    const allowedGranularity =
      props.granularity === GRANULARITY.DAY ||
      props.granularity === GRANULARITY.WZP ||
      props.granularity === GRANULARITY.HOUR;
    if (props.startDate && props.endDate && allowedGranularity && props.planningParametersId) {
      setState({ dataState: DataState.Loading, data: null });
      const uriParams = `?dateFrom=${formatDateToApiFormat(props.startDate)}&dateTo=${formatDateToApiFormat(
        props.endDate,
      )}`;
      const fullUrl = `${ACTIVE_ENV.basePathBe}/planningParameters/${
        props.planningParametersId
      }/unproductiveActivities${uriParams}`;
      const headers = { Authorization: `Bearer ${props.token}` };
      const fetchOptions = { method: 'GET', headers };
      try {
        fetch(fullUrl, fetchOptions)
          .then(response => {
            if (response.status >= 200 && response.status < 300) {
              return response.json();
            }
            throw response;
          })
          .then(results => {
            setState({ dataState: DataState.Loaded, data: parseDatesAndTimes(results) });
          })
          .catch(err => {
            setState({ dataState: DataState.Error, data: null });
            if (process.env.NODE_ENV !== 'production'){
              console.log('Fetch promise error', err);
            }else{
              console.log('Failed to fetch the Unproductive Activities data')
            }
          });
      } catch (err) {
        setState({ dataState: DataState.Error, data: null });
        if (process.env.NODE_ENV !== 'production'){
          console.log('Fetch error', err);
        }else{
          console.log('Failed to fetch the Unproductive Activities data')
        }
      }
    } else {
      setState({ dataState: DataState.NoInput, data: null });
    }
  }, [props.startDate, props.endDate, props.granularity, props.planningParametersId]);
  switch (state.dataState) {
    case DataState.Loading:
      return <CircularProgress size={20} />;
    case DataState.Loaded:
      return <UnproductiveActivitiesTable granularity={props.granularity} data={state.data} intl={props.intl} />;
    case DataState.NoInput:
    default:
      return null;
  }
});

interface UnproductiveActivitiesTableProps {
  granularity: GRANULARITY;
  data: ResponseData;
  intl: any;
}

interface DataCellType {
  field: string;
  dayEntry: DayEntry;
  startTime: Duration;
  endTime: Duration;
}

function getDurationsOverlap(startTime: Duration, endTime: Duration, startTime2: Duration, endTime2: Duration) {
  const maxStart = Math.max(startTime.valueOf(), startTime2.valueOf());
  const minEnd = Math.min(endTime.valueOf(), endTime2.valueOf());
  return maxStart < minEnd ? (minEnd - maxStart) / 60000 : 0;
}

const UnproductiveActivitiesTable = (props: UnproductiveActivitiesTableProps) => {
  const { granularity, data, intl } = props;
  const dataColumns: DataCellType[] = [];
  const wzpMap = {};
  data.wzps.forEach(wzp => (wzpMap[wzp.id] = wzp.name));
  const columnDefs = [
    {
      colId: 'activity',
      field: 'activity',
      width: 250,
      headerName: intl.formatMessage(messages.activity),
      headerTooltip: intl.formatMessage(messages.activity),
      pinned: true,
    },
  ];
  data.days.forEach(dayEntry => {
    const dayColumn = {
      colId: dayEntry.date.toISODate(),
      field: dayEntry.date.toISODate(),
      width: 100,
      headerName: formatDate(dayEntry.date),
      headerTooltip: formatDate(dayEntry.date),
      children: undefined,
      pinned: false,
    };
    if (granularity === GRANULARITY.DAY) {
      dataColumns.push({
        field: dayColumn.field,
        dayEntry,
        startTime: Duration.fromMillis(0),
        endTime: Duration.fromMillis(24 * 60 * 60 * 1000),
      });
    } else {
      dayColumn.children = [];
      dayEntry.wzps.forEach(wzp => {
        const wzpColumn = {
          colId: `${dayColumn.field}-${wzp.id}`,
          field: `${dayColumn.field}-${wzp.id}`,
          width: 100,
          headerName: wzpMap[wzp.id.toString()],
          headerTooltip: wzpMap[wzp.id.toString()],
          children: undefined,
        };
        dayColumn.children.push(wzpColumn);
        if (granularity === GRANULARITY.WZP) {
          dataColumns.push({
            field: wzpColumn.field,
            dayEntry,
            startTime: wzp.startTime,
            endTime: wzp.endTime,
          });
        } else {
          // hour granularity
          wzpColumn.children = [];
          let hourStart = wzp.startTime.normalize().hours;
          const lastStartHour = wzp.endTime.normalize().hours;
          while (hourStart <= lastStartHour) {
            const hourColumn = {
              colId: `${wzpColumn.field}-${hourStart}`,
              field: `${wzpColumn.field}-${hourStart}`,
              width: 100,
              headerName: `${hourStart}:00`,
              headerTooltip: `${hourStart}:00`,
            };
            dataColumns.push({
              field: hourColumn.field,
              dayEntry,
              startTime: Duration.fromObject({ hours: hourStart }),
              endTime: Duration.fromObject({ hours: hourStart + 1 }),
            });
            wzpColumn.children.push(hourColumn);
            hourStart += 1;
          }
        }
      });
    }
    columnDefs.push(dayColumn);
  });
  const rowData = data.activities.map(activityEntry => {
    const row = { activity: activityEntry.name };
    dataColumns.forEach(dataCell => {
      //const thisActivityInDay = dataCell.dayEntry.activities.find(a => a.id === activityEntry.id);
      // To filter same activities
      const thisActivityInDayFilter = dataCell.dayEntry.activities.filter(a => a.id === activityEntry.id);
      thisActivityInDayFilter.forEach((a)=>{
        if (a) {
          if (row[dataCell.field] == null){
            row[dataCell.field] =
            getDurationsOverlap(
              dataCell.startTime,
              dataCell.endTime,
              a.startTime,
              a.endTime,
            ) || null;
          }
        }
      })

    });
    return row;
  });
  return <Table columnDefs={columnDefs} rowData={rowData} domLayout="autoHeight" onGridReady={() => {}} />;
};

export default UnproductiveActivitiesResult;
