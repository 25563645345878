// @flow
/**
 *
 * ResultMatrixColumnOptions
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

import Switch from 'components/Switch';
import messages from './messages';
import ToggleButton from '../../components/ToggleButton';
import { ButtonBar } from './styled';

const Space = styled.div`
  margin-left: 40px;
`;

type ToggleButtonType = {
  input: Object,
  children: React$Node,
};

export default function SmallGraphFilter(props: any) {
  const {
    effortFte = 'effort',
    baseline = true,
    planned = true,
    actuals = false,
    effortFteActivity = 'effort',
    baselineActivity = true,
    plannedActivity = true,
    actualsActivity = false,
    available = false,
    onClick,
    showAvailable = true,
  } = props;
  return (
    <ButtonBar>
      <Switch
        name="effortFte"
        option1={{ value: 'effort', label: <FormattedMessage {...messages.e} /> }}
        option2={{ value: 'fte', label: <FormattedMessage {...messages.fte} /> }}
        value={effortFte}
        onChange={() => onClick('effortFte', (effortFte === 'fte' && 'effort') || 'fte')}
      />
      <Space />
      <ToggleButton
        name="baseline"
        input={{
          value: baseline,
          onChange: () => onClick('baseline', !baseline),
        }}
      >
        <FormattedMessage {...messages.baseline} />
      </ToggleButton>
      <ToggleButton
        name="planned"
        input={{
          value: planned,
          onChange: () => onClick('planned', !planned),
        }}
      >
        <FormattedMessage {...messages.planned} />
      </ToggleButton>
      <ToggleButton
        name="actuals"
        input={{
          value: actuals,
          onChange: () => onClick('actuals', !actuals),
        }}
      >
        <FormattedMessage {...messages.actuals} />
      </ToggleButton>
      {showAvailable && (
        <ToggleButton
          name="available"
          input={{
            value: available,
            onChange: () => onClick('available', !available),
          }}
        >
          <FormattedMessage {...messages.available} />
        </ToggleButton>
      )}
    </ButtonBar>
  );
}
