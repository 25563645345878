import { defineMessages } from 'react-intl';

const scope = 'app.components.UISelect';

export default defineMessages({
  msgNoOptionsAvailable: {
    id: `${scope}.msgNoOptionsAvailable`,
    defaultMessage: 'No Options',
  },
  msgNoOptionsMatchFilter: {
    id: `${scope}.msgNoOptionsMatchFilter`,
    defaultMessage: 'No Option Available',
  },
  msgNoValidValue: {
    id: `${scope}.msgNoValidValue`,
    defaultMessage: 'Wrong Value',
  },
});
