export const RESTART_ON_REMOUNT = '@@saga-injector/restart-on-remount';
export const DAEMON = '@@saga-injector/daemon';
export const ONCE_TILL_UNMOUNT = '@@saga-injector/once-till-unmount';

export const SECTION = {
  BUDGET: 'budget',
  PLANNED: 'planned',
  ACTIVITY: 'activity',
  WZP: 'wzp',
  SHIFT: 'shift',
  TIME: 'time',
  ACTUAL: 'actual',
  EFFORT_FORECAST: 'effortForecast',
  ADJUSTMENTS: 'adjustments',
  LABOUR: 'labour',
  MHE: 'mhe',
  VC_TIME_TRANS: 'volCatTimeTrans',
  PROD_RATE: 'productivityRate',
  LABOUR_AVAILABILITY_OVERVIEW: 'labourAvailabilityOverView',
  MHE_AVAILABILITY_OVERVIEW: 'mheAvailabilityOverView',
  ADJUSTMENTS_OVERVIEW: 'adjustmentsOverview'
};

// Activity transformation Daily = Day of Week Transformation for Volume Category
// Activity transformation Weekly = Day of Week Transformation for Activity (default)
export const DOW_TRANS_VOLUME_CATEGORY_AKA_DAILY = 'VOLUME_CATEGORY'; //
export const DOW_TRANS_ACTIVITY_AKA_WEEKLY = 'ACTIVITY';

export const VOLUME_GRANULARITY_DAILY = 'DAY';
export const VOLUME_GRANULARITY_WEEKLY = 'WEEK';

export const LABOUR_AVAILABILITY_SHIFT = 'SHIFT_ONLY';
export const LABOUR_AVAILABILITY_DEPARTMENT = 'DEPARTMENT';
export const LABOUR_AVAILABILITY_ROLE = 'LABOUR_ROLE';

export const CALCULATE_TYPE_FTE = 'FTE';
export const CALCULATE_TYPE_STAFF = 'Staff';

export const T_TYPE = {
  WZP: 'WZP',
  SHIFT: 'SHIFT',
  HOUR: 'HOUR',
};
