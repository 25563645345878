import './style.css';

import * as React from 'react';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import { injectIntl } from 'react-intl';
import { compose } from 'redux';

import { UNSET_COST } from '../constants';
import messages from '../messages';
import { getInDepartment, getInFacility, reversedSuffix } from './utils';

interface TotalCostWithTooltipProps {
  intl: any;
  data: any;
  colDef: any;
  smartShiftJobScheduleById: Map<number, string>;
  facilityById: Map<number, string>;
  departmentById: Map<number, string>;
}

/**
 * Component for transfer total costs, displays the total value with a tooltip, describing how the value was calculated
 */
class TotalCostWithTooltip extends React.Component<TotalCostWithTooltipProps> {
  public render() {
    const {
      data,
      colDef,
      smartShiftJobScheduleById,
      facilityById,
      departmentById,
      intl: { formatMessage },
    } = this.props;

    if (!data) {
      return null;
    }

    const suffix = colDef.field.endsWith('From') ? 'From' : 'To';

    const getInData = field => data[field + suffix];
    const doGetInFacility = field => getInFacility(field, data, suffix);
    const doGetInDepartment = field => getInDepartment(field, data, suffix);

    // Check if any part of the path is disabled
    const disabledMsg =
      (doGetInFacility('disabled') && messages.facilityDisabled) ||
      (doGetInDepartment('disabled') && messages.departmentDisabled) ||
      (getInData('disabled') && messages.activityDisabled);

    if (disabledMsg) {
      return (
        <Tooltip title={formatMessage(disabledMsg)}>
          <span>{UNSET_COST}</span>
        </Tooltip>
      );
    }

    // If all are active, display the total value with a tooltip
    const facilityName = facilityById.get(data[`facility${reversedSuffix(suffix)}`]);
    const departmentName = departmentById.get(data[`department${reversedSuffix(suffix)}`]);
    const activityId = data[`smartShiftJobSchedule${reversedSuffix(suffix)}`];
    const activityName = activityId && smartShiftJobScheduleById.get(activityId);
    const totalValue = this.calcTotalValue(suffix, data, activityName);

    // Tooltip consists of separated columns: facility | department | activity | total
    const tooltip = (
      <>
        <div className="cost-tooltip-wrap">
          {data.parentFacility && column(facilityName, doGetInFacility('value'), true)}
          {data.parentDepartment && column(departmentName, doGetInDepartment('value'))}
          {activityName && column(activityName, getInData('value'))}
          {column(<div key="space" className="cost-tooltip-spacer" />, ` = ${totalValue}`, true)}
        </div>
      </>
    );
    return (
      <Tooltip title={tooltip}>
        <span>{totalValue}</span>
      </Tooltip>
    );
  }

  private calcTotalValue(suffix, data, activity) {
    let totalValue = (getInFacility('value', data, suffix) || 0) + (getInDepartment('value', data, suffix) || 0);

    if (activity) {
      totalValue += data[`value${suffix}`];
    }
    return Math.max(0, totalValue);
  }
}

const column = (item1, item2, skipPrefix = false) => (
  <div className="cost-tooltip-block">
    <div key="1">{skipPrefix ? item1 : `+ ${item1}`}</div>
    <div key="2">{skipPrefix ? item2 : `+ ${item2}`}</div>
  </div>
);

export default compose(
  injectIntl,
  // @ts-ignore
)(TotalCostWithTooltip);
