/**
 *
 * TopBar
 *
 */

 import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators, compose } from 'redux';
import { createStructuredSelector } from 'reselect';

import LanguageSelector from 'components/LanguageSelector';
import Menu, { Button } from 'components/UIMenu';
import { logoutAction } from 'containers/LoginPage/actions';
import {openUploadModal} from 'containers/UploadModal/actions';
import { makeSelectUserSimple } from 'containers/LoginPage/selectors';
import AdminUploadModal from '../AdminUploadModal';

import { PATHS } from '../../containers/App/constants';
import withSecurity, {PERMISSIONS, ROLES} from '../../utils/security';
import messages from './messages';
import { Brand, BrandText, RightNav, WrapBrands, WrapItems, WrapTopMenu } from './styled';
import { ACTIVE_ENV } from '../../utils/activeEnv';

/* eslint-disable react/prefer-stateless-function */
class TopBar extends React.PureComponent {
  render() {
    const {
      props: { labelMessage, labelValues, user },
    } = this;
    const hasSomeRoles = user.user && user.user.roles && user.user.roles.length > 0;
    const hasCdm = hasSomeRoles && user.user.roles.filter(r => (r.role || '').startsWith('cdm_')).length > 0;
    const isAdmin = hasSomeRoles && user.user.roles.find(r => r.role === ROLES.ADMIN.name);
    const assignedApps = [];
    const umApp = {
      value: 'um',
      name: 'User management',
      icon: 'users-cog',
      url: ACTIVE_ENV.umUrl,
    };
    const cdmApp = {
      value: 'cdm',
      name: 'Configuration Data Management',
      icon: 'database',
      url: '/cdm',
    };
    if (hasCdm) {
      assignedApps.push(cdmApp);
    }
    if (umApp.url) {
      assignedApps.push(umApp);
    }



    if (this.props.hasPerm(PERMISSIONS.VIEW_SMART_PROD_SOURCE)) {
      assignedApps.push({
        value: 'smp-prod-sources',
        name: this.props.intl.formatMessage(messages.smartProdSourceList),
        icon: 'user-clock',
        onClick: () => {
          this.props.history.push(PATHS.smartProdSourceList);
        },
      });
    }

  if (isAdmin) {
    assignedApps.push({
      value: 'smp-smartvolume-upload',
      name: this.props.intl.formatMessage(messages.smartVolumeManualUpdate),
      icon: 'upload',
      onClick: () => {
        this.props.UploadModal()
      }
    });
  }
    return (
      <WrapTopMenu>
        <WrapBrands>
          <Brand />
          <BrandText labelMessage={labelMessage || { ...messages.header }} labelValues={labelValues} />
        </WrapBrands>
        <WrapItems />
        <RightNav>
          <LanguageSelector />
          <AdminUploadModal/>
          {assignedApps.length && (
            <Menu
              id="topbar-button-configuration"
              translate
              header={messages.configuration}
              headerIcon="cog"
              items={assignedApps.map(app => ({
                value: app.label,
                name: app.name,
                icon: app.icon,
                url: app.url,
                onClick: app.onClick,
              }))}
            />
          )}
          <Button
            id="topbar-button-logout"
            onClick={() => this.props.logout()}
            icon="sign-out-alt"
            title={messages.logout}
            translate
          />
        </RightNav>
      </WrapTopMenu>
    );
  }

}

const mapStateToProps = createStructuredSelector({
  user: makeSelectUserSimple(),
});

function mapDispatchToProps(dispatch) {
  return {
   ...bindActionCreators(
     {
       logout: logoutAction,
       UploadModal: openUploadModal
     },
      dispatch,
     ),
     dispatch,
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(injectIntl, withRouter, withSecurity(), withConnect)(TopBar);
