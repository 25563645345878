import React, { useCallback } from 'react';
import { connect as formikConnect, FormikProps } from 'formik';
import { isEmpty } from 'lodash';
import { InjectedIntl, injectIntl } from 'react-intl';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Prompt } from 'react-router';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import { parserPlanResult, searchToData } from 'utils/url';
import { ComponentWithDirtyCheck } from 'components/ButtonWithDirtyCheck';
import { ConnectedDateRangeHeader } from 'components/DateRangeHeader';
import IconButton, { SaveButton } from 'components/IconButton';
import { Title } from 'components/PlanningAreaDetailToolbar';
import { ButtonsGroup, ButtonWrap } from 'components/ResultToolBar';
import UserViewToggleButton from 'components/ToggleButton/UserViewToggleButton';
import { PATHS } from 'containers/App/constants';
import { getToken } from 'containers/App/selectors';
import { formatDateToApiFormat, withUrl } from 'utils/api';
import { fetchData } from 'utils/reduxApi';
import { withScrollWatch } from 'utils/scroll';
import withSecurity, { PERMISSIONS } from 'utils/security';
import { ApiPlanDTO, ApiPlanningAreaDTO } from 'types/drep-backend.d';

import createUploadModal from '../UploadModal';
import { openUploadModalAction } from '../UploadModal/actions';
import messages from './messages';
import { ForecastForm } from './types';

const UploadModal = createUploadModal('forecastPage');
type Props = {
  fixed: boolean;
  intl: InjectedIntl;
  formik: FormikProps<ForecastForm>;
  isArea: boolean;
  planEdit: boolean;
  forecastEdit: boolean;
  plan?: ApiPlanDTO | ApiPlanningAreaDTO;
  editModeChange: (editMode: boolean) => void;
  handleGetSmartProdJobCodes: () => void;
  hasPerm: (permission: string) => boolean;
  user: any;
  history: RouteComponentProps.history;
  openUploadModal: Function;
  handleReloadData: Function;
};

const ToolBar: React.FC<Props> = ({
  intl,
  fixed,
  formik,
  isArea,
  history,
  planEdit,
  forecastEdit,
  plan,
  editModeChange,
  handleGetSmartProdJobCodes,
  openUploadModal,
  user,
  hasPerm,
  handleReloadData,
}) => {
  const hasPermissions = true; // TODO add permissions when they are defined
  const token = useSelector(getToken);
  const dispatch = useDispatch();
  const searchObject = history.location.search && searchToData(history.location.search, parserPlanResult);
  const fromMasterPlan = searchObject.masterPlanId;

  const handleClickBack = useCallback(() => {
    let path;
    if (isArea) {
      if(fromMasterPlan){
        path = `${PATHS.masterplanplanningAreaDetail.replace(':id', `${plan.id}`)}?isEdit=true&masterPlanId=${fromMasterPlan}`;
      }else{
      path = `${PATHS.planningAreaDetail.replace(':id', `${plan.id}`)}`;
      }
    } else {
      if(fromMasterPlan){
        path = `${PATHS.masterplanplanDetailId.replace(':id', `${plan.id}`)}?isEdit=true&masterPlanId=${fromMasterPlan}`;
      }else{
      path = `${PATHS.planDetailId.replace(':id', `${plan.id}`)}`;
      }
    }
    if (planEdit) {
      path = `${path}?isEdit=true`;
    }
    history.push(path);
  }, [isArea, history, plan]);

  const handleGoToInterfaces = useCallback(() => {
    if (isArea) {
      history.push(PATHS.planningAreaInterfaces.replace(':id', plan.id));
    } else {
      history.push(PATHS.planInterfaces.replace(':id', plan.id));
    }
  }, [isArea, history, plan]);

  const handleSaveData = () => {
    formik.submitForm();
  };

  const handleSaveFromTo = async ({ data }) => {
    const body = {
      startDay: formatDateToApiFormat(data.startDay),
      endDay: formatDateToApiFormat(data.endDay),
    };
    await fetchData(withUrl(`/planningParameters/${plan?.planningParameters?.id}`).post(body).andToken(token), dispatch);
    await handleReloadData();
  };

  return (
    plan && (
      <ButtonWrap fixed={fixed}>
        {hasPerm(PERMISSIONS.VIEW_PLAN_DETAILS) && (
          <IconButton icon="arrow-circle-left" id="back" tooltip={{ ...messages.back }} onClick={handleClickBack} />
        )}
        <Title>{plan.name}</Title>
        <Prompt when={formik.dirty} message={intl.formatMessage(messages.confirmDirty)} />

        <ConnectedDateRangeHeader planningParameters={plan.planningParameters} saveFromTo={handleSaveFromTo} />

        <ButtonsGroup>
          <SaveButton disabled={!formik.dirty} onClick={handleSaveData} />
          {hasPermissions &&
            (forecastEdit ? (
              <ComponentWithDirtyCheck
                actionHandler={props => {
                  if (isEmpty(formik.errors) || !props?.waitForSaveAll) {
                    editModeChange(false);
                  }
                }}
              >
                {({ onClickHandler }) => (
                  <IconButton icon="eye" id="plan-detail-edit" tooltip={messages.display} onClick={onClickHandler} />
                )}
              </ComponentWithDirtyCheck>
            ) : (
              <IconButton icon="edit" id="edit" tooltip={{ ...messages.edit }} onClick={() => editModeChange(true)} />
            ))}
          <IconButton
            icon="upload"
            id="forecast-detail-upload"
            disabled={false}
            tooltip={messages.upload}
            onClick={() => openUploadModal()}
          />
          <IconButton
            icon="boxes"
            id="plan-detail-interfaces"
            tooltip={messages.interfaces}
            onClick={handleGoToInterfaces}
          />
          <ComponentWithDirtyCheck actionHandler={handleGetSmartProdJobCodes}>
            {({ onClickHandler }) => (
              <IconButton
                id="getSmartProdJobCodes"
                label={messages.getSmartProdJobCodes}
                tooltip={{ ...messages.getSmartProdJobCodes }}
                onClick={onClickHandler}
              />
            )}
          </ComponentWithDirtyCheck>
          <UserViewToggleButton />
        </ButtonsGroup>
        <UploadModal
          entity="forecast"
          currentId={plan.planningParameters.id}
          intl={intl}
          user={user}
          isAuditor={hasPerm(PERMISSIONS.VIEW_FORECAST_SECTION)}
          onCloseHandler={handleReloadData}
        />
      </ButtonWrap>
    )
  );
};

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators(
      {
        openUploadModal: openUploadModalAction,
      },
      dispatch,
    ),
    dispatch,
  };
}

const withConnect = connect(undefined, mapDispatchToProps);

export default withSecurity()(withScrollWatch(70)(injectIntl(formikConnect(withRouter(withConnect(ToolBar))))));
