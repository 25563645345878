/**
 *
 * List of shift schedules
 *
 */
import { LOCAL_STORAGE_KEYS, PATHS, TABLE_DEFAULTS } from 'containers/App/constants';
import { PERMISSIONS } from 'utils/security';

import makeListPage from '../ListPage';
import messages from './messages';

const ShiftScheduleListPage = makeListPage({
  defaultTableConfig: TABLE_DEFAULTS.PLAN_DEFAULT,
  domainName: 'shiftScheduleListPage',
  domainUrl: 'schedules',
  exportToExcel: true,
  messages,
  pathToCreate: PATHS.makeShiftSchedule,
  pathToDetail: PATHS.shiftScheduleDetailId,
  pathToSelf: PATHS.shiftScheduleList,

  permissionAdd: PERMISSIONS.ADD_SCHEDULE,
  permissionDisable: PERMISSIONS.DISABLE_SCHEDULE,
  permissionDisableData: PERMISSIONS.DISABLE_SCHEDULE,
  permissionSaveConfig: PERMISSIONS.SAVE_TABLE_CONFIG,
  permissionViewDetail: PERMISSIONS.VIEW_SCHEDULES,
  tableConfigKey: LOCAL_STORAGE_KEYS.SHIFT_SCHEDULE_TABLE_CONFIG,

  parseData: item => ({
    created: item.audit.created,
    createdBy: item.audit.createdBy,
    deleted: item.deleted,
    editable: item.editable,
    facilities: item.facilities || [],
    id: item.id,
    name: item.name,
    updated: item.audit.updated,
    updatedBy: item.audit.updatedBy,
  }),
  interceptColDef: colDefs => colDefs.filter(def => def.field !== 'customers' && def.field !== 'managementUnits'),

  actionCellRendererParams: (props, config) => ({
    onDetail:
      props.hasPerm(config.permissionViewDetail) &&
      (id => {
        props.history.push(config.pathToDetail.replace(':id', id));
      }),
    onGraph:
      (id => {
        props.history.push(PATHS.shiftScheduleRunsPowerBi.replace(':id', id));
      }),
  }),
});

export default ShiftScheduleListPage;
