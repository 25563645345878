/*
 *
 * LoginPage reducer
 *
 */

import { fromJS } from 'immutable';
import { LOGOUT, STORE_TOKEN, STORE_USER } from './constants';
import { extractPermissions } from '../../utils/security';

const initialState = fromJS({
  user: null,
  token: null,
  permissions: false,
  loaded: false,
});

function loginPageReducer(state = initialState, action) {
  switch (action.type) {
    case STORE_TOKEN:
      return state.set('token', action.payload);
    case STORE_USER:
      return state
        .set('user', fromJS(action.payload))
        .set('loaded', true)
        .set(
          'permissions',
          extractPermissions({
            user: action.payload,
            permissions: false,
          }),
        );
    case LOGOUT:
      return state.set('token', null).set('user', null);
    default:
      return state;
  }
}

export default loginPageReducer;
