import React from 'react';
import { injectIntl } from 'react-intl';
import Select from 'react-select';

import messages from './messages';

const selectStyles = {
  control: provided => ({
    ...provided,
    height: '28px',
    minHeight: '28px',
  }),
  placeholder: provided => ({
    ...provided,
    marginLeft: '0px',
  }),
  valueContainer: provided => ({
    ...provided,
    margin: '0px 3px',
    padding: 0,
  }),
  indicatorsContainer: provided => ({
    ...provided,
    margin: '0px',
    padding: '0px',
    height: '26px',
  }),
  dropdownIndicator: provided => ({
    ...provided,
    margin: '0px',
    padding: '0px',
  }),
  indicatorSeparator: provided => ({
    ...provided,
    margin: '0px',
    padding: '0px',
  }),
};

export default injectIntl(props => (
  <Select styles={selectStyles} placeholder={props.intl.formatMessage(messages.placeholder)} {...props} />
));
