import {IDatasource} from "ag-grid-community";
import {withUrl} from "utils/api";
import EnchantedMap from "utils/enchantedMap";
import {fetchData} from "utils/reduxApi";
import {TransferCostEntry} from "../types";
import {convertArrayToMap} from "../utils";
import {mapTransferCostsFromAPI} from "./mappingUtils";
import {getDepartmentCostId, getFacilityCostId} from "./utils";

/**
 * Server-side model datasource for activity transfer costs table
 */
export class ActivityTransferCostsDatasource implements IDatasource {

  /**
   * Contains activity transfer costs which were edited by user
   */
  public activityTransferCostsMap: EnchantedMap<number, TransferCostEntry>;
  public idToDepartmentCost: Map<string, TransferCostEntry>;
  public idToFacilityCost: Map<string, TransferCostEntry>;
  public hideBlank: boolean;

  private readonly dispatch;
  private readonly url;
  private dataCache = new EnchantedMap<number, any>();

  constructor(scheduleId: number, dispatch: any) {
    this.url = `/schedules/${scheduleId}/transferCosts`;
    this.dispatch = dispatch;
  }

  /**
   * Update references from activity cost to facility/department costs (it changes after page save).
   */
  public updateParentCostsReferences() {
    this.dataCache.forEach((cost) => {
      this.setParentCostReferences(cost);
    });
  }

  public getRows(params: any): void {
    const {filterModel, startRow, endRow} = params.request;
    const sortModel = params.request.sortModel?.[0] || {};
    sortModel.pageSize = endRow - startRow;
    sortModel.page = Math.floor(startRow / sortModel.pageSize);

    fetchData(withUrl(this.url)
      .post({filterModel, hideBlank: this.hideBlank, sortModel}), this.dispatch)
      .then((r) => {
        if (r.isOk) {
          const fromAPI = mapTransferCostsFromAPI(r.data?.data || []);

          const data = (fromAPI).listValues().map((c) => this.mapTransferCost(c));
          this.dataCache.setAll(convertArrayToMap(data));
          params.successCallback(data, r.data.rowCount);
        } else {
          params.failCallback();
        }
      }).catch(() => {
      params.failCallback();
    });
  }

  private mapTransferCost(c: TransferCostEntry): any {
    // It may happen that a transfer cost has been already edited and then loaded again (via filter change or paging)
    // Merge changed values for such cases
    const alreadyEdited = this.activityTransferCostsMap.get(c.id);
    if (alreadyEdited) {
      c.valueFrom = alreadyEdited.valueFrom;
      c.valueTo = alreadyEdited.valueTo;
      c.comment = alreadyEdited.comment;
      c.disabledFrom = alreadyEdited.disabledFrom;
      c.disabledTo = alreadyEdited.disabledTo;
    }
    return this.setParentCostReferences({...c});
  }

  private setParentCostReferences(cost): any {
    cost.parentFacility = this.idToFacilityCost.get(getFacilityCostId(cost));
    cost.parentDepartment = this.idToDepartmentCost.get(getDepartmentCostId(cost));
    return cost;
  }

}
