export const SAVE_SHIFT_SCHEDULE = 'app/ShiftScheduleDetailPage/SAVE';
export const LOAD_SHIFT_SCHEDULE = 'app/ShiftScheduleDetailPage/LOAD';

export const LOAD_SCHEDULE_STAFF = 'app/ShiftScheduleDetailPage/LOAD_SCHEDULE_STAFF';
export const LOAD_SCHEDULE_STAFF_SETTINGS = 'app/ShiftScheduleDetailPage/LOAD_SCHEDULE_STAFF_SETTINGS';
export const LOAD_SCHEDULE_STAFF_UNPRODUCTIVE_ACTIVITIES =
  'app/ShiftScheduleDetailPage/LOAD_SCHEDULE_STAFF_UNPRODUCTIVE_ACTIVITIES';
export const LOAD_AVAILABLE_UNPRODUCTIVE_ACTIVITIES =
  'app/ShiftScheduleDetailPage/LOAD_AVAILABLE_UNPRODUCTIVE_ACTIVITIES';
export const LOAD_DEFAULT_SCHEDULE_PARAMETERS = 'app/ShiftScheduleDetailPage/LOAD_DEFAULT_SCHEDULE_PARAMETERS';
export const LOAD_SCHEDULE_STAFF_SYNC_STATE = 'app/ShiftScheduleDetailPage/LOAD_SCHEDULE_STAFF_SYNC_STATE';
export const SAVE_SCHEDULE_STAFF = 'app/ShiftScheduleDetailPage/SAVE_SCHEDULE_STAFF';
export const STORE_SCHEDULE_STAFF = 'app/ShiftScheduleDetailPage/STORE_SCHEDULE_STAFF';
export const STORE_SCHEDULE_STAFF_SETTINGS = 'app/ShiftScheduleDetailPage/STORE_SCHEDULE_STAFF_SETTINGS';
export const STORE_SCHEDULE_STAFF_UNPRODUCTIVE_ACTIVITIES =
  'app/ShiftScheduleDetailPage/STORE_SCHEDULE_STAFF_UNPRODUCTIVE_ACTIVITIES';
export const STORE_AVAILABLE_UNPRODUCTIVE_ACTIVITIES =
  'app/ShiftScheduleDetailPage/STORE_AVAILABLE_UNPRODUCTIVE_ACTIVITIES';
export const STORE_DEFAULT_SCHEDULE_PARAMETERS = 'app/ShiftScheduleDetailPage/STORE_DEFAULT_SCHEDULE_PARAMETERS';
export const STORE_SCHEDULE_STAFF_SYNC_STATE = 'app/ShiftScheduleDetailPage/STORE_SCHEDULE_STAFF_SYNC_STATE';
export const INVOKE_SCHEDULE_STAFF_SYNC = 'app/ShiftScheduleDetailPage/INVOKE_SCHEDULE_STAFF_SYNC';

export const LOAD_AVAILABLE_PLANS = 'app/ShiftScheduleDetailPage/LOAD_AVAILABLE_PLANS';
export const STORE_AVAILABLE_PLANS = 'app/ShiftScheduleDetailPage/STORE_AVAILABLE_PLANS';
export const LOAD_AVAILABLE_LABOUR_CATEGORIES = 'app/ShiftScheduleDetailPage/LOAD_AVAILABLE_LABOUR';
export const STORE_AVAILABLE_LABOUR_CATEGORIES = 'app/ShiftScheduleDetailPage/STORE_AVAILABLE_LABOUR';

export const STORE_SHIFT_SCHEDULE = 'app/ShiftScheduleDetailPage/STORE';
export const RESET_STORE_SHIFT_SCHEDULE = 'app/ShiftScheduleDetailPage/STORE';
export const STORE_SHIFT_SCHEDULE_GUI_STATE_ITEM = 'app/ShiftScheduleDetailPage/STORE_GUI_STATE_ITEM';
export const STORE_SHIFT_SCHEDULE_PERIOD_INDEX = 'app/ShiftScheduleDetailPage/STORE_PERIOD_INDEX';

export const UNSET_COST = '-';
