import React, { useMemo } from 'react';
import { MuiThemeProvider } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { Field, Formik } from 'formik';
import { DateTime, Duration } from 'luxon';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import styled, { withTheme } from 'styled-components';

import Button from 'components/Button';
import { materialTheme } from 'components/DatePicker';
import DialogDHL from 'components/Dialog';
import FormikDatePicker from 'components/FormikDatePicker';
import TimePicker from 'components/TimePicker';
import { formatDateToApiFormat } from 'utils/api';
import { timeToApiFormat } from 'utils/commonDetailSaga';
import { parseDate, parseTime } from 'utils/dateTime';

import { InlineLabel } from '../../components/FormikTextInput';
import messages from './messages';

const SchedulePeriodInput = styled(TextField)`
  width: 100px;
`;

const InputCol = styled.div`
  display: block;
  width: 200px;
  min-height: 44px;
  float: left;
  padding-bottom: 4px;
`;

const InputRow = styled.div`
  width: 400px;
  min-height: 44px;

  ${InlineLabel} {
    display: block;
    width: 200px;
    min-height: 44px;
    float: left;

    > span {
      min-height: 44px;
      line-height: 40px;
    }
  }
`;

const DialogButton = ({ children, onClick, ...props }) => (
  <Button color="primary" variant="contained" onClick={onClick} {...props}>
    {children}
  </Button>
);

export interface SchedulingDTO {
  scheduleDateTime: string | null;
  schedulePeriod: number | null;
}

type Props = {
  scheduling?: SchedulingDTO;
  closeHandler?: Function;
  changeRow: (changed: SchedulingDTO) => void;
  headerLabel: any;
  openSmartProdModalCallback?: Function;
  smartProdModal?: boolean;
};

const CustomScheduleDialog: React.FC<Props> = ({ scheduling, closeHandler, changeRow, headerLabel, openSmartProdModalCallback,smartProdModal,...rest }) => {
  // @ts-ignore
  const muiTheme = materialTheme(rest);
  const handleClearSchedule = formikBag => () => {
    formikBag.setSubmitting(false);
    formikBag.resetForm({
      scheduleDate: null,
      scheduleTime: null,
      schedulePeriod: '',
    });
  };

  const handleCancel = () => {
    if(openSmartProdModalCallback){
      openSmartProdModalCallback();
    }
    if(closeHandler){
    closeHandler();
    }
  };

  function isValid(values): boolean {
    const scheduleParams = new Set([
      values.scheduleDate != null,
      values.scheduleTime != null,
      values.schedulePeriod != null && values.schedulePeriod !== '',
    ]);
    const isValidLoc = scheduleParams.size === 1;
    if (!isValidLoc) {
      toast.error(<FormattedMessage {...messages.scheduleParamsValidationError} />);
    }
    return isValidLoc;
  }

  const submitForm = values => {
    if (!isValid(values)) {
      return;
    }

    let scheduleDateTime = null;
    if (values.scheduleDate) {
      const date = parseDate(values.scheduleDate);
      const time = parseTime(values.scheduleTime);
      scheduleDateTime = DateTime.fromObject({
        year: date.year,
        month: date.month,
        day: date.day,
        hour: time.hours,
        minute: time.minutes,
      }).toISO();
    }

    changeRow({
      ...values,
      scheduleDateTime: formatDateToApiFormat(scheduleDateTime),
    });
  };

  // 16:00 in CET timezone
  const defaultScheduleHour = 16 - new Date('2021-01-17T16:00').getTimezoneOffset() / 60;

  const initialValues = useMemo(() => {
    if (scheduling) {
      const scheduleDate = scheduling.scheduleDateTime ? DateTime.fromISO(scheduling.scheduleDateTime) : null;
      const scheduleTime = scheduleDate
        ? timeToApiFormat(Duration.fromObject({ hours: scheduleDate.hour, minutes: scheduleDate.minute, seconds: 0 }))
        : null;
      return {
        scheduleDate: scheduling.scheduleDateTime || new Date().toISOString(),
        scheduleTime: scheduleTime || '00:00:00.000',
        schedulePeriod: scheduling.schedulePeriod || '1',
      };
    }
  }, [scheduling]);

  return (
    <Formik
      initialValues={initialValues}
      isInitialValid
      enableReinitialize
      onSubmit={submitForm}
      render={formikBag => (
        <DialogDHL
          open={!!scheduling || smartProdModal}
          isTextContent={false}
          headerLabel={scheduling && headerLabel}
          CustomButtons={
            <React.Fragment key={1}>
              <DialogButton
                id="SmartVolumeScheduleButton-Save"
                onClick={() => {
                  formikBag.submitForm();
                }}
              >
                <FormattedMessage {...messages.scheduleDialogSave} />
              </DialogButton>
              <DialogButton id="SmartVolumeScheduleButton-ClearSchedule" onClick={handleClearSchedule(formikBag)}>
                <FormattedMessage {...messages.scheduleDialogClearSchedule} />
              </DialogButton>
              <DialogButton id="SmartVolumeScheduleButton-Cancel" onClick={handleCancel}>
                <FormattedMessage {...messages.scheduleDialogCancelLabel} />
              </DialogButton>
            </React.Fragment>
          }
        >
          <MuiThemeProvider theme={muiTheme}>
            <InputRow>
              <InlineLabel {...messages.smartVolumeTableCellScheduleStartDate} />
              <InputCol>
                <Field
                  name="scheduleDate"
                  render={({ field, form }) => (
                    <FormikDatePicker
                      fullWidth
                      form={form}
                      timePicker={false}
                      field={field}
                      minDate={DateTime.local()}
                      defaultValue = {null}
                      minDateMessage = {null}
                    />
                  )}
                />
              </InputCol>
            </InputRow>
            <InputRow>
              <InlineLabel {...messages.smartVolumeTableCellScheduleTime} />
              <InputCol>
                <Field
                  name="scheduleTime"
                  render={({ field, form }) => {
                    const fieldValue = typeof field.value === 'string' ? Duration.fromISOTime(field.value) : field.value;

                    return (
                      <TimePicker
                        fullWidth
                        name="scheduleTime"
                        ampm={false}
                        width={200}
                        value={fieldValue}
                        onChange={value => {
                          form.setFieldValue(field.name, value);
                        }}
                      />
                    );
                  }}
                />
              </InputCol>
            </InputRow>
            <InputRow>
              <InlineLabel {...messages.smartVolumeTableCellSchedulePeriodDays} />
              <InputCol>
                <Field
                  name="schedulePeriod"
                  render={({ field, form }) => (
                    <SchedulePeriodInput
                      fullWidth
                      InputProps={{ inputProps: { min: 0 } }}
                      name="schedulePeriod"
                      type="number"
                      value={field.value}
                      onChange={({ target: { value } }) => form.setFieldValue(field.name, value)}
                    />
                  )}
                />
              </InputCol>
            </InputRow>
          </MuiThemeProvider>
        </DialogDHL>
      )}
    />
  );
};

export default withTheme(CustomScheduleDialog);
