import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IconButton from 'components/IconButton';
import DeleteCellRenderer from 'components/DeleteCellRenderer';
import messages from './messages';

export function DeleteCell(props) {
  if (props.data && props.data.status === 'NOT_SENT') {
    return <DeleteCellRenderer {...props} />;
  }
  return <span />;
}

export function OverviewCell(props) {
  const { downloadKronos } = props;

  function onClick(event) {
    event.preventDefault();
    // strange bug, I'm clicking button from enter on filter
    if (event.target.nodeName === 'svg' || event.target.nodeName === 'path') {
      downloadKronos(props.data);
    }
  }

  return (
    <button
      name="csv_download"
      id="csv_download"
      value="csv_download"
      className="DownloadKronosCSVButton"
      onClick={onClick}
    >
      <FontAwesomeIcon icon="file-csv" color="green" size="lg" />
    </button>
  );
}

export function SendToKronosCell(props) {
  const { sendToKronos, data } = props;

  return (
    <div style={{ width: '100%' }}>
      <IconButton
        icon="paper-plane"
        name="send_to_kronos"
        message={(data.status === 'NOT_SENT' && messages.send) || messages.resend}
        onClick={() => sendToKronos(data)}
        className="SendToKronosButton"
      />
    </div>
  );
}

export function StatusCell(props) {
  const { data, value, valueFormatted, showErrorMessage } = props;

  function onClick(e) {
    e.preventDefault();
    showErrorMessage(data);
  }

  return (
    <div>
      {valueFormatted}
      {value === 'FAILED' && (
        <a href="#" onClick={onClick} className="KronosErrorDetals">
          <FontAwesomeIcon icon="info-circle" color="red" size="lg" />
        </a>
      )}
    </div>
  );
}
