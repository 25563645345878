/**
 *
 * ToggleSection
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const Wrapper = styled.div`
  margin: 10px;
  padding: 5px;
  background-color: ${props => (props.subSection ? props.theme.color.greyDlgBgSub : props.theme.color.greyDlgBg)};
  box-shadow: ${props => props.theme.shadow.pageBox};
`;

export const Title = styled.div`
  font-size: ${props => props.theme.fontSize.title};
  font-weight: 700;
  margin-bottom: 8px;
  display: flex;
  cursor: pointer;
  > * {
    margin: 0px 5px;
  }
`;

export const Error = styled.p`
color: red;
`
class ToggleSection extends React.Component {
  constructor(props) {
    super(props);
    const stateControl = typeof props.expanded === 'undefined' || typeof props.onToggle === 'undefined';
    this.state = {
      expanded: props.expanded === true,
      stateControl,
    };
  }

  componentDidUpdate(prevProps) {
    const { expanded } = this.props;
    if (expanded !== prevProps.expanded) {
      this.setState({ expanded });
    }
  }

  isExpanded = (state, props) => (state.stateControl ? state.expanded : props.expanded);

  render() {
    const expanded = this.isExpanded(this.state, this.props);
    const { subSection, subtitle = '' } = this.props;
    return (
      <Wrapper subSection={subSection} className={this.props.className}>
        <Title onClick={this.toggleClick} className="title">
          <FontAwesomeIcon icon={expanded ? 'angle-up' : 'angle-down'} />
          <FormattedMessage {...this.props.message} />
          {subtitle}
          {this.props.subtitleToShow && <Error><FormattedMessage {...this.props.subtitleToShow} /></Error>}
        </Title>
        <div>{expanded && this.props.children}</div>
      </Wrapper>
    );
  }

  toggleClick = () => {
    if (this.state.stateControl) {
      this.setState({ expanded: !this.state.expanded });
    } else {
      this.props.onToggle(this.props.expanded);
    }
  };
}

ToggleSection.propTypes = {
  message: PropTypes.object,
  children: PropTypes.node,
  expanded: PropTypes.bool,
  subSection: PropTypes.bool,
  onToggle: PropTypes.func,
  className: PropTypes.string,
  subtitle: PropTypes.any,
};

export default ToggleSection;
