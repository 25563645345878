/*
 * PlannedVolumeTable Messages
 *
 * This contains all the text for the PlannedVolumeTable component.
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  category: {
    id: 'app.components.PlannedVolumeTable.category',
    defaultMessage: 'Volume Category',
  },
  categoryVariable: {
    id: 'app.components.PlannedVolumeTable.categoryVariable',
    defaultMessage: 'Category Variable',
  },
  categorySection: {
    id: 'app.components.PlannedVolumeTable.categorySection',
    defaultMessage: 'Volume Category',
  },
  categoryVariableSection: {
    id: 'app.components.PlannedVolumeTable.categoryVariableSection',
    defaultMessage: 'Volume Category Variable',
  },
  uom: {
    id: 'app.components.PlannedVolumeTable.uom',
    defaultMessage: 'Unit of Measure',
  },
  week: {
    id: 'app.components.PlannedVolumeTable.week',
    defaultMessage: 'Week',
  },
  day: {
    id: 'app.components.PlannedVolumeTable.day',
    defaultMessage: 'Day',
  },
  saveData: {
    id: 'app.components.PlannedVolumeTable.saveData',
    defaultMessage: 'Save',
  },
  action: {
    id: 'app.components.PlannedVolumeTable.action',
    defaultMessage: 'Action',
  },
  dialogDeleteText: {
    id: 'app.components.PlannedVolumeTable.dialogDeleteText',
    defaultMessage: 'Are you sure? You are deleting Volume Category {catName} with Unit of Measure {uomName}',
  },
  isVariable: {
    id: 'app.components.PlannedVolumeTable.isVariable',
    defaultMessage: 'Is variable',
  },
  variable: {
    id: 'app.components.PlannedVolumeTable.variable',
    defaultMessage: 'Variable',
  },
  regular: {
    id: 'app.components.PlannedVolumeTable.regular',
    defaultMessage: 'Regular',
  },
  value: {
    id: 'app.components.PlannedVolumeTable.value',
    defaultMessage: 'Value',
  },
  formula: {
    id: 'app.components.PlannedVolumeTable.formula',
    defaultMessage: 'Formula',
  },
  defaultFormula: {
    id: 'app.components.PlannedVolumeTable.defaultFormula',
    defaultMessage: 'Default Formula',
  },
  reverseFormula: {
    id: 'app.components.PlannedVolumeTable.reverseFormula',
    defaultMessage: 'Reverse Formula',
  },
  warningMessage:{
    id: 'app.components.PlannedVolumeTable.warningMessage',
    defaultMessage: 'Warning: {warning}.',
  },
  activityLabel: {
    id: 'app.components.PlannedVolumeTable.activityLabel',
    defaultMessage: 'Activity: {name}',
  },
  unknownVariables: {
    id: 'app.components.PlannedVolumeTable.unknownVariables',
    defaultMessage: 'Unknown variables {variables}',
  },
  selfVariables: {
    id: 'app.components.PlannedVolumeTable.selfVariables',
    defaultMessage: 'Formula point to the self variable {variables}',
  },
});
