import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import { FormattedMessage, injectIntl } from 'react-intl';
import styled from 'styled-components';
import uuid from 'uuid/v4';
import { nameWithRCLabel, toNumber, percentageCellFormatter } from 'utils/utils';
import NameWithToolTip from 'components/NameWithToolTip';
import cloneDeep from 'lodash/cloneDeep';
import AutocompleteSelect from '../AutocompleteSelect';
import IconButton from '../IconButton';
import messages from './messages';
import DeleteCellRenderer from '../DeleteCellRenderer';

import { getMhesForPeriodAndActivity, getShiftsForPeriodAndActivity, getRolesForPeriodAndActivity } from '../../utils/formikSelectors';
import { TABLE_DEFAULTS } from '../../containers/App/constants';
import TableControlled from '../../containers/TableControlled';

const Wrap = styled.div`
  display: flex;
  justify-content: flex-end;
  background-color: ${props => props.theme.color.grey4};
`;

const WrapInner = styled.div`
  width: 700px;
  max-width: 100%;
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  ${IconButton} {
    max-height: 28px;
  }
`;
const Menu = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 20px;
`;

const TableWrap = styled.div`
  display: flex;
  width: 100%;
  height: ${props => props.height}px;
  min-width: 320px;
  align-items: center;
  > * {
    height: 100%;
  }
`;

const AutocompleteStyled = styled(AutocompleteSelect)`
  margin: 0 20px;
  min-width: 440px;
`;

function createMhesColumnDef(props) {
  const { editable } = props.data;
  const columnDefs = [
    {
      headerName: props.intl.formatMessage(messages.addMHEName),
      field: 'name',
      colId: 'name',
      cellRendererFramework: NameWithToolTip(),
      width: 160,
    },
    {
      headerName: props.intl.formatMessage(messages.addMHETimepercent),
      field: 'transitionRate',
      colId: 'transitionRate',
      type: 'numericColumn',
      width: 80,
      valueFormatter: percentageCellFormatter,
      editable,
    },
    {
      headerName: props.intl.formatMessage(messages.addMHEType),
      field: 'mheTypeName',
      colId: 'mheTypeName',
      width: 160,
    },
  ];
  if (editable) {
    columnDefs.unshift({
      headerName: props.intl.formatMessage(messages.action),
      field: 'delete',
      colId: 'delete',
      cellRendererFramework: DeleteCellRenderer,
      width: 80,
      cellRendererParams: {
        onDelete: (row, params) => {
          props.deleteRowWithIndex(params.data.index);
        },
      },
      sortable: false,
      suppressMenu: true,
      pinned: true,
    });
  }
  return columnDefs;
}
function createShiftsColumnDef(props) {
  const { editable } = props.data;
  const columnDefs = [
    {
      headerName: props.intl.formatMessage(messages.addShiftName),
      field: 'wzpName',
      colId: 'wzpName',
      cellRendererFramework: NameWithToolTip(),
      width: 160,
    },
    {
      headerName: props.intl.formatMessage(messages.uphDefault),
      field: 'productivityRate',
      colId: 'productivityRate',
      type: 'numericColumn',
      width: 160,
      editable,
    },
  ];
  if (editable) {
    columnDefs.unshift({
      headerName: props.intl.formatMessage(messages.action),
      field: 'delete',
      colId: 'delete',
      cellRendererFramework: DeleteCellRenderer,
      width: 80,
      cellRendererParams: {
        onDelete: (row, params) => {
          props.deleteRowWithIndexShift(params.data.index);
        },
      },
      sortable: false,
      suppressMenu: true,
      pinned: true,
    });
  }
  return columnDefs;
}

function createRolesColumnDef(props) {
  const { editable } = props.data;
  const columnDefs = [
    {
      headerName: props.intl.formatMessage(messages.addRole),
      field: 'name',
      colId: 'name',
      cellRendererFramework: NameWithToolTip(),
      width: 150,
    },
    {
      headerName: props.intl.formatMessage(messages.timePercentage),
      field: 'timePercentage',
      colId: 'timePercentage',
      type: 'numericColumn',
      valueFormatter: percentageCellFormatter,
      width: 250,
      editable,
    },
  ];
  if (editable) {
    columnDefs.unshift({
      headerName: props.intl.formatMessage(messages.action),
      field: 'delete',
      colId: 'delete',
      cellRendererFramework: DeleteCellRenderer,
      width: 100,
      cellRendererParams: {
        onDelete: (row, params) => {
          props.deleteRowWithIndexRole(params.data.index);
        },
      },
      sortable: false,
      suppressMenu: true,
      pinned: true,
    });
  }
  return columnDefs;
}

class FullWidthCellRenderer extends Component {
  constructor(props) {
    super(props);

    if (props.reactContainer) {
      props.reactContainer.style.display = 'block'; // eslint-disable-line
      props.reactContainer.style.height = '100%'; // eslint-disable-line
    }

    this.state = { selectedMHE: undefined, selectedShift: undefined, selectedRole: undefined };
  }

  componentDidMount() {
    // to allow for inner scrolling
    ReactDOM.findDOMNode(this).addEventListener(
      // eslint-disable-line
      'mousewheel',
      event => {
        event.stopPropagation();
      },
      false,
    );
  }

  render() {
    const { isIndirect, periodIndex, buttonDetails } = this.props;
    const buttonType = this.props.data.mhes ? 'MHE' : this.props.data.roles ? 'ROLE' : 'SHIFT';
    const rowData = (
      cloneDeep(getMhesForPeriodAndActivity(this.props, periodIndex, this.props.data.activityIndex, isIndirect)) || []
    ).map((r, index) => ({ ...r, index }));
    rowData.sort((a, b) => a.name.localeCompare(b.name));

    const rowDataShift = (
      cloneDeep(getShiftsForPeriodAndActivity(this.props, periodIndex, this.props.data.activityIndex, isIndirect)) || []
    ).map((r, index) => ({ ...r, index }));
    rowData.sort((a, b) => a.name.localeCompare(b.name));
    const usedWzpIds = rowDataShift && rowDataShift.map(row => row.wzpId);
    const shiftData = this.props.formik.values.planningParameters.periods[periodIndex].workZonePeriods || [];
    const filteredShiftData = shiftData && shiftData.filter((sd) => !usedWzpIds.includes(sd.id))
    const rowDataRole = (
      cloneDeep(getRolesForPeriodAndActivity(this.props, periodIndex, this.props.data.activityIndex, isIndirect)) || []
    ).map((r, index) => ({ ...r, index }));
    rowData.sort((a, b) => a.name.localeCompare(b.name));
    const roles = (this.props.roles && this.props.roles.results && this.props.roles.results.map((role) => (
      { id: role.id, label: role.name, value: role.name, name: role.name }
    ))) || [];
    const usedRoleIds = rowDataRole && rowDataRole.map(row => row.roleId);
    const filteredRoleData = roles && roles.filter((r)=>!usedRoleIds.includes(r.id));
    // when assigned directly agGrid changes formik data during edit even if
    // doc says it never does: https://www.ag-grid.com/javascript-grid-client-side-model/#state-1-row-data
    // this also changes initial values so data is not found dirty and Save button stays gray (defect 342254)
    return (
      <Wrap className={this.props.className}>
        <WrapInner id={uuid()}>
          {this.props.data.editable && buttonType === 'MHE' && (
            <Menu>
              <FormattedMessage {...messages.addMHESelect} />
              <AutocompleteStyled
                maxMenuHeight={120}
                entity="mhes"
                setValue={value => {
                  this.setState({ selectedMHE: value });
                }}
                disabled={!this.props.data.editable}
                token={this.props.token}
                getOptionLabel={nameWithRCLabel}
                urlAppendixCallback={() =>
                  `&planningParametersId=${this.props.formik.initialValues.planningParameters.id}`
                }
              />
              <IconButton
                icon="plus-circle"
                message={messages.addMHEAddLine}
                onClick={() => {
                  const currentData = getMhesForPeriodAndActivity(
                    this.props,
                    periodIndex,
                    this.props.data.activityIndex,
                    isIndirect,
                  );
                  const newData = [...currentData, this.createNewMhe()];
                  this.props.formik.setFieldValue(this.getFormikDataPath(), newData);
                  this.props.api.resetRowHeights();
                }}
                disabled={
                  this.state.selectedMHE === undefined ||
                  this.state.selectedMHE === null ||
                  this.state.selectedMHE.length === 0
                }
              />
            </Menu>
          )}
          {this.props.data.editable && buttonType === 'SHIFT' && (
            <Menu>
              <FormattedMessage {...messages.addShiftSelect} />
              <AutocompleteStyled
                maxMenuHeight={120}
                entity="mhes"
                setValue={value => {
                  this.setState({ selectedShift: value });
                }}
                preloadedData={filteredShiftData}
                hideSelectedOptions={true}
                disabled={!this.props.data.editable}
                token={this.props.token}
                getOptionLabel={option => option.name}
              // urlAppendixCallback={() =>
              //   `&planningParametersId=${this.props.formik.initialValues.planningParameters.id}`
              // }
              />
              <IconButton
                icon="plus-circle"
                message={messages.addMHEAddLine}
                onClick={() => {
                  const currentData = getShiftsForPeriodAndActivity(
                    this.props,
                    periodIndex,
                    this.props.data.activityIndex,
                    isIndirect,
                  );
                  const newData = [...currentData, this.createNewShift()];
                  this.props.formik.setFieldValue(this.getFormikDataPathShift(), newData);
                  this.props.api.resetRowHeights();
                }}
                disabled={
                  this.state.selectedShift === undefined ||
                  this.state.selectedShift === null ||
                  this.state.selectedShift.length === 0
                }
              />
            </Menu>
          )}
          {this.props.data.editable && buttonType === 'ROLE' && (
            <Menu>
              <FormattedMessage {...messages.addShiftRole} />
              <AutocompleteStyled
                maxMenuHeight={120}
                entity="roles"
                setValue={value => {
                  this.setState({ selectedRole: value });
                }}
                preloadedData={filteredRoleData}
                hideSelectedOptions={true}
                disabled={!this.props.data.editable}
                token={this.props.token}
                getOptionLabel={option => option.name}
              />
              <IconButton
                icon="plus-circle"
                message={messages.addRoleAddLine}
                onClick={() => {
                  const currentData = getRolesForPeriodAndActivity(
                    this.props,
                    periodIndex,
                    this.props.data.activityIndex,
                    isIndirect,
                  );
                  const newData = [...currentData, this.createNewRole()];
                  this.props.formik.setFieldValue(this.getFormikDataPathRole(), newData);
                  this.props.api.resetRowHeights();
                }}
                disabled={
                  this.state.selectedRole === undefined ||
                  this.state.selectedRole === null ||
                  this.state.selectedRole.length === 0
                }
              />
            </Menu>
          )}
          {buttonType === 'MHE' &&
            <TableWrap height={((rowData.length || 1) + 1) * 35 + 50}>
              <TableControlled
                key={this.props.data.editable}
                defaultConfig={
                  this.props.data.editable ? TABLE_DEFAULTS.mheTableEditConfig : TABLE_DEFAULTS.mheTableConfig
                }
                messages={messages}
                name={this.props.data.editable ? 'mheEdit' : +'mhe'}
                columnDefs={createMhesColumnDef({ ...this.props, deleteRowWithIndex: this.deleteRowWithIndex }, messages)}
                rowData={rowData}
                rowHeight={35}
                pagination={false}
                onCellValueChanged={params => {
                  const convertedParams = params.newValue ? { ...params, newValue: toNumber(params.newValue) } : params;
                  // updateData(convertedParams);
                  this.onCellValueChanged(convertedParams)
                }}
              />
            </TableWrap>
          }
          {buttonType === 'SHIFT' &&
            <TableWrap height={((rowDataShift.length || 1) + 1) * 35 + 50}>
              <TableControlled
                key={this.props.data.editable}
                defaultConfig={
                  this.props.data.editable ? TABLE_DEFAULTS.perShiftEditConifg : TABLE_DEFAULTS.perShiftConfig
                }
                messages={messages}
                name={this.props.data.editable ? 'shiftEdit' : 'shift'}
                columnDefs={createShiftsColumnDef({ ...this.props, deleteRowWithIndexShift: this.deleteRowWithIndexShift }, messages)}
                rowData={rowDataShift}
                rowHeight={35}
                pagination={false}
                onCellValueChanged={params => {
                  const convertedParams = params.newValue ? { ...params, newValue: toNumber(params.newValue) } : params;
                  // updateData(convertedParams);
                  this.onCellValueChangedShift(convertedParams);
                }}
              />
            </TableWrap>
          }
          {buttonType === 'ROLE' &&
            <TableWrap height={((rowDataRole.length || 1) + 1) * 35 + 50}>
              <TableControlled
                key={this.props.data.editable}
                defaultConfig={
                  this.props.data.editable ? TABLE_DEFAULTS.roleEditConifg : TABLE_DEFAULTS.roleConfig
                }
                messages={messages}
                name={this.props.data.editable ? 'roleEdit' : 'role'}
                columnDefs={createRolesColumnDef({ ...this.props, deleteRowWithIndexRole: this.deleteRowWithIndexRole }, messages)}
                rowData={rowDataRole}
                rowHeight={35}
                pagination={false}
                onCellValueChanged={params => {
                  const convertedParams = params.newValue ? { ...params, newValue: toNumber(params.newValue) } : params;
                  // updateData(convertedParams);
                  this.onCellValueChangedRole(convertedParams);
                }}
              />
            </TableWrap>
          }
        </WrapInner>
      </Wrap>
    );
  }

  getFormikDataPath = () =>
    `planningParameters.periods.${this.props.periodIndex}.apCalculated.${this.props.isIndirect ? 'indirect' : 'direct'
    }.${this.props.data.activityIndex}.mhes`;

  getFormikDataPathShift = () =>
    `planningParameters.periods.${this.props.periodIndex}.apCalculated.${this.props.isIndirect ? 'indirect' : 'direct'
    }.${this.props.data.activityIndex}.perShift`;

  getFormikDataPathRole = () =>
    `planningParameters.periods.${this.props.periodIndex}.apCalculated.${this.props.isIndirect ? 'indirect' : 'direct'
    }.${this.props.data.activityIndex}.roles`;

  onCellValueChanged(params) {
    if (params.newValue !== params.oldValue) {
      const currentData = getMhesForPeriodAndActivity(
        this.props,
        this.props.periodIndex,
        this.props.data.activityIndex,
        this.props.isIndirect,
      );
      const newData = currentData.map((value, index) =>
        index === params.data.index ? { ...value, [params.colDef.field]: params.newValue } : value,
      );
      this.props.formik.setFieldValue(this.getFormikDataPath(), newData);
    }
    return true;
  }

  onCellValueChangedShift(params) {
    if (params.newValue !== params.oldValue) {
      const currentData = getShiftsForPeriodAndActivity(
        this.props,
        this.props.periodIndex,
        this.props.data.activityIndex,
        this.props.isIndirect,
      );
      const newData = currentData.map((value, index) =>
        index === params.data.index ? { ...value, [params.colDef.field]: params.newValue } : value,
      );
      this.props.formik.setFieldValue(this.getFormikDataPathShift(), newData);
    }
    return true;
  }

  onCellValueChangedRole(params) {
    if (params.newValue !== params.oldValue) {
      const currentData = getRolesForPeriodAndActivity(
        this.props,
        this.props.periodIndex,
        this.props.data.activityIndex,
        this.props.isIndirect,
      );
      const newData = currentData.map((value, index) =>
        index === params.data.index ? { ...value, [params.colDef.field]: params.newValue } : value,
      );
      this.props.formik.setFieldValue(this.getFormikDataPathRole(), newData);
    }
    return true;
  }

  deleteRowWithIndex = deleteIndex => {
    const newData = [
      ...getMhesForPeriodAndActivity(
        this.props,
        this.props.periodIndex,
        this.props.data.activityIndex,
        this.props.isIndirect,
      ),
    ];
    newData.splice(deleteIndex, 1);
    this.props.formik.setFieldValue(this.getFormikDataPath(), newData);
  };

  deleteRowWithIndexShift = deleteIndex => {
    const newData = [
      ...getShiftsForPeriodAndActivity(
        this.props,
        this.props.periodIndex,
        this.props.data.activityIndex,
        this.props.isIndirect,
      ),
    ];
    newData.splice(deleteIndex, 1);
    this.props.formik.setFieldValue(this.getFormikDataPathShift(), newData);
  };

  deleteRowWithIndexRole = deleteIndex => {
    const newData = [
      ...getRolesForPeriodAndActivity(
        this.props,
        this.props.periodIndex,
        this.props.data.activityIndex,
        this.props.isIndirect,
      ),
    ];
    newData.splice(deleteIndex, 1);
    this.props.formik.setFieldValue(this.getFormikDataPathRole(), newData);
  }

  createNewMhe() {
    const mhe = this.state.selectedMHE;
    return {
      mheId: mhe.id,
      mheTypeId: mhe.mheTypeId,
      mheTypeName: mhe.mheTypeName,
      regionalConfigurationName: mhe.regionalConfigurationName,
      name: mhe.name,
      transitionRate: 100,
    };
  }

  createNewShift() {
    const shift = this.state.selectedShift;
    const propsDataId = this.props.data.id;
    const activityParameters = this.props.formik.values.planningParameters.periods[this.props.periodIndex];
    const selectedData = activityParameters.activityParameters.filter((ap) => ap.id == propsDataId)
    return {
      wzpId: shift.id,
      productivityRate: selectedData && selectedData[0] && selectedData[0].hourlyProductivityRate,
      wzpName: shift.name
    };
  }

  createNewRole() {
    const role = this.state.selectedRole;
    return {
      roleId: role.id,
      timePercentage: 100,
      name: role.name
    }
  }
}

FullWidthCellRenderer.propTypes = {
  data: PropTypes.object,
  reactContainer: PropTypes.object,
  className: PropTypes.string,
  token: PropTypes.string,
  formik: PropTypes.object,
  periodIndex: PropTypes.number,
  isIndirect: PropTypes.bool,
  api: PropTypes.object, // parent grid api
};

export default styled(injectIntl(FullWidthCellRenderer))``;
