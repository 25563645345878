// @flow

/**
 *
 * MasterPlanResultToolBar
 *
 */

import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { DateTime } from 'luxon';

import DatePicker from 'components/DatePicker';
import Lb from 'components/Label';
import DialogDHL from 'components/Dialog';
import { getToken } from 'containers/App/selectors';
import IconButton, { SaveButton } from 'components/IconButton';
import Button from 'components/Button';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import ViewModePopover from 'components/ViewModePopover';
import { withScrollWatch } from 'utils/scroll';
import { fetchData } from 'utils/reduxApi';
import { withUrl } from 'utils/api';
import { ROLES } from 'utils/security';

import { PATHS } from '../../containers/App/constants';
import withSecurity, { PERMISSIONS } from '../../utils/security';
import { Title } from '../PlanningAreaDetailToolbar';
import UserViewToggleButton from '../ToggleButton/UserViewToggleButton';
import messages from './messages';
import { toast } from 'react-toastify';

const Label = styled(Lb)`
  margin-right: 10px;
  margin-top: 20px;
  height: ${props => props.theme.grid.rowHeight};
  display: flex;
  align-items: center;
`;

const ValidityButton = styled(Button)`
  padding-top: 0px !important
`
export const ButtonWrap = styled.div`
  margin: 10px 10px 5px;
  padding: 10px;
  background-color: ${props => props.theme.color.greyDlgBg};
  box-shadow: ${props => props.theme.shadow.pageBox};
  display: flex;
  justify-content: space-between;
  ${SaveButton} {
    display: inline-flex;
    :hover {
      box-shadow: none;
    }
  }
  ${props =>
        props.fixed &&
        css`
      top: 0px;
      z-index: ${props.theme.zIndex.overlayToolbar};
      left: 70px;
      margin: 0px;
      padding: 10px;
    `};
  :first-child {
    position: ${props => (props.fixed ? 'fixed' : 'absolute')};
    width: ${props => (props.fixed ? 'calc(100% - 75px)' : 'calc(100% - 30px)')};
  }
  :nth-child(2) {
    margin-top: 80px;
  }
`;

export const ButtonsGroup = styled.div`
  display: flex;
  position: absolute;
  right: 10px !important;
  > * {
    margin-left: 10px;
  }
`;

type PropsTypes = {
    history: Object,
    fixed: boolean,
    id: number,
    editable: boolean,
    deleted: boolean,
    ppId: number,
    planEdit: boolean,
    dirty: boolean,
    title: string,
    saveData: Function,
    editModeChange: Function,
    openUploadModal: Function,
    makeDirtyHandler: Function,
    makeReportDirtyHandler: Function,
    hasPerm: Function,
    editing: boolean,
};

const MasterPlanResultToolBar = React.memo(function MasterPlanResultToolBar(props: PropsTypes) {
    const {
        makeDirtyHandler,
        makeReportDirtyHandler,
        dirty = false,
        title,
        fixed,
        id,
        planEdit = false,
        saveData,
        editable = false,
        deleted = false,
        ppId,
        masterPlan,
        user,
        minvalidFrom,
        maxvalidTo
    } = props;
    const formattedDate = (val) => {
        const newValue = new Date(val);
        const year = newValue.toLocaleString("default", { year: "numeric" });
        const month = newValue.toLocaleString("default", { month: "2-digit" });
        const day = newValue.toLocaleString("default", { day: "2-digit" });
        const formattedDate = year + "-" + month + "-" + day;
        return formattedDate;
    }

    const token = useSelector(getToken);
    const dispatch = useDispatch();
    const isAdmin = user.user.roles.find(r => r.role === ROLES.ADMIN.name);
    const isMPAdmin = user.user.roles.find(r => r.role === ROLES.SMARTPLAN_MASTER_PLAN_ADMIN_ROLE.name);
    let minStartDate = formattedDate(DateTime.local());
    let fminvalidFrom = formattedDate(minvalidFrom);
    let fmaxvalidTo = formattedDate(maxvalidTo);
    const reportingFromFlag = (DateTime.fromFormat(minStartDate, 'yyyy-MM-dd').diff(DateTime.fromISO(fminvalidFrom), 'days').days);
    const reportingToFlag =  (DateTime.fromFormat(minStartDate, 'yyyy-MM-dd').diff(DateTime.fromISO(fmaxvalidTo), 'days').days);
    let reportingFlag = true;
    if(reportingFromFlag > 0 && reportingToFlag > 0) reportingFlag = true;
    if(reportingFromFlag === 0 && reportingToFlag === 0) reportingFlag = true;
    if(reportingFromFlag <= 0  && reportingFromFlag >= -55 ) reportingFlag = false;
    if(reportingToFlag <= 0  && reportingToFlag >= -55 ) reportingFlag = false;
    if(reportingFromFlag >= 0 && reportingToFlag <=0) reportingFlag = false;
    const [open, setOpen] = useState(false);
    const [startdate, setStartDate] = React.useState(minStartDate);
    const [enddate, setEndDate] = React.useState();
    const [formatstartdate, setformatstartdate] = React.useState(minStartDate);
    const [formatenddate, setformatenddate] = React.useState();

    React.useEffect(()=>{
        open && setStartDate(minStartDate);
    },[open])

    const onDateChange = (dateType, value) => {
        if (dateType === 'startDay' && value.startOf('day')) {
            setStartDate(value);
        }
        if (dateType === 'endDay' && value.startOf('day')) {
            setEndDate(value);
        }
    };

    const doRefreshNow = async () => {
        const response = await fetchData(withUrl(`/masterPlan/${masterPlan.id}/report?startDate=${formatstartdate}&endDate=${formatenddate ? formatenddate : ''}`).post().andToken(token).asRawResponse(), dispatch);
        if (response.isOk) {
            toast('Refresh for Reporting successful');
        }
    };

    const onAcceptEndDate = value => {
        onDateChange('endDay', value);
        setformatenddate(formattedDate(value))

    };

    const onAcceptStartDate = value => {
        onDateChange('startDay', value);
        setformatstartdate(formattedDate(value))
    };

    return (
        <ButtonWrap fixed={fixed}>
            {props.hasPerm(PERMISSIONS.VIEW_PLAN_DETAILS) && (
                <IconButton
                    icon="arrow-circle-left"
                    id="back"
                    tooltip={{ ...messages.back }}
                    onClick={() => props.history.push(`${PATHS.masterPlanDetail.replace(':id', id)}`)}
                />
            )}
            {fixed && <Title>{title}</Title>}

            <ButtonsGroup>
                { (isAdmin || isMPAdmin) &&
                <Tooltip title="Refresh for Reporting">
                    <ValidityButton disabled={reportingFlag} onClick={makeReportDirtyHandler(() => setOpen(true))}>
                        <FormattedMessage {...messages.refreshmsdb} />
                    </ValidityButton>
                </Tooltip>
                }
                <SaveButton disabled={!dirty} onClick={saveData} />
                {
                    (props.editing ? (
                        <IconButton
                            icon="eye"
                            id="edit"
                            tooltip={{ ...messages.display }}
                            onClick={makeDirtyHandler(() => props.editModeChange(false))}
                        />
                    ) : (
                        <IconButton
                            icon="edit"
                            id="edit"
                            tooltip={{ ...messages.edit }}
                            onClick={() => props.editModeChange(true)}
                            disabled={deleted === true}
                        />
                    ))}
                {/* {props.hasPerm(PERMISSIONS.EDIT_RESULT) && (
                    <IconButton icon="upload" id="upload" tooltip={messages.upload} onClick={props.openUploadModal} />
                )} */}
                {/* {props.hasPerm(PERMISSIONS.VIEW_RESULT) && (
                    <IconButton
                        id="graphs"
                        icon="chart-line"
                        tooltip={{ ...messages.graphs }}
                        onClick={() =>
                            props.history.push({
                                pathname: `${PATHS.planGraphs.replace(':id', id)}`,
                                search: props.history.location.search,
                            })
                        }
                    />
                )} */}
                {/* {props.hasPerm(PERMISSIONS.HISTORY_PLAN) && (
                    <IconButton
                        icon="history"
                        id="history"
                        tooltip={{ ...messages.history }}
                        onClick={makeDirtyHandler(() => props.history.push(PATHS.historyPlan.replace(':id', id)))}
                    />
                )} */}
                {/* {props.hasPerm(PERMISSIONS.HISTORY_PLAN) && (
                    <IconButton
                        icon="calculator"
                        id="calculationStats"
                        tooltip={{ ...messages.calculationStats }}
                        onClick={() =>
                            props.history.push({
                                pathname: `${PATHS.planComputationStatistics.replace(':id', id).replace(':paramsid', ppId)}`,
                                search: props.history.location.search,
                            })
                        }
                    />
                )} */}
                <ViewModePopover />
                <UserViewToggleButton />
            </ButtonsGroup>
            <DialogDHL
                open={open}
                headerLabel={messages.dateSelection}
                onCancel={() => {
                    setOpen(false);
                    setEndDate();
                    //setStartDate();
                    setformatenddate();
                    //setformatstartdate();
                }}
                onConfirm={() => {
                    doRefreshNow();
                    setOpen(false);
                }}
            >
                <Label {...messages.startDate} />

                <DatePicker
                    style={{ width: '90px' }}
                    value={startdate ? startdate : null}
                    onAccept={onAcceptStartDate}
                    minDate={minStartDate}
                />
                <Label {...messages.endDate} />
                <DatePicker
                    style={{ width: '90px' }}
                    value={enddate ? enddate : null}
                    onAccept={onAcceptEndDate}
                    minDate={startdate}
                />
            </DialogDHL>
        </ButtonWrap>
    );
});

export default withSecurity()(withScrollWatch(70)(withRouter(MasterPlanResultToolBar)));
