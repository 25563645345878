/*
 * DayToWzpTransformation Messages
 *
 * This contains all the text for the DayToWzpTransformation component.
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  header: {
    id: 'app.containers.DayToWzpTransformation.header',
    defaultMessage: 'Day to WZP Transformation',
  },
  headerShift: {
    id: 'app.containers.DayToWzpTransformation.headerShift',
    defaultMessage: 'Day to Shift Transformation',
  },
  activity: {
    id: 'app.containers.DayToWzpTransformation.activity',
    defaultMessage: 'Activity',
  },
  activityCustom: {
    id: 'app.containers.DayToWzpTransformation.activityCustom',
    defaultMessage: 'Activity (custom)',
  },
  indirect: {
    id: 'app.components.DayToWzpTransformation.indirect',
    defaultMessage: 'Indirect',
  },
  checkSum: {
    id: 'app.components.DayToWzpTransformation.checkSum',
    defaultMessage: 'Check Sum',
  },
  dayCopyConfirmationText: {
    id: 'app.components.DayToWzpTransformation.copyDayConfirmationText',
    defaultMessage: 'Are you sure you want copy settings from {dayFrom} to {dayTo}',
  },
  dayCopyConfirmationTitle: {
    id: 'app.components.DayToWzpTransformation.copyDayConfirmationTitle',
    defaultMessage: 'Copy day settings',
  },
  dayCopyConfirmationYes: {
    id: 'app.components.DayToWzpTransformation.copyDayConfirmationYes',
    defaultMessage: 'Yes, copy',
  },
  allDepartments: {
    id: 'app.components.DayToWzpTransformation.allDepartments',
    defaultMessage: 'All Departments',
  },
  department: {
    id: 'app.components.DayToWzpTransformation.department',
    defaultMessage: 'Department',
  },
  uom: {
    id: 'app.components.DayToWzpTransformation.uom',
    defaultMessage: 'Unit of Measure',
  },
  customerId: {
    id: 'app.components.DayToWzpTransformation.customerId',
    defaultMessage: 'Customer',
  },
});
