import React from "react";
import { ColDef } from 'ag-grid-community';
import styled from 'styled-components';
import { InjectedIntl, injectIntl } from 'react-intl';
import { dateComparator, dateTimeCellFormatter } from 'utils/dateTime';
import TableControlled from 'containers/TableControlled';
import { TABLE_DEFAULTS } from 'containers/App/constants';
import messages from "./messages";
import { ApiSmartProdSourceDTO } from 'types/drep-backend';
import { DisableValueGetter } from 'components/ListGrid/utils';
const Table = styled(TableControlled)`
  margin-top: 12px;
  height: ${props => props.height}px;

  .ag-overlay-no-rows-wrapper {
    padding-top: 90px;
  }
  .past-row {
    color: red;
  }
  .future-row {
    color: Orange;
  }
  .ag-full-width-row {
    overflow: visible;
  }
`;

type Props = {
    intl?: InjectedIntl;
    entity: ApiSmartProdSourceDTO;
}

const UsedPlansTable: React.FC<Props> = ({ intl, entity }) => {
    const [gridApi, setGridApi] = React.useState(null);
    const onGridReady = params => {
        setGridApi(params);
        params.api.sizeColumnsToFit();
    };

    const ROW_HEIGHT = 30;
    const name = "Plans"
    const rowsData = entity && entity.plans;

    React.useEffect(() => {
        if (gridApi) {
            const colDefs = columnDefs(intl);
            gridApi.api.setColumnDefs(colDefs);
            gridApi.api.sizeColumnsToFit();
        }
    }, [gridApi, entity]);

    const PlanLink = (params) =>{
        const url = `/plan/${params?.data?.id}`
          return (
            <a href={url} target="_blank">
            {params?.value}
          </a>
          )
      }

      const PALink = (params) =>{
        const url = `/area/${params?.data?.planningArea?.id}`
          return (
            <a href={url} target="_blank">
            {params?.value}
          </a>
          )
      }  

    const columnDefs = (intl: InjectedIntl) => {
        const columns: ColDef[] = [
            {
                colId: 'namePlan',
                field: 'name',
                filter: 'setFilter',
                cellRendererFramework: PlanLink,
                menuTabs: ['filterMenuTab'],
                headerName: intl.formatMessage(messages.namePlan),
                sortable: true,
            },
            {
                colId: 'planningAreaPlan',
                field: 'planningArea.name',
                filter: 'setFilter',
                cellRendererFramework: PALink,
                menuTabs: ['filterMenuTab'],
                headerName: intl.formatMessage(messages.planningAreaPlanBase),
                sortable: true,
                resizable: true,
            },
            {
                colId: 'facility',
                field: 'facility',
                filter: 'setFilter',
                menuTabs: ['filterMenuTab'],
                valueGetter: ({ data }) => {
                    const units =
                        data.planningArea && data.planningArea.facilities
                            ? data.planningArea.facilities.map(fac => fac.code).join(', ')
                            : [];
                    return units;
                },
                headerName: intl.formatMessage(messages.facilityPlan),
                sortable: true,
                resizable: true,
            },
            {
                colId: 'managementUnitPlan',
                field: 'managementUnit',
                menuTabs: ['filterMenuTab'],
                valueGetter: ({ data }) => {
                    const units =
                        data.planningArea && data.planningArea.facilities
                            ? data.planningArea.facilities
                                .flatMap(fac => fac.managementUnits)
                                .map(mu => mu.description)
                                .join(', ')
                            : [];
                    return units;
                },
                filter: 'setFilter',
                headerName: intl.formatMessage(messages.managementUnitPlan),
                sortable: true,
                resizable: true,
            },
            {
                colId: 'customerPlan',
                field: 'customer',
                valueGetter: ({ data }) =>
                    data.planningArea && data.planningArea.customers
                        ? data.planningArea.customers.map(c => `${c.code}: ${c.name}`).join(', ')
                        : '',
                filter: 'setFilter',
                menuTabs: ['filterMenuTab'],
                headerName: intl.formatMessage(messages.customerPlan),
                sortable: true,
                resizable: true,
            },
            {
                colId: 'modifiedPlan',
                field: 'audit.updated',
                valueFormatter: dateTimeCellFormatter,
                filter: 'agDateColumnFilter',
                menuTabs: ['filterMenuTab'],
                filterParams: {
                    filterOptions: ['lessThan', 'greaterThan', 'inRange'],
                    comparator: dateComparator,
                },
                headerName: intl.formatMessage(messages.modifiedPlan),
                sortable: true,
                resizable: true,
            },
            {
                colId: 'modifiedByPlan',
                field: 'audit.updatedBy',
                valueGetter: ({ data }) => `${data.audit?.updatedBy?.firstName || ''} ${data.audit?.updatedBy?.lastName || ''}`,
                filter: 'setFilter',
                menuTabs: ['filterMenuTab'],
                headerName: intl.formatMessage(messages.modifiedByPlan),
                sortable: true,
                resizable: true,
            },
            {
                headerName: intl.formatMessage(messages.deleted),
                width: 120,
                field: 'deleted',
                colId: 'deleted',
                menuTabs: ['filterMenuTab'],
                valueGetter: DisableValueGetter,
                filter: 'setFilter',
                filterParams: { values: ['Enabled', 'Disabled'], newRowsAction: 'keep' },
            }

        ]

        return columns;
    }

    return (
        <Table
            masterDetail
            defaultColDef={{
                flex: 1,
            }}
            defaultConfig={TABLE_DEFAULTS.smartProdSourceDetailsPlanConfiguration}
            columnDefs={columnDefs(intl)}
            name={name}
            messages={messages}
            rowData={rowsData}
            rowHeight={ROW_HEIGHT}
            onGridReady={onGridReady}
            height={80 + ROW_HEIGHT * Math.min(10, rowsData?.length || 0)}
            sortable
        />
    )
}

export default injectIntl(UsedPlansTable);