import React, { useState } from 'react';
import { ColDef, ColGroupDef } from 'ag-grid-community';
import { InjectedIntl, injectIntl } from 'react-intl';
import styled from 'styled-components';

import AgTable from 'components/Table';
import { dateComparator, dateTimeCellFormatter } from 'utils/dateTime';
import { useEffectDeepCompare } from 'utils/utils';
import { ApiSmartVolumeRunDTO } from 'types/drep-backend.d';

import messages from './messages';
import SmartProdVolumeRunsTableDetailCellRenderer from './SmartProdVolumeRunsTableDetailCellRenderer';
import { EntityEntry } from './types';

const Table = styled(AgTable)`
  margin-top: 12px;
  height: ${props => props.height}px;

  .ag-overlay-no-rows-wrapper {
    padding-top: 90px;
  }

  .ag-full-width-row {
    overflow: visible;
  }
`;

const columnDefs = (intl: InjectedIntl) => {
  const columns: (ColGroupDef | ColDef)[] = [
    {
      colId: 'created',
      field: 'created',
      valueFormatter: dateTimeCellFormatter,
      cellRenderer: 'agGroupCellRenderer',
      menuTabs: ['filterMenuTab'],
      filter: 'agDateColumnFilter',
      filterParams: {
        filterOptions: ['lessThan', 'greaterThan', 'inRange'],
        comparator: dateComparator,
      },
      headerName: intl.formatMessage(messages.requested),
      sortable: true,
    },
    {
      colId: 'smartProdSourceName',
      field: 'smartProdSourceName',
      filter: 'setFilter',
      menuTabs: ['filterMenuTab'],
      headerName: intl.formatMessage(messages.smartProd),
      sortable: true,
    },
    {
      colId: 'type',
      field: 'type',
      filter: 'setFilter',
      menuTabs: ['filterMenuTab'],
      headerName: intl.formatMessage(messages.type),
      sortable: true,
    },
    {
      colId: 'finished',
      field: 'finished',
      valueFormatter: dateTimeCellFormatter,
      menuTabs: ['filterMenuTab'],
      filter: 'agDateColumnFilter',
      filterParams: {
        filterOptions: ['lessThan', 'greaterThan', 'inRange'],
        comparator: dateComparator,
      },
      headerName: intl.formatMessage(messages.finished),
      sortable: true,
    },
    {
      colId: 'createdBy',
      field: 'createdBy',
      menuTabs: ['filterMenuTab'],
      valueGetter: ({ data }) => `${data.createdBy.firstName || ''} ${data.createdBy.lastName || ''}`,
      filter: 'setFilter',
      headerName: intl.formatMessage(messages.requestedBy),
      sortable: true,
    },
    {
      colId: 'status',
      field: 'status',
      menuTabs: ['filterMenuTab'],
      filter: 'setFilter',
      headerName: intl.formatMessage(messages.status),
      sortable: true,
    },
  ];
  return columns;
};

type Props = {
  intl: InjectedIntl;
  data: ApiSmartVolumeRunDTO[];
  allSmartProdInstances: EntityEntry[];
  allWHIDs: EntityEntry[];
};

const SmartProdVolumeRunsTable: React.FC<Props> = ({ intl, data, allSmartProdInstances, allWHIDs}) => {
  const [openedDetail, setOpenedDetail] = useState(null);
  const [gridApi, setGridApi] = useState(null);
  const onGridReady = params => {
    setGridApi(params);
    params.api.sizeColumnsToFit();
  };

  const handleRowClicked = params => {
    if (!params.node.detail && params.column.colId !== 'actions') {
      params.node.setExpanded(!params.node.expanded);
      gridApi.api.onGroupExpandedOrCollapsed();
    }
  };

  const onRowGroupToggle = params => {
    let isSomeNodeExpanded = params.node.expanded;
    if (params.node.expanded) {
      setOpenedDetail(params.data.id);
      gridApi.api.forEachNode(node => {
        if (node.expanded && node.id !== params.node.id) {
          node.setExpanded(false);
        }
      });
    } else {
      gridApi.api.forEachNode(node => {
        isSomeNodeExpanded = isSomeNodeExpanded || node.expanded;
      });
    }
    if (!isSomeNodeExpanded) {
      setOpenedDetail(null);
    }
  };

  const onFirstDataRendered = params => {
    params.api.forEachNode(node => {
      if (node.data.id === openedDetail) {
        node.setExpanded(true);
      }
    });
  };

  useEffectDeepCompare(() => {
    if (gridApi) {
      const colDefs = columnDefs(intl);
      gridApi.api.setColumnDefs(colDefs);
      gridApi.api.sizeColumnsToFit();
      gridApi.api.forEachNode(node => {
        node.setExpanded(node.data.id === openedDetail);
      });
    }
  //}, [gridApi, data]);
}, [gridApi]);

  const smartProdInstanceLabels = {};
  allSmartProdInstances.forEach(instance => {
    smartProdInstanceLabels[instance.value] = instance.label;
  });
  const whidLabels = {};
  allWHIDs.forEach(whid => {
    whidLabels[whid.value] = whid.label;
  });
  const mappedData = data?.map(row => ({
    ...row,
    smartProdSourceName: `${smartProdInstanceLabels[row.smartProdSource.smartProdInstance.id] || ''} / ${
      whidLabels[row.smartProdSource.smartProdWarehouse.id] || ''
    }`,
  }));

  const DETAIL_ROW_HEIGHT = 470;
  const ROW_HEIGHT = 30;

  return (
    <Table
      masterDetail
      detailCellRenderer="smartVolumeRunsTableDetailCellRenderer"
      detailCellRendererParams={{}}
      frameworkComponents={{
        smartVolumeRunsTableDetailCellRenderer: SmartProdVolumeRunsTableDetailCellRenderer,
      }}
      defaultColDef={{
        flex: 1,
      }}
      sortable
      pagination={false}
      columnDefs={columnDefs(intl)}
      height={80 + ROW_HEIGHT * Math.min(10, mappedData?.length || 0) + (openedDetail ? DETAIL_ROW_HEIGHT : 0)}
      rowData={mappedData}
      rowHeight={ROW_HEIGHT}
      detailRowHeight={DETAIL_ROW_HEIGHT}
      onGridReady={onGridReady}
      onRowGroupOpened={onRowGroupToggle}
      onFirstDataRendered={onFirstDataRendered}
      isRowSelectable={() => false}
      onCellClicked={handleRowClicked}
    />
  );
};

export default injectIntl(SmartProdVolumeRunsTable);
