import React from 'react';
import { Grid } from '@material-ui/core';
import { ErrorMessage, FormikProps } from 'formik';
import {FormattedMessage, injectIntl} from 'react-intl';

import AutomaticRefreshForReportingSettings from 'components/AutomaticRefreshForReportingSettings';
import { EditableList } from 'components/CdmEditableList';
import { FacilityItem } from 'components/CdmEditableList/FacilityItemMasterPlan';
import {ManagementItemNoUnits} from 'components/CdmEditableList/ManagementItem';
import { InputError } from 'components/FormikTextInput';
import MasterPlanDetailBasicInfo from 'components/MasterPlanDetailBasicInfo';
import { Section, TopPadding } from 'containers/CreateMasterPlanPage';
import {ApiMasterPlanDTO, ApiPlanDTO} from 'types/drep-backend.d';

import messages from './messages';
import {toast} from "react-toastify";

type Props = {
  editMode: boolean;
  allPlans: ApiPlanDTO[];
  formik: FormikProps<{
    masterPlan: ApiMasterPlanDTO;
  }>;
  loadPlanAction: Function;
};

const BasicSettings: React.FC<Props> = props => {
  const { formik, editMode, loadPlanAction, allPlans } = props;
  const currentPlans = (allPlans && formik.values.masterPlan.plans
    .filter(p => allPlans.some(candidate => candidate.id === p.planId))
    .map(p => {
      const i = allPlans.findIndex(candidate => candidate.id === p.planId);
      return allPlans[i];
    })) || []

  return (
    <Grid container spacing={0}>
      <Grid item xs={12}>
        <TopPadding />
      </Grid>
      <Grid item xs={6}>
        <Section message={messages.basicInfo} noMaxWidth>
          <MasterPlanDetailBasicInfo formik={formik} editMode={editMode} />
        </Section>
      </Grid>
      <Grid item xs={6}>
        <Section message={messages.automaticRefreshForReporting} noMaxWidth>
          <AutomaticRefreshForReportingSettings
            masterPlanId={formik?.values?.masterPlan.id}
            editMode={editMode}
            loadPlanAction={loadPlanAction}
          />
        </Section>
      </Grid>
      <Grid item xs={4}>
        <Section message={messages.facilities} noMaxWidth>
          <EditableList
            field="masterPlan.facilities"
            entity="facilities"
            isEdit={editMode}
            excludeItems={formik?.values?.masterPlan?.facilities}
            editComponent={FacilityItem}
            validationOnItemRemoved={(f) => {
              const facilities = currentPlans.flatMap(p => p.planningArea.facilities);
              if (facilities.some(facility => facility.id === f.id)) {
                toast.error(<FormattedMessage {...messages.facilityDeleteForbidden} />);
                return false
              } else {
                return true
              }
            }}
          />
          <InputError>
            <ErrorMessage name="masterPlan.facilities" />
          </InputError>
        </Section>
      </Grid>
      <Grid item xs={4}>
        <Section message={messages.customers} noMaxWidth>
          <EditableList
            field="masterPlan.customers"
            entity="customers"
            isEdit={editMode}
            excludeItems={formik?.values?.masterPlan?.customers}
            validationOnItemRemoved={(c) => {
              const customers = currentPlans.flatMap(p => p.planningArea.customers);
              if (customers.some(customer => customer.id === c.id)) {
                toast.error(<FormattedMessage {...messages.customerDeleteForbidden} />);
                return false
              } else {
                return true
              }
            }}
          />
          <InputError>
            <ErrorMessage name="masterPlan.customers" />
          </InputError>
        </Section>
      </Grid>
      <Grid item xs={4}>
        <Section message={messages.managementUnits} noMaxWidth>
          <EditableList
            field="masterPlan.managementUnits"
            entity="managementUnits"
            isEdit={editMode}
            editComponent={ManagementItemNoUnits}
            excludeItems={formik?.values?.masterPlan?.managementUnits}
            // validationOnItemRemoved={(c) => {
            //   const managementUnits = currentPlans.flatMap(p => p.planningArea.managementUnits);
            //   if (managementUnits.some(m => m.id === c.id)) {
            //     toast.error(<FormattedMessage {...messages.managementUnitsDeleteForbidden} />);
            //     return false
            //   } else {
            //     return true
            //   }
            // }}
          />
          <InputError>
            <ErrorMessage name="masterPlan.managementUnits" />
          </InputError>
        </Section>
      </Grid>
    </Grid>
  );
};

// @ts-ignore
export default injectIntl(BasicSettings);
