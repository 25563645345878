/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { connectRouter } from 'connected-react-router/immutable';
// Reducers that needs to be always operational
import { reducer as formReducer } from 'redux-form';
import { combineReducers } from 'redux-immutable';

import intlReducer from 'components/LanguageProvider/redux';
import { reducer as cellEditorReducer } from 'components/TimePickerCellEditor/redux';
import viewModePopoverReducer from 'components/ViewModePopover/reducer';
import globalReducer from 'containers/App/reducer';
import { LOGOUT } from 'containers/LoginPage/constants';
import loginPageReducer from 'containers/LoginPage/reducer';
import masterPlanDetailPageReducer from 'containers/MasterPlanDetailPage/reducer';
import planDetailPageReducer from 'containers/PlanDetailPage/reducer';
import planningAreaDetailPageReducer from 'containers/PlanningAreaDetailPage/reducer';
// TODO should be injected
import planResultPageReducer from 'containers/PlanResultPage/reducer';
import shiftScheduleActivityDetailPageReducer from 'containers/ShiftScheduleActivityDetailPage/reducer';
import shiftScheduleDetailPageReducer from 'containers/ShiftScheduleDetailPage/reducer';
import uploadModalReducer from 'containers/UploadModal/reducer';
import history from 'utils/history';

import shiftScheduleRunsPageReducer from './containers/ShiftScheduleRunsPage/reducer';

/**
 * Creates the main reducer with the dynamically injected ones
 */
const initialState = null;
export default function createReducer(injectedReducers) {
  const appReducer = combineReducers({
    router: connectRouter(history),
    intl: intlReducer,
    planResultPage: planResultPageReducer,
    planDetailPage: planDetailPageReducer,
    masterPlanDetailPage: masterPlanDetailPageReducer,
    planningAreaDetailPage: planningAreaDetailPageReducer,
    shiftScheduleDetailPage: shiftScheduleDetailPageReducer,
    shiftScheduleRunsPage: shiftScheduleRunsPageReducer,
    shiftScheduleActivityDetailPage: shiftScheduleActivityDetailPageReducer,
    viewModePopover: viewModePopoverReducer,
    user: loginPageReducer,
    global: globalReducer,
    form: formReducer,
    cellEditor: cellEditorReducer,
    uploadModal: uploadModalReducer,
    ...injectedReducers,
  });

  return function (state, action) {
    if (action.type === LOGOUT) {
      state = undefined;
    }
    return appReducer(state, action);
  };
}
