import { DateTime } from 'luxon';
import { toast } from 'react-toastify';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import { api, formatDateToApiFormat, withUrl } from 'utils/api';
import { parseTimes } from 'utils/dateTime';
import EnchantedMap from 'utils/enchantedMap';

import { loadShiftSchedule } from '../ShiftScheduleRunsPage/actions';
import { CLOSE_MODAL } from '../UploadModal/constants';
import {
  loadAvailablePlans,
  storeAvailablePlans,
  storeAvailableUnproductiveActivities,
  storeDefaultScheduleParameters,
  storeScheduleStaff,
  storeScheduleStaffSettings,
  storeScheduleStaffSyncState,
  storeScheduleStaffUnproductiveActivities,
  storeShiftSchedule,
} from './actions';
import {
  INVOKE_SCHEDULE_STAFF_SYNC,
  LOAD_AVAILABLE_PLANS,
  LOAD_AVAILABLE_UNPRODUCTIVE_ACTIVITIES,
  LOAD_DEFAULT_SCHEDULE_PARAMETERS,
  LOAD_SCHEDULE_STAFF,
  LOAD_SCHEDULE_STAFF_SETTINGS,
  LOAD_SCHEDULE_STAFF_SYNC_STATE,
  LOAD_SCHEDULE_STAFF_UNPRODUCTIVE_ACTIVITIES,
  LOAD_SHIFT_SCHEDULE,
  SAVE_SCHEDULE_STAFF,
  SAVE_SHIFT_SCHEDULE,
} from './constants';
import { selectShiftScheduleId } from './selectors';
import { mapTransferCostsFromAPI } from './transferCosts/mappingUtils';
import { TransferCostEntry } from './types';

const timeApiFormat = 'hh:mm';

export default function* defaultSaga() {
  yield takeLatest(SAVE_SHIFT_SCHEDULE, doSaveShiftSchedule);
  yield takeLatest(LOAD_SHIFT_SCHEDULE, doLoadShiftSchedule);
  yield takeLatest(LOAD_AVAILABLE_PLANS, doLoadAvailablePlans);
  yield takeLatest(LOAD_SCHEDULE_STAFF, doLoadScheduleStaff);
  yield takeLatest(LOAD_SCHEDULE_STAFF_SETTINGS, doLoadScheduleStaffSettings);
  yield takeLatest(LOAD_AVAILABLE_UNPRODUCTIVE_ACTIVITIES, doAvailableUnproductiveActivities);
  yield takeLatest(LOAD_DEFAULT_SCHEDULE_PARAMETERS, doDefaultScheduleParameters);
  yield takeLatest(LOAD_SCHEDULE_STAFF_UNPRODUCTIVE_ACTIVITIES, doLoadScheduleStaffUnproductiveActivities);
  yield takeLatest(SAVE_SCHEDULE_STAFF, doSaveScheduleStaff);
  yield takeLatest(INVOKE_SCHEDULE_STAFF_SYNC, invokeStaffSync);
  yield takeLatest(LOAD_SCHEDULE_STAFF_SYNC_STATE, doLoadScheduleStaffSyncState);
  yield takeLatest(CLOSE_MODAL, doOnCloseUploadModal);
}

const path = '/schedules/';

function* doLoadAvailablePlans(action) {
  if (action.payload && action.payload.length > 0) {
    const url = `/plans/?pageSize=1000000&facilityIds=${action.payload.join(',')}`;
    const result = yield call(api, withUrl(url));
    if (result.isOk) {
      yield put(storeAvailablePlans(result.data));
    }
  } else {
    yield put(storeAvailablePlans([]));
  }
}

function* doLoadScheduleStaff(action) {
  const response = yield call(api, withUrl(`${path}${action.payload}/staff`).andTitle('Loading staff'));
  if (response.isOk) {
    yield put(storeScheduleStaff(response.data));
  }
}

function* doLoadScheduleStaffSettings(action) {
  const response = yield call(
    api,
    withUrl(`${path}${action.payload.scheduleId}/staff/${action.payload.id}/settings`).andTitle(
      'Loading staff settings',
    ),
  );
  if (response.isOk) {
    yield put(doStoreStaffSettings(response));
  }
}

function* doAvailableUnproductiveActivities(action) {
  const response = yield call(
    api,
    withUrl(`/unproductive-activities`).andTitle('Loading available unproductive activities'),
  );
  if (response.isOk) {
    yield put(doStoreAvailableUnproductiveActivities(response));
  }
}

function* doDefaultScheduleParameters(action) {
  const response = yield call(
    api,
    withUrl(`/schedules/parameters/default`).andTitle('Loading default Schedule Parameters'),
  );
  if (response.isOk) {
    yield put(doStoreDefaultScheduleParameters(response));
  }
}

function* doLoadScheduleStaffUnproductiveActivities(action) {
  const response = yield call(
    api,
    withUrl(`${path}${action.payload.scheduleId}/staff/${action.payload.id}/staffSmartShiftJobSchedule`).andTitle(
      'Loading staff unproductive activities',
    ),
  );
  if (response.isOk) {
    yield put(doStoreStaffUnproductiveActivities(response));
  }
}

function* doLoadScheduleStaffSyncState(action) {
  const response = yield call(api, withUrl(`${path}${action.payload}/staff/state`).andTitle('Loading staff sync state'));
  if (response.isOk) {
    yield put(storeScheduleStaffSyncState(response.data));
  }
}

function* doSaveScheduleStaff(action) {
  const { payload } = action;

  const { staff } = payload;
  staff.staffSmartShiftJobSchedules = staff.staffSmartShiftJobSchedules.map(sssjs => ({
    ...sssjs,
    endTime: sssjs.endTime.toFormat(timeApiFormat),
    startTime: sssjs.startTime.toFormat(timeApiFormat),
    validFrom: formatDateToApiFormat(sssjs.validFrom),
    validTo: formatDateToApiFormat(sssjs.validTo),
  }));
  // SMP-2473
  if (staff.settings?.length) {
    staff.settings = staff.settings.map(ss => ({
      ...ss,
      validFrom: formatDateToApiFormat(ss.validFrom),
      validTo: formatDateToApiFormat(ss.validTo),
    }));
  }
  const response = yield call(
    api,
    withUrl(`${path}${payload.scheduleId}/staff/${payload.staffId}/saveAll`)
      .post(staff)
      .andTitle('Saving schedule staff'),
  );
  if (response.isOk) {
    yield put(doStoreStaffSettings(response));
    yield put(doStoreStaffUnproductiveActivities(response));
    toast.info(`Staff "${payload.staffName}" saved`);
  }
  return response;
}

function* invokeStaffSync(action) {
  yield call(api, withUrl(`${path}${action.payload}/staff`).put().andTitle('Sync schedule staff'));
  yield put(storeScheduleStaffSyncState({ isRunning: true }));
}

function doStoreStaffSettings(response) {
  const mapped = (response.data?.settings || []).map(s => ({
    ...s,
    validFrom: DateTime.fromISO(s.validFrom),
    validTo: s.validTo ? DateTime.fromISO(s.validTo) : null,
  }));
  return storeScheduleStaffSettings(mapped);
}

function doStoreStaffUnproductiveActivities(response) {
  const mapped = (response.data?.staffSmartShiftJobSchedules || []).map(s => {
    const times = parseTimes(s, ['startTime', 'endTime']);
    return {
      ...s,
      days: s.days,
      endTime: times.endTime,
      periodLength: s.periodLength,
      startTime: times.startTime,
      validFrom: DateTime.fromISO(s.validFrom),
      validTo: s.validTo ? DateTime.fromISO(s.validTo) : null,
    };
  });
  return storeScheduleStaffUnproductiveActivities(mapped);
}

function doStoreAvailableUnproductiveActivities(response) {
  const mapped = (response.data || []).map(s => ({
    ...s,
    validFrom: DateTime.fromISO(s.validFrom),
    validTo: s.validTo ? DateTime.fromISO(s.validTo) : null,
  }));
  return storeAvailableUnproductiveActivities(mapped);
}

function doStoreDefaultScheduleParameters(response) {
  const mapped = (response.data.groups || []).map(s => ({
    ...s,
  }));
  return storeDefaultScheduleParameters(mapped);
}

export function* doLoadShiftSchedule(action) {
  const response = yield call(api, withUrl(`${path}${action.payload}`).andTitle('Loading schedule'));
  if (response.isOk) {
    const schedule = getSchedule(response);
    yield put(storeShiftSchedule(schedule));
    const assignResponse = yield call(
      api,
      withUrl(`/schedules/${action.payload}/automaticallyAssignActivitiesToSmartShiftJobs`)
        .andTitle('Loading schedule')
        .post(),
    );
    if (assignResponse.isOk) {
      schedule.smartShiftJobSchedules = assignResponse.data;
      yield put(storeShiftSchedule(schedule));
    }
    // Reload available plans
    yield put(storeAvailablePlans([]));
    yield put(loadAvailablePlans(schedule.facilities.map(f => f.id)));
  }
}

function* doSaveShiftSchedule(action) {
  const response = yield call(
    api,
    withUrl(`${path}${action.payload.schedule.id}/saveAll`).post(action.payload.schedule).andTitle('Saving schedule'),
  );
  if (response.isOk) {
    yield put(storeShiftSchedule(getSchedule(response)));
    toast.info(`Schedule "${action.payload.scheduleName}" saved`);
  } else {
    toast.error('Failed to update the schedule');
  }
  return response;
}

function getSchedule(response) {
  const schedule = response.data;
  schedule.plans = schedule.plans.map(p => ({
    ...p,
    validFrom: DateTime.fromISO(p.validFrom),
    validTo: p.validTo ? DateTime.fromISO(p.validTo) : null,
  }));
  schedule.changesCounter = 0;
  schedule.activityTransferCostsMap = new EnchantedMap<number, TransferCostEntry>();
  schedule.facilityTransferCostsMap = mapTransferCostsFromAPI(schedule.facilityTransferCosts);
  schedule.departmentTransferCostsMap = mapTransferCostsFromAPI(schedule.departmentTransferCosts);
  schedule.version = new Date().getTime();
  return schedule;
}

function* doOnCloseUploadModal(action) {
  if (action && action.payload.doReload && action.payload.entity === 'schedules') {
    const scheduleId = yield select(selectShiftScheduleId);
    yield put(loadShiftSchedule(scheduleId));
  }
}
