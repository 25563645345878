/**
 *
 * PlannedVolumeAddLine
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { createStructuredSelector } from 'reselect';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { nameWithRCLabel } from 'utils/utils';
import { selectGuiState, selectPeriodIndexFromPlan } from '../../containers/PlanDetailPage/selectors';
import { selectGuiStateForPa, selectPeriodIndexFromPa } from '../../containers/PlanningAreaDetailPage/selectors';
import AutocompleteSelect from '../AutocompleteSelect';
import { AddLineButton } from '../IconButton';
import { makeSelectToken } from '../../containers/LoginPage/selectors';
import ButtonWithDirtyCheck from '../ButtonWithDirtyCheck';
import { addLabourAvailabilityCategoryLine } from '../../containers/PlanDetailPage/actions';
import { addLabourAvailabilityCategoryLinePa } from '../../containers/PlanningAreaDetailPage/actions';
// import { getFormikDataPath as labourCatogoriesFormikDataPath } from '../LabourAvailabilityCategoryTable';
// import { getIn } from 'formik';

const Wrapper = styled.div``;

const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  min-width: 200px;
  margin-bottom: 5px;
  margin-top: 5px;
  align-items: center;
  ${AddLineButton} {
    margin-left: 20px;
  }
  > * {
    margin-bottom: 5px;
  }
  > span {
    flex-basis: 150px;
  }
`;

const SelectWrapper = styled.div`
  width: 20em;
  margin-right: 20px;
`;

/* eslint-disable react/prefer-stateless-function */
class LabourAvailabilityCategoryAddLine extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      category: null,
    };
  }

  // getUsedLabourCategories() {
  //   const labourCategoryParameters =
  //     getIn(this.props.values, [...labourCatogoriesFormikDataPath(this.props.periodIndex).split('.')]) || [];
  //   return labourCategoryParameters.map(item => item.labourCategory);
  // }

  render() {
    const editable = this.props.guiState.get('edit');
    const usedLabourCategories = []; // this.getUsedLabourCategories();
    const { reloadCount } = this.props.values;

    return (
      <Wrapper>
        <Grid>
          <SelectWrapper>
            <AutocompleteSelect
              key={reloadCount}
              entity="labourCategories"
              maxMenuHeight={120}
              setValue={value => {
                this.setState({ category: value });
              }}
              value={editable ? this.state.category : null}
              getOptionLabel={nameWithRCLabel}
              disabled={!editable}
              token={this.props.token}
              exclude={usedLabourCategories}
              urlAppendixCallback={() => `&planningParametersId=${this.props.values.planningParameters.id}`}
            />
          </SelectWrapper>
          <div />
          {editable && (
            <ButtonWithDirtyCheck
              disabled={!this.state.category}
              actionPayload={this.state}
              actionHandler={payload => {
                this.props.addLabourAvailabilityCategoryLine(payload);
                this.setState({
                  category: null,
                });
              }}
              component={AddLineButton}
            />
          )}
        </Grid>
      </Wrapper>
    );
  }
}

LabourAvailabilityCategoryAddLine.propTypes = {
  guiState: PropTypes.object,
  token: PropTypes.string,
  addLabourAvailabilityCategoryLine: PropTypes.func,
  values: PropTypes.object,
  // periodIndex: PropTypes.number,
};

const withPlanConnect = connect(
  createStructuredSelector({
    guiState: selectGuiState,
    token: makeSelectToken(),
    periodIndex: selectPeriodIndexFromPlan,
  }),
  dispatch => bindActionCreators({ addLabourAvailabilityCategoryLine }, dispatch),
);

const withPaConnect = connect(
  createStructuredSelector({
    guiState: selectGuiStateForPa,
    token: makeSelectToken(),
    periodIndex: selectPeriodIndexFromPa,
  }),
  dispatch => bindActionCreators({ addLabourAvailabilityCategoryLine: addLabourAvailabilityCategoryLinePa }, dispatch),
);

export default withPlanConnect(LabourAvailabilityCategoryAddLine);
export const PaLabourAvailabilityCategoryAddLine = withPaConnect(LabourAvailabilityCategoryAddLine);
