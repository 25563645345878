import { all, put, select, takeEvery, call, takeLatest } from 'redux-saga/effects';
import { SAVE_CONFIG_TO_FAV, LOAD_CONFIG_FROM_FAV, LOAD_DEFAULTS, LOAD_USER } from './constants';
import { makeSelectTableControlledDomain } from './selectors';
import { setTableConfigAction } from './actions';
import { saveToBSAction, loadFromBSAction } from '../App/actions';
import { SAVE_PINNED_ONLY } from '../App/constants';
import { loadFromBSSaga } from '../App/saga';

// TODO: save config on server

export function* saveConfigToFavSaga() {
  const allRegisteredTables = yield select(makeSelectTableControlledDomain());
  const keys = Object.keys(allRegisteredTables).filter(key => allRegisteredTables[key].isActive);
  yield all(
    keys.map(key => {
      const value = allRegisteredTables[key].config;
      if (value) {
        if (value.columnState && SAVE_PINNED_ONLY.find(item => item === key)) {
          value.columnState = value.columnState.filter(item => item.pinned !== null);
        }
        const { paginationModel, ...other } = value;
        return put(saveToBSAction({ key, value: other }));
      }
      return undefined;
    }),
  );
}

function* loadDefaultsSaga() {
  const allRegisteredTables = yield select(makeSelectTableControlledDomain());
  const keys = Object.keys(allRegisteredTables).filter(key => allRegisteredTables[key].isActive);
  yield all(
    keys.map(key => {
      const value = allRegisteredTables[key].defaultConfig;
      return put(setTableConfigAction(key, value));
    }),
  );
}

export function* loadUserViewSaga() {
  const allRegisteredTables = yield select(makeSelectTableControlledDomain());
  const keys = Object.keys(allRegisteredTables).filter(key => allRegisteredTables[key].isActive);
  yield all(keys.map(key => call(loadConfigFromFavSaga, { tableName: key })));
}

export function* loadConfigFromFavSaga(action) {
  const { tableName } = action;
  const domain = yield select(makeSelectTableControlledDomain());
  if (domain[tableName]) {
    const { defaultConfig } = domain[tableName];
    const config = yield call(loadFromBSSaga, loadFromBSAction({ key: tableName, defaultValue: defaultConfig }));
    if(config && config.filterModel && config.filterModel.deleted){
      delete config.filterModel.deleted;
    }
    yield put(setTableConfigAction(tableName, config));
  }
}

export default function* defaultSaga() {
  yield takeEvery(SAVE_CONFIG_TO_FAV, saveConfigToFavSaga);
  yield takeEvery(LOAD_CONFIG_FROM_FAV, loadConfigFromFavSaga);
  yield takeLatest(LOAD_USER, loadUserViewSaga);
  yield takeLatest(LOAD_DEFAULTS, loadDefaultsSaga);
}
