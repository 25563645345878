import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { DisplayDate } from '../../utils/dateTime';
import messages from './messages';

const WrapDates = styled.div`
  font-size: 10px;
`;

const Value = props => {
  if (props.valueFrom || props.valueTo) {
    return (
      <WrapDates>
        {props.valueFrom ? <DisplayDate value={props.valueFrom} /> : <FormattedMessage {...messages.tbd} />}
        {' - '}
        <br />
        {props.valueTo ? <DisplayDate value={props.valueTo} /> : <FormattedMessage {...messages.tbd} />}
      </WrapDates>
    );
  }
  return <FormattedMessage {...messages.default} />;
};

Value.propTypes = {
  valueFrom: PropTypes.object,
  valueTo: PropTypes.object,
};

export default Value;
