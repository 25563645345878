/*
 * DeleteCellRenderer Messages
 *
 * This contains all the text for the DeleteCellRenderer component.
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  header: {
    id: 'app.components.DeleteCellRenderer.header',
    defaultMessage: 'This is the DeleteCellRenderer component !',
  },
  delete: {
    id: 'app.components.DeleteCellRenderer.delete',
    defaultMessage: 'Delete row',
  },
  details: {
    id: 'app.components.DeleteCellRenderer.details',
    defaultMessage: 'Details',
  },
  copy: {
    id: 'app.components.DeleteCellRenderer.copy',
    defaultMessage: 'Copy',
  },
  forecast: {
    id: 'app.components.DeleteCellRenderer.forecast',
    defaultMessage: 'Forecast',
  },
  result: {
    id: 'app.components.DeleteCellRenderer.result',
    defaultMessage: 'Result',
  },
  addValidity:{
    id: 'app.components.DeleteCellRenderer.addValidity',
    defaultMessage: 'Add Validity',
  }
});
