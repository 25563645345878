import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

const OPEN = 'TIME_PICKER/OPEN';
const CLOSE = 'TIME_PICKER/CLOSE';

export function reducer(state = {}, action) {
  switch (action.type) {
    case OPEN:
      return { ...action.payload };
    case CLOSE:
      return {};
    default:
      return state;
  }
}

export function openEditor(payload) {
  return {
    type: OPEN,
    payload,
  };
}
export function closeEditor(payload) {
  return {
    type: OPEN,
    payload,
  };
}

export function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      openEditor,
      closeEditor,
    },
    dispatch,
  );
}

export const getEditorInfo = state => state.get('cellEditor');

export const mapStateTоProps = createSelector(
  getEditorInfo,
  nextEditor => ({ nextEditor }),
);

export const connectPicker = connect(
  mapStateTоProps,
  mapDispatchToProps,
);
