/**
 *
 * NamedFourSection
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

const Wrapper = styled.div`
  margin: 10px;
  padding: 5px;
  min-width: 20%;
  @media screen and (max-width: 1200px) {
    min-width: 376px;
  }
  background-color: ${props => props.theme.color.greyDlgBg};
  box-shadow: ${props => props.theme.shadow.pageBox};
  flex: 1;
`;

const Title = styled.div`
  font-size: ${props => props.theme.fontSize.title};
  font-weight: 700;
  margin-bottom: 10px;
  height: 22px;
  padding-top: 2px;
`;

const ContentWrap = styled.div`
  position: relative;
  min-width: 340px;
`;

type Props = {
  id?: string,
  className: string,
  message: Object,
  noMaxWidth: boolean,
};

function NamedFourSection(props: Props) {
  return (
    <Wrapper id={props.id} className={props.className} noMaxWidth={props.noMaxWidth}>
      {props.message && (
        <Title>
          <FormattedMessage {...props.message} />
        </Title>
      )}
      <ContentWrap>{props.children}</ContentWrap>
    </Wrapper>
  );
}

NamedFourSection.propTypes = {
  message: PropTypes.object,
  children: PropTypes.node,
  className: PropTypes.node,
  noMaxWidth: PropTypes.bool,
};

export default NamedFourSection;
