/**
 *
 * IconButton
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '@material-ui/core';
import { FormattedMessage, injectIntl } from 'react-intl';
import styled, { css } from 'styled-components';

import Button from '../Button';
import messages from './messages';

const LeftMargin = styled.div`
  margin-left: 10px;
  white-space: nowrap;
`;

const StyledButton = styled(Button)`
  position: relative;
`;

const QuestionMark = styled.div`
  position: absolute;
  right: -6px;
  top: -6px;
`;

const IconButton = styled(props => {
  const text = () =>
    props.icon ? (
      <LeftMargin>
        <FormattedMessage {...(props.message || props.label)} />
      </LeftMargin>
    ) : (
      <FormattedMessage {...(props.message || props.label)} />
    );

  return (
    <StyledButton
      inset={props.inset}
      className={props.className}
      onClick={props.onClick}
      disabled={props.disabled}
      secondary={props.secondary}
      alternative={props.alternative}
      tabIndex={props.tabIndex}
      title={props.title}
      tooltip={props.tooltip && <FormattedMessage {...props.tooltip} />}
      id={props.id}
    >
      {typeof props.icon === 'string' ? <FontAwesomeIcon icon={props.icon} size={props.iconSize} /> : props.icon}
      {(props.message || props.label) && text()}
      {props.tooltipHelp && (
        <Tooltip title={<FormattedMessage {...props.tooltipHelp} />}>
          <QuestionMark>
            <FontAwesomeIcon icon="question-circle" size="sm" />
          </QuestionMark>
        </Tooltip>
      )}
      {props.tooltipHelpAdd && (
          <QuestionMark>
            <FontAwesomeIcon icon="plus-circle" size="sm" />
          </QuestionMark>
      )}
    </StyledButton>
  );
})``;

IconButton.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string,
  message: PropTypes.object,
  onClick: PropTypes.func,
  iconSize: PropTypes.string,
  disabled: PropTypes.bool,
  inset: PropTypes.bool,
  alternative: PropTypes.bool,
  secondary: PropTypes.bool,
  label: PropTypes.object,
};

const SmallButtonCSS = css`
  font-size: 12px;
  line-height: 1.5;
  padding: 5px 10px;
  height: 29px;
`;

export const AddButton = styled(props => <IconButton {...props} icon="plus-circle" label={messages.add} />)`
  ${SmallButtonCSS};
`;
export const SourceDetailButton = styled(props => <IconButton {...props} label={messages.sourceDetail} />)`
  ${SmallButtonCSS};
`;
export const AddLineButton = styled(props => <IconButton {...props} icon="plus-circle" label={messages.addLine} />)`
  ${SmallButtonCSS};
`;
export const ConfirmButton = styled(props => (
  <IconButton tooltip={messages.confirm} id="confirm" {...props} icon="check-circle" />
))``;

export const SaveButton = styled(props => (
  <IconButton id="save" {...props} icon="save" tooltip={messages.save} tabIndex="0" />
))``;

export const EnableSelected = styled(props => (
  <IconButton {...props} icon="trash-alt" label={messages.enableSelected} />
))``;

export const SelectAll = styled(props => <IconButton {...props} icon="plus-circle" label={messages.selectAll} />)``;

export const DeselectAll = styled(props => <IconButton {...props} icon="minus-circle" label={messages.deselectAll} />)``;

export const InvertSelection = styled(props => (
  <IconButton {...props} icon="yin-yang" label={messages.inverSelection} />
))``;

export const DisableSelected = styled(props => (
  <IconButton {...props} icon="trash" label={messages.disableSelected} />
))``;

export const MasterPlanReports = styled(props => (
  <IconButton {...props} label={messages.masterplanReports} />
))``;

export const SmartProdRunReports = styled(props => (
  <IconButton {...props} label={messages.smartProdRunReports} />
))``;

export const DeleteButton = styled(props => <IconButton {...props} icon="trash" label={messages.delete} />)``;

export const BackButton = styled(props => (
  <IconButton
    id="back"
    onClick={() => props.history.goBack()}
    icon="arrow-circle-left"
    tooltip={messages.back}
    {...props}
  />
))``;
export const LeftButton = styled(props => <IconButton {...props} icon="arrow-circle-left" inset />)``;
export const RightButton = styled(props => <IconButton {...props} icon="arrow-circle-right" inset />)``;

export const ExportButton = styled(props => (
  <IconButton id="button-export" message={messages.export} icon="file-export" {...props} />
))``;
export const MHEAssistantButton = styled(props => (
  <IconButton id="button-export" message={messages.mheAssistant} {...props} />
))``;
export const OverrideHelper = styled(props => (
  <IconButton {...props} message={messages.overrideHelper} icon="broadcast-tower" />
))``;
export const SentToKronos = styled(props => (
  <IconButton {...props} message={messages.sendToKronos} icon="external-link-alt" />
))``;
export const FavoritesButton = styled(
  injectIntl(props => (
    <IconButton id="button-favorites" {...props} title={props.intl.formatMessage(messages.fav)} icon="star" />
  )),
)``;

export const CancelButton = styled(
  injectIntl(props => <IconButton id="cancel" tooltip={messages.cancel} icon="times-circle" {...props} />),
)``;

export const SwitchShiftsButton = (message, isShift) =>
  styled(
    injectIntl(props => (
      <IconButton message={message} icon={(isShift && 'toggle-on') || 'toggle-off'} id="shiftOrWzp" {...props} />
    )),
  )``;

export const ExpandButton = styled(
  injectIntl(props => (
    <IconButton
      {...props}
      onClick={() => props.field.onChange(true)}
      title={props.intl.formatMessage(messages.expand)}
      icon="angle-down"
      iconSize="lg"
    />
  )),
)`
  height: 22px;
`;

export const ExpandCollapseButton = styled(props => {
  const {
    field: { value },
  } = props;
  if (value) {
    return <CollapseButton {...props} />;
  }
  return <ExpandButton {...props} />;
})``;

export const CollapseButton = styled(
  injectIntl(props => (
    <IconButton
      {...props}
      onClick={() => props.field.onChange(false)}
      title={props.intl.formatMessage(messages.collapse)}
      icon="angle-up"
      iconSize="lg"
    />
  )),
)`
  height: 22px;
`;

export default IconButton;
