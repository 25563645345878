import * as React from 'react';
import { FormikProps } from 'formik';
import styled from 'styled-components';

import DeleteCellRenderer from 'components/DeleteCellRenderer';
import IconButton from 'components/IconButton';
import ParametersGroups from 'components/ParametersGroups';
import ToggleSection from 'components/ToggleSection';
import { ApiScheduleDTO } from 'types/drep-backend.d';

import messages from './messages';

const InnerContainer = styled.div`
  padding: 0 10px 5px 10px;
`;

const HeaderGridRow = styled.div`
  padding-top: 11px;
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: 166px 127px 128px 127px 187px 185px 113px 36px;
  margin: 0 0 12px;
  column-gap: 10px;
  height: auto;
`;

const GridRow = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: 32px 110px 155px 110px 160px 100px 100px;
  margin: 0 5px 14px;
  column-gap: 10px;
`;

const HeaderText = styled.span`
  padding: 6px 0 0 11px;
`;

const DeleteHeaderText = styled.span`
  padding: 6px 0 0 11px;
  margin-left: 122px;
`;

const FullWidthToggleSection = styled(ToggleSection)`
  min-width: 1100px;
  width: 1100px;
  max-width: 1100px;
`;

export interface ScheduleParameterSetRendererProps {
  dispatch: any;
  intl: any;
  formik: FormikProps<ApiScheduleDTO>;
  data: any;
  user: any;
  doCollapse: () => any;
  scheduleId: number;
  isEdit: boolean;
  openDeleteDialog: (any1, any2, any3) => any;
  deleteParameterSet: () => any;
  sendScheduleRun: (payload) => any;
  cancelScheduleRun: (payload) => any;
  hasPerm(perm: string): () => boolean;
}

class ScheduleParameterSetRenderer extends React.Component<ScheduleParameterSetRendererProps> {
  public render() {
    const { data, doCollapse, formik, isEdit } = this.props;
    const parameterSetFormikIndex = formik.values.parameterSets.findIndex(item => item.name === data.name);

    const setParameterValue = (parameterValueData, newValue) => {
      const groupIndex = formik.values.parameterSets[parameterSetFormikIndex].groups.findIndex(
        group => group.groupName === parameterValueData.groupName,
      );
      const parameterValueFormikIndex = formik.values.parameterSets[parameterSetFormikIndex].groups[
        groupIndex
      ].parametersValues.findIndex(
        parameterValueRow => parameterValueRow.parameterId === parameterValueData.parameterId,
      );
      const path = `parameterSets.${parameterSetFormikIndex}.groups.${groupIndex}.parametersValues.${parameterValueFormikIndex}.value`;
      formik.setFieldValue(path, newValue);
    };

    const onDelete = (payload, params, event) => {
      this.props.openDeleteDialog(this.props.deleteParameterSet, payload, params.data);
      event.preventDefault();
    };

    const setDefault = index => {
      formik.values.parameterSets.forEach((set, i) => {
        const path = `parameterSets.${i}.default`;
        formik.setFieldValue(path, index === i);
      });
    };

    const collapseIcon = (
      <span
        className="ag-group-expanded"
        style={{ cursor: 'pointer', float: 'left', padding: '0 7px 0 0' }}
        onClick={doCollapse}
      >
        <span className="ag-icon ag-icon-contracted" />
      </span>
    );
    const { groups } = formik.values.parameterSets[parameterSetFormikIndex];
    const result = [
      <HeaderGridRow key="header">
        <HeaderText key="name">
          {collapseIcon}
          {data.name}
        </HeaderText>
        <HeaderText key="default">
          {!data.default ? (
            // tslint:disable-next-line:max-line-length
            <IconButton
              onClick={() => setDefault(parameterSetFormikIndex)}
              disabled={!isEdit}
              label={messages.setAsDefault}
            />
          ) : (
            ''
          )}
        </HeaderText>
        <span key="space" />
        <span key="space" />
        <span key="space" />
        <span key="space" />
        {!data.default && isEdit ? (
          // tslint:disable-next-line:jsx-wrap-multiline
          <DeleteHeaderText key="delete">
            <DeleteCellRenderer data={data} intl={this.props.intl} onDelete={onDelete} />
          </DeleteHeaderText>
        ) : (
          ''
        )}
      </HeaderGridRow>,
      <InnerContainer key="data">
        <GridRow>
          <FullWidthToggleSection message={messages.parametersTableHeader} expanded>
            <ParametersGroups
              key={`parameters-groups-editable-${isEdit}`}
              groups={groups}
              setParameterValue={setParameterValue}
              isEdit={isEdit}
            />
          </FullWidthToggleSection>
        </GridRow>
      </InnerContainer>,
    ];
    return result;
  }
}

export default ScheduleParameterSetRenderer;
