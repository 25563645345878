/**
 *
 * PlanDetailBasicSettings
 *
 */

import 'utils/edit.css';
import './style.css';

import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { connect as formikConnect, Field, getIn } from 'formik';
import { FormattedHTMLMessage, FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Tooltip from 'react-tooltip';
import { bindActionCreators, compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';

import ButtonWithDirtyCheck from 'components/ButtonWithDirtyCheck';
import Dialog from 'components/Dialog';
import { SwitchShiftsButton } from 'components/IconButton';
import Lb, { StyledLabel } from 'components/Label';
import { FormikCheckbox } from 'components/Menu/ItemSelectable';
import Select from 'components/StyledSelect';
import { PATHS } from 'containers/App/constants';
import { makeSelectToken } from 'containers/LoginPage/selectors';
import { savePlanBasicSettings } from 'containers/PlanDetailPage/actions';
import { selectGuiState } from 'containers/PlanDetailPage/selectors';
import { savePlanningAreaBasicSettings } from 'containers/PlanningAreaDetailPage/actions';
import { selectGuiStateForPa } from 'containers/PlanningAreaDetailPage/selectors';
import { DOW_TRANS_ACTIVITY_AKA_WEEKLY, DOW_TRANS_VOLUME_CATEGORY_AKA_DAILY, T_TYPE, LABOUR_AVAILABILITY_SHIFT, LABOUR_AVAILABILITY_DEPARTMENT, CALCULATE_TYPE_FTE, CALCULATE_TYPE_STAFF, LABOUR_AVAILABILITY_ROLE } from 'utils/constants';

import AutocompleteSelect from '../AutocompleteSelect';
import { FormikDayPicker } from '../FormikDayPicker';
import FormikNumberInput from '../FormikNumberInput';
import HelpPoint from '../HelpPoint';
import messages from './messages';

const Label = styled(Lb)`
  margin-right: 10px;
  height: ${props => props.theme.grid.rowHeight};
  display: flex;
  align-items: center;
`;

const LabelWithHelp = styled(StyledLabel)`
  margin-right: 10px;
`;

const WarningLabel = styled.div`
  margin: 12px 0px;
  color: ${props => props.theme.color.red};
`;

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 160px 192px;
  align-items: center;
  grid-row-gap: ${props => props.theme.grid.rowGap};
  input {
    background-color: ${props => props.theme.color.grey5};
  }
`;

const TransWrapper = styled.div`
  display: flex;
  > :first-child {
    flex: 2;
    min-width: 122px;
    margin-right: 5px;
  }
  > :last-child {
    height: 28px;
    font-size: 12px;
    > div {
      margin-left: 5px;
    }
    > :last-child {
      max-width: 170px;
      margin-left: 0px;
    }
  }
`;

const DayPickerWrapper = styled.div`
  max-width: 250px;
`;

const PATHS_BASIC_SETTINGS = {
  offdays: 'planningParameters.offDays',
  labourAvailabilityType: 'planningParameters.labourAvailabilityType',
  calculateType: 'planningParameters.calculateType',
  hours: 'planningParameters.hoursInDayFTE',
  currency: 'planningParameters.currency',
  timeZone: 'planningParameters.timezone',
  transformationType: 'planningParameters.transformationType',
  activityTransformation: 'planningParameters.dayTransformationType',
};

function isPlan(props) {
  const {
    match: { path },
  } = props;
  return path.startsWith('/plan/');
}

/* eslint-disable react/prefer-stateless-function */
class DetailEntityBasicSettings extends React.PureComponent {
  state = {
    showShiftWarning: false,
  };

  showShiftWarring = () => {
    this.setState({ showShiftWarning: true });
  };

  switchAndSave = isShift => () => {
    const { formik } = this.props;
    const value = (isShift && T_TYPE.WZP) || T_TYPE.SHIFT;
    formik.resetForm(); // form was even saved by dirty check or user wanted to ignore changes = do not save other changed fields - SMP-83
    formik.setFieldValue(PATHS_BASIC_SETTINGS.transformationType, value);
    formik.submitForm();
    this.setState({ showShiftWarning: false });
  };

  copyPlan = (isShift, plan) => () => {
    const {
      history,
      formik: { values },
    } = this.props;
    const value = (isShift && T_TYPE.WZP) || T_TYPE.SHIFT;
    const path = (plan && PATHS.makeCopyPlan) || PATHS.makeCopyPA;
    history.push({
      pathname: path.replace(':id', values.id),
      search: `?transformationType=${value}`,
    });
    this.setState({ showShiftWarning: false });
  };

  render() {
    const {
      intl: { formatMessage },
      guiState,
      formik,
      planDetailSchedulesData,
    } = this.props;
    const { showShiftWarning } = this.state;
    const editable = guiState.get('edit');
    const paths = PATHS_BASIC_SETTINGS;
    const tz = getIn(formik.values, paths.timeZone);
    const activityTransformation = getIn(formik.values, paths.activityTransformation, DOW_TRANS_ACTIVITY_AKA_WEEKLY);
    const labourAvailabilityType = getIn(formik.values, paths.labourAvailabilityType, LABOUR_AVAILABILITY_SHIFT);
    const calculateType = getIn(formik.values, paths.calculateType, CALCULATE_TYPE_FTE);
    const tTypeValue = getIn(formik.values, paths.transformationType, T_TYPE.WZP);
    const isShift = tTypeValue === T_TYPE.SHIFT;
    const warning = isShift ? 'fromShiftsWarning' : 'toShiftsWarning';
    const planDetials = isPlan(this.props);
    const itemText = planDetials ? formatMessage(messages.plan) : formatMessage(messages.planningArea);

    return (
      <>
        {showShiftWarning && (
          <Dialog
            open
            headerLabel={messages.warning}
            CustomButtons={
              <React.Fragment key={1}>
                <Button onClick={this.switchAndSave(isShift)} color="primary" variant="contained" size="small">
                  <FormattedMessage {...messages.changeAnyway} />
                </Button>
                <Button onClick={this.copyPlan(isShift, planDetials)} color="secondary" variant="contained" size="small">
                  <FormattedMessage {...messages.copyPlan} values={{ item: itemText }} />
                </Button>
                <Button
                  onClick={() => this.setState({ showShiftWarning: false })}
                  color="secondary"
                  variant="contained"
                  size="small"
                >
                  <FormattedMessage {...messages.cancel} />
                </Button>
              </React.Fragment>
            }
          >
            <FormattedMessage {...messages[warning]} />
            <br />
            <br />
            <FormattedMessage {...messages.warningSuggestion} values={{ item: itemText }} />
          </Dialog>
        )}
        <Wrapper>
          <Label {...messages.wtDay} />
          <FormikNumberInput
            name={paths.hours}
            value={getIn(formik.values, paths.hours)}
            editable={editable}
            required
            min={1}
            max={24}
          />
          <Label {...messages.offDays} />
          <DayPickerWrapper>
            <FormikDayPicker
              name={paths.offdays}
              value={getIn(formik.values, paths.offdays)}
              editable={editable}
              isMulti
            />
          </DayPickerWrapper>
          <Label {...messages.labourAvailabilityType} />  
          {editable ? (
            <Select
              classNamePrefix="react-select"
              className="react-select-container"
              isClearable={false}
              options={[{ value: LABOUR_AVAILABILITY_SHIFT }, { value: LABOUR_AVAILABILITY_DEPARTMENT }]}
              getOptionLabel={val => <FormattedMessage {...messages[val.value]} />}
              onChange={val => formik.setFieldValue(paths.labourAvailabilityType, val.value)}
              value={{ value: labourAvailabilityType }}
            />
          ) : (
            <span>
              {labourAvailabilityType in messages ? (
                <FormattedMessage {...messages[labourAvailabilityType]} />
              ) : (
                labourAvailabilityType
              )}
            </span>
          )}
          {
            <>
              <Label {...messages.activityAdjustment} />
              <div style={{ width: '34px' }}>
                <Field
                  name="planningParameters.hasActivityAdjustments"
                  editable={editable}
                  disabled={!editable}
                  component={FormikCheckbox}
                />
              </div>
            </>
          }
          {
            <>
              <Label {...messages.UPHPerShift} />
              <div style={{ width: '34px' }}>
                <Field
                  name="planningParameters.productivityRatePerShift"
                  editable={editable}
                  disabled={!editable}
                  component={FormikCheckbox}
                />
              </div>
            </>
          }
          <Label {...messages.currency} />
          {editable ? (
            <Field
              name={paths.currency}
              render={({ field, form }) => (
                <AutocompleteSelect
                  getOptionLabel={params => params.code}
                  entity="currencies"
                  value={field.value}
                  setValue={value => {
                    form.setFieldValue(paths.currency, value);
                  }}
                  token={this.props.token}
                />
              )}
            />
          ) : (
            <span>{getIn(this.props.formik.values, `${paths.currency}.code`)}</span>
          )}

          <Label {...messages.timeZone} />
          {editable ? (
            <Field
              name={paths.timeZone}
              render={({ field, form }) => (
                <AutocompleteSelect
                  getOptionLabel={params => `${params.offset} - ${params.name}`}
                  entity="timezones"
                  value={field.value}
                  setValue={value => {
                    form.setFieldValue(paths.timeZone, value);
                  }}
                  token={this.props.token}
                />
              )}
            />
          ) : (
            <span>{tz && `${tz.offset} - ${tz.name}`}</span>
          )}
          {isPlan(this.props) &&
            <>
              <Label {...messages.calculateType} />
              {editable ? (
                <Select
                  classNamePrefix="react-select"
                  className="react-select-container"
                  isClearable={false}
                  id="calculateType"
                  options={[{ value: CALCULATE_TYPE_FTE }, { value: CALCULATE_TYPE_STAFF }]}
                  getOptionLabel={val => <FormattedMessage {...messages[val.value]} />}
                  onChange={val => formik.setFieldValue(paths.calculateType, val.value)}
                  value={{ value: calculateType }}
                />
              ) : (
                <span>
                  {calculateType in messages ? (
                    <FormattedMessage {...messages[calculateType]} />
                  ) : (
                    calculateType
                  )}
                </span>
              )}
            </>
          }
          <Label {...messages.transformationType} />
          {editable ? (
            <TransWrapper>
              <Select
                classNamePrefix="react-select"
                className="react-select-container"
                isClearable={false}
                options={isShift ? [{ value: T_TYPE.SHIFT }] : [{ value: T_TYPE.WZP }, { value: T_TYPE.HOUR }]}
                getOptionLabel={val => <FormattedMessage {...messages[val.value]} />}
                onChange={val => this.props.formik.setFieldValue(paths.transformationType, val.value)}
                value={{ value: tTypeValue }}
              />
              <ButtonWithDirtyCheck
                component={SwitchShiftsButton((isShift && messages.useWZP) || messages.useShift, isShift)}
                actionHandler={this.showShiftWarring}
                tooltip={(isShift && { ...messages.useWZPTooltip }) || { ...messages.useShiftTooltip }}
                id="transformation_type_button"
              />
            </TransWrapper>
          ) : (
            <span>{tTypeValue in messages ? <FormattedMessage {...messages[tTypeValue]} /> : tTypeValue}</span>
          )}

          {tTypeValue !== T_TYPE.HOUR && (planDetailSchedulesData && planDetailSchedulesData.length) ? (
            <>
              <Label {...messages.useAdvancedShiftDistribution} />
              <div style={{ width: '34px' }}>
                <Field
                  name="planningParameters.useAdvancedShiftDistribution"
                  editable={editable}
                  disabled={!editable}
                  component={FormikCheckbox}
                />
              </div>
            </>
          ):null}

          <div>
            <LabelWithHelp>
              {formatMessage(messages.activityTransformation)}
              <HelpPoint dataFor="activityTransformationLabel" />
            </LabelWithHelp>
            <Tooltip
              className="activityTransformationLabelHelp"
              id="activityTransformationLabel"
              place="bottom"
              data-html="true"
              type="info"
              effect="float"
            >
              <FormattedHTMLMessage {...messages.activityTransformationHelp} />
            </Tooltip>
          </div>

          {editable ? (
            <Select
              classNamePrefix="react-select"
              className="react-select-container"
              isClearable={false}
              options={[{ value: DOW_TRANS_ACTIVITY_AKA_WEEKLY }, { value: DOW_TRANS_VOLUME_CATEGORY_AKA_DAILY }]}
              getOptionLabel={val => <FormattedMessage {...messages[val.value]} />}
              onChange={val => formik.setFieldValue(paths.activityTransformation, val.value)}
              value={{ value: activityTransformation }}
            />
          ) : (
            <span>
              {activityTransformation in messages ? (
                <FormattedMessage {...messages[activityTransformation]} />
              ) : (
                activityTransformation
              )}
            </span>
          )}
        </Wrapper>
        {activityTransformation === DOW_TRANS_ACTIVITY_AKA_WEEKLY && (
          <div>
            <WarningLabel>
              <FormattedMessage {...messages.weeklyTransformationTypeSelectedWarning} />
            </WarningLabel>
          </div>
        )}
      </>
    );
  }
}

DetailEntityBasicSettings.propTypes = {
  guiState: PropTypes.object,
  save: PropTypes.func,
  token: PropTypes.string,
  formik: PropTypes.object,
  intl: PropTypes.object,
};

const mapPlanStateToProps = state => ({
  ...createStructuredSelector({
    guiState: selectGuiState,
    token: makeSelectToken(),
  })(state),
});

function mapPlanDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      save: savePlanBasicSettings,
    },
    dispatch,
  );
}

const withPlanConnect = connect(mapPlanStateToProps, mapPlanDispatchToProps);

const mapPaStateToProps = state => ({
  ...createStructuredSelector({
    guiState: selectGuiStateForPa,
    token: makeSelectToken(),
  })(state),
});

function mapPaDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      save: savePlanningAreaBasicSettings,
    },
    dispatch,
  );
}

const withPaConnect = connect(mapPaStateToProps, mapPaDispatchToProps);

export const PlanDetailBasicSettings = compose(
  withPlanConnect,
  formikConnect,
  injectIntl,
)(withRouter(DetailEntityBasicSettings));

export const PlanningAreaDetailBasicSettings = compose(
  withPaConnect,
  formikConnect,
  injectIntl,
)(withRouter(DetailEntityBasicSettings));
