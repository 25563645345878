/*
 * ActualVolume Messages
 *
 * This contains all the text for the ActualVolume component.
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  formulas: {
    id: 'app.components.ActualVolume.formulas',
    defaultMessage: 'Reverse Formula',
  },
  calculate: {
    id: 'app.components.ActualVolume.calculate',
    defaultMessage: 'Calculate Actual Volume',
  },
  category: {
    id: 'app.components.ActualVolume.category',
    defaultMessage: 'Volume Category',
  },
  categoryVariable: {
    id: 'app.components.ActualVolume.categoryVariable',
    defaultMessage: 'Category Variable',
  },
  uom: {
    id: 'app.components.ActualVolume.uom',
    defaultMessage: 'Unit of Measure',
  },
  week: {
    id: 'app.components.ActualVolume.week',
    defaultMessage: 'Week',
  },
  categorySection: {
    id: 'app.components.ActualVolume.categorySection',
    defaultMessage: 'Volume Category',
  },
  categoryVariableSection: {
    id: 'app.components.ActualVolume.categoryVariableSection',
    defaultMessage: 'Volume Category Variable',
  },
  day: {
    id: 'app.components.ActualVolume.day',
    defaultMessage: 'Day',
  },
  value: {
    id: 'app.components.ActualVolume.value',
    defaultMessage: 'Value',
  },
  formula: {
    id: 'app.components.ActualVolume.formula',
    defaultMessage: 'Formula',
  },
  reverseFormula: {
    id: 'app.components.ActualVolume.reverseFormula',
    defaultMessage: 'Formula',
  },
  defaultFormula: {
    id: 'app.components.ActualVolume.defaultFormula',
    defaultMessage: 'Default Formula',
  },
});
