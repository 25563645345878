export function constructdays(formik) {
  const days = new Set();
  formik.values.adjustmentOverviewData && formik.values.adjustmentOverviewData.rows && formik.values.adjustmentOverviewData.rows.forEach((row) => {
    row.columns.forEach((col) => {
      if (!days.has(col.date)) {
        days.add(col.date);
      }
    })
  })
  return days;
}

function setOverRiddenValue(current, coming) {
  if ((current && current.overriddenRate >= 0 && current.overriddenRate !== null) && (coming && coming.overriddenRate >= 0 && coming.overriddenRate !== null)) {
    return current.overriddenRate;
  } else if (current && current.overriddenRate >=0 && coming && !coming.overriddenRate) {
    return current.overriddenRate;
  } else if (current && !current.overriddenRate && coming && coming.overriddenRate >=0) {
    if(current.type === "OVERRIDE_FROM_FORECAST" || current.type === "FORECAST"){
      return current.forecastRate;
    }else if(current.type === "DEFAULT" || current.type === "OVERRIDE_FROM_DEFAULT"){
      return current.defaultRate
    }
  } else {
    return current && current.overriddenRate;
  }
}

function setOverRiddenComingValue(current, coming) {
  if ((current.overriddenRate >= 0 && current.overriddenRate !== null) && coming && (coming.overriddenRate >= 0 && coming.overriddenRate !== null)) {
    return coming.overriddenRate;
  } else if (current.overriddenRate >=0 && coming && !coming.overriddenRate) {
    if(coming.type === "OVERRIDE_FROM_FORECAST" || coming.type === "FORECAST"){
      return coming.forecastRate;
    }else if(coming.type === "DEFAULT" || coming.type === "OVERRIDE_FROM_DEFAULT"){
      return coming.defaultRate
    }
  } else if (!current.overriddenRate && coming && coming.overriddenRate) {
    return coming.overriddenRate;
  } else {
    return coming && coming.overriddenRate;
  }
}
export function constructRowDataIndirect(tempRowData, days) {
  var rowDataIndirect = tempRowData.reduce((final, data) => {
    let isAlready = final.find((value) => {
      return value.activityForecastFromLogicalKey == data.activityForecastFromLogicalKey;
    });
    if (!isAlready) {
      final.push(data);
    } else {
      var index = final.indexOf(isAlready);
      const initialData = final[index] && final[index].columns;
      const columnsData = [...initialData];
      const addNotAvailableDays = days.map((day) => {
        if (!columnsData.find(col => col.date === day)) {
          columnsData.push({ date: day })
        }
      })
      columnsData.forEach((cd, index) => {
        const dataToAdd = data.columns.filter(c => c.date == cd.date);
        const overriddenCurrentValue = setOverRiddenValue(columnsData[index], dataToAdd[0]);
        const overriddenComingValue = setOverRiddenComingValue(columnsData[index], dataToAdd[0]);
        if(("overriddenRate" in columnsData[index]) || ("overriddenRate" in dataToAdd[0])){
        columnsData[index].overriddenRate = parseFloat(overriddenCurrentValue || 0) + parseFloat(overriddenComingValue || 0);
        }
        if(((columnsData[0].type ==='FORECAST' && columnsData[0].forecastRate >= 0) || (columnsData[0].type ==='DEFAULT' && columnsData[0].defaultRate >= 0)) || ((dataToAdd[0].type ==='FORECAST' && dataToAdd[0].forecastRate >= 0) || dataToAdd[0].type ==='DEFAULT' && dataToAdd[0].defaultRate >= 0)){
          const comingData = dataToAdd && dataToAdd[0] && dataToAdd[0].overriddenRate >= 0 &&  dataToAdd[0].overriddenRate === 0 ? 0 : dataToAdd[0].type ==='FORECAST' ? dataToAdd[0].forecastRate :  dataToAdd[0].defaultRate;
          if(columnsData[index].type === 'FORECAST'){
            columnsData[index].forecastRate = parseFloat(columnsData[index].forecastRate || 0) + parseFloat(comingData || 0);
          }else if(columnsData[index].type === 'DEFAULT'){
            columnsData[index].defaultRate = parseFloat(columnsData[index].defaultRate || 0) + parseFloat(comingData || 0);
          }
        }
      })
      final[index].columns = [...columnsData]
    }
    return final;
  }, [])
  return rowDataIndirect;
}

const getDepartment = (departments, id) => {
  if (id === null || id === undefined) {
    return '';
  }
  const department = departments && departments.filter((dep) => dep.id == id) || [];
  return department && department[0] && department[0].name ? department[0].name : '';
}
const getCustomer = (customers, id) => {
  if (id === null || id === undefined) {
    return ''
  }
  const customer = customers && customers.filter((cus) => cus.id == id);
  return customer && customer[0] && customer[0].name ? customer[0].name : '';
}

export function constructRowDataDirect(rowData, activityParamaterList, departments, customers, type) {
  if (type === 'direct') {
    rowData && rowData.forEach((rd) => {
      const activityRawFrom = rd.activityForecastToLogicalKey;
      const activityFrom = activityRawFrom && activityRawFrom.split('_')[0];
      const activityFromData = activityParamaterList && activityParamaterList.find((ac) => ac.activity.id == activityFrom);
      const department = getDepartment(departments, activityRawFrom && activityRawFrom.split('_')[1]);
      const customer = getCustomer(customers, activityFromData && activityFromData.customerId);
      const activityRawTo = rd.activityForecastFromLogicalKey;
      const activityTo = activityRawTo && activityRawTo.split('_')[0];
      const activityToData = activityParamaterList && activityParamaterList.find((ac) => ac.activity.id == activityTo);
      const departmentTo = getDepartment(departments, activityRawTo && activityRawTo.split('_')[1]);
      const customerTo = getCustomer(customers, activityRawTo && activityRawTo.split('_')[2]);
      const omsData = activityParamaterList && activityParamaterList.find((ac) => ac.activity.id == activityFrom);
      const omsName = omsData && omsData.activity && omsData.activity.omsName;
      const omsCode = omsData && omsData.activity && omsData.activity.omsCode;
      rd.oms = omsName && omsCode ? `${omsCode}-${omsName}` : '';
      rd.activityForecastToValue = `${customerTo}/${departmentTo}/${activityToData && activityToData.activity && activityToData.activity.name} (${activityToData && activityToData.activity && activityToData.activity.variableName})`
      rd.activityForecastFromValue = `${customer}/${department}/${activityFromData && activityFromData.activity && activityFromData.activity.name} (${activityFromData && activityFromData.activity && activityFromData.activity.variableName})`
    })
  }
  if (type === 'indirect') {
    rowData && rowData.forEach((rd) => {
      const activityRawTo = rd.activityForecastFromLogicalKey;
      const activityTo = activityRawTo && activityRawTo.split('_')[0];
      const activityToData = activityParamaterList && activityParamaterList.find((ac) => ac.activity.id == activityTo);
      const departmentTo = getDepartment(departments, activityRawTo && activityRawTo.split('_')[1]);
      const customerTo = getCustomer(customers, activityRawTo && activityRawTo.split('_')[2]);
      const omsData = activityParamaterList && activityParamaterList.find((ac) => ac.activity.id == activityTo);
      const omsName = omsData && omsData.activity && omsData.activity.omsName;
      const omsCode = omsData && omsData.activity && omsData.activity.omsCode;
      rd.oms = omsName && omsCode ? `${omsCode}-${omsName}` : '';
      rd.activityForecastFromValue = `${customerTo}/${departmentTo}/${activityToData && activityToData.activity && activityToData.activity.name} (${activityToData && activityToData.activity && activityToData.activity.variableName})`
    })
  }
  return rowData;
}


export function sortDataByDays(rowData, daysArray){
  rowData && rowData.map((row)=>{
    daysArray.map((day) => {
      if (!row.columns.find(col => col.date === day)) {
      //@ts-ignore
      var d = new Date(day);
      var days = ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'];
      var dayName = days[d.getDay()];
        row.columns.push({ date: day, dayOfWeek: dayName })
      }
    })
  })
  rowData && rowData.forEach((row)=>{
    row && row.columns && row.columns.sort((a,b)=>{
      var c = new Date(a.date);
      var d = new Date(b.date);
      //@ts-ignore
      return c-d;
    })
  })
  return rowData;
}


export function validateRowDataIndirect(tempRowData, days) {
  let greaterThanHundred = false;
  var rowDataIndirect = tempRowData.reduce((final, data) => {
    let isAlready = final.find((value) => {
      return value.activityForecastFromLogicalKey == data.activityForecastFromLogicalKey;
    });
    if (!isAlready) {
      final.push(data);
    } else {
      var index = final.indexOf(isAlready);
      const initialData = final[index] && final[index].columns;
      const columnsData = [...initialData];
      const addNotAvailableDays = days.map((day) => {
        if (!columnsData.find(col => col.date === day)) {
          columnsData.push({ date: day })
        }
      })
      columnsData.forEach((cd, index) => {
        const dataToAdd = data.columns.filter(c => c.date == cd.date);
        const overriddenCurrentValue = setOverRiddenValue(columnsData[index], dataToAdd[0]);
        const overriddenComingValue = setOverRiddenComingValue(columnsData[index], dataToAdd[0]);
        if(columnsData[index].overriddenRate || dataToAdd[0].overriddenRate){
        columnsData[index].overriddenRate = parseFloat(overriddenCurrentValue || 0) + parseFloat(overriddenComingValue || 0);
        if(parseFloat(overriddenCurrentValue || 0) + parseFloat(overriddenComingValue || 0) > 100){
          greaterThanHundred = parseFloat(overriddenCurrentValue || 0) + parseFloat(overriddenComingValue || 0);
        }
        }
        if(columnsData[index].rate || dataToAdd[0].rate >= 0){
        columnsData[index].rate = parseFloat(columnsData[index].rate || 0) + parseFloat(dataToAdd && dataToAdd[0] && dataToAdd[0].rate || 0);
        }
      })
      final[index].columns = [...columnsData]
    }
    return final;
  }, [])
  return greaterThanHundred;
}