/*
 * DayOfWeekTransformation Messages
 *
 * This contains all the text for the DayOfWeekTransformation component.
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  header: {
    id: 'app.components.DayOfWeekTransformation.header',
    defaultMessage: 'Activity Day of Week Transformation',
  },
  customer: {
    id: 'app.components.DayOfWeekTransformation.customer',
    defaultMessage: 'Customer',
  },
  volumeCategoryHeader: {
    id: 'app.components.DayOfWeekTransformation.volumeCategoryHeader',
    defaultMessage: 'Volume Category Day of Week Transformation',
  },
  activity: {
    id: 'app.components.DayOfWeekTransformation.activity',
    defaultMessage: 'Activity',
  },
  activityCustom: {
    id: 'app.components.DayOfWeekTransformation.activityCustom',
    defaultMessage: 'Activity (custom)',
  },
  indirect: {
    id: 'app.components.DayOfWeekTransformation.indirect',
    defaultMessage: 'Indirect',
  },
  action: {
    id: 'app.components.DayOfWeekTransformation.action',
    defaultMessage: 'Action',
  },
  checkSum: {
    id: 'app.components.DayOfWeekTransformation.checkSum',
    defaultMessage: 'Check Sum',
  },
  volumeCategory: {
    id: 'app.components.DayOfWeekTransformation.volumeCategory',
    defaultMessage: 'Volume Category',
  },
  uom: {
    id: 'app.components.DayOfWeekTransformation.uom',
    defaultMessage: 'Unit of Measure',
  },
  vcVariable: {
    id: 'app.components.DayOfWeekTransformation.vcVariable',
    defaultMessage: 'VC Variable',
  },
  vcNonVariable: {
    id: 'app.components.DayOfWeekTransformation.vcNonVariable',
    defaultMessage: 'Volume Category',
  },
  allDepartments: {
    id: 'app.components.DayOfWeekTransformation.allDepartments',
    defaultMessage: 'All Departments',
  },
  department: {
    id: 'app.components.DayOfWeekTransformation.department',
    defaultMessage: 'Department',
  },
});
