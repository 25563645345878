// @flow
import React from 'react';
import { FormattedMessage } from 'react-intl';
import uuid from 'uuid/v4';

import debounce from 'lodash/debounce';
import messages from '../messages';
import SearchInput from './SearchInput';
import Checkbox from './Checkbox';

type Props = {
  values: Array<Object>,
  valuesCallback?: () => any[],
  filterChangedCallback: Function,
  api: Object, // ag-grid api
  column: Object, // filter Column
  debounceMs?: number,
  suppressSearch?: boolean,
  clearButton?: boolean,
  applyButton?: boolean,
  valueGetter?: Function,
  colDef: Object,
};

type StateProps = {
  isLoading: boolean,
  list: Array<Object>,
  origList: Array<Object>,
  search: string,
  selectAll: boolean,
  selected: Object,
  isFilterActive: boolean,
};

class SetFilter extends React.PureComponent<Props, StateProps> {
  allSelectedLength = 0;

  filterChangedCallback = () => {};

  colId = null;

  valGetter = null;

  valFormatter = null;

  filterValueGetter = null;

  constructor(props: Props) {
    super(props);
    const {
      api,
      column: { colId },
    } = this.props;
    const filtersModel = api.getFilterModel();
    const model = (filtersModel && filtersModel[colId]) || null;
    this.state = { list: [], isLoading: true }
    this.getState(model).then(s => {
      this.setState(s)
    });
  }

  hidePopup() {
    document.getElementsByTagName('body')[0].click();
  }

  getValue = (rowNode: Object) => {
    if (!rowNode.data) return '';
    let val = rowNode.data && rowNode.data[this.colId];

    if (this.filterValueGetter) {
      val = this.filterValueGetter(rowNode);
    } else {
      if (this.valGetter) val = this.valGetter(rowNode);
      if (this.valFormatter) val = this.valFormatter({ value: val });
    }
    return val;
  };

  getState = (filterModel?: Object) : Promise => {
    const {
      column: { colId },
      colDef,
      debounceMs,
      filterChangedCallback,
      valueGetter,
      applyButton,
    } = this.props;
    this.colId = colId;
    if (!applyButton) {
      this.filterChangedCallback = debounceMs ? debounce(filterChangedCallback, debounceMs) : filterChangedCallback;
    }
    this.valGetter = (valueGetter && valueGetter) || (colDef.valueGetter && colDef.valueGetter) || null;
    this.valFormatter = colDef.valueFormatter;
    this.filterValueGetter = colDef.filterValueGetter;

    return this.getFilterValues().then(origList => {
      origList.sort();
      this.allSelectedLength = origList.length;

      if (!filterModel) {
        const selected = origList;
        return Promise.resolve({
          list: origList,
          origList,
          selected,
          search: '',
          selectAll: true,
          isFilterActive: false,
          isLoading: false
        });
      }

      const { values = [], search = '' } = filterModel;
      const list = search ? this.filterList(search, origList) : origList;
      const selected = (Array.isArray(values) && values) || list;
      return Promise.resolve({
        list,
        origList,
        selected,
        search,
        selectAll: false,
        isFilterActive: true,
        isLoading: false
      });
    });
  };

  getFilterValues() : Promise {
    let origList = [];

    if (this.props.valuesCallback) {
      return this.props.valuesCallback();
    }

    if (this.props.values) origList = this.props.values.slice();
    else {
      const {api, doesRowPassOtherFilter} = this.props;

      const {getValue} = this;
      api.forEachNode(function (rowNode, index) {
        if (!doesRowPassOtherFilter(rowNode)) return;
        if (!rowNode.data) return;

        let val = getValue(rowNode);
        if (typeof val === 'number') val = `${val}`;
        if (typeof val === 'string' && val.length > 0 && origList.indexOf(val) < 0) origList.push(val);
      });
    }
    return Promise.resolve(origList);
  }

  getModel = () => {
    const { selected, isFilterActive, search } = this.state;
    if (!isFilterActive) return;
    return { filterType: 'set', values: selected, search };
  };

  setModel = (model: Object) => {
    const { filterChangedCallback } = this.props;
    this.getState(model).then(s => this.setState(s, filterChangedCallback));
  };

  componentDidUpdate(prevProps: Props, prevState: StateProps) {
    if (prevProps.values !== this.props.values) {
      this.getState().then(s => this.setState(s))
    }
  }

  isFilterActive() {
    return this.state.isFilterActive;
  }

  doesFilterPass = (params: Object): boolean => {
    const { isFilterActive, selected } = this.state;
    if (!isFilterActive) return true;
    const val = this.getValue(params);
    return selected.indexOf(`${val}`) >= 0;
  };

  getList(): Array<Object> {
    const { values = [] } = this.props;
    if (!Array.isArray(values)) {
      console.error(`values should be an array of objects getting ${values}`);
      return [];
    }
    return values;
  }

  getModelAsString(model: any) {
    if (!model || !model.values || model.values.length === 0) return '';
    const { selected } = this.state;
    const { values } = model;
    return `(${values.length}) ${values.join(', ')}`;
  }

  renderItem = (value: Object) => {
    const { selected } = this.state;
    const checked = (selected && selected.indexOf(value) >= 0) || false;
    return (
      <label className="ag-set-filter-item" onClick={this.toggleSelectItem(value)}>
        <Checkbox id={value} checked={checked} />
        <span className="ag-filter-value" style={{ whiteSpace: 'pre-wrap' }}>
          {value}
        </span>
      </label>
    );
  };

  onSearchInputChange = (e: Object) => {
    e.stopPropagation();
    e.preventDefault();
    const text = e.target.value;
    const { search, origList, selectAll, selected } = this.state;
    if (text === search) return;

    const filteredList = this.filterList(text, origList);
    const newSelected = (selectAll && filteredList) || [];
    this.setState(
      {
        search: text,
        list: filteredList,
        selected: newSelected,
        isFilterActive: newSelected.length !== this.allSelectedLength,
      },
      this.filterChangedCallback,
    );
  };

  filterList = (text: string, list: Array<string>): Array<string> => {
    const searchText = text.trim().toLowerCase();
    if (searchText === '') {
      return list;
    }
    return list.filter(value => value.toString().toLowerCase().includes(searchText));
  };

  toggleSelectAll = (e: Object) => {
    e.stopPropagation();
    e.preventDefault();
    this.setState(state => {
      const selectAll = !state.selectAll;
      const isFilterActive = !selectAll || state.search !== '';
      const selected = selectAll ? state.list : [];
      return {
        ...state,
        selectAll,
        selected,
        isFilterActive,
      };
    }, this.filterChangedCallback);
  };

  toggleSelectItem = (value: string) => (e: Object) => {
    e.stopPropagation();
    e.preventDefault();
    const { selected } = this.state;
    const newSelected = selected.slice();
    const index = selected && selected.indexOf(value);
    if (index >= 0) {
      newSelected.splice(index, 1);
    } else {
      newSelected.push(value);
      newSelected.sort();
    }

    const isFilterActive = newSelected.length !== this.allSelectedLength;
    this.setState({ selected: newSelected, isFilterActive }, this.filterChangedCallback);
  };

  clearFilter = (e: Object) => {
    e.stopPropagation();
    e.preventDefault();

    const { filterChangedCallback } = this.props;
    this.setState(
      state => ({
        list: state.origList,
        search: '',
        selectAll: true,
        selected: state.origList,
        isFilterActive: false,
      }),
      filterChangedCallback,
    );
  };

  applyFilter = (e: Object) => {
    e.stopPropagation();
    e.preventDefault();

    const { filterChangedCallback } = this.props;
    filterChangedCallback();
    this.hidePopup();
  };

  afterGuiAttached = () => {
    const { api } = this.props;
    const filtersModel = api.getFilterModel();
    const model = (filtersModel && filtersModel[this.colId]) || null;
    this.getState(model).then(s => {
      this.setState(s)
    });
  };

  onFloatingFilterChanged(params: Object = {}) {
    if (params.model === null) this.clearFilter();
  }

  render() {
    const { suppressSearch, clearButton, applyButton } = this.props;
    const { isLoading, list, selectAll, search } = this.state;
    if (isLoading) {
      return <div>Loading...</div>
    }
    return (
      <div className="ag-filter-body-wrapper ag-filter-body-wrapper-custom">
        {!suppressSearch && (
          <div className="ag-input-text-wrapper ag-filter-header-container" id="ag-mini-filter">
            <SearchInput value={search} onChange={this.onSearchInputChange} />
          </div>
        )}
        <div className="ag-filter-header-container">
          <label id="selectAllContainer" onClick={this.toggleSelectAll}>
            <Checkbox id="selectAll" checked={selectAll} />
            <span className="ag-filter-value">
              (<FormattedMessage {...messages.select_all} />)
            </span>
          </label>
        </div>
        <div className="ag-set-filter-list" id="richList">
          <div className="ag-virtual-list-viewport">
            {list.map((value, index) => (
              <div key={`${value}-${uuid()}`}>{this.renderItem(value)}</div>
            ))}
          </div>
        </div>
        {(clearButton || applyButton) && (
          <div className="ag-filter-apply-panel" id="applyPanel">
            {clearButton && (
              <button type="button" id="clearButton" onClick={this.clearFilter}>
                <FormattedMessage {...messages.clear_filter} />
              </button>
            )}
            {applyButton && (
              <button type="button" id="applyButton" onClick={this.applyFilter}>
                <FormattedMessage {...messages.apply_filter} />
              </button>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default SetFilter;
