import React from 'react';

import { formatDateToApiFormat } from 'utils/api';
import { ApiSmartProdSourceDTO } from 'types/drep-backend';
import CustomScheduleDialog, { SchedulingDTO } from 'containers/PlanInterfaces/CustomScheduleDialog';
import messages from './messages';

type Props = {
  associations?: ApiSmartProdSourceDTO;
  changeRow: (association: ApiSmartProdSourceDTO, change: ApiSmartProdSourceDTO) => void;
  smartProdInstanceLabel?: string;
  WHIDLabel?: string;
  openSmartProdModalCallback: Function;
  smartProdModal: any;
};

const SmartProdScheduleDialog: React.FC<Props> = ({
  associations,
  changeRow,
  smartProdInstanceLabel,
  WHIDLabel,
  openSmartProdModalCallback,
  smartProdModal,
}) => {
  const headerLabel = {
    ...messages.scheduleDialogHeaderLabel,
    values: { smartProdInstance: smartProdInstanceLabel, smartProdWarehouseId: WHIDLabel },
  };
  const scheduling: SchedulingDTO = associations ? {
    scheduleDateTime: associations.scheduleStartDate,
    schedulePeriod: associations.schedulePeriod,
  } : null;
  const customChangedRow = (changed: SchedulingDTO) => {
    changeRow(associations, {
      ...associations,
      scheduleStartDate: formatDateToApiFormat(changed.scheduleDateTime),
      schedulePeriod: changed.schedulePeriod,
    });
  };
  return (
    <CustomScheduleDialog
      smartProdModal={smartProdModal}
      scheduling={scheduling}
      changeRow={customChangedRow}
      headerLabel={headerLabel}
      openSmartProdModalCallback={openSmartProdModalCallback}
    />
  );
};

export default SmartProdScheduleDialog;
