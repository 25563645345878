import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Dropzone from 'react-dropzone';

import { FormattedMessage } from 'react-intl';
import messages from './messages';

const getColor = props => {
  if (props.isDragReject) {
    return '#c66';
  }
  if (props.isDragActive) {
    return '#6c6';
  }
  if (props.disabled) {
    return '#eee';
  }
  return '#666';
};

const DropContainer = styled.div`
  height: 100px;
  border-width: 2px;
  border-radius: 5px;
  border-color: ${props => getColor(props)};
  border-style: ${props => (props.isDragReject || props.isDragActive ? 'solid' : 'dashed')};
  background-color: ${props => (props.isDragReject || props.isDragActive ? '#eee' : '')};
  color: ${props => (props.disabled && '#eee') || '#000'};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 300px;
`;

const Title = styled.div`
  color: ${props => (props.disabled && '#ccc') || '#000'};
`;

const DragMessageWrapper = styled.div``;

class NewUploadMul extends React.PureComponent {

  render() {
    return (
      <Title {...this.props}>
        {this.props.fileName ? (
          <FormattedMessage {...messages.fileReady} values={{ name: this.props.fileName }} />
        ) : (
          <FormattedMessage {...messages.selectFile} />
        )}
        {this.renderDropZone()}
      </Title>
    );
  }

  renderDropZone() {
    return (
      <Dropzone
        disabled={this.props.disabled || false}
        accept="application/json"
        onDrop={this.props.onDrop}
      >
        {({ getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject }) => (
          <DropContainer
            isDragActive={isDragActive}
            isDragReject={isDragReject}
            {...getRootProps()}
            disabled={this.props.disabled || false}
          >
            <input {...getInputProps()} />
            <DragMessageWrapper>
              <FormattedMessage {...this.getMessage(isDragActive, isDragAccept, isDragReject)} />
            </DragMessageWrapper>
          </DropContainer>
        )}
      </Dropzone>
    );
  }

  getMessage(isDragActive, isDragAccept, isDragReject) {
    if (isDragReject) {
      return messages.rejectedFile;
    }
    return isDragAccept ? messages.dropFile : messages.dragFile;
  }
}

NewUploadMul.propTypes = {
  onDrop: PropTypes.func,
  fileName: PropTypes.string,
};

export default NewUploadMul;
