import * as React from 'react';
import { Component } from 'react';
import { Form, Formik, FormikProps } from 'formik';
import { isEqual } from 'lodash';
import { DateTime } from 'luxon';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Prompt } from 'react-router';
import { bindActionCreators, compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';

import ToggleSection from 'components/ToggleSection';
import AutocompleteSelect from 'components/AutocompleteSelect';
import BasePage from 'components/BasePage';
import { ComponentWithDirtyCheck } from 'components/ButtonWithDirtyCheck';
import DatePicker from 'components/DatePicker';
import { DetailPageSectionsWrapper, DetailToolbarButtonsGroup } from 'components/DetailPageShared';
import { FormikText, InlineLabel, InputError, WarningText } from 'components/FormikTextInput';
import IconButton, { AddButton, SaveButton } from 'components/IconButton';
import NamedSection from 'components/NamedSection';
import { ButtonWrap } from 'components/ResultToolBar';
import { PATHS } from 'containers/App/constants';
import { formatDateToApiFormat } from 'utils/api';
import EnchantedMap from 'utils/enchantedMap';
import injectSaga from 'utils/injectSaga';
import { withScrollWatch } from 'utils/scroll';
import withSecurity, { PERMISSIONS } from 'utils/security';
import { ApiScheduleDTO } from 'types/drep-backend.d';
import { formatDateTime } from 'utils/dateTime';

import Label from '../../components/Label';
import { ItemSelectable } from '../../components/Menu/ItemSelectable';
import { Title } from '../ShiftScheduleDetailPage/toolbar';
import { convertArrayToMapWithReduce } from '../ShiftScheduleDetailPage/utils';
import {
  addScheduleRun,
  loadShiftSchedule,
  loadShiftScheduleRuns,
  pullShiftScheduleRunChanges,
  saveScheduleRuns,
  loadStaffSyncState
} from './actions';
import { granularities } from './constants';
import messages from './messages';
import saga from './saga';
import ScheduleRunsTable from './scheduleRunsTable';
import {
  selectPullingChanges,
  selectShiftSchedule,
  selectShiftScheduleName,
  selectShiftScheduleRuns,
  selectScheduleStaffSyncState,
} from './selectors';
import {
  getStartEndDateInputCacheForScheduleId,
  isStartEndDateEntryValid,
  saveStartEndDateInputsForScheduleId,
} from './startEndDateInputCache';
import { ShiftScheduleRun, ShiftScheduleRunsForm, ShiftScheduleRunsState } from './types';
import { withConfirmDialog } from './withConfirmDialog';

const FullWidthSection = styled(NamedSection)`
  min-width: 1170px;
  margin-top: 0px;
`;

const GridRow = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: 2fr 3fr 2fr 2fr 2fr 2fr 2fr 2fr 100px 3fr 100px;
  margin: 0 0 12px;
  column-gap: 10px;
  input {
    background-color: ${props => props.theme.color.grey5};
  }
`;

const CheckBox = styled(ItemSelectable)`
  justify-self: end;
  width: inherit;
  :hover {
    cursor: pointer;
  }
`;

const FullWidthToggleSection = styled(ToggleSection)`
  min-width: 1170px;
`;

const LongerInlineLabel = styled(Label)`
  display: inline-block;
`;

const GridRowBasicInformationFacilities = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: 1fr 1fr;
  margin: 0 0 12px;
  column-gap: 20px;
  justify-items: stretch;
  width: 100%;
  input {
    background-color: ${props => props.theme.color.grey5};
  }
`;

const GridRowLabelValue = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: 1fr 2fr;
  margin: 0 0 12px;
  column-gap: 10px;
  input {
    background-color: ${props => props.theme.color.grey5};
  }
`;

const GridRowKeyValue = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: 100px 20px 100px;
  margin: 0 0 12px;
  column-gap: 10px;
  input {
    background-color: ${props => props.theme.color.grey5};
  }
`;

const BigSection = styled(NamedSection)`
  min-width: 575px;
  input {
    background-color: ${props => props.theme.color.grey5};
  }
`;

const DateWrap = styled.div``;

interface ShiftScheduleRunsPageProps extends ShiftScheduleRunsForm {
  shiftSchedule: ApiScheduleDTO;
  history: any;
  intl: any;
  dispatch: any;
  match: any;
  openConfirmDialog: (any1, any2) => any;
  addScheduleRun(run: ShiftScheduleRun): () => any;
  loadShiftSchedule(action: any): () => any;
  loadShiftScheduleRuns(scheduleId: number): () => any;
  loadStaffSyncState(action: any): () => any;
  pullShiftScheduleRunChanges(payload: any): () => any;
  save(values: any): () => any;
  scheduleStaffSyncState: any;
}

const parameterSetOptions = parameterSets =>
  parameterSets?.map(set => ({
    id: set.id,
    isDefault: set.default,
    name: set.name,
  }));

class ShiftScheduleRunsPage extends Component<ShiftScheduleRunsPageProps, ShiftScheduleRunsState> {
  public state: ShiftScheduleRunsState = {
    endDate: null,
    granularity: granularities[0],
    name: '',
    nameError: false,
    parameterSet: (parameterSetOptions(this.props.shiftSchedule.parameterSets) || []).find(
      item => item.isDefault === true,
    ),
    parameterSetError: false,
    sendDirectly: false,
    startDate: null,
  };

  private formik = null;

  private staffPullingJob = null;

  private userChanges = new EnchantedMap<number, any>();

  private pullingJob = null;

  public shouldComponentUpdate(
    nextProps: Readonly<ShiftScheduleRunsPageProps>,
    nextState: Readonly<ShiftScheduleRunsState>,
    nextContext: any,
  ): boolean {
    return (
      !isEqual(this.state, nextState) ||
      !isEqual(this.props.shiftScheduleRuns, nextProps.shiftScheduleRuns) ||
      !isEqual(this.props.shiftSchedule, nextProps.shiftSchedule) ||
      !isEqual(this.props?.scheduleStaffSyncState?.isRunning, nextProps?.scheduleStaffSyncState?.isRunning) ||
      this.props.shiftScheduleName !== nextProps.shiftScheduleName
    );
  }

  public componentDidMount() {
    this.initStaffSyncPullingJob()
    this.props.loadShiftSchedule(this.props.match.params.id);
    this.props.loadShiftScheduleRuns(this.props.match.params.id);
    this.setStateOfStartEndDates();

    this.pullingJob = setInterval(() => {
      const runIds = this.props.shiftScheduleRuns.filter(r => r.status.shouldPullChanges()).map(r => r.id);

      if (runIds.length > 0) {
        this.props.pullShiftScheduleRunChanges({
          runIds,
          scheduleId: this.props.match.params.id,
        });
      }
    }, 5000);
  }

  private initStaffSyncPullingJob() {
    const staffSyncStatePullingJob = () => {
      this.props.loadStaffSyncState(this.props.match.params.id);
    };

    staffSyncStatePullingJob();
    this.staffPullingJob = setInterval(staffSyncStatePullingJob, 4000);
  }

  public componentWillUnmount() {
    clearInterval(this.pullingJob);
    clearInterval(this.staffPullingJob);
  }

  public componentDidUpdate = (prevProps, prevState) => {
    if ((!prevState.startDate && !prevState.endDate) || prevProps.shiftScheduleRuns !== this.props.shiftScheduleRuns) {
      this.setStateOfStartEndDates();
    }
    if (this.props?.scheduleStaffSyncState?.isRunning === false) {
      clearInterval(this.staffPullingJob);
    }
  };

  public setStateOfStartEndDates = () => {
    const firstRunRaw = this.props.shiftScheduleRuns?.[0];
    let firstRunDates;
    if (firstRunRaw) {
      firstRunDates = {
        startDate: DateTime.fromISO(firstRunRaw.startDate),
        endDate: DateTime.fromISO(firstRunRaw.endDate),
      };
    }
    const startEndDayLastUserInput = getStartEndDateInputCacheForScheduleId(this.props.match.params.id);
    let startDate = null;
    let endDate = null;
    if (startEndDayLastUserInput) {
      startDate = startEndDayLastUserInput.startDate;
      endDate = startEndDayLastUserInput.endDate;
    } else if (firstRunDates && isStartEndDateEntryValid(firstRunDates)) {
      startDate = firstRunDates.startDate;
      endDate = firstRunDates.endDate;
    } else {
      startDate = DateTime.local();
      endDate = DateTime.local();
    }
    this.setState(state => ({ ...state, startDate, endDate, name: '' }));
  };

  public render() {
    if (!Array.isArray(this.props.shiftScheduleRuns)) {
      return (
        <BasePage labelMessage={messages.header} labelValues={{ name: '' }}>
          Loading...
        </BasePage>
      );
    }

    const doSubmit = (values, actions) => {
      const modified = [];
      this.userChanges.forEach((value, key) => {
        if (Object.keys(value).length > 0) {
          modified.push({
            ...value,
            endDate: formatDateToApiFormat(value.endDate),
            id: key,
            startDate: formatDateToApiFormat(value.startDate),
          });
        }
      });
      this.userChanges.clear();
      this.props.save({
        runs: modified,
        scheduleId: this.props.match.params.id,
      });
      actions.setSubmitting(false);
    };

    const initialRuns = this.props.shiftScheduleRuns.map(i => ({
      ...i,
      endDate: this.userChanges.get(i.id)?.endDate || i.endDate,
      granularity: this.userChanges.get(i.id)?.granularity || i.granularity,
      name: this.userChanges.get(i.id)?.name || i.name,
      startDate: this.userChanges.get(i.id)?.startDate || i.startDate,
    }));

    const initial = {
      shiftScheduleName: this.props.shiftScheduleName,
      shiftScheduleRuns: initialRuns,
    };

    return (
      <BasePage labelMessage={messages.header} labelValues={{ name: this.props.shiftSchedule?.name }} noMaxWidth>
        <Formik
          validateOnChange
          onSubmit={doSubmit}
          enableReinitialize
          initialValues={initial}
          render={this.renderForm}
        />
      </BasePage>
    );
  }

  private renderToolbar = (formik, fixed) => {
    const changesCount = this.userChanges.listValues().filter(v => Object.keys(v).length > 0).length;
    const goBack = () => this.props.history.push(PATHS.shiftScheduleDetailId.replace(':id', this.props.match.params.id));
    const goToPowerBi = () =>
      this.props.history.push(PATHS.shiftScheduleRunsPowerBi.replace(':id', this.props.match.params.id));

    return (
      <ButtonWrap fixed={fixed}>
        <IconButton id="back" onClick={goBack} icon="arrow-circle-left" tooltip={messages.back} />
        {fixed && <Title>{this.props.shiftSchedule?.name}</Title>}
        <DetailToolbarButtonsGroup>
          <ComponentWithDirtyCheck actionHandler={goToPowerBi}>
            {({ onClickHandler }) => (
              <IconButton
                id="powerBiGraph"
                label={messages.graph}
                tooltip={{ ...messages.graph }}
                onClick={onClickHandler}
              />
            )}
          </ComponentWithDirtyCheck>
          <SaveButton onClick={formik.submitForm} disabled={changesCount === 0} />
        </DetailToolbarButtonsGroup>
      </ButtonWrap>
    );
  };

  private renderFacilities = facilities => (
    <>
      {/* tslint:disable-next-line:jsx-no-multiline-js jsx-wrap-multiline */}
      {facilities?.map((facility, index) => (
        <GridRowKeyValue key={index}>
          <FormikText>{facility.code}</FormikText>
          <span key="space" />
          <FormikText>{facility.name}</FormikText>
        </GridRowKeyValue>
      ))}
    </>
  );

  private renderForm = (formik: FormikProps<ShiftScheduleRunsForm>) => {
    const parameterSetSetter = value => {
      this.setState({ parameterSet: value, parameterSetError: false });
    };
    this.formik = formik;
    const {
      match,
      intl: { formatMessage },
    } = this.props;
    const timespanSetter = value => this.setState({ granularity: value });
    const nameSetter = event => this.setState({ name: event.target.value, nameError: false });
    const startSetter = newStartValue => {
      const update = { startDate: newStartValue, endDate: this.state.endDate };
      if (newStartValue.startOf('day') > this.state.endDate.startOf('day')) {
        update.endDate = newStartValue;
      }
      this.setState(update);
      saveStartEndDateInputsForScheduleId(match.params.id, update.startDate, update.endDate);
    };
    const endSetter = newEndValue => {
      const update = { endDate: newEndValue, startDate: this.state.startDate };
      if (newEndValue.startOf('day') < this.state.startDate.startOf('day')) {
        update.startDate = newEndValue;
      }
      this.setState(update);
      saveStartEndDateInputsForScheduleId(match.params.id, update.startDate, update.endDate);
    };
    const sendDirectlyToggle = () => this.setState(state => ({ sendDirectly: !state.sendDirectly }));
    const doSend = () => {
      this.props.addScheduleRun({
        endDate: formatDateToApiFormat(this.state.endDate),
        granularity: this.state.granularity.id,
        name: this.state.name,
        parameterSetId: this.state.parameterSet.id,
        plans: [],
        scheduleId: match.params.id,
        sendDirectly: this.state.sendDirectly,
        startDate: formatDateToApiFormat(this.state.startDate),
      });
      this.setState({ parameterSet: null });
    };

    // TODO rewrite
    const activitiesNotInKronosExist = false;
    // this.props.shiftSchedule.activities.length > 0;
    // this.props.shiftSchedule.activities?.filter(a => a.existsInKronos === 'NO').length > 0;

    const addButtonHandler = () => {
      if (!this.state.name) {
        this.setState({ nameError: true });
      }
      if (!this.state.parameterSet) {
        this.state.parameterSet = (parameterSetOptions(this.props.shiftSchedule.parameterSets) || []).find(
          item => item.isDefault === true,
        );
      }
      if (!this.state.name || !this.state.parameterSet) {
        return;
      }
      if (this.state.sendDirectly && activitiesNotInKronosExist) {
        this.props.openConfirmDialog(doSend, formatMessage(messages.pinkActivities));
      } else {
        doSend();
      }
    };
    const Toolbar = withScrollWatch(100)(({ fixed }) => this.renderToolbar(formik, fixed));
    const daysRestricted = this.state.granularity?.id == 'HOUR' ? this.props?.shiftSchedule?.dateLimitHourGranularity - 1 : this.props?.shiftSchedule?.dateLimitQuarterGranularity - 1 ;
    const checkError = () => {
      if(!this.props.scheduleStaffSyncState?.isRunning && this.props.scheduleStaffSyncState?.messages){
        return messages.warningAvailable
      }else if(this.props.scheduleStaffSyncState?.isRunning){
        return messages.warningAvailable
      }else{
        return ''
      }
    }
    const addRunFlag = ((!this.props.scheduleStaffSyncState?.isRunning && this.props.scheduleStaffSyncState?.messages) || this.props.scheduleStaffSyncState?.isRunning) ? true : false;

    const errorMessageParser = str => {
      if (str.indexOf('::') > -1) {
        const tokens = str.split('::');
        const facilityName = this.props.shiftSchedule?.facilities?.filter((fac)=>fac.id == tokens[0]);
        const facilityPart = `${this.props.intl.formatMessage(messages.facility)} ${facilityName && facilityName[0] && facilityName[0].name}: `;
        const detailPart = `${this.props.intl.formatMessage(messages[`syncError_${tokens[1]}`], { param1: tokens[2] })}`;

        return facilityPart + detailPart;
      }
      return str;
    };
    const errorMessage =  this.props.scheduleStaffSyncState?.messages?.split(';;').map(errorMessageParser);

    return (
      <Form>
        <Toolbar />
        <Prompt when={formik.dirty} message={formatMessage(messages.confirmDirty)} />
        <DetailPageSectionsWrapper>
          <GridRowBasicInformationFacilities>
            <BigSection message={messages.basicInfo} noMaxWidth>
              <GridRowLabelValue>
                <InlineLabel {...messages.basicInformationName} />
                <FormikText>{this.props.shiftSchedule.name}</FormikText>
              </GridRowLabelValue>
              <GridRowLabelValue>
                <InlineLabel {...messages.basicInformationDescription} />
                <FormikText>{this.props.shiftSchedule.description}</FormikText>
              </GridRowLabelValue>
              <GridRowLabelValue>
                <InlineLabel {...messages.lastUpdate} />
                <FormikText>{formatDateTime(this.props.shiftSchedule.lastUpdate)}</FormikText>
              </GridRowLabelValue>
            </BigSection>
            <BigSection message={messages.facilities} noMaxWidth>
              {this.renderFacilities(this.props.shiftSchedule.facilities)}
            </BigSection>
          </GridRowBasicInformationFacilities>
        </DetailPageSectionsWrapper>
        <FullWidthToggleSection
           message={messages.warnings}
           subtitleToShow={checkError()}
         >
            {
              !this.props.scheduleStaffSyncState?.isRunning && this.props.scheduleStaffSyncState?.messages 
                ? 
                <WarningText>{errorMessage && errorMessage[0]}</WarningText>
                :
                this.props.scheduleStaffSyncState?.isRunning ? <WarningText><FormattedMessage {...messages.syncInProgress} /></WarningText>
                :
                <WarningText><FormattedMessage {...messages.noWarningAvailable} /></WarningText>
            }
         </FullWidthToggleSection>

        <FullWidthSection message={messages.tableHeader} noMaxWidth>
          <GridRow>
            <InlineLabel {...messages.name} />
            <FormikText key="name">
              <input onChange={nameSetter} value={this.state.name} />
              {this.state.nameError && <InputError>{formatMessage(messages.nameRequired)}</InputError>}
            </FormikText>

            <InlineLabel {...messages.startDate} />
            <DateWrap>
              <DatePicker
                timePicker={false}
                value={this.state.startDate}
                minDate={DateTime.local()}
                onChange={startSetter}
              />
            </DateWrap>

            <InlineLabel {...messages.endDate} />
            <DateWrap>
              <DatePicker
                timePicker={false}
                value={this.state.endDate}
                onChange={endSetter}
                minDate={DateTime.local()}
                maxDate={this.state.startDate?.plus({ days : daysRestricted || 0})}
              />
            </DateWrap>

            <InlineLabel {...messages.timespan} />
            <DateWrap>
              <AutocompleteSelect
                preloadedData={granularities}
                value={this.state.granularity}
                setValue={timespanSetter}
              />
            </DateWrap>

            <CheckBox isSelected={this.state.sendDirectly} onClick={sendDirectlyToggle} />
            <LongerInlineLabel {...messages.sendDirectly} />

            <AddButton key="add-button" disabled={formik.isSubmitting || addRunFlag} onClick={addButtonHandler} />
          </GridRow>
          <GridRow>
            <InlineLabel {...messages.parameterSet} />
            <div>
              <AutocompleteSelect
                key="parameter-set-select"
                preloadedData={parameterSetOptions(this.props.shiftSchedule.parameterSets)}
                value={this.getValue()}
                setValue={parameterSetSetter}
              />
              {this.state.parameterSetError && <InputError>{formatMessage(messages.parameterSetRequired)}</InputError>}
            </div>
          </GridRow>

          <ScheduleRunsTable
            key="table"
            // @ts-ignore
            userChanges={this.userChanges}
            formik={formik}
            scheduleId={match.params.id}
            activitiesNotInKronosExist={activitiesNotInKronosExist}
            history={this.props.history}
            dateLimitHourGranularity = {this.props?.shiftSchedule?.dateLimitHourGranularity}
            dateLimitQuarterGranularity = {this.props?.shiftSchedule?.dateLimitQuarterGranularity}
          />
        </FullWidthSection>
      </Form>
    );
  };

  private getValue() {
    const sets = parameterSetOptions(this.props.shiftSchedule.parameterSets) || [];
    if (this.state.parameterSet === undefined || this.state.parameterSet == null) {
      return sets.find(item => item.isDefault === true);
    }
    return sets.find(item => item.id === this.state.parameterSet.id);
  }
}

const mapStateToProps = createStructuredSelector({
  pullingChanges: selectPullingChanges,
  shiftSchedule: selectShiftSchedule,
  shiftScheduleName: selectShiftScheduleName,
  shiftScheduleRuns: selectShiftScheduleRuns,
  scheduleStaffSyncState: selectScheduleStaffSyncState,
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators(
      {
        addScheduleRun,
        loadShiftSchedule,
        loadShiftScheduleRuns,
        pullShiftScheduleRunChanges,
        loadStaffSyncState,
        save: saveScheduleRuns,
      },
      dispatch,
    ),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withSaga = injectSaga({ key: 'shiftScheduleRunsPage', saga });

export default compose(
  injectIntl,
  withConnect,
  withSecurity(PERMISSIONS.VIEW_SCHEDULES),
  withSaga,
  withConfirmDialog,
  // @ts-ignore
)(ShiftScheduleRunsPage);
