/*
 *
 * TableControlled actions
 *
 */

import {
  REGISTER_TABLE,
  SET_TABLE_CONFIG,
  UNREGISTER_TABLE,
  SAVE_CONFIG_TO_FAV,
  LOAD_CONFIG_FROM_FAV,
  LOAD_DEFAULTS,
  LOAD_USER,
} from './constants';

export function loadUserAction() {
  return {
    type: LOAD_USER,
  };
}
export function loadDefaultsAction() {
  return {
    type: LOAD_DEFAULTS,
  };
}

export function registerTableAction(tableName, def) {
  return {
    type: REGISTER_TABLE,
    tableName,
    def,
  };
}

export function setTableConfigAction(tableName, config) {
  return {
    type: SET_TABLE_CONFIG,
    tableName,
    config,
  };
}

export function unregisterTableAction(tableName) {
  return {
    type: UNREGISTER_TABLE,
    tableName,
  };
}

export function saveConfigToFavAction() {
  return {
    type: SAVE_CONFIG_TO_FAV,
  };
}

export function loadConfigFromFavAction(tableName, def) {
  return {
    type: LOAD_CONFIG_FROM_FAV,
    tableName,
    def,
  };
}
