// @flow
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import UIButton from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import IconButton from '@material-ui/core/IconButton';
import UIMenu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { FormattedMessage } from 'react-intl';
import { withTheme } from 'styled-components';

import { useStyles } from './styled';

type Item = {
  value: string,
  name: string,
  icon?: string | React$Node,
  url?: string,
};

type Props = {
  id?: string,
  theme: Object,
  header?: string,
  headerIcon?: string | React$Node,
  items: Array<string | Item>,
  translate?: boolean,
  classes?: Object,
  onChange?: Function,
  isIcon?: boolean,
  fancy?: boolean,
};

type IconProps = {
  icon: string | React$Node,
  className?: string,
};

export function Icon(props: IconProps) {
  const { className = '', icon } = props;
  return typeof icon === 'string' ? <FontAwesomeIcon icon={icon} size="lg" className={`${className}`} /> : icon;
}

type ButtonProps = {
  id?: string,
  theme: Object,
  title?: string,
  icon?: string | React$Node,
  translate?: boolean,
  onClick: Function,
  isIcon?: boolean,
  fancy?: boolean,
};

function Btn(props: ButtonProps) {
  const { id, onClick, isIcon = false, translate = false, icon, theme, title, fancy = false } = props;
  const MenuEl = (isIcon && IconButton) || (fancy && Fab) || UIButton;
  const classes = useStyles(theme)();
  return (
    <MenuEl id={id} color="primary" onClick={onClick} className={(fancy && classes.fab) || classes.root}>
      {icon && <Icon icon={icon} className={classes.buttonIcon} />}
      {(translate && typeof title === 'object' && <FormattedMessage {...title} />) || <span>{title}</span>}
    </MenuEl>
  );
}

export const Button = withTheme(Btn);

function Menu(props: Props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const { id, onChange, isIcon = false, headerIcon, header, items, translate = false, theme, fancy = false } = props;
  const classes = useStyles(theme)();

  const handleChange = (value: string) => (event: Object) => {
    onChange && onChange(value);
    setAnchorEl(null);
  };

  function handleMenu(event: Object) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function renderItem(item) {
    if (typeof item === 'string') {
      return (
        <MenuItem id={item} className={classes.item} onClick={handleChange(item)} key={item} value={item}>
          {item}
        </MenuItem>
      );
    }
    const { value, name, icon, url, onClick } = item;
    const Item = (
      <MenuItem className={classes.item} onClick={onClick || handleChange(value)} key={value} value={value}>
        {icon && <Icon icon={icon} className={classes.itemIcon} />}
        {name}
      </MenuItem>
    );
    return (
      (url && (
        <a href={url} id={item.value} key={value} target="_blank" className={classes.link}>
          {Item}
        </a>
      )) ||
      Item
    );
  }

  return (
    <>
      <Button
        id={id}
        fancy={fancy}
        onClick={handleMenu}
        isIcon={isIcon}
        translate={translate}
        title={header}
        icon={headerIcon}
      />
      <UIMenu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
      >
        {items.map((item, index) => renderItem(item))}
      </UIMenu>
    </>
  );
}

export default withTheme(Menu);