import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { EditableListItemWrapper } from '../DetailPageShared';
import messages from './messages';
import Label from '../Label';
import AutocompleteSelect from '../AutocompleteSelect';

const ItemWrapper = styled(EditableListItemWrapper)`
  grid-template-columns: 10em auto;
`;

const WideLabel = styled(Label)`
  grid-column-end: span 2;
`;

const WideCell = styled.div`
  grid-column-end: span 2;
`;

const List = styled.ul`
  margin: 2px;
`;

export function FacilityItemNoUnits(props) {
  return FacilityItem({
    ...props,
    hideUnits: true,
  });
}

export function FacilityItem(props) {
  function renderMuDd() {
    const mKey = props.item && props.item.managementUnits && props.item.managementUnits && props.item.managementUnits.length;
    return (
      <WideCell key={mKey}>
        <AutocompleteSelect
          entity="managementUnits"
          getOptionLabel={option => `${option.code} : ${option.description}`}
          setValue={value => {
            if (props.onChange) {
              props.onChange(value, 'managementUnits');
            }
          }}
          urlAppendixCallback={() => `&facilityId=${props.item.id}`}
          isMulti
          token={props.token}
          initialValue={props.item.managementUnits}
        />
      </WideCell>
    );
  }

  function renderMus() {
    if (props.item && props.item.managementUnits && props.item.managementUnits) {
      return (
        <WideCell key="Mus">
          <List>
            {props.item.managementUnits.map(mu => (
              <li key={mu.id}>{mu.description}</li>
            ))}
          </List>
        </WideCell>
      );
    }
    return <div />;
  }

  const unit = [];
  if (!props.hideUnits) {
    unit.push(<WideLabel key="managementUnit" {...messages.managementUnit} />);
    unit.push(props.editable ? renderMuDd() : renderMus());
  }

  return (
    <ItemWrapper dark>
      <Label {...messages.code} />
      <div>{props.item && props.item.code}</div>
      <Label {...messages.name} />
      <div>{props.item && props.item.name}</div>
      {unit}
      {props.children}
    </ItemWrapper>
  );
}

FacilityItem.propTypes = {
  item: PropTypes.object,
  children: PropTypes.node,
  editable: PropTypes.bool,
  hideUnits: PropTypes.bool,
  token: PropTypes.string,
  onChange: PropTypes.func,
};
