import { Period, PlanningParameters, Shift } from 'types/formik-common';

import { LOG_SHIFTS } from './logger';

export type RemovedDaysInfo = {
  shift: Shift;
  removedDays: string[];
};

/*
  Return object with shift and days
 */
export const getRemovedShiftDays = (originalShifts: Shift[], newShifts: Shift[]): RemovedDaysInfo[] => {
  const result = [];
  originalShifts.forEach(shift => {
    const newShift = newShifts?.find(ns => ns.id === shift.id);
    if (newShift) {
      const removedDays = shift.days.filter(day => !newShift.days.includes(day));
      if (removedDays?.length) {
        result.push({ shift, removedDays });
      }
    }
  });
  return result;
};

const sumWzpTransformation = (period: Period, removedInfo: RemovedDaysInfo): number => {
  let result = 0;
  period.activityParameters.forEach(activityParameter => {
    activityParameter.wzpTransitions
      .filter(it => it.wzpId === removedInfo.shift.id)
      .filter(it => removedInfo.removedDays.includes(it.dayOfWeek))
      .forEach(wzpTransition => {
        result += wzpTransition.transferRate;
      });
  });
  return result;
};

export const areSomeShiftDaysRemovedAndUsed = (originalPp: PlanningParameters, newPp: PlanningParameters): boolean => {
  let result = false;
  originalPp.periods.forEach((origPeriod, i) => {
    const removedShiftDays = getRemovedShiftDays(origPeriod.shifts, newPp.periods[i].shifts);
    if (removedShiftDays.length) {
      LOG_SHIFTS.d(`Detected removed shift days for period ${i}`, removedShiftDays);
      LOG_SHIFTS.d(origPeriod);
      removedShiftDays.forEach(removedInfo => {
        const effort = sumWzpTransformation(newPp.periods[i], removedInfo);
        if (effort > 0) {
          result = true;
          LOG_SHIFTS.d(`Found existing shift transformation ${effort} in period ${i} for`, removedInfo);
        }
      });
    }
  });
  return result;
};

export const areSomeShiftDaysRemovedAndUsedFlag = (originalPp: PlanningParameters, newPp: PlanningParameters): boolean => {
  let result = false;
  let removedShiftDays = []
  originalPp.periods.forEach((origPeriod, i) => {
    removedShiftDays = getRemovedShiftDays(origPeriod.shifts, newPp.periods[i].shifts);
    if (removedShiftDays.length) {
      LOG_SHIFTS.d(`Detected removed shift days for period ${i}`, removedShiftDays);
      LOG_SHIFTS.d(origPeriod);
      removedShiftDays.forEach(removedInfo => {
        const effort = sumWzpTransformation(newPp.periods[i], removedInfo);
        if (effort > 0) {
          result = true;
          LOG_SHIFTS.d(`Found existing shift transformation ${effort} in period ${i} for`, removedInfo);
        }
      });
    }
  });
  return  removedShiftDays.length > 0 ? true : false;
};
