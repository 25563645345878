// @Flow

import React from 'react';
import { PageSection, Section, Wrap } from 'containers/PlanResultPage';
import { Helmet } from 'react-helmet';
import { fromJS } from 'immutable';
import { dataToSearch, searchToData } from 'utils/url';
import { PATHS, TABLE_DEFAULTS } from 'containers/App/constants';
import Toolbar from 'components/Toolbar';
import { createStructuredSelector } from 'reselect';
import { selectPlan, selectEdit, selectPlanStatisticsJS } from 'containers/PlanResultPage/selectors';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { injectIntl } from 'react-intl';
import withSecurity, { PERMISSIONS } from 'utils/security';
import ResultBasicInfo from 'components/ResultBasicInfo';
import injectSaga from 'utils/injectSaga';

import { loadPlanAction, loadPlanStats } from 'containers/PlanResultPage/actions';
import saga from 'containers/PlanResultPage/saga';
import TableControlled from 'containers/TableControlled';

import styled from 'styled-components';
import { DateTime } from 'luxon';
import { ExportButton } from 'components/IconButton';
import { formatDate, formatTime } from 'utils/dateTime';
import messages from './messages';
import createColumnDefs, { mockData } from './columnDefs';

const StyledTable = styled(TableControlled)`
  height: ${props => props.height}px;
`;

const SectionTitle = styled.div`
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  ${ExportButton} {
    margin-left: auto;
    font-weight: normal;
  }
`;

type Props = {
  intl: Object,
  match: {
    params: {
      id: string,
    },
  },
  plan: Object,
  history: Object,
  isEdit: Boolean,
  loadPlan: Function,
};

type StateProps = {
  columnDefs: Object,
};

const CalcStatsToolBar = props => {
  const { planId, title, history, isEdit } = props;

  return (
    <Toolbar
      {...props}
      area={false}
      title={title}
      onBack={() => {
        const params = searchToData(history.location.search);
        props.history.push({
          pathname: PATHS.planResult,
          search: dataToSearch({ ...params, planId, isEdit }),
        });
      }}
      backAlwaysVisibile
    />
  );
};

function processCellCallback(params) {
  // excel export date formatted (not as millis)
  if (params.value && params.value instanceof DateTime) {
    return `${formatDate(params.value)} ${formatTime(params.value)}`;
  }
  return params.value;
}

class CalculationStatistics extends React.PureComponent<Props, StateProps> {
  state = { columnDefs: createColumnDefs(this.props.intl.formatMessage, messages) };

  constructor(props) {
    super(props);
    this.gridApi = null;
    this.state = { columnDefs: createColumnDefs(this.props.intl.formatMessage, messages) };
  }

  componentDidMount() {
    const { loadPlan, loadStatistics, match: { params: { id, paramsid } = {} } = {}, intl } = this.props;
    loadPlan({ planId: id }, intl, false);
    loadStatistics({ planningParametersId: paramsid });
  }

  onExport = () => {
    const {
      intl: { formatMessage },
      plan,
    } = this.props;
    const name = (plan && plan.name) || '';

    if (this.gridApi) {
      this.gridApi.api.exportDataAsExcel({
        exportMode: 'xlsx',
        columnGroups: true,
        fileName: formatMessage(messages.exportLabel, { name }),
        sheetName: formatMessage(messages.calculationStatistics),
        processCellCallback,
      });
    }
  };

  onGridReady = params => {
    this.gridApi = params;
    params.api.sizeColumnsToFit();
  };

  render() {
    const {
      intl: { formatMessage },
      plan,
      history,
      isEdit,
      statistics,
    } = this.props;

    const name = (plan && plan.name) || '';
    const title = formatMessage(messages.header, { name });
    const maxTableRows = 10;
    // const totalRows = mockData && mockData.length ? (mockData.length > maxTableRows ? maxTableRows : mockData.length) : 0;
    const totalRows =
      // eslint-disable-next-line no-nested-ternary
      statistics && statistics.length ? (statistics.length > maxTableRows ? maxTableRows : statistics.length) : 0;
    const aproxHeight = (totalRows || 1) * 30 + 80;

    return (
      <Wrap noMaxWidth labelMessage={messages.header} labelValues={{ name }}>
        <Helmet>
          <title>{title}</title>
          <meta name="description" content={formatMessage(messages.content)} />
        </Helmet>
        <CalcStatsToolBar {...this.props} planId={plan && plan.id} title={title} history={history} isEdit={isEdit} />
        <PageSection labelMessage={messages.basicInfo}>
          <ResultBasicInfo {...this.props} />
        </PageSection>
        <Section message={messages.calculationStatistics} expanded>
          <SectionTitle>
            <ExportButton onClick={this.onExport} />
          </SectionTitle>
          <StyledTable
            defaultConfig={TABLE_DEFAULTS.planCalculationStatisticsTableConfig}
            autosize
            name="planCalculationStatisticsTable"
            messages={messages}
            pagination
            columnDefs={this.state.columnDefs}
            rowData={statistics}
            showCOG={false}
            height={aproxHeight}
            onGridReady={this.onGridReady}
          />
        </Section>
      </Wrap>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  plan: selectPlan,
  isEdit: selectEdit,
  statistics: selectPlanStatisticsJS,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      loadPlan: loadPlanAction,
      loadStatistics: loadPlanStats,
    },
    dispatch,
  );
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

const withSaga = injectSaga({ key: 'planResultPage', saga });

export default compose(
  withSaga,
  withConnect,
  injectIntl,
  withSecurity([PERMISSIONS.VIEW_RESULT, PERMISSIONS.HISTORY_PLAN]),
)(CalculationStatistics);
