import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the shiftsGraph state domain
 */

const selectShiftsGraphDomain = state => state.get('shiftsGraph', initialState);

/**
 * Other specific selectors
 */

/**
 * Default selector used by ShiftsGraph
 */

const makeSelectShiftsGraph = () =>
  createSelector(
    selectShiftsGraphDomain,
    substate => substate.toJS(),
  );

export default makeSelectShiftsGraph;
export { selectShiftsGraphDomain };
