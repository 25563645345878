/*
 * FormikAutocomplete Messages
 *
 * This contains all the text for the FormikAutocomplete component.
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  placeholder: {
    id: 'app.components.AutocompleteSelect.placeholder',
    defaultMessage: 'Select...',
  },
});
