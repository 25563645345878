import * as React from 'react';
import { useEffect } from 'react';
import { FormikProps } from 'formik';
import { ColDef } from 'ag-grid-community';
import { FormattedMessage, injectIntl } from 'react-intl';
import styled from 'styled-components';
import hash from 'object-hash';
import { camelCase, startCase } from 'lodash';
import ButtonWithDirtyCheck from 'components/ButtonWithDirtyCheck';
import Select from 'components/StyledSelect';
import { DAY_NAMES } from 'utils/calendar/constants';

import messages from './messages';
import calendarMessages from 'utils/calendar/messages';
import { disableOffDays, sortByWeekStart } from './utils';
import { toNumber } from '../../utils/utils';
import injectReducer from 'utils/injectReducer';
import { parseDateAndFormatToLocalString } from 'utils/dateTime';
import { createStructuredSelector } from 'reselect';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import AgTable from 'components/Table';

import { DayTransformationButtonsWrap, DayTransformationToggleButtonStyled } from 'containers/DayToHourTransformation';
import { selectFirstday, selectOffDays, selectEditFromplan } from 'containers/PlanDetailPage/selectors';
import { selectEditFromPa, selectFirstdayPA, selectOffDaysPA } from 'containers/PlanningAreaDetailPage/selectors';
import makeSelectLabourAvailabilityOverview from './selector';
import { selectViewModeStoredData } from 'components/ViewModePopover/selectors';
import reducer from './reducer';
import { toggleDayAction } from './action';

export interface LabourAvailabilityOverviewProps {
  isEdit: boolean;
  intl: any;
  formik: FormikProps<any>;
  hasPerm: Function;
  save: Function;
  saveLabourOverView: Function;
  granularityWeek: Function;
  selectedDays: any;
  toggleDayAction: any;
  offDays: any;
  firstDay: any;
  edit: boolean;
  viewSettings: any
}

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 5px 0;
  & > * {
    margin: 0 5px;
  }
`;

const TableWithHeight = styled(AgTable)`
  height: ${props => props.height}px;

  .cell-editable{
    background-color: #3a74ce;
    color: #FFF;
  }

  .cell-overriddenHeadCount{
    font-weight: bold;
    color: ${props => props.theme.color.red};
  }

  .cell-editable.cell-overriddenHeadCount{
    font-weight: bold;
    color: ${props => props.theme.color.citrusOrange};
  }

  .cell-headCountEmpty{
    background-color: lightgray;
    border-right-color: coral;
  }
`;

const StyledSelect = styled(Select)`
  width: 200px;
`;

const LabourAvailabilityOverview = ((props: LabourAvailabilityOverviewProps) => {
  const { isEdit } = props;
  const storedGranularity =
    props.formik.initialValues?.planningParameters?.labourAvailabilityGranularity?.toLowerCase() || 'day';
  const [granularity, setGranularity] = React.useState(storedGranularity);
  const [columns, setColumns] = React.useState([]);

  useEffect(() => {
    disableOffDays(props.offDays, props.toggleDayAction)
  }, [])

  useEffect(() => {
    disableOffDays(props.offDays, props.toggleDayAction)
  }, [props.formik.values.planningParameters.labourAvailabilityGranularity])

  const submitGranularity = async ({ waitForSaveAll }) => {
    if (waitForSaveAll) {
      await props.formik.submitForm();
    }
    const id = props.formik.initialValues.planningParametersId ? props.formik.initialValues.planningParametersId : props.formik.initialValues.planningParameters.id;
    props.saveLabourOverView(props.formik.initialValues, id, granularity.toUpperCase());
  }

  const renderDaySwitchButtons = () => {
    const {
      intl: { formatMessage },
    } = props;
    const firstDayOfWeek = props.formik.values.planningParameters.firstDayOfWeek;
    return sortByWeekStart(DAY_NAMES, firstDayOfWeek).map(dayName => {
      const isDaySelected = props.selectedDays.includes(dayName);
      return (
        <DayTransformationToggleButtonStyled
          key={dayName}
          value={isDaySelected}
          onClick={() => { props.toggleDayAction(dayName) }}
        >
          {dayName && formatMessage(calendarMessages[dayName.toLowerCase()])}
        </DayTransformationToggleButtonStyled>
      );
    });
  }

  useEffect(() => {
    const columns = createColumnsDef(props.selectedDays, props.isEdit, props.viewSettings.checkboxRole);
    var result = columns.reduce((unique, o) => {
      if (!unique.some(obj => obj.colId === o.colId)) {
        unique.push(o);
      }
      return unique;
    }, []);
    setColumns(result)
  }, [props.selectedDays, props.isEdit, props.formik.values.labourOverViewData?.rows, props.formik.values.planningParameters.labourAvailabilityType, props.viewSettings.checkboxRole])

  const createColumnsDef = (selectedDays, edit, role) => {
    const colDefs: ColDef[] = [
      {
        colId: 'shiftName',
        field: 'shiftName',
        headerName: props.intl.formatMessage(messages.shiftName),
        headerTooltip: props.intl.formatMessage(messages.shiftName),
        width: 70,
        suppressSizeToFit: true,
        pinned: true,
      },
      {
        colId: 'labourCategoryName',
        field: 'labourCategoryName',
        headerName: props.intl.formatMessage(messages.workerType),
        headerTooltip: props.intl.formatMessage(messages.workerType),
        width: 150,
        suppressSizeToFit: true,
        pinned: true,
      },
    ];
    if (props.formik.values.planningParameters.labourAvailabilityType == 'DEPARTMENT') {
      colDefs.push(
        {
          colId: 'departmentName',
          field: 'departmentName',
          headerName: props.intl.formatMessage(messages.departmentName),
          headerTooltip: props.intl.formatMessage(messages.departmentName),
          width: 120,
          suppressSizeToFit: true,
          pinned: true,
        },
      )
    }

    if (role) {
      colDefs.push(
        {
          colId: 'roleName',
          field: 'roleName',
          headerName: props.intl.formatMessage(messages.roleName),
          headerTooltip: props.intl.formatMessage(messages.roleName),
          width: 120,
          suppressSizeToFit: true,
          pinned: true,
        },
      )
    }

    const shiftData = props.formik.values?.labourOverViewData?.rows;
    const granularityType = props.formik.values.planningParameters.labourAvailabilityGranularity;
    const handleValueFormatter = (params) => {
      const columnValue = params.data?.columns?.filter((data) => data.date === params.colDef.colId);
      const overriddenHeadCountValue = columnValue[0]?.overriddenHeadCount >= 0 && columnValue[0]?.overriddenHeadCount;
      const defaultHeadCount = columnValue[0]?.defaultHeadCount >= 0 ? columnValue[0]?.defaultHeadCount : '';
      let valueToDisplay = defaultHeadCount;
      if (columnValue && columnValue[0] && ("overriddenHeadCount" in columnValue[0])) {
        //valueToDisplay = columnValue[0].overriddenHeadCount;
        if (columnValue[0].overriddenHeadCount === '' || columnValue[0].overriddenHeadCount === undefined) {
          valueToDisplay = ''
        } else {
          valueToDisplay = columnValue[0].overriddenHeadCount;
        }
      }
      //const valueToDisplay = overriddenHeadCountValue ? overriddenHeadCountValue : defaultHeadCount;
      return valueToDisplay
    }

    const handleValueSetter = (params) => {
      params.data.columns.forEach((data, index) => {
        if (data.date === params.colDef.colId) {
          const current = params.data.columns[index];
          let newValue = toNumber(params.newValue);
          if (newValue === '') {
            newValue = null;
          }
          if (newValue == current.defaultHeadCount) {
            newValue = current.defaultHeadCount;
          }
          if (Number.isNaN(newValue)) {
            return false;
          }
          props.formik.setFieldValue(`labourOverViewData.rows[${params.node.childIndex}].columns[${index}].overriddenHeadCount`, newValue);
          params.data.columns[index] = { ...current, overriddenHeadCount: newValue }
        }
      });
      return true;
    }
    const handleClassValues = (params) => {
      const cssClasses = [];
      const headCountValue = params.data.columns.filter((data) => data.date === params.colDef.colId);
      const defaultHeadCount = headCountValue[0]?.defaultHeadCount// >=0 ? headCountValue[0]?.defaultHeadCount : ''; 
      const overriddenHeadCountValue = headCountValue[0]?.overriddenHeadCount >= 0 && headCountValue[0]?.overriddenHeadCount;
      if (props.isEdit) {
        cssClasses.push('cell-editable');
      }
      if (headCountValue && headCountValue[0] && ("overriddenHeadCount" in headCountValue[0])) {
        headCountValue[0].overriddenHeadCount !== null && headCountValue[0].overriddenHeadCount !== undefined && overriddenHeadCountValue !== defaultHeadCount && cssClasses.push('cell-overriddenHeadCount');
      } else if (defaultHeadCount >= 0) {
        //cssClasses.push('cell-editable');
      } else {
        cssClasses.push('cell-headCountEmpty');
      }
      return cssClasses.join(' ');
    }

    const handleCellEditing = (params) => {
      const headCountValue = params.data.columns.filter((data) => data.date === params.colDef.colId);
      const defaultHeadCount = headCountValue[0]?.defaultHeadCount;
      const overriddenHeadCountValue = headCountValue[0]?.overriddenHeadCount;
      if ((Number.isInteger(overriddenHeadCountValue) || Number.isInteger(defaultHeadCount)) && props.isEdit) {
        return true;
      }
      return false;
    }

    const handleToolTop = (params) => {
      const headCountValue = params.data.columns.filter((data) => data.date === params.colDef.colId);
      const overriddenHeadCountValue = headCountValue[0]?.overriddenHeadCount;
      if (overriddenHeadCountValue !== null && overriddenHeadCountValue !== undefined) {
        return 'Overridden value';
      }
    }

    shiftData && shiftData.forEach((data) => {
      data.columns.forEach((c) => {
        const rowFlag = granularityType == 'DAY' ? selectedDays.includes(c.dayOfWeek) : true;
        if (rowFlag) {
          const dayOfWeek = props.intl.formatMessage(calendarMessages[`${c.dayOfWeek.toLowerCase()}Short`])
          colDefs.push({
            colId: `${c.date}`,
            sortable: false,
            suppressMenu: true,
            suppressMovable: true,
            headerName: `${granularityType == 'DAY' ? dayOfWeek : 'Week'} ${parseDateAndFormatToLocalString(c.date)}`,
            headerTooltip: `${granularityType == 'DAY' ? dayOfWeek : 'Week'} ${parseDateAndFormatToLocalString(c.date)}`,
            width: 130,
            valueGetter: handleValueFormatter,
            valueSetter: handleValueSetter,
            editable: props.isEdit && handleCellEditing,
            cellRendererParams: params => ({
              editable: props.isEdit && handleCellEditing(params),
            }),
            suppressSizeToFit: true,
            cellClass: handleClassValues,
            tooltipValueGetter: handleToolTop
          })
        }
      })
    })

    return colDefs;
  }
  const onCellValueChanged = (params) => {
    params.data.columns.forEach((data, index) => {
      if (data.date === params.colDef.colId) {
        const rowData = props.formik.values.labourOverViewData.rows[params.node.childIndex];
        const columnData = props.formik.values.labourOverViewData.rows[params.node.childIndex].columns[index];
        columnData.labourCategoryId = rowData.labourCategoryId;
        const currentData = props.formik.values.labourOverrideData.overrides || [];
        let findIndex = -1;
        if (rowData.departmentId && rowData.roleId) {
          findIndex = currentData.findIndex(a => (a.date === columnData.date) && (a.shiftId === columnData.shiftId) && (a.LabourCategoryId === columnData.labourCategoryId) && (a.roleId === rowData.roleId) && (a.departmentId === rowData.departmentId));
        } else if (rowData.departmentId && !rowData.roleId) {
          findIndex = currentData.findIndex(a => (a.date === columnData.date) && (a.shiftId === columnData.shiftId) && (a.LabourCategoryId === columnData.labourCategoryId) && (a.departmentId === rowData.departmentId));
        } else if (!rowData.departmentId && rowData.roleId) {
          findIndex = currentData.findIndex(a => (a.date === columnData.date) && (a.shiftId === columnData.shiftId) && (a.LabourCategoryId === columnData.labourCategoryId) && (a.roleId === rowData.roleId));
        } else {
          findIndex = currentData.findIndex(a => (a.date === columnData.date) && (a.shiftId === columnData.shiftId) && (a.LabourCategoryId === columnData.labourCategoryId));
        }
        findIndex !== -1 && currentData.splice(findIndex, 1)
        if (columnData.overriddenHeadCount >= 0) {
          const newOverRideData = {
            LabourCategoryId: rowData.labourCategoryId,
            departmentId: rowData.departmentId,
            date: columnData.date,
            periodParametersId: columnData.periodParametersId,
            shiftId: columnData.shiftId,
            overriddenHeadCount: columnData?.overriddenHeadCount,
            roleId: rowData.roleId,
          }
          const newData = [...currentData, newOverRideData]
          const labourOverviewDataToSend = {
            granularity: props.formik.initialValues?.planningParameters?.labourAvailabilityGranularity,
            overrides: newData
          }
          props.formik.setFieldValue(`labourOverrideData`, labourOverviewDataToSend)
        }
      }
    });
  }
  const rowData = props.formik?.values?.labourOverViewData?.rows;
  const colDefKey = hash(columns);
  return (
    <div>
      <Row>
        <FormattedMessage {...messages.granularity} />
        {isEdit ? (
          <StyledSelect
            classNamePrefix="react-select"
            className="react-select-container"
            isClearable={false}
            options={[{ value: 'day' }, { value: 'week' }]}
            getOptionLabel={val => <FormattedMessage {...messages[val.value]} />}
            onChange={val => setGranularity(val.value)}
            value={{ value: granularity }}
          />
        ) : (
          <FormattedMessage {...messages[granularity]} />
        )}
        {isEdit && (
          <ButtonWithDirtyCheck
            actionHandler={submitGranularity}
            message={messages.confirm}
            disabled={storedGranularity === granularity}
          />
        )}
      </Row>
      {
        props.formik.values.planningParameters.labourAvailabilityGranularity == 'DAY' &&
        <Row>
          <DayTransformationButtonsWrap>
            {renderDaySwitchButtons()}
          </DayTransformationButtonsWrap>
        </Row>
      }
      <Row>
        <FormattedMessage {...messages.availableHeads} />
      </Row>
      <div key={colDefKey}>
        <TableWithHeight
          showCOG={false}
          applyColumnDefOrder
          pagination
          paginationPageSize={50}
          columnDefs={columns}
          rowData={rowData}
          domLayout="autoHeight"
          onModelUpdated={params => params.columnApi.autoSizeAllColumns()}
          onCellValueChanged={onCellValueChanged}
        />
      </div>
    </div>
  );
})
  ;


const mapStateToPropsPlan = createStructuredSelector({
  selectedDays: makeSelectLabourAvailabilityOverview(),
  edit: selectEditFromplan,
  //offDays: selectOffDays,
  firstDay: selectFirstday,
  viewSettings: selectViewModeStoredData,
});

const mapStateToPropsPa = createStructuredSelector({
  selectedDays: makeSelectLabourAvailabilityOverview(),
  edit: selectEditFromPa,
  //offdays: selectOffDaysPA,
  firstDay: selectFirstdayPA,
  viewSettings: selectViewModeStoredData,
});

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators({ toggleDayAction }, dispatch),
    dispatch,
  };
}

const withPlanConnect = connect(
  mapStateToPropsPlan,
  mapDispatchToProps,
);

const withPaConnect = connect(
  mapStateToPropsPa,
  mapDispatchToProps,
);

const withReducer = injectReducer({ key: 'LabourAvailabilityOverview', reducer });

export const LabourAvailabilityOverviewPlan = compose(
  withReducer,
  injectIntl,
  withPlanConnect,
)(LabourAvailabilityOverview);

export const LabourAvailabilityOverviewPa = compose(
  withReducer,
  injectIntl,
  withPaConnect,
)(LabourAvailabilityOverview);