import { parserPlanResult, searchToData } from 'utils/url';
import { useHistory } from "react-router-dom";
export const constructNavigtionDetails = (pathname) => {
    const history = useHistory();
    const searchObject = history.location.search && searchToData(history.location.search, parserPlanResult);
    const planId = searchObject?.planId;
    const areaId = searchObject?.areaId;
    const names = pathname.split("/").filter(Boolean) || [];
    // Planning area detail page
    if (names?.length === 3 && names[1] === 'reset' && names[2] === 'false') {
        names.pop();
        names.pop();
    }
    if (history?.location?.pathname.includes('/area')) {
        if (names[0] === 'area') {
            if (names.length == 2) {
                names.pop();
            }
        }
        if (names[0] === 'area' && names[2] === 'interfaces') {
            names.shift();
        }
        if (names[0] === 'history' && names[1] === 'area') {
            names.pop();
            swapArrayElements(names, 0, 1);
        }
        if (names[0] === 'area' && names[1] === 'create' && names[2] === 'insert') {
            names.pop();
            names.pop();
            names.pop();
            names.push('planningAreaCreation')
        }
    }
    // Plan area detail page
    if (history?.location?.pathname.includes('/plan')) {
        if (names[0] === 'plan') {
            if (names.length == 2) {
                if (names[1] !== 'result') names.pop();
            }
        }
        if (names[0] === 'plan' && names[2] === 'interfaces') {
            names.shift();
        }
        if (names[0] === 'history' && names[1] === 'plan') {
            names.pop();
            swapArrayElements(names, 0, 1);
        }
        if (names[0] === 'plan' && names[1] === 'result') {

        }
        if (names[0] === 'plan' && names[2] === 'statistics') {
            names.pop();
        }
        if (names[0] === 'plan' && names[1] === 'create' && names[2] === 'insert' && !searchObject.paId) {
            names.pop();
            names.pop();
            names.pop();
            names.push('planCreation')
        }
        if (names[0] === 'plan' && names[1] === 'create' && names[2] === 'insert' && searchObject.paId) {
            names.pop();
            names.pop();
            names.pop();
            names.unshift('planFromPlannigArea');
            names.unshift(searchObject.paId);
        }
    }
    // Schedule detail page
    if (history?.location?.pathname.includes('/schedules')) {
        if (names[0] === 'schedules') {
            if (names.length == 2) {
                names.pop();
            }
        }
        if (names[0] === 'schedules' && names[2] === 'interfaces') {
            names.shift();
        }
        if (names[0] === 'schedules' && names[2] === 'staff' && names[3] !== 'powerbi') {
            names.shift();
            names.pop();
        }
        if (names[0] === 'schedules' && names[2] === 'runs') {
            names.shift();
        }
        if (names[0] === 'schedules' && names[2] === 'runs' && names[3] === 'powerbi') {
            names.shift();
        }
        if (names[0] === 'schedules' && names[2] === 'run' && names[3] !== 'powerbi') {
            names.shift();
            names.pop();
            names.push('Run Details')
        }
        if (names[0] === 'schedules' && names[2] === 'smartShiftJob' && names[3] !== 'powerbi') {
            names.shift();
            names.pop();
        }
        if (names[0] === 'schedules' && names[1] === 'create' && names[2] === 'insert') {
            names.pop();
            names.pop();
            names.pop();
            names.push('scheduleCreation')
        }
    }
    //Smartprod source detail page
    //smartProdSource
    if (history?.location?.pathname.includes('/smartProdSource')) {
        if (names[0] === 'smartProdSource') {
            if (names.length == 2) {
                names.pop();
            }
        }
        if (names[0] === 'smartProdSource' && names[1] === 'create' && names[2] === 'insert') {
            names.pop();
            names.pop();
            names.pop();
            names.push('smartProdSourceCreation')
        }
    }
    //Master plan detail page
    if (history?.location?.pathname.includes('/masterPlan')) {
        if (names[0] === 'masterPlan') {
            if (names.length == 2) {
                if (names[1] !== 'result') names.pop();
            }
        }
        if (names[0] === 'masterPlan' && names[1] === 'create' && names[2] === 'insert') {
            names.pop();
            names.pop();
            names.pop();
            names.push('masterplanCreation')
        }
        if (names[0] === 'masterPlan' && names[1] === 'plan' && names[2] === 'create' && names[3] === 'insert') {
            names.pop();
            names.pop();
            names.pop();
            names.push('planfrommasterplanCreation')
        }
        if (names[0] === 'masterPlan' && names[1] === 'area' && searchObject.masterPlanId) {
            names.shift();
        }
        if (names[0] === 'masterPlan' && names[1] === 'plan' && searchObject.masterPlanId && names[2] !== 'result' && names[3] !== 'graphs' && names[3] !== 'statistics') {
            names.shift();
        }
        if (names[0] === 'masterPlan' && names[2] === 'interfaces') {
            names.shift();
        }
        if (names[0] === 'masterPlan' && names[1] === 'area' && names[3] === 'interfaces') {
            names.shift();
        }
        if (names[0] === 'masterPlan' && names[1] === 'history' && names[2] === 'area') {
            names.pop();
            swapArrayElements(names, 1, 2);
        }
        if (names[0] === 'masterPlan' && names[1] === 'plan' && names[3] === 'interfaces') {
            names.shift();
        }
        if (names[0] === 'masterPlan' && names[1] === 'history' && names[2] === 'plan') {
            names.pop();
            swapArrayElements(names, 1, 2);
        }
        if (names[0] === 'masterPlan' && names[1] === 'plan' && names[3] === 'statistics') {
            names.pop();
        }
    }
    // Forecast page
    if (names[0] === 'forecast' && areaId && searchObject.masterPlanId) {
        names.unshift('masterPlan');
        names.unshift('area');
    }
    if (names[0] === 'forecast' && planId && searchObject.masterPlanId) {
        names.unshift('masterPlan');
        names.unshift('plan');
    }
    if (names[0] === 'forecast' && areaId) {
        names.unshift('area');
    }
    if (names[0] === 'forecast' && planId) {
        names.unshift('plan');
    }
    // Plan creation from Planning Area
    if (names[0] === 'plan' && names[1] === 'create' && names[2] === 'insert' && searchObject.paId) {
        names.pop();
        names.pop();
        names.pop();
        names.unshift('planFromPlannigArea');
        names.unshift(searchObject.paId);
    }
    // Plan creation from Plan
    if (names[0] === 'plan' && names[1] === 'create' && names[2] === 'copy') {
        names.shift();
        names.shift();
        names.shift();
        names.push('planFromPlan');
    }
    // PA copy creation from Planning Area
    if (names[0] === 'area' && names[1] === 'create' && names[2] === 'copy') {
        names.shift();
        names.shift();
        names.shift();
        names.push('copyFromPlannigArea');
    }
    return names;
}

const swapArrayElements = function (arr, indexA, indexB) {
    var temp = arr[indexA];
    arr[indexA] = arr[indexB];
    arr[indexB] = temp;
};

export const constructRouteTo = (pathname, pathnames, name, index, masterPlanId) => {
    const history = useHistory();
    const searchObject = history.location.search && searchToData(history.location.search, parserPlanResult);
    const planId = searchObject?.planId;
    const areaId = searchObject?.areaId;
    let routeTo = `/${pathnames.slice(0, index + 2).join("/")}`;
    //Planning area detail routes
    if (history?.location?.pathname.includes('/area')) {
        if (history?.location?.pathname.includes('/history/area/')) {
            routeTo = pathname.slice(8);
        }
        if (history?.location?.pathname.includes('/interfaces')) {
            routeTo = `/area/${pathnames[0]}`
        }
    }
    //Plan detail routes
    if (history?.location?.pathname.includes('/plan')) {
        if (history?.location?.pathname.includes('/history/plan/')) {
            routeTo = pathname.slice(8);
        }
        if (history?.location?.pathname.includes('/interfaces')) {
            routeTo = `/plan/${pathnames[0]}`
        }
        if (history?.location?.pathname.includes('/statistics')) {
            if (index === 1) {
                routeTo = `/plan/result/?planId=${pathnames[1]}`
            }
        }
        if (history?.location?.pathname.includes('/graphs')) {
            if (index === 1) {
                routeTo = `/plan/result/?planId=${pathnames[1]}`
            }
        }
    }
    //Master plan detail routes
    //Plan detail routes
    if (history?.location?.pathname.includes('/masterPlan')) {
        if (history?.location?.pathname.includes('/interfaces')) {
            routeTo = `/masterPlan/${pathnames[0]}`
        }
        if (history?.location?.pathname.includes('/masterPlan/area')) {
            routeTo = `/masterPlan/${searchObject.masterPlanId}`
        }
        if (history?.location?.pathname.includes('/masterPlan/plan')) {
            routeTo = `/masterPlan/${searchObject.masterPlanId}`
        }
    }
    //// Plan creation from Planning Area
    if (pathnames[1] === 'planFromPlannigArea') {
        routeTo = `/area/${pathnames[0]}`
    }
    //Plan creation from plan
    if (pathnames[1] === 'planFromPlan') {
        routeTo = `/plan/${pathnames[0]}`
    }
    // PA copy creation from Planning Area
    if (pathnames[1] === 'copyFromPlannigArea') {
        routeTo = `/area/${pathnames[0]}`
    }
    //  Forecast detail page
    if (pathnames[0] === 'area' && pathnames[1] === 'forecast' && areaId) {
        routeTo = `/area/${areaId}`
        //names.unshift('area');
    }
    if (pathnames[0] === 'area' && pathnames[1] === 'masterPlan' && pathnames[2] === 'forecast' && areaId && searchObject.masterPlanId) {
        if (name === 'area' && index === 0) {
            routeTo = `/masterPlan/${searchObject.masterPlanId}`
        }
        if (name === 'masterPlan' && index === 1) {
            routeTo = `/masterPlan/area/${areaId}?isEdit=true&masterPlanId=${searchObject.masterPlanId}`
        }
    }
    if (pathnames[0] === 'plan' && pathnames[1] === 'masterPlan' && pathnames[2] === 'forecast' && planId && searchObject.masterPlanId) {
        if (name === 'plan' && index === 0) {
            routeTo = `/masterPlan/${searchObject.masterPlanId}`
        }
        if (name === 'masterPlan' && index === 1) {
            routeTo = `/masterPlan/plan/${planId}?isEdit=true&masterPlanId=${searchObject.masterPlanId}`
        }
    }
    if (pathnames[0] === 'plan' && pathnames[1] === 'forecast' && planId) {
        routeTo = `/plan/${planId}`
        //names.unshift('area');
    }
    //Result detail page
    if (pathnames[0] === 'plan' && pathnames[1] === 'result') {
        routeTo = `/plan/${planId}`
        //names.unshift('area');
    }
    if (pathnames[0] === 'masterPlan' && pathnames[1] === 'result') {
        routeTo = `/masterPlan/${searchObject.masterPlanId}`
        //names.unshift('area');
    }
    if (pathnames[0] === 'masterPlan' && pathnames[1] === 'plan' && pathnames[2] === 'result') {
        if (name === 'plan' && index === 1) {
            routeTo = `/masterPlan/plan/${searchObject.planId}?isEdit=true&masterPlanId=${searchObject.masterPlanId}`;
        }
    }
    //Master plan routes
    if (history?.location?.pathname.includes('/masterPlan')) {
        if (pathnames[0] === 'masterPlan' && pathnames[1] === "planfrommasterplanCreation") {
            routeTo = `/masterPlan/${masterPlanId}?isEdit=true`
        }
        if (history?.location?.pathname.includes('/masterPlan/area')) {
            if (name === 'area' && index === 0) {
                routeTo = `/masterPlan/${searchObject.masterPlanId}/?masterPlanId=${searchObject.masterPlanId}?isEdit=true`
            }
            if (pathnames[2] === 'interfaces' && index === 1) {
                routeTo = `/masterPlan/area/${pathnames[1]}/?masterPlanId=${searchObject.masterPlanId}`
            }
        }
        if (history?.location?.pathname.includes('/masterPlan/plan')) {
            if (name === 'plan' && index === 0) {
                routeTo = `/masterPlan/${searchObject.masterPlanId}/?masterPlanId=${searchObject.masterPlanId}?isEdit=true`
            }
            if (pathnames[2] === 'interfaces' && index === 1) {
                routeTo = `/masterPlan/plan/${pathnames[1]}/?masterPlanId=${searchObject.masterPlanId}`
            }
        }
        if (history?.location?.pathname.includes('/masterPlan/history/area')) {
            if (name === 'masterPlan' && index === 0) {
                routeTo = `/masterPlan/${searchObject.masterPlanId}?isEdit=true`;
            }
            if (name === 'area' && index === 1) {
                const paId = history?.location?.pathname?.slice(25);
                routeTo = `/masterPlan/area/${paId}/?masterPlanId=${searchObject.masterPlanId}`
            }
        }
        if (history?.location?.pathname.includes('/masterPlan/history/plan')) {
            if (name === 'masterPlan' && index === 0) {
                routeTo = `/masterPlan/${searchObject.masterPlanId}?isEdit=true`;
            }
            if (name === 'plan' && index === 1) {
                const paId = history?.location?.pathname?.slice(25);
                routeTo = `/masterPlan/plan/${paId}/?masterPlanId=${searchObject.masterPlanId}`
            }
        }
        if (pathnames[0] === 'plan' && pathnames[1] === 'history' && pathnames[2] === 'plan' && history?.location?.pathname?.includes('/masterPlan/plan/history/plan')) {
            if (name === 'history' && index === 1) {
                routeTo = `/masterPlan/plan/${pathnames[3]}?isEdit=true&masterPlanId=${searchObject.masterPlanId}`
            }
            if (name === 'plan' && index === 2) {
                routeTo = `/masterPlan/plan/result?planId=${pathnames[3]}&masterPlanId=${searchObject.masterPlanId}`
            }
        }
        if (pathnames[0] === 'masterPlan' && pathnames[1] === 'plan' && pathnames[3] === 'graphs') {
            if (name === 'plan' && index === 1) {
                routeTo = `/masterPlan/plan/${searchObject.planId}?isEdit=true&masterPlanId=${searchObject.masterPlanId}`
            }
            if (index === 2) {
                routeTo = `/masterPlan/plan/result?planId=${searchObject.planId}&masterPlanId=${searchObject.masterPlanId}`
            }
        }
        if (pathnames[0] === 'masterPlan' && pathnames[1] === 'plan' && pathnames[3] === 'statistics') {
            if (name === 'plan' && index === 1) {
                routeTo = `/masterPlan/plan/${searchObject.planId}?isEdit=true&masterPlanId=${searchObject.masterPlanId}`
            }
            if (index === 2) {
                routeTo = `/masterPlan/plan/result?planId=${searchObject.planId}&masterPlanId=${searchObject.masterPlanId}`
            }
        }
    }
    //Schedule detail routes
    if (history?.location?.pathname.includes('/schedules')) {
        if (history?.location?.pathname.includes('/history/plan/')) {
            routeTo = pathname.slice(8);
        }
        if (history?.location?.pathname.includes('/interfaces')) {
            routeTo = `/schedules/${pathnames[0]}`
        }
        if (history?.location?.pathname.includes('/runs')) {
            if (name === 'runs') {
                routeTo = `/schedules/${pathnames[0]}/runs`
            } else {
                routeTo = `/schedules/${pathnames[0]}`
            }
        }
        if (history?.location?.pathname.includes('/run')) {
            if (index === 0) {
                routeTo = `/schedules/${pathnames[0]}`
            } else {
                routeTo = `/schedules/${pathnames[0]}/runs`
            }
        }
        if (history?.location?.pathname.includes('/smartShiftJob')) {
            routeTo = `/schedules/${pathnames[0]}`
        }
        if (history?.location?.pathname.includes('/staff')) {
            routeTo = `/schedules/${pathnames[0]}`
        }
    }
    return routeTo;
}



export const constructLinkName = (link, name, index, history, pathnames) => {
    const searchObject = history.location.search && searchToData(history.location.search, parserPlanResult);
    const planId = searchObject?.planId;
    const areaId = searchObject?.areaId;
    let linkName = link;
    if (history?.location?.pathname?.includes('/area')) {
        if(index === 0) linkName = `PA - ${linkName}` || 'Planning Area Detail';
    }
    if (history?.location?.pathname?.includes('/plan')) {
        if(pathnames[1] === 'planFromPlannigArea' && index === 0){
             linkName = `PA - ${linkName}`
        } else if(index === 0){
            if(history?.location?.pathname?.includes('/masterPlan/plan/create/insert')){
            }else{
                linkName = `P - ${linkName}` || 'Plan Detail';
            }
           
        }
        
    }
    if(history?.location?.pathname?.includes('/forecast')){
        if(planId) linkName = `P - ${linkName}`;
        if(areaId && !searchObject.masterPlanId) linkName = `PA - ${linkName}`;
    }
    if (history?.location?.pathname?.includes('/masterPlan')) {
        if(index === 0) linkName = `MP - ${linkName}`;
        if(pathnames[0] === 'plan' && index === 1) linkName = `P - ${linkName}`;
    }
    if (history?.location?.pathname?.includes('/masterPlan/area')) {
        if (name === 'area' && index === 0) {
            linkName = `MP - ${history?.location?.state?.masterPlan?.name || sessionStorage.getItem('masterPlanName') || 'Master Plan Detail'}`;
        }
        if(index === 1 && pathnames[2] === 'interfaces'){
            linkName = `PA - ${linkName}`
        }
    }
    if (history?.location?.pathname?.includes('/masterPlan/plan')) {
        if (name === 'plan' && index === 0) {
            linkName = `MP - ${history?.location?.state?.masterPlan?.name || sessionStorage.getItem('masterPlanName') || 'Master Plan Detail'}`;
        }
        if(index === 1 && pathnames[2] !== 'interfaces' && pathnames[1] !== 'planfrommasterplanCreation' && pathnames[1] !== 'history'){
            linkName = `P - ${linkName}`
        }
    }
    if (history?.location?.pathname?.includes('/masterPlan/history/area')) {
        if (name === 'masterPlan' && index === 0) {
            linkName = `MP - ${history?.location?.state?.masterPlan?.name || sessionStorage.getItem('masterPlanName') || 'Master Plan Detail'}`;
        }
        if(index === 1){
            linkName = `PA - ${linkName}`
        }
    }
    if (history?.location?.pathname?.includes('/masterPlan/plan/result')) {
        if (name === 'masterPlan' && index === 0) {
            linkName = `MP - ${history?.location?.state?.masterPlan?.name || sessionStorage.getItem('masterPlanName') || 'Master Plan Detail'}`;
        }
    }
    if (pathnames[0] === 'area' && pathnames[1] === 'masterPlan' && pathnames[2] === 'forecast') {
        if (name === 'area' && index === 0) linkName = `MP - ${history?.location?.state?.masterPlan?.name || sessionStorage.getItem('masterPlanName') || 'Master Plan Detail'}`;
        if(index === 1) linkName = `PA - ${linkName}`
    }
    if (history?.location?.pathname?.includes('/masterPlan/history/plan')) {
        if (name === 'masterPlan' && index === 0) {
            linkName = `MP - ${history?.location?.state?.masterPlan?.name || sessionStorage.getItem('masterPlanName') || 'Master Plan Detail'}`;
        }
        if(index === 1){
            linkName = `P - ${linkName}`
        }
    }
    if (pathnames[0] === 'masterPlan' && pathnames[1] === 'plan' && (pathnames[3] === 'graphs' || pathnames[3] === 'statistics')) {
        if (name === 'masterPlan' && index === 0) {
            linkName = `MP - ${sessionStorage.getItem('masterPlanName') || 'Master Plan Detail'}`;
        }
        if (name === 'plan' && index === 1) {
            //linkName = 'Plan Detail';
        }
        if (index === 2) {
            linkName = 'Plan Result';
        }
    }
    if (pathnames[0] === 'plan' && pathnames[1] === 'history' && pathnames[2] === 'plan' && history?.location?.pathname?.includes('/masterPlan/plan/history/plan')) {
        if (name === 'history' && index === 1) {
            //linkName = 'Plan Detail';
        }
        if (name === 'plan' && index === 2) {
            linkName = 'Plan Result';
        }
    }
    return linkName;
}

export const constructShortLinkName = (name) => {
    return name?.length <= 25 ? name : (name?.substr(0, 25) + "...");
}

export const constructLabelName = (label, history, pathnames, name, index) => {
    if(!label) return '';
    if(history?.location?.pathname?.includes('/masterPlan/plan/history/plan') && index === 3){
        return 'History';
    }
    if(history?.location?.pathname?.includes('/schedules') && pathnames.length === 1 && name === 'schedules' && index === 0){
        return label;
    }
    if(history?.location?.pathname?.includes('/smartProdSource') && pathnames.length === 1 && name === 'smartProdSource' && index === 0){
        return label;
    }
    if(history?.location?.pathname?.includes('/area')){
        return `PA - ${label}`;
    }
    if(history?.location?.pathname?.includes('/plan')){
        if(history?.location?.pathname?.includes('/masterPlan/plan/create/insert')) return;
        return `P - ${label}`;
    }
    if(history?.location?.pathname?.includes('/masterPlan')){
        return `MP - ${label}`;
    }
    if(history?.location?.pathname?.includes('/masterPlan/plan/result')){
        if(pathnames[1] === 'plan' && index === 1){
            return `P - ${linkName}`;
        }
    }
    if(history?.location?.pathname?.includes('/smartProd')){
        return label;
    }
}