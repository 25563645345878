/*
 * TopBar Messages
 *
 * This contains all the text for the TopBar component.
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  header: {
    id: 'app.components.TopBar.header',
    defaultMessage: 'DHL SmartPlan',
  },
  logout: {
    id: 'app.components.TopBar.logout',
    defaultMessage: 'Logout',
  },
  configuration: {
    id: 'app.components.TopBar.configuration',
    defaultMessage: 'Configuration',
  },
  um: {
    id: 'app.components.TopBar.um',
    defaultMessage: 'User Management',
  },
  cdm: {
    id: 'app.components.TopBar.cdm',
    defaultMessage: 'Customer Data Management',
  },
  smartProdSourceList: {
    id: 'app.components.TopBar.smartProdSourceList',
    defaultMessage: 'SmartProd source',
  },
  smartVolumeManualUpdate: {
    id: 'app.components.TopBar.smartVolumeManualUpdate',
    defaultMessage: 'SmartVolume Manual Update',
  }
});
