import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import { Field, FormikProps } from 'formik';
import { DateTime } from 'luxon';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import AutocompleteSelect from 'components/AutocompleteSelect';
import FormikDatePicker from 'components/FormikDatePicker';
import { FormikText, InlineLabel } from 'components/FormikTextInput';
import Label from 'components/Label';
import { ItemSelectable } from 'components/Menu/ItemSelectable';
import ParametersGroups from 'components/ParametersGroups';
import ToggleSection from 'components/ToggleSection';
import KpiData from 'containers/ShiftScheduleRunDetailPage/KpiData';
import ErrorsWarnings from './ErrorsWarnings';
import { granularities, granularityById } from 'containers/ShiftScheduleRunsPage/constants';
import messages from 'containers/ShiftScheduleRunsPage/messages';
import { ShiftScheduleRun } from 'containers/ShiftScheduleRunsPage/types';
import { downloadScheduleFile, statusCell } from 'containers/ShiftScheduleRunsPage/utils';
import { DisplayDate, DisplayDateTime, parseDate } from 'utils/dateTime';
import { hasOnePerm, PERMISSIONS } from 'utils/security';
import { ApiScheduleDTO } from 'types/drep-backend.d';

import ScheduleRunSteps from './ScheduleRunSteps';
import { ShiftScheduleRunDetailForm } from './types';

const InnerContainer = styled.div`
  padding: 25px 10px 5px 15px;
`;

const GridRow = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: 110px 155px 110px 160px 100px 100px 100px;
  margin: 0 5px 14px;
  column-gap: 10px;
`;

const FullWidthToggleSection = styled(ToggleSection)`
  margin-left: -5px;
  min-width: 1100px;
  width: 1100px;
  max-width: 1100px;
`;

const LabelAt6 = styled(InlineLabel)`
  grid-column-start: 6;
`;

const DateWrap = styled.div`
  max-width: 86px;
`;

const WrappingLabel = styled(Label)`
  white-space: normal;
`;

const CheckBox = styled(ItemSelectable)`
  justify-self: start;
  width: inherit;
  :hover {
    cursor: pointer;
  }
`;

const DownloadIconsContainer = styled.div`
  span {
    margin-right: 10px;
  }
`;

const SpanWithHiddenOverflow = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  width: 150px;
  display: inline-block;
`;

export interface ScheduleRunRendererProps {
  dispatch: any;
  intl: any;
  formik: FormikProps<ShiftScheduleRunDetailForm>;
  shiftScheduleRuns: ShiftScheduleRun[];
  shiftSchedule: ApiScheduleDTO;
  location: any;
  user: any;
  scheduleId: number;
  isCopyPath: boolean;
  isEditable: boolean;
  deleteScheduleRun: () => any;
  recalculateShiftScheduleRuns: () => any;
  activitiesNotInKronosExist: boolean;
  openDeleteDialog: (any1, any2, any3) => any;
  openConfirmDialog: (any1, any2) => any;
  dateLimitHourGranularity?: number,
  dateLimitQuarterGranularity?: number,

  hasPerm(perm: string): () => boolean;
  loadErrorDescription: () => any;
  errorDescriptions: any;
}

class ScheduleRunRendererImpl extends React.Component<ScheduleRunRendererProps> {
  public componentDidMount(): void {
    this.props.loadErrorDescription()
  }
  public render() {
    const {
      recalculateShiftScheduleRuns,
      formik,
      shiftSchedule,
      isEditable,
      isCopyPath,
      activitiesNotInKronosExist,
      intl: { formatMessage },
      errorDescriptions,
    } = this.props;
    const { shiftScheduleRun } = formik.values;

    const plans = (shiftScheduleRun.plans || []).map((p, i) => (
      <GridRow key={i}>
        {i === 0 ? <InlineLabel {...messages.plan} /> : <span key="space2" />}
        <FormikText>
          <SpanWithHiddenOverflow title={p.name}>{p.name}</SpanWithHiddenOverflow>
        </FormikText>
        <FormikText>
          <DisplayDate value={p.validFrom} />
        </FormikText>
        <FormikText>
          <DisplayDate value={p.validTo} />
        </FormikText>
      </GridRow>
    ));

    const plansHeader = (shiftScheduleRun.plans || []).length > 0 && (
      <>
        <span key="space2" />
        <span key="space3" />
        <InlineLabel {...messages.from} />
        <InlineLabel {...messages.to} />
      </>
    );

    const timespanSetter = v => {
      formik.setFieldValue(`shiftScheduleRun.granularity`, v.id);
    };

    const onNameBlur = e => {
      formik.setFieldValue(`shiftScheduleRun.name`, e.target.value);
    };

    const setParameterValue = (parameterValueData, newValue) => {
      const groupIndex = formik.values.shiftScheduleRun.groups.findIndex(
        group => group.groupName === parameterValueData.groupName,
      );
      const parameterValueFormikIndex = formik.values.shiftScheduleRun.groups[groupIndex].parametersValues.findIndex(
        parameterValueRow => parameterValueRow.id === parameterValueData.id,
      );
      const path = `shiftScheduleRun.groups[${groupIndex}].parametersValues[${parameterValueFormikIndex}].value`;
      formik.setFieldValue(path, newValue);
    };

    const onStartDateChange = val => {
      formik.setFieldValue(`shiftScheduleRun.startDate`, val);
    };
    const onEndDateChange = val => {
      formik.setFieldValue(`shiftScheduleRun.endDate`, val);
    };
    const userName = user => (user ? `${user.firstName || ''} ${user.lastName || ''}` : '');

    const statusText = shiftScheduleRun
      ? statusCell({
          data: shiftScheduleRun,
          dispatch: this.props.dispatch,
          formatMessage,
          messages,
          recalculateShiftScheduleRuns,
          scheduleId: this.props.scheduleId,
          value: shiftScheduleRun.status,
          dateLimitHourGranularity: this.props?.shiftSchedule?.dateLimitHourGranularity,
          dateLimitQuarterGranularity: this.props?.shiftSchedule?.dateLimitQuarterGranularity,
        })
      : '';

    const doDownloadCsvOverview = () =>
      downloadScheduleFile(shiftSchedule, shiftScheduleRun.csvFileId, 'CSV_REQUEST', this.props.dispatch);
    const csvOverviewFileDownload = shiftScheduleRun.csvFileId ? (
      <span style={{ cursor: 'pointer', width: '25px' }}>
        <FontAwesomeIcon icon="file-csv" color="green" size="lg" onClick={doDownloadCsvOverview} />
      </span>
    ) : (
      <span />
    );

    const doDownloadRequest = () =>
      downloadScheduleFile(shiftSchedule, shiftScheduleRun.requestFileId, 'REQUEST', this.props.dispatch);
    const requestFileDownload =
      shiftScheduleRun.requestFileId && hasOnePerm(PERMISSIONS.DOWNLOAD_SCHEDULE_REQUEST_FILE, this.props.user) ? (
        <span style={{ cursor: 'pointer', width: '25px' }}>
          <FontAwesomeIcon icon="file-upload" color="green" size="lg" onClick={doDownloadRequest} />
        </span>
      ) : (
        <span />
      );

    const { groups } = formik.values.shiftScheduleRun;
    const { steps } = shiftScheduleRun;
    const stepWithKpis = steps.find(s => (s.status === 'SUCCESSFUL' || s.status === 'SUCCESSFUL_WITH_WARNINGS') && s.kpi);
    const stepsWithErrors = steps.find(s => s.errors && s.errors.length > 0);
    const stepsWithWarnings = steps.find(s => s.warnings && s.warnings.length > 0);
    const kpiToShow = stepWithKpis ? stepWithKpis.kpi : null;
    const daysRestricted = shiftScheduleRun.granularity == 'HOUR' ? this.props.shiftSchedule?.dateLimitHourGranularity - 1 : this.props.shiftSchedule?.dateLimitQuarterGranularity - 1 ;
    return (
      <React.Fragment key={`${shiftScheduleRun.id}`}>
        <InnerContainer key="data">
          <GridRow key="data">
            <InlineLabel {...messages.name} />
            <input defaultValue={shiftScheduleRun.name} onBlur={onNameBlur} disabled={!isEditable} />
            {!isCopyPath && (
              <>
                <LabelAt6 {...messages.status} />
                <FormikText>{statusText}</FormikText>
              </>
            )}
          </GridRow>

          <GridRow>
            <InlineLabel {...messages.startDate} />
            <DateWrap>
              <Field
                name="shiftScheduleRun.startDate"
                component={FormikDatePicker}
                timePicker={false}
                onChange={onStartDateChange}
                minDate={DateTime.local()}
                minDateMessage=""
                editable={isEditable}
              />
            </DateWrap>
            <InlineLabel {...messages.endDate} />
            <DateWrap>
              <Field
                name="shiftScheduleRun.endDate"
                minDate={parseDate(shiftScheduleRun.startDate) || undefined}
                maxDate={parseDate(shiftScheduleRun?.startDate).plus({ days : daysRestricted})}
                maxDateMessage=""
                component={FormikDatePicker}
                timePicker={false}
                onChange={onEndDateChange}
                editable={isEditable}
              />
            </DateWrap>
            {!isCopyPath && (
              <>
                <LabelAt6 {...messages.calculated} />
                <FormikText key="created">
                  <DisplayDateTime value={shiftScheduleRun.calculatedDate} />
                </FormikText>
              </>
            )}
          </GridRow>

          <GridRow>
            <InlineLabel {...messages.timespan} />
            <DateWrap>
              <AutocompleteSelect
                key="granularity"
                preloadedData={granularities}
                initialValue={granularityById(shiftScheduleRun.granularity)}
                setValue={timespanSetter}
                disabled={!isEditable}
              />
            </DateWrap>
            {!isCopyPath && (
              <>
                <LabelAt6 {...messages.calculatedBy} />
                <FormikText>
                  <span>{userName(shiftScheduleRun.calculatedBy)}</span>
                </FormikText>
              </>
            )}
          </GridRow>
          <GridRow>
            {!isCopyPath && (
              <>
                <LabelAt6 {...messages.sent} />
                <FormikText key="created">
                  <DisplayDateTime value={shiftScheduleRun.sentDate} />
                </FormikText>
              </>
            )}
          </GridRow>
          <GridRow>
            {!isCopyPath && (
              <>
                <LabelAt6 {...messages.sentBy} />
                <FormikText>
                  <span>{userName(shiftScheduleRun.sentBy)}</span>
                </FormikText>
              </>
            )}
          </GridRow>
          <GridRow>
            {plansHeader}
            {!isCopyPath && (
              <>
                <LabelAt6 {...messages.effort} />
                <DownloadIconsContainer>
                  <Tooltip title="CSV Overview">{csvOverviewFileDownload}</Tooltip>
                  <Tooltip title="Request to Engine">{requestFileDownload}</Tooltip>
                </DownloadIconsContainer>
              </>
            )}
          </GridRow>
          {plans}
          <GridRow>
            <FullWidthToggleSection message={messages.parametersTableHeader} expanded={false}>
              <ParametersGroups
                key={`run-parameters-groups-editable-${isEditable}`}
                groups={groups}
                setParameterValue={setParameterValue}
                isEdit={isEditable}
              />
            </FullWidthToggleSection>
          </GridRow>
          {!isCopyPath && (
            <>
              <GridRow>
                <FullWidthToggleSection message={messages.kpi} expanded>
                  <KpiData kpi={kpiToShow} />
                </FullWidthToggleSection>
              </GridRow>
              <GridRow>
                <FullWidthToggleSection message={messages.errorswarnings} expanded>
                    { stepsWithErrors?.errors?.length > 0 && <ErrorsWarnings  errorMessages={messages.error} error={stepsWithErrors.errors} errorDescriptions={errorDescriptions}/>}
                    { stepsWithWarnings?.warnings?.length > 0 && <ErrorsWarnings warningMessages={messages.warnings} warning={stepsWithWarnings.warnings} errorDescriptions={errorDescriptions}/>}
                </FullWidthToggleSection>
              </GridRow>
              <GridRow>
                <FullWidthToggleSection message={messages.statusHeader} expanded>
                  <ScheduleRunSteps steps={steps} />
                </FullWidthToggleSection>
              </GridRow>
            </>
          )}
        </InnerContainer>
      </React.Fragment>
    );
  }
}

export const ScheduleRunRenderer = withRouter(ScheduleRunRendererImpl);
