import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { cloneDeep, isEmpty, isEqual } from 'lodash';
import { DateTime } from 'luxon';
import { Helmet } from 'react-helmet';
import { FormattedMessage, InjectedIntl, injectIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { toast } from 'react-toastify';
import { compose } from 'redux';
import styled from 'styled-components';

import BasePage from 'components/BasePage';
import FormikErrorsPopup from 'components/DetailPageShared/FormikErrorsPopup';
import ToggleSection from 'components/ToggleSection';
import { getToken } from 'containers/App/selectors';
import { WarningText, WarningLiText, WarningLiElement, WarningUiElement } from 'components/FormikTextInput';
import { validateEvent } from './EventsTableDetailCellRenderer';
import { validateHolidayEntry } from './HolidaysTableDetailCellRenderer';
import {
  EntityEntry,
  SmartProdSourceForm,
  Holiday,
  SmartProdSourceEntityEntry,
  SubCategoryEntry,
} from './types';
import { loadPlanAction } from 'containers/PlanResultPage/actions';
import { convertEntityWithPlanningParametersFromApi, withUrl } from 'utils/api';
import { formatDate, generateDays, parseDate } from 'utils/dateTime';
import { fetchData } from 'utils/reduxApi';
import withSecurity, { PERMISSIONS, ROLES } from 'utils/security';
import { parserPlanResult, searchToData } from 'utils/url';
import { FormikProps } from 'formik';
import {
  ApiActivityDTO,
  ApiForecastEventDTO,
  ApiSmartProdSourceDTO,
  ApiSmartVolumeRunDTO,
} from 'types/drep-backend.d';

import EventAddForm from './EventAddForm';
import EventsTable from './EventsTable';
import {
  fetchActivities,
  fetchEventCategories,
  fetchEventOrigins,
  fetchEvents,
  fetchEventSubCategories,
  fetchHolidays,
  fetchOMSCodes,
  fetchSmartProdInstances,
  fetchWarehouses,
  saveEvents,
  saveForecastHolidays,
  fetchSmartVolumeRuns,
  saveSmartProdSourceData,
  fetchsmartProdSources,
  fetchFacilities,
  fetchNewFacility,
  saveSmartProdSourceFacility,
  smartProdSourceName
} from './fetch';
import Holidays from './Holidays';
import UsedPlansAndPA from './UsedPlansAndPA';
import messages from './messages';
import Toolbar from './ToolBar';
import BasicInfo from "./BasicInfo";
import BasicSettings from "./BasicSettings";
import SmartVolumeScheduling from "./SmartVolumeScheduling";
import SmartProdVolumeRunsTable from './SmartProdVolumeRunsTable';
import OMSCodesTable from './OMSCodesTable';
import OMSCodesAddForm from './OMSCodesAddForm';

export const SectionWrap = styled.div`
  margin: 5px 10px;
  padding: 5px;
  box-shadow: ${props => props.theme.shadow.pageBox};
  background-color: white;
  flex-grow: 1;
`;

const SectionTitle = styled.div`
  font-size: ${props => props.theme.fontSize.title};
  font-weight: 700;
  margin-bottom: 8px;
`;

export const PageSection: React.FC<{
  labelMessage: FormattedMessage.Props;
}> = ({ labelMessage, children }) => (
  <SectionWrap>
    {labelMessage && (
      <SectionTitle>
        <FormattedMessage {...labelMessage} />
      </SectionTitle>
    )}
    {children}
  </SectionWrap>
);

const ColumnsParent = styled.div`
  margin-top: 80px;
  display: flex;
  flex-direction: row;
  justify-content: stretch;
`;

export const Section = styled(ToggleSection)`
  margin: 5px 10px;
`;

export const Wrap = styled(BasePage)`
  ${Section}:nth-child(2) {
    margin-top: 80px;
  }

  ${Section} {
    .title {
      margin-bottom: 0;
    }
  }
`;

type PropsType = {
  intl: InjectedIntl;
  handleSubmit: () => void;
  fixed: boolean;
  isEdit: boolean;
  token: string;
  dispatch: () => void;
  result: unknown;
  formik?: FormikProps<SmartProdSourceForm>;
} & RouteComponentProps;

const SmartProdSourceDetailPage: React.FC<PropsType> = (props: PropsType) => {
  const dispatch = useDispatch();
  const { intl, history, formik, user } = props;
  const id = props.match?.params?.id;
  const isAdmin = user && user.user.roles.find(r => r.role === ROLES.ADMIN.name);
  const isMasterPlanEngineer = user && user.user.roles.find(r => r.role === ROLES.SMARTPLAN_MASTER_PLAN_ADMIN_ROLE.name);
  //const searchObject = history.location.search && searchToData(history.location.search, parserPlanResult);
  //const isEditInit = searchObject?.isEdit === 'true';

  const token: string = useSelector(getToken);
  const [isEdit, setIsEdit] = useState(false);
  const [entity, setEntity] = useState<ApiSmartProdSourceDTO>(null);
  const [allSmartProdInstances, setAllSmartProdInstances] = useState<EntityEntry[]>(null);
  const [allEventCategories, setAllEventCategories] = useState<EntityEntry[]>(null);
  const [allEventSubCategories, setAllEventSubCategories] = useState<SubCategoryEntry[]>(null);
  const [smartProdSources, setSmartProdSources] = useState([]);
  const [allOmsCodes, setAllOmsCodes] = useState<EntityEntry[]>(null);
  const [allOrigins, setAllOrigins] = useState<EntityEntry[]>(null);
  const [allWarehouses, setAllWarehouses] = useState<EntityEntry[]>(null);
  const [openedEventDetail, setOpenedEventDetail] = useState(null);
  const [entityRelations, setEntityRelations] = React.useState<EntityEntry[]>([]);

  const [initialValues, setInitialValues] = useState<SmartProdSourceForm>({
    eventsTable: {},
    holidaysTable: {},
    entity: null,
  });
  const [events, setEvents] = useState<ApiForecastEventDTO[]>([]);
  const [holidays, setHolidays] = useState<Holiday[]>([]);
  const holidayRef = React.useRef();
  const [data, setData] = useState<ApiSmartVolumeRunDTO[]>(null);
  const [allWHIDs, setWHIDs] = useState<EntityEntry[]>([]);
  const [hasRunningRun, setHasRunningRun] = useState<boolean>(false);
  const handleReloadData = () => {
    const fetchDetails = async () => {
      if (id) {
        const response = await fetchData(withUrl(`/smartProdSource/${id}`).andToken(token), dispatch);
        const details = response.isOk ? convertEntityWithPlanningParametersFromApi(response.data) : null;
        setEntity(details);
        if (details) {
          const facilities = [details.facility];
          const countryCodes = facilities.map(facility => facility.code.split('_')[0]);
          fetchOMSCodes(token, dispatch, setAllOmsCodes);
          fetchEvents(token, id, dispatch, setEvents);
          fetchHolidays(token, id, countryCodes, dispatch, setHolidays);
        }
      }
    };
    fetchDetails();
    fetchsmartProdSources(token, dispatch, setSmartProdSources);
    fetchSmartProdInstances(token, dispatch, setAllSmartProdInstances);
    fetchEventCategories(token, dispatch, setAllEventCategories);
    fetchEventSubCategories(token, dispatch, setAllEventSubCategories);
    fetchEventOrigins(token, dispatch, setAllOrigins);
    fetchWarehouses(token, dispatch, setAllWarehouses);
    fetchWarehouses(token, dispatch, setWHIDs);
    fetchSmartVolumeRuns(token, dispatch, id, setData);
    fetchFacilities(token, dispatch, setEntityRelations);
  };

  const handleReloadLatestData = () => {
    const fetchDetails = async () => {
      if (id) {
        const response = await fetchData(withUrl(`/smartProdSource/${id}`).andToken(token), dispatch);
        const details = response.isOk ? convertEntityWithPlanningParametersFromApi(response.data) : null;
        setEntity(details);
        if (details) {
          const facilities = [details.facility];
          const countryCodes = facilities.map(facility => facility.code.split('_')[0]);
          fetchOMSCodes(token, dispatch, setAllOmsCodes);
          fetchEvents(token, id, dispatch, setEvents);
          fetchHolidays(token, id, countryCodes, dispatch, setHolidays);
        }
      }
    };
    fetchDetails();
  }

  useEffect(()=>{
    //@ts-ignore
    holidayRef.current = holidays;
  },[holidays])

  useEffect(() => {
    handleReloadData();
  }, [id, token]);

  const handleReloadOMSData = () => {
    const fetchDetails = async () => {
      if (id) {
        const response = await fetchData(withUrl(`/smartProdSource/${id}`).andToken(token), dispatch);
        const details = response.isOk ? convertEntityWithPlanningParametersFromApi(response.data) : null;
        setEntity(details);
      }
    };
    fetchDetails();
  };

  useEffect(() => {
    const values: SmartProdSourceForm = {
      eventsTable: {},
      holidaysTable: {},
      entity,
    };
    events.forEach(data => {
      values.eventsTable[data.id] = data;
    });
    holidays.forEach(data => {
      values.holidaysTable[data.id] = data;
    });
    setInitialValues(values);
  }, [events, holidays, isEdit, entity]);

  useEffect(() => {
    if (entity && allWarehouses && allSmartProdInstances) {
      const warehousesById = {};
      allWarehouses.forEach(warehouse => {
        warehousesById[warehouse.value] = warehouse.label;
      });
      const smartProdInstancesById = {};
      allSmartProdInstances.forEach(instance => {
        smartProdInstancesById[instance.value] = instance.label;
      });
    }
  }, [entity, allWarehouses, allSmartProdInstances]);

  useEffect(() => {
    dispatch(loadPlanAction({ id }, intl, true));
  }, []);

  const editModeChange = (editMode: boolean) => {
    setIsEdit(editMode);
  };

  const findMatchingOMSCodes = (arr1, arr2) => {
    const results = arr1.filter(({ id: id1 }) => arr2.some(({ id: id2 }) => id2 === id1));
    return results.length > 0;
  };

  const findMatchingDays = (arr1,arr2) => {
    const sameDays = arr1.filter(arr1Item => arr2.includes(arr1Item));
    return sameDays.length > 0;
  };

  const findSameEvents = (events) => {
    let modifiedSameData = [];
    if (events) {
      for (let i = 0; i < events.length; i++) {
        for (let j = i; j < events.length; j++) {
          if (i !== j && (events[i].type === "RECURRING" && events[j].type === "RECURRING") && (((new Date(events[i].startDate) <= new Date(events[j].endDate)) && (new Date(events[i].endDate) >= new Date(events[j].startDate))) && findMatchingOMSCodes(events[i].omses, events[j].omses) && isEqual(events[i].category, events[j].category) && findMatchingDays(events[i].selectedDays,events[j].selectedDays))) {
            modifiedSameData.push([events[i], events[j]])
          }
        }
      }
      return modifiedSameData;
    }
  };

  const handleSubmitPage = async (values: SmartProdSourceForm) => {
    if (!isEmpty(handleValidate(values))) {
      return;
    }

    const customEventCompare = (initialVal, modifiedVal) => {
      let arr11 = Object.values(initialVal)
      let arr22 = Object.values(modifiedVal)
      let modifiedEvents = arr22.filter(function (item, index) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return !(item.name === arr11[index].name && item.impact === arr11[index].impact && item.startDate === arr11[index].startDate && item.endDate == arr11[index].endDate && item.type === arr11[index].type && item.omses === arr11[index].omses && item.selectedDays === arr11[index].selectedDays && item.category === arr11[index].category && item.subCategory === arr11[index].subCategory && item.origin === arr11[index].origin && item.description === arr11[index].description);
      });
      return modifiedEvents;
    }

    let reload = false;
    if (!isEqual(initialValues.eventsTable, values.eventsTable)) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const response = await saveEvents(token, dispatch, customEventCompare(initialValues.eventsTable, values.eventsTable));
      if (response.isOk) {
        reload = true;
      }
    }
    if (!isEqual(initialValues.holidaysTable, values.holidaysTable)) {
      const response = await saveForecastHolidays(
        token,
        dispatch,
        id,
        Object.values(values.holidaysTable),
      );
      if (response.isOk) {
        reload = true;
      }
    }
    if (!isEqual(initialValues.entity, values.entity)) {
      const response = await saveSmartProdSourceData(
        token,
        dispatch,
        values.entity
      );
      if (response.isOk) {
        reload = true;
      }
    }

    if (!isEqual(initialValues.entity.facility.id, values.entity.facility.id)) {
      const response = await saveSmartProdSourceFacility(
        token,
        dispatch,
        values.entity
      );
      if (response.isOk) {
        reload = true;
      }
    }
    if (reload) {
      handleReloadData();
    }
  };

  const handleGetSmartProdJobCodes = () => {
    /*if (planningParametersId) {
      refreshJobCodes(token, dispatch, planningParametersId).then(response => {
        if (response.isOk) {
          handleReloadData();
          toast.info(intl.formatMessage(messages.jobCodesRefreshedToast));
        }
      });
    }*/
  };

  const dataProps = {
    allWHIDs,
    allSmartProdInstances,
  };

  const handleValidate = values => {
    let errors = {};
    Object.values(values.eventsTable).forEach((event: ApiForecastEventDTO) => {
      const eventErrors = validateEvent(intl, event, true);
      if (eventErrors) {
        errors = { ...errors, ...eventErrors };
      }
    });
    Object.values(values.holidaysTable).forEach((holiday: Holiday) => {
      const holidayErrors = validateHolidayEntry(holiday, intl, true);
      if (holidayErrors) {
        errors = { ...errors, ...holidayErrors };
      }
    });
    return errors;
  };

  const RefreshSmartVolumeRuns = async () => {
    const response = await fetchData(
      withUrl(`/smartVolume/runs/?smartProdSourceId=${entity.id}`).andToken(token).post(),
      dispatch,
    );
    if (response.isOk) {
      setHasRunningRun(true);
      fetchSmartVolumeRuns(token, dispatch, id, setData);
    }
  };

  const loadSmartVolumeRuns = () => {
    fetchSmartVolumeRuns(token, dispatch, id, setData)
  }

  React.useEffect(() => {
    let runningStatus = false;
    const isLatestRunFailed = data?.[0]?.status === 'FAILED';
    if(isLatestRunFailed){
      handleReloadOMSData();
    }
    const currentRunning = data && data.find((data) => (data?.status === 'CREATED' || data?.status === 'RUNNING'));
    const isLatestRunSuccess =  data?.[0]?.status === 'SUCCESSFUL';
    if(hasRunningRun && isLatestRunSuccess){
      handleReloadOMSData();
    };
    if (currentRunning) {
      runningStatus = true;
    }
    setHasRunningRun(runningStatus);
    let timeout;
    if (runningStatus) {
      timeout = setTimeout(loadSmartVolumeRuns, 10000);
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [data]);

  const handleStopAutoAssign = async (data) => {
    // @ts-ignore
    const item = holidayRef.current.filter(it => it.id === data.id)[0];
    const response = await fetchData(
      withUrl(`/smartProdSource/${entity.id}/autoAssign`).andToken(token).post(
          {
          holidayId: item.id,
          omsIds: item.omsIds,
          smartProdSourceIds: item.smartProdSourceIds,
          autoAssignHoliday: !item.autoAssignHoliday,
          holidayWasAssigned: item.holidayWasAssigned,
          assigned: item.assigned,
        }
      ).asRawResponse(),
      dispatch,
    );
    if (response.isOk) {
      handleReloadLatestData();
    }
  }

  // eslint-disable-next-line no-shadow
  const handleAutoAssign = async data => {
    // @ts-ignore
    const item = holidayRef.current.filter(it => it.id === data.id)[0];
    const response = await fetchData(
      withUrl(`/smartProdSource/${entity.id}/autoAssign`).andToken(token).post(
        {
          holidayId: item.id,
          omsIds: item.omsIds,
          smartProdSourceIds: item.smartProdSourceIds,
          autoAssignHoliday: true,
          holidayWasAssigned: true,
          assigned: true
        }
      ),
      dispatch,
    );
    if (response.isOk) {
      handleReloadLatestData();
    }
  }

  const handleHolidayAssigment = async () => {
    const response = await fetchData(
      withUrl(`/smartProdSource/${entity.id}/holidays`).andToken(token).post(
        //@ts-ignore
        holidayRef.current.map(holiday => ({
          holidayId: holiday.id,
          omsIds: holiday.omsIds,
          smartProdSourceIds: holiday.smartProdSourceIds,
          autoAssignHoliday: holiday.autoAssignHoliday,
          holidayWasAssigned: false
        })),
      ),
      dispatch,
    );
    if (response.isOk) {
      handleReloadLatestData();
    }
  }

  const fetchNewFacilities = (option)=>{
    option ? fetchNewFacility(token, dispatch, setEntityRelations,option) : fetchFacilities(token, dispatch, setEntityRelations);
  };

  const mappedEvents = events.filter((data) => data.omses.length == 0);
  let unMappedHolidays = false;
  const sameEvents = findSameEvents(events);
  if (holidays.length > 0) unMappedHolidays = holidays && holidays.every((data) => data.omsIds.length == 0);
  const holidayWarnings = (entity && entity.warnings) || [];
  const warningOMSText = 'There are no OMS codes configured for event:';
  const holidayWasAssignedFlag = holidays?.some((h)=>h.holidayWasAssigned);
  const initRuns = data?.filter(run => run.type === 'INITIAL');
  const lastInitRunFailedHasWarning = initRuns?.length > 0 && initRuns[0].status === 'FAILED' && (initRuns[0]?.errorMessage?.includes('No data') || initRuns[0]?.errorMessage?.includes('No recent') || initRuns[0]?.errorMessage?.includes('SmartVolume'));
  const lastInitRunFailed = (initRuns?.length > 0 && initRuns[0].status === 'FAILED') && !lastInitRunFailedHasWarning;
  const warningTextDisplay = (mappedEvents.length > 0 || unMappedHolidays || sameEvents.length > 0 || holidayWarnings.length > 0 || lastInitRunFailedHasWarning || lastInitRunFailed) ? messages.warningAvailable : '';
  const nameText = smartProdSourceName(entity);
  const nameTitle = nameText ? `${nameText}` : '';
  return (
    <Formik
      initialValues={initialValues}
      isInitialValid
      enableReinitialize
      validateOnChange
      validate={handleValidate}
      onSubmit={handleSubmitPage}
    >
      <Wrap noMaxWidth labelMessage={messages.header} labelValues={{ name : nameTitle }}>
        <Helmet>
          <title>{intl.formatMessage(messages.header, { name })}</title>
          <meta name="description" content={intl.formatMessage(messages.content)} />
        </Helmet>

        <FormikErrorsPopup />

        <Toolbar
          entity={entity}
          isEdit={isEdit}
          isNew={!id}
          editModeChange={editModeChange}
          handleGetSmartProdJobCodes={handleGetSmartProdJobCodes}
          handleReloadData={handleReloadData}
          handleReloadLatestData={handleReloadLatestData}
          hasRunningRun={hasRunningRun}
          initialRequestSmartVolumeForecast={initialValues?.entity?.isRequestSmartVolumeForecast}
        />

        <ColumnsParent>
          <PageSection labelMessage={messages.basicInfo}>
            <BasicInfo entity={entity} isEdit={isEdit} entityRelations={entityRelations} fetchNewFacilities={fetchNewFacilities} />
          </PageSection>
          <PageSection labelMessage={messages.basicSettings}>
            <BasicSettings isAdmin={isAdmin} isEdit={isEdit} isMasterPlanEngineer={isMasterPlanEngineer}/>
          </PageSection>
          <PageSection labelMessage={messages.smartVolumeScheduling}>
            <SmartVolumeScheduling entity={entity} RefreshSmartVolumeRuns={RefreshSmartVolumeRuns} hasRunningRun={hasRunningRun} initialRequestSmartVolumeForecast={initialValues?.entity?.isRequestSmartVolumeForecast} />
          </PageSection>
        </ColumnsParent>

        {entity && (
          <>
            {
              <Section subtitleToShow={warningTextDisplay} message={messages.warning}>
                {
                  (mappedEvents.length > 0 || unMappedHolidays || sameEvents.length > 0 || holidayWarnings.length > 0 || lastInitRunFailed || lastInitRunFailedHasWarning) ? (
                    <>
                      {lastInitRunFailed && <WarningText>{`Last initial Smart Volume run failed. SmartProd source data isn’t available in Data Lake. Request SmartVolume forecast unchecked.`}</WarningText>}
                      {lastInitRunFailedHasWarning && <WarningText>{initRuns[0].errorMessage}</WarningText>}
                      {holidayWarnings && holidayWarnings.map(data=><WarningText>{data}</WarningText>)}
                      {mappedEvents && mappedEvents.map((data) => <WarningText>{`${warningOMSText} ${data.name}.`}</WarningText>)}
                      {unMappedHolidays && <WarningText>{'No holidays configured for this SmartProd Source.'}</WarningText>}
                      {
                        sameEvents.length > 0 &&
                        <>
                          <WarningText>
                            {'SmartVolume algorithm cannot learn from multiple recurring events for same category, same OMS code and overlapping dates. Below are such events'}
                          </WarningText>
                          <WarningUiElement>
                            {
                              sameEvents.map((events) => {
                                return <WarningLiElement><WarningLiText>{`Event ${events[1].name} with date range ${formatDate(events[1].startDate)} - ${formatDate(events[1].endDate)} is overlapped with event ${events[0].name} having data range ${formatDate(events[0].startDate)} - ${formatDate(events[0].endDate)}.`}</WarningLiText></WarningLiElement>
                              })
                            }
                          </WarningUiElement>
                        </>
                      }
                    </>
                  ) :
                    <WarningText><FormattedMessage {...messages.noWarningsAvailable}/></WarningText>
                }
              </Section>
            }
            <Section message={messages.eventsTitle}>
              {allEventCategories && allEventSubCategories && allOmsCodes && allOrigins && (
                <>
                  <EventAddForm
                    forecastEdit={isEdit}
                    handleReloadData={handleReloadData}
                    smartProdSourceId={entity.id}
                    allEventCategories={allEventCategories}
                    setOpenedEventDetail={setOpenedEventDetail}
                  />
                  <EventsTable
                    forecastEdit={isEdit}
                    eventsData={events}
                    reloadData={handleReloadData}
                    smartProdSourceId={entity.id}
                    allEventCategories={allEventCategories}
                    allEventSubCategories={allEventSubCategories}
                    allOmsCodes={allOmsCodes}
                    allOrigins={allOrigins}
                    openedEventDetail={openedEventDetail}
                    setOpenedEventDetail={setOpenedEventDetail}
                    isAdmin={isAdmin}
                  />
                </>
              )}
            </Section>
            <Section message={messages.holidaysTitle}>
              <Holidays
                entity={entity}
                forecastEdit={isEdit}
                holidaysData={holidays}
                allOmsCodes={allOmsCodes}
                planFacilities={[entity.facility]}
                allSmartProdSources={[]}
                smartProdSources={smartProdSources}
                handleStopAutoAssign={handleStopAutoAssign}
                handleAutoAssign={handleAutoAssign}
                holidayWasAssignedFlag={holidayWasAssignedFlag}
                handleHolidayAssigment={handleHolidayAssigment}
              />
            </Section>
            <Section message={messages.smartVolumeRun}>
              <SmartProdVolumeRunsTable data={data} {...dataProps} />
            </Section>
            <Section message={messages.usedPlanAndPATitleBase}>
              <UsedPlansAndPA entity={entity} />
            </Section>
            {/* SMP-4273 */}
            {/* <Section message={messages.omsCodeTitle}>
              <OMSCodesAddForm
                isAdmin={isAdmin}
                isEdit={isEdit}
                handleReloadData={handleReloadOMSData}
                smartProdSourceId={entity.id}
                allOMSCodes={allOmsCodes}
                formik={formik}
              />
              <OMSCodesTable handleReloadData={handleReloadOMSData} entity={entity} formik={formik} isEdit={isEdit} isAdmin={isAdmin} />
            </Section> */}
          </>
        )}
      </Wrap>
    </Formik>
  );
};
// @ts-ignore
export default compose(injectIntl, withSecurity(PERMISSIONS.VIEW_SMA))(SmartProdSourceDetailPage);
