export const getPeriod = (props, index) => {
  const period =
    props && props.formik && props.formik.values.planningParameters && props.formik.values.planningParameters.periods
      ? props.formik.values.planningParameters.periods[index]
      : {};
  return period || {};
};

export const getWorkZonePeriodsForPeriod = (props, index) => {
  const period = getPeriod(props, index);
  return period.workZonePeriods || [];
};

export const getApCalculatedForPeriod = (props, index) => {
  const period = getPeriod(props, index);
  return period.apCalculated || {};
};

export const getMhesForPeriodAndActivity = (props, periodIndex, activityIndex, isIndirect) => {
  const apc = getApCalculatedForPeriod(props, periodIndex);
  return (isIndirect ? apc.indirect[activityIndex].mhes : apc.direct[activityIndex].mhes) || [];
};


export const getShiftsForPeriodAndActivity = (props, periodIndex, activityIndex, isIndirect) => {
  const apc = getApCalculatedForPeriod(props, periodIndex);
  return (isIndirect ? apc.indirect[activityIndex].perShift : apc.direct[activityIndex].perShift) || [];
};

export const getRolesForPeriodAndActivity = (props, periodIndex, activityIndex, isIndirect) => {
  const apc = getApCalculatedForPeriod(props, periodIndex);
  return (isIndirect ? apc.indirect[activityIndex].roles : apc.direct[activityIndex].roles) || [];
};
