import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'formik';
import { injectIntl } from 'react-intl';
import styled from 'styled-components';

import AutocompleteSelect from '../AutocompleteSelect';
import { EditableListItemWrapper } from '../DetailPageShared';
import Label from '../Label';
import messages from './messages';

const ItemWrapper = styled(EditableListItemWrapper)`
  grid-template-columns: 5em auto;
`;

const WideLabel = styled(Label)`
  grid-column-end: span 2;
`;

const WideCell = styled.div`
  grid-column-end: span 2;
`;

function DepartmentItem(props) {
  const {
    intl: { formatMessage },
  } = props;
  const [error, setError] = useState('');

  function validate(departments, name, facilityId) {
    let result = '';
    const specialChars = ['\\', ',', '_'];

    for (let i = 0; i < specialChars.length; i++) {
      if (name.includes(specialChars[i])) {
        result = 'specialCharactersError';
      }
    }
    if (!name || name.length === 0) {
      result = 'emptyValueError';
    } else if (
      departments.find(d => d.id && d.name.toLowerCase() === name.toLowerCase() && d.facilityId === facilityId)
    ) {
      result = 'uniqueValueError';
    }

    return result;
  }

  function renderEditFieldForName() {
    const errorMsg = error ? (
      <WideCell key="name-error">
        <div style={{ color: 'red' }}>{formatMessage((messages[error] && messages[error]) || messages.error)}</div>
      </WideCell>
    ) : null;

    return [
      <WideCell key="name-input-cell">
        <input
          maxLength={45}
          key="name-input"
          value={props.item.name}
          onChange={e => {
            const { value } = e.target;

            setError(validate(props.formik.values.planningParameters.departments, value, props.item.facilityId));

            props.onChange(value, 'name');
          }}
        />
      </WideCell>,
      errorMsg,
    ];
  }

  function getAvailableFacilities() {
    return (props.formik.values.facilities || props.formik.values.planningArea.facilities).filter(f => f.id);
  }

  function getFacilityById(facilityId) {
    return facilityId ? getAvailableFacilities().find(f => f.id === facilityId) : null;
  }

  function renderEditable() {
    // Name field is editable only during creation
    const name = props.isNewRow ? renderEditFieldForName() : <div>{props.item.name}</div>;

    return (
      <ItemWrapper dark>
        <Label {...messages.name} />
        {name}
        <Label {...messages.facility} />

        <WideCell key="facilities-dropdown">
          <AutocompleteSelect
            key="facilities-dropdown-select"
            entity="facilities"
            preloadedData={getAvailableFacilities()}
            getOptionLabel={option => {
              const facility = getFacilityById(option.id);
              return facility ? `${facility.code} : ${facility.name}` : '';
            }}
            setValue={value => {
              props.onChange(value.id, 'facilityId');
              setError(validate(props.formik.values.planningParameters.departments, props.item.name, value.id));
            }}
            token={props.token}
            value={{ id: props.item.facilityId }}
          />
        </WideCell>
        {props.children}
      </ItemWrapper>
    );
  }

  function renderReadOnly() {
    if (props.item) {
      const facility = getFacilityById(props.item.facilityId);

      return (
        <ItemWrapper dark>
          <Label {...messages.name} />
          <div>{props.item && props.item.name}</div>
          <Label {...messages.facility} />
          <div>{facility ? `${facility.code} : ${facility.name}` : ''}</div>
          {props.children}
        </ItemWrapper>
      );
    }
    return <div />;
  }

  return props.editable ? renderEditable() : renderReadOnly();
}

DepartmentItem.propTypes = {
  item: PropTypes.object,
  children: PropTypes.node,
  editable: PropTypes.bool,
  token: PropTypes.string,
  onChange: PropTypes.func,
  isNewRow: PropTypes.bool,
};

export default injectIntl(connect(DepartmentItem));
