/* eslint-disable no-restricted-syntax */
/**
 *
 * ActualVolume
 *
 */

import React from 'react';
import { connect as formikConnect, getIn } from 'formik';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { compose } from 'redux';
import { FormattedMessage, injectIntl } from 'react-intl';

import Table from 'components/Table';
import Button from 'components/Button';
import { TABLE_DEFAULTS } from 'containers/App/constants';
import cloneDeep from 'lodash/cloneDeep';
import messages from './messages';
import { createStandardActualVolumeColumnDefs, isDaily } from '../PlannedVolumeTable/volume';
import { toNumber } from '../../utils/utils';

const TableWrap = styled.div`
  display: flex;
  height: ${props => props.height}px;
`;

const Title = styled.div`
  font-size: ${props => props.theme.fontSize.title};
  font-weight: 700;
  margin-bottom: 8px;
  margin-top: 10px;
`;

const ButtonsWrap = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 2px;
  > * {
    margin: 0 2px;
  }
`;

const ToggleButtonStyled = styled(Button)`
  background-color: ${props => (props.value ? props.theme.color.yellow : props.theme.color.grey1)};
`;

const renderTable = (props, rowData, categoryMessage, sectionMessage, varPostfix) => {
  const granularity = isDaily(props) ? 'dailyRowData' : 'rowData';
  const onCellValueChanged = params => {
    const fieldName = `planningParameters.actualsVolumeCategoryParameters.${granularity}${varPostfix}.${
      params.data.index
    }.${params.colDef.field}`;
    props.formik.setFieldValue(fieldName, toNumber(params.newValue));
  };

  const onPaste = data => {
    const fieldName = `planningParameters.actualsVolumeCategoryParameters.${granularity}${varPostfix}`;
    props.formik.setFieldValue(fieldName, data);
  };

  const aproxHeight = rowData.length * 35 + 90;
  const columnDefs = createStandardActualVolumeColumnDefs(
    props,
    messages,
    categoryMessage,
    'actualsVolumeCategoryParameters',
    isDaily(props),
  );
  return (
    <div>
      <Title>
        <FormattedMessage {...sectionMessage} />
      </Title>
      <TableWrap height={aproxHeight}>
        <Table
          name={`actualVolumeTable${varPostfix}`}
          messages={messages}
          defaultConfig={TABLE_DEFAULTS.plannedVolumeTableConfig}
          pagination={false}
          columnDefs={columnDefs}
          rowData={rowData}
          onGridReady={params => {
            // do nothing - suppress params.api.sizeColumnsToFit();
          }}
          onCellValueChanged={onCellValueChanged}
          onPaste={onPaste}
        />
      </TableWrap>
    </div>
  );
};

const ActualVolume = props => {
  const granularity = isDaily(props) ? 'dailyRowData' : 'rowData';
  const rowData = cloneDeep(
    getIn(props, `formik.values.planningParameters.actualsVolumeCategoryParameters.${granularity}`, []),
  );
  const rowDataVar = cloneDeep(
    getIn(props, `formik.values.planningParameters.actualsVolumeCategoryParameters.${granularity}Var`, []),
  );
  return (
    <>
      {rowData.length > 0 && renderTable(props, rowData, messages.category, messages.categorySection, '')}
      {rowDataVar.length > 0 &&
        renderTable(props, rowDataVar, messages.categoryVariable, messages.categoryVariableSection, 'Var')}
    </>
  );
};

function areEqual(prevProps, nextProps) {
  if(prevProps.showFormula !== nextProps.showFormula){
    return false;
  }
  if(prevProps.showValue !== nextProps.showValue){
    return false;
  }
  return (
    prevProps.edit === nextProps.edit &&
    getIn(prevProps, 'formik.values.reloadCount', 0) === getIn(nextProps, 'formik.values.reloadCount', 0)
  );
}

ActualVolume.propTypes = {
  edit: PropTypes.bool,
  formik: PropTypes.object,
};

export default compose(
  formikConnect,
  injectIntl,
)(React.memo(ActualVolume, areEqual));
