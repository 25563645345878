/**
 *
 * Switch
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const BGDisabled = styled.div`
  display: flex;
  position: relative;
  z-index: 0;
  background-color: ${props => props.theme.color.grey1};
`;
const BGActive = styled.div`
  position: absolute;
  width: 50%;
  height: 100%;
  transition: ${props => props.theme.transitions.slow};
  left: ${props => (props.isOption1 ? 0 : 'calc( 50% + 1px)')};
  background-color: ${props => props.theme.color.yellow};
  z-index: 1;
`;
const Text = styled.div`
  font-size: 90%;
  transition: ${props => props.theme.transitions.fast};
  position: relative;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 20px;
  z-index: 2;
  white-space: nowrap;
  > svg {
    margin-right: ${props => (props.label !== undefined ? '5px' : '0px')};
  }
  ${props =>
    props.isActive &&
    css`
      transform: scale(1.1);
      :hover {
        cursor: inherit;
      }
    `};
  ${props =>
    !props.isActive &&
    css`
      :hover {
        cursor: pointer;
        box-shadow: ${props.theme.shadow.buttonBigInset};
      }
    `};
`;

const Wrap = styled.div`
  display: flex;
  box-shadow: ${props => props.theme.shadow.button};
`;

/* eslint-disable react/prefer-stateless-function */
class Switch extends React.PureComponent {
  handleClick = () => {
    const { option1, option2, value, onChange } = this.props;
    const isOption1 = option1.value === value;
    onChange(isOption1 ? option2.value : option1.value);
  };

  render() {
    const { option1, option2, value } = this.props;
    const isOption1 = option1.value === value;
    return (
      <Wrap>
        <BGDisabled>
          <Text
            isActive={isOption1}
            onClick={!isOption1 ? this.handleClick : undefined}
            label={option1.label}
            title={option1.title}
          >
            {option1.icon && <FontAwesomeIcon icon={option1.icon} />}
            {option1.label}
          </Text>
          <Text
            isActive={!isOption1}
            onClick={isOption1 ? this.handleClick : undefined}
            label={option2.label}
            title={option2.title}
          >
            {option2.icon && <FontAwesomeIcon icon={option2.icon} />}
            {option2.label}
          </Text>
          <BGActive isOption1={isOption1} />
        </BGDisabled>
      </Wrap>
    );
  }
}

Switch.propTypes = {
  option1: PropTypes.shape({
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    title: PropTypes.string,
    value: PropTypes.any.isRequired,
    icon: PropTypes.string,
  }).isRequired,
  option2: PropTypes.shape({
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    title: PropTypes.string,
    value: PropTypes.any.isRequired,
    icon: PropTypes.string,
  }).isRequired,
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default Switch;
