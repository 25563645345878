import styled from 'styled-components';

export default styled.div`
  margin: 0px 10px;
  flex-grow: 1;
  min-height: 48vh;
  display: flex;
  box-shadow: ${props => props.theme.shadow.pageBox};
  .ag-theme-balham .ag-row.deleted-row {
    background-color: ${props => props.theme.color.grey4};
  }
  .ag-theme-balham .ag-row.past-row {
    color: ${props => props.theme.color.red};
  }
  .ag-theme-balham .ag-row.expiry-row {
    color: ${props => props.theme.color.orange};
  }
  .ag-theme-balham .ag-row.future-row {
    color: ${props => props.theme.color.black};
  }
`;
