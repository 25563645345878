import { takeLatest, call, put, select } from 'redux-saga/effects';
import { FETCH_LANGUAGES, SAVE_LANGUAGES } from 'components/LanguageProvider/constants';
import { withUrl, api } from 'utils/api';

const saveLanguages = data => ({
  type: SAVE_LANGUAGES,
  data,
});

function* fetchLanguages(action) {
  const response = yield call(api, withUrl(`/language/`));
  if (response.isOk) {
    yield put(saveLanguages(response.data));
  }
}

export default function* defaultSaga() {
  yield takeLatest(FETCH_LANGUAGES, fetchLanguages);
}
