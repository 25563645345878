import React from 'react';
import { injectIntl } from 'react-intl';
import styled from 'styled-components';
import { ColDef } from 'ag-grid-community';

import AgTable from 'components/Table';
import Label from 'components/Label';
import messages from './messages';
import ScheduleRunRendererErrorsTooltip from 'containers/ShiftScheduleRunDetailPage/ScheduleRunRendererErrorsTooltip';
import ScheduleRunRendererWarningTooltip from 'containers/ShiftScheduleRunDetailPage/ScheduleRunRendererWarningTooltip';

export interface ErrorsWarningsProps {
  error?: any;
  warning?: any;
  errorMessages?: any;
  warningMessages?: any;
  errorDescriptions?: any;
  intl: any;
}

const TableWithHeight = styled(AgTable)`
  height: ${props => props.height}px;
  padding-left: 25px;
`;

const WrappingLabel = styled(Label)`
  font-size: 15px;
  padding-left: 25px;
  padding-bottom: 10px;
`;


const ErrorsWarnings = ((props: ErrorsWarningsProps) => {

  const createColumnsDef = () => {
    const handleDateCell = params => {
      if (params?.value) {
        const newValue = new Date(params.value);
        //@ts-ignore
        if(newValue == "Invalid Date"){
          const initialSplittedDate =  params.value.split(' ');
          const splittedDate = initialSplittedDate[0].split(/\//);
          return splittedDate[2] + '-' + splittedDate[1] + '-' + splittedDate[0];
        }
        const year = newValue.toLocaleString("default", { year: "numeric" });
        const month = newValue.toLocaleString("default", { month: "2-digit" });
        const day = newValue.toLocaleString("default", { day: "2-digit" });
        const formattedDate = year + "-" + month + "-" + day;
        return formattedDate;
      } else {
        return params.value;
      }
    }
    const handleSecondaryObject = params => {
      if(params.value && params.value.length > 0){
        var str = params.value,
        delimiter = '/',
        start = 3,
        tokens = str?.split(delimiter).slice(start),
        result = tokens?.join(delimiter);
        return result ? `/${result}` : params.value;
      } else {
        return params.value
      }
    }
    const colDefs: ColDef[] = [
      {
        colId: 'name',
        field: 'name',
        headerName: props.intl.formatMessage(messages.nameEW),
        width: 100,
        suppressSizeToFit: true,
      },
      {
        colId: 'primaryObject',
        field: 'primaryObject',
        headerName: props.intl.formatMessage(messages.primaryObject),
        width: 125,
        suppressSizeToFit: true,
      },
      {
        colId: 'description',
        field: 'description',
        headerName: props.intl.formatMessage(messages.description),
        tooltip: params => params.value,
        width: 280,
        suppressSizeToFit: true,
      },
      {
        colId: 'secondaryObject',
        field: 'secondaryObject',
        headerName: props.intl.formatMessage(messages.secondaryObject),
        width: 250,
        valueFormatter: handleSecondaryObject,
        suppressSizeToFit: true,
      },
      {
        colId: 'dateFrom',
        field: 'dateFrom',
        headerName: props.intl.formatMessage(messages.dateFrom),
        width: 100,
        suppressSizeToFit: true,
        valueFormatter: handleDateCell
      },
      {
        colId: 'dateTo',
        field: 'dateTo',
        headerName: props.intl.formatMessage(messages.dateTo),
        width: 100,
        suppressSizeToFit: true,
        valueFormatter: handleDateCell
      },
    ];
    if(props.error){
      colDefs.push(
        {
          colId: 'howToFix',
          field: 'howToFix',
          cellRendererFramework: ScheduleRunRendererErrorsTooltip,
          headerName: props.intl.formatMessage(messages.howToFix),
          width: 100,
          suppressSizeToFit: true,
          cellClass: 'ag-tooltipCenter-aligned-cell'
        }
      )
    }
    if(props.warning){
      colDefs.push(
        {
          colId: 'howToFix',
          field: 'howToFix',
          cellRendererFramework: ScheduleRunRendererWarningTooltip,
          headerName: props.intl.formatMessage(messages.howToFix),
          width: 100,
          suppressSizeToFit: true,
          cellClass: 'ag-tooltipCenter-aligned-cell',
          suppressMenu: true
        }
      )
    }
    return colDefs;
  }
  const tableData = props.error ? props.error : props.warning;
  tableData?.forEach((data)=>{
    data.code = data?.name?.split('-')[1]
  })
  tableData?.sort((a,b) => a.code - b.code);


  return (
    <div>
      {props.errorMessages && <WrappingLabel {...props.errorMessages} />}
      {props.warningMessages && <WrappingLabel {...props.warningMessages} />}
      <TableWithHeight
        columnDefs={createColumnsDef()}
        rowData={tableData}
        pagination
        paginationPageSize={50}
        domLayout="autoHeight"
      />
    </div>
  )
}
)

export default injectIntl(ErrorsWarnings);