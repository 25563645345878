// @flow

import React, { useEffect, useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import find from 'lodash/find';
import { withTheme } from 'styled-components';
import { useActionCreators, useSelectors } from 'use-redux';

import { LOCALE_KEY } from 'components/LanguageProvider/constants';
import { changeLocale, fetchLanguages, getLanguages, getLocale } from 'components/LanguageProvider/redux';
import injectSaga from 'utils/injectSaga';

import saga from './saga';
import { useStyles } from './styled';

type Props = {
  theme: Object,
};

function LanguageSelector(props: Props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [locale, languages]: [string, Array<Object>] = useSelectors(getLocale, getLanguages);
  const [loadLanguages, changeLanguage] = useActionCreators(fetchLanguages, changeLocale);
  const classes = useStyles(props.theme)();
  const open = Boolean(anchorEl);

  // ComponenDidMount
  useEffect(() => {
    if (languages === null) {
      loadLanguages();
    }
  }, []);

  if (!languages || languages.length === 0) return <div />;

  const handleChange = (locale: string) => (event: Object) => {
    localStorage && localStorage.setItem(LOCALE_KEY, locale);
    changeLanguage(locale);
    setAnchorEl(null);
  };

  function handleMenu(event: Object) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  let value = find(languages, { code: locale });
  if (!value) value = find(languages, { code: 'en' });
  if (!value) value = languages[0];

  // TODO: fix it with new fiel
  return (
    <>
      <IconButton id="topbar-button-languageSelect" onClick={handleMenu} className={classes.iconButton}>
        <span className={`${classes.icon} flag-icon flag-icon-${value.flagCode.toLowerCase()}`} />
      </IconButton>
      <Menu
        id="language-selector"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        onClose={handleClose}
      >
        {languages.map(({ code, flagCode, nameNative }) => (
          <MenuItem id={`topbar-languageSelect-button-${code}`} onClick={handleChange(code)} key={code} value={code}>
            <span className={`${classes.iconItem} flag-icon-background flag-icon-${flagCode.toLowerCase()}`} />
            {nameNative}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

export default injectSaga({ key: 'languageSelector', saga })(withTheme(LanguageSelector));
