/**
 *
 * AdminUpload
 *
 */

import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { logoutAction } from 'containers/LoginPage/actions';
import { closeUploadModal, uploadAdminFileAction } from 'containers/UploadModal/actions';
import { makeSelectUploadModal } from 'containers/UploadModal/selectors';
import injectSaga from 'utils/injectSaga';
import saga from 'containers/UploadModal/saga';
import messages from './messages';
import Lb from 'components/Label';
import DatePicker from 'components/DatePicker';
import UploadMultipleFiles from './UploadMultipleFiles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';

const Label = styled(Lb)`
  height: ${props => props.theme.grid.rowHeight};
  display: flex;
  align-items: center;
  margin-right: 30px;
`;

const StartDateContainer = styled.div`
display: flex;
margin-bottom: 15px;
`;

const EndDateContainer = styled.div`
display: flex;
margin-bottom: 15px;
margin-left: 3px;
`;

/* eslint-disable react/prefer-stateless-function */
const AdminUpload = (props) => {
  const [startdate, setStartDate] = React.useState();
  const [enddate, setEndDate] = React.useState();
  const [formatstartdate, setformatstartdate] = React.useState();
  const [formatenddate, setformatenddate] = React.useState();

  const handleCloseModal = () => {
    props.resetModal();
    setEndDate();
    setStartDate();
    setformatenddate();
    setformatstartdate();
  };

  const onDateChange = (dateType, value) => {
    if (dateType === 'startDay' && value.startOf('day')) {
      setStartDate(value);
    }
    if (dateType === 'endDay' && value.startOf('day')) {
      setEndDate(value);
    }
  };

  const formattedDate = (val) => {
    const newValue = new Date(val);
    const year = newValue.toLocaleString("default", { year: "numeric" });
    const month = newValue.toLocaleString("default", { month: "2-digit" });
    const day = newValue.toLocaleString("default", { day: "2-digit" });
    const formattedDate = year + "-" + month + "-" + day;
    return formattedDate;
  }

  const onAcceptEndDate = value => {
    onDateChange('endDay', value);
    setformatenddate(formattedDate(value))

  };

  const onAcceptStartDate = value => {
    onDateChange('startDay', value);
    setformatstartdate(formattedDate(value))

  };

  const uploadFile = (files) => {
    props.uploadAdminFile(files, formatstartdate, formatenddate);
    handleCloseModal();
  };

  return (
    <>
      <Dialog
        open={props.modalFlag}
        onBackdropClick={handleCloseModal}
      >
        <DialogTitle>SmartVolume Manual Update</DialogTitle>
        <DialogContent>
          <StartDateContainer>
            <Label {...messages.uploadStartDate} />

            <DatePicker
              style={{width:'90px'}}
              value={startdate ? startdate : null}
              onAccept={onAcceptStartDate}
            />
          </StartDateContainer>
          <EndDateContainer>
            <Label {...messages.uploadEndDate} />
            <DatePicker
              style={{width:'90px'}}
              value={enddate ? enddate : null}
              onAccept={onAcceptEndDate}
              minDate={startdate}
            />
          </EndDateContainer>
          <UploadMultipleFiles startdate={startdate} enddate={enddate} handleCloseModal={handleCloseModal} uploadFile={uploadFile} />
        </DialogContent>
      </Dialog>
    </>
  );
}

const mapStateToProps = createStructuredSelector({
  modalFlag: makeSelectUploadModal(),
});

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators(
      {
        logout: logoutAction,
        resetModal: closeUploadModal,
        uploadAdminFile: uploadAdminFileAction
      },
      dispatch,
    ),
    dispatch,
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withSaga = injectSaga({ key: `uploadModal`, saga });
export default compose(withConnect, withSaga)(AdminUpload);
