import { defineMessages } from 'react-intl';

export default defineMessages({
  required: {
    id: 'app.util.Validations.required',
    defaultMessage: 'Field is mandatory.',
  },
  notEmpty: {
    id: 'app.util.Validations.notEmpty',
    defaultMessage: 'Field must not be empty string.',
  },
  requiredArray: {
    id: 'app.util.Validations.requiredArray',
    defaultMessage: 'Field is mandatory.',
  },
  maxLength: {
    id: 'app.util.Validations.maxLength',
    defaultMessage: 'Has to be at most {max} characters or less.',
  },
  minValue: {
    id: 'app.util.Validations.minValue',
    defaultMessage: 'Has to be at least {min}.',
  },
  minLength: {
    id: 'app.util.Validations.minLength',
    defaultMessage: 'Has to be {min} or more characters.',
  },
  number: {
    id: 'app.util.Validations.number',
    defaultMessage: 'Has to be a number.',
  },
  email: {
    id: 'app.util.Validations.email',
    defaultMessage: 'Not a valid email.',
  },
  alphaNumeric: {
    id: 'app.util.Validations.alphaNumeric',
    defaultMessage: 'Has to contain alpha numeric characters only.',
  },
  phoneNumber: {
    id: 'app.util.Validations.phoneNumber',
    defaultMessage: 'Not a valid telephone number.',
  },
  sameAs: {
    id: 'app.util.Validations.sameAs',
    defaultMessage: 'Value has to be same as {sameAsText}',
  },
  password: {
    id: 'app.util.Validations.password',
    defaultMessage: 'Password must contain {capital}, {number} and has to be at least {length} characters',
  },
  password_capital: {
    id: 'app.util.Validations.password_capital',
    defaultMessage: 'capital letter',
  },
  password_number: {
    id: 'app.util.Validations.password_number',
    defaultMessage: 'number',
  },
  password_length: {
    id: 'app.util.Validations.password_length',
    defaultMessage: '7 characters',
  },
});
