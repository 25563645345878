import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import messages from './messages';
import { parseTime } from './calcData';

const WrapTooltip = styled.div``;
const Name = styled.div``;
const StartTime = styled.div``;
const EndTime = styled.div``;
const Days = styled.div``;
const HeadCount = styled.div`
  margin-left: 20px;
`;
const BreakTime = styled.div`
  margin-left: 20px;
`;
const Labor = styled.div`
  margin-top: 10px;
`;

export default item => (
  <WrapTooltip>
    <Name>
      <FormattedMessage {...messages.name} />
      &nbsp;{item.name}
    </Name>
    {item.startTime && (
      <StartTime>
        <FormattedMessage {...messages.startTime} />
        &nbsp;
        {parseTime(item.startTime)
          .filter((_, index) => index < 2)
          .join(':')}
      </StartTime>
    )}
    {item.endTime && (
      <EndTime>
        <FormattedMessage {...messages.endTime} />
        &nbsp;
        {parseTime(item.endTime)
          .filter((_, index) => index < 2)
          .join(':')}
      </EndTime>
    )}
    {item.days && (
      <Days>
        <FormattedMessage {...messages.days} />
        &nbsp;{item.days.map(i => `${i.substr(0, 1)}${i.substr(1, i.length).toLowerCase()}`).join(', ')}
      </Days>
    )}
    {item.labourCategoryTransitions &&
      item.labourCategoryTransitions.map(labor => (
        <Labor key={labor.labourCategory.id}>
          <Name>
            <FormattedMessage {...messages.laborName} />
            &nbsp;{labor.labourCategory.name}
          </Name>
          <HeadCount>
            <FormattedMessage {...messages.headCount} />
            &nbsp;{labor.headCount}
          </HeadCount>
          <BreakTime>
            <FormattedMessage {...messages.breakTime} />
            &nbsp;{labor.breakTime}
          </BreakTime>
        </Labor>
      ))}
  </WrapTooltip>
);
