import React from 'react';
import { DAY_NAMES } from 'utils/calendar/constants';
import calendarMessages from 'utils/calendar/messages';
import { DayTransformationToggleButtonStyled } from 'containers/DayToHourTransformation';

const AdjustmentDays = ({ props, handleToggle, selectedDays }) => {
  return (DAY_NAMES).map(dayName => {
    const isDaySelected = selectedDays.includes(dayName);
    return (
      <DayTransformationToggleButtonStyled
        key={dayName}
        value={isDaySelected}
        onClick={() => {
          handleToggle(dayName);
        }}
      >
        {dayName && props && props.intl && props.intl.formatMessage(calendarMessages[dayName.toLowerCase()])}
      </DayTransformationToggleButtonStyled>
    );
  });
}

export default AdjustmentDays;