import { defineMessages } from 'react-intl';


export default defineMessages({
  shiftEqualStart: {
    id: 'app.components.ShiftDialog.shiftEqualStart',
    defaultMessage: '% in time transformation table will be distributed equally to other working shifts on the day you are trying to unassign',
  },
  shiftEqualList1: {
    id: 'app.components.ShiftDialog.shiftEqualList1',
    defaultMessage: 'There is 50% set for Morning shift on Monday.'
  },
  shiftEqualList2: {
    id: 'app.components.ShiftDialog.shiftEqualList2',
    defaultMessage: 'There are also 2 more shifts on Monday.'
  },
  shiftEqualList3: {
    id: 'app.components.ShiftDialog.shiftEqualList3',
    defaultMessage: 'I unassign Monday from Morning shift. 50% is distributed equally: + 25% to other shifts on this day.'
  },
  shiftProportionalStart: {
    id: 'app.components.ShiftDialog.shiftProportionalStart',
    defaultMessage: '% in time transformation table will be distributed proportionally to other working shifts on the day you are trying to unassign',
  },
  example: {
    id: 'app.components.ShiftDialog.example',
    defaultMessage: 'Example:',
  },
  shiftProportionalList1: {
    id: 'app.components.ShiftDialog.shiftProportionalList1',
    defaultMessage: 'There is 50% set for Morning shift on Monday.'
  },
  shiftProportionalList2: {
    id: 'app.components.ShiftDialog.shiftProportionalList2',
    defaultMessage: 'There are also two more shifts on Monday. 30% Night and 20% Afternoon Shifts.'
  },
  shiftProportionalList3: {
    id: 'app.components.ShiftDialog.shiftProportionalList3',
    defaultMessage: 'I unassign Monday from Morning shift. 50% is distributed proportionally: + 30% to Night and 20% to Afternoon Shift.'
  },
});
