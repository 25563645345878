/*
 *
 * PlanDetailPage reducer
 *
 */

import { fromJS } from 'immutable';
import { SAVE_PA } from 'containers/PlanningAreaDetailPage/constants';
import {
  START_NEW,
  CLEAR_PLAN,
  STORE_PLAN,
  SAVE_PLAN,
  SAVE_MASTER_PLAN_CONFLICT,
  SUBMIT_ERROR,
  CLEAR_MASTER_PLAN_CONFLICT,
  SAVE_PLANDETAIL_MASTERPLANS,
  SAVE_PLAN_DETAIL_SMARTPROD_SMARTVOLUME_RUNS,
  RESET_PLAN,
  OPEN_MASTER_PLAN_DIALOG,
  CLOSE_MASTER_PLAN_DIALOG,
  STORE_PLAN_DETAILS,
  PLAN_DETAIL_SMARTPROD_SMARTVOLUME_RUNS_FROM_SAGA,
  PLAN_DETAILS_LOADER,
  OPEN_SCHEDULE_DIALOG,
  CLOSE_SCHEDULE_DIALOG,
  PLAN_RATIO_WARNING_FLAG,
  SAVE_PLANDETAIL_SCHEDULES,
  SAVE_ROLES,
  PLAN_DETAILS_LOADER_FLAG,
  PLAN_DETAILS_LOADER_OVERVIEW_FLAG,
  PLAN_DETAIL_SMARTPROD_SMARTVOLUME_RUNS,
  SAVE_PLAN_SMART_CODE_MAPPINGS,
  TOGGLE_VOLUME_FORMULA,
  TOGGLE_VOLUME_VALUE,
  TOGGLE_VOLUME_FORMULA_VAR,
  TOGGLE_VOLUME_VALUE_VAR,
  CLEAR_PLANDETAIL_MASTERPLANS,
  SAVE_OMS_CHANGE_HISTORY,
} from './constants';
import { DEFAULT_GUI_STATE, handleCommonEventsForPlan } from '../../utils/commonDetailReducer';

export const initialState = fromJS({
  plan: null,
  guiState: DEFAULT_GUI_STATE,
  activityIdsMheOpen: [],
  activityIdsShiftOpen: [],
  activityIdsRoleOpen: [],
  showValue: true,
  showFormula: true,
  showValueVar: true,
  showFormulaVar: true,
});

function planDetailPageReducer(state = initialState, action) {
  const commonResult = handleCommonEventsForPlan(state, action);
  if (commonResult) {
    return commonResult;
  }
  switch (action.type) {
    case CLEAR_PLAN:
    case SAVE_PA:
      return state.set('plan', null);
    case RESET_PLAN:
      return state.set('plan', null);
    case START_NEW:
      return initialState.setIn(['guiState', 'edit'], true).setIn(['guiState', 'isNew'], true);
    case SUBMIT_ERROR:
      return state.setIn(['guiState', 'submitError'], true);
    case SAVE_PLAN:
      return state.setIn(['guiState', 'submitError'], false);
    case SAVE_MASTER_PLAN_CONFLICT:
      return state.setIn(['guiState', 'submitError'], false).setIn(['guiState', 'masterPlanConflict'], action.payload);
    case CLEAR_MASTER_PLAN_CONFLICT:
      return state.setIn(['guiState', 'masterPlanConflict'], null);
    case SAVE_PLANDETAIL_MASTERPLANS:
      return state.set('planDetailMasterPlans', action.payload);
    case CLEAR_PLANDETAIL_MASTERPLANS:  
    return state.set('planDetailMasterPlans', null);
    case SAVE_PLANDETAIL_SCHEDULES:
      return state.set('planDetailSchedules', action.payload);
    case PLAN_DETAIL_SMARTPROD_SMARTVOLUME_RUNS_FROM_SAGA:
      return state.set('planWarnings',true);
    case PLAN_DETAIL_SMARTPROD_SMARTVOLUME_RUNS:
      return state.set('planWarnings',true);
    case PLAN_DETAILS_LOADER:
      return state.set('plandetailsWarning', action.payload)
    case PLAN_DETAILS_LOADER_FLAG:
      return state.set('plandetailsFlag', action.payload);
    case PLAN_DETAILS_LOADER_OVERVIEW_FLAG:
      return state.set('planOverviewFlag', action.payload)
    case SAVE_PLAN_DETAIL_SMARTPROD_SMARTVOLUME_RUNS:
      return state.set('planDetailSmartRuns', action.payload).set('planWarnings', false);
    case SAVE_PLAN_SMART_CODE_MAPPINGS:
      return state.set('planSmartCodeMappings', action.payload);
      case OPEN_MASTER_PLAN_DIALOG:
      return state.set('masterplanDialog', true);
    case CLOSE_MASTER_PLAN_DIALOG:
      return state.set('masterplanDialog', false);
    case OPEN_SCHEDULE_DIALOG:
      return state.set('scheduleDialog', true);
    case CLOSE_SCHEDULE_DIALOG:
      return state.set('scheduleDialog', false);
    case STORE_PLAN_DETAILS:
      return state.set('planDetailsforMP',action.payload);
    case PLAN_RATIO_WARNING_FLAG:
      return state.set('planRationWarningFlag', action.payload);
    case SAVE_ROLES:
      return state.set('planRoles', action.payload);
    case TOGGLE_VOLUME_FORMULA:
      return state.set('showFormula', action.payload);
    case TOGGLE_VOLUME_VALUE:
      return state.set('showValue', action.payload);   
    case TOGGLE_VOLUME_FORMULA_VAR:
      return state.set('showFormulaVar', action.payload);
    case TOGGLE_VOLUME_VALUE_VAR:
      return state.set('showValueVar', action.payload);   
    case SAVE_OMS_CHANGE_HISTORY:
      return state.set('omsChangeHistory', action.payload);  
    default:
      return state;
  }
}

export default planDetailPageReducer;
