/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { isEmpty } from 'lodash';

import IconButton from 'components/IconButton';
import Select from 'components/StyledSelect';
import { lcnameWithRCLabel } from 'utils/utils';

import messages from './messages';

const DivStyledAsMenu = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 20px;
`;

const SelectStyled = styled(Select)`
  margin: 0 20px;
  min-width: 300px;
`;

export default props => {
  const [selectedCategory, setSelectedCategory] = useState();
  const [selectedDepartment, setSelectedDepartment] = useState([]);
  const [departmentOptions, setDepartmentOptions] = useState([]);
  const [roleOptions, setRoleOptions] = useState(props.roles);
  const [selectedRole, setSelectedRole] = useState([]);
  const [addItemButtonFlag, setAddItemButtonFlag] = useState(true);
  const [roleEnableFlag, setRoleEnableFlag] = useState(true);
  React.useEffect(() => {
    if (props.labourAvailabilityType == 'DEPARTMENT' && !props.roleChecked) {
      const usedCategories = selectedCategory && props.rowData && props.rowData.filter((r) => r.labourCategory.id == selectedCategory.labourCategory.id);
      const usedDepartmentIds = usedCategories && usedCategories.map(uc => uc.department && uc.department.id);
      const departmentsAvailable = usedDepartmentIds && props.departments && props.departments.filter(d => !usedDepartmentIds.includes(d.id));
      const options = departmentsAvailable && departmentsAvailable.map(d => ({ value: d.name, label: d.name, id: d.id }))
      setDepartmentOptions(options);
      setSelectedDepartment([]);
    }
    if (props.roleChecked && props.labourAvailabilityType == 'SHIFT_ONLY') {
      const usedCategories = selectedCategory && props.rowData && props.rowData.filter((r) => r.labourCategory.id == selectedCategory.labourCategory.id);
      const usedDepartmentIds = usedCategories && usedCategories.map(uc => uc.role && uc.role.id);
      const departmentsAvailable = usedDepartmentIds && props.roles && props.roles.filter(d => !usedDepartmentIds.includes(d.id));
      const options = departmentsAvailable && departmentsAvailable.map(d => ({ value: d.name, label: d.name, id: d.id, name: d.name }))
      setRoleOptions(options);
      if(props.roleChecked){
        setSelectedRole([]);
      }
      setRoleEnableFlag(false);
    }
    if (props.labourAvailabilityType == 'DEPARTMENT' && props.roleChecked) {
      const usedCategories = selectedCategory && props.rowData && props.rowData.filter((r) => r.labourCategory.id == selectedCategory.labourCategory.id);
      const filterUsedCategories = usedCategories && usedCategories.filter((uc)=> (uc.department && uc.role));
      const usedDepartmentIds = filterUsedCategories && filterUsedCategories.map(uc => (uc.department && uc.department.id));
      if (usedDepartmentIds === null || usedDepartmentIds === undefined) {
        setDepartmentOptions(props.departments && props.departments.map(d => ({ value: d.name, label: d.name, id: d.id })))
      } else {
        const counts = {};

        for (const num of usedDepartmentIds) {
          counts[num] = counts[num] ? counts[num] + 1 : 1;
        }

        const depOptions = [];
        if (Object.keys(counts).length === 0) {
          setDepartmentOptions(props.departments && props.departments.map(d => ({ value: d.name, label: d.name, id: d.id })));
        } else {
          const allDeps = props.departments && props.departments.map((dep) => dep.id);
          allDeps && allDeps.forEach((dep) => {
            if (!counts[dep]) {
              counts[dep] = 0;
            }
          })
          for (const depId in counts) {
            if (counts[depId] === 0) {
              const dept = props.departments && props.departments.filter(d => (d.id == depId));
              const dep = dept && dept.map(d => ({ value: d.name, label: d.name, id: d.id }));
              depOptions.push(...dep);
            } else if (counts[depId] !== (props.roles && props.roles.length)) {
              const dept = props.departments && props.departments.filter(d => (d.id == depId));
              const dep = dept && dept.map(d => ({ value: d.name, label: d.name, id: d.id }));
              depOptions.push(...dep);
            } else if(counts[depId] === (props.roles && props.roles.length)){
              const isLCWithDeptWithoutRole = props.rowData && props.rowData.filter((r) => (r.labourCategory.id == selectedCategory.labourCategory.id) && ((r.department && r.department.id) == depId) && !r.role);
              if(isEmpty(isLCWithDeptWithoutRole)){
                const dept = props.departments && props.departments.filter(d => (d.id == depId));
                const dep = dept && dept.map(d => ({ value: d.name, label: d.name, id: d.id }));
                depOptions.push(...dep);
              }
            }
          }
          let result = depOptions.filter(
            (person, index) => index === depOptions.findIndex(
              other => person.id === other.id
            ));
            setDepartmentOptions(result);
        }
      }
      setSelectedDepartment([]);
      setSelectedRole([]);
    }
    if (props.labourAvailabilityType == 'SHIFT_ONLY' && !props.roleChecked && selectedCategory) {
      setAddItemButtonFlag(false);
    }
  }, [selectedCategory]);

  React.useEffect(() => {
    if (props.labourAvailabilityType == 'DEPARTMENT' && !props.roleChecked && (selectedCategory) && !checkDeptEmptyFlag()) {
      setAddItemButtonFlag(false);
    }
    if (props.labourAvailabilityType == 'DEPARTMENT' && !props.roleChecked && (selectedCategory) && checkDeptEmptyFlag()) {
      setAddItemButtonFlag(true);
    }
    if (props.labourAvailabilityType == 'DEPARTMENT' && props.roleChecked && selectedCategory && selectedDepartment) {
      const usedCategories = selectedCategory && props.rowData && props.rowData.filter((r) => r.labourCategory.id == selectedCategory.labourCategory.id);
        const filterDep = usedCategories && usedCategories.filter((used) => {
          return (((used && used.department && used.department.id) == (selectedDepartment && selectedDepartment.id)) && used.role)
        });
        if (filterDep && filterDep.length !== (props.roles && props.roles.length)) {
          const usedroleIds = filterDep && filterDep.map(uc => (uc.role && uc.role.id));
          const rolesAvailable = usedroleIds && props.roles && props.roles.filter(d => !usedroleIds.includes(d.id));
          const options = rolesAvailable && rolesAvailable.map(d => ({ value: d.name, label: d.name, id: d.id, name: d.name }))
          setRoleOptions(options);
        }
      setRoleEnableFlag(false);
    }
    setSelectedRole([]);
  }, [selectedDepartment]);


  React.useEffect(() => {
    setSelectedDepartment([]);
  }, [props.roleChecked])

  React.useEffect(() => {
    setRoleEnableFlag(true);
  },[props.labourAvailabilityType])

  React.useEffect(()=>{
    if(props.rowData.length === 0 && (!isEmpty(selectedCategory) || ((Array.isArray(selectedDepartment) && selectedDepartment.length)) || ((Array.isArray(selectedRole) && selectedRole.length)))){
      setAddItemButtonFlag(false);
    }else{
      if(props.rowData.length > 0){
        if(!isEmpty(selectedCategory) && !isEmpty(selectedDepartment) && !isEmpty(selectedRole)){
          const usedroleIds = selectedRole && selectedRole.map((role)=>role.id);
          const useddepIds = selectedDepartment && selectedDepartment.id;
          const flag = (props.rowData.some((data) => (data.labourCategory.id == selectedCategory.labourCategory.id) && (useddepIds === (data.department && data.department.id)) && (usedroleIds.includes(data.role && data.role.id))));
          setAddItemButtonFlag(flag);
        }else if(!isEmpty(selectedCategory) && !isEmpty(selectedDepartment)){
          if(!Array.isArray(selectedDepartment)){
            const useddepIds = selectedDepartment && selectedDepartment.id;
            const flag = (props.rowData.some((data) => (data.labourCategory.id == selectedCategory.labourCategory.id) && !data.role && (useddepIds === (data.department && data.department.id))));
            setAddItemButtonFlag(flag);
          }else if(Array.isArray(selectedDepartment)){
            const useddepIds = selectedDepartment && selectedDepartment.map((department)=>department.id);
          const flag = (props.rowData.some((data) => (data.labourCategory.id == selectedCategory.labourCategory.id) && !data.role && (useddepIds.includes(data.department && data.department.id))));
          setAddItemButtonFlag(flag);
          }
        }else if(!isEmpty(selectedCategory) && !isEmpty(selectedRole)){
          const usedroleIds = selectedRole && selectedRole.map((role)=>role.id);
          const flag = (props.rowData.some((data) => (data.labourCategory.id == selectedCategory.labourCategory.id) && !data.department && (usedroleIds.includes(data.role && data.role.id))));
          setAddItemButtonFlag(flag);
        }else if(!isEmpty(selectedCategory)){
          const flag = (props.rowData.some((data) => (data.labourCategory.id == selectedCategory.labourCategory.id) && !data.department && !data.role))
          setAddItemButtonFlag(flag);
        }
      }
    }
  },[selectedCategory, selectedDepartment, selectedRole])

  const checkDeptEmptyFlag = () => {
    if (selectedDepartment && selectedDepartment.length > 0) {
      return false;
    } else if (selectedDepartment === null || (selectedDepartment && selectedDepartment.length == 0)) {
      const usedCategoriesWithoutDepartment = selectedCategory && props.rowData && props.rowData.find((r) => (r.labourCategory.id == selectedCategory.labourCategory.id) && (!r.department && !selectedCategory.department));
      return (usedCategoriesWithoutDepartment && usedCategoriesWithoutDepartment.labourCategory) ? true : false;
    }
  }
  return (
    <DivStyledAsMenu>
      <FormattedMessage {...messages.workerType} />
      <SelectStyled
        maxMenuHeight={120}
        isClearable={false}
        options={props.labourCategories}
        onChange={setSelectedCategory}
        getOptionLabel={lcnameWithRCLabel}
        getOptionValue={lcnameWithRCLabel}
      />

      {
        props.labourAvailabilityType == 'DEPARTMENT' &&
        <>
          <FormattedMessage {...messages.department} />
          <SelectStyled
            value={selectedDepartment}
            isDisabled={selectedCategory === undefined || selectedCategory === null || props.labourAvailabilityType == 'SHIFT_ONLY'}
            isClearable={false}
            maxMenuHeight={120}
            isMulti = {!props.roleChecked}
            options={departmentOptions}
            onChange={setSelectedDepartment}
          />
        </>
      }
      {
        props.roleChecked &&
        <>
          <FormattedMessage {...messages.labourRole} />
          <SelectStyled
            value={selectedRole}
            isDisabled={selectedCategory === undefined || selectedCategory === null}
            isClearable={false}
            maxMenuHeight={120}
            isMulti
            options={roleOptions}
            onChange={setSelectedRole}
          />
        </>
      }
      <IconButton
        icon="plus-circle"
        message={messages.addLine}
        onClick={() => props.addLineHandler(selectedCategory, selectedDepartment, selectedRole)}
        disabled={addItemButtonFlag}
      />
    </DivStyledAsMenu>
  );
};
