/**
 *
 * Menu
 *
 */

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl';

const Wrap = styled.div`
  outline: none;
  position: relative;
  display: flex;
  :hover {
    cursor: pointer;
  }
`;
const Header = styled.div`
  display: flex;
  align-items: center;
  padding: 5px;
  > * {
    margin: 0 5px;
  }
`;
export const MenuItem = styled.div`
  width: 100%;
  white-space: nowrap;
  display: flex;
  align-items: center;
  padding: 5px;
  > * {
    margin: 0 5px;
  }
  transition: ${props => props.theme.transitions.all};
  :hover {
    transform: scale(1.01);
    background-color: ${props => props.theme.color.yellow};
  }
`;
export const IconItem = props => (
  <MenuItem onClick={() => window.open(props.link)}>
    <FontAwesomeIcon icon={props.icon} />
    {props.label}
  </MenuItem>
);

IconItem.propTypes = {
  label: PropTypes.string,
  link: PropTypes.string,
  icon: PropTypes.string,
};

export const IconHeader = props => (
  <>
    <FontAwesomeIcon icon={props.icon} />
    <FormattedMessage {...props.label} />
  </>
);

IconHeader.propTypes = {
  label: PropTypes.object,
  icon: PropTypes.string,
};

const Items = styled.div`
  transition: ${props => props.theme.transitions.all};
  position: absolute;
  z-index: ${props => props.theme.zIndex.topMenu};
  display: flex;
  flex-wrap: wrap;
  opacity: ${props => (props.isOpen ? 1 : 0)};
  top: 100%;
  height: ${props => (props.isOpen ? 'inherit' : '0px')};
  ${props =>
    props.itemsAlignRight &&
    css`
      right: 0;
    `};
  overflow: hidden;
  background-color: ${props => props.theme.color.white};
  box-shadow: ${props => (props.isOpen ? props.theme.shadow.buttonBig : 'none')};
`;

/* eslint-disable react/prefer-stateless-function */
class Menu extends React.PureComponent {
  state = { isOpen: undefined };

  onMouseOver = () => {
    this.setState({ isOpen: true });
  };

  onMouseOut = () => {
    this.setState({ isOpen: false });
  };

  render() {
    return (
      <Wrap
        tabIndex="-1"
        className={this.props.className}
        onMouseOver={this.onMouseOver}
        onMouseOut={this.onMouseOut}
        onBlur={this.onMouseOut}
        onFocus={this.onMouseOver}
      >
        <Header>{this.props.header}</Header>
        <Items {...this.state} itemsAlignRight={this.props.itemsAlignRight}>
          {this.props.items}
        </Items>
      </Wrap>
    );
  }
}

Menu.propTypes = {
  header: PropTypes.node,
  items: PropTypes.array,
  className: PropTypes.string,
  itemsAlignRight: PropTypes.bool,
};

export default Menu;
