import { defineMessages } from 'react-intl';

export default defineMessages({
  excelExport: {
    id: 'app.containers.ListPage.excelExport',
    defaultMessage: 'Excel Export (.xlsx)',
  },
  smartProdSourceListPage_Enabled: {
    id: 'app.containers.ListPage.smartProdSourceListPage_Enabled',
    defaultMessage: 'Please wait, while we fetch List of enabled SmartProd Sources',
  },
  smartProdSourceListPage_Disabled: {
    id: 'app.containers.ListPage.smartProdSourceListPage_Disabled',
    defaultMessage: 'Please wait, while we fetch List of disabled SmartProd Sources',
  },
  masterPlanListPage_Enabled: {
    id: 'app.containers.ListPage.masterPlanListPage_Enabled',
    defaultMessage: 'Please wait, while we fetch List of enabled Master Plans',
  },
  masterPlanListPage_Disabled: {
    id: 'app.containers.ListPage.masterPlanListPage_Disabled',
    defaultMessage: 'Please wait, while we fetch List of disabled Master Plans',
  },
  planListPage_Enabled: {
    id: 'app.containers.ListPage.planListPage_Enabled',
    defaultMessage: 'Please wait, while we fetch List of enabled Plans',
  },
  planListPage_Disabled: {
    id: 'app.containers.ListPage.planListPage_Disabled',
    defaultMessage: 'Please wait, while we fetch List of disabled Plans',
  },
  areaListPage_Enabled: {
    id: 'app.containers.ListPage.areaListPage_Enabled',
    defaultMessage: 'Please wait, while we fetch List of enabled Planning Areas (baseline)',
  },
  areaListPage_Disabled: {
    id: 'app.containers.ListPage.areaListPage_Disabled',
    defaultMessage: 'Please wait, while we fetch List of disabled Planning Areas (baseline)',
  },
  areaListPageBase_Enabled: {
    id: 'app.containers.ListPage.areaListPageBase_Enabled',
    defaultMessage: 'Please wait, while we fetch List of enabled Planning Areas (baseline)',
  },
  areaListPageBase_Disabled: {
    id: 'app.containers.ListPage.areaListPageBase_Disabled',
    defaultMessage: 'Please wait, while we fetch List of disabled Planning Areas (baseline)',
  },
  shiftScheduleListPage_Enabled: {
    id: 'app.containers.ListPage.shiftScheduleListPage_Enabled',
    defaultMessage: 'Please wait, while we fetch List of enabled Shift fillings',
  },
  shiftScheduleListPage_Disabled: {
    id: 'app.containers.ListPage.shiftScheduleListPage_Disabled',
    defaultMessage: 'Please wait, while we fetch List of disabled Shift fillings',
  },
  defaultLoadingMessage:{
    id: 'app.containers.ListPage.defaultLoadingMessage',
    defaultMessage: 'Please wait, while we fetch the data',
  },
});
