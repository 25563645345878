import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import HelpPoint from 'components/HelpPoint';
import * as React from 'react';
import { TransferCostEntry } from '../types';

interface CommentTooltipRendererProps {
  data: TransferCostEntry;
}

export class CommentTooltipRenderer extends React.Component<CommentTooltipRendererProps> {
  public render() {
    const { data } = this.props;
    if (!data || !data.comment) {
      return null;
    }

    return (
      <div>
        <Tooltip title={data.comment}>
          <span>
            <HelpPoint />
          </span>
        </Tooltip>
      </div>
    );
  }
}
