import React from "react";
import RatioToggleSection from './RatioToggleSection';
import { WarningText } from 'components/FormikTextInput';

const RatioWarnings = ({ ratioWarningsGroup }) => {
    const keys = Object.keys(ratioWarningsGroup);
    const disPlayWarnings = (key) => {
        const currentOMSCode = ratioWarningsGroup[key];
        return (
            <RatioToggleSection message={key}>
                {
                    currentOMSCode && currentOMSCode.map((cms) => {
                        return <WarningText>{cms.obj}</WarningText>
                    })
                }
            </RatioToggleSection>
        )

    }
    return (
        <div>
            <RatioToggleSection message={`OMS Code ratio warnings`}>
                {
                    keys && keys.map((key) => {
                        return disPlayWarnings(key);
                    })
                }
            </RatioToggleSection>
        </div>
    )
}

export default RatioWarnings;