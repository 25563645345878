import { defineMessages } from 'react-intl';

export default defineMessages({
  activityIdLabebl: {
    defaultMessage: 'Schedule Activity Id',
    id: 'app.containers.KronosJobRestrictionsTable.activityIdLabebl',
  },
  activityExistsInSmartShift: {
    defaultMessage: 'Exists in SmartShift',
    id: 'app.containers.KronosJobRestrictionsTable..activityExistsInSmartShift',
  },
  smartShiftJobNumberRestrictions: {
    defaultMessage: 'SmartShift Job Restrictions Number',
    id: 'app.containers.KronosJobRestrictionsTable.smartShiftJobNumberRestrictions',
  },
  smartShiftJobTimeRestrictions: {
    defaultMessage: 'SmartShift Job Restrictions Time',
    id: 'app.containers.KronosJobRestrictionsTable.smartShiftJobTimeRestrictions',
  },
  allowLeftoverSpread: {
    defaultMessage: 'Allow Leftover Spread',
    id: 'app.containers.ShiftScheduleActivityPage.allowLeftoverSpread',
  },
  back: {
    defaultMessage: 'Back',
    id: 'app.containers.ShiftScheduleActivityPage.back',
  },
  basicInfo: {
    defaultMessage: 'Basic Information',
    id: 'app.containers.ShiftScheduleActivityPage.basicInfo',
  },
  planActivitiesInfo: {
    defaultMessage: 'SmartShift Job Contains below Plan Activities',
    id: 'app.containers.ShiftScheduleActivityPage.planActivitiesInfo',
  },
  confirmDirty: {
    defaultMessage: 'There are unsaved changes at this activity. Do you want to leave the page without saving them?',
    id: 'app.containers.ShiftScheduleActivityPage.confirmDirty',
  },
  coveragePriority: {
    defaultMessage: 'Coverage Priority',
    id: 'app.containers.ShiftScheduleActivityPage.coveragePriority',
  },
  department: {
    defaultMessage: 'Department',
    id: 'app.containers.ShiftScheduleActivityPage.department',
  },
  facility: {
    defaultMessage: 'Facility',
    id: 'app.containers.ShiftScheduleActivityPage.facility',
  },
  smartShiftDetailHeader: {
    defaultMessage: 'SmartShift Job Detail',
    id: 'app.containers.ShiftScheduleActivityPage.smartShiftDetailHeader',
  },
  limitNumber: {
    defaultMessage: 'Limit',
    id: 'app.containers.ShiftScheduleActivityPage.limitNumber',
  },
  limitTime: {
    defaultMessage: 'Limit (hours)',
    id: 'app.containers.ShiftScheduleActivityPage.limitTime',
  },
  name: {
    defaultMessage: '(Group) Name',
    id: 'app.containers.ShiftScheduleActivityPage.name',
  },
  next: {
    defaultMessage: 'Next',
    id: 'app.containers.ShiftScheduleActivityPage.next',
  },
  no: {
    defaultMessage: 'No',
    id: 'app.containers.ShiftScheduleActivityPage.no',
  },
  period: {
    defaultMessage: 'Period',
    id: 'app.containers.ShiftScheduleActivityPage.period',
  },
  customer: {
    defaultMessage: 'Customer',
    id: 'app.containers.ShiftScheduleActivityPage.customer',
  },
  plan: {
    defaultMessage: 'Plan',
    id: 'app.containers.ShiftScheduleActivityPage.plan',
  },
  previous: {
    defaultMessage: 'Previous',
    id: 'app.containers.ShiftScheduleActivityPage.previous',
  },
  selectPeriod: {
    defaultMessage: 'Select period',
    id: 'app.containers.KronosJobRestrictionsTable.selectPeriod',
  },
  validFrom: {
    defaultMessage: 'Valid from',
    id: 'app.containers.ShiftScheduleActivityPage.validFrom',
  },
  validTo: {
    defaultMessage: 'Valid to',
    id: 'app.containers.ShiftScheduleActivityPage.validTo',
  },
  yes: {
    defaultMessage: 'Yes',
    id: 'app.containers.ShiftScheduleActivityPage.yes',
  },
  minimumJobSegmentLength: {
    defaultMessage: 'Minimum job Segment length',
    id: 'app.containers.ShiftScheduleActivityPage.minimumJobSegmentLength',
  },
  openShiftGroup: {
    defaultMessage: 'Open Shift Group',
    id: 'app.containers.ShiftScheduleActivityPage.openShiftGroup',
  },
  minutes: {
    defaultMessage: 'minutes',
    id: 'app.containers.ShiftScheduleActivityPage.minutes',
  },
  select: {
    defaultMessage: 'Select or type...',
    id: 'app.containers.ShiftScheduleActivityPage.select',
  },
  addOpenShiftGroupModalInputLabel: {
    defaultMessage: 'Name',
    id: 'app.containers.ShiftScheduleActivityPage.addOpenShiftGroupModalInputLabel',
  },
  addOpenShiftGroupModalTitle: {
    defaultMessage: 'Add Open Shift Group',
    id: 'app.containers.ShiftScheduleActivityPage.addOpenShiftGroupModalTitle',
  },
  addOpenShiftGroupModalConcel: {
    defaultMessage: 'Cancel',
    id: 'app.containers.ShiftScheduleActivityPage.addOpenShiftGroupModalConcel',
  },
  addOpenShiftGroupModalConfirm: {
    defaultMessage: 'Add',
    id: 'app.containers.ShiftScheduleActivityPage.addOpenShiftGroupModalConfirm',
  },
});
