import React, { useEffect, useState } from 'react';
import { ColDef, ColGroupDef } from 'ag-grid-community';
import { DateTime } from 'luxon';
import { InjectedIntl, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';

import DateTimePickerCellEditor from 'components/DateTimePickerCellEditor';
import { dataToSearch } from 'utils/url';
import { PATHS } from 'containers/App/constants';
import AgTable from 'components/Table';
import { dateComparator, dateTimeCellFormatter, parseDate } from 'utils/dateTime';
import { ROLES } from 'utils/security';
import { ApiMasterPlanToPlanDTO, ApiPlanDTO } from 'types/drep-backend.d';

import { makeSelectUser } from '../LoginPage/selectors';
import messages from './messages';
import DeleteCellRenderer from 'components/DeleteCellRenderer';
import ActionCell from 'components/DeleteCellRenderer/ActionCell';

const Table = styled(AgTable)`
  margin-top: 12px;
  height: ${props => props.height}px;

  .ag-overlay-no-rows-wrapper {
    padding-top: 90px;
  }
  .past-row {
    color: red;
  }
  .future-row {
    color: Orange;
  }
  .ag-full-width-row {
    overflow: visible;
  }
`;

const columnDefs = (intl: InjectedIntl, formik, editable: boolean) => {

  const columns: (ColGroupDef | ColDef)[] = [
    {
        headerName: intl.formatMessage(messages.categoryMessage),
        // valueGetter: CategoryWithVariableGetter,
        // cellRendererFramework: NameWithToolTip('volumeCategory.regionalConfigurationName'),
        width: 160,
        field: 'category',
        colId: 'category',
        suppressMovable: true,
        pinned: true,
        menuTabs: ['filterMenuTab'],
    },
    {
        headerName: intl.formatMessage(messages.uom),
        field: 'uom',
        colId: 'uom',
        editable: editable,
        cellEditor: 'agRichSelectCellEditor',
        // cellRendererFramework: UOMWithToolTip,
        // valueFormatter: params => params.value && `${params.value.name} / ${params.value.regionalConfigurationName}`,
        // cellEditorParams: {
        //   values: props.uoms,
        // },
        suppressMovable: true,
        width: 160,
        pinned: true,
        menuTabs: ['filterMenuTab'],
    },
  ];
  
  return columns;
};

type Props = {
  formik: any;
  intl: InjectedIntl;
  data: (ApiMasterPlanToPlanDTO & { uuid?: string })[];
  plans: ApiPlanDTO[];
  fullPlans: ApiPlanDTO[];
  editable: boolean;
  user: any;
  mappingTable: boolean;
  history: any;
};

const MasterPlanVolumeCategoryTable: React.FC<Props> = (props) => {
    const {intl, formik, editable} = props;
    //console.log(props,"props")
  const [gridApi, setGridApi] = useState(null);
  const onGridReady = params => {
    setGridApi(params);
    params.api.sizeColumnsToFit();
  };
  const userData = props.user.toJS();
  const isAdmin = userData.roles.find(r => r.role === ROLES.ADMIN.name);

  const ROW_HEIGHT = 30;

  let withAction = false;
//   const rowsData = data.map(row => {
//     const plan = fullPlans?.find(p => p.id === row.planId);
//     withAction = withAction || row.uuid !== undefined;
//     return {
//       ...row,
//       validFrom: row.validFrom ? parseDate(row.validFrom) : row.validFrom,
//       validTo: row.validTo ? parseDate(row.validTo) : row.validTo,
//       ...plan,
//       id: row.id,
//     };
//   });

  useEffect(() => {
    if (gridApi) {
      const colDefs = columnDefs(intl, props.formik, editable);
      gridApi.api.setColumnDefs(colDefs);
      gridApi.api.sizeColumnsToFit();
    }
  }, [gridApi, props.formik, ]);

  return (
    <Table
      masterDetail
      defaultColDef={{
        flex: 1,
      }}
      sortable
      pagination={false}
      columnDefs={columnDefs(intl, formik, editable)}
      height={80 + ROW_HEIGHT * Math.min(10, []?.length || 0)}
      rowData={[]}
      rowHeight={ROW_HEIGHT}
      onGridReady={onGridReady}
    />
  );
};

const mapStateToProps = createStructuredSelector({
  user: makeSelectUser(),
});
const withConnect = connect(mapStateToProps);
export default injectIntl(withConnect(MasterPlanVolumeCategoryTable));
