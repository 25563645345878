import { DateCell } from 'components/ListGrid/utils';
import React from 'react';
import { DateTime } from 'luxon';

export default function createColumnDefs(t, messages) {
  return [
    {
      headerName: t(messages.calculated),
      field: 'calculated',
      colId: 'calculated',
      cellRendererFramework: DateCell,
      filter: 'agDateColumnFilter',
      filterParams: {
        filterOptions: ['lessThan', 'greaterThan', 'inRange'],
      },
      menuTabs: ['filterMenuTab'],
    },
    {
      headerName: t(messages.calculatedBy),
      field: 'calculatedBy',
      colId: 'calculatedBy',
      filter: 'agSetColumnFilter',
      menuTabs: ['filterMenuTab'],
    },
    {
      headerName: t(messages.granularity),
      field: 'granularity',
      colId: 'granularity',
      filter: 'agSetColumnFilter',
      menuTabs: ['filterMenuTab'],
      valueGetter: params => {
        // value getter needed, so the value of granularity is translatable and sortable on translations
        const { data: { granularity } = {} } = params;
        return granularity && messages[granularity.toLowerCase()] ? t(messages[granularity.toLowerCase()]) : granularity;
      },
    },
    {
      headerName: t(messages.startDate),
      field: 'startDate',
      colId: 'startDate',
      cellRendererFramework: DateCell,
      filter: 'agDateColumnFilter',
      filterParams: {
        filterOptions: ['lessThan', 'greaterThan', 'inRange'],
      },
      menuTabs: ['filterMenuTab'],
    },
    {
      headerName: t(messages.endDate),
      field: 'endDate',
      colId: 'endDate',
      cellRendererFramework: DateCell,
      filter: 'agDateColumnFilter',
      filterParams: {
        filterOptions: ['lessThan', 'greaterThan', 'inRange'],
      },
      menuTabs: ['filterMenuTab'],
    },
  ];
}

export const mockData = [
  {
    calculatedBy: 'petra.krnacova@dhl.com',
    calculated: DateTime.fromISO('2019-10-31T13:15:47.969Z'),
    startDate: DateTime.fromISO('2019-10-31T13:15:47.969Z'),
    endDate: DateTime.fromISO('2019-10-31T13:15:47.969Z'),
    granularity: 'Week',
  },
  { calculatedBy: 'jozef.cierny@dhl.com', calculated: DateTime.fromISO('2018-10-31T13:15:47.969Z') },
  { calculatedBy: 'test@dhl.com', calculated: DateTime.fromISO('2018-10-31T13:15:43.969Z') },
  { calculatedBy: 'test@dhl.com', granularity: 'Day' },
  { calculatedBy: 'test@dhl.com', calculated: DateTime.fromISO('2019-11-23T13:15:47.969Z') },
  { calculatedBy: 'test@dhl.com' },
  { calculatedBy: 'test@dhl.com' },
  { calculatedBy: 'test@dhl.com' },
  { calculatedBy: 'test@dhl.com' },
  { calculatedBy: 'test@dhl.com' },
  { calculatedBy: 'test@dhl.com' },
  { calculatedBy: 'test@dhl.com' },
  { calculatedBy: 'test@dhl.com' },
  { calculatedBy: 'petra.krnacova@dhl.com' },
  { calculatedBy: 'jozef.cierny@dhl.com' },
  { calculatedBy: 'test@dhl.com' },
  { calculatedBy: 'test@dhl.com' },
  { calculatedBy: 'test@dhl.com' },
  { calculatedBy: 'test@dhl.com' },
  { calculatedBy: 'test@dhl.com' },
  { calculatedBy: 'test@dhl.com' },
  { calculatedBy: 'test@dhl.com' },
  { calculatedBy: 'test@dhl.com' },
  { calculatedBy: 'test@dhl.com' },
  { calculatedBy: 'test@dhl.com' },
  { calculatedBy: 'test@dhl.com' },
  { calculatedBy: 'petra.krnacova@dhl.com' },
  { calculatedBy: 'jozef.cierny@dhl.com' },
  { calculatedBy: 'test@dhl.com' },
  { calculatedBy: 'test@dhl.com' },
  { calculatedBy: 'test@dhl.com' },
  { calculatedBy: 'test@dhl.com' },
  { calculatedBy: 'test@dhl.com' },
  { calculatedBy: 'test@dhl.com' },
  { calculatedBy: 'test@dhl.com' },
  { calculatedBy: 'test@dhl.com' },
  { calculatedBy: 'test@dhl.com' },
  { calculatedBy: 'test@dhl.com' },
  { calculatedBy: 'test@dhl.com' },
  { calculatedBy: 'petra.krnacova@dhl.com' },
  { calculatedBy: 'jozef.cierny@dhl.com' },
  { calculatedBy: 'test@dhl.com' },
  { calculatedBy: 'test@dhl.com' },
  { calculatedBy: 'test@dhl.com' },
  { calculatedBy: 'test@dhl.com' },
  { calculatedBy: 'test@dhl.com' },
  { calculatedBy: 'test@dhl.com' },
  { calculatedBy: 'test@dhl.com' },
  { calculatedBy: 'test@dhl.com' },
  { calculatedBy: 'test@dhl.com' },
  { calculatedBy: 'test@dhl.com' },
  { calculatedBy: 'test@dhl.com' },
  { calculatedBy: 'petra.krnacova@dhl.com' },
  { calculatedBy: 'jozef.cierny@dhl.com' },
  { calculatedBy: 'test@dhl.com' },
  { calculatedBy: 'test@dhl.com' },
  { calculatedBy: 'test@dhl.com' },
  { calculatedBy: 'test@dhl.com' },
  { calculatedBy: 'test@dhl.com' },
  { calculatedBy: 'test@dhl.com' },
  { calculatedBy: 'test@dhl.com' },
  { calculatedBy: 'test@dhl.com' },
  { calculatedBy: 'test@dhl.com' },
  { calculatedBy: 'test@dhl.com' },
  { calculatedBy: 'test@dhl.com' },
  { calculatedBy: 'petra.krnacova@dhl.com' },
  { calculatedBy: 'jozef.cierny@dhl.com' },
  { calculatedBy: 'test@dhl.com' },
  { calculatedBy: 'test@dhl.com' },
  { calculatedBy: 'test@dhl.com' },
  { calculatedBy: 'test@dhl.com' },
  { calculatedBy: 'test@dhl.com' },
  { calculatedBy: 'test@dhl.com' },
  { calculatedBy: 'test@dhl.com' },
  { calculatedBy: 'test@dhl.com' },
  { calculatedBy: 'test@dhl.com' },
  { calculatedBy: 'test@dhl.com' },
  { calculatedBy: 'test@dhl.com' },
];
