import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { Field, Formik } from 'formik';
import { injectIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';
import styled from 'styled-components';
import * as Yup from 'yup';

import BasePage from 'components/BasePage';
import { DetailToolbarButtonsWrap } from 'components/DetailPageShared';
import { FormikTextInput } from 'components/FormikTextInput';
import { BackButton, SaveButton } from 'components/IconButton';
import Label from 'components/Label';
import NamedSection from 'components/NamedSection';
import { PATHS } from 'containers/App/constants';
import { getToken } from 'containers/App/selectors';
import { fetchSmartProdInstancesActive, fetchFacilities, fetchWarehouses, fetchNewFacility } from 'containers/PlanInterfaces/fetch';
import { withUrl } from 'utils/api';
import { fetchData } from 'utils/reduxApi';
import withSecurity, { PERMISSIONS } from 'utils/security';
import Select from 'components/StyledSelect';
import { EntityEntry } from './types';
import messages from './messages';

const Form = styled.form``;

const ValidatePage = props => (
  <BasePage labelMessage={messages.headerPAInsert} labelValues={{ name: props.headerName || '' }}>
    <Form>{props.children}</Form>
  </BasePage>
);

ValidatePage.propTypes = {
  children: PropTypes.node,
  headerName: PropTypes.string,
};

const LabelLocal = styled(Label)`
  flex-basis: 35%;
`;

export const Section = styled(NamedSection)`
  max-width: none;
  min-height: calc(100% - 18px);
  input {
    background-color: ${props => props.theme.color.grey5};
    width: 100%;
  }
  padding: 12px;
`;

const StyledSelect = styled(Select)`
  width: 12em;
  margin-top: 5px;
  margin-left: 12px;
`;


export const TopPadding = styled.div`
  margin-top: 72px;
`;

const FieldWrapper = styled.div`
  padding-left:10px;
`;

Yup.addMethod(Yup.array, 'allItemsContainValue', function (errorMessage) {
  return this.test(`array-has-values`, errorMessage, function (value) {
    const { path, createError } = this;
    return !value || value.filter(x => !x.id).length !== 0 ? createError({ path, message: errorMessage }) : true;
  });
});

export const validationSchema = Yup.object().shape({
  description: Yup.string().required('Name is mandatory'),
  facility: Yup.array().min(1, 'Facility is mandatory').allItemsContainValue('Select a facility'),
  smartProdInstance: Yup.array().min(1, 'SmartProd Instance is mandatory').allItemsContainValue('Select a SmartProd Instance'),
  whID: Yup.array().min(1, 'WHID is mandatort').allItemsContainValue('Select a WHID'),
});

const INITIAL_VALUES = { facility: [], smartProdInstance: [], whID: [], description: '' };

type Props = {
  history: { push: Function };
};

const CreateSmartProdPage: React.FC<Props> = props => {
  const token: string = useSelector(getToken);
  const dispatch = useDispatch();

  const [allSmartProdInstances, setSmartProdInstances] = React.useState<EntityEntry[]>([]);
  const [allWHIDs, setWHIDs] = React.useState<EntityEntry[]>([]);
  const [entityRelations, setEntityRelations] = React.useState<EntityEntry[]>([]);

  const handleSubmit = async (values, formik) => {
    const smartProdInstanceDetails = {
      id: values.smartProdInstanceValue.value,
      name: values.smartProdInstanceValue.label,
    };
    const smartProdWarehouseDetails = {
      id: values.whIDValue.value,
      facilityId: values.facilityValue.value,
      smartProdWhId: values.whIDValue.label,
    };
    const payload = {
      smartProdInstance: smartProdInstanceDetails,
      smartProdWarehouse: smartProdWarehouseDetails,
      description: values.description,
    };

    const result = await fetchData(withUrl(`/smartProdSource/`).put(payload).andToken(token), dispatch);
    if (result.isOk) {
      formik.resetForm(INITIAL_VALUES);
      props.history.push(`${PATHS.SmartProdSoureceDetail.replace(':id', result.data.id)}`);
    }
    formik.setSubmitting(false);
  };


  React.useEffect(() => {
    fetchSmartProdInstancesActive(token, dispatch, setSmartProdInstances);
    fetchWarehouses(token, dispatch, setWHIDs);
    fetchFacilities(token, dispatch, setEntityRelations);
  }, []);

  const InputError = styled.div`
  color: ${props => props.theme.color.red};
  margin-top: 8px;
  margin-bottom: 2px;
`;

  const handleValidate = (value) => {
    let errors = {};
    if (value.facility === "" || value.facility.length === 0) {
      const facility = "Facility is mandatory";
      errors = { ...errors, facility }
    }
    if (value.smartProdInstance === "" || value.smartProdInstance.length === 0) {
      const smartProdInstance = "smartProdInstance is mandatory";
      errors = { ...errors, smartProdInstance }
    }
    if (value.whID === "" || value.whID.length === 0) {
      const whID = "WHID is mandatory";
      errors = { ...errors, whID }
    }
    return errors;
  };

  const handleSaveValidate = (value) =>{
    let validateflag = typeof value.facility == 'number' && typeof value.smartProdInstance == 'number' && typeof value.whID == 'number';
    return validateflag;
  };

  const fetchNewFacilities = (option)=>{
    option && fetchNewFacility(token, dispatch, setEntityRelations,option);
  };

  return (
    <Formik
      onSubmit={handleSubmit}
      enableReinitialize
      validate={handleValidate}
      initialValues={INITIAL_VALUES}
      render={formikBag => (
        <ValidatePage {...props}>
          <DetailToolbarButtonsWrap>
            <BackButton {...props} />
            <SaveButton onClick={() => formikBag.submitForm()} disabled={!handleSaveValidate(formikBag.values)} />
          </DetailToolbarButtonsWrap>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <div style={{ marginTop: 83 }} />
            </Grid>
            <Grid item xs={4}>
              <Section message={messages.basicInfo} noMaxWidth>
                <Grid container spacing={1}>
                  <LabelLocal {...messages.facility} />
                  <Grid item xs={12} sm={6}>
                    <Field
                      name="facility"
                      render={({ field, form }) => (
                        <StyledSelect
                          id="facility"
                          options={entityRelations}
                          onInputChange = {fetchNewFacilities}
                          onChange={option => {
                            form.setFieldValue(field.name, option.value);
                            form.setTouched({ ...form.touched, [field.name]: true });
                            form.setFieldValue('facilityValue', option);
                            form.setFieldValue(`whID`, null);
                            form.setFieldValue('whIDValue', null);
                          }}
                          value={entityRelations.find(o => o.value === field.value) || null}
                        />
                      )}
                    />
                    {formikBag.errors.facility && formikBag.touched.facility && <InputError>{'Facility is Mandatory'}</InputError>}
                  </Grid>
                  <LabelLocal {...messages.smartProdInstance} />
                  <Grid item xs={12} sm={6}>
                    <Field
                      name="smartProdInstance"
                      render={({ field, form }) => (
                        <StyledSelect
                          id="smartProdInstance"
                          options={allSmartProdInstances}
                          isDisabled={!formikBag.values.facilityValue}
                          onChange={option => {
                            form.setFieldValue(field.name, option.value);
                            form.setTouched({ ...form.touched, [field.name]: true });
                            form.setFieldValue('smartProdInstanceValue', option);
                          }}
                          value={allSmartProdInstances.find(o => o.value === field.value) || null}
                        />
                      )}
                    />
                    {formikBag.errors.smartProdInstance && formikBag.touched.smartProdInstance && <InputError>{formikBag.errors.smartProdInstance}</InputError>}
                  </Grid>
                  <LabelLocal {...messages.whID} />
                  <Grid item xs={12} sm={6}>
                    <Field
                      name="whID"
                      render={({ field, form }) => (
                        <StyledSelect
                          id="whID"
                          isDisabled={!formikBag.values.facilityValue}
                          options={allWHIDs.filter(option => option.facilityId === formikBag.values.facility)}
                          onChange={option => {
                            form.setFieldValue(field.name, option.value);
                            form.setTouched({ ...form.touched, [field.name]: true });
                            form.setFieldValue('whIDValue', option);
                          }}
                          value={allWHIDs.find(o => o.value === field.value) || null}
                        />
                      )}
                    />
                    {formikBag.errors.whID && formikBag.touched.whID && <InputError>{formikBag.errors.whID}</InputError>}
                  </Grid>
                  <LabelLocal {...messages.description} />
                  <Grid item xs={12} sm={6}>
                    <FieldWrapper><Field name="description" type="text" component={FormikTextInput} /></FieldWrapper>
                  </Grid>
                </Grid>
              </Section>
            </Grid>
            <Grid item xs={6} />
          </Grid>
        </ValidatePage>
      )}
    />
  );
};

export default compose(
  injectIntl,
  withSecurity(() => PERMISSIONS.VIEW_SMART_PROD_SOURCE),
  // @ts-ignore
)(CreateSmartProdPage);



