import * as React from 'react';
import Dialog from '../../components/Dialog';
import messages from './messages';

export interface WithConfirmDialogProps {
  confirmDialogVisible: boolean;
}

export interface WithConfirmDialogState {
  confirmDialogVisible: boolean;
  message: string;
}

export const withConfirmDialog = <P extends object>(WrappedComponent: React.ComponentType<P>) =>
  class extends React.Component<P & WithConfirmDialogProps, WithConfirmDialogState> {
    public state = {
      confirmDialogVisible: false,
      message: '',
    };

    private confirmHandler = () => {};

    public openConfirmDialog = (confirmHandler, message) => {
      this.setState({ confirmDialogVisible: true, message });
      this.confirmHandler = confirmHandler;
    };

    render() {
      return (
        <>
          <WrappedComponent {...this.props as P} openConfirmDialog={this.openConfirmDialog} />
          {this.state.confirmDialogVisible && (
            <Dialog
              open
              headerLabel={messages.warning}
              confirmLabel={messages.send}
              isTextContent
              onCancel={() => this.setState({ confirmDialogVisible: false })}
              onConfirm={() => {
                this.setState({ confirmDialogVisible: false });
                this.confirmHandler();
              }}
            >
              {this.state.message}
            </Dialog>
          )}
        </>
      );
    }
  };
