// @flow

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = (style: Object) =>
  makeStyles((theme: ?Object) => ({
    fab: {
      textTransform: 'none',
      backgroundColor: style.color.yellow2,
      '& *': {
        transition: 'color 0.1s ease-out',
      },
      color: style.color.black2,

      '&:hover': {
        color: style.color.black,
        backgroundColor: style.color.yellow1,
      },
    },
    root: {
      textTransform: 'none',
      '& *': {
        transition: 'color 0.1s ease-out',
      },
      color: style.color.black2,

      '&:hover': {
        color: style.color.black,
        backgroundColor: 'rgba(255, 241, 144, 0.5)',
      },
    },
    buttonIcon: {
      marginRight: '7px',
    },
    itemIcon: {
      marginRight: '15px',
      color: style.color.black2,
    },
    link: {
      color: style.color.black,
      textDecoration: 'none',
    },
    item: {
      color: style.color.black2,

      '& *': {
        transition: 'color 0.1s ease-out',
      },
      '&:hover': {
        color: style.color.black,
      },
      '&:hover svg': {
        color: style.color.black,
      },
    },
  }));
