/**
 *
 * Label
 *
 */

import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

export const StyledLabel = styled.div`
  font-size: 12px;
  font-weight: 700;
  align-self: center;
`;

function Label(props) {
  return (
    <StyledLabel className={props.className}>
      <FormattedMessage {...props} />
    </StyledLabel>
  );
}

Label.propTypes = {
  className: PropTypes.string,
};

export const LabelStyled = styled(Label)``;

export default Label;
