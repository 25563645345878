/* eslint-disable no-plusplus */
/**
 *
 * ActivitySettingsDirect
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { compose, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import styled from 'styled-components';
import { getIn } from 'formik';
import cloneDeep from 'lodash/cloneDeep';
import { selectEditFromplan, selectPeriodIndexFromPlan } from 'containers/PlanDetailPage/selectors';
import { deleteLabourAvailabilityCategoryLine } from 'containers/PlanDetailPage/actions';
import { deleteLabourAvailabilityCategoryLinePa } from 'containers/PlanningAreaDetailPage/actions';
import { selectEditFromPa, selectPeriodIndexFromPa } from 'containers/PlanningAreaDetailPage/selectors';
import TableControlled from 'containers/TableControlled';
import { TABLE_DEFAULTS } from 'containers/App/constants';
import { toNumber } from 'utils/utils';
import messages from '../PlannedVolumeTable/messages';
import createColumnDefs from './columnDefs';
import { DeleteDialog, withDeleteDialog } from '../Dialog';
import directMessages from './messages';
import { cellEditDirtyMarker } from '../DetailPageShared';

const StyledTable = styled(TableControlled)`
  height: ${props => props.height}px;
`;

export const getFormikDataPath = periodIndex => `planningParameters.periods.${periodIndex}.labourCategoryParameters`;

/* eslint-disable react/prefer-stateless-function */
class LabourAvailabilityCategoryTable extends React.Component {
  keyFromProps = props => {
    const rowData = getIn(props.formik.values, this.getFormikDataPath(), []);
    return `labourAvailabilityCategoryTable_${props.edit}_${props.periodIndex}_${props.deleteDialogOpen}_${
      rowData.length
    }_${this.calculateUsedLabourCategoriesIds(props).toString()}`;
  };

  shouldComponentUpdate(nextProps, nextState) {
    return this.keyFromProps(nextProps) !== this.keyFromProps(this.props);
  }

  handleCellValueChanged = params => {
    const fieldPath = [...this.getFormikDataPath().split('.'), params.rowIndex, params.colDef.field];
    this.props.formik.setFieldValue(fieldPath, toNumber(params.newValue));
  };

  getFormikDataPath = () => getFormikDataPath(this.props.periodIndex);

  calculateUsedLabourCategoriesIds = props => {
    const shiftsPath = `planningParameters.periods.${props.periodIndex}.shifts`;
    const shifts = getIn(props.formik.values, shiftsPath, []);
    const result = new Set();
    shifts.forEach(shift => {
      const transitions = shift.labourCategoryTransitions || [];
      transitions.forEach(transition => {
        if (transition && transition.labourCategory) {
          result.add(transition.labourCategory.id);
        }
      });
    });
    return [...result];
  };

  render() {
    const columnDefs = createColumnDefs(
      {
        ...this.props,
        deleteLine: this.props.deleteLabourAvailabilityCategoryLine,
      },
      directMessages,
    );
    const usedLabourCategories = this.calculateUsedLabourCategoriesIds(this.props);
    const rowData = cloneDeep(getIn(this.props.formik.values, this.getFormikDataPath(), [])).map(row => ({
      ...row,
      used: usedLabourCategories.includes(row.labourCategory.id),
    }));
    const totalRows = (rowData && rowData.length) || 0;
    const aproxHeight = (totalRows || 1) * 30 + 50;
    const key = this.keyFromProps(this.props);
    return (
      <>
        <StyledTable
          key={key}
          defaultConfig={
            this.props.edit
              ? TABLE_DEFAULTS.labourAvailabilityCategoryTableEditConfig
              : TABLE_DEFAULTS.labourAvailabilityCategoryTableConfig
          }
          autosize
          height={aproxHeight}
          name={!this.props.edit ? 'labourAvailabilityCategoryTable' : 'labourAvailabilityCategoryTableEdit'}
          messages={messages}
          pagination={false}
          columnDefs={columnDefs}
          rowData={rowData}
          onCellValueChanged={this.handleCellValueChanged}
          showCOG={false}
          getRowNodeId={data => data.labourCategory.id}
          deltaRowDataMode
          {...cellEditDirtyMarker(this.props.formik.setFieldValue)}
          onPaste={this.onPaste}
        />
        <DeleteDialog {...this.props} text={directMessages.dialogDeleteTextWorkerType} />
      </>
    );
  }

  onPaste = data => {
    const { setFieldValue } = this.props.formik;
    setFieldValue(this.getFormikDataPath(), data);
  };
}

LabourAvailabilityCategoryTable.propTypes = {
  intl: PropTypes.object,
  edit: PropTypes.bool,
  formik: PropTypes.object,
  deleteLabourAvailabilityCategoryLine: PropTypes.func,
  periodIndex: PropTypes.number,
};

// Plan
const mapPlanStateToProps = createStructuredSelector({
  edit: selectEditFromplan,
  periodIndex: selectPeriodIndexFromPlan,
});

function mapPlanDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      deleteLabourAvailabilityCategoryLine,
    },
    dispatch,
  );
}

const withPlanConnect = connect(
  mapPlanStateToProps,
  mapPlanDispatchToProps,
);

const mapPaStateToProps = createStructuredSelector({
  edit: selectEditFromPa,
  periodIndex: selectPeriodIndexFromPa,
});

function mapPaDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      deleteLabourAvailabilityCategoryLine: deleteLabourAvailabilityCategoryLinePa,
    },
    dispatch,
  );
}

const withPaConnect = connect(
  mapPaStateToProps,
  mapPaDispatchToProps,
);

export default compose(
  injectIntl,
  withPlanConnect,
  withDeleteDialog,
)(LabourAvailabilityCategoryTable);

export const PaLabourAvailabilityCategoryTable = compose(
  injectIntl,
  withPaConnect,
  withDeleteDialog,
)(LabourAvailabilityCategoryTable);
