/*
 * ShiftFillingListPage Messages
 *
 * This contains all the text for the ShiftFillingListPage component.
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  header: {
    defaultMessage: 'Shift fillings',
    id: 'app.containers.ShiftFillingListPage.header',
  },
  disabled: {
    defaultMessage: 'Status',
    id: 'app.containers.ShiftFillingListPage.disabled',
  },
  updatedBy: {
    defaultMessage: 'Modified By',
    id: 'app.containers.ShiftFillingListPage.updatedBy',
  },
  updated: {
    defaultMessage: 'Modified',
    id: 'app.containers.ShiftFillingListPage.updated',
  },
  calculated: {
    defaultMessage: 'Calculated',
    id: 'app.containers.ShiftFillingListPage.calculated',
  },
  calculatedBy: {
    defaultMessage: 'Calculated By',
    id: 'app.containers.ShiftFillingListPage.calculatedBy',
  },
  numOfThisMonthCalcs: {
    defaultMessage: 'This month # of Calculations',
    id: 'app.containers.ShiftFillingListPage.numOfThisMonthCalcs',
  },
  numOfLastMonthCalcs: {
    defaultMessage: 'Last month # of Calculations',
    id: 'app.containers.ShiftFillingListPage.numOfLastMonthCalcs',
  },
  content: {
    defaultMessage: 'Planning Areas',
    id: 'app.containers.ShiftFillingListPage.content',
  },
  add: {
    defaultMessage: 'Add',
    id: 'app.containers.ShiftFillingListPage.add',
  },
  removeSelected: {
    defaultMessage: 'Disable selected',
    id: 'app.containers.ShiftFillingListPage.removeSelected',
  },
  name: {
    defaultMessage: 'Name',
    id: 'app.containers.ShiftFillingListPage.name',
  },
  id: {
    defaultMessage: 'Id',
    id: 'app.containers.ShiftFillingListPage.id',
  },
  facilities: {
    defaultMessage: 'Facilities',
    id: 'app.containers.ShiftFillingListPage.facilities',
  },
  customers: {
    defaultMessage: 'Customers',
    id: 'app.containers.ShiftFillingListPage.customers',
  },
  managementUnits: {
    defaultMessage: 'Management Units',
    id: 'app.containers.ShiftFillingListPage.managementUnits',
  },
  validFrom: {
    defaultMessage: 'Valid From',
    id: 'app.containers.ShiftFillingListPage.validFrom',
  },
  validTo: {
    defaultMessage: 'Valid To',
    id: 'app.containers.ShiftFillingListPage.validTo',
  },
  created: {
    defaultMessage: 'Created',
    id: 'app.containers.ShiftFillingListPage.created',
  },
  createdBy: {
    defaultMessage: 'Created By',
    id: 'app.containers.ShiftFillingListPage.createdBy',
  },
  action: {
    defaultMessage: 'Actions',
    id: 'app.containers.ShiftFillingListPage.action',
  },
  deleteConfirmText: {
    defaultMessage: 'Are you sure? Disabling items: {items}',
    id: 'app.containers.ShiftFillingListPage.deleteConfirmText',
  },
  enableConfirmText: {
    defaultMessage: 'Are you sure? Enabling items: {items}',
    id: 'app.containers.ShiftFillingListPage.enableConfirmText',
  },
  enableConfirmButton: {
    defaultMessage: 'ENABLE',
    id: 'app.containers.ShiftFillingListPage.enableConfirmButton',
  },
  enableTitle: {
    defaultMessage: 'Enable Action',
    id: 'app.containers.ShiftFillingListPage.enableTitle',
  },
  disableConfirmButton: {
    defaultMessage: 'DISABLE',
    id: 'app.containers.ShiftFillingListPage.disableConfirmButton',
  },
  disableTitle: {
    defaultMessage: 'Disable Action',
    id: 'app.containers.ShiftFillingListPage.disableTitle',
  },
  saveTableConfig: {
    defaultMessage: 'Save Current To User View',
    id: 'app.containers.ShiftFillingListPage.saveTableConfig',
  },
  defaultView: {
    defaultMessage: 'Default View',
    id: 'app.containers.ShiftFillingListPage.defaultView',
  },
  userView: {
    defaultMessage: 'User View',
    id: 'app.containers.ShiftFillingListPage.userView',
  },
  deleted: {
    defaultMessage: 'Status',
    id: 'app.containers.ShiftFillingListPage.deleted',
  },
  area: {
    defaultMessage: 'Planning Area',
    id: 'app.containers.ShiftFillingListPage.area',
  },
  shiftScheduleListPage_Enabled:{
    defaultMessage: 'Load enabled Shift fillings',
    id: 'app.containers.ShiftFillingListPage.shiftScheduleListPage_Enabled',
  },
  shiftScheduleListPage_Disabled:{
    defaultMessage: 'Load disabled Shift fillings',
    id: 'app.containers.ShiftFillingListPage.shiftScheduleListPage_Disabled',
  }
});
