/*
 *
 * UploadModal actions
 *
 */

import {
  OPEN_MODAL,
  CLOSE_MODAL,
  FETCH_UPLOADS,
  STORE_UPLOADS,
  EXPAND_HISTORY,
  COLLAPSE_HISTORY,
  UPLOAD_FILE,
  STORE_UPLOAD_INFO,
  EXPORT_FILE,
  DOWNLOAD_FROM_HISTORY,
  EXPAND_EXPORT,
  COLLAPSE_EXPORT,
  UPLOAD_MODAL_ADMIN,
  CLOSE_MODAL_ADMIN,
  UPLOAD_FILE_ADMIN,
} from './constants';

export function openUploadModalAction() {
  return {
    type: OPEN_MODAL,
  };
}

export function closeUploadModalAction(doReload, entity) {
  return {
    type: CLOSE_MODAL,
    payload: { doReload, entity },
  };
}

export function fetchUploadsAction(payload) {
  return {
    type: FETCH_UPLOADS,
    payload,
  };
}

export function storeUploadsAction(payload) {
  return {
    type: STORE_UPLOADS,
    payload,
  };
}

export function expandHistoryAction() {
  return {
    type: EXPAND_HISTORY,
  };
}

export function collapseHistoryAction() {
  return {
    type: COLLAPSE_HISTORY,
  };
}

export function expandExportAction() {
  return {
    type: EXPAND_EXPORT,
  };
}

export function collapseExportAction() {
  return {
    type: COLLAPSE_EXPORT,
  };
}

export function uploadFileAction(entity, id, files) {
  return {
    type: UPLOAD_FILE,
    payload: {
      entity,
      id,
      files,
    },
  };
}

export function storeUploadInfoAction(payload) {
  return {
    type: STORE_UPLOAD_INFO,
    payload,
  };
}

export function exportFileAction(entity, id, sections) {
  return {
    type: EXPORT_FILE,
    payload: { entity, id, sections },
  };
}

export function downloadFromHistoryAction(entity, id, fileName) {
  return {
    type: DOWNLOAD_FROM_HISTORY,
    payload: { entity, id, fileName },
  };
}


export function openUploadModal() {
  return {
    type: UPLOAD_MODAL_ADMIN,
  };
}

export function closeUploadModal() {
return {
  type: CLOSE_MODAL_ADMIN,
};
}

export function uploadAdminFileAction(file, start, end){
  return {
    type: UPLOAD_FILE_ADMIN,
    payload: {
      file,
      start,
      end,
    },
  };
}