export const TREND_SETTINGS_KEY = 'graph_page_trend_settings';
export const TREND_COLUMN_SETTINGS_KEY = 'graph_page_trend_column_settings';

export const EFWZPG_SETTINGS_KEY = 'graph_page_efwzp_settings';
export const EFWZPG_COLUMN_SETTINGS_KEY = 'graph_page_efwzp_column_settings';

export const DILO_SETTINGS_KEY = 'graph_page_dilo_settings';
export const DILO_COLUMN_SETTINGS_KEY = 'graph_page_dilo_column_settings';

export const HEADS_SETTINGS_KEY = 'graph_page_heads_settings';
export const HEADS_COLUMN_SETTINGS_KEY = 'graph_page_heads_column_settings';
