import React from 'react';
import { InjectedIntl, injectIntl } from 'react-intl';
import styled from 'styled-components';
import Label from 'components/Label';
import SmartProdJobAdjustment from './SmartProdJobAdjustment';
import messages from './messages';
import SmartProdJobAdjustmentsIndirect from './SmartProdJobAdjustmentsIndirect';
import SmartProdActivityAdjustment from './SmartProdActivityAdjustment';
import SmartProdActivityAdjustmentIndirect from './SmartProdActivityAdjustmentIndirect';

type Props = {
    intl: InjectedIntl;
    smartProdJobAdjustments: any;
    smartProdActivityAdjustments: any;
    planDetails: any;
    response: any;
  };

const SmartProdJobLabel = styled(Label)`
    font-size: 14px;
    margin-top: 18px;
`;

const DirectLabel = styled(Label)`
    font-size: 14px;
    font-weight: normal;
    margin-top: 12px;
`;  

const IndirectLabel = styled(Label)`
    font-size: 14px;
    font-weight: normal;
    margin-top: 12px;
`;  

const Adjustments: React.FC<Props> = ({
    intl,
    smartProdJobAdjustments,
    smartProdActivityAdjustments,
    planDetails,
    response,
  }) => {
    return(
        <>
        <SmartProdJobLabel {...messages.smartProdJobAdjustmentTable}/>
        <DirectLabel {...messages.direct} />
        <SmartProdJobAdjustment smartProdJobAdjustments={smartProdJobAdjustments}/>
        <IndirectLabel {...messages.inDirect} />
        <SmartProdJobAdjustmentsIndirect smartProdJobAdjustments={smartProdJobAdjustments}/>
        <SmartProdJobLabel {...messages.smartPlanActivityAdjustmentTable} />
        <DirectLabel {...messages.direct} />
        <SmartProdActivityAdjustment planDetails={planDetails} smartProdActivityAdjustments={smartProdActivityAdjustments} response={response}/>
        <IndirectLabel {...messages.inDirect} />
        <SmartProdActivityAdjustmentIndirect planDetails={planDetails} smartProdActivityAdjustments={smartProdActivityAdjustments} response={response}/>
        </>
    )
  }


export default injectIntl(Adjustments);