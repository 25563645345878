import {
  LOAD_UOMS,
  STORE_UOMS,
  API_CALL_ENDED,
  API_CALL_STARTED,
  LOAD_ACTIVITIES,
  STORE_ACTIVITIES,
  STORE_FROM_BROWSERSTORAGE,
  SAVE_TO_BROWSERSTORAGE,
  LOAD_FROM_BROWSERSTORAGE,
  STORE_ACTIVITIES_BY_PLAN,
  SET_VIEW,
  STORE_BE_VERSION,
} from './constants';

export function setViewAction(payload) {
  return {
    type: SET_VIEW,
    payload,
  };
}

export function storeActivitiesByPlanAction(payload) {
  return {
    type: STORE_ACTIVITIES_BY_PLAN,
    payload,
  };
}

export function loadUOMSAction(payload) {
  return {
    type: LOAD_UOMS,
    payload,
  };
}

export function storeUOMSAction(payload) {
  return {
    type: STORE_UOMS,
    payload,
  };
}

export function apiCallEndedAction(payload) {
  return {
    type: API_CALL_ENDED,
    payload,
  };
}

export function apiCallStartedAction(payload) {
  return {
    type: API_CALL_STARTED,
    payload,
  };
}

export function storeActivitiesAction(payload) {
  return {
    type: STORE_ACTIVITIES,
    payload,
  };
}

export function loadActivitiesAction(payload) {
  return {
    type: LOAD_ACTIVITIES,
    payload,
  };
}
export function storeFromBSAction(payload) {
  return {
    type: STORE_FROM_BROWSERSTORAGE,
    payload,
  };
}
export function saveToBSAction(payload) {
  return {
    type: SAVE_TO_BROWSERSTORAGE,
    payload,
  };
}
export function loadFromBSAction(payload) {
  return {
    type: LOAD_FROM_BROWSERSTORAGE,
    payload,
  };
}

export const storeBeVersionAction = payload => ({ type: STORE_BE_VERSION, payload });
