import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the tableControlled state domain
 */

const selectTableControlledDomain = state => state.get('tableControlled', initialState);
const selectTableControlledDomainName = name => state => state.getIn(['tableControlled', name], initialState.get(name));

/**
 * Other specific selectors
 */

/**
 * Default selector used by TableControlled
 */

const makeSelectTableControlledDomain = () =>
  createSelector(
    selectTableControlledDomain,
    substate => substate.toJS(),
  );

const makeSelectTableControlled = name =>
  createSelector(
    selectTableControlledDomainName(name),
    substate => substate && substate.getIn(['config']) && substate.getIn(['config']).toJS(),
  );

export { makeSelectTableControlled, makeSelectTableControlledDomain };
