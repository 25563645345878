import includes from 'lodash/includes';
import { DAY_NAMES } from './constants';
import messages from './messages';

export function getWeekdays(formatMessage, values, short = false) {
  const weekDays = {};
  if (typeof values === 'string') {
    if (values in messages) return formatMessage(messages[values]);
    return values;
  }

  const days = values || DAY_NAMES;
  days.forEach(el => {
    const key = (short && `${el.toLowerCase()}Short`) || el.toLowerCase();
    weekDays[el] = (key in messages && formatMessage(messages[key])) || el;
  });
  return weekDays;
}

export function getWeekdaysSortedString(formatMessage, values, short = false) {
  if (typeof values === 'string') {
    if (values in messages) return formatMessage(messages[values]);
    return values;
  }

  const days = [];
  // eslint-disable-next-line no-restricted-syntax
  for (const name of DAY_NAMES) {
    if (includes(values, name)) {
      const key = (short && `${name.toLowerCase()}Short`) || name.toLowerCase();
      const val = (key in messages && formatMessage(messages[key])) || name;
      days.push(val);
    }
  }

  return days.join(', ');
}
