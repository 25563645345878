// @flow

/**
 *
 * PlannedVolumeAddLine
 *
 */

import React, { useEffect, useState } from 'react';
import { createStructuredSelector } from 'reselect';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect as formikConnect, getIn } from 'formik';
import { AddLineButton } from 'components/IconButton';

import { selectUOMS } from 'containers/App/selectors';
import { makeSelectToken } from 'containers/LoginPage/selectors';

import ButtonWithDirtyCheck, { ComponentWithDirtyCheck } from 'components/ButtonWithDirtyCheck';
import SelectDialog from 'components/SelectDialog';
import DropDownCellEditor from 'components/DropDownCellEditor';
import { UOMValueGetter } from 'components/ListGrid/utils';
import { fetchData } from 'utils/reduxApi';
import { withUrl } from 'utils/api';
import { addBudgetVolumeLine } from '../../containers/PlanningAreaDetailPage/actions';
import { selectGuiStateForPa } from '../../containers/PlanningAreaDetailPage/selectors';
import { addPlannedVolumeLine } from '../../containers/PlanDetailPage/actions';
import { selectGuiState } from '../../containers/PlanDetailPage/selectors';
import messages from './messages';

import './style.css';

const columnDefs = props => {
  const {
    intl: { formatMessage },
    uoms,
  } = props;
  const uomsOptions = uoms.map(uom => ({
    uom,
    label: `${uom.name} / ${uom.regionalConfigurationName}`,
    value: `${uom.id}`,
  }));
  const colDefs = [
    {
      headerName: '#',
      width: 60,
      checkboxSelection: true,
      sortable: false,
      suppressMenu: true,
      pinned: true,
      filter: false,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
    },
    {
      headerName: formatMessage(messages.regionalConfigurationName),
      minWidth: 160,
      field: 'regionalConfigurationName',
      colId: 'regionalConfigurationName',
      menuTabs: ['filterMenuTab'],
    },
    {
      headerName: formatMessage(messages.name),
      minWidth: 160,
      field: 'name',
      colId: 'name',
      valueGetter: params => (params.data && `${params.data.name} (${params.data.variableName})`) || '',
      menuTabs: ['filterMenuTab'],
    },
    {
      headerName: formatMessage(messages.uom),
      minWidth: 60,
      field: 'uom',
      colId: 'uom',
      editable: false,
      suppressSizeToFit: true,
      suppressMenu: true,
      filterValueGetter: UOMValueGetter,
      valueFormatter: params => params.value && params.value.name,
      cellRendererFramework: DropDownCellEditor,
      cellRendererParams: {
        placeholder: formatMessage(messages.selectUOM),
        getValue: uom => (uom && `${uom.id}`) || '',
        getValues: params => uomsOptions,
        valueKey: 'uom',
      },
    },
    {
      headerName: formatMessage(messages.type),
      minWidth: 100,
      field: 'variable',
      colId: 'variable',
      menuTabs: ['filterMenuTab'],
      valueGetter: params =>
        (params.data.variable === true && formatMessage(messages.variable)) || formatMessage(messages.volumeCategory),
    },
  ];
  return colDefs;
};

function validateData(rows) {
  if (rows.filter(r => !r.uom).length > 0) {
    return false;
  }
  return true;
}

/* eslint-disable react/prefer-stateless-function */
function PVAddLine(props) {
  const [showDialog, setShowDialog] = useState(false);
  const [data, setData] = useState(null);
  const [vcList, setVCList] = useState(null);
  const {
    token,
    dispatch,
    formik: { values: { planningParameters, reloadCount } = {} },
    uoms,
    page,
  } = props;
  const ppId = planningParameters && planningParameters.id;

  function addLine(rows) {
    const payload = {};
    payload.volumeCategoryParameters =
      rows.map(row => ({
        volumeCategoryId: row.id,
        uomId: row.uom.id,
      })) || [];
    if (page === 'plan') {
      dispatch(addPlannedVolumeLine(payload));
    }
    if (page === 'area') {
      dispatch(addBudgetVolumeLine(payload));
    }
  }

  // filter data by usedVC
  useEffect(() => {
    if (!vcList) return;
    const vcp = planningParameters && planningParameters.volumeCategoryParameters;
    if (vcp && (vcp.rowData || vcp.rowDataVar)) {
      let allVcp = [];
      if (vcp.rowData) {
        allVcp = vcp.rowData.map(vc => vc.volumeCategory.id);
      }

      if (vcp.rowDataVar) {
        allVcp = allVcp.concat(vcp.rowDataVar.map(vc => vc.volumeCategory.id));
      }
      if (allVcp.length > 0) {
        const filteredVCList = vcList.filter(v => allVcp.indexOf(v.id) === -1);
        setData(filteredVCList);
      } else {
        setData(vcList);
      }
    } else {
      setData(vcList);
    }
  }, [vcList, planningParameters]);

  async function loadVolumeCategories() {
    const response = await fetchData(
      withUrl(`/volumeCategories/?expression=&pageSize=100000&planningParametersId=${ppId}`).andToken(token),
      dispatch,
    );
    if (response.isOk && response.data && response.data.results) {
      setVCList(response.data.results);
    } else setVCList([]);
  }

  useEffect(() => {
    loadVolumeCategories();
  }, [token, reloadCount]);

  return (
    <div className="addLine">
      <FormattedMessage {...messages.volumeCategory} />

      <div className="addLineButton">
        <ButtonWithDirtyCheck actionHandler={() => setShowDialog(true)} component={AddLineButton} />
      </div>
      {data && uoms && (
        <SelectDialog
          title={messages.title}
          onSelected={addLine}
          data={data}
          isOpen={showDialog}
          validateData={validateData}
          messages={messages}
          onClose={() => setShowDialog(false)}
          columnDefs={columnDefs(props)}
          frameworkComponents={{
            dropDown: DropDownCellEditor,
          }}
        />
      )}
    </div>
  );
}

const PlannedVolumeAddLine = formikConnect(injectIntl(PVAddLine));

/* PlannedVolumeAddLine.propTypes = {
  guiState: PropTypes.object,
  addPlannedVolumeLine: PropTypes.func,
  token: PropTypes.string,
  formik: PropTypes.object,
}; */

const withPlanConnect = connect(
  createStructuredSelector({
    guiState: selectGuiState,
    token: makeSelectToken(),
    uoms: selectUOMS,
    page: () => 'plan',
  }),
);

const withPaConnect = connect(
  createStructuredSelector({
    guiState: selectGuiStateForPa,
    token: makeSelectToken(),
    uoms: selectUOMS,
    page: () => 'area',
  }),
);

export default withPlanConnect(PlannedVolumeAddLine);
export const BudgetVolumeAddLine = withPaConnect(PlannedVolumeAddLine);
