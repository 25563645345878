// @flow

import React from 'react';
import { FormattedMessage } from 'react-intl';
import UISelect from 'components/UISelect';
import { ConfirmButton } from 'components/IconButton';
import messages from './messages';
import './style.css';

type Props = {
  departments: Array<Object>,
  onChange: Function,
  onConfirm: Function,
};

function ActivityHeader(props: Props) {
  const { departments, onChange, onConfirm } = props;
  return (
    <div className="ActiveHeader">
      <div className="ActiveHeader__Title">
        <FormattedMessage {...messages.selectActivityTitle} />
      </div>
      <div className="ActiveHeader__Department">
        <UISelect
          className="ActiveHeader__Select"
          placeholder={<FormattedMessage {...messages.departmentPlaceholder} />}
          options={departments.map(d => ({ value: d.id, label: d.name }))}
          onChange={onChange}
          SelectProps={{
            isClearable: true,
          }}
        />
        <ConfirmButton className="ActiveHeader__ConfirmBtn" onClick={onConfirm} />
      </div>
    </div>
  );
}

export default ActivityHeader;
