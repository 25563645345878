import { fromJS } from 'immutable';
import { STORE_SHIFT_SCHEDULE, RESET_STORE_SHIFT_SCHEDULE } from '../ShiftScheduleDetailPage/constants';

export const initialState = fromJS({
  availableKronosJobs: [],
  shiftScheduleActivity: {},
});

function shiftScheduleActivityDetailPageReducer(state = initialState, action) {
  switch (action.type) {
    case STORE_SHIFT_SCHEDULE:
      return state.set('shiftSchedule', action.payload);
    case RESET_STORE_SHIFT_SCHEDULE:
      return state.set('shiftSchedule', null);  
    default:
      return state;
  }
}

export default shiftScheduleActivityDetailPageReducer;
