/**
 *
 * ResultMatrixColumnOptions
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';
import { injectIntl } from 'react-intl';
import { compose } from 'redux';
import BarGraph from '../../components/BarGraph';
import { formatNumberForExport } from './utils';

/* eslint-disable react/prefer-stateless-function */
class TrendGraph extends React.PureComponent {
  tooltip = params => {
    const { messages, intl } = this.props;
    const isEffort = /.*effort/.test(params.seriesId);
    const unit = isEffort ? 'h' : 'FTE';
    const isPlan = /.*plan/.test(params.seriesId);
    const isBaseLine = /.*budget/.test(params.seriesId);
    const isIndirect = /.*indirect/.test(params.seriesId);
    const isAvailable = /.*availability/.test(params.seriesId);
    const prefix = isPlan
      ? intl.formatMessage(messages.planned)
      : isBaseLine
      ? intl.formatMessage(messages.baseline)
      : isAvailable
      ? intl.formatMessage(messages.availability)
      : intl.formatMessage(messages.actuals);
    const sufix = isEffort ? intl.formatMessage(messages.e) : intl.formatMessage(messages.fte);
    const main = isAvailable
      ? ''
      : isIndirect
      ? intl.formatMessage(messages.indirect)
      : intl.formatMessage(messages.direct);
    const marker = params.marker.replace('[object Object]', params.color.colorStops[0].color);
    const value = formatNumberForExport(params.value, 2);

    return `${marker} ${main} ${prefix} ${sufix}: ${value} ${unit}`;
  };

  getUnitByData = () => {
    if (this.props.data && this.props.data.data && this.props.data.data.length > 0) {
      const {
        props: {
          data: { data },
        },
      } = this;
      const isEffort = /.*effort/.test(data[0].name);
      return isEffort ? 'h' : 'FTE';
    }
    return '';
  };

  render() {
    return (
      <BarGraph
        axisYFormatter={`{value} ${this.getUnitByData()}`}
        axisXFormatter={formatAxisX}
        {...this.props.data}
        messages={this.props.messages}
        tooltipFormatter={params =>
          `${formatAxisX(params[0].axisValueLabel)}: <br/> ${params.map(this.tooltip).join('<br/>')}`
        }
      />
    );
  }
}

const formatAxisX = params => params;

TrendGraph.propTypes = {
  data: PropTypes.object,
  messages: PropTypes.object,
  theme: PropTypes.object,
  intl: PropTypes.object,
};

export default compose(
  injectIntl,
  withTheme,
)(TrendGraph);
