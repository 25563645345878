import React, { useState } from 'react';
import { ColDef, ColGroupDef } from 'ag-grid-community';
import { connect as formikConnect, FormikProps } from 'formik';
import { DateTime } from 'luxon';
import { InjectedIntl, injectIntl } from 'react-intl';
import styled from 'styled-components';

import { GroupWithToolTip } from 'components/NameWithToolTip';
import AgTable from 'components/Table';
import GroupFilter from 'components/Table/filter/GroupFilter';
import { TableWrap } from 'containers/PlanResultPage/styled';
import { checksumPercentageFormatter } from 'utils/checksum';
import { formatDate } from 'utils/dateTime';
import { useEffectDeepCompare } from 'utils/utils';
import { ApiForecastPerOmsCodeTableDTO } from 'types/drep-backend.d';

import messages from './messages';
import {
  EntityEntry,
  ForecastConversionRateTableDTOWithId,
  ForecastForm,
  ForecastListEntityEntry,
  ForecastPerOMSCodeColumns,
} from './types';

const Table = styled(AgTable)`
  margin-top: 12px;
  height: ${props => props.height}px;

  .ag-overlay-no-rows-wrapper {
    padding-top: 90px;
  }

  .ag-header-cell-label .ag-header-cell-text {
    white-space: normal !important;
    line-height: 20px !important;
    text-overflow: initial !important;
    padding-top: 8px;
  }

  .ag-body-viewport div[col-id='activityForecastId'] {
    white-space: normal;
    line-height: 20px;
    overflow: auto;
  }

  .ag-rich-select-list {
    width: 360px;
  }
`;

const DEFAULT_MODEL = 'ml-weekday';

const columnDefs = (
  formik,
  omsCodesById: { [value: number]: EntityEntry },
  activityForecastList: ForecastListEntityEntry[],
  forecastPerOMSCode: ApiForecastPerOmsCodeTableDTO,
  displayedColumns: ForecastPerOMSCodeColumns,
  dates: DateTime[],
  intl: InjectedIntl,
  hasAdjustments: boolean
) => {
  const classNameForCell = (params, i) => {
    const leafData = params.node.allLeafChildren?.[0].data || params.data;
    const data = leafData?.omsDates?.[i];
    if (data?.model && data?.model !== DEFAULT_MODEL) {
      return 'cell-data-source-not-default';
    }
    return '';
  };
  const tooltipValueGetter = (params, i) => {
    const leafData = params.node.allLeafChildren?.[0].data || params.data;
    const data = leafData?.omsDates?.[i];
    const model = data?.model ? data.model : '-';
    return intl.formatMessage(messages.omsCodesTableModelTooltip, { model });
  };

  const columns: (ColGroupDef | ColDef)[] = [
    {
      colId: 'smartProdSourceName',
      field: 'smartProdSourceName',
      filter: 'setFilter',
      headerName: intl.formatMessage(messages.omsCodesTableSmartProdSource),
      cellRenderer: 'agGroupCellRenderer',
      checkboxSelection: false,
      sortable: true,
      pinned: 'left',
      width: 180,
      hide: true,
      suppressSizeToFit: true,
      rowGroup: true,
      resizable: true,
    },
    {
      colId: 'omsId',
      field: 'omsId',
      filter: 'setFilter',
      headerName: intl.formatMessage(messages.omsCodesTableOMSCode),
      valueFormatter: ({ value }) => omsCodesById[value]?.label || '',
      cellRenderer: 'agGroupCellRenderer',
      sortable: true,
      pinned: 'left',
      width: 150,
      hide: true,
      suppressSizeToFit: true,
      rowGroup: true,
      resizable: true,
    },
    {
      colId: 'activityForecastName',
      field: 'activityForecastName',
      filter: 'setFilter',
      headerName: intl.formatMessage(messages.omsCodesTableSmartPlanActivity),
      sortable: true,
      pinned: 'left',
      width: 260,
      suppressSizeToFit: true,
      hide: true,
      resizable: true,
      rowGroup: true,
    },
    {
      colId: 'omsCodesTableSmartProdJobCode',
      field: 'omsCodesTableSmartProdJobCode',
      filter: 'setFilter',
      headerName: intl.formatMessage(messages.omsCodesTableSmartProdJobCodeWithCustomer),
      sortable: true,
      pinned: 'left',
      hide: true,
      width: 300,
      suppressSizeToFit: true,
      resizable: true,
    },
  ];
  return [
    ...columns,
    ...dates.map((date, i) => ({
      field: 'date',
      headerName: `${formatDate(date)}`,
      children: [
        ...(displayedColumns.effortSmartVolume
          ? [
              {
                headerName: intl.formatMessage(messages.omsCodesTableEffortSmartVolume),
                valueGetter: params => {
                  if (params.node.group) {
                    const leafData = params.node.allLeafChildren?.[0].data;
                    if (params.node.level === 0) {
                      return '-';
                    }
                    if (params.node.level === 1) {
                      const data = leafData?.omsDates?.[i] || {};
                      if (data?.effortSmartVolume === undefined || data?.effortSmartVolume === null) {
                        return '-';
                      }
                      return data?.effortSmartVolume ? data.effortSmartVolume.toFixed(2) : 0;
                    }
                    if (params.node.level === 2) {
                      const data = leafData?.activityDates?.[i] || {};
                      if (data?.effortSmartVolume === undefined || data?.effortSmartVolume === null) {
                        return '-';
                      }
                      return data?.effortSmartVolume ? data.effortSmartVolume.toFixed(2) : 0;
                    }
                    return `${params.node.level}`;
                  }
                  return '-';
                },
                cellClass: params => classNameForCell(params, i),
                tooltipValueGetter: params => tooltipValueGetter(params, i),
                width: 120,
                filter: null,
                sortable: false,
                suppressSizeToFit: true,
              },
            ]
          : []),
          ...(displayedColumns.effortSmartVolumeWithEvent
            ? [
                {
                  headerName: intl.formatMessage(messages.omsCodesTableEffortSmartVolumeEvent),
                  valueGetter: params => {
                    if (params.node.group) {
                      const leafData = params.node.allLeafChildren?.[0].data;
                      if (params.node.level === 0) {
                        return '-';
                      }
                      if (params.node.level === 1) {
                        const data = leafData?.omsDates?.[i] || {};
                        if (data?.effortSmartVolumeWithEvent === undefined || data?.effortSmartVolumeWithEvent === null) {
                          return '-';
                        }
                        return data?.effortSmartVolumeWithEvent ? data.effortSmartVolumeWithEvent.toFixed(2) : 0;
                      }
                      if (params.node.level === 2) {
                        const data = leafData?.activityDates?.[i] || {};
                        if (data?.effortSmartVolumeWithEvent === undefined || data?.effortSmartVolumeWithEvent === null) {
                          return '-';
                        }
                        return data?.effortSmartVolumeWithEvent ? data.effortSmartVolumeWithEvent.toFixed(2) : 0;
                      }
                      return `${params.node.level}`;
                    }
                    return '-';
                  },
                  cellClass: 'cell-smartVolumeEvent',
                  tooltipValueGetter: params => tooltipValueGetter(params, i),
                  width: 120,
                  filter: null,
                  sortable: false,
                  suppressSizeToFit: true,
                },
              ]
            : []),
            ...(displayedColumns.effortSmartVolumeWithAdjustment && hasAdjustments
              ? [
                  {
                    headerName: intl.formatMessage(messages.omsCodesTableeffortSmartVolumeWithAdjustment),
                    valueGetter: params => {
                      if (params.node.group) {
                        const leafData = params.node.allLeafChildren?.[0].data;
                        if (params.node.level === 0) {
                          return '-';
                        }
                        if (params.node.level === 1) {
                          const data = leafData?.omsDates?.[i] || {};
                          if (data?.effortSmartVolumeWithAdjustment === undefined || data?.effortSmartVolumeWithAdjustment === null) {
                            return '-';
                          }
                          return data?.effortSmartVolumeWithAdjustment ? data.effortSmartVolumeWithAdjustment.toFixed(2) : 0;
                        }
                        if (params.node.level === 2) {
                          const data = leafData?.activityDates?.[i] || {};
                          if (data?.effortSmartVolumeWithAdjustment === undefined || data?.effortSmartVolumeWithAdjustment === null) {
                            return '-';
                          }
                          return data?.effortSmartVolumeWithAdjustment ? data.effortSmartVolumeWithAdjustment.toFixed(2) : 0;
                        }
                        return `${params.node.level}`;
                      }
                      return '-';
                    },
                    cellClass: 'cell-smartProdEventAdjustment',
                    tooltipValueGetter: params => tooltipValueGetter(params, i),
                    width: 120,
                    filter: null,
                    sortable: false,
                    suppressSizeToFit: true,
                  },
                ]
              : []),
        ...(displayedColumns.effortSmartProd
          ? [
              {
                headerName: intl.formatMessage(messages.omsCodesTableEffortSmartProd),
                valueGetter: params => {
                  if (params.node.group) {
                    const leafData = params.node.allLeafChildren?.[0].data;
                    if (params.node.level === 0) {
                      return '-';
                    }
                    if (params.node.level === 1) {
                      const data = leafData?.omsDates?.[i] || {};
                      return data?.effortSmartProd ? data.effortSmartProd.toFixed(2) : 0;
                    }
                    if (params.node.level === 2) {
                      const data = leafData?.activityDates?.[i] || {};
                      return data?.effortSmartProd ? data.effortSmartProd.toFixed(2) : 0;
                    }
                    return `${params.node.level}`;
                  }
                  const data = params.data?.jobDates?.[i] || {};
                  return data?.effortSmartProd ? data.effortSmartProd.toFixed(2) : 0;
                },
                cellClass: 'cell-smartProd',
                width: 120,
                filter: null,
                sortable: false,
                suppressSizeToFit: true,
              },
            ]
          : []),
          ...(displayedColumns.effortSmartProdWithEvent
            ? [
                {
                  headerName: intl.formatMessage(messages.omsCodesTableEffortSmartProdEvent),
                  valueGetter: params => {
                    if (params.node.group) {
                      const leafData = params.node.allLeafChildren?.[0].data;
                      if (params.node.level === 0) {
                        return '-';
                      }
                      if (params.node.level === 1) {
                        const data = leafData?.omsDates?.[i] || {};
                        return data?.effortSmartProdWithEvent ? data.effortSmartProdWithEvent.toFixed(2) : 0;
                      }
                      if (params.node.level === 2) {
                        const data = leafData?.activityDates?.[i] || {};
                        return data?.effortSmartProdWithEvent ? data.effortSmartProdWithEvent.toFixed(2) : 0;
                      }
                      return `${params.node.level}`;
                    }
                    const data = params.data?.jobDates?.[i] || {};
                    return data?.effortSmartProdWithEvent ? data.effortSmartProdWithEvent.toFixed(2) : 0;
                  },
                  cellClass: 'cell-smartProdEvent',
                  width: 120,
                  filter: null,
                  sortable: false,
                  suppressSizeToFit: true,
                },
              ]
            : []),
            ...(displayedColumns.effortSmartProdWithEventAndAdjustment && hasAdjustments
              ? [
                  {
                    headerName: intl.formatMessage(messages.omsCodesTableeffortSmartProdWithEventAndAdjustment),
                    valueGetter: params => {
                      if (params.node.group) {
                        const leafData = params.node.allLeafChildren?.[0].data;
                        if (params.node.level === 0) {
                          return '-';
                        }
                        if (params.node.level === 1) {
                          const data = leafData?.omsDates?.[i] || {};
                          return data?.effortSmartProdWithEventAndAdjustment ? data.effortSmartProdWithEventAndAdjustment.toFixed(2) : 0;
                        }
                        if (params.node.level === 2) {
                          const data = leafData?.activityDates?.[i] || {};
                          return data?.effortSmartProdWithEventAndAdjustment ? data.effortSmartProdWithEventAndAdjustment.toFixed(2) : 0;
                        }
                        return `${params.node.level}`;
                      }
                      const data = params.data?.jobDates?.[i] || {};
                      return data?.effortSmartProdWithEventAndAdjustment ? data.effortSmartProdWithEventAndAdjustment.toFixed(2) : 0;
                    },
                    cellClass: 'cell-smartProdEventAdjustment',
                    width: 130,
                    filter: null,
                    sortable: false,
                    suppressSizeToFit: true,
                  },
                ]
              : []),
        ...(displayedColumns.actualUPH
          ? [
              {
                headerName: intl.formatMessage(messages.omsCodesTableActualUPH),
                valueGetter: params => {
                  if (params.node.group) {
                    const leafData = params.node.allLeafChildren?.[0].data;
                    if (params.node.level === 0) {
                      return '-';
                    }
                    if (params.node.level === 1) {
                      return '-';
                    }
                    if (params.node.level === 2) {
                      const data = leafData?.activityDates?.[i] || {};
                      return data?.productivityRateActual ? data.productivityRateActual.toFixed(2) : 0;
                    }
                    return `${params.node.level}`;
                  }
                  const data = params.data?.jobDates?.[i] || {};
                  return data?.productivityRateActual ? data.productivityRateActual.toFixed(2) : 0;
                },
                width: 120,
                filter: null,
                sortable: false,
                suppressSizeToFit: true,
              },
            ]
          : []),
        ...(displayedColumns.targetUPH
          ? [
              {
                headerName: intl.formatMessage(messages.omsCodesTableTargetUPH),
                valueGetter: params => {
                  if (params.node.group) {
                    const leafData = params.node.allLeafChildren?.[0].data;
                    if (params.node.level === 0) {
                      return '-';
                    }
                    if (params.node.level === 1) {
                      return '-';
                    }
                    if (params.node.level === 2) {
                      const data = leafData?.activityDates?.[i] || {};
                      return data?.productivityRateTarget ? data.productivityRateTarget.toFixed(2) : 0;
                    }
                    return `${params.node.level}`;
                  }
                  const data = params.data?.jobDates?.[i] || {};
                  return data?.productivityRateTarget ? data.productivityRateTarget.toFixed(2) : 0;
                },
                width: 120,
                filter: null,
                sortable: false,
                suppressSizeToFit: true,
              },
            ]
          : []),
        ...(displayedColumns.ratio
          ? [
              {
                headerName: intl.formatMessage(messages.omsCodesTableRatio),
                valueGetter: params => {
                  if (params.node.group) {
                    const leafData = params.node.allLeafChildren?.[0].data;
                    if (params.node.level === 0) {
                      return '-';
                    }
                    if (params.node.level === 1) {
                      let sumOfActivityRatios = 0;
                      leafData?.activities?.forEach(activity => {
                        const dateData = activity.dates[i] || {};
                        sumOfActivityRatios += dateData?.ratioSmartProd || 0;
                      });
                      return sumOfActivityRatios;
                    }
                    if (params.node.level === 2) {
                      const data = leafData?.activityDates?.[i] || {};
                      return data?.ratioSmartProd || 0;
                    }
                    return `-`;
                  }
                  const data = params.data?.jobDates?.[i] || {};
                  return data ? data.ratio : 0;
                },
                valueFormatter: params =>
                  // eslint-disable-next-line no-restricted-globals
                  isNaN(params.value) ? '-' : checksumPercentageFormatter({ value: params.value * 100 }),
                width: 100,
                filter: null,
                sortable: false,
                suppressSizeToFit: true,
              },
            ]
          : []),
        ...(displayedColumns.model
          ? [
              {
                headerName: intl.formatMessage(messages.omsCodesTableFieldName_model),
                valueGetter: params => {
                  if (params.node.group) {
                    const leafData = params.node.allLeafChildren?.[0].data;
                    if (params.node.level === 1) {
                      const data = leafData?.omsDates?.[i] || {};
                      return data?.model;
                    }
                  }
                  return `-`;
                },
                cellClass: params => classNameForCell(params, i),
                width: 100,
                filter: null,
                sortable: false,
                suppressSizeToFit: true,
              },
            ]
          : []),
      ],
    })),
  ];
};

type Props = {
  formik?: FormikProps<ForecastForm>;
  intl: InjectedIntl;
  forecastConversionRates: ForecastConversionRateTableDTOWithId[];
  forecastPerOMSCode: ApiForecastPerOmsCodeTableDTO;
  dates: DateTime[];
  allOmsCodes: EntityEntry[];
  activityForecastList: ForecastListEntityEntry[];
  displayedColumns: ForecastPerOMSCodeColumns;
  hasAdjustments: boolean;
};

const ForecastPerOMSCodeTable: React.FC<Props> = ({
  formik,
  intl,
  forecastConversionRates,
  forecastPerOMSCode,
  dates,
  allOmsCodes,
  activityForecastList,
  displayedColumns,
  hasAdjustments,
}) => {
  const [gridApi, setGridApi] = useState(null);
  const omsCodesById = {};
  if (allOmsCodes) {
    allOmsCodes.forEach(omsCode => {
      omsCodesById[omsCode.value] = omsCode;
    });
  }

  const onGridReady = params => {
    setGridApi(params);
    params.api.sizeColumnsToFit();
  };

  useEffectDeepCompare(() => {
    if (gridApi) {
      const colDefs = columnDefs(
        formik,
        omsCodesById,
        activityForecastList,
        forecastPerOMSCode,
        displayedColumns,
        dates,
        intl,
        hasAdjustments,
      );
      gridApi.api.setColumnDefs(colDefs);
      gridApi.api.sizeColumnsToFit();
    }
  }, [gridApi, forecastConversionRates, forecastPerOMSCode]);

  const tableData = [];
  forecastPerOMSCode?.oms?.forEach(oms => {
    oms?.activities?.forEach(activity => {
      activity?.jobs?.forEach(job => {
        const jobData = forecastConversionRates.find(c => c.smartProdJobCodeId === job.smartProdJobCodeId);
        const omsCodesTableSmartProdJobCode = jobData
          ? `${jobData.smartProdJobCodeName} (${jobData.customer} / ${jobData.smartProdJobCodeUomName})`
          : '';
        const omsId = forecastConversionRates.find(c => c.omsId === oms.omsId)?.omsId;
        const smartProdSourceName = forecastConversionRates.find(c => c.smartProdSourceId === oms.smartProdSourceId)
          ?.smartProdSourceName;
        const activityForecastName = activityForecastList.find(s => s.value === activity.activityForecastId)?.label;
        const ForecastOutOfPlanDateIntervalData = activityForecastList?.find(s => s?.value === activity?.activityForecastId);
        if (oms.smartProdSourceId) {
          tableData.push({
            omsId,
            smartProdSourceName,
            activityForecastName,
            omsCodesTableSmartProdJobCode,
            activities: oms.activities,
            omsDates: oms.dates,
            activityDates: activity.dates,
            jobDates: job.dates,
            //@ts-ignore
            ForecastOutOfPlanDateInterval: ForecastOutOfPlanDateIntervalData?.ForecastOutOfPlanDateInterval
          });
        }
      });
    });
  });
  tableData.sort((a, b) => {
    const codeA = omsCodesById[a.omsId]?.label || '';
    const codeB = omsCodesById[b.omsId]?.label || '';
    if (codeA === codeB) {
      if (a.activityForecastName === b.activityForecastName) {
        return a.omsCodesTableSmartProdJobCode < b.omsCodesTableSmartProdJobCode ? -1 : 1;
      }
      return a.activityForecastName < b.activityForecastName ? -1 : 1;
    }
    return codeA < codeB ? -1 : 1;
  });

  const ROW_HEIGHT = 30;

  return (
    allOmsCodes && (
      <TableWrap>
        <Table
          defaultColDef={{
            flex: 1,
          }}
          frameworkComponents={{
            acWithToolTip: GroupWithToolTip(),
            groupFilter: GroupFilter,
          }}
          sortable
          pagination={false}
          suppressMovableColumns
          columnDefs={columnDefs(
            formik,
            omsCodesById,
            activityForecastList,
            forecastPerOMSCode,
            displayedColumns,
            dates,
            intl,
            hasAdjustments,
          )}
          rowData={tableData}
          rowHeight={ROW_HEIGHT}
          headerHeight={ROW_HEIGHT * 2.5}
          groupHeaderHeight={ROW_HEIGHT}
          height={300 + ROW_HEIGHT * Math.min(10, tableData.length)}
          onGridReady={onGridReady}
          isRowSelectable={() => false}
          groupDefaultExpanded={1}
          autoGroupColumnDef={{
            colId: 'groupping',
            headerName: `${intl.formatMessage(messages.omsCodesTableSmartProdSource)} \u2192 ${intl.formatMessage(
              messages.omsCodesTableOMSCode,
            )} \u2192 ${intl.formatMessage(messages.omsCodesTableSmartPlanActivity)} \u2192 ${intl.formatMessage(
              messages.omsCodesTableSmartProdJobCodeWithCustomer,
            )}`,
            field: 'omsCodesTableSmartProdJobCode',
            valueFormatter: data => omsCodesById[data.value]?.label || '',
            width: 620,
            suppressSizeToFit: true,
            pinned: true,
            cellStyle: params => {
              //@ts-ignore
              const highLightActivityData = activityForecastList?.filter((activity)=>activity?.ForecastOutOfPlanDateInterval) || [];
              const isHighlighted = highLightActivityData?.filter((act) =>act?.label == params?.value);
              if(isHighlighted?.length > 0){
                return {color: 'red'}
              }
            },
            cellRenderer: 'agGroupCellRenderer',
            cellRendererParams: { checkbox: true },
            menuTabs: ['filterMenuTab'],
            filter: 'groupFilter',
            filterParams: {
              columns: ['omsId', 'smartProdSourceName', 'activityForecastName', 'omsCodesTableSmartProdJobCode'],
              applyButton: true,
              clearButton: true,
            },
          }}
          suppressAggFuncInHeader
          showCollapseButtonsInsideTable
        />
      </TableWrap>
    )
  );
};

export default injectIntl(formikConnect(ForecastPerOMSCodeTable));
