/*
 * DeleteCellRenderer Messages
 *
 * This contains all the text for the DeleteCellRenderer component.
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  noOpts: {
    id: 'app.components.DropDownCellEditor.noOpts',
    defaultMessage: 'No Options',
  },
});
