// Import Azure stuff
import { AccountInfo, PublicClientApplication, RedirectRequest, Configuration } from '@azure/msal-browser';

import { LOG_AZURE } from './logger';
import { ACTIVE_ENV } from './activeEnv';

const AZURE_CLIENT_ID = '53823968-4fee-43fe-b2e1-bcb5b45c187c';

const msaConfig: Configuration = {
  auth: {
    clientId: AZURE_CLIENT_ID,
    authority: 'https://login.microsoftonline.com/cd99fef8-1cd3-4a2a-9bdf-15531181d65e',
    redirectUri: ACTIVE_ENV.azureRedirect,
  },
  cache: {
    cacheLocation: 'sessionStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};
// create PublicClientApplication instance
export const msalInstance = new PublicClientApplication(msaConfig);

export const fetchAccessToken = (): Promise<string> | undefined => {
  const myAccount = getActiveAccount();
  if (myAccount) {
    return new Promise(resolve => {
      msalInstance.acquireTokenSilent({ ...loginRequest, account: myAccount }).then(response => {
        LOG_AZURE.d('acquireTokenSilent response', response);
        resolve(response.accessToken);
      });
    });
  }
  LOG_AZURE.d('No active account, login redirect');
  return new Promise(() => {
    msalInstance.loginRedirect(loginRequest).then(response => {
      LOG_AZURE.d('Login redirect response', response);
    });
  });
};

export const loginRequest: RedirectRequest = {
  scopes: [`${AZURE_CLIENT_ID}/.default`],
};

export const logoutRequest: RedirectRequest = {
  scopes: [`${AZURE_CLIENT_ID}/.default`],
  redirectUri: window.location.origin,
};

type AccountHolder = {
  value: AccountInfo | null;
};

const activeAccountHolder: AccountHolder = {
  value: null,
};

export const setActiveAccount = (account: AccountInfo | null) => {
  activeAccountHolder.value = account;
};

export const getActiveAccount = () => activeAccountHolder.value;

export const logoutRedirect = () => {
  const account = msalInstance.getActiveAccount();
  return msalInstance.logoutRedirect({ ...logoutRequest, account });
};
