import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { connect as formikConnect } from 'formik';
import { isEmpty } from 'lodash';
import { injectIntl } from 'react-intl';
import styled, { withTheme } from 'styled-components';

import StyledLabel from 'components/Label';
import { formatDate, formatDateTime } from 'utils/dateTime';
import { ApiMasterPlanReportingRunDTO, ApiPlanDTO } from 'types/drep-backend.d';

import messages from './messages';

const DetailWrapper = styled.div`
  max-width: 1440px;
  padding: 20px;
  z-index: 100;

  div > span {
  }

  span {
    display: inline-block;
    vertical-align: middle;
    line-height: normal;
  }
`;

const PlansWrapper = styled.div`
  height: 220px;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
`;

const CenteredLabel = styled(StyledLabel)`
  height: 100%;
  display: table;

  > span {
    display: table-cell;
    vertical-align: middle;
  }
`;

const ValueLabel = styled.span``;

type Props = {
  data: ApiMasterPlanReportingRunDTO;
  plans: ApiPlanDTO[];
};

const RunsTableDetailCellRenderer: React.FC<Props> = ({ data, plans }) => {
  const [plansById, setPlansById] = useState({});
  const renderField = (labelName, value, labelWidth = undefined, inputWidth = undefined) => (
    <>
      {labelName && (
        <Grid item xs={labelWidth || 4}>
          <CenteredLabel {...labelName} />
        </Grid>
      )}
      <Grid item xs={inputWidth || 8}>
        <ValueLabel>{value}</ValueLabel>
      </Grid>
    </>
  );

  useEffect(() => {
    const pById = {};
    if (plans) {
      plans.forEach(plan => {
        pById[plan.id] = plan;
      });
    }
    setPlansById(pById);
  }, [plans]);

  return (
    <DetailWrapper>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CenteredLabel {...messages.reportingPeriod} />
        </Grid>
        <Grid item xs={2}>
          <Grid container spacing={1}>
            {renderField(messages.periodStartDay, formatDate(data.periodStartDay))}
            <Grid item xs={6} />
            <Grid item xs={6} />
          </Grid>
        </Grid>
        <Grid item xs={2}>
          <Grid container spacing={1}>
            {renderField(messages.periodEndDay, formatDate(data.periodEndDay))}
            <Grid item xs={6} />
            <Grid item xs={6} />
          </Grid>
        </Grid>
        <Grid item xs={2}>
          <Grid container spacing={1}>
            {renderField(messages.finished, formatDateTime(data.finishedDate))}
            <Grid item xs={6} />
            <Grid item xs={6} />
          </Grid>
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={5}>
          <Grid container spacing={1}>
            {[
              {
                label: messages.status,
                value: data.status,
              },
              {
                label: messages.requested,
                value: data.requestedDate,
                format: formatDateTime,
              },
              {
                label: messages.requestedBy,
                value: data.requestedBy,
                format: value => (value ? `${value.firstName} ${value.lastName}` : ''),
              },
            ].map(({ label, value, format }) => (
              <React.Fragment key={label.id}>{renderField(label, format ? format(value) : value, 3, 9)}</React.Fragment>
            ))}
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <PlansWrapper>
            <Grid container spacing={1}>
              <Grid item xs={4}>
                <CenteredLabel {...messages.planUsed} />
              </Grid>
              <Grid item xs={4}>
                <CenteredLabel {...messages.periodStartDay} />
              </Grid>
              <Grid item xs={4}>
                <CenteredLabel {...messages.periodEndDay} />
              </Grid>
              {!isEmpty(plansById) &&
                data.targetPlans &&
                data.targetPlans.map(targetPlan => {
                  const plan = plansById[targetPlan.planId];
                  if (!plan) {
                    return null;
                  }
                  return (
                    <React.Fragment key={targetPlan.id}>
                      <Grid item xs={4}>
                        {plan.name}
                      </Grid>
                      <Grid item xs={4}>
                        {formatDate(targetPlan.validFrom)}
                      </Grid>
                      <Grid item xs={4}>
                        {formatDate(targetPlan.validTo)}
                      </Grid>
                    </React.Fragment>
                  );
                })}
            </Grid>
          </PlansWrapper>
        </Grid>
      </Grid>
    </DetailWrapper>
  );
};

export default injectIntl(formikConnect(withTheme(RunsTableDetailCellRenderer)));
