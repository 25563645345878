const parser = require('./formulaParser');

export function formulaToText(formula, vars) {
  // console.log('Converting formula to text', formula);
  if (formula.r) {
    return nodeWithPossibleBracketsToText(formula.r, vars);
  }
  console.warn('Root element r not found in formula:', formula);
  return '0';
}

function nodeWithPossibleBracketsToText(node, vars) {
  let text = [];
  if (!node) return [];
  if (node.brackets === true) {
    text = ['('];
    text = text.concat(nodeToText(node, vars));
    text.push(')');
    return text;
  }
  return nodeToText(node, vars);
}

function nodeToText(node, vars) {
  if (node.c || node.c === 0) {
    return [node.c]; // constant
  }
  if (node.v) {
    if (vars && node.v in vars) return [{ text: node.v, tooltip: vars[node.v] }];
    return [node.v]; // variable
  }
  if (node.p) {
    return [`${node.p}%`];
  }
  if (node.o) {
    let text = nodeWithPossibleBracketsToText(node.l, vars);
    text.push(node.o);
    text = text.concat(nodeWithPossibleBracketsToText(node.r, vars));
    return text;
  }
  console.warn('Ignored unexpected elements in formula node ', node);
  return [''];
}

export function textToFormula(text) {
  const result = parser.parse(text);
  return result;
}

export function textToFormulaActivity(text) {
  if(text === ''){
    return {c: 0}
  }
  const result = parser.parse(text);
  return result;
}
// return true on success and string with error message on failure
export function checkVariables(parsedFormula, variableNames, t, msg, selfVar) {
  // console.log('Checking parsed formula ', parsedFormula);
  const formulaVariables = [];
  const checkLevel = node => {
    if (node && node.r) {
      checkLevel(node.r);
    }
    if (node && node.l) {
      checkLevel(node.l);
    }
    if (node && node.v) {
      formulaVariables.push(node.v);
    }
  };
  checkLevel(parsedFormula);
  // console.log('Found variable names', formulaVariables);
  const wrongVariables = formulaVariables.filter(it => !(it in variableNames));
  const unknown =
    wrongVariables.length === 0 ? false : t(msg.unknownVariables, { variables: wrongVariables.join(', ') });
  const selfVars = formulaVariables.filter(it => it === selfVar);
  const selfM = selfVars.length === 0 ? false : t(msg.selfVariables, { variables: selfVars.join(', ') });
  if (!unknown && !selfM) {
    return true;
  }
  const message = [(unknown && unknown) || '', (selfM && selfM) || ''].join(' ').trim();
  return message;
}

/*

PEG.js grammar for parser, using https://pegjs.org/online

TODO: convert to webpack loader: https://github.com/eploko/pegjs-loader


Expression
  = head:Term tail:(_ ("+" / "-") _ Term)* {
      return tail.reduce(function(result, element) {
        if (element[1] === "+") { return {l:result, o:"+", r:element[3]}; }
        if (element[1] === "-") { return {l:result, o:"-", r:element[3]}; }
      }, head);
    }

Term
  = head:Factor tail:(_ ("*" / "/") _ Factor)* {
      return tail.reduce(function(result, element) {
        if (element[1] === "*") { return {l:result, o:"*", r:element[3]}; }
        if (element[1] === "/") { return {l:result, o:"/", r:element[3]}; }
      }, head);
    }

Factor
  = "(" _ expr:Expression _ ")" { return {...expr, brackets:true}; }
  / Number
  / Variable

Number "number"
  = _ [0-9]+([.,][0-9]+)? { return {c:Number(text().replace(',','.'))}; }

Variable "variable"
  = _ [a-zA-Z_][a-zA-Z0-9_]* { return {v:text()}; }

_ "whitespace"
  = [ \t\n\r]*

*/
