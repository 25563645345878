import indexOf from 'lodash/indexOf';
import slice from 'lodash/slice';

import { DAY_NAMES } from 'utils/calendar/constants';

export function sortByWeekStart(days, start) {
  if (start === undefined) {
    start = DAY_NAMES[0];
  }
  const index = indexOf(days, start);
  const putAtStart = slice(days, index);
  const putAtEnd = slice(days, 0, index);
  return putAtStart.concat(putAtEnd);
}

export function disableOffDays(offdays, toggleDayAction) {
  if (offdays)
    offdays.forEach(item => {
      toggleDayAction(item, false);
    });
  DAY_NAMES.filter(item => !offdays.includes(item)).forEach(item => {
    toggleDayAction(item, true);
  });
}

export function constructRowData(rowData, activityParametersList, departments, customers, type){
  rowData && rowData.forEach((rd)=>{
    if(rd.activityFrom && type === 'direct'){
      const activityFrom = activityParametersList && activityParametersList.filter((ap) => ap.id === rd.activityTo);
      const department = departments && departments.filter((dep) => dep.id == (activityFrom && activityFrom[0] && activityFrom[0].departmentId));
      const depName = department && department[0] && department[0].name ? department[0].name : '';
      const customer = customers && customers.filter((cust) => cust.id == (activityFrom && activityFrom[0] && activityFrom[0].customerId));
      const customerName = customer && customer[0] && customer[0].name ? customer[0].name : '';
      const omsName = activityFrom && activityFrom[0] && activityFrom[0].activity && activityFrom[0].activity.omsName;
      const omsCode = activityFrom && activityFrom[0] && activityFrom[0].activity && activityFrom[0].activity.omsCode;
      rd.oms =  omsName && omsCode ? `${omsCode}-${omsName}` : '';
      rd.activityFromValue =  `${customerName}/${depName}/${activityFrom && activityFrom[0] && activityFrom[0].activity && activityFrom[0].activity.name} (${activityFrom && activityFrom[0] && activityFrom[0].variableName})`
      rd.activityFromId = rd.activityFrom;
      rd.activityToId = rd.activityTo;
    }
    if(rd.activityTo && type === 'direct'){
      const activityTo = activityParametersList && activityParametersList.filter((ap) => ap.id === rd.activityFrom);
        const department = departments && departments.filter((dep) => dep.id == (activityTo && activityTo[0] && activityTo[0].departmentId));
        const depName = department && department[0] && department[0].name ? department[0].name : '';
        const customer = customers && customers.filter((cust) => cust.id == (activityTo && activityTo[0] && activityTo[0].customerId));
        const customerName = customer && customer[0] && customer[0].name ? customer[0].name : '';
        rd.activityToValue = `${customerName}/${depName}/${activityTo && activityTo[0] && activityTo[0].activity && activityTo[0].activity.name} (${activityTo && activityTo[0] && activityTo[0].variableName})`
    }
    if(type === 'indirect'){
      const activityTo = activityParametersList && activityParametersList.filter((ap) => ap.id === rd.activityFrom);
        const department = departments && departments.filter((dep) => dep.id == (activityTo && activityTo[0] && activityTo[0].departmentId));
        const depName = department && department[0] && department[0].name ? department[0].name : '';
        const customer = customers && customers.filter((cust) => cust.id == (activityTo && activityTo[0] && activityTo[0].customerId));
        const customerName = customer && customer[0] && customer[0].name ? customer[0].name : '';
        const omsName = activityTo && activityTo[0] && activityTo[0].activity && activityTo[0].activity.omsName;
        const omsCode = activityTo && activityTo[0] && activityTo[0].activity && activityTo[0].activity.omsCode;
        rd.oms =  omsName && omsCode ? `${omsCode}-${omsName}` : '';
        rd.activityFromValue = `${customerName}/${depName}/${activityTo && activityTo[0] && activityTo[0].activity && activityTo[0].activity.name} (${activityTo && activityTo[0] && activityTo[0].variableName})`
    }
  })
  return rowData;
}

export function constructRowDataIndirect(tempRowData){
  const reducedData = tempRowData.reduce((final, data) => {
    let isAlready = final.find((value) => {
      return value.activityFrom == data.activityFrom;
    });
    if (!isAlready) {
      final.push(data);
    } else {
      var index = final.indexOf(isAlready);
      final[index].mondayRate = parseFloat(final[index].mondayRate) + parseFloat(data.mondayRate);
      final[index].tuesdayRate = parseFloat(final[index].tuesdayRate) + parseFloat(data.tuesdayRate);
      final[index].wednesdayRate = parseFloat(final[index].wednesdayRate) + parseFloat(data.wednesdayRate);
      final[index].thursdayRate = parseFloat(final[index].thursdayRate) + parseFloat(data.thursdayRate);
      final[index].fridayRate = parseFloat(final[index].fridayRate) + parseFloat(data.fridayRate);
      final[index].saturdayRate = parseFloat(final[index].saturdayRate) + parseFloat(data.saturdayRate);
      final[index].sundayRate = parseFloat(final[index].sundayRate) + parseFloat(data.sundayRate);
    }
    return final;
  }, [])
  return reducedData
}
