import DeleteCellRenderer from 'components/DeleteCellRenderer';
import {SimpleHeader} from 'components/DetailPageShared';
import {messages} from "./messages";
import EnchantedMap from "utils/enchantedMap";
import {NamedEntity} from "containers/ShiftScheduleDetailPage/types";
import NameWithToolTip from 'components/NameWithToolTip';
import {FormikProps, getIn} from "formik";
import {PlanDetailData} from "../../containers/PlanResultPage/calculation/types";
import {GuiState} from "../../containers/PlanDetailPage/types";
import {convertArrayToMap} from "../../containers/ShiftScheduleDetailPage/utils";
import {FormattedMessage} from "react-intl";
import * as React from "react";
import Table from "components/Table/index";
import { DeleteDialog, withDeleteDialog } from '../Dialog';
import styled from "styled-components";


interface UnproductiveSectionProps {
  formik: FormikProps<PlanDetailData>;
  guiState: GuiState;
  intl: any;
  openDeleteDialog: any;
  deleteActivityLines: () => {};
}

const HeaderDiv = styled.div`
  margin-top: 25px !important;
  margin-bottom: 2px !important;
  font-weight: bold;
`;

const ErrorsDiv = styled.div`
  color: red;
  margin: 5px;
`;

export const createUnproductiveSection = config => withDeleteDialog((props: UnproductiveSectionProps) => {
  const dataPath = `planningParameters.periods.${props.guiState.periodIndex}.apCalculated.${config.dataElementName}`;
  const customDataMapping = config.customDataMapping || (it => it);
  const planningArea = props.formik.values.planningArea || props.formik.values;
  //@ts-ignore
  const departmentValues = props?.formik?.values?.planningParameters?.departments;
  const departmentsMap: EnchantedMap<number, NamedEntity> = convertArrayToMap(departmentValues);
  const data = getIn(props.formik.values, dataPath, [])
    .map((act, index) => ({
      ...act,
      index,periodIndex: props.guiState.periodIndex,
      departmentName: act.departmentId ? departmentsMap.get(act.departmentId)?.name || act.departmentId : null,
    })).map(customDataMapping); // basically semi-deep clone because of agGrid mutating original data
  const customersMap = convertArrayToMap(planningArea.customers);
  const errors = getIn(props.formik.errors, dataPath, []);
  const errorsWithNonEmptyUniqueValues = [...new Set(errors.filter(val => val))];
  const logging = config.logging;
  if (logging) {
    console.log(`FORMIK is ${props.formik.dirty ? '' : 'not '}dirty with values`, props.formik.values);
    console.log('PROPS', props);
    console.log('DATA', data);
    console.log('INITDATA', getIn(props.formik.initialValues, dataPath, []));
    console.log('ERRORS', errors);
  }
  const {formatMessage} = props.intl;
  const groupingProps = {
    groupDefaultExpanded: -1,
    autoGroupColumnDef: {
      filterValueGetter: params => params.data && params.data.departmentName || formatMessage(messages.allDepartments),
      headerName: formatMessage(messages.department), // custom header name for group
      width: config?.dataElementName === "unproductiveStaff" ? 75 : 250,
      pinned: 'left', // force pinned left. Does not work in columnDef
      cellRendererParams: {
        suppressCount: true, // remove number in Group Column
      },
      menuTabs: ['filterMenuTab'],
    }
  };
  const onCellValueChanged = (params) => {
    const fieldPath: string = `${dataPath}.${params.data.index}.${params.colDef.field}`;
    const value = params.newValue.id || params.newValue;
    props.formik.setFieldValue(fieldPath, value);
    props.formik.validateForm().then(r => null);
    if (logging) {
      console.log("SETTING " + fieldPath, value)
    }
  };
  const Errors = errorsWithNonEmptyUniqueValues.length === 0 ? () => null : () => <ErrorsDiv>
    {errorsWithNonEmptyUniqueValues.join(' ')}
  </ErrorsDiv>;
  const onDelete = payload => props.openDeleteDialog(props.deleteActivityLines, payload, payload.activity)
  const columnsDef = getColumnDefs(props.intl, props.guiState.edit, customersMap, onDelete);
  const additionalColumns = config.additionalColumns ? config.additionalColumns(props.intl, props.guiState, props.formik) : [];
  return <>
    <HeaderDiv><FormattedMessage {...config.header} /></HeaderDiv>
    <Errors />
    <Table
      showCollapseButtonsInsideTable
      singleClickEdit={true}
      messages={messages}
      pagination={false}
      rowData={data}
      columnDefs={[...columnsDef, ...additionalColumns]}
      domLayout="autoHeight"
      showNoMatchOverlay={true}
      getRowNodeId={data => data.id}
      deltaRowDataMode
      stopEditingWhenGridLosesFocus={true}
      {...groupingProps}
      onCellValueChanged={onCellValueChanged}
    />
    <DeleteDialog {...props} text={messages.dialogDeleteText} />
  </>;
});

const getColumnDefs = (intl, isEdit, customersMap, onDelete) => {
  const colDefs: any[] = isEdit ? [createDeleteColumn(intl, onDelete)] : [];
  colDefs.push(createDepartmentColumn(intl));
  colDefs.push(createActivityColumn(intl));
  colDefs.push(createCustomerColumn(intl, isEdit, customersMap));
  return colDefs;
};

export const createDeleteColumn = (intl, onDelete) => {
  return {
    headerName: intl.formatMessage(messages.action),
    field: 'delete',
    colId: 'delete',
    cellRendererFramework: DeleteCellRenderer,
    width: 60,
    cellRendererParams: {
      onDelete,
    },
    sortable: false,
    suppressMenu: true,
    pinned: true,
    headerComponentFramework: SimpleHeader,
  }
}

export const createDepartmentColumn = (intl) => {
  return {
    headerName: intl.formatMessage(messages.department),
    width: 300,
    children: [{
      headerName: intl.formatMessage(messages.department),
      valueGetter: params => {
        const depId = params?.data?.departmentId;
        const depName = params?.data?.departmentName;
        if (depId || depName) {
          return depName || depId;
        }
        return intl.formatMessage(messages.allDepartments);
      },
      field: 'departmentId',
      colId: 'departmentId',
      rowGroup: true,
      hide: true,
  }],
  }
}

export const createActivityColumn = (intl) => {
  return {
    headerName: intl.formatMessage(messages.activity),
    width: 160,
    colId: 'name',
    editable: false,
    valueGetter: params => params?.data?.activity?.name,
    cellRendererFramework: NameWithToolTip('activity.regionalConfigurationName'),
    sortable: false,
  }
}

export const createCustomerColumn = (intl, editable, customersMap: EnchantedMap<number, NamedEntity>) => {
  const nullCustomer = {id: null, name: intl.formatMessage(messages.all)};
  const customersArrayWithNull = editable ? [nullCustomer, ...customersMap.values()].map(o => ({
    ...o,
    toString: () => o.name
  })) : [];
  const customerFormatter = customerId => customerId ? customersMap.get(customerId)?.name || customerId : intl.formatMessage(messages.all);
  return {
    headerName: intl.formatMessage(messages.customer),
    minWidth: 80,
    field: 'customerId',
    colId: 'customerId',
    editable,
    valueGetter: (params) => {
      if (!params.data) return;
      const {customerId} = params.data;
      return customerFormatter(customerId);
    },
    cellEditor: 'agRichSelectCellEditor',
    cellEditorParams: {
      values: customersArrayWithNull,
    },
    sortable: false,
  }
}
