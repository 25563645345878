import * as React from 'react';
import { Component } from 'react';
import { FormikProps } from 'formik';
import { isEqual } from 'lodash';
import moment from 'moment';
import { InjectedIntl, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { bindActionCreators, compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';

import DeleteCellRenderer from 'components/DeleteCellRenderer';
import { DeleteDialog, withDeleteDialog } from 'components/Dialog';
import IconButton from 'components/IconButton';
import { CreatedByCell, DateNoTimeCell } from 'components/ListGrid/utils';
import Table from 'components/Table/index';
import { PATHS } from 'containers/App/constants';
import { withConfirmDialog } from 'containers/ShiftScheduleRunDetailPage/withConfirmDialog';
import { ShiftScheduleRunsForm } from 'containers/ShiftScheduleRunsPage/types';
import { dateTimeCellFormatter } from 'utils/dateTime';
import EnchantedMap from 'utils/enchantedMap';

import { cancelScheduleRun, deleteScheduleRun, recalculateShiftScheduleRuns, sendScheduleRun } from './actions';
import { granularityById } from './constants';
import messages from './messages';
import { selectUserSimple } from './selectors';
import { statusCell } from './utils';

const IconsWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 5px;
  align-items: center;
`;

const SmallButton = styled(IconButton)`
  height: 20px;
`;

interface ScheduleRunsTableProps {
  dispatch: any;
  intl: InjectedIntl;
  history: RouteComponentProps.history;
  user: any;
  formik: FormikProps<ShiftScheduleRunsForm>;
  deleteScheduleRun: (payload) => any;
  openDeleteDialog: (any1, any2, any3) => any;
  openConfirmDialog: Function;
  recalculateShiftScheduleRuns: (payload) => any;
  sendScheduleRun: (payload) => any;
  cancelScheduleRun: (payload) => any;
  deleteDialogOpen: boolean;
  scheduleId: number;
  userChanges: EnchantedMap<number, any>;
  activitiesNotInKronosExist: boolean;
  dateLimitHourGranularity?: number,
  dateLimitQuarterGranularity?: number,
}

class ScheduleRunsTable extends Component<ScheduleRunsTableProps, {}> {
  public state = {
    selectedId: -1,
  };

  private gridApi = null;

  public shouldComponentUpdate(
    nextProps: Readonly<ScheduleRunsTableProps>,
    nextState: Readonly<any>,
    nextContext: any,
  ): boolean {
    return (
      this.props.deleteDialogOpen !== nextProps.deleteDialogOpen ||
      this.state.selectedId !== nextState.selectedId ||
      !isEqual(this.props.formik.values.shiftScheduleRuns, nextProps.formik.values.shiftScheduleRuns)
    );
  }

  public render() {
    const { formik } = this.props;
    const onGridReady = gridApi => {
      this.gridApi = gridApi;
      gridApi.api.sizeColumnsToFit();
    };

    const deleteTextFormat = value => ({ name: value.name });
    const deleteCallback = value =>
      this.props.deleteScheduleRun({
        id: value.id,
        scheduleId: this.props.scheduleId,
      });

    const totalRows = formik?.values?.shiftScheduleRuns?.length;
    const aproxHeight = (totalRows || 1) * 35 + 150;

    const TableWrap = styled.div`
  display: flex;
  height: ${props => props.height}px;
   .cell-highlightSuccessWithWarnings {
    color: red;
  }
`;
    return [
      <TableWrap height={aproxHeight}>
        <Table
          key="table"
          rowSelection={null}
          columnDefs={this.getColumnDefs()}
          rowData={formik.values.shiftScheduleRuns}
          domLayout="autoHeight"
          onGridReady={onGridReady}
        />
      </TableWrap>,
      <DeleteDialog
        {...this.props}
        key="del"
        text={messages.deleteConfirmation}
        formatText={deleteTextFormat}
        deleteDialogCallback={deleteCallback}
      />,
    ];
  }

  private getColumnDefs() {
    const { scheduleId, history, activitiesNotInKronosExist, openConfirmDialog, intl } = this.props;
    const sendScheduleRunAction = this.props.sendScheduleRun;
    const { formatMessage } = intl;
    const scheduleIdString = scheduleId.toString();

    const isSendable = shiftScheduleRun =>
      shiftScheduleRun?.status?.code === 'CALCULATED' && !(shiftScheduleRun?.startDate < moment().format('YYYY-MM-DD'));

    const goToDetail = id => () =>
      this.props.history.push(
        PATHS.shiftScheduleRunDetailId.replace(':id', id).replace(':scheduleId', scheduleIdString),
      );
    const handleCopy = id => () => {
      history.push(PATHS.shiftScheduleRunDetailCopy.replace(':id', id).replace(':scheduleId', scheduleIdString));
    };

    const sendToEngine = id => () => {
      const params = { scheduleId, runId: id };
      if (activitiesNotInKronosExist) {
        openConfirmDialog(() => sendScheduleRunAction(params), intl.formatMessage(messages.pinkActivities));
      } else {
        sendScheduleRunAction(params);
      }
    };

    const actionButtonsRenderer = params => (
      <IconsWrapper>
        <ButtonWrapper>
          <SmallButton onClick={goToDetail(params.data.id)} label={messages.detail} />
        </ButtonWrapper>
        <ButtonWrapper>
          <SmallButton onClick={handleCopy(params.data.id)} label={messages.copy} />
        </ButtonWrapper>
        {isSendable(params.data) && (
          <ButtonWrapper>
            <SmallButton onClick={sendToEngine(params.data.id)} label={messages.sendToEngine} />
          </ButtonWrapper>
        )}
      </IconsWrapper>
    );

    return [
      {
        colId: 'name',
        field: 'name',
        headerName: formatMessage(messages.name),
      },
      {
        cellRendererFramework: DateNoTimeCell,
        colId: 'startDate',
        field: 'startDate',
        headerName: formatMessage(messages.startDate),
      },
      {
        cellRendererFramework: DateNoTimeCell,
        colId: 'endDate',
        field: 'endDate',
        headerName: formatMessage(messages.endDate),
      },
      {
        colId: 'createdDate',
        field: 'audit.created',
        headerName: formatMessage(messages.created),
        valueFormatter: dateTimeCellFormatter,
      },
      {
        cellRendererFramework: CreatedByCell,
        colId: 'createdBy',
        field: 'audit',
        headerName: formatMessage(messages.createdBy),
        valueGetter: row => {
          const value = row.data.createdBy || row.data.audit?.createdBy;
          return value ? `${value.firstName} ${value.lastName}` : '';
        },
      },
      {
        cellRendererFramework: statusCell,
        cellRendererParams: {
          dispatch: this.props.dispatch,
          formatMessage,
          messages,
          recalculateShiftScheduleRuns: this.props.recalculateShiftScheduleRuns,
          scheduleId: this.props.scheduleId,
          dateLimitHourGranularity: this.props?.dateLimitHourGranularity,
          dateLimitQuarterGranularity: this.props?.dateLimitQuarterGranularity,
        },
        colId: 'status',
        field: 'status',
        filter: 'setFilter',
        valueGetter: row => {
          const messageKey = messages[`status_${row.data.status?.code?.toLowerCase()}`];
          return messageKey ? formatMessage(messageKey) : row.data.status?.code || '';
        },
        headerName: formatMessage(messages.status),
      },
      {
        colId: 'granularity',
        field: 'granularity',
        width: 165,
        headerName: formatMessage(messages.timespan),
        valueGetter: row => granularityById(row.data.granularity)?.name,
      },
      {
        cellRendererFramework: actionButtonsRenderer,
        cellRendererParams: params => ({
          runId: params.data.id,
          shiftScheduleRun: params.data,
          dateLimitHourGranularity: this.props?.dateLimitHourGranularity,
          dateLimitQuarterGranularity: this.props?.dateLimitQuarterGranularity,
        }),
        headerName: '',
        width: 250,
        supressSizeToFit: true,
        suppressMenu: true,
      },
      {
        cellRendererFramework: DeleteCellRenderer,
        cellRendererParams: {
          onDelete: (payload, params, event) => {
            this.props.openDeleteDialog(this.props.deleteScheduleRun, payload, params.data);
            event.preventDefault();
          },
          hide: row => row.status.code === 'CALCULATED' || row.status.code === 'RUNNING' || row.status.code === 'NEW',
        },
        width: 44,
        suppressMenu: true,
      },
    ];
  }
}

const mapStateToProps = createStructuredSelector({
  user: selectUserSimple,
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators(
      {
        deleteScheduleRun,
        recalculateShiftScheduleRuns,
        sendScheduleRun,
        cancelScheduleRun,
      },
      dispatch,
    ),
  };
}

export default compose(
  injectIntl,
  withDeleteDialog,
  withConfirmDialog,
  connect(mapStateToProps, mapDispatchToProps),
  // @ts-ignore
)(ScheduleRunsTable);
