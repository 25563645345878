import messages from './messages';

export const DATA_SOURCE_TYPES = {
  LAST_WEEK: {
    messageId: messages.sourcePeriodLastWeek,
  },
  LAST_4_WEEKS: {
    messageId: messages.sourcePeriodLast4Weeks,
  },
  LAST_MONTH: {
    messageId: messages.sourcePeriodLastMonth,
  },
  LAST_3_MONTHS: {
    messageId: messages.sourcePeriodLast3Months,
  },
  SAME_MONTH_LAST_YEAR: {
    messageId: messages.sourcePeriodSameMonthLastYear,
  },
  LAST_YEAR: {
    messageId: messages.sourcePeriodLastYear,
  },
  CUSTOM: {
    messageId: messages.sourcePeriodCustom,
  },
  DATE_RANGE: {
    messageId: messages.sourcePeriodDateRange,
  },
};

export const FORECAST_PERIOD_TYPES = {
  NEXT_WEEK: {
    messageId: messages.forecastPeriodNextWeek,
  },
  NEXT_4_WEEKS: {
    messageId: messages.forecastPeriodNext4Weeks,
  },
  NEXT_MONTH: {
    messageId: messages.forecastPeriodNextMonth,
  },
  NEXT_3_MONTHS: {
    messageId: messages.forecastPeriodNext3Months,
  },
  CUSTOM: {
    messageId: messages.forecastPeriodCustom,
  },
};
