// @flow

/*
    Plan Graphs Page
 */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { DateTime } from 'luxon';
import { injectIntl } from 'react-intl';
import BasePage from 'components/BasePage';
import { Helmet } from 'react-helmet';
import { compose, bindActionCreators } from 'redux';
import { reduxForm } from 'redux-form';
import { fromJS } from 'immutable';

import ResultMatrixDateOptions from 'components/ResultMatrixDateOptions';
import ResultBasicInfo from 'components/ResultBasicInfo';

import { PageSection, Section, Wrap } from 'containers/PlanResultPage';

import SmallGraphFilter from 'containers/PlanResultPage/SmallGraphFilter';
import TrendGraph from 'containers/PlanResultPage/TrendGraph';
import ActivityGraph from 'containers/PlanResultPage/ActivityGraph';
import HeadsGraph from 'containers/PlanResultPage/HeadsGraph';
import DiloGraph from 'containers/PlanResultPage/DiloGraph';
import { FORM_NAME, GRANULARITY } from 'containers/PlanResultPage/constants';
import { ButtonWrap as BWrap } from 'components/ResultToolBar';
import { fetchCalculations, loadFromStorageById, saveToStorageById } from 'containers/PlanResultPage/utils';
import { PATHS } from 'containers/App/constants';
import { extractDiffFieldsShallow } from 'utils/commonDetailSaga';

import { makeSelectFormByName, makeSelectFormState } from 'utils/form/selectors';

import withSecurity, { PERMISSIONS } from 'utils/security';
import { withScrollWatch } from 'utils/scroll';
import injectSaga from 'utils/injectSaga';
import { dataToSearch, searchToData, parserPlanResult } from 'utils/url';

import {
  selectPlan,
  makeSelectTrendData,
  selectActivityData,
  makeSelectDiloData,
  selectEdit,
  getResult,
  selectTrendSettings,
} from 'containers/PlanResultPage/selectors';

import {
  loadTrendGraphDataAction,
  loadActivityGraphDataAction,
  loadDiloGraphDataAction,
  loadPlanAction,
  cleanTrendGraphAction,
  colSettingsChangeAction,
} from 'containers/PlanResultPage/actions';
import saga from 'containers/PlanResultPage/saga';
import Toolbar from 'components/Toolbar';

import resultMessages from 'containers/PlanResultPage/messages';
import messages from './messages';

import {
  TREND_SETTINGS_KEY,
  TREND_COLUMN_SETTINGS_KEY,
  EFWZPG_SETTINGS_KEY,
  EFWZPG_COLUMN_SETTINGS_KEY,
  DILO_SETTINGS_KEY,
  DILO_COLUMN_SETTINGS_KEY,
  HEADS_SETTINGS_KEY,
  HEADS_COLUMN_SETTINGS_KEY,
} from './constants';

type PropsType = {
  intl: Object,
  match: {
    params: {
      id: string,
    },
  },
  plan: Object,
  trendData: Object | Boolean,
  activityData: Object | Boolean,
  diloData: Object | Boolean,
  loadActivityGraphData: Function,
  loadDiloGraphData: Function,
  loadPlan: Function,
  handleSubmit: Function,
  fixed: Boolean,
  history: Object,
  isEdit: Boolean,
  token: string,
  dispatch: Function,
  loadTrendGraphData: Function,
  cleanTrendGraph: Function,
  result: Object,
  trendCalcSettings?: Object,
  user: Object,
};

const ButtonWrap = styled(BWrap)`
  padding: 0;
`;
const GraphToolBar = props => {
  const { plan, title, history, isEdit } = props;

  return (
    <Toolbar
      {...props}
      area={false}
      planId={plan && plan.id}
      title={title}
      onBack={() => {
        const params = searchToData(history.location.search, parserPlanResult);
        props.history.push({
          pathname: PATHS.planResult,
          search: dataToSearch({ ...params, planId: plan && plan.id, isEdit }),
        });
      }}
      backAlwaysVisibile
    />
  );
};

function PlanGraphs(props: PropsType) {
  const {
    intl: { formatMessage },
    match: { params: { id } = {} } = {},
    plan,
    loadPlan,
    loadDiloGraphData,
    loadActivityGraphData,
    isEdit,
    result,
    loadTrendGraphData,
    token,
    dispatch,
    history,
    trendCalcSettings,
    trendData,
    cleanTrendGraph,
    colSettingsChange,
    user: {
      user: { login },
    },
  } = props;
  const name = (plan && plan.name) || '';
  const pParams = plan && plan.planningParameters;
  const isShift = pParams && pParams.transformationType === 'SHIFT';

  const [trendSettings, setTrendSettings] = useState({});
  const [trendColumnSettings, setTrendColumnSettings] = useState({});
  const [headsSettings, setHeadsSettings] = useState({});

  const [effortSettings, setEffortSettings] = useState({ granularity: GRANULARITY.WEEK });
  const [effortColumnSettings, setEffortColumnSettings] = useState({});

  const [diloSettings, setDiloSettings] = useState({ granularity: GRANULARITY.WEEK });
  const [diloColumnSettings, setDiloColumnSettings] = useState({});

  const [searchData, setSearchData] = useState(searchToData(history.location.search, parserPlanResult));
  const [showResult, setShowResult] = useState(trendData !== false);
  const title = formatMessage(messages.header, { name });

  const calculateMatrix = async (values: Object) => {
    // Ask back-end to return the baseline always, so we don't have to re-calculate if user selects it in the future
    const settings = {
      ...values,
      includeBaseLine: true,
    };
    saveToStorageById(login, plan && plan.id, values, TREND_SETTINGS_KEY);
    await fetchCalculations(settings, token, dispatch, true);
    setTrendSettings(values);
  };

  function getDate(sdKey, ppKey) {
    return (searchData[sdKey] && searchData[sdKey]) || (pParams && pParams[ppKey]) || DateTime.local();
  }

  function getPPID() {
    return (searchData && searchData.planningParametersId) || (pParams && pParams.id);
  }

  function getSettings(key) {
    const saved = loadFromStorageById(login, plan && plan.id, key);
    return {
      endDate: (saved && DateTime.fromISO(saved.endDate)) || getDate('endDate', 'endDay'),
      startDate: (saved && DateTime.fromISO(saved.startDate)) || getDate('startDate', 'startDay'),
      planningParametersId: (saved && saved.planningParametersId) || getPPID(),
      granularity: (saved && saved.granularity) || searchData.granularity || GRANULARITY.WEEK,
      includeBaseLine: saved && saved.includeBaseLine,
    };
  }

  useEffect(() => {
    setShowResult(trendData !== false);
  }, [trendData]);

  useEffect(() => {
    loadPlan({ planId: id }, props.intl, true);
  }, []);

  useEffect(() => {
    if (!pParams) return;

    const tSettings = getSettings(TREND_SETTINGS_KEY);
    setTrendSettings(tSettings);
    setEffortSettings(getSettings(EFWZPG_SETTINGS_KEY));
    setDiloSettings(getSettings(DILO_SETTINGS_KEY));
    setHeadsSettings(getSettings(HEADS_SETTINGS_KEY));
    const diff = extractDiffFieldsShallow(trendCalcSettings, tSettings);
    if (Object.keys(diff).length > 0 && trendData) {
      cleanTrendGraph();
    }

    const tcSettings = loadFromStorageById(login, plan && plan.id, TREND_COLUMN_SETTINGS_KEY);
    if (tcSettings) setTrendColumnSettings(tcSettings);

    const efSettings = loadFromStorageById(login, plan && plan.id, EFWZPG_COLUMN_SETTINGS_KEY);
    if (efSettings) setEffortColumnSettings(efSettings);

    const dlSettings = loadFromStorageById(login, plan && plan.id, DILO_COLUMN_SETTINGS_KEY);
    if (dlSettings) setDiloColumnSettings(dlSettings);
  }, [plan]);

  useEffect(() => {
    if (result) {
      if (Object.keys(result).length > 0) {
        loadTrendGraphData({ data: result, trendSettings, login, planId: plan && plan.id });
      }
    }
  }, [result]);

  const onColumnChange = filterName => (name, value) => {
    switch (filterName) {
      case 'trendFilter': {
        const newS = { ...trendColumnSettings, [name]: value };
        setTrendColumnSettings(newS);
        saveToStorageById(login, plan && plan.id, newS, TREND_COLUMN_SETTINGS_KEY);
        break;
      }
      case 'activityFilter': {
        const newS = { ...effortColumnSettings, [name]: value };
        setEffortColumnSettings(newS);
        saveToStorageById(login, plan && plan.id, newS, EFWZPG_COLUMN_SETTINGS_KEY);
        break;
      }
      case 'diloFilter': {
        const newS = { ...diloColumnSettings, [name]: value };
        setDiloColumnSettings(newS);
        saveToStorageById(login, plan && plan.id, newS, DILO_COLUMN_SETTINGS_KEY);
        break;
      }
    }
    dispatch(colSettingsChange(filterName));
  };

  const loadGraph = graphName => values => {
    switch (graphName) {
      case 'activityGraph': {
        saveToStorageById(login, plan && plan.id, values, EFWZPG_SETTINGS_KEY);
        dispatch(loadActivityGraphData(values));
        break;
      }
      case 'diloGraph': {
        saveToStorageById(login, plan && plan.id, values, DILO_SETTINGS_KEY);
        dispatch(loadDiloGraphData(values));
        break;
      }
    }
  };
  const shotGOpts = {
    [GRANULARITY.WEEK]: GRANULARITY.WEEK,
    [GRANULARITY.DAY]: GRANULARITY.DAY,
  };

  return (
    <Wrap noMaxWidth labelMessage={messages.header} labelValues={{ name }}>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={formatMessage(messages.content)} />
      </Helmet>
      <GraphToolBar {...props} plan={plan} title={title} history={history} isEdit={isEdit} />
      <PageSection labelMessage={messages.basicInfo}>
        <ResultBasicInfo {...props} />
      </PageSection>

      {props.plan && (
        <>
          <Section message={resultMessages.graphTitleTrend} expanded={showResult}>
            <form onSubmit={calculateMatrix} style={{ marginTop: '8px' }}>
              <ResultMatrixDateOptions
                {...trendSettings}
                mheCheckbox={false}
                onConfirm={calculateMatrix}
                isShift={isShift}
              />
            </form>
            <SmallGraphFilter onClick={onColumnChange('trendFilter')} {...trendColumnSettings} />
            {props.trendData && <TrendGraph data={props.trendData} messages={resultMessages} />}
          </Section>
          {!isShift && (
            <Section message={resultMessages.graphTitleActivity}>
              <ResultMatrixDateOptions
                showEndDate={false}
                granularityOptions={shotGOpts}
                mheCheckbox={false}
                limitStartDate={false}
                {...effortSettings}
                onConfirm={loadGraph('activityGraph')}
              />
              <SmallGraphFilter
                onClick={onColumnChange('activityFilter')}
                {...effortColumnSettings}
                showAvailable={false}
              />
              {props.activityData && <ActivityGraph data={props.activityData} messages={resultMessages} />}
            </Section>
          )}
          <Section message={resultMessages.graphTitleDilo}>
            <ResultMatrixDateOptions
              showEndDate={false}
              mheCheckbox={false}
              limitStartDate={false}
              granularityOptions={shotGOpts}
              {...diloSettings}
              onConfirm={loadGraph('diloGraph')}
            />
            <SmallGraphFilter onClick={onColumnChange('diloFilter')} {...diloColumnSettings} showAvailable={false} />
            {props.diloData && <DiloGraph {...props} data={props.diloData} messages={resultMessages} />}
          </Section>
          <Section message={resultMessages.graphHeads}>
            <HeadsGraph login={login} planId={plan && plan.id} isShift={isShift} settings={headsSettings} />
          </Section>
        </>
      )}
    </Wrap>
  );
}

const mapStateToProps = createStructuredSelector({
  plan: selectPlan,
  trendData: makeSelectTrendData(),
  trendCalcSettings: selectTrendSettings,
  activityData: selectActivityData,
  diloData: makeSelectDiloData(),
  isEdit: selectEdit,
  result: getResult,
});

const mapDispatchToProps = dispatch => {
  const actions = bindActionCreators(
    {
      loadActivityGraphData: loadActivityGraphDataAction,
      loadDiloGraphData: loadDiloGraphDataAction,
      loadTrendGraphData: loadTrendGraphDataAction,
      loadPlan: loadPlanAction,
      cleanTrendGraph: cleanTrendGraphAction,
      colSettingsChange: colSettingsChangeAction,
    },
    dispatch,
  );
  return { ...actions, dispatch };
};

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

const withSaga = injectSaga({ key: 'planResultPage', saga });

export default compose(
  withSaga,
  withConnect,
  injectIntl,
  withSecurity(PERMISSIONS.VIEW_RESULT),
)(PlanGraphs);
