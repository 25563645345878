import React from 'react';
import { FormattedMessage } from 'react-intl';
import ReactTooltip from 'react-tooltip';

import HelpPoint from 'components/HelpPoint';

import { TableInfo } from './styled';

function Subtitle({ message, tooltipImage }) {
  return (
    <TableInfo>
      <FormattedMessage {...message} />
      {tooltipImage && (
        <>
          <HelpPoint dataFor="subtitle-help" />
          <ReactTooltip id="subtitle-help" aria-haspopup="true" type="info" effect="float">
            <img alt="" width="300" src={tooltipImage} />
          </ReactTooltip>
        </>
      )}
    </TableInfo>
  );
}

export default Subtitle;
