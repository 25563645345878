// @flow
import 'react-calendar-timeline/lib/Timeline.css';

import React from 'react';
import { DateTime } from 'luxon';
import Timeline, {
  CursorMarker,
  DateHeader,
  TimelineHeaders,
  TimelineMarkers,
  TodayMarker,
} from 'react-calendar-timeline';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';

import { DAEMON } from 'utils/constants';
import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';

import { dataToGroups, getFirstDay, getItems } from './calcData';
import Item from './Item';
import reducer from './reducer';
import saga from './saga';
import makeSelectShiftsGraph from './selectors';
import { Wrap } from './styled';
import TooltipBody from './TooltipBody';

type Props = {
  data: Object,
  periodIndex: number,
  intl: Object,
};
const formatOptions = {
  dayLong: 'cccc',
  dayMediumLong: 'cccc',
  dayMedium: 'cccc',
  dayShort: 'ccc',

  hourLong: 'cccc, HH:00',
  hourMedium: 'HH:00',
  hourMediumLong: 'HH:00',
  hourShort: 'HH',

  minuteLong: 'cccc, T',
  minuteMedium: 'ccc T',
  minuteMediumLong: 'cccc, T',
  minuteShort: 'mm',

  monthLong: 'LLLL yyyy',
  monthMedium: 'LLL/yyyy',
  monthMediumLong: 'LL yyyy',
  monthShort: 'LL/YY',

  timeShort: 'T',
  timeLong: 'tt',
  timeMediumLong: 'tt',
  timeMedium: 'tt',

  yearLong: 'yyyy',
  yearMediumLong: 'yyyy',
  yearMedium: 'yyyy',
  yearShort: 'yy',
};

const formatLabel = locale => ([timeStart, timeEnd], unit, labelWidth) => {
  let format;
  if (labelWidth >= 150) {
    format = formatOptions[`${unit}Long`];
  } else if (labelWidth >= 100) {
    format = formatOptions[`${unit}MediumLong`];
  } else if (labelWidth >= 50) {
    format = formatOptions[`${unit}Medium`];
  } else {
    format = formatOptions[`${unit}Short`];
  }
  return DateTime.fromISO(timeStart.toISOString(), { locale }).toFormat(format);
};
const maxZoomDays = 7;

function ShiftsGraph(props: Props) {
  const {
    data,
    periodIndex,
    intl: { locale, formatMessage },
  } = props;

  const onTimeChange = (visibleTimeStart, visibleTimeEnd, updateScrollCanvas) => {
    const minTime = firstDay.valueOf();
    const maxTime = endDay.valueOf();
    if (visibleTimeStart < minTime && visibleTimeEnd > maxTime) {
      updateScrollCanvas(minTime, maxTime);
      return;
    }
    // Let's not resize less than 1 hour
    let vStart = visibleTimeStart;
    let vEnd = visibleTimeEnd;
    if (visibleTimeStart < minTime) {
      vStart = minTime;
      vEnd = minTime + (visibleTimeEnd - visibleTimeStart);
    } else if (visibleTimeEnd > maxTime) {
      vStart = maxTime - (visibleTimeEnd - visibleTimeStart);
      vEnd = maxTime;
    }
    updateScrollCanvas(vStart, vEnd);
  };
  const [items, uniqueItems] = getItems(data, periodIndex);
  const groups = dataToGroups(data, periodIndex, formatMessage);
  const firstDay = getFirstDay(data, periodIndex);
  const endDay = firstDay.plus({ days: maxZoomDays });
  if (!groups || !firstDay || !endDay) {
    return null;
  }

  return (
    <Wrap>
      <Timeline
        groups={groups}
        items={items}
        key={periodIndex}
        defaultTimeStart={firstDay}
        defaultTimeEnd={endDay}
        maxZoom={maxZoomDays * 86400 * 1000}
        onZoom={() => {
          ReactTooltip.rebuild();
        }}
        canMove={false}
        canResize={false}
        canChangeGroup={false}
        itemRenderer={Item}
        onTimeChange={onTimeChange}
        lineHeight={60}
        timeSteps={{
          second: 60,
          minute: 1,
          hour: 1,
          day: 1,
          month: 1,
          year: 1,
        }}
      >
        <TimelineHeaders>
          <DateHeader unit="primaryHeader" labelFormat={formatLabel(locale)} />
          <DateHeader varient="day" labelFormat={formatLabel(locale)} />
        </TimelineHeaders>
        <TimelineMarkers>
          <CursorMarker />
          <TodayMarker />
        </TimelineMarkers>
      </Timeline>
      {uniqueItems &&
        uniqueItems.map(item => (
          <ReactTooltip key={item.id} id={`${item.id.split('_')[1]}`} border delayHide={0} effect="solid">
            <TooltipBody {...item} />
          </ReactTooltip>
        ))}
    </Wrap>
  );
}

const mapStateToProps = createStructuredSelector({
  shiftsgraph: makeSelectShiftsGraph(),
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withReducer = injectReducer({ key: 'shiftsGraph', reducer, mode: DAEMON });
const withSaga = injectSaga({ key: 'shiftsGraph', saga, mode: DAEMON });

export default compose(withReducer, withSaga, withConnect, injectIntl)(ShiftsGraph);
