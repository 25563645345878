import Color from 'color';
import menuGradient from 'images/assets/0a206c54-2dc5-4cef-8184-b63b5a204423.png';
import brand from 'images/assets/b0f3e300-3261-4f7b-88f8-be7c13ca0072.svg';
import loginBackground from 'images/assets/background.jpg';
import menuGradient1 from 'images/assets/bd727235-eb53-45ca-979d-0716d544730c.png';
import dhlLogo from 'images/assets/dhl_logo.png';
import resultTitleTooltipShift from 'images/assets/result_tooltip_shift.png';
import resultTitleTooltip from 'images/assets/tooltip.png';
import ShiftProportional from 'images/assets/Shift_Proportional.png';
import ShiftEqually from 'images/assets/Shift_Equally.png';
import { css, keyframes } from 'styled-components';

const color = {
  black: '#000',
  black1: '#323232',
  black2: '#333333',
  black3: '#404040',
  black4: '#222222',
  citrusOrange :'#FFA500',
  white: '#FFF',
  white1: '#FCFCFC',
  yellow: '#FFCC00',
  yellow1: 'rgba(255,204,0,0.08)',
  yellow2: '#FFF2C0',
  yellow3: '#ffe61e',
  yellowPepa: 'rgb(247,220,109)',
  yellowOrange: '#DE970B',
  red: '#D40511',
  grey1: '#999999',
  grey2: '#666666',
  greyPageBg: '#EEEEEE',
  grey4: '#D9D9D9',
  grey5: '#F3F3F3',
  greyDlgBg: '#FFF',
  greyDlgBgSub: '#F6F6F6',
  greyBorder: '#CCCCCC',
  greenDark: '#27aE3F',
  green: '#47CE5F',
  greenLL: '#adffb1',
  blue: '#3a74ce',
  blueLight: '#b8d8ff',
  blueLL: '#e7f5ff',
  blueDark: '#374d9a',
  blueDark1: '#2F2FA2',
  violetDark: '#c10058',
  violetLL: '#ff9dd2',
  orange: '#ffa500',
};

const image = {
  brand,
  menuGradient,
  menuGradient1,
  loginBackground,
  dhlLogo,
  resultTitleTooltip,
  resultTitleTooltipShift,
  ShiftEqually,
  ShiftProportional,
};

const shadow = {
  pageBox: '0 2px 6px 1px rgba(0, 0, 0, 0.15)',
  pageBoxTabs: '0 2px 6px 0px rgba(0, 0, 0, 0.15)',
  pageBoxSlight: '0 2px 2px 1px rgba(0, 0, 0, 0.15)',
  button: '0px 4px 8px 0px rgba(0, 0, 0, 0.15)',
  buttonInset: 'inset 0px 4px 8px 0px rgba(0, 0, 0, 0.15)',
  buttonBig: '2px 4px 8px 2px rgba(0, 0, 0, 0.20)',
  buttonBigInset: 'inset 2px 4px 8px 2px rgba(0, 0, 0, 0.20)',
};

export function makeColors(number, rootColor = '#006699') {
  let i = 0;
  const result = [];
  const increment = 1069 / number;
  while (i < number) {
    result.push(
      Color(rootColor)
        .rotate(i * increment)
        .hex(),
    );
    i += 1;
  }
  return result;
}

const graphColor = makeColors(23, '#006699');
const wzpColor = makeColors(15, '#FFF2C0');

const transitions = {
  shadow: 'box-shadow 0.15s ease-in-out',
  all: 'all 0.15s ease-in-out',
  slow: 'all 0.5s ease-in-out',
  fast: 'all 0.15s ease-in-out',
  normal: 'all 0.3s ease-in-out',
};

const showAnimation = keyframes`
  from {
    opacity: 0;
    height: 0px;
  }
  to {
    opacity: 1;
    height: inherit;
  }
`;
const hideAnimation = keyframes`
  from {
    opacity: 1;
    height: inherit;
  }
  to {
    opacity: 0;
    height: 0px;
  }
`;

const animations = {
  show: css`
    animation-name: ${showAnimation};
    animation-duration: 0.3s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  `,
  hide: css`
    animation: ${hideAnimation};
    animation-duration: 0.4s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  `,
};

export const theme = {
  color,
  image,
  shadow,
  transitions,
  animations,
  fontFamily: `-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif`,
  fontSize: {
    title: '16px',
  },
  zIndex: {
    label: 0,
    input: 1,
    topMenu: 3000,
    overlayToolbar: 2000,
    modal: 3000,
    progressBar: 4000,
  },
  grid: {
    rowGap: '6px',
    rowHeight: '28px',
    tinyRowHeight: '18px',
  },
  graphColor,
  wzpColor,
};
