/**
 *
 * AgGrid with smart defaults and styled for Drep. Acts same as AgGrid. Any props passed and forwarded to AgGrid.
 *
 * Feature: colmnDefs are generated from data provided. However can be overridden as well.
 *
 */

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import './ag-grid-override.css';
import 'ag-grid-enterprise';

import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import { AgGridReact } from 'ag-grid-react';
import { injectIntl } from 'react-intl';
import { compose } from 'redux';
import styled from 'styled-components';

import { DateCell } from 'components/ListGrid/utils';
import { Button } from 'components/UIMenu';

import SetFilter from './filter/SetFilter';
import messages from './messages';

const CollapsButtons = styled.div`
  .MuiButton-root {
    font-size: 12px;
  }
`;

const Wrap = styled.div`
  width: 100%;
  position: relative;
  .ag-filter-icon .ag-icon-filter {
    background-image: url('data:image/svg+xml;base64,PHN2ZyBhcmlhLWhpZGRlbj0idHJ1ZSIgZm9jdXNhYmxlPSJmYWxzZSIgZGF0YS1wcmVmaXg9ImZ
hcyIgZGF0YS1pY29uPSJmaWx0ZXIiIGNsYXNzPSJzdmctaW5saW5lLS1mYSBmYS1maWx0ZXIgZm
Etdy0xNiIgcm9sZT0iaW1nIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZ
XdCb3g9IjAgMCA1MTIgNTEyIj48cGF0aCBmaWxsPSJjdXJyZW50Q29sb3IiIGQ9Ik00ODcuOTc2
IDBIMjQuMDI4QzIuNzEgMC04LjA0NyAyNS44NjYgNy4wNTggNDAuOTcxTDE5MiAyMjUuOTQxVjQ
zMmMwIDcuODMxIDMuODIxIDE1LjE3IDEwLjIzNyAxOS42NjJsODAgNTUuOThDMjk4LjAyIDUxOC
42OSAzMjAgNTA3LjQ5MyAzMjAgNDg3Ljk4VjIyNS45NDFsMTg0Ljk0Ny0xODQuOTdDNTIwLjAyM
SAyNS44OTYgNTA5LjMzOCAwIDQ4Ny45NzYgMHoiPjwvcGF0aD48L3N2Zz4=');
  }

  .collapseButtons {
    position: absolute;
    top: 1px;
    left: 1px;
    padding-left: 8px;
    height: 30px;
    overflow: hidden;
    display: grid;
    grid-template-columns: auto auto;
    grid-column-gap: 10px;
    align-content: center;
  }
`;

function makeDefsFromData(props) {
  const { rowData, messages, definitionInterceptor, hideColumns, messagesPrefix = 'tableHeaderLabel' } = props;
  if (rowData === undefined) {
    return defaultColDef;
  }
  const firstItem = rowData[0];
  let defs = [];
  if (props.rowSelection !== undefined) {
    defs.push({
      headerName: '#',
      width: 60,
      checkboxSelection: true,
      sortable: false,
      suppressMenu: true,
      pinned: true,
      filter: false,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
    });
  }
  if (firstItem) {
    Object.keys(firstItem).map(key => {
      if (hideColumns && hideColumns.find(item => item === key)) {
        return undefined;
      }
      // const value = firstItem[key];
      const messageKey = `${messagesPrefix}_${key}`;
      if (messages[messageKey] === undefined) {
        throw new Error(`Not found in messages: '${messageKey}'`);
      }
      const headerName = props.intl.formatMessage(messages[messageKey]);
      const def = {
        headerName,
        field: key,
        colId: key,
        menuTabs: ['filterMenuTab'],
        minWidth: headerName.length * 9,
      };
      if (false) {
        // is value = date type
        def.filter = 'agDateColumnFilter';
        def.filterParams = {
          filterOptions: ['lessThan', 'greaterThan', 'inRange'],
        };
        def.cellRendererFramework = DateCell;
      }
      defs.push(def);
      return undefined;
    });
  } else {
    console.error(`Data is empty. component.Table.index.makeDefsFromData`);
  }
  defs = definitionInterceptor ? definitionInterceptor(defs) : defs;
  return defs;
}

const defaultColDef = {
  filter: 'setFilter',
  menuTabs: ['filterMenuTab', 'generalMenuTab', 'columnsMenuTab'],
  filterParams: {
    applyButton: true,
    clearButton: true,
  },
  cellStyle: { display: 'flex', alignItems: 'center' },
  resizable: true,
  sortable: true,
};
const rowStyle = { borderBottom: 'none' };

export const localeTextFunc = formatMessage => (key, defaultValue) => {
  if (key in messages) {
    return formatMessage(messages[key]);
  }
  console.error(`missing translation for ag-grid ${key}, using defaultValue`);
  return defaultValue;
};

/* eslint-disable react/prefer-stateless-function */
class Table extends React.Component {
  // Do not calculate when overridden
  state = { columnDefs: this.props.columnDefs || makeDefsFromData(this.props) };

  constructor(props) {
    super(props);
    this.gridApi = React.createRef();
  }

  onGridReady(params) {
    if (this.props && this.props.onGridReady) {
      this.props.onGridReady(params);
    } else {
      params.api.sizeColumnsToFit();
    }
    this.gridApi.current = params;
  }

  setAllNodesExpanded(expanded) {
    this.gridApi.current.api.forEachNode(node => {
      node.expanded = expanded;
    });
    this.gridApi.current.api.onGroupExpandedOrCollapsed();
  }

  render() {
    const {
      children,
      intl: { formatMessage },
      showCollapseButtonsInsideTable,
      ...propsForGrid
    } = this.props;
    let frameworkComponents = {
      setFilter: SetFilter,
    };
    if ('frameworkComponents' in propsForGrid) {
      frameworkComponents = {
        ...propsForGrid.frameworkComponents,
        ...frameworkComponents,
      };
    }

    const collapseButtons = (
      <CollapsButtons>
        <Button
          color="primary"
          variant="text"
          size="small"
          onClick={() => this.setAllNodesExpanded(true)}
          title={this.props.intl.formatMessage(messages.expandAll)}
        />
        <Button
          color="primary"
          variant="text"
          size="small"
          onClick={() => this.setAllNodesExpanded(false)}
          title={this.props.intl.formatMessage(messages.collapseAll)}
        />
      </CollapsButtons>
    );

    const showCollapseButtons = this.props.autoGroupColumnDef && !this.props.hideCollapseExpandButtons;

    return (
      <>
        {showCollapseButtons && !showCollapseButtonsInsideTable && <Box pt={2}>{collapseButtons}</Box>}
        <Wrap className={`ag-theme-balham ${this.props.className || ''}`}>
          <AgGridReact
            reactNext
            resizable
            rowStyle={rowStyle}
            pagination
            sortable
            filter
            enableRangeSelection
            paginationPageSize={10}
            stopEditingWhenGridLosesFocus
            columnDefs={this.state.columnDefs}
            defaultColDef={defaultColDef}
            suppressCopyRowsToClipboard
            rowSelection="single"
            suppressMenuHide={true}
            defaultExportParams={{
              columnGroups: true,
              skipGroups: false,
            }}
            {...propsForGrid}
            onGridReady={params => {
              this.onGridReady(params);
            }}
            frameworkComponents={frameworkComponents}
            localeTextFunc={localeTextFunc(formatMessage)}
          />
          {children}
          {showCollapseButtons && showCollapseButtonsInsideTable && (
            <div className="collapseButtons">{collapseButtons}</div>
          )}
        </Wrap>
      </>
    );
  }
}

Table.propTypes = {
  /**
   * Styleable with styled-components.
   */
  className: PropTypes.string,
  /**
   * Prefix for generated columns labels.
   */
  messagesPrefix: PropTypes.string,
  /**
   * Your own column definitions.
   */
  columnDefs: PropTypes.array,
  /**
   * Easy way to do not generate definitions for certain columns.
   */
  hideColumns: PropTypes.array,
  /**
   * Data to display.
   */
  rowData: PropTypes.array,
  /**
   * Intercepting generated definitions.
   */
  definitionInterceptor: PropTypes.func,
  /**
   * Object user for localizations.
   */
  messages: PropTypes.object,
  /**
   *
   * Render cong or anything else
   */
  children: PropTypes.node,
  /**
   *
   * Hide collapse/expand buttons
   */
  hideCollapseExpandButtons: PropTypes.bool,
  /**
   *
   * Show collapse/expand buttons inside first row of headers in table
   */
  showCollapseButtonsInsideTable: PropTypes.bool,
};

export default compose(injectIntl)(Table);
