/*
 *
 * TableControlled actions
 *
 */

import { SCROLL_IN_TAB } from './constants';

export function scrollInTabAction(payload) {
  return {
    type: SCROLL_IN_TAB,
    payload,
  };
}
