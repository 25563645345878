/*
 * PlannedVolumeAddLine Messages
 *
 * This contains all the text for the PlannedVolumeAddLine component.
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'app.components.PlannedVolumeAddLine.title',
    defaultMessage: 'Select Volume Category',
  },
  regionalConfigurationName: {
    id: 'app.components.PlannedVolumeAddLine.regionalConfigurationName',
    defaultMessage: 'Regional Configuration',
  },
  name: {
    id: 'app.containers.PlannedVolumeAddLine.name',
    defaultMessage: 'Name',
  },
  uom: {
    id: 'app.containers.PlannedVolumeAddLine.uom',
    defaultMessage: 'Unit of Measure',
  },
  selectUOM: {
    id: 'app.components.PlannedVolumeAddLine.selectUOM',
    defaultMessage: 'Select Unit',
  },
  volumeCategory: {
    id: 'app.components.PlannedVolumeAddLine.volumeCategory',
    defaultMessage: 'Volume Category',
  },
  uom: {
    id: 'app.components.PlannedVolumeAddLine.uom',
    defaultMessage: 'Unit of Measure',
  },
  addLine: {
    id: 'app.components.PlannedVolumeAddLine.save',
    defaultMessage: 'Add line',
  },
  variableName: {
    id: 'app.components.PlannedVolumeAddLine.variableName',
    defaultMessage: 'Variable Name',
  },
  variable: {
    id: 'app.components.PlannedVolumeAddLine.variable',
    defaultMessage: 'Variable',
  },
  type: {
    id: 'app.components.PlannedVolumeAddLine.type',
    defaultMessage: 'Type',
  },
});
