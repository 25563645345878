/*
 *
 * TableControlled constants
 *
 */

export const DEFAULT_ACTION = 'app/TableControlled/DEFAULT_ACTION';
export const SET_TABLE_CONFIG = 'app/TableControlled/SET_TABLE_CONFIG';
export const REGISTER_TABLE = 'app/TableControlled/REGISTER_TABLE';
export const UNREGISTER_TABLE = 'app/TableControlled/UNREGISTER_TABLE';
export const SAVE_CONFIG_TO_FAV = 'app/TableControlled/SAVE_CONFIG_TO_FAV';
export const LOAD_CONFIG_FROM_FAV = 'app/TableControlled/LOAD_CONFIG_FROM_FAV';
export const LOAD_DEFAULTS = 'app/TableControlled/LOAD_DEFAULTS';
export const LOAD_USER = 'app/TableControlled/LOAD_USER';
