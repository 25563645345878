/**
 *
 * ActionCellCellRenderer
 *
 */

import React from 'react';
import PropTypes from 'prop-types';

import { injectIntl } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import styled from 'styled-components';
import messages from './messages';

const CellWrapper = styled.div`
  display: flex;
  align-items: center;
  > * {
    margin-right: 5px;
  }
`;

const SmallButton = styled.div`
  height: 20px;
  width: 20px;
  margin-top: 3px;
  text-align: center;
  border: 0px none;
  border-image-width: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: ${props => (props.disabled ? 0.5 : 'inherit')};
  :hover {
    cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
    box-shadow: none;
  }
`;

/* eslint-disable react/prefer-stateless-function */
class ActionCellCellRenderer extends React.Component {
    render() {
        // eslint-disable-next-line react/prop-types
        const { intl, data } = this.props;
        const disabled = typeof this.props.disabled === 'function' ? this.props.disabled(data) : this.props.disabled;
        if (!data) return <div />;
        const buttonProps = disabled ? { disabled } : { onClick: e => this.props.onDuplicate(data, this.props, e) };
        const buttonDeleteProps = disabled ? { disabled } : { onClick: e => this.props.onDelete(data, this.props, e) };
        return (
            <CellWrapper>
                {!this.props.hide || (typeof this.props.hide === 'function' && this.props.hide(this.props.data)) ? (
                    <>
                        <Tooltip title={intl.formatMessage(messages.duplicate)}>
                            <SmallButton {...buttonProps} className="delete-cell-renderer-button">
                                <FontAwesomeIcon size="lg" icon="copy" />
                            </SmallButton>
                        </Tooltip>
                        <Tooltip title={intl.formatMessage(messages.delete)}>
                            <SmallButton {...buttonDeleteProps} className="delete-cell-renderer-button">
                                <FontAwesomeIcon size="lg" icon="times" />
                            </SmallButton>
                        </Tooltip>
                    </>
                ) : (
                    ''
                )}
            </CellWrapper>
        );
    }
}

ActionCellCellRenderer.propTypes = {
    onDuplicate: PropTypes.func,
    onDelete: PropTypes.func,
    hide: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
    disabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
};

export default injectIntl(ActionCellCellRenderer);
