/**
 * Create the store with dynamic reducers
 */

import { createStore, applyMiddleware, compose } from 'redux';
import { fromJS, isImmutable } from 'immutable';
import { routerMiddleware } from 'connected-react-router/immutable';
import createSagaMiddleware from 'redux-saga';
import { createLogger } from 'redux-logger';
import formActionSaga from 'redux-form-saga';
import createReducer from './reducers';
import globalSaga from './containers/App/saga';

// TODO: inject login saga
import loginSaga from './containers/LoginPage/saga';

const sagaMiddleware = createSagaMiddleware();

export default function configureStore(initialState = {}, hist) {
  // Create the store with two middlewares
  // 1. sagaMiddleware: Makes redux-sagas work
  // 2. routerMiddleware: Syncs the location/URL path to the state
  const middlewares = [sagaMiddleware, routerMiddleware(hist)];

  if (process.env.NODE_ENV !== 'production' && process.env.REDUX_LOGGER) {
    middlewares.push(
      createLogger({
        collapsed: true,
        stateTransformer: state => (isImmutable(state) ? state.toJS() : state),
      }),
    );
  }

  const enhancers = [applyMiddleware(...middlewares)];

  // If Redux DevTools Extension is installed use it, otherwise use Redux compose
  /* eslint-disable no-underscore-dangle, indent */
  const composeEnhancers =
    process.env.NODE_ENV !== 'production' && typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
          // TODO Try to remove when `react-router-redux` is out of beta, LOCATION_CHANGE should not be fired more than once after hot reloading
          // Prevent recomputing reducers for `replaceReducer`
          shouldHotReload: false,
        })
      : compose;
  /* eslint-enable */

  const theStore = createStore(createReducer(), fromJS(initialState), composeEnhancers(...enhancers));

  // Extensions
  theStore.runSaga = sagaMiddleware.run;
  theStore.injectedReducers = {}; // Reducer registry
  theStore.injectedSagas = {}; // Saga registry

  // Make reducers hot reloadable, see http://mxs.is/googmo
  /* istanbul ignore next */
  if (module.hot) {
    module.hot.accept('./reducers', () => {
      theStore.replaceReducer(createReducer(theStore.injectedReducers));
    });
  }

  const sagas = [formActionSaga, loginSaga, globalSaga];
  sagas.forEach(saga => sagaMiddleware.run(saga));

  return theStore;
}
