import { defineMessages } from 'react-intl';

export default defineMessages({
  inputTypeTitle: {
    id: 'app.components.ViewModePopover.inputTypeTitle',
    defaultMessage: 'Input Type',
  },
  inputTypeTitleHelper: {
    id: 'app.components.ViewModePopover.inputTypeTitleHelper',
    defaultMessage:
      'To plan direct activities you need to provide an input. This field defines if this input will be in volume (e.g. pallets or cases) / hours / both (for some direct activities you will input volume and hours for others).',
  },
  planTitle: {
    id: 'app.components.ViewModePopover.planTitle',
    defaultMessage: 'Plan',
  },
  planTitleHelper: {
    id: 'app.components.ViewModePopover.planTitleHelper',
    defaultMessage:
      'Select if you would like to plan MHE and available labour in this plan. If yes, then you will be able to do required configurations and compare required vs available MHE and labour on result page.',
  },
  checkboxMHE: {
    id: 'app.components.ViewModePopover.checkboxMHE',
    defaultMessage: 'MHE',
  },
  checkboxLabourAvailable: {
    id: 'app.components.ViewModePopover.checkboxLabourAvailable',
    defaultMessage: 'Labour Available',
  },
  inputTypeVolume: {
    id: 'app.components.ViewModePopover.inputTypeVolume',
    defaultMessage: 'Volume',
  },
  inputTypeVolumeAndHours: {
    id: 'app.components.ViewModePopover.inputTypeVolumeAndHours',
    defaultMessage: 'Volume & Hours',
  },
  inputTypeHours: {
    id: 'app.components.ViewModePopover.inputTypeHours',
    defaultMessage: 'Hours',
  },
  checkboxRole: {
    id: 'app.components.ViewModePopover.checkboxRole',
    defaultMessage: 'Role',
  }
});
