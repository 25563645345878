import React from 'react';

const CheckboxCellRenderer = params => {
  if (!params.data) return <span />;
  let disabledFlag = !params.editable;
 if(params.editable && params.data){
  if(params.data.viewSettings && params.data.viewSettings.inputType ==="volume"){
    disabledFlag = true;
    params.setValue(false);
  }else if(params.data.viewSettings && params.data.viewSettings.inputType ==="hours"){
    disabledFlag = true;
    params.setValue(true);
  }
 }
  const checkedHandler = e => {
    const { checked } = e.target;
    params.setValue(checked);
  };

  return <input disabled={disabledFlag} type="checkbox" onChange={checkedHandler} checked={params.getValue()} />;
};

export default CheckboxCellRenderer;
