import React, { useEffect, useState } from 'react';
import { ColDef, ColGroupDef } from 'ag-grid-community';
import { FormattedMessage, InjectedIntl, injectIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import Button from 'components/Button';
import DialogDHL from 'components/Dialog';
import Label from 'components/Label';
import AgTable from 'components/Table';
import { getToken } from 'containers/App/selectors';
import {
  copyMappingOfPlansAndPaWithSameSmartProdSource,
  fetchPlansAndPaWithSameSmartProdSource,
} from 'containers/ForecastDetailPage/fetch';
import { EntityEntry } from 'containers/ForecastDetailPage/types';
import { ApiPlanOrPlanningAreaDTO } from 'types/drep-backend.d';

import messages from './messages';

const Table = styled(AgTable)`
  margin-top: 12px;
  height: ${props => props.height}px;
  width: 1200px;

  .ag-body-viewport div[col-id='smartProdSources'],
  .ag-body-viewport div[col-id='facilityName'],
  .ag-body-viewport div[col-id='customerName'] {
    white-space: normal;
    line-height: 20px;
    overflow: auto;
  }
`;

const OverrideWrapper = styled.div`
  margin-right: auto;
  margin-left: 8px;

  > * {
    margin-left: 6px;
    float: left;
  }
`;

const DialogButton = ({ children, onClick, ...props }) => (
  <Button color="primary" variant="contained" onClick={onClick} {...props}>
    {children}
  </Button>
);

const columnDefs = (intl: InjectedIntl, allSmartProdSources: EntityEntry[]) => {
  const defaultColumn = {
    filter: 'setFilter',
    sortable: true,
    // resizable: true,
  };
  const columns: (ColGroupDef | ColDef)[] = [
    {
      colId: 'type',
      field: 'type',
      headerName: intl.formatMessage(messages.copyMappingDialogType),
      valueFormatter: ({ value }) => {
        if (value === 'PLAN') {
          return intl.formatMessage(messages.copyMappingDialogTypePlan);
        }
        if (value === 'PLANNING_AREA') {
          return intl.formatMessage(messages.copyMappingDialogTypePlanningArea);
        }
        return value;
      },
      checkboxSelection: true,
      width: 120,
      ...defaultColumn,
    },
    {
      colId: 'name',
      field: 'name',
      filter: 'setFilter',
      headerName: intl.formatMessage(messages.copyMappingDialogName),
      width: 240,
      ...defaultColumn,
    },
    {
      colId: 'customerName',
      field: 'customerName',
      filter: 'setFilter',
      headerName: intl.formatMessage(messages.copyMappingDialogCustomer),
      ...defaultColumn,
    },
    {
      colId: 'facilityName',
      field: 'facilityName',
      filter: 'setFilter',
      headerName: intl.formatMessage(messages.copyMappingDialogFacility),
      width: 300,
      ...defaultColumn,
    },
    {
      colId: 'smartProdSources',
      field: 'smartProdSources',
      filter: 'setFilter',
      headerName: intl.formatMessage(messages.copyMappingDialogSmartProdSources),
      ...defaultColumn,
    },
  ];
  return columns;
};

type Props = {
  intl: InjectedIntl;
  open: boolean;
  planningParametersId: number;
  closeHandler: Function;
  handleReloadData: Function;
  allSmartProdSources: EntityEntry[];
};

const OMSCodesCopyMappingDialog: React.FC<Props> = ({
  intl,
  open,
  closeHandler,
  handleReloadData,
  planningParametersId,
  allSmartProdSources,
}) => {
  // @ts-ignore
  const token: string = useSelector(getToken);
  const dispatch = useDispatch();

  const [override, setOverride] = useState(false);
  const [gridApi, setGridApi] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [data, setData] = useState<ApiPlanOrPlanningAreaDTO[]>(null);

  useEffect(() => {
    open &&
      planningParametersId &&
      fetchPlansAndPaWithSameSmartProdSource(token, dispatch, planningParametersId, setData);
  }, [open, planningParametersId]);

  const onGridReady = params => {
    setGridApi(params);
    params.api.sizeColumnsToFit();
  };

  const onSelectionChanged = event => {
    const nodes = event.api.getSelectedNodes();
    if (nodes && nodes.length > 0) {
      const selectedNode = event.api.getSelectedNodes()[0];
      setSelectedRow(selectedNode.data);
    } else {
      setSelectedRow(null);
    }
  };

  useEffect(() => {
    if (gridApi) {
      const colDefs = columnDefs(intl, allSmartProdSources);
      gridApi.api.setColumnDefs(colDefs);
      gridApi.api.sizeColumnsToFit();
      setSelectedRow(null);
    }
  }, [gridApi, data]);

  useEffect(() => {
    gridApi && gridApi.api.sizeColumnsToFit();
  }, [data]);

  const handleCancel = () => {
    closeHandler();
    setData(null);
  };

  const submitForm = async () => {
    const response = await copyMappingOfPlansAndPaWithSameSmartProdSource(
      token,
      dispatch,
      selectedRow.planningParametersId,
      planningParametersId,
      override,
    );
    if (response.isOk) {
      handleReloadData();
      handleCancel();
    }
  };

  return (
    <DialogDHL
      open={!!open}
      isTextContent={false}
      headerLabel={messages.copyMappingDialogHeader}
      maxWidth="xl"
      CustomButtons={
        <React.Fragment key={1}>
          <OverrideWrapper onClick={() => setOverride(!override)}>
            <input type="checkbox" checked={override} readOnly />
            <Label {...messages.copyMappingDialogOverride} />
          </OverrideWrapper>
          <DialogButton onClick={submitForm} disabled={!selectedRow}>
            <FormattedMessage {...messages.copyMappingDialogSave} />
          </DialogButton>
          <DialogButton onClick={handleCancel}>
            <FormattedMessage {...messages.copyMappingDialogCancel} />
          </DialogButton>
        </React.Fragment>
      }
    >
      <Table
        sortable
        pagination={false}
        suppressMovableColumns
        columnDefs={columnDefs(intl, allSmartProdSources)}
        onSelectionChanged={onSelectionChanged}
        rowData={data}
        rowHeight={65}
        height={500}
        onGridReady={onGridReady}
      />
    </DialogDHL>
  );
};

export default injectIntl(OMSCodesCopyMappingDialog);
