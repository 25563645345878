/*
 * TopBar Messages
 *
 * This contains all the text for the TopBar component.
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  name: {
    id: 'app.components.WorkingHours.name',
    defaultMessage: 'Name',
  },
  startTime: {
    id: 'app.components.WorkingHours.startTime',
    defaultMessage: 'Start Time',
  },
  endTime: {
    id: 'app.components.WorkingHours.endTime',
    defaultMessage: 'End Time',
  },
});
