import * as React from 'react';
import { Component } from 'react';
import { FormikProps } from 'formik';
import { DateTime, Duration } from 'luxon';
import { injectIntl } from 'react-intl';
import styled from 'styled-components';

import AutocompleteSelect from 'components/AutocompleteSelect';
import DatePicker from 'components/DatePicker';
import DaysPickerCellEditor from 'components/DaysPickerCellEditor';
import DeleteCellRenderer from 'components/DeleteCellRenderer';
import { AddButton } from 'components/IconButton';
import Table from 'components/Table';
import TimePicker from 'components/TimePicker';
import { NamedEntity, ScheduleStaff } from 'containers/ShiftScheduleDetailPage/types';
import { getWeekdays } from 'utils/calendar/localize';
import { pushToFormikArray, removeFromFormikArray } from 'utils/utils';
import { ApiScheduleDTO, ApiStaffSmartShiftJobScheduleDTO } from 'types/drep-backend.d';

import { convertArrayToMap } from '../ShiftScheduleDetailPage/utils';
import messages from './messages';

const GridRow = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: 43px 550px 70px;
  margin: 0 0 12px 2px;
  column-gap: 10px;
`;

interface UnproductiveActivitiesProps {
  formik: FormikProps<ScheduleStaff>;
  intl: any;
  smartShiftJobs: Partial<ApiStaffSmartShiftJobScheduleDTO>[];
  shiftSchedule: ApiScheduleDTO;
}

class StaffUnproductiveActivitiesTable extends Component<UnproductiveActivitiesProps, {}> {
  public state: {
    selectedUnproductiveActivity?: ApiStaffSmartShiftJobScheduleDTO;
  } = {
    selectedUnproductiveActivity: null,
  };

  public render() {
    const data = this.props.formik.values.smartShiftJobs || [];
    const setSelectedUnproductiveActivity = value => {
      const selectedUnproductiveActivity = this.props.smartShiftJobs.find(ssj => ssj.smartShiftJob.id === value.id);
      this.setState({
        selectedUnproductiveActivity,
      });
    };

    const addNewItem = () => {
      if (this.state.selectedUnproductiveActivity) {
        pushToFormikArray(this.props.formik, 'smartShiftJobs', {
          days: [],
          endTime: Duration.fromObject({ hours: 7, minutes: 0, seconds: 0 }),
          periodLength: '',
          startTime: Duration.fromObject({ hours: 7, minutes: 0, seconds: 0 }),
          smartShiftJob: this.state.selectedUnproductiveActivity.smartShiftJob,
          smartShiftJobScheduleId: this.state.selectedUnproductiveActivity.smartShiftJobScheduleId,
          staffSmartShiftJobScheduleId: null,
          validFrom: DateTime.local(),
          validTo: null,
        });
        this.setState({ selectedUnproductiveActivity: null });
      }
    };

    const rowHeight = () => 49;

    return [
      <GridRow key="newItem">
        <span key="space" />
        <AutocompleteSelect
          key="activity-select"
          preloadedData={this.props.smartShiftJobs.map(ssj => ({
            id: ssj.smartShiftJob.id,
            name: ssj.smartShiftJob.activityKey,
          }))}
          setValue={setSelectedUnproductiveActivity}
          value={
            this.state.selectedUnproductiveActivity
              ? {
                  id: this.state.selectedUnproductiveActivity.smartShiftJob.id,
                  name: this.state.selectedUnproductiveActivity.smartShiftJob.activityKey,
                }
              : undefined
          }
        />
        <AddButton key="add-button" disabled={this.props.formik.isSubmitting} onClick={addNewItem} />
      </GridRow>,
      <Table
        key="table"
        messages={messages}
        pagination={false}
        columnDefs={this.getColumnDefs()}
        rowData={data}
        onGridReady={null}
        getRowHeight={rowHeight}
        domLayout="autoHeight"
        singleClickEdit
      />,
    ];
  }

  private getColumnDefs() {
    const datePicker = (minDateField, isClearable?) => params => (
      <DatePicker
        value={params.data[params.colDef.field]}
        timePicker={false}
        clearable={isClearable}
        minDate={minDateField ? params.data[minDateField] : undefined}
        onAccept={val => {
          const id = this.props.formik.values.smartShiftJobs.findIndex(i => i === params.data);
          this.props.formik.setFieldValue(`smartShiftJobs[${id}].${params.colDef.field}`, val);
        }}
      />
    );
    const timePicker = okMessage => params => (
      <TimePicker
        onChange={val => {
          const id = this.props.formik.values.smartShiftJobs.findIndex(i => i === params.data);
          this.props.formik.setFieldValue(`smartShiftJobs[${id}].${params.colDef.field}`, val);
        }}
        value={params.data[params.colDef.field]}
        okLabel={this.props.intl.formatMessage(okMessage)}
      />
    );

    const availableDepartments = convertArrayToMap(this.props.shiftSchedule.departments);
    const availableFacilities = convertArrayToMap(this.props.shiftSchedule.facilities);

    return [
      {
        cellRendererFramework: DeleteCellRenderer,
        cellRendererParams: {
          onDelete: (row, params) => {
            removeFromFormikArray(this.props.formik, 'smartShiftJobs', i => i === params.data);
          },
        },
        colId: 'removeButton',
        field: 'id',
        headerName: '',
        width: 50,
        suppressMenu: true,
      },
      {
        colId: 'activity',
        field: 'activity',
        headerName: '',
        valueGetter: params => {
          if (params.data.smartShiftJob.activityKey) {
            return params.data.smartShiftJob.activityKey;
          }
          return '';
        },
        width: 400,
      },
      {
        cellEditorFramework: DaysPickerCellEditor,
        colId: 'days',
        editable: true,
        field: 'days',
        headerName: this.props.intl.formatMessage(messages.days),
        sortable: false,
        valueFormatter: params =>
          params.data ? Object.values(getWeekdays(this.props.intl.formatMessage, params.value, true)).join(', ') : null,
        valueSetter: params => {
          if (params && params.newValue) {
            if (typeof params.newValue === 'string') {
              this.props.formik.setFieldValue(`smartShiftJobs[${params.node.id}].days`, params.newValue.split(','));
            } else {
              this.props.formik.setFieldValue(`smartShiftJobs[${params.node.id}].days`, params.newValue);
            }
            return true;
          }
          return false; // value was not changed
        },
        width: 500,
      },
      {
        cellRendererFramework: timePicker(messages.setStartTime),
        colId: 'startTime',
        field: 'startTime',
        headerName: this.props.intl.formatMessage(messages.startTime),
        width: 110,
      },
      {
        cellRendererFramework: timePicker(messages.setEndTime),
        colId: 'endTime',
        field: 'endTime',
        headerName: this.props.intl.formatMessage(messages.endTime),
        width: 110,
      },
      {
        colId: 'periodLength',
        editable: true,
        field: 'periodLength',
        headerName: this.props.intl.formatMessage(messages.periodLength),
        valueSetter: params => {
          this.props.formik.setFieldValue(`smartShiftJobs[${params.node.id}].periodLength`, params.newValue);
          return true;
        },
        width: 130,
      },

      {
        cellRendererFramework: datePicker(null),
        colId: 'validFrom',
        field: 'validFrom',
        headerName: this.props.intl.formatMessage(messages.validFrom),
        width: 250,
      },
      {
        cellRendererFramework: datePicker('validFrom', true),
        colId: 'validTo',
        field: 'validTo',
        headerName: this.props.intl.formatMessage(messages.validTo),
        width: 250,
      },
    ];
  }
}

// @ts-ignore
export default injectIntl(StaffUnproductiveActivitiesTable);
