/*
 * Dialog Messages
 *
 * This contains all the text for the Dialog component.
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  saveFromTo: {
    id: 'app.components.IconButton.saveFromTo',
    defaultMessage: 'Save',
  },
  back: {
    id: 'app.components.IconButton.back',
    defaultMessage: 'Back',
  },
  save: {
    id: 'app.components.IconButton.save',
    defaultMessage: 'Save',
  },
  cancel: {
    id: 'app.components.IconButton.cancel',
    defaultMessage: 'Cancel',
  },
  add: {
    id: 'app.components.IconButton.add',
    defaultMessage: 'Add',
  },
  sourceDetail:{
    id: 'app.components.IconButton.sourceDetail',
    defaultMessage: 'Source Detail',
  },
  confirm: {
    id: 'app.components.IconButton.confirm',
    defaultMessage: 'Confirm',
  },
  addLine: {
    id: 'app.components.IconButton.addLine',
    defaultMessage: 'Add Line',
  },
  disableSelected: {
    id: 'app.components.IconButton.disableSelected',
    defaultMessage: 'Disable Selected',
  },
  enableSelected: {
    id: 'app.components.IconButton.enableSelected',
    defaultMessage: 'Enable Selected',
  },
  export: {
    id: 'app.components.IconButton.export',
    defaultMessage: 'Export',
  },
  exportCsv: {
    id: 'app.components.IconButton.exportCsv',
    defaultMessage: 'CSV',
  },
  delete: {
    id: 'app.components.IconButton.delete',
    defaultMessage: 'Delete',
  },
  fav: {
    id: 'app.components.IconButton.fav',
    defaultMessage: 'Save Current Tables To User View',
  },
  overrideHelper: {
    id: 'app.components.IconButton.overrideHelper',
    defaultMessage: 'Override Helper',
  },
  sendToKronos: {
    id: 'app.components.IconButton.sendToKronos',
    defaultMessage: 'Send to SmartShift',
  },
  deselectAll: {
    id: 'app.components.IconButton.deselectAll',
    defaultMessage: 'Deselect All',
  },
  selectAll: {
    id: 'app.components.IconButton.selectAll',
    defaultMessage: 'Select All',
  },
  inverSelection: {
    id: 'app.components.IconButton.inverSelection',
    defaultMessage: 'Invert Selection',
  },
  expand: {
    id: 'app.components.IconButton.expand',
    defaultMessage: 'Expand',
  },
  collapse: {
    id: 'app.components.IconButton.collapse',
    defaultMessage: 'Collapse',
  },
  masterplanReports: {
    id: 'app.components.IconButton.masterplanReports',
    defaultMessage: 'Refresh multiple Master plan Report Runs',
  },
  smartProdRunReports:{
    id: 'app.components.IconButton.smartProdRunReports',
    defaultMessage: 'Refresh multiple SmartVolume Runs',
  },
  mheAssistant: {
    id: 'app.components.IconButton.mheAssistant',
    defaultMessage: 'MHE Assistant',
  }
});
