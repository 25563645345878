import React from 'react';

import { LOCAL_STORAGE_KEYS, PATHS, TABLE_DEFAULTS } from 'containers/App/constants';
import { PERMISSIONS } from 'utils/security';
import { parseSearch } from 'utils/url';

import { dateComparator, DateNoTimeCell } from '../../components/ListGrid/utils';
import makeListPage from '../ListPage';
import messages from './messages';

const SmartProdSourceListPage = makeListPage({
  defaultTableConfig: TABLE_DEFAULTS.smartProdSourceListTableConfig,
  tableConfigKey: LOCAL_STORAGE_KEYS.SMART_PROD_SOURCE_LIST_TABLE_CONFIG,
  permissionAdd: PERMISSIONS.VIEW_SMART_PROD_SOURCE,
  permissionDisable: PERMISSIONS.VIEW_SMART_PROD_SOURCE,
  permissionSaveConfig: PERMISSIONS.SAVE_TABLE_CONFIG,
  permissionDisableData: PERMISSIONS.VIEW_SMART_PROD_SOURCE,
  permissionViewDetail: PERMISSIONS.VIEW_SMART_PROD_SOURCE,
  pathToDetail: PATHS.smartProdSourceDetail,
  pathToCreate: PATHS.makeSmartProdSource,
  pathToSelf: PATHS.smartProdSourceList,
  messages,
  domainName: 'smartProdSourceListPage',
  domainUrl: 'smartProdSource',
  exportToExcel: true,
  parseData: item => ({
    id: item.id,
    facility: item.facility || {},
    smartProdInstance: item.smartProdInstance || {},
    smartProdWarehouse: item.smartProdWarehouse || {},
    created: item.audit.created,
    createdBy: item.audit.createdBy,
    updated: item.audit.updated,
    updatedBy: item.audit.updatedBy,
    editable: true,
    deleted: item.deleted,
    startDate: item.startDate,
    description: item.description,
    requestSmartVolumeForecast: item.isRequestSmartVolumeForecast,
  }),
  interceptColDef: (colDefs, config, props) => {
    const newColDefs = [colDefs[0]];
    newColDefs.push({
      headerName: props.intl.formatMessage(messages.facility),
      field: 'facility',
      colId: 'facility',
      menuTabs: ['filterMenuTab'],
      valueGetter: params => params.data.facility && `${params.data.facility.code} - ${params.data.facility.name}`,
      width: 150,
    });
    newColDefs.push({
      headerName: props.intl.formatMessage(messages.id),
      field: 'id',
      colId: 'id',
      menuTabs: ['filterMenuTab'],
      valueGetter: params => params.data.id,
      width: 150,
    });
    newColDefs.push({
      headerName: props.intl.formatMessage(messages.smartProdInstance),
      field: 'smartProdInstance',
      colId: 'smartProdInstance',
      menuTabs: ['filterMenuTab'],
      valueGetter: params => params.data.smartProdInstance && params.data.smartProdInstance.name,
      width: 150,
    });
    newColDefs.push({
      headerName: props.intl.formatMessage(messages.whId),
      field: 'whId',
      colId: 'whId',
      menuTabs: ['filterMenuTab'],
      valueGetter: params => params.data.smartProdWarehouse && params.data.smartProdWarehouse.smartProdWhId,
      width: 150,
    });
    colDefs.filter(it => ['action'].includes(it.field)).forEach(it => newColDefs.push(it));
    newColDefs.push({
      headerName: props.intl.formatMessage(messages.description),
      field: 'description',
      colId: 'description',
      menuTabs: ['filterMenuTab'],
      width: 150,
    });
    newColDefs.push({
      headerName: props.intl.formatMessage(messages.requestSmartVolumeForecast),
      field: 'requestSmartVolumeForecast',
      colId: 'requestSmartVolumeForecast',
      menuTabs: ['filterMenuTab'],
      valueGetter: params => params.data.requestSmartVolumeForecast ? 'Yes' : 'No',
      width: 220,
    });
    colDefs
      .filter(it => ['created', 'createdBy', 'updated', 'updatedBy', 'deleted'].includes(it.field))
      .forEach(it => newColDefs.push(it));
    return newColDefs;
  },
  onGridReady: (params, props) => {
    const pa = parseSearch('filterForPA', props);
    if (pa) {
      const filterModel = params.api.getFilterModel();
      if (pa) filterModel.area = { values: [pa], filterType: 'set', search: pa };
      setTimeout(() => params.api.setFilterModel({ ...filterModel }), 1000);
    }
  },

  actionCellRendererParams: (props, config) => ({
    onDetail:
      props.hasPerm(config.permissionViewDetail) &&
      (id => {
        props.history.push(config.pathToDetail.replace(':id', id));
      }),
  }),
});

export default SmartProdSourceListPage;
