/**
 *
 * ResultBasicInfo
 *
 */

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { FormattedMessage, injectIntl } from 'react-intl';
import { formatDay } from 'utils/dateTime';
import { getWeekdays } from 'utils/calendar/localize';
import WorkingHours from 'components/WorkingHours';
import { T_TYPE } from 'utils/constants';
import messages from './messages';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

const ColumnWrapper = styled.div`
  flex-grow: ${props => (props.size ? props.size : 1)};
  display: grid;
  grid-row-gap: ${props => props.theme.grid.rowGap};
  grid-template-columns: fit-content(40%) auto;
  align-items: normal;
  font-size: 12px;
  > * {
    margin-right: 10px;
  }
  > span {
    white-space: nowrap;
  }
`;

const Value = styled.div`
  font-weight: bold;
  min-height: ${props => props.theme.grid.tinyRowHeight};
`;
const Wrappable = styled(Value)`
  white-space: normal;
`;

type PropsType = {
  planMode: Boolean,
  plan: Object,
  intl: {
    formatMessage: Function,
  },
};
/* eslint-disable react/prefer-stateless-function */
function InterfaceBasicInfo(props: PropsType) {
  const {
    plan,
    intl: { formatMessage },
    planMode = true,
  } = props;
  const [planInfo, setPlanInfo] = useState('');
  const [planParams, setPParams] = useState('');

  useEffect(() => {
    const pParams = plan.planningParameters;
    const pArea = plan.planningArea;

    let offDays = (pParams && pParams.offDays) || null;
    offDays = offDays && Object.values(getWeekdays(formatMessage, offDays, true)).join(', ');

    let facilities;
    let regionName;
    let country;
    let sector;
    let timeZone;
    let tTypeValue;
    let paName;
    let customers;

    const planName = plan.name;
    const { description } = plan;

    if (planMode) {
      if (pArea) {
        facilities = pArea.facilities;
        country = pArea.country;
        sector = pArea.sector && pArea.sector.name;
        paName = pArea.name;
        customers = pArea.customers;
      }
      tTypeValue = pParams && pParams.transformationType;
    } else {
      facilities = plan.facilities;
      country = plan.country;
      sector = plan.sector && plan.sector.name;
      tTypeValue = T_TYPE.WZP;
      customers = plan.customers;
    }
    regionName = country && country.regionName;
    country = (country && country.name) || null;

    if (pParams) {
      timeZone = pParams.timezone && pParams.timezone.offset;
      if (timeZone) {
        timeZone = `${timeZone} - ${pParams.timezone.name}`;
      }
    }
    const isShift = tTypeValue === T_TYPE.SHIFT;
    setPlanInfo({
      isShift,
      timeZone,
      country,
      regionName,
      facilities,
      sector,
      customers,
      tTypeValue,
      paName,
      planName,
      description,
      offDays,
    });
    setPParams(pParams);
  }, [plan, planMode]);

  return (
    <Wrapper>
      <ColumnWrapper>
        <FormattedMessage {...messages.name} />
        <Value>{planInfo.planName}</Value>
        <FormattedMessage {...messages.description} />
        <Wrappable>{planInfo.description}</Wrappable>
        {planMode && (
          <>
            <FormattedMessage {...messages.planningAreaBase} />
            <Value>{planInfo.paName}</Value>
          </>
        )}
      </ColumnWrapper>
      <ColumnWrapper>
        <FormattedMessage {...messages.region} />
        <Value>{planInfo.regionName}</Value>
        <FormattedMessage {...messages.country} />
        <Value>{planInfo.country}</Value>
        <FormattedMessage {...messages.sector} />
        <Value>{planInfo.sector}</Value>
      </ColumnWrapper>
      <ColumnWrapper size={2}>
        <FormattedMessage {...messages.customer} />
        <Value>{planInfo.customers && planInfo.customers.map(cust => <div key={cust.id}>{cust.name}</div>)}</Value>
        <FormattedMessage {...messages.facility} />
        <Value>{planInfo.facilities && planInfo.facilities.map(f => <div key={f.id}>{f.name}</div>)}</Value>
        {planInfo.isShift ? <FormattedMessage {...messages.shifts} /> : <FormattedMessage {...messages.wzp} />}
        <Value>
          <WorkingHours
            formatMessage={formatMessage}
            height="95px"
            shadowBackground={false}
            values={{ planningParameters: planParams }}
          />
        </Value>
      </ColumnWrapper>
      <ColumnWrapper>
        <FormattedMessage {...messages.weekStartDay} />
        <Value>{formatDay(planParams && planParams.firstDayOfWeek)}</Value>
        <FormattedMessage {...messages.fteHours} />
        <Value>{planParams && planParams.hoursInDayFTE}</Value>
        <FormattedMessage {...messages.offDays} />
        <Value>{planInfo.offDays}</Value>
        <FormattedMessage {...messages.timeZone} />
        <Value>{planInfo.timeZone}</Value>
      </ColumnWrapper>
    </Wrapper>
  );
}

export default injectIntl(InterfaceBasicInfo);
