/**
 *
 * TimePicker
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import LuxonUtils from '@date-io/luxon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MuiThemeProvider } from '@material-ui/core';
import { MuiPickersUtilsProvider, TimePicker } from '@material-ui/pickers';
import { DateTime, Duration } from 'luxon';
import { injectIntl } from 'react-intl';
import { withTheme } from 'styled-components';

import { materialTheme } from '../DatePicker';
import messages from './messages';

const KeyboardIcon = <FontAwesomeIcon icon="clock" size="sm" />;

/* eslint-disable react/prefer-stateless-function */
class TimePickerDHL extends React.PureComponent {
  constructor(props) {
    super(props);

    const duration = this.props.value;
    const now =
      duration &&
      DateTime.local().set({
        hour: duration.hours,
        minute: duration.minutes,
        second: duration.seconds,
      });

    this.state = {
      muiTheme: materialTheme(this.props),
      selectedTime: now,
    };

    this.format = 'H:mm';
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      const duration = this.props.value;
      const now =
        duration &&
        DateTime.local().set({
          hour: duration.hours,
          minute: duration.minutes,
          second: duration.seconds,
        });
      this.setState({ selectedTime: now });
    }
  }

  onPickerChange = e => {
    const value = e;
    // console.log('time value change from to ', this.state.selectedTime, value);
    const val = Duration.fromObject({
      hours: value.hour,
      minutes: value.minute,
      seconds: value.second,
    });
    this.setState({ selectedTime: value });
  };

  onAccept = (date: any) => {
    const asDuration = Duration.fromObject({
      hours: date.c.hour,
      minutes: date.c.minute,
      seconds: date.c.second,
    });
    if (asDuration && this.props.onChange) {
      this.props.onChange(asDuration);
    }
  };

  render() {
    const theTime = this.state.selectedTime;
    const {
      okLabel,
      intl: { formatMessage },
    } = this.props;
    const config = {
      ...this.props,
      // clearable: true,
      ampm: false,
      format: this.format,
      value: theTime,
      onChange: this.onPickerChange,
      InputProps: {
        style: { width: this.props.width || 100 },
      },
      onAccept: this.onAccept,
      okLabel: okLabel || formatMessage(messages.okLabel),
      clearLabel: formatMessage(messages.clearLabel),
      cancelLabel: formatMessage(messages.cancelLabel),
      minDateMessage: formatMessage(messages.minDateError),
      maxDateMessage: formatMessage(messages.maxDateError),
      invalidDateMessage: formatMessage(messages.invalidDateMessage),
    };
    // console.log('Picker props', config);
    return (
      <MuiPickersUtilsProvider utils={LuxonUtils}>
        <MuiThemeProvider theme={this.state.muiTheme}>
          <TimePicker {...config} />
        </MuiThemeProvider>
      </MuiPickersUtilsProvider>
    );
  }
}

TimePickerDHL.propTypes = {
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  onChange: PropTypes.func,
  onOpen: PropTypes.func,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  setPickerRef: PropTypes.func,
  okLabel: PropTypes.string,
  keyboard: PropTypes.bool,
  autoFocus: PropTypes.bool,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

const ThemedTimePicker = withTheme(injectIntl(TimePickerDHL));

export default ThemedTimePicker;
