// @flow
import React from 'react';
import Select from 'react-select-material-ui';
import './style.css';
import { injectIntl } from 'react-intl';
import messages from './messages';

type Props = {
  width?: Number,
  intl: Object,
  options: Array<any>,
  SelectProps?: Object,
};
function UISelect(props: Props) {
  const {
    width,
    intl: { formatMessage },
    SelectProps = {},
    ...otherProps
  } = props;
  const defaultSelectProps = {
    msgNoOptionsAvailable: formatMessage(messages.msgNoOptionsAvailable),
    msgNoOptionsMatchFilter: formatMessage(messages.msgNoOptionsMatchFilter),
    msgNoValidValue: formatMessage(messages.msgNoValidValue),
  };
  return <Select className="UISelect" {...otherProps} SelectProps={{ ...defaultSelectProps, ...SelectProps }} />;
}

export default injectIntl(UISelect);
