import React, { useState } from 'react';
import { ColDef, ColGroupDef } from 'ag-grid-community';
import { InjectedIntl, injectIntl } from 'react-intl';
import styled from 'styled-components';

import AgTable from 'components/Table';

import messages from './messages';

const Table = styled(AgTable)`
  margin-top: 12px;
  height: ${props => props.height}px;

  .ag-overlay-no-rows-wrapper {
    padding-top: 90px;
  }

  .ag-full-width-row {
    overflow: visible;
  }

  .expiry-row {
    color: #D40511;
  }
`;

const columnDefs = (intl: InjectedIntl) => {
  const columns: (ColGroupDef | ColDef)[] = [
    {
      colId: 'smartProdJob',
      field: 'smartProdJob',
      filter: 'setFilter',
      menuTabs: ['filterMenuTab', 'generalMenuTab', 'columnsMenuTab'],
      cellRenderer: 'agGroupCellRenderer',
      headerName: intl.formatMessage(messages.smartProdJobHeader),
      sortable: true,
      resizable: true,
      cellStyle: { alignItems: 'left' },
      valueGetter: params => `${params.data.smartProdJob} (${params.data.jobCustomer})`,
    },
    {
      colId: 'omsSmartProd',
      field: 'omsSmartProd',
      filter: 'setFilter',
      menuTabs: ['filterMenuTab', 'generalMenuTab', 'columnsMenuTab'],
      cellRenderer: 'agGroupCellRenderer',
      headerName: intl.formatMessage(messages.smartProdJobOMSHeader),
      width: 100,
      sortable: true,
      resizable: true,
      cellStyle: { alignItems: 'left' },
    },
    {
      colId: 'smartPlanOMSCode',
      field: 'smartPlanOMSCode',
      filter: 'setFilter',
      menuTabs: ['filterMenuTab', 'generalMenuTab', 'columnsMenuTab'],
      cellRenderer: 'agGroupCellRenderer',
      headerName: intl.formatMessage(messages.smartPlanOmsCodeHeader),
      sortable: true,
      resizable: true,
      cellStyle: { alignItems: 'left' },
    },
    {
      colId: 'smartPlanActivity',
      field: 'smartPlanActivity',
      filter: 'setFilter',
      menuTabs: ['filterMenuTab', 'generalMenuTab', 'columnsMenuTab'],
      cellRenderer: 'agGroupCellRenderer',
      headerName: intl.formatMessage(messages.smartPlanActivityHeader),
      sortable: true,
      resizable: true,
      width: 300,
      valueFormatter: ({ value }) => (value ? `${value}` : 'No Path filled or not set on any Plan'),
      cellStyle: ({ value }) => (value ? { alignItems: 'left' } : { color: 'red', alignItems: 'left' }),
    },
    {
      colId: 'plansWhereExist',
      field: 'plansWhereExist',
      menuTabs: ['filterMenuTab', 'generalMenuTab', 'columnsMenuTab'],
      cellRenderer: 'agGroupCellRenderer',
      valueFormatter: ({ value }) => (value ? `${value.map(v => v.name).join(',')}` : ''),
      headerName: intl.formatMessage(messages.plansWhereExistHeader),
      sortable: true,
      resizable: true,
      cellStyle: { alignItems: 'left' },
    },
    {
      colId: 'paWhereExist',
      field: 'paWhereExist',
      filter: 'setFilter',
      menuTabs: ['filterMenuTab', 'generalMenuTab', 'columnsMenuTab'],
      cellRenderer: 'agGroupCellRenderer',
      valueFormatter: ({ value }) => (value ? `${value.map(v => v.name).join(',')}` : ''),
      headerName: intl.formatMessage(messages.pAreaBaseWhereExist),
      sortable: true,
      resizable: true,
      cellStyle: { alignItems: 'left' },
    },
    // {
    //   colId: 'jobCustomerPlan',
    //   field: 'jobCustomerPlan',
    //   filter: 'setFilter',
    //   menuTabs: ['filterMenuTab', 'generalMenuTab', 'columnsMenuTab'],
    //   cellRenderer: 'agGroupCellRenderer',
    //   headerName: intl.formatMessage(messages.jobCustomerPlan),
    //   sortable: true,
    //   resizable: true,
    //   cellStyle: { alignItems: 'left' },
    // },
  ];
  return columns;
};

type Props = {
  intl: InjectedIntl;
  data: any[];
};

const SmartCodeTable: React.FC<Props> = ({ intl, data }) => {
  const [openedDetail, setOpenedDetail] = useState(null);
  const [gridApi, setGridApi] = useState(null);

  const onGridReady = params => {
    setGridApi(params);
  };

  React.useEffect(() => {
    if (gridApi) {
      gridApi.api.refreshCells({ force: true });
    }
  }, [gridApi]);

  const DETAIL_ROW_HEIGHT = 370;
  const ROW_HEIGHT = 30;

  const handleRowStyle = params => {
    const smartProdJob = params?.data?.smartProdJob?.trim() + params?.data?.jobCustomer?.trim();
    const dupsmartProdJob = data?.filter(d=>{
        const currentItem = d?.smartProdJob?.trim() + d?.jobCustomer?.trim();
        return currentItem === smartProdJob;
      }) || [];
    const planPAData = params?.data?.paWhereExist?.length == 0 && params?.data?.plansWhereExist?.length == 0;
    const validRow = params?.data?.valid;
    const activityOmsCode = params?.data?.smartPlanOMSCode?.split('-')[0]?.trim()
    const smartProdOmsCode = params?.data?.omsSmartProd?.split('-')[0]?.trim()
    const omsSet = new Set();
    omsSet.add(activityOmsCode).add(smartProdOmsCode)
    const diffscOMSCode = omsSet.size !== 1;
    if(planPAData || diffscOMSCode || dupsmartProdJob.length > 1 || !validRow){
      return 'expiry-row'
    }
  };
  return (
    <Table
      detailCellRenderer="detailCellRenderer"
      defaultColDef={{
        flex: 1,
      }}
      sortable
      pagination={false}
      columnDefs={columnDefs(intl)}
      height={80 + ROW_HEIGHT * Math.min(10, data.length) + (openedDetail ? DETAIL_ROW_HEIGHT : 0)}
      rowData={data}
      rowHeight={ROW_HEIGHT}
      detailRowHeight={DETAIL_ROW_HEIGHT}
      isRowSelectable={() => false}
      getRowClass = {handleRowStyle}
      onGridReady={onGridReady}
    />
  );
};

export default injectIntl(SmartCodeTable);
