
import React from 'react';
import messages from './messages';
import NewUploadMul from './NewUploadMul';
import Button from 'components/Button';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

const UploadContainer = styled.div`
display: flex;
margin-bottom: 15px;
`;

const ButtonMainDiv = styled.div`
width: 75px;
`;

const ButtonDiv = styled.div`

padding-right: 10px
padding-left: 10px
`;
const UploadMultipleFiles = (props) => {

  const [files, setFiles] = React.useState([]);
  const onDrop = (acceptedFiles, rejectedFiles) => {
    setFiles(acceptedFiles);
  };

  const handleUploadModal = () => {
    props.uploadFile(files);
    setFiles([]);
  }
  return (
    <UploadContainer>

      <NewUploadMul
        onDrop={onDrop}
        fileName={files && files.length > 0 ? files[0].name : null}
      />

      <ButtonDiv>
        <ButtonMainDiv>
          <Button
            style={{marginBottom: '3px'}}
            disabled={(files.length > 0 && props.startdate && props.enddate) ? false : true}
            onClick={handleUploadModal}
          >
            <FormattedMessage {...messages.upload} />
          </Button>
          <Button onClick={props.handleCloseModal}
          >
            <FormattedMessage {...messages.cancel} />
          </Button>
        </ButtonMainDiv>
      </ButtonDiv>
    </UploadContainer>
  );
}

export default UploadMultipleFiles;