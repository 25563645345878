/*
 * ActivityTabs Messages
 *
 * This contains all the text for the ActivityTabs component.
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  header: {
    id: 'app.containers.ActivityTabs.header',
    defaultMessage: 'This is ActivityTabs container !',
  },
  newTab: {
    id: 'app.containers.ActivityTabs.newTab',
    defaultMessage: 'New Tab',
  },
  default: {
    id: 'app.containers.ActivityTabs.default',
    defaultMessage: 'Default',
  },
  from: {
    id: 'app.containers.ActivityTabs.from',
    defaultMessage: 'Start Date:',
  },
  to: {
    id: 'app.containers.ActivityTabs.to',
    defaultMessage: 'End Date:',
  },
  tbd: {
    id: 'app.containers.ActivityTabs.tbd',
    defaultMessage: 'TBD',
  },
  copy: {
    id: 'app.containers.ActivityTabs.copy',
    defaultMessage: 'Copy',
  },
  setStartDate: {
    id: 'app.containers.ActivityTabs.setStartDate',
    defaultMessage: 'Set Start Date',
  },
  setEndDate: {
    id: 'app.containers.ActivityTabs.setEndDate',
    defaultMessage: 'Set End Date',
  },
});
