// @flow

/**
 *
 * PlanDetailPlannedVolume
 *
 */

import React from 'react';
import PlannedVolumeHeader from '../PlannedVolumeHeader';
import PlannedVolumeAddLine from '../PlannedVolumeAddLine';
import PlannedVolumeTable from '../PlannedVolumeTable';

type Props = {
  editMode: Boolean,
};
/* eslint-disable react/prefer-stateless-function */
class PlanDetailPlannedVolume extends React.PureComponent<Props> {
  render() {
    const { editMode } = this.props;
    return (
      <div>
        <PlannedVolumeHeader />
        {editMode && <PlannedVolumeAddLine />}
        <PlannedVolumeTable plan />
      </div>
    );
  }
}

export default PlanDetailPlannedVolume;
