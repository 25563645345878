/*
 * PlanDetailPlannedVolume Messages
 *
 * This contains all the text for the PlanDetailPlannedVolume component.
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  header: {
    id: 'app.components.PlanDetailLabourAvailability.header',
    defaultMessage: 'Labour Category',
  },
  workerType: {
    id: 'app.components.PlanDetailLabourAvailability.workerType',
    defaultMessage: 'Worker Type',
  },
});
