/**
 *
 * ProgressBar
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import LinearProgress from '@material-ui/core/LinearProgress';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { makeSelectRunningApiCalls } from '../App/selectors';

const Wrapper = styled.div`
  position: fixed;
  z-index: ${props => props.theme.zIndex.progressBar};
  width: 100%;
  height: 5px;
  top: 0px;
  > div {
    background-color: ${props => props.theme.color.greyPageBg};
  }
  > div > div {
    background-color: ${props => props.theme.color.red};
  }
`;

/* eslint-disable react/prefer-stateless-function */
export class ProgressBar extends React.Component {
  render() {
    return this.props.isApiCallRunning ? (
      <Wrapper>
        <LinearProgress />
      </Wrapper>
    ) : null;
  }
}

ProgressBar.propTypes = {
  isApiCallRunning: PropTypes.bool,
};

const mapStateToProps = createStructuredSelector({
  isApiCallRunning: makeSelectRunningApiCalls(),
});

const withConnect = connect(
  mapStateToProps,
  null,
);

export default compose(withConnect)(ProgressBar);
