import React, { useEffect, useState } from 'react';
import { ErrorMessage, Field, Formik } from 'formik';
import { DateTime } from 'luxon';
import { FormattedMessage, InjectedIntl, injectIntl } from 'react-intl';
import styled from 'styled-components';
import * as Yup from 'yup';

import Button from 'components/Button';
import DialogDHL from 'components/Dialog';
import { addRangeValidation } from 'components/EffortForecast/rangeValidation';
import FormikDatePicker from 'components/FormikDatePicker';
import { FormikTextInput, InputError } from 'components/FormikTextInput';
import Label from 'components/Label';
import Select from 'components/StyledSelect';
import messages from 'containers/ModifyMultipleDialog/messages';
import {
  ModifyMultipleChangeType,
  ModifyMultipleDataSource,
  ModifyMultipleDialogType,
  ModifyMultipleFormSettings,
} from 'containers/ModifyMultipleDialog/types';

const CheckboxContainer = styled.div`
  margin-top: 12px;

  display: flex;
  align-items: start;
  justify-content: start;

  label,
  > div {
    margin-left: 8px;
    font-size: 14px;
  }

  > input {
    margin-top: 6px;
  }

  > label {
    margin-top: 4px;
  }
`;

const CheckboxContainerShift = styled.div`
  margin-top: 12px;
  margin-left: 20px;
  display: flex;
  align-items: start;
  justify-content: start;

  label,
  > div {
    margin-left: 8px;
    font-size: 14px;
  }

  > input {
    margin-top: 6px;
  }

  > label {
    margin-top: 4px;
  }
`;

const CheckboxContainerActivities = styled.div`
  margin-top: 12px;
  margin-left: 40px;
  display: flex;
  align-items: start;
  justify-content: start;

  label,
  > div {
    margin-left: 8px;
    font-size: 14px;
  }

  > input {
    margin-top: 6px;
  }

  > label {
    margin-top: 4px;
  }
`;

const StyledFormikTextInput = styled(FormikTextInput)`
  div {
    width: 95%;
  }
`;

const DivStyledToWidth = styled.div`
  margin-left: 8px;
  margin-top: -4px;
  width: 15em;
  display: inline-block;
`;

const FormWrapper = styled.div`
  margin-bottom: 150px;
  ${CheckboxContainer} {
    input[type='text'] {
      background-color: ${props => props.theme.color.grey5};
      text-align: right;
      width: 95%;
    }
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  min-height: 44px;

  > * {
    margin: 0 20px;
  }

  padding-bottom: 5px;
`;

const DataContainer = styled.div`
  max-height: 400px;
  overflow-y: auto;
  margin-bottom: 20px;
  margin-top: 20px;
`;

const DialogButton = ({ children, onClick, ...props }) => (
  <Button color="primary" variant="contained" onClick={onClick} {...props}>
    {children}
  </Button>
);

Yup.addMethod(Yup.object, 'nonEmptyObject', function (errorMessage) {
  return this.test(`non-empty-object`, errorMessage, function (value) {
    const { path, createError } = this;
    return Object.values(value).filter(x => x === true).length === 0
      ? createError({ path, message: errorMessage })
      : true;
  });
});

const PathOfDateRangeValidation = 'datesHaveCorrectRange';

Yup.addMethod(Yup.object, 'startEndDateOrder', function (errorMessage) {
  return this.test('startEndDateOrder', errorMessage, function (value) {
    const { createError } = this;
    const [startDate, endDate] = [value.startDate, value.endDate];
    const invalidRange = startDate && endDate && startDate.startOf('day') > endDate.startOf('day');
    return invalidRange ? createError({ path: 'datesHaveCorrectRange', message: errorMessage }) : true;
  });
});

addRangeValidation({
  months: 3,
  startDateField: 'startDate',
  endDateField: 'endDate',
  nameOfValidation: PathOfDateRangeValidation,
});

const validationSchema = intl =>
  Yup.object()
    .shape({
      percentageInput: Yup.string()
        .when('changeType', {
          is: ModifyMultipleChangeType.PERCENTAGE,
          then: Yup.string()
            .required(intl.formatMessage(messages.percentageIsMandatoryError))
            .test(
              'percentage-change-should-be-above-minus-100-percent',
              intl.formatMessage(messages.negativeProductivityRateNotAllowedError),
              function (percentageInput) {
                const { path, createError } = this;
                const isAboveMinus100 = Number(percentageInput) >= -100;
                return !isAboveMinus100
                  ? createError({ path, message: intl.formatMessage(messages.negativeProductivityRateNotAllowedError) })
                  : true;
              },
            ),
        })
        // eslint-disable-next-line no-useless-escape
        .matches(/^[\+\-]?[0-9\.]+$/, intl.formatMessage(messages.enterANumberError)),
      valueInput: Yup.string()
        .when('changeType', {
          is: ModifyMultipleChangeType.VALUE,
          then: Yup.string()
            .required(intl.formatMessage(messages.valueIsMandatoryError))
            .test(
              'value-change-should-be-above-minus-0',
              intl.formatMessage(messages.enterAPositiveNumberError),
              function (input) {
                const { path, createError } = this;
                const isAboveMinus100 = Number(input) >= 0;
                return !isAboveMinus100
                  ? createError({ path, message: intl.formatMessage(messages.enterAPositiveNumberError) })
                  : true;
              },
            ),
        })
        // eslint-disable-next-line no-useless-escape
        .matches(/^[\+\-]?[0-9\.]+$/, intl.formatMessage(messages.enterANumberError)),
      selectedData: Yup.object().nonEmptyObject(intl.formatMessage(messages.selectAtLeastOneItemError)),
    })
    .datesHaveCorrectRange(intl.formatMessage(messages.startEndDatesRangeError))
    .startEndDateOrder(intl.formatMessage(messages.startEndDateOrder));

type Props = {
  intl: InjectedIntl;
  open: boolean;
  closeHandler: Function;
  dialogType: ModifyMultipleDialogType;
  startDate: DateTime;
  endDate: DateTime;
  isForecastEditor?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any,no-unused-vars
  dataSourceSwitchChanged?: (value: any) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dataSourceSwitch?: any;
  dataSourceLabel?: string;
  dataSourceOptions?: Array<{
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any;
    label: string;
  }>;
  selectedData: Array<{ value: any; label: string, wzpId: any }>;
  isWeeklyProductivityRate?: boolean;
  editValueSubmit: (settings: ModifyMultipleFormSettings) => void;
  filteredActivitiesData?: any;
};

const ModifyMultipleDialog_PR: React.FC<Props> = ({
  intl,
  open,
  closeHandler,
  dialogType,
  startDate,
  endDate,
  dataSourceSwitchChanged,
  dataSourceSwitch,
  dataSourceOptions,
  dataSourceLabel,
  selectedData,
  isForecastEditor,
  editValueSubmit,
  isWeeklyProductivityRate,
  filteredActivitiesData
}) => {

  const checkStates = {};
  selectedData.forEach(row => {
    checkStates[row.value] = true;

  });
  const defaultInitialValues: ModifyMultipleFormSettings = {
    startDate,
    endDate,
    changeType: ModifyMultipleChangeType.PERCENTAGE,
    selectedData: checkStates,
    valueInput: '',
    percentageInput: '',
    dataTypeChangeEffortForecast: 'SMART_PROD',
    dataTypeChangeProductivityRate: 'DAY_TARGET',
  };

  const [initialValues, setInitialValues] = useState<ModifyMultipleFormSettings>(defaultInitialValues);
  const effortForecastDataTypeOptions = [
    {
      value: 'SMART_PROD',
      label: <FormattedMessage {...messages.plannerDriven} />,
    },
    {
      value: 'SMART_VOLUME',
      label: <FormattedMessage {...messages.smartVolume} />,
    },
    {
      value: 'SMART_PROD_WITH_EVENT',
      label: <FormattedMessage {...messages.plannerDrivenOneOff} />,
    },
    {
      value: 'SMART_VOLUME_WITH_EVENT',
      label: <FormattedMessage {...messages.smartVolumeOneOff} />,
    },
  ];
  const productivityRateDataTypeOptions = [
    {
      value: 'DAY_TARGET',
      label: <FormattedMessage {...messages.goal} />,
    },
    {
      value: 'DAY_ACTUAL',
      label: <FormattedMessage {...messages.actual} />,
    },
  ];

  const handleCancel = () => {
    closeHandler();
  };

  const submitForm = (values, actions) => {
    editValueSubmit(values);
    closeHandler();
    actions.resetForm(defaultInitialValues);
    actions.setSubmitting(false);
  };

  useEffect(() => {
    setInitialValues(defaultInitialValues);
  }, [startDate, endDate, selectedData, open]);

  const handleShiftCheckBox = (fd, formikBag) => {
    const shiftSelectedData = selectedData?.filter((sd) => sd.wzpId == fd.wzpId);
    const valueOfCheckBoxes = shiftSelectedData?.map((ssd) => {
      return formikBag.values.selectedData[ssd.value]
    })
    return !valueOfCheckBoxes.includes(false)
  }

  const handleActivityCheckboxValue = formikBag => {
    const values = Object.values(formikBag.values.selectedData);
    return values?.every(v => v === true);
  }
  return (
    <Formik
      initialValues={initialValues}
      isInitialValid
      enableReinitialize
      validateOnChange
      onSubmit={submitForm}
      validationSchema={validationSchema(intl)}
      render={formikBag => (
        <DialogDHL
          open={!!open}
          isTextContent={false}
          headerLabel={
            dialogType === ModifyMultipleDialogType.EFFORT_FORECAST
              ? messages.headerEffortForecast
              : messages.headerMMUPH
          }
          maxWidth="xl"
          CustomButtons={
            <React.Fragment key={1}>
              <DialogButton onClick={formikBag.submitForm} disabled={!formikBag.isValid}>
                <FormattedMessage {...messages.saveButton} />
              </DialogButton>
              <DialogButton onClick={handleCancel}>
                <FormattedMessage {...messages.cancelButton} />
              </DialogButton>
            </React.Fragment>
          }
        >
          <FormWrapper>
            <Wrapper>
              <Label {...messages.startDate} />
              <Field
                component={FormikDatePicker}
                name="startDate"
                onChange={() => formikBag.setFieldTouched(PathOfDateRangeValidation, true)}
              />
              <Label {...messages.endDate} />
              <Field
                component={FormikDatePicker}
                name="endDate"
                onChange={() => formikBag.setFieldTouched(PathOfDateRangeValidation, true)}
              />
              {dataSourceOptions && (
                <DivStyledToWidth>
                  <Select
                    style={{ width: 200 }}
                    options={dataSourceOptions}
                    onChange={option => {
                      dataSourceSwitchChanged(option.value);
                    }}
                    value={dataSourceOptions.filter(o => o.value === dataSourceSwitch)}
                  />
                </DivStyledToWidth>
              )}
              <hr />
            </Wrapper>
            <InputError>
              <ErrorMessage name={PathOfDateRangeValidation} />
            </InputError>
            <DataContainer>
              {dialogType === ModifyMultipleDialogType.EFFORT_FORECAST && (
                <>
                  {dataSourceSwitch === ModifyMultipleDataSource.OMS_CODE ? (
                    <Label {...messages.omsCodeLevel} />
                  ) : (
                    <Label {...messages.activityLevel} />
                  )}
                </>
              )}
              <CheckboxContainer
                onClick={() => {
                  const value = handleActivityCheckboxValue(formikBag);
                  selectedData.forEach(data => {
                    formikBag.setFieldValue(`selectedData[${data.value}]`, !value);
                  });
                  formikBag.setFieldTouched(`selectedData`, true);
                }}
              >
                <input
                  type="checkbox"
                  checked={!Object.values(formikBag.values.selectedData).includes(false)}
                  readOnly
                />
                <label>{dataSourceLabel}</label>
              </CheckboxContainer>
              {
                filteredActivitiesData?.map(fd => (
                  <>
                    <CheckboxContainerShift
                      onClick={() => {
                        const shiftSelectedData = selectedData?.filter((sd) => sd.wzpId == fd.wzpId);
                        const valueOfCheckBoxes = shiftSelectedData?.map((ssd) => {
                          return formikBag.values.selectedData[ssd.value]
                        })
                        const value = valueOfCheckBoxes.includes(true);
                        selectedData.forEach(data => {
                          if (fd.wzpId == data.wzpId) {
                            formikBag.setFieldValue(`selectedData[${data.value}]`, !value);
                          }
                        });
                        formikBag.setFieldTouched(`selectedData`, true);
                      }}
                    >
                      <input
                        type="checkbox"
                        checked={handleShiftCheckBox(fd, formikBag)}
                        readOnly
                      />
                      <label>{fd.wzpName ? fd.wzpName : 'All Shifts'}</label>
                    </CheckboxContainerShift>
                    {selectedData?.map(data => {
                      return (data.wzpId == fd.wzpId &&
                        <CheckboxContainerActivities
                          key={data.value}
                          onClick={() => {
                            const value = formikBag.values.selectedData[data.value];
                            formikBag.setFieldValue(`selectedData[${data.value}]`, !value);
                            formikBag.setFieldTouched(`selectedData`, true);
                          }}
                        >
                          <input type="checkbox" checked={formikBag.values.selectedData[data.value]} readOnly />
                          <label>{data.label}</label>
                        </CheckboxContainerActivities>
                      )
                    })}
                  </>
                ))
              }
              <InputError>
                <ErrorMessage name="selectedData" />
              </InputError>
            </DataContainer>
            <div>
              {/* eslint-disable-next-line no-nested-ternary */}
              {dialogType === ModifyMultipleDialogType.EFFORT_FORECAST ? (
                dataSourceSwitch === ModifyMultipleDataSource.OMS_CODE ? (
                  <Label {...messages.settingsTitleOMSEffortUpdate} />
                ) : (
                  <Label {...messages.settingsTitleActivityEffortUpdate} />
                )
              ) : (
                <Label {...messages.settingsTitleUPHUpdate} />
              )}
            </div>
            <div>
              <CheckboxContainer
                onClick={() => formikBag.setFieldValue('changeType', ModifyMultipleChangeType.PERCENTAGE)}
              >
                <input
                  id="changeTypePercentage"
                  type="radio"
                  name="changeType"
                  value={ModifyMultipleChangeType.PERCENTAGE}
                  checked={formikBag.values.changeType === ModifyMultipleChangeType.PERCENTAGE}
                  readOnly
                />
                <Field name="percentageInput" type="text" component={StyledFormikTextInput} autoComplete="off" />
                <label htmlFor="changeTypePercentage">
                  {dialogType === ModifyMultipleDialogType.EFFORT_FORECAST &&
                    dataSourceSwitch === ModifyMultipleDataSource.ACTIVITY ? (
                    <FormattedMessage {...messages.changeTypePercentageOfValueActivity} />
                  ) : (
                    <FormattedMessage {...messages.changeTypePercentageOfValue} />
                  )}
                </label>
              </CheckboxContainer>
              <CheckboxContainer onClick={() => formikBag.setFieldValue('changeType', ModifyMultipleChangeType.VALUE)}>
                <input
                  id="changeTypeValue"
                  type="radio"
                  name="changeType"
                  value={ModifyMultipleChangeType.VALUE}
                  checked={formikBag.values.changeType === ModifyMultipleChangeType.VALUE}
                  readOnly
                />
                <Field name="valueInput" type="text" component={StyledFormikTextInput} autoComplete="off" />
                <label htmlFor="changeTypeValue">
                  {/* eslint-disable-next-line no-nested-ternary */}
                  {dialogType === ModifyMultipleDialogType.EFFORT_FORECAST ? (
                    dataSourceSwitch === ModifyMultipleDataSource.OMS_CODE ? (
                      <FormattedMessage {...messages.changeTypeOMSCodeValueEffortOMSCode} />
                    ) : (
                      <FormattedMessage {...messages.changeTypeOMSCodeValueEffortActivity} />
                    )
                  ) : (
                    <FormattedMessage {...messages.changeTypeOMSCodeValueUPH} />
                  )}
                </label>
              </CheckboxContainer>
              {dataSourceSwitch === ModifyMultipleDataSource.OMS_CODE && (
                <CheckboxContainer
                  onClick={() => formikBag.setFieldValue('changeType', ModifyMultipleChangeType.DATA_SOURCE)}
                >
                  <input
                    type="radio"
                    name="changeType"
                    value={ModifyMultipleChangeType.DATA_SOURCE}
                    checked={formikBag.values.changeType === ModifyMultipleChangeType.DATA_SOURCE}
                    readOnly
                  />
                  <label htmlFor="changeType">
                    <FormattedMessage {...messages.changeTypeDataSourceEffortForecast} />
                  </label>
                  <DivStyledToWidth>
                    <Select
                      options={effortForecastDataTypeOptions}
                      onChange={option => {
                        formikBag.setFieldValue('dataTypeChangeEffortForecast', option.value);
                      }}
                      value={effortForecastDataTypeOptions.filter(
                        o => o.value === formikBag.values.dataTypeChangeEffortForecast,
                      )}
                    />
                  </DivStyledToWidth>
                </CheckboxContainer>
              )}
              {!isWeeklyProductivityRate &&
                dialogType === ModifyMultipleDialogType.PRODUCTIVITY_RATE &&
                isForecastEditor && (
                  <CheckboxContainer
                    onClick={() => formikBag.setFieldValue('changeType', ModifyMultipleChangeType.DATA_SOURCE)}
                  >
                    <input
                      type="radio"
                      name="changeType"
                      value={ModifyMultipleChangeType.DATA_SOURCE}
                      checked={formikBag.values.changeType === ModifyMultipleChangeType.DATA_SOURCE}
                      readOnly
                    />
                    <label htmlFor="changeType">
                      <FormattedMessage {...messages.changeTypeDataSourceUPH} />
                    </label>
                    <DivStyledToWidth>
                      <Select
                        options={productivityRateDataTypeOptions}
                        onChange={option => {
                          formikBag.setFieldValue('dataTypeChangeProductivityRate', option.value);
                        }}
                        value={productivityRateDataTypeOptions.filter(
                          o => o.value === formikBag.values.dataTypeChangeProductivityRate,
                        )}
                      />
                    </DivStyledToWidth>
                  </CheckboxContainer>
                )}
            </div>
          </FormWrapper>
        </DialogDHL>
      )}
    />
  );
};

export default injectIntl(ModifyMultipleDialog_PR);
