import React from "react";
import { ColDef } from 'ag-grid-community';
import styled from 'styled-components';
import { InjectedIntl, injectIntl } from 'react-intl';
import TableControlled from 'containers/TableControlled';
import { TABLE_DEFAULTS } from 'containers/App/constants';
import { dateTimeCellFormatter, dateCellFormater } from 'utils/dateTime';
import messages from "./messages";

const Table = styled(TableControlled)`
  margin-top: 12px;
  height: ${props => props.height}px;
`;

type Props = {
    intl?: InjectedIntl;
    rowData: any;
}

const Logs: React.FC<Props> = ({ intl, rowData }) => {
    const [gridApi, setGridApi] = React.useState(null);
    const onGridReady = params => {
        setGridApi(params);
        params.api.sizeColumnsToFit();
    };

    const ROW_HEIGHT = 30;
    const name = "Plans"
    const rowsData = rowData

    React.useEffect(() => {
        if (gridApi) {
            const colDefs = columnDefs(intl);
            gridApi.api.setColumnDefs(colDefs);
            gridApi.api.sizeColumnsToFit();
        }
    }, [gridApi, rowData]);

    const columnDefs = (intl: InjectedIntl) => {
        const columns: ColDef[] = [
            {
                colId: 'changeType',
                field: 'changeType',
                filter: 'setFilter',
                menuTabs: ['filterMenuTab'],
                headerName: intl.formatMessage(messages.type),
                sortable: true,
            },
            {
                colId: 'omsCodeOld',
                field: 'omsCodeOld',
                filter: 'setFilter',
                menuTabs: ['filterMenuTab'],
                headerName: intl.formatMessage(messages.oldValue),
                sortable: true,
            },
            {
                colId: 'omsCodeNew',
                field: 'omsCodeNew',
                filter: 'setFilter',
                menuTabs: ['filterMenuTab'],
                headerName: intl.formatMessage(messages.newValue),
                sortable: true,
            },
            {
                colId: 'createdBy',
                field: 'createdBy',
                filter: 'setFilter',
                menuTabs: ['filterMenuTab'],
                headerName: intl.formatMessage(messages.user),
                sortable: true,
            },
            {
                colId: 'oldValidityFrom',
                field: 'oldValidityFrom',
                filter: 'setFilter',
                menuTabs: ['filterMenuTab'],
                headerName: intl.formatMessage(messages.lastValidityFrom),
                valueFormatter: dateCellFormater,
                sortable: true,
            },
            {
                colId: 'newValidityFrom',
                field: 'newValidityFrom',
                filter: 'setFilter',
                menuTabs: ['filterMenuTab'],
                headerName: intl.formatMessage(messages.newValidityFrom),
                valueFormatter: dateCellFormater,
                sortable: true,
            },

        ]

        return columns;
    }

    return (
        <Table
            masterDetail
            defaultColDef={{
                flex: 1,
            }}
            showCOG={false}
            defaultConfig={TABLE_DEFAULTS.masterPlanLogs}
            columnDefs={columnDefs(intl)}
            name={name}
            messages={messages}
            rowData={rowsData}
            rowHeight={ROW_HEIGHT}
            onGridReady={onGridReady}
            height={120 + ROW_HEIGHT * Math.min(10, rowsData?.length || 0)}
            sortable
        />
    )
}

export default injectIntl(Logs);