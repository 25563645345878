/*
 *
 * PlanDetailPage actions
 *
 */

import {
  ADD_ACTIVITY_LINE,
  ADD_PLANNED_VOLUME_LINE,
  MODIFY_ACTIVITY_LINE,
  NAVIGATE_PLAN_DETAIL,
  DELETE_ACTIVITY_LINES,
  SAVE_BASIC_INFO,
  SAVE_BASIC_SETTINGS,
  DELETE_PLANNED_VOLUME,
  SAVE_PLANNED_VOLUME_HEADER,
  STORE_PLAN_GUI_STATE_ITEM,
  STORE_PLAN,
  ADD_MHE_OPEN,
  ADD_SHIFT_OPEN,
  START_NEW,
  START_PLAN_COPY,
  RESET_PLAN_BUDGET,
  CLEAR_PLAN,
  CLEAR_PLAN_MHE,
  SAVE_PLAN,
  SAVE_PLAN_DONE,
  SAVE_MASTER_PLAN_CONFLICT,
  SUBMIT_ERROR,
  LOAD_PLAN,
  LOAD_PLAN_DETAILS,
  LOAD_PLAN_OVERVIEW_DATA,
  SWITCH_TO_DISPLAY_PLAN,
  TOGGLE_SECTION_PLAN,
  ADD_PERIOD_PLAN,
  DELETE_PERIOD_PLAN,
  STORE_PLAN_PERIOD_INDEX,
  COPY_PERIOD_PLAN,
  ADD_LABOUR_AVAILABILITY_CATEGORY_LINE,
  DELETE_LABOUR_AVAILABILITY_CATEGORY_LINE,
  ADD_SHIFT_SETTINGS_LINE,
  DELETE_SHIFT_SETTINGS_LINE,
  CLEAR_MASTER_PLAN_CONFLICT,
  PLANDETAIL_MASTERPLANS,
  PLAN_DETAIL_SMARTPROD_SMARTVOLUME_RUNS,
  SAVE_PLANDETAIL_MASTERPLANS,
  SAVE_PLAN_DETAIL_SMARTPROD_SMARTVOLUME_RUNS,
  RESET_PLAN,
  OPEN_MASTER_PLAN_DIALOG,
  CLOSE_MASTER_PLAN_DIALOG,
  STORE_PLAN_DETAILS,
  SAVE_LABOUR_OVERVIEW,
  PLAN_DETAIL_SMARTPROD_SMARTVOLUME_RUNS_FROM_SAGA,
  PLAN_DETAILS_LOADER,
  PLAN_DETAILS_LOADER_FLAG,
  PLAN_DETAILS_LOADER_OVERVIEW_FLAG,
  SAVE_MHE_OVERVIEW,
  OPEN_SCHEDULE_DIALOG,
  CLOSE_SCHEDULE_DIALOG,
  PLAN_RATIO_WARNING_FLAG,
  ADD_ADJUSTMENT_LINE,
  DELETE_ADJUSTMENT_LINE,
  SAVE_ADJUSTMENT_OVERVIEW,
  PLANDETAIL_SCHEDULES,
  SAVE_PLANDETAIL_SCHEDULES,
  SAVE_ROLES,
  ADD_ROLE_OPEN,
  SAVE_PLAN_SMART_CODE_MAPPINGS,
  TOGGLE_VOLUME_FORMULA,
  TOGGLE_VOLUME_VALUE,
  TOGGLE_VOLUME_VALUE_VAR,
  TOGGLE_VOLUME_FORMULA_VAR,
  CLEAR_PLANDETAIL_MASTERPLANS,
  OMS_CHANGE_HISTORY,
  SAVE_OMS_CHANGE_HISTORY,
} from './constants';

export function clearPlanMHEAction() {
  return { type: CLEAR_PLAN_MHE };
}

export function loadPlanAction(payload) {
  return { type: LOAD_PLAN, payload };
}

export function loadPlanDetails(payload) {
  return { type: LOAD_PLAN_DETAILS, payload };
}

export function loadPlanOverviewData(){
  return { type: LOAD_PLAN_OVERVIEW_DATA };
}
export function resetPlanDetails(){
  return {type: RESET_PLAN}
}

export function clearPlanAction(payload) {
  return {
    type: CLEAR_PLAN,
    payload,
  };
}

export function resetPlanBudgetAction(payload) {
  return {
    type: RESET_PLAN_BUDGET,
    payload,
  };
}

export function addPlanMHEOpenAction(payload) {
  return {
    type: ADD_MHE_OPEN,
    payload,
    mhe: true
  };
}


export function addPlanShiftOpenAction(payload) {
  return {
    type: ADD_SHIFT_OPEN,
    payload,
    shift: true
  };
}

export function addPlanRoleOpenAction(payload) {
  return {
    type: ADD_ROLE_OPEN,
    payload,
    role: true
  };
}

export function navigatePlanDetail(id, forceDisplay = false) {
  return {
    type: NAVIGATE_PLAN_DETAIL,
    payload: { id, edit: !forceDisplay },
  };
}

export function navigatePlanDetailWithCopy(id) {
  return {
    type: NAVIGATE_PLAN_DETAIL,
    payload: { id, copy: true },
  };
}

export function reloadPlanDetail(id, reloadCount = 0) {
  return {
    type: NAVIGATE_PLAN_DETAIL,
    payload: { id, replace: true, reloadCount },
  };
}

export function storePlan(payload) {
  return {
    type: STORE_PLAN,
    payload,
  };
}

export function storePlanEdit(payload) {
  return {
    type: STORE_PLAN_GUI_STATE_ITEM,
    payload: { field: 'edit', value: payload },
  };
}

export function storePlanLoading(value) {
  return {
    type: STORE_PLAN_GUI_STATE_ITEM,
    payload: { field: 'loading', value },
  };
}

export function savePlanBasicInfo(payload) {
  return {
    type: SAVE_BASIC_INFO,
    payload,
  };
}

export function savePlanBasicSettings(payload) {
  return {
    type: SAVE_BASIC_SETTINGS,
    payload,
  };
}

export function savePlannedVolumeHeader(payload) {
  return {
    type: SAVE_PLANNED_VOLUME_HEADER,
    payload,
  };
}

export function addPlannedVolumeLine(payload) {
  return {
    type: ADD_PLANNED_VOLUME_LINE,
    payload,
  };
}

export function deletePlannedVolume(payload) {
  return {
    type: DELETE_PLANNED_VOLUME,
    payload,
  };
}

export function addPlanActivityLine(payload) {
  return {
    type: ADD_ACTIVITY_LINE,
    payload,
  };
}

export function addAdjustmentsline(payload) {
  return {
    type: ADD_ADJUSTMENT_LINE,
    payload,
  };
}

export function modifyPlanActivityLine(payload) {
  return {
    type: MODIFY_ACTIVITY_LINE,
    payload,
  };
}

export function deletePlanActivityLines(payload) {
  return {
    type: DELETE_ACTIVITY_LINES,
    payload,
  };
}

export function startNewAction(payload) {
  return {
    type: START_NEW,
    payload,
  };
}

export function startPlanCopyAction(payload) {
  return {
    type: START_PLAN_COPY,
    payload,
  };
}

export function savePlan(payload) {
  return {
    type: SAVE_PLAN,
    payload,
  };
}

export function savePlanDone(payload) {
  return {
    type: SAVE_PLAN_DONE,
    payload,
  };
}

export function saveMasterPlanConflict(payload) {
  return {
    type: SAVE_MASTER_PLAN_CONFLICT,
    payload,
  };
}

export function clearMasterPlanConflict() {
  return {
    type: CLEAR_MASTER_PLAN_CONFLICT,
  };
}

export function savePlanError(payload) {
  return {
    type: SUBMIT_ERROR,
    payload,
  };
}

export function switchPlanToDisplayMode(payload) {
  return {
    type: SWITCH_TO_DISPLAY_PLAN,
    payload,
  };
}

export function togglePlanSection(payload) {
  return {
    type: TOGGLE_SECTION_PLAN,
    payload,
  };
}

export function addPeriodPlanAction(payload) {
  return {
    type: ADD_PERIOD_PLAN,
    payload,
  };
}

export function deletePeriodPlanAction(payload) {
  return {
    type: DELETE_PERIOD_PLAN,
    payload,
  };
}

export function setPeriodIndexPlanAction(payload) {
  return {
    type: STORE_PLAN_PERIOD_INDEX,
    payload,
  };
}

export function copyPeriodPlanAction(payload) {
  return {
    type: COPY_PERIOD_PLAN,
    payload,
  };
}
export function addLabourAvailabilityCategoryLine(payload) {
  return {
    type: ADD_LABOUR_AVAILABILITY_CATEGORY_LINE,
    payload,
  };
}
export function deleteLabourAvailabilityCategoryLine(payload) {
  return {
    type: DELETE_LABOUR_AVAILABILITY_CATEGORY_LINE,
    payload,
  };
}
export function addShiftSettingsLine(payload) {
  return {
    type: ADD_SHIFT_SETTINGS_LINE,
    payload,
  };
}
export function deleteShiftSettingsLine(payload) {
  return {
    type: DELETE_SHIFT_SETTINGS_LINE,
    payload,
  };
}

export function deleteAdjustmentLines(payload){
  return {
    type: DELETE_ADJUSTMENT_LINE,
    payload,
  };
}

export function planDetailMasterPlanDetails(payload){
  return {
    type: PLANDETAIL_MASTERPLANS,
    payload
  }
}

export function planDetailSchedules(payload){
  return {
    type: PLANDETAIL_SCHEDULES,
    payload
  }
}

export function planDetailSmartProdVolumeRuns(payload){
  return {
    type: PLAN_DETAIL_SMARTPROD_SMARTVOLUME_RUNS,
    payload
  }
}

export function planDetailSmartProdVolumeRunsFromSaga(){
  return {
    type: PLAN_DETAIL_SMARTPROD_SMARTVOLUME_RUNS_FROM_SAGA
  }
}

export function saveplanDetailMasterPlanDetails(payload){
  return {
    type: SAVE_PLANDETAIL_MASTERPLANS,
    payload
  }
}

export function clearplanDetailMasterPlanDetails(){
  return {
    type: CLEAR_PLANDETAIL_MASTERPLANS,
  }
}

export function saveplanDetailSmartProdVolumeRuns(payload){
  return {
    type: SAVE_PLAN_DETAIL_SMARTPROD_SMARTVOLUME_RUNS,
    payload
  }
}

export function savePlanSmartCodeMappings(payload) {
  return {
    type: SAVE_PLAN_SMART_CODE_MAPPINGS,
    payload,
  };
}
export function openMasterPlanDialog(){
  return {
    type: OPEN_MASTER_PLAN_DIALOG
  }
}

export function openScheduleDialog(){
  return {
    type: OPEN_SCHEDULE_DIALOG
  }
}

export function closeMasterPlanDialog(){
  return {
    type: CLOSE_MASTER_PLAN_DIALOG
  }
}

export function closecheduleDialog(){
  return {
    type: CLOSE_SCHEDULE_DIALOG
  }
}

export function storePlanDetails(payload){
  return {
    type: STORE_PLAN_DETAILS,
    payload
  }
}

export function saveLabourOverView(payload, id, granularity){
  return {
    type : SAVE_LABOUR_OVERVIEW,
    payload,
    id,
    granularity
  }
}

export function saveMheOverview(payload, id, granularity){
  return {
    type : SAVE_MHE_OVERVIEW,
    payload,
    id,
    granularity
  }
}

export function saveAdjustmentOverview(payload, id, granularity){
  return {
    type : SAVE_ADJUSTMENT_OVERVIEW,
    payload,
    id,
    granularity
  }
}

export function planDetailsLoader(payload){
  return {
    type : PLAN_DETAILS_LOADER,
    payload
  }
}

export function planDetailsLoaderFlag(payload){
  return {
    type : PLAN_DETAILS_LOADER_FLAG,
    payload
  }
}

export function planDetailsOverviewFlag(payload){
  return {
    type : PLAN_DETAILS_LOADER_OVERVIEW_FLAG,
    payload
  }
}

export function planRatioWarningFlag(payload){
  return {
    type: PLAN_RATIO_WARNING_FLAG,
    payload
  }
}

export function saveplanDetailScheduleDetails(payload){
  return {
    type: SAVE_PLANDETAIL_SCHEDULES,
    payload
  }
}

export function storeRole(payload){
  return {
    type: SAVE_ROLES,
    payload
  }
}

export function toggleVolumeValue(payload){
  return {
    type: TOGGLE_VOLUME_VALUE,
    payload
  }
}

export function toggleVolumeFormula(payload){
  return {
    type: TOGGLE_VOLUME_FORMULA,
    payload
  }
}

export function toggleVolumeValueVar(payload){
  return {
    type: TOGGLE_VOLUME_VALUE_VAR,
    payload
  }
}

export function toggleVolumeFormulaVar(payload){
  return {
    type: TOGGLE_VOLUME_FORMULA_VAR,
    payload
  }
}

export function omChangeHistory(payload){
  return {
    type: OMS_CHANGE_HISTORY,
    payload
  }
}

export function saveomsChangeHistory(payload){
  return {
    type: SAVE_OMS_CHANGE_HISTORY,
    payload
  }
}