import React from 'react';
import { injectIntl } from 'react-intl';

import messages from '../messages';

const SearchInput = ({ value, onChange, applyFilter, intl: { formatMessage } }) => {
  const onKeyUp = event => {
    if (event.key === 'Enter' && applyFilter) {
      applyFilter(event);
    }
  };

  return (
    <input
      className="ag-filter-filter"
      type="text"
      placeholder={formatMessage(messages.search)}
      onChange={onChange}
      onKeyUp={onKeyUp}
      value={value}
    />
  );
};

export default injectIntl(SearchInput);
