export const getPlan = state => {
  const plan = state.getIn(['planDetailPage', 'plan']) || null;
  // we have some initial state that I think shouldn't be there
  // checking if plan is loaded
  return (plan && plan.id && plan) || null;
};

export const getPlanIsEdit = state => state.getIn(['planDetailPage', 'guiState', 'edit']);

export const getArea = state => {
  const plan = state.getIn(['planningAreaDetailPage', 'planningArea']) || null;
  // we have some initial state that I think shouldn't be there
  // checking if plan is loaded
  return (plan && plan.id && plan) || null;
};

export const getAreaIsEdit = state => state.getIn(['planningAreaDetailPage', 'guiState', 'edit']);
