import { cloneDeep } from 'lodash';

const createLogger = (name, doLogging) => ({
  d: (...args) => {
    if (doLogging) {
      console.log(`${name}: `, ...args);
    }
  },
  w: (...args) => {
    if (doLogging) {
      console.warn(`${name}: `, ...args);
    }
  },
  logMutable(logProvider) {
    if (doLogging) {
      const value = cloneDeep(logProvider());
      if (Array.isArray(value)) {
        console.log(`${name}: `, ...value);
      } else {
        console.log(`${name}: `, value);
      }
    }
  },
  isEnabled: () => doLogging,
});

export const LOG_PROD_RATE = createLogger('ProductivityRate', true);
export const LOG_RESULT = createLogger('Result', true);
export const LOG_AZURE = createLogger('Azure', true);
export const LOG_SHIFTS = createLogger('Shifts', false);
