import { defineMessages } from 'react-intl';

export const scope = 'app.components.Table';

export default defineMessages({
  yes: {
    id: `${scope}.yes`,
    defaultMessage: 'Yes',
  },
  no: {
    id: 'app.components.Table.no',
    defaultMessage: 'No',
  },
  selectAll: {
    id: 'app.components.Table.selectAll',
    defaultMessage: 'Select All',
  },
  search: {
    id: 'app.components.Table.search',
    defaultMessage: 'Search...',
  },
  searchOoo: {
    id: 'app.components.Table.searchOoo',
    defaultMessage: 'Search',
  },
  clear_filters: {
    id: 'app.components.Table.clear_filters',
    defaultMessage: 'Clear Filters',
  },
  clearFilter: {
    id: 'app.components.Table.clearFilter',
    defaultMessage: 'Clear Filter',
  },
  clear_all: {
    id: 'app.components.Table.clear_all',
    defaultMessage: 'Clear All',
  },
  clear_tab: {
    id: 'app.components.Table.clear_tab',
    defaultMessage: 'Clear Tab',
  },
  saved_filters: {
    id: 'app.components.Table.saved_filters',
    defaultMessage: 'Saved Filters',
  },
  save_filter: {
    id: 'app.components.Table.save_filter',
    defaultMessage: 'Save Filter',
  },
  filter_saved: {
    id: 'app.components.Table.filter_saved',
    defaultMessage: 'Filter "{name}" has been saved!',
  },
  filter_deleted: {
    id: 'app.components.Table.filter_deleted',
    defaultMessage: 'Filter "{name}" has been deleted!',
  },
  type_filter_name: {
    id: 'app.components.Table.type_filter_name',
    defaultMessage: 'Type The Filter Name',
  },
  entries_overall: {
    id: 'app.components.Table.entries_overall',
    defaultMessage: '({totalRows} { totalRows, plural, one { entry } other { entries }} overall)',
  },
  show_more: {
    id: 'app.components.Table.show_more',
    defaultMessage: 'Show More',
  },
  close: {
    id: 'app.components.Table.close',
    defaultMessage: 'Close',
  },
  bulk_actions: {
    id: 'app.components.Table.bulk_actions',
    defaultMessage: 'Bulk Actions',
  },
  clear_filter: {
    id: 'app.components.Table.clear_filter',
    defaultMessage: 'Clear Filter',
  },
  apply_filter: {
    id: 'app.components.Table.apply_filter',
    defaultMessage: 'Apply Filter',
  },
  select_all: {
    id: 'app.components.Table.select_all',
    defaultMessage: 'Select All',
  },
  filtered_by: {
    id: 'app.components.Table.filtered_by',
    defaultMessage: 'Filtered By',
  },
  equals: {
    id: 'app.components.Table.equals',
    defaultMessage: 'Equals',
  },
  lessThanOrEqual: {
    id: 'app.components.Table.lessThanOrEqual',
    defaultMessage: 'Less Than Or Equal',
  },
  greaterThanOrEqual: {
    id: 'app.components.Table.greaterThanOrEqual',
    defaultMessage: 'Greater Than Or Equal',
  },
  range: {
    id: 'app.components.Table.range',
    defaultMessage: 'Range',
  },
  from: {
    id: 'app.components.Table.from',
    defaultMessage: 'From',
  },
  to: {
    id: 'app.components.Table.to',
    defaultMessage: 'To',
  },
  zeroEntries: {
    id: 'app.components.Table.zeroEntries',
    defaultMessage: '(0) entries',
  },
  page: {
    id: 'app.components.Table.page',
    defaultMessage: 'Page',
  },
  more: {
    id: 'app.components.Table.more',
    defaultMessage: 'More',
  },
  of: {
    id: 'app.components.Table.of',
    defaultMessage: 'Of',
  },
  next: {
    id: 'app.components.Table.next',
    defaultMessage: 'Next',
  },

  last: {
    id: 'app.components.Table.last',
    defaultMessage: 'Last',
  },

  first: {
    id: 'app.components.Table.first',
    defaultMessage: 'First',
  },

  previous: {
    id: 'app.components.Table.previous',
    defaultMessage: 'Previous',
  },

  loadingOoo: {
    id: 'app.components.Table.loadingOoo',
    defaultMessage: 'Loading...',
  },

  filterOoo: {
    id: 'app.components.Table.filterOoo',
    defaultMessage: 'Filter...',
  },
  applyFilter: {
    id: 'app.components.Table.applyFilter',
    defaultMessage: 'ApplyFilter',
  },
  equals: {
    id: 'app.components.Table.equals',
    defaultMessage: 'Equals',
  },
  notEquals: {
    id: 'app.components.Table.notEquals',
    defaultMessage: 'Not Equal',
  },
  lessThan: {
    id: 'app.components.Table.lessThan',
    defaultMessage: 'Less Than',
  },
  greaterThan: {
    id: 'app.components.Table.greaterThan',
    defaultMessage: 'Greater than',
  },
  lessThanOrEqual: {
    id: 'app.components.Table.lessThanOrEqual',
    defaultMessage: 'Less Than Or Equal',
  },
  greaterThanOrEqual: {
    id: 'app.components.Table.greaterThanOrEqual',
    defaultMessage: 'Greater Than Or Equal',
  },
  inRange: {
    id: 'app.components.Table.inRange',
    defaultMessage: 'In Range',
  },

  // for text filter
  contains: {
    id: 'app.components.Table.contains',
    defaultMessage: 'Contains',
  },
  notContains: {
    id: 'app.components.Table.notContains',
    defaultMessage: 'Not Contains',
  },
  startsWith: {
    id: 'app.components.Table.startsWith',
    defaultMessage: 'Starts with',
  },
  // filter conditions
  andCondition: {
    id: 'app.components.Table.andCondition',
    defaultMessage: 'AND',
  },
  orCondition: {
    id: 'app.components.Table.orCondition',
    defaultMessage: 'OR',
  },
  endsWith: {
    id: 'app.components.Table.endsWith',
    defaultMessage: 'Ends with',
  },

  // the header of the default group column
  group: {
    id: 'app.components.Table.group',
    defaultMessage: 'Group',
  },

  // tool panel
  columns: {
    id: 'app.components.Table.columns',
    defaultMessage: 'Columns',
  },
  filters: {
    id: 'app.components.Table.filters',
    defaultMessage: 'Filters',
  },
  rowGroupColumns: {
    id: 'app.components.Table.rowGroupColumns',
    defaultMessage: 'Pivot Cols',
  },
  rowGroupColumnsEmptyMessage: {
    id: 'app.components.Table.rowGroupColumnsEmptyMessage',
    defaultMessage: ' drag cols to group',
  },

  valueColumns: {
    id: 'app.components.Table.valueColumns',
    defaultMessage: 'Value Cols',
  },
  pivotMode: {
    id: 'app.components.Table.pivotMode',
    defaultMessage: 'Pivot-Mode',
  },
  groups: {
    id: 'app.components.Table.groups',
    defaultMessage: 'Groups',
  },
  values: {
    id: 'app.components.Table.values',
    defaultMessage: 'Values',
  },
  pivots: {
    id: 'app.components.Table.pivots',
    defaultMessage: 'Pivots',
  },
  valueColumnsEmptyMessage: {
    id: 'app.components.Table.valueColumnsEmptyMessage',
    defaultMessage: ' drag cols to aggregate',
  },
  pivotColumnsEmptyMessage: {
    id: 'app.components.Table.pivotColumnsEmptyMessage',
    defaultMessage: ' drag here to pivot',
  },
  toolPanelButton: {
    id: 'app.components.Table.toolPanelButton',
    defaultMessage: ' tool panel',
  },

  // other
  noRowsToShow: {
    id: 'app.components.Table.noRowsToShow',
    defaultMessage: 'No Rows To Show',
  },

  // enterprise menu
  pinColumn: {
    id: 'app.components.Table.pinColumn',
    defaultMessage: 'Pin Column',
  },
  valueAggregation: {
    id: 'app.components.Table.valueAggregation',
    defaultMessage: 'Value Agg',
  },
  autosizeThiscolumn: {
    id: 'app.components.Table.autosizeThiscolumn',
    defaultMessage: 'Autosize This Column',
  },
  autosizeAllColumns: {
    id: 'app.components.Table.autosizeAllColumns',
    defaultMessage: 'Autsoie All Columns',
  },
  groupBy: {
    id: 'app.components.Table.groupBy',
    defaultMessage: 'Group by',
  },
  ungroupBy: {
    id: 'app.components.Table.ungroupBy',
    defaultMessage: 'UnGroup by',
  },
  resetColumns: {
    id: 'app.components.Table.resetColumns',
    defaultMessage: 'Reset Columns',
  },
  expandAll: {
    id: 'app.components.Table.expandAll',
    defaultMessage: 'Expand All',
  },
  collapseAll: {
    id: 'app.components.Table.collapseAll',
    defaultMessage: 'Collapse All',
  },
  toolPanel: {
    id: 'app.components.Table.toolPanel',
    defaultMessage: 'Tool Panel',
  },
  export: {
    id: 'app.components.Table.export',
    defaultMessage: 'Export',
  },
  csvExport: {
    id: 'app.components.Table.csvExport',
    defaultMessage: 'CSV Export',
  },
  excelExport: {
    id: 'app.components.Table.excelExport',
    defaultMessage: 'Excel Export (.xlsx)',
  },
  excelXmlExport: {
    id: 'app.components.Table.excelXmlExport',
    defaultMessage: 'Excel Export (.xml)',
  },

  // enterprise menu pinning
  pinLeft: {
    id: 'app.components.Table.pinLeft',
    defaultMessage: 'Pin Left',
  },
  pinRight: {
    id: 'app.components.Table.pinRight',
    defaultMessage: 'Pin Right',
  },
  noPin: {
    id: 'app.components.Table.noPin',
    defaultMessage: 'No Pin',
  },

  // enterprise menu aggregation and status bar
  sum: {
    id: 'app.components.Table.sum',
    defaultMessage: 'Sum',
  },
  min: {
    id: 'app.components.Table.min',
    defaultMessage: 'Min',
  },
  max: {
    id: 'app.components.Table.max',
    defaultMessage: 'Max',
  },
  none: {
    id: 'app.components.Table.none',
    defaultMessage: 'None',
  },
  count: {
    id: 'app.components.Table.count',
    defaultMessage: 'Count',
  },
  average: {
    id: 'app.components.Table.average',
    defaultMessage: 'Average',
  },
  filteredRows: {
    id: 'app.components.Table.filteredRows',
    defaultMessage: 'Filtered Rows',
  },
  selectedRows: {
    id: 'app.components.Table.selectedRows',
    defaultMessage: 'Selected Rows',
  },
  totalRows: {
    id: 'app.components.Table.totalRows',
    defaultMessage: 'Total Rows',
  },
  totalAndFilteredRows: {
    id: 'app.components.Table.totalAndFilteredRows',
    defaultMessage: 'Rows',
  },
  copyWithHeaders: {
    id: 'app.components.Table.copyWithHeaders',
    defaultMessage: 'Copy With Headers',
  },
  copy: {
    id: 'app.components.Table.copy',
    defaultMessage: 'Copy',
  },
  ctrlC: {
    id: 'app.components.Table.ctrlC',
    defaultMessage: 'ctrl n C',
  },
  paste: {
    id: 'app.components.Table.paste',
    defaultMessage: 'Paste',
  },
  ctrlV: {
    id: 'app.components.Table.ctrlV',
    defaultMessage: 'ctrl n V',
  },
  blanks: {
    id: 'app.components.Table.blanks',
    defaultMessage: 'Blanks',
  },
});
