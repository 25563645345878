import React, { useEffect, useState } from 'react';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import { connect as formikConnect, Field, Formik, FormikProps } from 'formik';
import { DateTime } from 'luxon';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import Button from 'components/Button';
import { ComponentWithDirtyCheck } from 'components/ButtonWithDirtyCheck';
import Label from 'components/Label';
import Select from 'components/StyledSelect';
import OMSCodesAndSmartProdJobsTable from 'containers/ForecastDetailPage/OMSCodesAndSmartProdJobsTable';
import OMSCodesCopyMappingDialog from 'containers/ForecastDetailPage/OMSCodesCopyMappingDialog';

import messages from './messages';
import { EntityEntry, ForecastConversionRateTableDTOWithId, ForecastForm, ForecastListEntityEntry } from './types';

const LabelOMSCodeWrapper = styled(Label)``;
const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  min-height: 44px;

  > * {
    margin: 0 20px;
  }

  > ${LabelOMSCodeWrapper} {
    min-width: 160px;
  }

  input {
    background-color: ${props => props.theme.color.grey5};
  }

  padding-bottom: 5px;
`;

const StyledSelect = styled(Select)`
  width: 20em;
  margin-top: 5px;
  margin-left: 12px;
`;

const WarningLabel = styled(Label)`
  padding-left: 20px;
  color: red;
  font-size: 14px;
  font-weight: 400;
`;

type Props = {
  formik?: FormikProps<ForecastForm>;
  forecastEdit: boolean;
  planningParametersId: number;
  forecastConversionRates: ForecastConversionRateTableDTOWithId[];
  handleReloadData: () => void;
  allOmsCodes: EntityEntry[];
  allSmartProdSources: EntityEntry[];
  activityForecastList?: ForecastListEntityEntry[];
  dates: DateTime[];
  planActivityMapping?: boolean;
};

const OMSCodesAndSmartProdJobs: React.FC<Props> = ({
  formik,
  forecastEdit,
  planningParametersId,
  forecastConversionRates,
  handleReloadData,
  allOmsCodes,
  allSmartProdSources,
  activityForecastList,
  dates,
  planActivityMapping,
}) => {
  const [gridApi, setGridApi] = useState(null);
  const [selectedActivity, setSelectedActivity] = useState<ForecastListEntityEntry>(null);
  const [isAppliedOMSCodeFilter, setIsAppliedOMSCodeFilter] = useState<boolean>(false);
  const [copyMappingDialogOpen, setCopyMappingDialogOpen] = useState(false);

  let selectedOMSCode = null;
  if (selectedActivity?.omsId) {
    selectedOMSCode = allOmsCodes.find(omsCode => omsCode.value === selectedActivity?.omsId)?.label;
  }

  const changeFilterModelToOMSCode = omsName => {
    if (omsName && gridApi) {
      gridApi.api.deselectAll();
      gridApi.api.destroyFilter('ag-Grid-AutoColumn');
      setTimeout(() => {
        const filterModel = {
          'ag-Grid-AutoColumn': {
            omsId: {
              filterType: 'set',
              search: omsName,
              values: [omsName],
            },
          },
        };
        gridApi.api.setFilterModel(filterModel);
        gridApi.api.onFilterChanged();
      }, 1);
    }
  };

  useEffect(() => {
    gridApi?.api.redrawRows();
    handleFilterChanged();
  }, [gridApi, selectedOMSCode, forecastEdit]);

  const handleFiltertoSelectedOMSCode = () => {
    changeFilterModelToOMSCode(selectedOMSCode);
  };

  const removeFilterForSelectedOMSCode = () => {
    if (gridApi) {
      gridApi.api.deselectAll();
      gridApi.api.destroyFilter('ag-Grid-AutoColumn');
      gridApi.api.onFilterChanged();
    }
  };

  const handleFilterChanged = () => {
    const filterModel = gridApi?.api.getFilterModel();
    if (filterModel?.['ag-Grid-AutoColumn']?.omsId?.search === selectedOMSCode) {
      setIsAppliedOMSCodeFilter(true);
    } else {
      setIsAppliedOMSCodeFilter(false);
    }
  };

  const handleAssignToSelectedSmartProdJobCodes = () => {
    if (gridApi && selectedActivity) {
      const nodes = gridApi.api.getSelectedNodes();
      nodes.forEach(node => {
        node.setData({
          ...node.data,
          activityForecastId: selectedActivity.value,
        });
        formik.setFieldValue(`forecastConversionRates[${node.data.id}].activityForecastId`, selectedActivity.value);
      });
    }
  };

  const distinctactivityForecastList = activityForecastList && activityForecastList.filter(
    (item, index, self) =>
      index === self.findIndex(t => (t.customerId === item.customerId) && (t.departmentId === item.departmentId) && (t.value === item.value) && (t.uom === item.uom))
   );
  
  const renderEditForm = () => (
    <ComponentWithDirtyCheck formik={formik} dirty={formik.dirty} actionHandler={() => setCopyMappingDialogOpen(true)}>
      {({ onClickHandler }) => (
        <Formik
          initialValues={{}}
          isInitialValid
          enableReinitialize
          onSubmit={() => {}}
          render={() => (
            <Wrapper>
              <Label {...messages.labelActivity} />
              <Field
                name="activityForecastId"
                render={({ field, form }) => (
                  <StyledSelect
                    id="activityForecastId"
                    options={
                      distinctactivityForecastList ? distinctactivityForecastList.filter(a => a.customerId && a.departmentId) : []
                    }
                    isDisabled={!forecastEdit || !distinctactivityForecastList || !planActivityMapping}
                    onChange={option => {
                      form.setFieldValue(field.name, option.value);
                      setSelectedActivity(option);
                    }}
                    value={(distinctactivityForecastList || []).find(o => o.value === field.value) || null}
                  />
                )}
              />

              <LabelOMSCodeWrapper {...messages.labelOMSCode} values={{ omsCode: selectedOMSCode }} />

              {forecastEdit && (
                <>
                  <Tooltip title={<FormattedMessage {...messages.filterToSelectedOMSCodeTooltip} />}>
                    {isAppliedOMSCodeFilter ? (
                      <Button onClick={removeFilterForSelectedOMSCode} color="secondary" variant="contained">
                        <FormattedMessage {...messages.clearFilterToSelectedOMSCode} />
                      </Button>
                    ) : (
                      <Button
                        onClick={handleFiltertoSelectedOMSCode}
                        color="secondary"
                        variant="contained"
                        disabled={!selectedActivity}
                      >
                        <FormattedMessage {...messages.filterToSelectedOMSCode} />
                      </Button>
                    )}
                  </Tooltip>
                  <Tooltip title={<FormattedMessage {...messages.assignToSelectedSmartProdJobCodesTooltip} />}>
                    <Button
                      onClick={handleAssignToSelectedSmartProdJobCodes}
                      color="secondary"
                      variant="contained"
                      disabled={!selectedActivity}
                    >
                      <FormattedMessage {...messages.assignToSelectedSmartProdJobCodes} />
                    </Button>
                  </Tooltip>

                  <Tooltip title={<FormattedMessage {...messages.copyMappingButtonTooltip} />}>
                    <Button onClick={onClickHandler} color="secondary" variant="contained" disabled={!planActivityMapping}>
                      <FormattedMessage {...messages.copyMapping} />
                    </Button>
                  </Tooltip>
                </>
              )}
            </Wrapper>
          )}
        />
      )}
    </ComponentWithDirtyCheck>
  );

  return (
    <div>
      <div>
        {renderEditForm()}
        { 
        forecastEdit && !planActivityMapping &&
          <WarningLabel{...messages.mappingOMSCodesLabelWaring}/>
        }
        <OMSCodesCopyMappingDialog
          planningParametersId={planningParametersId}
          allSmartProdSources={allSmartProdSources}
          open={copyMappingDialogOpen}
          handleReloadData={handleReloadData}
          closeHandler={() => setCopyMappingDialogOpen(false)}
        />
      </div>
      {activityForecastList && (
        <OMSCodesAndSmartProdJobsTable
          gridApi={gridApi}
          setGridApi={setGridApi}
          onFilterChanged={handleFilterChanged}
          forecastEdit={forecastEdit}
          forecastConversionRates={forecastConversionRates}
          activityForecastList={activityForecastList}
          selectedForecastActivity={selectedActivity}
          dates={dates}
          allOmsCodes={allOmsCodes}
          planActivityMapping={planActivityMapping}
        />
      )}
    </div>
  );
};
export default formikConnect(OMSCodesAndSmartProdJobs);
