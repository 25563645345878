/*
 * adjustmentOverview Messages
 *
 * This contains all the text for the adjustmentOverview component.
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  direct: {
    id: 'app.components.adjustmentOverview.direct',
    defaultMessage: 'Adjusted',
  },
  indirect: {
    id: 'app.components.adjustmentOverview.indirect',
    defaultMessage: 'Adjusting',
  },
  oms:{
    id: 'app.components.adjustmentOverview.oms',
    defaultMessage: 'OMS',
  },
  activityForecastFrom:{
    id: 'app.components.adjustmentOverview.activityForecastFrom',
    defaultMessage: 'Activity',
  },
  activityForecastTo: {
    id: 'app.components.adjustmentOverview.activityForecastTo',
    defaultMessage: 'Adjustment Mapping Activity',
  },
  forecast:{
    id: 'app.components.adjustmentOverview.forecast',
    defaultMessage: 'Forecast',
  },
  plan:{
    id: 'app.components.adjustmentOverview.plan',
    defaultMessage: 'Plan',
  },
  backToForecast: {
    id: 'app.components.adjustmentOverview.backToForecast',
    defaultMessage: 'Restore Forecast',
  },
  modifyMultiple: {
    id: 'app.components.adjustmentOverview.modifyMultiple',
    defaultMessage: 'Modify Multiple',
  },
  adjsutment:{
    id: 'app.components.adjustmentOverview.adjsutment',
    defaultMessage: 'Adjustment',
  },
  remaining:{
    id: 'app.components.adjustmentOverview.remaining',
    defaultMessage: 'Remaining',
  }
});
