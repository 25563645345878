import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { connect as formikConnect } from 'formik';
import { DateTime } from 'luxon';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';

import { ComponentWithDirtyCheck } from 'components/ButtonWithDirtyCheck';
import DialogDHL from 'components/Dialog';
import { InputError } from 'components/FormikTextInput';
import { savePlannedVolumeHeader } from 'containers/PlanDetailPage/actions';
import { selectGuiState, selectPlanningParameters } from 'containers/PlanDetailPage/selectors';
import { savePlanningAreaPlanningParameters } from 'containers/PlanningAreaDetailPage/actions';
import { selectGuiStateForPa, selectPlanningParametersFromPa } from 'containers/PlanningAreaDetailPage/selectors';

import { DisplayDate } from '../../utils/dateTime';
import DatePicker from '../DatePicker';
import Label from '../Label';
import messages from './messages';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  min-width: 200px;
  align-items: flex-start;
  justify-content: space-between;
  > * {
    margin: 0 10px;
  }
`;

const StyledButton = styled(Button)`
  &.MuiButton-root {
    height: 100%;
    padding: 2px 8px;
    line-height: normal;
    background-color: rgba(0, 0, 0, 0.05);
    font-size: 14px;
  }
`;

const ErrorWrapper = styled.div`
  margin-top: -8px;
  font-size: 16px;
`;

const DialogForm = styled.div`
  min-width: 400px;
  display: flex;
  flex-direction: row;
  > * {
    margin-right: 20px;
    margin-bottom: 20px;
  }
`;

const DialogButton = ({ children, onClick, ...props }) => (
  <Button color="primary" variant="contained" onClick={onClick} {...props}>
    {children}
  </Button>
);

/* eslint-disable react/prefer-stateless-function */
class DateRangeHeader extends React.PureComponent {
  static getDerivedStateFromProps(nextProps, prevState) {
    const loading = !!(nextProps.guiState && nextProps.guiState.get('loading'));
    const initUpdate = !prevState || !prevState.startDay;
    const reloadUpdate = prevState && prevState.loading && loading === false;
    const pp = nextProps.planningParameters;
    return initUpdate || reloadUpdate
      ? {
          startDay: pp && pp.startDay,
          endDay: pp && pp.endDay,
          loading,
          openEndDay: false,
        }
      : { ...prevState, loading };
  }

  state = {};

  onDateChange = (dateType: 'startDay' | 'endDay') => (value: DateTime) => {
    const update = { [dateType]: value };
    if (dateType === 'startDay' && value.startOf('day') > this.state.endDay.startOf('day')) {
      update.endDay = value;
    }
    if (dateType === 'endDay' && value.startOf('day') < this.state.startDay.startOf('day')) {
      update.startDay = value;
    }
    this.setState(update);
  };

  onAcceptEndDate = value => {
    this.onDateChange('endDay')(value);
    this.setState({ openEndDay: false });
  };

  onAcceptStartDate = value => {
    this.onDateChange('startDay')(value);
    this.setState({ openEndDay: true });
  };

  openDateRangeDialog = () => {
    this.setState({ open: true });
  };

  handleCancel = () => {
    this.setState({ open: false });
  };

  submitForm = () => {
    this.props.saveFromTo({
      data: {
        startDay: this.state.startDay,
        endDay: this.state.endDay,
      },
    });
    this.setState({ open: false });
  };

  render() {
    const { open } = this.state;
    const { formik } = this.props;
    const { openEndDay, startDay, endDay } = this.state;
    const savedDates = this.props.planningParameters || {};
    const dateRangeIsInvalid = startDay && endDay && startDay.plus({ months: 3 }).startOf('day') < endDay.startOf('day');
    return (
      <>
        {savedDates && (
          <ComponentWithDirtyCheck formikOverride={formik} actionHandler={this.openDateRangeDialog}>
            {({ onClickHandler }) => (
              <StyledButton onClick={onClickHandler}>
                <Wrapper>
                  <div>
                    <Label {...messages.startDate} />
                    <DisplayDate value={savedDates.startDay} />
                  </div>
                  <div>
                    <Label {...messages.endDate} />
                    <DisplayDate value={savedDates.endDay} />
                  </div>
                </Wrapper>
              </StyledButton>
            )}
          </ComponentWithDirtyCheck>
        )}

        <DialogDHL
          open={!!open}
          isTextContent={false}
          headerLabel={messages.dialogHeader}
          maxWidth="xl"
          CustomButtons={
            <React.Fragment key={1}>
              <DialogButton color="secondary" onClick={this.submitForm} disabled={dateRangeIsInvalid}>
                <FormattedMessage {...messages.saveButton} />
              </DialogButton>
              <DialogButton color="secondary" onClick={this.handleCancel}>
                <FormattedMessage {...messages.cancelButton} />
              </DialogButton>
            </React.Fragment>
          }
        >
          <DialogForm>
            <div>
              <Label {...messages.startDate} />
              <DatePicker
                value={startDay}
                okLabel={this.props.intl.formatMessage(messages.setStartDate)}
                onAccept={this.onAcceptStartDate}
              />
            </div>
            <div>
              <Label {...messages.endDate} />
              <DatePicker
                key={`${this.state.openEndDay}`}
                value={this.state.endDay}
                onAccept={this.onAcceptEndDate}
                okLabel={this.props.intl.formatMessage(messages.setEndDate)}
                open={openEndDay}
                onOpen={() => this.setState({ openEndDay: true })}
                onClose={() => this.setState({ openEndDay: false })}
              />
            </div>
          </DialogForm>
          <div>
            {dateRangeIsInvalid && (
              <ErrorWrapper>
                <InputError>
                  <FormattedMessage {...messages.startEndDatesRangeError} />
                </InputError>
              </ErrorWrapper>
            )}
          </div>
        </DialogDHL>
      </>
    );
  }
}

DateRangeHeader.propTypes = {
  planningParameters: PropTypes.object,
  planningParametersData: PropTypes.object,
  guiState: PropTypes.object,
  intl: PropTypes.object,
  saveFromTo: PropTypes.func,
  saveAll: PropTypes.func,
  openDirtyDialog: PropTypes.func,
};

// PLAN
const mapPlanStateToProps = createStructuredSelector({
  planningParameters: selectPlanningParameters,
  guiState: selectGuiState,
});

function mapPlanDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      saveFromTo: savePlannedVolumeHeader,
    },
    dispatch,
  );
}

const withPlanConnect = connect(mapPlanStateToProps, mapPlanDispatchToProps);

// PLANNING AREA
const mapPaStateToProps = createStructuredSelector({
  planningParameters: selectPlanningParametersFromPa,
  guiState: selectGuiStateForPa,
});

function mapPaDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      saveFromTo: savePlanningAreaPlanningParameters,
    },
    dispatch,
  );
}

const withPaConnect = connect(mapPaStateToProps, mapPaDispatchToProps);

export const ConnectedDateRangeHeader = formikConnect(injectIntl(DateRangeHeader));
export const PlanDateRangeHeader = formikConnect(injectIntl(withPlanConnect(DateRangeHeader)));
export const PaDateRangeHeader = formikConnect(injectIntl(withPaConnect(DateRangeHeader)));
