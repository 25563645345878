import { numberFormat, uniqueBy } from 'utils/utils';
import { ApiActivityParametersMultipleDaysCalculationResultDTO } from 'types/drep-backend.d';

import {
  cellClassFromDataset,
  cellClassFromUnit,
  columnName,
  getNextLevel,
  headerByLevel,
  headerClassFromDataset,
} from './calculateCommon';
import {
  CELL_TYPE,
  ColDef,
  CURSORS,
  DATASET,
  Filter,
  GRANULARITY,
  GranulartiyTree,
  LEVEL,
  MENU_TABS,
  OVERRIDE_KEYS,
  OVERRIDE_STATE,
  OVERRIDE_STATE_SOURCE,
  Params,
  Props,
  T_TYPE,
  TablesColumnResult,
  TablesResult,
  UNIT,
  VALUE_TYPE,
} from './types';
import calculateLabourAvailabilityValues from './calculateLabourAvailableValues'

const cellClass = (props: Props, dataset: DATASET, granularityTree: GranulartiyTree, filter: Filter, unit: UNIT) => (
  params,
): string => {
  const { data, node } = params;
  if (!data) {
    if (node.group) {
      return `${unit}_${node.field}_sum`;
    }
    return;
  }
  const result = [];
  const isEditable = editable(props, dataset, granularityTree, filter, unit)(params);
  result.push(cellClassFromUnit(unit));
  result.push(cellClassFromDataset(dataset));
  result.push(isEditable ? 'cell-editable' : '');
  result.push(data.isIndirect === false && isEditable ? 'cell-overridable' : '');

  const overUnit = unit === UNIT.fte ? UNIT.e : unit;
  result.push(
    `override-state-${
      params.data[
        columnName(
          dataset,
          overUnit,
          {
            ...granularityTree,
            cellType: CELL_TYPE.OVERRIDE_STATE,
          },
          filter.byHour,
        )
      ]
    }`,
  );
  result.push(
    `override-state-${
      params.data[
        columnName(
          dataset,
          overUnit,
          {
            ...granularityTree,
            cellType: CELL_TYPE.OVERRIDE_STATE_SOURCE,
          },
          filter.byHour,
        )
      ]
    }`,
  );
  if (data[columnName(dataset, unit, granularityTree, filter.byHour)] === undefined) {
    result.push(`cell-empty`);
  }
  if (granularityTree.isSum) {
    result.push('total-column');
  }
  const cellClassColumn = `${columnName(dataset, unit, granularityTree, filter.byHour)}_cellClass`;
  if (data[cellClassColumn]) {
    result.push(data[cellClassColumn]);
  }
  return result.join(' ');
};

const editable = (props: Props, dataset: DATASET, granularityTree: GranulartiyTree, filter: Filter, unit: UNIT) => (
  params: Params,
): boolean => {
  const { isSum, isSumRow, isLabor } = granularityTree;
  const { editing, byHour, isDaily, isDailyPR } = filter;
  const {
    data,
    colDef: { colId },
  } = params;
  if (
    filter.granularity === GRANULARITY.MONTH ||
    isLabor ||
    isSumRow ||
    isSum ||
    !editing ||
    dataset === DATASET.budget ||
    dataset === DATASET.forecast ||
    byHour ||
    (isDaily && filter.granularity === GRANULARITY.WEEK) ||
    (isDailyPR && filter.granularity === GRANULARITY.WEEK) ||
    filter.includeWeekStartAsSunday
  ) {
    return false;
  }
  if (!data || data.isSumRow) {
    return false;
  }
  if (
    data.isIndirect === true &&
    (unit === UNIT.pr ||
      unit === UNIT.v ||
      unit === UNIT.fte ||
      unit === UNIT.heads ||
      unit === UNIT.headsOpt ||
      unit === UNIT.nos ||
      unit === UNIT.fteAdju ||
      unit === UNIT.nosAdju ||
      unit === UNIT.variance)
  ) {
    return false;
  }
  if (
    !data.isIndirect &&
    (unit === UNIT.fte || unit === UNIT.heads || unit === UNIT.headsOpt || unit === UNIT.nos || unit === UNIT.variance || unit === UNIT.effAdju || unit === UNIT.diffAdju || unit === UNIT.fteAdju || unit === UNIT.nosAdju)
  ) {
    return false;
  }
  if (data[colId] === undefined) {
    return false;
  }
  return canOverrideValue(dataset, unit, granularityTree, filter, data);
};

/*
  Only 2 out of 3 UNIT values (fte, v, e) can be overrwriten and the remaining one is calculated on BE
 */
const canOverrideValue = (
  dataset: DATASET,
  columnUnit: UNIT,
  granularityTree: GranulartiyTree,
  filter: Filter,
  data,
) => {
  const dataIdentifiers = {};
  const overrideStateIdentifiers = {};
  [UNIT.pr, UNIT.e, UNIT.v].forEach(u => {
    overrideStateIdentifiers[u] = columnName(
      dataset,
      u,
      { ...granularityTree, cellType: CELL_TYPE.OVERRIDE_STATE },
      filter.byHour,
    );
    dataIdentifiers[u] = columnName(dataset, u, granularityTree, filter.byHour);
  });

  const unitCombinations = {
    [UNIT.pr]: [UNIT.v, UNIT.e],
    [UNIT.v]: [UNIT.pr, UNIT.e],
    [UNIT.e]: [UNIT.v, UNIT.pr],
  };
  const complementaryColumns = unitCombinations[columnUnit];

  // if 2 other units are in override state, disable editing of the first one
  const firstOverriden =
    typeof data[dataIdentifiers[complementaryColumns[0]]] === 'string'
      ? data[dataIdentifiers[complementaryColumns[0]]] !== ''
      : data[overrideStateIdentifiers[complementaryColumns[0]]] === OVERRIDE_STATE.ovrd;
  const secondOverriden =
    typeof data[dataIdentifiers[complementaryColumns[1]]] === 'string'
      ? data[dataIdentifiers[complementaryColumns[1]]] !== ''
      : data[overrideStateIdentifiers[complementaryColumns[1]]] === OVERRIDE_STATE.ovrd;
  if (firstOverriden && secondOverriden) {
    return false;
  }
  return true;
};

const unitToOverrideKey = (unit: UNIT): OVERRIDE_KEYS => {
  switch (unit) {
    case UNIT.fte:
    case UNIT.fteAdju:  
    case UNIT.e:
      return OVERRIDE_KEYS.effortSrc;
    case UNIT.pr:
      return OVERRIDE_KEYS.productivityRateSrc;
    case UNIT.v:
      return OVERRIDE_KEYS.volumeSrc;
    default:
      return undefined;
  }
};

const overrideTooltip = (
  props: Props,
  dataset: DATASET,
  granularityTree: GranulartiyTree,
  filter: Filter,
  unit: UNIT,
) => (params: Params): string => {
  const result = [];
  if (editable(props, dataset, granularityTree, filter, unit)(params)) {
    switch (unitToOverrideKey(unit)) {
      case OVERRIDE_KEYS.effortSrc:
        result.push(props.messages.youCanOverrideThisCell);
        break;
      case OVERRIDE_KEYS.productivityRateSrc:
        result.push(props.messages.youCanOverrideThisCell);
        break;
      case OVERRIDE_KEYS.volumeSrc:
        result.push(props.messages.youCanOverrideThisCell);
        break;
      default:
        break;
    }
  }
  const colName = columnName(dataset, unit, { ...granularityTree, cellType: CELL_TYPE.OVERRIDE_STATE }, filter.byHour);
  const colNameSource = columnName(dataset, unit, { ...granularityTree, cellType: CELL_TYPE.OVERRIDE_STATE_SOURCE }, filter.byHour);
  if (params.data) {
    switch (params.data[colNameSource] || params.data[colName]) {
      case OVERRIDE_STATE_SOURCE.mhe:
        result.push(props.messages.overrideStateOverridenMhe);
        break;
        case OVERRIDE_STATE_SOURCE.user:
          result.push(props.messages.overrideStateOverridenUser);
          break;  
      case OVERRIDE_STATE.calc:
        result.push(props.messages.overrideStateCalc);
        break;
      case OVERRIDE_STATE.calcShift:
        result.push(props.messages.overrideStateCalc);
        break;
      case OVERRIDE_STATE.ovrd:
        result.push(props.messages.overrideStateOverriden);
        break;
      case OVERRIDE_STATE.ovrdcal:
        result.push(props.messages.overrideStateOverrideCalc);
        break;
      default:
    }
  }
  const { value } = params;
  if (value) {
    result.push(`${props.messages.fullValue}: ${value}`);
  }
  return result.join(' ');
};

export function unitToValueType(unit: UNIT): VALUE_TYPE {
  switch (unit) {
    case UNIT.fte:
    case UNIT.fteAdju:  
    case UNIT.e:
      return VALUE_TYPE.EFFORT;
    case UNIT.pr:
      return VALUE_TYPE.PRODUCTIVITY_RATE;
    case UNIT.v:
      return VALUE_TYPE.VOLUME;
    default:
      return VALUE_TYPE.EFFORT;
  }
}

function calculateRowsDifferenceAdjustmentByHour(params, dataSet, unit, filter){
  let differenceValue;
  let sumEffort;
  let adjustmentEffort;
  if (params?.colDef?.colId === "sum_planned_diffAdju") {
    sumEffort = params?.node?.aggData.sum_planned_e;
    adjustmentEffort = params?.node?.aggData.sum_planned_effAdju;
  } else if (params?.colDef?.colId === "sum_budget_diffAdju") {
    sumEffort = params?.node?.aggData.sum_budget_e;
    adjustmentEffort = params?.node?.aggData.sum_budget_effAdju;
  } else if (params?.colDef?.colId === "sum_forecast_diffAdju") {
    sumEffort = params?.node?.aggData.sum_forecast_e;
    adjustmentEffort = params?.node?.aggData.sum_forecast_effAdju;
  } 
  if (!sumEffort && !adjustmentEffort) {
    differenceValue = 0;
  }
  if (!sumEffort) {
    differenceValue = 100;
  }
  if (!adjustmentEffort) {
    differenceValue = 100;
  }
  if(sumEffort === 0 && adjustmentEffort ===0){
    differenceValue = 0;
  }
  if(sumEffort > 0 && adjustmentEffort === 0){
    differenceValue = -100;
  }
  if(sumEffort === 0 && adjustmentEffort > 0){
    differenceValue = 100;
  }
  if (sumEffort >0 && adjustmentEffort>0) {
    differenceValue = ((adjustmentEffort - sumEffort) / sumEffort) * 100;
  }
  return differenceValue ? differenceValue.toFixed(2) : 0;
}

function calculateRowsDifferenceAdjustment(params, dataSet, unit, filter) {
  const result = [];
  if (params?.colDef?.week) {
    result.push(`w${params?.colDef?.week}`);
  }
  if (params?.colDef?.day) {
    result.push(`d${params?.colDef?.day}`);
  }
  if (params?.colDef?.wzp && !filter?.byHour) {
    result.push(`wzp${params?.colDef?.wzp}`);
  }
  if (params?.colDef?.hour) {
    result.push(`h${params?.colDef?.hour}`);
  }

  const string = result.join('_');
  const sumEffortColumn = `${string}_${dataSet}_e`;
  const effortAdjustmentColumn = `${string}_${dataSet}_effAdju`;
  let differenceValue;
  let sumEffort;
  let adjustmentEffort;
  if (params?.colDef?.colId === "sum_planned_diffAdju") {
    sumEffort = params?.node?.aggData.sum_planned_e;
    adjustmentEffort = params?.node?.aggData.sum_planned_effAdju;
  } else if (params?.colDef?.colId === "sum_budget_diffAdju") {
    sumEffort = params?.node?.aggData.sum_budget_e;
    adjustmentEffort = params?.node?.aggData.sum_budget_effAdju;
  }  else if (params?.colDef?.colId === "sum_forecast_diffAdju") {
    sumEffort = params?.node?.aggData.sum_forecast_e;
    adjustmentEffort = params?.node?.aggData.sum_forecast_effAdju;
  }else {
    sumEffort = params?.node?.aggData[sumEffortColumn];
    adjustmentEffort = params?.node?.aggData[effortAdjustmentColumn];
  }
  if (!sumEffort && !adjustmentEffort) {
    differenceValue = 0;
  }
  if (!sumEffort) {
    differenceValue = 100;
  }
  if (!adjustmentEffort) {
    differenceValue = 100;
  }
  if(sumEffort === 0 && adjustmentEffort===0){
    differenceValue = 0;
  }
  if(sumEffort > 0 && adjustmentEffort === 0){
    differenceValue = -100;
  }
  if(sumEffort === 0 && adjustmentEffort > 0){
    differenceValue = 100;
  }
  if (sumEffort > 0 && adjustmentEffort > 0) {
    differenceValue = ((adjustmentEffort - sumEffort) / sumEffort) * 100;
  }
  if(differenceValue?.toFixed(2) === '-0.00') return 0;
  return differenceValue ? differenceValue.toFixed(2) : 0;
}

export function leafColumnDefinitions(
  props: Props,
  unit: UNIT,
  dataset: DATASET,
  granularityTree: GranulartiyTree,
  filter: Filter,
): ColDef {
  const colName = columnName(dataset, unit, granularityTree, filter.byHour);
  const decimalNums = granularityTree.formatters[colName] || 2;
  const { period, day, wzp, hour } = granularityTree;
  const columnIdent = {
    dataSet: dataset,
    day: day && day.day,
    hour: hour && hour.hourOfDay,
    unit,
    week: period && period.startDay,
    wzp: !filter.byHour && wzp && wzp.workZonePeriodId,
    wzpExcel: wzp && wzp.workZonePeriodId
  };
  const header = granularityTree.isSum ? props.messages[`sum_${unit}`] : props.messages[unit];
  const headerNameValue = header == "Productivity Rate" ? 'UPH' : header == 'Available Hours' ? 'Av. Hours' : header == 'Available FTE' ? 'Av. FTE' : header == 'Available Staff' ? 'Av. Staff' : header == 'Labour Discrepancy in %' ? 'Lab. Disc in %' : header;
  const defaultWidth = header == "Labour Discrepancy in %" ? 100 : (header == 'Available Hours' || header == 'FTE Disc.' || header === 'Staff Disc.') ? 85 : 70;
  // @ts-ignore
  return {
    ...columnIdent,
    cellClass: cellClass(props, dataset, granularityTree, filter, unit),
    colId: colName,
    colType: unitToValueType(unit),
    editable: editable(props, dataset, granularityTree, filter, unit),
    newValueHandler: params => {
      const change = Number(params.newValue) !== Number(params.oldValue);
      if (change || (params.newValue === '' && params.data[params.colDef.colId] !== '')) {
        params.data[params.colDef.colId] = params.newValue;
        return true;
      }
      return false;
    },
    onCellValueChanged: params => {
      params.api.refreshCells({ force: true });
      params.api.redrawRows();
      const cell = params.api.getFocusedCell();
      if (cell) {
        params.api.setFocusedCell(cell.rowIndex, cell.column);
        params.api.startEditingCell({ rowIndex: cell.rowIndex, colKey: cell.column });
      }
    },
    field: colName,
    headerClass: cellClassFromUnit(unit),
    headerName: headerNameValue,
    headerTooltip: granularityTree.isSum ? props.messages[`sum_${unit}`] : props.messages[unit], 
    suppressMenu: true,
    valueFormatter: params => {
      // console.log('params', params.data?.activity, params);
      if (!params.value || params.value === 0) {
        return params.value;
      }
      let val = params.value;
      if (decimalNums !== undefined) {
        val = Number(params.value).toFixed(2);
      }
      if(params?.colDef?.colId?.includes(UNIT.diffAdju)){
        if((params?.colDef?.colId ==="sum_planned_diffAdju" || params?.colDef?.colId ==="sum_budget_diffAdju" || params?.colDef?.colId ==="sum_forecast_diffAdju") && (params?.node?.field ==="activity" || params?.node?.field ==="uom" ) && filter.byHour){
          const value = numberFormat( calculateRowsDifferenceAdjustmentByHour(params, dataset, unit, filter), { minimumFractionDigits: 2 });
          if(value == 0) return '0%';
          return value < 0 ? `${value}%` : `+${value}%`
        } 
        if((params?.node?.field ==="department" || params?.node?.field === "customer")){
          const value = numberFormat( calculateRowsDifferenceAdjustment(params, dataset, unit, filter)  , { minimumFractionDigits: 2 });
          if(value == 0) return '0%';
          return value < 0 ? `${value}%` : `+${value}%`
        }
        const value = numberFormat(val, { minimumFractionDigits: 2 });
        if(value == 0) return '0%';
        return value < 0 ? `${value}%` : `+${value}%`
      }
      return numberFormat(val, { minimumFractionDigits: 2 });
    },
    sortable: false,
    tooltipValueGetter: overrideTooltip(props, dataset, granularityTree, filter, unit),
    type: 'numericColumn',
    width: defaultWidth,
  };
}

function getMHEHeaderName(props: Props, dataset: any, level: LEVEL, isShift: boolean) {
  switch (level) {
    case LEVEL.DAY:
      return props.messages.hDay;
    case LEVEL.MONTH:
      return props.messages.hMonth;
    case LEVEL.WZP:
      return isShift ? props.messages.hShift : props.messages.hWzp;
    case LEVEL.WEEK:
      return props.messages.hWeek;
    case LEVEL.HOUR:
      return props.messages.hHour;
    case LEVEL.MINUTE:
      return props.messages.hMinute;
    default:
      return undefined;
  }
}

function getDatasetByFilter(filter: Filter): any {
  const result = [];
  if (filter.showBudget && filter.includeBaseLine) {
    result.push(DATASET.budget);
  }
  if (filter.showPlanned) {
    result.push(DATASET.planned);
  }
  if (filter.showActuals) {
    result.push(DATASET.actuals);
  }
  if (filter.showForecast && filter.includeForecast) {
    result.push(DATASET.forecast);
  }
  return result;
}

function getUnitsByFilter(filter: Filter, dataset: DATASET): any {
  const result = [];

  if (filter.showProductivityRate) {
    result.push(UNIT.pr);
  }
  if (filter.showVolume) {
    result.push(UNIT.v);
  }
  if (filter.showEffort) {
    result.push(UNIT.e);
  }
  if (filter.showFte && filter.calculateValueType === 'FTE') {
    result.push(UNIT.fte);
  }
  if (filter.showNumberOfStaff && filter.calculateValueType === 'Staff') {
    result.push(UNIT.nos);
  }
  if (filter.showAvailableHours && dataset === DATASET.planned && filter.isLabourAvailabilityTypePR) {
    result.push(UNIT.sah)
  }
  if (filter.showAvailableFTE && dataset === DATASET.planned && filter.isLabourAvailabilityTypePR && filter.calculateValueType === 'FTE') {
    result.push(UNIT.saf)
  }
  if (filter.showAvailableStaff && dataset === DATASET.planned && filter.isLabourAvailabilityTypePR && filter.calculateValueType === 'Staff') {
    result.push(UNIT.sas)
  }
  if (filter.showlabourDiscrepancy && dataset === DATASET.planned && filter.isLabourAvailabilityTypePR) {
    result.push(UNIT.diff)
  }
  if (filter.showlabourDiscrepancy && filter.showFte && filter.calculateValueType === 'FTE' && filter.showAvailableFTE && filter.isLabourAvailabilityTypePR && dataset === DATASET.planned) {
    result.push(UNIT.fteDiff);
  }
  if (filter.showlabourDiscrepancy && filter.showNumberOfStaff && filter.calculateValueType === 'Staff' && filter.showAvailableStaff && filter.isLabourAvailabilityTypePR && dataset === DATASET.planned) {
    result.push(UNIT.staffDiff);
  }
  if(filter.showEffortAdjustment && filter.displayAdjustments){
    result.push(UNIT.effAdju)
    result.push(UNIT.diffAdju)
  }
  if (filter.showFte && filter.calculateValueType === 'FTE' && filter.showEffortAdjustment  && filter.displayAdjustments) {
    result.push(UNIT.fteAdju);
  }
  if (filter.showNumberOfStaff && filter.calculateValueType === 'Staff' && filter.showEffortAdjustment  && filter.displayAdjustments) {
    result.push(UNIT.nosAdju);
  }
  if (filter.showVariance && dataset === DATASET.actuals) {
    result.push(UNIT.variance);
  }
  return result;
}

/**
 * Aggregation function: returns sum or zero if no values are given
 */
function sumOrZero(values: number[]): number {
  return values.filter(v => v != null).reduce((sum, current) => Number(sum) + Number(current), 0);
}

function leafsColumnDefinitions(
  filter: Filter,
  props: Props & { additionalColumns?: (dataset: DATASET) => ColDef[] },
  granularityTree: GranulartiyTree,
  apiData?: any,
): TablesResult {
  const result = { activity: { colDef: [] }, mhe: { colDef: [] }, role: { colDef: [] } };
  let eId;
  getDatasetByFilter(filter).forEach(dataset => {
    const mheChildren = [];
    const activityChildren = [];
    const roleChildren = [];
    getUnitsByFilter(filter, dataset).forEach(unit => {
      // tslint:disable-next-line
      if (
        granularityTree.isSum &&
        (unit === UNIT.pr ||
          unit === UNIT.heads ||
          unit === UNIT.headsOpt ||
          unit === UNIT.nos ||
          unit === UNIT.nosAdju ||
          unit === UNIT.variance ||
          unit === UNIT.sas ||
          unit === UNIT.saf ||
          unit === UNIT.sah ||
          unit === UNIT.diff ||
          unit === UNIT.fteDiff ||
          unit === UNIT.staffDiff
        )
      ) {
        return;
      }
      if(
          unit === UNIT.effAdju && dataset === 'actuals' ||
          unit === UNIT.diffAdju && dataset === 'actuals'
        ){
        return;
      }
      const child = leafColumnDefinitions(props, unit, dataset, granularityTree, filter);
      if (unit !== UNIT.pr && unit !== UNIT.variance) {
        // @ts-ignore
        child.aggFunc = sumOrZero;
      }
      if (unit === UNIT.sas) {
        let sas;
        child.valueFormatter = (params: Params) => {
          if (params.node && params.node.group && params.node.field == 'department') {
            // @ts-ignore
            sas = calculateLabourAvailabilityValues(params, apiData, granularityTree, 'calculatedNumberOfStaff');
            return sas ? Number(sas).toFixed(2) : sas;
          }
          else {
            // @ts-ignore
            sas = false;
            return '';
          }
        };
        child.tooltipValueGetter = params => {
          return sas !== false ? `Full Value: ${sas}` : ''
        };
      };
      if (unit === UNIT.sah) {
        let sah;
        child.valueFormatter = (params: Params) => {
          if (params.node && params.node.group && params.node.field == 'department') {
            // @ts-ignore
            sah = calculateLabourAvailabilityValues(params, apiData, granularityTree, 'calculatedHoursEffort');
            return sah ? Number(sah).toFixed(2) : sah;
          }
          else {
            // @ts-ignore
            sah = false;
            return '';
          }
        };
        child.tooltipValueGetter = params => {
          return sah !== false ? `Full Value: ${sah}` : ''
        };
      };
      if (unit === UNIT.saf) {
        let saf;
        child.valueFormatter = (params: Params) => {
          if (params.node && params.node.group && params.node.field == 'department') {
            // @ts-ignore
            saf = calculateLabourAvailabilityValues(params, apiData, granularityTree, 'calculatedFte');
            return saf ? Number(saf).toFixed(2) : saf;
          }
          else {
            // @ts-ignore
            saf = false;
            return '';
          }
        };
        child.tooltipValueGetter = params => {
          return saf !== false ? `Full Value: ${saf}` : ''
        };
      }
      if (unit === UNIT.v) {
        const vF = child.valueFormatter;
        child.valueFormatter = (params: Params) => {
          if (params.node && params.node.group && params.node.field !== 'activity') {
            return '';
          }
          return (vF && vF(params)) || params.value;
        };
      }
      if (unit === UNIT.e) {
        child.headerName = granularityTree.isSum
          ? `${props.messages.sum} ${getMHEHeaderName(
            props,
            dataset,
            granularityTree.level,
            filter.transformationType === T_TYPE.SHIFT,
          )}`
          : getMHEHeaderName(props, dataset, granularityTree.level, filter.transformationType === T_TYPE.SHIFT);
        child.headerTooltip = granularityTree.isSum
          ? `${props.messages.sum} ${getMHEHeaderName(
            props,
            dataset,
            granularityTree.level,
            filter.transformationType === T_TYPE.SHIFT,
          )}`
          : getMHEHeaderName(props, dataset, granularityTree.level, filter.transformationType === T_TYPE.SHIFT);
        mheChildren.push(child);
      }
      if (unit === UNIT.diff) {
        let availableHours = 0;
        let differnce;
        activityChildren.forEach((ac) => {
          if(filter.calculateValueType === 'FTE' && ac.unit == 'fte'){
            eId = ac.field;
          }else if(filter.calculateValueType === 'Staff' && ac.unit == 'nos'){
            eId = ac.field;
          }
        })
        // @ts-ignore
        child.field = eId;
        child.aggFunc = 'sum';
        child.valueFormatter = (params: Params) => {
          if (params.node && params.node.group && params.node.field == 'department') {
            // @ts-ignore
            const currentHours = params?.node?.aggData[eId] >= 0 ? params?.node?.aggData[eId] : params.value;
            // @ts-ignore
            availableHours = calculateLabourAvailabilityValues(params, apiData, granularityTree, filter?.calculateValueType === 'FTE' ? 'calculatedFte': 'calculatedNumberOfStaff');
            // @ts-ignore
            if (availableHours === '') {
              return '';
            }
            if(availableHours === 0 && currentHours == 0){
              differnce = 0;
              return '0%'
            }
            if(availableHours === 0 && currentHours === null){
              differnce = 0;
              return '0%'
            }
            if (availableHours === 0) {
              differnce = -100;
              return '-100%';
            }
            if(currentHours == 0){
              differnce = 100;
              return '+100%'
            }
            if (currentHours && availableHours) {
              differnce = ((availableHours - currentHours) / availableHours) * 100;
            }
            return differnce > 0 ? `+${Number(differnce).toFixed(2)}%` : `${Number(differnce).toFixed(2)}%`;
          }
          else {
            return '';
          }
        };
        child.tooltipValueGetter = params => {
          if (params.node && params.node.group && params.node.field == 'department') {
            return differnce === 0 ? 'Full Value: 0%' : differnce < 0 ? `Full Value: ${Number(differnce).toFixed(2)}%` : differnce > 0 ? `Full Value: +${Number(differnce).toFixed(2)}%` : '';
          }
        };
        child.cellClass = (params: Params) => {
          if (params.node && params.node.group && params.node.field == 'department') {
            //@ts-ignore
            const currentHours = params?.node?.aggData[eId] >=0 ? params?.node?.aggData[eId] : params.value;
            const availableHours = calculateLabourAvailabilityValues(params, apiData, granularityTree, filter?.calculateValueType === 'FTE' ? 'calculatedFte':'calculatedNumberOfStaff');
            let differnce = 0;
            if(availableHours === 0 && currentHours == 0){
              return ''
            }
            if(availableHours === 0 && currentHours === null){
              return '';
            }
            if (availableHours === 0) {
              return 'cell-overStaff';
            }
            if(currentHours == 0){
              return 'cell-underStaff';
            }
            if (currentHours && availableHours) {
              differnce = ((availableHours - currentHours) / availableHours);
            }
            return differnce == 0 ? '' : differnce > 0 ? 'cell-underStaff' : 'cell-overStaff';
          }
        };
      }
      if (unit === UNIT.fteDiff || unit === UNIT.staffDiff) {
        let availableHours = 0;
        let differnce;
        activityChildren.forEach((ac) => {
          if (filter.calculateValueType === 'FTE' && ac.unit == 'fte') {
            eId = ac.field;
          } else if (filter.calculateValueType === 'Staff' && ac.unit == 'nos') {
            eId = ac.field;
          }
        })
        // @ts-ignore
        child.field = eId;
        //child.aggFunc = 'sum';
        child.valueFormatter = (params: Params) => {
          if (params.node && params.node.group && params.node.field == 'department') {
            // @ts-ignore
            const currentHours = params?.node?.aggData[eId] >= 0 ? params?.node?.aggData[eId] : params.value;
            // @ts-ignore
            availableHours = calculateLabourAvailabilityValues(params, apiData, granularityTree, filter?.calculateValueType === 'FTE' ? 'calculatedFte' : 'calculatedNumberOfStaff');
            differnce = availableHours - currentHours;
            return differnce === 0 ? 0 : differnce > 0 ? `+${Number(differnce).toFixed(2)}` : Number(differnce).toFixed(2);
          }
          else {
            return '';
          }
        };
        child.tooltipValueGetter = params => {
          if (params.node && params.node.group && params.node.field == 'department') {
            return `Full Value: ${differnce}`
          }
        };
      }
      if(unit === UNIT.effAdju){
        child.tooltipValueGetter = params => {
          return params?.value >= 0 ? `Full Value: ${params?.value}` : '';
        };
        child.cellClass = (params: Params) => {
          if(params.node && params.node.field == 'department'){
            return 'cell-adjustable-dept'
          }
          if(params.node && params.node.field == 'customer'){
            return 'cell-adjustable-cust'
          }
          if(params?.colDef?.colId === "sum_planned_effAdju" && (params?.value || params?.value === 0)){
            return 'cell-adjustable-sum'
          }
          if(params?.colDef?.colId === "sum_budget_effAdju" && (params?.value || params?.value === 0)){
            return 'cell-adjustable-sum'
          }
          if(params?.value || params?.value === 0){
            return 'cell-adjustable'
          }
        }
        child.headerName = `${granularityTree.isSum ? props.messages.sum_effAdju : props.messages.effAdju} ${getMHEHeaderName(
            props,
            dataset,
            granularityTree.level,
            filter.transformationType === T_TYPE.SHIFT,
          )}`

          child.headerTooltip = `${granularityTree.isSum ? props.messages.sum_effAdju : props.messages.effAdju} ${getMHEHeaderName(
            props,
            dataset,
            granularityTree.level,
            filter.transformationType === T_TYPE.SHIFT,
          )}`
      }
      if(unit === UNIT.diffAdju){
        child.tooltipValueGetter = params => {
          //@ts-ignore
          return params?.valueFormatted ? `Full Value: ${params.valueFormatted}` : params?.valueFormatted === 0 ? `Full Value: 0%`: '';
        };
        child.cellClass = (params: Params) => {
          if(params.node && params.node.field == 'department'){
            return 'cell-adjustable-dept'
          }
          if(params.node && params.node.field == 'customer'){
            return 'cell-adjustable-cust'
          }
          if(params?.colDef?.colId === "sum_planned_diffAdju" && (params?.value || params?.value === 0)){
            return 'cell-adjustable-sum'
          }
          if(params?.colDef?.colId === "sum_budget_diffAdju" && (params?.value || params?.value === 0)){
            return 'cell-adjustable-sum'
          }
          if(params?.colDef?.colId === "sum_forecast_diffAdju" && (params?.value || params?.value === 0)){
            return 'cell-adjustable-sum'
          }
          if(params?.value || params?.value === 0){
            return 'cell-adjustable'
          }
        }
      }
      activityChildren.push(child);
    });
    if (props.additionalColumns) {
      activityChildren.push(...props.additionalColumns(dataset));
    }

    if (activityChildren.length > 0) {
      result.activity.colDef.push({
        children: activityChildren,
        headerClass: headerClassFromDataset(dataset),
        headerName: props.messages[dataset],
        headerTooltip: props.messages[dataset],
      });
    }
    if (mheChildren.length > 0) {
      result.mhe.colDef.push({
        children: mheChildren,
        headerClass: headerClassFromDataset(dataset),
        headerName: props.messages[dataset],
        headerTooltip: props.messages[dataset],
      });
    }
    if (roleChildren.length > 0) {
      result.role.colDef.push({
        children: roleChildren,
        headerClass: headerClassFromDataset(dataset),
        headerName: props.messages[dataset],
        headerTooltip: props.messages[dataset],
      });
    }
  });
  return result;
}

export function periodToColumnDefinitions(
  filter: Filter,
  props: Props,
  granularityTree: GranulartiyTree,
  apiData?: any,
): TablesColumnResult {
  const cursor = CURSORS[granularityTree.level];
  const single = granularityTree[cursor.single];
  const nextLevel: LEVEL = getNextLevel(granularityTree.level);
  const nextCursor = CURSORS[nextLevel];
  let subitems = single[nextCursor.array];
  // @ts-ignore
  const resultActivity: ColDef = { headerName: headerByLevel(granularityTree.level, single, props), children: [] };
  // @ts-ignore
  const resultMhe: ColDef = { headerName: headerByLevel(granularityTree.level, single, props), children: [] };
  // @ts-ignore
  const resultRole: ColDef = { headerName: headerByLevel(granularityTree.level, single, props), children: [] };

  if (granularityTree.level === LEVEL.HOUR) {
    // @ts-ignore
    resultActivity.order = single.hourOfDay;
    // @ts-ignore
    resultMhe.order = single.hourOfDay;
     // @ts-ignore
     resultRole.order = single.hourOfDay;
  }

  if (filter.granularity.valueOf() !== granularityTree.level.valueOf() && (!subitems || !subitems.length)) {
    // there are no children at this level but it can also happen for days with no wzp
    // so if we're not at the bottom yet (leaf level) add empty children and go down
    let dummyItem;
    if (granularityTree.level === LEVEL.DAY) {
      dummyItem = { workZonePeriodName: '' };
    }
    if (dummyItem) {
      subitems = subitems.concat([dummyItem]);
    }
  }

  if (subitems && subitems.length > 0) {
    subitems.forEach(subitem => {
      const result: TablesColumnResult = periodToColumnDefinitions(filter, props, {
        ...granularityTree,
        level: nextLevel,
        [nextCursor.single]: subitem,
      },
        apiData
      );
      // Skip shift level if filter isShift
      if (filter.byHour && nextLevel === LEVEL.WZP) {
        resultActivity.children = resultActivity.children.concat(result.activity.colDef.children);
        resultMhe.children = resultMhe.children.concat(result.mhe.colDef.children);
        resultRole.children = resultRole.children.concat(result.role.colDef.children);
      } else {
        resultActivity.children.push(result.activity.colDef);
        resultMhe.children.push(result.mhe.colDef);
        resultRole.children.push(result.role.colDef);
      }
    });
    if (filter.byHour && nextLevel === LEVEL.WZP) {
      resultActivity.children = uniqueBy(resultActivity.children, 'headerName');
      resultMhe.children = uniqueBy(resultMhe.children, 'headerName');
      resultRole.children = uniqueBy(resultRole.children, 'headerName');
    }
  } else {
    const result = leafsColumnDefinitions(filter, props, { ...granularityTree }, apiData);
    resultActivity.children = result.activity.colDef;
    resultMhe.children = result.mhe.colDef;
    resultRole.children = result.role.colDef;
  }
  return { activity: { colDef: resultActivity }, mhe: { colDef: resultMhe }, role: { colDef: resultRole } };
}

export function getWzpAvgColId(wzpName: string, dataset: DATASET) {
  return `wzp_avg_${dataset}_${wzpName}`;
}

export default function makeColumnDefinitions(
  filter: Filter,
  props: Props,
  periods: ApiActivityParametersMultipleDaysCalculationResultDTO[],
  rows: TablesResult,
  wzpNames?: string[],
  apiData?: any,
): TablesResult {
  const colDefMhe: ColDef[] = [
    // @ts-ignore
    {
      colId: 'department',
      field: 'department',
      headerName: props.messages.department,
      hide: true,
      menuTabs: [MENU_TABS.filterMenuTab],
      rowGroup: true,
      width: 220,
    },
    {
      colId: 'customer',
      field: 'customer',
      headerName: props.messages.customer,
      width: 220,
      menuTabs: [MENU_TABS.filterMenuTab],
      keyCreator: params => (params.value && params.value.name) || props.messages.all,
      hide: true,
      rowGroup: true,
      pinned: true,
    },
    // @ts-ignore
    {
      headerName: props.messages.activity,
      width: 220,
      colId: 'activity',
      field: 'activity',
      cellRenderer: 'acWithToolTip',
      valueFormatter: params => params.value && params.value.split('||')[0],
      pinned: true,
      rowGroup: filter.byHour,
      hide: true,
    },
  ];
  const colDefRole: ColDef[] = [
    // @ts-ignore
    {
      colId: 'department',
      field: 'department',
      headerName: props.messages.department,
      hide: true,
      menuTabs: [MENU_TABS.filterMenuTab],
      rowGroup: true,
      width: 220,
    },
    {
      colId: 'customer',
      field: 'customer',
      headerName: props.messages.customer,
      width: 220,
      menuTabs: [MENU_TABS.filterMenuTab],
      keyCreator: params => (params.value && params.value.name) || props.messages.all,
      hide: true,
      rowGroup: true,
      pinned: true,
    },
    // @ts-ignore
    {
      headerName: props.messages.activity,
      width: 220,
      colId: 'activity',
      field: 'activity',
      cellRenderer: 'acWithToolTip',
      valueFormatter: params => params.value && params.value.split('||')[0],
      pinned: true,
      rowGroup: filter.byHour,
      hide: true,
    },
  ];

  if (!filter.byHour) {
    // @ts-ignore
    colDefMhe.push({
      colId: 'uom',
      field: 'uom',
      headerName: props.messages.uom,
      menuTabs: [MENU_TABS.filterMenuTab],
      cellRenderer: 'nameWithToolTip',
      rowGroup: filter.byHour,
      hide: true,
      filterParams: {
        valueGetter: params => {
          if (!params.data || !params.data.uom) {
            return '';
          }
          const { uom } = params.data;
          if (typeof uom === 'string') {
            return uom;
          }
          return `${uom.name} / ${uom.regionalConfigurationName}`;
        },
        applyButton: true,
        clearButton: true,
      },
      pinned: true,
      width: 100,
    });
    //@ts-ignore
    colDefRole.push({
      colId: 'uom',
      field: 'uom',
      headerName: props.messages.uom,
      menuTabs: [MENU_TABS.filterMenuTab],
      cellRenderer: 'nameWithToolTip',
      rowGroup: filter.byHour,
      hide: true,
      filterParams: {
        valueGetter: params => {
          if (!params.data || !params.data.uom) {
            return '';
          }
          const { uom } = params.data;
          if (typeof uom === 'string') {
            return uom;
          }
          return `${uom.name} / ${uom.regionalConfigurationName}`;
        },
        applyButton: true,
        clearButton: true,
      },
      pinned: true,
      width: 100,
    });
  }
   // @ts-ignore
   colDefRole.push({
    colId: 'role',
    field: 'role',
    headerName: props.messages.role,
    headerTooltip: props.messages.role,
    menuTabs: [MENU_TABS.filterMenuTab],
    pinned: true,
    rowGroup: filter.byHour,
    hide: filter.byHour,
    width: 220,
  });
  // @ts-ignore
  colDefMhe.push({
    colId: 'mhe',
    field: 'mhe',
    headerName: props.messages.mhe,
    headerTooltip: props.messages.mhe,
    menuTabs: [MENU_TABS.filterMenuTab],
    pinned: true,
    rowGroup: filter.byHour,
    hide: filter.byHour,
    width: 220,
  });
  if (filter.byHour) {
    // @ts-ignore
    colDefMhe.push({
      colId: 'shift',
      field: 'shift',
      headerName: filter.isShift ? props.messages.shift : props.messages.wzp,
      headerTooltip: filter.isShift ? props.messages.shift : props.messages.wzp,
      menuTabs: [MENU_TABS.filterMenuTab],
      pinned: true,
      width: 240,
    });
     // @ts-ignore
     colDefRole.push({
      colId: 'shift',
      field: 'shift',
      headerName: filter.isShift ? props.messages.shift : props.messages.wzp,
      headerTooltip: filter.isShift ? props.messages.shift : props.messages.wzp,
      menuTabs: [MENU_TABS.filterMenuTab],
      pinned: true,
      width: 240,
    });
  }
  const colDefActivity: ColDef[] = [
    // @ts-ignore
    {
      headerName: props.messages.department,
      width: 220,
      field: 'department',
      colId: 'department',
      menuTabs: [MENU_TABS.filterMenuTab],
      hide: true,
      rowGroup: true,
      pinned: true,
    },
    {
      headerName: props.messages.customer,
      width: 220,
      field: 'customer',
      colId: 'customer',
      menuTabs: [MENU_TABS.filterMenuTab],
      keyCreator: params => (params.value && params.value.name) || props.messages.all,
      hide: true,
      rowGroup: true,
      pinned: true,
    },
  ];
  // @ts-ignore
  colDefActivity.push({
    headerName: props.messages.activity,
    width: 220,
    colId: 'activity',
    field: 'activity',
    cellRenderer: 'acWithToolTip',
    valueFormatter: params => params.value && params.value.split('||')[0],
    pinned: true,
    rowGroup: filter.byHour,
    hide: true,
  });

  // @ts-ignore
  colDefActivity.push({
    colId: 'uom',
    field: 'uom',
    headerName: props.messages.uom,
    menuTabs: [MENU_TABS.filterMenuTab],
    rowGroup: false,
    hide: true,
    filterParams: {
      valueGetter: params => {
        if (!params.data || !params.data.uom) {
          return '';
        }
        const { uom } = params.data;
        if (typeof uom === 'string') {
          return uom;
        }
        return `${uom.name} / ${uom.regionalConfigurationName}`;
      },
      applyButton: true,
      clearButton: true,
    },
    pinned: true,
    width: 100,
  });

  if (filter.byHour) {
    // @ts-ignore
    colDefActivity.push({
      colId: filter.isShift ? 'shift' : 'wzp',
      field: filter.isShift ? 'shift' : 'wzp',
      valueFormatter: filter.isShift ? null : params => params.value && params.value.workZonePeriodName,
      headerName: props.messages[filter.isShift ? 'shift' : 'wzp'],
      headerTooltip: props.messages[filter.isShift ? 'shift' : 'wzp'],
      menuTabs: [MENU_TABS.filterMenuTab],
      pinned: true,
      width: 220,
      pinnedRowCellRenderer: 'acShowLabourAvailability',
    });
  }

  (periods || []).forEach(period => {
    const result: TablesColumnResult = periodToColumnDefinitions(filter, props, {
      period,
      level: filter.granularity === GRANULARITY.MONTH ? LEVEL.MONTH : LEVEL.WEEK,
      formatters: rows.activity.formatters,
    },
      apiData,
    );
    colDefActivity.push(result.activity.colDef);
    colDefMhe.push(result.mhe.colDef);
    colDefRole.push(result.role.colDef);
  });
  const addNosAvgColumns =  filter.calculateValueType === 'Staff' && filter.showNumberOfStaff && wzpNames && filter.granularity === GRANULARITY.WZP;
  const nosAvgColumns: (dataset: DATASET) => ColDef[] = (dataset: DATASET) =>
    addNosAvgColumns
      ? wzpNames.map(name => ({
        colId: getWzpAvgColId(name, dataset),
        field: getWzpAvgColId(name, dataset),
        headerName: `${name} ${props.messages.wzpAverage}`,
        headerTooltip: `${name} ${props.messages.wzpAverage}`,
        keyCreator: undefined,
        suppressMenu: true,
        editable: false,
        headerClass: 'cell-nos',
        cellClass: params => {
          const { data, node, value } = params;
          if (!data) {
            if (node.group) {
              return `nos_${node.field}_sum`;
            }
            return;
          }
          return value || value === 0 ? 'cell-nos' : undefined;
        },
        valueFormatter: params => {
          if (!params.value || params.value === 0) {
            return params.value;
          }
          const val = Number(params.value).toFixed(2);
          return numberFormat(val, { minimumFractionDigits: 2 });
        },
        type: 'numericColumn',
        tooltipValueGetter: params => `${props.messages.fullValue}: ${params.value}`,
        width: 65,
        aggFunc: sumOrZero,
      }))
      : [];
  const resultTotal = leafsColumnDefinitions(
    filter,
    { ...props, additionalColumns: nosAvgColumns },
    {
      isSum: true,
      level: LEVEL[filter.granularity],
      formatters: rows.activity.formatters,
    },
  );
  // @ts-ignore
  colDefActivity.push({
    children: resultTotal.activity.colDef,
    headerName: props.messages.result,
    headerTooltip: props.messages.result,
  });
  // @ts-ignore
  colDefMhe.push({
    children: resultTotal.mhe.colDef,
    headerName: props.messages.mheResult,
    headerTooltip: props.messages.mheResult,
  });
   // @ts-ignore
   colDefRole.push({
    children: resultTotal.role.colDef,
    headerName: props.messages.roleResult,
    headerTooltip: props.messages.roleResult,
  });
  return { activity: { colDef: colDefActivity, data: [] }, mhe: { colDef: colDefMhe, data: [] }, role: { colDef: colDefRole, data: [] } };
}
