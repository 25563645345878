/**
 *
 * DatePicker
 *
 */

import './style.css';

import React from 'react';
import PropTypes from 'prop-types';
import LuxonUtils from '@date-io/luxon';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core';
import { DatePicker, DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { DateTime } from 'luxon';
import { injectIntl } from 'react-intl';
import { withTheme } from 'styled-components';

import { parseDate, parseDateMax } from 'utils/dateTime';
import { userLocale } from 'utils/utils';

import messages from './messages';

export const materialTheme = props =>
  createMuiTheme({
    palette: {
      primary: {
        main: props.theme.color.yellow,
      },
    },
    overrides: {
      MuiInput: {
        underline: {
          '&:after': {
            borderBottom: `2px solid ${props.theme.color.yellow}`,
          },
          '&:hover:after': {
            transform: 'scaleX(1)',
            borderBottom: `2px solid ${props.theme.color.yellow}`,
          },
          '&:before': {
            borderBottom: `2px solid ${props.theme.color.greyBorder}`,
          },
          '&:hover:before': {
            borderBottom: `2px solid ${props.theme.color.greyBorder} !important`, // TODO rewrite override
          },
        },
      },
      MuiPickersToolbar: {
        toolbar: {
          backgroundColor: props.theme.color.yellow,
        },
      },
      MuiPickersCalendarHeader: {
        switchHeader: {
          marginTop: 10,
          // backgroundColor: lightBlue.A200,
          // color: 'white',
        },
      },
      MuiPickersDay: {
        day: {
          color: props.theme.color.grey1,
        },
        current: {
          color: props.theme.color.black1,
        },
      },
    },
  });

/* eslint-disable react/prefer-stateless-function */
class DatePickerDHL extends React.PureComponent {
  muiTheme: null;

  constructor(props) {
    super(props);

    this.muiTheme = materialTheme(this.props);
    // TODO set some how via luxon to keep locale
    this.format = this.props.timePicker ? 'D HH:mm' : 'D';
  }

  onChange = value => {
    if (value && this.props.onChange) {
      this.props.onChange(value);
    }
  };

  onAccept = value => {
    const { onChange, onAccept } = this.props;

    if (value && onAccept) {
      onAccept(value);
    } else if (value && onChange) {
      onChange(value);
    }
  };

  canBeSetToToday = (minDate, maxDate) => {
    if (
      (maxDate && parseDateMax(maxDate).startOf('day') < DateTime.local().startOf('day')) ||
      (minDate && parseDate(minDate).startOf('day') > DateTime.local().startOf('day'))
    ) {
      return false;
    }
    return true;
  };

  render() {
    const {
      inline = false,
      value,
      onOpen,
      onClose,
      minDate,
      maxDate,
      okLabel,
      timePicker,
      open,
      intl: { formatMessage },
      intl,
      ...otherProps
    } = this.props;
    const config = {
      clearable: false,
      showTodayButton: this.canBeSetToToday(minDate, maxDate),
      format: this.format,
      value,
      onChange: this.onChange,
      onAccept: this.onAccept,
      okLabel: okLabel || formatMessage(messages.okLabel),
      className: 'smp-date-picker',
      minDate,
      minDateMessage: formatMessage(messages.minDateError),
      maxDate,
      maxDateMessage: formatMessage(messages.maxDateError),
      invalidDateMessage: formatMessage(messages.invalidDateMessage),
      todayLabel: formatMessage(messages.todayLabel),
      clearLabel: formatMessage(messages.clearLabel),
      cancelLabel: formatMessage(messages.cancelLabel),
      ...otherProps,
    };
    if (open !== undefined) config.open = open;
    if (onOpen) config.onOpen = onOpen;
    if (onClose) config.onClose = onClose;

    // console.log('Picker props', config);
    const Picker = timePicker ? DateTimePicker : DatePicker;
    return (
      <MuiThemeProvider theme={this.muiTheme}>
        <MuiPickersUtilsProvider utils={LuxonUtils} locale={userLocale || intl.locale}>
          <Picker {...config} />
        </MuiPickersUtilsProvider>
      </MuiThemeProvider>
    );
  }
}

DatePickerDHL.propTypes = {
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onInputChange: PropTypes.func,
  datePickerProps: PropTypes.object,
  minDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  maxDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  timePicker: PropTypes.bool,
  okLabel: PropTypes.string,
  onClose: PropTypes.func,
};

export default withTheme(injectIntl(DatePickerDHL));
