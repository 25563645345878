import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectTabsDomain = state => state.get('tabs', initialState);

const makeSelectTabsDomain = () =>
  createSelector(
    selectTabsDomain,
    substate => substate.toJS(),
  );

export { makeSelectTabsDomain };
