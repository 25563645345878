/* eslint-disable no-plusplus */
/**
 *
 * WorkingHours
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { getWeekdaysSortedString } from 'utils/calendar/localize';

import { getIn } from 'formik';
import { T_TYPE } from 'utils/constants';
import messages from './messages';

const Wrapper = styled.div`
  max-width: 365px;
  max-height: ${props => props.height || '365px'};
  overflow-y: auto;
  input {
    background-color: ${props => props.theme.color.grey5};
  }
`;

const Section = styled.div`
  ${props =>
    props.shadowBackground &&
    css`
      background-color: ${props.theme.color.greyDlgBgSub};
      box-shadow: ${props.theme.shadow.pageBoxSlight};
      margin: 8px 2px;
    `};
`;

const SectionTitle = styled.div``;

const Name = styled.div`
  ${props => props.isShift && 'margin-left: 10px;'}
  font-size: 12px;
  font-weight: 700;
  align-self: center;
`;

const Wzps = styled.div`
  display: grid;
  grid-template-columns: 10em 5em 5em;
  grid-row-gap: ${props => props.theme.grid.rowGap};
`;

function WorkingHours(props) {
  const pp = props && getIn(props.values, 'planningParameters');
  if (!pp) return <span />;
  const tTypeValue = pp.transformationType || T_TYPE.WZP;
  const isShift = tTypeValue === T_TYPE.SHIFT;
  const data = pp.workingHours;
  return (
    <Wrapper height={props.height}>
      <Wzps key={-1}>
        <FormattedMessage {...messages.name} />
        <FormattedMessage {...messages.startTime} />
        <FormattedMessage {...messages.endTime} />
      </Wzps>
      {data &&
        data.map(section => (
          <Section key={section.key} shadowBackground={props.shadowBackground}>
            {section.title && <SectionTitle>{section.title}</SectionTitle>}
            {!isShift && renderGroupedPart(section.wzps)}
            {isShift && renderGroupedShift(section.days, props.formatMessage)}
          </Section>
        ))}
    </Wrapper>
  );
}

function renderGroupedPart(data, isShift = false) {
  return (
    <Wzps>
      {data &&
        data.map(({ id, title, from, to }) => (
          <React.Fragment key={id}>
            <Name isShift={isShift}>{title}</Name>
            <div>{from}</div>
            <div>{to}</div>
          </React.Fragment>
        ))}
    </Wzps>
  );
}

function renderGroupedShift(data, formatMessage) {
  return (
    <>
      {data &&
        data.map(({ title, shifts, key }) => (
          <React.Fragment key={key}>
            <SectionTitle>{getWeekdaysSortedString(formatMessage, title.split(','), true)}</SectionTitle>
            {renderGroupedPart(shifts, true)}
          </React.Fragment>
        ))}
    </>
  );
}

WorkingHours.defaultProps = {
  shadowBackground: true,
};

WorkingHours.propTypes = {
  values: PropTypes.object,
  shadowBackground: PropTypes.bool,
};

export default WorkingHours;
