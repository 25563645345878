/**
 *
 * FormikNumberPicker
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';

import { toNumber } from '../../utils/utils';

const numOnly = event => {
  if (
    /[0-9]/.test(event.key) ||
    /(^110$)|(^8$)|(^37$)|(^46$)|(^39$)|(^38$)|(^40$)|(^35$)|(^36$)|(^9$)|(^17$)|(^67$)|(^65$)|(^86$)|(^188$)|(^190$)/.test(
      event.keyCode,
    )
  ) {
    return true;
  }
  event.preventDefault();
  event.stopPropagation();
  return false;
};

const CustomInputComponent = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors, setFieldValue },
  ...props
}) => {
  if (field.value == null) {
    field.value = '';
  }
  return (
    <input
      type="number"
      onKeyDown={numOnly}
      {...field}
      onBlur={e => setFieldValue(field.name, toNumber(e.target.value))}
      {...props}
      min = {0}
    />
  );
};

const CustomInputComponentCP = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors, setFieldValue },
  ...props
}) => {
  if (field.value == null) {
    field.value = '';
  }
  return (
    <input
      type="number"
      onKeyDown={numOnly}
      {...field}
      onBlur={e => setFieldValue(field.name, toNumber(e.target.value))}
      {...props}
      min = {0}
      max = {10}
    />
  );
};

function FormikNumberInput(props) {
  return props.editable !== false ? (
    <Field
      name={props.name}
      min={props.min ? props.min : undefined}
      max={props.max ? props.max : undefined}
      required={props.required ? props.required : false}
      component={props.componentType ==="coveragePriority" ? CustomInputComponentCP :CustomInputComponent}
      style={props.style}
    />
  ) : (
    <div>{props.value ? props.value.toLocaleString() : null}</div>
  );
}

FormikNumberInput.propTypes = {
  editable: PropTypes.bool,
  required: PropTypes.bool,
  min: PropTypes.number,
  max: PropTypes.number,
  name: PropTypes.string,
  value: PropTypes.number,
};

export default FormikNumberInput;
