/**
 *
 * DirtyDialog
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import messages from './messages';
import DialogDHLWarning from 'components/Dialog/DialogWarning';

export const DirtyDialog = props =>
    props.isOpen ? (
        <DialogDHLWarning
            open={props.isOpen}
            onCancel={() => {
                props.actionHandler(false);
                props.close();
            }}
            onConfirm={() => {
                !props.deleteConfirmation && props.saveHandler();
                props.close();
            }}
            confirmLabel={props.deleteConfirmation ? messages.delete : messages.saveFromTo}
            cancelLabel={messages.cancel}
            headerLabel={messages.emptyHeader}
        >
            <div style={{ marginTop: '25px' }}>
                <FormattedMessage {...messages.planningAreaText} />
            </div>
        </DialogDHLWarning>
    ) : null;

DirtyDialog.propTypes = {
    isOpen: PropTypes.bool,
    close: PropTypes.func,
    deleteConfirmation: PropTypes.bool,
    saveHandler: PropTypes.func,
    actionHandler: PropTypes.func,
};

export const withDirtyDialog = WrappedComponent =>
    class extends React.Component {
        state = {
            isOpen: false,
            actionHandler: () => null,
            saveHandler: () => null,
            deleteConfirmation: false,
        };

        close = () =>
            this.setState({
                isOpen: false,
            });

        openDialog = (saveHandler, actionHandler) =>
            this.setState({
                isOpen: true,
                actionHandler,
                saveHandler,
                deleteConfirmation: false,
            });

        openDirtyDialogAsDeleteConfirm = (saveHandler, actionHandler) =>
            this.setState({
                isOpen: true,
                actionHandler,
                saveHandler,
                deleteConfirmation: true,
            });

        render() {
            return (
                <>
                    <WrappedComponent
                        {...this.props}
                        openDirtyDialog={this.openDialog}
                        openDirtyDialogAsDeleteConfirm={this.openDirtyDialogAsDeleteConfirm}
                        closeDirtyDialog={this.close}
                    />
                    <DirtyDialog {...this.state} close={this.close} />
                </>
            );
        }
    };

export default DirtyDialog;
