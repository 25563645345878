import ActionColumn from 'components/ActionColumn';
import {
  CustomersCell,
  CustomersValueGetter,
  DateCell,
  dateComparator,
  FacilitiesCell,
  FacilitiesValueGetter,
  CreatedByCell,
  CreatedByValueGetter,
  CreatedValueGetter,
  DisableValueGetter,
  FirstLastNameCell,
  FirstLastNameValueGetter,
  UpdatedValueGetter,
  ManagementUnitsCell,
  ManagementUnitsValueGetter,
} from 'components/ListGrid/utils';

export const makeColumnDefs = config => props => {
  const { permissionDisable, messages, actionCellRendererParams, interceptColDef } = config;
  if (!props.domain || !props.domain.defs) return [];
  const columns = [
    {
      headerName: '#',
      width: 60,
      checkboxSelection: true,
      sortable: false,
      suppressMenu: true,
      pinned: true,
      filter: false,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
    },
    {
      headerName: props.intl.formatMessage(messages.name),
      field: 'name',
      colId: 'name',
      menuTabs: ['filterMenuTab'],
      ...props.domain.defs.name,
    },
    {
      headerName: props.intl.formatMessage(messages.id),
      field: 'id',
      colId: 'id',
      menuTabs: ['filterMenuTab'],
      ...props.domain.defs.id,
    },
    {
      headerName: props.intl.formatMessage(messages.action),
      field: 'action',
      colId: 'action',
      sortable: false,
      suppressMenu: true,
      cellRendererFramework: ActionColumn,
      cellRendererParams: actionCellRendererParams(props, config),
      width: 180,
      autoHeight: true,
      menuTabs: ['filterMenuTab'],
    },
    {
      headerName: props.intl.formatMessage(messages.facilities),
      field: 'facilities',
      menuTabs: ['filterMenuTab'],
      colId: 'facilities',
      cellRendererFramework: FacilitiesCell,
      valueGetter: FacilitiesValueGetter,
      autoHeight: true,
      ...props.domain.defs.facility,
    },
    {
      headerName: props.intl.formatMessage(messages.managementUnits),
      field: 'managementUnits',
      menuTabs: ['filterMenuTab'],
      colId: 'managementUnits',
      valueGetter: ManagementUnitsValueGetter,
      cellRendererFramework: ManagementUnitsCell,
      autoHeight: true,
    },
    {
      headerName: props.intl.formatMessage(messages.customers),
      field: 'customers',
      colId: 'customers',
      menuTabs: ['filterMenuTab'],
      cellRendererFramework: CustomersCell,
      valueGetter: CustomersValueGetter,
      autoHeight: true,
      ...props.domain.defs.customer,
    },
    {
      headerName: props.intl.formatMessage(messages.created),
      field: 'created',
      colId: 'created',
      menuTabs: ['filterMenuTab'],
      width: 120,
      cellRendererFramework: DateCell,
      valueGetter: CreatedValueGetter,
      filter: 'agDateColumnFilter',
      filterParams: {
        filterOptions: ['lessThan', 'greaterThan', 'inRange'],
        comparator: dateComparator,
      },
    },
    {
      headerName: props.intl.formatMessage(messages.createdBy),
      width: 120,
      field: 'createdBy',
      colId: 'createdBy',
      menuTabs: ['filterMenuTab'],
      cellRendererFramework: CreatedByCell,
      valueGetter: CreatedByValueGetter,
    },
  ];
  columns.push({
    headerName: props.intl.formatMessage(messages.updated),
    width: 120,
    field: 'updated',
    colId: 'updated',
    menuTabs: ['filterMenuTab'],
    cellRendererFramework: DateCell,
    valueGetter: UpdatedValueGetter,
    filter: 'agDateColumnFilter',
    filterParams: {
      filterOptions: ['lessThan', 'greaterThan', 'inRange'],
      comparator: dateComparator,
    },
  });
  columns.push({
    headerName: props.intl.formatMessage(messages.updatedBy),
    width: 120,
    field: 'updatedBy',
    colId: 'updatedBy',
    menuTabs: ['filterMenuTab'],
    cellRendererFramework: FirstLastNameCell('updatedBy'),
    valueGetter: FirstLastNameValueGetter('updatedBy'),
  });
  if (props.hasPerm(permissionDisable)) {
    columns.push({
      headerName: props.intl.formatMessage(messages.disabled),
      width: 120,
      field: 'deleted',
      colId: 'deleted',
      suppressMenu: true,
      valueGetter: DisableValueGetter,
    });
  }
  return interceptColDef ? interceptColDef(columns, config, props) : columns;
};
