import React, { useState } from 'react';
import { ColDef } from 'ag-grid-community';
import { connect as formikConnect, FormikProps } from 'formik';
import { isEmpty } from 'lodash';
import { InjectedIntl, injectIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import Button from 'components/Button';
import ButtonWithDirtyCheck from 'components/ButtonWithDirtyCheck';
import { DeleteDialog, withDeleteDialog } from 'components/Dialog';
import AgTable from 'components/Table';
import { getToken } from 'containers/App/selectors';
import { withUrl } from 'utils/api';
import { dateCellFormater, dateComparator } from 'utils/dateTime';
import { fetchData } from 'utils/reduxApi';
import { useEffectDeepCompare } from 'utils/utils';
import { ApiForecastEventDTO } from 'types/drep-backend.d';

import EventsTableDetailCellRenderer, { validateEvent } from './EventsTableDetailCellRenderer';
import messages from './messages';
import { EntityEntry, SubCategoryEntry, SmartProdSourceForm } from './types';

const Table = styled(AgTable)`
  margin-top: 20px;
  height: ${props => props.height}px;

  .ag-full-width-row {
    overflow: visible;
  }
`;

const Wrap = styled.div`
  display: flex;
  align-items: center;
  height: 25px;

  ${Button} {
    padding-top: 5px;
    height: 25px;
  }
`;

const ActionsCellRenderer = params => {
  const event = params.formik.values.eventsTable[params.data.id];
  const hasErrors = !isEmpty(validateEvent(params.intl, event));
  return (
    <Wrap>
      {params.editable && (
        <>
          <ButtonWithDirtyCheck
            dirty={params.formik.dirty}
            className="copy-event-button"
            actionHandler={() => params.copyEventHandler(params.data)}
            message={messages.copyEventButton}
            disabled={hasErrors}
          />
          &nbsp;
          <ButtonWithDirtyCheck
            dirty={params.formik.dirty}
            className="delete-event-button"
            actionHandler={() => params.deleteEventHandler(params.data)}
            message={messages.deleteEventButton}
          />
        </>
      )}
    </Wrap>
  );
};

const columnDefs = (
  formik,
  editable: boolean,
  copyEventHandler: (data) => void,
  deleteEventHandler: (data) => void,
  intl: InjectedIntl,
) => {
  const columns: ColDef[] = [
    {
      colId: 'name',
      field: 'name',
      filter: 'setFilter',
      menuTabs: ['filterMenuTab'],
      headerName: intl.formatMessage(messages.eventsTableName),
      cellRenderer: 'agGroupCellRenderer',
      sortable: true,
      width: 200,
    },
    {
      colId: 'category',
      field: 'category',
      menuTabs: ['filterMenuTab'],
      filter: 'setFilter',
      headerName: intl.formatMessage(messages.eventsTableCategory),
      valueGetter: ({ data }) => data.category?.name,
      sortable: true,
      width: 150,
    },
    {
      colId: 'impact',
      field: 'impact',
      filter: 'setFilter',
      menuTabs: ['filterMenuTab'],
      headerName: intl.formatMessage(messages.eventsTableImpact),
      valueFormatter: ({ value }) => (!Number.isNaN(value) ? `${value} %` : ''),
      sortable: true,
      width: 150,
    },
    {
      colId: 'startDate',
      field: 'startDate',
      menuTabs: ['filterMenuTab'],
      headerName: intl.formatMessage(messages.eventsTableStartDate),
      filter: 'agDateColumnFilter',
      filterParams: {
        filterOptions: ['lessThan', 'greaterThan', 'inRange'],
        comparator: dateComparator,
      },
      sortable: true,
      valueFormatter: dateCellFormater,
    },
    {
      colId: 'endDate',
      field: 'endDate',
      menuTabs: ['filterMenuTab'],
      headerName: intl.formatMessage(messages.eventsTableEndDate),
      filter: 'agDateColumnFilter',
      filterParams: {
        filterOptions: ['lessThan', 'greaterThan', 'inRange'],
        comparator: dateComparator,
      },
      sortable: true,
      valueFormatter: dateCellFormater,
    },
    {
      width: 160,
      colId: 'actions',
      cellRendererFramework: formikConnect(ActionsCellRenderer),
      cellRendererParams: { intl, copyEventHandler, deleteEventHandler, editable, formik },
      sortable: false,
      suppressMenu: true,
    },
  ];
  return columns;
};

type Props = {
  formik?: FormikProps<SmartProdSourceForm>;
  intl: InjectedIntl;
  forecastEdit: boolean;
  eventsData: ApiForecastEventDTO[];
  reloadData: () => void;
  openedEventDetail?: number;
  setOpenedEventDetail: (id: number) => void;
  allEventCategories: EntityEntry[];
  allEventSubCategories: SubCategoryEntry[];
  allOmsCodes: EntityEntry[];
  allOrigins: EntityEntry[];
  deleteDialogOpen?: boolean;
  // eslint-disable-next-line @typescript-eslint/ban-types
  deleteDialogPayload?: Object;
  // eslint-disable-next-line @typescript-eslint/ban-types
  deleteDialogValues?: Object;
  // eslint-disable-next-line @typescript-eslint/ban-types
  deleteDialogCallback?: () => {};
  openDeleteDialog?: (cb, payload, value) => {};
  isAdmin?: any;
};

const EventsTable: React.FC<Props> = props => {
  const {
    formik,
    intl,
    forecastEdit,
    reloadData,
    eventsData,
    openedEventDetail,
    setOpenedEventDetail,
    allEventCategories,
    allEventSubCategories,
    allOmsCodes,
    allOrigins,
    openDeleteDialog,
    isAdmin
  } = props;
  const token = useSelector(getToken);
  const dispatch = useDispatch();

  const [expandedDetail, setExpandedDetail] = useState(false);
  const [gridApi, setGridApi] = useState(null);

  const onGridReady = params => {
    setGridApi(params);
    params.api.sizeColumnsToFit();
  };

  const handleRowClicked = params => {
    if (!params.node.detail && params.column.colId !== 'actions') {
      params.node.setExpanded(!params.node.expanded);
      gridApi.api.onGroupExpandedOrCollapsed();
    }
  };

  const onRowGroupToggle = params => {
    let isSomeNodeExpanded = params.node.expanded;
    if (params.node.expanded) {
      setOpenedEventDetail(params.data.id);
      gridApi.api.forEachNode(node => {
        if (node.expanded && node.id !== params.node.id) {
          node.setExpanded(false);
        }
      });
    } else {
      gridApi.api.forEachNode(node => {
        isSomeNodeExpanded = isSomeNodeExpanded || node.expanded;
      });
    }
    setExpandedDetail(isSomeNodeExpanded);
    if (!isSomeNodeExpanded) {
      setOpenedEventDetail(null);
    }
  };

  const onFirstDataRendered = params => {
    params.api.forEachNode(node => {
      if (node.data.id === openedEventDetail) {
        node.setExpanded(true);
      }
    });
  };

  const copyEventHandler = async (event: ApiForecastEventDTO) => {
    const result = await fetchData(withUrl(`/smartVolume/events/${event.id}/copy`).put().andToken(token), dispatch);
    if (result.isOk) {
      setOpenedEventDetail(result.data.id);
      reloadData();
    }
  };

  const deleteEvent = async event => {
    const result = await fetchData(withUrl(`/smartVolume/events/${event.id}`).delete().andToken(token), dispatch);
    if (result.isOk) {
      setOpenedEventDetail(undefined);
      reloadData();
    }
  };

  const deleteEventHandler = (event: ApiForecastEventDTO) => {
    openDeleteDialog(() => deleteEvent(event), undefined, undefined);
  };

  useEffectDeepCompare(() => {
    if (gridApi) {
      const colDefs = columnDefs(
        formik,
        forecastEdit,
        copyEventHandler,
        deleteEventHandler,
        intl,
      );
      gridApi.api.setColumnDefs(colDefs);
      gridApi.api.sizeColumnsToFit();
      gridApi.api.forEachNode(node => {
        node.setExpanded(node.data.id === openedEventDetail);
      });
    }
  }, [
    gridApi,
    forecastEdit,
    eventsData,
    allEventCategories,
    allEventSubCategories,
    allOmsCodes,
    allOrigins,
  ]);

  const DETAIL_ROW_HEIGHT = 520;
  const ROW_HEIGHT = 30;
  const HEIGHT = eventsData.length === 0 ?
  100 + ROW_HEIGHT * Math.min(10, eventsData.length) + (openedEventDetail ? DETAIL_ROW_HEIGHT : 0) :
  50 + ROW_HEIGHT * Math.min(10, eventsData.length) + (openedEventDetail ? DETAIL_ROW_HEIGHT : 0)
  return (
    <>
      <Table
        masterDetail
        detailCellRenderer="eventsTableDetailCellRenderer"
        detailCellRendererParams={{
          intl,
          forecastEdit,
          allEventCategories,
          allEventSubCategories,
          allOmsCodes,
          allOrigins,
          isAdmin
        }}
        frameworkComponents={{
          eventsTableDetailCellRenderer: EventsTableDetailCellRenderer,
        }}
        defaultColDef={{
          flex: 1,
        }}
        sortable
        pagination={false}
        columnDefs={columnDefs(
          formik,
          forecastEdit,
          copyEventHandler,
          deleteEventHandler,
          intl,
        )}
        height={HEIGHT}
        rowData={eventsData}
        rowHeight={ROW_HEIGHT}
        //domLayout="autoHeight"
        detailRowHeight={DETAIL_ROW_HEIGHT}
        onGridReady={onGridReady}
        onRowGroupOpened={onRowGroupToggle}
        onFirstDataRendered={onFirstDataRendered}
        isRowSelectable={() => false}
        onCellClicked={handleRowClicked}
      />
      <DeleteDialog {...props} />
    </>
  );
};

export default withDeleteDialog(injectIntl(EventsTable));
