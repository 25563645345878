import React from 'react';
import Table from 'components/Table';
import styled from 'styled-components';

export const Wrap = styled.div`
  opacity: 0;
  transition: ${props => props.theme.transitions.normal};
  ${props => props.shown && 'opacity: 1;'};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: ${props => props.theme.zIndex.modal};
  display: flex;
  align-items: flex-start;
  justify-content: center;
  overflow-y: auto;
  margin: 0 !important;
`;
export const Content = styled.div`
  margin-top: 100px;
  display: inline-block;
  z-index: 1;
  background-color: ${props => props.theme.color.white};
  padding: 20px;
  border-radius: 10px;
`;
export const Mask = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  left: 0;
  z-index: 0;
  background-color: rgba(0, 0, 0, 0.51);
`;
export const Controls = styled.div`
  display: flex;
  justify-content: flex-end;
  > * {
    margin-left: 10px;
  }
  margin-top: 10px;
`;
export const Header = styled.div`
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 10px;
`;
export const Body = styled.div``;

export const TableStyled = styled(Table)`
  height: 400px;
  width: 900px;
  visibility: ${props => props.visibility};
`;
