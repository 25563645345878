import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the LabourAvailabilityOverview state domain
 */

const selectLabourAvailabilityOverviewDomain = state => state.get('LabourAvailabilityOverview', initialState);

/**
 * Other specific selectors
 */

/**
 * Default selector used by LabourAvailabilityOverview
 */

const makeSelectLabourAvailabilityOverview = () =>
  createSelector(
    selectLabourAvailabilityOverviewDomain,
    substate => substate.toJS(),
  );

export default makeSelectLabourAvailabilityOverview;
export { selectLabourAvailabilityOverviewDomain };
