import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { injectIntl } from 'react-intl';

import messages from './messages';
import { parseDateTimeAndFormatToLocalString } from '../../utils/dateTime';
import TableControlled from '../TableControlled';
import { TABLE_DEFAULTS } from '../App/constants';

const TableStyled = styled(TableControlled)`
  height: 400px;
  width: 700px;
`;

const createdByValueGetter = params => {
  const value = params.data.createdBy;
  return `${value.firstName} ${value.lastName}`;
};

const DownloadLink = styled.div`
  cursor: pointer;
  color: darkblue;
`;

const DownloadRenderer = props => (
  <DownloadLink onClick={() => props.downloadFromHistoryAction(props.data.id, props.value)}>{props.value}</DownloadLink>
);

const columnDefs = props => [
  {
    headerName: props.intl.formatMessage(messages.fileName),
    width: 250,
    field: 'fileName',
    colId: 'fileName',
    menuTabs: ['filterMenuTab'],
    tooltipField: 'fileName',
    cellRendererFramework: DownloadRenderer,
    cellRendererParams: {
      downloadFromHistoryAction: (id, fileName) => {
        props.downloadFromHistoryAction(props.entity, id, fileName);
      },
    },
  },
  {
    headerName: props.intl.formatMessage(messages.recordsCreated),
    width: 100,
    field: 'recordsCreated',
    colId: 'recordsCreated',
    menuTabs: ['filterMenuTab'],
    headerTooltip: props.intl.formatMessage(messages.recordsCreated),
  },
  {
    headerName: props.intl.formatMessage(messages.recordsFailed),
    width: 100,
    field: 'recordsFailed',
    colId: 'recordsFailed',
    menuTabs: ['filterMenuTab'],
    headerTooltip: props.intl.formatMessage(messages.recordsFailed),
  },
  {
    headerName: props.intl.formatMessage(messages.created),
    field: 'created',
    colId: 'created',
    menuTabs: ['filterMenuTab'],
    valueFormatter: field => parseDateTimeAndFormatToLocalString(field.value),
  },
  {
    headerName: props.intl.formatMessage(messages.createdBy),
    colId: 'createdBy',
    valueGetter: createdByValueGetter,
    menuTabs: ['filterMenuTab'],
  },
  {
    headerName: props.intl.formatMessage(messages.status),
    field: 'status',
    colId: 'status',
    menuTabs: ['filterMenuTab'],
  },
  {
    headerName: props.intl.formatMessage(messages.errorMessage),
    field: 'errorMessage',
    colId: 'errorMessage',
    menuTabs: ['filterMenuTab'],
    tooltipField: 'errorMessage',
  },
];

const UploadsList = props => (
  <TableStyled
    suppressDragLeaveHidesColumns
    rowData={props.rowData || []}
    messages={messages}
    messagesPrefix="upload"
    columnDefs={columnDefs(props)}
    defaultConfig={TABLE_DEFAULTS.uploadsTableConfig}
    name={props.tableKey}
    key={props.tableKey}
    onFilterChanged={props.onFilterChanged}
    onSortChanged={props.onSortChanged}
    onGridReady={props.onGridReady}
  />
);

UploadsList.propTypes = {
  rowData: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
};

export default injectIntl(UploadsList);
