import React, { useCallback, useContext } from 'react';
import Grid from '@material-ui/core/Grid';
import { connect as formikConnect, FastField, Field, FormikProps } from 'formik';
import { FormattedMessage, InjectedIntl, injectIntl } from 'react-intl';
import styled, { Theme, ThemeContext, withTheme } from 'styled-components';

import Button from 'components/Button';
import DatePicker from 'components/DatePicker';
import DayPickerComponent from 'components/FormikDayPicker';
import { useStyles } from 'components/FormikDayPicker/styled';
import Label from 'components/Label';
import Select from 'components/StyledSelect';
import { DisplayDateTime, formatDate, parseDate } from 'utils/dateTime';
import { ApiForecastEventDTO } from 'types/drep-backend.d';

import messages from './messages';
import { EntityEntry, EventType, SubCategoryEntry } from './types';
import {SmartProdSourceForm} from "./types";
import { DateTime } from 'luxon';

const CompactButton = styled(Button)`
  margin-right: 12px;
  display: inline-block;
`;

const StyledInput = styled.input`
  width: 100%;
  height: 28px;
`;

const DetailWrapper = styled.div`
  max-width: 1440px;
  padding: 20px;
  z-index: 100;

  ${Button} {
    white-space: normal;
    max-width: 120px;
    height: auto;
  }

  div > span {
  }

  span {
    display: inline-block;
    vertical-align: middle;
    line-height: normal;
  }
`;

const CenteredLabel = styled(Label)`
  height: 100%;
  display: table;

  > span {
    display: table-cell;
    vertical-align: middle;
  }
`;

const InputError = styled.div`
  padding-top: 8px;
  padding-bottom: 8px;
  color: ${props => props.theme.color.red};
`;

const impactValidate = (intl: InjectedIntl, event: ApiForecastEventDTO, useEventNameInError = false) => {
  const value = event ? event.impact : 0;
  if (event && (Number.isNaN(value) || Number(value) < -100 || Number(value) > 1000)) {
    const error = intl.formatMessage(messages.eventsTableImpactValidation);
    return useEventNameInError
      ? intl.formatMessage(messages.eventsTableValidationErrorEventName, { event: event.name, error })
      : error;
  }
  return undefined;
};

const validateDaysOfWeek = (intl: InjectedIntl, event: ApiForecastEventDTO, useEventNameInError = false) => {
  if (event && (!event.selectedDays || !event.selectedDays.length)) {
    const error = intl.formatMessage(messages.eventsTableSelectedDaysValidation);
    return useEventNameInError
      ? intl.formatMessage(messages.eventsTableValidationErrorEventName, { event: event.name, error })
      : error;
  }
  return undefined;
};

const validateOMSCodes = (intl: InjectedIntl, event: ApiForecastEventDTO, useEventNameInError = false) => {
  if (event && (!event.omses || !event.omses.length)) {
    const error = intl.formatMessage(messages.eventsTableSelectedOMSCodeValidation);
    return useEventNameInError
      ? intl.formatMessage(messages.eventsTableValidationErrorEventName, { event: event.name, error })
      : error;
  }
  return undefined;
};

export const validateEvent = (intl: InjectedIntl, event: ApiForecastEventDTO, useEventNameInError = false) => {
  let errors = {};
  if (impactValidate(intl, event)) {
    errors = { ...errors, impact: impactValidate(intl, event, useEventNameInError) };
  }
  if (validateDaysOfWeek(intl, event)) {
    errors = { ...errors, daysOfWeek: validateDaysOfWeek(intl, event, useEventNameInError) };
  }
  if (validateOMSCodes(intl, event)) {
    errors = { ...errors, omses: validateOMSCodes(intl, event, useEventNameInError) };
  }
  return errors;
};

type Props = {
  theme: Theme;
  intl: InjectedIntl;
  formik: FormikProps<SmartProdSourceForm>;
  data: ApiForecastEventDTO;
  forecastEdit: boolean;
  allEventCategories: EntityEntry[];
  allEventSubCategories: SubCategoryEntry[];
  allOmsCodes: EntityEntry[];
  allOrigins: EntityEntry[];
  isAdmin: any;
};

const EventsTableDetailCellRenderer: React.FC<Props> = ({
  theme,
  intl,
  formik,
  data,
  forecastEdit,
  allEventCategories,
  allEventSubCategories,
  allOmsCodes,
  allOrigins,
  isAdmin
}) => {
  const themeContext: { color: { yellow: string } } = useContext(ThemeContext);
  const classes = useStyles(theme, 48)();

  const renderField = (
    labelName,
    fieldName,
    input,
    fastField = true,
    detailButton = undefined,
    labelWidth = undefined,
    inputWidth = undefined,
    detailWidth = undefined,
    validate = undefined,
  ) => (
    <>
      <Grid item xs={labelWidth || 4}>
        <CenteredLabel {...labelName} />
      </Grid>
      <Grid item xs={inputWidth || 8}>
        {fastField ? (
          <FastField name={fieldName} render={input} validate={validate} />
        ) : (
          <Field name={fieldName} render={input} validate={validate} />
        )}
      </Grid>
      {detailWidth && (
        <Grid item xs={detailWidth || 0}>
          {detailButton}
        </Grid>
      )}
    </>
  );

  const impact: number = formik.values.eventsTable?.[data.id]?.impact;
  const selectedCategoryId: number = formik.values.eventsTable?.[data.id]?.category?.id;
  const startDate: string = parseDate(formik.values.eventsTable?.[data.id]?.startDate);
  const endDate: string = parseDate(formik.values.eventsTable?.[data.id]?.endDate);

  const handleConsideredNotConsideredClick = () => {
    if (impact === -100) {
      formik.setFieldValue(`eventsTable[${data.id}].impact`, 0);
    } else {
      formik.setFieldValue(`eventsTable[${data.id}].impact`, -100);
    }
  };

  const handleAddAllOMSCodes = () => {
    formik.setFieldValue(
      `eventsTable[${data.id}].omses`,
      allOmsCodes.map(code => ({ id: code.value, name: code.label })),
    );
  };

  const handleRemoveAllOMSCodes = () => {
    formik.setFieldValue(`eventsTable[${data.id}].omses`, []);
  };

  const startDateValidation = useCallback(
    value => (value && parseDate(value) > endDate ? `Must not be more than ${formatDate(endDate)}` : undefined),
    [endDate, startDate],
  );

  const endDateValidation = useCallback(
    value => {
      if (value && parseDate(value) < startDate) {
        return `Must be at least ${formatDate(startDate)}`;
      }
      return undefined;
    },
    [endDate, startDate],
  );

  const allTypes = [
    { value: EventType.ONE_OFF, label: <FormattedMessage {...messages.eventsTableTypeOneOff} /> },
    { value: EventType.RECURRING, label: <FormattedMessage {...messages.eventsTableTypeRecurring} /> },
  ];

  return (
    <DetailWrapper>
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <Grid container spacing={1}>
            {renderField(
              messages.eventsTableName,
              `eventsTable[${data.id}].name`,
              ({ field, form }) => (
                <StyledInput
                  disabled={!forecastEdit}
                  name={field.name}
                  type="text"
                  value={field.value || ''}
                  onChange={({ target: { value } }) => form.setFieldValue(field.name, value)}
                />
              ),
              true,
            )}
            {renderField(
              messages.eventsTableDescription,
              `eventsTable[${data.id}].description`,
              ({ field, form }) => (
                <StyledInput
                  disabled={!forecastEdit}
                  name="description"
                  type="text"
                  value={field.value || ''}
                  onChange={({ target: { value } }) => form.setFieldValue(field.name, value)}
                />
              ),
              true,
            )}
            {renderField(
              messages.eventsTableCategory,
              `eventsTable[${data.id}].category`,
              ({ field, form }) => (
                <Select
                  options={allEventCategories}
                  isDisabled={!forecastEdit}
                  onChange={option => {
                    form.setFieldValue(field.name, { id: option.value, name: option.label });
                    form.setFieldValue(`eventsTable[${data.id}].subCategory`, null);
                  }}
                  value={field.value ? { value: field.value.id, label: field.value.name } : null}
                />
              ),
              true,
            )}
            {renderField(
              messages.eventsTableSubCategory,
              `eventsTable[${data.id}].subCategory`,
              ({ field, form }) => (
                <Select
                  options={allEventSubCategories.filter(
                    subCategory => subCategory.eventCategoryId === selectedCategoryId,
                  )}
                  isDisabled={!forecastEdit || !selectedCategoryId}
                  onChange={option => form.setFieldValue(field.name, { id: option.value, name: option.label })}
                  value={field.value ? { value: field.value.id, label: field.value.name } : null}
                />
              ),
              false,
            )}
            {renderField(
              messages.eventsTableOrigin,
              `eventsTable[${data.id}].origin`,
              ({ field, form }) => (
                <Select
                  options={allOrigins}
                  isDisabled={!forecastEdit}
                  onChange={option => form.setFieldValue(field.name, { id: option.value, name: option.label })}
                  value={field.value ? { value: field.value.id, label: field.value.name } : null}
                />
              ),
              true,
            )}
            {renderField(
              messages.eventsTableType,
              `eventsTable[${data.id}].type`,
              ({ field, form }) => (
                <Select
                  options={allTypes}
                  isDisabled={!forecastEdit}
                  onChange={option => form.setFieldValue(field.name, option.value)}
                  value={allTypes.find(o => o.value === field.value) || null}
                />
              ),
              true,
            )}
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Grid container spacing={1}>
            {renderField(
              messages.eventsTableImpact,
              `eventsTable[${data.id}].impact`,
              ({ field, form }) => (
                <>
                  <StyledInput
                    disabled={!forecastEdit}
                    name={field.name}
                    type="text"
                    value={forecastEdit ? field.value : `${field.value} %`}
                    onChange={({ target: { value } }) => {
                      form.setFieldValue(field.name, value);
                    }}
                  />
                  {impactValidate(intl, form.values.eventsTable[data.id]) && (
                    <InputError>{impactValidate(intl, form.values.eventsTable[data.id])}</InputError>
                  )}
                </>
              ),
              false,
              forecastEdit ? (
                <Button
                  onClick={handleConsideredNotConsideredClick}
                  color="primary"
                  autoFocus
                  variant="contained"
                  disabled={!forecastEdit}
                >
                  {impact === -100 ? (
                    <FormattedMessage {...messages.eventsTableConsideredButton} />
                  ) : (
                    <FormattedMessage {...messages.eventsTableNotConsideredButton} />
                  )}
                </Button>
              ) : (
                ''
              ),
              4,
              4,
              4,
            )}
            {renderField(
              messages.eventsTableStartDate,
              `eventsTable[${data.id}].startDate`,
              ({ field, form }) => (
                <>
                  <DatePicker
                    disabled={!forecastEdit}
                    value={field.value}
                    onAccept={value => {
                      const updateValue = value.startOf('day');
                      form.setFieldValue(field.name, updateValue);
                      if (updateValue > parseDate(formik.values.eventsTable[data.id].endDate).startOf('day')) {
                        form.setFieldValue(`eventsTable[${data.id}].endDate`, updateValue);
                      }
                    }}
                  />
                  {startDateValidation(field.value) && <InputError>{startDateValidation(field.value)}</InputError>}
                </>
              ),
              false,
              undefined,
              4,
              4,
              4,
            )}
            {renderField(
              messages.eventsTableEndDate,
              `eventsTable[${data.id}].endDate`,
              ({ field, form }) => (
                <>
                  <DatePicker
                    disabled={!forecastEdit}
                    value={field.value}
                    maxDate={isAdmin ? undefined : DateTime.fromISO(formik.values.eventsTable[data.id].startDate).plus({days:13})}
                    onAccept={value => {
                      const updateValue = value.startOf('day');
                      form.setFieldValue(field.name, updateValue);
                      if (updateValue < parseDate(formik.values.eventsTable[data.id].startDate).startOf('day')) {
                        form.setFieldValue(`eventsTable[${data.id}].startDate`, updateValue);
                      }
                    }}
                  />
                  {endDateValidation(field.value) && <InputError>{endDateValidation(field.value)}</InputError>}
                </>
              ),
              false,
              undefined,
              4,
              4,
              4,
            )}
            {renderField(
              messages.eventsTableDaysOfWeek,
              `eventsTable[${data.id}].selectedDays`,
              ({ field, form }) => (
                <>
                  <DayPickerComponent
                    name={field.name}
                    isMulti
                    setFieldValue={(name, value) => form.setFieldValue(field.name, value)}
                    editable={forecastEdit}
                    value={field.value || []}
                  />
                  {validateDaysOfWeek(intl, form.values.eventsTable[data.id]) && (
                    <InputError>{validateDaysOfWeek(intl, form.values.eventsTable[data.id])}</InputError>
                  )}
                </>
              ),
              true,
            )}
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Grid container spacing={1}>
            {renderField(messages.eventsTableCreated, `eventsTable[${data.id}].created`, () => (
              <DisplayDateTime value={data.created} />
            ))}
            {renderField(messages.eventsTableCreatedBy, `eventsTable[${data.id}].createdBy`, () =>
              data.createdBy ? `${data.createdBy.firstName} ${data.createdBy.lastName}` : '',
            )}
          </Grid>
        </Grid>
        <Grid item xs={8}>
          <Grid container spacing={1}>
            {renderField(
              messages.eventsTableOMSCodes,
              `eventsTable[${data.id}].omses`,
              ({ field, form }) =>
                forecastEdit ? (
                  <>
                    <Select
                      name={field.name}
                      classNamePrefix="omsCodes"
                      isMulti
                      onChange={options => {
                        form.setFieldValue(
                          field.name,
                          options
                            ? options.map(option => ({
                                id: option.value,
                                name: option.label,
                              }))
                            : [],
                        );
                      }}
                      editable={forecastEdit}
                      isDisabled={!forecastEdit}
                      value={allOmsCodes.filter(option => field.value?.find(f => f.id === option.value))}
                      options={allOmsCodes}
                      isClearable={false}
                      closeMenuOnSelect={false}
                      styles={{
                        menuList: base => ({
                          ...base,
                          maxHeight: 150,
                        }),
                        multiValue: base => ({
                          ...base,
                          color: 'black',
                          backgroundColor: themeContext?.color?.yellow,
                        }),
                      }}
                    />
                    {validateOMSCodes(intl, form.values.eventsTable[data.id]) && (
                      <InputError>{validateOMSCodes(intl, form.values.eventsTable[data.id])}</InputError>
                    )}
                  </>
                ) : (
                  allOmsCodes
                    .filter(option => field.value?.find(f => f.id === option.value))
                    .map(item => (
                      <div className={classes.values} key={item.value}>
                        {item.label}
                      </div>
                    ))
                ),
              true,
              forecastEdit ? (
                <>
                  <CompactButton
                    onClick={handleAddAllOMSCodes}
                    color="primary"
                    autoFocus
                    variant="contained"
                    disabled={!forecastEdit}
                  >
                    <FormattedMessage {...messages.eventsTableAddAllOMSCodes} />
                  </CompactButton>
                  <CompactButton
                    onClick={handleRemoveAllOMSCodes}
                    color="primary"
                    autoFocus
                    variant="contained"
                    disabled={!forecastEdit}
                  >
                    <FormattedMessage {...messages.eventsTableRemoveAllOMSCodes} />
                  </CompactButton>
                </>
              ) : (
                ''
              ),
              2,
              6,
              4,
            )}
          </Grid>
        </Grid>
      </Grid>
    </DetailWrapper>
  );
};

export default formikConnect(withTheme(injectIntl(EventsTableDetailCellRenderer)));
