/**
 *
 * DayPicker
 *
 */
import React from 'react';
import PropTypes from 'prop-types';
import { connect, getIn } from 'formik';
import { injectIntl } from 'react-intl';
import { components } from 'react-select';
import { withTheme } from 'styled-components';

import Select from 'components/StyledSelect';
import { DAY_NAMES, getWeekdays } from 'utils/calendar';

import { useStyles } from './styled';

const customStyles = theme => ({
  multiValue: styles => ({
    ...styles,
    color: 'black',
    backgroundColor: theme.color.yellow,
  }),
});

const MultiValue = props => <components.MultiValue {...props}>{props.data.shortLabel}</components.MultiValue>;

const DayPicker = props => {
  const {
    editable = true,
    value,
    name,
    setFieldValue,
    formik,
    height,
    intl: { formatMessage },
    theme,
  } = props;

  const weekDays = getWeekdays(formatMessage);
  const shortWeekDays = getWeekdays(formatMessage, null, true);

  const theValue = formik ? getIn(formik.values, name) : value;
  const classes = useStyles(theme, height)();
  if (!editable) {
    return Array.isArray(theValue) ? (
      <div>
        {theValue.map(item => (
          <div className={classes.values} key={item}>
            {shortWeekDays[item]}
          </div>
        ))}
      </div>
    ) : (
      <div className={classes.value}>{shortWeekDays[theValue]}</div>
    );
  }

  const options = DAY_NAMES.map(day => ({
    label: weekDays[day],
    shortLabel: shortWeekDays[day],
    value: day,
  }));

  let selectValue = [];
  if (theValue) {
    const transformedValue = Array.isArray(theValue) ? theValue.slice() : [theValue];
    selectValue = options.filter(option => transformedValue.includes(option.value));
  }

  const onChangeFunc = setFieldValue || (formik && formik.setFieldValue);
  const setFieldValueHandler = newValue => {
    if (onChangeFunc) {
      onChangeFunc(name, (newValue && newValue.slice().map(val => val.value)) || []);
    }
  };

  if (!theValue) return <div />;

  return (
    <Select
      style={{ minWidth: '160px' }}
      closeMenuOnSelect={false}
      defaultValue={selectValue}
      isMulti
      isClearable={false}
      options={options}
      onChange={setFieldValueHandler}
      styles={customStyles(theme)}
      components={{ MultiValue }}
    />
  );
};

DayPicker.propTypes = {
  editable: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  name: PropTypes.string,
  setFieldValue: PropTypes.func,
  formik: PropTypes.object,
  height: PropTypes.number,
  theme: PropTypes.object,
};

const DayPickerComponent = withTheme(injectIntl(DayPicker));
export default DayPickerComponent;

export const FormikDayPicker = connect(DayPickerComponent);
