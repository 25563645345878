/*
 * PlanDetailToolbar Messages
 *
 * This contains all the text for the PlanDetailToolbar component.
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  saveTableConfig: {
    id: 'app.components.PlanDetailToolbar.saveTableConfig',
    defaultMessage: 'Save Current To User View',
  },
  result: {
    id: 'app.components.PlanDetailToolbar.result',
    defaultMessage: 'Result',
  },
  copy: {
    id: 'app.components.PlanDetailToolbar.copy',
    defaultMessage: 'Copy',
  },
  history: {
    id: 'app.components.PlanDetailToolbar.history',
    defaultMessage: 'History',
  },
  display: {
    id: 'app.components.PlanDetailToolbar.display',
    defaultMessage: 'Display',
  },
  edit: {
    id: 'app.components.PlanDetailToolbar.edit',
    defaultMessage: 'Edit',
  },
  back: {
    id: 'app.components.PlanDetailToolbar.back',
    defaultMessage: 'Back',
  },
  save: {
    id: 'app.components.PlanDetailToolbar.save',
    defaultMessage: 'Save',
  },
  paIsDeleted: {
    id: 'app.components.PlanDetailToolbar.paIsDeleted',
    defaultMessage: 'Planning Area is disabled.',
  },
  upload: {
    id: 'app.components.PlanDetailToolbar.upload',
    defaultMessage: 'Upload',
  },
  interfaces: {
    id: 'app.components.PlanDetailToolbar.interfaces',
    defaultMessage: 'Interfaces',
  },
  forecast: {
    id: 'app.components.PlanDetailToolbar.forecast',
    defaultMessage: 'Forecast',
  },
  viewMode: {
    id: 'app.components.PlanDetailToolbar.viewMode',
    defaultMessage: 'View Mode',
  },
  interfacesTooltipText: {
    id: 'app.components.PlanningAreaDetailToolbar.interfacesTooltipText',
    defaultMessage: 'On this page you can configure connection between this plan and SmartProductivity',
  },
  forecastTooltipText: {
    id: 'app.components.PlanningAreaDetailToolbar.forecastTooltipText',
    defaultMessage:
      'Before you start using forecasting you must configure interfaces. On this page you can view forecast received from SmartVolume or create forecast on your own using SmartProductivity data.',
  },
  masterPlan: {
    id: 'app.components.PlanDetailToolbar.masterPlan',
    defaultMessage: 'Create/Update Master Plan',
  },
  createSchedule: {
    id: 'app.components.PlanDetailToolbar.createSchedule',
    defaultMessage: 'Create Schedule',
  },
  editLoader: {
    id: 'app.components.PlanDetailToolbar.editLoader',
    defaultMessage: 'Please wait until we fetch data',
  }

});
