import { createSelector } from 'reselect';

import { initialState } from './reducer';

const page = state => state.get('shiftScheduleRunsPage', initialState);
const detailPage = state => state.get('shiftScheduleDetailPage', initialState);
const selectUserDomain = state => state.get('user');

export const selectShiftScheduleRuns = createSelector(page, substate => substate.get('shiftScheduleRuns'));
export const selectPullingChanges = createSelector(page, substate => substate.get('changesPullingUpdates'));
export const selectShiftScheduleName = createSelector(page, substate => substate.get('shiftScheduleName'));
export const selectLoadedRunsScheduleId = createSelector(page, substate => substate.get('shiftScheduleId'));
export const selectShiftSchedule = createSelector(detailPage, substate => substate.get('shiftSchedule'));
export const selectUserSimple = createSelector(selectUserDomain, substate => substate.toJS());
export const selectErrorDescriptions = createSelector(page, substate => substate.get('errorDescriptions'));
export const selectScheduleStaffSyncState = createSelector(page, substate => substate.get('scheduleStaffSyncState'));
