/*
 *
 * UploadModal constants
 *
 */

export const OPEN_MODAL = 'app/UploadModal/OPEN_MODAL';
export const CLOSE_MODAL = 'app/UploadModal/CLOSE_MODAL';
export const FETCH_UPLOADS = 'app/UploadModal/FETCH_UPLOADS';
export const STORE_UPLOADS = 'app/UploadModal/STORE_UPLOADS';
export const EXPAND_HISTORY = 'app/UploadModal/EXPAND_HISTORY';
export const COLLAPSE_HISTORY = 'app/UploadModal/COLLAPSE_HISTORY';
export const EXPAND_EXPORT = 'app/UploadModal/EXPAND_EXPORT';
export const COLLAPSE_EXPORT = 'app/UploadModal/COLLAPSE_EXPORT';
export const UPLOAD_FILE = 'app/UploadModal/UPLOAD_FILE';
export const STORE_UPLOAD_INFO = 'app/UploadModal/STORE_UPLOAD_INFO';
export const EXPORT_FILE = 'app/UploadModal/EXPORT_FILE';
export const DOWNLOAD_FROM_HISTORY = 'app/UploadModal/DOWNLOAD_FROM_HISTORY';
export const UPLOAD_MODAL_ADMIN = 'app/UploadModal/UPLOAD_MODAL_ADMIN';
export const CLOSE_MODAL_ADMIN = 'app/UploadModal/CLOSE_MODAL_ADMIN';
export const UPLOAD_FILE_ADMIN = 'app/UploadModal/UPLOAD_FILE_ADMIN';

const DETAIL_EXPORTS = [
  {
    code: 'BASIC',
    message: 'basicSettings',
    default: true,
  },
  {
    code: 'VOL',
    message: 'volumeCategory',
    default: true,
  },
  {
    code: 'ACT',
    message: 'activitySettings',
    default: true,
  },
  {
    code: 'AVAS',
    message: 'availabilityShifts',
    default: true,
  },
  {
    code: 'WEEKLYPRODR',
    message: 'weeklyUPH',
    default: true,
  },
  {
    code: 'DAILYPRODR',
    message: 'dailyUPH',
    default: false,
  },
];

export const EXPORT_SECTIONS = {
  results: [
    {
      code: 'OVA',
      message: 'overridesActuals',
      default: true,
    },
  ],
  plans: DETAIL_EXPORTS,
  planningAreas: DETAIL_EXPORTS,
  schedules: [],
};
