import * as React from 'react';
import { useEffect, useState } from 'react';
import { FormikProps } from 'formik';
import cloneDeep from 'lodash/cloneDeep';
import { FormattedMessage, injectIntl } from 'react-intl';
import styled from 'styled-components';

import Button from 'components/Button';
import ButtonWithDirtyCheck from 'components/ButtonWithDirtyCheck';
import OverwriteConfirmDialog from 'components/EffortForecast/OverwriteConfirmDialog';
import Select from 'components/StyledSelect';
import ModifyMultipleDialog from 'containers/ModifyMultipleDialog';
import ModifyMultipleDialog_PR from 'containers/ModifyMultipleDialog/ModifyMultipleDialog_PR';
import {
  ModifyMultipleChangeType,
  ModifyMultipleDataSource,
  ModifyMultipleDialogType,
  ModifyMultipleFormSettings,
} from 'containers/ModifyMultipleDialog/types';
import { formatDateToApiFormat } from 'utils/api';
import { DAY_NAMES } from 'utils/calendar/constants';
import { generateDays, parseDate } from 'utils/dateTime';
import withSecurity, { PERMISSIONS } from 'utils/security';

import messages from './messages';
import { ProductivityRateTable } from './ProductivityRateTable';
import { ProductivityRateForecastPlanSwitch } from './types';

export interface ProductivityRateProps {
  isEdit: boolean;
  intl: any;
  formik: FormikProps<any>;
  hasPerm: Function;
  save: Function;
}

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 5px 0;
  & > * {
    margin: 0 5px;
  }
`;

const StyledSelect = styled(Select)`
  width: 200px;
`;

export const ProductivityRate = injectIntl(
  withSecurity()((props: ProductivityRateProps) => {
    const { isEdit } = props;
    const storedGranularity =
      props.formik.initialValues?.planningParameters?.productivityRateGranularity?.toLowerCase() || 'day';
    const [forecastPlanSwitch, setForecastPlanSwitch] = useState<ProductivityRateForecastPlanSwitch>('plan');
    const [granularity, setGranularity] = React.useState(storedGranularity);
    const [modifyMultipleOpen, setModifyMultipleOpen] = useState<boolean>(false);
    const [overwriteConfirmDialogVisible, setOverwriteConfirmDialogVisible] = useState<boolean>(false);
    const isForecastEditor = props.hasPerm(PERMISSIONS.VIEW_FORECAST_SECTION);
    const isWeekGranularity = storedGranularity.toUpperCase() === 'WEEK';
    const sortedDays = [...props.formik.values.planningParameters.activityForecastTable.days].sort();
    const modifyMultipleStartDate = parseDate(
      sortedDays.length ? sortedDays[0] : props.formik.values.planningParameters.startDay,
    );
    const modifyMultipleEndDate = parseDate(
      props.formik.values.planningParameters.endDay,
    );

    const submitGranularity = async ({ waitForSaveAll }) => {
      if (waitForSaveAll) {
        await props.formik.submitForm();
      }
      props.save({
        planningParameters: {
          ...props.formik.initialValues?.planningParameters,
          productivityRateGranularity: granularity.toUpperCase(),
        },
      });
    };

    useEffect(() => {
      if (isWeekGranularity) {
        setForecastPlanSwitch('plan');
      }
    }, [granularity]);

    const handleTogglePlanForecast = () => {
      setForecastPlanSwitch(forecastPlanSwitch === 'forecast' ? 'plan' : 'forecast');
    };

    const handleBackToForecast = () => {
      const { rowData, days } = props.formik.values.planningParameters.activityForecastTable;
      rowData.forEach(row => {
        days.forEach(day => {
          if(row && row[day]){
          props.formik.setFieldValue(
            `planningParameters.activityForecastTable.rowData.${row.index}.${day}.overrideHours`,
            null,
          );
        }
        });
      });
      setOverwriteConfirmDialogVisible(false);
    };

    const handleModifyMultiple = () => {
      setModifyMultipleOpen(true);
    };

    const handleCloseModifyMultiple = () => {
      setModifyMultipleOpen(false);
    };

    const getDatesStoredInFormik = () => {
      const { days } = props.formik.values.planningParameters.activityForecastTable;
      return new Set<string>(days);
    };

    const getDatesFromSettingsThatDoNotExistInData = (settings: ModifyMultipleFormSettings) => {
      const dayGeneratorGranularity = storedGranularity.toUpperCase() === 'WEEK' ? 'week' : 'day';
      const datesSet = new Set<string>(
        generateDays(
          parseDate(settings.startDate),
          parseDate(settings.endDate),
          dayGeneratorGranularity,
          props.formik.initialValues.planningParameters.firstDayOfWeek,
        ).map(luxonDate => formatDateToApiFormat(luxonDate)),
      );
      const existingDates = getDatesStoredInFormik();
      return [...datesSet].filter(d => !existingDates.has(d));
    };

    const handleMultipleEditValueSubmit = (settings: ModifyMultipleFormSettings) => {
      // add virtual rows, if the start/end dates are out of range
      const defaultValueDataSource = storedGranularity.toUpperCase() === 'WEEK' ? 'WEEK_OVERRIDE' : 'DAY_OVERRIDE';

      if (storedGranularity.toUpperCase() === 'WEEK') {
        const dayOfWeekAsNumber = DAY_NAMES.indexOf(props.formik.initialValues.planningParameters.firstDayOfWeek);
        let startDate = parseDate(settings.startDate);
        while (startDate.weekday !== dayOfWeekAsNumber + 1) {
          startDate = startDate.minus({ days: 1 });
        }
        // eslint-disable-next-line no-param-reassign
        settings.startDate = startDate;
      }

      const datesThatHaveToBeCreated = getDatesFromSettingsThatDoNotExistInData(settings);
      const update = cloneDeep(props.formik.values.planningParameters.activityForecastTable);

      let affectedDays = update.days;
      if (datesThatHaveToBeCreated.length) {
        affectedDays = [...update.days, ...datesThatHaveToBeCreated];
        update.rowData = update.rowData.map(row => {
          const modified = { ...row };
          datesThatHaveToBeCreated.forEach(date => {
            modified[date] = {
              actualHours: null,
              dataSource: defaultValueDataSource,
              forecastDate: date,
              overrideHours: null,
              targetHours: null,
            };
          });
          return modified;
        });
      }

      const { rowData } = update;
      rowData.forEach(row => {
        affectedDays.forEach(day => {
          const date = parseDate(day);
          if (
            parseDate(settings.startDate).ts <= date.ts &&
            date.ts <= parseDate(settings.endDate).ts &&
            settings.selectedData[row.wzpId ? `${row.id}_${row.wzpId}` : row.id]
          ) {
            const dayRow = row[day];
            if (settings.changeType === ModifyMultipleChangeType.PERCENTAGE) {
              const percentageUpdate = parseFloat(settings.percentageInput);
              let valueBefore;
              if (dayRow?.overrideHours !== null && dayRow?.overrideHours !== undefined) {
                valueBefore = dayRow.overrideHours;
              } else if (dayRow?.dataSource === 'DAY_ACTUAL' || dayRow?.dataSource === 'DAY_DEFAULT') {
                valueBefore = dayRow?.actualHours;
              } else {
                valueBefore = dayRow?.targetHours;
              }
              if (valueBefore !== undefined && valueBefore !== null) {
                dayRow.overrideHours = valueBefore + valueBefore * (percentageUpdate / 100.0);
              }
            }
            if (settings.changeType === ModifyMultipleChangeType.VALUE) {
              const valueUpdate = parseFloat(settings.valueInput);
              if(dayRow !== undefined ){
                dayRow.overrideHours = valueUpdate;
              }
            }
            if (settings.changeType === ModifyMultipleChangeType.DATA_SOURCE && dayRow) {
              dayRow.dataSource = settings.dataTypeChangeProductivityRate;
              dayRow.overrideHours = null;
            }
          }
        });
      });
      props.formik.setFieldValue('planningParameters.activityForecastTable', update);
    };

    const activities =
      props.formik.values.planningParameters?.activityForecastTable?.rowData?.filter(row => !row.indirect && !row.ForecastOutOfPlanDateInterval) || [];
    const filteredActivitiesData = activities?.filter((value, index, self) =>
      self.findIndex(v => v.wzpId === value.wzpId) === index
    );
    const dayTransformationType = props.formik.values.planningParameters?.dayTransformationType;
    return (
      <div>
        <Row>
          <FormattedMessage {...messages.granularity} />
          {isEdit ? (
            <StyledSelect
              classNamePrefix="react-select"
              className="react-select-container"
              isClearable={false}
              options={[{ value: 'week' }, { value: 'day' }]}
              getOptionLabel={val => <FormattedMessage {...messages[val.value]} />}
              onChange={val => setGranularity(val.value)}
              value={{ value: granularity }}
            />
          ) : (
            <FormattedMessage {...messages[granularity]} />
          )}
          {isEdit && (
            <ButtonWithDirtyCheck
              actionHandler={submitGranularity}
              message={messages.confirm}
              disabled={storedGranularity === granularity}
            />
          )}
        </Row>
        <Row>
          {isForecastEditor && !isWeekGranularity && (
            <Button onClick={handleTogglePlanForecast}>
              {forecastPlanSwitch === 'plan' && <FormattedMessage {...messages.forecast} />}
              {forecastPlanSwitch === 'forecast' && <FormattedMessage {...messages.plan} />}
            </Button>
          )}
          {isEdit && (
            <>
              {isForecastEditor && !isWeekGranularity && (
                <Button
                  onClick={() => setOverwriteConfirmDialogVisible(true)}
                  disabled={forecastPlanSwitch !== 'plan' || !activities.length}
                >
                  <FormattedMessage {...messages.backToForecast} />
                </Button>
              )}
              <Button onClick={handleModifyMultiple} disabled={forecastPlanSwitch !== 'plan' || !activities.length}>
                <FormattedMessage {...messages.modifyMultiple} />
              </Button>
            </>
          )}
        </Row>
        <div>
          <ProductivityRateTable
            granularity={storedGranularity}
            isEdit={isEdit}
            planningParameters={props.formik.values.planningParameters}
            forecastPlanSwitch={forecastPlanSwitch}
            lookups={props.formik.values.lookups}
            intl={props.intl}
            setFieldValue={props.formik.setFieldValue}
            dayTransformationType={dayTransformationType}
          />
          <ModifyMultipleDialog_PR
            dialogType={ModifyMultipleDialogType.PRODUCTIVITY_RATE}
            open={modifyMultipleOpen}
            closeHandler={handleCloseModifyMultiple}
            startDate={modifyMultipleStartDate}
            endDate={modifyMultipleEndDate}
            selectedData={activities.map(row => ({
              label: row.activity,
              value: row.wzpId ? `${row.id}_${row.wzpId}` : row.id,
              wzpId: row.wzpId,
            }))}
            isForecastEditor={isForecastEditor}
            dataSourceLabel="Activities"
            dataSourceSwitch={ModifyMultipleDataSource.ACTIVITY}
            isWeeklyProductivityRate={storedGranularity === 'week'}
            editValueSubmit={handleMultipleEditValueSubmit}
            filteredActivitiesData={filteredActivitiesData}
          />
          <OverwriteConfirmDialog
            visible={overwriteConfirmDialogVisible}
            onCancel={() => setOverwriteConfirmDialogVisible(false)}
            onConfirm={handleBackToForecast}
          />
        </div>
      </div>
    );
  }),
);
