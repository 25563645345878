import React from 'react';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import ReactDOM from 'react-dom';

import HelpPoint from 'components/HelpPoint';

const TooltipRoot = document.getElementById('tooltip');

class TableTooltip extends React.Component {
  constructor(props) {
    super(props);
    this.el = document.createElement('div');
  }

  componentDidMount() {
    TooltipRoot.appendChild(this.el);
  }

  componentWillUnmount() {
    TooltipRoot.removeChild(this.el);
  }

  render() {
    return ReactDOM.createPortal(this.props.children, this.el);
  }
}

const FormulaRenderer = params => {
  if (!params.data) return <span />;
  const {
    data: { err, comment, id, formulaArray },
    commentTitle,
    isDayOfWeekTransformationActivity,
  } = params;

  // Hide formula if effort forecast is used
  if (params.data.isEffortForecast && !isDayOfWeekTransformationActivity) {
    return <span />;
  }

  return (
    <div>
      {(formulaArray && (
        <span style={{ color: err ? 'red' : 'black' }}>
          {formulaArray.map((v, index) => {
            if (typeof v !== 'object') {
              return (
                <span style={{ paddingLeft: '5px' }} key={index}>
                  {v}
                </span>
              );
            }
            return (
              <Tooltip place="bottom" title={v.tooltip} key={`o_${index}`}>
                <span style={{ paddingLeft: '5px' }}>{v.text}</span>
              </Tooltip>
            );
          })}
        </span>
      )) || <span style={{ color: err ? 'red' : 'black' }}>{params.value}</span>}
      {comment && (
        <Tooltip
          place="bottom"
          id={`activityComment.${id}`}
          title={
            <div>
              <b>{commentTitle}</b>
              <p>{comment}</p>
            </div>
          }
        >
          <span>
            <HelpPoint dataFor={`activityComment.${id}`} />
          </span>
        </Tooltip>
      )}
    </div>
  );
};

export default FormulaRenderer;
