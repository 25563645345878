import React from 'react';
import { Field, Formik } from 'formik';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import ButtonWithDirtyCheck from 'components/ButtonWithDirtyCheck';
import Label from 'components/Label';
import Select from 'components/StyledSelect';
import { getToken } from 'containers/App/selectors';
import { withUrl } from 'utils/api';
import { fetchData } from 'utils/reduxApi';
import { ApiPlanDTO, ApiPlanningAreaDTO, ApiSmartProdSourceDTO } from 'types/drep-backend.d';

import messages from './messages';
import { EntityEntry, FacilityWarehouseEntityRelations } from './types';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;

  > * {
    margin-right: 30px;
    display: flex;
  }

  padding-bottom: 5px;
`;

const StyledSelect = styled(Select)`
  width: 12em;
  margin-top: 5px;
  margin-left: 12px;
`;

type AddFormType = {
  smartProdInstanceId?: number;
  facilityId?: number;
  smartProdWarehouseId?: number;
}

export const initialValues: AddFormType = {
  smartProdInstanceId: null,
  facilityId: null,
  smartProdWarehouseId: null,
};

type Props = {
  dirty: boolean;
  plan: ApiPlanDTO | ApiPlanningAreaDTO;
  allSmartProdInstances: EntityEntry[];
  addEntryFacilities: EntityEntry[];
  allWHIDs: EntityEntry[];
  entityRelations: FacilityWarehouseEntityRelations;
  fetchDetails: () => void;
};

const SmartVolumeAddAssociationForm: React.FC<Props> = ({
  dirty,
  plan,
  allSmartProdInstances,
  addEntryFacilities,
  allWHIDs,
  entityRelations,
  fetchDetails,
}) => {
  const token = useSelector(getToken);
  const dispatch = useDispatch();

  const isFormValid = (values: AddFormType): boolean => {
    const smartProdSource: ApiSmartProdSourceDTO =
      entityRelations[values.facilityId]?.[values.smartProdInstanceId]?.[values.smartProdWarehouseId];
    return !!(
      values.smartProdInstanceId &&
      values.facilityId &&
      values.smartProdWarehouseId
    );
  };

  const handleSubmit = async (formValues: AddFormType, actions) => {
    const result = await fetchData(
      withUrl(`/masterPlan/${plan.id}/smartProdSource`)
        .put({
          "smartProdInstance":{"id":formValues.smartProdInstanceId},
          "smartProdWarehouse":{"id":formValues.smartProdWarehouseId,"facilityId":formValues.facilityId}
        })
        .andToken(token),
      dispatch,
    );
    if (result.isOk) {
      fetchDetails();
      actions.resetForm(initialValues);
    }
    actions.setSubmitting(false);
  };

  return (
    <Formik
      initialValues={initialValues}
      isInitialValid
      enableReinitialize
      onSubmit={handleSubmit}
      render={formikBag => (
        <>
          <Wrapper id="smartVolumeAddAssociationForm">
            <div>
              <Label {...messages.smartVolumeFacility} />
              <Field
                name="facilityId"
                render={({ field, form }) => (
                  <StyledSelect
                    id="smartVolumeAddAssociationForm-facilityId"
                    options={addEntryFacilities}
                    onChange={option => {
                      form.setFieldValue(field.name, option.value);
                      form.setFieldValue(`smartProdInstanceId`, null);
                      form.setFieldValue('smartProdWarehouseId', null);
                    }}
                    value={addEntryFacilities.find(o => o.value === field.value) || null}
                  />
                )}
              />
            </div>
            <div>
              <Label {...messages.smartVolumeSmartProdInstance} />
              <Field
                name="smartProdInstanceId"
                render={({ field, form }) => (
                  <StyledSelect
                    id="smartVolumeAddAssociationForm-smartProdInstanceId"
                    options={allSmartProdInstances.filter(
                      option => entityRelations[formikBag.values.facilityId]?.[option.value],
                    )}
                    isDisabled={!formikBag.values.facilityId}
                    onChange={option => {
                      form.setFieldValue(field.name, option.value);
                      form.setFieldValue('smartProdWarehouseId', null);
                    }}
                    value={allSmartProdInstances.find(o => o.value === field.value) || null}
                  />
                )}
              />
            </div>
            <div>
              <Label {...messages.smartVolumeWHID} />
              <Field
                name="smartProdWarehouseId"
                render={({ field, form }) => (
                  <StyledSelect
                    id="smartVolumeAddAssociationForm-smartProdWarehouseId"
                    options={allWHIDs.filter(
                      option =>
                        entityRelations[formikBag.values.facilityId]?.[formikBag.values.smartProdInstanceId]?.[
                          option.value
                        ],
                    )}
                    isDisabled={!formikBag.values.smartProdInstanceId}
                    onChange={option => {
                      form.setFieldValue(field.name, option.value);
                    }}
                    value={allWHIDs.find(o => o.value === field.value) || null}
                  />
                )}
              />
            </div>
            <div>
              <ButtonWithDirtyCheck
                id="smartVolumeAddAssociationForm-addButton"
                name="add"
                type="submit"
                dirty={dirty}
                message={messages.addSmartProdAsociation}
                disabled={formikBag.isSubmitting || !isFormValid(formikBag.values)}
                actionHandler={() => formikBag.handleSubmit()}
              >
                <FormattedMessage {...messages.addSmartProdAsociation} />
              </ButtonWithDirtyCheck>
            </div>
          </Wrapper>
        </>
      )}
    />
  );
};

export default SmartVolumeAddAssociationForm;
