/**
 *
 * PlanDetailBasicInfo
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { FastField, Field, getIn } from 'formik';
import get from 'lodash/get';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';
import { Link } from "@material-ui/core";

import { FormikCheckbox } from 'components/Menu/ItemSelectable';
import TimePicker from 'components/TimePicker';
import { parserPlanResult, searchToData } from 'utils/url';
import { PATHS } from 'containers/App/constants';
import { makeSelectToken } from 'containers/LoginPage/selectors';
import { selectGuiState, selectPlan, selectPlanDetailMasterPlan, selectPlanDetailSchedule } from 'containers/PlanDetailPage/selectors';
import { selectGuiStateForPa, selectPlanningAreaFromPa } from 'containers/PlanningAreaDetailPage/selectors';
import calendarMessages from 'utils/calendar/messages';

import AutocompleteSelect, { AutocompleteFormik } from '../AutocompleteSelect';
import Lb from '../Label';
import messages from './messages';

export const Wrapper = styled.div`
  display: grid;
  grid-row-gap: ${props => props.theme.grid.rowGap};
  align-items: center;
  grid-template-columns: 160px auto;
  input {
    background-color: ${props => props.theme.color.grey5};
  }
`;

const Label = styled(Lb)`
  height: ${props => props.theme.grid.rowHeight};
  align-items: center;
  display: flex;
`;
const ReadOnly = styled.div`
  text-transform: capitalize;
`;

const PATHS_BASIC_SETTINGS = pa => ({
  country: pa ? [pa, 'country'] : ['country'],
  countryName: pa ? [pa, 'country', 'name'] : ['country', 'name'],
  regionName: pa ? [pa, 'region', 'name'] : ['region', 'name'],
  sectorName: pa ? [pa, 'sector', 'name'] : ['sector', 'name'],
  sector: pa ? [pa, 'sector'] : ['sector'],
});

class DetailEntityBasicInfo extends React.PureComponent {
  static getDerivedStateFromProps(nextProps, oldState) {
    const isDisplay = !nextProps.guiState.get('edit');
    const { entity } = nextProps;
    const pa = nextProps.paPath ? getIn(entity, [nextProps.paPath]) : entity;
    if (!pa || (oldState && pa.id === oldState.id && !isDisplay && pa.reloadCount === oldState.reloadCount)) {
      return (
        oldState || {
          country: {},
          region: {},
        }
      );
    }
    const newState = {
      country: pa.country,
      region: pa.country ? { name: pa.country.regionName, id: pa.country.regionId } : null,
      id: pa.id,
      reloadCount: pa.reloadCount,
    };
    return newState;
  }

  state = {};

  render() {
    return <Wrapper>{this.renderContent()}</Wrapper>;
  }

  renderContent() {
    const { entity, paPath, planDetailMasterPlans, planDetailSchedules } = this.props;
    const editable = this.props.guiState.get('edit');
    const paths = PATHS_BASIC_SETTINGS(paPath);
    const pp = entity && entity.planningParameters;
    const pa = entity && entity.planningArea;
    let weekStart = getIn(pp, 'firstDayOfWeek');
    weekStart = (weekStart && weekStart.toLowerCase()) || 'monday';
    const firstHourOfDay = getIn(pp, 'firstHourOfDay');
    const firstHourOfDayStr =
      (firstHourOfDay && typeof firstHourOfDay === 'object' && firstHourOfDay.toFormat('h:mm')) || firstHourOfDay;
    const regionName = this.state.region ? this.state.region.name : null;
    const goToPADetail = (pa) => {
      const searchObject = this.props?.history?.location?.search && searchToData(history?.location?.search, parserPlanResult);
      const path = searchObject.masterPlanId ? `${PATHS.masterplanplanningAreaDetail.replace(':id', `${pa?.id}`)}?isEdit=true&masterPlanId=${searchObject.masterPlanId}` : `${PATHS.planningAreaDetail.replace(':id', `${pa.id}`)}`;
      this.props.history.push(path);
    };
    return (
      <>
        <Label {...messages.name} />
        {this.renderValue('name')}

        <Label {...messages.description} />
        {this.renderValue('description')}

        <Label {...this.props.paMessage} />
        { 
          paPath ?
          <div><Link style={{cursor:'pointer'}} onClick={() => goToPADetail(pa)}>{pa?.name}</Link></div> :
          <div>{(entity && entity.parentName) || (pa && pa.name)}</div>
        }

        <Label {...messages.weekStartDay} />
        <ReadOnly>{weekStart && <FormattedMessage {...calendarMessages[weekStart]} />}</ReadOnly>

        <Label {...messages.region} />
        <div>
          {this.props.paEditable && editable ? (
            <AutocompleteSelect
              entity="regions"
              value={this.state.region}
              setValue={value => {
                this.setState({ country: null, region: value });
              }}
              disabled={!editable}
              token={this.props.token}
              isClearable
            />
          ) : (
            regionName
          )}
        </div>

        <Label {...messages.country} />
        <div>
          {this.props.paEditable && editable ? (
            <Field
              name={paths.country}
              render={fieldProps => (
                <AutocompleteSelect
                  key={`${!editable})_${this.state.region ? this.state.region.id : ''}`}
                  entity="countries"
                  value={this.state.country}
                  urlAppendixCallback={() => (this.state.region ? `&regionId=${this.state.region.id}` : false)}
                  setValue={value => {
                    fieldProps.form.setFieldValue(paths.country, value);
                    this.setState({ country: value });
                  }}
                  disabled={!editable || !this.state.region}
                  token={this.props.token}
                  isClearable
                />
              )}
            />
          ) : (
            getIn(entity, paths.countryName, null)
          )}
        </div>

        <Label {...messages.sector} />
        <div>
          {this.props.paEditable && editable ? (
            <Field
              name={paths.sector}
              entity="sectors"
              component={AutocompleteFormik}
              initialValue={getIn(entity, paths.sector, null)}
              disabled={!editable}
              token={this.props.token}
              isClearable
            />
          ) : (
            getIn(entity, paths.sectorName, null)
          )}
        </div>

        {/* IsMaster is visible on Plan only */}
        {paPath && <Label {...messages.masterPlans} />}
        {paPath && (
          <div>
            {planDetailMasterPlans && planDetailMasterPlans
              .map(it => this.renderLink(`/masterPlan/${it.id}`, it.name))
              .reduce((accu, elem) => (accu === null ? [elem] : [...accu, ', ', elem]), null)}
          </div>
        )}
        { planDetailSchedules && planDetailSchedules.length ?
        <>
        {paPath && <Label {...messages.schedules} />}
        {paPath && (
          <div>
            {planDetailSchedules && planDetailSchedules
              .map(it => this.renderLink(`/schedules/${it.id}`, it.name))
              .reduce((accu, elem) => (accu === null ? [elem] : [...accu, ', ', elem]), null)}
          </div>
        )}
        </>
        : null
        }
      </>
    );
  }

  renderLink = (url, title) => (
    <a href={url} target="_blank">
      {title}
    </a>
  );

  renderValue = (field, component, entiy, fieldRead, setValueAfterLoad, editableParam) => {
    const editable = editableParam === undefined ? this.props.guiState.get('edit') : editableParam;
    if (editable) {
      if (setValueAfterLoad) {
        return <FastField name={field} component={component} entity={entiy} setValueAfterLoad={setValueAfterLoad} />;
      }
      return <FastField name={field} component={component} entity={entiy} />;
    }
    return this.renderReadOnlyValue(field, fieldRead);
  };

  renderReadOnlyValue = (field, fieldRead) => <div>{get(this.props.entity, fieldRead || field)}</div>;
}

DetailEntityBasicInfo.propTypes = {
  entity: PropTypes.object,
  guiState: PropTypes.object,
  paMessage: PropTypes.object,
  paEditable: PropTypes.bool,
  paPath: PropTypes.string,
  token: PropTypes.string,
  formik: PropTypes.object,
};

const mapPlanStateToProps = state => ({
  ...createStructuredSelector({
    entity: selectPlan,
    planDetailMasterPlans: selectPlanDetailMasterPlan,
    planDetailSchedules: selectPlanDetailSchedule,
    guiState: selectGuiState,
    token: makeSelectToken(),
  })(state),
  paMessage: messages.planningAreaBase,
  paEditable: false,
  paPath: 'planningArea',
});

const mapPaStateToProps = state => ({
  ...createStructuredSelector({
    entity: selectPlanningAreaFromPa,
    guiState: selectGuiStateForPa,
    token: makeSelectToken(),
  })(state),
  paMessage: messages.parentPlanningAreaBase,
  paEditable: true,
  paPath: null,
});

const withPlanConnect = connect(mapPlanStateToProps);

const withPaConnect = connect(mapPaStateToProps);

export const PlanDetailBasicInfo = withRouter(withPlanConnect(DetailEntityBasicInfo));
export const PlaningAreaDetailBasicInfo = withRouter(withPaConnect(DetailEntityBasicInfo));
