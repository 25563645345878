import { defineMessages } from 'react-intl';

export default defineMessages({
  monday: {
    id: `app.utils.calendar.monday`,
    defaultMessage: `Monday`,
  },
  tuesday: {
    id: `app.utils.calendar.tuesday`,
    defaultMessage: `Tuesday`,
  },
  wednesday: {
    id: `app.utils.calendar.wednesday`,
    defaultMessage: `Wednesday`,
  },
  thursday: {
    id: `app.utils.calendar.thursday`,
    defaultMessage: `Thursday`,
  },
  friday: {
    id: `app.utils.calendar.friday`,
    defaultMessage: `Friday`,
  },
  saturday: {
    id: `app.utils.calendar.saturday`,
    defaultMessage: `Saturday`,
  },
  sunday: {
    id: `app.utils.calendar.sunday`,
    defaultMessage: `Sunday`,
  },
  mondayShort: {
    id: `app.utils.calendar.monday_short`,
    defaultMessage: `Mon`,
  },
  tuesdayShort: {
    id: `app.utils.calendar.tuesday_short`,
    defaultMessage: `Tue`,
  },
  wednesdayShort: {
    id: `app.utils.calendar.wednesday_short`,
    defaultMessage: `Wed`,
  },
  thursdayShort: {
    id: `app.utils.calendar.thursday_short`,
    defaultMessage: `Thu`,
  },
  fridayShort: {
    id: `app.utils.calendar.friday_short`,
    defaultMessage: `Fri`,
  },
  saturdayShort: {
    id: `app.utils.calendar.saturday_short`,
    defaultMessage: `Sat`,
  },
  sundayShort: {
    id: `app.utils.calendar.sunday_short`,
    defaultMessage: `Sun`,
  },
});
