import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { withUrl } from 'utils/api';
import { fetchData } from 'utils/reduxApi';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import moment from 'moment';
import { toast } from 'react-toastify';

const fileTypeToTitle = {
  CSV_REQUEST: 'CSV Overview',
  ERROR_LOG: 'Error Log',
  REQUEST: 'Request to Engine',
};

export const downloadScheduleFile = (schedule, fileId, type, dispatch) => {
  const suffix = type === 'CSV_REQUEST' ? '.csv' : '.json';
  const typeTitle = fileTypeToTitle[type] || '';

  const name = `Schedule Run ${schedule.name} - ${typeTitle}${suffix}`;
  const url = `/schedules/${schedule.id}/calculation/${fileId}/download?type=${type}`;
  fetchData(withUrl(url).setFileDownload(name), dispatch);
};

export function statusCell(params) {
  const isRecalculatable = () =>
    params.data.status.code === 'CALCULATED' && !(params.data.startDate < moment().format('YYYY-MM-DD'));
  const downloadLog = () => {
    downloadScheduleFile(params, params.data.errorLogFileId, 'ERROR_LOG', params.dispatch);
  };
  const recalculate = () => {
    const diffTime = +new Date(params?.data?.endDate) - +new Date(params?.data?.startDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    if(params?.data?.granularity === "HOUR" && diffDays >= params?.dateLimitHourGranularity){
      toast.error("Schedule period for time span 1 Hour is 30 days");
    }else if(params?.data?.granularity === "QUARTER_OF_HOUR" && diffDays >= params?.dateLimitQuarterGranularity){
      toast.error("Schedule period for time span 15 Minutes is 7 days");
    }else{
      params.recalculateShiftScheduleRuns({ scheduleId: params.scheduleId, runId: params.data.id });
    }
  };

  const downloadLogButton = params.data.status.isFailed() && params.data.errorLogFileId && (
    <Tooltip title="Error Log">
      <span style={{ cursor: 'pointer' }}>
        <FontAwesomeIcon icon="download" color="green" size="lg" onClick={downloadLog} />
      </span>
    </Tooltip>
  );

  const recalculateButton = isRecalculatable() && (
    <Tooltip title="Recalculate">
      <span style={{ cursor: 'pointer' }}>
        <FontAwesomeIcon icon="sync" color="green" size="lg" onClick={recalculate} />
      </span>
    </Tooltip>
  );

  const messageKey = params.messages[`status_${params.data.status.code.toLowerCase()}`];
  const displayText = messageKey ? params.formatMessage(messageKey) : params.data.status.code;
  return (
    <div>
      <span style={{ marginRight: '6px' }}>{displayText}</span>
      {downloadLogButton}
      {recalculateButton}
    </div>
  );
}
