import { DateTime } from 'luxon';

import { formatDateToApiFormat, withUrl } from 'utils/api';
import { fetchData } from 'utils/reduxApi';
import {
  ApiActivityDTO,
  ApiForecastConversionRateTableDTO,
  ApiForecastEventDTO,
  ApiForecastPerOmsCodeTableDTO,
  ApiPlanOrPlanningAreaDTO,
  ApiSmartProdRunDTO,
  ApiSmartProdRunScheduleDTO, ApiSmartProdSourceDTO,ApiSmartVolumeRunDTO,
} from 'types/drep-backend.d';

import { EntityEntry, ForecastConversionRateTableDTOWithId, Holiday, SubCategoryEntry } from './types';

export const smartProdSourceName = (entity: ApiSmartProdSourceDTO) =>
  (entity?.smartProdInstance?.name && entity?.smartProdWarehouse?.smartProdWhId)
    ? `${entity?.smartProdInstance?.name} / ${entity?.smartProdWarehouse?.smartProdWhId}`
    : '';

export const fetchSmartProdInstances = async (token: string, dispatch, setEntries: (entries: EntityEntry[]) => void) => {
  const response = await fetchData(withUrl(`/smartProdInstances/`).andToken(token), dispatch);
  setEntries(
    response.isOk
      ? response.data.results.map(result => ({
          value: result.id,
          label: result.name,
        }))
      : [],
  );
};

export const fetchEventCategories = async (token: string, dispatch, setEntries: (entries: EntityEntry[]) => void) => {
  const response = await fetchData(withUrl(`/eventCategories/`).andToken(token), dispatch);
  setEntries(
    response.isOk
      ? response.data.results.map(result => ({
          value: result.id,
          label: result.name,
        }))
      : [],
  );
};

export const fetchEventSubCategories = async (
  token: string,
  dispatch,
  setEntries: (entries: SubCategoryEntry[]) => void,
) => {
  const response = await fetchData(withUrl(`/eventSubCategories/`).andToken(token), dispatch);
  setEntries(
    response.isOk
      ? response.data.results.map(result => ({
          value: result.id,
          label: result.name,
          eventCategoryId: result.eventCategoryId,
        }))
      : [],
  );
};

export const fetchEventOrigins = async (token: string, dispatch, setEntries: (entries: EntityEntry[]) => void) => {
  const response = await fetchData(withUrl(`/eventOrigins/`).andToken(token), dispatch);
  setEntries(
    response.isOk
      ? response.data.results.map(result => ({
          value: result.id,
          label: result.name,
        }))
      : [],
  );
};

export const fetchsmartProdSources = async (token: string, dispatch, setSmartProdSources) => {
  const response = await fetchData(withUrl(`/smartProdSource/`).andToken(token), dispatch);
  if(response.isOk){
    setSmartProdSources(response.data)
  }
};

export const fetchOMSCodes = async (token: string, dispatch, setEntries: (entries: EntityEntry[]) => void) => {
  const response = await fetchData(withUrl(`/omses/`).andToken(token), dispatch);
  setEntries(
    response.isOk
      ? response.data.results
          .sort((a, b) => {
            if (a.code === b.code) {
              return a.name < b.name ? -1 : 1;
            }
            return a.code < b.code ? -1 : 1;
          })
          .map(result => ({
            value: result.id,
            label: `${result.code} - ${result.name}`,
          }))
      : [],
  );
};

export const fetchWarehouses = async (token: string, dispatch, setWHIDs: (entries: EntityEntry[]) => void) => {
  const response = await fetchData(withUrl(`/smartProdWarehouses/?expression=`).andToken(token), dispatch);
  setWHIDs(
    response.isOk
      ? response.data.results.map(result => ({
          value: result.id,
          label: result.smartProdWhId,
        }))
      : [],
  );
};

export const fetchEvents = async (
  token: string,
  smartProdSourceId: number,
  dispatch,
  setEntries: (entries: ApiForecastEventDTO[]) => void,
) => {
  const response = await fetchData(
    withUrl(`/smartVolume/events?smartProdSourceId=${smartProdSourceId}`).andToken(token),
    dispatch,
  );
  setEntries(
    response.isOk
      ? response.data
          .sort((a, b) => (DateTime.fromISO(a.startDate) < DateTime.fromISO(b.startDate) ? 1 : -1))
          .map(event => ({
            ...event,
            impact: (Number(event.impact) * 100).toFixed(3).replace(/(\.0*|(?<=(\..*))0*)$/, ''),
          }))
      : [],
  );
};

export const saveEvents = async (token: string, dispatch, events: ApiForecastEventDTO[]) => {
  const response = await fetchData(
    withUrl(`/smartVolume/events`)
      .andToken(token)
      .post(
        events.map(event => ({
          ...event,
          createdBy: undefined,
          startDate: formatDateToApiFormat(event.startDate),
          endDate: formatDateToApiFormat(event.endDate),
          impact: Number(event.impact) / 100,
        })),
      ),
    dispatch,
  );
  return response;
};

export const fetchHolidays = async (
  token: string,
  smartProdSourceId: number,
  countryCodes: string[],
  dispatch,
  setEntries: (entries: Holiday[]) => void,
) => {
  const joinedCountryCodes = countryCodes.join(',');
  const responseHolidays = await fetchData(
    withUrl(`/holidays?countryCodes=${joinedCountryCodes}`).andToken(token),
    dispatch,
  );
  const responseForecastHolidays = await fetchData(
    withUrl(`/smartProdSource/${smartProdSourceId}/holidays`).andToken(token),
    dispatch,
  );

  let data = responseHolidays.isOk ? responseHolidays.data.results : [];
  if (responseHolidays.isOk && responseForecastHolidays.isOk) {
    const forecastHolidaysById = {};
    responseForecastHolidays.data.forEach(rfh => {
      forecastHolidaysById[rfh.holidayId] = rfh;
    });
    data = data
      .map(holiday => ({
        ...holiday,
        omsIds: [],
        smartProdSourceId: [],
        ...forecastHolidaysById[holiday.id],
      }))
      .sort((a, b) => (DateTime.fromISO(a.holidayDate) < DateTime.fromISO(b.holidayDate) ? 1 : -1));
  }
  setEntries(data);
};

export const saveForecastHolidays = async (
  token: string,
  dispatch,
  smartProdSourceId: number,
  holidays: Holiday[],
) => {
  const holidayPayload = holidays.filter((data)=>data.omsIds.length > 0);
  const response = await fetchData(
    withUrl(`/smartProdSource/${smartProdSourceId}/holidays`)
      .andToken(token)
      .post(
        holidayPayload.map(holiday => ({
          holidayId: holiday.id,
          omsIds: holiday.omsIds,
          smartProdSourceIds: holiday.smartProdSourceIds,
          autoAssignHoliday: holiday.omsIds.length > 0 ? true : holiday.autoAssignHoliday,
          holidayWasAssigned: holiday.holidayWasAssigned,
        })),
      ),
    dispatch,
  );
  return response;
};

export const saveSmartProdSourceData = async (
  token,
  dispatch,
  entity,
  omsData?:any
) => {
  const response = await fetchData(
    withUrl(`/smartProdSource/`)
      .andToken(token)
      .post({
        deleted : entity.deleted,
        id: entity.id,
        isUsable:entity.isUsable,
        omses: omsData ? omsData : entity.omses,
        schedulePeriod:entity.schedulePeriod,
        scheduleStartDate:entity.scheduleStartDate,
        smartProdInstance:entity.smartProdInstance,
        smartProdWarehouse:entity.smartProdWarehouse,
        startDate: formatDateToApiFormat(entity.startDate),
        description:entity.description,
        isRequestSmartVolumeForecast: entity.isRequestSmartVolumeForecast,
      }
      ),
    dispatch,
  );
  return response;
};

export const saveSmartProdSource = async (
  token,
  dispatch,
  payload
) => {
  const response = await fetchData(
    withUrl(`/smartProdSource/`)
      .andToken(token)
      .post({
       ...payload
      }
      ),
    dispatch,
  );
  return response;
};

export const fetchActivities = async (
  token: string,
  dispatch,
  planningParametersId: string | number,
  setEntries: (entries: ApiActivityDTO[]) => void,
) => {
  const activitiesResponse = await fetchData(
    withUrl(`/activities/?expression=&pageSize=100000&planningParametersId=${planningParametersId}`).andToken(token),
    dispatch,
  );
  if (activitiesResponse.isOk) {
    setEntries(activitiesResponse.data.results);
  }
};

export const fetchForecastConversionRateTable = async (
  token: string,
  dispatch,
  planningParametersId: string | number,
  setEntries: (entries: ForecastConversionRateTableDTOWithId[]) => void,
) => {
  const response = await fetchData(
    withUrl(`/planningParameters/${planningParametersId}/forecastConversionRates`).andToken(token),
    dispatch,
  );
  if (response.isOk) {
    setEntries(
      response.data
        .sort((a, b) => {
          if (!a.omsId) {
            return 1;
          }
          if (!b.omsId) {
            return -1;
          }
          return 0;
        })
        .map((r, id) => ({
          ...r,
          id,
        })),
    );
  }
};

export const saveForecastConversionRateTable = (
  token: string,
  dispatch,
  planningParametersId: string,
  forecastConversionRates: ApiForecastConversionRateTableDTO[],
) =>
  fetchData(
    withUrl(`/planningParameters/${planningParametersId}/forecastConversionRates`)
      .andToken(token)
      .post(forecastConversionRates),
    dispatch,
  );

export const getForecastPerOMSCode = async (
  token: string,
  dispatch,
  planningParametersId: string | number,
  setEntries: (entries: ApiForecastPerOmsCodeTableDTO) => void,
  jobsIncluded: boolean,
) => {
  const response = await fetchData(
    withUrl(
      `/planningParameters/${planningParametersId}/forecastPerOmsCode?jobsIncluded=${jobsIncluded ? 'true' : 'false'}`,
    ).andToken(token),
    dispatch,
  );
  if (response.isOk) {
    const sortedEntries = {
      oms: response.data.oms.map(omsData => ({
        ...omsData,
        dates: omsData.dates.sort((a, b) => (DateTime.fromISO(a.effortDate) > DateTime.fromISO(b.effortDate) ? 1 : -1)),
        activities: omsData.activities.map(activityData => ({
          ...activityData,
          dates: activityData.dates.sort((a, b) =>
            DateTime.fromISO(a.effortDate) > DateTime.fromISO(b.effortDate) ? 1 : -1,
          ),
          jobs: !activityData.jobs
            ? undefined
            : activityData.jobs.map(job => ({
                ...job,
                dates: job.dates
                  .filter(j => j.effortDate)
                  .sort((a, b) => (DateTime.fromISO(a.effortDate) > DateTime.fromISO(b.effortDate) ? 1 : -1)),
              })),
        })),
      })),
    };
    setEntries(sortedEntries);
  }
};

export const refreshJobCodes = (token: string, dispatch, planningParametersId: string) =>
  fetchData(
    withUrl(`/planningParameters/${planningParametersId}/refreshJobCodes`).andToken(token).asRawResponse().post(),
    dispatch,
  );

type RunsSetter = ((entries: ApiSmartProdRunDTO[]) => void) | ((entries: ApiSmartProdRunScheduleDTO[]) => void);

const getSmartProdRuns = url => async (token, dispatch, planningParametersId: string, setEntries: RunsSetter) => {
  const response = await fetchData(
    withUrl(`${url}?planningParametersId=${planningParametersId}`).andToken(token),
    dispatch,
  );
  if (response.isOk) {
    setEntries(
      response.data.sort((a, b) => (DateTime.fromISO(a.createdDate) < DateTime.fromISO(b.createdDate) ? 1 : -1)),
    );
  }
};

export const getSmartProdRequestedRuns = getSmartProdRuns('/smartVolume/run');

export const getSmartProdScheduledRuns = getSmartProdRuns('/smartVolume/runSchedule');

export const saveSmartProdRuns = (
  token: string,
  dispatch,
  planningParametersId: string,
  smartProdRuns: ApiSmartProdRunDTO[],
) =>
  fetchData(
    withUrl(`/smartVolume/run?planningParametersId=${planningParametersId}`)
      .andToken(token)
      .post(
        smartProdRuns.map(s => ({
          ...s,
          dataSourceDates: {
            ...s.dataSourceDates,
            startDate: formatDateToApiFormat(s.dataSourceDates.startDate),
            endDate: formatDateToApiFormat(s.dataSourceDates.endDate),
            years: s.dataSourceDates.years.map(formatDateToApiFormat),
            months: s.dataSourceDates.months.map(formatDateToApiFormat),
            weeks: s.dataSourceDates.weeks.map(formatDateToApiFormat),
            dates: s.dataSourceDates.dates.map(formatDateToApiFormat),
          },
          forecastDates: {
            ...s.forecastDates,
            startDate: formatDateToApiFormat(s.forecastDates.startDate),
            endDate: formatDateToApiFormat(s.forecastDates.endDate),
            years: s.forecastDates.years.map(formatDateToApiFormat),
            months: s.forecastDates.months.map(formatDateToApiFormat),
            weeks: s.forecastDates.weeks.map(formatDateToApiFormat),
            dates: s.forecastDates.dates.map(formatDateToApiFormat),
          },
          createdBy: undefined,
          sentBy: undefined,
          calculatedBy: undefined,
        })),
      ),
    dispatch,
  );

export const saveSmartProdScheduledRuns = (
  token: string,
  dispatch,
  planningParametersId: string,
  smartProdRuns: ApiSmartProdRunScheduleDTO[],
) =>
  fetchData(
    withUrl(`/smartVolume/runSchedule?planningParametersId=${planningParametersId}`)
      .andToken(token)
      .post(
        smartProdRuns.map(s => ({
          ...s,
          createdBy: undefined,
          sentBy: undefined,
          calculatedBy: undefined,
          sourceStartDate: formatDateToApiFormat(s.sourceStartDate),
          sourceEndDate: formatDateToApiFormat(s.sourceEndDate),
        })),
      ),
    dispatch,
  );

export const smartProdRunsRequest = (token: string, dispatch, planningParametersId: number, runId: number) =>
  fetchData(
    withUrl(`/smartVolume/run/${runId}/calculate?planningParametersId=${planningParametersId}`).andToken(token).post(),
    dispatch,
  );

export const smartProdRunsCopyRunSettings = (
  token: string,
  endpoint: string,
  dispatch,
  runId: number,
  data: ApiSmartProdRunDTO,
) => fetchData(withUrl(`/smartVolume/${endpoint}/${runId}/copy`).andToken(token).post(data), dispatch);

export const fetchPlansAndPaWithSameSmartProdSource = async (
  token: string,
  dispatch,
  planningParametersId: number,
  setEntries: (entries: ApiPlanOrPlanningAreaDTO[]) => void,
) => {
  const response = await fetchData(
    withUrl(`/planningParameters/${planningParametersId}/plansAndPaWithSameSmartProdSource`).andToken(token),
    dispatch,
  );
  if (response.isOk) {
    setEntries(response.data);
  }
};

export const copyMappingOfPlansAndPaWithSameSmartProdSource = async (
  token: string,
  dispatch,
  sourcePlanningParametersId: number,
  destinationPlanningParametersId: number,
  override: boolean,
) => {
  const response = await fetchData(
    withUrl(
      `/planningParameters/${destinationPlanningParametersId}/copyJobCodeMapping/${sourcePlanningParametersId}?override=${
        override ? 'true' : 'false'
      }`,
    )
      .andToken(token)
      .post()
      .asRawResponse(),
    dispatch,
  );
  return response;
};

export const fetchSmartVolumeRuns = async (
  token: string,
  dispatch,
  plannningParametersId: number,
  setData: (entries: ApiSmartVolumeRunDTO[]) => void,
) => {
  const response = await fetchData(
    withUrl(`/smartVolume/runs/?smartProdSourceId=${plannningParametersId}`).andToken(token),
    dispatch,
  );
  if (response.isOk) {
    setData(response.data.sort((a, b) => (a.created > b.created ? -1 : 1)));
  }
};


export const fetchFacilities = async (token: string, dispatch, setFacilities: (entries: EntityEntry[]) => void) => {
  const response = await fetchData(withUrl(`/facilities/?expression=`).andToken(token), dispatch);
  setFacilities(
    response.isOk
      ? response.data.results.map(result => ({
          value: result.id,
          label: `${result.code} : ${result.name}`,
          id: result.id,
          code: result.code,
          name: result.name
        }))
      : [],
  );
};

export const fetchNewFacility = async (token: string, dispatch, setFacilities: (entries: EntityEntry[]) => void, option) => {
  const response = await fetchData(withUrl(`/facilities/?expression=${option}`).andToken(token), dispatch);
  setFacilities(
    response.isOk
      ? response.data.results.map(result => ({
          value: result.id,
          label: `${result.code} : ${result.name}`,
          name: result.name,
          id: result.id,
          code: result.code,
        }))
      : [],
  );
};

export const saveSmartProdSourceFacility = async (
  token,
  dispatch,
  entity,
) => {
  const response = await fetchData(
    withUrl(`/planningParameters/smartProdSource/${entity.id}`)
      .andToken(token)
      .asRawResponse()
      .patch({
        facilityId: entity.facility.id
      }
      ),
    dispatch,
  );
  return response;
};