import { defineMessages } from 'react-intl';

export default defineMessages({
  startDate: {
    id: 'app.components.DateRangeHeader.startDate',
    defaultMessage: 'Start Date',
  },
  endDate: {
    id: 'app.components.DateRangeHeader.endDate',
    defaultMessage: 'End Date',
  },
  setEndDate: {
    id: 'app.components.DateRangeHeader.setEndDate',
    defaultMessage: 'Set End Date',
  },
  setStartDate: {
    id: 'app.components.DateRangeHeader.setStartDate',
    defaultMessage: 'Set Start Date',
  },
  startEndDatesRangeError: {
    id: 'app.components.DateRangeHeader.startEndDatesRangeError',
    defaultMessage: 'Maximum range for date interval is 3 months',
  },
  dialogHeader: {
    id: 'app.components.DateRangeHeader.dialogHeader',
    defaultMessage: 'Period Selection',
  },
  saveButton: {
    id: 'app.components.DateRangeHeader.saveButton',
    defaultMessage: 'Save',
  },
  cancelButton: {
    id: 'app.components.DateRangeHeader.cancelButton',
    defaultMessage: 'Cancel',
  },
});
