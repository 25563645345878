import * as React from 'react';
import { messages } from './messages';
import { createUnproductiveSection } from './common';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { deletePlanActivityLines } from 'containers/PlanDetailPage/actions';
import { deletePaActivityLines } from 'containers/PlanningAreaDetailPage/actions';

const config = {
  header: messages.staffHeader,
  dataElementName: 'unproductiveStaff',
};

const withPlanConnect = connect(
  null,
  { deleteActivityLines: deletePlanActivityLines },
);
const withPaConnect = connect(
  null,
  { deleteActivityLines: deletePaActivityLines },
);

export const UnproductiveStaffPlan = injectIntl(withPlanConnect(createUnproductiveSection(config)));
export const UnproductiveStaffPa = injectIntl(withPaConnect(createUnproductiveSection(config)));
