import React from 'react';
import { FormattedMessage } from 'react-intl';

import HolidaysTable from './HolidaysTable';
import Button from 'components/Button';
import {EntityEntry, Holiday, IdAndCode, SmartProdSourceEntityEntry} from './types';
import styled from 'styled-components';
import { ApiSmartProdSourceDTO } from 'types/drep-backend.d';

import messages from './messages';

const Wrapper = styled.div`
display: flex
`;

const WrapperLabel = styled.div`
margin-top: 5px;
color: red;
`;

const StyledButton = styled(Button)`
  height: 28px;
  margin-left: 20px;
`;

type Props = {
  forecastEdit: boolean;
  holidaysData: Holiday[];
  planFacilities: IdAndCode[];
  allOmsCodes: EntityEntry[];
  allSmartProdSources: SmartProdSourceEntityEntry[];
  entity?: ApiSmartProdSourceDTO,
  smartProdSources: any;
  handleStopAutoAssign: any;
  handleAutoAssign: any;
  holidayWasAssignedFlag: boolean;
  handleHolidayAssigment: any;
};

const Holidays: React.FC<Props> = ({ forecastEdit, holidaysData, planFacilities, allOmsCodes, allSmartProdSources, entity, smartProdSources, handleStopAutoAssign,handleAutoAssign, holidayWasAssignedFlag, handleHolidayAssigment }) =>
  holidaysData &&
  allOmsCodes &&
  allSmartProdSources && (
    <div>
      <p>
        <FormattedMessage {...messages.assignedHolidaysTitle} />
      </p>
      {
        holidayWasAssignedFlag && (
          <Wrapper>
            <WrapperLabel><FormattedMessage {...messages.holidayWasAssigned} /></WrapperLabel>
            <StyledButton onClick={handleHolidayAssigment} > <FormattedMessage {...messages.confirm} /></StyledButton>
          </Wrapper>
        )
      }
      <HolidaysTable
        entity={entity}
        holidaysData={holidaysData.filter(holiday => holiday.omsIds?.length > 0)}
        forecastEdit={forecastEdit}
        allOmsCodes={allOmsCodes}
        planFacilities={planFacilities}
        allSmartProdSources={allSmartProdSources}
        smartProdSources={smartProdSources}
        holidayType = 'assigned'
        handleStopAutoAssign={handleStopAutoAssign}
        handleAutoAssign={handleAutoAssign}
      />
      <p>
        <FormattedMessage {...messages.notAssignedHolidaysTitle} />
      </p>
      <HolidaysTable
        entity={entity}
        holidaysData={holidaysData.filter(holiday => holiday.omsIds?.length === 0)?.sort((a,b)=> Number(b.autoAssignHoliday) - Number(a.autoAssignHoliday))}
        forecastEdit={forecastEdit}
        allOmsCodes={allOmsCodes}
        planFacilities={planFacilities}
        allSmartProdSources={allSmartProdSources}
        smartProdSources={smartProdSources}
        holidayType = 'unassigned'
        handleStopAutoAssign={handleStopAutoAssign}
        handleAutoAssign={handleAutoAssign}
      />
    </div>
  );

export default Holidays;
