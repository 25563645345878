/*
 * Dialog Messages
 *
 * This contains all the text for the Dialog component.
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  id: {
    id: 'app.components.ParametersValuesTable.id',
    defaultMessage: 'Id',
  },
  message: {
    id: 'app.components.ParametersValuesTable.message',
    defaultMessage: 'Message',
  },
  no: {
    id: 'app.components.ParametersValuesTable.no',
    defaultMessage: 'Yes',
  },
  value: {
    id: 'app.components.ParametersValuesTable.value',
    defaultMessage: 'Value',
  },
  unit: {
    id: 'app.components.ParametersValuesTable.unit',
    defaultMessage: 'Unit',
  },
  yes: {
    id: 'app.components.ParametersValuesTable.yes',
    defaultMessage: 'Yes',
  },
});
