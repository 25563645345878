import * as React from 'react';
import { useEffect, useState } from 'react';
import { Form, Formik, FormikProps } from 'formik';
import { DateTime } from 'luxon';
import { injectIntl } from 'react-intl';
import { connect, useDispatch, useSelector } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';

import BasePage from 'components/BasePage';
import ToggleSection from 'components/ToggleSection';
import DatePicker from 'components/DatePicker';
import { DetailPageSectionsWrapper } from 'components/DetailPageShared';
import { FormikText, InlineLabel, FormikCollapseText } from 'components/FormikTextInput';
import IconButton from 'components/IconButton';
import NamedSection from 'components/NamedSection';
import { ButtonWrap } from 'components/ResultToolBar';
import { PATHS } from 'containers/App/constants';
import { getToken } from 'containers/App/selectors';
import { storeShiftSchedule } from 'containers/ShiftScheduleDetailPage/actions';
import { Title } from 'containers/ShiftScheduleDetailPage/toolbar';
import { loadShiftSchedule } from 'containers/ShiftScheduleRunsPage/actions';
import saga from 'containers/ShiftScheduleRunsPage/saga';
import { selectShiftSchedule, selectShiftScheduleName } from 'containers/ShiftScheduleRunsPage/selectors';
import { formatDateToApiFormat, withUrl } from 'utils/api';
import { parseDate } from 'utils/dateTime';
import injectSaga from 'utils/injectSaga';
import { fetchData } from 'utils/reduxApi';
import { withScrollWatch } from 'utils/scroll';
import withSecurity, { PERMISSIONS } from 'utils/security';
import { ApiScheduleDTO } from 'types/drep-backend.d';

import messages from './messages';
import { ACTIVE_ENV } from '../../utils/activeEnv';

const FullWidthSection = styled(NamedSection)`
  min-width: 1170px;
  margin-top: 0px;
`;

const GridRow = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: 2fr 3fr 2fr 2fr 2fr 2fr 2fr 2fr 100px 3fr 100px;
  margin: 0 0 12px;
  column-gap: 10px;
  input {
    background-color: ${props => props.theme.color.grey5};
  }
`;

export const Section = styled(ToggleSection)`
  margin: 5px 10px;
`;

const GridRowBasicInformationFacilities = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: 1fr 1fr;
  margin: 0 0 12px;
  column-gap: 20px;
  justify-items: stretch;
  width: 100%;
  input {
    background-color: ${props => props.theme.color.grey5};
  }
`;

const GridRowLabelValue = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: 1fr 2fr;
  margin: 0 0 12px;
  column-gap: 10px;
  input {
    background-color: ${props => props.theme.color.grey5};
  }
`;

const GridRowKeyValue = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: 100px 20px 100px;
  margin: 0 0 12px;
  column-gap: 10px;
  input {
    background-color: ${props => props.theme.color.grey5};
  }
`;

const BigSection = styled(NamedSection)`
  min-width: 575px;
  input {
    background-color: ${props => props.theme.color.grey5};
  }
`;

const DateWrap = styled.div``;

interface ShiftScheduleRunsPowerBIPageProps extends ShiftScheduleRunsForm {
  shiftSchedule: ApiScheduleDTO;
  history: any;
  intl: any;
  dispatch: any;
  match: any;
  loadShiftSchedule(action: any): () => any;
  storeShiftSchedule(shiftSchedule: ApiScheduleDTO): () => void;
}

export interface ShiftScheduleRunsForm {
  endDate: DateTime;
  startDate: DateTime;
}

const ShiftScheduleRunsPowerBIPage: React.FC<ShiftScheduleRunsPowerBIPageProps> = ({
  shiftSchedule,
  match,
  ...props
}) => {
  const [initialValues, setInitialValues] = useState<ShiftScheduleRunsForm>({
    endDate: DateTime.local(),
    startDate: DateTime.local().minus({ days: 28 }),
  });
  // this is here to reload iframe content, when powerBiReportInProgress changes to false
  const [iframeReloadKey, setIframeReloadKey] = useState(0);
  const [reloadIframe, setReloadIframe] = useState(false);

  const scheduleId = match.params.id;
  const dispatch = useDispatch();
  const token: string = useSelector(getToken);
  const powerBIError =  shiftSchedule?.powerBiStatus?.includes('ERROR');
  const warningToDisplay = powerBIError ? messages.tableHeaderWarningAvailable : '';
  const warningMessage = powerBIError ? shiftSchedule?.powerBiReportError : 'No warnings available to display.';

  useEffect(() => {
    fetchData(
      withUrl(`/schedules/${scheduleId}/powerbi/visited`)
        .andToken(token)
        .asRawResponse(),
      dispatch,
    );
  }, [])

  useEffect(() => {
    props.loadShiftSchedule(scheduleId);
  }, []);

  useEffect(() => {
    let timer = null;
    if (shiftSchedule.powerBiReportInProgress) {
      timer = setInterval(() => {
        props.loadShiftSchedule(scheduleId);
      }, 5000);
    }
    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [shiftSchedule.powerBiReportInProgress]);

  useEffect(() => {
    if (!shiftSchedule.powerBiReportInProgress && reloadIframe) {
      setIframeReloadKey(iframeReloadKey + 1);
    }
  }, [shiftSchedule.powerBiReportInProgress]);

  useEffect(() => {
    setInitialValues({
      startDate: shiftSchedule?.powerBiStartDay
        ? parseDate(shiftSchedule?.powerBiStartDay)
        : DateTime.local().minus({ days: 28 }),
      endDate: shiftSchedule?.powerBiEndDay ? parseDate(shiftSchedule?.powerBiEndDay) : DateTime.local(),
    });
  }, [shiftSchedule]);

  const handleSubmit = async (values, actions) => {
    const response = await fetchData(
      withUrl(
        `/schedules/${scheduleId}/powerbi/export?startDay=${formatDateToApiFormat(
          values.startDate,
        )}&endDay=${formatDateToApiFormat(values.endDate)}`,
      )
        .andToken(token)
        .asRawResponse(),
      dispatch,
    );
    if (response.isOk) {
      actions.resetForm(values);
      // it has to be modified manualy, because BE doesn't immediately set the flag powerBiReportInProgress to true
      // after ther run was scheduled
      props.storeShiftSchedule({ ...shiftSchedule, powerBiReportInProgress: true });
      setReloadIframe(true);
    }
    actions.setSubmitting(false);
  };

  const renderToolbar = (formik, fixed) => {
    const goBack = () => props.history.push(PATHS.shiftScheduleRuns.replace(':id', scheduleId));
    return (
      <ButtonWrap fixed={fixed}>
        <IconButton id="back" onClick={goBack} icon="arrow-circle-left" tooltip={messages.back} />
        {fixed && <Title>{shiftSchedule?.name}</Title>}
      </ButtonWrap>
    );
  };

  const renderFacilities = facilities => (
    <>
      {facilities?.map((facility, index) => (
        <GridRowKeyValue key={index}>
          <FormikText>{facility.code}</FormikText>
          <span key="space" />
          <FormikText>{facility.name}</FormikText>
        </GridRowKeyValue>
      ))}
    </>
  );

  const renderForm = (formik: FormikProps<ShiftScheduleRunsForm>) => {
    const Toolbar = withScrollWatch(100)(({ fixed }) => renderToolbar(formik, fixed));
    return (
      <Form>
        <Toolbar />
        <DetailPageSectionsWrapper>
          <GridRowBasicInformationFacilities>
            <BigSection message={messages.basicInfo} noMaxWidth>
              <GridRowLabelValue>
                <InlineLabel {...messages.basicInformationName} />
                <FormikText>{shiftSchedule?.name}</FormikText>
              </GridRowLabelValue>
              <GridRowLabelValue>
                <InlineLabel {...messages.basicInformationDescription} />
                <FormikText>{shiftSchedule?.description}</FormikText>
              </GridRowLabelValue>
            </BigSection>
            <BigSection message={messages.facilities} noMaxWidth>
              {renderFacilities(shiftSchedule?.facilities)}
            </BigSection>
          </GridRowBasicInformationFacilities>
        </DetailPageSectionsWrapper>

        <Section subtitleToShow={warningToDisplay} message={messages.tableHeaderWarning}>
          <FormikCollapseText>{warningMessage}</FormikCollapseText>
        </Section>

        <FullWidthSection message={messages.tableHeader} noMaxWidth>
          <GridRow>
            <InlineLabel {...messages.startDate} />
            <DateWrap>
              <DatePicker
                timePicker={false}
                value={formik.values.startDate}
                maxDate={formik.values.endDate}
                onChange={value => formik.setFieldValue('startDate', value)}
              />
            </DateWrap>

            <InlineLabel {...messages.endDate} />
            <DateWrap>
              <DatePicker
                timePicker={false}
                value={formik.values.endDate}
                minDate={formik.values.startDate}
                onChange={value => formik.setFieldValue('endDate', value)}
              />
            </DateWrap>
            <IconButton
              id="powerBiGraph"
              label={messages.runReportButton}
              tooltip={shiftSchedule.powerBiReportInProgress ? { ...messages.powerBiReportInProgress } : undefined}
              onClick={formik.submitForm}
              disabled={formik.isSubmitting || shiftSchedule.powerBiReportInProgress}
            />
          </GridRow>
          {shiftSchedule?.powerBiStartDay && shiftSchedule?.powerBiEndDay && (
            <GridRow>
              <div style={{ marginTop: 20, marginBottom: 20, position: 'relative' }}>
                <iframe
                  key={`${iframeReloadKey}`}
                  title="PowerBI Report"
                  style={{ position: 'relative', width: 'calc(100vw - 130px)', height: '70vw' }}
                  src={`${ACTIVE_ENV.powerBIScheduleURL}&filter=PRECALCULATED/schedule_id eq ${scheduleId}&filterPaneEnabled=false&navContentPaneEnabled=false`}
                  frameBorder="0"
                  allowFullScreen
                />
              </div>
            </GridRow>
          )}
        </FullWidthSection>
      </Form>
    );
  };

  return (
    <BasePage labelMessage={messages.header} labelValues={{ name: shiftSchedule?.name }} noMaxWidth>
      <Formik
        validateOnChange
        onSubmit={handleSubmit}
        enableReinitialize
        initialValues={initialValues}
        render={renderForm}
      />
    </BasePage>
  );
};

const mapStateToProps = createStructuredSelector({
  shiftSchedule: selectShiftSchedule,
  shiftScheduleName: selectShiftScheduleName,
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators(
      {
        loadShiftSchedule,
        storeShiftSchedule,
      },
      dispatch,
    ),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withSaga = injectSaga({ key: 'shiftScheduleRunsPowerBIPage', saga });

export default compose(
  injectIntl,
  withConnect,
  withSecurity(PERMISSIONS.VIEW_SCHEDULES),
  withSaga,
  // @ts-ignore
)(ShiftScheduleRunsPowerBIPage);
