import { Component } from 'react';
import * as React from 'react';
import { FormikProps } from 'formik';
import { DateTime } from 'luxon';
import { injectIntl } from 'react-intl';
import styled from 'styled-components';

import AutocompleteSelect from 'components/AutocompleteSelect';
import DatePicker from 'components/DatePicker';
import DeleteCellRenderer from 'components/DeleteCellRenderer';
import { InlineLabel } from 'components/FormikTextInput';
import { AddButton } from 'components/IconButton';
import Table from 'components/Table';
import { NamedEntity, ScheduleStaff } from 'containers/ShiftScheduleDetailPage/types';
import { percentageCellFormatter, pushToFormikArray, removeFromFormikArray } from 'utils/utils';

import messages from './messages';

interface StaffSettingsTableProps {
  formik: FormikProps<ScheduleStaff>;
  intl: any;
  activities: NamedEntity[];
}

class StaffSettingsTable extends Component<StaffSettingsTableProps, {}> {
  public state = {
    selectedSetting: null,
  };

  public render() {
    const data = this.props.formik.values.settings || [];
    const setSelectedSetting = value => this.setState({ selectedSetting: value });

    const addNewItem = () => {
      if (this.state.selectedSetting) {
        const { priority } = this.props.formik.values.settings.find(i => i.id === this.state.selectedSetting.id);
        pushToFormikArray(this.props.formik, 'settings', {
          activityKey: this.state.selectedSetting.name,
          priority,
          proficiency: 100,
          validFrom: DateTime.local(),
          validTo: null,
        });
        this.setState({ selectedSetting: null });
      }
    };

    const GridRow = styled.div`
      display: grid;
      grid-auto-flow: row;
      grid-template-columns: 63px 550px 70px;
      margin: 0 0 12px 2px;
      column-gap: 10px;
    `;

    const rowHeight = () => 49;
    return [
      this.props.activities.length > 0 && (
        <GridRow key="newItem">
          <InlineLabel {...messages.job} />
          <AutocompleteSelect
            key="period-select"
            preloadedData={this.props.activities}
            setValue={setSelectedSetting}
            value={this.state.selectedSetting}
          />
          <AddButton key="add-button" disabled={this.props.formik.isSubmitting} onClick={addNewItem} />
        </GridRow>
      ),
      <Table
        key="table"
        messages={messages}
        pagination={false}
        columnDefs={this.getColumnDefs()}
        rowData={data}
        onGridReady={null}
        getRowHeight={rowHeight}
        domLayout="autoHeight"
      />,
    ];
  }

  private getColumnDefs() {
    const datePicker = (minDateField, isClearable?) => params => (
      <DatePicker
        value={params.data[params.colDef.field]}
        timePicker={false}
        clearable={isClearable}
        minDate={minDateField ? params.data[minDateField] : undefined}
        onAccept={val => {
          const id = this.props.formik.values.settings.findIndex(i => i === params.data);
          this.props.formik.setFieldValue(`settings[${id}].${params.colDef.field}`, val);
        }}
      />
    );

    return [
      {
        cellRendererFramework: DeleteCellRenderer,
        cellRendererParams: {
          onDelete: (row, params) => {
            removeFromFormikArray(this.props.formik, 'settings', i => i === params.data);
          },
        },
        colId: 'removeButton',
        field: 'id',
        headerName: '',
        width: 50,
        suppressMenu: true,
      },
      {
        colId: 'activity',
        field: 'activity',
        headerName: this.props.intl.formatMessage(messages.job),
        valueGetter: params => params.data.activityKey,
        width: 400,
      },
      {
        colId: 'proficiency',
        editable: true,
        field: 'proficiency',
        headerName: this.props.intl.formatMessage(messages.proficiency),
        valueFormatter: percentageCellFormatter,
        valueSetter: params => {
          this.props.formik.setFieldValue(`settings[${params.node.id}].proficiency`, params.newValue);
          return true;
        },
        width: 110,
      },
      {
        colId: 'priority',
        editable: false,
        field: 'priority',
        headerName: this.props.intl.formatMessage(messages.priority),
        valueSetter: params => {
          this.props.formik.setFieldValue(`settings[${params.node.id}].priority`, params.newValue);
          return true;
        },
        width: 110,
      },
      {
        cellRendererFramework: datePicker(null),
        colId: 'validFrom',
        field: 'validFrom',
        headerName: this.props.intl.formatMessage(messages.validFrom),
        width: 250,
      },
      {
        cellRendererFramework: datePicker('validFrom', true),
        colId: 'validTo',
        field: 'validTo',
        headerName: this.props.intl.formatMessage(messages.validTo),
        width: 250,
      },
    ];
  }
}

// @ts-ignore
export default injectIntl(StaffSettingsTable);
