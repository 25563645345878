/**
 *
 * Switch
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { compose } from 'redux';

import ToggleButton from './index';

/* eslint-disable react/prefer-stateless-function */
class UserViewToggleButton extends React.PureComponent {
  state = { value: this.props.defaultValue };

  render() {
    const { intl, id, renderChildren, onChange } = this.props; // eslint-disable-line
    return (
      <ToggleButton
        id={id}
        input={{
          onChange: e => {
            this.setState(state => ({ value: onChange(e, state) }));
          },
          value: true,
        }}
      >
        {renderChildren(this.state)}
      </ToggleButton>
    );
  }
}

UserViewToggleButton.propTypes = {
  defaultValue: PropTypes.any,
  onChange: PropTypes.func,
  renderChildren: PropTypes.func,
};

export default compose(injectIntl)(UserViewToggleButton);
