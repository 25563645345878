/*
 * DayToHourTransformation Messages
 *
 * This contains all the text for the DayToHourTransformation component.
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  header: {
    id: 'app.containers.DayToHourTransformation.header',
    defaultMessage: 'Day to Hour Transformation',
  },
  activity: {
    id: 'app.containers.DayToHourTransformation.activity',
    defaultMessage: 'Activity',
  },
  customerId: {
    id: 'app.containers.DayToHourTransformation.customerId',
    defaultMessage: 'Customer',
  },
  activityCustom: {
    id: 'app.containers.DayToHourTransformation.activityCustom',
    defaultMessage: 'Activity (custom)',
  },
  indirect: {
    id: 'app.components.DayToHourTransformation.indirect',
    defaultMessage: 'Indirect',
  },
  checkSum: {
    id: 'app.components.DayToHourTransformation.checkSum',
    defaultMessage: 'Check Sum',
  },
  dayCopyConfirmationText: {
    id: 'app.components.DayToHourTransformation.copyDayConfirmationText',
    defaultMessage: 'Are you sure you want copy settings from {dayFrom} to {dayTo}',
  },
  dayCopyConfirmationTitle: {
    id: 'app.components.DayToHourTransformation.copyDayConfirmationTitle',
    defaultMessage: 'Copy day settings',
  },
  dayCopyConfirmationYes: {
    id: 'app.components.DayToHourTransformation.copyDayConfirmationYes',
    defaultMessage: 'Yes, copy',
  },
  distributeOffHoursText: {
    id: 'app.components.DayToHourTransformation.distributeOffHoursText',
    defaultMessage: 'Are you sure you want distribute time from offhours between active hours',
  },
  distributeOffHoursTitle: {
    id: 'app.components.DayToHourTransformation.distributeOffHoursTitle',
    defaultMessage: 'Distribute off hours',
  },
  distributeOffHoursYes: {
    id: 'app.components.DayToHourTransformation.distributeOffHoursYes',
    defaultMessage: 'Yes',
  },
  allDepartments: {
    id: 'app.components.DayToHourTransformation.allDepartments',
    defaultMessage: 'All Departments',
  },
  department: {
    id: 'app.components.DayToHourTransformation.department',
    defaultMessage: 'Department',
  },
  uom: {
    id: 'app.components.DayToHourTransformation.uom',
    defaultMessage: 'Unit of Measure',
  },
});
