/* eslint-disable no-restricted-syntax */
import { fromJS } from 'immutable';

import {
  STORE_MASTER_PLAN,
  STORE_MASTER_PLAN_GUI_STATE_ITEM,
  STORE_MASTER_PLAN_PERIOD_INDEX,
} from '../containers/MasterPlanDetailPage/constants';
import {
  ADD_MHE_OPEN,
  ADD_SHIFT_OPEN,
  ADD_ROLE_OPEN,
  CLEAR_PLAN_MHE,
  LOAD_PLAN,
  START_PLAN_COPY,
  STORE_PLAN,
  STORE_PLAN_GUI_STATE_ITEM,
  STORE_PLAN_PERIOD_INDEX,
  TOGGLE_SECTION_PLAN,
} from '../containers/PlanDetailPage/constants';
import {
  ADD_PA_MHE_OPEN,
  ADD_PA_SHIFT_OPEN,
  ADD_PA_ROLE_OPEN,
  CLEAR_AREA_MHE,
  LOAD_PA,
  START_PA_COPY,
  STORE_PA,
  STORE_PA_GUI_STATE_ITEM,
  STORE_PA_PERIOD_INDEX,
  TOGGLE_SECTION_PA,
} from '../containers/PlanningAreaDetailPage/constants';
import {
  LOAD_SHIFT_SCHEDULE,
  STORE_SHIFT_SCHEDULE,
  STORE_SHIFT_SCHEDULE_GUI_STATE_ITEM,
  STORE_SHIFT_SCHEDULE_PERIOD_INDEX,
} from '../containers/ShiftScheduleDetailPage/constants';
import { SECTION } from './constants';

export const DEFAULT_GUI_STATE = {
  edit: false,
  isNew: true,
  copy: false,
  loading: false,
  periodIndex: 0,
  submitError: false,
  expanded: {
    [SECTION.BUDGET]: false,
    [SECTION.PLANNED]: false,
    [SECTION.ACTIVITY]: false,
    [SECTION.TIME]: false,
    [SECTION.WZP]: false,
    [SECTION.LABOUR]: false,
  },
};

const planEntity = 'PLAN';
const masterPlanEntity = 'MASTER_PLAN';
const paEntity = 'PA';
const shiftScheduleEntity = 'SCHEDULE';

const defaultPlanConfig = { entityType: planEntity, entityPath: 'plan' };
const defaultMasterPlanConfig = { entityType: masterPlanEntity, entityPath: 'masterPlan' };
const defaultPaConfig = { entityType: paEntity, entityPath: 'planningArea' };
const defaultShiftScheduleConfig = { entityType: shiftScheduleEntity, entityPath: 'shiftSchedule' };

const commonEntityReducerConfig = [
  {
    eventConfig: {
      [CLEAR_PLAN_MHE]: defaultPlanConfig,
      [CLEAR_AREA_MHE]: defaultPaConfig,
    },
    handler: state => state.set('activityIdsMheOpen', fromJS([])).set('activityIdsShiftOpen', fromJS([])).set('activityIdsRoleOpen', fromJS([])),
  },
  {
    eventConfig: {
      [STORE_PLAN]: defaultPlanConfig,
      [STORE_PA]: defaultPaConfig,
      [STORE_MASTER_PLAN]: defaultMasterPlanConfig,
      [STORE_SHIFT_SCHEDULE]: defaultShiftScheduleConfig,
    },
    handler: (state, action, config) =>
      state.set(config.entityPath, action.payload).set(
        'guiState',
        fromJS({
          ...state.get('guiState').toJS(),
          isNew: !action.payload.id,
          edit: !action.payload.id || state.getIn(['guiState', 'edit']),
          loading: false,
        }),
      ),
  },
  {
    eventConfig: {
      [STORE_PLAN_GUI_STATE_ITEM]: { entityType: planEntity },
      [STORE_MASTER_PLAN_GUI_STATE_ITEM]: { entityType: masterPlanEntity },
      [STORE_PA_GUI_STATE_ITEM]: { entityType: paEntity },
      [STORE_SHIFT_SCHEDULE_GUI_STATE_ITEM]: { entityType: shiftScheduleEntity },
    },
    handler: (state, action) => state.setIn(['guiState', action.payload.field], action.payload.value),
  },
  {
    eventConfig: {
      [STORE_PLAN_PERIOD_INDEX]: defaultPlanConfig,
      [STORE_MASTER_PLAN_PERIOD_INDEX]: defaultMasterPlanConfig,
      [STORE_PA_PERIOD_INDEX]: defaultPaConfig,
      [STORE_SHIFT_SCHEDULE_PERIOD_INDEX]: defaultShiftScheduleConfig,
    },
    handler: (state, action, config) =>
      state.setIn(
        ['guiState', 'periodIndex'],
        action.payload < 0
          ? state.getIn([config.entityPath, 'planningParameters', 'periods']).length + action.payload
          : action.payload,
      ),
  },
  {
    eventConfig: {
      [ADD_ROLE_OPEN]: defaultPlanConfig,
      [ADD_PA_ROLE_OPEN]: defaultPaConfig,
    },
    handler: (state, action) => {
      const {
        payload: { id },
      } = action;
      const current = state.get('activityIdsMheOpen') || [];
      const currentIndex = current.indexOf(id);
      const currentShift = state.get('activityIdsShiftOpen');
      const currentIndexShift = currentShift.indexOf(id);
      const currentRole = state.get('activityIdsRoleOpen');
      const currentIndexRole = currentRole.indexOf(id);
      return state.set('activityIdsRoleOpen', currentIndexRole >= 0 ? currentRole.delete(currentIndexRole) : currentRole.push(id)).set('activityIdsShiftOpen', currentIndexShift >= 0 ? currentShift.delete(currentIndexShift) : currentShift).set('activityIdsMheOpen', currentIndex >= 0 ? current.delete(currentIndex) : current).set('openedButtonFlag', action.mhe ? 'MHE' : 'SHIFT');
    },
  },
  {
    eventConfig: {
      [ADD_MHE_OPEN]: defaultPlanConfig,
      [ADD_PA_MHE_OPEN]: defaultPaConfig,
    },
    handler: (state, action) => {
      const {
        payload: { id },
      } = action;
      const current = state.get('activityIdsMheOpen') || [];
      const currentIndex = current.indexOf(id);
      const currentShift = state.get('activityIdsShiftOpen');
      const currentIndexShift = currentShift.indexOf(id);
      const currentRole = state.get('activityIdsRoleOpen');
      const currentIndexRole = currentRole.indexOf(id);
      return state.set('activityIdsMheOpen', currentIndex >= 0 ? current.delete(currentIndex) : current.push(id)).set('activityIdsShiftOpen', currentIndexShift >= 0 ? currentShift.delete(currentIndexShift) : currentShift).set('activityIdsRoleOpen', currentIndexRole >= 0 ? currentRole.delete(currentIndexRole) : currentRole).set('openedButtonFlag', action.mhe ? 'MHE' : 'SHIFT');
    },
  },
  {
    eventConfig: {
      [ADD_PA_SHIFT_OPEN]: defaultPaConfig,
      [ADD_SHIFT_OPEN]: defaultPlanConfig,
    },
    handler: (state, action) => {
      const {
        payload: { id },
      } = action;
      const current = state.get('activityIdsMheOpen');
      const currentIndex = current.indexOf(id);
      const currentShift = state.get('activityIdsShiftOpen');
      const currentIndexShift = currentShift.indexOf(id);
      const currentRole = state.get('activityIdsRoleOpen');
      const currentIndexRole = currentRole.indexOf(id);
      return state.set('activityIdsShiftOpen', currentIndexShift >= 0 ? currentShift.delete(currentIndexShift) : currentShift.push(id)).set('activityIdsMheOpen',currentIndex >= 0 ? current.delete(currentIndex) : current).set('activityIdsRoleOpen',currentIndexRole >= 0 ? currentRole.delete(currentIndexRole) : currentRole).set('openedButtonFlag', action.mhe ? 'MHE' : 'SHIFT');
    },
  },
  {
    eventConfig: {
      [START_PLAN_COPY]: defaultPlanConfig,
      [START_PA_COPY]: defaultPaConfig,
    },
    handler: (state, action, config) =>
      // (action.payload) is set we're coming from list and need to set data for basic info widget
      (action.payload ? state.set(config.entityPath, action.payload) : state)
        .setIn(['guiState', 'copy'], true)
        .setIn(['guiState', 'edit'], true)
        .setIn([config.entityPath, 'name'], `Copy of ${state.getIn([config.entityPath, 'name'])}`),
  },
  {
    eventConfig: {
      [TOGGLE_SECTION_PLAN]: defaultPlanConfig,
      [TOGGLE_SECTION_PA]: defaultPaConfig,
    },
    handler: (state, action) =>
      state.setIn(['guiState', 'expanded', action.payload], !state.getIn(['guiState', 'expanded', action.payload])),
  },
  {
    eventConfig: {
      [LOAD_PLAN]: defaultPlanConfig,
      [LOAD_PA]: defaultPaConfig,
      [LOAD_SHIFT_SCHEDULE]: defaultShiftScheduleConfig,
    },
    handler: state => state.setIn(['guiState', 'loading'], true),
  },
];

export const handleCommonEventsForPlan = (state, action) => handleCommonDetailEvents(state, action, planEntity);
export const handleCommonEventsForMasterPlan = (state, action) =>
  handleCommonDetailEvents(state, action, masterPlanEntity);
export const handleCommonEventsForPlanningArea = (state, action) => handleCommonDetailEvents(state, action, paEntity);
export const handleCommonEventsForShiftSchedule = (state, action) =>
  handleCommonDetailEvents(state, action, shiftScheduleEntity);

function handleCommonDetailEvents(state, action, entityType) {
  for (const configItem of commonEntityReducerConfig) {
    const config = configItem.eventConfig[action.type];
    if (config && config.entityType === entityType) {
      return configItem.handler(state, action, config);
    }
  }
  return null;
}
