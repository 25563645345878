/*
 * MasterPlanMultipleReportRuns Messages
 *
 * This contains all the text for the MasterPlanMultipleReportRuns component.
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  uploadStartDate: {
    id: 'app.components.MasterPlanMultipleReportRuns.uploadStartDate',
    defaultMessage: 'Start date',
  },
  uploadEndDate: {
    id: 'app.components.MasterPlanMultipleReportRuns.uploadEndDate',
    defaultMessage: 'End date',
  },
});
