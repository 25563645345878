import * as React from 'react';
import styled from 'styled-components';

import { KpiItem, KpiObject } from 'containers/ShiftScheduleRunsPage/types';

interface KpiModalProps {
  kpi: KpiObject;
}

const ModalBodyWrap = styled.div`
  min-width: 60vw;
  min-height: 100px;
  overflow-y: auto;
  max-height: 90vh;
`;

const Cell = styled.div`
  padding: 2px 5px;
  overflow: hidden;
`;

const SectionWrap = styled.div`
  margin-bottom: 20px;
`;

const SectionLabel = styled.div`
  margin-top: 5px;
  padding: 2px 5px;
  font-size: 18px;
  font-weight: 700;
`;

const Table = styled.div`
  margin-top: 5px;
  display: inline-grid;
  grid-template-columns: ${props => props.columnDefs};
  gap: 3px 10px;
  justify-items: stretch;
`;

const TableHead = styled.div`
  margin-top: 5px;
  margin-bottom: 3px;
  padding: 2px 5px;
  font-weight: bold;
  border-bottom: 1px solid gray;
`;

function capitalizeWords(string) {
  return string.replace(/(?:^|\s)\S/g, a => a.toUpperCase());
}

const convertKeyToName = key => capitalizeWords(key.replaceAll('_', ' '));

const renderKpiSection = (sectionHeader: string, kpiItems: KpiItem[]) => {
  const columns = kpiItems.length ? Object.keys(kpiItems[0]) : [];
  const gridTemplateColumns = columns.map(() => 'auto ');

  return (
    <SectionWrap key={sectionHeader}>
      <SectionLabel>{capitalizeWords(sectionHeader)}</SectionLabel>
      <Table columnDefs={gridTemplateColumns}>
        {columns.map(c => (
          <TableHead key={c}>{capitalizeWords(c)}</TableHead>
        ))}
        {kpiItems.map((row, i) => (
          <React.Fragment key={i}>
            {columns.map(c => (
              <Cell key={`${i}-${c}`} title={c === 'name' ? row[c] : undefined}>
                {c === 'name' ? convertKeyToName(row[c]) : row[c]}
              </Cell>
            ))}
          </React.Fragment>
        ))}
      </Table>
    </SectionWrap>
  );
};

export const KpiData: React.FC<KpiModalProps> = props =>
  props.kpi && <ModalBodyWrap>{Object.keys(props.kpi).map(key => renderKpiSection(key, props.kpi[key]))}</ModalBodyWrap>;

export default KpiData;
