// @flow

import React, { useEffect, useState } from 'react';
import get from 'lodash/get';
import DateTime from 'luxon/src/datetime.js';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import Button from 'components/Button';
import ButtonWithDirtyCheck from 'components/ButtonWithDirtyCheck';
import DatePicker from 'components/DatePicker';
import IconButton from 'components/IconButton';
import Label from 'components/Label';
import { ItemSelectable } from 'components/Menu/ItemSelectable';
import Modal from 'components/Modal';
import AgTable from 'components/Table';
import { Section } from 'containers/PlanResultPage';
import { withUrl } from 'utils/api';
import { dateCellFormater, dateComparator } from 'utils/dateTime';
import { fetchData } from 'utils/reduxApi';

import { DeleteCell, OverviewCell, SendToKronosCell, StatusCell } from './ActionsCell';
import messages from './messages';

const Table = styled(AgTable)`
  margin-top: 20px;
  height: ${props => props.height}px;
`;

function columnDefs(
  props: Object,
  kronosName: string,
  onDelete: Function,
  sendToKronos: Function,
  downloadKronos: Function,
  showErrorMessage: Function,
) {
  const {
    intl: { formatMessage },
  } = props;
  return [
    {
      colId: 'delete',
      headerName: formatMessage(messages.delete),
      cellRendererFramework: DeleteCell,
      cellRendererParams: { onDelete },
      suppressMenu: true,
      sortable: false,
      width: 120,
    },
    {
      colId: 'overview',
      headerName: formatMessage(messages.overview),
      cellRendererFramework: OverviewCell,
      cellRendererParams: { downloadKronos },
      suppressMenu: true,
      sortable: false,
    },
    {
      colId: 'startDay',
      field: 'startDay',
      headerName: formatMessage(messages.startDate),
      filter: 'agDateColumnFilter',
      filterParams: {
        filterOptions: ['lessThan', 'greaterThan', 'inRange'],
        comparator: dateComparator,
      },
      valueFormatter: dateCellFormater,
      menuTabs: ['filterMenuTab'],
    },
    {
      colId: 'endDay',
      field: 'endDay',
      headerName: formatMessage(messages.endDate),
      filter: 'agDateColumnFilter',
      filterParams: {
        filterOptions: ['lessThan', 'greaterThan', 'inRange'],
        comparator: dateComparator,
      },
      menuTabs: ['filterMenuTab'],
      valueFormatter: dateCellFormater,
    },
    {
      colId: 'generated',
      field: 'audit.created',
      headerName: formatMessage(messages.generated),
      filter: 'agDateColumnFilter',
      // valueFormatter: params => params.value && params.value.substr(0, 10),
      filterParams: {
        filterOptions: ['lessThan', 'greaterThan', 'inRange'],
        comparator: dateComparator,
      },
      valueFormatter: dateCellFormater,
      menuTabs: ['filterMenuTab'],
    },
    {
      colId: 'generatedBy',
      field: 'audit.createdBy',
      valueFormatter: params => params.value && `${params.value.firstName} ${params.value.lastName}`,
      headerName: formatMessage(messages.generatedBy),
      menuTabs: ['filterMenuTab'],
    },
    {
      colId: 'status',
      field: 'status',
      cellRendererFramework: StatusCell,
      cellRendererParams: {
        showErrorMessage,
      },
      valueFormatter: params => {
        const { value } = params;
        return (value && ((value in messages && formatMessage(messages[value])) || value)) || '';
      },
      headerName: formatMessage(messages.status),
      menuTabs: ['filterMenuTab'],
    },
    {
      colId: 'kronos',
      headerName: formatMessage(messages.kronos),
      valueGetter: params => kronosName,
      suppressMenu: true,
      sortable: false,
    },
    {
      colId: 'granularity',
      field: 'granularity',
      headerName: formatMessage(messages.timeSpan),
      valueFormatter: params => {
        const { value } = params;
        return (value && ((value in messages && formatMessage(messages[value])) || value)) || '';
      },
      menuTabs: ['filterMenuTab'],
      sortable: false,
    },
    {
      colId: 'optimizeHeads',
      field: 'optimizeHeads',
      headerName: formatMessage(messages.headsOpt),
      menuTabs: ['filterMenuTab'],
      valueFormatter: params => {
        const { value } = params;
        if (value === true) return formatMessage(messages.yes);

        if (value === false) return formatMessage(messages.no);

        return '';
      },
    },
    {
      colId: 'sendToKronos',
      headerName: formatMessage(messages.sendToKronos),
      cellRendererFramework: SendToKronosCell,
      cellRendererParams: { sendToKronos },
      suppressMenu: true,
      sortable: false,
    },
  ];
}

type Props = {
  kronos?: {
    granularity: string,
    smartShiftConfiguration: {
      name: string,
      id: number | string,
    },
  },
  kronosId?: string,
  plan: Object,
  intl: Object,
  dispatch: Function,
  token: string,
  ppId: number | string, // planning parameters Id,
  formikBag: Object,
};

function KronosUploads(props: Props) {
  const {
    intl: { formatMessage },
    dispatch,
    kronos,
    kronosId,
    token,
    ppId,
    formikBag,
  } = props;
  const [startDate, setStartDate] = useState(DateTime.local().toISO());
  const [endDate, setEndDate] = useState(DateTime.local().toISO());
  const [openEndDate, setOpenEndDate] = useState(false);
  const [disableButtons, setDisableButtons] = useState(!kronos);
  const [uploads, setUploads] = useState([]);
  const [gridApi, setGridApi] = useState(null);
  const [optimizeHeads, setOptimizeHeads] = useState(false);
  const [pendingUpload, setPendingUpload] = useState(false);

  // Error Message for failed kronos
  const [error, setError] = useState(null);
  const [showError, setShowError] = useState(false);
  const savedKronosId = get(
    formikBag,
    'initialValues.planningParameters.smartShiftExportSettings.smartShiftConfiguration.id',
  );
  const kronosName = get(
    formikBag,
    'initialValues.planningParameters.smartShiftExportSettings.smartShiftConfiguration.name',
  );
  const url = `/planningParameters/${ppId}/kronos/`;

  function checkPendingUpload() {
    if (pendingUpload) {
      generateUploads();
      setPendingUpload(false);
    }
  }

  useEffect(() => {
    fetchKronosExports();
    setDisableButtons(!kronosId);
    checkPendingUpload();
    if (gridApi) {
      const colDefs = columnDefs(props, kronosName, deleteRow, sendToKronos, downloadKronos, showErrorMessage);
      gridApi.api.setColumnDefs(colDefs);
      gridApi.api.sizeColumnsToFit();
    }
  }, [token, savedKronosId]);

  useEffect(() => {
    checkPendingUpload();
  }, [kronos]);

  async function fetchKronosExports() {
    const result = await fetchData(withUrl(url).andToken(token), dispatch);
    if (result.isOk) setUploads(result.data);
  }

  function openEndDateCalendar(value) {
    setStartDate(value);
    setOpenEndDate(true);
  }

  function onAcceptEndDate(value) {
    setEndDate(value);
    setOpenEndDate(false);
  }

  async function generateUploads(params) {
    setDisableButtons(true);
    if (params) {
      const { waitForSaveAll } = params;
      if (waitForSaveAll !== undefined) {
        setPendingUpload(true);
        if (!waitForSaveAll) {
          formikBag.resetForm();
        }
        return;
      }
    }
    const result = await fetchData(
      withUrl(url)
        .put({
          startDay: DateTime.fromISO(startDate).toFormat('yyyy-LL-dd'),
          endDay: DateTime.fromISO(endDate).toFormat('yyyy-LL-dd'),
          granularity: (kronos && kronos.granularity) || null,
          optimizeHeads,
        })
        .andToken(token),
      dispatch,
    );
    if (result.isOk) setUploads(result.data);
    setDisableButtons(false);
  }

  async function deleteRow(row, params) {
    const deleteUrl = `/planningParameters/${ppId}/kronos/${row.id}/delete`;
    const result = await fetchData(withUrl(deleteUrl).post().andToken(token), dispatch);
    if (result.isOk) setUploads(result.data);
    toast(formatMessage(messages.deleted));
  }

  async function sendToKronos(props) {
    const sendUrl = `/planningParameters/${ppId}/kronos/${props.id}/send`;
    const result = await fetchData(withUrl(sendUrl).post().andToken(token), dispatch);
    if (result.isOk) setUploads(result.data);
  }

  async function downloadKronos(props) {
    const downloadUrl = `/planningParameters/${ppId}/kronos/${props.id}/download?type=CSV_REQUEST`;
    return await fetchData(
      withUrl(downloadUrl)
        .andContentType('text/csv')
        .setFileDownload(`${(kronos && kronosName) || ''}_${props.startDay}_${props.endDay}.csv`)
        .andToken(token),
      dispatch,
    );
  }

  const minLen = (uploads && uploads.length) || 1;
  let aproxHeight = Math.min(minLen, 10) * 36 + 50;
  if (aproxHeight < 150) aproxHeight = 150;

  function showErrorMessage(upload: string): void {
    setError(upload);
    setShowError(true);
  }

  function onGridReady(params) {
    setGridApi(params);
    params.api.sizeColumnsToFit();
  }

  async function downloadErrorLog() {
    const downloadUrl = `/planningParameters/${ppId}/kronos/${error.id}/download?type=ERROR_LOG`;
    await fetchData(
      withUrl(downloadUrl)
        .andContentType('text/csv')
        .setFileDownload(`errorlLog_${kronosName || ''}_${error.startDay}_${error.endDay}.csv`)
        .andToken(token),
      dispatch,
    );

    setShowError(false);
  }

  return (
    <Section message={messages.kronosUploads}>
      {showError && (
        <Modal
          isOpen={showError}
          onClose={() => setShowError(false)}
          title={messages.errorDetails}
          buttons={
            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
              <IconButton
                name="error_download"
                id="error_download"
                value="error_download"
                className="DownloadKronosErrorButton"
                alternative
                onClick={downloadErrorLog}
                label={messages.errorLog}
                icon="download"
              />

              <Button onClick={() => setShowError(false)}>{formatMessage(messages.close)}</Button>
            </div>
          }
        >
          <pre className="errorMessage">{error && error.errorMessage}</pre>
        </Modal>
      )}
      <div className="PlanInterfaces__Period">
        <div className="PlanInterfaces__Period__Item">
          <Label {...messages.startDate} className="PIP__Label" />
          <DatePicker
            value={startDate}
            onChange={value => setStartDate(value)}
            okLabel={formatMessage(messages.setStartDate)}
            onAccept={openEndDateCalendar}
            disablePast
          />
        </div>
        <div className="PlanInterfaces__Period__Item">
          <Label {...messages.endDate} className="PIP__Label" />
          <DatePicker
            value={endDate}
            onChange={value => setEndDate(value)}
            minDate={startDate}
            okLabel={formatMessage(messages.setEndDate)}
            open={openEndDate}
            onOpen={() => setOpenEndDate(true)}
            onClose={() => setOpenEndDate(false)}
            onAccept={onAcceptEndDate}
            disablePast
          />
        </div>
        <div className="PlanInterfaces__Period__Item">
          <Label {...messages.headsOpt} className="PIP__Label" />
          <ItemSelectable isSelected={optimizeHeads} onClick={() => setOptimizeHeads(!optimizeHeads)} />
        </div>
        <div className="PlanInterfaces__Period__Item">
          <ButtonWithDirtyCheck
            name="generate"
            message={messages.generate}
            actionHandler={generateUploads}
            disabled={disableButtons}
          />
        </div>
      </div>
      <Table
        columnDefs={columnDefs(props, kronosName, deleteRow, sendToKronos, downloadKronos, showErrorMessage)}
        rowData={uploads || []}
        height={aproxHeight}
        rowHeight={30}
        onGridReady={onGridReady}
      />
    </Section>
  );
}

export default connect()(injectIntl(KronosUploads));
