import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { FormikProps } from 'formik';
import { InjectedIntl, injectIntl } from 'react-intl';
import { RouteComponentProps } from 'react-router-dom';
import { toast } from 'react-toastify';
import { compose } from 'redux';
import styled from 'styled-components';

import CheckboxCellRenderer from 'components/CheckboxCellRenderer';
import IconButton from 'components/IconButton';
import Table from 'components/Table';
import { PATHS } from 'containers/App/constants';
import { formatDate } from 'utils/dateTime';
import EnchantedMap from 'utils/enchantedMap';
import { useEffectDeepCompare } from 'utils/utils';
import {
  ApiScheduleActivityDTO,
  ApiScheduleDepartmentDTO,
  ApiScheduleDTO,
  ApiScheduleFacilityDTO,
  ApiSchedulePlanDTO,
} from 'types/drep-backend.d';

import messages from './messages';
import { convertArrayToMap } from './utils';

const productivityTypeTextByType = (intl, productivityType) => {
  switch (productivityType) {
    case 'DIRECT':
      return intl.formatMessage(messages.productivityTypeDirect);
    case 'INDIRECT_EFFORT':
      return intl.formatMessage(messages.productivityTypeIndirectEffort);
    case 'LOCATION_UNPRODUCTIVE':
      return intl.formatMessage(messages.productivityTypeIndirectLocationUnproductive);
    case 'STAFF_UNPRODUCTIVE':
      return intl.formatMessage(messages.productivityTypeIndirectStaffUnproductive);
    default:
      return '';
  }
};

const getColumnDefs = (intl, formik, history, editable) => {
  const SmallButton = styled(IconButton)`
    height: 20px;
  `;

  const goToDetail = id => () =>
    history.push(PATHS.smartShiftJobScheduleDetailId.replace(':id', id).replace(':scheduleId', formik.values.id));
  const goToDetailButton = params => {
    const id = params.data.jobSchedule?.id;
    return id ? <SmallButton onClick={goToDetail(id)} label={messages.detail} /> : <span />;
  };

  const getAvailableActivitiesForRow = params => {
    const filteredJobs = formik.values.smartShiftJobSchedules
      ?.map((js, jobScheduleIndex) => ({
        label: js.activity,
        id: js.id,
        smartShiftJobId: js.smartShiftJobId,
        department: js.department,
        customer: js.customer?.name || null,
        facility: js.facility?.name || null,
        jobScheduleIndex,
      }))
      ?.filter(
        js =>
          js.department === params.data?.department &&
          js?.customer === params.data?.customer &&
          js?.facility === params.data?.facility,
      );
    // do not let the unassign action when there is a same named job that would be automatically assigned again
    let hasJobWithSameName = false;
    filteredJobs.forEach(job => {
      hasJobWithSameName = hasJobWithSameName || job.label === params.data.activity.activity.name;
    });
    return [
      ...(params.data?.jobSchedule && !hasJobWithSameName
        ? [{ id: null, smartShiftJobId: null, label: intl.formatMessage(messages.activityTableUnassign) }]
        : []),
      ...filteredJobs,
    ];
  };

  return [
    {
      colId: 'jobScheduleName',
      field: 'jobScheduleName',
      headerName: intl.formatMessage(messages.smartShiftJob),
      headerTooltip: intl.formatMessage(messages.smartShiftJob),
      width: 150,
      filter: 'setFilter',
      sortable: true,
      suppressSizeToFit: true,
      resizable: true,
      editable: params => editable && (!!getAvailableActivitiesForRow(params).length || params.data.jobSchedule),
      singleClickEdit: true,
      cellEditor: 'agRichSelectCellEditor',
      cellEditorParams: params => ({
        values: getAvailableActivitiesForRow(params),
      }),
      filterValueGetter: params => (params.value?.label ? params.value.label : params?.data?.jobSchedule?.activity),
      valueFormatter: params => (params.value.label ? params.value.label : params?.data?.jobSchedule?.activity),
      valueSetter: params => {
        if (typeof params.newValue !== 'object') {
          return false;
        }
        formik.setFieldValue(
          'smartShiftJobSchedules',
          formik.values.smartShiftJobSchedules.map(ssjs => ({
            ...ssjs,
            scheduleActivityIds:
              ssjs.id === params.newValue?.id && ssjs.smartShiftJobId === params.newValue?.smartShiftJobId
                ? [...ssjs.scheduleActivityIds, params.data.activity.id]
                : ssjs.scheduleActivityIds.filter(aId => aId !== params.data.activity.id),
          })),
        );
        return true;
      },
    },
    {
      colId: 'activity.activity.name',
      field: 'activity.activity.name',
      headerName: intl.formatMessage(messages.activityName),
      width: 150,
      suppressSizeToFit: true,
      headerTooltip: intl.formatMessage(messages.activityName)
    },
    {
      colId: 'activity.productivityType',
      field: 'activity.productivityType',
      headerName: intl.formatMessage(messages.type),
      valueGetter: params => productivityTypeTextByType(intl, params.data.productivityType),
      width: 200,
      headerTooltip: intl.formatMessage(messages.type),
    },
    {
      colId: 'facility',
      field: 'facility',
      headerName: intl.formatMessage(messages.activityFacility),
      headerTooltip: intl.formatMessage(messages.activityFacility),
    },
    {
      colId: 'department',
      field: 'department',
      headerName: intl.formatMessage(messages.activityDepartment),
      headerTooltip: intl.formatMessage(messages.activityDepartment),
    },
    {
      colId: 'customer',
      field: 'customer',
      headerName: intl.formatMessage(messages.activityCustomer),
      headerTooltip: intl.formatMessage(messages.activityCustomer),
    },
    {
      colId: 'plan',
      field: 'plan',
      headerName: intl.formatMessage(messages.activityPlan),
      headerTooltip: intl.formatMessage(messages.activityPlan),
    },
    {
      colId: 'jobSchedule.openShiftGroup',
      field: 'jobSchedule.openShiftGroup',
      filter: 'setFilter',
      headerName: intl.formatMessage(messages.openShiftGroups),
      filterValueGetter: params => params?.data?.jobSchedule?.openShiftGroup,
      headerTooltip: intl.formatMessage(messages.openShiftGroups),
    },
    {
      colId: 'validFrom',
      field: 'validFrom',
      valueGetter: params => formatDate(params.data.validFrom),
      headerName: intl.formatMessage(messages.validFrom),
      headerTooltip: intl.formatMessage(messages.validFrom),
      width: 120,
    },
    {
      colId: 'validTo',
      field: 'validTo',
      valueGetter: params => formatDate(params.data.validTo),
      headerName: intl.formatMessage(messages.validTo),
      headerTooltip: intl.formatMessage(messages.validTo),
      width: 120,
    },
    {
      colId: 'jobSchedule.coveragePriority',
      field: 'jobSchedule.coveragePriority',
      width: 100,
      editable: params => editable && params.data.jobSchedule,
      headerName: intl.formatMessage(messages.coveragePriority),
      headerTooltip: intl.formatMessage(messages.coveragePriority),
      type: 'numericColumn',
      valueSetter: params => {
        const dataPath = `smartShiftJobSchedules.${params.data.jobScheduleIndex}.coveragePriority`;
        const theVal = parseInt(params.newValue, 10);
        if (!Number.isNaN(theVal)) {
          if (theVal < 0 || theVal > 10) {
            toast.error(intl.formatMessage(messages.coveragePriorityLimits));
          } else {
            formik.setFieldValue(dataPath, theVal);
          }
        }
      },
    },
    {
      colId: 'jobSchedule.allowLeftoverSpread',
      field: 'jobSchedule.allowLeftoverSpread',
      editable: params => editable && params.data.jobSchedule,
      headerName: intl.formatMessage(messages.allowLeftoverSpread),
      headerTooltip: intl.formatMessage(messages.allowLeftoverSpread),
      cellRendererFramework: CheckboxCellRenderer,
      cellRendererParams: params => ({
        editable: editable && params.data.jobSchedule,
      }),
      valueSetter: params => {
        const dataPath = `smartShiftJobSchedules.${params.data.jobScheduleIndex}.allowLeftoverSpread`;
        formik.setFieldValue(dataPath, !params.oldValue);
      },
    },
    {
      colId: 'activity.ignore',
      field: 'activity.ignore',
      editable: editable,
      headerName: intl.formatMessage(messages.ignoreActivity),
      headerTooltip: intl.formatMessage(messages.ignoreActivity),
      cellRendererFramework: CheckboxCellRenderer,
      cellRendererParams: {
        editable: editable
      },
      valueSetter: params => {
        const dataPath = `activities.${params.data.index}.ignore`;
        formik.setFieldValue(dataPath, !params.oldValue);
      },
    },
    {
      cellRendererFramework: goToDetailButton,
      cellRendererParams: params => ({
        jobSchedule: params.data.jobSchedule,
      }),
      colId: 'goToDetailButton',
      field: 'jobSchedule.id',
      headerName: '',
      width: 100,
      suppressMenu: true,
    },
  ];
};

const WrapWithStyles = styled.div`
  .no-kronos {
    background-color: ${props => props.theme.color.violetLL} !important;
  }
`;

interface ActivitiesTableProps {
  intl: InjectedIntl;
  history: RouteComponentProps.history;
  formik: FormikProps<ApiScheduleDTO>;
  isEdit: boolean;
}

const ActivitiesTable: React.FC<ActivitiesTableProps> = ({ intl, history, formik, isEdit }) => {
  const editable = isEdit;
  const [data, setData] = useState(null);
  const [gridApi, setGridApi] = useState(null);
  const [colDefs, setColDefs] = useState([]);
  const [plansById, setPlansById] = useState(new EnchantedMap<number, ApiSchedulePlanDTO>());
  const [facilityById, setFacilityById] = useState(new EnchantedMap<number, ApiScheduleFacilityDTO>());
  const [departmentById, setDepartmentById] = useState(new EnchantedMap<number, ApiScheduleDepartmentDTO>());

  const onGridReady = params => {
    setGridApi(params.api);
    params.api.sizeColumnsToFit();
  };

  const facilityByDepartmentId = useCallback(
    departmentId => {
      const f = departmentById.get(departmentId)?.facilityId || -1;
      return facilityById.get(f);
    },
    [facilityById, departmentById],
  );

  const activitySort = useCallback(
    (a, b) => {
      const jobA = a.jobSchedule?.activity;
      const jobB = b.jobSchedule?.activity;
      if (jobA === jobB) {
        return 0;
      }
      if (!jobA || jobA > jobB) {
        return 1;
      }
      return -1;
    },
    [facilityByDepartmentId, departmentById, plansById],
  );

  useEffect(() => {
    setFacilityById(convertArrayToMap(formik.values.facilities));
    setDepartmentById(convertArrayToMap(formik.values.departments));
    setPlansById(convertArrayToMap(formik.values.plans, plan => plan.planId));
  }, [formik.values.facilities, formik.values.departments, formik.values.plans]);

  useEffectDeepCompare(() => {
    setColDefs(getColumnDefs(intl, formik, history, editable));
  }, [intl, formik.values.activities, formik.values.smartShiftJobSchedules, formik.values.plans, history, editable]);

  useEffect(() => {
    if (gridApi) {
      gridApi.setColumnDefs(colDefs);
      gridApi.redrawRows();
      gridApi.refreshCells({ force: true });
    }
  }, [gridApi, colDefs]);

  useEffect(() => {
    setData(
      formik.values.activities
        .map((activity: ApiScheduleActivityDTO, index) => {
          const plan = plansById.get(activity.planId);

          let jobSchedule = null;
          let jobScheduleIndex = null;
          let jobScheduleName = '';
          formik.values.smartShiftJobSchedules.forEach((js, i) => {
            const isAssigned = js.scheduleActivityIds.find(aId => aId === activity.id);
            if (isAssigned) {
              jobSchedule = js;
              jobScheduleIndex = i;
              jobScheduleName = js.activity;
            }
          });

          return {
            activity,
            jobSchedule,
            jobScheduleName,
            jobScheduleIndex,
            editable,
            customer: activity.customer?.name || '',
            department: departmentById.get(activity.departmentId)?.name || '',
            facility: facilityByDepartmentId(activity.departmentId)?.name || '',
            id: activity.id,
            index,
            plan: plan ? plan.plan.name : '',
            productivityType: activity.activity ? activity.activity.productivityType : '',
            uom: activity.uom ? activity.uom.name : '',
            validFrom: plan ? plan.validFrom : '',
            validTo: plan ? plan.validTo : '',
          };
        })
        .sort(activitySort),
    );
  }, [
    formik.values.activities,
    formik.values.smartShiftJobSchedules,
    activitySort,
    plansById,
    facilityByDepartmentId,
    editable,
  ]);
  const columnsToExport  = ['jobScheduleName', 'activity.activity.name', 'activity.productivityType', 'facility', 'department', 'customer', 'plan', 'jobSchedule.openShiftGroup', 'validFrom', 'validTo', 'jobSchedule.coveragePriority', 'jobSchedule.allowLeftoverSpread', 'activity.ignore'];
  return (
    <WrapWithStyles>
      <Table
        key={editable}
        messages={messages}
        pagination={false}
        columnDefs={colDefs}
        rowData={data}
        domLayout="autoHeight"
        onGridReady={onGridReady}
        defaultExportParams={{
          columnKeys: columnsToExport
        }}
      />
    </WrapWithStyles>
  );
};

export default compose(injectIntl)(ActivitiesTable);
