import { defineMessages } from 'react-intl';

const scope = 'app.containers.SmartProdSourceDetailPage';
const scopeToolbar = 'app.containers.SmartProdSourceDetailPage.Toolbar';
const scopeEvents = 'app.containers.SmartProdSourceDetailPage.Events';
const usedPAP = 'app.containers.SmartProdSourceDetailPage.usedPAP';
const smartVolumeRun = 'app.containers.SmartProdSourceDetailPage.smartVolumeRun';
const scopeHolidays = 'app.containers.SmartProdSourceDetailPage.Holidays';
const scopeOMSCodesAndSmartProdJobs = 'app.containers.SmartProdSourceDetailPage.OMSCodesAndSmartProdJobs';

export default defineMessages({
  // Page
  header: {
    id: `${scope}.header`,
    defaultMessage: 'SmartProd Source',
  },
  content: {
    id: `${scope}.content`,
    defaultMessage: 'SmartProd Source Detail',
  },
  basicInfo: {
    id: `${scope}.basicInfo`,
    defaultMessage: 'Basic Information',
  },
  basicSettings: {
    id: `${scope}.basicSettings`,
    defaultMessage: 'Basic Settings',
  },
  smartVolumeScheduling: {
    id: `${scope}.smartVolumeScheduling`,
    defaultMessage: 'SmartVolume Scheduling',
  },
  periodSelection: {
    id: `${scope}.periodSelectionSection`,
    defaultMessage: 'Period Selection',
  },
  activityAssignment: {
    id: `${scope}.activityAssignmentSection`,
    defaultMessage: 'Mapping OMS Codes Activities and SmartProd Jobs',
  },
  forecastPerOMSCode: {
    id: `${scope}.forecastPerOMSCode`,
    defaultMessage: 'Forecast per OMS Code',
  },

  // Toolbar
  back: {
    id: `${scopeToolbar}.back`,
    defaultMessage: 'Back',
  },
  edit: {
    id: `${scopeToolbar}.edit`,
    defaultMessage: 'Edit',
  },
  display: {
    id: `${scopeToolbar}.display`,
    defaultMessage: 'Display',
  },
  upload: {
    id: `${scopeToolbar}.upload`,
    defaultMessage: 'Upload',
  },
  scheduleSmartVolume: {
    id: `${scopeToolbar}.scheduleSmartVolume`,
    defaultMessage: 'Schedule SmartVolume',
  },
  scheduleSmartVolumeWarning: {
    id: `${scopeToolbar}.scheduleSmartVolumeWarning`,
    defaultMessage: 'Start date of the site not available for this SmartProd Source',
  },
  disable: {
    id: `${scopeToolbar}.disable`,
    defaultMessage: 'Disable',
  },
  enable: {
    id: `${scopeToolbar}.enable`,
    defaultMessage: 'Enable',
  },
  facility: {
    id: `${scope}.facility`,
    defaultMessage: 'Facility',
  },
  smartProdInstance: {
    id: `${scope}.smartProdInstance`,
    defaultMessage: 'SmartProd Instance',
  },
  whid: {
    id: `${scope}.whid`,
    defaultMessage: 'WHID',
  },
  description: {
    id: `${scope}.description`,
    defaultMessage: 'Description',
  },
  startDateSite: {
    id: `${scope}.startDateSite`,
    defaultMessage: 'Start Date of the Site',
  },
  requestSmartVolumeForecast:{
    id: `${scope}.requestSmartVolumeForecast`,
    defaultMessage: 'Request SmartVolume forecast',
  },
  confirmDirty: {
    id: `${scope}.confirmDirty`,
    defaultMessage: 'You have unsaved changes. Do you want to leave anyway?',
  },
  scheduleStartDate: {
    id: `${scope}.scheduleStartDate`,
    defaultMessage: 'Schedule Start Date',
  },
  scheduleTime: {
    id: `${scope}.scheduleTime`,
    defaultMessage: 'Schedule Time',
  },
  schedulePeriod: {
    id: `${scope}.schedulePeriod`,
    defaultMessage: 'Schedule Period (days)',
  },
  refreshNow: {
    id: `${scope}.refreshNow`,
    defaultMessage: 'Refresh Now',
  },
  scheduleDialogHeaderLabel: {
    id: `${scope}.scheduleDialogHeaderLabel`,
    defaultMessage: 'SmartVolume Scheduling for {smartProdInstance}/{smartProdWarehouseId}',
  },
  scheduleParamsValidationError: {
    id: `${scope}.scheduleParamsValidationError`,
    defaultMessage: 'Either all or none of schedule parameters must be set',
  },
  scheduleDialogSave: {
    id: `${scope}.scheduleDialogSave`,
    defaultMessage: 'Save',
  },
  scheduleDialogCancelLabel: {
    id: `${scope}.scheduleDialogCancelLabel`,
    defaultMessage: 'Cancel',
  },
  scheduleDialogClearSchedule: {
    id: `${scope}.scheduleDialogClearSchedule`,
    defaultMessage: 'Clear Schedule',
  },
  smartVolumeTableCellScheduleStartDate: {
    id: `${scope}.smartVolumeTableCellScheduleStartDate`,
    defaultMessage: 'Schedule Start Date',
  },
  smartVolumeTableCellScheduleTime: {
    id: `${scope}.smartVolumeTableCellScheduleTime`,
    defaultMessage: 'Schedule Time',
  },
  smartVolumeTableCellSchedulePeriodDays: {
    id: `${scope}.smartVolumeTableCellSchedulePeriodDays`,
    defaultMessage: 'Schedule Period (Days)',
  },
  //warnings
  noWarningsAvailable: {
    id: `${scopeEvents}.noWarningsAvailable`,
    defaultMessage: 'No warnings available to display.',
  },
  warning: {
    id: `${scopeEvents}.warning`,
    defaultMessage: 'Warnings',
  },
  warningLoader:{
    id: `${scopeEvents}.warningLoader`,
    defaultMessage: 'Please wait, while we fetch the warnings',
  },
  warningAvailable: {
    id: `${scopeEvents}.warningAvailable`,
    defaultMessage: 'There are warnings for this SmartProd source!',
  },
  warningAvailablePlan: {
    id: `${scopeEvents}.warningAvailablePlan`,
    defaultMessage: 'There are warnings for this Plan!',
  },
  warningAvailablePlaningArea: {
    id: `${scopeEvents}.warningAvailablePlaningArea`,
    defaultMessage: 'There are warnings for this Planning Area!',
  },
  warningNotAvailable: {
    id: `${scopeEvents}.warningNotAvailable`,
    defaultMessage: 'Warnings not available for this SmartProd source!',
  },

  // Events
  eventsTitle: {
    id: `${scopeEvents}.title`,
    defaultMessage: 'Events',
  },
  newEventFormName: {
    id: `${scopeEvents}.newEventFormName`,
    defaultMessage: 'Name',
  },
  newEventFormCategory: {
    id: `${scopeEvents}.newEventFormCategory`,
    defaultMessage: 'Category',
  },
  newEventFormSmartProd: {
    id: `${scopeEvents}.newEventFormSmartProd`,
    defaultMessage: 'SmartProd',
  },
  copyEventButton: {
    id: `${scopeEvents}.copyEventButton`,
    defaultMessage: 'Copy',
  },
  deleteEventButton: {
    id: `${scopeEvents}.deleteEventButton`,
    defaultMessage: 'Delete',
  },
  eventsTableName: {
    id: `${scopeEvents}.eventsTableName`,
    defaultMessage: 'Name',
  },
  eventsTableDescription: {
    id: `${scopeEvents}.eventsTableDescription`,
    defaultMessage: 'Description',
  },
  eventsTableSmartProd: {
    id: `${scopeEvents}.eventsTableSmartProd`,
    defaultMessage: 'SmartProd',
  },
  eventsTableCategory: {
    id: `${scopeEvents}.eventsTableCategory`,
    defaultMessage: 'Category',
  },
  eventsTableSubCategory: {
    id: `${scopeEvents}.eventsTableSubCategory`,
    defaultMessage: 'Subcategory',
  },
  eventsTableOrigin: {
    id: `${scopeEvents}.eventsTableOrigin`,
    defaultMessage: 'Origin',
  },
  eventsTableType: {
    id: `${scopeEvents}.eventsTableType`,
    defaultMessage: 'Type',
  },
  eventsTableTypeOneOff: {
    id: `${scopeEvents}.eventsTableTypeOneOff`,
    defaultMessage: 'One-off',
  },
  eventsTableTypeRecurring: {
    id: `${scopeEvents}.eventsTableTypeRecurring`,
    defaultMessage: 'Recurring',
  },
  eventsTableValidationErrorEventName: {
    id: `${scopeEvents}.eventsTableValidationErrorEventName`,
    defaultMessage: "Event '{event}': {error}",
  },
  eventsTableImpact: {
    id: `${scopeEvents}.eventsTableImpact`,
    defaultMessage: 'Impact',
  },
  eventsTableImpactValidation: {
    id: `${scopeEvents}.eventsTableImpactValidation`,
    defaultMessage: 'Value should be a number between -100 and +1000',
  },
  eventsTableSelectedDaysValidation: {
    id: `${scopeEvents}.eventsTableSelectedDaysValidation`,
    defaultMessage: 'Select at least one day of week',
  },
  eventsTableSelectedOMSCodeValidation: {
    id: `${scopeEvents}.eventsTableSelectedOMSCodeValidation`,
    defaultMessage: 'Select at least one OMS code',
  },
  eventsTableSelectedSmartProdSourceValidation: {
    id: `${scopeEvents}.eventsTableSelectedSmartProdSourceValidation`,
    defaultMessage: 'Select SmartProd',
  },
  eventsTableStartDate: {
    id: `${scopeEvents}.eventsTableStartDate`,
    defaultMessage: 'Start Date',
  },
  eventsTableEndDate: {
    id: `${scopeEvents}.eventsTableEndDate`,
    defaultMessage: 'End Date',
  },
  eventsTableActions: {
    id: `${scopeEvents}.eventsTableActions`,
    defaultMessage: 'Actions',
  },
  eventsTableDaysOfWeek: {
    id: `${scopeEvents}.eventsTableDaysOfWeek`,
    defaultMessage: 'Days of Week',
  },
  eventsTableOMSCodes: {
    id: `${scopeEvents}.eventsTableOMSCodes`,
    defaultMessage: 'OMS Codes',
  },
  eventsTableCreated: {
    id: `${scopeEvents}.eventsTableCreated`,
    defaultMessage: 'Created',
  },
  eventsTableCreatedBy: {
    id: `${scopeEvents}.eventsTableCreatedBy`,
    defaultMessage: 'Created by',
  },
  eventsTableConsideredButton: {
    id: `${scopeEvents}.eventsTableConsideredButton`,
    defaultMessage: 'Considered',
  },
  eventsTableNotConsideredButton: {
    id: `${scopeEvents}.eventsTableNotConsideredButton`,
    defaultMessage: 'Not Considered',
  },
  eventsTableAddAllOMSCodes: {
    id: `${scopeEvents}.eventsTableAddAllOMSCodes`,
    defaultMessage: 'Add All OMS Codes',
  },
  eventsTableRemoveAllOMSCodes: {
    id: `${scopeEvents}.eventsTableRemoveAllOMSCodes`,
    defaultMessage: 'Unselect All OMS Codes',
  },

  // holidays
  holidaysTitle: {
    id: `${scopeHolidays}.holidaysTitle`,
    defaultMessage: 'Holidays',
  },
  holidaysTableName: {
    id: `${scopeHolidays}.holidaysTableName`,
    defaultMessage: 'Name',
  },
  holidaysTableLocalName: {
    id: `${scopeHolidays}.holidaysTableLocalName`,
    defaultMessage: 'Local Name',
  },
  holidaysTableDescription: {
    id: `${scopeHolidays}.holidaysTableDescription`,
    defaultMessage: 'Description',
  },
  holidaysTableCategory: {
    id: `${scopeHolidays}.holidaysTableCategory`,
    defaultMessage: 'Category',
  },
  holidaysTableStartDate: {
    id: `${scopeHolidays}.holidaysTableStartDate`,
    defaultMessage: 'Start Date',
  },
  holidaysTableEndDate: {
    id: `${scopeHolidays}.holidaysTableEndDate`,
    defaultMessage: 'End Date',
  },
  holidaysTableDate: {
    id: `${scopeHolidays}.holidaysTableDate`,
    defaultMessage: 'Date',
  },
  holidaysTableSource: {
    id: `${scopeHolidays}.holidaysTableSource`,
    defaultMessage: 'Source System',
  },
  holidaysTableCountry: {
    id: `${scopeHolidays}.holidaysTableCountry`,
    defaultMessage: 'Country',
  },
  holidaysTableDistrict: {
    id: `${scopeHolidays}.holidaysTableDistrict`,
    defaultMessage: 'Counties',
  },
  holidaysTableDistricts: {
    id: `${scopeHolidays}.holidaysTableDistricts`,
    defaultMessage: 'District',
  },
  holidaysTableHolidayType: {
    id: `${scopeHolidays}.holidaysTableHolidayType`,
    defaultMessage: 'Holiday Type',
  },
  holidaysTableSmartProd: {
    id: `${scopeHolidays}.holidaysTableSmartProd`,
    defaultMessage: 'SmartProd',
  },
  holidaysTableOMSCodes: {
    id: `${scopeHolidays}.holidaysTableOMSCodes`,
    defaultMessage: 'OMS Codes',
  },
  assignedHolidaysTitle: {
    id: `${scopeHolidays}.assignedHolidaysTitle`,
    defaultMessage: 'Assigned Holidays',
  },
  notAssignedHolidaysTitle: {
    id: `${scopeHolidays}.notAssignedHolidaysTitle`,
    defaultMessage: 'Not assigned Holidays',
  },
  holidaysTableErrorHolidayName: {
    id: `${scopeEvents}.holidaysTableErrorHolidayName`,
    defaultMessage: "Holiday '{holiday}': {error}",
  },
  holidaysTableAllOMSCodes: {
    id: `${scopeEvents}.omsIds`,
    defaultMessage: 'All OMS Code',
  },

  // Used Plans and Planning Areas

  //usedPlanAndPATitle
  //usedPAP

  usedPlanAndPATitle: {
    id: `${usedPAP}.usedPlanAndPATitle`,
    defaultMessage: "Used on Plans and Planning Areas",
  },
  usedPlanAndPATitleBase: {
    id: `${usedPAP}.usedPlanAndPATitleBase`,
    defaultMessage: "Used on Plans and Planning Areas (baseline)",
  },
  plan: {
    id: `${usedPAP}.plan`,
    defaultMessage: "Plans",
  },
  planningArea: {
    id: `${usedPAP}.planningArea`,
    defaultMessage: "Planning Areas",
  },
  planningAreaBase:{
    id: `${usedPAP}.planningAreaBase`,
    defaultMessage: "Planning Areas (baseline)",
  },
  namePlan: {
    id: `${usedPAP}.namePlan`,
    defaultMessage: "Name",
  },
  planningAreaPlan: {
    id: `${usedPAP}.planningAreaTable`,
    defaultMessage: "Planning Area",
  },
  planningAreaPlanBase:{
    id: `${usedPAP}.planningAreaPlanBase`,
    defaultMessage: "Planning Areas (baseline)",
  },
  facilityPlan: {
    id: `${usedPAP}.facility`,
    defaultMessage: "Facility",
  },
  managementUnitPlan: {
    id: `${usedPAP}.managementUnitPlan`,
    defaultMessage: "Management Unit",
  },
  customerPlan: {
    id: `${usedPAP}.customerPlan`,
    defaultMessage: "Customer",
  },
  modifiedPlan: {
    id: `${usedPAP}.modified`,
    defaultMessage: "Modified",
  },
  modifiedByPlan: {
    id: `${usedPAP}.modifiedByPlan`,
    defaultMessage: "Modified by",
  },
  namePA: {
    id: `${usedPAP}.namePA`,
    defaultMessage: "Name",
  },
  facilityPA: {
    id: `${usedPAP}.facilityPA`,
    defaultMessage: "Facility",
  },
  managementUnitPA: {
    id: `${usedPAP}.managementUnitPA`,
    defaultMessage: "Management Unit",
  },
  customerPA: {
    id: `${usedPAP}.customerPA`,
    defaultMessage: "Customer",
  },
  modifiedPA: {
    id: `${usedPAP}.modifiedPA`,
    defaultMessage: "Modified",
  },
  modifiedByPA: {
    id: `${usedPAP}.modifiedByPA`,
    defaultMessage: "Modified by",
  },
  //smartVolumeRun
  smartVolumeRun: {
    id: `${usedPAP}.smartVolumeRun`,
    defaultMessage: "SmartVolume Runs",
  },
  requested: {
    id: `${usedPAP}.requested`,
    defaultMessage: "Requested",
  },
  smartProd: {
    id: `${usedPAP}.smartProd`,
    defaultMessage: "SmartProd",
  },
  type: {
    id: `${usedPAP}.type`,
    defaultMessage: "Type",
  },
  finished: {
    id: `${usedPAP}.finished`,
    defaultMessage: "Finished",
  },
  requestedBy: {
    id: `${usedPAP}.requestedBy`,
    defaultMessage: "Requested By",
  },
  status: {
    id: `${usedPAP}.status`,
    defaultMessage: "Status",
  },
  deleted: {
    id: `${usedPAP}.deleted`,
    defaultMessage: "Status",
  },
  smartVolumeRunsFinished: {
    id: `${scope}.smartVolumeRunsFinished`,
    defaultMessage: 'Finished',
  },
  smartVolumeRunsRequestedBy: {
    id: `${scope}.smartVolumeRunsRequestedBy`,
    defaultMessage: 'Requested by',
  },
  smartVolumeRunsStatus: {
    id: `${scope}.smartVolumeRunsStatus`,
    defaultMessage: 'Status',
  },
  smartVolumeRunsForecastPeriod: {
    id: `${scope}.smartVolumeRunsForecastPeriod`,
    defaultMessage: 'Forecast Period',
  },
  smartVolumeRunsStartDate: {
    id: `${scope}.smartVolumeRunsStartDate`,
    defaultMessage: 'Start Date',
  },
  smartVolumeRunsEndDate: {
    id: `${scope}.smartVolumeRunsEndDate`,
    defaultMessage: 'End Date',
  },
  smartVolumeRunsLearningDate: {
    id: `${scope}.smartVolumeRunsLearningDate`,
    defaultMessage: 'SmartVolume Learning Date',
  },
  smartVolumeRunsDownloadFile: {
    id: `${scope}.smartVolumeRunsDownloadFile`,
    defaultMessage: 'SmartVolume Response',
  },
  smartVolumeRunsMessages: {
    id: `${scope}.smartVolumeRunsMessages`,
    defaultMessage: 'SmartVolume Messages',
  },
  downloadResponseTooltip: {
    id: `${scope}.downloadResponseTooltip`,
    defaultMessage: 'Download Response',
  },
  smartVolumeRunsRequested: {
    id: `${scope}.smartVolumeRunsRequested`,
    defaultMessage: 'Requested',
  },
  // OMS Codes
  omsCodeTitle: {
    id: `${usedPAP}.omsCodeTitle`,
    defaultMessage: "Start Dates per OMS Code",
  },
  omsDelete: {
    id: `${usedPAP}.omsDelete`,
    defaultMessage: "OMS Name",
  },
  omsName: {
    id: `${usedPAP}.omsName`,
    defaultMessage: "OMS Name",
  },
  omsCode: {
    id: `${usedPAP}.omsCode`,
    defaultMessage: "OMS Code",
  },
  omsCodeStartDate: {
    id: `${usedPAP}.omsCodeStartDate`,
    defaultMessage: "Start Date",
  },
  // scope OMS Codes table
  filterToSelectedOMSCode: {
    id: `${scopeOMSCodesAndSmartProdJobs}.filterToSelectedOMSCode`,
    defaultMessage: 'Filter',
  },
  clearFilterToSelectedOMSCode: {
    id: `${scopeOMSCodesAndSmartProdJobs}.clearFilterToSelectedOMSCode`,
    defaultMessage: 'Clear Filter',
  },
  filterToSelectedOMSCodeTooltip: {
    id: `${scopeOMSCodesAndSmartProdJobs}.filterToSelectedOMSCodeTooltip`,
    defaultMessage: 'Filter to Selected OMSCode',
  },
  assignToSelectedSmartProdJobCodes: {
    id: `${scopeOMSCodesAndSmartProdJobs}.assignToSelectedSmartProdJobCodes`,
    defaultMessage: 'Assign',
  },
  assignToSelectedSmartProdJobCodesTooltip: {
    id: `${scopeOMSCodesAndSmartProdJobs}.assignToSelectedSmartProdJobCodesTooltip`,
    defaultMessage: 'Assign to Selected SmartProd Job Codes',
  },
  copyMapping: {
    id: `${scopeOMSCodesAndSmartProdJobs}.copyMapping`,
    defaultMessage: 'Copy Mapping',
  },
  copyMappingDialogHeader: {
    id: `${scopeOMSCodesAndSmartProdJobs}.copyMappingDialogHeader`,
    defaultMessage: 'Copy Mapping',
  },
  copyMappingButtonTooltip: {
    id: `${scopeOMSCodesAndSmartProdJobs}.copyMappingButtonTooltip`,
    defaultMessage:
      'You can copy mapping from another plan. Mapping will be copied only for SmartPlan activities that exist in this plan and the plan you copy from.',
  },
  copyMappingDialogSave: {
    id: `${scopeOMSCodesAndSmartProdJobs}.copyMappingDialogSave`,
    defaultMessage: 'Copy Selected',
  },
  copyMappingDialogCancel: {
    id: `${scopeOMSCodesAndSmartProdJobs}.copyMappingDialogCancel`,
    defaultMessage: 'Cancel',
  },
  copyMappingDialogOverride: {
    id: `${scopeOMSCodesAndSmartProdJobs}.copyMappingDialogOverride`,
    defaultMessage: 'Override',
  },
  copyMappingDialogType: {
    id: `${scopeOMSCodesAndSmartProdJobs}.copyMappingDialogType`,
    defaultMessage: 'Type',
  },
  copyMappingDialogTypePlan: {
    id: `${scopeOMSCodesAndSmartProdJobs}.copyMappingDialogTypePlan`,
    defaultMessage: 'Plan',
  },
  copyMappingDialogTypePlanningArea: {
    id: `${scopeOMSCodesAndSmartProdJobs}.copyMappingDialogTypePlanningArea`,
    defaultMessage: 'Planning Area',
  },
  copyMappingDialogName: {
    id: `${scopeOMSCodesAndSmartProdJobs}.copyMappingDialogName`,
    defaultMessage: 'Name',
  },
  copyMappingDialogCustomer: {
    id: `${scopeOMSCodesAndSmartProdJobs}.copyMappingDialogCustomer`,
    defaultMessage: 'Customers',
  },
  copyMappingDialogFacility: {
    id: `${scopeOMSCodesAndSmartProdJobs}.copyMappingDialogFacility`,
    defaultMessage: 'Facilities',
  },
  copyMappingDialogSmartProdSources: {
    id: `${scopeOMSCodesAndSmartProdJobs}.copyMappingDialogSmartProdSources`,
    defaultMessage: 'SmartProd Sources',
  },
  labelOMSCode: {
    id: `${scopeOMSCodesAndSmartProdJobs}.labelOMSCode`,
    defaultMessage: 'OMS Code: {omsCode}',
  },
  labelActivity: {
    id: `${scopeOMSCodesAndSmartProdJobs}.labelActivity`,
    defaultMessage: 'Activity',
  },
  omsCodesTableOMSCode: {
    id: `${scopeOMSCodesAndSmartProdJobs}.omsCodesTableOMSCode`,
    defaultMessage: 'OMS Code',
  },
  omsCodesTableSmartProdSource: {
    id: `${scopeOMSCodesAndSmartProdJobs}.omsCodesTableSmartProdSource`,
    defaultMessage: 'SmartProd Source',
  },
  omsCodesTableSmartPlanActivity: {
    id: `${scopeOMSCodesAndSmartProdJobs}.omsCodesTableSmartPlanActivity`,
    defaultMessage: 'SmartPlan Activity (UoM)',
  },
  omsCodesTableSmartPlanActivityShort: {
    id: `${scopeOMSCodesAndSmartProdJobs}.omsCodesTableSmartPlanActivityShort`,
    defaultMessage: 'SmartPlan Activity',
  },
  omsCodesTableSmartProdJobCode: {
    id: `${scopeOMSCodesAndSmartProdJobs}.omsCodesTableSmartProdJobCode`,
    defaultMessage: 'SmartProd Job Code (UoM)',
  },
  omsCodesTableSmartConversionRate: {
    id: `${scopeOMSCodesAndSmartProdJobs}.omsCodesTableSmartConversionRate`,
    defaultMessage: 'Conversion Rate',
  },
  omsCodesTableActivity: {
    id: `${scopeOMSCodesAndSmartProdJobs}.omsCodesTableActivity`,
    defaultMessage: 'Activity',
  },
  omsCodesTableJob: {
    id: `${scopeOMSCodesAndSmartProdJobs}.omsCodesTableJob`,
    defaultMessage: 'Job',
  },
  omsCodesTableUnassign: {
    id: `${scopeOMSCodesAndSmartProdJobs}.omsCodesTableUnassign`,
    defaultMessage: 'Unassign Current Activity',
  },
  omsCodesTableDefaultConversionRate: {
    id: `${scopeOMSCodesAndSmartProdJobs}.omsCodesTableDefaultConversionRate`,
    defaultMessage: 'Default Conversion Rate',
  },
  invalidConversionRateErrorActivityDate: {
    id: `${scopeOMSCodesAndSmartProdJobs}.invalidConversionRateErrorActivityDate`,
    defaultMessage: 'Invalid conversion rate activity value "{value}" for: {jobCode} / {date}',
  },
  invalidConversionRateErrorJobDate: {
    id: `${scopeOMSCodesAndSmartProdJobs}.invalidConversionRateErrorJobDate`,
    defaultMessage: 'Invalid conversion rate job value "{value}" for: {jobCode} / {date}',
  },

  // forecast per OMS code
  omsCodesTableEffort: {
    id: `${scopeOMSCodesAndSmartProdJobs}.omsCodesTableEffort`,
    defaultMessage: 'Effort',
  },
  omsCodesTableEffortSmartVolume: {
    id: `${scopeOMSCodesAndSmartProdJobs}.omsCodesTableEffortSmartVolume`,
    defaultMessage: 'SmartVolume Effort',
  },
  omsCodesTableEffortSmartProd: {
    id: `${scopeOMSCodesAndSmartProdJobs}.omsCodesTableEffortSmartProd`,
    defaultMessage: 'Planner Driven Effort',
  },
  omsCodesTableActualProductivityRate: {
    id: `${scopeOMSCodesAndSmartProdJobs}.omsCodesTableActualProductivityRate`,
    defaultMessage: 'Actual Productivity Rate',
  },
  omsCodesTableTargetProductivityRate: {
    id: `${scopeOMSCodesAndSmartProdJobs}.omsCodesTableTargetProductivityRate`,
    defaultMessage: 'Target Productivity Rate',
  },
  omsCodesTableRatio: {
    id: `${scopeOMSCodesAndSmartProdJobs}.omsCodesTableRatio`,
    defaultMessage: 'Ratio',
  },
  omsCodesTableColumnDisplayFilter: {
    id: `${scopeOMSCodesAndSmartProdJobs}.omsCodesTableColumnDisplayFilter`,
    defaultMessage: 'Column Display Filter',
  },
  omsCodesTableSmartVolumeInfo: {
    id: `${scopeOMSCodesAndSmartProdJobs}.omsCodesTableSmartVolumeInfo`,
    defaultMessage: 'SmartVolume Info',
  },
  omsCodesTableLastRefresh: {
    id: `${scopeOMSCodesAndSmartProdJobs}.omsCodesTableLastRefresh`,
    defaultMessage: 'Last Refresh',
  },
  omsCodesTableLastRunInProgress: {
    id: `${scopeOMSCodesAndSmartProdJobs}.omsCodesTableLastRunInProgress`,
    defaultMessage: 'In Progress',
  },
  omsCodesTableLastRunFailed: {
    id: `${scopeOMSCodesAndSmartProdJobs}.omsCodesTableLastRunFailed`,
    defaultMessage: 'Failed',
  },
  omsCodesRefreshedToast: {
    id: `${scopeToolbar}.omsCodesRefreshedToast`,
    defaultMessage: 'Refreshed',
  },
  omsCodesTableAction: {
    id: `${scopeOMSCodesAndSmartProdJobs}.omsCodesTableAction`,
    defaultMessage: 'Action',
  },
  omsCodesTableFieldName_effortSmartVolume: {
    id: `${scopeOMSCodesAndSmartProdJobs}.omsCodesTableFieldName_effortSmartVolume`,
    defaultMessage: 'SmartVolume Effort',
  },
  omsCodesTableFieldName_effortSmartProd: {
    id: `${scopeOMSCodesAndSmartProdJobs}.omsCodesTableFieldName_effortSmartProd`,
    defaultMessage: 'Planner Driven Effort',
  },
  omsCodesTableFieldName_actualPR: {
    id: `${scopeOMSCodesAndSmartProdJobs}.omsCodesTableFieldName_actualPR`,
    defaultMessage: 'Actual PR',
  },
  omsCodesTableFieldName_targetPR: {
    id: `${scopeOMSCodesAndSmartProdJobs}.omsCodesTableFieldName_targetPR`,
    defaultMessage: 'Target PR',
  },
  omsCodesTableFieldName_ratio: {
    id: `${scopeOMSCodesAndSmartProdJobs}.omsCodesTableFieldName_ratio`,
    defaultMessage: 'Ratio',
  },
  omsCodesTableFieldName_model: {
    id: `${scopeOMSCodesAndSmartProdJobs}.omsCodesTableFieldName_model`,
    defaultMessage: 'Model',
  },
  omsCodesTableRefreshSmartProdSources: {
    id: `${scopeOMSCodesAndSmartProdJobs}.omsCodesTableRefreshSmartProdSources`,
    defaultMessage: 'Refresh All',
  },
  omsCodesTableModelTooltip: {
    id: `${scopeOMSCodesAndSmartProdJobs}.omsCodesTableModelTooltip`,
    defaultMessage: 'Model: {model}',
  },
  doNotAutoAssign: {
    id: `${scopeOMSCodesAndSmartProdJobs}.doNotAutoAssign`,
    defaultMessage: 'Stop Auto Assign',
  },
  startNotAutoAssign: {
    id: `${scopeOMSCodesAndSmartProdJobs}.startNotAutoAssign`,
    defaultMessage: 'Start Auto Assign',
  },
  NotAssignHoliday: {
    id: `${scopeOMSCodesAndSmartProdJobs}.NotAssignHoliday`,
    defaultMessage: 'Auto Assign Holiday',
  },
  holidayWasAssigned: {
    id: `${scopeOMSCodesAndSmartProdJobs}.holidayWasAssigned`,
    defaultMessage: 'There are new holidays assigned to current SmartProd Source!',
  },
  confirm:{
    id: `${scopeOMSCodesAndSmartProdJobs}.confirm`,
    defaultMessage: 'Confirm',
  },
  omsChangeHistory:{
    id: `${scopeOMSCodesAndSmartProdJobs}.omsChangeHistory`,
    defaultMessage: 'OMS Code change warnings',
  }
});
