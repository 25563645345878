export const selectViewModeStoredData = state => {
  const storedValues = state.getIn(['viewModePopover', 'viewModeData']) || {
    inputType: 'volumeAndHours',
    checkboxMHE: true,
    checkboxLabourAvailable: true,
  };

  return {
    ...storedValues,
    showHoursSections: storedValues
      ? storedValues.inputType === 'volumeAndHours' || storedValues.inputType === 'hours'
      : true,
    showVolumeSections: storedValues
      ? storedValues.inputType === 'volumeAndHours' || storedValues.inputType === 'volume'
      : true,
    checkboxMHE: storedValues && storedValues.checkboxMHE,
    checkboxLabourAvailable: storedValues && storedValues.checkboxLabourAvailable,
  };
};
