import React from 'react';

function aboveThreshold(threshold) {
  const scrollTop = window.scrollY;
  return scrollTop > threshold;
}

export const withScrollWatch = threshold => WrappedComponent =>
  class ScrollWatch extends React.Component {
    state = { fixed: aboveThreshold(threshold) };

    componentDidMount() {
      window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
      window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = () => {
      const fixedNew = aboveThreshold(threshold);
      if (this.state.fixed !== fixedNew) {
        this.setState({ fixed: fixedNew });
      }
    };

    render() {
      return <WrappedComponent {...this.props} fixed={this.state.fixed} />;
    }
  };
