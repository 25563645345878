import { fork, takeLatest, call, put, select, all } from 'redux-saga/effects';

import { push, replace } from 'connected-react-router';
import { SubmissionError } from 'redux-form';
import get from 'lodash/get';
import uniqid from 'uniqid';
import { formatDate } from 'utils/dateTime';
import { makeChangeAction } from 'utils/form/actions';
import { FIELD_CHANGED } from 'utils/form/constants';
import { toNumber } from 'utils/utils';
import calendarMessages from 'utils/calendar/messages';
import { dataToSearch, parserPlanResult } from 'utils/url';
import { makeSelectUserSimple } from 'containers/LoginPage/selectors';
import { saveToStorageById, loadFromStorageById, defaultSettings } from 'containers/PlanResultPage/utils';
import {
  api,
  withUrl,
  convertEntityWithPlanningParametersFromApi,
  convertCalculationStatisticsFromApi,
} from 'utils/api';
import { getWorker } from 'utils/webworker/workerAPI';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import { saveConfigToFavSaga, loadConfigFromFavSaga, loadUserViewSaga } from 'containers/TableControlled/saga';
import { SAVE_CONFIG_TO_FAV, LOAD_CONFIG_FROM_FAV, LOAD_USER } from 'containers/TableControlled/constants';
import { diffPlanningParameters } from 'utils/commonDetailSaga';
import {
  TREND_COLUMN_SETTINGS_KEY,
  EFWZPG_COLUMN_SETTINGS_KEY,
  DILO_COLUMN_SETTINGS_KEY,
} from 'containers/PlanGraphs/constants';
import {
  formDataSelector,
  selectEdit,
  makeSelectorByPath,
  makeSelectOverrides,
  selectPlan,
  selectResults,
  selectActivitiesByPlan,
  selectPlanId,
} from './selectors';

import messages from './messages';

import {
  LOAD_TREND_GRAPH,
  GRANULARITY,
  OVERRIDE_VALUE_TYPE,
  LOAD_PLAN,
  FORM_NAME,
  SET_GRAPH_DATA_ACTIVITY,
  LOAD_GRAPH_DATA_ACTIVITY,
  PURPOSE,
  EDIT_MODE_CHANGE,
  STORE_GRAPH_DATA_TREND,
  LOAD_GRAPH_DATA_DILO,
  SET_GRAPH_DATA_DILO,
  LOAD_PLAN_STATS,
  COL_SETTINGS_CHANGE,
} from './constants';
import { PATHS } from '../App/constants';
import { calculateColDefs, mergeDataAndDefs } from './calculation/calculate';
import { fetchPlan } from '../PlanDetailPage/saga';
import {
  storePlanAction,
  setGraphDataTrendFilteredAction,
  setGraphDataActivityAction,
  setGraphDataActivityFilteredAction,
  storeCalcualtedTablesAction,
  storeCalcualtedTablesDefAction,
  storeGraphDataTrendAction,
  remountTablesAction,
  setGraphDataDiloAction,
  setGraphDataDiloFilteredAction,
  storePlanStats,
} from './actions';

import { apiCallEndedAction, apiCallStartedAction } from '../App/actions';

// Individual exports for testing
export default function* defaultSaga() {
  yield takeLatest(LOAD_TREND_GRAPH, calculateTrendSaga);
  yield takeLatest(LOAD_PLAN, loadPlanSaga);
  yield takeLatest(STORE_GRAPH_DATA_TREND, calculateFilterSaga);
  yield takeLatest(SET_GRAPH_DATA_DILO, calculateDiloFilterSaga);
  yield takeLatest(SET_GRAPH_DATA_ACTIVITY, calculateActivityFilterSaga);
  yield takeLatest(COL_SETTINGS_CHANGE, isRelevantFieldSaga);
  yield takeLatest(LOAD_GRAPH_DATA_ACTIVITY, loadGraphDataActivitySaga);
  yield takeLatest(LOAD_GRAPH_DATA_DILO, loadGraphDataDiloSaga);
  yield takeLatest(LOAD_PLAN_STATS, loadPlanStats);
  // Faourites
  yield takeLatest(SAVE_CONFIG_TO_FAV, saveConfigToFavSaga);
  yield takeLatest(LOAD_CONFIG_FROM_FAV, loadConfigFromFavSaga);
  yield takeLatest(LOAD_USER, loadUserViewSaga);
}

function* loadGraphDataDiloSaga(action) {
  const filterData = action.payload;
  const { startDate } = filterData;
  const url = `/planningParameters/${
    filterData.planningParametersId
  }/calculate?dateFrom=${startDate.toISODate()}&dateTo=${startDate
    .plus({ days: filterData.granularity === GRANULARITY.WEEK ? 6 : 0 })
    .toISODate()}&granularity=HOUR&includeMhe=false&includeRole=false&includeBaseLine=true&includeWeekStartAsSunday=false&includeVolumeCategory=false`;
  const response = yield call(api, withUrl(url).disableLogCalls());
  if (response.isOk) {
    const activitiesByPlan = yield select(selectActivitiesByPlan);
    const result = yield call(() =>
      getWorker('calculateDilo', () => new Worker(new URL('./calculation/workers/calculate.worker.js', import.meta.url), { type: 'module' })).then(
        ww =>
          ww.start({
            data: response.data,
            granularityActivity: filterData.granularity,
            activitiesByPlan,
          }),
      ),
    );
    yield put(setGraphDataDiloAction(result));
  }
}

function* loadPlanSaga(action) {
  const {
    data: { planId, otherData },
    intl,
    graphPage = false,
  } = action.payload;
  if (planId === undefined) {
    yield put(push(PATHS.planList));
    return;
  }
  const plan = yield call(fetchPlan, planId, false);
  if (plan) {
    if (graphPage) {
      const currentFilter = yield select(formDataSelector);
      if (planId !== (currentFilter && currentFilter.planId)) {
        // reset to default if plan changed
        const {
          planningParameters: { id },
        } = plan;
        const defaultData = {
          ...defaultSettings,
          startDate: plan.planningParameters.startDay,
          endDate: plan.planningParameters.endDay,
          startDateActivity: plan.planningParameters.startDay,
          planId,
          planningParametersId: id,
          startDateDilo: plan.planningParameters.startDay,
        };
        // sync url
        // don't fetch in first go
      }
    }
    yield put(storePlanAction(plan));
  } else {
    yield put(push(PATHS.planList));
  }
}

function* loadPlanStats(action) {
  const { planningParametersId } = action.payload;
  const result = yield call(api, withUrl(`/planningParameters/${planningParametersId}/statistics`));
  if (result.isOk) {
    yield put(storePlanStats(convertCalculationStatisticsFromApi(result.data)));
  }
}

function* loadGraphDataActivitySaga(action) {
  const filterData = action.payload;
  const { startDate } = filterData;
  const url = `/planningParameters/${
    filterData.planningParametersId
  }/calculate?dateFrom=${filterData.startDate.toISODate()}&dateTo=${startDate
    .plus({ days: filterData.granularity === GRANULARITY.WEEK ? 6 : 0 })
    .toISODate()}&granularity=WZP&includeMhe=false&includeRole=false&includeBaseLine=true&includeWeekStartAsSunday=false&includeVolumeCategory=false`;
  const response = yield call(api, withUrl(url).disableLogCalls());
  if (response.isOk) {
    const activitiesByPlan = yield select(selectActivitiesByPlan);
    const result = yield call(() =>
      getWorker(
        'calculateActivity',
        () => new Worker(new URL('./calculation/workers/calculate.worker.js', import.meta.url), { type: 'module' }),
      ).then(ww =>
        ww.start({
          data: response.data,
          granularityActivity: filterData.granularity,
          activitiesByPlan,
        }),
      ),
    );
    yield put(setGraphDataActivityAction(result));
  }
}

function* calculateTrendSaga(action) {
  const { data, settings } = action.payload;
  const result = yield call(() =>
    getWorker('calculateTrend', () => new Worker(new URL('./calculation/workers/calculate.worker.js', import.meta.url), { type: 'module' })).then(
      ww => ww.start(data),
    ),
  );

  yield put(storeGraphDataTrendAction({ result, settings }));
}

// Leaving it otherwise it will fall down somwehre without tests
const intlObject = { formatDate: a => formatDate(a), formatMessage: a => a && a.defaultMessage };

function* calculateTablesSaga(filter, data, intl = intlObject) {
  const callId = uniqid();
  try {
    yield put(apiCallStartedAction(callId));
    const editing = yield select(selectEdit);
    const activitiesByPlan = yield select(selectActivitiesByPlan);
    const props = { intl, messages: { ...messages, ...calendarMessages } };
    const filterCurrent = { ...filter, activitiesByPlan, editing };
    const result = yield call(() =>
      getWorker('calculateData', () => new Worker(new URL('./calculation/workers/calculate.worker.js', import.meta.url), { type: 'module' })).then(
        ww => ww.start({ data, filter: filterCurrent }),
      ),
    );
    const defsAndData = mergeDataAndDefs(data, props, filterCurrent, result);
    const plan = yield select(selectPlan);
    const category = get(plan, 'planningParameters.budgetVolumeCategoryParameters');
    yield put(storeCalcualtedTablesAction(defsAndData));
    return defsAndData;
  } finally {
    yield put(apiCallEndedAction(callId));
  }
}

function* calculateDefsSaga(filter, data, intl = intlObject) {
  const callId = uniqid();
  try {
    yield put(apiCallStartedAction(callId));
    const editing = yield select(selectEdit);
    const props = { intl, messages: { ...messages, ...calendarMessages } };
    const result = calculateColDefs(data, props, { ...filter, editing });
    yield put(storeCalcualtedTablesDefAction(result));
  } finally {
    yield put(apiCallEndedAction(callId));
  }
}

const graphDataActivitySelector = makeSelectorByPath(['planResultPage', 'graphDataActivity']);
const graphDataTrendSelector = makeSelectorByPath(['planResultPage', 'graphDataTrend']);
const graphDataDiloSelector = makeSelectorByPath(['planResultPage', 'graphDataDilo']);

function* calculateActivityFilterSaga() {
  const callId = uniqid();
  try {
    yield put(apiCallStartedAction(callId));
    const graphDataActivity = yield select(graphDataActivitySelector);
    if (!graphDataActivity || !graphDataActivity.data) {
      return;
    }
    const planId = yield select(selectPlanId);
    const user = yield select(makeSelectUserSimple());
    const filterData = loadFromStorageById(user.user.login, planId, EFWZPG_COLUMN_SETTINGS_KEY);
    const { effortFte = 'effort', baseline = true, planned = true, actuals = false } = filterData || {};
    const newData = { ...graphDataActivity };
    newData.data = graphDataActivity.data.filter(item => {
      if (effortFte === 'effort' && /.*_fte_.*/.test(item.name)) {
        return false;
      }
      if (effortFte === 'fte' && /.*_effort_.*/.test(item.name)) {
        return false;
      }
      if (baseline === false && /^budget_.*$/.test(item.name)) {
        return false;
      }
      if (planned === false && /^plan_.*$/.test(item.name)) {
        return false;
      }
      if (actuals === false && /^actuals_.*$/.test(item.name)) {
        return false;
      }
      return true;
    });
    yield put(setGraphDataActivityFilteredAction(newData));
  } finally {
    yield put(apiCallEndedAction(callId));
  }
}

function* calculateDiloFilterSaga() {
  const callId = uniqid();
  try {
    yield put(apiCallStartedAction(callId));
    const graphDataActivity = yield select(graphDataDiloSelector);
    if (!graphDataActivity || !graphDataActivity.data) {
      return;
    }
    const planId = yield select(selectPlanId);
    const user = yield select(makeSelectUserSimple());
    const filterData = loadFromStorageById(user.user.login, planId, DILO_COLUMN_SETTINGS_KEY);
    const { baseline = true, planned = true, actuals = false, effortFte = 'effort' } = filterData || {};

    const newData = { ...graphDataActivity };
    newData.data = graphDataActivity.data.filter(item => {
      if (effortFte === 'effort' && /.*_fte$/.test(item.name)) {
        return false;
      }
      if (effortFte === 'fte' && /.*_effort$/.test(item.name)) {
        return false;
      }
      if (baseline === false && /.*_budget_.*$/.test(item.name)) {
        return false;
      }
      if (planned === false && /.*_plan_.*$/.test(item.name)) {
        return false;
      }
      if (actuals === false && /.*_actuals_.*$/.test(item.name)) {
        return false;
      }
      return true;
    });
    yield put(setGraphDataDiloFilteredAction(newData));
  } finally {
    yield put(apiCallEndedAction(callId));
  }
}

function* calculateFilterSaga() {
  const callId = uniqid();
  try {
    yield put(apiCallStartedAction(callId));
    const graphDataTrend = yield select(graphDataTrendSelector);
    if (!graphDataTrend || !graphDataTrend.data) {
      return;
    }

    const planId = yield select(selectPlanId);
    const user = yield select(makeSelectUserSimple());
    const filterData = loadFromStorageById(user.user.login, planId, TREND_COLUMN_SETTINGS_KEY);
    const { effortFte = true, planned = true, baseline = true, actuals = true, available = true } = filterData || {};
    // we just remove data no more calculation
    const newData = { ...graphDataTrend };

    newData.data = graphDataTrend.data.filter(item => {
      if (effortFte === 'effort' && /.*fte/.test(item.name)) {
        return false;
      }
      if (effortFte === 'fte' && /.*effort/.test(item.name)) {
        return false;
      }
      if (baseline === false && /.*budget.*/.test(item.name)) {
        return false;
      }
      if (planned === false && /.*plan.*/.test(item.name)) {
        return false;
      }
      if (actuals === false && /.*actuals.*/.test(item.name)) {
        return false;
      }
      if (available === false && /.*availability.*/.test(item.name)) {
        return false;
      }
      return true;
    });

    yield put(setGraphDataTrendFilteredAction(newData));
  } finally {
    yield put(apiCallEndedAction(callId));
  }
}

function* isRelevantFieldSaga(action) {
  const { filterName } = action.payload;

  if (filterName === 'trendFilter') {
    yield call(calculateFilterSaga);
    return;
  }
  if (filterName === 'activityFilter') {
    yield call(calculateActivityFilterSaga);
    return;
  }
  if (filterName === 'diloFilter') {
    yield call(calculateDiloFilterSaga);
  }
}
