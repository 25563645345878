import { defineMessages } from 'react-intl';

export default defineMessages({
  back: {
    defaultMessage: 'Back',
    id: 'app.containers.ShiftScheduleRunsPowerBIGraphPage.back',
  },
  powerBiGraph: {
    defaultMessage: 'PowerBI Graph',
    id: 'app.containers.ShiftScheduleRunsPowerBIGraphPage.powerBiGraph',
  },
  basicInfo: {
    defaultMessage: 'Basic Information',
    id: 'app.containers.ShiftScheduleRunsPowerBIGraphPage.basicInfo',
  },
  basicInformationDescription: {
    defaultMessage: 'Description',
    id: 'app.containers.ShiftScheduleRunsPowerBIGraphPage.basicInformationDescription',
  },
  basicInformationName: {
    defaultMessage: 'Name',
    id: 'app.containers.ShiftScheduleRunsPowerBIGraphPage.basicInformationName',
  },
  created: {
    defaultMessage: 'Created',
    id: 'app.containers.ShiftScheduleRunsPowerBIGraphPage.created',
  },
  createdBy: {
    defaultMessage: 'Created By',
    id: 'app.containers.ShiftScheduleRunsPowerBIGraphPage.createdBy',
  },
  endDate: {
    defaultMessage: 'End Date',
    id: 'app.containers.ShiftScheduleRunsPowerBIGraphPage.endDate',
  },
  startDate: {
    defaultMessage: 'Start Date',
    id: 'app.containers.ShiftScheduleRunsPowerBIGraphPage.startDate',
  },
  facilities: {
    defaultMessage: 'Facilities',
    id: 'app.containers.ShiftScheduleDetailPage.basicInformationFacilities',
  },
  from: {
    defaultMessage: 'From',
    id: 'app.containers.ShiftScheduleRunsPowerBIGraphPage.from',
  },
  header: {
    defaultMessage: 'PowerBI Graph - {name}',
    id: 'app.containers.ShiftScheduleRunsPowerBIGraphPage.header',
  },
  name: {
    defaultMessage: 'Name',
    id: 'app.containers.ShiftScheduleRunsPowerBIGraphPage.name',
  },
  parametersTableHeader: {
    defaultMessage: 'Parameters',
    id: 'app.containers.ShiftScheduleRunsPowerBIGraphPage.parametersValues.parametersTableHeader',
  },
  runReportButton: {
    defaultMessage: 'Run Report',
    id: 'app.containers.ShiftScheduleRunsPowerBIGraphPage.runReportButton',
  },
  tableHeader: {
    defaultMessage: 'PowerBI Graph',
    id: 'app.containers.ShiftScheduleRunsPowerBIGraphPage.tableHeader',
  },
  tableHeaderWarning: {
    defaultMessage: 'Warnings',
    id: 'app.containers.ShiftScheduleRunsPowerBIGraphPage.tableHeaderWarning',
  },
  tableHeaderWarningAvailable: {
    defaultMessage: 'Warning available',
    id: 'app.containers.ShiftScheduleRunsPowerBIGraphPage.tableHeaderWarningAvailable',
  },
  powerBiReportInProgress: {
    defaultMessage: 'Another report is already running',
    id: 'app.containers.ShiftScheduleRunsPowerBIGraphPage.powerBiReportInProgress',
  },
});
