/**
 * Get all facilities: directly associated facilities to the schedule and implicitly associated via plans
 */
import EnchantedMap from 'utils/enchantedMap';

/**
 * Group array items using given `keyExtractor`, one key is mapped to an array of values.
 */
export function convertArrayToMultiMap<T, K>(
  array: T[],
  // @ts-ignore
  keyExtractor: (i: T) => K = i => i.id as K,
): EnchantedMap<K, T[]> {
  const result = new EnchantedMap<K, T[]>();
  array.forEach(item => {
    const container = result.setIfAbsent(keyExtractor(item), []);
    container.push(item);
  });
  return result;
}

/**
 * Associate array items using given `keyExtractor`, one key is mapped to a single value.
 */
export function convertArrayToMap<T, K>(
  array: T[],
  // @ts-ignore
  keyExtractor: (i: T) => K = i => i.id as number,
): EnchantedMap<K, T> {
  return convertArrayToMapWithReduce(array, keyExtractor, item => item);
}

/**
 * Associate array items using given `keyExtractor`. Value may be reduced using `valueReducer`
 */
export function convertArrayToMapWithReduce<T, K, V>(
  array: T[],
  keyExtractor: (i: T) => K,
  // @ts-ignore
  valueReducer: (i: T) => V = item => item,
): EnchantedMap<K, V> {
  const result = new EnchantedMap<K, V>();
  array.forEach(item => result.set(keyExtractor(item), valueReducer(item)));
  return result;
}

export function findById<T>(array: T[], id: any): T {
  // @ts-ignore
  return (array || []).find(item => item && item.id === id);
}

export const capitalize = string => string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();


/**
 * Associate array items using given `keyExtractor`, id and wzpId is mapped to a single value.
 */
export function convertArrayToMapTest<T, K>(
  array: T[],
  // @ts-ignore
  keyExtractor: (i: T) => K = i => `${i.wzpId ? `${i.id}_${i.wzpId}` : `${i.id}`}` as number,
): EnchantedMap<K, T> {
  return convertArrayToMapWithReduce(array, keyExtractor, item => item);
}
