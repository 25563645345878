import { createSelector } from 'reselect';

/**
 * Direct selector to the planningAreasListPage state domain
 */
export const makeSelectors = ({ domainName }) => {
  const selectData = state => state.getIn([domainName, 'data']);
  const selectLoader = state => state.getIn([domainName, 'listLoaderFlag'])
  const selectDomain = state => state.getIn([domainName]);
  const selectSelectedRows = state => state.getIn([domainName, 'selectedRows']);

  /**
   * Other specific selectors
   */

  /**
   * Default selector used by PlanningAreasListPage
   */

  const makeSelectData = () =>
    createSelector(
      selectData,
      substate => substate && substate.toJS(),
    );
  const selectLoaderFlag = () =>
      createSelector(
        selectLoader,
        substate => substate
      );
  const makeSelectSelectedRows = () =>
    createSelector(
      selectSelectedRows,
      substate => {
        const o = substate && substate.toJS();
        if (o) {
          return Object.keys(o).map(key => o[key]);
        }
        return [];
      },
    );
  const makeSelectDomain = () =>
    createSelector(
      selectDomain,
      substate => substate && substate.toJS(),
    );
    
    const makeSelectMasterPlanReportFlag = () => {
      createSelector(
        selectDomain,
        substate =>substate && substate.masterPlanReportRunsFlag
      )
    };

  return { makeSelectData, makeSelectSelectedRows, makeSelectDomain, makeSelectMasterPlanReportFlag, selectLoaderFlag };
};
