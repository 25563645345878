import React from "react";
import { ColDef } from 'ag-grid-community';
import styled from 'styled-components';
import { InjectedIntl, injectIntl } from 'react-intl';
import { dateComparator, dateTimeCellFormatter } from 'utils/dateTime';
import TableControlled from 'containers/TableControlled';
import messages from "./messages";
import { ApiSmartProdSourceDTO } from 'types/drep-backend';
import { TABLE_DEFAULTS } from 'containers/App/constants';
import { DisableValueGetter } from 'components/ListGrid/utils';

const Table = styled(TableControlled)`
  margin-top: 12px;
  height: ${props => props.height}px;

  .ag-overlay-no-rows-wrapper {
    padding-top: 90px;
  }
  .past-row {
    color: red;
  }
  .future-row {
    color: Orange;
  }
  .ag-full-width-row {
    overflow: visible;
  }
`;

type Props = {
    intl?: InjectedIntl;
    entity: ApiSmartProdSourceDTO;
}

const UsedPlanningAreasTable: React.FC<Props> = ({ intl, entity }) => {
    const [gridApi, setGridApi] = React.useState(null);
    const onGridReady = params => {
        setGridApi(params);
        params.api.sizeColumnsToFit();
    };

    const ROW_HEIGHT = 30;
    const name ="PlanningArea"
    const rowsData = entity && entity.planningAreas;

    React.useEffect(() => {
        if (gridApi) {
            const colDefs = columnDefs(intl);
            gridApi.api.setColumnDefs(colDefs);
            gridApi.api.sizeColumnsToFit();
        }
    }, [gridApi, entity]);

    const PALink = (params) =>{
        const url = `/area/${params?.data?.id}`
          return (
            <a href={url} target="_blank">
            {params?.value}
          </a>
          )
      }

    const columnDefs = (intl: InjectedIntl) => {
        const columns: ColDef[] = [
            {
                colId: 'namePA',
                field: 'name',
                filter: 'setFilter',
                cellRendererFramework: PALink,
                menuTabs: ['filterMenuTab'],
                headerName: intl.formatMessage(messages.namePA),
                sortable: true,
            },
            {
                colId: 'facility',
                field: 'facility',
                menuTabs: ['filterMenuTab'],
                filter: 'setFilter',
                valueGetter: ({ data }) => {
                    const units =
                        data && data.facilities
                            ? data.facilities.map(fac => fac.code).join(', ')
                            : [];
                    return units;
                },
                headerName: intl.formatMessage(messages.facilityPA),
                sortable: true,
                resizable: true,
            },
            {
                colId: 'managementUnitPA',
                field: 'managementUnit',
                menuTabs: ['filterMenuTab'],
                valueGetter: ({ data }) => {
                    const units =
                        data && data.facilities
                            ? data.facilities
                                .flatMap(fac => fac.managementUnits)
                                .map(mu => mu.description)
                                .join(', ')
                            : [];
                    return units;
                },
                filter: 'setFilter',
                headerName: intl.formatMessage(messages.managementUnitPA),
                sortable: true,
                resizable: true,
            },
            {
                colId: 'customerPA',
                field: 'customer',
                valueGetter: ({ data }) =>
                    data && data.customers
                        ? data.customers.map(c => `${c.code}: ${c.name}`).join(', ')
                        : '',
                filter: 'setFilter',
                menuTabs: ['filterMenuTab'],
                headerName: intl.formatMessage(messages.customerPA),
                sortable: true,
                resizable: true,
            },
            {
                colId: 'modifiedPA',
                field: 'audit.updated',
                menuTabs: ['filterMenuTab'],
                valueFormatter: dateTimeCellFormatter,
                filter: 'agDateColumnFilter',
                filterParams: {
                    filterOptions: ['lessThan', 'greaterThan', 'inRange'],
                    comparator: dateComparator,
                },
                headerName: intl.formatMessage(messages.modifiedPA),
                sortable: true,
                resizable: true,
            },
            {
                colId: 'modifiedByPA',
                field: 'audit.updatedBy',
                menuTabs: ['filterMenuTab'],
                valueGetter: ({ data }) => `${data.audit?.updatedBy?.firstName || ''} ${data.audit?.updatedBy?.lastName || ''}`,
                filter: 'setFilter',
                headerName: intl.formatMessage(messages.modifiedByPA),
                sortable: true,
                resizable: true,
            },
            {
                headerName: intl.formatMessage(messages.deleted),
                width: 120,
                field: 'deleted',
                colId: 'deleted',
                menuTabs: ['filterMenuTab'],
                valueGetter: DisableValueGetter,
                filter: 'setFilter',
                filterParams: { filterOptions: ['Enabled', 'Disabled'], newRowsAction: 'keep' },
            }

        ]

        return columns;
    }

    return (
        <Table
            masterDetail
            defaultColDef={{
                flex: 1,
            }}
            name={name}
            defaultConfig={TABLE_DEFAULTS.smartProdSourceDetailsPlanningAreaConfiguration}
            columnDefs={columnDefs(intl)}
            messages={messages}
            rowData={rowsData}
            rowHeight={ROW_HEIGHT}
            onGridReady={onGridReady}
            height={80 + ROW_HEIGHT * Math.min(10, rowsData?.length || 0)}
            sortable
        />
    )
}

export default injectIntl(UsedPlanningAreasTable);