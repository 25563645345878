import { createSelector } from 'reselect';
import { initialState } from './reducer';

const detailPage = state => state.get('shiftScheduleDetailPage', initialState);

export const getToken = state => state.getIn(['user', 'token']);

export const selectShiftSchedule = createSelector(
  detailPage,
  substate => substate.get('shiftSchedule'),
);

export const selectShiftScheduleStaff = createSelector(
  detailPage,
  substate => substate.get('scheduleStaff'),
);

export const selectShiftScheduleStaffSettings = createSelector(
  detailPage,
  substate => substate.get('scheduleStaffSettings'),
);

export const selectShiftScheduleStaffUnproductiveActivities = createSelector(
  detailPage,
  substate => substate.get('scheduleStaffUnproductiveActivities'),
);

export const selectAvailableUnproductiveActivities = createSelector(
  detailPage,
  substate => substate.get('availableUnproductiveActivities'),
);

export const selectDefaultScheduleParameters = createSelector(
  detailPage,
  substate => substate.get('defaultScheduleParameters'),
);

export const selectScheduleStaffSyncState = createSelector(
  detailPage,
  substate => substate.get('scheduleStaffSyncState'),
);

export const selectAvailablePlans = createSelector(
  detailPage,
  substate => substate.get('availablePlans'),
);

export const selectShiftScheduleGuiState = createSelector(
  detailPage,
  substate => substate.get('guiState'),
);

export const selectShiftScheduleEdit = createSelector(
  detailPage,
  substate => substate && substate.getIn(['guiState', 'edit']),
);

export const selectShiftScheduleIndex = createSelector(
  detailPage,
  substate => substate && substate.getIn(['guiState', 'periodIndex']),
);

export const selectShiftScheduleId = createSelector(
  detailPage,
  substate => substate.getIn(['shiftSchedule', 'id']),
);
