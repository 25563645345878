import {
  LookupsType,
  ProductivityRatesTable,
  ProductivityRatesTableRow
} from "types/planningParametersTypes";
import {
  ApiActivity,
  ApiActivityForecastDTO,
  ApiPlanningParametersDTO,
  ApiProductivityRateForecastDTO,
} from "types/drep-backend.d";
import { convertArrayToMapTest } from "../../containers/ShiftScheduleDetailPage/utils";
import {LOG_PROD_RATE} from "../../utils/logger";

export interface ProductivityRateRowUpdate {
  activityForecastListToSave: ApiProductivityRateForecastDTO[];
}

export interface ProductivityRateUpdate {
  activityForecastListToSave: ApiActivityForecastDTO[];
}

export function calculateProductivityRate(planningParameters: ApiPlanningParametersDTO, lookups: LookupsType): ProductivityRatesTable {
  const rowData = [];
  let minDay = null;
  let maxDay = null;
  const days = new Set<string>();
  planningParameters.activityForecastList?.forEach(item => {
    const activityData = lookups.activities[item.activityId] as unknown as ApiActivity;
    const department = (item.departmentId ? lookups.departments[item.departmentId] : null) || {name: item.departmentId, facilityId: null};
    const facility = (department?.facilityId ? lookups.facilities[department?.facilityId]: null)?.code || department?.facilityId;
    const activity = (item.activityId ? activityData : null)?.name || item.activityId;
    const customer = (item.customerId ? lookups.customers[item.customerId] : null)?.code || item.customerId;
    const name = [facility, customer || '', department?.name, activity].join(' / ');
    const uom = (item.uomId ? lookups.uoms[item.uomId] : null)?.name || item.uomId;
    const row = {
      id: item.id,
      wzpId: item.wzpId,
      wzpName: item.wzpName,
      departmentId: item.departmentId,
      activityId: item.activityId,
      customerId: item.customerId,
      uomId: item.uomId,
      activity: name,
      indirect: activityData ? activityData.indirect : false,
      uom,
    };
    item?.productivityRateForecastList.forEach(dayItem => {
      if (dayItem.forecastDate < minDay || !minDay) {
        minDay = dayItem.forecastDate;
      }
      if (dayItem.forecastDate > maxDay || !maxDay) {
        maxDay = dayItem.forecastDate;
      }
      if (!days.has(dayItem.forecastDate)) {
        days.add(dayItem.forecastDate);
      }
      row[dayItem.forecastDate] = {
        overrideHours: null,
        actualHours: null,
        targetHours: null,
        ...dayItem,
      };
    });
    rowData.push(row);
  });
  planningParameters.activityForecastOutOfPlanDateInterval?.forEach(item => {
    const activityData = lookups.activities[item.activityId] as unknown as ApiActivity;
    const department = (item.departmentId ? lookups.departments[item.departmentId] : null) || {name: item.departmentId, facilityId: null};
    const facility = (department?.facilityId ? lookups.facilities[department?.facilityId]: null)?.code || department?.facilityId;
    const activity = item?.activityName;
    const customer = (item.customerId ? lookups.customers[item.customerId] : null)?.code || item.customerId;
    const name = [facility, customer || '', department?.name, activity].join(' / ');
    const uom = item.uomName;
    const row = {
      id: item.id,
      wzpId: item.wzpId,
      wzpName: item.wzpName,
      departmentId: item.departmentId,
      activityId: item.activityId,
      customerId: item.customerId,
      uomId: item.uomId,
      activity: name,
      indirect: activityData ? activityData.indirect : false,
      uom,
      ForecastOutOfPlanDateInterval: true,
    };
    item?.productivityRateForecastList?.forEach(dayItem => {
      if (dayItem.forecastDate < minDay || !minDay) {
        minDay = dayItem.forecastDate;
      }
      if (dayItem.forecastDate > maxDay || !maxDay) {
        maxDay = dayItem.forecastDate;
      }
      if (!days.has(dayItem.forecastDate)) {
        days.add(dayItem.forecastDate);
      }
      row[dayItem.forecastDate] = {
        overrideHours: null,
        actualHours: null,
        targetHours: null,
        ...dayItem,
      };
    });
    rowData.push(row);
  });
  return { rowData: rowData.map((row, index) => ({...row, index})), minDay, maxDay, days };
}

export function diffProductivityRate(oldRowData: ProductivityRatesTable, newRowData: ProductivityRatesTable): ProductivityRateUpdate {
  if (LOG_PROD_RATE.isEnabled()) {
    LOG_PROD_RATE.d("Diffing saved productivity rates", oldRowData);
    LOG_PROD_RATE.d("With modified", newRowData);
  }
  const newMap = convertArrayToMapTest(newRowData.rowData);
  const activityForecastListToSave: ApiActivityForecastDTO[] = [];
  oldRowData.rowData.forEach(oldRow => {
    const keyss = oldRow.wzpId ?  `${oldRow.id}_${oldRow.wzpId}` : `${oldRow.id}`
    const newRow = newMap.get(keyss);
    if (newRow) {
      const diff = diffProductivityRateRow(oldRow, newRow);
      const rowItem: ApiActivityForecastDTO = {
        activityId: newRow.activityId,
        customerId: newRow.customerId,
        departmentId: newRow.departmentId,
        id: newRow.id,
        productivityRateForecastList: [],
        uomId: newRow.uomId,
        wzpId: newRow.wzpId,
      };
      if (diff.activityForecastListToSave.length) {
        activityForecastListToSave.push({...rowItem, productivityRateForecastList: diff.activityForecastListToSave});
      }
    } else {
      LOG_PROD_RATE.w(`Updated productivity forecast row with id ${oldRow.id} not found. Should never happen. Updated data:`, newRowData);
    }
  });
  return { activityForecastListToSave };
}

function diffProductivityRateRow(oldRow: ProductivityRatesTableRow, newRow: ProductivityRatesTableRow): ProductivityRateRowUpdate {
  const keysToCompare = Object.keys(newRow).filter(keyName => keyName.includes("-"));
  const result = {
    activityForecastListToSave: [],
  };
 
  keysToCompare.forEach(column => {
    const oldValue: ApiProductivityRateForecastDTO = oldRow[column];
    const newValue: ApiProductivityRateForecastDTO = newRow[column];
    if (oldValue?.overrideHours !== newValue?.overrideHours || oldValue?.dataSource !== newValue?.dataSource) {
      result.activityForecastListToSave.push({
        forecastDate: column,
        overrideHours: newValue.overrideHours,
        dataSource: newValue?.dataSource,
      });
    }
  });
  return result;
}
