import React, { useEffect } from "react";
import AgTable from 'components/Table';
import styled from 'styled-components';
import hash from 'object-hash';
import { InjectedIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { connect as formikConnect, getIn, FormikProps, setIn } from 'formik';
import { ColDef, ColGroupDef } from 'ag-grid-community';
import { percentageCellFormatter, toNumber } from 'utils/utils';
import ActionCellCellRenderer from './ActionCellCellRenderer';
import { makeSelectRunningApiCalls } from 'containers/App/selectors';
import { constructShifts } from './utils';
import { ForecastForm } from './types';
import messages from "./messages";
import { toast } from "react-toastify";
import { cloneDeep, isEqual } from "lodash";
import uuid from 'uuid/v4';

const Table = styled(AgTable)`
  margin-top: 12px;
  height: ${props => props.height}px;
  .ag-header-cell-label{
    align-items: center !important;
  }
`;
type Props = {
    intl: InjectedIntl;
    formik: FormikProps<ForecastForm>;
    editable: boolean;
    id: string | number;
    updateData: any;
    planDetails,
    handleSubmitPage: Function,
}

const columnDefs = (intl, formik, editable: boolean, id, shifts, isLoading) => {
    const handleValueSetter = (params) => {
        if(toNumber(params.newValue) < 0 ){
            toast.error('Shift split cannot be negitive');
            return false;
        }
        if(params.oldValue === params.newValue) return false;
        const findIndex = formik?.values?.timeTransformationRuns[id]?.smartProdRunWzps?.findIndex((s)=> s.id === params?.data?.id);
        const fieldName = `timeTransformationRuns.${id}.smartProdRunWzps.${findIndex}.${params.colDef.field}`;
        formik.setFieldValue(fieldName, params?.colDef?.headerName === "%Split of SmartProd Shift" ? Number((params.newValue) / 100) : params.newValue.name);
        return true;
    }
    const columns: (ColGroupDef | ColDef)[] = [
        {
            colId: 'smartProdShiftName',
            field: 'smartProdShiftName',
            suppressMenu: true,
            width: 150,
            filter: 'setFilter',
            menuTabs: ['filterMenuTab', 'generalMenuTab', 'columnsMenuTab'],
            headerName: intl.formatMessage(messages.shiftFromSmartProd),
            sortable: true,
            resizable: true,
        },
        {
            colId: 'wzpName',
            field: 'wzpName',
            width: 150,
            suppressMenu: true,
            filter: 'setFilter',
            cellEditor: 'agRichSelectCellEditor',
            valueFormatter: params => {
                return params.value && params.value.label
            },
            menuTabs: ['filterMenuTab', 'generalMenuTab', 'columnsMenuTab'],
            cellEditorParams: params => {
                return {values: shifts}
            },
            headerName: intl.formatMessage(messages.shiftFromSmartPlan),
            sortable: true,
            resizable: true,
            editable
        },
        {
            headerName: intl.formatMessage(messages.split),
            field: 'shiftSplit',
            colId: 'shiftSplit',
            suppressMenu: true,
            width: 150,
            editable,
            valueFormatter: percentageCellFormatter,
            valueSetter: handleValueSetter
        },
    ]
    if (editable) {
        columns.push(
            {
                headerName: intl.formatMessage(messages.action),
                field: 'delete',
                colId: 'delete',
                cellRendererFramework: ActionCellCellRenderer,
                width: 80,
                cellRendererParams: {
                    disabled: isLoading,
                    onDelete: payload => {
                        const shiftMappingData = getIn(formik.values, `timeTransformationRuns.${id}.smartProdRunWzps`);
                        const selectedShiftMappingData = shiftMappingData?.filter((sp)=> sp.smartProdShiftName == payload.smartProdShiftName)
                        const shiftSplit = shiftMappingData?.filter((s)=> s.smartProdShiftName === payload.smartProdShiftName)?.filter((sp)=> sp.id != payload.id)?.reduce((sum, r)=> sum + ((r.shiftSplit)), 0);
                        if(selectedShiftMappingData?.length === 1){
                            if (shiftMappingData?.length === 1) {
                                toast.error('There must be atleast one shift mapping line')
                                return;
                            }
                        }else {
                            // if(shiftSplit !== 1){
                            //     toast.error(`Please adjust %Split to 100% for ${payload.smartProdShiftName} before deleting shift mapping`);
                            //     return;
                            // }
                            if (shiftMappingData?.length === 1) {
                                toast.error('There must be atleast one shift mapping line')
                                return;
                            }
                        }
                        formik.setFieldValue(`timeTransformationRuns.${id}.smartProdRunWzps`, formik.values.timeTransformationRuns?.[id].smartProdRunWzps.filter(it => it.id !== payload.id));
                    },
                    onDuplicate: payload => {
                        const shiftMappingData = getIn(formik.values, `timeTransformationRuns.${id}.smartProdRunWzps`);
                        let shiftSplit = shiftMappingData?.filter((s)=> s.smartProdShiftName === payload.smartProdShiftName)?.reduce((sum, r)=> sum + ((r.shiftSplit)), 0);
                        const newData = [{
                            shiftSplit: shiftSplit > 1 ? 0 : 1 - shiftSplit,
                            smartProdShiftName: payload.smartProdShiftName,
                            startTime: payload.startTime,
                            endTime: payload.endTime,
                            id: uuid()
                        }]
                        formik.setFieldValue(`timeTransformationRuns.${id}.smartProdRunWzps`, [...shiftMappingData, ...newData])
                    }
                },
                sortable: false,
                suppressMenu: true,
            });

    }
    return columns;
};

export const  constructShiftSplit = (split) => {
    return split == 0.55 ? 55 : split == 0.07 ? 7 : split == 0.14 ? 14 : split === 0.28 ? 28 : split == 0.29 ? 29 : split == 0.56 ? 56 : split == 0.57 ? 57 : split == 0.58 ? 58 : (split * 100).toFixed(3)
}

const shiftMappings: React.FC<Props> = ({ intl, formik, editable, id, updateData, planDetails, handleSubmitPage }) => {
    const rowData = cloneDeep(formik?.values?.timeTransformationRuns?.[id]?.smartProdRunWzps?.sort((a,b)=> a?.smartProdShiftName?.localeCompare(b?.smartProdShiftName)));
    const isLoading = useSelector(makeSelectRunningApiCalls());
    rowData?.forEach((r) => {
        r.shiftSplit = Number.isInteger(r.shiftSplit * 100) ? (r.shiftSplit * 100) : constructShiftSplit(r.shiftSplit);
    });
    const shifts = constructShifts(planDetails, formik?.initialValues?.timeTransformationRuns?.[id]?.periods);
    const ROW_HEIGHT = 30;
    const handleCellValueChanged = (params) => {
        if(params.oldValue === params.newValue) return false;
        const findIndex = formik?.values?.timeTransformationRuns[id]?.smartProdRunWzps?.findIndex((s)=> s.id === params?.data?.id);
        const fieldName = `timeTransformationRuns.${id}.smartProdRunWzps.${findIndex}.${params.colDef.field}`;
        formik.setFieldValue(fieldName, params?.colDef?.headerName === "%Split of SmartProd Shift" ? Number((params.newValue) / 100) : params.newValue.name);
        return true;
    }

    // useEffect(()=>{
    //     if(!isEqual(formik?.values?.timeTransformationRuns?.[id]?.smartProdRunWzps?.length, formik?.initialValues?.timeTransformationRuns?.[id]?.smartProdRunWzps?.length)){
    //         handleSubmitPage(formik.values);
    //     }
    // },[formik?.values?.timeTransformationRuns?.[id]?.smartProdRunWzps])
    const hashkey = hash(formik?.values?.timeTransformationRuns?.[id]?.smartProdRunWzps);

    return (
        <Table
            key={`${formik?.values?.timeTransformationRuns?.[id]?.smartProdRunWzps?.length}_${hashkey}`}
            defaultColDef={{ flex: 1 }}
            sortable
            pagination={false}
            columnDefs={columnDefs(intl, formik, editable, id, shifts, isLoading)}
            height={80 + ROW_HEIGHT * Math.min(10, rowData?.length || 0)}
            rowData={rowData}
            onCellValueChanged={handleCellValueChanged}
            rowHeight={ROW_HEIGHT}
            singleClickEdit
        />
    );
}

export default formikConnect(shiftMappings);