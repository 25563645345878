/**
 *
 * PlannedVolumeHeader
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';

import { InputError } from 'components/FormikTextInput';
import Select from 'components/StyledSelect';
import { savePlannedVolumeHeader } from 'containers/PlanDetailPage/actions';
import { selectGuiState, selectPlanningParameters } from 'containers/PlanDetailPage/selectors';
import { savePlanningAreaPlanningParameters } from 'containers/PlanningAreaDetailPage/actions';
import { selectGuiStateForPa, selectPlanningParametersFromPa } from 'containers/PlanningAreaDetailPage/selectors';

import {
  DOW_TRANS_VOLUME_CATEGORY_AKA_DAILY,
  VOLUME_GRANULARITY_DAILY,
  VOLUME_GRANULARITY_WEEKLY,
} from '../../utils/constants';
import { DisplayDate } from '../../utils/dateTime';
import ButtonWithDirtyCheck from '../ButtonWithDirtyCheck';
import DatePicker from '../DatePicker';
import { ConfirmButton } from '../IconButton';
import Label from '../Label';
import messages from './messages';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin: 5px 0;
  > * {
    margin: 0 20px;
  }
  ${ConfirmButton} {
    margin-bottom: 10px;
  }
`;

const ErrorWrapper = styled.div`
  margin-bottom: 22px;
`;

const DivStyledToWidth = styled.div`
  width: 10em;
`;

/* eslint-disable react/prefer-stateless-function */
class PlannedVolumeHeader extends React.PureComponent {
  static getDerivedStateFromProps(nextProps, prevState) {
    const initUpdate = !prevState || !prevState.startDay;
    const reloadUpdate = prevState && prevState.loading && nextProps.guiState.get('loading') === false;
    const pp = nextProps.planningParameters;
    return initUpdate || reloadUpdate
      ? {
          startDay: pp && pp.startDay,
          endDay: pp && pp.endDay,
          loading: nextProps.guiState.get('loading'),
          openEndDay: false,
          granularity: (pp && pp.volumeGranularity) || VOLUME_GRANULARITY_WEEKLY,
        }
      : { ...prevState, loading: nextProps.guiState.get('loading') };
  }

  state = {};

  onDateChange = (dateType: 'startDay' | 'endDay') => (value: DateTime) => {
    const update = { [dateType]: value };
    if (dateType === 'startDay' && value.startOf('day') > this.state.endDay.startOf('day')) {
      update.endDay = value;
    }
    if (dateType === 'endDay' && value.startOf('day') < this.state.startDay.startOf('day')) {
      update.startDay = value;
    }
    this.setState(update);
  };

  onAcceptEndDate = value => {
    this.onDateChange('endDay')(value);
    this.setState({ openEndDay: false });
  };

  onAcceptStartDate = value => {
    this.onDateChange('startDay')(value);
    this.setState({ openEndDay: true });
  };

  render() {
    const pp = this.props.planningParameters;
    const editable = this.props.guiState.get('edit');
    const { openEndDay, startDay, endDay } = this.state;
    const dateRangeIsInvalid = startDay && endDay && startDay.plus({ months: 3 }).startOf('day') < endDay.startOf('day');
    return (
      <>
        <Wrapper>
          {pp && pp.dayTransformationType === DOW_TRANS_VOLUME_CATEGORY_AKA_DAILY && (
            <>
              <Label {...messages.granularity} />
              {editable ? (
                <DivStyledToWidth>
                  <Select
                    classNamePrefix="react-select"
                    className="react-select-container"
                    isClearable={false}
                    options={[{ value: VOLUME_GRANULARITY_WEEKLY }, { value: VOLUME_GRANULARITY_DAILY }]}
                    getOptionLabel={val => <FormattedMessage {...messages[val.value]} />}
                    onChange={val => this.setState({ granularity: val.value })}
                    value={{ value: this.state.granularity }}
                  />
                </DivStyledToWidth>
              ) : (
                <FormattedMessage {...messages[this.state.granularity]} />
              )}
              {editable && (
                <ButtonWithDirtyCheck
                  actionPayload={{
                    startDay: this.state.startDay,
                    endDay: this.state.endDay,
                    volumeGranularity: this.state.granularity,
                  }}
                  actionHandler={this.props.saveFromTo}
                  component={ConfirmButton}
                  disabled={dateRangeIsInvalid}
                />
              )}
            </>
          )}
        </Wrapper>
        {dateRangeIsInvalid && (
          <ErrorWrapper>
            <InputError>
              <FormattedMessage {...messages.startEndDatesRangeError} />
            </InputError>
          </ErrorWrapper>
        )}
      </>
    );
  }
}

PlannedVolumeHeader.propTypes = {
  planningParameters: PropTypes.object,
  guiState: PropTypes.object,
  intl: PropTypes.object,
  saveFromTo: PropTypes.func,
  saveAll: PropTypes.func,
  openDirtyDialog: PropTypes.func,
};

// PLAN
const mapPlanStateToProps = createStructuredSelector({
  planningParameters: selectPlanningParameters,
  guiState: selectGuiState,
});

function mapPlanDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      saveFromTo: savePlannedVolumeHeader,
    },
    dispatch,
  );
}

const withPlanConnect = connect(mapPlanStateToProps, mapPlanDispatchToProps);

// PLANNING AREA
const mapPaStateToProps = createStructuredSelector({
  planningParameters: selectPlanningParametersFromPa,
  guiState: selectGuiStateForPa,
});

function mapPaDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      saveFromTo: savePlanningAreaPlanningParameters,
    },
    dispatch,
  );
}

const withPaConnect = connect(mapPaStateToProps, mapPaDispatchToProps);

export default injectIntl(withPlanConnect(PlannedVolumeHeader));
export const BudgetVolumeHeader = injectIntl(withPaConnect(PlannedVolumeHeader));
