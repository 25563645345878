/*
 * ShiftsGraph Messages
 *
 * This contains all the text for the ShiftsGraph component.
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  header: {
    id: 'app.containers.ShiftsGraph.header',
    defaultMessage: 'This is ShiftsGraph container !',
  },
  startTime: {
    id: 'app.containers.ShiftsGraph.startTime',
    defaultMessage: 'Start time:',
  },
  endTime: {
    id: 'app.containers.ShiftsGraph.endTime',
    defaultMessage: 'End time:',
  },
  days: {
    id: 'app.containers.ShiftsGraph.days',
    defaultMessage: 'Days:',
  },
  name: {
    id: 'app.containers.ShiftsGraph.name',
    defaultMessage: 'Name:',
  },
  headCount: {
    id: 'app.containers.ShiftsGraph.headCount',
    defaultMessage: 'Head count:',
  },
  breakTime: {
    id: 'app.containers.ShiftsGraph.breakTime',
    defaultMessage: 'Break time (min):',
  },
  laborName: {
    id: 'app.containers.ShiftsGraph.laborName',
    defaultMessage: 'Labor category name:',
  },
  shifts: {
    id: 'app.containers.ShiftsGraph.shifts',
    defaultMessage: 'Shifts',
  },
});
