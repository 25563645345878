/**
 *
 * FormikDatePicker
 *
 */

import React from 'react';
import PropTypes from 'prop-types';

import { DisplayDate, DisplayDateTime } from '../../utils/dateTime';
import DatePicker from '../DatePicker';

// import styled from 'styled-components';

function FormikDatePicker(props) {
  const { field, editable, form, onChange, timePicker, defaultValue, ...theRest } = props;
  return editable !== false ? (
    <DatePicker
      value={field.value || defaultValue}
      timePicker={timePicker}
      onChange={val => {
        form.setFieldValue(field.name, val);
        if (onChange) {
          onChange(val);
        }
      }}
      {...theRest}
    />
  ) : (
    <div>
      {timePicker === false ? (
        <DisplayDate value={props.field.value || defaultValue} />
      ) : (
        <DisplayDateTime value={props.field.value || defaultValue} />
      )}
    </div>
  );
}

export const ReduxFormDatePicker = props => {
  const { meta, input, ...other } = props;
  return <DatePicker {...meta} onChange={input.onChange} onAccept={input.onChange} value={input.value} {...other} />;
};

ReduxFormDatePicker.propTypes = {
  meta: PropTypes.object,
  input: PropTypes.object,
};

FormikDatePicker.propTypes = {
  editable: PropTypes.bool,
  field: PropTypes.object,
  form: PropTypes.object,
  minDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  maxDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  onClose: PropTypes.func,
  onChange: PropTypes.func,
  okLabel: PropTypes.string,
  defaultValue: PropTypes.any,

  /**
   * Contains date and time if true (none considered as true)
   */
  timePicker: PropTypes.bool,
};

export default FormikDatePicker;
