/*
 *
 * LanguageProvider
 *
 * this component connects the redux state language locale to the
 * IntlProvider component and i18n messages (loaded from `app/translations`)
 */

import React, { useEffect } from 'react';
import { IntlProvider } from 'react-intl';
import { useSelectors, useActionCreators } from 'use-redux';
import injectSaga from 'utils/injectSaga';
import { intlSelector, fetchMessages, addLocale } from './redux';
import saga from './saga';

const formats = {
  time: {
    '24hour': { hour12: false },
  },
};

function loading(intl) {
  if (!intl || !intl.localeAdded || !intl.messages || intl.messages.status === 'loading') {
    return true;
  }
  return false;
}
// Updating on a new language after loading
const updateAfterLoading = (prevProps, nextProps) => loading(nextProps.intl);

function Provider(props) {
  const { intl, children } = props;
  if (loading(intl)) {
    return <div />;
  }
  const key = (intl.messages && `${intl.locale}-${intl.messages.status}`) || intl.locale;
  return (
    <IntlProvider locale={intl.locale} key={key} messages={intl.messages && intl.messages.data} formats={formats}>
      {React.Children.only(children)}
    </IntlProvider>
  );
}

const LangProvider = React.memo(Provider, updateAfterLoading);

function LanguageProvider(props) {
  const [intl] = useSelectors(intlSelector);
  const [getMessages, addIntlLocale] = useActionCreators(fetchMessages, addLocale);

  // ComponenDidMount;
  useEffect(() => {
    if (!intl.messages) {
      getMessages(intl.locale);
    }
    if (!intl.localeAdded) {
      addIntlLocale(intl.locale);
    }
  }, [intl.locale]);

  // second attempt (we should make messages available without login)
  useEffect(() => {
    if (intl.user && intl.messages && intl.messages.status === 'failed') {
      getMessages(intl.locale);
    }
  }, [intl.user]);

  return <LangProvider intl={intl}>{props.children}</LangProvider>;
}

export default injectSaga({ key: 'languageProvider', saga })(LanguageProvider);
