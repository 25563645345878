/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { ErrorMessage, FastField } from 'formik';
import styled from 'styled-components';
import { injectIntl } from 'react-intl';
import { EditableListDeleteIconButton, EditableListItemWrapperWzp } from '../DetailPageShared';
import Label from '../Label';
import messages from './messages';
import TimePicker from '../TimePicker';
import FormikNumberInput from '../FormikNumberInput';

const FastFieldDarker = styled(FastField)`
  border: 1px solid #e0e0e0;
`;

const StyledErrorDiv = styled.div`
  color: red;
`;

export default injectIntl(function(props) {
  const [isEndOpen, setEndOpen] = useState(false);
  return (
    <EditableListItemWrapperWzp key={props.key}>
      <Label {...messages.name} />
      <div>
        <FastFieldDarker name={`${props.name}.${props.index}.name`} type="text" />
        <ErrorMessage
          name={`${props.name}.${props.index}.name`}
          render={msg => <StyledErrorDiv>{msg}</StyledErrorDiv>}
        />
      </div>
      <Label {...messages.startTime} />
      <TimePicker
        name={`${props.wzpPath}.${props.index}.startTime`}
        onChange={val => props.form.setFieldValue(`${props.wzpPath}.${props.index}.startTime`, val)}
        value={props.item.startTime}
        onClose={() => setEndOpen(true)}
        okLabel={props.intl.formatMessage(messages.setStartTime)}
      />
      <Label {...messages.endTime} />
      <TimePicker
        name={`${props.wzpPath}.${props.index}.endTime`}
        onChange={val => props.form.setFieldValue(`${props.wzpPath}.${props.index}.endTime`, val)}
        value={props.item.endTime}
        open={isEndOpen}
        onClose={() => setEndOpen(false)}
        onOpen={() => setEndOpen(true)}
        okLabel={props.intl.formatMessage(messages.setEndTime)}
      />
      <Label {...messages.breakTime} />
      <FormikNumberInput name={`${props.wzpPath}.${props.index}.breakTime`} />
      <Label {...messages.minutes} />
      <EditableListDeleteIconButton onClick={props.deleteHandler} />
    </EditableListItemWrapperWzp>
  );
});
