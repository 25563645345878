import { PureComponent } from 'react';
import * as React from 'react';
import { connect as formikConnect, FormikProps } from 'formik';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators, compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';

import { ComponentWithDirtyCheck } from 'components/ButtonWithDirtyCheck';
import { DetailToolbarButtonsGroup } from 'components/DetailPageShared';
import IconButton, { SaveButton } from 'components/IconButton';
import { ButtonWrap } from 'components/ResultToolBar';
import { PATHS } from 'containers/App/constants';
import withSecurity, { PERMISSIONS } from 'utils/security';

import { withScrollWatch } from '../../utils/scroll';
import { openUploadModalAction } from '../UploadModal/actions';
import { storeShiftScheduleEdit, resetstoreShiftSchedule } from './actions';
import messages from './messages';
import { selectScheduleStaffSyncState, selectShiftScheduleGuiState, selectShiftScheduleStaff } from './selectors';
import { ScheduleStaffSyncState } from './types';

interface ShiftScheduleDetailToolbarProps {
  formik: FormikProps<any>;
  fixed: boolean;
  guiState: any;
  history: any;
  openUploadModalAction: any;
  scheduleStaffSyncState: ScheduleStaffSyncState;
  scheduleStaff: any;
  storeShiftScheduleEdit(action: boolean): () => any;
  resetstoreShiftSchedule(action: any): () => any;
  invokeStaffSync(): () => any;
  hasPerm(perm: string): () => boolean;
}

export const Title = styled.div`
  align-items: center;
  flex: 1;
  font-size: 24px;
  font-weight: bold;
  line-height: 34px;
  margin-left: 20px;
  white-space: nowrap;
`;

export const TitleFixed = props => (props.fixed ? <Title>{props.title}</Title> : <Title />);

class ShiftScheduleDetailToolbar extends PureComponent<ShiftScheduleDetailToolbarProps, {}> {
  public render() {
    const canEdit = true;
    const guiState = this.props.guiState.toJS();
    const kronosInterfaceSet = this.props.formik.values.smartShiftConfiguration != null;
    const syncDisabled = !kronosInterfaceSet || this.props.scheduleStaffSyncState?.isRunning;
    const syncTooltip = kronosInterfaceSet ? {} : { tooltip: { ...messages.kronosNotSet } };
    const uploadButton = this.props?.scheduleStaff?.length > 0 && this.props.formik?.values?.plans?.length > 0;
    const setEdit = edit => () => this.props.storeShiftScheduleEdit(edit);
    const disabled = this.props?.formik?.values?.deleted;
    const goBack = () => {
      this.props.resetstoreShiftSchedule(this.props.formik.values.id);
      this.props.history.push(PATHS.shiftScheduleListNoReset.replace(':reset', false),{disabled: disabled});
    }
    const goToRuns = () => this.props.history.push(PATHS.shiftScheduleRuns.replace(':id', this.props.formik.values.id));
    const goToInterfaces = () =>
      this.props.history.push(PATHS.shiftScheduleInterfaces.replace(':id', this.props.formik.values.id));
    return (
      <ButtonWrap fixed={this.props.fixed}>
        <IconButton id="back" onClick={goBack} icon="arrow-circle-left" tooltip={messages.back} />
        <TitleFixed fixed={this.props.fixed} title={this.props.formik.initialValues.name} />
        <DetailToolbarButtonsGroup>
          <SaveButton
            onClick={this.props.formik.submitForm}
            disabled={
              !this.props.formik.dirty || (this.props.formik.errors && Object.keys(this.props.formik.errors).length > 0)
            }
          />
          <IconButton
            id="sync"
            label={messages.syncKronos}
            onClick={this.props.invokeStaffSync}
            disabled={syncDisabled}
            {...syncTooltip}
          />
          {canEdit &&
            (guiState.edit === true ? (
              <ComponentWithDirtyCheck actionHandler={setEdit(false)}>
                {({ onClickHandler }) => (
                  <IconButton id="edit" icon="eye" tooltip={{ ...messages.display }} onClick={onClickHandler} />
                )}
              </ComponentWithDirtyCheck>
            ) : (
              <IconButton icon="edit" id="edit" tooltip={{ ...messages.edit }} onClick={setEdit(true)} />
            ))}
          <ComponentWithDirtyCheck actionHandler={() => this.props.openUploadModalAction()}>
            {({ onClickHandler }) => (
              <IconButton
                icon="upload"
                id="schedule-detail-upload"
                disabled={!uploadButton}
                tooltip={!uploadButton ? messages.uploadDisable : messages.upload}
                onClick={onClickHandler}
              />
            )}
          </ComponentWithDirtyCheck>
          {this.props.hasPerm(PERMISSIONS.EDIT_SCHEDULE_INTERFACES) && (
            <IconButton
              icon="boxes"
              tooltip={messages.interfaces}
              message={messages.interfaces}
              onClick={goToInterfaces}
            />
          )}
          <IconButton icon="exchange-alt" onClick={goToRuns} label={messages.runs} />
        </DetailToolbarButtonsGroup>
      </ButtonWrap>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  guiState: selectShiftScheduleGuiState,
  scheduleStaff: selectShiftScheduleStaff,
  scheduleStaffSyncState: selectScheduleStaffSyncState,
});

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators(
      {
        openUploadModalAction,
        storeShiftScheduleEdit,
        resetstoreShiftSchedule,
      },
      dispatch,
    ),
    dispatch,
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  injectIntl,
  withRouter,
  withConnect,
  formikConnect,
  withSecurity(),
  withScrollWatch(100),
  // @ts-ignore
)(ShiftScheduleDetailToolbar);
