// @flow

/**
 *
 * PlanResultPage
 *
 */
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Snackbar from '@material-ui/core/Snackbar';
import { Formik } from 'formik';
import intersection from 'lodash/intersection';
import pick from 'lodash/pick';
import { DateTime } from 'luxon';
import { Helmet } from 'react-helmet';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Prompt } from 'react-router';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { bindActionCreators, compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import styled, { withTheme } from 'styled-components';

import BasePage from 'components/BasePage';
import { withDirtyDialog } from 'components/DirtyDialog';
import { ResultVolumeTable, ResultVolumeTableDaily } from 'components/PlannedVolumeTable';
import { ResultMasterPlanVolumeTable, ResultMasterPlanVolumeTableDaily } from 'components/PlannedVolumeTable/MasterPlanPlannedVolume';
import ToggleSection from 'components/ToggleSection';
import { WarningText } from 'components/FormikTextInput';
import ResultMatrixActivitiesTableMasterPlan, { Loading, ScrollPosition } from 'components/ResultMatrixActivitiesTable/ResultActivities';
import MasterPlanColumnOptions from 'components/ResultMatrixColumnOptions/MasterPlanColumnOptions';
import ResultMatrixDateOptionsMasterPlan from 'components/ResultMatrixDateOptions/MasterPlanDateOptions';
import ResultMatrixMHETableSimplifiedDataModelMasterPlan from 'components/ResultMatrixMHETable_SimplifiedDataModel/ResultMHE';
import ResultMatrixRoleMasterPlan from 'components/ResultMatrixRoleTable/ResultRole';
import MasterPlanResultToolBar from '../../components/ResultToolBar/MasterPlanResultToolBar';
import { selectViewModeStoredData } from 'components/ViewModePopover/selectors';
import { makeSelectResultTableMHE, makeSelectResultTableRole } from 'containers/PlanResultPage/selectors';
import { getToken, makeSelectRunningApiCalls, makeSelectView } from 'containers/App/selectors';
import MasterPlanInterfaceBasicInfo from 'components/InterfaceBasicInfo/MasterPlanInterfaceBasicInfo';
import Subtitle from 'containers/PlanResultPage/Subtitle';
import {
  loadConfigFromFavAction,
  registerTableAction,
  setTableConfigAction,
  unregisterTableAction,
} from 'containers/TableControlled/actions';
import { withReducer as withTCReducer } from 'containers/TableControlled/index';
import { makeSelectTableControlled } from 'containers/TableControlled/selectors';
import { openUploadModalAction } from 'containers/UploadModal/actions';
import { vcHeadersByRange } from 'utils/api/calculations';
import { extractDiffFieldsShallow } from 'utils/commonDetailSaga';
import { DOW_TRANS_ACTIVITY_AKA_WEEKLY, T_TYPE } from 'utils/constants';
import withSecurity, { PERMISSIONS } from 'utils/security';
import { parserPlanResult, searchToData } from 'utils/url';

import { PATHS } from '../App/constants';
import { selectEditFromplan } from '../PlanDetailPage/selectors';
import createUploadModal from '../UploadModal';
import { cleanAction, cleanCalculationAction, editModeChangeAction, toggleVolumeFormula, toggleVolumeValue, storeCalcualtedResultTablesAction as storeResult } from './actions';
import { DT_TYPE } from './calculation/types';
import { ACTIVITY_T_NAME, COLUMN_SETTINGS_KEY, GRANULARITY, MHE_T_NAME, SETTINGS_KEY } from './constants';
import messages from './messages';
import { fetchPlan, fetchMasterPlan } from './planUtils';
import {
  getResult,
  selectActivitiesByPlan,
  selectEdit,
  selectPlan,
  selectTableKey,
  selectResultTableSettings,
  selectMasterPlan,
  selectPlanBasicDetails,
  getVolumeRawData,
  getMasterPlanVolumeRawData
} from './selectors';
import { CloseButton, SnackbarContent, UndoButton } from './styled';
import UnproductiveActivitiesResultMasterPlan from './UnproductiveActivitiesResultMasterPlan';
import MasterPlanVolumeCategoryTable from './MasterPlanVolumeCategoryTable';
import {
  cellCallBack,
  resultcreateColDefs,
  defaultColumnSettings,
  exportResultAsCsvUtilsMaster,
  extractTableConfig,
  fetchMasterPlanCalculations,
  isUserView,
  loadFromStorage,
  loadFromStorageById,
  saveOverrides,
  saveMasterPlanOverrides,
  saveToStorageById,
  undoMasterPlanResultChanges,
  minMaxDates
} from './utils';
import { ACTIVE_ENV } from '../../utils/activeEnv';
import VolumeCategoryForWZPHour from './VolumeCategoryForWZPHour';
import { recalculateVolumeCategoryParameters } from '../../utils/api/calculations';

const UploadModal = createUploadModal('planResult');

export const SectionWrap = styled.div`
  margin: 5px 10px;
  padding: 5px;
  box-shadow: ${props => props.theme.shadow.pageBox};
  background-color: white;

  :nth-child(2) {
    margin-top: 80px;
  }
`;

const ActivitySection = styled.p`
    text-align: center;
`;

const SectionTitle = styled.div`
  font-size: ${props => props.theme.fontSize.title};
  font-weight: 700;
  margin-bottom: 8px;
`;

export const PageSection = (sectionProps: Object) => (
  <SectionWrap>
    {sectionProps.labelMessage && (
      <SectionTitle>
        <FormattedMessage {...sectionProps.labelMessage} />
      </SectionTitle>
    )}
    {sectionProps.children}
  </SectionWrap>
);

export const Section = styled(ToggleSection)`
  margin: 5px 10px;
  padding: 5px;
`;

export const Wrap = styled(BasePage)`
  ${Section}:nth-child(2) {
    margin-top: 80px;
  }

  ${Section} {
    .title {
      margin-bottom: 0px;
    }
  }
`;

type ObjectOrBool = boolean | Object;

type Props = {
  plan: Object,
  result: Object,
  intl: Object,
  resultSettings: Object,
  user: Object,

  activityData: ObjectOrBool,
  trendData: ObjectOrBool,
  diloData: ObjectOrBool,

  editing: boolean,
  isLoading: boolean,

  // is plan was editing
  planEdit: boolean,
  editingOpen: boolean,
  fixed: boolean,
  showOverrideHelper: boolean,

  sendToKronosAction: Function,
  toggleOverrideHelperAction: Function,

  handleSubmit: Function,
  cleanResults: Function,
  fetchPlanAction: Function,
  loadActivityGraphDataAction: Function,
  loadDiloGraphDataAction: Function,
  cleanCalculation: Function,
  editModeChange: Function,
  openUploadModal: Function,
  registerTable: Function,
  unregisterTable: Function,

  activityTable: Object,
  tableKey: string,

  dispatch: Function,
  openDirtyDialog: Function,
  activitiesByPlan: Object,
  token: string,
  setTableConfig: Function,
  history: Object,

  view: string,
  activityConfig: Object,
  mheConfig: Object,
};

type StateProps = {
  columnSettings: Object,
  settings: Object,
  resultSettings: Object,
  showResult: boolean,
  userChangeId?: number | null,
};

const groupReg = new RegExp('_group$');

type SettingsType = {
  includeMhe: boolean,
  includeRole: boolean,
  startDate: Object | null,
  endDate: Object | null,
  granularity: string,
  planningParametersId: number | null,
};

class MasterPlanResultPage extends React.PureComponent<Props, StateProps> {
  activityTableSortState = null;

  activityTableFilterState = null;

  mheTableFilterState = null;

  mheTableSortState = null;

  mheGroupState = {};

  activityGroupState = {};

  activityTableName = ACTIVITY_T_NAME;

  mheTableName = MHE_T_NAME;

  onColumnSettingsChange = ({ name, value }) => {
    const {
      history,
      user: {
        user: { login },
      },
      plan,
    } = this.props;
    const columnSettings = { ...this.state.columnSettings, [name]: value };
    this.lastTableScrollPosition.current = null;
    this.setState({ columnSettings });
    saveToStorageById(login, plan && plan.id, columnSettings, COLUMN_SETTINGS_KEY);
  };



  getSettings = () => {
    const {
      history: { location },
      plan,
      user: {
        user: { login },
      },
      masterPlan
    } = this.props;
    let data = {};
    if (location.search) {
      data = searchToData(location.search, parserPlanResult) || {};
    }
    const { minDate, maxDate } = minMaxDates(masterPlan && masterPlan.plans);
    const pp = (plan && plan.id === data.planId && plan.planningParameters) || null;
    const planIds = masterPlan && masterPlan.plans.map((plan) => plan.planingParameterId) || [];
    const planData = masterPlan && masterPlan.plans;
    const saved = loadFromStorageById(login, plan && plan.id, SETTINGS_KEY);
    const settings = {
      includeMhe: (saved && saved.includeMhe) || false,
      includeRole: (saved && saved.includeRole) || false,
      includeBaseLine: (saved && saved.includeBaseLine) || false,
      includeWeekStartAsSunday: (saved && saved.includeWeekStartAsSunday) || false,
      includeVolumeCategory: (saved && saved.includeVolumeCategory) || false,
      includeForecast: (saved && saved.includeForecast) || false,
      startDate: (saved && DateTime.fromISO(saved.startDate)) || DateTime.fromISO(minDate) || null,
      endDate: (saved && DateTime.fromISO(saved.endDate)) || DateTime.fromISO(maxDate) || null,
      granularity: (saved && saved.granularity) || GRANULARITY.WEEK,
      planningParametersId: planIds,
      planIds: planIds,
      planData: planData,
    };
    return settings;
  };

  getColumnSettings = () => {
    const {
      plan,
      user: {
        user: { login },
      },
    } = this.props;
    const saved = loadFromStorageById(login, plan && plan.id, COLUMN_SETTINGS_KEY);
    const pp = (plan && plan.planningParameters) || null;

    const columnSettings = { ...defaultColumnSettings };
    Object.keys(columnSettings).forEach(key => {
      if (saved && key in saved) {
        columnSettings[key] = saved[key];
      }
    });

    return columnSettings;
  };

  constructor(props: Props) {
    super(props);
    const {
      history: { location },
      plan,
      user: {
        user: { login },
      },
    } = this.props;

    const settings = this.getSettings();
    const columnSettings = this.getColumnSettings();

    this.state = {
      showResult: false,
      userChangeId: null,
      columnSettings,
      settings,
      resultSettings: settings,
    };

    let data = {};
    if (location.search) {
      data = searchToData(location.search, parserPlanResult) || {};
    }

    this.activityTableName = `${ACTIVITY_T_NAME}_${data.planId}`;
    this.mheTableName = `${MHE_T_NAME}_${data.planId}`;

    const { activityConfig, mheConfig, view } = this.props;
    const activityConf = activityConfig;
    const mheConf = mheConfig || loadFromStorage(this.mheTableName);

    const userView = isUserView(view);
    this.mheTableSortState = (userView && mheConfig && mheConfig.sortModel) || null;
    this.mheTableFilterState = (userView && mheConfig && mheConfig.filterModel) || null;
    this.activityTableSortState = (userView && activityConf && activityConf.sortModel) || null;
    this.activityTableFilterState = (userView && activityConf && activityConf.filterModel) || null;
    this.activityGridApi = null;
    this.mheGridApi = null;
    this.lastTableScrollPosition = React.createRef < ScrollPosition > (null);
  }

  componentDidMount() {
    const { registerTable } = this.props;
    registerTable(this.mheTableName, {});
    registerTable(this.activityTableName, {});
    this.loadData();
    this.props.toggleVolumeFormula(true);
    this.props.toggleVolumeValue(true);
  }

  componentWillUnmount() {
    const { unregisterTable } = this.props;
    unregisterTable(this.mheTableName);
    unregisterTable(this.activityTableName);
  }

  loadData = () => {
    const {
      plan,
      activityTable,
      dispatch,
      token,
      history: { push },
      cleanResults,
      cleanCalculation,
    } = this.props;
    const { settings } = this.state;
    const data = searchToData(this.props, parserPlanResult);
    // if (!data.planId) {
    //   push(PATHS.planList);
    // }
    // shoud we reload plan
    if (plan && plan.id !== data.planId) {
      cleanResults();
    } else if (plan && activityTable && activityTable.settings) {
      // should we clean the calculation data
      const keysToPick = intersection(Object.keys(settings), Object.keys(activityTable.settings));
      const diff = extractDiffFieldsShallow(pick(settings, keysToPick), pick(activityTable.settings, keysToPick));
      if (Object.keys(diff).length > 0) {
        cleanCalculation();
        return;
      }
      if (activityTable.data) {
        this.setState({ showResult: true });
      }
    }
    fetchMasterPlan(data.masterPlanId, token, dispatch);
  };

  componentDidUpdate(prevProps, prevState) {
    const { plan, view, cleanCalculation, masterPlan, editing, planBasicDetails } = this.props;
    if (prevProps.view !== view) {
      const userView = isUserView(view);
      let activityConf = null;
      let mheConf = null;

      if (userView) {
        const { activityConfig, mheConfig } = this.props;
        activityConf = activityConfig;
        mheConf = mheConfig;
        if (!activityConfig) {
          activityConf = loadFromStorage(this.activityTableName) || null;
          mheConf = loadFromStorage(this.mheTableName) || null;
        }
      }

      this.activityTableSortState = (activityConf && activityConf.sortModel) || null;
      this.activityTableFilterState = (activityConf && activityConf.filterModel) || null;
      this.mheTableSortState = (mheConf && mheConf.sortModel) || null;
      this.mheTableFilterState = (mheConf && mheConf.filterModel) || null;
      if (this.mheGridApi) {
        this.mheGridApi.api.setSortModel(this.mheTableSortState);
        this.mheGridApi.api.setFilterModel(this.mheTableFilterState);
      }
    }

    if ((!prevProps.plan && plan) || (plan && prevProps.plan && prevProps.plan.id !== plan.id) || (!prevProps.masterPlan && masterPlan) || (plan && prevProps.masterPlan && prevProps.masterPlan.id !== masterPlan.id)) {
      this.setState({
        settings: this.getSettings(),
        resultSettings: this.getSettings(),
        columnSettings: this.getColumnSettings(),
      });
    }

    const result = this.props.result || null;
    const finalData = [];
    if (result && Object.keys(result).length > 0 && plan) {
      if (this.hasNoWorkingHoursOrShift()) {
        cleanCalculation();
      }
      if (
        prevProps.result !== this.props.result ||
        prevState.columnSettings !== this.state.columnSettings ||
        prevProps.editing !== this.props.editing
      ) {
        const { intl, editing, activitiesByPlan, dispatch } = this.props;
        const resultSettings = this.state.columnSettings;
        const filter = { ...this.state.settings, ...resultSettings };
        const editingFlag = this.props.editing;
        if (this.mheGridApi) {
          this.mheTableFilterState = this.mheGridApi.api.getFilterModel();
          this.mheTableSortState = this.mheGridApi.api.getSortModel();
        }
        // this.props.result.forEach((resultData)=>{
        //   createColDefs(intl, plan, resultData, filter, editing, activitiesByPlan, dispatch,);
        // })
        getColDefs(this.props.result)
        async function getColDefs(resultArray) {
          for (const resultData of resultArray) {
            const colDefData = await resultcreateColDefs(intl, plan, resultData, filter, editingFlag, activitiesByPlan, dispatch, planBasicDetails);
            const {
              defsAndData: { activity, mhe, editing, isShift, byHour, role, name, planingParameterId, isShiftFlag, dateFrom, dateTo, planId },
              settings,
            } = colDefData;
            const currentValidFrom = plan?.plans?.filter((p)=> p.planingParameterId === planingParameterId);
            const newObject = {
              editing, settings, isShift, byHour,
              mhe: {},
              role: {},
              activity: {}
            }
            if (mhe) {
              newObject.mhe.colDef = mhe.colDef;
              newObject.mhe.data = mhe.data;
              newObject.mhe.totals = mhe.totals;
              newObject.mhe.planName = name;
            }
            if (role) {
              newObject.role.colDef = role.colDef;
              newObject.role.data = role.data;
              newObject.role.totals = role.totals;
              newObject.role.planName = name;
            }
            newObject.activity.colDef = activity.colDef;
            newObject.activity.data = activity.data;
            newObject.activity.totals = activity.totals;
            newObject.activity.planName = name;
            newObject.activity.byHour = byHour;
            newObject.calculationStatus = 'finished';
            newObject.isShiftFlag = isShiftFlag;
            newObject.activity.planingParameterId = planingParameterId;
            newObject.planId = currentValidFrom[0]?.planId;
            newObject.validFrom = currentValidFrom[0]?.validFrom;
            newObject.validTo = currentValidFrom[0]?.validTo;
            newObject.dateFrom = dateFrom;
            newObject.dateTo = dateTo;
            finalData.push(newObject)
          }
          const sortByDate = (a, b) => (a.validFrom > b.validFrom) ? 1 : ((b.validFrom > a.validFrom) ? -1 : 0);
          finalData?.sort(sortByDate);
          dispatch(storeResult(finalData));
        }
      }
    }
  }

  activityGridApi = undefined;

  mheGridApi = undefined;

  fileName = (m, type = 'Activities') =>
    this.props.intl.formatMessage(m || messages.exportLabel, {
      name: this.props.plan.name.replace(/\./g, '-'),
      type,
    });

  sheetName = type => {
    /*
      Certain characters are not allowed in worksheet names, including square brackets,
      asterisks, question marks, forward and backward slashes, periods,  apostrophes,
      and colons. If try to type these characters, into a worksheet name,
      Excel will simply ignore the input.
    */
    let name = this.props.intl
      .formatMessage(messages.sheetLabel, {
        name: this.props.plan.name,
        type,
      })
      .match(/^(.{0,15}).*$/)[1];

    name = name
      // eslint-disable-next-line no-useless-escape
      .replace(/\/|\\|\:|\?/g, '.')
      .replace(/\[/g, '(')
      .replace(/\]/g, ')')
      .replace(/"|'|\*/g, '')
      .replace('&', '')
      .trim();
    return name;
  };

  export = (api, type, data, calculateValueType, granularity) => {
    if (api) {
      let columnKeys = api.columnApi.getAllDisplayedColumns().map(a => a.colId.replace(groupReg, ''));

      // Hidden column "uom" should be exported as well
      columnKeys = [columnKeys[0], 'uom'].concat(columnKeys.slice(1));

      api.api.exportDataAsExcel({
        columnGroups: true,
        skipGroups: false,
        columnKeys,
        fileName: this.fileName(undefined, type),
        sheetName: this.sheetName(type),
        processCellCallback: params => cellCallBack(params, data, calculateValueType, granularity),
      });
    }
  };

  onActivityExport = (params) => {
    const data = this.props?.result?.filter((r)=> params.activity.planingParameterId === r.planingParameterId);
    return this.export(this.activityGridApi, 'activity', Object.assign({}, data) , this.props.plan?.planningParameters?.calculateType, this.state.settings.granularity);
  }

  onMHEExport = () => this.export(this.mheGridApi, 'MHE');

  onRoleExport = () => this.export(this.roleGridApi, 'ROLE');

  onActivityGridReady = (params, loaded) => {
    if (!loaded) return;
    this.activityGridApi = params;
    this.activityTableFilterState && params.api.setFilterModel(this.activityTableFilterState);
    this.activityTableSortState && params.api.setSortModel(this.activityTableSortState);
    let groupStateWasChanged = false;
    if (this.activityGroupState && Object.keys(this.activityGroupState).length > 0) {
      params.api.forEachNodeAfterFilter(node => {
        const expanded = this.activityGroupState[node.id];
        if (expanded !== undefined) {
          node.expanded = expanded;
          groupStateWasChanged = true;
        }
      });
    }
    if (groupStateWasChanged) {
      params.api.onGroupExpandedOrCollapsed();
    }
  };

  filterCols = ['department', 'uom', 'activity', 'ag-Grid-AutoColumn'];

  makeDirtyHandler = (cb: Function, waitTime = 500, reloadBeforeCB = true) => (values: any) => {
    if (this.formikBag.dirty) {
      const { props } = this;
      const { settings } = this.state;
      props.openDirtyDialog(this.save, async () => {
        this.formikBag.resetForm();
        if (reloadBeforeCB) {
          await fetchMasterPlanCalculations(settings, props.token, props.dispatch);
        }
        cb(values);
      });
    } else {
      cb(values);
    }
  };

  onMheTableReady = (params, loaded, mhe) => {
    if (mhe && mhe.length > 0) {
      //console.log("Coming heer")
      this.setState({ mheValues: mhe })
    }
    if (!loaded) return;
    this.mheGridApi = params;
    this.mheTableFilterState && params.api.setFilterModel(this.mheTableFilterState);
    this.mheTableSortState && params.api.setSortModel(this.mheTableSortState);
  };

  onRoleTableReady = (params, loaded) => {
    if (!loaded) return;
    this.roleGridApi = params;
    this.roleTableFilterState && params.api.setFilterModel(this.roleTableFilterState);
    this.roleTableSortState && params.api.setSortModel(this.roleTableSortState);
  };

  reloadCaluculation = (data) => {
    fetchMasterPlanCalculations(this.state.settings, this.props.token, this.props.dispatch);
    this.setState({ userChangeId: data });
  };

  hasNoWorkingHoursOrShift = () => {
    const { plan } = this.props;
    if (plan) {
      const pParams = plan.planningParameters;
      if (pParams) {
        let hasShiftOrWZP = false;
        if (pParams.workingHours) {
          pParams.workingHours.forEach(wh => {
            hasShiftOrWZP =
              hasShiftOrWZP ||
              !!(wh.wzps && wh.wzps.length) ||
              !!(wh.days && wh.days.length) ||
              !!(wh.shifts && wh.shifts.length);
          });
        }
        return !hasShiftOrWZP;
      }
    }
    return false;
  };

  calculateMatrix = (values: Object) => {
    const { intl, token, dispatch, user, plan, masterPlan } = this.props;
    if (this.hasNoWorkingHoursOrShift()) {
      const pParams = this.formikBag && this.formikBag.values.planningParameters;
      if (pParams.transformationType === T_TYPE.SHIFT) {
        toast.error(intl.formatMessage(messages.errorAssignShiftToPerformCalculation));
      } else {
        toast.error(intl.formatMessage(messages.errorAssignWZPToPerformCalculation));
      }
      this.setState({ showResult: false });
    } else {
      saveToStorageById(user.user.login, masterPlan && masterPlan.id, values, SETTINGS_KEY);
      fetchMasterPlanCalculations(values, token, dispatch);
      this.lastTableScrollPosition.current = null;
      this.setState({ showResult: true, settings: values });
    }
  };

  save = async () => {
    const {
      dispatch,
      token,
      activityTable: { calculationStatus },
    } = this.props;
    this.formikBag && this.formikBag.setSubmitting(true);
    const userChangeId = await saveMasterPlanOverrides(
      this.formikBag && this.formikBag.values,
      this.formikBag && this.formikBag.initialValues,
      { ...this.state.settings },
      token,
      dispatch,
      calculationStatus !== 'notStarted',
    );
    this.formikBag.setSubmitting(false);
    this.formikBag.resetForm(this.formikBag.initialValues);
    this.setState({ userChangeId });
  };

  onShowLabourAvailability = value => {
    const {
      user: {
        user: { login },
      },
      plan,
    } = this.props;
    const columnSettings = { ...this.state.columnSettings, showLabourAvailability: value };
    this.setState({ columnSettings });
    saveToStorageById(login, plan && plan.id, columnSettings, COLUMN_SETTINGS_KEY);
  };

  undoChanges = () => async () => {
    const { userChangeId, settings } = this.state;
    const { token, dispatch } = this.props;
    const response = await undoMasterPlanResultChanges(userChangeId, token, dispatch);
    if (response) {
      await fetchMasterPlanCalculations(settings, token, dispatch);
    }
    this.setState({ userChangeId: null });
  };

  handleConfigChange = (name, params) => {
    const { setTableConfig } = this.props;
    const toSet = extractTableConfig(params);
    setTableConfig(name, toSet);
  };

  onActivityFilterChanged = params => {
    this.activityTableFilterState = params.api.getFilterModel();
    this.activityTableSortState = params.api.getSortModel();
  };

  onActivitySortChanged = params => {
    const { activityConfig } = this.props;
    this.activityTableSortState = params.api.getSortModel();
    if (activityConfig) {
      const { sortModel } = activityConfig;
      if (JSON.stringify(sortModel) === JSON.stringify(this.activityTableSortState)) {
        return;
      }
    }
    this.handleConfigChange(this.activityTableName, params);
  };

  onMheFilterChanged = params => {
    this.mheTableFilterState = params.api.getFilterModel();
    const { mheConfig } = this.props;

    if (mheConfig) {
      const { filterModel } = mheConfig;
      if (JSON.stringify(filterModel) === JSON.stringify(this.mheTableFilterState)) {
        return;
      }
    }
    this.handleConfigChange(this.mheTableName, params);
  };

  onMheSortChanged = params => {
    this.mheTableSortState = params.api.getSortModel();
    const { mheConfig } = this.props;

    if (mheConfig) {
      const { sortModel } = mheConfig;
      if (JSON.stringify(sortModel) === JSON.stringify(this.mheTableSortState)) {
        return;
      }
    }

    this.handleConfigChange(this.mheTableName, params);
  };

  onActivityRowGroupOpened = row => (this.activityGroupState[row.node.id] = row.node.expanded);

  onMHERowGroupopened = row => (this.onMHERowGroupopened[row.node.id] = row.node.expanded);

  // removing formikBag Refreshing
  onResultMatrixConfirm = null;

  onExportActivity = null;

  dirty = false;

  formikBag = null;

  dirtyHandler = (func: Function) => this.makeDirtyHandler(func, 2000, false);
  dirtyReportHandler = (func: Function) => this.makeDirtyHandler(func, 2000, true);

  renderFormikArea = (formikBag: Object) => {
    const { props } = this;
    const { editing, plan, planEdit, tableKey, intl, activityTable, user, theme, masterPlan, planBasicDetails } = props;
    const {
      showResult,
      userChangeId,
      settings: { includeMhe, granularity, includeBaseLine, includeForecast, startDate, endDate, includeRole, includeWeekStartAsSunday, includeVolumeCategory },
      columnSettings,
    } = this.state;
    const minvalidFrom = masterPlan?.plans?.length > 1 ? new Date(Math.min(...masterPlan?.plans.map(e => new Date(e.validFrom)))).toLocaleDateString() : masterPlan?.plans?.length ==1 ? masterPlan?.plans[0]?.validFrom :'';
    const maxvalidTo = masterPlan?.plans?.length > 1 ? new Date(Math.max(...masterPlan?.plans.map(e => new Date(e.validTo)))).toLocaleDateString() : masterPlan?.plans?.length ==1 ? masterPlan?.plans[0]?.validTo :'';
    const pParams = masterPlan && masterPlan.plans && masterPlan.plans[0]?.planId;
    // const tTypeWeek = pParams && pParams.dayTransformationType === DOW_TRANS_ACTIVITY_AKA_WEEKLY;
    const plansTransformationType = true//planBasicDetails?.every((p)=>p.dayTransformationType === 'VOLUME_CATEGORY');
    const hasNoWorkingHoursOrShift = this.hasNoWorkingHoursOrShift();
    let showVC = granularity === 'WEEK' || (granularity === 'DAY' && plansTransformationType);
    let showVCForWZPHour = (granularity === 'WZP' || granularity === 'HOUR') && this.state.settings.includeVolumeCategory && activityTable.calculationStatus !== 'notStarted';
    let showVCIncludeSelected = (granularity === 'WEEK' || (granularity === 'DAY') && this.state.settings.includeVolumeCategory);
    // if (showVC) {
    //   const vc = pParams && pParams.volumeCategoryParameters;
    //   showVC = vc.rowData.length > 0 || vc.rowDataVar.length > 0;
    // }
    // if (showVCIncludeSelected) {
    //   const vc = pParams && pParams.volumeCategoryParameters;
    //   showVCIncludeSelected = (vc.rowData.length > 0 || vc.rowDataVar.length > 0);
    // }
    // if (showVCForWZPHour) {
    //   const vc = pParams && pParams.volumeCategoryParameters;
    //   showVCForWZPHour = (vc.rowData && vc.rowData.length > 0) || (vc.rowDataVar && vc.rowDataVar.length > 0);
    // }
    if (!this.formikBag || formikBag.dirty !== this.dirty) {
      this.dirty = formikBag.dirty;
      this.onExportActivity = this.makeDirtyHandler(this.onActivityExport, 2000);
      this.onResultMatrixConfirm = this.makeDirtyHandler(this.calculateMatrix, 2000, false);
      this.onColumnSettingsChange = this.makeDirtyHandler(this.onColumnSettingsChange, 2000, false);
    }

    this.formikBag = formikBag;

    const toolBarProps = {
      id: plan.id,
      editable: plan.editable,
      deleted: plan.deleted,
      //ppId: pParams.id,
      planEdit,
      editing,
      dirty: this.dirty,
      title: plan.name,
    };
    // const storedPRGranularity = pParams.productivityRateGranularity.toUpperCase();
    // const noOverrideWeeklyForPRDaily = storedPRGranularity === GRANULARITY.DAY && granularity === GRANULARITY.WEEK;
    // const noOverrideWeekly = pParams.dayTransformationType === DT_TYPE.DAILY && granularity === GRANULARITY.WEEK;
    // const noOverrideMonthly = granularity === GRANULARITY.MONTH;
    // const noOverrideTransformationTypeShift =
    //   pParams.transformationType === T_TYPE.SHIFT && granularity === GRANULARITY.HOUR;
    let subtitle = '';
    // if (showResult && editing) {
    //   if (noOverrideWeekly) {
    //     subtitle = <Subtitle message={messages.noWeeklyOverides} tooltipImage={theme.image.resultTitleTooltip} />;
    //   }
    //   if (noOverrideMonthly) {
    //     subtitle = <Subtitle message={messages.noMonthlyOverides} tooltipImage={theme.image.resultTitleTooltip} />;
    //   }
    //   if (noOverrideTransformationTypeShift) {
    //     subtitle = <Subtitle message={messages.noHourlyOverides} tooltipImage={theme.image.resultTitleTooltipShift} />;
    //   }
    //   if (noOverrideWeeklyForPRDaily) {
    //     subtitle = (
    //       <Subtitle message={messages.noWeeklyOveridesPRHourly} tooltipImage={theme.image.resultTitleTooltip} />
    //     );
    //   }
    // }

    const unproductiveActivitiesResultProps =
      showResult && !hasNoWorkingHoursOrShift
        ? {
          startDate: this.state.settings.startDate,
          endDate: this.state.settings.endDate,
          granularity: this.state.settings.granularity,
          planningParametersId: this.state.settings.planningParametersId,
          token: this.props.token,
          masterPlan: this.props.masterPlan,
        }
        : {};

    const limitColumnSettingsByCalculatedData = cs => ({
      ...cs,
      showBudget: cs.showBudget && includeBaseLine,
      showForecast: cs.showForecast && includeForecast,
    });
    const mheWarningValues = this.state.mheValues && this.state.mheValues.map((item) => { return item.value }).join(', ');
    const mheWarings = this.state.mheValues && this.state.mheValues.length > 0 ? messages.mheWarningTitle : '';
    const isShiftFlag = this.props.activityTable && this.props.activityTable[0] && this.props.activityTable[0]?.isShiftFlag;//planBasicDetails?.every((p) => p.planningParameters.transformationType == "SHIFT");
    const isShiftDateOptionsFlag = planBasicDetails?.every((p) => p.planningParameters.transformationType == "SHIFT");
    return (
      <>
        <Prompt when={formikBag.dirty} message={intl.formatMessage(messages.dirtyForm)} />
        <MasterPlanResultToolBar
          {...toolBarProps}
          saveData={this.save}
          makeDirtyHandler={this.dirtyHandler}
          makeReportDirtyHandler={this.dirtyReportHandler}
          editModeChange={props.editModeChange}
          openUploadModal={props.openUploadModal}
          masterPlan={masterPlan}
          minvalidFrom={minvalidFrom}
          maxvalidTo = {maxvalidTo}
        />
        <PageSection labelMessage={messages.masterPlanbasicInfo}>
          <MasterPlanInterfaceBasicInfo masterPlan={masterPlan} planBasicDetails={planBasicDetails} intl={intl} isresultPage={true}/>
        </PageSection>
        <Section subtitleToShow={mheWarings} message={messages.mheWarning}>
            {
              this.state.mheValues && this.state.mheValues.length > 0 ? (
               <WarningText>
                  {`Discrepancy available for the MHE ${mheWarningValues}.`}
               </WarningText>
              ) : (
                <WarningText><FormattedMessage {...messages.noWarningAvailable} /></WarningText>
              )
            }
        </Section>
        {/* <Section message={messages.basicInfoPA}>
          <ResultPABasicInfo pa={plan.planningArea} intl={intl} />
        </Section> */}
        <PageSection labelMessage={messages.resultMatrix}>
          <ResultMatrixDateOptionsMasterPlan
            onResultSettingsChange={s => this.setState({ resultSettings: s })}
            {...this.props}
            formikBag={formikBag}
            planningParametersId={masterPlan.id}
            isShift={isShiftDateOptionsFlag}
            endDate={masterPlan?.plans[0]?.validFrom}
            startDate={masterPlan?.plans[0]?.validTo}
            {...this.state.settings}
            onConfirm={this.onResultMatrixConfirm}
            viewSettings={this.viewSettings}
            showBaseLine
            granularityValue={this.state.resultSettings.granularity}
            minDate = {minvalidFrom}
            maxDate = {maxvalidTo}
          />
        </PageSection>
         {(!includeWeekStartAsSunday) &&(
          (activityTable.calculationStatus === 'notStarted') && showVC ?
            <Section expanded={this.props.isLoading } message={messages.volumeCategory}>
              {granularity === 'WEEK' ? <ResultMasterPlanVolumeTable resultPage={true} toggleVolumeValue={this.props.toggleVolumeValue} toggleVolumeFormula={this.props.toggleVolumeFormula} /> : <ResultMasterPlanVolumeTableDaily resultPage={true} toggleVolumeValue={this.props.toggleVolumeValue} toggleVolumeFormula={this.props.toggleVolumeFormula} />}
            </Section> : (showVC || showVCIncludeSelected) ?
              <Section expanded={this.props.isLoading} message={messages.volumeCategory}>
                {granularity === 'WEEK' ? <ResultMasterPlanVolumeTable resultPage={true} toggleVolumeValue={this.props.toggleVolumeValue} toggleVolumeFormula={this.props.toggleVolumeFormula} /> : <ResultMasterPlanVolumeTableDaily resultPage={true} toggleVolumeValue={this.props.toggleVolumeValue} toggleVolumeFormula={this.props.toggleVolumeFormula} />}
              </Section> : showVCForWZPHour ?
                <Section expanded={this.props.isLoading} message={messages.volumeCategory}>
                  { this.props.volumeCategoryWZPHourRawData && this.props.volumeCategoryWZPHourRawData.map((data)=>{
                    return <VolumeCategoryForWZPHour isShift={isShiftFlag} data={data} granularity={this.state.settings.granularity} {...this.props} />
                  })
                  }
                </Section> :
                null
        )} 
        <Section message={messages.activityTitle} subtitle={subtitle} expanded={showResult && !hasNoWorkingHoursOrShift}>
          <MasterPlanColumnOptions
            {...this.props}
            formikBag={formikBag}
            settings={{
              ...columnSettings,
              showBudget: includeBaseLine === false ? false : columnSettings.showBudget,
              showForecast:
                includeForecast === false
                  ? false
                  : columnSettings.showForecast &&
                  this.props.viewSettings &&
                  this.props.viewSettings.inputType !== 'volume',
            }}
            disableShowBudget={includeBaseLine === false}
            disableShowForecast={includeForecast === false}
            onChange={this.onColumnSettingsChange}
          />
          {userChangeId && (
            <Snackbar open={!!userChangeId}>
              <SnackbarContent
                message={<span>{intl.formatMessage(messages.changesSaved)}</span>}
                action={[
                  <UndoButton
                    size="small"
                    onClick={this.undoChanges()}
                    label={messages.undoChanges}
                    key="undo"
                  />,
                  <CloseButton
                    key="close"
                    aria-label="close"
                    color="inherit"
                    onClick={() => this.setState({ userChangeId: null })}
                  >
                    <FontAwesomeIcon icon="times" size="sm" />
                  </CloseButton>,
                ]}
              />
            </Snackbar>
          )}
          {(activityTable && activityTable.calculationStatus === 'notStarted') ? 
          <ActivitySection>Please confirm selection to see data.</ActivitySection>
          :
          this.props.isLoading || (activityTable && activityTable.calculationStatus === 'running') ? (
            // Don't render the tables at all during calculation. Otherwise it may affect performance on repeated calculation.
            <Loading status={this.props.isLoading ? 'loading' : activityTable.calculationStatus} />
          ) : (
            <div className="result_tables">
                {
                activityTable && Object.keys(activityTable) && Object.keys(activityTable).map((data, index) => (
                  <ResultMatrixActivitiesTableMasterPlan
                    result={activityTable[data] && activityTable[data]['activity']}
                    formikBag={formikBag}
                    onExport={()=> this.onExportActivity(activityTable[data])}
                    onCsvExport={() => {
                      exportResultAsCsvUtilsMaster(
                        activityTable[data].planId,
                        this.state.resultSettings.granularity,
                        activityTable[data].dateFrom,
                        activityTable[data].dateTo,
                        this.props.token,
                        this.props.dispatch,
                        false,
                        false
                      ).then();
                    }}
                    onOverrideHelper={() => this.props.toggleOverrideHelperAction(true)}
                    onGridReady={this.onActivityGridReady}
                    onShowLabourAvailability={this.onShowLabourAvailability}
                    showLabourAvailability={this.state.columnSettings.showLabourAvailability}
                    granularity={granularity}
                    onFilterChanged={this.onActivityFilterChanged}
                    onSortChanged={this.onActivitySortChanged}
                    onRowGroupOpened={this.onActivityRowGroupOpened}
                    lastScrollPosition={this.lastTableScrollPosition}
                    calculationStatus={activityTable[data] && activityTable[data].calculationStatus}
                    isShiftFlag={isShiftFlag}
                  />
                ))
              }
              {(includeMhe && !includeWeekStartAsSunday) && (
                <>
                  {(this.props.result) && (this.props.result).map((data, index) => (
                    <ResultMatrixMHETableSimplifiedDataModelMasterPlan
                      key={`mhe_simplified_data_model_${tableKey}_index`}
                      planId={plan.id}
                      formik={formikBag}
                      editing={editing}
                      data={data}
                      columnSettings={limitColumnSettingsByCalculatedData(columnSettings)}
                      planningParameters={this.props.plan.planningParameters}
                      onGridReady={this.onMheTableReady}
                      onExport={this.onMHEExport}
                      granularity={granularity}
                      startDate={startDate}
                      endDate={endDate}
                      onFilterChanged={this.onMheFilterChanged}
                      onSortChanged={this.onMheSortChanged}
                      onRowGroupOpened={this.onMheRowGroupOpened}
                      reloadCaluculation={this.reloadCaluculation}
                      pa={plan.planningArea}
                      paat={plan.planningParameters}
                      index={index}
                      result={this.props?.mheResult[index]?.mhe}
                      calculationStatus={this.props?.mheResult[index]?.calculationStatus}
                      isShiftFlag={isShiftFlag}
                      onCsvExport={() => {
                        exportResultAsCsvUtilsMaster(
                          data.planId,
                          this.state.resultSettings.granularity,
                          data.dateFrom,
                          data.dateTo,
                          this.props.token,
                          this.props.dispatch,
                          true,
                          false
                        ).then();
                      }}
                    />
                  ))
                  }
                </>
              )}
              {
                includeRole && (!includeWeekStartAsSunday) && (
                  <>
                    {(this.props.result) && (this.props.result).map((data, index) => (
                      <ResultMatrixRoleMasterPlan
                        key={`role_${tableKey}_index`}
                        planId={plan.id}
                        formik={formikBag}
                        editing={editing}
                        data={data}
                        columnSettings={limitColumnSettingsByCalculatedData(columnSettings)}
                        planningParameters={this.props.plan.planningParameters}
                        onGridReady={this.onRoleTableReady}
                        onExport={this.onRoleExport}
                        onCsvExport={() => {
                          exportResultAsCsvUtilsMaster(
                            data.planId,
                            this.state.resultSettings.granularity,
                            data.dateFrom,
                            data.dateTo,
                            this.props.token,
                            this.props.dispatch,
                            false,
                            true
                          ).then();
                        }}
                        granularity={granularity}
                        startDate={startDate}
                        endDate={endDate}
                        //onFilterChanged={this.onMheFilterChanged}
                        //onSortChanged={this.onMheSortChanged}
                        //onRowGroupOpened={this.onMheRowGroupOpened}
                        reloadCaluculation={this.reloadCaluculation}
                        pa={plan.planningArea}
                        paat={plan.planningParameters}
                        index={index}
                        result={this.props?.roleResult[index]?.role}
                        calculationStatus={this.props?.roleResult[index]?.calculationStatus}
                        isShiftFlag={isShiftFlag}
                      />
                    ))
                    }
                  </>
                )
              }
            </div>
          )}

        </Section>
        {!ACTIVE_ENV.shiftFillingDisabled && (
          <PageSection labelMessage={messages.unproductiveActivities}>
            <UnproductiveActivitiesResultMasterPlan {...unproductiveActivitiesResultProps} />
          </PageSection>
        )}
        <UploadModal
          entity="results"
          currentId={plan.id}
          intl={intl}
          user={user}
          isAuditor={this.props.hasPerm(PERMISSIONS.HISTORY_PLAN)}
        />
      </>
    );
  };

  render() {
    const { props } = this;
    const { intl, activityTable, plan, masterPlan, planBasicDetails } = props;
    const {
      settings: { startDate, endDate, granularity },
    } = this.state;
    const name = { name: masterPlan && masterPlan.name };
    let vcData = planBasicDetails?.map((p)=> ({volumeCategoryParameters: p.planningParameters.volumeCategoryParameters, planningParametersId: p.planningParametersId, planningParameters: p.planningParameters, planId: p.id, planName: p.name}) );
    if(props?.result){
      vcData?.forEach((vc)=>{
        const newData = props?.result?.filter((r)=>r.planingParameterId === vc.planningParametersId);
        vc.volumeCategoryParameters = newData[0]?.volumeCategoryParameters;
      })
    }
    const initialValues = {
      id: masterPlan && masterPlan.plans && masterPlan.plans[0]?.planId,
      overrides: {},
      mheOverrides: {},
      planningParameters: vcData,
    };
    return (
      (masterPlan ?
        <Wrap labelMessage={messages.masterPlanheader} noMaxWidth labelValues={name}>
          <Helmet>
            <title>{intl.formatMessage(messages.masterPlanheader, name)}</title>
            <meta name="description" content={intl.formatMessage(messages.masterPlancontent)} />
          </Helmet>
          <Formik
            initialValues={initialValues}
            enableReinitialize
            validateOnChange={false}
            onSubmit={this.save}
            render={this.renderFormikArea}
          />
        </Wrap> : null
      )
    );
  }
}

const mapStateToProps = (state, props) => {
  const {
    history: { location },
  } = props;
  let data = {};
  if (location.search) {
    data = searchToData(location.search, parserPlanResult);
  }
  return createStructuredSelector({
    plan: selectMasterPlan,
    masterPlan: selectMasterPlan,
    // TODO: SMP-89: here calculated activity table is loaded from redux but is not reseted when loading different plan. So results from old plan appear even if the plan is not calculated yet
    activityTable: selectResultTableSettings,
    editing: selectEdit,
    tableKey: selectTableKey,
    planEdit: selectEditFromplan,
    activitiesByPlan: selectActivitiesByPlan,
    mheConfig: makeSelectTableControlled(`${MHE_T_NAME}_${data.planId}`),
    activityConfig: makeSelectTableControlled(`${ACTIVITY_T_NAME}_${data.planId}`),
    view: makeSelectView(),
    token: getToken,
    result: getResult,
    isLoading: makeSelectRunningApiCalls(),
    viewSettings: selectViewModeStoredData,
    mheResult: makeSelectResultTableMHE(),
    roleResult: makeSelectResultTableRole(),
    planBasicDetails: selectPlanBasicDetails,
    volumeCategoryRawData: getVolumeRawData,
    volumeCategoryWZPHourRawData: getMasterPlanVolumeRawData,
  });
};

function mapDispatchToProps(dispatch) {
  const actions = bindActionCreators(
    {
      // tables
      registerTable: registerTableAction,
      setTableConfig: setTableConfigAction,
      unregisterTable: unregisterTableAction,
      loadConfigFromFav: loadConfigFromFavAction,
      editModeChange: editModeChangeAction,
      openUploadModal: openUploadModalAction,
      cleanResults: cleanAction,
      cleanCalculation: cleanCalculationAction,
      toggleVolumeFormula: toggleVolumeFormula,
      toggleVolumeValue: toggleVolumeValue,
    },
    dispatch,
  );
  return { ...actions, dispatch };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

// const withSaga = injectSaga({ key: 'MasterPlanResultPage', saga });

const PResultPage = compose(
  injectIntl,
  withConnect,
  withDirtyDialog,
  withTCReducer,
  withRouter,
  withSecurity(PERMISSIONS.VIEW_RESULT),
  withTheme,
)(MasterPlanResultPage);

export default PResultPage;
