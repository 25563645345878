import * as React from 'react';
import { useEffect } from 'react';
import { FormikProps } from 'formik';
import { ColDef, ColGroupDef } from 'ag-grid-community';
import { FormattedMessage, injectIntl } from 'react-intl';
import styled from 'styled-components';
import hash from 'object-hash';
import { camelCase, startCase } from 'lodash';
import Button from 'components/Button';
import { DAY_NAMES } from 'utils/calendar/constants';
import { constructdays, constructRowDataIndirect, constructRowDataDirect, sortDataByDays, validateRowDataIndirect } from './utils';
import OverwriteConfirmDialog from 'components/EffortForecast/OverwriteConfirmDialog';
import ModifyMultipleDialog from './ModifyMultipleDialog';
import messages from './messages';
import calendarMessages from 'utils/calendar/messages';
import { toNumber, numberFormat } from 'utils/utils';
import { parseDateAndFormatToLocalString, parseDate } from 'utils/dateTime';
import { createStructuredSelector } from 'reselect';
import { bindActionCreators, compose } from 'redux';
import { toast } from 'react-toastify';
import uuid from 'uuid/v4';
import { connect } from 'react-redux';
import cloneDeep from 'lodash/cloneDeep';
import AgTable from 'components/Table';
import { getCustomers } from 'components/CustomerCellRenderer';
import { selectEditFromplan, selectPlanDepartments } from 'containers/PlanDetailPage/selectors';
import { selectEditFromPa, selectAreaDepartments } from 'containers/PlanningAreaDetailPage/selectors';
import {
  ModifyMultipleDataSource,
  ModifyMultipleDialogType,
  ModifyMultipleFormSettingsAdjustments,
} from 'containers/ModifyMultipleDialog/types';

export interface AdjustmentOverviewProps {
  isEdit: boolean;
  intl: any;
  formik: FormikProps<any>;
  hasPerm: Function;
  save: Function;
  saveAdjustmentOverview: Function;
  granularityWeek: Function;
  offDays: any;
  firstDay: any;
  edit: boolean;
  periodIndex: number;
  departments: any;
  customers: any;
  isForecastEditor: boolean;
}

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 5px 0;
  & > * {
    margin: 5px 5px;
  }
`;

const TableWithHeight = styled(AgTable)`
  height: ${props => props.height}px;

  .cell-editable{
    background-color: #3a74ce;
    color: #FFF;
  }

  .cell-overriddenRate{
    font-weight: bold;
    color: ${props => props.theme.color.red};
  }

  .cell-editable.cell-overriddenRate{
    font-weight: bold;
    color: ${props => props.theme.color.citrusOrange};
  }

  .cell-empty-overriddenRate{
    background-color: #3a74ce !important;
    color: #FFF !important;
    font-weight: normal !important;
  }

  .cell-headCountEmpty{
    background-color: lightgray;
    border-right-color: coral;
  }
  .cell-negitiveValue{
    background-color: red !important;
    color: #FFF !important;
  }
`;

const AdjustmentOverview = ((props: AdjustmentOverviewProps) => {
  const { isEdit } = props;
  const [redrawTable, setRedrawTable] = React.useState(false);
  const [redrawDirectTable, setRedrawDirectTable] = React.useState(false);
  const [forecastPlanSwitch, setForecastPlanSwitch] = React.useState('plan');
  const [columns, setColumns] = React.useState([]);
  const [indirectColumns, setIndirectColumns] = React.useState([]);
  const [modifyMultipleOpen, setModifyMultipleOpen] = React.useState<boolean>(false);
  const [overwriteConfirmDialogVisible, setOverwriteConfirmDialogVisible] = React.useState<boolean>(false);
  const activityParamaterList = props?.formik?.values?.planningParameters?.periods?.map((data) => data.activityParameters).flat();
  const customers = getCustomers(props.formik);
  const isForecastEditor = props.isForecastEditor;
  const days = constructdays(props.formik);
  const daysArray = Array.from(days);
  const departments = props?.formik?.values?.planningParameters?.departments;

  const handleTogglePlanForecast = () => {
    setForecastPlanSwitch(forecastPlanSwitch === 'forecast' ? 'plan' : 'forecast');
  };
  const modifyMultipleStartDate = parseDate(props.formik.values.planningParameters.startDay);
  const modifyMultipleEndDate = parseDate(props.formik.values.planningParameters.endDay);

  const handleModifyMultiple = () => {
    setModifyMultipleOpen(true);
  };

  const handleCloseModifyMultiple = () => {
    setModifyMultipleOpen(false);
  };

  const handleBackToForecast = () => {
    const payload = [];
    const adjustmentOverviewToSend = cloneDeep(props.formik.values?.adjustmentOverviewData?.rows);
    const adjustmentOverviewData = cloneDeep(props.formik.values?.adjustmentOverviewData?.rows);
    adjustmentOverviewToSend && adjustmentOverviewToSend.forEach((row) => {
      row && row.columns.forEach(col => {
        if ("overriddenRate" in col) {
          payload.push({
            activityForecastFrom: row.activityForecastFromId,
            activityForecastTo: row.activityForecastToId,
            date: col.date,
            overriddenRate: null,
          })
        }
      })
    })
    adjustmentOverviewData && adjustmentOverviewData.forEach((row, index) => {
      row && row.columns.forEach((col, ind) => {
        if ("overriddenRate" in adjustmentOverviewData[index].columns[ind]) {
          adjustmentOverviewData[index].columns[ind].overriddenRate = null
        }
      }
      )
    }
    )
    const formikToModify = [];
    //@ts-ignore
    formikToModify.rows = adjustmentOverviewData;
    props.formik.setFieldValue(`adjustmentOverviewData`, formikToModify)
    props.formik.setFieldValue(`adjsutmentOverrideData`, payload)
    setOverwriteConfirmDialogVisible(false);
  };

  const handleMultipleEditValueSubmit = (settings: ModifyMultipleFormSettingsAdjustments) => {
    let greaterThanHundred = false;
    let greaterThanHundredValue = 0;
    const modStartDateFormat = settings.startDate.toFormat('yyyy-MM-dd');
    const modEndDateFormat = settings.endDate.toFormat('yyyy-MM-dd');
    const modStartDate = new Date(modStartDateFormat);
    const modEndDate = new Date(modEndDateFormat);
    const handleNewRate = (row) => {
      if (settings.changeType == "PERCENTAGE") {
        const currentRate = row.overriddenRate ? row.overriddenRate : row.type === 'FORECAST' ? row.forecastRate : row.defaultRate;
        const percentageUpdate = parseFloat(settings.percentageInput);
        const calValue = (Number(currentRate) + Number(currentRate) * (percentageUpdate / 100))
        if(calValue > 100){
          greaterThanHundred = true;
        }
          return calValue >=0 ? (Number(calValue)) : Number(calValue);
      } else {
        return Number(settings.valueInput);
      }
    }
    const payload = [];
    const adjustmentOverviewToSend = cloneDeep(props.formik.values?.adjustmentOverviewData?.rows);
    const adjustmentOverviewData = cloneDeep(props.formik.values?.adjustmentOverviewData?.rows);
    adjustmentOverviewToSend?.forEach((row) => {
      const id = `${row.activityForecastFromLogicalKey}_${row.activityForecastToLogicalKey}`
      if (settings.selectedData[id]) {
        row && row.columns.forEach(col => {
          const colDate = new Date(col.date);
          const dateRangeFlag = (modStartDate <= colDate) && (colDate <= modEndDate)
          if(("activityForecastFromId" in row) && ("activityForecastToId" in row) && dateRangeFlag){
          payload.push({
            activityForecastFrom: row.activityForecastFromId,
            activityForecastTo: row.activityForecastToId,
            date: col.date,
            overriddenRate: handleNewRate(col)
            ,
          })
        }
        })
      }
    })
    adjustmentOverviewData && adjustmentOverviewData.forEach((row, index) => {
      const id = `${row.activityForecastFromLogicalKey}_${row.activityForecastToLogicalKey}`
      if (settings.selectedData[id]) {
        row && row.columns.forEach((col, ind) => {
          const colDate = new Date(col.date);
          const dateRangeFlag = (modStartDate <= colDate) && (colDate <= modEndDate)
          if(("activityForecastFromId" in row) && ("activityForecastToId" in row) && dateRangeFlag){
          if(adjustmentOverviewData[index].columns[ind].type === 'DEFAULT'){
            adjustmentOverviewData[index].columns[ind].defaultRate = col.defaultRate
          }else if(adjustmentOverviewData[index].columns[ind].type === 'FORECAST'){
            adjustmentOverviewData[index].columns[ind].forecastRate = col.forecastRate
          }
          adjustmentOverviewData[index].columns[ind].overriddenRate = handleNewRate(col)
          }
        }
        )
      }
    }
    )
    const currentData = props.formik.values?.adjsutmentOverrideData || [];
    for(var i = 0, l = currentData.length; i < l; i++) {
      for(var j = 0, ll = payload.length; j < ll; j++) {
          if((currentData[i].activityForecastFrom === payload[j].activityForecastFrom) && (currentData[i].activityForecastTo === payload[j].activityForecastTo) && (currentData[i].date == payload[j].date)) {
            currentData.splice(i, 1, payload[j]);
            break;
          }
      }
  }
  greaterThanHundredValue = validateRowDataIndirect(JSON.parse(JSON.stringify(adjustmentOverviewData)), daysArray)
    const updatedPayload = [...currentData, ...payload]
    const formikToModify = [];
    //@ts-ignore
    formikToModify.rows = adjustmentOverviewData;
    if(!greaterThanHundred && greaterThanHundredValue <= 100){
      props.formik.setFieldValue(`adjustmentOverviewData`, formikToModify)
      props.formik.setFieldValue(`adjsutmentOverrideData`, updatedPayload)
    }else{
      toast.error('The adjustment value must not exceed 100')
    }
    
  };

  const activities = props.formik.values?.adjustmentOverviewData?.rows || [];
  const adjustmentActivities = activities?.map((row) => {
    return { activityForecastFrom: row.activityForecastFromLogicalKey, activityForecastTo: row.activityForecastToLogicalKey, activityForecastFromValue: row.activityForecastFromValue, activityForecastToValue: row.activityForecastToValue }
  })
  useEffect(() => {
    const columns = createColumnsDef();
    var result = columns.reduce((unique, o) => {
      //@ts-ignore
      if (!unique.some(obj => obj.colId === o.colId)) {
        unique.push(o);
      }
      return unique;
    }, []);
    const indirectColumns = createIndirectColumnsDef();
    var resultIndirect = indirectColumns.reduce((unique, o) => {
      //@ts-ignore
      if (!unique.some(obj => obj.colId === o.colId)) {
        unique.push(o);
      }
      return unique;
    }, []);
    setColumns(result);
    setIndirectColumns(resultIndirect);
  }, [props.isEdit, props.formik.values.adjsutmentOverrideData?.rows, props.formik.values.adjustmentOverviewData?.rows, forecastPlanSwitch]);

  useEffect(() => {
    setRedrawTable(uuid())
  }, [props.formik.values.adjsutmentOverrideData?.rows, props.formik.values.adjustmentOverviewData?.rows, forecastPlanSwitch])

  useEffect(()=>{
    setRedrawDirectTable(uuid())
  },[forecastPlanSwitch, props.isEdit, props.formik.values.adjustmentOverviewData?.rows])

  const createColumnsDef = () => {
    const colDefs: (ColDef | ColGroupDef)[] = [
      {
        colId: 'oms',
        field: 'oms',
        headerName: props.intl.formatMessage(messages.oms),
        headerTooltip: props.intl.formatMessage(messages.oms),
        width: 120,
        suppressSizeToFit: true,
        pinned: true,
      },
      {
        colId: 'activityForecastFromValue',
        field: 'activityForecastFromValue',
        headerName: props.intl.formatMessage(messages.activityForecastFrom),
        headerTooltip: props.intl.formatMessage(messages.activityForecastFrom),
        width: 230,
        suppressSizeToFit: true,
        pinned: true,
      },
      {
        colId: 'activityForecastToValue',
        field: 'activityForecastToValue',
        headerName: props.intl.formatMessage(messages.activityForecastTo),
        headerTooltip: props.intl.formatMessage(messages.activityForecastTo),
        width: 230,
        suppressSizeToFit: true,
        pinned: true,
      },
    ];
    const shiftData = props.formik.values?.adjustmentOverviewData?.rows;
    const granularityType = props.formik.values.planningParameters.mheAvailabilityGranularity;
    const handleValueFormatter = (params) => {
      const columnValue = params.data?.columns?.filter((data) => data.date === params.colDef.colId);
      const recalculatedFlag = (forecastPlanSwitch === 'plan' && columnValue[0]?.type === 'FORECAST') || (forecastPlanSwitch === 'forecast' && (columnValue[0]?.type === 'OVERRIDE_FROM_FORECAST' || columnValue[0]?.type === 'FORECAST'));
      const selectRate = recalculatedFlag ? columnValue[0]?.forecastRate : (columnValue[0]?.type === 'DEFAULT' || columnValue[0]?.type === 'OVERRIDE_FROM_DEFAULT') ? columnValue[0]?.defaultRate: '';
      const rate = selectRate >= 0 ? selectRate : '';
      let valueToDisplay = rate;
      if (columnValue && columnValue[0] && ("overriddenRate" in columnValue[0]) && columnValue[0].overriddenRate !== null && forecastPlanSwitch === 'plan') {
        if (columnValue[0].overriddenRate === '' || columnValue[0].overriddenRate === undefined) {
          valueToDisplay = ''
        } else {
          valueToDisplay = columnValue[0].overriddenRate;
        }
      }
      if(columnValue && columnValue[0] && ("overriddenRate" in columnValue[0]) && columnValue[0].overriddenRate === null && forecastPlanSwitch === 'plan'){
        const defaultData = (columnValue[0]?.type === 'FORECAST' || columnValue[0]?.type === "OVERRIDE_FROM_FORECAST") ? columnValue[0]?.forecastRate : columnValue[0].defaultRate;
        valueToDisplay = defaultData;
      }
      const valueToReturn = (valueToDisplay !== '' && valueToDisplay !== null && valueToDisplay >= 0) ? (toNumber(valueToDisplay)) : null;
      return valueToReturn >=0 ? valueToReturn : null
    }

    const handlepercentageCellFormatter = (params) => {
        const value = params.value ? numberFormat(toNumber(params.value).toFixed(2)) : params.value === 0 ? 0:  null;
        return value ? `${parseFloat(value).toFixed(2)}%` : value == 0 ? '0.00%' : value;
    }

    const handleValueSetter = (params) => {
      let comingValue = toNumber(params.newValue);
      if(comingValue > 100){
        toast.error('The adjustment value must not exceed 100')
            return false;
      }
      if(comingValue < 0){
        toast.error('The adjustment value must not below 0')
            return false;
      }
      params.data.columns.forEach((data, index) => {
        if (data.date === params.colDef.colId) {
          const current = params.data.columns[index];
          let newValue = toNumber(params.newValue);
          if(newValue > 100){
            toast.error('The adjustment value must not exceed 100')
            return false;
          }
          if (newValue === '') {
            newValue = null;
          }
          if (newValue == (current.type === 'FORECAST' ? current.forecastRate: current.defaultRate)) {
            newValue = null;
          }
          if (Number.isNaN(newValue)) {
            return false;
          }
          const currentAdjustmentOverviewData = props.formik.values.adjustmentOverviewData || [];
          currentAdjustmentOverviewData?.rows?.forEach((data)=>{
            if((data.activityForecastToLogicalKey === params.data.activityForecastToLogicalKey) && (data.activityForecastFromLogicalKey === params.data.activityForecastFromLogicalKey)){
              data?.columns?.forEach((column)=>{
                if(column.date ===params.colDef.colId){
                  column.overriddenRate = newValue
                }
              })
            }
          })
          props.formik.setFieldValue('adjustmentOverviewData',currentAdjustmentOverviewData)
        }
      });
      params.api.refreshCells({ force: true });
      params.api.redrawRows();
      return true;
    }
    const handleClassValues = (params) => {
      const cssClasses = [];
      const headCountValue = params.data.columns.filter((data) => data.date === params.colDef.colId);
      const rate = (headCountValue[0]?.type === 'FORECAST') ?  headCountValue[0]?.forecastRate : headCountValue[0]?.defaultRate;
      const overriddenRateValue = headCountValue[0]?.overriddenRate >= 0 && headCountValue[0]?.overriddenRate;
      if (props.isEdit && forecastPlanSwitch === 'plan') {
        cssClasses.push('cell-editable');
        if(headCountValue[0].overriddenRate === null && (((headCountValue[0]?.type === 'FORECAST') && headCountValue[0].forecastRate >= 0) || (headCountValue[0]?.type === 'DEFAULT' && headCountValue[0].defaultRate >= 0))){
          cssClasses.push('cell-empty-overriddenRate')
      }
      }
      if (headCountValue && headCountValue[0] && ("overriddenRate" in headCountValue[0] && headCountValue[0].overriddenRate !== null) && (overriddenRateValue !== rate) && forecastPlanSwitch === 'plan') {
       cssClasses.push('cell-overriddenRate');
      } else if(headCountValue[0]?.defaultRate >= 0 && headCountValue[0]?.type === 'FORECAST' && !("forecastRate" in headCountValue[0]) && !("overriddenRate" in headCountValue[0])){
        cssClasses.push('cell-headCountEmpty');
      } else if (rate >= 0 || headCountValue[0]?.forecastRate >= 0 || headCountValue[0]?.defaultRate >= 0) {
        //cssClasses.push('cell-editable');
      } else {
        cssClasses.push('cell-headCountEmpty');
      }
      return cssClasses.join(' ');
    }

    const handleCellEditing = (params) => {
      const headCountValue = params.data.columns.filter((data) => data.date === params.colDef.colId);
      const rate = headCountValue[0]?.type === 'FORECAST' ?  headCountValue[0]?.forecastRate : headCountValue[0]?.defaultRate;
      const overriddenRateValue = headCountValue[0]?.overriddenRate;
      if ((Number(overriddenRateValue)>=0 || Number(rate) >=0) && props.isEdit) {
        return true;
      }
      return false;
    }

    const handleToolTop = (params) => {
      const headCountValue = params.data.columns.filter((data) => data.date === params.colDef.colId);
      const overriddenRateValue = headCountValue[0]?.overriddenRate;
      if (forecastPlanSwitch === 'plan') {
        if (((headCountValue[0].type === 'FORECAST' || headCountValue[0].type === 'OVERRIDE_FROM_FORECAST') ? headCountValue[0]?.forecastRate : headCountValue[0]?.defaultRate) === overriddenRateValue) {
          if(("defaultRate" in headCountValue[0]) && headCountValue[0].type === 'DEFAULT'){
            return 'Default value'
          }else if(("forecastRate" in headCountValue[0]) && headCountValue[0].type === 'FORECAST'){
            return 'Forecast value'
          }
        }
        if (overriddenRateValue !== null && overriddenRateValue !== undefined) {
          if(headCountValue[0].type === 'DEFAULT' || headCountValue[0].type === 'OVERRIDE_FROM_DEFAULT'){
            return 'Overridden'
          }else if(headCountValue[0].type === 'FORECAST' || headCountValue[0].type === 'OVERRIDE_FROM_FORECAST'){
            return 'Overridden';
          }
        }
      }
      if((headCountValue[0].type === 'DEFAULT' || headCountValue[0].type === 'OVERRIDE_FROM_DEFAULT') && headCountValue[0]?.defaultRate >=0){
        return 'Default value';
      }else if((headCountValue[0].type === 'FORECAST' || headCountValue[0].type === 'OVERRIDE_FROM_FORECAST') && headCountValue[0]?.forecastRate >=0){
        return 'Forecast value';
      }
    }

    shiftData && shiftData.forEach((data) => {
      data.columns.forEach((c) => {
        const rowFlag = granularityType == 'DAY' ? DAY_NAMES.includes(c.dayOfWeek) : true;
        if (rowFlag) {
          const dayOfWeek = props.intl.formatMessage(calendarMessages[`${c.dayOfWeek.toLowerCase()}Short`]);
          colDefs.push({
            colId: `${c.date}`,
            headerName: `${dayOfWeek} ${parseDateAndFormatToLocalString(c.date)}`,
            children: [{
              colId: `${c.date}`,
              sortable: false,
              suppressMenu: true,
              suppressMovable: true,
              headerName: props.intl.formatMessage(messages.adjsutment),
              width: 120,
              pinned: false,
              valueGetter: handleValueFormatter,
              valueSetter: handleValueSetter,
              editable: params=> {
               return props.isEdit && handleCellEditing(params) && forecastPlanSwitch === 'plan'
              },
              suppressSizeToFit: true,
              cellClass: handleClassValues,
              tooltipValueGetter: handleToolTop,
              valueFormatter: handlepercentageCellFormatter
            }]
          })
        }
      })
    })

    return colDefs;
  }
  const createIndirectColumnsDef = () => {
    const handleValueFormatter = (params) => {
      const columnValue = params.data?.columns?.filter((data) => data.date === params.colDef.colId);
      const selectRate = columnValue[0]?.type === 'FORECAST' ? columnValue[0]?.forecastRate : columnValue[0]?.defaultRate;
      const rate = selectRate >= 0 ? selectRate : '';
      let valueToDisplay = rate;
      if (columnValue && columnValue[0] && ("overriddenRate" in columnValue[0]) && forecastPlanSwitch === 'plan') {
        if (columnValue[0].overriddenRate === '' || columnValue[0].overriddenRate === undefined || columnValue[0].overriddenRate === null) {
          valueToDisplay = ''
        } else {
          valueToDisplay = columnValue[0].overriddenRate;
        }
      }
      if(!("overriddenRate" in columnValue[0]) && !(("defaultRate" in columnValue[0]) || ("forecastRate" in columnValue[0]))){
        return ''
      }
      const value = (valueToDisplay !== null && valueToDisplay >= 0) ? numberFormat(toNumber(valueToDisplay)) : null;
      const diff = 100 - value;
      return diff ? `${diff.toFixed(2)}%` : diff == 0 ? '0.00%' : diff;
    }
    const handleClassIndirectValues = (params) => {
      const cssClasses = [];
      const headCountValue = params.data.columns.filter((data) => data.date === params.colDef.colId);
      const columnValue = params.data?.columns?.filter((data) => data.date === params.colDef.colId);
      const selectRate = columnValue[0]?.type === 'FORECAST' ? columnValue[0]?.forecastRate : columnValue[0]?.defaultRate;
      const rate = selectRate >= 0 ? selectRate : '';
      let valueToDisplay = rate;
      if (columnValue && columnValue[0] && ("overriddenRate" in columnValue[0]) && forecastPlanSwitch === 'plan') {
        if (columnValue[0].overriddenRate === '' || columnValue[0].overriddenRate === undefined || columnValue[0].overriddenRate === null) {
          valueToDisplay = ''
        } else {
          valueToDisplay = columnValue[0].overriddenRate;
        }
      }
      const value = (valueToDisplay !== null && valueToDisplay >= 0) ? numberFormat(toNumber(valueToDisplay)) : null;
      const diff = 100 - value;
      if(diff < 0){
        cssClasses.push('cell-negitiveValue');
      }
      if(!("overriddenRate" in headCountValue[0]) && !(("defaultRate" in headCountValue[0]) || ("forecastRate" in headCountValue[0]))){
        cssClasses.push('cell-headCountEmpty');
      }
      return cssClasses.join(' ');
    }
    const colDefs: (ColDef | ColGroupDef)[] = [
      {
        colId: 'oms',
        field: 'oms',
        headerName: props.intl.formatMessage(messages.oms),
        headerTooltip: props.intl.formatMessage(messages.oms),
        width: 120,
        suppressSizeToFit: true,
        pinned: true,
      },
      {
        colId: 'activityForecastFromValue',
        field: 'activityForecastFromValue',
        headerName: props.intl.formatMessage(messages.activityForecastFrom),
        headerTooltip: props.intl.formatMessage(messages.activityForecastFrom),
        width: 460,
        suppressSizeToFit: true,
        pinned: true,
      },
    ];
    const shiftData = props.formik.values?.adjustmentOverviewData?.rows;
    const granularityType = props.formik.values.planningParameters.mheAvailabilityGranularity;

    shiftData && shiftData.forEach((data) => {
      data.columns.forEach((c) => {
        const rowFlag = granularityType == 'DAY' ? DAY_NAMES.includes(c.dayOfWeek) : true;
        if (rowFlag) {
          const dayOfWeek = props.intl.formatMessage(calendarMessages[`${c.dayOfWeek.toLowerCase()}Short`]);
          colDefs.push({
            colId: `${c.date}`,
            headerName: `${dayOfWeek} ${parseDateAndFormatToLocalString(c.date)}`,
            children: [{
              colId: `${c.date}`,
              sortable: false,
              suppressMenu: true,
              suppressMovable: true,
              headerName: props.intl.formatMessage(messages.remaining),
              headerTooltip: `${granularityType == 'DAY' ? dayOfWeek : 'Week'} ${parseDateAndFormatToLocalString(c.date)}`,
              width: 120,
              pinned: false,
              cellRendererParams: params => ({
                editable: props.isEdit,
              }),
              suppressSizeToFit: true,
              cellClass: handleClassIndirectValues,
              valueGetter: handleValueFormatter,
            }]
          })
        }
      })
    })

    return colDefs;
  }
  const onCellValueChanged = (params) => {
    if(Number(params.newValue) > 100){
      return false;
    }
    if(Number(params.newValue) < 0){
      return false;
    }
    params.data.columns.forEach((data, index) => {
      if (data.date === params.colDef.colId) {
        const updatedRowData = props.formik.values?.adjustmentOverviewData?.rows?.filter((row)=>(row.activityForecastFromLogicalKey === params.data.activityForecastFromLogicalKey) && (row.activityForecastToLogicalKey === params.data.activityForecastToLogicalKey)) || [];
        const updatedColumnData = updatedRowData[0]?.columns?.filter((a)=>(a.date === data.date)) || [];
        const currentData = props.formik.values?.adjsutmentOverrideData || [];
        const findIndex = currentData.findIndex(a => (a.date === data.date) && (a.activityForecastFrom === params.data.activityForecastFromId) && (a.activityForecastTo === params.data.activityForecastToId))
        findIndex !== -1 && currentData.splice(findIndex, 1);
          const newOverRideData = {
            activityForecastFrom: updatedRowData[0].activityForecastFromId,
            activityForecastTo: updatedRowData[0].activityForecastToId,
            date: updatedColumnData[0].date,
            overriddenRate: updatedColumnData[0].overriddenRate,
          }
          const newData = [...currentData, newOverRideData]
          const adjustmentOverviewToSend = newData
          props.formik.setFieldValue(`adjsutmentOverrideData`, adjustmentOverviewToSend)
      }
    });
  }
  const rowSortedData = constructRowDataDirect(props.formik?.values?.adjustmentOverviewData?.rows, activityParamaterList, departments, customers, 'direct');
  const rowData = sortDataByDays(rowSortedData, daysArray)
  const tempRowData = constructRowDataDirect(JSON.parse(JSON.stringify(props.formik?.values?.adjustmentOverviewData?.rows || [])), activityParamaterList, departments, customers, 'indirect')
  const rowDataIndirect = constructRowDataIndirect(tempRowData, daysArray);
  const colDefKey = hash(columns);
  const colIndirectDefKey = hash(indirectColumns);
  return (
    <div>
      <Row>
        {
          isForecastEditor && (
            <Button onClick={handleTogglePlanForecast}>
              {forecastPlanSwitch === 'plan' && <FormattedMessage {...messages.forecast} />}
              {forecastPlanSwitch === 'forecast' && <FormattedMessage {...messages.plan} />}
            </Button>
          )
        }
        {isEdit && (
          <>
            {
              isForecastEditor && (
                <Button
                  onClick={() => setOverwriteConfirmDialogVisible(true)}
                  disabled={forecastPlanSwitch !== 'plan' || !adjustmentActivities.length}
                >
                  <FormattedMessage {...messages.backToForecast} />
                </Button>
              )
            }
            <Button
              onClick={handleModifyMultiple} disabled={forecastPlanSwitch !== 'plan' || !adjustmentActivities.length}
            >
              <FormattedMessage {...messages.modifyMultiple} />
            </Button>
          </>
        )}
      </Row>
      <Row>
        <FormattedMessage {...messages.direct} />
      </Row>
      <div key={`Direct_${colDefKey}_${forecastPlanSwitch}_${redrawDirectTable}}`}>
        <TableWithHeight
          showCOG={false}
          applyColumnDefOrder
          pagination
          paginationPageSize={50}
          columnDefs={columns}
          rowData={rowData}
          domLayout="autoHeight"
          onCellValueChanged={onCellValueChanged}
        />
      </div>
      <Row>
        <FormattedMessage {...messages.indirect} />
      </Row>
      <div key={`Indirect_${colIndirectDefKey}_${forecastPlanSwitch}_${redrawDirectTable}}`}>
        <TableWithHeight
          showCOG={false}
          applyColumnDefOrder
          pagination
          paginationPageSize={50}
          columnDefs={indirectColumns}
          rowData={rowDataIndirect}
          domLayout="autoHeight"
        />
      </div>
      <ModifyMultipleDialog
        dialogType={ModifyMultipleDialogType.ADJUSTMENTS_OVERVIEW}
        open={modifyMultipleOpen}
        closeHandler={handleCloseModifyMultiple}
        startDate={modifyMultipleStartDate}
        endDate={modifyMultipleEndDate}
        selectedData={adjustmentActivities?.map(row => ({
          label: `${row.activityForecastFromValue} adjusted by ${row.activityForecastToValue}`,
          value: `${row.activityForecastFrom}_${row.activityForecastTo}`,
          activityForecastFrom: row.activityForecastFrom,
          activityForecastTo: row.activityForecastTo,
        }))}
        isForecastEditor={isForecastEditor}
        dataSourceLabel="Activities"
        dataSourceSwitch={ModifyMultipleDataSource.ACTIVITY}
        editValueSubmit={handleMultipleEditValueSubmit}
      />
      <OverwriteConfirmDialog
        visible={overwriteConfirmDialogVisible}
        onCancel={() => setOverwriteConfirmDialogVisible(false)}
        onConfirm={handleBackToForecast}
      />
    </div>
  );
})
  ;


const mapStateToPropsPlan = createStructuredSelector({
  edit: selectEditFromplan,
  departments: selectPlanDepartments,
});

const mapStateToPropsPa = createStructuredSelector({
  edit: selectEditFromPa,
  departments: selectAreaDepartments,
});

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators({}, dispatch),
    dispatch,
  };
}

const withPlanConnect = connect(
  mapStateToPropsPlan,
  mapDispatchToProps,
);

const withPaConnect = connect(
  mapStateToPropsPa,
  mapDispatchToProps,
);

export const AdjustmentOverviewPlan = compose(
  injectIntl,
  withPlanConnect,
)(AdjustmentOverview);

export const AdjustmentOverviewPa = compose(
  injectIntl,
  withPaConnect,
)(AdjustmentOverview);