import * as React from 'react';
import { injectIntl } from 'react-intl';
import { compose } from 'redux';
import styled from 'styled-components';
import messages from '../messages';
import { TransferCostEntry } from '../types';

interface StatusCellRendererProps {
  intl: any;
  isEdit: boolean;
  data: TransferCostEntry;
}

class StatusCellRenderer extends React.Component<StatusCellRendererProps> {
  public render() {
    const {
      data,
      isEdit,
      intl: { formatMessage },
    } = this.props;

    if (!data) {
      return null;
    }

    const Wrapper = isEdit
      ? styled.div`
          cursor: pointer;
          background-color: #ffcc00;
          height: 19px;
          line-height: 1.4;
          padding: 0 10px;
        `
      : styled.div``;
    const isDisabled = data.disabledFrom && data.disabledTo;

    return (
      <Wrapper>{isDisabled ? formatMessage(messages.statusDisabled) : formatMessage(messages.statusEnabled)}</Wrapper>
    );
  }
}

export default compose(
  injectIntl,
  // @ts-ignore
)(StatusCellRenderer);
