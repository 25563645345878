/**
 *
 * DaysPickerCellEditor
 *
 */
import React from 'react';
import PropTypes from 'prop-types';

import isString from 'lodash/isString';
import styled from 'styled-components';

import DayPicker from 'components/FormikDayPicker';

const CellWrapper = styled.div`
  display: block;
  min-width: 160px;
  align-items: center;
  > * {
    margin-right: 5px;
  }
`;

/* eslint-disable react/prefer-stateless-function */
class DaysPickerCellEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = { value: props.value };
  }

  t = null;

  isPopup() {
    return true;
  }

  getValue = () => this.state.value;

  updateValue = (name, value) => {
    this.setState({ value });
  };

  componentDidMount() {
    this.t = setTimeout(() => {
      if (this.inputRef) {
        this.inputRef.focus();
      }
    }, 0);
  }

  componentWillUnmount() {
    this.t && clearTimeout(this.t);
  }

  render() {
    const normalizedValue = isString(this.state.value) ? this.state.value.split(',') : this.state.value;
    const {
      eGridCell: { clientHeight },
    } = this.props;
    return (
      <CellWrapper>
        <DayPicker
          name="daysPickerCellEditor"
          value={normalizedValue}
          editable
          isMulti
          setFieldValue={this.updateValue}
          tabSelectsValue={false}
          height={clientHeight}
          refHandler={ref => {
            this.inputRef = ref;
          }}
        />
      </CellWrapper>
    );
  }
}

DaysPickerCellEditor.propTypes = {
  value: PropTypes.array,
};

export default DaysPickerCellEditor;
