/*
 * Adjustment Messages
 *
 * This contains all the text for the Adjustment component.
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  header: {
    id: 'app.components.Adjustments.header',
    defaultMessage: 'Labour Category',
  },
  directActivity: {
    id: 'app.components.Adjustments.directActivity',
    defaultMessage: 'Direct Activity',
  },
  inDirectActivity: {
    id: 'app.components.Adjustments.inDirectActivity',
    defaultMessage: 'Adjustment Mapping Activity',
  },
  mappingActivity: {
    id: 'app.components.Adjustments.inDirectActivity',
    defaultMessage: 'Adjustment Mapping Activity',
  },
  oms: {
    id: 'app.components.Adjustments.oms',
    defaultMessage: 'OMS',
  },
  activityFrom: {
    id: 'app.components.Adjustments.activity',
    defaultMessage: 'Activity',
  },
  activityFromValue: {
    id: 'app.components.Adjustments.activityFromValue',
    defaultMessage: 'Activity',
  },
  activityTo: {
    id: 'app.components.Adjustments.activityTo',
    defaultMessage: 'Adjustment Mapping Activity',
  },
  activityToValue: {
    id: 'app.components.Adjustments.activityToValue',
    defaultMessage: 'Adjustment Mapping Activity',
  },
  action: {
    id: 'app.components.Adjustments.action',
    defaultMessage: 'Action',
  },
  oms: {
    id: 'app.components.Adjustments.oms',
    defaultMessage: 'OMS',
  },
  mondayRate: {
    id: 'app.components.Adjustments.mondayRate',
    defaultMessage: 'Monday',
  },
  tuesdayRate: {
    id: 'app.components.Adjustments.tuesdayRate',
    defaultMessage: 'Tuesday',
  },
  wednesdayRate: {
    id: 'app.components.Adjustments.wednesdayRate',
    defaultMessage: 'Wednesday',
  },
  thursdayRate: {
    id: 'app.components.Adjustments.thursdayRate',
    defaultMessage: 'Thursday',
  },
  fridayRate: {
    id: 'app.components.Adjustments.fridayRate',
    defaultMessage: 'Friday',
  },
  saturdayRate: {
    id: 'app.components.Adjustments.saturdayRate',
    defaultMessage: 'Saturday',
  },
  sundayRate: {
    id: 'app.components.Adjustments.sundayRate',
    defaultMessage: 'Sunday',
  },
  adjustments: {
    id: 'app.components.Adjustments.adjustments',
    defaultMessage: 'Adjustment',
  },
  remaining: {
    id: 'app.components.Adjustments.remaining',
    defaultMessage: 'Remaining',
  },
  direct: {
    id: 'app.components.Adjustments.direct',
    defaultMessage: 'Adjusted',
  },
  indirect: {
    id: 'app.components.Adjustments.indirect',
    defaultMessage: 'Adjusting',
  },
  delete: {
    id: 'app.components.Adjustments.delete',
    defaultMessage: 'Action',
  },
  dialogDeleteText: {
    id: 'app.components.Adjustments.dialogDeleteText',
    defaultMessage: 'Are you sure? You are deleting Adjustment for activity {name}',
  },
  alreadyAddedAdjustment: {
    id: 'app.components.Adjustments.alreadyAddedAdjustment',
    defaultMessage: 'Adjustment is already added with this direct activity and indirect activity.',
  },
});
