// @flow

import React from 'react';

const Checkbox = ({ id, checked }: { id: string, checked: boolean }) => {
  const className = `ag-icon ${(checked && 'ag-icon-checkbox-checked') || 'ag-icon-checkbox-unchecked'}`;
  return (
    <div id={id} className="ag-filter-checkbox">
      <span className={className}></span>
    </div>
  );
};

export default Checkbox;
