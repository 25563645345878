/**
 *
 * ButtonWithDirtyCheck
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'formik';
import { FormattedMessage } from 'react-intl';

import Button from '../Button';
import { withDirtyDialog } from '../DirtyDialog';

const ButtonWithDirtyCheck = buttonProps => {
  const ButtonCompo = buttonProps.component || Button;
  const formik = buttonProps.formikOverride || buttonProps.formik;
  return (
    <ButtonCompo
      onClick={() => {
        const dataWrapper = { data: buttonProps.actionPayload };
        const dirty = typeof buttonProps.dirty === 'undefined' ? formik && formik.dirty : buttonProps.dirty;
        if (dirty) {
          buttonProps.openDirtyDialog(formik.submitForm, isSaveAll =>
            buttonProps.actionHandler({ ...dataWrapper, waitForSaveAll: isSaveAll }),
          );
        } else {
          buttonProps.actionHandler(dataWrapper);
        }
      }}
      disabled={buttonProps.disabled}
      tooltip={buttonProps.tooltip}
      id={buttonProps.id}
      {...buttonProps}
    >
      {buttonProps.message && <FormattedMessage {...buttonProps.message} />}
    </ButtonCompo>
  );
};

ButtonWithDirtyCheck.propTypes = {
  saveAction: PropTypes.func,
  payload: PropTypes.object,
  dirty: PropTypes.bool,
  message: PropTypes.object,
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func, PropTypes.object]),
  disabled: PropTypes.bool,
  tooltip: PropTypes.object,
  id: PropTypes.string,
  formikOverride: PropTypes.object,
};

const ComponentWithDirtyCheckInner = props => {
  const onClickHandler = payload => {
    const dataWrapper = { data: payload || props.actionPayload };
    const formik = props.formikOverride || props.formik;
    if (formik.dirty) {
      props.openDirtyDialog(formik.submitForm, isSaveAll => {
        if (!isSaveAll) {
          formik.resetForm();
          if (props.onReset) {
            props.onReset();
          }
        }
        props.actionHandler({ ...dataWrapper, waitForSaveAll: isSaveAll });
      });
    } else if (props.deleteConfirmation) {
      props.openDirtyDialogAsDeleteConfirm(formik.submitForm, isSaveAll =>
        props.actionHandler({ ...dataWrapper, waitForSaveAll: isSaveAll , periodData : props.actionPayload }),
      );
    } else {
      props.actionHandler(dataWrapper);
    }
  };
  return props.children({ onClickHandler });
};

ComponentWithDirtyCheckInner.propTypes = {
  actionHandler: PropTypes.func,
  actionPayload: PropTypes.object,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
  deleteConfirmation: PropTypes.bool,
  formikOverride: PropTypes.object,
};

export const ComponentWithDirtyCheck = withDirtyDialog(connect(ComponentWithDirtyCheckInner));

export default withDirtyDialog(connect(ButtonWithDirtyCheck));
