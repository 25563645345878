import { ColDef } from 'ag-grid-community';
import Button from 'components/Button';
import { withRouter } from 'react-router-dom';
import DeleteCellRenderer from 'components/DeleteCellRenderer';
import AgTable from 'components/Table';
import React, { useEffect, useState } from 'react';
import { InjectedIntl, injectIntl } from 'react-intl';
import styled from 'styled-components';
import { dateCellFormater, dateComparator, formatTime } from 'utils/dateTime';
import { ApiPlanDTO, ApiSmartProdSourceDTO } from 'types/drep-backend.d';
import { toast } from 'react-toastify';
import { getToken } from 'containers/App/selectors';
import { withDeleteDialog } from 'components/Dialog';

import { fetchData } from 'utils/reduxApi';
import { withUrl } from 'utils/api';

import { useSelector, useDispatch } from 'react-redux';
import { SourceDetailButton } from 'components/IconButton';
import messages from './messages';
import SmartVolumeScheduleDialog from './SmartVolumeScheduleDialog';
import { EntityEntry } from './types';
import { useHistory } from "react-router-dom";

const Table = styled(AgTable)`
  margin-top: 20px;
  height: ${props => props.height}px;
`;

const smartProdInstanceValueGetter = (allValues: EntityEntry[]) => params =>
  allValues.find((item: EntityEntry) => item.value === params.data.smartProdInstance.id)?.label;
const facilityValueGetter = (allValues: EntityEntry[]) => params => {
  return allValues.find((item: EntityEntry) => item.value === params.data.facility.id)?.code || '';
};
const WHIDValueGetter = (allValues: EntityEntry[]) => params =>
  allValues.find((item: EntityEntry) => item.value === params.data.smartProdWarehouse.id)?.label;

const Wrap = styled.div`
  display: flex;
  align-items: center;
  height: 25px;

  ${Button} {
    padding-top: 5px;
    height: 25px;
  }
`;

const columnDefs = (
  dirty: boolean,
  intl: InjectedIntl,
  onDelete: Function,
  openDeleteDialog: Function,
  openScheduleModal: Function,
  allSmartProdInstances,
  allFacilities,
  allWHIDs,
  openSmartSourceDetail,
  masterPlans
) => {
  const columns: ColDef[] = [
    {
      colId: 'smartProdInstance',
      field: 'smartProdInstance',
      headerName: intl.formatMessage(messages.smartVolumeTableCellInstance),
      valueGetter: smartProdInstanceValueGetter(allSmartProdInstances),
      width: 140,
    },
    {
      colId: 'facility',
      field: 'facility',
      headerName: intl.formatMessage(messages.smartVolumeTableCellFacility),
      valueGetter: facilityValueGetter(allFacilities),
      width: 120,
    },
    {
      colId: 'smartProdWarehouseId',
      field: 'smartProdWarehouseId',
      headerName: intl.formatMessage(messages.smartVolumeTableCellWHID),
      valueGetter: WHIDValueGetter(allWHIDs),
      width: 120,
    },
    {
      colId: 'smartProdSourceDate',
      field: 'smartProdSourceDate',
      headerName: intl.formatMessage(messages.smartVolumeTableCellScheduleStartDate),
      filter: 'agDateColumnFilter',
      filterParams: {
        filterOptions: ['lessThan', 'greaterThan', 'inRange'],
        comparator: dateComparator,
      },
      valueFormatter: dateCellFormater,
      width: 150,
    },
    {
      colId: 'scheduleTime',
      field: 'scheduleTime',
      headerName: intl.formatMessage(messages.smartVolumeTableCellScheduleTime),
      valueFormatter: params => (params.value ? formatTime(params.value) : ''),
      width: 150
    },
    {
      colId: 'schedulePeriod',
      field: 'schedulePeriod',
      headerName: intl.formatMessage(messages.smartVolumeTableCellSchedulePeriodDays),
      width: 150,
    },
    {
      width: 100,
      colId: dirty ? 'dirtySchedule' : 'schedule',
      cellRendererFramework: params => {
       return (<Wrap>
          <SourceDetailButton
            className="smart-volume-association-schedule-button"
            onClick={() => openSmartSourceDetail(params.data)}
          />
        </Wrap>)
      },
      cellRendererParams: { openScheduleModal, dirty },
      sortable: false,
    },
  ];
  if(masterPlans?.length === 0){
    columns.unshift(
      {
        colId: 'delete',
        headerName: intl.formatMessage(messages.delete),
        cellRendererFramework: DeleteCellRenderer,
        cellRendererParams: {
          onDelete,
          dirty,
        },
        suppressMenu: true,
        sortable: false,
        width: 100,
      },
    )
  }
  return columns;
};

type Props = {
  dirty: boolean;
  plan: ApiPlanDTO;
  intl: InjectedIntl;
  associations: ApiSmartProdSourceDTO[];
  storePlanDetails: (update: Record<string, unknown>) => void;
  openDeleteDialog: () => void;
  allSmartProdInstances: EntityEntry[];
  allFacilities: EntityEntry[];
  allWHIDs: EntityEntry[];
  handleScheduleVolumeUpdate: Function;
  masterPlans: any;
};

const SmartVolumeAssociationTable: React.FC<Props> = ({
  dirty,
  plan,
  intl,
  associations,
  storePlanDetails,
  openDeleteDialog,
  allSmartProdInstances,
  allFacilities,
  allWHIDs,
  handleScheduleVolumeUpdate,
  masterPlans
}) => {
  const token = useSelector(getToken);
  const dispatch = useDispatch();
  const historyTo = useHistory();
  const [gridApi, setGridApi] = useState(null);
  const [dialogAssociation, setDialogAssociation] = useState<ApiSmartProdSourceDTO>(null);

  const openSmartSourceDetail = (data) => {
    historyTo.push(`/smartProdSource/${data.id}`)
  };

  const dialogAssociationLabels = {
    smartProdInstanceLabel: allSmartProdInstances.find(item => item.value === dialogAssociation?.smartProdInstance?.id)
      ?.label,
    WHIDLabel: allWHIDs.find(item => item.value === dialogAssociation?.smartProdWarehouse?.id)?.label,
  };

  const onGridReady = params => {
    setGridApi(params);
    params.api.sizeColumnsToFit();
  };

  const handleDeleteRow = async association => {
    const result = await fetchData(
      withUrl(`/planningParameters/${plan.planningParameters.id}/smartProdSource/${association.id}`)
        .delete()
        .asRawResponse()
        .andToken(token),
      dispatch,
    );
    if (result.isOk) {
      storePlanDetails({
        ...plan,
        planningParameters: {
          ...plan.planningParameters,
          smartProdSourceList: associations.filter(row => association.id !== row.id),
        },
      });
      toast(intl.formatMessage(messages.deleted));
    }
  };

  const handleChangeRow = async (association: ApiSmartProdSourceDTO, change: ApiSmartProdSourceDTO) => {
    const payload = {
      "id": change.id,
      "smartProdInstance": change.smartProdInstance,
      "smartProdWarehouse": change.smartProdWarehouse,
      "startDate": change.startDate,
      "description": change.description,
      "deleted": change.deleted,
      "omses": change.omses ? change.omses : [],
      "plans": change.plans ? change.plans : [],
      "planningAreas": change.planningAreas ? change.planningAreas : [],
      "scheduleStartDate": change.scheduleStartDate,
      "schedulePeriod": change.schedulePeriod ? Number(change.schedulePeriod) : '',
    }
    const result = await fetchData(
      withUrl(`/smartVolume/smartProdSource/update`)
        .post(payload)
        .andToken(token),
      dispatch,
    );
    if (result.isOk) {
      handleScheduleVolumeUpdate();
      setDialogAssociation(null);
    }
  };

  const handleOpenScheduleModal = association => {
    setDialogAssociation(association);
  };

  const handleCloseAssociationDialog = () => {
    setDialogAssociation(null);
  };

  useEffect(() => {
    if (gridApi) {
      const colDefs = columnDefs(
        dirty,
        intl,
        handleDeleteRow,
        openDeleteDialog,
        handleOpenScheduleModal,
        allSmartProdInstances,
        allFacilities,
        allWHIDs,
        openSmartSourceDetail,
        masterPlans,
      );
      gridApi.api.setColumnDefs(colDefs);
      gridApi.api.sizeColumnsToFit();
    }
  }, [gridApi, allSmartProdInstances, allFacilities, allWHIDs, associations, dirty, masterPlans]);

  return (
    <>
      <Table
        key={`${allSmartProdInstances.length}-${allFacilities.length}-${allWHIDs.length}`}
        columnDefs={columnDefs(
          dirty,
          intl,
          handleDeleteRow,
          openDeleteDialog,
          handleOpenScheduleModal,
          allSmartProdInstances,
          allFacilities,
          allWHIDs,
          openSmartSourceDetail,
          masterPlans
        )}
        rowData={associations}
        height={300}
        rowHeight={30}
        onGridReady={onGridReady}
      />
      {/* <SmartVolumeScheduleDialog
        association={dialogAssociation}
        {...dialogAssociationLabels}
        closeHandler={handleCloseAssociationDialog}
        changeRow={handleChangeRow}
      /> */}
    </>
  );
};

export default withDeleteDialog(injectIntl(withRouter(SmartVolumeAssociationTable)));
