/**
 *
 * TimePickerCellEditor
 *
 */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import DatePicker from 'components/DatePicker';
import { formatDate } from 'utils/dateTime';

import { connectPicker } from './redux';
const CellWrapper = styled.div`
  display: block;
  align-items: center;
  > * {
    margin-right: 5px;
  }

  .MuiInputBase-input {
    font-size: 13px;
  }
`;

/* eslint-disable react/prefer-stateless-function */
class DatePickerCellEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value,
      open: false,
    };
  }

  t = null;

  handleChange = value => {
    const { node, nextCellEditor, rowIndex, api, openEditor, onChange } = this.props;
    this.setState({ value, openNext: true });
    if (this.props.setValue) {
      this.props.setValue(value);
      onChange(value);
      if (openEditor && nextCellEditor) {
        this.t = setTimeout(() => {
          openEditor({ rowIndex, colKey: nextCellEditor });
        }, 500);
      }
    }
  };

  componentWillUnmount() {
    clearTimeout(this.t);
  }

  componentDidUpdate(prevProps) {
    const {
      nextEditor,
      rowIndex,
      column: { colId },
    } = this.props;
    if (prevProps.nextEditor !== nextEditor) {
      if (nextEditor.rowIndex === rowIndex && colId === nextEditor.colKey) {
        this.setState({ open: true });
      }
    }
  }

  render() {
    const {
      charPress,
      editable,
      value,
      format,
      setValue,
      okLabel,
      rowIndex,
      column: { colId },
      nextCellEditor,
      nextEditor,
      api,
      ...otherProps
    } = this.props;
    const { open = false } = this.state;
    return (
      <CellWrapper onFocus={this.onFocus}>
        {editable ? (
          <DatePicker
            {...otherProps}
            name="DatePickerCellEditor"
            value={value}
            onChange={this.handleChange}
            okLabel={okLabel}
            open={open}
            onClose={() => this.setState({ open: false })}
            onOpen={() => this.setState({ open: true })}
          />
        ) : (
          value && (format ? value.toFormat(format) : formatDate(value))
        )}
      </CellWrapper>
    );
  }
}

DatePickerCellEditor.propTypes = {
  value: PropTypes.object,
  editable: PropTypes.bool,
  setValue: PropTypes.func,
  format: PropTypes.string,
  okLabel: PropTypes.string,
};

const Editor = connectPicker(DatePickerCellEditor);

export default class DateTimePickerEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value,
    };
  }

  isPopup() {
    return true;
  }

  getValue = () => this.state.value;

  handleChange = value => {
    this.setState({ value });
  };

  render() {
    return <Editor {...this.props} onChange={this.handleChange} />;
  }
}
