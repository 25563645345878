/**
 *
 * PlanDetailPlannedVolume
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import messages from './messages';
import Label from '../Label';
import { PaLabourAvailabilityCategoryTable } from '../LabourAvailabilityCategoryTable';
import { PaLabourAvailabilityCategoryAddLine } from '../LabourAvailabilityCategoryAddLine';

/* eslint-disable react/prefer-stateless-function */
class PlanningAreaLabourAvailability extends React.PureComponent {
  render() {
    return (
      <div>
        <Label {...messages.workerType} />
        <PaLabourAvailabilityCategoryAddLine values={this.props.formik.values} />
        <PaLabourAvailabilityCategoryTable formik={this.props.formik} />
      </div>
    );
  }
}

PlanningAreaLabourAvailability.propTypes = {
  formik: PropTypes.object,
};

export default PlanningAreaLabourAvailability;
