
export function refreshDiscrepancyRowByDemandTotalsAndAdaptNos(allTotals: any[], showNos: boolean) {
  /*
  Input is array of total rows - those pinned bottom rows at the end of activity result table
  row[0] is Labour Demand Sum
  row[1] is Labour Discrepancy
  row[2] is Labour Availability Sum

  First row is controlled by agGrid filter - can sum only filtered row
  This function changes data of 2nd row to be row[2] - row[0]
  In fact row[0] (and row[1] as result of this function) are filtered, row[2] is not. So at some
  cases we're subtracting filtered and non-filtered data but it's confirmed by Zdenek as correct behavior

  This functions also removes # of Staff discrepancy + availability when Activity transformation is not Day to Shift
   */

  if (!allTotals || allTotals.length < 3) {
    return allTotals;
  }
  const suffixesInScope = /(.+)_(planned|budget)_(effAdju|e|fte|nos)/;
  const colKeys = Object.keys(allTotals[0])
    .map(name => suffixesInScope.exec(name))
    .filter(reResult => reResult != null)
    .map(reResult => reResult[0]);
  const result = [...allTotals];
  colKeys.forEach(col => {
    const availabilitySum = result[2][col];
    const demandSum = result[0][col];
    if (availabilitySum !== undefined && demandSum !== undefined) {
      result[1][col] = availabilitySum - demandSum;
    }
  });
  if (!showNos) {
    result.forEach((row, index) => {
      if (index > 0) {
        Object.keys(row)
          .filter((name) => name.endsWith("_planned_nos") || name.endsWith("_budget_nos"))
          .forEach((key) => row[key] = undefined);
      }
    });
  }
  return result;
}
