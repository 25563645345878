/*
 *
 * PlanDetailPage actions
 *
 */

import {
  ADD_ACTIVITY_LINE,
  ADD_LABOUR_AVAILABILITY_CATEGORY_LINE,
  ADD_MASTER_PLANNED_VOLUME_LINE,
  ADD_MHE_OPEN,
  ADD_PERIOD_MASTER_PLAN,
  ADD_SHIFT_SETTINGS_LINE,
  CLEAR_MASTER_PLAN,
  CLEAR_MASTER_PLAN_CONFLICT,
  CLEAR_MASTER_PLAN_MHE,
  COPY_PERIOD_MASTER_PLAN,
  DELETE_ACTIVITY_LINES,
  DELETE_LABOUR_AVAILABILITY_CATEGORY_LINE,
  DELETE_PERIOD_MASTER_PLAN,
  DELETE_SHIFT_SETTINGS_LINE,
  LOAD_MASTER_PLAN,
  MODIFY_ACTIVITY_LINE,
  NAVIGATE_MASTER_PLAN_DETAIL,
  SAVE_BASIC_INFO,
  SAVE_BASIC_SETTINGS,
  SAVE_MASTER_PLAN,
  SAVE_MASTER_PLAN_CONFLICT,
  SAVE_MASTER_PLAN_DONE,
  SAVE_MASTER_PLANNED_VOLUME_HEADER,
  START_MASTER_PLAN_COPY,
  START_NEW,
  STORE_MASTER_PLAN,
  STORE_MASTER_PLAN_GUI_STATE_ITEM,
  STORE_MASTER_PLAN_PERIOD_INDEX,
  SUBMIT_ERROR,
  SWITCH_TO_DISPLAY_MASTER_PLAN,
  TOGGLE_SECTION_MASTER_PLAN,
} from './constants';

export function clearPlanMHEAction() {
  return { type: CLEAR_MASTER_PLAN_MHE };
}

export function loadPlanAction(payload) {
  return { type: LOAD_MASTER_PLAN, payload };
}

export function clearPlanAction(payload) {
  return {
    type: CLEAR_MASTER_PLAN,
    payload,
  };
}

export function addPlanMHEOpenAction(payload) {
  return {
    type: ADD_MHE_OPEN,
    payload,
  };
}

export function navigatePlanDetail(id, forceDisplay = false) {
  return {
    type: NAVIGATE_MASTER_PLAN_DETAIL,
    payload: { id, edit: !forceDisplay },
  };
}

export function navigatePlanDetailWithCopy(id) {
  return {
    type: NAVIGATE_MASTER_PLAN_DETAIL,
    payload: { id, copy: true },
  };
}

export function reloadPlanDetail(id, reloadCount = 0) {
  return {
    type: NAVIGATE_MASTER_PLAN_DETAIL,
    payload: { id, replace: true, reloadCount },
  };
}

export function storePlan(payload) {
  return {
    type: STORE_MASTER_PLAN,
    payload,
  };
}

export function storePlanEdit(payload) {
  return {
    type: STORE_MASTER_PLAN_GUI_STATE_ITEM,
    payload: { field: 'edit', value: payload },
  };
}

export function storePlanLoading(value) {
  return {
    type: STORE_MASTER_PLAN_GUI_STATE_ITEM,
    payload: { field: 'loading', value },
  };
}

export function savePlanBasicInfo(payload) {
  return {
    type: SAVE_BASIC_INFO,
    payload,
  };
}

export function savePlanBasicSettings(payload) {
  return {
    type: SAVE_BASIC_SETTINGS,
    payload,
  };
}

export function savePlannedVolumeHeader(payload) {
  return {
    type: SAVE_MASTER_PLANNED_VOLUME_HEADER,
    payload,
  };
}

export function addPlannedVolumeLine(payload) {
  return {
    type: ADD_MASTER_PLANNED_VOLUME_LINE,
    payload,
  };
}

export function addPlanActivityLine(payload) {
  return {
    type: ADD_ACTIVITY_LINE,
    payload,
  };
}

export function modifyPlanActivityLine(payload) {
  return {
    type: MODIFY_ACTIVITY_LINE,
    payload,
  };
}

export function deletePlanActivityLines(payload) {
  return {
    type: DELETE_ACTIVITY_LINES,
    payload,
  };
}

export function startNewAction(payload) {
  return {
    type: START_NEW,
    payload,
  };
}

export function startPlanCopyAction(payload) {
  return {
    type: START_MASTER_PLAN_COPY,
    payload,
  };
}

export function savePlan(payload) {
  return {
    type: SAVE_MASTER_PLAN,
    payload,
  };
}

export function savePlanDone(payload) {
  return {
    type: SAVE_MASTER_PLAN_DONE,
    payload,
  };
}

export function saveMasterPlanConflict(payload) {
  return {
    type: SAVE_MASTER_PLAN_CONFLICT,
    payload,
  };
}

export function clearMasterPlanConflict() {
  return {
    type: CLEAR_MASTER_PLAN_CONFLICT,
  };
}

export function savePlanError(payload) {
  return {
    type: SUBMIT_ERROR,
    payload,
  };
}

export function switchPlanToDisplayMode(payload) {
  return {
    type: SWITCH_TO_DISPLAY_MASTER_PLAN,
    payload,
  };
}

export function togglePlanSection(payload) {
  return {
    type: TOGGLE_SECTION_MASTER_PLAN,
    payload,
  };
}

export function addPeriodPlanAction(payload) {
  return {
    type: ADD_PERIOD_MASTER_PLAN,
    payload,
  };
}

export function deletePeriodPlanAction(payload) {
  return {
    type: DELETE_PERIOD_MASTER_PLAN,
    payload,
  };
}

export function setPeriodIndexPlanAction(payload) {
  return {
    type: STORE_MASTER_PLAN_PERIOD_INDEX,
    payload,
  };
}

export function copyPeriodPlanAction(payload) {
  return {
    type: COPY_PERIOD_MASTER_PLAN,
    payload,
  };
}
export function addLabourAvailabilityCategoryLine(payload) {
  return {
    type: ADD_LABOUR_AVAILABILITY_CATEGORY_LINE,
    payload,
  };
}
export function deleteLabourAvailabilityCategoryLine(payload) {
  return {
    type: DELETE_LABOUR_AVAILABILITY_CATEGORY_LINE,
    payload,
  };
}
export function addShiftSettingsLine(payload) {
  return {
    type: ADD_SHIFT_SETTINGS_LINE,
    payload,
  };
}
export function deleteShiftSettingsLine(payload) {
  return {
    type: DELETE_SHIFT_SETTINGS_LINE,
    payload,
  };
}
