/* eslint-disable no-param-reassign,no-useless-escape */
import React, { Component, Fragment } from 'react';
import ErrorBoundary from 'react-error-boundary';
import * as PropTypes from 'prop-types';
import styled from 'styled-components';

function formatStack(stack) {
  if (stack) {
    return stack.replace(/\n/g, '<br/>');
  }
  return stack;
}

function syntaxHighlight(json) {
  if (typeof json !== 'string') {
    json = JSON.stringify(json, undefined, '\t');
  }
  json = json
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;');
  json = json.replace(
    /("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g,
    match => {
      let cls = 'number';
      if (/^"/.test(match)) {
        if (/:$/.test(match)) {
          cls = 'key';
        } else {
          cls = 'string';
        }
      } else if (/true|false/.test(match)) {
        cls = 'boolean';
      } else if (/null/.test(match)) {
        cls = 'null';
      }
      return `<span class="${cls}">${match}</span>`;
    },
  );
  return json;
}

const ParagraphError = styled.pre`
  font-family: inherit;
  outline: none !important;
  margin: 0px !important;
  padding: 0px !important;
`;

const Wrapper = styled.div`
  max-width: 80%;
  top: 0;
  margin: 0 auto;
  pre {
    outline: 1px solid #ccc;
    padding: 5px;
    margin: 5px;
  }
  .string {
    color: green;
  }
  .number {
    color: darkorange;
  }
  .boolean {
    color: blue;
  }
  .null {
    color: magenta;
  }
  .key {
    color: red;
  }
`;

const Error = props => {
  const { componentStack, error } = props;
  return (
    <>
      <div>
        <strong>URL:</strong> {window.location.toString()}
      </div>
      <div>
        <strong>Error:</strong> {error.toString()}
      </div>
      <div>
        <strong>Error stack:</strong> <ParagraphError>{error.stack}</ParagraphError>
      </div>
      <div>
        <strong>Component stack:</strong> <ParagraphError>{componentStack.replace('\n', '')}</ParagraphError>
      </div>
      <div>
        <strong>Debug info:</strong> {window.__DREPdebugInfo__}
      </div>
    </>
  );
};

Error.propTypes = {
  componentStack: PropTypes.string,
  error: PropTypes.object,
};

// eslint-disable-next-line react/prefer-stateless-function
class MyFallbackComponent extends Component {
  render() {
    return (
      <Wrapper>
        <p>
          <strong>Oops! An error occurred!</strong>
        </p>
        <p>
          <strong>Please send error details below to application support</strong>
          <br />
          <br />
          <a href="/">Go to Main page</a>
        </p>
        <Error {...this.props} />
      </Wrapper>
    );
  }
}

MyFallbackComponent.propTypes = {
  componentStack: PropTypes.any,
  error: PropTypes.any,
};

// eslint-disable-next-line react/prop-types
export default props => <ErrorBoundary FallbackComponent={MyFallbackComponent}>{props.children}</ErrorBoundary>;
