import React from 'react';
import styled from 'styled-components';
import { Formik, Field } from 'formik';
import { useSelector, useDispatch } from 'react-redux';

import Select from 'components/StyledSelect';
import { required } from 'utils/form/validations';
import Label from 'components/Label';
import { ConfirmButton } from 'components/IconButton';
import { FormikTextInput } from 'components/FormikTextInput';
import { getToken } from 'containers/App/selectors';
import { fetchData } from 'utils/reduxApi';
import { withUrl } from 'utils/api';
import messages from './messages';
import { EntityEntry } from './types';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  min-height: 44px;

  > * {
    margin: 0 20px;
  }

  input {
    background-color: ${props => props.theme.color.grey5};
  }

  padding-bottom: 5px;
`;

const StyledSelect = styled(Select)`
  width: 12em;
  margin-top: 5px;
  margin-left: 12px;
`;

export type Props = {
  forecastEdit: boolean;
  handleReloadData: () => void;
  smartProdSourceId: number;
  allEventCategories: EntityEntry[];
  setOpenedEventDetail: (id: number) => void;
};

const EventAddForm: React.FC<Props> = ({
  handleReloadData,
  forecastEdit,
  smartProdSourceId,
  allEventCategories,
  setOpenedEventDetail,
}) => {
  const token = useSelector(getToken);
  const dispatch = useDispatch();

  const initialValues = {
    name: '',
    category: null,
  };

  const isFormValid = formikBag =>
    formikBag.values.name && formikBag.values.category;

  const handleAddNewEvent = async (formValues, actions) => {
    const result = await fetchData(
      withUrl(`/smartVolume/events`)
        .put({
          smartProdSourceId,
          category: {
            id: formValues.category,
          },
          name: formValues.name,
          omses: [],
        })
        .andToken(token),
      dispatch,
    );

    actions.setSubmitting(false);
    if (result.isOk) {
      actions.resetForm(initialValues);
      setOpenedEventDetail(result.data.id);
      handleReloadData();
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      isInitialValid
      enableReinitialize
      onSubmit={handleAddNewEvent}
      render={formikBag => (
        <Wrapper>
          <Label {...messages.newEventFormName} />
          <Field name="name" type="text" disabled={!forecastEdit} component={FormikTextInput} validate={[required]} />

          <Label {...messages.newEventFormCategory} />
          <Field
            name="category"
            render={({ field, form }) => (
              <StyledSelect
                id="eventAddForm-category"
                options={allEventCategories}
                isDisabled={!forecastEdit}
                onChange={option => {
                  form.setFieldValue(field.name, option.value);
                }}
                value={allEventCategories.find(o => o.value === field.value) || null}
              />
            )}
          />

          {forecastEdit && (
            <ConfirmButton
              id="event-add-confirm"
              type="submit"
              disabled={formikBag.isSubmitting || !isFormValid(formikBag)}
              onClick={formikBag.handleSubmit}
            />
          )}
        </Wrapper>
      )}
    />
  );
};

export default EventAddForm;
