/**
 *
 * PlanDetailPlannedVolume
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { PaShiftAddLine } from '../ShiftAddLine';
import { PaShiftSettings } from '../../containers/ShiftSettings';

/* eslint-disable react/prefer-stateless-function */
class PaShiftsDefinition extends React.PureComponent {
  render() {
    const { values, formik } = this.props;
    return (
      <div>
        <PaShiftAddLine {...{ values }} />
        <PaShiftSettings tableKey="shift-definition" labourCategory={false} formik={formik} />
      </div>
    );
  }
}

PaShiftsDefinition.propTypes = {
  values: PropTypes.object,
  periodIndex: PropTypes.number,
  formik: PropTypes.object,
};

export default PaShiftsDefinition;
