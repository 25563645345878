import React from 'react';
import PropTypes from 'prop-types';
import { connect as withFormik } from 'formik';
import get from 'lodash/get';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';

import ButtonWithDirtyCheck from 'components/ButtonWithDirtyCheck';
import { getCustomers } from 'components/CustomerCellRenderer';
import DropDownCellEditor from 'components/DropDownCellEditor';
import { AddLineButton } from 'components/IconButton';
import { UOMValueGetter } from 'components/ListGrid/utils';
import SelectDialog from 'components/SelectDialog';
import { selectViewModeStoredData } from 'components/ViewModePopover/selectors';
import { InputTypeValue } from 'components/ViewModePopover/types';
import { selectActivities, selectUOMS } from 'containers/App/selectors';
import { makeSelectToken } from 'containers/LoginPage/selectors';
import { addPlanActivityLine } from 'containers/PlanDetailPage/actions';
import {
  makeSelectIsShiftFromPlan,
  makeSelectShiftCountFromPlan,
  selectEditFromplan,
  selectPeriodIndexFromPlan,
  selectWzpCountFromPlan,
  uomsForActitivies,
} from 'containers/PlanDetailPage/selectors';
import { addPaActivityLine } from 'containers/PlanningAreaDetailPage/actions';
import {
  get as getFromObject,
  selectEditFromPa,
  selectIsShiftFromPa,
  selectPeriodIndexFromPa,
  selectShiftCountFromPa,
  selectWzpCountFromPa,
  uomsForPaActitivies,
} from 'containers/PlanningAreaDetailPage/selectors';
import { PRODUCTIVITY_TYPE } from 'utils/common/productivity/constants';
import withSecurity, { PERMISSIONS } from 'utils/security';

import ActivityHeader from './ActivityHeader';
import messages from './messages';

const AddWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  padding-bottom: 20px;
  > * {
    margin: 0 20px;
  }
`;

const WarnWrapper = styled.div`
  color: red;
`;

const effortForecastDefaultCheckedInputType: InputTypeValue = 'hours';

const activitiesColDefs = self => {
  const {
    intl: { formatMessage },
    uoms,
    formik,
    hasPerm,
    viewSettings,
  } = self.props;
  const isForecastEditor = hasPerm(PERMISSIONS.VIEW_FORECAST_SECTION);
  const uomOptions = uoms.map(u => ({
    label: `${u.name} / ${u.regionalConfigurationName}`,
    value: `${u.id}`,
    uom: u,
  }));

  let customerOptions = getCustomers(formik) || [];
  customerOptions = customerOptions.map(o => ({ value: `${o.id}`, label: o.name, ...o }));
  customerOptions.unshift({ value: 'all', label: formatMessage(messages.all) });

  let colDefs = [
    {
      headerName: '#',
      width: 60,
      checkboxSelection: true,
      sortable: false,
      suppressMenu: true,
      pinned: true,
      filter: false,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
    },
    {
      headerName: formatMessage(messages.tableHeaderLabel_regionalConfigurationName),
      minWidth: 160,
      field: 'regionalConfigurationName',
      colId: 'regionalConfigurationName',
      menuTabs: ['filterMenuTab'],
    },
    {
      headerName: formatMessage(messages.tableHeaderLabel_name),
      minWidth: 160,
      field: 'name',
      colId: 'name',
      menuTabs: ['filterMenuTab'],
    },
    {
      headerName: formatMessage(messages.tableHeaderLabel_customer),
      minWidth: 80,
      field: 'customer',
      colId: 'customer',
      editable: false,
      suppressMenu: true,
      valueFormatter: params => params.value && params.value.label,
      cellRendererFramework: DropDownCellEditor,
      cellRendererParams: {
        getValues: params => {
          const { data } = params;
          const { uomsForActitivies = {} } = self.props;
          if (!data || (!data.uom && !data.indirect) || !uomsForActitivies) return customerOptions;
          let { departmentId } = self.state;
          const { id, uom, indirect, productivityType } = data;
          departmentId = departmentId || 'all';
          if (!indirect) {
            const customers = getFromObject(uomsForActitivies, [departmentId, id]);
            if (!customers) return customerOptions;
            const excludeCustomers = Object.keys(customers).filter(c => uom.id in customers[c]);
            if (!excludeCustomers) return customerOptions;
            return customerOptions.filter(c => !excludeCustomers.includes(c.value));
          }
          const excludeCustomers = getFromObject(uomsForActitivies, [departmentId, id]);
          if (!excludeCustomers || productivityType === PRODUCTIVITY_TYPE.LOCATION_UNPRODUCTIVE) return customerOptions;
          return customerOptions.filter(c => !(c.value in excludeCustomers));
        },
        defaultValue: 'all',
        disableNoOpts: true,
        valueKey: 'id',
        placeholder: formatMessage(messages.selectCustomer),
      },
    },
  ];

  if (isForecastEditor) {
    colDefs = colDefs.concat([
      {
        headerName: formatMessage(messages.omsCode),
        width: 80,
        field: 'omsName',
        colId: 'omsName',
        valueGetter: params => (params.data ? `${params.data.omsCode} - ${params.data.omsName}` : ''),
        editable: false,
        menuTabs: ['filterMenuTab'],
        sortable: false,
      },
    ]);
  }
  colDefs = colDefs.concat([
    {
      headerName: formatMessage(messages.tableHeaderLabel_uom),
      minWidth: 80,
      field: 'uom',
      colId: 'uom',
      editable: false,
      suppressMenu: true,
      filterValueGetter: UOMValueGetter,
      valueFormatter: params => params.value && params.value.name,
      cellRendererFramework: DropDownCellEditor,
      cellRendererParams: {
        placeholder: formatMessage(messages.selectUOM),
        getValue: uom => (uom && `${uom.id}`) || '',
        getValues: params => {
          const { uomsForActitivies = {} } = self.props;
          let { departmentId } = self.state;
          let { id, customer, indirect } = params.data;
          if (indirect) return;
          if (!uomsForActitivies) return uomOptions;

          departmentId = departmentId || 'all';
          customer = customer || 'all';
          const excludeUoms = getFromObject(uomsForActitivies, [departmentId, id, customer]);
          if (!excludeUoms) return uomOptions;
          return uomOptions.filter(o => !(o.value in excludeUoms));
        },
        valueKey: 'uom',
        disableNoOpts: true,
        skipIndirect: true,
      },
    },
    {
      headerName: formatMessage(messages.tableHeaderLabel_variableName),
      minWidth: 100,
      field: 'variableName',
      colId: 'variableName',
      menuTabs: ['filterMenuTab'],
    },
    {
      headerName: formatMessage(messages.tableHeaderLabel_activityTypeName),
      minWidth: 100,
      field: 'activityTypeName',
      colId: 'activityTypeName',
      menuTabs: ['filterMenuTab'],
    },
    {
      headerName: formatMessage(messages.tableHeaderLabel_indirect),
      minWidth: 50,
      field: 'indirect',
      colId: 'indirect',
      menuTabs: ['filterMenuTab'],
      valueGetter: params => (params.data.indirect === true ? formatMessage(messages.yes) : formatMessage(messages.no)),
    },
    {
      headerName: formatMessage(messages.tableHeaderLabel_productivityType),
      minWidth: 50,
      field: 'productivityType',
      colId: 'productivityType',
      menuTabs: ['filterMenuTab'],
      valueGetter: params =>
        messages[params.data.productivityType]
          ? formatMessage(messages[params.data.productivityType])
          : params.data.productivityType,
    },
  ]);
  return colDefs;
};

/* eslint-disable react/prefer-stateless-function */
class PlanDetailActivitySettings extends React.PureComponent {
  state = {
    activity: null,
    selectDialogIsOpen: false,
    departmentId: null,
    showTable: false,
  };

  api = null;

  openDialog = () => {
    this.setState({ activity: null, selectDialogIsOpen: true, departmentId: null });
  };

  setDepartment = departmentId => {
    this.setState({ departmentId });
    this.api.redrawRows();
  };

  onGridReady = params => {
    this.api = params.api;
  };

  validateData(rows) {
    if (rows.filter(r => !r.customer || (!r.indirect && !r.uom)).length > 0) {
      return false;
    }
    return true;
  }

  setShowTable = () => {
    this.setState({ showTable: true }, () => this.api.sizeColumnsToFit());
  };

  render() {
    const editable = this.props.edit;
    const {
      props: {
        isShift,
        periodIndex,
        activities,
        wzpCount,
        shiftsCount,
        formik: {
          values: {
            planningParameters: { periods, departments },
          },
        },
        viewSettings,
      },
    } = this;
    const { departmentId, showTable } = this.state;
    const activityParameters = periods && periods[periodIndex] && periods[periodIndex].activityParameters;
    const customers = getCustomers(this.props.formik);
    let activitiesActive = activities;

    // customers + 'all' options
    const cLength = (customers && customers.length + 1) || 1;
    const dLength = (departments && departments.length + 1) || 1;
    const allLength = dLength * cLength;
    function filterActivity(a) {
      if (!a.indirect || a.productivityType === PRODUCTIVITY_TYPE.LOCATION_UNPRODUCTIVE || !activityParameters)
        return true;
      const allA = activityParameters.filter(item => get(item, 'activity.id') === a.id);
      if (allA.length === 0) return true;
      if (allA.length === allLength) return false;

      return allA.filter(a => a.departmentId === departmentId).length !== cLength;
    }
    if (activities && activityParameters) {
      activitiesActive = activities.filter(filterActivity).map(a => ({ ...a, uom: null }));
    }
    return (
      <div>
        {editable &&
          ((!isShift && wzpCount === 0) || (isShift && shiftsCount === 0) ? (
            <WarnWrapper>
              {isShift ? (
                <FormattedMessage {...messages.noShiftWarning} />
              ) : (
                <FormattedMessage {...messages.noWzpWarning} />
              )}
            </WarnWrapper>
          ) : (
            <AddWrapper>
              <FormattedMessage {...messages.activity} />
              <>
                <ButtonWithDirtyCheck
                  actionPayload={this.state}
                  actionHandler={this.openDialog}
                  component={AddLineButton}
                />
                <SelectDialog
                  CusomHeader={
                    <ActivityHeader
                      departments={departments}
                      onChange={this.setDepartment}
                      onConfirm={this.setShowTable}
                    />
                  }
                  onSelected={data => {
                    this.setState(
                      {
                        activity: data.map(d => ({
                          ...d,
                          isEffortForecast:
                            viewSettings && viewSettings.inputType === effortForecastDefaultCheckedInputType,
                        })),
                        selectDialogIsOpen: false,
                        showTable: false,
                      },
                      () => {
                        this.props.addActivityLine({ data: this.state });
                      },
                    );
                  }}
                  data={activitiesActive}
                  isOpen={this.state.selectDialogIsOpen}
                  messages={messages}
                  onClose={() => this.setState({ selectDialogIsOpen: false, showTable: false })}
                  columnDefs={showTable ? activitiesColDefs(this) : []}
                  onGridReady={this.onGridReady}
                  validateData={this.validateData}
                  frameworkComponents={{
                    dropDown: DropDownCellEditor,
                  }}
                  departmentId={departmentId}
                  showTable={showTable}
                />
              </>
            </AddWrapper>
          ))}
        {this.props.children}
      </div>
    );
  }
}

PlanDetailActivitySettings.propTypes = {
  edit: PropTypes.bool,
  addActivityLine: PropTypes.func,
  children: PropTypes.node,
  intl: PropTypes.object,
  token: PropTypes.string,
  activities: PropTypes.array,
  wzpCount: PropTypes.number,
};

function mapPlanDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      addActivityLine: addPlanActivityLine,
    },
    dispatch,
  );
}

const withPlanConnect = connect(
  createStructuredSelector({
    edit: selectEditFromplan,
    token: makeSelectToken(),
    activities: selectActivities,
    wzpCount: selectWzpCountFromPlan,
    shiftsCount: makeSelectShiftCountFromPlan(),
    isShift: makeSelectIsShiftFromPlan(),
    periodIndex: selectPeriodIndexFromPlan,
    uomsForActitivies,
    uoms: selectUOMS,
    viewSettings: selectViewModeStoredData,
  }),
  mapPlanDispatchToProps,
);

function mapPaDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      addActivityLine: addPaActivityLine,
    },
    dispatch,
  );
}

const withPaConnect = connect(
  createStructuredSelector({
    edit: selectEditFromPa,
    token: makeSelectToken(),
    activities: selectActivities,
    wzpCount: selectWzpCountFromPa,
    shiftsCount: selectShiftCountFromPa,
    isShift: selectIsShiftFromPa,
    periodIndex: selectPeriodIndexFromPa,
    uomsForActitivies: uomsForPaActitivies,
    uoms: selectUOMS,
  }),
  mapPaDispatchToProps,
);

export default compose(injectIntl, withPlanConnect, withFormik, withSecurity())(PlanDetailActivitySettings);

export const PlanningAreaDetailActivitySettings = compose(
  injectIntl,
  withPaConnect,
  withFormik,
  withSecurity(),
)(PlanDetailActivitySettings);
