import React from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { connect as formikConnect, Field, FormikProps } from 'formik';
import { DateTime } from 'luxon';
import { FormattedMessage, InjectedIntl, injectIntl } from 'react-intl';
import styled from 'styled-components';

import Select from 'components/StyledSelect';
import { ForecastForm, SmartProdPeriodTypeEnum } from 'containers/ForecastDetailPage/types';
import { ApiSmartProdRunScheduleDTO } from 'types/drep-backend.d';

import { DATA_SOURCE_TYPES, FORECAST_PERIOD_TYPES } from './constants';
import messages from './messages';
import StartEndDate from './StartEndDate';

const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0 10px 0 0;
  height: 100%;
  min-width: 200px;
  align-items: center;
  > * {
    margin: 0 10px;
  }
`;

const DaysInput = styled(TextField)`
  width: 50px;
`;

const StyledSelect = styled(Select)`
  width: 12em;
  margin-top: 5px;
  margin-left: 12px;
`;

export type FormValues = {
  startDate: DateTime;
  endDate: DateTime;
};

export type Props = {
  formik?: FormikProps<ForecastForm>;
  forecastEdit: boolean;
  intl: InjectedIntl;
  periodType: SmartProdPeriodTypeEnum;
  data: ApiSmartProdRunScheduleDTO;
};

const RelativePeriod: React.FC<Props> = ({ formik, forecastEdit, intl, periodType, data }) => {
  const predefinedPeriods = periodType === SmartProdPeriodTypeEnum.SOURCE ? DATA_SOURCE_TYPES : FORECAST_PERIOD_TYPES;
  const formikBasePath = `smartProdScheduledRuns.${data.id}`;
  const formikBaseValue = formik.values.smartProdScheduledRuns[data.id];
  const periodTypeValue =
    periodType === SmartProdPeriodTypeEnum.SOURCE ? formikBaseValue.sourceType : formikBaseValue.forecastType;
  const options = Object.keys(predefinedPeriods).map(key => ({
    value: key,
    label: intl.formatMessage(predefinedPeriods[key].messageId),
  }));
  const currentValue =
    periodType === SmartProdPeriodTypeEnum.SOURCE ? formikBaseValue.sourceType : formikBaseValue.forecastType;
  const daysFromFieldPath =
    formikBasePath + (periodType === SmartProdPeriodTypeEnum.SOURCE ? '.sourceDaysFrom' : '.forecastDaysFrom');
  const daysToFieldPath =
    formikBasePath + (periodType === SmartProdPeriodTypeEnum.SOURCE ? '.sourceDaysTo' : '.forecastDaysTo');
  const dateLimits = { maxDate: DateTime.now().minus({ days: 1 }) };
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <StyledSelect
          id={`periodType-${periodType}`}
          options={options}
          isDisabled={!forecastEdit}
          onChange={option => {
            const fieldPath =
              formikBasePath + (periodType === SmartProdPeriodTypeEnum.SOURCE ? '.sourceType' : '.forecastType');
            formik.setFieldValue(fieldPath, option.value);
          }}
          value={options.find(o => o.value === periodTypeValue) || null}
        />
      </Grid>
      {currentValue === 'CUSTOM' && (
        <RowWrapper>
          <strong>
            <FormattedMessage {...messages.smartProdRunsStart} />
          </strong>
          <FormattedMessage {...messages.smartProdRunsToday} />
          {periodType === SmartProdPeriodTypeEnum.SOURCE ? '-' : '+'}
          <Field
            name={daysFromFieldPath}
            render={({ field, form }) => (
              <DaysInput
                InputProps={{ inputProps: { min: 0 } }}
                name={daysFromFieldPath}
                type="number"
                value={field.value}
                onChange={({ target: { value } }) => form.setFieldValue(field.name, value)}
              />
            )}
          />
          <FormattedMessage {...messages.smartProdRunsDays} />
          <strong>
            <FormattedMessage {...messages.smartProdRunsEnd} />
          </strong>
          <FormattedMessage {...messages.smartProdRunsToday} />
          {periodType === SmartProdPeriodTypeEnum.SOURCE ? '-' : '+'}
          <Field
            name={daysToFieldPath}
            render={({ field, form }) => (
              <DaysInput
                InputProps={{ inputProps: { min: 0 } }}
                name={daysToFieldPath}
                type="number"
                value={field.value}
                onChange={({ target: { value } }) => form.setFieldValue(field.name, value)}
              />
            )}
          />
          <FormattedMessage {...messages.smartProdRunsDays} />
        </RowWrapper>
      )}
      {currentValue === 'DATE_RANGE' && (
        <StartEndDate
          startFieldName={`smartProdScheduledRuns.${data.id}.sourceStartDate`}
          endFieldName={`smartProdScheduledRuns.${data.id}.sourceEndDate`}
          forecastEdit={forecastEdit}
          startDateLimits={forecastEdit ? dateLimits : undefined}
          endDateLimits={forecastEdit ? dateLimits : undefined}
          formik={formik}
        />
      )}
    </Grid>
  );
};

export default injectIntl(formikConnect(RelativePeriod));
