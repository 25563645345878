import 'utils/edit.css';
import './style.css';

import React, { useEffect, useState } from 'react';
import { connect as formikConnect, Field, FormikProps, getIn } from 'formik';
import { DateTime } from 'luxon';
import { isEmpty } from 'lodash';
import { FormattedMessage, InjectedIntl, injectIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import Button from 'components/Button';
import ButtonWithDirtyCheck from 'components/ButtonWithDirtyCheck';
import DialogDHL from 'components/Dialog';
import FormikDatePicker from 'components/FormikDatePicker';
import Lb from 'components/Label';
import { getToken } from 'containers/App/selectors';
import { withUrl } from 'utils/api';
import { formatTime, parseTime } from 'utils/dateTime';
import { fetchData } from 'utils/reduxApi';
import withSecurity, { ROLES } from 'utils/security';
import { ApiMasterPlanDTO } from 'types/drep-backend.d';

import FormikTextInput from '../FormikTextInput';
import TimePicker from '../TimePicker';
import messages from './messages';
const Label = styled(Lb)`
  margin-right: 10px;
  margin-top: 20px;
  height: ${props => props.theme.grid.rowHeight};
  display: flex;
  align-items: center;
`;

const RefreshNowButton = styled(Button)`
  margin: 10px;
  height: 28px;
`;

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 160px 192px 160px;
  align-items: center;
  grid-row-gap: ${props => props.theme.grid.rowGap};
  input {
    background-color: ${props => props.theme.color.grey5};
    margin: 0px;
  }
`;

const PATHS_BASIC_SETTINGS = {
  customRefreshDate: 'masterPlan.customRefreshDate',
  customRefreshStartDate: 'customRefreshStartDate',
  customRefreshEndDate: 'customRefreshEndDate',
  scheduleStartDay: 'masterPlan.scheduleStartDay',
  schedulePeriod: 'masterPlan.schedulePeriod',
  scheduleStartTime: 'masterPlan.scheduleStartTime',
  clearSchedule: 'masterPlan.clearSchedule',
};

type Props = {
  intl: InjectedIntl;
  editMode: boolean;
  masterPlanId: number;
  formik?: FormikProps<{
    masterPlan: ApiMasterPlanDTO;
    customRefreshStartDate: any;
    customRefreshEndDate: any;
  }>;
  loadPlanAction: Function;
  user: any;
};

const AutomaticRefreshForReportingSettings: React.FC<Props> = ({
  intl,
  formik,
  masterPlanId,
  editMode,
  loadPlanAction,
  user,
}) => {
  const token = useSelector(getToken);
  const [saveFlag, setSaveFlag] = useState(false);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const hasPlans = formik?.values?.masterPlan?.plans?.length;
  const isAdmin = user.user.roles.find(r => r.role === ROLES.ADMIN.name);
  const isMPAdmin = user.user.roles.find(r => r.role === ROLES.SMARTPLAN_MASTER_PLAN_ADMIN_ROLE.name);
  const plansLength = formik.values.masterPlan.plans.length > 0;
  const handleRefreshNow = (props) => {
    setOpen(true);
    if(props?.waitForSaveAll) setSaveFlag(props?.waitForSaveAll);
    if (isAdmin || isMPAdmin) {
      formik.setFieldValue(paths.customRefreshDate, DateTime.local());
      formik.setFieldValue(paths.customRefreshStartDate, DateTime.now());
      //formik.setFieldValue(paths.customRefreshEndDate, DateTime.now());
    } else {
      doRefreshNow('', '');
    }
  };

  const doRefreshNow = (startDate, endDate) => {
    if (hasPlans) {
      fetchData(
        withUrl(`/masterPlan/${masterPlanId}/report?startDate=${startDate}&endDate=${endDate ? endDate : ''}`).post().andToken(token).asRawResponse(),
        dispatch,
      ).then(() => {
        if (saveFlag) {
          formik.submitForm();
        } else {
          loadPlanAction({ id: masterPlanId, edit: editMode });
        }
      });
    } else {
      toast.error(intl.formatMessage(messages.masterPlanHasNoAssignedPlans));
    }
  };

  const handleClearScheduling = () => {
    formik.setFieldValue(paths.scheduleStartDay, '');
    formik.setFieldValue(paths.scheduleStartTime, '');
    formik.setFieldValue(paths.schedulePeriod, '');
    formik.setFieldValue(paths.clearSchedule, true);
    formik.setFieldValue(paths.customRefreshStartDate, '');
    formik.setFieldValue(paths.customRefreshEndDate, '');
  };

  const handleSetDefault = () => {
    const createdDate = new Date(formik.values.masterPlan.createdDate);
    const hour = createdDate.getMinutes() > 30 ? createdDate.getHours() + 1 : createdDate.getHours();
    const hourStr = hour < 10 ? `0${hour}` : hour;

    let startDay = DateTime.fromObject({ hour, minute: 30 });
    if (startDay < DateTime.now()) {
      startDay = DateTime.fromObject({ day: startDay.day + 1, hour, minute: 30 });
    }
    formik.setFieldValue(paths.scheduleStartDay, startDay);
    formik.setFieldValue(paths.scheduleStartTime, `${hourStr}:30:00.000`);
    formik.setFieldValue(paths.schedulePeriod, '1');
  };

  const paths = PATHS_BASIC_SETTINGS;
  let minValidFrom, maxValidTo, maxStartDate;

  if (plansLength) {
    const data = formik.values.masterPlan.plans;
    data.sort(function (a, b) {
      if (typeof (b.validTo) == 'object' || typeof (a.validTo) == 'object') {
        return +new Date(b.validTo).toDateString() - +new Date(a.validTo).toDateString()
      } else {
        return +new Date(b.validTo) - +new Date(a.validTo)
      }
    });
    minValidFrom = data[data.length - 1].validFrom;
    maxValidTo = data[0].validTo;
    maxStartDate = DateTime.fromISO(maxValidTo)
  };

  let minStartDate = DateTime.local();
  if (isAdmin) {
    minStartDate = minValidFrom ? DateTime.fromISO(minValidFrom) : undefined;
  }
  const minEndDate = formik.values.customRefreshStartDate;
  const formikValues = formik.values.customRefreshStartDate;
  let maxEndDate = formikValues && formikValues.plus({ days: 364 });
  if (formikValues) {
    const date1 = +new Date(maxValidTo);
    const date2 = +DateTime.now().plus({ days: 364 });
    const date3 = +formikValues.plus({ days: 364 });
    const diffTime = date2 - date1;
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    if (diffDays < 0) {
      maxEndDate = DateTime.fromISO(maxValidTo);
    }
    if(date3 < date1){
      maxEndDate = formikValues && formikValues.plus({ days: 364 });
    }else{
      maxEndDate = DateTime.fromISO(maxValidTo);
    }
  };

  useEffect(()=>{
    if(formik.values.customRefreshEndDate < formik.values.customRefreshStartDate){
      formik.setFieldValue('customRefreshEndDate', undefined)
    }
  },[formik.values.customRefreshStartDate]);
  
  return (
    <>
      <Wrapper>
        <Label {...messages.scheduleStartDate} />
        <Field
          component={FormikDatePicker}
          name={paths.scheduleStartDay}
          editable={editMode}
          minDate={DateTime.local()}
          minDateMessage=""
          timePicker={false}
        />
        <div>
          {!(formik.values.masterPlan && formik.values.masterPlan.deleted) && (
            <ButtonWithDirtyCheck
              disabled={!plansLength}
              actionHandler={(props) => handleRefreshNow(props)}
              component={RefreshNowButton}
              message={messages.refreshNowButton}
            />
          )}
        </div>
        <Label {...messages.scheduleTime} />
        {editMode ? (
          <TimePicker
            name={paths.scheduleStartTime}
            value={parseTime(getIn(formik.values, paths.scheduleStartTime))}
            onChange={val => formik.setFieldValue(paths.scheduleStartTime, val)}
            editable={editMode}
            width="100%"
          />
        ) : (
          <div>{formatTime(getIn(formik.values, paths.scheduleStartTime))}</div>
        )}
        <div>
          {editMode && !(formik.values.masterPlan && formik.values.masterPlan.deleted) && (
            <RefreshNowButton onClick={handleSetDefault}>
              <FormattedMessage {...messages.setDefaultButton} />
            </RefreshNowButton>
          )}
        </div>
        <Label {...messages.schedulePeriod} />
        {editMode ? (
          <Field
            component={FormikTextInput}
            name={paths.schedulePeriod}
            editable={editMode}
            value={getIn(formik.values, paths.schedulePeriod) || ''}
            style={{ margin: 0, width: 192 }}
          />
        ) : (
          <div>{formatTime(getIn(formik.values, paths.schedulePeriod))}</div>
        )}
        <div>
          {editMode && !(formik.values.masterPlan && formik.values.masterPlan.deleted) && (
            <RefreshNowButton onClick={handleClearScheduling}>
              <FormattedMessage {...messages.clearSchedulingButton} />
            </RefreshNowButton>
          )}
        </div>
        <DialogDHL
          open={open}
          headerLabel={messages.refreshDates}
          onCancel={() => {
            if(!isEmpty(Object.keys(formik.touched)) && saveFlag){
              formik.submitForm();
            }else if(!isEmpty(Object.keys(formik.touched)) && saveFlag === false){
              formik.resetForm();
            }else{
              formik.setFieldValue(paths.customRefreshDate, null);
              formik.resetForm();
            }
            setSaveFlag(false);
            setOpen(false);
          }}
          onConfirm={() => {
            doRefreshNow(getIn(formik.values, paths.customRefreshStartDate).toUTC().toString(),
              getIn(formik.values, paths.customRefreshEndDate) ? getIn(formik.values, paths.customRefreshEndDate).toUTC().toString() : null);
            formik.setFieldValue(paths.customRefreshDate, null);
            setSaveFlag(false);
            setOpen(false);
          }}
        >
          <Label {...messages.refreshStartDateSelect} />
          <Field
            component={FormikDatePicker}
            name={paths.customRefreshStartDate}
            editable
            minDate={minStartDate}
            maxDate={maxStartDate}
            minDateMessage=""
            timePicker={false}
          />
          <Label {...messages.refreshEndDateSelect} />
          <Field
            component={FormikDatePicker}
            name={paths.customRefreshEndDate}
            defaultValue={null}
            editable
            minDate={minEndDate}
            maxDate={maxEndDate}
            minDateMessage=""
            maxDateMessage=""
            timePicker={false}
          />
        </DialogDHL>
      </Wrapper>
    </>
  );
};

// @ts-ignore
export default withSecurity()(formikConnect(injectIntl(AutomaticRefreshForReportingSettings)));
