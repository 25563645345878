import React from 'react';
import MenuItem from '@material-ui/core/MenuItem/MenuItem';
import Select from '@material-ui/core/Select/Select';
import { Field, Formik, FormikActions, FormikProps } from 'formik';
import { DateTime } from 'luxon';
import { FormattedMessage, InjectedIntl, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';

import { isDayOfWeekTransformationActivity } from 'components/DayOfWeekTransformation';
import { InputError } from 'components/FormikTextInput';
import { selectViewModeStoredData } from 'components/ViewModePopover/selectors';
import { ViewSettings } from 'components/ViewModePopover/types';
import { GRANULARITY } from 'containers/PlanResultPage/constants';
import { formatDate } from 'utils/dateTime';
import withSecurity, { PERMISSIONS } from 'utils/security';

import FormikDatePicker from '../FormikDatePicker';
import { ConfirmButton, ExportButton } from '../IconButton';
import { LabelStyled } from '../Label';
import { FormikCheckbox, FormikCheckboxSunday } from '../Menu/ItemSelectable';
import messages from './messages';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  > * {
    margin: 0 10px 0 0;
  }
  @media screen and (min-width: 1400px) {
    > * {
      margin: 0 20px 0 0;
    }
  }
  @media screen and (min-width: 1700px) {
    > * {
      margin: 0 20px 0 20px;
    }
  }

  padding-bottom: 5px;

  ${ExportButton} {
    margin-left: auto;
  }

  ${InputError} {
    margin-top: 8px;
    margin-bottom: 4px;
    font-size: 12px;
  }

  ${LabelStyled} {
    word-break: normal;
  }
`;

export const SaveButton: React.FC<{ isSubmitting: boolean; onClick: () => void }> = buttonProps => (
  <ConfirmButton
    id="result-matrix-confirm"
    type="submit"
    disabled={buttonProps.isSubmitting}
    onClick={buttonProps.onClick}
  />
);

type FormikSelectProps = {
  field: any;
  children: any;
  form: FormikActions<any>;
  onChange?: Function;
};

export function FormikSelect(props: FormikSelectProps) {
  const { children, field, onChange, ...other } = props;
  return (
    <Select
      {...other}
      {...field}
      onChange={c => {
        props.form.setFieldValue(field.name, c.target.value);
        if (onChange) onChange(c.target.value);
      }}
    >
      {children}
    </Select>
  );
}

function maxDateByGranularity(gran, startDate) {
  switch (gran) {
    case GRANULARITY.MONTH:
    case GRANULARITY.WEEK:
      return (startDate && startDate.plus({ years: 1 })) || null;
    case GRANULARITY.DAY:
    case GRANULARITY.WZP:
      return (startDate && startDate.plus({ months: 1 })) || null;
    case GRANULARITY.HOUR:
    default:
      return (startDate && startDate.plus({ days: 14 })) || null;
  }
}

const startDateValidation = endDate => value =>
  value && value > endDate ? `Must not be more than ${formatDate(endDate)}` : undefined;

const endDateValidation = values => value => {
  if (value && value < values.startDate) return `Must be at least ${formatDate(values.startDate)}`;

  const max = getMaxDate(values);
  if (value && value > max) return `Must not be more than ${formatDate(max)}`;

  return undefined;
};

function getMaxDate(values) {
  const { granularity, startDate } = values;
  let date = startDate;
  if (typeof date === 'string') {
    date = DateTime.fromFormat(startDate, 'dd/MM/yyyy');
  }
  return maxDateByGranularity(granularity, date);
}

const formattedDate = (val) => {
  const newValue = new Date(val);
  const year = newValue.toLocaleString("default", { year: "numeric" });
  const month = newValue.toLocaleString("default", { month: "2-digit" });
  const day = newValue.toLocaleString("default", { day: "2-digit" });
  const formattedDate = year + "-" + month + "-" + day;
  return formattedDate;
}

const validateDates = (formik, min, max) =>{
  return DateTime.fromFormat(formattedDate(formik?.values?.startDate), 'yyyy-MM-dd') >= DateTime.fromISO(formattedDate(min)) && DateTime.fromFormat(formattedDate(formik?.values?.endDate), 'yyyy-MM-dd') <= DateTime.fromISO(formattedDate(max))
}

/* eslint-disable react/prefer-stateless-function */
type ResultMatrixDateOptionsMasterPlanType = {
  token: string;
  dispatch: any;
  formikBag: FormikProps<Object>;
  startDate: DateTime;
  endDate: DateTime;
  granularity: string;
  granularityOptions: Array<string>;
  onConfirm: () => {};
  mheCheckbox?: boolean;
  includeMhe: boolean;
  includeRole: boolean;
  isShift: boolean;
  showEndDate: boolean;
  limitStartDate: boolean;
  includeBaseLine: boolean;
  includeForecast: boolean;
  includeWeekStartAsSunday: boolean;
  includeVolumeCategory: boolean;
  showBaseLine: boolean;
  showGranularity: boolean;
  showForecast: boolean;
  showCsvExport: boolean;
  plan: any;
  planningParametersId: string | number;
  intl: InjectedIntl;
  hasPerm: Function;
  onResultSettingsChange: Function;
  viewSettings: ViewSettings;
  granularityValue: string;
  planData: any;
  minDate: any;
  maxDate: any;
  planBasicDetails: any;
};

function ResultMatrixDateOptionsMasterPlan(props: ResultMatrixDateOptionsMasterPlanType) {
  const {
    endDate,
    startDate,
    granularity = GRANULARITY.WEEK,
    granularityOptions = GRANULARITY,
    includeMhe = false,
    includeRole = false,
    includeBaseLine = false,
    includeWeekStartAsSunday = false,
    includeVolumeCategory = false,
    includeForecast = false,
    mheCheckbox = true,
    planningParametersId,
    isShift = false,
    showEndDate = true,
    showBaseLine = false,
    showGranularity = true,
    showForecast = true,
    onConfirm,
    limitStartDate = true,
    hasPerm,
    onResultSettingsChange,
    viewSettings,
    granularityValue,
    planData,
    minDate,
    maxDate,
    planBasicDetails
  } = props;
  const hasDayOfWeekTransformationActivity = !isDayOfWeekTransformationActivity(props.formikBag);
  const isForecastEditor = hasPerm(PERMISSIONS.VIEW_FORECAST_SECTION);
  const plansTransformationType = planData?.every((p)=>p.dayTransformationType === 'VOLUME_CATEGORY');
  const plantransformationType = [...new Set(planBasicDetails?.map((p) => p.planningParameters.transformationType))] || [];
  const plantransformationTypeFlag = plantransformationType.includes('SHIFT') && (plantransformationType.includes('WZP') || plantransformationType.includes('HOUR'));
  return (
    <Formik
      initialValues={{
        endDate,
        startDate,
        granularity,
        includeMhe: includeMhe && !!viewSettings?.checkboxMHE,
        includeRole: includeRole && !!viewSettings?.checkboxRole,
        planningParametersId,
        includeBaseLine,
        includeWeekStartAsSunday,
        includeVolumeCategory,
        includeForecast: includeForecast && viewSettings && viewSettings.inputType !== 'volume',
        planData
      }}
      isInitialValid
      enableReinitialize
      validateOnChange
      onSubmit={onConfirm}
      render={formikBag => (
        <Wrapper>
          <LabelStyled {...messages.startDate} />
          <div>
            <Field
              onAccept={newStartDate => {
                const update = { ...formikBag.values, startDate: newStartDate };
                if (newStartDate.startOf('day') > formikBag.values.endDate.startOf('day')) {
                  update.endDate = newStartDate;
                  formikBag.setFieldValue('endDate', newStartDate);
                }
                formikBag.setFieldValue('startDate', newStartDate);
                onResultSettingsChange && onResultSettingsChange(update);
              }}
              component={FormikDatePicker}
              minDate={minDate}
              maxDate={maxDate}
              okLabel={props.intl.formatMessage(messages.setstartDate)}
              name="startDate"
              {...(limitStartDate
                ? {
                  validate: startDateValidation(formikBag.values.endDate),
                }
                : {})}
            />
            <InputError>{formikBag.errors.startDate}</InputError>
          </div>
          {showEndDate && <LabelStyled {...messages.endDate} />}
          {showEndDate && (
            <div>
              <Field
                onAccept={newEndDate => {
                  const update = { ...formikBag.values, endDate: newEndDate };
                  if (newEndDate.startOf('day') < formikBag.values.startDate.startOf('day')) {
                    update.startDate = newEndDate;
                    formikBag.setFieldValue('startDate', newEndDate);
                  }
                  formikBag.setFieldValue('endDate', newEndDate);
                  onResultSettingsChange && onResultSettingsChange(update);
                }}
                component={FormikDatePicker}
                maxDate={maxDate}
                minDate={minDate}
                name="endDate"
                okLabel={props.intl.formatMessage(messages.setendDate)}
                validate={endDateValidation(formikBag.values)}
              />
              <InputError>{formikBag.errors.endDate}</InputError>
            </div>
          )}
          {showGranularity && (
            <>
              <LabelStyled {...messages.granularity} />
              <Field
                onChange={c => {
                  if(c != 'WEEK'){
                    formikBag.setFieldValue('includeWeekStartAsSunday', false);
                  }
                  onResultSettingsChange && onResultSettingsChange({ ...formikBag.values, granularity: c })
                }}
                component={FormikSelect}
                name="granularity"
              >
                {Object.keys(granularityOptions).map(key => (
                  <MenuItem key={key} value={key}>
                    {isShift && key === GRANULARITY.WZP ? (
                      <FormattedMessage {...messages.shift} />
                    ) : (key === GRANULARITY.WZP && plantransformationTypeFlag) ?
                      <FormattedMessage {...messages.shiftWzp} />
                      : (
                        <FormattedMessage {...messages[key.toLocaleLowerCase()]} />
                      )}
                  </MenuItem>
                ))}
              </Field>
            </>
          )}
          {mheCheckbox && viewSettings?.checkboxMHE && !formikBag.values.includeWeekStartAsSunday &&(
            <div style={{ display: 'flex ' }}>
              <LabelStyled {...messages.includeMhe} />
              <Field component={FormikCheckbox} name="includeMhe" />
            </div>
          )}
          {showBaseLine && (
            <div style={{ display: 'flex ' }}>
              <LabelStyled {...messages.includeBaseLine} />
              <Field component={FormikCheckbox} name="includeBaseLine" />
            </div>
          )}

          {showForecast &&
            isForecastEditor &&
            plansTransformationType &&
            viewSettings &&
            viewSettings.inputType !== 'volume' && (
              <div style={{ display: 'flex ' }}>
                <LabelStyled {...messages.includeForecast} />
                <Field component={FormikCheckbox} name="includeForecast" />
              </div>
            )}
          {viewSettings?.checkboxRole && !formikBag.values.includeWeekStartAsSunday &&(
            <div style={{ display: 'flex ' }}>
              <LabelStyled {...messages.includeRole} />
              <Field component={FormikCheckbox} name="includeRole" />
            </div>
          )}
          {granularityValue === 'WEEK' && (
            <div style={{ display: 'flex '}}>
              <LabelStyled {...messages.includeWeekStartAsSunday} />
              <Field component={FormikCheckboxSunday} name="includeWeekStartAsSunday" />
            </div>
          )}
           {(viewSettings.showVolumeSections && !formikBag.values.includeWeekStartAsSunday) && granularityValue !== 'MONTH' &&(
            <div style={{ display: 'flex '}}>
              <LabelStyled {...messages.includeVolumeCategory} />
              <Field component={FormikCheckbox} name="includeVolumeCategory" />
            </div>
          )}
          <SaveButton isSubmitting={!formikBag.isValid || !validateDates(formikBag, minDate, maxDate)} onClick={formikBag.handleSubmit} />
        </Wrapper>
      )}
    />
  );
}

const viewSettingsConnect = connect(
  createStructuredSelector({
    viewSettings: selectViewModeStoredData,
  }),
);

export default withSecurity()(viewSettingsConnect(injectIntl(ResultMatrixDateOptionsMasterPlan)));
