/*
 * MasterPlanColorCodeDetails Messages
 *
 * This contains all the text for the MasterPlanColorCodeDetails component.
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  redColor: {
    id: 'app.components.MasterPlanColorCodeDetails.redColor',
    defaultMessage: 'Red - Master plan is already expired.',
  },
  orangeColor: {
    id: 'app.components.MasterPlanColorCodeDetails.orangeColor',
    defaultMessage: 'Orange - Master plan is going to expire within 40 days.',
  },
  blackColor: {
    id: 'app.components.MasterPlanColorCodeDetails.blackColor',
    defaultMessage: 'Black - Master plan is valid for more than 40 days.',
  },
 
});
