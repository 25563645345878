/*
 * PlanningAreaDetailToolbar Messages
 *
 * This contains all the text for the PlanningAreaDetailToolbar component.
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  defaultView: {
    id: 'app.components.ToggleButton.defaultView',
    defaultMessage: 'Switch to Default View',
  },
  userView: {
    id: 'app.components.ToggleButton.userView',
    defaultMessage: 'Switch to User View',
  },
  defaultNames: {
    id: 'app.components.ToggleButton.defaultNames',
    defaultMessage: 'Switch to Default Names',
  },
  customNames: {
    id: 'app.components.ToggleButton.customNames',
    defaultMessage: 'Switch to Custom Names',
  },
});
