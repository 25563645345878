import * as React from 'react';
import { Component } from 'react';
import { FormikProps } from 'formik';
import { isEqual } from 'lodash';
import { InjectedIntl, injectIntl } from 'react-intl';
import { RouteComponentProps } from 'react-router-dom';
import { compose } from 'redux';
import styled from 'styled-components';

import IconButton from 'components/IconButton';
import Table from 'components/Table';
import { PATHS } from 'containers/App/constants';
import { ApiSmartShiftJobScheduleDTO } from 'types/drep-backend.d';

import messages from './messages';
import { ApiSchedule } from './types';

const SmallButton = styled(IconButton)`
  height: 20px;
`;

interface KronosActivitiesTableProps {
  intl: InjectedIntl;
  history: RouteComponentProps.history;
  formik: FormikProps<ApiSchedule>;
}

class KronosActivitiesTable extends Component<KronosActivitiesTableProps, {}> {
  public shouldComponentUpdate(nextProps: Readonly<KronosActivitiesTableProps>, nextState: Readonly<{}>): boolean {
    return (
      !isEqual(this.props.formik.values.smartShiftJobSchedules, nextProps.formik.values.smartShiftJobSchedules) ||
      !isEqual(this.props.formik.values.plans, nextProps.formik.values.plans)
    );
  }

  public render() {
    const { intl } = this.props;
    const { values } = this.props.formik;

    const data = values.smartShiftJobSchedules
      .filter((a: ApiSmartShiftJobScheduleDTO) => !a.scheduleActivityIds?.length)
      .map((a: ApiSmartShiftJobScheduleDTO) => ({
        id: a.id,
        smartShiftJobId: a.smartShiftJobId,
        name: a.activity,
        customer: a.customer ? a.customer.name : '',
        existsInKronos: intl.formatMessage(messages.yes),
        department: a.department,
        facility: a.facility?.name || '',
      }));

    return (
      <Table
        messages={messages}
        pagination={false}
        columnDefs={this.getColumnDefs()}
        rowData={data}
        domLayout="autoHeight"
      />
    );
  }

  private getColumnDefs() {
    const { history, formik } = this.props;
    const goToDetail = id => () =>
      history.push(PATHS.smartShiftJobScheduleDetailId.replace(':id', id).replace(':scheduleId', formik.values.id));
    const goToDetailButton = params => {
      const { data } = params;
      const canGoToDetail = data.id && data.department && data.customer && data.facility;
      return canGoToDetail ? <SmallButton onClick={goToDetail(data.id)} label={messages.detail} /> : <span />;
    };

    return [
      {
        colId: 'name',
        field: 'name',
        headerName: this.props.intl.formatMessage(messages.activityName),
        width: 100,
      },
      {
        colId: 'existsInKronos',
        field: 'existsInKronos',
        headerName: this.props.intl.formatMessage(messages.activityExistsInSmartShift),
        width: 90,
      },
      {
        colId: 'facility',
        field: 'facility',
        headerName: this.props.intl.formatMessage(messages.activityFacility),
      },
      {
        colId: 'department',
        field: 'department',
        headerName: this.props.intl.formatMessage(messages.activityDepartment),
      },
      {
        colId: 'customer',
        field: 'customer',
        headerName: this.props.intl.formatMessage(messages.activityCustomer),
      },
      {
        cellRendererFramework: goToDetailButton,
        cellRendererParams: params => ({
          id: params.data.id,
        }),
        colId: 'goToDetailButton',
        field: 'jobSchedule.id',
        headerName: '',
        width: 100,
        suppressMenu: true,
      },
    ];
  }
}

export default compose(
  injectIntl,
  // @ts-ignore
)(KronosActivitiesTable);
