import { withUrl } from 'utils/api';
import { fetchData } from 'utils/reduxApi';
import { ApiSmartProdSourceDTO, ApiSmartVolumeRunDTO } from "types/drep-backend.d";

import { EntityEntry, FacilityWarehouseEntityRelations } from './types';

export const fetchFacilities = async (token: string, dispatch, setFacilities: (entries: EntityEntry[]) => void) => {
  const response = await fetchData(withUrl(`/facilities/?expression=`).andToken(token), dispatch);
  setFacilities(
    response.isOk
      ? response.data.results.map(result => ({
          value: result.id,
          label: `${result.code} : ${result.name}`,
        }))
      : [],
  );
};

export const fetchNewFacility = async (token: string, dispatch, setFacilities: (entries: EntityEntry[]) => void, option) => {
  const response = await fetchData(withUrl(`/facilities/?expression=${option}`).andToken(token), dispatch);
  setFacilities(
    response.isOk
      ? response.data.results.map(result => ({
          value: result.id,
          label: `${result.code} : ${result.name}`,
        }))
      : [],
  );
};

export const fetchSmartProdInstances = async (
  token: string,
  dispatch,
  setSmartProdInstances: (entries: EntityEntry[]) => void,
) => {
  const response = await fetchData(withUrl(`/smartProdInstances/?expression=`).andToken(token), dispatch);
  setSmartProdInstances(
    response.isOk
      ? response.data.results.map(result => ({
          value: result.id,
          label: result.name,
        }))
      : [],
  );
};

export const fetchSmartProdInstancesActive = async (
  token: string,
  dispatch,
  setSmartProdInstances: (entries: EntityEntry[]) => void,
) => {
  const response = await fetchData(withUrl(`/smartProdInstances/?expression=&notDeleted=true`).andToken(token), dispatch);
  setSmartProdInstances(
    response.isOk
      ? response.data.results.map(result => ({
          value: result.id,
          label: result.name,
        }))
      : [],
  );
};

export const fetchWarehouses = async (token: string, dispatch, setWHIDs: (entries: EntityEntry[]) => void) => {
  const response = await fetchData(withUrl(`/smartProdWarehouses/?expression=`).andToken(token), dispatch);
  setWHIDs(
    response.isOk
      ? response.data.results.map(result => ({
          value: result.id,
          label: result.smartProdWhId,
          facilityId : result.facilityId,
        }))
      : [],
  );
};

export const fetchEntityRelation = async (
  token: string,
  dispatch,
  setEntityRelations: (entries: FacilityWarehouseEntityRelations) => void,
) => {
  const response = await fetchData(withUrl(`/smartProdSource/?expression=`).andToken(token), dispatch);
  if (response.isOk) {
    const newResponse = response && response.data && response.data.filter((data)=>!data.deleted);
    const relationsDictionary: FacilityWarehouseEntityRelations = {};
    newResponse && newResponse.forEach((row: ApiSmartProdSourceDTO) => {
      const { facility, smartProdInstance, smartProdWarehouse } = row;
      if (!relationsDictionary[facility.id]) {
        relationsDictionary[facility.id] = {};
      }
      if (!relationsDictionary[facility.id][smartProdInstance.id]) {
        relationsDictionary[facility.id][smartProdInstance.id] = {};
      }
      relationsDictionary[facility.id][smartProdInstance.id][smartProdWarehouse.id] = row
    });
    setEntityRelations(relationsDictionary);
  }
};

export const fetchSmartVolumeRuns = async (
  token: string,
  dispatch,
  plannningParametersId: number,
  setData: (entries: ApiSmartVolumeRunDTO[]) => void,
  setSmartVolumeLoader?: any,
) => {
  if(setSmartVolumeLoader) {
    setSmartVolumeLoader(true);
  }
  const response = await fetchData(
    withUrl(`/smartVolume/runs/?planningParametersId=${plannningParametersId}`).andToken(token),
    dispatch,
  );
  if (response.isOk) {
    setData(response.data.sort((a, b) => (a.finished > b.finished ? -1 : 1)));
  }
  if(setSmartVolumeLoader){
  setSmartVolumeLoader(false)
  }
};

export const refreshSmartVolumeRuns = (token: string, dispatch, plannningParametersId: number) =>
  fetchData(
    withUrl(`/smartVolume/runs/?planningParametersId=${plannningParametersId}`).andToken(token).post(),
    dispatch,
  );

  export const fetchSmartVolumeLastRuns = async (
    token: string,
    dispatch,
    plannningParametersId: number,
    setData: (entries: ApiSmartVolumeRunDTO[]) => void,
    setSmartVolumeLoader?: any,
  ) => {
    if(setSmartVolumeLoader) {
      setSmartVolumeLoader(true);
    }
    const response = await fetchData(
      withUrl(`/smartVolume/lastSuccessRun/?planningParametersId=${plannningParametersId}`).andToken(token),
      dispatch,
    );
    if (response.isOk) {
      setData(response.data.sort((a, b) => (a.finished > b.finished ? -1 : 1)));
    }
    if(setSmartVolumeLoader){
    setSmartVolumeLoader(false)
    }
  };
