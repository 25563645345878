export default class EnchantedMap<K, V> extends Map<K, V> {
  /**
   * Set a value is absent. Return the existing value if present, otherwise a new one
   */
  public setIfAbsent(key: K, value: V) {
    const current = this.get(key);
    if (current) {
      return current;
    } else {
      this.set(key, value);
      return value;
    }
  }

  /**
   * Set all values from provided `other` map
   */
  public setAll(other: Map<K, V>) {
    for (const e of other.entries()) {
      this.set(e[0], e[1]);
    }
  }

  /**
   * Get all values as an array
   */
  public listValues(): V[] {
    const result = [];
    for (const value of this.values()) {
      result.push(value);
    }
    return result;
  }
}
