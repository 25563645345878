import React, { useContext } from 'react';
import Grid from '@material-ui/core/Grid';
import { countries } from 'country-data';
import { connect as formikConnect, FastField, Field, FormikProps } from 'formik';
import { FormattedMessage, InjectedIntl, injectIntl } from 'react-intl';
import styled, { Theme, ThemeContext, withTheme } from 'styled-components';

import Button from 'components/Button';
import { useStyles } from 'components/FormikDayPicker/styled';
import Label from 'components/Label';
import Select from 'components/StyledSelect';
import { formatDate } from 'utils/dateTime';
import { smartProdSourceName } from "./fetch";
import { ApiSmartProdSourceDTO } from 'types/drep-backend.d';

import messages from './messages';
import { EntityEntry, SmartProdSourceForm, Holiday, SmartProdSourceEntityEntry, IdAndCode } from './types';

const CompactButton = styled(Button)`
  margin-right: 12px;
  display: inline-block;
`;
const DetailWrapper = styled.div`
  max-width: 1440px;
  padding: 20px;
  z-index: 100;

  ${Button} {
    white-space: normal;
    max-width: 120px;
    height: auto;
    margin-bottom: 15px;
  }

  div > span {
  }

  span {
    display: inline-block;
    vertical-align: middle;
    line-height: normal;
  }
`;

const CenteredLabel = styled(Label)`
  height: 100%;
  display: table;

  > span {
    display: table-cell;
    vertical-align: middle;
  }
`;

const InputError = styled.div`
  padding-top: 8px;
  padding-bottom: 8px;
  color: ${props => props.theme.color.red};
`;

const DoNotAssignButton = styled(Button)`
  display: inline-block;
  max-width: 200px !important;
`;

const DoAssignButton = styled(Button)`
  display: inline-block;
  max-width: 200px !important;
`;

const validateOMSCodes = (data: Holiday, intl: InjectedIntl, useHolidayName = true) => {
  if ((!data.omsIds || !data.omsIds.length) && (data.smartProdSourceIds && data.smartProdSourceIds.length)) {
    if (useHolidayName) {
      return intl.formatMessage(messages.holidaysTableErrorHolidayName, {
        holiday: data.localName,
        error: intl.formatMessage(messages.eventsTableSelectedOMSCodeValidation),
      });
    }
    return intl.formatMessage(messages.eventsTableSelectedOMSCodeValidation);
  }
  return undefined;
};

const validateSmartProdSource = (data: Holiday, intl: InjectedIntl, useHolidayName = true) => {
  if ((data.smartProdSourceIds && !data.smartProdSourceIds.length) && data.omsIds && data.omsIds.length) {
    if (useHolidayName) {
      return intl.formatMessage(messages.holidaysTableErrorHolidayName, {
        holiday: data.localName,
        error: intl.formatMessage(messages.eventsTableSelectedSmartProdSourceValidation),
      });
    }
    return intl.formatMessage(messages.eventsTableSelectedSmartProdSourceValidation);
  }
  return undefined;
};

export const validateHolidayEntry = (data: Holiday, intl: InjectedIntl, useEventNameInError = false) => {
  let errors = {};
  // if (validateOMSCodes(data, intl)) {
  //   errors = { ...errors, omsIds: validateOMSCodes(data, intl, useEventNameInError) };
  // }
  // if (validateSmartProdSource(data, intl)) {
  //   errors = { ...errors, smartProdSourceId: validateSmartProdSource(data, intl, useEventNameInError) };
  // }
  return errors;
};

type Props = {
  entity?: ApiSmartProdSourceDTO,
  intl: InjectedIntl;
  theme: Theme;
  formik: FormikProps<SmartProdSourceForm>;
  data: Holiday;
  forecastEdit: boolean;
  allOmsCodes: EntityEntry[];
  planFacilities: IdAndCode[];
  allSmartProdSources: SmartProdSourceEntityEntry[];
  holidayType: String;
  smartProdSources: any
  handleStopAutoAssign: any;
  handleAutoAssign: any;
};

const HolidaysTableDetailCellRenderer: React.FC<Props> = ({
  entity,
  intl,
  theme,
  formik,
  data,
  forecastEdit,
  allOmsCodes,
  planFacilities,
  allSmartProdSources,
  holidayType,
  smartProdSources,
  handleStopAutoAssign,
  handleAutoAssign,
}) => {
  const themeContext: { color: { yellow: string } } = useContext(ThemeContext);
  const classes = useStyles(theme, 48)();

  const sourceSystemName = (data) => {
    const filteredData = smartProdSources.filter((smart) => data.smartProdSourceIds.includes(smart.id));
    let sourceValues = [];
    if (holidayType == 'assigned') {
      filteredData.map((data) => {
        sourceValues.push(` ${data?.smartProdInstance?.name} / ${data?.smartProdWarehouse?.smartProdWhId} `)
      })
    };
    return sourceValues ? sourceValues.toString() : '';
  };

  const renderField = (
    labelName,
    fieldName,
    input,
    fastField = true,
    labelWidth = undefined,
    inputWidth = undefined,
    validate = undefined,
  ) => (
    <>
      <Grid item xs={labelWidth || 4}>
        <CenteredLabel {...labelName} />
      </Grid>
      <Grid item xs={inputWidth || 8}>
        {fastField ? (
          <FastField name={fieldName} render={input} validate={validate} />
        ) : (
          <Field name={fieldName} render={input} validate={validate} />
        )}
      </Grid>
    </>
  );

  const handleAddAllOMSCodes = () => {
    formik.setFieldValue(
      `holidaysTable[${data.id}].omsIds`,
      allOmsCodes.map(code => code.value),
    );
  };

  const handleRemoveAllOMSCodes = () => {
    formik.setFieldValue(`holidaysTable[${data.id}].omsIds`, []);
  };

  const StopAutoAssign = (data) => {
    handleStopAutoAssign(data)
  }
  const AutoAssignHoliday = (data) => {
    handleAutoAssign(data)
  }
  const showAutoStartButton = !data.autoAssignHoliday && data.holidayType === 'Local holiday';

  return (
    <DetailWrapper>
       {
        <DoNotAssignButton
          onClick={()=> StopAutoAssign(data)}
          color="primary"
          autoFocus
          variant="contained"
          disabled={!forecastEdit}
        >
          {data.autoAssignHoliday ? <FormattedMessage {...messages.doNotAutoAssign} /> : <FormattedMessage {...messages.startNotAutoAssign} />}
        </DoNotAssignButton>
       }
      {/*{*/}
      {/*  showAutoStartButton &&*/}
      {/*  <DoAssignButton*/}
      {/*    onClick={() => AutoAssignHoliday(data)}*/}
      {/*    color="primary"*/}
      {/*    autoFocus*/}
      {/*    variant="contained"*/}
      {/*    disabled={!forecastEdit}*/}
      {/*  >*/}
      {/*    <FormattedMessage {...messages.NotAssignHoliday} />*/}
      {/*  </DoAssignButton>*/}
      {/*}*/}
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <Grid container spacing={1}>
            {renderField(
              messages.holidaysTableName,
              `holidaysTable[${data.id}].name`,
              ({ field, form }) => (
                <span style={{ whiteSpace: 'normal' }}  >{field.value}</span>
              ),
              true,
            )}
            {renderField(
              messages.holidaysTableLocalName,
              `holidaysTable[${data.id}].localName`,
              ({ field, form }) => (
                <span style={{ whiteSpace: 'normal' }}>{field.value}</span>
              ),
              true,
            )}
            {renderField(
              messages.holidaysTableDescription,
              `holidaysTable[${data.id}].description`,
              ({ field, form }) => (
                <span style={{ whiteSpace: 'normal', marginTop: '10px' }}>{field.value}</span>
              ),
              true,
            )}
            {renderField(
              messages.holidaysTableCategory,
              `holidaysTable[${data.id}].category`,
              ({ field, form }) => (
                <span>{field.value}</span>
              ),
              true,
            )}
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Grid container spacing={1}>
            {renderField(
              messages.holidaysTableDate,
              `holidaysTable[${data.id}].holidayDate`,
              ({ field, form }) => (
                <span>{formatDate(field.value)}</span>
              ),
              true,
            )}
            {renderField(
              messages.holidaysTableCountry,
              `holidaysTable[${data.id}].countryCode`,
              ({ field, form }) => (
                <span>{countries[field.value]?.name || field.value}</span>
              ),
              true,
            )}
            {renderField(
              messages.holidaysTableDistricts,
              `holidaysTable[${data.id}].districts`,
              ({ field, form }) => (
                <span>{field.value}</span>
              ),
              true,
            )}
            {renderField(
              messages.holidaysTableHolidayType,
              `holidaysTable[${data.id}].holidayType`,
              ({ field, form }) => (
                <span>{field.value}</span>
              ),
              true,
            )}
            {renderField(
              messages.holidaysTableSource,
              `holidaysTable[${data.id}].holidayType`,
              ({ field, form }) => (
                <span>{sourceSystemName(data)}</span>
              ),
              true,
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} />

        <Grid item xs={7}>
          <Grid container spacing={1}>
            {renderField(
              messages.holidaysTableOMSCodes,
              `holidaysTable[${data.id}].omsIds`,
              ({ field, form }) =>
                forecastEdit ? (
                  <Grid container spacing={1}>
                    <Grid item xs={7}>
                      <Select
                        name={field.name}
                        isMulti
                        onChange={options => {
                          form.setFieldValue(field.name, options?.map(option => option.value) || []);
                        }}
                        editable={forecastEdit}
                        isDisabled={!forecastEdit}
                        value={allOmsCodes.filter(option => field.value?.find(f => f === option.value))}
                        options={allOmsCodes}
                        isClearable={false}
                        closeMenuOnSelect={false}
                        styles={{
                          menuList: base => ({
                            ...base,
                            maxHeight: 150,
                          }),
                          multiValue: base => ({
                            ...base,
                            color: 'black',
                            backgroundColor: themeContext?.color?.yellow,
                          }),
                        }}
                      />
                      {/* {validateOMSCodes(form.values.holidaysTable[data.id], intl) && (
                        <InputError>{validateOMSCodes(form.values.holidaysTable[data.id], intl, false)}</InputError>
                      )} */}
                    </Grid>
                    <Grid item xs={5}>
                      <CompactButton
                        onClick={handleAddAllOMSCodes}
                        color="primary"
                        autoFocus
                        variant="contained"
                        disabled={!forecastEdit}
                      >
                        <FormattedMessage {...messages.eventsTableAddAllOMSCodes} />
                      </CompactButton>
                      <CompactButton
                        onClick={handleRemoveAllOMSCodes}
                        color="primary"
                        autoFocus
                        variant="contained"
                        disabled={!forecastEdit}
                      >
                        <FormattedMessage {...messages.eventsTableRemoveAllOMSCodes} />
                      </CompactButton>
                    </Grid>
                  </Grid>
                ) : (
                  allOmsCodes
                    .filter(option => field.value?.find(f => f === option.value))
                    .map(item => (
                      <div className={classes.values} key={item.value}>
                        {item.label}
                      </div>
                    ))
                ),
              false,
              12,
              12,
            )}
          </Grid>
        </Grid>
      </Grid>
    </DetailWrapper>
  );
};

export default injectIntl(formikConnect(withTheme(HolidaysTableDetailCellRenderer)));
