import { push } from 'connected-react-router';
import { DateTime } from 'luxon';
import { toast } from 'react-toastify';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import { api, formatDateToApiFormat, withUrl } from 'utils/api';

import { PATHS } from '../App/constants';
import { doLoadShiftSchedule } from '../ShiftScheduleDetailPage/saga';
import { convertArrayToMap } from '../ShiftScheduleDetailPage/utils';
import { loadShiftScheduleRuns, storeShiftScheduleRuns, storeSingleShiftScheduleRun, saveErrorDescriptions, storeScheduleStaffSyncState } from './actions';
import {
  ADD_SHIFT_SCHEDULE_RUN,
  CANCEL_SCHEDULE_RUN,
  COPY_SCHEDULE_RUN,
  DELETE_SHIFT_SCHEDULE_RUN,
  LOAD_SHIFT_SCHEDULE,
  LOAD_SHIFT_SCHEDULE_RUNS,
  PULL_SHIFT_SCHEDULE_RUN_CHANGES,
  RECALCULATE_SCHEDULE_RUN,
  SAVE_SHIFT_SCHEDULE_RUNS,
  SEND_SCHEDULE_RUN,
  LOAD_ERROR_DESCRIPTION,
  LOAD_SCHEDULE_STAFF_SYNC_STATE,
} from './constants';
import { selectLoadedRunsScheduleId, selectShiftScheduleRuns } from './selectors';
import { ShiftScheduleRunStatus } from './types';

export default function* defaultSaga() {
  yield takeLatest(LOAD_SHIFT_SCHEDULE, doLoadShiftSchedule);
  yield takeLatest(LOAD_SHIFT_SCHEDULE_RUNS, doLoadRuns);
  yield takeLatest(PULL_SHIFT_SCHEDULE_RUN_CHANGES, doPullRunChanges);
  yield takeLatest(SAVE_SHIFT_SCHEDULE_RUNS, updateRuns);
  yield takeLatest(ADD_SHIFT_SCHEDULE_RUN, addRun);
  yield takeLatest(DELETE_SHIFT_SCHEDULE_RUN, deleteRun);
  yield takeLatest(RECALCULATE_SCHEDULE_RUN, recalculateRun);
  yield takeLatest(SEND_SCHEDULE_RUN, sendScheduleRun);
  yield takeLatest(COPY_SCHEDULE_RUN, copyScheduleRun);
  yield takeLatest(CANCEL_SCHEDULE_RUN, cancelScheduleRun);
  yield takeLatest(LOAD_ERROR_DESCRIPTION, loadErrorDescriptionsSaga)
  yield takeLatest(LOAD_SCHEDULE_STAFF_SYNC_STATE, doLoadScheduleStaffSyncState);
}

function* recalculateRun(action) {
  const url = `/schedules/${action.payload.scheduleId}/runs/${action.payload.runId}/calculate`;
  const result = yield call(api, withUrl(url).post());
  if (result.isOk) {
    const run = result.data;
    yield put(storeSingleShiftScheduleRun({ ...run, status: new ShiftScheduleRunStatus(run.status) }));
  }
}

function* sendScheduleRun(action) {
  const url = `/schedules/${action.payload.scheduleId}/runs/${action.payload.runId}/send`;
  const result = yield call(api, withUrl(url).post());
  if (result.isOk && result.data.isOk) {
    toast.info('Request to engine sent.');
  } else {
    toast.error(`Engine call failed. ${result?.data?.message || ''}`);
  }
  yield put(loadShiftScheduleRuns(action.payload.scheduleId)); // reload to refresh sent flag, SMP-692
}

function* copyScheduleRun(action) {
  const { shiftScheduleRun, scheduleId } = action.payload;
  const url = `/schedules/${scheduleId}/runs/copy`;
  const result = yield call(
    api,
    withUrl(url).post({
      ...shiftScheduleRun,
      endDate: formatDateToApiFormat(shiftScheduleRun.endDate),
      startDate: formatDateToApiFormat(shiftScheduleRun.startDate),
    }),
  );
  if (result.isOk && result.data.runs) {
    toast.info('Schedule run copied.');
    yield put(loadShiftScheduleRuns(scheduleId));
    yield put(
      push(
        `${PATHS.shiftScheduleRunDetailId
          .replace(':id', result.data.runs[0].id)
          .replace(':scheduleId', scheduleId.toString())}`,
      ),
    );
  } else {
    toast.error(`Failed to copy run. ${result?.data?.message || ''}`);
  }
}

function* cancelScheduleRun(action) {
  const url = `/schedules/runs/${action.payload.runId}/steps/fe`;
  const params = {
    scheduleRunId: action.payload.runId,
    status: 'FAILED',
    subject: 'FAILED',
    message: 'canceled by button',
  };
  const result = yield call(api, withUrl(url).post(params));
  if (result.isOk && result.data.isOk) {
    toast.info('Run canceled.');
  } else {
    toast.error(`Run cancel failed. ${result?.data?.message || ''}`);
  }
  yield put(loadShiftScheduleRuns(action.payload.scheduleId)); // reload to refresh sent flag, SMP-692
}

function* addRun(action) {
  const url = `/schedules/${action.payload.scheduleId}/runs`;
  const result = yield call(api, withUrl(url).post(action.payload));
  if (result.isOk) {
    yield put(loadShiftScheduleRuns(action.payload.scheduleId));
  }
}

function* updateRuns(action) {
  const url = `/schedules/${action.payload.scheduleId}/runs/saveAll`;
  const result = yield call(api, withUrl(url).post(action.payload.runs));
  if (result.isOk) {
    toast.info(`Schedule Runs updated`);
    yield mapAndSetRuns(result, Number(action.payload.scheduleId));
  }
}

function* deleteRun(action) {
  const url = `/schedules/${action.payload.scheduleId}/runs/${action.payload.id}/delete`;
  const result = yield call(api, withUrl(url).post());
  yield mapAndSetRuns(result, Number(action.payload.scheduleId));
}

function* doLoadRuns(action) {
  const shiftScheduleId = Number(action.payload);
  const url = `/schedules/${shiftScheduleId}/runs`;

  // clear the cache if schedule id is different
  const lastLoadedScheduleId = yield select(selectLoadedRunsScheduleId);
  if (lastLoadedScheduleId !== shiftScheduleId) {
    yield put(storeShiftScheduleRuns(undefined));
  }

  const result = yield call(api, withUrl(url));
  yield mapAndSetRuns(result, shiftScheduleId);
}

const replaceSpecials = str => (str ? str.replaceAll('\\n', '\n').replaceAll('\\t', '\t') : str);

const mapRunStep = s => ({
  ...s,
  message: replaceSpecials(s.message),
  technicalMessage: replaceSpecials(s.technicalMessage),
  created: DateTime.fromISO(s.created),
});

function* doPullRunChanges(action) {
  const runIds = (action.payload.runIds || []).join(',');
  const url = `/schedules/${action.payload.scheduleId}/runs?runIds=${runIds}`;
  const currentRuns = yield select(selectShiftScheduleRuns);
  const currentRunsMap = currentRuns ? convertArrayToMap(currentRuns) : new Map();
  const result = yield call(api, withUrl(url));
  if (result.isOk) {
    // eslint-disable-next-line no-restricted-syntax
    for (const run of result.data.runs) {
      const statusChanged = run.status !== 'RUNNING';
      let stepsChanged = false;
      if (statusChanged) {
        if (run.status === 'CALCULATED') {
          toast.info(`Schedule Run "${run.name}" calculated`);
        } else if (run.status === 'FAILED') {
          toast.warn(`Schedule Run "${run.name}" failed`);
        }
      } else {
        const currentNumberOfSteps = currentRunsMap.get(run.id)?.steps?.length || 0;
        const newSteps = run.steps?.length || 0;
        stepsChanged = currentNumberOfSteps !== newSteps;
      }
      if (statusChanged || stepsChanged) {
        yield put(
          storeSingleShiftScheduleRun({
            ...run,
            status: new ShiftScheduleRunStatus(run.status),
            steps: run.steps.map(mapRunStep).sort((a, b) => b.created.toMillis() - a.created.toMillis()),
          }),
        );
      }
    }
  }
}

function* mapAndSetRuns(result, shiftScheduleId: number) {
  if (result.isOk) {
    yield put(
      storeShiftScheduleRuns({
        runs: result.data.runs.map(r => ({
          ...r,
          status: new ShiftScheduleRunStatus(r.status),
          steps: r.steps.map(mapRunStep).sort((a, b) => b.created.toMillis() - a.created.toMillis()),
          scheduleId: result.data.scheduleId,
          scheduleName: result.data.scheduleName,
        })),
        shiftScheduleId,
      }),
    );
  }
}

function* loadErrorDescriptionsSaga(){
  const url = `/scheduleRunErrors`;
  const result = yield call(api, withUrl(url));
  if(result.isOk){
    yield put(saveErrorDescriptions(result.data))
  }
}

function* doLoadScheduleStaffSyncState(action) {
  const response = yield call(api, withUrl(`/schedules/${action.payload}/staff/state`).andTitle('Loading staff sync state'));
  if (response.isOk) {
    yield put(storeScheduleStaffSyncState(response.data));
  }
}