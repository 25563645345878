import React, { useEffect, useState } from 'react';
import { ColDef } from 'ag-grid-community';
import { countries } from 'country-data';
import { FormikProps } from 'formik';
import { InjectedIntl, injectIntl } from 'react-intl';
import styled from 'styled-components';

import AgTable from 'components/Table';
import { dateCellFormater, dateComparator } from 'utils/dateTime';
import { useEffectDeepCompare } from 'utils/utils';
import { ApiSmartProdSourceDTO } from 'types/drep-backend';

import HolidaysTableDetailCellRenderer from './HolidaysTableDetailCellRenderer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import messages from './messages';
import {EntityEntry, SmartProdSourceForm, Holiday, SmartProdSourceEntityEntry, IdAndCode} from './types';

const Table = styled(AgTable)`
  margin-top: 12px;
  height: ${props => props.height}px;

  .ag-full-width-row {
    overflow: visible;
  }

  .highlight-row {
    background-color: #D9D9D9 !important;
  }
`;

const columnDefs = (formik, editable: boolean, allSmartProdSources: EntityEntry[], intl: InjectedIntl, allOmsCodes, holidayType) => {
  const columns: ColDef[] = [
    {
      colId: 'name',
      field: 'name',
      filter: 'setFilter',
      width: 330,
      menuTabs: ['filterMenuTab'],
      headerName: intl.formatMessage(messages.holidaysTableName),
      cellRenderer: 'agGroupCellRenderer',
      sortable: true,
      resizable: true,
    },
    {
      colId: 'localName',
      field: 'localName',
      filter: 'setFilter',
      menuTabs: ['filterMenuTab'],
      headerName: intl.formatMessage(messages.holidaysTableLocalName),
      sortable: true,
      resizable: true,
    },
    {
      colId: 'description',
      field: 'description',
      width: 400,
      filter: 'setFilter',
      menuTabs: ['filterMenuTab'],
      headerName: intl.formatMessage(messages.holidaysTableDescription),
      sortable: true,
      resizable: true,
    },
    {
      colId: 'countryCode',
      field: 'countryCode',
      filter: 'setFilter',
      menuTabs: ['filterMenuTab'],
      headerName: intl.formatMessage(messages.holidaysTableCountry),
      valueFormatter: ({ value: countryCode }) => countries[countryCode]?.name || countryCode,
      sortable: true,
      resizable: true,
    },
    {
      colId: 'holidayDate',
      field: 'holidayDate',
      menuTabs: ['filterMenuTab'],
      headerName: intl.formatMessage(messages.holidaysTableDate),
      filter: 'agDateColumnFilter',
      filterParams: {
        filterOptions: ['lessThan', 'greaterThan', 'inRange'],
        comparator: dateComparator,
      },
      sortable: true,
      valueFormatter: dateCellFormater,
      resizable: true,
    },
  ];
  if (holidayType == 'assigned') {
    columns.push({
      colId: 'omsIds',
      field: 'omsIds',
      filter: 'setFilter',
      menuTabs: ['filterMenuTab'],
      headerName: intl.formatMessage(messages.holidaysTableAllOMSCodes),
      valueFormatter: ({ value: omsIds }) => omsIds?.length == allOmsCodes.length ? 'Yes' : 'No',
      sortable: true,
      resizable: true
    })
  }
    columns.push(
        {
          headerName: intl.formatMessage(messages.NotAssignHoliday),
          field: 'autoAssignHoliday',
          colId: 'autoAssignHoliday',
          cellRendererFramework: params => {
            if(params.data.autoAssignHoliday){
              return <FontAwesomeIcon size="lg" icon="check" />
            }else if(!params.data.autoAssignHoliday){
              return <FontAwesomeIcon size="lg" icon='times' />
            }else{
              return null;
            }
          },
          cellRendererParams: { editable: false},
          sortable: false,
          resizable: true
        },
    )
  return columns;
};

type Props = {
  entity?:ApiSmartProdSourceDTO,
  formik?: FormikProps<SmartProdSourceForm>;
  intl: InjectedIntl;
  forecastEdit: boolean;
  holidaysData: Holiday[];
  planFacilities: IdAndCode[];
  allOmsCodes: EntityEntry[];
  allSmartProdSources: SmartProdSourceEntityEntry[];
  holidayType: String;
  smartProdSources: any;
  handleStopAutoAssign: any;
  handleAutoAssign: any;
};

const HolidaysTable: React.FC<Props> = ({
  entity,
  formik,
  intl,
  forecastEdit,
  holidaysData,
  planFacilities,
  allOmsCodes,
  allSmartProdSources,
  holidayType,
  smartProdSources,
  handleStopAutoAssign, handleAutoAssign
}) => {
  const [expandedDetail, setExpandedDetail] = useState(false);
  const [holidayOpenedDetail, setOpenedHolidayDetail] = useState(null);
  const [gridApi, setGridApi] = useState(null);

  const onGridReady = params => {
    setGridApi(params);
    params.api.sizeColumnsToFit();
  };

  const handleRowClicked = params => {
    if (!params.node.detail && params.column.colId !== 'actions') {
      params.node.setExpanded(!params.node.expanded);
      gridApi.api.onGroupExpandedOrCollapsed();
    }
  };

  const onRowGroupToggle = params => {
    let isSomeNodeExpanded = params.node.expanded;
    if (params.node.expanded) {
      setOpenedHolidayDetail(params.data.id);
      gridApi.api.forEachNode(node => {
        if (node.expanded && node.id !== params.node.id) {
          node.setExpanded(false);
        }
      });
    } else {
      gridApi.api.forEachNode(node => {
        isSomeNodeExpanded = isSomeNodeExpanded || node.expanded;
      });
    }
    setExpandedDetail(isSomeNodeExpanded);
    if (!isSomeNodeExpanded) {
      setOpenedHolidayDetail(null);
    }
  };

  const onFirstDataRendered = params => {
    params.api.forEachNode(node => {
      if (node.data.id === holidayOpenedDetail) {
        node.setExpanded(true);
      }
    });
  };

  useEffectDeepCompare(() => {
    if (gridApi) {
      const colDefs = columnDefs(formik, forecastEdit, allSmartProdSources, intl,allOmsCodes,holidayType);
      gridApi.api.setColumnDefs(colDefs);
      gridApi.api.sizeColumnsToFit();
      gridApi.api.forEachNode(node => {
        node.setExpanded(node.data.id === holidayOpenedDetail);
      });
    }
  }, [gridApi, forecastEdit, holidaysData]);

  const DETAIL_ROW_HEIGHT = 520;
  const ROW_HEIGHT = 30;

  const HEIGHT = holidaysData.length === 0 ?
  100 + ROW_HEIGHT * Math.min(10, holidaysData.length):
  100 + ROW_HEIGHT * Math.min(10, holidaysData.length) + (holidayOpenedDetail ? DETAIL_ROW_HEIGHT : 0)

  const getRowClass = params => {
    const result = [];
    if (!params.data) {
      return '';
    }
    if (!params.data.autoAssignHoliday) {
      result.push('highlight-row');
    }
    return result.join(' ');
  };

  useEffect(()=>{
    setOpenedHolidayDetail(null);
  },[holidaysData.length])

  return (
    allOmsCodes && (
      <Table
        masterDetail
        detailCellRenderer="holidaysTableDetailCellRenderer"
        detailCellRendererParams={{
          forecastEdit,
          allOmsCodes,
          planFacilities,
          allSmartProdSources,
          entity,
          holidayType,
          smartProdSources,
          handleStopAutoAssign,
          handleAutoAssign,
        }}
        frameworkComponents={{
          holidaysTableDetailCellRenderer: HolidaysTableDetailCellRenderer,
        }}
        defaultColDef={{
          flex: 1,
        }}
        sortable
        pagination={false}
        columnDefs={columnDefs(formik, forecastEdit, allSmartProdSources, intl,allOmsCodes, holidayType)}
        height={HEIGHT}
        rowData={holidaysData}
        rowHeight={ROW_HEIGHT}
        detailRowHeight={DETAIL_ROW_HEIGHT}
        onGridReady={onGridReady}
        onRowGroupOpened={onRowGroupToggle}
        onFirstDataRendered={onFirstDataRendered}
        isRowSelectable={() => false}
        onCellClicked={handleRowClicked}
        //getRowClass={holidayType === "unassigned" ? getRowClass : ''}
      />
    )
  );
};

export default injectIntl(HolidaysTable);
