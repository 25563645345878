// @flow
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type Props = {
  dataFor: string,
  className?: string,
};

const style = {
  color: '#337AB7',
  fontWeight: 'bold',
  marginLeft: '3px',
};

export default function HelpPoint(props: Props) {
  const { className = '', dataFor } = props;
  return (
    <span className={className} style={style} data-tip data-for={dataFor}>
      <FontAwesomeIcon icon="question-circle" size="sm" />
    </span>
  );
}
