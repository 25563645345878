import React from 'react';
import { saveAs } from 'file-saver';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import { api, withUrl } from 'utils/api';

import { fetchUploadsAction, storeUploadInfoAction, storeUploadsAction, closeUploadModal } from './actions';
import { DOWNLOAD_FROM_HISTORY, EXPORT_FILE, FETCH_UPLOADS, UPLOAD_FILE, UPLOAD_FILE_ADMIN } from './constants';
import messages from './messages';
import { makeSelectUploadsExpanded } from './selectors';
var uploadCount = 0;
var exportCount = 0;

// Individual exports for testing
export default function* defaultSaga() {
  yield takeLatest(UPLOAD_FILE_ADMIN, doUploadAdminFile);
  yield takeLatest(FETCH_UPLOADS, doFetchUploads);
  yield takeLatest(UPLOAD_FILE, doUploadFile);
  yield takeLatest(EXPORT_FILE, doExportFile);
  yield takeLatest(DOWNLOAD_FROM_HISTORY, doDownloadFromHistory);
}

// in API calls result upload is also plan, but in GUI it's better to keep it separated
const apiEntity = entity => (entity === 'results' ? 'plans' : entity);

function* doFetchUploads(action) {
  const response = yield call(api, withUrl(`/${apiEntity(action.payload)}/upload`));
  return response.isOk ? yield put(storeUploadsAction(response.data)) : null;
}

function* doUploadFile(action) {
  const { entity, files, id } = action.payload;
  yield put(storeUploadInfoAction({ uploading: true }));
  try {
    uploadCount = uploadCount + 1 ;
    if(uploadCount == 1){
      const formData = new FormData();
    const file = files[0];
    formData.append('file', file);
    const response = yield call(
      api,
      withUrl(`/${apiEntity(entity)}/upload/?entityId=${id}`)
        .put()
        .andContentType(null) // let it set automatically by FormData
        .andFile(formData),
    );
    if (response.isOk) {
      toast(<FormattedMessage {...messages.fileUploaded} />);
      yield call(doFetchUploads, fetchUploadsAction(entity));
    }
    uploadCount = 0;
    }
  } catch (e) {
    uploadCount = 0;
    console.error('Upload error', e);
  }
  yield put(storeUploadInfoAction({ uploading: false }));
  uploadCount = 0;
}

function* doUploadAdminFile(action) {
   const { start, file, end } = action.payload;
  try {
    const formData = new FormData();
    formData.append('file', file[0]);
    formData.append('startDate', start);
    formData.append('endDate', end);
    const response = yield call(
      api,
      withUrl(`/smartVolume/runs/byUser`)
        .post()
        .andContentType(null) // let it set automatically by FormData
        .andFile(formData)
        .asRawResponse(),
    );
    if (response.ok) {
      toast(<FormattedMessage {...messages.fileUploaded} />);
    }
  } catch (e) {
    console.error('Upload error', e);
  }
  yield put(closeUploadModal());
}

function* doExportFile(action) {
  exportCount = exportCount + 1;
 if(exportCount == 1){
  const { entity, id, sections } = action.payload;
  const rawResponse = yield call(
    api,
    withUrl(`/${apiEntity(entity)}/${id}/export?sections=${(sections || []).join(',')}`).asRawResponse(),
  );
  if (rawResponse) {
    const blob = yield rawResponse.blob();
    saveAs(blob, 'export.xlsx');
  }
 }
 exportCount = 0;
}

function* doDownloadFromHistory(action) {
  const { entity, id, fileName } = action.payload;
  try {
    const rawResponse = yield call(api, withUrl(`/${apiEntity(entity)}/upload/${id}/all`).asRawResponse());
    if (rawResponse && rawResponse.status === 200) {
      const blob = yield rawResponse.blob();
      saveAs(blob, fileName);
    } else {
      toast.error((rawResponse.messages && rawResponse.messages) || `Download error`);
    }
  } catch (e) {
    toast.error(`File is not ready for download yet`);
  }
}
