/**
 *
 * BasePage
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import SideBar from '../SideBar';
import TopBar from '../TopBar';
import Breadcrumbs from './Breadcrumbs';

const PageWrap = styled.div`
  height: 100%;
  background-color: ${props => props.theme.color.greyPageBg};
`;

const ContentWrap = styled.div`
  padding: 5px;
  flex-grow: 1;
  position: relative;
  margin-left: 10px;
  max-width: inherit;
  min-width: 600px;
  display: flex;
  flex-direction: column;
`;

const RightWrap = styled.div`
  min-height: 100vh;
  margin-left: 50px;
  display: flex;
  flex-direction: column;
`;

const WrapButton = styled.div`
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: flex-end;
  padding: 10px 10px 0px 10px;
  margin-left: 25px;
`;

type Props = {
  children: React.Node,
  labelMessage: Object,
  labelValues: Object,
  noMaxWidth: boolean,
};

function BasePage(props: Props) {
  const noMaxWidth = props.noMaxWidth ? { noMaxWidth: true } : {};
  return (
    <PageWrap className={props.className}>
      <SideBar />
      <RightWrap>
        <TopBar labelMessage={props.labelMessage} labelValues={props.labelValues} />
        <WrapButton><Breadcrumbs makeDirtyHandler={props?.makeDirtyHandler && props?.makeDirtyHandler()} createdFromMasterPlan={props?.createdFromMasterPlan} labelValues={props.labelValues?.name} scheduleName={props?.scheduleName}/></WrapButton>
        <ContentWrap {...noMaxWidth}>{props.children}</ContentWrap>
      </RightWrap>
    </PageWrap>
  );
}

BasePage.propTypes = {
  children: PropTypes.node.isRequired,
  labelMessage: PropTypes.object,
  labelValues: PropTypes.object,
  noMaxWidth: PropTypes.bool,
};

export default BasePage;
