import { defineMessages } from 'react-intl';

const scope = 'app.containers.PlanCalculationStatistics';
export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: 'Plan {name} - Calculation Statistics',
  },
  exportLabel: {
    id: `${scope}.exportLabel`,
    defaultMessage: 'Calculation Statistics - {name}',
  },
  content: {
    id: `${scope}.content`,
    defaultMessage: 'Plan Calculation Statistics',
  },
  basicInfo: {
    id: `${scope}.basicInfo`,
    defaultMessage: 'Basic Information',
  },
  calculationStatistics: {
    id: `${scope}.calculationStatistics`,
    defaultMessage: 'Calculation Statistics',
  },
  calculated: {
    id: `${scope}.calculated`,
    defaultMessage: 'Calculated',
  },
  calculatedBy: {
    id: `${scope}.calculatedBy`,
    defaultMessage: 'Calculated by',
  },
  granularity: {
    id: `${scope}.granularity`,
    defaultMessage: 'Granularity',
  },
  startDate: {
    id: `${scope}.startDate`,
    defaultMessage: 'Start Date',
  },
  endDate: {
    id: `${scope}.endDate`,
    defaultMessage: 'End Date',
  },
  week: {
    id: `${scope}.week`,
    defaultMessage: 'Week',
  },
  day: {
    id: `${scope}.day`,
    defaultMessage: 'Day',
  },
  wzp: {
    id: `${scope}.wzp`,
    defaultMessage: 'WZP',
  },
  hour: {
    id: `${scope}.hour`,
    defaultMessage: 'Hour',
  },
  month: {
    id: `${scope}.month`,
    defaultMessage: 'Month',
  },
});
