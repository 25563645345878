import Table from 'components/Table/index';
import * as React from 'react';
import { injectIntl } from 'react-intl';
import { compose } from 'redux';
import messages from '../messages';
import AbstractTransferCostsTable from './abstractTransferCostsTable';
import { getFacilityCostId, getInFacility } from './utils';

class DepartmentTransferCostsTable extends AbstractTransferCostsTable {
  private gridApi;

  public render() {
    const { formik } = this.props;

    super.beforeRender();

    const data = formik.values.departmentTransferCostsMap
      .listValues()
      .filter(c => !this.state.hideBlank || c.valueFrom > 0 || c.valueTo > 0)
      .map(c => ({
        ...c,
        parentDepartment: c,
        parentFacility: this.props.idToFacilityCost.get(getFacilityCostId(c)),
      }));

    this.refreshRowsIfNeeded(formik, data);
    const onGridReady = params => {
      this.gridApi = params.api;
    };

    return [
      this.hideBlankButton(),
      <Table
        key="table"
        onModelUpdated={params => params.columnApi.autoSizeAllColumns()}
        messages={messages}
        pagination={true}
        paginationPageSize={50}
        columnDefs={this.getColumnDefs()}
        rowData={data}
        domLayout="autoHeight"
        onGridReady={onGridReady}
      />,
    ];
  }

  protected getFormikPath(): string {
    return 'departmentTransferCostsMap';
  }

  private refreshRowsIfNeeded(formik, data) {
    if (this.gridApi) {
      if (!formik.values.lastUpdatedRow || !formik.values.lastUpdatedRow.data.departmentFrom) {
        // Refresh all rows on facility change
        this.gridApi.setRowData(data);
      } else if (!formik.values.lastUpdatedRow.data.smartShiftJobScheduleFrom) {
        // Refresh the changed row only
        this.gridApi.refreshCells({
          columns: ['totalFrom', 'totalTo'],
          enableCellChangeFlash: false,
          force: true,
          rowNodes: [formik.values.lastUpdatedRow],
        });
      }
    }
  }

  private getColumnDefs() {
    return [
      {
        children: [this.facilityFromColumn({ flex: 2 }), this.departmentFromColumn({ flex: 2 })],
        colId: 'from',
        headerName: this.props.intl.formatMessage(messages.transferFrom),
        flex: 4,
      },
      {
        children: [this.facilityToColumn({ flex: 2 }), this.departmentToColumn({ flex: 2 })],
        colId: 'to',
        headerName: this.props.intl.formatMessage(messages.transferTo),
        flex: 4,
      },
      {
        children: [
          {
            ...this.valueFromColumn(),
            editable: params => {
              return this.props.isEdit && !getInFacility('disabled', params.data, 'From');
            },
            flex: 1,
          },
          {
            ...this.valueToColumn(),
            editable: params => {
              return this.props.isEdit && !getInFacility('disabled', params.data, 'To');
            },
            flex: 1,
          },
        ],
        colId: 'values',
        headerName: this.props.intl.formatMessage(messages.values),
        flex: 2,
      },
      {
        children: this.totalsColumns(110),
        colId: 'totals',
        headerName: this.props.intl.formatMessage(messages.total),
        flex: 2,
      },
      this.statusColumn(),
      this.commentColumn(),
    ];
  }
}

export default compose(
  injectIntl,
  // @ts-ignore
)(DepartmentTransferCostsTable);
