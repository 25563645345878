import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  locationHeader: {
    defaultMessage: 'Unproductive Location (scheduling only)',
    id: 'app.components.UnproductiveActivities.header',
  },
  staffHeader: {
    defaultMessage: 'Unproductive Staff (scheduling only)',
    id: 'app.components.UnproductiveActivities.staffHeader',
  },
  action: {
    defaultMessage: 'Action',
    id: 'app.components.UnproductiveActivities.action',
  },
  department: {
    defaultMessage: 'Department',
    id: 'app.components.UnproductiveActivities.department',
  },
  allDepartments: {
    id: 'app.components.UnproductiveActivities.allDepartments',
    defaultMessage: 'All Departments',
  },
  activity: {
    id: 'app.components.UnproductiveActivities.activity',
    defaultMessage: 'Activity',
  },
  customer: {
    id: 'app.components.UnproductiveActivities.customer',
    defaultMessage: 'Customer',
  },
  all: {
    id: 'app.components.UnproductiveActivities.all',
    defaultMessage: 'All',
  },
  dialogDeleteText: {
    id: 'app.components.UnproductiveActivities.dialogDeleteText',
    defaultMessage: 'Are you sure this activity should be deleted?',
  },
  setStartTime: {
    defaultMessage: 'Set Start time',
    id: 'app.components.UnproductiveActivities.setStartTime',
  },
  setEndTime: {
    defaultMessage: 'Set End time',
    id: 'app.components.UnproductiveActivities.setEndTime',
  },
  startTime: {
    id: 'app.components.UnproductiveActivities.startTime',
    defaultMessage: 'Start Time',
  },
  endTime: {
    id: 'app.components.UnproductiveActivities.endTime',
    defaultMessage: 'End Time',
  },
  periodLength: {
    id: 'app.components.UnproductiveActivities.periodLength',
    defaultMessage: 'Period Length (days)',
  },
  startDate: {
    defaultMessage: 'Start Date',
    id: 'app.components.UnproductiveActivities.startDate',
  },
  endDate: {
    defaultMessage: 'End Date',
    id: 'app.components.UnproductiveActivities.endDate',
  },
  days: {
    defaultMessage: 'Days',
    id: 'app.components.UnproductiveActivities.days',
  },
  selectDays: {
    defaultMessage: 'Select days',
    id: 'app.components.UnproductiveActivities.selectDays',
  },
});
