/*
 * LabourAvailabilityCategoryTable Messages
 *
 * This contains all the text for the LabourAvailabilityCategoryTable component.
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  header: {
    id: 'app.components.LabourAvailabilityCategoryTable.header',
    defaultMessage: 'Labour availability',
  },
  activity: {
    id: 'app.components.LabourAvailabilityCategoryTable.activity',
    defaultMessage: 'Activity',
  },
  activityCustom: {
    id: 'app.components.LabourAvailabilityCategoryTable.activityCustom',
    defaultMessage: 'Activity (custom)',
  },
  absenteerism: {
    id: 'app.components.LabourAvailabilityCategoryTable.Absenteerism',
    defaultMessage: 'Absenteeism',
  },
  vacation: {
    id: 'app.components.LabourAvailabilityCategoryTable.vacation',
    defaultMessage: 'Vacation',
  },
  costPerHour: {
    id: 'app.components.LabourAvailabilityCategoryTable.costPerHour',
    defaultMessage: 'Cost per hour',
  },
  workHoursDay: {
    id: 'app.components.LabourAvailabilityCategoryTable.workHoursDay',
    defaultMessage: 'Work Hours/Day',
  },
  action: {
    id: 'app.components.LabourAvailabilityCategoryTable.action',
    defaultMessage: 'Action',
  },
  details: {
    id: 'app.components.LabourAvailabilityCategoryTable.details',
    defaultMessage: 'Details',
  },
  detailsButtons: {
    id: 'app.components.LabourAvailabilityCategoryTable.detailsButtons',
    defaultMessage: 'Details',
  },
  delete: {
    id: 'app.components.LabourAvailabilityCategoryTable.delete',
    defaultMessage: 'Delete',
  },
  addMHEButtonLabel: {
    id: 'app.components.LabourAvailabilityCategoryTable.addMHEButtonLabel',
    defaultMessage: 'MHE',
  },
  addMHESelect: {
    id: 'app.components.LabourAvailabilityCategoryTable.addMHESelect',
    defaultMessage: 'Select MHE:',
  },
  addMHEAddLine: {
    id: 'app.components.LabourAvailabilityCategoryTable.addMHEAddLine',
    defaultMessage: 'Add line',
  },
  addMHETimepercent: {
    id: 'app.components.LabourAvailabilityCategoryTable.addMHETimepercent',
    defaultMessage: 'Time percent',
  },
  transitionRate: {
    id: 'app.components.LabourAvailabilityCategoryTable.transitionRate',
    defaultMessage: 'Time percent',
  },
  addMHEType: {
    id: 'app.components.LabourAvailabilityCategoryTable.addMHEType',
    defaultMessage: 'MHE Type',
  },
  mheTypeName: {
    id: 'app.components.LabourAvailabilityCategoryTable.mheTypeName',
    defaultMessage: 'MHE Type',
  },
  addMHEName: {
    id: 'app.components.LabourAvailabilityCategoryTable.addMHEName',
    defaultMessage: 'Name',
  },
  name: {
    id: 'app.components.LabourAvailabilityCategoryTable.name',
    defaultMessage: 'Name',
  },
  productivity: {
    id: 'app.components.LabourAvailabilityCategoryTable.productivity',
    defaultMessage: 'Productivity',
  },
  dialogDeleteText: {
    id: 'app.components.LabourAvailabilityCategoryTable.dialogDeleteText',
    defaultMessage: 'Are you sure? You are deleting labour category {categoryName}',
  },
  dialogDeleteTextWorkerType: {
    id: 'app.components.LabourAvailabilityCategoryTable.dialogDeleteTextWorkerType',
    defaultMessage: 'Are you sure? You are deleting worker type {categoryName}',
  },
  category: {
    id: 'app.components.LabourAvailabilityCategoryTable.category',
    defaultMessage: 'Category',
  },
  workerType:{
    id: 'app.components.LabourAvailabilityCategoryTable.workerType',
    defaultMessage: 'Worker Type',
  }
});
