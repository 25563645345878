import { PlanningParameters } from 'containers/PlanResultPage/calculation/types';
import {
  ApiForecastConversionRateTableDTO,
  ApiForecastHolidayDTO,
  ApiHolidayDTO,
  ApiSmartProdRunDTO,
  ApiSmartProdRunScheduleDTO,
  ApiSmartProdSourceDTO,
} from 'types/drep-backend.d';

export type EntityEntry = { value: number; label: string };
export type SmartProdSourceEntityEntry = { value: number; label: string; smartProdSource: ApiSmartProdSourceDTO };
export type ForecastListEntityEntry = EntityEntry & {
  omsId: number;
  uom: string;
  customerId: number;
  departmentId: number;
};
export type ForecastConversionRateTableDTOWithId = ApiForecastConversionRateTableDTO & { id: number };

export type ForecastForm = {
  forecastConversionRates: ForecastConversionRateTableDTOWithId[];
  smartProdRuns: { [id: number]: ApiSmartProdRunDTO };
  smartProdScheduledRuns: { [id: number]: ApiSmartProdRunScheduleDTO };
  timeTransformationRuns: {[id: number]: ApiSmartProdRunDTO},
  planningParameters: PlanningParameters;
};

export type ForecastPerOMSCodeColumns = {
  effortSmartVolume: boolean;
  effortSmartVolumeWithEvent: boolean;
  effortSmartVolumeWithAdjustment: boolean;
  effortSmartProd: boolean;
  effortSmartProdWithEvent: boolean;
  effortSmartProdWithEventAndAdjustment: boolean;
  actualUPH: boolean;
  targetUPH: boolean;
  ratio: boolean;
  model: boolean;
};

export type Holiday = ApiForecastHolidayDTO & ApiHolidayDTO;

export const SmartProdRunEnum = {
  REQUEST: 'REQUEST',
  SCHEDULE: 'SCHEDULE',
};

export const TimeTransformationTableRunEnum = {
  YES: 'YES',
  NO: 'NO'
}

export type SmartProdRunsType = {
  requested: ApiSmartProdRunDTO[];
  scheduled: ApiSmartProdRunScheduleDTO[];
  timeTransformation: ApiSmartProdRunDTO[];
};

export enum SmartProdPeriodTypeEnum {
  SOURCE,
  FORECAST,
}
