import React from 'react';
import PropTypes from 'prop-types';
import { EditableListItemWrapper } from '../DetailPageShared';
import messages from './messages';
import Label from '../Label';

function SimpleCodeNameCdmItem(props) {
  return (
    <EditableListItemWrapper dark>
      <Label {...messages.code} />
      <div>{props.item && props.item.code}</div>
      <Label {...messages.name} />
      <div>{props.item && props.item.name}</div>
      {props.children}
    </EditableListItemWrapper>
  );
}

SimpleCodeNameCdmItem.propTypes = {
  item: PropTypes.object,
  children: PropTypes.node,
};

export default SimpleCodeNameCdmItem;
