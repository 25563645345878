/*
 *
 * PlanResultPage actions
 *
 */

import { createFormAction } from 'redux-form-saga';

import {
  FETCH_RESULTS,
  LOAD_TREND_GRAPH,
  STORE_RESULT_OPTIONS,
  STORE_RESULTS,
  OVERRIDE_EFFORT,
  OVERRIDE_PRODUCTION,
  OVERRIDE_VOLUME,
  EDIT_MODE_CHANGE,
  OVERRIDES_SAVED,
  CLEAN,
  CLEAN_CALCULATION,
  LOAD_PLAN,
  IS_EDITING,
  STORE_PLAN,
  OVERRIDES_DISCARD,
  SET_GRAPH_DATA_ACTIVITY,
  SET_GRAPH_DATA_ACTIVITY_FILTERED,
  SET_GRAPH_DATA_TREND,
  SET_GRAPH_DATA_TREND_FILTERED,
  LOAD_GRAPH_DATA_ACTIVITY,
  STORE_CALCULATED_TABLES,
  STORE_CALCULATED_TABLES_DEF,
  STORE_GRAPH_DATA_TREND,
  REMOUNT_TABLES,
  SET_GRAPH_DATA_DILO,
  SET_GRAPH_DATA_DILO_FILTERED,
  LOAD_GRAPH_DATA_DILO,
  CLEAN_TREND_DATA,
  TOGGLE_OVERRIDE_HELPER,
  FILTER_DATA_REQUEST,
  RESULT_CALCULATION_STARTED,
  STORE_VOLUME_CP,
  LOAD_PLAN_STATS,
  STORE_PLAN_STATS,
  STORE_HEADS_GRAPH_DATA,
  STORE_HEADS_GRAPH_FILTERED_DATA,
  COL_SETTINGS_CHANGE,
  MAPPING_OMS_CODES,
  TOGGLE_VOLUME_VALUE,
  TOGGLE_VOLUME_FORMULA,
  STORE_VOLUME_RAW,
  STORE_RESULT_VOLUME_RAW,
  STORE_MASTER_PLAN,
  STORE_PLAN_BASIC_DETAILS,
  STORE_CALCULATED_RESULT_TABLES
} from './constants';

export function storeVolumeCP(payload) {
  return {
    type: STORE_VOLUME_CP,
    payload,
  };
}

export function storeVolumeRaw(payload) {
  return {
    type: STORE_VOLUME_RAW,
    payload,
  };
}

export function storeResultVolumeRaw(payload){
  return {
    type: STORE_RESULT_VOLUME_RAW,
    payload,
  };
}

export function calculationStarted() {
  return {
    type: RESULT_CALCULATION_STARTED,
  };
}

export function remountTablesAction() {
  return {
    type: REMOUNT_TABLES,
  };
}

export function toggleOverrideHelperAction(isVisible) {
  return {
    type: TOGGLE_OVERRIDE_HELPER,
    payload: isVisible,
  };
}

export function loadDiloGraphDataAction(payload) {
  return {
    type: LOAD_GRAPH_DATA_DILO,
    payload,
  };
}

export function storeGraphDataTrendAction(payload) {
  return {
    type: STORE_GRAPH_DATA_TREND,
    payload,
  };
}
export function storeCalcualtedTablesAction(payload) {
  return {
    type: STORE_CALCULATED_TABLES,
    payload,
  };
}
export function storeCalcualtedResultTablesAction(payload) {
  return {
    type: STORE_CALCULATED_RESULT_TABLES,
    payload,
  };
}
export function storeCalcualtedTablesDefAction(payload) {
  return {
    type: STORE_CALCULATED_TABLES_DEF,
    payload,
  };
}

export function loadActivityGraphDataAction(payload) {
  return {
    type: LOAD_GRAPH_DATA_ACTIVITY,
    payload,
  };
}
export function isEditingAction(payload) {
  return {
    type: IS_EDITING,
    payload,
  };
}

export function colSettingsChangeAction(filterName) {
  return {
    type: COL_SETTINGS_CHANGE,
    payload: {
      filterName,
    },
  };
}

export function setGraphDataTrendFilteredAction(payload) {
  return {
    type: SET_GRAPH_DATA_TREND_FILTERED,
    payload,
  };
}

export function setGraphDataActivityFilteredAction(payload) {
  return {
    type: SET_GRAPH_DATA_ACTIVITY_FILTERED,
    payload,
  };
}
export function setGraphDataDiloFilteredAction(payload) {
  return {
    type: SET_GRAPH_DATA_DILO_FILTERED,
    payload,
  };
}

export function loadPlanAction(data, intl, graphPage = false) {
  return {
    type: LOAD_PLAN,
    payload: {
      data,
      intl,
      graphPage,
    },
  };
}

export function loadPlanStats(payload) {
  return {
    type: LOAD_PLAN_STATS,
    payload,
  };
}

export function storePlanStats(payload) {
  return {
    type: STORE_PLAN_STATS,
    payload,
  };
}

export function cleanAction(payload) {
  return {
    type: CLEAN,
    payload,
  };
}

export function cleanCalculationAction(payload) {
  return {
    type: CLEAN_CALCULATION,
    payload,
  };
}

export function overridesSavedAction(payload) {
  return {
    type: OVERRIDES_SAVED,
    payload,
  };
}

export function overrideEffortAction(payload) {
  return {
    type: OVERRIDE_EFFORT,
    payload,
  };
}

export function editModeChangeAction(payload) {
  return {
    type: EDIT_MODE_CHANGE,
    payload,
  };
}

export function overrideProductionAction(payload) {
  return {
    type: OVERRIDE_PRODUCTION,
    payload,
  };
}

export function overrideVolumeAction(payload) {
  return {
    type: OVERRIDE_VOLUME,
    payload,
  };
}

export function storeResultOptions(payload) {
  return {
    type: STORE_RESULT_OPTIONS,
    payload,
  };
}

export function loadTrendGraphDataAction(payload) {
  return {
    type: LOAD_TREND_GRAPH,
    payload,
  };
}

export function storeResults(payload) {
  return {
    type: STORE_RESULTS,
    payload,
  };
}

export function storeHeadsGraphData(payload) {
  return {
    type: STORE_HEADS_GRAPH_DATA,
    payload,
  };
}

export function storeHeadsGraphFilteredData(payload) {
  return {
    type: STORE_HEADS_GRAPH_FILTERED_DATA,
    payload,
  };
}

export function storePlanAction(payload) {
  return {
    type: STORE_PLAN,
    payload,
  };
}

export function storemasterPlanlanAction(payload){
  return {
    type: STORE_MASTER_PLAN,
    payload,
  };
}
export function overridesDiscardAction(payload) {
  return {
    type: OVERRIDES_DISCARD,
    payload,
  };
}
export function setGraphDataActivityAction(payload) {
  return {
    type: SET_GRAPH_DATA_ACTIVITY,
    payload,
  };
}
export function setGraphDataDiloAction(payload) {
  return {
    type: SET_GRAPH_DATA_DILO,
    payload,
  };
}
export function setGraphDataTrendAction(payload) {
  return {
    type: SET_GRAPH_DATA_TREND,
    payload,
  };
}

export function filterDataRequest(props, data) {
  return {
    type: FILTER_DATA_REQUEST,
    payload: {
      data,
      intl: props.intl,
    },
  };
}

export function cleanTrendGraphAction() {
  return {
    type: CLEAN_TREND_DATA,
  };
}

export function mappingOMSCodes(payload) {
  return {
    type: MAPPING_OMS_CODES,
    payload
  }
}

export function toggleVolumeValue(payload){
  return {
    type: TOGGLE_VOLUME_VALUE,
    payload
  }
}

export function toggleVolumeFormula(payload){
  return {
    type: TOGGLE_VOLUME_FORMULA,
    payload
  }
}

export function storePlanBasicDetails(payload){
  return {
    type: STORE_PLAN_BASIC_DETAILS,
    payload
  }
}