/**
 *
 * Schedule Dialog
 *
 */

import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import injectSaga from 'utils/injectSaga';
import saga from '../../containers/PlanDetailPage/saga';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { withStyles } from '@material-ui/core/styles';
import { DialogTitle } from '@material-ui/core';
import styled from 'styled-components';
import { withUrl } from 'utils/api';
import { getToken } from 'containers/App/selectors';
import { useSelector, useDispatch } from 'react-redux';
import { fetchData } from 'utils/reduxApi';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core';

const ConfirmButton = withStyles({
    root: {
        borderRadius: 0,
        border: 0,
        height: 35,
        padding: '0 25px',
        color: '#FFF',
        backgroundColor: '#D40511',
        '&:hover': {
            backgroundColor: '#D40511',
            color: '#FFF',
        },
    },
})(Button);

const CancelButton = withStyles({
    root: {
        borderRadius: 0,
        border: 0,
        height: 35,
        padding: '0 25px',
        color: '#000',
        backgroundColor: '#FFCC00',
        '&:hover': {
            backgroundColor: '#FFCC00',
            color: '#000',
        },
    },
})(Button);

export const materialTheme = () =>
    createMuiTheme({
        overrides: {
            MuiDialogPaper: {
                overflowY: 'scroll'
            },
            MuiDialogTitle: {
                root: {
                    paddingBottom: '5px'
                }
            },
            MuiRadio: {
                colorSecondary: {
                    '&.Mui-checked': { color: '#3a74ce !important' }
                }
            },
            MuiButton: {
                root: {
                    '&.Mui-disabled': { backgroundColor: 'rgb(153, 153, 153)' }
                }
            },
        },
    });

const InputName = styled.input`
     background-color: #F3F3F3;
     margin-left: 43px;
     margin-bottom: 10px;
     `
const InputDescription = styled.input`
     background-color: #F3F3F3;
     margin-left: 12px
     `

const Label = styled.label`
    font-weight: 700;
    font-size: 12px;
`

/* eslint-disable react/prefer-stateless-function */
const MasterPlanDialog = (props) => {
    const muiTheme = materialTheme();
    const token = useSelector(getToken);
    const [name, setName] = React.useState('');
    const [description, setDescription] = React.useState('');
    const dispatch = useDispatch();

    const handleConfirmDialog = () => {
        createSchedule();
    };

    const createSchedule = async () => {
        const facilities = (props.plan && props.plan.planningArea && props.plan.planningArea.facilities || []).map(f => f.id);
        const payload = {
            name: name,
            description: description,
            facilityIds: facilities,
            planId: props.plan.id
        }
        const response = await fetchData(withUrl(`/schedules/`).post(payload).andToken(token), dispatch);
        if (response.isOk) {
            props.redirectSchedule(response.data.id);
        }
        props.closecheduleDialog();
    };

    const handleCloseDialog = () => {
        props.closecheduleDialog();
    };

    const handleNameChange = (event) => {
        setName(event.target.value)
    };

    const handleDescriptionChange = (event) => {
        setDescription(event.target.value)
    };

    return (
        <MuiThemeProvider theme={muiTheme}>
            <Dialog
                open={props.scheduleDialog}
                onBackdropClick={props.closecheduleDialog}
            >
                <DialogTitle><b>Create Schedule</b></DialogTitle>
                <DialogContent>
                    <div>
                        <Label>Name</Label>
                        <InputName id="text" onChange={handleNameChange} type="text" />
                    </div>

                    <div>
                        <Label>Description</Label>
                        <InputDescription onChange={handleDescriptionChange} id="description" type="text" />
                    </div>
                </DialogContent>
                <DialogActions>
                    <ConfirmButton disabled={!name} onClick={handleConfirmDialog}>Confirm</ConfirmButton>
                    <CancelButton onClick={handleCloseDialog}>Cancel</CancelButton>
                </DialogActions>
            </Dialog>
        </MuiThemeProvider>
    );
}

const mapStateToProps = createStructuredSelector({
});

function mapDispatchToProps(dispatch) {
    return {
        ...bindActionCreators(
            {},
            dispatch,
        ),
        dispatch,
    };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withSaga = injectSaga({ key: `scheduleuploadModal`, saga });
export default compose(withConnect, withSaga)(MasterPlanDialog);
