/**
 *
 * ActivityTabs
 *
 */

import React from 'react';
import { findDOMNode } from 'react-dom';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { createStructuredSelector } from 'reselect';
import { connect as formikConnect } from 'formik';
import injectReducer from 'utils/injectReducer';
import { compose, bindActionCreators } from 'redux';
import Button from '../../components/Button';

import TabBody from './TabBody';
import FromToOrTitle from './FromToOrTitle';
import { selectEditFromplan, selectPeriodIndexFromPlan } from '../PlanDetailPage/selectors';
import {
  addPeriodPlanAction,
  copyPeriodPlanAction,
  deletePeriodPlanAction,
  setPeriodIndexPlanAction,
} from '../PlanDetailPage/actions';
import { ComponentWithDirtyCheck } from '../../components/ButtonWithDirtyCheck';
import { selectEditFromPa, selectPeriodIndexFromPa } from '../PlanningAreaDetailPage/selectors';
import {
  addPeriodPaAction,
  copyPeriodPaAction,
  deletePeriodPaAction,
  setPeriodIndexPaAction,
} from '../PlanningAreaDetailPage/actions';
import { formatDate } from '../../utils/dateTime';
import reducer from './reducer';
import { scrollInTabAction } from './actions';
import messages from './messages';
import { LeftButton, RightButton } from '../../components/IconButton';
import {
  selectShiftScheduleEdit,
  selectShiftScheduleGuiState,
  selectShiftScheduleIndex,
} from '../ShiftScheduleDetailPage/selectors';
import { addPlanToShiftSchedule, storePeriodIndexShiftScheduleAction } from '../ShiftScheduleDetailPage/actions';

const TabButton = styled.div`
  user-select: none;
  border-radius: 10px 10px 0px 0px;
  background-color: ${props => (props.isOpen ? props.theme.color.white : props.theme.color.greyPageBg)};
  ${props => props.isOpen && `z-index: 30`};
  font-weight: bold;
  white-space: nowrap;
  box-shadow: ${props => props.theme.shadow.pageBox};
  padding: 10px;
  position: relative;
  margin-right: 10px;
  :hover {
    cursor: ${props => !props.isOpen && 'pointer'};
    box-shadow: ${props => !props.isOpen && 'none'};
    background-color: ${props => !props.isOpen && props.theme.color.yellow};
  }
`;

const TablHidable = styled(TabButton)`
  visibility: ${props => (props.edit ? 'visible' : 'hidden')};
`;
const TabBar = styled.div`
  display: flex;
  height: 44px;
`;
const WrapBody = styled.div`
  position: relative;
  background-color: ${props => props.theme.color.white};
  box-shadow: ${props => props.theme.shadow.pageBoxTabs};
  padding: 10px;
`;

const Wrapper = styled.div`
  margin: 10px;
`;

const Scrollable = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  ${props =>
    props.showLeftStop &&
    css`
      :before {
        pointer-events: none;
        left: 0;
        z-index: 1;
        position: absolute;
        content: '';
        height: 100%;
        width: 150px;
        background: linear-gradient(to right, rgba(238, 238, 238, 1) 0%, rgba(238, 238, 238, 0) 100%);
        display: block;
      }
    `};
  ${props =>
    props.showRightStop &&
    css`
      :after {
        z-index: 1;
        pointer-events: none;
        right: 0;
        position: absolute;
        content: '';
        height: 100%;
        width: 50px;
        background: linear-gradient(to right, rgba(238, 238, 238, 0) 0%, rgba(238, 238, 238, 1) 100%);
        display: block;
      }
    `};
`;
const ScrollableInner = styled.div`
  transition: ${props => props.theme.transitions.normal};
  position: absolute;
  display: flex;
  left: ${props => props.hscroll}px;
  bottom: 0;
`;

const ButtonWrap = styled.div`
  display: flex;
  align-items: flex-end;
`;

/* eslint-disable react/prefer-stateless-function */
class Tabs extends React.PureComponent {
  tabBar = React.createRef();

  scrollInner = React.createRef();

  scrollOuter = React.createRef();

  openTab = React.createRef();

  state = { inTabs: false, hscroll: 0 };

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
    this.setState(state => ({ ...state, hscroll: this.openTab.current ? -this.openTab.current.offsetLeft : 0 }));
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  doWhenScroll = lastKnownScrollPosition => {
    const el = findDOMNode(this.tabBar.current); // eslint-disable-line
    const { offsetTop } = el;
    if (offsetTop + 60 < lastKnownScrollPosition && this.props.scrollInTabAction) {
      if (!this.state.inTabs) {
        this.props.scrollInTabAction(
          this.openPeriod && this.openPeriod.startDay
            ? `${formatDate(this.openPeriod.startDay)} - ${formatDate(this.openPeriod.endDay)}`
            : this.props.intl.formatMessage(messages.default),
        );
        this.setState({ inTabs: true });
      }
    } else if (this.state.inTabs) {
      this.props.scrollInTabAction(false);
      this.setState({ inTabs: false });
    }
  };

  ticking = false;

  animationFrame = lastKnownScrollPosition => () => {
    this.doWhenScroll(lastKnownScrollPosition);
    this.ticking = false;
  };

  handleScroll = () => {
    const lastKnownScrollPosition = window.scrollY;

    if (!this.ticking) {
      window.requestAnimationFrame(this.animationFrame(lastKnownScrollPosition));

      this.ticking = true;
    }
  };

  addHscroll = () => {
    this.setState(state => ({ ...state, hscroll: state.hscroll >= 0 ? 0 : state.hscroll + 100 }));
  };

  subHscroll = () => {
    const maxoffset = this.getMaxoffset();
    this.setState(state => ({ ...state, hscroll: state.hscroll <= maxoffset ? maxoffset : state.hscroll - 100 }));
  };

  getMaxoffset = () => {
    if (this.scrollInner.current && this.scrollOuter.current) {
      let distance = this.scrollOuter.current.offsetWidth - this.scrollInner.current.offsetWidth;
      distance = distance - (distance % 100) - 100;
      if (distance > 0) {
        return 0;
      }
      return distance;
    }
    return -500;
  };

  render() {
    const { addTabAction, edit, tabs, periodIndex, formik } = this.props;
    const tabsToRender = Array.isArray(tabs) ? tabs : tabs(formik);

    this.openPeriod = tabsToRender[periodIndex];
    // TODO check this.openPeriod = tabs && tabs.find(t => t.index === periodIndex);

    function addTabButtonWithDirtyCheck() {
      return (
        <ComponentWithDirtyCheck actionHandler={addTabAction}>
          {({ onClickHandler }) => (
            <TablHidable onClick={onClickHandler} edit={edit}>
              <FontAwesomeIcon icon="plus-circle" />
            </TablHidable>
          )}
        </ComponentWithDirtyCheck>
      );
    }

    function addTabButton() {
      return (
        <TablHidable onClick={() => addTabAction(formik)} edit={edit}>
          <FontAwesomeIcon icon="plus-circle" />
        </TablHidable>
      );
    }

    return (
      <Wrapper>
        <TabBar ref={this.tabBar}>
          <Scrollable
            ref={this.scrollOuter}
            showLeftStop={this.state.hscroll !== 0}
            showRightStop={this.state.hscroll !== this.getMaxoffset()}
          >
            <ScrollableInner hscroll={this.state.hscroll} ref={this.scrollInner}>
              {tabsToRender &&
                tabsToRender.map((tab, index) => (
                  <TabButton
                    key={tab.id || (tab.plan && tab.plan.id) || -1}
                    onClick={() => this.props.openTabAction(index)}
                    isOpen={index === periodIndex}
                    ref={index === periodIndex ? this.openTab : undefined}
                  >
                    {this.props.tabHeader(tab)}
                  </TabButton>
                ))}
              {this.props.isAddButtonWithDirtyCheck ? addTabButtonWithDirtyCheck.call(this) : addTabButton.call(this)}
            </ScrollableInner>
          </Scrollable>

          <ButtonWrap>
            <LeftButton disabled={this.state.hscroll === 0} onClick={this.addHscroll} />
            <RightButton disabled={this.state.hscroll === this.getMaxoffset()} onClick={this.subHscroll} />
          </ButtonWrap>
        </TabBar>
        <WrapBody>
          {this.props.isAddButtonWithDirtyCheck ? (
            <TabBody
              onDelete={this.props.removeTabAction}
              onCopy={this.props.copyTabAction}
              edit={edit}
              periodIndex={this.props.periodIndex}
              periodId={this.openPeriod ? this.openPeriod.id : -1}
            >
              {this.props.children}
            </TabBody>
          ) : (
            this.props.children
          )}
        </WrapBody>
      </Wrapper>
    );
  }
}

Tabs.propTypes = {
  tabHeader: PropTypes.func,
  tabs: PropTypes.any,

  // If true, button with dirty check will be used as"add new tab" button, otherwise a simple one
  isAddButtonWithDirtyCheck: PropTypes.bool,

  formik: PropTypes.object,
  intl: PropTypes.object,
  periodIndex: PropTypes.number,
  edit: PropTypes.bool,
  children: PropTypes.node,
  openTabAction: PropTypes.func,
  addTabAction: PropTypes.func,
  removeTabAction: PropTypes.func,
  scrollInTabAction: PropTypes.func,
  copyTabAction: PropTypes.func,
};

/* PLAN PAGE */

const mapPlanStateToProps = createStructuredSelector({
  edit: selectEditFromplan,
  periodIndex: selectPeriodIndexFromPlan,
  isAddButtonWithDirtyCheck: () => true,
  tabs: () => formik => formik.values.planningParameters.periods,
  tabHeader: () => period => <FromToOrTitle valueFrom={period.startDay} valueTo={period.endDay} />,
});

function mapPlanDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      scrollInTabAction,
      openTabAction: setPeriodIndexPlanAction,
      addTabAction: addPeriodPlanAction,
      removeTabAction: deletePeriodPlanAction,
      copyTabAction: copyPeriodPlanAction,
    },
    dispatch,
  );
}

const withPlanConnect = connect(
  mapPlanStateToProps,
  mapPlanDispatchToProps,
);

/* PLAN AREA PAGE */

const mapPaStateToProps = createStructuredSelector({
  edit: selectEditFromPa,
  periodIndex: selectPeriodIndexFromPa,
  isAddButtonWithDirtyCheck: () => true, // TODO rename?
  tabs: () => formik => formik.values.planningParameters.periods,
  tabHeader: () => period => <FromToOrTitle valueFrom={period.startDay} valueTo={period.endDay} />,
});

function mapPaDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      scrollInTabAction,
      openTabAction: setPeriodIndexPaAction,
      addTabAction: addPeriodPaAction,
      removeTabAction: deletePeriodPaAction,
      copyTabAction: copyPeriodPaAction,
    },
    dispatch,
  );
}

const withPaConnect = connect(
  mapPaStateToProps,
  mapPaDispatchToProps,
);

/* SHIFT SCHEDULE PAGE */

const mapShiftSchedulePlansStateToProps = createStructuredSelector({
  edit: selectShiftScheduleEdit,
  periodIndex: selectShiftScheduleIndex,
});

function mapShiftSchedulePlansDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      scrollInTabAction,
      openTabAction: storePeriodIndexShiftScheduleAction,
      removeTabAction: deletePeriodPaAction,
      copyTabAction: copyPeriodPaAction,
    },
    dispatch,
  );
}

const withShiftSchedulePlansConnect = connect(
  mapShiftSchedulePlansStateToProps,
  mapShiftSchedulePlansDispatchToProps,
);

const withReducer = injectReducer({ key: 'tabs', reducer });

export const PlanTabs = compose(
  withReducer,
  withPlanConnect,
  formikConnect,
  injectIntl,
)(Tabs);

export const PaTabs = compose(
  withReducer,
  withPaConnect,
  formikConnect,
  injectIntl,
)(Tabs);

export const ShiftSchedulePlanTabs = compose(
  withReducer,
  withShiftSchedulePlansConnect,
  formikConnect,
  injectIntl,
)(Tabs);
