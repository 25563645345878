export const ADD_SHIFT_SCHEDULE_RUN = 'ADD_SHIFT_SCHEDULE_RUN';
export const SAVE_SHIFT_SCHEDULE_RUNS = 'SAVE_SHIFT_SCHEDULE_RUNS';
export const DELETE_SHIFT_SCHEDULE_RUN = 'DELETE_SHIFT_SCHEDULE_RUN';
export const LOAD_SHIFT_SCHEDULE = 'app/ShiftScheduleDetailPage/LOAD';
export const LOAD_SHIFT_SCHEDULE_RUNS = 'LOAD_SHIFT_SCHEDULE_RUNS';
export const PULL_SHIFT_SCHEDULE_RUN_CHANGES = 'PULL_SHIFT_SCHEDULE_RUN_CHANGES';
export const STORE_SHIFT_SCHEDULE_RUNS = 'STORE_SHIFT_SCHEDULE_RUNS';
export const STORE_SHIFT_SCHEDULE_RUN = 'STORE_SHIFT_SCHEDULE_RUN';
export const RECALCULATE_SCHEDULE_RUN = 'RECALCULATE_SCHEDULE_RUN';
export const COPY_SCHEDULE_RUN = 'COPY_SCHEDULE_RUN';
export const SEND_SCHEDULE_RUN = 'SEND_SCHEDULE_RUN';
export const CANCEL_SCHEDULE_RUN = 'CANCEL_SCHEDULE_RUN';
export const LOAD_ERROR_DESCRIPTION = 'LOAD_ERROR_DESCRIPTION';
export const SAVE_ERROR_DESCRIPTIONS = 'SAVE_ERROR_DESCRIPTIONS';
export const LOAD_SCHEDULE_STAFF_SYNC_STATE = 'LOAD_SCHEDULE_STAFF_SYNC_STATE';
export const STORE_SCHEDULE_STAFF_SYNC_STATE = 'STORE_SCHEDULE_STAFF_SYNC_STATE';

export const granularityById = id => granularities.find(g => g.id === id);

export const granularities = [
  {
    id: 'HOUR',
    name: '1 H',
  },
  {
    id: 'QUARTER_OF_HOUR',
    name: '15 Min',
  },
];

export const parameterType = {
  EMPLOYEE_PREFERENCE_PENALTY: 'employee-preference-penalty',
  MAX_NUMBER_OF_JOBS_PER_SHIFT: 'max-number-of-jobs-per-shift',
  MINIMUM_JOB_SEGMENT_LENGTH: 'minimum-job-segment-length',
  WORKLOAD_OVER_COVERAGE_PENALTY: 'workload-over-coverage-penalty',
  WORKLOAD_UNDER_COVERAGE_PENALTY: 'workload-under-coverage-penalty',
};
