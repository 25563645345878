import React from 'react';
import ViewModeIcon from 'images/assets/view-mode.svg';
import { FormattedMessage } from 'react-intl';

import Button from 'components/Button';
import messages from 'components/PlanDetailToolbar/messages';

export default ({ handleClick }): React.FC => (
  <Button onClick={handleClick} tooltip={<FormattedMessage {...messages.viewMode} />} id="plan-detail-view-settings">
    <img alt="View Mode" src={ViewModeIcon} width="20px" height="20px" />
  </Button>
);
