/**
 *
 * Init
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose, bindActionCreators } from 'redux';
import { loadFromBSAction } from '../App/actions';
import { LOCAL_STORAGE } from '../App/constants';

/* eslint-disable react/prefer-stateless-function */
export class Init extends React.PureComponent {
  componentDidMount() {
    // this component loads regardless of page so we can make some global preparations
    LOCAL_STORAGE.forEach(payload => {
      this.props.loadFromBSAction(payload);
    });
  }

  render() {
    return null;
  }
}

Init.propTypes = {
  loadFromBSAction: PropTypes.func,
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ loadFromBSAction }, dispatch);
}

const withConnect = connect(
  null,
  mapDispatchToProps,
);

export default compose(withConnect)(Init);
