import { DateTime } from 'luxon';

const localStorageKey = 'startEndDateInputsForScheduleRuns';

type Entry = { startDate: DateTime; endDate: DateTime };

const getData = (): { [key: string]: Entry } => {
  const data = JSON.parse(sessionStorage.getItem(localStorageKey)) || {};
  Object.keys(data).forEach(scheduleId => {
    data[scheduleId].startDate = DateTime.fromISO(data[scheduleId].startDate);
    data[scheduleId].endDate = DateTime.fromISO(data[scheduleId].endDate);
  });
  return data;
};

const saveData = (data: { [key: string]: Entry }) => {
  sessionStorage.setItem(localStorageKey, JSON.stringify(data));
};

export const isStartEndDateEntryValid = (scheduleData: Entry): boolean => {
  if (scheduleData && scheduleData.startDate && scheduleData.endDate) {
    return (
      scheduleData.startDate.startOf('day') >= DateTime.local().startOf('day') &&
      scheduleData.endDate.startOf('day') >= DateTime.local().startOf('day')
    );
  }
  return false;
};

export const getStartEndDateInputCacheForScheduleId = (scheduleId: string): Entry | undefined => {
  const startEndDateInputsForScheduleId = getData();
  const scheduleData = startEndDateInputsForScheduleId?.[scheduleId];
  if (isStartEndDateEntryValid(scheduleData)) {
    return scheduleData;
  }
};

export const saveStartEndDateInputsForScheduleId = (
  scheduleId: string,
  startDate: DateTime,
  endDate: DateTime,
): void => {
  const startEndDateInputsForScheduleId = getData();
  startEndDateInputsForScheduleId[scheduleId] = { startDate, endDate };
  saveData(startEndDateInputsForScheduleId);
};
