import styled, { css } from 'styled-components';
import Color from 'color';

export const Wrap = styled.div`
  .react-calendar-timeline .rct-outer {
  }
  .react-calendar-timeline .item-wzp > .rct-item-content {
    display: none;
  }
  .react-calendar-timeline .rct-scroll{
   overflow-x: hidden !important;
  }
  .react-calendar-timeline .item-offday > .rct-item-content {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .item-shift{
    height: auto !important;
    line-height: 20px !important;
    top: 30% !important;
  }
  .react-calendar-timeline .rct-horizontal-lines .rct-hl-even {
    border-bottom: none;
  }
  .react-calendar-timeline .rct-horizontal-lines .rct-hl-even {
    background-color: ${props => props.theme.color.white};
  }
  .react-calendar-timeline .rct-horizontal-lines .rct-hl-odd {
    border-bottom: none;
  }
  .react-calendar-timeline .rct-horizontal-lines .rct-hl-odd {
    background-color: ${props => props.theme.color.white};
  }
  .react-calendar-timeline .rct-header-root {
    background-color: ${props => props.theme.color.yellow};
  }
  .react-calendar-timeline .rct-header .rct-label-group {
    background-color: ${props => props.theme.color.yellow};
  }

  .react-calendar-timeline .rct-vertical-lines .rct-vl.rct-day-6,
  .react-calendar-timeline .rct-vertical-lines .rct-vl.rct-day-0 {
    background: inherit;
  }

  ${props =>
    props.theme.wzpColor.map(
      (item, index) => css`
        .item-wzp-${index} {
          height: 100% !important;
          top: 0px !important;
          z-index: 30 !important;
          background-color: ${item} !important;
          border-color: ${item} !important;
          color: ${Color(item)
            .darken(0.5)
            .hex()} !important;
          font-weight: bold;
        }
      `,
    )};
`;
