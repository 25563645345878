import { takeLatest, call, put, select } from 'redux-saga/effects';
import { withUrl, api } from 'utils/api';
import { addLocaleData } from 'react-intl';
import moment from 'moment';
import {
  FETCH_MESSAGES,
  ADD_LOCALE,
  LOCALE_ADDED,
  FAILED_MESSAGES,
  FETCH_LANGUAGES,
  SAVE_MESSAGES,
  SAVE_LANGUAGES,
} from './constants';

const saveMesages = (data, locale) => ({
  type: SAVE_MESSAGES,
  data,
  locale,
});

const failedMesages = locale => ({
  type: FAILED_MESSAGES,
  locale,
});

const localeAdded = locale => ({
  type: LOCALE_ADDED,
  locale,
});

function* fetchMessages(action) {
  const response = yield call(api, withUrl(`/localization/${action.locale}`));
  if (response.isOk) {
    yield put(saveMesages(response.data, action.locale));
  } else {
    yield put(failedMesages(action.locale));
  }
}

function* addLocale(action) {
  try {
    const localeData = yield call(() => import(`react-intl/locale-data/${action.locale}`));
    addLocaleData(localeData.default);
    const momentLocale = (action.locale === 'en' && 'en-gb') || action.locale;
    const momentLocaleModule = yield call(() => import(`moment/locale/${momentLocale}`));
    moment.updateLocale(momentLocale, momentLocaleModule);
    yield put(localeAdded(action.locale));
  } catch (err) {
    console.error(`localization Error, failed to import ${action.locale} : ${err}`);
    yield put(localeAdded(action.locale));
  }
}

export default function* defaultSaga() {
  yield takeLatest(FETCH_MESSAGES, fetchMessages);
  yield takeLatest(ADD_LOCALE, addLocale);
}
