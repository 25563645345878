import flow from 'lodash/fp/flow';
import groupBy from 'lodash/fp/groupBy';
import values from 'lodash/fp/values';
import mapFp from 'lodash/fp/map';
import pickBy from 'lodash/pickBy';
import startsWith from 'lodash/startsWith';
import forEach from 'lodash/forEach';
import replace from 'lodash/replace';
import flatten from 'lodash/flatten';
import map from 'lodash/map';

export function copyDay({ headers, rowData }, { dayFrom, dayTo }) {
  const dayFromKey = dayFrom.toUpperCase();
  const dayToKey = dayTo.toUpperCase();
  // for all activities
  map(rowData, activity => {
    // get all records for fromDay
    const dayFromValues = pickBy(activity, (value, key) => startsWith(key, dayFromKey));

    // replace the day and store them under toDay
    forEach(dayFromValues, (value, key) => {
      const newKey = replace(key, dayFromKey, dayToKey);
      activity[newKey] = value;
    });
  });
  return { headers, rowData };
}

export const fixHeaders = headers =>
  flow(
    groupBy('label'),
    values,
    mapFp(items => {
      const result = flatten(map(items, item => item.children));
      return { ...items[0], children: result };
    }),
  )(headers);
