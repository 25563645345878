import { useEffect, useRef, EffectCallback } from 'react';
import { toast } from 'react-toastify';
import isEqual from 'lodash/isEqual';
import transform from 'lodash/transform';
import isObject from 'lodash/isObject';
import uniqBy from 'lodash/uniqBy';
import omit from 'lodash/omit';
import find from 'lodash/find';

export const uniqueBy = uniqBy;
export const omitKey = omit;
export const findByKey = find;

// formatters based by user settings
export const userLocale = new Intl.DateTimeFormat().resolvedOptions().locale;
export const numberFormat = new Intl.NumberFormat(userLocale).format;
export const dateFormat = new Intl.DateTimeFormat(userLocale).format;

export function toNumber(val) {
  if (val && typeof val === 'string') {
    let onlyDots = val.replace(',', '.');
    const countDots = onlyDots.match(/\./g) || [];
    countDots.forEach((item, index) => {
      // replace until we have just one to be able convert to number
      if (index > 0) onlyDots = onlyDots.replace('.', '');
    });
    return Number(onlyDots);
  }
  return val;
}

export function fieldsByRegexpToNumbers(value, pattern, defaultValue) {
  const rowConversion = row => {
    if (row) {
      const ret = {};
      Object.keys(row).forEach(key => {
        if (key.match(pattern)) {
          ret[key] = toNumber(row[key]);
          if (defaultValue !== undefined && Number.isNaN(ret[key])) {
            ret[key] = defaultValue;
          }
        } else {
          ret[key] = row[key];
        }
      });
      return ret;
    }
    return row;
  };
  return Array.isArray(value) ? value.map(rowConversion) : rowConversion(value);
}

export const alphaSortGetter = getter => (a, b) => alphaSort(getter(a), getter(b));

export const alphaSort = (a, b) => {
  const nameA = a.name.toUpperCase();
  const nameB = b.name.toUpperCase();
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }

  // names must be equal
  return 0;
};

export const percentageFormatter = value =>
  value !== undefined && value !== null ? `${numberFormat((toNumber(value) * 100).toFixed(2))}%` : '0';

export const percentageSplitFormatter = val => {
  const value = val?.value;
  return value !== undefined && value !== null ? `${numberFormat((toNumber(value) * 100).toFixed(2))}%` : '0';
};

export const percentageCellFormatter = params =>
  params.value ? `${numberFormat(toNumber(params.value).toFixed(2))}%` : '0%';
export const percentageCellFormatterNull = params =>
  params.value ? `${numberFormat(toNumber(params.value).toFixed(2))}%` : null;

export const currencyCellFormatter = params => params.value && `${numberFormat(toNumber(params.value))} EUR`;

export const numberCellFormatter = params => params.value && numberFormat(toNumber(params.value));

export function diffObjects(object, base) {
  function changes(o, b) {
    return transform(o, (result, value, key) => {
      if (!isEqual(value, b[key])) {
        // eslint-disable-next-line no-param-reassign
        result[key] = isObject(value) && isObject(b[key]) ? changes(value, b[key]) : value;
      }
    });
  }

  return changes(object, base);
}

export const forceCapitalize = string => string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();

export function numericSetter(params) {
  if (params && params.newValue) {
    const numeric = toNumber(params.newValue);
    if (Number.isNaN(numeric)) {
      // parse error
      console.log(`Can't convert '${params.newValue}' to number`);
    } else {
      params.data[params.colDef.field] = numeric;
      return true;
    }
  }
  return false; // value was not changed
}

export function numericSetterVolumes(params) {
  if(params.newValue === ""){
    params.data[params.colDef.field] = null;
    return true;
  }
  if (params && params.newValue) {
    const numeric = toNumber(params.newValue);
    if (Number.isNaN(numeric)) {
      // parse error
      console.log(`Can't convert '${params.newValue}' to number`);
    } else {
      params.data[params.colDef.field] = numeric;
      return true;
    }
  }
  return false; // value was not changed
}

export function numericSetterAdjustment(params) {
  if(params.newValue > 100){
    toast.error('Adjustment cannot be greater than 100')
    return false;
  }
  if(params.newValue < 0){
    toast.error('Adjustment cannot be less than 0')
    return false;
  }
  if (params && params.newValue) {
    const numeric = toNumber(params.newValue);
    if (Number.isNaN(numeric)) {
      // parse error
      console.log(`Can't convert '${params.newValue}' to number`);
    } else {
      params.data[params.colDef.field] = numeric;
      return true;
    }
  }
  return false; // value was not changed
}

// name with Regional Config Label
export function nameWithRCLabel(option) {
  if (!option) return '';
  const name = option.variable === true ? `${option.name} (${option.variableName})` : option.name;
  return `${name} / ${option.regionalConfigurationName}`;
}

// name with Regional Config Label for Labour Availability
export function lcnameWithRCLabel(option) {
  if (!option) return '';
  return `${option.labourCategory.name} / ${option.labourCategory.regionalConfigurationName}`;
}

export function pushToFormikArray(formik, pathToArray, objectToPush) {
  const index = (formik.values[pathToArray] || []).length;
  replaceFormikArrayAtIndex(formik, pathToArray, objectToPush, index);
}

/**
 * Replaces an element in formik values array at given [index]
 */
export function replaceFormikArrayAtIndex(formik, pathToArray, objectToPush, index) {
  formik.setFieldValue(`${pathToArray}[${index}]`, objectToPush);
}

/**
 * Remove elements from formik values array which match given [matcher]
 */
export function removeFromFormikArray(formik, pathToArray, matcher) {
  const newArray = (formik.values[pathToArray] || []).filter(candidate => !matcher.call(this, candidate));
  formik.setFieldValue(pathToArray, newArray);
}

/**
 * Get nested field value using dotted path. Dots must be used for accessing arrays at index as well (e.g. `myArray.0` instead of `myArray[0]`)
 */
export function getNestedField(object, pathToField) {
  let ref = object;
  pathToField.split('.').forEach(token => {
    if (ref) ref = ref[token];
  });
  return ref;
}

/**
 * Get array chunks.
 */
export const chunk = (longArray, chunkSize) => {
  const shortArrays = [];
  let i;
  let len;
  for (i = 0, len = longArray.length; i < len; i += chunkSize) {
    shortArrays.push(longArray.slice(i, i + chunkSize));
  }
  return shortArrays;
};

const useDeepCompareDataTransform = value => {
  const ref = useRef();
  if (!isEqual(value, ref.current)) {
    ref.current = value;
  }
  return ref.current;
};

export const useEffectDeepCompare = (callback: EffectCallback, dependencies: Array) => {
  useEffect(callback, dependencies.map(useDeepCompareDataTransform));
};
