import React, { useRef, useState } from 'react';
import { ColDef, ColGroupDef } from 'ag-grid-community';
import { connect as formikConnect, FormikProps } from 'formik';
import { DateTime } from 'luxon';
import { InjectedIntl, injectIntl } from 'react-intl';
import styled from 'styled-components';

import {
  EffortForecastModifySwitch,
  EffortForecastPlanSwitch,
  EffortForecastTableData,
  EffortRatioSwitch,
} from 'components/EffortForecast/types';
import { GroupWithToolTip } from 'components/NameWithToolTip';
import AgTable from 'components/Table';
import GroupFilter from 'components/Table/filter/GroupFilter';
import { EntityEntry, ForecastListEntityEntry } from 'containers/ForecastDetailPage/types';
import { PlanDetailData } from 'containers/PlanResultPage/calculation/types';
import { TableWrap } from 'containers/PlanResultPage/styled';
import { checksumPercentageFormatter, isInTolerance } from 'utils/checksum';
import { formatDate } from 'utils/dateTime';
import { numberFormat, toNumber, useEffectDeepCompare } from 'utils/utils';
import { ApiOmsCodeEffortPlanningParametersType } from 'types/drep-backend.d';

import messages from './messages';

const Table = styled(AgTable)`
  margin-top: 12px;
  height: ${props => props.height}px;

  .ag-overlay-no-rows-wrapper {
    padding-top: 90px;
  }

  .ag-header-cell-label .ag-header-cell-text {
    white-space: normal !important;
    line-height: 20px !important;
    text-overflow: initial !important;
    padding-top: 8px;
  }

  .ag-body-viewport div[col-id='activityForecastId'] {
    white-space: normal;
    line-height: 20px;
    overflow: auto;
  }

  .ag-rich-select-list {
    width: 360px;
  }

  .override-state-ovrd {
    font-weight: bold;
    color: ${props => props.theme.color.red};
  }
  .cell-editable.override-state-ovrd {
    font-weight: bold;
    color: ${props => props.theme.color.citrusOrange};
  }
`;

const columnDefs = (
  formik,
  editable,
  forecastPlanSwitch: EffortForecastPlanSwitch,
  effortRatioSwitch: EffortRatioSwitch,
  modifySwitch: EffortForecastModifySwitch,
  omsCodesById: { [value: number]: EntityEntry },
  activityForecastList: ForecastListEntityEntry[],
  effortForecast: EffortForecastTableData[],
  dates: DateTime[],
  intl: InjectedIntl,
) => {
  const defaultDataSource: ApiOmsCodeEffortPlanningParametersType = 'SMART_PROD';
  const smartVolumeDataSource: ApiOmsCodeEffortPlanningParametersType = 'SMART_VOLUME';
  const smartVolumeEventDataSource: ApiOmsCodeEffortPlanningParametersType = 'SMART_VOLUME_WITH_EVENT';
  const smartProdEventDataSource: ApiOmsCodeEffortPlanningParametersType = 'SMART_PROD_WITH_EVENT';
  const forecastSources = [
    { value: 'SMART_PROD', label: 'Planner Driven' },
    { value: 'SMART_VOLUME', label: 'SmartVolume' },
    { value: 'SMART_PROD_WITH_EVENT', label: 'Planner Driven One-Off Event' },
    { value: 'SMART_VOLUME_WITH_EVENT', label: 'SmartVolume One-Off Event' },
  ]

  const columns: (ColGroupDef | ColDef)[] = [
    {
      colId: 'omsCodeSmartProdSourceName',
      field: 'omsCodeSmartProdSourceName',
      filter: 'setFilter',
      headerName: intl.formatMessage(messages.tableOMSCode),
      cellRenderer: 'agGroupCellRenderer',
      sortable: true,
      pinned: 'left',
      width: 150,
      hide: true,
      suppressSizeToFit: true,
      rowGroup: true,
      resizable: true,
    },
    {
      colId: 'activityForecastName',
      field: 'activityForecastName',
      filter: 'setFilter',
      headerName: intl.formatMessage(messages.tableFacilityCustomerDepartmentActivity),
      cellRenderer: 'agGroupCellRenderer',
      checkboxSelection: false,
      sortable: true,
      pinned: 'left',
      width: 180,
      hide: true,
      suppressSizeToFit: true,
      rowGroup: false,
      resizable: true,
    },
    {
      colId: 'uomName',
      field: 'uomName',
      filter: 'setFilter',
      menuTabs: ['filterMenuTab', 'generalMenuTab', 'columnsMenuTab'],
      headerName: intl.formatMessage(messages.tableUnitOfMeasure),
      filterParams: {
        applyButton: true,
        clearButton: true,
      },
      sortable: true,
      pinned: 'left',
      width: 140,
      suppressSizeToFit: true,
      hide: false,
      resizable: true,
      rowGroup: false,
    },
    {
      colId: 'defaultType',
      field: 'defaultType',
      headerName: intl.formatMessage(messages.defaultType),
      editable: params => (params?.node?.field === "omsCodeSmartProdSourceName") ? editable : false,
      valueFormatter: params => {
        if (params?.node?.field === "omsCodeSmartProdSourceName") {
          const nodeValue = params?.node?.allLeafChildren[0]?.data?.defaultType;
          const activity = forecastSources.find(s => s.value === nodeValue);
          return activity ? activity.label : '';
        } else {
          return '';
        }
      },
      newValueHandler: params => {
        if(!params.newValue){
          return false;
        }
        let omsId;
        let defaultType;
        params.node.allLeafChildren.forEach(node => {
          if (node.data && node.data.omsId) {
            omsId = node.data.omsId;
            defaultType = node.data.defaultType;
          }
        });
        effortForecast?.forEach((f) => {
          if (f.omsId === omsId) {
            f?.omsDates?.forEach((oms, i) => {
              if (defaultType === undefined) {
                if(oms.dataSource === formik?.values?.planningParameters?.defaultForecastSource){
                oms.dataSource = params?.newValue?.value;
                oms.effortOverride = null;
                if( f && f.activityDates[i] && f.activityDates[i].effortOverride) f.activityDates[i].effortOverride =null;
                if( f && f.activityDates[i] && f.activityDates[i].ratioOverride)  f.activityDates[i].ratioOverride = null;
                }
              } else {
                if (f.defaultType === oms.dataSource) {
                  oms.dataSource = params?.newValue?.value;
                  oms.effortOverride = null;
                  if( f && f.activityDates[i] && f.activityDates[i].effortOverride) f.activityDates[i].effortOverride =null;
                if( f && f.activityDates[i] && f.activityDates[i].ratioOverride)  f.activityDates[i].ratioOverride = null;
                }
              }
            })
          }
        })
        params.node.allLeafChildren.forEach(node => {
          if (node.data && node.data.omsId) {
            node.data.defaultType = params?.newValue?.value;
          }
        });
        formik.setFieldValue(`effortForecast`, effortForecast);
        formik.setErrors({});
        params.api.refreshCells({ force: true });
        params.api.redrawRows();
        return true;
      },
      cellEditorParams: params => ({
        component: 'agRichSelect',
        values: forecastSources,
        cellRenderer: p => {
          return p?.value?.label || ''
        },
      }),
      pinned: 'left',
      width: 200,
      cellEditor: 'agRichSelectCellEditor',
      suppressSizeToFit: true,
      singleClickEdit: true,
    },
  ];

  const effortEditable = params => {
    if (params.node.group) {
      return editable && modifySwitch === 'modifyOmsCode' && forecastPlanSwitch === 'plan';
    }
    return editable && modifySwitch === 'modifyActivity' && forecastPlanSwitch === 'plan';
  };
  const ratioEditable = params => {
    if (params.node.group) {
      return false;
    }
    const isSingleActivity = params.node.parent.allLeafChildren.length === 1;
    return editable && modifySwitch === 'modifyOmsCode' && forecastPlanSwitch === 'plan' && !isSingleActivity;
  };

  const getEffort = (node, i) => {
    const omsDate = node.group
      ? node.allLeafChildren[0].data.omsDates[i]
      : node.parent.allLeafChildren[0].data.omsDates[i];

    if (node.group) {
      if (omsDate?.effortOverride !== null && omsDate?.effortOverride !== undefined && forecastPlanSwitch === 'plan') {
        return omsDate?.effortOverride;
      }
      if (omsDate?.dataSource === 'SMART_VOLUME') {
        return omsDate.effortSmartVolume;
      }
      if (omsDate?.dataSource === 'SMART_VOLUME_WITH_EVENT'){
        return omsDate.effortSmartVolumeWithEvent;
      }
      if (omsDate?.dataSource === 'SMART_PROD_WITH_EVENT'){
        return omsDate?.effortSmartProdWithEvent;
      }
      return omsDate?.effortSmartProd;
    }
    const activityDate = node.data.activityDates[i] || {};
    if (
      activityDate.effortOverride !== null &&
      activityDate.effortOverride !== undefined &&
      forecastPlanSwitch === 'plan'
    ) {
      return activityDate.effortOverride;
    }
    if (omsDate?.dataSource === 'SMART_VOLUME') {
      return activityDate.effortSmartVolume;
    }
    if (omsDate?.dataSource === 'SMART_VOLUME_WITH_EVENT'){
      return activityDate.effortSmartVolumeWithEvent;
    }
    if (omsDate?.dataSource === 'SMART_PROD_WITH_EVENT'){
      return activityDate.effortSmartProdWithEvent;
    }
    return activityDate?.effortSmartProd;
  };

  const getRatio = (node, i) => {
    if (node.group) {
      let sumOfRatios = 0;
      node.allLeafChildren.forEach(leafNode => {
        sumOfRatios += getRatio(leafNode, i) || 0;
      });
      return sumOfRatios;
    }
    const activityDate = node.data.activityDates[i] || {};
    if (
      activityDate.ratioOverride !== null &&
      activityDate.ratioOverride !== undefined &&
      forecastPlanSwitch === 'plan'
    ) {
      return activityDate.ratioOverride;
    }
    return activityDate.ratioSmartProd;
  };

  const getTooltip = (params, i) => {
    let omsDate;
    if (params.node.group) {
      omsDate = params.node.allLeafChildren[0].data.omsDates[i];
    } else {
      omsDate = params.node.parent.allLeafChildren[0].data.omsDates[i];
    }
    if (omsDate?.dataSource === smartVolumeDataSource) {
      return 'Smart Volume';
    }
    if (omsDate?.dataSource === defaultDataSource) {
      return 'Planner Driven';
    }
  };

  return [
    ...columns,
    ...dates.map((date, i) => ({
      colId: `date_${forecastPlanSwitch}`,
      headerName: intl.formatMessage(messages.tableDay, { day: formatDate(date) }),
      width: 180,
      aggFunc: 'sum',
      children: [
        ...(effortRatioSwitch.effort
          ? [
              {
                headerName: intl.formatMessage(messages.buttonEffortHour),
                valueGetter: params => getEffort(params.node, i),
                tooltipValueGetter: params => getTooltip(params, i),
                valueFormatter: params =>
                  // eslint-disable-next-line no-restricted-globals
                  params.value === null || params.value === undefined || params.value === ''
                    ? ''
                    : `${numberFormat(toNumber(params.value)?.toFixed(2))}`,
                newValueHandler: params => {
                  const editedCellValue = params.newValue === '' ? null : Number(params.newValue);
                  if (Number.isNaN(editedCellValue)) {
                    return false;
                  }
                  if(params.oldValue === editedCellValue){
                    return false;
                  }
                  if (params.node.group) {
                    params.node.allLeafChildren.forEach(node => {
                      node.data.omsDates[i].effortOverride = editedCellValue;
                    });
                    const groupRatio = getRatio(params.node, i) || 0;
                    params.node.allLeafChildren.forEach(node => {
                      const leafRatio = getRatio(node, i) || 0;
                      if (groupRatio === 0) {
                        node.data.activityDates[i].effortOverride =
                          (1.0 / params.node.allLeafChildren.length) * editedCellValue;
                        node.data.activityDates[i].ratioOverride = 100.0 / params.node.allLeafChildren.length;
                      } else {
                        node.data.activityDates[i].effortOverride = (leafRatio / groupRatio) * editedCellValue;
                        node.data.activityDates[i].ratioOverride = leafRatio || 0;
                      }
                    });
                    if (editedCellValue === null) {
                      params.node.allLeafChildren.forEach(node => {
                        node.data.activityDates[i].effortOverride = null;
                        node.data.activityDates[i].ratioOverride = null;
                      });
                    }
                  } else {
                    params.node.data.activityDates[i].effortOverride = editedCellValue;
                    let sumOfEfforts = 0;
                    let hasAnyOverride = false;
                    params.node.parent.allLeafChildren.forEach(node => {
                      sumOfEfforts += getEffort(node, i);
                      const hasOverride =
                        node.data.activityDates[i].effortOverride !== null &&
                        node.data.activityDates[i].effortOverride !== undefined;
                      hasAnyOverride = hasAnyOverride || hasOverride;
                    });
                    if (hasAnyOverride) {
                      params.node.parent.allLeafChildren.forEach(node => {
                        node.data.omsDates[i].effortOverride = sumOfEfforts;
                      });
                      params.node.parent.allLeafChildren.forEach(node => {
                        if (sumOfEfforts === 0) {
                          node.data.activityDates[i].ratioOverride = 100.0 / params.node.parent.allLeafChildren.length;
                        } else {
                          node.data.activityDates[i].ratioOverride = (getEffort(node, i) / sumOfEfforts) * 100;
                        }
                      });
                    } else {
                      params.node.parent.allLeafChildren.forEach(node => {
                        node.data.omsDates[i].effortOverride = null;
                      });
                      params.node.parent.allLeafChildren.forEach(node => {
                        node.data.activityDates[i].ratioOverride = null;
                      });
                    }
                  }
                  formik.setFieldValue(`effortForecast`, effortForecast);
                  formik.setErrors({});
                  params.api.refreshCells({ force: true });
                  params.api.redrawRows();
                },
                onCellValueChanged: params => {
                  params.api.refreshCells({ force: true });
                },
                minWidth: 90,
                filter: null,
                editable: effortEditable,
                sortable: false,
                cellClass: params => {
                  const cssClasses = [];
                  if (effortEditable(params)) {
                    cssClasses.push('cell-editable');
                  }
                  cssClasses.push('cell-overridable');
                  let modified;
                  if (!params.node.group) {
                    const row = params.node.data.activityDates[i];
                    modified = row?.effortOverride !== null && row?.effortOverride !== undefined;
                    const omsRow = params.node.parent.allLeafChildren[0].data.omsDates[i];
                    if (omsRow?.dataSource !== defaultDataSource) {
                      cssClasses.push('cell-data-source-not-default');
                    }
                  } else {
                    const row = params.node.allLeafChildren[0].data.omsDates[i];
                    modified = row?.effortOverride !== null && row?.effortOverride !== undefined;
                    if (row?.dataSource !== defaultDataSource) {
                      cssClasses.push('cell-data-source-not-default');
                    }
                  }
                  if (modified && forecastPlanSwitch === 'plan') {
                    cssClasses.push('override-state-ovrd');
                  }
                  return cssClasses.join(' ');
                },
              },
            ]
          : []),
        ...(effortRatioSwitch.ratio
          ? [
              {
                headerName: intl.formatMessage(messages.buttonRatio),
                valueGetter: params => getRatio(params.node, i),
                tooltipValueGetter: params => getTooltip(params, i),
                valueFormatter: params =>
                  // eslint-disable-next-line no-restricted-globals
                  isNaN(params.value) ? '' : checksumPercentageFormatter({ value: params.value }),
                newValueHandler: (params, e) => {
                  const editedCellValue = params.newValue === '' ? null : Number(params.newValue);
                  if (Number.isNaN(editedCellValue)) {
                    return false;
                  }
                  if(params.oldValue === editedCellValue){
                    return false;
                  }
                  if (params.newValue === params.oldValue || (params.oldValue === null && params.newValue === '')) {
                    return;
                  }
                  const sumOfEfforts = getEffort(params.node.parent, i);
                  let sumOfRatios = 0;
                  params.node.data.activityDates[i].ratioOverride = editedCellValue;
                  params.node.parent.allLeafChildren.forEach(node => {
                    sumOfRatios += getRatio(node, i) || 0;
                  });
                  params.node.parent.allLeafChildren.forEach(node => {
                    if (sumOfRatios === 0) {
                      node.data.activityDates[i].effortOverride = 0;
                    } else {
                      node.data.activityDates[i].effortOverride = (sumOfEfforts / 100.0) * (getRatio(node, i) || 0);
                    }
                  });
                  formik.setFieldValue(`effortForecast`, effortForecast);
                  formik.setErrors({});
                  params.api.refreshCells({ force: true });
                  params.api.redrawRows();
                },
                minWidth: 90,
                filter: null,
                editable: ratioEditable,
                sortable: false,
                cellClass: params => {
                  const cssClasses = [];
                  if (ratioEditable(params)) {
                    cssClasses.push('cell-editable');
                  }
                  cssClasses.push('cell-overridable');
                  let modified;
                  if (!params.node.group) {
                    const row = params.node.data.activityDates[i];
                    modified = row?.ratioOverride !== null && row?.ratioOverride !== undefined;
                    const omsRow = params.node.parent.allLeafChildren[0].data.omsDates[i];
                    if (omsRow?.dataSource !== defaultDataSource) {
                      cssClasses.push('cell-data-source-not-default');
                    }
                  } else {
                    const row = params.node.allLeafChildren[0].data.omsDates[i];
                    modified = row?.ratioOverride !== null && row?.ratioOverride !== undefined;
                    const sum = getRatio(params.node, i) || 0;
                    if (!isInTolerance(sum) && forecastPlanSwitch === 'plan') {
                      cssClasses.push('cell-ratio-not-100-percent');
                    }
                    if (row?.dataSource !== defaultDataSource) {
                      cssClasses.push('cell-data-source-not-default');
                    }
                  }
                  if (modified && forecastPlanSwitch === 'plan') {
                    cssClasses.push('override-state-ovrd');
                  }
                  return cssClasses.join(' ');
                },
              },
            ]
          : []),
        ...(effortRatioSwitch.dataSource
          ? [
              {
                colId: 'dataSource',
                field: 'dataSource',
                filter: 'setFilter',
                filterParams: {
                  applyButton: true,
                  clearButton: true,
                },
                headerName: intl.formatMessage(messages.dataSource),
                menuTabs: ['filterMenuTab', 'generalMenuTab', 'columnsMenuTab'],
                valueGetter: params => {
                  if (params.node.group) {
                    return params.node.allLeafChildren[0].data.omsDates[i].dataSource;
                  }
                  if(params?.data?.omsDates[i]?.dataSource){
                    const activity = [
                      { value: 'SMART_PROD', label: 'Planner Driven' },
                      { value: 'SMART_VOLUME', label: 'SmartVolume' },
                      { value: 'SMART_PROD_WITH_EVENT', label: 'Planner Driven One-Off Event' },
                      { value: 'SMART_VOLUME_WITH_EVENT', label: 'SmartVolume One-Off Event' },
                    ].find(s => s.value === params?.data?.omsDates[i]?.dataSource);
                    return activity?.label;
                  }
                  return null;
                },
                singleClickEdit: true,
                cellEditor: 'agRichSelectCellEditor',
                cellEditorParams: params => ({
                  component: 'agRichSelect',
                  values: [
                    { value: 'SMART_PROD', label: 'Planner Driven' },
                    { value: 'SMART_VOLUME', label: 'SmartVolume' },
                    { value: 'SMART_PROD_WITH_EVENT', label: 'Planner Driven One-Off Event' },
                    { value: 'SMART_VOLUME_WITH_EVENT', label: 'SmartVolume One-Off Event' },
                  ],
                  cellRenderer: p => p?.value?.label || '',
                }),
                cellRenderer: params => {
                  if (!params.node.group) {
                    return '';
                  }
                  const activity = [
                    { value: undefined, label: '-' },
                    { value: null, label: '-' },
                    { value: 'SMART_PROD', label: 'Planner Driven' },
                    { value: 'SMART_VOLUME', label: 'SmartVolume' },
                    { value: 'SMART_PROD_WITH_EVENT', label: 'Planner Driven One-Off Event' },
                    { value: 'SMART_VOLUME_WITH_EVENT', label: 'SmartVolume One-Off Event' },
                  ].find(s => s.value === params.value);
                  return activity ? activity.label : '';
                },
                newValueHandler: params => {
                  const editedCellValue = params.newValue;
                  if (params.node.group && editedCellValue.value) {
                    params.node.allLeafChildren.forEach(node => {
                      node.data.omsDates[i].dataSource = editedCellValue.value;
                      node.data.omsDates[i].effortOverride = null;
                      node.data.activityDates[i].effortOverride = null;
                      node.data.activityDates[i].ratioOverride = null;
                    });
                  }
                  formik.setFieldValue(`effortForecast`, effortForecast);
                  formik.setErrors({});
                  params.api.refreshCells({ force: true });
                  params.api.redrawRows();
                },
                editable: params => {
                  if (params.node.group) {
                    return editable;
                  }
                  return false;
                },
                cellClass: params => {
                  const cssClasses = [];
                  if (params.node.group && editable) {
                    cssClasses.push('cell-editable');
                  }
                  cssClasses.push('cell-overridable');
                  if (params.node.group) {
                    const row = params.node.allLeafChildren[0].data.omsDates[i];
                    if (row?.dataSource == smartVolumeDataSource && forecastPlanSwitch === 'plan') {
                      cssClasses.push('override-stateVolume-ovrd');
                    }
                    if (row?.dataSource == smartVolumeEventDataSource && forecastPlanSwitch === 'plan') {
                      cssClasses.push('override-smartEvent-ovrd');
                    }
                    if (row?.dataSource == smartProdEventDataSource && forecastPlanSwitch === 'plan') {
                      cssClasses.push('override-prodEvent-ovrd');
                    }
                    if(row?.dataSource == defaultDataSource  && forecastPlanSwitch === 'plan'){
                      cssClasses.push('override-smartProd-ovrd')
                    }
                  }
                  return cssClasses.join(' ');
                },
                sortable: true,
                width: 140,
                suppressSizeToFit: true,
                hide: false,
                resizable: true,
              },
            ]
          : []),
      ],
    })),
  ];
};

type Props = {
  edit: boolean;
  forecastPlanSwitch: EffortForecastPlanSwitch;
  effortRatioSwitch: EffortRatioSwitch;
  formik?: FormikProps<PlanDetailData>;
  intl: InjectedIntl;
  effortForecast: EffortForecastTableData[];
  dates: DateTime[];
  allOmsCodes: EntityEntry[];
  activityForecastList: ForecastListEntityEntry[];
  modifySwitch: EffortForecastModifySwitch;
};

const EffortForecastTable: React.FC<Props> = ({
  edit,
  forecastPlanSwitch,
  effortRatioSwitch,
  formik,
  intl,
  effortForecast,
  dates,
  allOmsCodes,
  activityForecastList,
  modifySwitch,
}) => {
  const [gridApi, setGridApi] = useState(null);
  const lastScrollPosition = useRef(null);
  const omsCodesById = {};
  if (allOmsCodes) {
    allOmsCodes.forEach(omsCode => {
      omsCodesById[omsCode.value] = omsCode;
    });
  }

  const onBodyScroll = params => {
    lastScrollPosition.current = {
      left: params.left,
      top: params.top,
    };
  };

  const onGridReady = params => {
    setGridApi(params);
    params.api.sizeColumnsToFit();
    const scrollPosition = lastScrollPosition.current;
    if (scrollPosition) {
      params.api.gridPanel.setCenterViewportScrollLeft(scrollPosition.left);
      params.api.gridPanel.setVerticalScrollPosition(scrollPosition.top);
    }
  };

  const handleCellValueChanged = params => {};

  const tableData: EffortForecastTableData[] = Array.isArray(effortForecast)
    ? effortForecast.map(data => {
        const activityForecast = activityForecastList.find(s => s.value === data.activityForecastId);
        const omsCodeName = `${omsCodesById[data.omsId]?.label || data.omsId}`;
        let omsCodeSmartProdSourceName = omsCodeName;
        if (data.smartProdSourceName) {
          omsCodeSmartProdSourceName = `${omsCodeName} - ${data.smartProdSourceName}`;
        }
        return {
          ...data,
          omsCodeName,
          omsCodeSmartProdSourceName,
          activityForecastName: activityForecast?.label,
          uomName: activityForecast?.uom,
          //@ts-ignore
          ForecastOutOfPlanDateInterval: activityForecast?.ForecastOutOfPlanDateInterval
        };
      })
    : [];

  tableData.sort((a, b) => {
    const codeA = a.omsCodeName;
    const codeB = b.omsCodeName;
    if (codeA === codeB) {
      return a.activityForecastName < b.activityForecastName ? -1 : 1;
    }
    return codeA < codeB ? -1 : 1;
  });

  useEffectDeepCompare(() => {
    if (gridApi) {
      const colDefs = columnDefs(
        formik,
        edit,
        forecastPlanSwitch,
        effortRatioSwitch,
        modifySwitch,
        omsCodesById,
        activityForecastList,
        tableData,
        dates,
        intl,
      );
      gridApi.api.setRowData(tableData);
      gridApi.api.setColumnDefs(colDefs);
      gridApi.api.sizeColumnsToFit();
      gridApi.api.refreshCells({ force: true });
      gridApi.api.redrawRows();
      const scrollPosition = lastScrollPosition.current;
      if (scrollPosition) {
        gridApi.api.gridPanel.setCenterViewportScrollLeft(scrollPosition.left);
        gridApi.api.gridPanel.setVerticalScrollPosition(scrollPosition.top);
      }
      gridApi.api.forEachNode(node => {
        node.expanded = false;
      });
      gridApi.api.onGroupExpandedOrCollapsed();
    }
  }, [gridApi, edit]);

  useEffectDeepCompare(() => {
    if (gridApi) {
      const colDefs = columnDefs(
        formik,
        edit,
        forecastPlanSwitch,
        effortRatioSwitch,
        modifySwitch,
        omsCodesById,
        activityForecastList,
        tableData,
        dates,
        intl,
      );
      gridApi.api.setRowData(tableData);
      gridApi.api.setColumnDefs(colDefs);
      gridApi.api.sizeColumnsToFit();
      gridApi.api.refreshCells({ force: true });
      gridApi.api.redrawRows();
      const scrollPosition = lastScrollPosition.current;
      if (scrollPosition) {
        gridApi.api.gridPanel.setCenterViewportScrollLeft(scrollPosition.left);
        gridApi.api.gridPanel.setVerticalScrollPosition(scrollPosition.top);
      }
    }
  }, [effortForecast, forecastPlanSwitch, effortRatioSwitch, modifySwitch, formik?.values?.planningParameters?.defaultForecastSource]);

  const ROW_HEIGHT = 30;

  return (
    allOmsCodes && (
      <TableWrap>
        <Table
          defaultColDef={{
            flex: 1,
          }}
          frameworkComponents={{
            acWithToolTip: GroupWithToolTip('activityForecastName'),
            groupFilter: GroupFilter,
          }}
          sortable
          pagination={false}
          suppressMovableColumns
          onCellValueChanged={handleCellValueChanged}
          columnDefs={columnDefs(
            formik,
            edit,
            forecastPlanSwitch,
            effortRatioSwitch,
            modifySwitch,
            omsCodesById,
            activityForecastList,
            tableData,
            dates,
            intl,
          )}
          rowData={tableData}
          rowHeight={ROW_HEIGHT}
          headerHeight={ROW_HEIGHT}
          groupHeaderHeight={ROW_HEIGHT}
          height={240 + ROW_HEIGHT * Math.min(10, tableData?.length || 0)}
          onGridReady={onGridReady}
          onBodyScroll={onBodyScroll}
          isRowSelectable={() => false}
          groupDefaultExpanded={0}
          rememberGroupStateWhenNewData={true}
          enableGroupEdit
          showCollapseButtonsInsideTable
          autoGroupColumnDef={{
            colId: 'groupping',
            headerName: `${intl.formatMessage(messages.tableOMSCode)} \u2192 ${intl.formatMessage(
              messages.tableFacilityCustomerDepartmentActivity,
            )}`,
            field: 'activityForecastName',
            valueFormatter: data => omsCodesById[data.value]?.label || '',
            width: 420,
            suppressSizeToFit: true,
            pinned: true,
            cellStyle: ((params: any) => {
              if(params?.data?.ForecastOutOfPlanDateInterval){
                return {color: 'red'}
              }
            }),
            cellRenderer: 'agGroupCellRenderer',
            cellRendererParams: { checkbox: true },
            menuTabs: ['filterMenuTab'],
            filter: 'groupFilter',
            filterParams: {
              columns: ['omsCodeSmartProdSourceName', 'activityForecastName'],
              applyButton: true,
              clearButton: true,
            },
          }}
        />
      </TableWrap>
    )
  );
};

export default injectIntl(formikConnect(EffortForecastTable));
