/**
 *
 * ResultBasicInfo
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage, injectIntl } from 'react-intl';
import get from 'lodash/get';
import keyBy from 'lodash/keyBy';
import { formatDay } from 'utils/dateTime';
import { getWeekdays } from 'utils/calendar/localize';
import WorkingHours from 'components/WorkingHours';
import { T_TYPE } from 'utils/constants';
import messages from './messages';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

const ColumnWrapper = styled.div`
  flex-grow: ${props => (props.size ? props.size : 1)};
  display: grid;
  grid-template-columns: fit-content(40%) auto;
  grid-row-gap: ${props => props.theme.grid.rowGap};
  align-items: normal;
  font-size: 12px;
  > * {
    margin-right: 10px;
  }
  > span {
    white-space: nowrap;
  }
`;

const Value = styled.div`
  font-weight: bold;
  min-height: ${props => props.theme.grid.tinyRowHeight};
`;
const Wrappable = styled(Value)`
  white-space: normal;
`;
/* eslint-disable react/prefer-stateless-function */
class ResultBasicInfo extends React.PureComponent {
  render() {
    const {
      plan,
      intl: { formatMessage },
    } = this.props;

    if (!plan || !plan.planningParameters) return <span />;

    const pp = plan.planningParameters;

    let { offDays } = pp;
    offDays = offDays && Object.values(getWeekdays(formatMessage, offDays, true)).join(', ');
    let timeZone = pp.timezone && pp.timezone.offset;
    if (timeZone) {
      timeZone = `${timeZone} - ${pp.timezone.name}`;
    }
    const pa = plan.planningArea || plan;
    const { customers } = pa;
    const { facilities } = pa;

    const tTypeValue = pp.transformationType || T_TYPE.WZP;
    const isShift = tTypeValue === T_TYPE.SHIFT;
    return (
      <Wrapper id="ResultBasicInfo">
        <ColumnWrapper>
          <FormattedMessage {...messages.name} />
          <Value>{plan.name}</Value>
          <FormattedMessage {...messages.description} />
          <Wrappable>{plan.description}</Wrappable>
          <FormattedMessage {...messages.planningAreaBase} />
          <Value>{pa.name}</Value>
          <FormattedMessage {...messages.weekStartDay} />
          <Value>{formatDay(pp.firstDayOfWeek)}</Value>
        </ColumnWrapper>
        <ColumnWrapper>
          <FormattedMessage {...messages.region} />
          <Value>{pa.country && pa.country.regionName}</Value>
          <FormattedMessage {...messages.country} />
          <Value>{pa.country && pa.country.name}</Value>
          <FormattedMessage {...messages.sector} />
          <Value>{pa.sector && pa.sector.name}</Value>
        </ColumnWrapper>
        <ColumnWrapper size={2}>
          <FormattedMessage {...messages.customer} />
          <Value>{customers && customers.map(cust => <div key={cust.id}>{cust.name}</div>)}</Value>
          <FormattedMessage {...messages.facility} />
          <Value>{facilities && facilities.map(f => <div key={f.id}>{f.name}</div>)}</Value>
        </ColumnWrapper>
        <ColumnWrapper>
          <FormattedMessage {...messages.fteHours} />
          <Value>{pp.hoursInDayFTE}</Value>
          <FormattedMessage {...messages.offDays} />
          <Value>{offDays}</Value>
          <FormattedMessage {...messages.timeZone} />
          <Value>{timeZone}</Value>
          {isShift ? <FormattedMessage {...messages.shifts} /> : <FormattedMessage {...messages.wzp} />}
          <Value>
            <WorkingHours
              formatMessage={formatMessage}
              height="95px"
              shadowBackground={false}
              values={{ planningParameters: pp }}
            />
          </Value>
        </ColumnWrapper>
      </Wrapper>
    );
  }
}

ResultBasicInfo.propTypes = {
  plan: PropTypes.object,
};

export default injectIntl(ResultBasicInfo);
