import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { toast } from 'react-toastify';

import Select from 'components/StyledSelect';
import { AddLineButton } from 'components/IconButton';

import messages from './messages';

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 15px 0;
  & > * {
    margin: 0 5px;
  }
`;

const StyledSelect = styled(Select)`
  width: 250px;
`;


function AdjustmentAddLine(props) {
    const { departments, activityParametersList, customers, rowData } = props;
    const [dirActivity, setDirActivity] = useState([]);
    const [indirActivity, setIndDirActivity] = useState([]);
    const [selectedDirActivity, setSelectedDirActivity] = useState(null);
    const [selectedInDirActivity, setSelectedInDirActivity] = useState(null);
    React.useEffect(() => {
        const directActivities = activityParametersList && activityParametersList.filter((ap) => !ap.indirect);
        const indirectActivities = activityParametersList && activityParametersList.filter((ap) => (ap.indirect || !ap.indirect) && ap.activity.productivityType !== 'STAFF_UNPRODUCTIVE' && ap.activity.productivityType !== 'LOCATION_UNPRODUCTIVE');
        const dAct = directActivities && directActivities.map((d) => {
            const department = departments && departments.filter((dep) => dep.id == d.departmentId);
            const depName = department && department[0] && department[0].name ? department[0].name : '';
            const customer = customers && customers.filter((cust) => cust.id == d.customerId);
            const customerName = customer && customer[0] && customer[0].name ? customer[0].name : '';
            const object = {
                value: d.activity.id,
                label: `${customerName}/${depName}/${d.activity.name} (${d.variableName})`,
                name: `${d.activity.name} (${d.variableName})`,
                oms: `${d.activity.omsCode}-${d.activity.omsName}`,
                activityFromId: d.id,
            }
            return object
        })
        const idAct = indirectActivities && indirectActivities.map((d) => {
            const department = departments && departments.filter((dep) => dep.id == d.departmentId);
            const depName = department && department[0] && department[0].name ? department[0].name : '';
            const customer = customers && customers.filter((cust) => cust.id == d.customerId);
            const customerName = customer && customer[0] && customer[0].name ? customer[0].name : '';
            const object = {
                value: d.activity.id,
                label: `${customerName}/${depName}/${d.activity.name} (${d.variableName})`,
                name: `${d.activity.name} (${d.variableName})`,
                oms: `${d.activity.omsCode}-${d.activity.omsName}`,
                activityToId: d.id,
            }
            return object
        })
        setDirActivity(dAct);
        setIndDirActivity(idAct)
    }, [activityParametersList]);
    const handleAddLine = () => {
        const alreadyAdded = rowData && rowData.find((row) => (row.activityTo == selectedDirActivity.activityFromId) && (row.activityFrom == selectedInDirActivity.activityToId));
        if (alreadyAdded) {
            toast.error(props.props.intl.formatMessage(messages.alreadyAddedAdjustment));
        } else {
            props.handleAddAdjustments({ activityFrom: selectedDirActivity, activityTo: selectedInDirActivity })
            setSelectedDirActivity(null);
            setSelectedInDirActivity(null);
        }
    }
    React.useEffect(()=>{
            const indirectActivities = activityParametersList && activityParametersList.filter((ap) => (ap.indirect || !ap.indirect) && ap.activity.productivityType !== 'STAFF_UNPRODUCTIVE' && ap.activity.productivityType !== 'LOCATION_UNPRODUCTIVE');
            const idAct = indirectActivities && indirectActivities.map((d) => {
                const department = departments && departments.filter((dep) => dep.id == d.departmentId);
                const depName = department && department[0] && department[0].name ? department[0].name : '';
                const customer = customers && customers.filter((cust) => cust.id == d.customerId);
                const customerName = customer && customer[0] && customer[0].name ? customer[0].name : '';
                const object = {
                    value: d.activity.id,
                    label: `${customerName}/${depName}/${d.activity.name} (${d.variableName})`,
                    name: `${d.activity.name} (${d.variableName})`,
                    oms: `${d.activity.omsCode}-${d.activity.omsName}`,
                    activityToId: d.id,
                }
                return object
            })
            const removeSelected = idAct?.filter((ind)=> ind?.activityToId !== selectedDirActivity?.activityFromId);
            const addedDirectActivity = rowData?.filter((r)=> (selectedDirActivity?.activityFromId === r.activityTo));
            const addedDirectActivityId = addedDirectActivity?.map((aId) => aId.activityFrom);
            setIndDirActivity(removeSelected?.filter((rem) => !addedDirectActivityId.includes(rem.activityToId)));
    },[selectedDirActivity])
    
    React.useEffect(()=>{
        if(dirActivity && dirActivity.length !== 0){
            const directActivities = activityParametersList && activityParametersList.filter((ap) => !ap.indirect);
            const dAct = directActivities && directActivities.map((d) => {
                const department = departments && departments.filter((dep) => dep.id == d.departmentId);
                const depName = department && department[0] && department[0].name ? department[0].name : '';
                const customer = customers && customers.filter((cust) => cust.id == d.customerId);
                const customerName = customer && customer[0] && customer[0].name ? customer[0].name : '';
                const object = {
                    value: d.activity.id,
                    label: `${customerName}/${depName}/${d.activity.name} (${d.variableName})`,
                    name: `${d.activity.name} (${d.variableName})`,
                    oms: `${d.activity.omsCode}-${d.activity.omsName}`,
                    activityFromId: d.id,
                }
                return object
            })
            const removeSelected = dAct?.filter((dir)=> dir?.activityFromId !== selectedInDirActivity?.activityToId);
            const addedIndirectActivity = rowData?.filter((r)=> (selectedInDirActivity?.activityToId === r.activityFrom));
            const addedIndirectActivityId = addedIndirectActivity?.map((aiId) => aiId.activityTo);
            setDirActivity(removeSelected?.filter((rem)=> !addedIndirectActivityId.includes(rem.activityFromId)));
        }
    },[selectedInDirActivity])

    return (
        <Row>
            <FormattedMessage {...messages.directActivity} />
            <StyledSelect
                options={dirActivity || []}
                getOptionValue={option => option.label}
                onChange={setSelectedDirActivity}
                value={selectedDirActivity}
            />
            <FormattedMessage {...messages.inDirectActivity} />
            <StyledSelect
                options={indirActivity || []}
                onChange={setSelectedInDirActivity}
                value={selectedInDirActivity}
            />
            <AddLineButton
                disabled={!selectedDirActivity || !selectedInDirActivity}
                onClick={() => handleAddLine()}
            />
        </Row>
    );
}

export default AdjustmentAddLine;
