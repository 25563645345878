/*
 *
 * TableControlled reducer
 *
 */

import { fromJS } from 'immutable';
import { REGISTER_TABLE, UNREGISTER_TABLE, SET_TABLE_CONFIG } from './constants';

export const initialState = fromJS({});

function tableControlledReducer(state = initialState, action) {
  switch (action.type) {
    case SET_TABLE_CONFIG:
      return state.setIn([action.tableName, 'config'], fromJS(action.config));
    case REGISTER_TABLE:
      return state.setIn([action.tableName, 'isActive'], true).setIn([action.tableName, 'defaultConfig'], action.def);
    case UNREGISTER_TABLE:
      return state.setIn([action.tableName, 'isActive'], false);
    default:
      return state;
  }
}

export default tableControlledReducer;
