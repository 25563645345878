import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the uploadModal state domain
 */

const selectUploadModalDomain = state => state.get('uploadModal', initialState);

/**
 * Other specific selectors
 */

/**
 * Default selector used by UploadModal
 */

export const makeSelectUploadRowData = () =>
  createSelector(
    selectUploadModalDomain,
    substate => substate.get('rowData'),
  );

const getUploadsByPlan = (state, props) => {
  const data = state.getIn(['uploadModal', 'rowData']);
  if (!data) return false;
  return data.filter(row => row.get('entityId') === props.currentId);
};

const getUploadsByUser = (state, props) => {
  const data = state.getIn(['uploadModal', 'rowData']);
  const { user } = props.user;

  if (!data || !user) return false;
  return data.filter(row => row.getIn(['createdBy', 'login']) === user.login);
};

export const selectUploadsByUser = createSelector(
  getUploadsByUser,
  data => data,
);

export const selectUploadsByPlan = createSelector(
  getUploadsByPlan,
  data => data,
);

export const makeSelectUploadModalVisible = () =>
  createSelector(
    selectUploadModalDomain,
    substate => substate.get('visible'),
  );
export const makeSelectUploadsExpanded = () =>
  createSelector(
    selectUploadModalDomain,
    substate => substate.get('uploadsExpanded'),
  );
export const makeSelectUploadInfo = () =>
  createSelector(
    selectUploadModalDomain,
    substate => substate.get('uploadInfo'),
  );
export const makeSelectExportExpanded = () =>
  createSelector(
    selectUploadModalDomain,
    substate => substate.get('exportExpanded'),
  );

const getCurrentId = (state, props) => props.currentId;

export const isRunningSelector = createSelector(
  makeSelectUploadRowData(),
  getCurrentId,
  (rowData, currentId) => {
    if (!rowData) return false;
    return rowData.filter(row => row.get('status') === 'RUNNING' && row.get('entityId') === currentId).size > 0;
  },
);

export const makeSelectUploadModal = () =>
createSelector(
  selectUploadModalDomain,
  substate => substate.get('uploadModal'),
); 