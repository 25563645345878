import * as React from 'react';
import { Component } from 'react';
import { Form, Formik, FormikProps } from 'formik';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Prompt } from 'react-router';
import { toast } from 'react-toastify';
import { bindActionCreators, compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';

import BasePage from 'components/BasePage';
import {
  DetailPageSectionsWrapper,
  DetailToolbarButtonsGroup,
  DetailToolbarButtonsWrap,
} from 'components/DetailPageShared';
import { FormikText, InlineLabel } from 'components/FormikTextInput';
import IconButton, { SaveButton } from 'components/IconButton';
import NamedSection from 'components/NamedSection';
import ToggleSection from 'components/ToggleSection';
import { PATHS } from 'containers/App/constants';
import { DisplayDate } from 'utils/dateTime';
import EnchantedMap from 'utils/enchantedMap';
import injectSaga from 'utils/injectSaga';
import { withScrollWatch } from 'utils/scroll';
import withSecurity, { PERMISSIONS } from 'utils/security';
import { findDiffs, findOverlaps } from 'utils/utilsTs';
import {
  ApiScheduleDTO,
  ApiScheduleStaffSettingDTO,
  ApiStaffSmartShiftJobScheduleDTO,
  ApiUpdateScheduleStaffDTO,
} from 'types/drep-backend.d';

import { selectShiftSchedule } from '../ShiftScheduleActivityDetailPage/selectors';
import {
  loadScheduleStaff,
  loadScheduleStaffSettings,
  loadScheduleStaffUnproductiveActivities,
  loadShiftSchedule,
  saveScheduleStaff,
} from '../ShiftScheduleDetailPage/actions';
import saga from '../ShiftScheduleDetailPage/saga';
import {
  selectShiftScheduleStaff,
  selectShiftScheduleStaffSettings,
  selectShiftScheduleStaffUnproductiveActivities,
} from '../ShiftScheduleDetailPage/selectors';
import {
  NamedEntity,
  ScheduleStaff,
} from '../ShiftScheduleDetailPage/types';
import { convertArrayToMap, convertArrayToMultiMap } from '../ShiftScheduleDetailPage/utils';
import messages from './messages';
import StaffSettingsTable from './staffSettingsTable';
import StaffUnproductiveActivitiesTable from './staffUnproductiveActivitiesTable';

const GridRow = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: 128px 200px;
  margin: 0 0 12px;
  column-gap: 10px;
`;

const Section = styled(NamedSection)`
  input {
    background-color: ${props => props.theme.color.grey5};
  }
`;

const FullWidthToggleSection = styled(ToggleSection)`
  min-width: 1170px;
  flex: 1;
`;

interface ShiftScheduleStaffDetailPageProps {
  match: any;
  history: any;
  intl: any;
  fixed: boolean;
  staff: ScheduleStaff[];
  staffSettings: ApiScheduleStaffSettingDTO[];
  staffUnproductiveActivities: ApiStaffSmartShiftJobScheduleDTO[];
  shiftSchedule: ApiScheduleDTO;
  loadShiftSchedule(id: number): () => void;
  loadScheduleStaff(id: number): () => void;
  loadScheduleStaffSettings(payload: any): () => void;
  loadScheduleStaffUnproductiveActivities(payload: any): () => void;
  save(activity: any): () => void;
}

class ShiftScheduleStaffDetailPage extends Component<ShiftScheduleStaffDetailPageProps, {}> {
  private availableSettings: EnchantedMap<number, NamedEntity>;

  private availableUnproductiveJobs: EnchantedMap<number, Partial<ApiStaffSmartShiftJobScheduleDTO>>;

  public componentDidMount() {
    const { params } = this.props.match;

    this.props.loadScheduleStaff(params.scheduleId);
    this.props.loadScheduleStaffSettings({
      id: params.id,
      scheduleId: params.scheduleId,
    });
    this.props.loadScheduleStaffUnproductiveActivities({
      id: params.id,
      scheduleId: params.scheduleId,
    });
    this.props.loadShiftSchedule(params.scheduleId);
  }

  public componentDidUpdate(prevProps: Readonly<ShiftScheduleStaffDetailPageProps>): void {
    const { params } = this.props.match;
    if (prevProps.match.params.id !== params.id) {
      this.props.loadScheduleStaffSettings({
        id: params.id,
        scheduleId: params.scheduleId,
      });
      this.props.loadScheduleStaffUnproductiveActivities({
        id: params.id,
        scheduleId: params.scheduleId,
      });
    }
  }

  public buildActivitiesMap = () => {
    if (this.props.shiftSchedule?.plans && this.props.shiftSchedule?.activities && this.props.staffSettings) {
      const availableDepartments = convertArrayToMap(this.props.shiftSchedule.departments);
      const availableFacilities = convertArrayToMap(this.props.shiftSchedule.facilities);
      const uniqueKeysWithIds = [];
      this.props.staffSettings.forEach(item => {
        if (uniqueKeysWithIds.map(obj => obj.activityKey).indexOf(item.activityKey) === -1) {
          uniqueKeysWithIds.push({
            activityKey: item.activityKey,
            id: item.id,
          });
        }
      });
      const ids = uniqueKeysWithIds.map(i => i.id);
      this.availableSettings = convertArrayToMap(
        this.props.staffSettings
          .filter(item => ids.includes(item.id))
          .map(setting => ({
            id: setting.id,
            name: setting.activityKey,
          })),
      );

      const unproductiveActivities = {};
      this.props.shiftSchedule.activities.forEach(activity => {
        if (activity.activity.productivityType === 'STAFF_UNPRODUCTIVE') {
          unproductiveActivities[activity.id] = activity;
        }
      });
      this.availableUnproductiveJobs = convertArrayToMap(
        this.props.shiftSchedule.smartShiftJobSchedules
          .filter(jobSchedule => jobSchedule.scheduleActivityIds.filter(aId => unproductiveActivities[aId]).length)
          .map(smartShiftJobSchedule => {
            const {
              facility: { id: facilityId },
              department,
              customer,
              activity,
            } = smartShiftJobSchedule;
            const unproductiveJob: Partial<ApiStaffSmartShiftJobScheduleDTO> = {
              smartShiftJobScheduleId: smartShiftJobSchedule.id,
              smartShiftJob: {
                id: smartShiftJobSchedule.smartShiftJobId,
                activityKey: `${availableFacilities.get(facilityId)?.code}/${customer?.name}/${department}/${activity}`,
              },
            };
            return unproductiveJob;
          }),
        value => value.smartShiftJob.id,
      );
    }
  };

  public render() {
    if(this.props?.shiftSchedule?.name){
      sessionStorage.setItem('scheduleName', this.props?.shiftSchedule?.name)
    }
    this.buildActivitiesMap();
    if (
      !Array.isArray(this.props.staffSettings) ||
      !this.props.staff ||
      !this.props.shiftSchedule?.id ||
      !this.availableSettings ||
      !Array.isArray(this.props.staffUnproductiveActivities)
    ) {
      return <BasePage labelMessage={messages.header}>Loading staff...</BasePage>;
    }
    const scheduleStaff: ScheduleStaff = this.props.staff.find(a => a.id === parseInt(this.props.match.params.id, 0));

    const overlapSettingsCheck = (values: ScheduleStaff, actions) => {
      const settingsByActivity = convertArrayToMultiMap(values.settings, item => item.activityKey);

      const hasOverlaps = group => findOverlaps(group).length > 0;
      const overlaps = settingsByActivity.listValues().filter(hasOverlaps);

      if (overlaps.length > 0) {
        overlaps.forEach(o => {
          const name = this.availableSettings.get(o[0].id)?.name;
          toast.error(`There is a dates overlap for activity ${name}`);
        });

        actions.setSubmitting(false);
        return false;
      }
      return true;
    };

    const getSettingWithoutPriority = (r: ApiScheduleStaffSettingDTO): ApiScheduleStaffSettingDTO => {
      const setting: ApiScheduleStaffSettingDTO = { ...r };
      delete setting.priority;
      return setting;
    };

    const doSubmit = (values: ScheduleStaff, actions) => {
      if (overlapSettingsCheck(values, actions)) {
        const settingsDiffs = findDiffs(this.props.staffSettings, values.settings);
        const unproductiveActivitiesDiffs = findDiffs(
          this.props.staffUnproductiveActivities,
          values.smartShiftJobs,
          'staffSmartShiftJobScheduleId',
        );
        const staff: ApiUpdateScheduleStaffDTO = {
          deletedSettings: settingsDiffs.deletedIds,
          deletedSmartShiftJobs: unproductiveActivitiesDiffs.deletedIds,
          settings: settingsDiffs.added.concat(settingsDiffs.modified).map(r => getSettingWithoutPriority(r)),
          staffSmartShiftJobSchedules: unproductiveActivitiesDiffs.added
            .concat(unproductiveActivitiesDiffs.modified)
            .map(r => ({
              ...r,
              staffSmartShiftJobScheduleId: r.staffSmartShiftJobScheduleId,
              smartShiftJobScheduleId: r.smartShiftJobScheduleId,
              smartShiftJob: r.smartShiftJob,
              days: r.days,
              periodLength: r.periodLength,
            })),
        };
        const toSave = {
          scheduleId: this.props.shiftSchedule.id,
          staffId: values.id,
          staff,
          staffName: `${values.firstName} ${values.lastName}`,
        };

        this.props.save(toSave);
        actions.setSubmitting(false);
      }
    };

    const initialValues = {
      ...scheduleStaff,
      settings: this.props.staffSettings,
      smartShiftJobs: this.props.staffUnproductiveActivities,
    };

    return (
      <BasePage labelMessage={messages.header}>
        <Formik
          validateOnChange
          onSubmit={doSubmit}
          enableReinitialize
          initialValues={initialValues}
          render={this.renderForm}
        />
      </BasePage>
    );
  }

  private renderForm = (formik: FormikProps<ScheduleStaff>) => (
    <Form>
      <Prompt when={formik.dirty} message={this.props.intl.formatMessage(messages.confirmDirty)} />

      {this.renderToolbar(formik)}

      <DetailPageSectionsWrapper>
        {this.renderBasicInfoSection(formik)}

        <FullWidthToggleSection message={messages.staffJobSettings} expanded>
          <StaffSettingsTable activities={this.availableSettings.listValues()} formik={formik} />
        </FullWidthToggleSection>
        <FullWidthToggleSection message={messages.unproductiveJobs} expanded>
          <StaffUnproductiveActivitiesTable
            formik={formik}
            shiftSchedule={this.props.shiftSchedule}
            smartShiftJobs={this.availableUnproductiveJobs.listValues()}
          />
        </FullWidthToggleSection>
      </DetailPageSectionsWrapper>
    </Form>
  );

  private renderBasicInfoSection = (formik: FormikProps<ScheduleStaff>) => {
    const facility = this.props.shiftSchedule.facilities.find(f => f.id === formik.values.facilityId);

    return (
      <Section message={messages.basicInfo}>
        <GridRow>
          <InlineLabel {...messages.firstName} />
          <FormikText>{formik.values.firstName}</FormikText>
        </GridRow>
        <GridRow>
          <InlineLabel {...messages.lastName} />
          <FormikText>{formik.values.lastName}</FormikText>
        </GridRow>
        <GridRow>
          <InlineLabel {...messages.mainFacility} />
          <FormikText>{`${facility?.code} - ${facility?.name}`}</FormikText>
        </GridRow>
        <GridRow>
          <InlineLabel {...messages.laborCategory} />
          <FormikText>{formik.values.laborCategory}</FormikText>
        </GridRow>
        <GridRow>
          <InlineLabel {...messages.kronosId} />
          <FormikText>{formik.values.kronosId}</FormikText>
        </GridRow>
        <GridRow>
          <InlineLabel {...messages.validFrom} />
          <FormikText>
            <DisplayDate value={formik.values.validFrom} />
          </FormikText>
        </GridRow>
        <GridRow>
          <InlineLabel {...messages.validTo} />
          <FormikText>
            <DisplayDate value={formik.values.validTo} />
          </FormikText>
        </GridRow>
        <GridRow>
          <InlineLabel {...messages.primaryJob} />
          <FormikText>{formik.values.primaryJob}</FormikText>
        </GridRow>
      </Section>
    );
  };

  private renderToolbar = (formik: FormikProps<ScheduleStaff>) => {
    const { scheduleId } = this.props.match.params;
    const { fixed } = this.props;
    const siblings = this.props.staff;
    const currentIndex = siblings.findIndex(candidate => candidate.id === formik.values.id);
    const nextIndex = currentIndex === siblings.length - 1 ? 0 : currentIndex + 1;
    const prevIndex = currentIndex === 0 ? siblings.length - 1 : currentIndex - 1;
    const goTo = path => () => this.props.history.push(path.replace(':scheduleId', scheduleId));

    const goToPrev = () => goTo(PATHS.shiftScheduleStaffDetailId.replace(':id', siblings[prevIndex].id));
    const goToNext = () => goTo(PATHS.shiftScheduleStaffDetailId.replace(':id', siblings[nextIndex].id));
    const goBack = () => goTo(PATHS.shiftScheduleDetailId.replace(':id', scheduleId));

    return (
      <DetailToolbarButtonsWrap fixed={fixed}>
        <IconButton id="back" onClick={goBack()} icon="arrow-circle-left" tooltip={messages.back} />

        <DetailToolbarButtonsGroup>
          <SaveButton onClick={formik.submitForm} disabled={!formik.dirty} />
          <IconButton onClick={goToPrev()} label={messages.previous} />
          <IconButton onClick={goToNext()} label={messages.next} />
        </DetailToolbarButtonsGroup>
      </DetailToolbarButtonsWrap>
    );
  };
}

const mapStateToProps = createStructuredSelector({
  shiftSchedule: selectShiftSchedule,
  staff: selectShiftScheduleStaff,
  staffSettings: selectShiftScheduleStaffSettings,
  staffUnproductiveActivities: selectShiftScheduleStaffUnproductiveActivities,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      loadScheduleStaff,
      loadScheduleStaffSettings,
      loadScheduleStaffUnproductiveActivities,
      loadShiftSchedule,
      save: saveScheduleStaff,
    },
    dispatch,
  );
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withSaga = injectSaga({ key: 'shiftScheduleStaffDetailPage', saga });

export default compose(
  injectIntl,
  withConnect,
  withSecurity(PERMISSIONS.VIEW_SCHEDULES),
  withSaga,
  withScrollWatch(100),
  // @ts-ignore
)(ShiftScheduleStaffDetailPage);
