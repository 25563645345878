/*
 * MheAvailability Messages
 *
 * This contains all the text for the MheAvailability component.
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  mhe: {
    id: 'app.components.MheAvailability.mhe',
    defaultMessage: 'MHE',
  },
  name: {
    id: 'app.components.MheAvailability.name',
    defaultMessage: 'Name',
  },
  maintenance: {
    id: 'app.components.MheAvailability.maintenance',
    defaultMessage: 'Maintenance',
  },
  pieces: {
    id: 'app.components.MheAvailability.pieces',
    defaultMessage: 'Pieces',
  },
  piecesDefault: {
    id: 'app.components.MheAvailability.piecesDefault',
    defaultMessage: 'Pieces (default)',
  },
  day: {
    id: 'app.components.MheAvailability.day',
    defaultMessage: 'Maintenance Day',
  },
  wzp: {
    id: 'app.components.MheAvailability.wzp',
    defaultMessage: 'Maintenance WZP',
  },
  shift: {
    id: 'app.components.MheAvailability.shift',
    defaultMessage: 'Maintenance Shift',
  },
  action: {
    id: 'app.components.MheAvailability.action',
    defaultMessage: 'Action',
  },
  departmentId: {
    id: 'app.components.MheAvailability.departmentId',
    defaultMessage: 'Department',
  },
  department: {
    id: 'app.components.MheAvailability.department',
    defaultMessage: 'Department',
  },
  allDepartments: {
    id: 'app.components.MheAvailability.allDepartments',
    defaultMessage: 'All Departments',
  },
  key: {
    id: 'app.components.MheAvailability.key',
    defaultMessage: 'Key',
  },
});
