import React, { useEffect, useState } from 'react';
import { ColDef, ColGroupDef } from 'ag-grid-community';
import { FormikProps } from 'formik';
import { InjectedIntl, injectIntl } from 'react-intl';
import styled from 'styled-components';

import AgTable from 'components/Table';
import { dateCellFormater, dateTimeCellFormatter, timeCellFormatter, dateScheduledCellFormater } from 'utils/dateTime';
import { useEffectDeepCompare } from 'utils/utils';
import { formatDate } from 'utils/dateTime';

import messages from './messages';
import TimeTransformationTableDetailCellRenderer from './TimeTransformationTableDetailCellRenderer';
import { EntityEntry, ForecastForm, SmartProdRunEnum, SmartProdRunsType } from './types';

const Table = styled(AgTable)`
  margin-top: 12px;
  height: ${props => props.height}px;

  .ag-overlay-no-rows-wrapper {
    padding-top: 90px;
  }

  .ag-full-width-row {
    overflow: visible;
  }
`;

const columnDefs = (formik, editable: boolean, allSmartProdSources: EntityEntry[], intl: InjectedIntl, planDetails) => {
  const columns: (ColGroupDef | ColDef)[] = [
    {
      colId: 'name',
      field: 'name',
      filter: 'setFilter',
      menuTabs: ['filterMenuTab', 'generalMenuTab', 'columnsMenuTab'],
      cellRenderer: 'agGroupCellRenderer',
      headerName: intl.formatMessage(messages.smartProdRunsName),
      sortable: true,
    },
    {
      headerName: intl.formatMessage(messages.smartProdRunsDataSourcePeriod),
      children: [
        {
          colId: 'dataSourceDates.startDate',
          field: 'dataSourceDates.startDate',
          filter: 'setFilter',
          menuTabs: ['filterMenuTab', 'generalMenuTab', 'columnsMenuTab'],
          valueFormatter: dateCellFormater,
          headerName: intl.formatMessage(messages.smartProdRunsSartDate),
          sortable: true,
        },
        {
          colId: 'dataSourceDates.endDate',
          field: 'dataSourceDates.endDate',
          filter: 'setFilter',
          menuTabs: ['filterMenuTab', 'generalMenuTab', 'columnsMenuTab'],
          valueFormatter: dateCellFormater,
          headerName: intl.formatMessage(messages.smartProdRunsEndDate),
          sortable: true,
        },
      ],
    },
    {
      headerName: intl.formatMessage(messages.smartProdRunsForecastPeriod),
      children: [
        {
          colId: 'periods',
          field: 'periods',
          filter: 'setFilter',
          menuTabs: ['filterMenuTab', 'generalMenuTab', 'columnsMenuTab'],
          valueFormatter: params => {
            const periodIds = params?.data?.periods || [];
            const tabDetails = planDetails?.planningParameters?.periods?.map((p, index) => ({ value: p.id, label: index === 0 ? 'Default' : ` ${formatDate(p.startDay)} - ${formatDate(p.endDay)}` }));
            const dataToDisplay = tabDetails?.filter((t) => periodIds?.includes(t.value))?.map(({ label }) => label)?.join(',');
            return dataToDisplay;
          },
          headerName: intl.formatMessage(messages.tabs),
          sortable: true,
        }
      ],
    },
    {
      colId: 'createdDate',
      field: 'createdDate',
      filter: 'setFilter',
      menuTabs: ['filterMenuTab', 'generalMenuTab', 'columnsMenuTab'],
      valueFormatter: dateTimeCellFormatter,
      headerName: intl.formatMessage(messages.smartProdRunsCreated),
      sortable: true,
    },
    {
      colId: 'createdBy',
      field: 'createdBy',
      filter: 'setFilter',
      menuTabs: ['filterMenuTab', 'generalMenuTab', 'columnsMenuTab'],
      valueFormatter: ({ value }) => (value ? `${value.firstName} ${value.lastName}` : ''),
      headerName: intl.formatMessage(messages.smartProdRunsCreatedBy),
      sortable: true,
    },
    {
      colId: 'status',
      field: 'status',
      filter: 'setFilter',
      menuTabs: ['filterMenuTab', 'generalMenuTab', 'columnsMenuTab'],
      headerName: intl.formatMessage(messages.smartProdRunsStatus),
      sortable: true,
    },
  ];
  return columns;
};

type Props = {
  formik?: FormikProps<ForecastForm>;
  intl: InjectedIntl;
  forecastEdit: boolean;
  runType: string;
  data: SmartProdRunsType;
  allOmsCodes: EntityEntry[];
  allSmartProdSources: EntityEntry[];
  planningParametersId: number;
  handleSubmitPage: Function;
  handleReloadData: () => void;
  recentlyCreatedRun?: number;
  hasAdjustments: boolean;
  planDetails
};

const TimeTransformationRunTable: React.FC<Props> = ({
  formik,
  intl,
  forecastEdit,
  runType,
  data,
  allOmsCodes,
  allSmartProdSources,
  planningParametersId,
  handleSubmitPage,
  handleReloadData,
  recentlyCreatedRun,
  hasAdjustments,
  planDetails
}) => {
  const [openedDetail, setOpenedDetail] = useState(recentlyCreatedRun);
  const [gridApi, setGridApi] = useState(null);

  useEffect(() => {
    setOpenedDetail(recentlyCreatedRun);
  }, [recentlyCreatedRun]);

  const onGridReady = params => {
    setGridApi(params);
    params.api.sizeColumnsToFit();
  };

  const handleRowClicked = params => {
    if (!params.node.detail && params.column.colId !== 'actions') {
      params.node.setExpanded(!params.node.expanded);
      gridApi.api.onGroupExpandedOrCollapsed();
    }
  };
  const rowData = data.timeTransformation;
  const onRowGroupToggle = params => {
    let isSomeNodeExpanded = params.node.expanded;
    if (params.node.expanded) {
      setOpenedDetail(params.data.id);
      gridApi.api.forEachNode(node => {
        if (node.expanded && node.id !== params.node.id) {
          node.setExpanded(false);
        }
      });
    } else {
      gridApi.api.forEachNode(node => {
        isSomeNodeExpanded = isSomeNodeExpanded || node.expanded;
      });
    }
    if (!isSomeNodeExpanded) {
      setOpenedDetail(null);
    }
  };

  const onFirstDataRendered = params => {
    params.api.forEachNode(node => {
      if (node.data.id === openedDetail) {
        node.setExpanded(true);
      }
    });
  };

  useEffectDeepCompare(() => {
    if (gridApi) {
      const colDefs = columnDefs(formik, forecastEdit, allSmartProdSources, intl, planDetails);
      gridApi.api.setColumnDefs(colDefs);
      gridApi.api.sizeColumnsToFit();
      gridApi.api.forEachNode(node => {
        node.setExpanded(node.data.id === openedDetail);
      });
    }
  }, [gridApi, forecastEdit, data.timeTransformation, planDetails]);

  const DETAIL_ROW_HEIGHT = 800;
  const ROW_HEIGHT = 30;

  return (
      <Table
        masterDetail
        detailCellRenderer="TimeTransformationTableDetailCellRenderer"
        detailCellRendererParams={{
          forecastEdit,
          allOmsCodes,
          allSmartProdSources,
          planningParametersId,
          handleSubmitPage,
          handleReloadData,
          setOpenedDetail,
          runType,
          hasAdjustments,
          planDetails
        }}
        frameworkComponents={{
          TimeTransformationTableDetailCellRenderer: TimeTransformationTableDetailCellRenderer,
        }}
        defaultColDef={{
          flex: 1,
        }}
        sortable
        pagination={false}
        columnDefs={columnDefs(formik, forecastEdit, allSmartProdSources, intl, planDetails)}
        height={80 + ROW_HEIGHT * Math.min(10, rowData.length) + (openedDetail ? DETAIL_ROW_HEIGHT : 0)}
        rowData={rowData}
        rowHeight={ROW_HEIGHT}
        detailRowHeight={DETAIL_ROW_HEIGHT}
        onGridReady={onGridReady}
        onRowGroupOpened={onRowGroupToggle}
        onFirstDataRendered={onFirstDataRendered}
        isRowSelectable={() => false}
        onCellClicked={handleRowClicked}
      />
  );
};

export default injectIntl(TimeTransformationRunTable);
