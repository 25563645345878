import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { DateTime } from 'luxon';
import { FormattedMessage, InjectedIntl, injectIntl } from 'react-intl';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { getToken } from 'containers/App/selectors';

import Button from 'components/Button';
import DialogDHL from 'components/Dialog';
import Label, { LabelStyled } from 'components/Label';
import Select from 'components/StyledSelect';
import { toast } from 'react-toastify';
import messages from 'containers/MHEAssistant/messages';
import {
  ModifyMultipleChangeType,
  MHERedistribute
} from 'containers/MHEAssistant/types';
import { mheRedistributeAPI } from './fetch';

const CheckboxContainer = styled.div`
  margin-top: 12px;

  display: flex;
  align-items: start;
  justify-content: start;

  label,
  > div {
    margin-left: 8px;
    font-size: 14px;
  }

  > input {
    margin-top: 6px;
  }

  > label {
    margin-top: 4px;
  }
`;

const CheckboxDiffContainer = styled.div`
  margin-top: 12px;
  width: 200px;

  display: flex;
  align-items: start;
  justify-content: start;

  label,
  > div {
    margin-left: 8px;
    font-size: 14px;
  }

  > input {
    margin-top: 6px;
  }

  > label {
    margin-top: 4px;
  }
`;

const DivStyledToWidth = styled.div`
  margin-left: 10px;
  display: flex;
  padding-right: 50px;
`;

const StyledSelect = styled(Select)`
  width: 10em;
`;

const StyledButton = styled(Button)`
  height: 28px;
  margin-left: 5px;
`;

const StyledLabel = styled(Label)`
  padding-bottom: 10px;
`;

const StyleSelectActivity = styled(Select)`
  width: 12em;
  padding-top: 8px;
  padding-left: 25px;
`;

const StyledDiv = styled.div`
  padding-top: 13px;
`;

const MHEDiv = styled.div`
display: flex;
`;

const LabelSpan = styled.span`
margin-top: 10px !important;
font-size: 14px !important;
`

const FormWrapper = styled.div`
  overflow:'visible';
  ${CheckboxContainer} {
    input[type='text'] {
      background-color: ${props => props.theme.color.grey5};
      text-align: right;
      width: 95%;
    }
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  min-height: 44px;

  > * {
    margin: 0 20px;
  }

  padding-bottom: 5px;
`;

const DialogButton = ({ children, onClick, ...props }) => (
  <Button color="primary" variant="contained" onClick={onClick} {...props}>
    {children}
  </Button>
);

type Props = {
  intl: InjectedIntl;
  open: boolean;
  closeHandler: Function;
  granularity: string;
  startDate: DateTime;
  endDate: DateTime;
  activityData: any;
  mheData: any;
  planId: any;
  mheIdData: any;
  reloadCaluculation: any,
  transformationType: any,
};

const MHEAssistant: React.FC<Props> = ({
  open,
  closeHandler,
  granularity,
  startDate,
  endDate,
  activityData,
  mheData,
  planId,
  mheIdData,
  reloadCaluculation,
  transformationType,
}) => {
  const checkStates = {};
  activityData?.forEach(row => {
    checkStates[row.key] = false;
  });
  const token: string = useSelector(getToken);
  const dispatch = useDispatch();
  const defaultInitialValues: MHERedistribute = {
    startDate,
    endDate,
    granularity: granularity,
    activityData: checkStates,
    activitySameData: checkStates,
    activityDifferentData: checkStates,
    mheData: mheData,
  };
  const [initialValues, setInitialValues] = useState<MHERedistribute>(defaultInitialValues);
  const [mheSelected, setMHESelected] = useState(false);
  const [mheValueSelectedFlag, setMHEValueSelectedFlag] = useState(false);
  const [redistributeMheType, setredistributeMheType] = useState(false);
  const [submitFlag, setSubmitFlag] = useState(false);
  const [activityValue, setActivityValue] = useState([]);
  const [avtivitySelection, setActivitySelection] = useState('same');
  const [differentActivity, setDifferentActivity] = useState([]);
  const [sameActivity, setSameActivity] = useState([]);
  const handleCancel = () => {
    closeHandler();
  };
  const handleMHEValueChange = (val) => {
    setActivitySelection(avtivitySelection)
    const actData = activityData.filter((a) => a.mhe == val && !a?.data?.activityDetails?.activity?.indirect);
    setActivityValue(actData)
  }

  const submitForm = async (values, actions) => {
    const mheId = mheIdData.byMhe.filter((data) => data.mhe.name == values.mheOption)
    let activityValues = [];
    if (avtivitySelection == 'same') {
      const selectedsameActivity = sameActivity.filter(data => data.checkboxActivity == true);
      activityValues = selectedsameActivity.map((data) => {
        return {
          from: data.from,
        }
      })
    } else {
      activityValues = differentActivity.map((data) => {
        return {
          from: data.from,
          to: data.to
        }
      })
    }
    const payload = {
      "planId": planId,
      "startDay": (values.startDate).toFormat('yyyy-MM-dd'),
      "endDay": (values.endDate).toFormat('yyyy-MM-dd'),
      "granularity": granularity,
      "mheId": mheId[0].mhe.id,
      "activities": activityValues,
      "redistributeMheType": values.changeType
    }
    actions.setSubmitting(false);

    const response = await mheRedistributeAPI(
      token,
      dispatch,
      payload
    );
    if (response.isOk) {
      if(!response?.data?.everythingSolved && response?.data?.overridesCount === 0){
        toast.info('Nothing was redistributed')
      }else if(!response?.data?.everythingSolved && response?.data?.overridesCount > 0){
        toast.info(`Partially redistributed with ${response.data.overridesCount} changes`)
      }else if(response?.data?.everythingSolved && response?.data?.overridesCount === 0){
        toast.info('All are redistributed')
      }else if(response?.data?.everythingSolved && response?.data?.overridesCount > 0){
        toast.info(`All are redistributed with ${response.data.overridesCount} changes`)
      }
      reloadCaluculation(response.data.userChangeId);
    }
  };


  useEffect(() => {
    setInitialValues(defaultInitialValues);
  }, [startDate, endDate, open]);

  useEffect(()=>{
    const selectedsameActivityLength = sameActivity.filter(data => data.checkboxActivity == true);
    const differentActivityLength = differentActivity.filter(data=>data.checkboxActivity && data.dropdownActivity)
    if((selectedsameActivityLength.length > 0 || (differentActivity.length > 0 && differentActivityLength.length == differentActivity.length)) && redistributeMheType){
      setSubmitFlag(true);
    }else{
      setSubmitFlag(false);
    }
  },[sameActivity, differentActivity, redistributeMheType])

  const handlemheSelection = () => {
    setMHESelected(true);
  };

  const handleSameActivity = (option, valueTicked, data, formikBag) => {
    const checkvalue = formikBag.values.activitySameData[data.key];
    const newData = [...sameActivity];
    const dataToPush = {
      key: data.key,
      checkboxActivity: option ? checkvalue : !valueTicked,
      from: {
        "id": 0,
        "activityId": data ? data.data.activityDetails.activity.id : '',
        "departmentId": data ? data?.data?.activityDetails?.department?.id : '',
        "customerId": data ? data?.data?.activityDetails?.customer?.id : '',
        "uomId": data ? data?.data?.activityDetails?.uom?.id : '',
        "productivityRateForecastList": []
      },
    }
    const index = sameActivity && sameActivity.findIndex((e) => e.key === data.key);
    if (index === -1) {
      newData.push(dataToPush);
    } else {
      newData[index] = dataToPush;
    }
    setSameActivity(newData)
  }

  const handleDifferentActivity = (option, valueTicked, data, formikBag, idOption) => {
    const checkvalue = formikBag.values.activityDifferentData[data.key];
    const dropvalue = idOption != 'Select' ? idOption : null;
    const newData = [...differentActivity];
    const dataToPush = {
      key: data.key,
      dropdownActivity: option ? option.value : dropvalue,
      checkboxActivity: option ? checkvalue : !valueTicked,
      from: {
        "id": 0,
        "activityId": data ? data.data.activityDetails.activity.id : '',
        "departmentId": data ? data?.data?.activityDetails?.department?.id : '',
        "customerId": data ? data?.data?.activityDetails?.customer?.id : '',
        "uomId": data ? data?.data?.activityDetails?.uom?.id : '',
        "productivityRateForecastList": []
      },
      to: {
        "id": 0,
        "activityId": option ? option.data.activityDetails.activity.id : '',
        "departmentId": option ? option?.data?.activityDetails?.department?.id : '',
        "customerId": option ? option?.data?.activityDetails?.customer?.id : '',
        "uomId": option ? option?.data?.activityDetails?.uom?.id : '',
        "productivityRateForecastList": []
      }
    }
    const index = differentActivity && differentActivity.findIndex((e) => e.key === data.key);
    const dataToRemove = dataToPush.checkboxActivity == false && dataToPush.dropdownActivity === null;
    if(dataToRemove){
      setDifferentActivity(differentActivity.filter((data)=>data.key !== dataToPush.key));
    }else{
      if (index === -1) {
        newData.push(dataToPush);
      } else {
        newData[index] = dataToPush;
      }
      setDifferentActivity(newData)
    }
  };

  const handleActivitySelection = (val) => {
    const checkStates = {};
    activityData.forEach(row => {
      checkStates[row.key] = false;
    });
    const defaultInitialValues: MHERedistribute = {
      startDate,
      endDate,
      granularity: granularity,
      activityData: checkStates,
      activitySameData: checkStates,
      activityDifferentData: checkStates,
      mheData: mheData,
    }
    setInitialValues(defaultInitialValues);
    setActivitySelection(val);
  };
  return (
    <Formik
      initialValues={initialValues}
      isInitialValid
      enableReinitialize
      validateOnChange
      onSubmit={submitForm}
      render={formikBag => (
        <DialogDHL
          open={!!open}
          isTextContent={false}
          headerLabel={messages.headerMHEAssistant}
          maxWidth="xl"
          CustomButtons={
            <React.Fragment key={1}>
              <DialogButton onClick={formikBag.submitForm} disabled={!submitFlag}>
                <FormattedMessage {...messages.saveButton} />
              </DialogButton>
              <DialogButton onClick={handleCancel}>
                <FormattedMessage {...messages.cancelButton} />
              </DialogButton>
            </React.Fragment>
          }
        >
          <FormWrapper>
            <Wrapper>
              <Label {...messages.startDate} />
              <LabelSpan>{(formikBag.values.startDate).toFormat('MM/dd/yyyy')}</LabelSpan>
              <Label {...messages.endDate} />
              <LabelSpan>{(formikBag.values.endDate).toFormat('MM/dd/yyyy')}</LabelSpan>
              <Label {...messages.granularity} />
              <LabelSpan>{transformationType =="SHIFT" && formikBag.values.granularity == 'WZP' ? 'SHIFT' : formikBag.values.granularity}</LabelSpan>
            </Wrapper>
            <div style={{ marginBottom: '20px', marginLeft: '20px' }}>
              <StyledLabel {...messages.selectHeaderRedistribute} />
              <MHEDiv>
                <DivStyledToWidth>
                  <StyledSelect
                    options={mheData}
                    onChange={option => {
                      formikBag.setFieldValue('mheOption', option.key);
                      formikBag.setFieldValue('activitySameData', checkStates);
                      formikBag.setFieldValue('activityDifferentData', checkStates);
                      setMHEValueSelectedFlag(true);
                      setSameActivity([]);
                      setDifferentActivity([]);
                      handleMHEValueChange(option.value);
                    }}
                    value={mheData.filter(
                      o => o.key === formikBag.values.mheOption,
                    )}
                  />
                  <StyledButton
                    disabled={!mheValueSelectedFlag}
                    onClick={handlemheSelection}
                    color="secondary"
                    variant="contained"
                  >
                    <LabelStyled {...messages.use} />
                  </StyledButton>
                </DivStyledToWidth>
                <StyledButton
                  onClick={() => handleActivitySelection('same')}
                  style={{ background: avtivitySelection == 'same' ? '#FFCC00' : 'lightgray' }}
                  color="secondary"
                  variant="contained"
                >
                  <LabelStyled {...messages.sameActivity} />
                </StyledButton>
                <StyledButton
                  onClick={() => handleActivitySelection('different')}
                  style={{ background: avtivitySelection != 'same' ? '#FFCC00' : 'lightgray' }}
                  color="secondary"
                  variant="contained"
                >
                  <LabelStyled {...messages.differentActivity} />
                </StyledButton>
              </MHEDiv>
              {
                mheSelected && avtivitySelection &&
                <div style={{ marginTop: '10px' }}>
                  <Label {...messages.TextRedistribute} />
                </div>
              }
              {mheSelected && avtivitySelection == 'same' && activityValue?.map(data => (
                <CheckboxContainer
                  key={data.key}
                  onClick={() => {
                    const value = formikBag.values.activitySameData[data.key];
                    handleSameActivity(null, value, data, formikBag)
                    formikBag.setFieldValue(`activitySameData[${data.key}]`, !value);
                    formikBag.setFieldTouched(`activitySameData`, true);
                    formikBag.setFieldValue('activityDifferentData', checkStates);
                    setDifferentActivity([]);
                  }}
                >
                  <input type="checkbox" checked={formikBag.values.activitySameData[data.key]} readOnly />
                  <label>{data.label}</label>
                </CheckboxContainer>
              ))}

              {avtivitySelection == 'different' && mheSelected &&
                activityValue?.map((data) => (
                  <MHEDiv>
                    <CheckboxDiffContainer
                      onClick={() => {
                        const selectedOption = document.getElementById(`${data.value}-${data.key}`).innerText
                        const value = formikBag.values.activityDifferentData[data.key];
                        handleDifferentActivity(null, value, data, formikBag, selectedOption)
                        formikBag.setFieldValue(`activityDifferentData[${data.key}]`, !value);
                        formikBag.setFieldValue('activitySameData', checkStates);
                        formikBag.setFieldTouched(`activityDifferentData`, true);
                        setSameActivity([]);
                      }}
                    >
                      <input type="checkbox" checked={formikBag.values.activityDifferentData[data.key]} readOnly />
                      <label>{data.label}</label>
                    </CheckboxDiffContainer>
                    <StyledDiv><FormattedMessage {...messages.to} /></StyledDiv>
                    <StyleSelectActivity
                      isClearable ={true}
                      isSearchable= {true}
                      id={`${data.value}-${data.key}`}
                      key={`${data.value}-${data.key}`}
                      options={activityData.filter((actData) => (actData.data?.uom == data.data?.uom) && !actData.data?.activityDetails?.activity?.indirect)}
                      onChange={option => {
                        const selectedValue = option && option.key ? option.key : null
                        handleDifferentActivity(option, null, data, formikBag, null)
                        formikBag.setFieldValue('activityOption', selectedValue);
                      }}
                    />
                  </MHEDiv>
                ))
              }
            </div>
            <div style={{ marginLeft: '20px' }}>
              <Label {...messages.headerRedistribute} />
            </div>
            <div style={{ marginLeft: '30px' }}>
              <CheckboxContainer
                onClick={() => {
                  formikBag.setFieldValue('changeType', ModifyMultipleChangeType.START_DATE)
                  setredistributeMheType(true)
                }}
              >
                <input
                  id="changeTypePercentage"
                  type="radio"
                  name="changeType"
                  value={ModifyMultipleChangeType.START_DATE}
                  checked={formikBag.values.changeType === ModifyMultipleChangeType.START_DATE}
                  readOnly
                />
                <label htmlFor="changeTypePercentage">
                  <FormattedMessage {...messages.redistributeFromStartDate} />
                </label>
              </CheckboxContainer>
              <CheckboxContainer onClick={() => {
                formikBag.setFieldValue('changeType', ModifyMultipleChangeType.MHE_SHORTFALL)
                setredistributeMheType(true)
              }}>
                <input
                  id="changeTypeValue"
                  type="radio"
                  name="changeType"
                  value={ModifyMultipleChangeType.MHE_SHORTFALL}
                  checked={formikBag.values.changeType === ModifyMultipleChangeType.MHE_SHORTFALL}
                  readOnly
                />
                <label htmlFor="changeTypeValue">
                  <FormattedMessage {...messages.redistributeShortFall} />
                </label>
              </CheckboxContainer>
              <CheckboxContainer>
                <label>
                  {`If still leftovers it will stay unchanged in the ${transformationType =="SHIFT" && formikBag.values.granularity == 'WZP' ? 'SHIFT' : formikBag.values.granularity} where the leftovers were before.`}
                </label>
              </CheckboxContainer>

            </div>
          </FormWrapper>
        </DialogDHL>
      )}
    />
  );
};

export default injectIntl(MHEAssistant);
