/**
 *
 * PlanningAreaDetailPage
 *
 */

import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Formik, getIn } from 'formik';
import { isEqual } from 'lodash';
import cloneDeep from 'lodash/cloneDeep';
import { Helmet } from 'react-helmet';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Prompt } from 'react-router';
import { bindActionCreators, compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { toast } from 'react-toastify';
import { DateTime } from 'luxon';

import { parserPlanResult, searchToData } from 'utils/url';
import { PATHS } from 'containers/App/constants';
import { makeSelectToken } from 'containers/LoginPage/selectors';
import { withDirtyDialog } from 'components/DirtyDialog/DirtyDialogPA';
import { PaActivitySettingsDirect } from 'components/ActivitySettingsDirect';
import { PaActivitySettingsIndirect } from 'components/ActivitySettingsIndirect';
import BasePage from 'components/BasePage';
import { EditableList } from 'components/CdmEditableList';
import DepartmentItem from 'components/CdmEditableList/DepartmentItem';
import { FacilityItemNoUnits } from 'components/CdmEditableList/FacilityItem';
import { ManagementItemNoUnits } from 'components/CdmEditableList/ManagementItem';
import { isDayOfWeekTransformationActivity, PaDayOfWeekTransformation } from 'components/DayOfWeekTransformation';
import { DetailPageSectionsWrapper, DetailPageFourSectionsWrapper } from 'components/DetailPageShared';
import FormikErrorsPopup from 'components/DetailPageShared/FormikErrorsPopup';
import DialogDHL from 'components/Dialog';
import EffortForecast from 'components/EffortForecast';
import { PaMheAvailability } from 'components/MheAvailability';
import NamedSection from 'components/NamedSection';
import NamedFourSection from 'components/NamedSection/NameFourSection';
import PaShiftsDefinition from 'components/PaShiftsDefinition';
import { PlanningAreaDetailActivitySettings } from 'components/PlanDetailActivitySettings';
import { PlaningAreaDetailBasicInfo } from 'components/PlanDetailBasicInfo';
import { PlanningAreaDetailBasicSettings } from 'components/PlanDetailBasicSettings';
import { PlaningAreaDetailWorkzonePeriod } from 'components/PlanDetailWorkzonePeriod';
import { BudgetVolumeAddLine } from 'components/PlannedVolumeAddLine';
import { BudgetVolumeHeader } from 'components/PlannedVolumeHeader';
import { BudgetVolumeTable } from 'components/PlannedVolumeTable';
import PlanningAreaDetailShifts from 'components/PlanningAreaDetailShifts';
import PlanningAreaDetailToolbar from 'components/PlanningAreaDetailToolbar';
import PlanningAreaLabourAvailability from 'components/PlanningAreaLabourAvailability';
import ToggleSection from 'components/ToggleSection';
import WorkingHours from 'components/WorkingHours';
import {LabourAvailabilityOverviewPlan} from 'components/LabourAvailabilityOverview';
import {MHEAvailabilityOverviewPlan} from 'components/MHEAvailabilityOverview';
import {AdjustmentOverviewPlan} from 'components/AdjustmentsOverview';
import { SECTION, T_TYPE } from 'utils/constants';
import injectSaga from 'utils/injectSaga';
import withSecurity, { PERMISSIONS } from 'utils/security';
import { loadSearch, UrlUpdateWatcher } from 'utils/url';

import styled from 'styled-components';
import { ProductivityRate } from '../../components/ProductivityRate';
import {PaAdjustments} from '../../components/Adjustments';
import { ShiftDistributionWarningDialog } from '../../components/ShiftDistributionWarningDialog';
import StaticSection from '../../components/StaticSection';
import { UnproductiveLocationPa } from '../../components/UnproductiveActivities/location';
import { UnproductiveStaffPa } from '../../components/UnproductiveActivities/staff';
import { selectViewModeStoredData } from '../../components/ViewModePopover/selectors';
import { areSomeShiftDaysRemovedAndUsed, areSomeShiftDaysRemovedAndUsedFlag } from '../../utils/shiftUtils';
import { DayToHourTransformationPa } from '../DayToHourTransformation';
import { DayToWzpTransformationPa } from '../DayToWzpTransformation';
import { PaTabs } from '../Tabs';
import createUploadModal from '../UploadModal';
import {
  clearAreaMHEAction,
  clearMasterPlanConflict,
  loadPlanningArea,
  loadPlanningAreaDetails,
  loadPlanningAreaOverviewData,
  savePlanningArea,
  togglePaSection,
  saveLabourOverView,
  saveMheOverview,
  saveAdjustmentOverview,
  omChangeHistory,
  saveomsChangeHistory,
} from './actions';
import { makeSelectRunningApiCalls } from 'containers/App/selectors';
import messages from './messages';
import saga, { paValidationSchema, addObjectIdIfChanged, addCdmEntityUpdates, extractMus } from './saga';
// eslint-disable-next-line import/named
import {
  selectAreaDepartments,
  selectGuiStateForPa,
  selectOffDaysPA,
  selectPlanningAreaFromPa,
  selectPlanningAreaDetailsLoader,
  selectplanRoles,
  selectPlanSmartCodeMappings,
  selectPlanningAreaDetailsLoaderFlag,
  selectPlanningAreaOverviewLoaderFlag,
  selectomsChangeHistory,
} from './selectors';

const LoadingIndicatorWrap = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  > span {
    padding: 10px;
    min-width: 200px;
    text-align: center;
  }
`;

export const LoadingIndicator = () => {
  return (
    <LoadingIndicatorWrap>
      <div className='loader'></div>
      <FormattedMessage {...messages.planningAreadetailsLoaderFlag} />
    </LoadingIndicatorWrap>
  );
};

const UploadModal = createUploadModal('planningAreaDetail');

import { ACTIVE_ENV } from '../../utils/activeEnv';
import PlanningAreaWarning from './PlanningAreaWarning';

const LoadingWrap = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  > span {
    padding: 10px;
    min-width: 200px;
    text-align: center;
  }
`;

const FacilitySection = styled(NamedFourSection)`
  .react-select__multi-value__label {
    white-space: initial;
  }
`;

export const Loading = () => {
  return (
    <LoadingWrap>
        <div className='loader'></div>
        <FormattedMessage {...messages.planingAreadetailsLoader} />
    </LoadingWrap>
  );
};

/* eslint-disable react/prefer-stateless-function */
class PlanningAreaDetailPage extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { shiftDaysRemovedWarning: false };
  }

  componentWillUnmount() {
    this.props.clearAreaMHEAction();
    this.props.saveomsChangeHistory([]);
  }

  shouldComponentUpdate(nextProps, nextState){
    if(nextState.shiftDaysRemovedWarning !== this.state.shiftDaysRemovedWarning){
      return true
    }
    return !isEqual(nextProps, this.props)
  }

  urlId = () => Number(this.props.match.params.id);

  makeDirtyHandler = (cb) => (values) => {
    if (this.props?.history?.location?.state?.createdFromMasterPlan) {
      const { props } = this;
      const searchObject = this.props.history.location.search && searchToData(this.props.history.location.search, parserPlanResult);
      const fromMasterPlan = searchObject.masterPlanId;
      props.openDirtyDialog(async () => {
        const fullUrl = `${ACTIVE_ENV.basePathBe}/plans/`;
        const fetchOptions = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.props.token}`
          },
          body: JSON.stringify({
            name: `${this.props?.history?.location?.state?.planName}`,
            planningAreaId: this.props?.pa?.id,
            description: '',
            firstDayOfWeek: this.props?.pa?.planningParameters?.firstDayOfWeek,
          }),
        };
        toast.info('Please wait, while we create Plan and assign it to Master Plan')
        fetch(fullUrl, fetchOptions)
          .then(response => {
            if (response.status >= 200 && response.status < 300) {
              return response.json();
            }
            if(!response.isOk){
              toast.dismiss();
              toast.error(`Plan with Name = ${this.props?.history?.location?.state?.planName} already exists but it is disabled or you do not have permission to see it.`);
              if(cb === undefined && values === 'Homepage'){
                this.props.history.push("/");
              }else if(cb === undefined){
                this.props.history.push((PATHS.masterPlanDetail.replace(':id', `${fromMasterPlan}?isEdit=true`)))
              }else{
                cb(values);
              }
            }
            throw response;
          })
          .then(results => {
            const plans = [];
            let validFrom = null;
            let validTo = null;
            if (this.props?.history?.location?.state?.masterPlan?.plans?.length === 0) {
              const newValue = new Date(DateTime.local());
              const year = newValue.toLocaleString("default", { year: "numeric" });
              const month = newValue.toLocaleString("default", { month: "2-digit" });
              const day = newValue.toLocaleString("default", { day: "2-digit" });
              validFrom = year + "-" + month + "-" + day;
              validTo = '2100-01-01';
            }
            const url = `${ACTIVE_ENV.basePathBe}/masterPlan/${this.props?.history?.location?.state?.masterPlan?.id}`;
            plans.push({
              planId: results?.id,
              validFrom: validFrom,
              validTo: validTo
            })
            const fetchOptions = {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${this.props.token}`
              },
              body: JSON.stringify({
                plans: plans
              }),
            };
            fetch(url, fetchOptions)
              .then(response => {
                toast.dismiss();
                if (response.status >= 200 && response.status < 300) {
                  return response.json();
                }
                throw response;
              })
             .then(() => {
              if(cb === undefined && values === 'Homepage'){
                this.props.history.push("/");
              }else if(cb === undefined){
                this.props.history.push((PATHS.masterPlanDetail.replace(':id', `${fromMasterPlan}?isEdit=true`)))
              }else{
                cb(values);
              }
             })
          })
      }, async () => {
        if(cb === undefined && values === 'Homepage'){
          //this.props.history.push("/");
        }else if(cb === undefined){
          //this.props.history.push((PATHS.masterPlanDetail.replace(':id', `${fromMasterPlan}?isEdit=true`)))
        }else{
          //cb(values);
        }
      });
    } else {
      if(cb === undefined && values === 'Homepage'){
        this.props.history.push("/");
      }else if(cb === undefined){
        this.props.history.push((PATHS.masterPlanDetail.replace(':id', `${fromMasterPlan}?isEdit=true`)))
      }else{
        cb(values);
      }
    }
  };

  dirtyHandler = (func) => this.makeDirtyHandler(func);

  onMount = () => {
    const query = loadSearch(this.props);
    const isCopy = query.get('isCopy') === 'true';
    const isEdit = query.get('isEdit') === 'true';
    const id = this.urlId();
    this.props.loadPlanningArea(id, { copy: isCopy, edit: isEdit });
  };

  componentDidUpdate(prevProps) {
    const editMode = (this.props.guiState && this.props.guiState.get('edit')) || false;
    const id = this.urlId();
    if ((prevProps.planningAreaDetailsLoader !== this.props.planningAreaDetailsLoader) && this.props.planningAreaDetailsLoader === false) {
      this.props.loadPlanningAreaDetails({ id, edit: editMode });
    };
    if ((prevProps.planningAreaLoaderFlag !== this.props.planningAreaLoaderFlag) && this.props.planningAreaLoaderFlag === false) {
      this.props.loadPlanningAreaOverviewData();
      this.props.omChangeHistory();
    }
  }

  render() {
    const { intl, pa } = this.props;
    const title = (pa && pa.name) || '';

    // render only when loaded
    return (
      <UrlUpdateWatcher key={this.props.match.params.id} onMount={this.onMount}>
        <BasePage makeDirtyHandler={this.dirtyHandler} createdFromMasterPlan={this.props?.history?.location?.state?.createdFromMasterPlan} labelMessage={pa ? messages.headerBase : messages.headerBaseOnly} labelValues={{ name: title }} history={this.props.history}>
          <Helmet>
            <title>{intl.formatMessage(messages.header, { name: title })}</title>
            <meta name="description" content="Planning Area Detail Page" />
          </Helmet>
          {(pa && pa.id === this.urlId() && this.renderForm(title))}
        </BasePage>
      </UrlUpdateWatcher>
    );
  }

  renderEditableAreas(title, formikBag) {
    const {
      guiState,
      departments,
      intl: { formatMessage },
      hasPerm,
      user,
      save,
      saveLabourOverView,
      saveMheOverview,
      saveAdjustmentOverview,
      offDays,
      viewSettings,
      planningAreaLoaderFlag,
      planningAreaOverviewFlag
    } = this.props;
    const editMode = (guiState && guiState.get('edit')) || false;
    const guiStateObject = guiState ? guiState.toJS() : {};
    const submitError = (guiState && guiState.get('submitError')) || false;
    const tTypeValue = formikBag && getIn(formikBag.values, 'planningParameters.transformationType', T_TYPE.WZP);
    const startDay = formikBag && getIn(formikBag.values, 'planningParameters.startDay');
    const endDay = formikBag && getIn(formikBag.values, 'planningParameters.endDay');
    const planningParametersId = getIn(formikBag.values, 'planningParameters.id');
    const isAdjustmentsChecked = formikBag && getIn(formikBag.values, 'planningParameters.hasActivityAdjustments');
    const initialAdjustmentsChecked = formikBag && getIn(formikBag.initialValues, 'planningParameters.hasActivityAdjustments');
    const activityForecastList =
      formikBag &&
      getIn(formikBag.values, 'planningParameters.activityForecastTable.rowData', []).map(activity => ({
        label: activity.activity,
        value: activity.id,
        uom: activity.uom,
        ForecastOutOfPlanDateInterval: activity?.ForecastOutOfPlanDateInterval,
      }));
    const isForecastEditor = hasPerm(PERMISSIONS.VIEW_FORECAST_SECTION);
    // TODO: take from formikBag
    const isShift = tTypeValue === T_TYPE.SHIFT;
    const getFormikDataPathFun = periodIndex => `planningParameters.periods.${periodIndex}.labourCategoryParameters`;
  const getFormikDataPath = () => getFormikDataPathFun(guiState.get('periodIndex'));
  const  calculateUsedLabourCategoriesIds = props => {
  const shiftsPath = `planningParameters.periods.${props.periodIndex}.shifts`;
  const shifts = getIn(formikBag.values, shiftsPath, []);
  const result = new Set();
  shifts.forEach(shift => {
    const transitions = shift.labourCategoryTransitions || [];
    transitions.forEach(transition => {
      if (transition && transition.labourCategory) {
        result.add(transition.labourCategory.id);
      }
    });
  });
  return [...result];
};
  const usedLabourCategories = calculateUsedLabourCategoriesIds(this.props);
  const labourAvailabilityData = cloneDeep(getIn(formikBag.values, getFormikDataPath(), [])).map(row => ({
    ...row,
    used: usedLabourCategories.includes(row.labourCategory.id),
  })) || [];
  const fieldPath = (periodIndex) => `planningParameters.periods.${periodIndex}.mheAvailabilities`;
  let mheAvailablilityData = (getIn(formikBag.values, fieldPath(guiState.get('periodIndex')), []) || []).map((it, index) => ({ ...it, index })) || [];
  const getShiftFormikDataPath = (periodIndex) => `planningParameters.periods.${periodIndex}.shifts`;
  const ShiftWeeklyvalue = getIn(formikBag.values, getShiftFormikDataPath(guiState.get('periodIndex')));
  const ShiftWeeklyData = ShiftWeeklyvalue && ShiftWeeklyvalue.every(s=> s && s.labourCategoryTransitions && s.labourCategoryTransitions.length == 0);
  const activitySettingsData = formikBag.values && formikBag.values.planningParameters && formikBag.values.planningParameters.periods && formikBag.values.planningParameters.periods.filter((ap)=> ap.apCalculated && ap.apCalculated.direct && ap.apCalculated.direct.length > 0);
    const facilityRemoveValidation = () => {
      if (formikBag.values && formikBag.values.facilities &&formikBag.values.facilities.length === 1) {
        const error = <FormattedMessage {...messages.errorLastFacility} />;
        toast.error(error);
        return false;
      }
      return true;
    }

    const muRemoveValidation = () => {
      if (formikBag.values && formikBag.values.managementUnits &&formikBag.values.managementUnits.length === 1) {
        const error = <FormattedMessage {...messages.errorLastMU} />;
        toast.error(error);
        return false;
      }
      return true;
    }
    const omsChangeHistoryWarnings = this?.props?.omsChangeHistory?.filter((o)=>o.jobCodeName);
    return (
      <>
        <PlanningAreaDetailToolbar title={title} planningAreaOverviewFlag={planningAreaOverviewFlag} makeDirtyHandler={this.dirtyHandler} createdFromMasterPlan={this.props?.history?.location?.state?.createdFromMasterPlan}/>
        <DetailPageSectionsWrapper>
          <NamedSection message={messages.basicInfo}>
            <PlaningAreaDetailBasicInfo formik={formikBag} />
          </NamedSection>
          <NamedSection message={messages.basicSettings}>
            <PlanningAreaDetailBasicSettings />
          </NamedSection>
          <NamedSection message={isShift ? messages.shifts : messages.workingHours}>
            <WorkingHours values={formikBag.values} formatMessage={formatMessage} />
          </NamedSection>
          </DetailPageSectionsWrapper>
          <DetailPageFourSectionsWrapper>
          <FacilitySection message={messages.facilities}>
            <EditableList
              field="facilities"
              editComponent={FacilityItemNoUnits}
              editComponentField="managementUnits"
              isEdit={this.props?.history?.location?.state?.createdFromMasterPlan ? false : editMode}
              validationOnItemRemoved={facilityRemoveValidation}
            />
          </FacilitySection>
          <NamedFourSection message={messages.managementUnits}>
            <EditableList editComponent={ManagementItemNoUnits} field="managementUnits" isEdit={this.props?.history?.location?.state?.createdFromMasterPlan ? false : editMode}/>
          </NamedFourSection>
          <NamedFourSection  message={messages.customers}>
            <EditableList field="customers" isEdit={this.props?.history?.location?.state?.createdFromMasterPlan ? false : editMode} />
          </NamedFourSection>
          <NamedFourSection message={messages.departments}>
            <EditableList
              field="planningParameters.departments"
              editComponent={DepartmentItem}
              delegateNewItemRender
              isEdit={editMode}
            />
          </NamedFourSection>
        </DetailPageFourSectionsWrapper>
        <PlanningAreaWarning omsChangeHistory={omsChangeHistoryWarnings}/>  
        {viewSettings && viewSettings.showVolumeSections && (
          <ToggleSection message={messages.budgetVolume} {...this.toggleProps(SECTION.BUDGET)}>
            {(planningAreaLoaderFlag === true || planningAreaLoaderFlag === undefined) ? <LoadingIndicator /> :
              <>
                <BudgetVolumeHeader />
                {editMode && <BudgetVolumeAddLine />}
                <BudgetVolumeTable />
              </>
            }
          </ToggleSection>
        )}
        {!isDayOfWeekTransformationActivity(formikBag) &&
          isForecastEditor &&
          viewSettings &&
          viewSettings.showHoursSections && (
            <ToggleSection message={messages.effortForecast} {...this.toggleProps(SECTION.EFFORT_FORECAST)}>
              {(planningAreaOverviewFlag === true || planningAreaOverviewFlag === undefined) ? <LoadingIndicator /> :
                <EffortForecast
                  formik={formikBag}
                  startDay={startDay}
                  endDay={endDay}
                  planningParametersId={planningParametersId}
                  activityForecastList={activityForecastList}
                  edit={guiState.get('edit')}
                  planningParameters={formikBag.values.planningParameters}
                />
              }
            </ToggleSection>
          )}
        <PaTabs name="planningAreaDetail" editing={editMode}>
          {!isDayOfWeekTransformationActivity(formikBag) && (
            <ToggleSection
              message={messages.volumeCategoryTimeTrans}
              {...this.toggleProps(SECTION.VC_TIME_TRANS)}
              subSection
            >
              <PaDayOfWeekTransformation />
            </ToggleSection>
          )}
          {isShift ? (
            <ToggleSection message={messages.shiftDefinition} {...this.toggleProps(SECTION.SHIFT)} subSection>
              <PaShiftsDefinition
                values={formikBag.values}
                periodIndex={guiState.get('periodIndex')}
                formik={formikBag}
                isLoading = {this.props.isLoading}
              />
            </ToggleSection>
          ) : (
            <ToggleSection message={messages.workZonePeriod} {...this.toggleProps(SECTION.WZP)} subSection>
              <PlaningAreaDetailWorkzonePeriod />
            </ToggleSection>
          )}

          <ToggleSection message={messages.activitySettings} {...this.toggleProps(SECTION.ACTIVITY)} subSection>
            {(planningAreaLoaderFlag === true || planningAreaLoaderFlag === undefined) ? <LoadingIndicator /> :
            <PlanningAreaDetailActivitySettings>
              <StaticSection message={messages.directActivities} {...this.toggleProps(SECTION.ACTIVITY)} subSection>
                <PaActivitySettingsDirect formik={formikBag} roles={this.props.planRoles} isPA />
              </StaticSection>
              <StaticSection message={messages.indirectActivities} {...this.toggleProps(SECTION.ACTIVITY)} subSection>
                <PaActivitySettingsIndirect formik={formikBag} roles={this.props.planRoles} isPA />
                {!ACTIVE_ENV.shiftFillingDisabled && (
                  <UnproductiveLocationPa formik={formikBag} guiState={guiStateObject} />
                )}
                {!ACTIVE_ENV.shiftFillingDisabled && (
                  <UnproductiveStaffPa formik={formikBag} guiState={guiStateObject} />
                )}
              </StaticSection>
            </PlanningAreaDetailActivitySettings>
            }
          </ToggleSection>
          <ToggleSection message={messages.ttTables} {...this.toggleProps(SECTION.TIME)} subSection>
            {isDayOfWeekTransformationActivity(formikBag) && <PaDayOfWeekTransformation />}
            {formikBag.values.planningParameters.transformationType === 'HOUR' && <DayToHourTransformationPa />}
            {(formikBag.values.planningParameters.transformationType === 'WZP' || isShift) && (
              <DayToWzpTransformationPa />
            )}
          </ToggleSection>
          { isAdjustmentsChecked && initialAdjustmentsChecked &&
            <ToggleSection message={messages.activityAdjustments} {...this.toggleProps(SECTION.ADJUSTMENTS)} subSection>
              {(planningAreaLoaderFlag === true || planningAreaLoaderFlag === undefined) ? <LoadingIndicator /> : <PaAdjustments />}
            </ToggleSection>
          }
          {viewSettings.checkboxLabourAvailable && (
            <ToggleSection message={messages.labourAvailabilityTitle} {...this.toggleProps(SECTION.LABOUR)} subSection>
              {(planningAreaLoaderFlag === true || planningAreaLoaderFlag === undefined) ? <LoadingIndicator /> :
                <>
                  <PlanningAreaLabourAvailability formik={formikBag} />
                  <PlanningAreaDetailShifts
                    values={formikBag.values}
                    periodIndex={guiState.get('periodIndex')}
                    formik={formikBag}
                    isShift={isShift}
                    roles={this.props.planRoles}
                  />
                </>
              }
            </ToggleSection>
          )}
          {viewSettings.checkboxMHE && (
            <ToggleSection message={messages.mheAvailability} {...this.toggleProps(SECTION.MHE)} subSection>
              {(planningAreaLoaderFlag === true || planningAreaLoaderFlag === undefined) ? <LoadingIndicator /> : <PaMheAvailability departments={departments} />}
            </ToggleSection>
          )}
        </PaTabs>
        <ToggleSection subtitleToShow={activitySettingsData.length > 0 ? '' : messages.productivityError} message={messages.unitsPerHour} {...this.toggleProps(SECTION.PROD_RATE)}>
          {(planningAreaLoaderFlag === true || planningAreaLoaderFlag === undefined) ? <LoadingIndicator /> :
            <ProductivityRate isEdit={editMode} formik={formikBag} save={save} />
          }
        </ToggleSection>
        <ToggleSection subtitleToShow={(labourAvailabilityData.length > 0 && !ShiftWeeklyData) ? '' : messages.subTiTleError} message={messages.labourAvailabilityOverview} {...this.toggleProps(SECTION.LABOUR_AVAILABILITY_OVERVIEW)}>
          {(planningAreaOverviewFlag === true || planningAreaOverviewFlag === undefined) ? <LoadingIndicator /> :
            <LabourAvailabilityOverviewPlan isEdit={editMode} formik={formikBag} save={save} offDays={offDays} saveLabourOverView={saveLabourOverView} />
          }
        </ToggleSection>
        <ToggleSection subtitleToShow={mheAvailablilityData && mheAvailablilityData.length > 0 ? '' : messages.mheError} message={messages.mheAvailabilityOverview} {...this.toggleProps(SECTION.MHE_AVAILABILITY_OVERVIEW)}>
          {(planningAreaOverviewFlag === true || planningAreaOverviewFlag === undefined) ? <LoadingIndicator /> :
            <MHEAvailabilityOverviewPlan isEdit={editMode} formik={formikBag} save={save} offDays={offDays} saveMheOverview={saveMheOverview} periodIndex={guiState.get('periodIndex')} />
          }
        </ToggleSection>
      { isAdjustmentsChecked && initialAdjustmentsChecked &&
          <ToggleSection message={messages.adjustmentOverview} {...this.toggleProps(SECTION.ADJUSTMENTS_OVERVIEW)}>
            {(planningAreaOverviewFlag === true || planningAreaOverviewFlag === undefined) ? <LoadingIndicator /> :
              <AdjustmentOverviewPlan isEdit={editMode} formik={formikBag} save={save} offDays={offDays} saveAdjustmentOverview={saveAdjustmentOverview} isForecastEditor={isForecastEditor} />
            }
          </ToggleSection>
      }
      </>
    );
  }

  renderForm(title) {
    const { intl, pa, guiState, user } = this.props;
    const masterPlanConflict = guiState.get('masterPlanConflict');
    const { shiftDaysRemovedWarning } = this.state;
    const setShiftDaysRemovedWarning = value => this.setState({ shiftDaysRemovedWarning: value });
    return (
      <Formik
        initialValues={cloneDeep(pa)}
        enableReinitialize
        validateOnChange={false}
        validationSchema={paValidationSchema(intl)}
        onSubmit={(values, formikBag) => {
          const shiftCollisionDecided = !!values.planningParameters.redistributeActivityTransformation;
          if (
            shiftCollisionDecided ||
            !areSomeShiftDaysRemovedAndUsed(pa.planningParameters, values.planningParameters)
          ) {
            this.props.save(values);
          } else {
            setShiftDaysRemovedWarning(true);
          }
          formikBag.setSubmitting(false);
        }}
        render={formikBag => (
          <Form>
            {this.renderEditableAreas(title, formikBag)}
            <Prompt when={formikBag.dirty} message={intl.formatMessage(messages.confirmDirty)} />
            <UploadModal
              entity="planningAreas"
              currentId={pa && pa.id}
              intl={intl}
              user={user}
              isAuditor={this.props.hasPerm(PERMISSIONS.HISTORY_AREA)}
            />
            <FormikErrorsPopup />

            {/* If there is a conflict with another master plan, user must confirm whether to overwrite it */}
            <DialogDHL
              open={masterPlanConflict != null}
              headerLabel={messages.masterPlanConflict}
              confirmLabel={messages.changeAnyway}
              onCancel={this.props.clearMasterPlanConflict}
              onConfirm={() => {
                this.props.clearMasterPlanConflict();
                formikBag.setFieldValue('forceUpdateMaster', 'true');
                formikBag.submitForm();
              }}
            >
              <FormattedMessage
                {...messages.masterPlanConflictDescription}
                values={{ names: masterPlanConflict && masterPlanConflict.masterPlansInCollision.join(', ') }}
              />
            </DialogDHL>
            <ShiftDistributionWarningDialog
              stateHook={[shiftDaysRemovedWarning, setShiftDaysRemovedWarning]}
              formikBag={formikBag}
            />
          </Form>
        )}
      />
    );
  }

  toggleProps(sectionName) {
    const expanded = this.props.guiState.get('expanded');
    return {
      expanded: expanded.get(sectionName),
      onToggle: () => this.props.toggleSection(sectionName),
    };
  }
}

PlanningAreaDetailPage.propTypes = {
  intl: PropTypes.object,
  pa: PropTypes.object,
  match: PropTypes.object,
  loadPlanningArea: PropTypes.func,
  loadPlanningAreaDetails: PropTypes.func,
  loadPlanningAreaOverviewData: PropTypes.func,
  clearAreaMHEAction: PropTypes.func,
  saveomsChangeHistory: PropTypes.func,
  guiState: PropTypes.object,
  save: PropTypes.func,
  toggleSection: PropTypes.func,
  omChangeHistory: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
  pa: selectPlanningAreaFromPa,
  guiState: selectGuiStateForPa,
  departments: selectAreaDepartments,
  viewSettings: selectViewModeStoredData,
  offDays: selectOffDaysPA,
  planningAreaDetailsLoader: selectPlanningAreaDetailsLoader,
  planningAreaLoaderFlag: selectPlanningAreaDetailsLoaderFlag,
  planningAreaOverviewFlag: selectPlanningAreaOverviewLoaderFlag,
  planRoles: selectplanRoles,
  planSmartCodeMappings: selectPlanSmartCodeMappings,
  isLoading: makeSelectRunningApiCalls(),
  token: makeSelectToken(),
  omsChangeHistory: selectomsChangeHistory,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      loadPlanningArea,
      loadPlanningAreaDetails,
      loadPlanningAreaOverviewData,
      clearAreaMHEAction,
      save: savePlanningArea,
      toggleSection: togglePaSection,
      clearMasterPlanConflict,
      saveLabourOverView,
      saveMheOverview,
      saveAdjustmentOverview,
      omChangeHistory,
      saveomsChangeHistory,
    },
    dispatch,
  );
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withSaga = injectSaga({ key: 'planningAreaDetailPage', saga });

export default compose(
  withSaga,
  withConnect,
  injectIntl,
  withDirtyDialog,
  withSecurity(PERMISSIONS.VIEW_AREA_DETAILS),
)(PlanningAreaDetailPage);
