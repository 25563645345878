import { Component } from 'react';
import * as React from 'react';
import { Field, FormikProps } from 'formik';
import { isEqual } from 'lodash';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';

import Label from 'components/Label';
import { FormikCheckbox } from 'components/Menu/ItemSelectable';
import ToggleSection from 'components/ToggleSection';

import messages from '../messages';
import { getToken } from '../selectors';
import { ApiSchedule } from '../types';
import { convertArrayToMap } from '../utils';
import ActivityTransferCostsTable from './activityTransferCosts';
import DepartmentTransferCostsTable from './departmentTransferCosts';
import FacilityTransferCostsTable from './facilityTransferCosts';
import { getDepartmentCostId, getFacilityCostId } from './utils';

const NestedTableFullWidthToggleSection = styled(ToggleSection)`
  min-width: 1140px;
  margin-bottom: 10px;
  :last-child {
    margin-bottom: 50px;
  }

  .fromCellColor {
    background-color: rgba(196, 223, 185, 0.44);
  }

  .toCellColor {
    background-color: rgba(223, 195, 208, 0.44);
  }
`;

const TextRow = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: 184px 35px auto 200px;
  padding: 0 15px;
  height: 22px;
`;

const SaveWarningWrapper = styled.span`
  padding-left: 45px;
  color: red;
`;

interface AllTransferCostsProps {
  dispatch;
  intl: any;
  formik: FormikProps<ApiSchedule>;
  isEdit: boolean;
  token?: string;
}

class AllTransferCosts extends Component<AllTransferCostsProps, {}> {
  public shouldComponentUpdate(
    nextProps: Readonly<AllTransferCostsProps>,
    nextState: Readonly<{}>,
    nextContext: any,
  ): boolean {
    const thisValues = this.props.formik.values;
    const nextValues = nextProps.formik.values;

    return (
      this.props.isEdit !== nextProps.isEdit ||
      thisValues.changesCounter !== nextValues.changesCounter ||
      thisValues.version !== nextValues.version ||
      !isEqual(thisValues.facilityTransferCostsMap, nextValues.facilityTransferCostsMap) ||
      !isEqual(thisValues.departmentTransferCostsMap, nextValues.departmentTransferCostsMap) ||
      !isEqual(thisValues.activityTransferCostsMap, nextValues.activityTransferCostsMap)
    );
  }

  public render() {
    const {
      formik,
      formik: { values },
      isEdit,
      intl: { formatMessage },
    } = this.props;

    const idToFacilityCost = convertArrayToMap(values.facilityTransferCostsMap.listValues(), getFacilityCostId);
    const idToDepartmentCost = convertArrayToMap(values.departmentTransferCostsMap.listValues(), getDepartmentCostId);

    const changesCounter =
      values.changesCounter > 10 ? (
        <SaveWarningWrapper key="changesCounter">
          {formatMessage(messages.unsavedCount, { count: values.changesCounter })}
        </SaveWarningWrapper>
      ) : (
        <span />
      );

    const textRow = (
      <TextRow key="textRow">
        <Label {...messages.unproductive} />
        <Field component={FormikCheckbox} name="transferCostUnproductive" disabled={!this.props.isEdit} />
        {changesCounter}
        <div key="hint">{formatMessage(messages.disableHint)}</div>
      </TextRow>
    );

    return [
      textRow,
      <NestedTableFullWidthToggleSection key="facilities" message={messages.facilityTransferCosts} expanded>
        <FacilityTransferCostsTable
          formik={formik}
          isEdit={isEdit}
          idToDepartmentCost={idToDepartmentCost}
          idToFacilityCost={idToFacilityCost}
          token={this.props.token}
        />
      </NestedTableFullWidthToggleSection>,
      <NestedTableFullWidthToggleSection key="deps" message={messages.departmentTransferCosts} expanded>
        <DepartmentTransferCostsTable
          formik={formik}
          isEdit={isEdit}
          idToDepartmentCost={idToDepartmentCost}
          idToFacilityCost={idToFacilityCost}
          token={this.props.token}
        />
      </NestedTableFullWidthToggleSection>,
      <NestedTableFullWidthToggleSection key="activity" message={messages.smartShiftJobTransferCosts} expanded>
        <ActivityTransferCostsTable
          dispatch={this.props.dispatch}
          formik={formik}
          isEdit={isEdit}
          idToFacilityCost={idToFacilityCost}
          idToDepartmentCost={idToDepartmentCost}
          token={this.props.token}
        />
      </NestedTableFullWidthToggleSection>,
    ];
  }
}

const mapStateToProps = createStructuredSelector({
  token: getToken,
});

export default compose(
  injectIntl,
  connect(mapStateToProps, null),
  // @ts-ignore
)(AllTransferCosts);
