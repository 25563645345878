// @flow

import React, { useEffect, useState } from 'react';
import { useSelectors } from 'use-redux';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { withTheme } from 'styled-components';
import ReactEcharts from 'echarts-for-react';

import ResultMatrixDateOptions from 'components/ResultMatrixDateOptions';
import ToggleButton from 'components/ToggleButton';
import BarGraph from 'components/BarGraph';
import { getToken } from 'containers/App/selectors';
import {
  loadFromStorageById,
  saveToStorageById,
  fetchCalculations,
  calculateHeadsGraph,
  getGraphUnit,
  getSectionName,
  filterAndStoreHeadsGraph,
} from 'containers/PlanResultPage/utils';
import { HEADS_SETTINGS_KEY, HEADS_COLUMN_SETTINGS_KEY } from 'containers/PlanGraphs/constants';
import messages from './messages';
import { ButtonBar } from './styled';
import { getHeadsGraphData } from './selectors';

type PropsType = {
  isShift: boolean,
  settings: Object,
  dispatch: Function,
  intl: {
    formatMessage: Function,
  },
  searchData: {
    showEffort: boolean,
    showHeads: boolean,
    showHeadsOpt: boolean,
  },
};

const formatName = (intl: Object) => item => {
  const activity = item.name.match(/^(.*)_.*_.*$/)[1];
  const sectionName = getSectionName(item.name);
  return `${intl.formatMessage(messages[sectionName])} ${activity}`;
};

const labelOptions = {
  show: true,
  position: 'top',
  distance: 5,
  align: 'left',
  verticalAlign: 'middle',
  align: 'left',
  padding: [5, 2, 5, 2],
  borderWidth: 1,
  borderColor: '#ccc',
  borderRadius: 3,
  rotate: 90,
  fontSize: 11,
  backgroundColor: 'white',
  color: 'black',
};

function HeadsGraph(props: PropsType) {
  const { isShift = false, dispatch, intl, login, planId } = props;
  const [token, graphData] = useSelectors(getToken, getHeadsGraphData);
  const [settings, setSettings] = useState((graphData && graphData.settings) || props.settings);
  const savedSettings = loadFromStorageById(login, planId, HEADS_SETTINGS_KEY);
  const colSettings = loadFromStorageById(login, planId, HEADS_COLUMN_SETTINGS_KEY);
  const [showEffort, setShowEffort] = useState(colSettings ? colSettings.showEffort : true);
  const [showHeads, setShowHeads] = useState(colSettings ? colSettings.showHeads : true);
  const [showHeadsOpt, setShowHeadsOpt] = useState(colSettings ? colSettings.showHeadsOpt : true);
  const activitiesNum = (graphData && graphData.results && graphData.results.activitiesNum) || 0;
  const [showLabels, setShowLabels] = useState(colSettings ? colSettings.showLabels : true);

  let series = null;
  function getOption(opt) {
    series = opt.series;
  }

  const sectionTitles = [
    [intl.formatMessage(messages.headsOpt), 'headsOpt'],
    [intl.formatMessage(messages.effort), 'effort'],
    [intl.formatMessage(messages.heads), 'heads'],
  ];

  useEffect(() => {
    const stgs = { showEffort, showHeads, showHeadsOpt, showLabels };
    filterAndStoreHeadsGraph(graphData.results, stgs, dispatch);
    saveToStorageById(login, planId, stgs, HEADS_COLUMN_SETTINGS_KEY);
  }, [showEffort, showHeads, showHeadsOpt]);

  useEffect(() => {
    const stgs = { showEffort, showHeads, showHeadsOpt, showLabels };
    saveToStorageById(login, planId, stgs, HEADS_COLUMN_SETTINGS_KEY);
  }, [showLabels]);

  async function calculateGraph(values: Object) {
    saveToStorageById(login, planId, values, HEADS_SETTINGS_KEY);
    const calculations = await fetchCalculations(values, token, dispatch, true, false);
    if (calculations) {
      calculateHeadsGraph(calculations, values, { showEffort, showHeads, showHeadsOpt }, dispatch);
    }
    setSettings(values);

    return false;
  }

  const labelFormatter = (sectionName, selectedSeries) => params => {
    if (!series) return;
    const fSeries = series.filter(s => s.stack === sectionName && (!selectedSeries || selectedSeries[s.name]));
    function getSum(total, item) {
      return total + item.data[params.dataIndex];
    }
    const sum = fSeries.reduce(getSum, 0);

    return `${intl.formatMessage(messages[sectionName])}: ${sum && Math.round(sum)}`;
  };

  function tooltip(params) {
    let unit = getGraphUnit(params);
    if (unit) {
      unit = intl.formatMessage(messages[unit]);
    }
    const marker = params.marker.replace('[object Object]', params.color.colorStops[0].color);
    const { seriesId } = params;
    return `${marker} ${formatName(intl)({ name: seriesId })}: ${params.value} ${unit}`;
  }

  const labels = {};
  function getLabel(item, index) {
    const show = activitiesNum > 0 && index % activitiesNum === activitiesNum - 1;
    if (show) {
      labels[`${index}`] = show;
    }
    // const formatter = item.sectionName
    return { ...labelOptions, formatter: labelFormatter(item.sectionName, null), show };
  }

  let myChart = null;
  function onChartReady(chart) {
    myChart = chart;
  }

  function findIndex(selected, key, labels, index) {
    const keys = Object.keys(selected);
    const newIndex = index - 1;
    if (newIndex >= 0 && !labels[`${newIndex}`] && selected[keys[newIndex]]) return newIndex;
    if (newIndex < 0 || labels[`${newIndex}`]) return false;
    return findIndex(selected, key, labels, newIndex);
  }

  function onMagicTypeChanged(params) {
    if (!myChart) return;
    if (params.newOption && params.newOption.series && params.newOption.series.length > 0) {
      const { series } = params.newOption;
      const stackType = series[0].stack;
      const newSeriesOptions = [];
      if (stackType === '__ec_magicType_stack__') {
        series.map(s =>
          newSeriesOptions.push({
            label: {
              rotate: 0,
              position: 'right',
              align: 'right',
              color: 'black',
              verticalAlign: 'top',
              backgroundColor: 'white',
            },
          }),
        );
      } else {
        series.map(s => newSeriesOptions.push({ label: { ...labelOptions, show: s.label.show } }));
      }
      myChart.setOption({ series: newSeriesOptions });
    }
  }

  function onRestore(params) {
    if (!myChart) return;
    const options = myChart.getOption();
    const newSeries = [];
    options.series.map(s => newSeries.push({ label: { ...labelOptions, show: s.label.show } }));
    myChart.setOption({ series: newSeries });
  }

  function getSectionAndName(title) {
    for (let i = 0; i < sectionTitles.length; i++) {
      const stitle = sectionTitles[i][0];
      if (title.startsWith(stitle)) return { section: sectionTitles[i][1], name: title.substr(stitle.length).trim() };
    }
  }

  function onLegendselectchanged(params) {
    if (!myChart || !showLabels) return;
    let index = 0;
    const { selected } = params;
    const parsed = getSectionAndName(params.name);
    const newLabels = { ...labels };
    for (const key in selected) {
      const lk = `${index}`;
      if (lk in labels && selected[key] === false) {
        const newIndex = findIndex(selected, key, labels, index);
        if (newIndex !== false) {
          newLabels[`${newIndex}`] = labels[lk];
        }
        labels[lk] = false;
        delete newLabels[lk];
      } else if (labels[lk] === false && selected[key] === true) {
        newLabels[lk] = true;
        labels[lk] = true;
      }
      index += 1;
    }

    const newSeriesOpts = [];
    const l = Object.keys(selected).length;
    for (let i = 0; i < l; i++) {
      if (`${i}` in newLabels) {
        newSeriesOpts.push({
          label: { show: true, formatter: labelFormatter(series && series[i].stack, selected) },
        });
      } else {
        newSeriesOpts.push({
          label: { show: false, formatter: labelFormatter(series && series[i].stack, selected) },
        });
      }
    }
    myChart && myChart.setOption({ series: newSeriesOpts });
  }

  return (
    <>
      <ResultMatrixDateOptions mheCheckbox={false} onConfirm={calculateGraph} isShift={isShift} {...settings} />
      <ButtonBar>
        <ToggleButton input={{ value: showEffort, onChange: () => setShowEffort(!showEffort) }}>
          <FormattedMessage {...messages.effort} />
        </ToggleButton>
        <ToggleButton input={{ value: showHeads, onChange: () => setShowHeads(!showHeads) }}>
          <FormattedMessage {...messages.heads} />
        </ToggleButton>
        <ToggleButton input={{ value: showHeadsOpt, onChange: () => setShowHeadsOpt(!showHeadsOpt) }}>
          <FormattedMessage {...messages.headsOpt} />
        </ToggleButton>
        <div style={{ marginLeft: 'auto' }}>
          <ToggleButton input={{ value: showLabels, onChange: () => setShowLabels(!showLabels) }}>
            <FormattedMessage {...messages.showLabels} />
          </ToggleButton>
        </div>
      </ButtonBar>
      {graphData && (
        <BarGraph
          spaceBeforeGraph={items => Math.floor(items / 5) * 25 + 100}
          heightPerData={items => Math.floor(items / 5) * 25}
          seriesNameFormatter={formatName(intl)}
          tooltipFormatter={params => `${params[0].axisValueLabel}<br/> ${params.map(tooltip).join('<br/>')}`}
          {...{ ...graphData.results, data: graphData.filteredData }}
          label={showLabels && getLabel}
          onChartReady={onChartReady}
          getOption={getOption}
          otherProps={{
            onEvents: {
              legendselectchanged: onLegendselectchanged,
              restore: onRestore,
            },
          }}
        />
      )}
    </>
  );
}

export default connect()(withTheme(injectIntl(HeadsGraph)));
