import indexOf from 'lodash/indexOf';
import slice from 'lodash/slice';

import { DAY_NAMES } from 'utils/calendar/constants';

export function sortByWeekStart(days, start) {
  if (start === undefined) {
    start = DAY_NAMES[0];
  }
  const index = indexOf(days, start);
  const putAtStart = slice(days, index);
  const putAtEnd = slice(days, 0, index);
  return putAtStart.concat(putAtEnd);
}

export function disableOffDays(offdays, toggleDayAction) {
  if (offdays)
    offdays.forEach(item => {
      toggleDayAction(item, false);
    });
  DAY_NAMES.filter(item => !offdays.includes(item)).forEach(item => {
    toggleDayAction(item, true);
  });
}
