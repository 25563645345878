import { defineMessages } from 'react-intl';

export default defineMessages({
  back: {
    defaultMessage: 'Back',
    id: 'app.containers.ShiftScheduleInterfacesPage.back',
  },
  basicInfo: {
    defaultMessage: 'Basic Information',
    id: 'app.containers.ShiftScheduleInterfacesPage.basicInfo',
  },
  confirmDirty: {
    defaultMessage: 'There are unsaved changes at this activity. Do you want to leave the page without saving them?',
    id: 'app.containers.ShiftScheduleInterfacesPage.confirmDirty',
  },
  description: {
    defaultMessage: 'Description',
    id: 'app.containers.ShiftScheduleInterfacesPage.description',
  },
  header: {
    defaultMessage: 'Interfaces - {name}',
    id: 'app.containers.ShiftScheduleInterfacesPage.header',
  },
  hyperFind: {
    defaultMessage: 'Hyperfind Query Name for Staff',
    id: 'app.containers.ShiftScheduleInterfacesPage.hyperFind',
  },
  kronos: {
    defaultMessage: 'SmartShift',
    id: 'app.containers.ShiftScheduleInterfacesPage.kronos',
  },
  name: {
    defaultMessage: 'Name',
    id: 'app.containers.ShiftScheduleInterfacesPage.name',
  },
  error: {
    defaultMessage: 'Error',
    id: 'app.containers.ShiftScheduleInterfacesPage.error',
  },
  url: {
    defaultMessage: 'URL',
    id: 'app.containers.ShiftScheduleInterfacesPage.url',
  },
});
