/*
 *
 * UploadModal reducer
 *
 */

import { fromJS } from 'immutable';
import {
  STORE_UPLOADS,
  OPEN_MODAL,
  CLOSE_MODAL,
  EXPAND_HISTORY,
  COLLAPSE_HISTORY,
  STORE_UPLOAD_INFO,
  COLLAPSE_EXPORT,
  EXPAND_EXPORT,
  UPLOAD_MODAL_ADMIN,
  CLOSE_MODAL_ADMIN,
} from './constants';

export const initialState = fromJS({
  rowData: false,
  visible: false,
  uploadsExpanded: false,
  exportExpanded: false,
  uploadInfo: { uploading: false },
});

function uploadModalReducer(state = initialState, action) {
  switch (action.type) {
    case STORE_UPLOADS:
      return state.set('rowData', fromJS(action.payload));
    case OPEN_MODAL:
      return state.set('visible', true);
    case CLOSE_MODAL:
      return state.set('visible', false);
    case EXPAND_HISTORY:
      return state.set('uploadsExpanded', true);
    case COLLAPSE_HISTORY:
      return state.set('uploadsExpanded', false);
    case EXPAND_EXPORT:
      return state.set('exportExpanded', true);
    case COLLAPSE_EXPORT:
      return state.set('exportExpanded', false);
    case STORE_UPLOAD_INFO:
      return state.set('uploadInfo', fromJS(action.payload));
      case UPLOAD_MODAL_ADMIN:
      return state.set('uploadModal',true);
    case CLOSE_MODAL_ADMIN:
      return state.set('uploadModal',false);  
    default:
      return state;
  }
}

export default uploadModalReducer;
