import { PlanningParameters } from 'containers/PlanResultPage/calculation/types';
import {
  ApiDataTransferObject,
  ApiForecastConversionRateTableDTO,
  ApiForecastEventDTO,
  ApiForecastHolidayDTO,
  ApiHolidayDTO, ApiINestedDTOContainer, ApiManagementUnitDTO,
  ApiSmartProdRunDTO,
  ApiSmartProdRunScheduleDTO,
  ApiSmartProdSourceDTO,
} from 'types/drep-backend.d';

export type EntityEntry = { value: number; label: string };
export type SmartProdSourceEntityEntry = { value: number; label: string; smartProdSource: ApiSmartProdSourceDTO };
export type SubCategoryEntry = EntityEntry & { eventCategoryId: number };
export type ForecastListEntityEntry = EntityEntry & {
  omsId: number;
  uom: string;
  customerId: number;
  departmentId: number;
};
export type ForecastConversionRateTableDTOWithId = ApiForecastConversionRateTableDTO & { id: number };

export const EventType = {
  ONE_OFF: 'ONE_OFF',
  RECURRING: 'RECURRING',
};

export type SmartProdSourceForm = {
  eventsTable: { [id: number]: ApiForecastEventDTO };
  holidaysTable: { [id: number]: Holiday };
  entity: ApiSmartProdSourceDTO;
};

export type ForecastPerOMSCodeColumns = {
  effortSmartVolume: boolean;
  effortSmartProd: boolean;
  actualPR: boolean;
  targetPR: boolean;
  ratio: boolean;
  model: boolean;
};

export type Holiday = ApiForecastHolidayDTO & ApiHolidayDTO;

export const SmartProdRunEnum = {
  REQUEST: 'REQUEST',
  SCHEDULE: 'SCHEDULE',
};

export type SmartProdRunsType = {
  requested: ApiSmartProdRunDTO[];
  scheduled: ApiSmartProdRunScheduleDTO[];
};

export enum SmartProdPeriodTypeEnum {
  SOURCE,
  FORECAST,
}

export interface IdAndCode {
  id: number;
  code: string;
}
