import React from 'react';
import { connect, Field, FormikProps } from 'formik';
import { FormattedMessage, injectIntl } from 'react-intl';
import { FormikCheckbox } from 'components/Menu/ItemSelectable';
import styled from 'styled-components';

import FormikDatePicker from 'components/FormikDatePicker';

import messages from './messages';
import { SmartProdSourceForm } from './types';

const Wrapper = styled.div`
  display: grid;
  font-size: 12px;
  grid-template-columns: 220px 140px;
  align-items: center;
  grid-row-gap: ${props => props.theme.grid.rowGap};
  input {
    background-color: ${props => props.theme.color.grey5};
  }
`;


const Value = styled.div`
  font-weight: bold;
  min-height: ${props => props.theme.grid.tinyRowHeight};
  width: 220px
`;


type BasicSettingsProps = {
  isEdit: boolean;
  isAdmin: any;
  formik?: FormikProps<SmartProdSourceForm>;
  isMasterPlanEngineer: any;
};

const BasicSettings = (props: BasicSettingsProps) => {
  const { formik, isEdit, isAdmin, isMasterPlanEngineer } = props;
  const isAdminFlag = isAdmin ? true : false;
  const isMasterPlanEngineerFlag = isMasterPlanEngineer ? true : false;
  if (!formik.values.entity) return <span />;

  return (
    <Wrapper id="SmartProdSourceBasicInSettings">
      <Value><FormattedMessage {...messages.requestSmartVolumeForecast} /></Value>
      <div style={{ width: '34px' }}>
        <Field
          name="entity.isRequestSmartVolumeForecast"
          disabled={!(isEdit && (isAdminFlag || isMasterPlanEngineerFlag))}
          component={FormikCheckbox}
        />
      </div>
    </Wrapper>
  );
};

// @ts-ignore
export default injectIntl(connect(BasicSettings));
