/**
 *
 * ResultMatrixColumnOptions
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';
import { injectIntl } from 'react-intl';
import { compose } from 'redux';
import BarGraph from '../../components/BarGraph';
import { formatNumberForExport, getGraphUnit, getSectionName } from './utils';

/* eslint-disable react/prefer-stateless-function */
class TrendGraph extends React.PureComponent {
  acivitiesByIdMap = {};

  tooltip = params => {
    const { messages, intl } = this.props;
    const unit = getGraphUnit(params);
    const marker = params.marker.replace('[object Object]', params.color.colorStops[0].color);
    const { seriesId } = params;
    const value = formatNumberForExport(params.value, 2);
    return `${marker} ${this.formatName({ name: seriesId })}: ${value} ${intl.formatMessage(messages[unit])}`;
  };

  formatName = item => {
    const { messages, intl } = this.props;
    const activity = item.name.match(/^(.*)_.*_.*$/)[1];
    const sectionName = getSectionName(item.name);
    return `${intl.formatMessage(messages[sectionName])} ${activity}`;
  };

  getUnitByData = () => {
    if (this.props.data && this.props.data.data && this.props.data.data.length > 0) {
      const {
        props: {
          data: { data },
        },
      } = this;
      const isEffort = /.*effort/.test(data[0].name);
      return isEffort ? 'h' : 'FTE';
    }
    return '';
  };

  render() {
    const unit = this.getUnitByData();
    const { plan } = this.props;
    this.acivitiesByIdMap = plan.planningParameters.periods
      .flatMap(p => p.activityParameters)
      .map(ap => ap.activity)
      .reduce((result, current) => ({ ...result, [current.id]: current }), {});
    // console.log("Activities by id are", this.acivitiesByIdMap);
    return (
      <BarGraph
        spaceBeforeGraph={items => Math.floor(items / 5) * 25}
        heightPerData={items => Math.floor(items / 5) * 25}
        tooltipFormatter={params => `${params[0].axisValueLabel}:00<br/> ${params.map(this.tooltip).join('<br/>')}`}
        axisYFormatter={`{value} ${unit}`}
        axisXFormatter="{value}:00"
        {...this.props.data}
        messages={this.props.messages}
        seriesNameFormatter={this.formatName}
      />
    );
  }
}

TrendGraph.propTypes = {
  data: PropTypes.array,
  messages: PropTypes.object,
  theme: PropTypes.object,
  intl: PropTypes.object,
};

export default compose(
  injectIntl,
  withTheme,
)(TrendGraph);
