import Table from 'components/Table/index';
import * as React from 'react';
import { injectIntl } from 'react-intl';
import { compose } from 'redux';
import messages from '../messages';
import AbstractTransferCostsTable from './abstractTransferCostsTable';

class FacilityTransferCostsTable extends AbstractTransferCostsTable {
  public render() {
    super.beforeRender();

    // Clone the formik data, using the same underlying data for formik & ag-grid causes problems after save
    const data = this.props.formik.values.facilityTransferCostsMap
      .listValues()
      // TODO temp workaround for hiding rows after facility deletion
      .filter(c => this.facilityById.get(c.facilityFrom) && this.facilityById.get(c.facilityTo))
      .filter(c => !this.state.hideBlank || c.valueFrom > 0 || c.valueTo > 0)
      .map(c => ({
        ...c,
      }));

    return [
      this.hideBlankButton(),
      <Table
        key="table"
        onModelUpdated={params => params.columnApi.autoSizeAllColumns()}
        messages={messages}
        pagination={false}
        columnDefs={this.getColumnDefs()}
        rowData={data}
        domLayout="autoHeight"
        onGridReady={null}
      />,
    ];
  }

  protected getFormikPath(): string {
    return 'facilityTransferCostsMap';
  }

  private getColumnDefs() {
    return [
      this.facilityFromColumn({ flex: 2 }, messages.facilityFrom),
      this.facilityToColumn({ flex: 2 }, messages.facilityTo),
      {
        ...this.valueFromColumn(),
        editable: this.props.isEdit,
        flex: 1,
      },
      {
        ...this.valueToColumn(),
        editable: this.props.isEdit,
        flex: 1,
      },
      this.statusColumn(),
      this.commentColumn(),
    ];
  }
}

export default compose(
  injectIntl,
  // @ts-ignore
)(FacilityTransferCostsTable);
