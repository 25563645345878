import React, { useState } from 'react';
import { ColDef, ColGroupDef } from 'ag-grid-community';
import { InjectedIntl, injectIntl } from 'react-intl';
import styled from 'styled-components';

import AgTable from 'components/Table';
import { dateTimeCellFormatter } from 'utils/dateTime';
import { useEffectDeepCompare } from 'utils/utils';
import { ApiMasterPlanReportingRunDTO, ApiPlanDTO } from 'types/drep-backend.d';

import messages from './messages';
import RunsTableDetailCellRenderer from './RunsTableDetailCellRenderer';

const Table = styled(AgTable)`
  margin-top: 12px;
  height: ${props => props.height}px;

  .ag-overlay-no-rows-wrapper {
    padding-top: 90px;
  }

  .ag-full-width-row {
    overflow: visible;
  }
`;

const columnDefs = (intl: InjectedIntl) => {
  const columns: (ColGroupDef | ColDef)[] = [
    {
      colId: 'requestedDate',
      field: 'requestedDate',
      filter: 'setFilter',
      menuTabs: ['filterMenuTab', 'generalMenuTab', 'columnsMenuTab'],
      cellRenderer: 'agGroupCellRenderer',
      valueFormatter: dateTimeCellFormatter,
      headerName: intl.formatMessage(messages.requested),
      sortable: true,
      resizable: true,
    },
    {
      colId: 'type',
      field: 'type',
      filter: 'setFilter',
      menuTabs: ['filterMenuTab', 'generalMenuTab', 'columnsMenuTab'],
      headerName: intl.formatMessage(messages.type),
      sortable: true,
      resizable: true,
    },
    {
      colId: 'finishedDate',
      field: 'finishedDate',
      filter: 'setFilter',
      menuTabs: ['filterMenuTab', 'generalMenuTab', 'columnsMenuTab'],
      valueFormatter: dateTimeCellFormatter,
      headerName: intl.formatMessage(messages.finished),
      sortable: true,
      resizable: true,
    },
    {
      colId: 'requestedBy',
      field: 'requestedBy',
      filter: 'setFilter',
      menuTabs: ['filterMenuTab', 'generalMenuTab', 'columnsMenuTab'],
      valueFormatter: ({ value }) => (value ? `${value.firstName} ${value.lastName}` : ''),
      headerName: intl.formatMessage(messages.requestedBy),
      sortable: true,
      resizable: true,
    },
    {
      colId: 'status',
      field: 'status',
      filter: 'setFilter',
      menuTabs: ['filterMenuTab', 'generalMenuTab', 'columnsMenuTab'],
      headerName: intl.formatMessage(messages.status),
      sortable: true,
      resizable: true,
    },
  ];
  return columns;
};

type Props = {
  intl: InjectedIntl;
  data: ApiMasterPlanReportingRunDTO[];
  plans: ApiPlanDTO[];
};

const RunsTable: React.FC<Props> = ({ intl, data, plans }) => {
  const [openedDetail, setOpenedDetail] = useState(null);
  const [gridApi, setGridApi] = useState(null);

  const onGridReady = params => {
    setGridApi(params);
    params.api.sizeColumnsToFit();
  };

  const handleRowClicked = params => {
    if (!params.node.detail && params.column.colId !== 'actions') {
      params.node.setExpanded(!params.node.expanded);
      gridApi.api.onGroupExpandedOrCollapsed();
    }
  };

  const onRowGroupToggle = params => {
    let isSomeNodeExpanded = params.node.expanded;
    if (params.node.expanded) {
      setOpenedDetail(params.data.id);
      gridApi.api.forEachNode(node => {
        if (node.expanded && node.id !== params.node.id) {
          node.setExpanded(false);
        }
      });
    } else {
      gridApi.api.forEachNode(node => {
        isSomeNodeExpanded = isSomeNodeExpanded || node.expanded;
      });
    }
    if (!isSomeNodeExpanded) {
      setOpenedDetail(null);
    }
  };

  const onFirstDataRendered = params => {
    params.api.forEachNode(node => {
      if (node.data.id === openedDetail) {
        node.setExpanded(true);
      }
    });
  };

  useEffectDeepCompare(() => {
    if (gridApi) {
      const colDefs = columnDefs(intl);
      gridApi.api.setColumnDefs(colDefs);
      gridApi.api.sizeColumnsToFit();
      gridApi.api.forEachNode(node => {
        node.setExpanded(node.data.id === openedDetail);
      });
    }
  }, [gridApi, data]);

  const DETAIL_ROW_HEIGHT = 370;
  const ROW_HEIGHT = 30;

  return (
    <Table
      masterDetail
      detailCellRenderer="detailCellRenderer"
      detailCellRendererParams={{
        plans,
      }}
      frameworkComponents={{
        detailCellRenderer: RunsTableDetailCellRenderer,
      }}
      defaultColDef={{
        flex: 1,
      }}
      sortable
      pagination={false}
      columnDefs={columnDefs(intl)}
      height={80 + ROW_HEIGHT * Math.min(10, data.length) + (openedDetail ? DETAIL_ROW_HEIGHT : 0)}
      rowData={data}
      rowHeight={ROW_HEIGHT}
      detailRowHeight={DETAIL_ROW_HEIGHT}
      onGridReady={onGridReady}
      onRowGroupOpened={onRowGroupToggle}
      onFirstDataRendered={onFirstDataRendered}
      isRowSelectable={() => false}
      onCellClicked={handleRowClicked}
    />
  );
};

export default injectIntl(RunsTable);
