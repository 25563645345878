import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import './style.css'

export const LoadingWrap = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  > span {
    padding: 10px;
    background-color: white;
    min-width: 200px;
    text-align: center;
  }
`;

export const Loading = (props) => {
  return (
    <LoadingWrap>
      <div className='loader'></div>
      <FormattedMessage {...props.message} />
    </LoadingWrap>
  );
};