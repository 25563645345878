/**
 *
 * Button
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';

const getColor = props => {
  if (props.disabled) {
    return props.theme.color.grey1;
  }
  if (props.secondary) {
    return props.theme.color.greyBorder;
  }
  if (props.alternative) {
    return props.theme.color.white;
  }
  if (props.danger) {
    return props.theme.color.red;
  }
  return props.theme.color.yellow;
};

function getShadow(props) {
  if (props.disabled) {
    return 'none';
  }
  if (props.alternative) {
    return props.theme.shadow.buttonBig;
  }
  if (props.inset) {
    return 'none';
  }
  return props.theme.shadow.pageBox;
}

function getShadowHover(props) {
  if (props.disabled) {
    return 'none';
  }
  if (props.inset) {
    return props.theme.shadow.buttonBigInset;
  }
  return 'none';
}

const WrapInput = styled.input`
  user-select: none;
  transition: ${props => props.theme.transitions.shadow};
  padding-top: 5px;
  padding-right: 10px;
  padding-bottom: 5px;
  padding-left: 10px;
  height: 36px;
  background-color: ${props => getColor(props)};
  text-align: center;
  box-shadow: ${props => getShadow(props)};
  :hover {
    cursor: ${props => (props.disabled ? 'inherit' : 'pointer')};
    box-shadow: ${props => getShadowHover(props)};
  }
`;

const WrapDiv = styled.div`
  user-select: none;
  transition: ${props => props.theme.transitions.shadow};
  padding-top: 5px;
  padding-right: 10px;
  padding-bottom: 5px;
  padding-left: 10px;
  height: 36px;
  background-color: ${props => getColor(props)};
  text-align: center;
  border: 0px none;
  border-image-width: 0px;
  display: flex;
  align-items: center;
  box-shadow: ${props => getShadow(props)};
  :hover {
    cursor: ${props => (props.disabled ? 'inherit' : 'pointer')};
    box-shadow: ${props => getShadowHover(props)};
  }
`;

function getType(props) {
  if (props.submit === false) {
    return undefined;
  }
  return props.children ? undefined : 'submit';
}

function Button(props) {
  let Wrap = WrapInput;
  if (props.children) {
    Wrap = WrapDiv;
  }

  const handleClick = e => {
    if (props.disabled === true) {
      e.preventDefault();
      return;
    }
    props.submit || e.preventDefault(); // eslint-disable-line
    props.onClick && props.onClick(e); // eslint-disable-line
  };
  let tooltipProps = {};
  if (props.tooltip && props.id) {
    tooltipProps = {
      'data-tip': true,
      'data-for': props.id,
    };
  }
  return (
    <Wrap
      {...props}
      inset={props.inset}
      tabIndex={props.tabIndex}
      name={props.name}
      title={props.title}
      disabled={props.disabled}
      type={getType(props)}
      className={props.className}
      secondary={props.secondary}
      alternative={props.alternative}
      danger={props.danger}
      onKeyDown={e => {
        const { keyCode } = e;
        if (keyCode === 13) {
          handleClick(e);
        }
      }}
      onClick={handleClick}
      onChange={props.onChange || (() => {})} // problem with value and no onchange implemented
      value={(props.label && props.intl.formatMessage(props.label)) || 'Submit'}
      {...tooltipProps}
    >
      {props.children}
      {props.tooltip && props.id && (
        <ReactTooltip id={props.id} place="bottom" type="dark" effect="float">
          {props.tooltip}
        </ReactTooltip>
      )}
    </Wrap>
  );
}

Button.defaultProps = {
  disabled: false,
  secondary: false,
  submit: true,
};

Button.propTypes = {
  // For identification in tests
  name: PropTypes.string,
  intl: PropTypes.object,
  onClick: PropTypes.func,
  onChange: PropTypes.func,
  label: PropTypes.object,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  inset: PropTypes.bool,
  submit: PropTypes.bool,
  secondary: PropTypes.bool,
  alternative: PropTypes.bool,
  danger: PropTypes.bool,
  className: PropTypes.string,
  tabIndex: PropTypes.string,
  title: PropTypes.string, // tooltip
};

export default styled(injectIntl(Button))``;
