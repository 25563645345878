/**
 *
 * ResultMatrixColumnOptions
 *
 */
import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';
import { injectIntl } from 'react-intl';
import { compose } from 'redux';
import maxBy from 'lodash/maxBy';
import BarGraph from 'components/BarGraph';
import { formatNumberForExport } from './utils';

/* eslint-disable react/prefer-stateless-function */
class TrendGraph extends React.PureComponent {
  tooltip = params => {
    const { messages, intl } = this.props;
    const unit = this.getUnit(params);
    const isPlan = /.*plan/.test(params.seriesId);
    const isBaseLine = /.*budget/.test(params.seriesId);
    const wzpName = params.seriesId.match(/^.*_(.*)$/)[1];
    const prefix = this.getPrefix(isPlan, intl, messages, isBaseLine);
    const marker = params.marker.replace('[object Object]', params.color.colorStops[0].color);
    const value = formatNumberForExport(params.value, 2);
    return `${marker} ${prefix} ${wzpName}: ${value} ${unit}`;
  };

  getUnit(params) {
    const isEffort = /.*effort/.test(params.seriesId);
    const unit = isEffort ? 'h' : 'FTE';
    return unit;
  }

  getPrefix(isPlan, intl, messages, isBaseLine) {
    if (isPlan) return intl.formatMessage(messages.planned);
    if (isBaseLine) return intl.formatMessage(messages.baseline);
    return intl.formatMessage(messages.actuals);
  }

  formatName = item => {
    const { messages, intl } = this.props;
    const type = item.name.match(/^(.*)_.*_.*$/)[1];
    if (messages[type] === undefined) {
      console.error(`Not found default message ${type}`);
    }
    const prefix = intl.formatMessage(messages[type]);
    const wzpName = item.name.match(/^.*_(.*)$/)[1];
    return `${prefix} ${wzpName}`;
  };

  getUnitByData = () => {
    if (this.props.data && this.props.data.data && this.props.data.data.length > 0) {
      const {
        props: {
          data: { data },
        },
      } = this;
      const isEffort = /.*effort/.test(data[0].name);
      return isEffort ? 'h' : 'FTE';
    }
    return '';
  };

  render() {
    const unit = this.getUnitByData();
    return (
      <BarGraph
        heightPerData={items => Math.floor(items / 10) * 250}
        spaceInfrontGraph={(length, data, axisX) =>
          (axisX && axisX.length > 0 && maxBy(axisX, it => it.length).length * 7) || 0
        }
        axisYFormatter={`{value} ${unit}`}
        {...this.props.data}
        messages={this.props.messages}
        tooltipFormatter={params => `${params[0].axisValueLabel}: <br/> ${params.map(this.tooltip).join('<br/>')}`}
        switchAxis
        featureMagicTypeDisable
        seriesNameFormatter={this.formatName}
      />
    );
  }
}

TrendGraph.propTypes = {
  data: PropTypes.array,
  messages: PropTypes.object,
  theme: PropTypes.object,
  intl: PropTypes.object,
};

export default compose(
  injectIntl,
  withTheme,
)(TrendGraph);
