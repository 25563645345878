import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const ToggleButtonStyled = styled.div`
  padding-top: 5px;
  padding-right: 10px;
  padding-bottom: 5px;
  padding-left: 10px;
  height: 36px;
  color: ${props => (props.disabled ? props.theme.color.grey1 : props.theme.color.black)};
  background-color: ${props =>
    props.disabled ? props.theme.color.grey4 : props.value ? props.theme.color.yellow : props.theme.color.grey1};
  text-align: center;
  border: 0px none;
  border-image-width: 0px;
  display: flex;
  align-items: center;
  :hover {
    cursor: ${props => (props.disabled ? 'inherit' : 'pointer')};
    box-shadow: ${props => (props.disabled ? 'inherit' : props.theme.shadow.buttonBigInset)};
  }
  box-shadow: ${props => (props.disabled ? 'inherit' : !props.value ? props.theme.shadow.button : 'inherit')};
`;

const ToggleButton = props => {
  const { id, input, disabled } = props;
  return (
    <ToggleButtonStyled id={id} disabled={disabled} value={input.value} onClick={() => input.onChange(!input.value)}>
      {props.children}
    </ToggleButtonStyled>
  );
};

export const CheckButton = props => {
  const { value, name, onChange, disabled } = props;
  return (
    <ToggleButtonStyled disabled={disabled} value={value} onClick={() => onChange({ name, value: !value })}>
      {props.children}
    </ToggleButtonStyled>
  );
};

ToggleButton.propTypes = {
  input: PropTypes.object,
  children: PropTypes.node,
};

export default ToggleButton;
