import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Field, connect as formikConnect } from 'formik';

import { compose } from 'redux';
import IconButton, { DeleteButton } from 'components/IconButton';
import FormikDatePicker from 'components/FormikDatePicker';
import { ComponentWithDirtyCheck } from 'components/ButtonWithDirtyCheck';
import { getPeriod } from 'utils/formikSelectors';
import DatePicker from 'components/DatePicker';
import Label from 'components/Label';
import messages from './messages';

const TabControl = styled.div`
  display: flex;
  > * {
    margin: 0 10px;
  }
`;
const Space = styled.div`
  flex: 1;
`;
const TabContent = styled.div``;

class TabBody extends React.PureComponent {
  state = {
    openEndDay: false,
  };

  render() {
    const { onDelete, periodIndex, edit, onCopy } = this.props;
    const period = getPeriod(this.props, periodIndex);
    const isEditable = periodIndex > 0 && edit;
    return (
      <>
        {isEditable && (
          <TabControl>
            <Label {...messages.from} />
            <Field
              name={`planningParameters.periods.${periodIndex}.startDay`}
              component={FormikDatePicker}
              editable={edit}
              onChange={value => {
                // on close is called before onChange and new value is not set yet
                // if we need start value to calculate end value, we need to put
                // auto opening handler to onChange, not onClose
                const day = value.weekday; // 1 Monday, 7 Sunday
                this.props.formik.setFieldValue(
                  `planningParameters.periods.${periodIndex}.endDay`,
                  value.plus({ days: 7 - day }),
                );
              }}
              okLabel={this.props.intl.formatMessage(messages.setStartDate)}
              onAccept={() => this.setState({ openEndDay: true })}
            />
            <Label {...messages.to} />
            <Field
              name={`planningParameters.periods.${periodIndex}.endDay`}
              component={FormikDatePicker}
              editable={edit}
              okLabel={this.props.intl.formatMessage(messages.setEndDate)}
              open={this.state.openEndDay}
              onAccept={() => this.setState({ openEndDay: false })}
              onOpen={() => this.setState({ openEndDay: true })}
              onClose={() => this.setState({ openEndDay: false })}
            />
            <Space />
            <ComponentWithDirtyCheck actionHandler={onCopy} actionPayload={{ periodId: period.id }}>
              {({ onClickHandler }) => (
                <IconButton icon="copy" message={{ ...messages.copy }} onClick={onClickHandler} />
              )}
            </ComponentWithDirtyCheck>
            <ComponentWithDirtyCheck actionHandler={onDelete} actionPayload={{ periodId: period.id }} deleteConfirmation>
              {({ onClickHandler }) => <DeleteButton onClick={onClickHandler} />}
            </ComponentWithDirtyCheck>
          </TabControl>
        )}
        <TabContent>{this.props.children}</TabContent>
      </>
    );
  }
}

TabBody.propTypes = {
  periodIndex: PropTypes.number,
  children: PropTypes.node,
  onDelete: PropTypes.func,
  onCopy: PropTypes.func,
  edit: PropTypes.bool,
  intl: PropTypes.object,
};
export default compose(
  injectIntl,
  formikConnect,
)(TabBody);
