Object.defineProperty(exports, '__esModule', { value: true });
exports.getFirstDay = exports.dataToGroups = exports.getItems = exports.parseTime = void 0;
const _ = require('lodash');
// @ts-ignore
const moment_1 = require('moment');
const uniqid = require('uniqid');
const constants_1 = require('utils/calendar/constants');
const dateTime_js_1 = require('../../utils/dateTime.js');
const utils_1 = require('../../utils/utils');
const messages_1 = require('./messages');
exports.parseTime = time => {
  let timesplit;
  if (time.values !== undefined) {
    timesplit = [
      time.values.hours > 9 ? time.values.hours : `0${time.values.hours}`,
      time.values.minutes > 9 ? time.values.minutes : `0${time.values.minutes}`,
    ];
  } else {
    timesplit = time.split(':');
  }
  return timesplit;
};
const setTime = (day, time) => {
  const timesplit = exports.parseTime(time);
  return day.plus({ hours: utils_1.toNumber(timesplit[0]), minutes: utils_1.toNumber(timesplit[1]) });
};
function isShiftInDay(start, shift) {
  return shift.days.find(item => constants_1.DAY_NAMES[start.weekday - 1] === item) !== undefined;
}
function getShifts(start, period, offDays) {
  const result = [];
  if (!isOffDay(start, offDays)) {
    period.shifts.map(shift => {
      const sStart = setTime(start, shift.startTime);
      let sEnd = setTime(start, shift.endTime);
      if (!isShiftInDay(sStart, shift)) {
        return;
      }
      if (sStart > sEnd) {
        sEnd = sEnd.plus({ days: 1 });
      }
      result.push({
        ...shift,
        className: 'item-shift',
        end_time: moment_1.default(sEnd.valueOf()),
        group: 'shift',
        id: `${uniqid()}_${shift.id}`,
        start_time: moment_1.default(sStart.valueOf()),
        title: shift.name,
      });
    });
  }
  return result;
}
function getWzps(start, period, offDays) {
  const result = [];
  if (!isOffDay(start, offDays)) {
    period.workZonePeriods.forEach((wzp, index) => {
      const wzpStart = setTime(start, wzp.startTime);
      let wzpEnd = setTime(start, wzp.endTime);
      if (wzpStart > wzpEnd) {
        wzpEnd = wzpEnd.plus({ days: 1 });
      }
      result.push({
        ...wzp,
        className: `item-wzp item-wzp-${index}`,
        end_time: moment_1.default(wzpEnd.valueOf()),
        group: 'shift',
        id: `${uniqid()}_${wzp.id}`,
        isWzp: true,
        start_time: moment_1.default(wzpStart.valueOf()),
        title: wzp.name,
      });
    });
  }
  return result;
}
function isOffDay(start, offDays) {
  return offDays.find(offday => constants_1.DAY_NAMES[start.weekday - 1] === offday) !== undefined;
}
function getOffDay(start, period, offDays) {
  const result = [];
  if (isOffDay(start, offDays)) {
    result.push({
      className: `item-offday item-wzp-14`,
      end_time: moment_1.default(start.plus({ days: 1 }).valueOf()),
      group: 'shift',
      id: `${uniqid()}_${constants_1.DAY_NAMES[start.weekday - 1]}`,
      isOffday: true,
      name: dateTime_js_1.formatDay(constants_1.DAY_NAMES[start.weekday - 1]),
      start_time: moment_1.default(start.valueOf()),
      title: 'Day Off',
    });
  }
  return result;
}
function getItems(data, periodIndex) {
  const { offDays } = data.planningParameters;
  let { startDay, endDay } = data.planningParameters;
  const period = data.planningParameters.periods[periodIndex];
  if (periodIndex !== undefined) {
    startDay = period.startDay || startDay;
    endDay = period.endDay || endDay;
  }
  let start = dateTime_js_1.parseDate(startDay);
  const end = dateTime_js_1.parseDate(endDay);
  let result = [];
  while (start <= end) {
    result = result.concat(getShifts(start, period, offDays));
    result = result.concat(getWzps(start, period, offDays));
    result = result.concat(getOffDay(start, period, offDays));
    start = start.plus({ days: 1 });
  }
  const counted = _.countBy(result, 'name');
  const uniqueResult = {};
  result.forEach(function(item) {
    const id = item.id.split('_')[1];
    if (id in uniqueResult) return;
    item.count = counted[item.name];
    if (item.isOffday || item.isWzp) {
      item.count = 0;
    }
  });
  result = _.sortBy(result || [], ['count']).reverse();
  return [result, Object.values(uniqueResult)];
}
exports.getItems = getItems;
exports.dataToGroups = (params, periodIndex, formatMessage) => {
  const { shifts } = params.planningParameters.periods[periodIndex];
  let height = shifts.length * 60;
  height = height < 200 ? 200 : height;
  return [{ id: 'shift', title: formatMessage(messages_1.default.shifts), stackItems: true }];
};
exports.getFirstDay = (data, periodIndex) => {
  let { startDay } = data.planningParameters;
  const { firstDayOfWeek } = data.planningParameters;
  if (periodIndex !== undefined) {
    startDay = data.planningParameters.periods[periodIndex].startDay || startDay;
  }
  let current = dateTime_js_1.parseDate(startDay);
  while (constants_1.DAY_NAMES[current.weekday - 1] !== firstDayOfWeek) {
    current = current.plus({ days: 1 });
  }
  return current;
};
// # sourceMappingURL=calcData.js.map
