import React, { ChangeEvent, useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import Button from 'components/Button';
import DialogDHL from 'components/Dialog';
import Label from 'components/Label';

import messages from './messages';

const StyledInput = styled.input`
  margin-top: 4px;
  width: 100%;
  padding-top: 5px;
  padding-right: 10px;
  padding-bottom: 5px;
  padding-left: 10px;
  height: 36px;
  background-color: ${props => props.theme.color.grey5};
`;

type Props = {
  open: boolean;
  onClose: () => void;
  onAddOpenShiftGroup: (groupName: string) => void;
};

const AddOpenShiftGroupModal: React.FC<Props> = ({ open, onClose, onAddOpenShiftGroup, ...props }) => {
  const [groupName, setGroupName] = useState('');

  const handleGroupNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setGroupName(e.target.value);
  };

  useEffect(() => {
    setGroupName('');
  }, [open]);

  return (
    <DialogDHL
      open={!!open}
      isTextContent={false}
      headerLabel={messages.addOpenShiftGroupModalTitle}
      maxWidth="xl"
      minWidth="sm"
      {...props}
      CustomButtons={
        <>
          <Button onClick={onClose}>
            <FormattedMessage {...messages.addOpenShiftGroupModalConcel} />
          </Button>
          <Button
            disabled={!groupName}
            onClick={() => {
              onAddOpenShiftGroup(groupName);
              onClose();
            }}
          >
            <FormattedMessage {...messages.addOpenShiftGroupModalConfirm} />
          </Button>
        </>
      }
    >
      <Box py={1} style={{ minWidth: 360 }}>
        <Label {...messages.addOpenShiftGroupModalInputLabel} />
        <StyledInput autoFocus value={groupName} onChange={handleGroupNameChange} />
      </Box>
    </DialogDHL>
  );
};

export default AddOpenShiftGroupModal;
