/**
 *
 * DeleteCellRenderer
 *
 */

import React from 'react';
import PropTypes from 'prop-types';

import { FormattedMessage, injectIntl } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import CrystalBallIcon from 'images/assets/magic-ball.png';
import styled from 'styled-components';
import { ComponentWithDirtyCheck } from '../ButtonWithDirtyCheck';
import messages from './messages';
import Button from '../Button';


const ImgWrapper = styled.img`
  height: 16.5px;
  width: 17px;
`;
const CellWrapper = styled.div`
  display: flex;
  align-items: center;
  > * {
    margin-right: 5px;
  }
`;

const ValidityButton = styled(Button)`
  padding-top: 0px !important
`

const SmallButton = styled.div`
  height: 20px;
  width: 20px;
  margin-top: 3px;
  text-align: center;
  border: 0px none;
  border-image-width: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: ${props => (props.disabled ? 0.5 : 'inherit')};
  :hover {
    cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
    box-shadow: none;
  }
`;

/* eslint-disable react/prefer-stateless-function */
class ActionCell extends React.Component {
  render() {
    // eslint-disable-next-line react/prop-types
    const { intl, data, editable } = this.props;
    const disabled = typeof this.props.disabled === 'function' ? this.props.disabled(data) : this.props.disabled;
    if (!data) return <div />;
    const detailbuttonProps = disabled ? { disabled } : { onClick: e => this.props.onDetail(data, this.props, e) };
    const copybuttonProps = disabled ? { disabled } : { onClick: e => this.props.onCopy(data, this.props, e) };
    const forecastbuttonProps = disabled ? { disabled } : { onClick: e => this.props.onInterface(data, this.props, e) };
    const resultbuttonProps = disabled ? { disabled } : { onClick: e => this.props.onResult(data, this.props, e) };
    return (
      <CellWrapper>
        {!this.props.hide || (typeof this.props.hide === 'function' && this.props.hide(this.props.data)) ? (
          <>
          <Tooltip title={intl.formatMessage(messages.details)}>
            <SmallButton {...detailbuttonProps} className="delete-cell-renderer-button">
              <FontAwesomeIcon size="lg" icon="edit" />
            </SmallButton>
          </Tooltip>
          <Tooltip title={intl.formatMessage(messages.result)}>
            <SmallButton {...resultbuttonProps} className="delete-cell-renderer-button">
            <FontAwesomeIcon size="lg" icon="vials" />
            </SmallButton>
          </Tooltip>
          <Tooltip title={intl.formatMessage(messages.forecast)}>
            <SmallButton {...forecastbuttonProps} className="delete-cell-renderer-button">
              <ImgWrapper src={CrystalBallIcon} height='16.5px' width='17px' />
            </SmallButton>
          </Tooltip>
          <Tooltip title={intl.formatMessage(messages.copy)}>
            <SmallButton {...copybuttonProps} className="delete-cell-renderer-button">
              <FontAwesomeIcon size="lg" icon="copy" />
            </SmallButton>
          </Tooltip>
          { this.props.mappingTable &&
          <Tooltip title={intl.formatMessage(messages.addValidity)}>
            <ValidityButton disabled ={!editable} onClick={(e)=>this.props.addValidity(data, this.props, e)} className="delete-cell-renderer-button">
            <FormattedMessage {...messages.addValidity} />
            </ValidityButton>
          </Tooltip>
          }
          </>
        ) : (
          ''
        )}
      </CellWrapper>
    );
  }
}

ActionCell.propTypes = {
  onDelete: PropTypes.func,
  hide: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  disabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
};

export default injectIntl(ActionCell);
