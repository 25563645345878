// @flow

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = (style: Object) =>
  makeStyles((theme: ?Object) => ({
    root: {
      margin: '0 10px',
      fontFamily: 'inherit',
      cursor: 'pointer',
      transition: 'color 0.5s ease-out',
      '&:hover': {
        border: 'none',
        color: style.color.red,
      },
      '& *': {
        transition: 'color 0.5s ease-out',
      },
    },
    iconButton: {
      '&:hover': {
        backgroundColor: 'rgba(255, 241, 144, 0.5)',
      },
    },

    icon: {
      width: '1.1em',
      backgroundSize: 'inherit',
    },

    iconItem: {
      marginRight: '15px',
      width: '1.66em',
      height: '1.6em',
    },
  }));
