import { labourCategoryToRowDept } from "./calculateData";
import { DATASET } from "./types";
export default function labourCategoryToRowDepartmentBaseLine(data, filter) {
  let allLabourAvailability = {};
  let budgetLabourAvailability = {}
  data.budgetAvailability.labourCategories.map((item) => {
    if (filter.granularity === 'WEEK' || filter.granularity === 'MONTH') {
      if (item.availabilityCalculatedValuesForDepartmentsAndRoles.length == 0) {
        return;
      }
      item?.periods?.forEach((per) => {
        if (per?.availabilityCalculatedValuesForDepartmentsAndRoles.length == 0) {
          item?.availabilityCalculatedValuesForDepartmentsAndRoles?.forEach((a) => {
            const id = (a?.department && a?.role) ? `${item.labourCategory.id}_${a?.department?.id}_${a?.role?.id}` : (!a?.department && a?.role) ? `${item.labourCategory.id}_${a?.role?.id}` : (a?.department && !a?.role) ? `${item.labourCategory.id}_${a?.department?.id}` : `${item.labourCategory.id}`;
            allLabourAvailability[id] = id;
            const lcdr = labourCategoryToRowDept({ calculatedHoursEffort: 0, calculatedFte: 0, calculatedNumberOfStaff: 0, department: { name: a?.department?.name, id: a?.department?.id }, role : {name: a?.role?.name, id: a?.role?.id} }, filter, DATASET.budget, item, per);
            let alreadyExists = false;
            for (let key in budgetLabourAvailability) {
              //@ts-ignore
              if (key == lcdr.id) {
                alreadyExists = true
              }
            }
            //@ts-ignore
            if (alreadyExists) {
              budgetLabourAvailability[id] = Object.assign({}, budgetLabourAvailability[id], lcdr)
            } else {
              budgetLabourAvailability[id] = lcdr;
            }
          })
        } else {
          per?.availabilityCalculatedValuesForDepartmentsAndRoles?.forEach((a) => {
            const id = (a?.department && a?.role) ? `${item.labourCategory.id}_${a?.department?.id}_${a?.role?.id}` : (!a?.department && a?.role) ? `${item.labourCategory.id}_${a?.role?.id}` : (a?.department && !a?.role) ? `${item.labourCategory.id}_${a?.department?.id}` : `${item.labourCategory.id}`;
            allLabourAvailability[id] = id;
            const lcdr = labourCategoryToRowDept(a, filter, DATASET.budget, item, per);
            let alreadyExists = false;
            for (let key in budgetLabourAvailability) {
              //@ts-ignore
              if (key == lcdr.id) {
                alreadyExists = true
              }
            }
            if (alreadyExists) {
              budgetLabourAvailability[id] = Object.assign({}, budgetLabourAvailability[id], lcdr)
            } else {
              budgetLabourAvailability[id] = lcdr;
            }

          })
        }
      })
    }
    if (filter.granularity === 'DAY') {
      if (item.availabilityCalculatedValuesForDepartmentsAndRoles.length == 0) {
        return;
      }
      item?.periods?.forEach((per) => {
        per?.days?.forEach((day) => {
          if (day?.availabilityCalculatedValuesForDepartmentsAndRoles.length == 0) {
            item?.availabilityCalculatedValuesForDepartmentsAndRoles?.forEach((a) => {
              const id = (a?.department && a?.role) ? `${item.labourCategory.id}_${a?.department?.id}_${a?.role?.id}` : (!a?.department && a?.role) ? `${item.labourCategory.id}_${a?.role?.id}` : (a?.department && !a?.role) ? `${item.labourCategory.id}_${a?.department?.id}` : `${item.labourCategory.id}`;
              allLabourAvailability[id] = id;
              const lcdr = labourCategoryToRowDept({ calculatedHoursEffort: 0, calculatedFte: 0, calculatedNumberOfStaff: 0, department: { name: a?.department?.name, id: a?.department?.id }, role : {name: a?.role?.name, id: a?.role?.id} }, filter, DATASET.budget, item, per, day);
              let alreadyExists = false;
              for (let key in budgetLabourAvailability) {
                //@ts-ignore
                if (key == lcdr.id) {
                  alreadyExists = true
                }
              }
              if (alreadyExists) {
                budgetLabourAvailability[id] = Object.assign({}, budgetLabourAvailability[id], lcdr)
              } else {
                budgetLabourAvailability[id] = lcdr;
              }

            })
          } else {
            day?.availabilityCalculatedValuesForDepartmentsAndRoles?.forEach((a) => {
              const id = (a?.department && a?.role) ? `${item.labourCategory.id}_${a?.department?.id}_${a?.role?.id}` : (!a?.department && a?.role) ? `${item.labourCategory.id}_${a?.role?.id}` : (a?.department && !a?.role) ? `${item.labourCategory.id}_${a?.department?.id}` : `${item.labourCategory.id}`;
              allLabourAvailability[id] = id;
              const lcdr = labourCategoryToRowDept(a, filter, DATASET.budget, item, per, day);
              let alreadyExists = false;
              for (let key in budgetLabourAvailability) {
                //@ts-ignore
                if (key == lcdr.id) {
                  alreadyExists = true
                }
              }
              if (alreadyExists) {
                budgetLabourAvailability[id] = Object.assign({}, budgetLabourAvailability[id], lcdr)
              } else {
                budgetLabourAvailability[id] = lcdr;
              }

            })
          }
        })
      })
    }
    if (filter.granularity === 'WZP') {
      if (item.availabilityCalculatedValuesForDepartmentsAndRoles.length == 0) {
        return;
      }
      item?.periods?.forEach((per) => {
        per?.days?.forEach((day) => {
          day?.wzps?.forEach((wzp) => {
            if (wzp?.availabilityCalculatedValuesForDepartmentsAndRoles.length == 0) {
              item?.availabilityCalculatedValuesForDepartmentsAndRoles?.forEach((a) => {
                const id = (a?.department && a?.role) ? `${item.labourCategory.id}_${a?.department?.id}_${a?.role?.id}` : (!a?.department && a?.role) ? `${item.labourCategory.id}_${a?.role?.id}` : (a?.department && !a?.role) ? `${item.labourCategory.id}_${a?.department?.id}` : `${item.labourCategory.id}`;
                allLabourAvailability[id] = id;
                const lcdr = labourCategoryToRowDept({ calculatedHoursEffort: 0, calculatedFte: 0, calculatedNumberOfStaff: 0, department: { name: a?.department?.name, id: a?.department?.id }, role : {name: a?.role?.name, id: a?.role?.id} }, filter, DATASET.budget, item, per, day, wzp);
                let alreadyExists = false;
                for (let key in budgetLabourAvailability) {
                  //@ts-ignore
                  if (key == lcdr.id) {
                    alreadyExists = true
                  }
                }
                if (alreadyExists) {
                  budgetLabourAvailability[id] = Object.assign({}, budgetLabourAvailability[id], lcdr)
                } else {
                  budgetLabourAvailability[id] = lcdr;
                }

              })
            } else {
              wzp?.availabilityCalculatedValuesForDepartmentsAndRoles?.forEach((a) => {
                const id = (a?.department && a?.role) ? `${item.labourCategory.id}_${a?.department?.id}_${a?.role?.id}` : (!a?.department && a?.role) ? `${item.labourCategory.id}_${a?.role?.id}` : (a?.department && !a?.role) ? `${item.labourCategory.id}_${a?.department?.id}` : `${item.labourCategory.id}`;
                allLabourAvailability[id] = id;
                const lcdr = labourCategoryToRowDept(a, filter, DATASET.budget, item, per, day, wzp);
                let alreadyExists = false;
                for (let key in budgetLabourAvailability) {
                  //@ts-ignore
                  if (key == lcdr.id) {
                    alreadyExists = true
                  }
                }
                if (alreadyExists) {
                  budgetLabourAvailability[id] = Object.assign({}, budgetLabourAvailability[id], lcdr)
                } else {
                  budgetLabourAvailability[id] = lcdr;
                }

              })
            }
          })
        })
      })
    }
    if (filter.granularity === 'HOUR') {
      if (item.availabilityCalculatedValuesForDepartmentsAndRoles.length == 0) {
        return;
      }
      item?.periods?.forEach((per) => {
        per?.days?.forEach((day) => {
          day?.wzps?.forEach((wzp) => {
            wzp?.hours?.forEach((hour) => {
              if (hour?.availabilityCalculatedValuesForDepartmentsAndRoles.length == 0) {
                item?.availabilityCalculatedValuesForDepartmentsAndRoles?.forEach((a) => {
                  const id = (a?.department && a?.role) ? `${item.labourCategory.id}_${a?.department?.id}_${a?.role?.id}` : (!a?.department && a?.role) ? `${item.labourCategory.id}_${a?.role?.id}` : (a?.department && !a?.role) ? `${item.labourCategory.id}_${a?.department?.id}` : `${item.labourCategory.id}`;
                  allLabourAvailability[id] = id;
                  const lcdr = labourCategoryToRowDept({ calculatedHoursEffort: 0, calculatedFte: 0, calculatedNumberOfStaff: 0, department: { name: a?.department?.name, id: a?.department?.id }, role : {name: a?.role?.name, id: a?.role?.id} }, filter, DATASET.budget, item, per, day, wzp, hour);
                  let alreadyExists = false;
                  for (let key in budgetLabourAvailability) {
                    //@ts-ignore
                    if (key == lcdr.id) {
                      alreadyExists = true
                    }
                  }
                  if (alreadyExists) {
                    budgetLabourAvailability[id] = Object.assign({}, budgetLabourAvailability[id], lcdr)
                  } else {
                    budgetLabourAvailability[id] = lcdr;
                  }

                })
              } else {
                hour?.availabilityCalculatedValuesForDepartmentsAndRoles?.forEach((a) => {
                  const id = (a?.department && a?.role) ? `${item.labourCategory.id}_${a?.department?.id}_${a?.role?.id}` : (!a?.department && a?.role) ? `${item.labourCategory.id}_${a?.role?.id}` : (a?.department && !a?.role) ? `${item.labourCategory.id}_${a?.department?.id}` : `${item.labourCategory.id}`;
                  allLabourAvailability[id] = id;
                  const lcdr = labourCategoryToRowDept(a, filter, DATASET.budget, item, per, day, wzp, hour);
                  let alreadyExists = false;
                  for (let key in budgetLabourAvailability) {
                    //@ts-ignore
                    if (key == lcdr.id) {
                      alreadyExists = true
                    }
                  }
                  if (alreadyExists) {
                    budgetLabourAvailability[id] = Object.assign({}, budgetLabourAvailability[id], lcdr)
                  } else {
                    budgetLabourAvailability[id] = lcdr;
                  }

                })
              }
            })
          })
        })
      })
    }
  })
  return { allLabourAvailability, budgetLabourAvailability }
}
