/*
 * ActualVolume Messages
 *
 * This contains all the text for the ActualVolume component.
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  testLabel: {
    id: 'app.components.ModifyMultipleDialog.testLabel',
    defaultMessage: 'TODO',
  },
  cancelButton: {
    id: 'app.components.ModifyMultipleDialog.cancelButton',
    defaultMessage: 'Cancel',
  },
  saveButton: {
    id: 'app.components.ModifyMultipleDialog.saveButton',
    defaultMessage: 'Confirm',
  },
  headerEffortForecast: {
    id: 'app.components.ModifyMultipleDialog.headerEffortForecast',
    defaultMessage: 'Modify Multiple Plan Values for Effort Forecast',
  },
  headerProductivityRate: {
    id: 'app.components.ModifyMultipleDialog.headerProductivityRate',
    defaultMessage: 'Modify Multiple Plan Values for Productivity Rate Forecast',
  },
  headerMMUPH:{
    id: 'app.components.ModifyMultipleDialog.headerMMUPH',
    defaultMessage: 'Modify Multiple Plan Values for Units Per Hour Forecast',
  },
  headeradjustmentOverview:{
    id: 'app.components.ModifyMultipleDialog.headeradjustmentOverview',
    defaultMessage: 'Modify Multiple Adjustments',
  },
  startDate: {
    id: 'app.components.ModifyMultipleDialog.startDate',
    defaultMessage: 'Start Date',
  },
  endDate: {
    id: 'app.components.ModifyMultipleDialog.endDate',
    defaultMessage: 'End Date',
  },
  plannerDriven: {
    id: 'app.components.ModifyMultipleDialog.plannerDriven',
    defaultMessage: 'Planner Driven',
  },
  smartVolume: {
    id: 'app.components.ModifyMultipleDialog.smartVolume',
    defaultMessage: 'Smart Volume',
  },
  plannerDrivenOneOff: {
    id: 'app.components.ModifyMultipleDialog.plannerDrivenOneOff',
    defaultMessage: 'Planner Driven One-Off Event',
  },
  smartVolumeOneOff: {
    id: 'app.components.ModifyMultipleDialog.smartVolumeOneOff',
    defaultMessage: 'Smart Volume One-Off Event',
  },
  actual: {
    id: 'app.components.ModifyMultipleDialog.actual',
    defaultMessage: 'Actual',
  },
  target: {
    id: 'app.components.ModifyMultipleDialog.target',
    defaultMessage: 'Target',
  },
  omsCodeLevel: {
    id: 'app.components.ModifyMultipleDialog.omsCodeLevel',
    defaultMessage: 'OMS Codes Level',
  },
  activityLevel: {
    id: 'app.components.ModifyMultipleDialog.activityLevel',
    defaultMessage: 'Activity Level',
  },

  settingsTitleOMSEffortUpdate: {
    id: 'app.components.ModifyMultipleDialog.settingsTitleOMSEffortUpdate',
    defaultMessage: 'OMS Effort Update (Activities updated based on Ratio)',
  },

  settingsTitleActivityEffortUpdate: {
    id: 'app.components.ModifyMultipleDialog.settingsTitleActivityEffortUpdate',
    defaultMessage: 'Activity effort Update (OMS Effort recalculated based on changed activities)',
  },
  settingsTitleProductivityRateUpdate: {
    id: 'app.components.ModifyMultipleDialog.settingsTitleProductivityRateUpdate',
    defaultMessage: 'Activity Productivity Rate Update',
  },
  settingsTitleUPHUpdate: {
    id: 'app.components.ModifyMultipleDialog.settingsTitleUPHUpdate',
    defaultMessage: 'Activity Units per Hour Update',
  },
  settingsTitleAdjustmentRateUpdate: {
    id: 'app.components.ModifyMultipleDialog.settingsTitleAdjustmentRateUpdate',
    defaultMessage: 'Activity Adjustment Rate Update',
  },
  changeTypeOMSCodeValueEffortOMSCode: {
    id: 'app.components.ModifyMultipleDialog.changeTypeOMSCodeValueEffortOMSCode',
    defaultMessage: 'change all selected OMS effort for the period to this value (Activities recalculated per Ratio)',
  },
  changeTypeOMSCodeValueEffortActivity: {
    id: 'app.components.ModifyMultipleDialog.changeTypeOMSCodeValueEffortActivity',
    defaultMessage:
      'change all selected Activities effort for the period to this value. (Ratio recalculated accordingly)',
  },
  changeTypeOMSCodeValueProductityRate: {
    id: 'app.components.ModifyMultipleDialog.changeTypeOMSCodeValueProductityRate',
    defaultMessage: 'change all selected Activities Productivity Rate for the period to this value.',
  },
  changeTypeOMSCodeValueUPH: {
    id: 'app.components.ModifyMultipleDialog.changeTypeOMSCodeValueUPH',
    defaultMessage: 'change all selected Activities Units Per Hour for the period to this value.',
  },
  changeTypeOMSCodeValueAdjustment: {
    id: 'app.components.ModifyMultipleDialog.changeTypeOMSCodeValueAdjustment',
    defaultMessage: 'change all selected Activities Adjustment Rate for the period to this value.',
  },
  changeTypeDataSourceEffortForecast: {
    id: 'app.components.ModifyMultipleDialog.changeTypeDataSourceEffortForecast',
    defaultMessage: 'change all selected OMS effort to',
  },
  changeTypeDataSourceProductivityRate: {
    id: 'app.components.ModifyMultipleDialog.changeTypeDataSourceProductivityRate',
    defaultMessage: 'change all selected Productivity rate to',
  },
  changeTypeDataSourceUPH: {
    id: 'app.components.ModifyMultipleDialog.changeTypeDataSourceUPH',
    defaultMessage: 'change all selected Units Per Hour to',
  },
  changeTypePercentageOfValue: {
    id: 'app.components.ModifyMultipleDialog.changeTypePercentageOfValue',
    defaultMessage: '% change of value',
  },
  changeTypePercentageOfValueActivity: {
    id: 'app.components.ModifyMultipleDialog.changeTypePercentageOfValueActivity',
    defaultMessage: '% change of value. (Ratio recalculated accordingly)',
  },
  startEndDatesRangeError: {
    id: 'app.components.ModifyMultipleDialog.startEndDatesRangeError',
    defaultMessage: 'Maximum range for date interval is 3 months',
  },
  startEndDateOrder: {
    id: `app.components.ModifyMultipleDialog.startEndDateOrder`,
    defaultMessage: 'End date should be after start date',
  },
  negativeProductivityRateNotAllowedError: {
    id: 'app.components.ModifyMultipleDialog.negativeProductivityRateNotAllowedError',
    defaultMessage: 'Negative productivity rate is not allowed',
  },
  percentageIsMandatoryError: {
    id: 'app.components.ModifyMultipleDialog.percentageIsMandatoryError',
    defaultMessage: 'Percentage is mandatory',
  },
  valueIsMandatoryError: {
    id: 'app.components.ModifyMultipleDialog.valueIsMandatoryError',
    defaultMessage: 'Value is mandatory',
  },
  enterANumberError: {
    id: 'app.components.ModifyMultipleDialog.enterANumberError',
    defaultMessage: 'Enter a number',
  },
  enterAPositiveNumberError: {
    id: 'app.components.ModifyMultipleDialog.enterAPositiveNumberError',
    defaultMessage: 'Enter a positive number',
  },
  selectAtLeastOneItemError: {
    id: 'app.components.ModifyMultipleDialog.selectAtLeastOneItemError',
    defaultMessage: 'Select at least 1 item',
  },
  goal:{
   id: 'app.components.ModifyMultipleDialog.goal',
    defaultMessage: 'Goal',
  }
});
