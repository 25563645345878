import './style.css';

import React, { useEffect } from 'react';
import { Form, Formik } from 'formik';
import { Helmet } from 'react-helmet';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Prompt } from 'react-router';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { compose } from 'redux';
import { useActionCreators, useSelectors } from 'use-redux';

import BasePage from 'components/BasePage';
import FormikErrorsPopup from 'components/DetailPageShared/FormikErrorsPopup';
import { CancelButton, SaveButton } from 'components/IconButton';
import MasterPlanInterfaceBasicInfo from 'components/InterfaceBasicInfo/MasterPlanInterfaceBasicInfo';
import Toolbar from 'components/Toolbar';
import { getToken } from 'containers/App/selectors';
import { logoutAction } from 'containers/LoginPage/actions';
import NotFoundPage from 'containers/NotFoundPage';
import { storePlan } from 'containers/PlanDetailPage/actions';
import SmartVolume from 'containers/MasterPlanInterfaces/SmartVolume';
import { storePlanningArea } from 'containers/PlanningAreaDetailPage/actions';
import { PageSection, Section } from 'containers/PlanResultPage';
import { convertEntityWithPlanningParametersFromApi as normalizePlan, withUrl } from 'utils/api';
import { fetchData } from 'utils/reduxApi';
import withSecurity, { PERMISSIONS } from 'utils/security';

import messages from './messages';
import { getArea, getAreaIsEdit, getPlan, getPlanIsEdit } from './redux';

function PlanInterfaces(props) {
  const {
    intl: { formatMessage },
    match: { params: { id } = {} } = {},
    dispatch,
    history,
    area = false,
  } = props;
  const [plan, token] = useSelectors((area && getArea) || getPlan, getToken);
  const [isEdit] = useSelectors((area && getAreaIsEdit) || getPlanIsEdit);
  const [logout, storePlanDetails] = useActionCreators(logoutAction, (area && storePlanningArea) || storePlan);
  const isForecastEditor = props.hasPerm(PERMISSIONS.VIEW_FORECAST_SECTION);

  const entity = 'masterPlan';

  const fetchDetails = async () => {
    let planDetails = id && (await fetchData(withUrl(`/${entity}/${id}`).andToken(token), dispatch));
    planDetails = (planDetails.isOk && normalizePlan(planDetails.data)) || null;
    storePlanDetails(planDetails);
  };

  // Fetching plan and put it to redux
  useEffect(() => {
    fetchDetails();
  }, [token]);
  const name = (plan && plan.name) || '';

  async function save(formikBag, values) {
    formikBag.setSubmitting(true);
    const conf = values.planningParameters.smartShiftExportSettings;
    const payload = {
      planningParameters: {
        smartShiftExportParameters: {
          granularity: conf.granularity,
          smartShiftConfigurationId: conf.smartShiftConfiguration.id,
        },
      },
    };
    const updated = await fetchData(
      withUrl(`/${entity}/${plan.id}/saveAll`).post(payload).andTitle('Saving master plan').andToken(token),
      dispatch,
    );
    toast(formatMessage((area && messages.paSaved) || messages.planSaved));
    updated.isOk && storePlanDetails(normalizePlan(updated.data));
  }

  const handleScheduleVolumeUpdate = ()=>{
    fetchDetails();
  };
  const title = formatMessage(messages.header, { name });
  function renderForm(formikBag) {
    return (
      <Form>
        <Prompt when={formikBag.dirty} message={formatMessage(messages.confirmDirty)} />
        <Toolbar {...props} area={area} planId={plan.id} title={title} isEdit={isEdit} onBack={props.history.goBack}>
        </Toolbar>
        <PageSection>
          <MasterPlanInterfaceBasicInfo masterPlan={plan} planMode={!props.area} />
        </PageSection>
        {isForecastEditor && (
          <Section message={messages.smartVolumeAssociation} expanded>
            {plan && (
              <SmartVolume
                formik={formikBag}
                plan={plan}
                fetchDetails={fetchDetails}
                storePlanDetails={storePlanDetails}
                dirty={formikBag.dirty}
                handleScheduleVolumeUpdate={handleScheduleVolumeUpdate}
              />
            )}
          </Section>
        )}
        <FormikErrorsPopup />
      </Form>
    );
  }

  // Login verification
  if (!token) {
    logout();
    return <span />;
  }

  if (!id) return <NotFoundPage />;

  return (
    <BasePage labelMessage={(area && messages.paHeader) || messages.header} labelValues={{ name }} noMaxWidth>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content="Plan Interfaces" />
      </Helmet>
      {plan && (
        <Formik
          initialValues={plan}
          enableReinitialize
          validateOnChange={false}
          onSubmit={(values, formikBag) => {
            save(formikBag, values);
            formikBag.setSubmitting(false);
          }}
          render={renderForm}
        />
      )}
    </BasePage>
  );
}

const Interfaces = compose(
  withRouter,
  withSecurity(PERMISSIONS.VIEW_PLAN_DETAILS),
  connect(),
  injectIntl,
)(PlanInterfaces);

export default withSecurity(PERMISSIONS.EDIT_PLAN_DETAILS)(Interfaces);

export const PlanAreaInterfaces = withSecurity(PERMISSIONS.EDIT_AREA_DETAIL)((props) => (
  <Interfaces {...props} area />
));
