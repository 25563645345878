import { Component } from 'react';
import * as React from 'react';
import { Field, Form, Formik, FormikProps } from 'formik';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Prompt } from 'react-router';
import { bindActionCreators, compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';

import AutocompleteSelect from 'components/AutocompleteSelect';
import BasePage from 'components/BasePage';
import {
  DetailPageSectionsWrapper,
  DetailToolbarButtonsGroup,
  DetailToolbarButtonsWrap,
} from 'components/DetailPageShared';
import { FormikText, InlineLabel } from 'components/FormikTextInput';
import IconButton, { SaveButton } from 'components/IconButton';
import NamedSection from 'components/NamedSection';
import ToggleSection from 'components/ToggleSection';
import { PATHS } from 'containers/App/constants';
import { getToken } from 'containers/App/selectors';
import injectSaga from 'utils/injectSaga';
import withSecurity, { PERMISSIONS } from 'utils/security';
import { findDiffs } from 'utils/utilsTs';
import { ApiScheduleDTO } from 'types/drep-backend.d';

import { selectShiftSchedule } from '../ShiftScheduleActivityDetailPage/selectors';
import { loadShiftSchedule, saveShiftSchedule } from '../ShiftScheduleDetailPage/actions';
import saga from '../ShiftScheduleDetailPage/saga';
import messages from './messages';

const GridRow = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: 150px 350px;
  margin: 0 0 12px;
  column-gap: 10px;
`;

const InnerGridRow = styled(GridRow)`
  grid-template-columns: 140px 350px;
  grid-column-gap: 30px;
`;

const FullWidthSection = styled(NamedSection)`
  min-width: 1170px;
`;

const InnerSection = styled(ToggleSection)`
  margin-top: 20px;
  min-width: 1140px;
  width: 1140px;
  max-width: 1140px;
  input {
    background-color: ${props => props.theme.color.grey5};
  }
`;

interface ShiftScheduleInterfacesProps {
  intl: any;
  match: any;
  history: any;
  shiftSchedule: ApiScheduleDTO;
  token: any;

  loadShiftSchedule(id: number): () => void;

  save(activity: any): () => void;
}

class ShiftScheduleInterfacesPage extends Component<ShiftScheduleInterfacesProps, {}> {
  public componentDidMount() {
    this.props.loadShiftSchedule(this.props.match.params.id);
  }

  public render() {
    if (!this.props.shiftSchedule?.id) {
      return (
        <BasePage labelMessage={messages.header} labelValues={{ name: '' }}>
          Loading schedule interfaces...
        </BasePage>
      );
    }

    const doSubmit = (values, actions) => {
      const diffs = findDiffs(this.props.shiftSchedule.facilities, values.facilities);
      const toSave = {
        facilities: diffs.modified,
        id: values.id,
        smartShiftConfiguration: values.smartShiftConfiguration?.id,
      };

      this.props.save({
        schedule: toSave,
        scheduleName: values.name,
      });
      actions.setSubmitting(false);
    };

    return (
      <BasePage labelMessage={messages.header} labelValues={{ name: this.props.shiftSchedule.name }}>
        <Formik
          validateOnChange
          onSubmit={doSubmit}
          enableReinitialize
          initialValues={this.props.shiftSchedule}
          render={this.renderForm}
        />
      </BasePage>
    );
  }

  private renderForm = (formik: FormikProps<ApiScheduleDTO>) => {
    const hyperFinds = (formik.values.facilities || []).map((f, i) => (
      <InnerGridRow key={i}>
        <span>{f.code}</span>
        <Field name={`facilities[${i}].hyperFind`} type="text" />
      </InnerGridRow>
    ));

    const kronosSetter = kronos => {
      formik.setFieldValue('smartShiftConfiguration', kronos);
    };

    const kronosField = ({ field }) => (
      <AutocompleteSelect
        entity="smartShiftConfigurations"
        token={this.props.token}
        maxMenuHeight={120}
        value={field.value}
        placeholder={messages.kronos}
        name="smartShiftConfiguration"
        setValue={kronosSetter}
      />
    );

    return (
      <Form>
        <Prompt when={formik.dirty} message={this.props.intl.formatMessage(messages.confirmDirty)} />

        {this.renderToolbar(formik)}

        <DetailPageSectionsWrapper>
          {this.renderBasicInfoSection(formik)}

          <FullWidthSection message={messages.kronos}>
            <GridRow>
              <InlineLabel {...messages.name} />
              <FormikText>{formik.values.smartShiftConfiguration?.name}</FormikText>
            </GridRow>
            {
              formik.values.smartShiftConfigurationError &&
              <GridRow>
                <InlineLabel {...messages.error} />
                <FormikText>{formik.values.smartShiftConfigurationError}</FormikText>
              </GridRow>
            }

            <InnerSection message={messages.hyperFind} expanded>
              {hyperFinds}
            </InnerSection>
          </FullWidthSection>
        </DetailPageSectionsWrapper>
      </Form>
    );
  };

  private renderBasicInfoSection = (formik: FormikProps<ApiScheduleDTO>) => (
    <FullWidthSection message={messages.basicInfo}>
      <GridRow>
        <InlineLabel {...messages.name} />
        <FormikText>{formik.values.name}</FormikText>
      </GridRow>
      <GridRow>
        <InlineLabel {...messages.description} />
        <FormikText>{formik.values.description}</FormikText>
      </GridRow>
    </FullWidthSection>
  );

  private renderToolbar = (formik: FormikProps<ApiScheduleDTO>) => {
    const goBack = () => this.props.history.push(PATHS.shiftScheduleDetailId.replace(':id', this.props.match.params.id));

    return (
      <DetailToolbarButtonsWrap>
        <IconButton id="back" onClick={goBack} icon="arrow-circle-left" tooltip={messages.back} />

        <DetailToolbarButtonsGroup>
          <SaveButton onClick={formik.submitForm} disabled={!formik.dirty} />
        </DetailToolbarButtonsGroup>
      </DetailToolbarButtonsWrap>
    );
  };
}

const mapStateToProps = createStructuredSelector({
  shiftSchedule: selectShiftSchedule,
  token: getToken,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      loadShiftSchedule,
      save: saveShiftSchedule,
    },
    dispatch,
  );
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withSaga = injectSaga({ key: 'shiftScheduleInterfacesPage', saga });

export default compose(
  injectIntl,
  withConnect,
  withSecurity(PERMISSIONS.VIEW_SCHEDULES),
  withSaga,
  // @ts-ignore
)(ShiftScheduleInterfacesPage);
