/* eslint-disable no-restricted-syntax,no-param-reassign */
import React from 'react';
import { push } from 'connected-react-router';
import { DateTime } from 'luxon';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import * as Yup from 'yup';
import { isEqual } from 'lodash';

import { isInTolerance } from 'utils/checksum';
import {
  addActivityLine,
  addAdjustmentLine,
  addLabourCategoryLine,
  addPeriod,
  copyPeriod,
  addShiftSettings,
  addVolumeLine,
  deleteLabourCategoryLine,
  deletePeriod,
  deleteShiftSettings,
  deleteWeeklyVolumes,
  diffPlanningParameters,
  doAddWzp,
  doDeleteWzp,
  extractDiffFieldsShallow,
  extractDiffValuesFromEffortForecast,
  savePlaningParametersFromTo,
  spreadIntoChildFieldIfNotEmpty,
  waitForSaveAll,
} from 'utils/commonDetailSaga';
import { generateDays, parseDate } from 'utils/dateTime';

import { api, convertEntityWithPlanningParametersFromApi, formatDateToApiFormat, withUrl } from '../../utils/api';
import { loadActivitiesAction, loadUOMSAction, storeActivitiesByPlanAction } from '../App/actions';
import { PATHS } from '../App/constants';
import { CLOSE_MODAL } from '../UploadModal/constants';
import {
  loadPlanningArea,
  saveMasterPlanConflict,
  savePlanningAreaDone,
  savePlanningAreaError,
  setPeriodIndexPaAction,
  startPaCopyAction,
  storePaEdit,
  storePaLoading,
  storePlanningArea,
  planningAreaDetailsLoader,
  clearAreaMHEAction,
  storeRole,
  savePlanSmartCodeMappings,
  planningAreaDetailsLoaderFlag,
  planningAreaDetailsOverviewFlag,
  saveomsChangeHistory
} from './actions';
import {
  ADD_BUDGET_VOLUME_LINE,
  ADD_LABOUR_AVAILABILITY_CATEGORY_LINE_PA,
  ADD_PA_ACTIVITY_LINE,
  ADD_ADJUSTMENT_LINE,
  ADD_PA_WZP,
  ADD_PERIOD_PA,
  ADD_SHIFT_SETTINGS_LINE_PA,
  COPY_PA,
  COPY_PERIOD_PA,
  CREATE_PLAN,
  DELETE_BUDGET_VOLUME,
  DELETE_LABOUR_AVAILABILITY_CATEGORY_LINE_PA,
  DELETE_PA_ACTIVITY_LINES,
  DELETE_ADJUSTMENT_LINE,
  DELETE_PA_WZP,
  DELETE_PERIOD_PA,
  DELETE_SHIFT_SETTINGS_LINE_PA,
  LOAD_PA,
  LOAD_PA_DETAILS,
  LOAD_PA_OVERVIEW_DATA,
  NAVIGATE_TO_PLANS,
  SAVE_PA,
  SAVE_PA_PLANNING_PARAMETERS,
  SWITCH_TO_DISPLAY_PA,
  SAVE_LABOUR_OVERVIEW,
  SAVE_MHE_OVERVIEW,
  SAVE_ADJUSTMENT_OVERVIEW,
  SAVE_PLANINGAREA_SMART_CODE_MAPPINGS,
  OMS_CHANGE_HISTORY
} from './constants';
import messages from './messages';
import {
  selectGuiStateForPa,
  selectPeriodIdFromPa,
  selectPlanningAreaFromPa,
  selectPlanningParametersIdFromPa, selectPlanSmartCodeMappings, selectUOMS,
} from './selectors';
import {planRatioWarningFlag} from 'containers/PlanDetailPage/actions';
const newPlanningArea = {
  name: '',
  description: '',
  deleted: false,
  planningParameters: {},
};

export const paValidationSchema = intl =>
  Yup.object().shape({
    effortForecast: Yup.array()
      .test(
        'ratios-add-up-to-100-percent',
        intl.formatMessage(messages.errorSumOfRatiosMustBe100Percent),
        function (effortForecast) {
          const { path, createError } = this;
          const activitiesByOmsId = {};
          effortForecast.forEach(row => {
            activitiesByOmsId[row.omsId] = activitiesByOmsId[row.omsId] ? [...activitiesByOmsId[row.omsId], row] : [row];
          });
          let existsNon100Value = false;
          Object.values(activitiesByOmsId).forEach(activities => {
            activities[0].activityDates.forEach((_, i) => {
              let sum = 0;
              let hasOverride = false;
              activities.forEach(activity => {
                const data = activity.activityDates[i];
                if (!isNaN(data.ratioOverride)){
                sum += (data.ratioOverride !== null ? data.ratioOverride : data.ratioSmartProd) || 0;
                hasOverride = hasOverride || data.ratioOverride !== null;
                }
              });
              if (!isInTolerance(sum) && hasOverride) {
                existsNon100Value = true;
              }
            });
          });
          return existsNon100Value
            ? createError({ path, message: intl.formatMessage(messages.errorSumOfRatiosMustBe100Percent) })
            : true;
        },
      )
      .test(
        'effort-should-be-a-positive-number',
        intl.formatMessage(messages.errorNegativeEffortIsNotAllowed),
        function (effortForecast) {
          const { path, createError } = this;
          let hasNegativeEffort = false;
          effortForecast.forEach(row => {
            [...row.activityDates, row.omsDates].forEach(date => {
              if (date.effortOverride !== null && date.effortOverride !== undefined) {
                hasNegativeEffort = hasNegativeEffort || date.effortOverride < 0;
              }
            });
          });
          return hasNegativeEffort
            ? createError({
                path,
                message: intl.formatMessage(messages.errorNegativeEffortIsNotAllowed),
              })
            : true;
        },
      ),
    planningParameters: Yup.object({
      activityForecastTable: Yup.object()
        .test(
        'negative-values-not-allowed',
        intl.formatMessage(messages.errorNegativeProductivityRateIsNotAllowed),
        function (data) {
          const { path, createError } = this;
          let hasNegativeOverride = false;
          data.days.forEach(day => {
            data.rowData.forEach(row => {
              if (row[day] && row[day].overrideHours && row[day].overrideHours < 0) {
                hasNegativeOverride = true;
              }
            });
          });
          return hasNegativeOverride
            ? createError({ path, message: intl.formatMessage(messages.errorNegativeProductivityRateIsNotAllowed) })
            : true;
        },
      )
        .test(
          'values-out-of-period-range',
          intl.formatMessage(messages.errorNegativeProductivityRateIsNotAllowed),
          function (data) {
            const { path, createError } = this;
            let isOverPeriodRange = false;
            data.days.forEach(day => {
              data.rowData.forEach(row => {
                if(row[day] && !row[day].dataSource){
                  isOverPeriodRange = true;
                }
              });
            });
            return isOverPeriodRange
              ? createError({ path, message: intl.formatMessage(messages.errorOverrideOutOfPeriod) })
              : true;
          },
        ),
      periods: Yup.array().of(
        Yup.object({
          workZonePeriods: Yup.array().of(
            Yup.object({
              name: Yup.string().required(intl.formatMessage(messages.errorNameIsRequired)),
            }),
          ),
          mheAvailabilities: Yup.array().of(
            Yup.object({
              wzp: Yup.object().nullable().required(intl.formatMessage(messages.errorWZPForMHEAvailabilityIsRequired)),
              maintenanceDay: Yup.string()
                .nullable()
                .required(intl.formatMessage(messages.errorMaintenanceDayForMHEIsRequired)),
            }),
          ),
          apCalculated: Yup.object({
            unproductiveLocations: Yup.array().of(
              Yup.object({
                startTime: Yup.object()
                  .nullable()
                  .required(intl.formatMessage(messages.errorStartTimeForUnproductiveActivityIsRequired)),
                endTime: Yup.object()
                  .nullable()
                  .required(intl.formatMessage(messages.errorEndTimeForUnproductiveActivityIsRequired)),
              })
                .test(
                  'days-or-period-none',
                  intl.formatMessage(messages.errorPeriodLengthOrDaysMustBeProvided),
                  ua => !!(ua.periodLength || (ua.days && ua.days.length)),
                )
                .test(
                  'days-or-period-both',
                  intl.formatMessage(messages.errorOnlyPeriodLengthOrDaysMustBeProvided),
                  ua => !(ua.periodLength && ua.days && ua.days.length),
                ),
            ),
          }),
        }),
      ),
    }),
  });

// Individual exports for testing
export default function* defaultSaga() {
  yield takeLatest(LOAD_PA, doLoadPlanningAreaBasic);
  yield takeLatest(LOAD_PA_DETAILS, doLoadPlanningAreaDetails);
  yield takeLatest(LOAD_PA_OVERVIEW_DATA, doLoadPlanningAreaOverviewData);
  yield takeLatest(SAVE_PA_PLANNING_PARAMETERS, doSavePlanningParameters);
  yield takeLatest(ADD_BUDGET_VOLUME_LINE, addPlannedVolumeLine);
  yield takeLatest(DELETE_BUDGET_VOLUME, deletePaWeeklyVolumes);
  yield takeLatest(ADD_PA_ACTIVITY_LINE, addPaActivityLine);
  yield takeLatest(DELETE_PA_ACTIVITY_LINES, deletePaActivityLine);
  yield takeLatest(COPY_PA, copyPASaga);
  yield takeLatest(NAVIGATE_TO_PLANS, navigateToPlansSaga);
  yield takeLatest(CREATE_PLAN, createPlanSaga);
  yield takeLatest(SAVE_PA, saveAllPa);
  yield takeLatest(DELETE_PA_WZP, doDeleteWzpPlan);
  yield takeLatest(ADD_PA_WZP, doAddWzpPlan);
  yield takeLatest(SWITCH_TO_DISPLAY_PA, doSwitchToDisplay);
  yield takeLatest(ADD_PERIOD_PA, addPaPeriod);
  yield takeLatest(COPY_PERIOD_PA, copyPaPeriod);
  yield takeLatest(DELETE_PERIOD_PA, deletePaPeriod);
  yield takeLatest(ADD_LABOUR_AVAILABILITY_CATEGORY_LINE_PA, addLabourAvailabilityCategoryLine);
  yield takeLatest(DELETE_LABOUR_AVAILABILITY_CATEGORY_LINE_PA, deleteLabourAvailabilityCategoryLine);
  yield takeLatest(ADD_SHIFT_SETTINGS_LINE_PA, addShiftSettingsLine);
  yield takeLatest(DELETE_SHIFT_SETTINGS_LINE_PA, deleteShiftSettingsLine);
  yield takeLatest(CLOSE_MODAL, doOnCloseUploadModal);
  yield takeLatest(SAVE_LABOUR_OVERVIEW, saveLabourOverViewSaga);
  yield takeLatest(SAVE_MHE_OVERVIEW, saveMheOverViewSaga);
  yield takeLatest(ADD_ADJUSTMENT_LINE, addPaAdjustmentLine);
  yield takeLatest(DELETE_ADJUSTMENT_LINE, deletePaAdjustmentLines);
  yield takeLatest(SAVE_ADJUSTMENT_OVERVIEW, saveAdjustmentOverViewSaga);
  yield takeLatest(OMS_CHANGE_HISTORY, omsChangeHistory);
}


function* createPlanSaga(action) {
  const pa = action.payload.toJS();
  yield put(push(`${PATHS.planDetail}?paId=${pa.id}&isNew=true`));
}

function* addPaAdjustmentLine(action){
  return yield call(wrapWithPlanningParametersAndCall, action, addAdjustmentLine);
}

function* navigateToPlansSaga(action) {
  const dataWrapper = action.payload;
  if (dataWrapper) {
    yield call(waitForSaveAll, dataWrapper);
    const pa = dataWrapper.data;
    yield put(push(`${PATHS.planList}?filterForPA=${pa.name}`));
  }
}

export function addObjectIdIfChanged(payload, outField, inField, saved, updated) {
  const savedValue = saved[inField];
  const updatedValue = updated[inField];
  if (savedValue || updatedValue) {
    if (savedValue && updatedValue && savedValue.id !== updatedValue.id) {
      // changed
      payload[outField] = updatedValue.id;
    } else if (!savedValue && updatedValue.id) {
      // appeared
      payload[outField] = updatedValue.id;
    } else if (savedValue && !(updatedValue && updatedValue.id)) {
      // disappeared
      payload[outField] = null;
    }
  }
}

export function addCdmEntityUpdates(payload, field, saved, updated) {
  const currentEntities = saved[field] || [];
  const updatedEntities = updated[field] || [];
  const currentIds = currentEntities.map(cdmItem => cdmItem.id);
  const updatedIds = updatedEntities.filter(it => it.id).map(cdmItem => cdmItem.id);
  const toInsert = updatedIds.filter(i => !currentIds.includes(i));
  const toDelete = currentIds.filter(i => !updatedIds.includes(i));
  const ucField = field.charAt(0).toUpperCase() + field.slice(1);
  if (toInsert.length > 0) {
    payload[`added${ucField}`] = toInsert;
  }
  if (toDelete.length > 0) {
    payload[`deleted${ucField}`] = toDelete;
  }
}

export function extractMus(mus) {
  const result = [];
  if (mus) {
    mus.forEach(it => {
      if (it.managementUnits) {
        result.push(...it.managementUnits);
      }
    });
  }
  return result;
}

function* fetchLabourOverview(plan) {
  const response = yield call(
    api,
    withUrl(`/planningParameters/${plan.planningParameters.id}/labourAvailability`),
  );
  if(response.isOk){
    return response.data;
  }
}

function* fetchmheOverview(plan) {
  const response = yield call(
    api,
    withUrl(`/planningParameters/${plan.planningParameters.id}/mheAvailability`),
  );
  if(response.isOk){
    return response.data;
  }
}

function* fetchAdjustmentOverview(plan) {
  const response = yield call(
    api,
    withUrl(`/planningParameters/${plan.planningParameters.id}/activityForecastAdjustments`),
  );
  if(response.isOk){
    return response.data;
  }
}

function* saveLabourOverViewSaga(action){
  let response = ''
  if(action.granularity){
  const payloadToSend = { "labourAvailabilityGranularity":action.granularity }
  const Granularityurl = `/planningParameters/${action.id}`;
  response = yield call(api, withUrl(Granularityurl).post(payloadToSend).asRawResponse());
  }else{
  const url = `/planningParameters/${action.id}/labourAvailabilityOverrides`;
  response = yield call(api, withUrl(url).post(action.payload).asRawResponse());
  }
  if(response.ok){
    yield call(reload)
  }
}

function* saveMheOverViewSaga(action){
  let response = ''
  if(action.granularity){
  const payloadToSend = { "mheAvailabilityGranularity": action.granularity }
  const Granularityurl = `/planningParameters/${action.id}`;
  response = yield call(api, withUrl(Granularityurl).post(payloadToSend).asRawResponse());
  }else{
  const url = `/planningParameters/${action.id}/mheAvailabilityOverrides`;
  response = yield call(api, withUrl(url).post(action.payload).asRawResponse());
  }
  if(response.ok){
    yield call(reload)
  }
}

function* saveAdjustmentOverViewSaga(action){
  const url = `/planningParameters/${action.id}/activityForecastAdjustments`;
  const response = yield call(api, withUrl(url).post(action.payload).asRawResponse());
  if(response.ok){
    yield call(reload)
  }
}

function* saveAllPa(action) {
  const guiState = yield select(selectGuiStateForPa);
  if (guiState.get('copy')) {
    return yield call(copyPASaga, action.payload);
  }
  const values = action.payload;
  const saved = yield select(selectPlanningAreaFromPa);
  const payload = {};
  const mappings = yield select(selectPlanSmartCodeMappings);
  const uoms = yield select(selectUOMS);
  const { customers } = saved;
  const { departments } = saved.planningParameters;

  spreadIntoChildFieldIfNotEmpty(payload, 'planningArea', extractDiffFieldsShallow(saved, values));
  spreadIntoChildFieldIfNotEmpty(payload, 'planningParameters', diffPlanningParameters(saved, values));
  const paPayload = {};
  values.sector && addObjectIdIfChanged(paPayload, 'sectorId', 'sector', saved, values);
  values.country && addObjectIdIfChanged(paPayload, 'countryId', 'country', saved, values);
  values.customers && addCdmEntityUpdates(paPayload, 'customers', saved, values);
  values.facilities && addCdmEntityUpdates(paPayload, 'facilities', saved, values);
  values.managementUnits && addCdmEntityUpdates(paPayload, 'managementUnits', saved, values);
  const savedMus = extractMus(saved.managementUnits);
  const updatedMus = extractMus(values.managementUnits);
  //addCdmEntityUpdates(paPayload, 'managementUnits', { managementUnits: savedMus }, { managementUnits: updatedMus });
  spreadIntoChildFieldIfNotEmpty(payload, 'planningArea', paPayload);
  // console.log('Pa values to save', payload);
  const areaTitle = values.name;
  const isInsert = !saved.id;
  const periods = payload?.planningParameters?.periods;
  const customerMap = customers.reduce((map, customer) => {
    map[customer.id] = customer.code;
    return map;
  }, {});

  const departmentsMap = departments.reduce((map, department) => {
    map[department.id] = department.name;
    return map;
  }, {});
  const uomMap = uoms.reduce((map, uom) => {
    map[uom.id] = uom.name;
    return map;
  }, {});
  if (mappings) {
    periods?.forEach(period => {
      period?.activityParameters?.forEach(activity => {
        if (mappings[activity.activityParametersId] !== null && mappings[activity.activityParametersId] !== undefined) {
          getActivityMappedToSmartCodeMessage(
            mappings[activity.activityParametersId],
            activity,
            customerMap,
            uomMap,
            departmentsMap,
          );
        }
      });
    });
  }

  let response;
  if (isInsert && values.planningArea !== undefined) {
    const url = `/planningAreas/${values.planningArea.id}/copy`;
    response = yield call(api, withUrl(url).post(payload.planningArea).andTitle(`Insert Planning area with copy`));
  } else {
    const url = isInsert ? `/planningAreas/` : `/planningAreas/${saved.id}/saveAll`;
    response = yield call(
      api,
      withUrl(url)
        .post(isInsert ? payload.planningArea : payload)
        .andTitle(`Saving planning area`),
    );
  }
  if (response.isOk) {
    if(payload && payload.planningParameters && payload.planningParameters.productivityRatePerShift === false || payload && payload.planningParameters && payload.planningParameters.productivityRatePerShift === true){
      yield put(clearAreaMHEAction());
    }
    const effortForecastToSave = extractDiffValuesFromEffortForecast(saved.effortForecast, values.effortForecast);
    if (effortForecastToSave && effortForecastToSave.oms && effortForecastToSave.oms.length) {
      yield call(
        api,
        withUrl(`/planningParameters/${values.planningParameters.id}/forecastPerOmsCode?jobsIncluded=false`)
          .post(effortForecastToSave)
          .asRawResponse(),
      );
    }
    if(!isEqual(saved.adjsutmentOverrideData, values.adjsutmentOverrideData) && values.adjsutmentOverrideData !== undefined){
      const url = `/planningParameters/${values.planningParameters.id}/activityForecastAdjustments`;
      const response = yield call(api, withUrl(url).post(values.adjsutmentOverrideData).asRawResponse());
      if(!response.ok){
        return;
      }
    }
    if (!isEqual(saved.labourOverrideData, values.labourOverrideData) && values.labourOverrideData !== undefined){
        const url = `/planningParameters/${values.planningParameters.id}/labourAvailabilityOverrides`;
        const response = yield call(api, withUrl(url).post(values.labourOverrideData).asRawResponse());
        if(!response.ok){
          return;
        }
    }
    if(!isEqual(saved.mheOverrideData, values.mheOverrideData) && values.mheOverrideData !== undefined){
      const url = `/planningParameters/${values.planningParameters.id}/mheAvailabilityOverrides`;
      const response = yield call(api, withUrl(url).post(values.mheOverrideData).asRawResponse())
      if(!response.ok){
        return;
      }
    }

    toast(<FormattedMessage {...messages.planningAreaSaved} values={{ area: areaTitle }} />);
    const paToBeStored = convertEntityWithPlanningParametersFromApi(response.data);
    paToBeStored.reloadCount = saved.reloadCount + 1;
    paToBeStored.effortForecast = yield fetchEffortForecastForPlan(paToBeStored);
    paToBeStored.labourOverViewData = yield fetchLabourOverview(paToBeStored);
    paToBeStored.mheOverViewData = yield fetchmheOverview(paToBeStored)
    paToBeStored.adjustmentOverviewData = yield fetchAdjustmentOverview(paToBeStored);
    //paToBeStored.adjustmentOverviewData = {...rowData};
    paPayload.mheOverrideData = [];
    paToBeStored.labourOverrideData = [];
    paToBeStored.adjsutmentOverrideData = [];
    if (isInsert) {
      yield put(push(PATHS.planningAreaDetail.replace(':id', paToBeStored.id)));
    } else {
      yield put(storePlanningArea(paToBeStored));
    }
    yield put(loadActivitiesAction(paToBeStored.planningParameters.id));
    yield getPlanMappings(paToBeStored.id);
    yield put(loadUOMSAction(paToBeStored.planningParameters.id));
  } else if (response.errorType === 'MASTER_PLAN_IN_COLLISION') {
    yield put(saveMasterPlanConflict(response));
  } else {
    yield put(savePlanningAreaError());
  }
  yield put(savePlanningAreaDone(response));
  return response;
}

function* doDeleteWzpPlan(action) {
  return yield call(wrapWithPlanningParametersAndCall, action, doDeleteWzp);
}

function* doAddWzpPlan(action) {
  return yield call(wrapWithPlanningParametersAndCall, action, doAddWzp);
}

function* addPaPeriod(action) {
  const result = yield call(wrapWithPlanningParametersAndCall, action, addPeriod);
  yield put(setPeriodIndexPaAction(-1));
  return result;
}

function* copyPaPeriod(action){
  const result = yield call(wrapWithPlanningParametersAndCall, action, copyPeriod);
  yield put(setPeriodIndexPaAction(-1));
  return result;
}

function* deletePaPeriod(action) {
  yield put(setPeriodIndexPaAction(0));
  return yield call(wrapWithPlanningParametersAndCall, action, deletePeriod);
}

function* doSwitchToDisplay(action) {
  const dataWrapper = action.payload;
  if (dataWrapper) {
    const response = yield call(waitForSaveAll, dataWrapper);
    if (response && response.isOk) {
      yield put(storePaEdit(false));
      yield call(reload);
    }
  }
}

function* wrapWithPlanningParametersAndCall(action, functionToCall, toastMessage) {
  const dataWrapper = action.payload;
  dataWrapper.planningParametersId = yield select(selectPlanningParametersIdFromPa);
  dataWrapper.periodId = yield select(selectPeriodIdFromPa);
  const response = yield call(functionToCall, dataWrapper);
  if (response.isOk) {
    yield call(reload);
    if(action.type === "app/PlanningAreaDetailPage/DELETE_PERIOD_PA"){
      yield put(setPeriodIndexPaAction(0));
    }
    if (toastMessage) {
      toast(toastMessage);
    }
  }
}

export function* copyPASaga(pa) {
  const { id, name, description } = pa;
  const apiResponse = yield call(api, withUrl(`/planningAreas/${id}/copy`).post({ name, description }));
  if (apiResponse.isOk) {
    yield put(push(PATHS.planningAreaDetail.replace(':id', apiResponse.data.id)));
  }
}

function* doLoadPlanningArea(action) {
  if (action.payload.id) {
    yield put(storePaLoading(true));
    yield put(planningAreaDetailsLoader(true));
    const pa = yield call(fetchPlanningArea, action.payload.id);
    yield put(planningAreaDetailsLoader(false));
    if (pa) {
      pa.reloadCount = action.payload.reloadCount || 0;
      yield put(storeActivitiesByPlanAction(pa));
      yield put(storePlanningArea(pa));
      yield put(loadActivitiesAction(pa.planningParameters.id));
      yield put(loadUOMSAction(pa.planningParameters.id));
      yield getPlanMappings(action.payload.id);
      if (action.payload.edit === true || action.payload.edit === false) {
        yield put(storePaEdit(action.payload.edit));
      }
      if (action.payload.copy) {
        yield put(startPaCopyAction());
      }
    }
  } else {
    yield put(storePlanningArea(newPlanningArea));
  }
}

function* doLoadPlanningAreaBasic(action) {
  if (action.payload.id) {
    yield put(storePaLoading(true));
    yield put(planningAreaDetailsLoader(true));
    const pa = yield call(fetchPlanningArea, action.payload.id);
    yield put(planningAreaDetailsLoader(false));
    if (pa) {
      pa.reloadCount = action.payload.reloadCount || 0;
      yield put(storePlanningArea(pa));
      if (action.payload.copy === true) {
        yield put(startPaCopyAction());
      } else if (action.payload.edit === true || action.payload.edit === false) {
        yield put(storePaEdit(action.payload.edit));
      }
    }
  } else {
    yield put(storePlanningArea(newPlanningArea));
  }
}

function* doLoadPlanningAreaDetails(action){
  yield put(planningAreaDetailsLoaderFlag(true));
  const pa = action.payload.id ? yield call(fetchPlanningAreaDetails, action.payload.id) : newPlanningArea;
  if (pa) {
    pa.reloadCount = action.payload.reloadCount || 0;
    yield put(storePlanningArea(pa));
    yield put(planningAreaDetailsLoaderFlag(false));
  }
  return pa;
}

function* doLoadPlanningAreaOverviewData(){
  yield put(planningAreaDetailsOverviewFlag(true));
  const pa = yield select(selectPlanningAreaFromPa);
  yield fetchRoles(pa);
    const effortForecast = yield fetchEffortForecastForPlan(pa);
    const labourOverViewData = yield fetchLabourOverview(pa);
    const labourOverrideData = [];
    const mheOverViewData = yield fetchmheOverview(pa);
    const mheOverrideData = [];
    const adjustmentOverviewData = yield fetchAdjustmentOverview(pa);
    const adjsutmentOverrideData = [];
    yield put(loadActivitiesAction(pa.planningParameters.id));
    yield put(loadUOMSAction(pa.planningParameters.id));
    yield getPlanMappings(pa.id);
    const paDetails = {
      ...pa,
      effortForecast,
      labourOverViewData,
      labourOverrideData,
      mheOverViewData,
      mheOverrideData,
      adjsutmentOverrideData,
      adjustmentOverviewData
    };
  yield put(storePlanningArea(paDetails));
  yield put(planningAreaDetailsOverviewFlag(false));
}

export function* fetchEffortForecastForPlan(plan) {
  const responseForecastEffort = yield call(
    api,
    withUrl(`/planningParameters/${plan.planningParameters.id}/forecastPerOmsCode?jobsIncluded=false`),
  );
  if(responseForecastEffort){
    let flag = true;
    if(responseForecastEffort.isOk){
      flag = responseForecastEffort && responseForecastEffort.data && responseForecastEffort.data.isAllOmsCodeRatioOK;
    }
    yield put(planRatioWarningFlag(flag))
  }
  const responseForecastConversionRates = yield call(
    api,
    withUrl(`/planningParameters/${plan.planningParameters.id}/forecastConversionRates`),
  );
  if (!responseForecastEffort.isOk || !responseForecastConversionRates.isOk) {
    return [];
  }
  const rows = [];
  responseForecastEffort.data.oms.forEach(row => {
    row.activities.forEach(activity => {
      const fcrEntry = responseForecastConversionRates.data.find(
        fcr =>
          fcr.omsId === row.omsId &&
          fcr.smartProdSourceId === row.smartProdSourceId &&
          fcr.activityForecastId === activity.activityForecastId,
      );
      rows.push({
        omsId: row.omsId,
        smartProdSourceId: row.smartProdSourceId,
        defaultType: row.defaultType,
        smartProdSourceName: fcrEntry ? fcrEntry.smartProdSourceName : '',
        activityForecastId: activity.activityForecastId,
        isOutOfPeriodForecasting: activity.isOutOfPeriodForecasting,
        dateInterval: activity.dateInterval,
      });
    });
  });

  const datesList = generateDays(parseDate(plan.planningParameters.startDay), parseDate(plan.planningParameters.endDay));
  return (
    rows
      // .filter(row => row.activityForecastId && row.dates && row.dates.length)
      .map(row => {
        let activityDates = null;
        let omsDates = null;
        const omsData = responseForecastEffort.data.oms.find(
          (forecastRow, index) =>
            forecastRow.omsId === row.omsId && forecastRow.smartProdSourceId === row.smartProdSourceId,
        );
        if (omsData) {
          omsDates = omsData.dates.map(a => ({
            effortSmartVolume: null,
            effortSmartVolumeWithEvent: null,
            effortSmartVolumeWithAdjustment: null,
            effortSmartProd: null,
            effortSmartProdWithEvent: null,
            effortSmartProdWithEventAndAdjustment: null,
            effortOverride: null,
            ...a,
            ratioSmartProd: typeof a.ratioSmartProd === 'number' ? a.ratioSmartProd * 100 : null,
            ratioOverride: typeof a.ratioOverride === 'number' ? a.ratioOverride * 100 : null,
            dataSource: a.dataSource ? a.dataSource : row?.defaultType ? row?.defaultType : plan?.planningParameters?.defaultForecastSource ? plan?.planningParameters?.defaultForecastSource : 'SMART_PROD',
          }));
          const activityData = omsData.activities.find(
            (activityRow, index) => row.activityForecastId === activityRow.activityForecastId,
          );
          if (activityData) {
            activityDates = activityData.dates.map(a => ({
              effortSmartVolume: null,
              effortSmartVolumeWithEvent: null,
              effortSmartVolumeWithAdjustment: null,
              effortSmartProd: null,
              effortSmartProdWithEvent: null,
              effortSmartProdWithEventAndAdjustment: null,
              effortOverride: null,
              ...a,
              ratioSmartProd: typeof a.ratioSmartProd === 'number' ? a.ratioSmartProd * 100 : null,
              ratioOverride: typeof a.ratioOverride === 'number' ? a.ratioOverride * 100 : null,
            }));
          }
        }
        if (!omsDates) {
          omsDates = datesList.map(date => ({
            effortDate: formatDateToApiFormat(date),
            effortSmartVolume: 0,
            effortSmartVolumeWithEvent: 0,
            effortSmartVolumeWithAdjustment: 0,
            effortSmartProd: 0,
            effortSmartProdWithEvent: 0,
            effortSmartProdWithEventAndAdjustment: 0,
            effortOverride: 0,
            dataSource: row?.defaultType ? row?.defaultType : plan?.planningParameters?.defaultForecastSource ? plan?.planningParameters?.defaultForecastSource : 'SMART_PROD',
          }));
        }
        if (!activityDates) {
          activityDates = datesList.map(date => ({
            effortDate: formatDateToApiFormat(date),
            effortSmartVolume: 0,
            effortSmartVolumeWithEvent: 0,
            effortSmartVolumeWithAdjustment: 0,
            effortSmartProd: 0,
            effortSmartProdWithEvent: 0,
            effortSmartProdWithEventAndAdjustment: 0,
            effortOverride: 0,
            ratioOverride: 0,
            ratioSmartProd: 0,
          }));
        }
        return {
          ...row,
          activityDates: activityDates.sort((a, b) =>
            DateTime.fromISO(a.effortDate) > DateTime.fromISO(b.effortDate) ? 1 : -1,
          ),
          omsDates: omsDates.sort((a, b) => (DateTime.fromISO(a.effortDate) > DateTime.fromISO(b.effortDate) ? 1 : -1)),
        };
      })
  );
}

function* fetchRoles(pa){
  const response = yield call(
    api,
    withUrl(`/roles/?expression=&planningParametersId=${pa.planningParameters.id}`),
  );
  if(response.isOk){
    yield put(storeRole(response.data));
  }
}

export function* fetchPlanningArea(id) {
  const response = yield call(api, withUrl(`/planningAreas/basic/${id}`).andTitle('Loading planning area'));
  if (response.isOk) {
    const data = convertEntityWithPlanningParametersFromApi(response.data);
    return data;
  }
  return null;
}

export function* fetchPlanningAreaDetails(id, logResponse) {
  const response = yield call(
    api,
    withUrl(`/planningAreas/details/${id}`)
      .andTitle('Loading planing area details')
      .disableLogCalls(logResponse || true),
  );
  if (response.isOk) {
    const data = convertEntityWithPlanningParametersFromApi(response.data);
    return data;

  }
  return null;
}

function* reload() {
  // eslint-disable-next-line no-unneeded-ternary
  const pa = yield select(selectPlanningAreaFromPa);
  yield call(doLoadPlanningArea, loadPlanningArea(pa.id, { reloadCount: pa.reloadCount + 1 }));
}

function* doSavePlanningParameters(action) {
  const dataWrapper = action.payload;
  dataWrapper.planningParametersId = yield select(selectPlanningParametersIdFromPa);
  yield call(savePlaningParametersFromTo, dataWrapper);
  toast(<FormattedMessage {...messages.planningParamsUpdated} />);
  yield call(reload);
}

function* addPlannedVolumeLine(action) {
  const planningParametersId = yield select(selectPlanningParametersIdFromPa);
  const response = yield call(addVolumeLine, action, planningParametersId);
  if (response.isOk) {
    yield call(reload);
  }
}

function* deletePaWeeklyVolumes(action) {
  const planningParametersId = yield select(selectPlanningParametersIdFromPa);
  const res = yield call(deleteWeeklyVolumes, planningParametersId, action.payload.volumeCategory.id);
  if (res.isOk) {
    yield call(reload);
    toast(<FormattedMessage {...messages.budgetVolumesDeleted} />);
  }
}

function* addPaActivityLine(action) {
  return yield call(wrapWithPlanningParametersAndCall, action, addActivityLine);
}

function* deletePaActivityLine(action) {
  const planningParametersId = yield select(selectPlanningParametersIdFromPa);
  const periodId = yield select(selectPeriodIdFromPa);
  const apId = action.payload.id;
  const url = `/planningParameters/${planningParametersId}/periods/${periodId}/activityParameters/${apId}/delete`;
  const response = yield call(api, withUrl(url).post().andTitle('Deleting activity line'));
  if (response.isOk) {
    yield call(reload);
    toast(<FormattedMessage {...messages.activityLineDeleted} />);
  }
}

function* deletePaAdjustmentLines(action){
  const planningParametersId = yield select(selectPlanningParametersIdFromPa);
  const periodId = yield select(selectPeriodIdFromPa);
  const apId = action.payload.id;
  const url = `/planningParameters/${planningParametersId}/periods/${periodId}/activityAdjustments/${apId}/delete`;
  const response = yield call(api, withUrl(url).post().andTitle('Deleting Adjustment line'));
  if (response.isOk) {
    yield call(reload);
    toast(<FormattedMessage {...messages.adjustmentLineDeleted} />);
  }
}

function* addLabourAvailabilityCategoryLine(action) {
  return yield call(wrapWithPlanningParametersAndCall, action, addLabourCategoryLine);
}

function* deleteLabourAvailabilityCategoryLine(action) {
  return yield call(wrapWithPlanningParametersAndCall, action, deleteLabourCategoryLine);
}

function* addShiftSettingsLine(action) {
  return yield call(wrapWithPlanningParametersAndCall, action, addShiftSettings);
}

function* deleteShiftSettingsLine(action) {
  return yield call(wrapWithPlanningParametersAndCall, action, deleteShiftSettings);
}

function* doOnCloseUploadModal(action) {
  if (action && action.payload.doReload && action.payload.entity === 'planningAreas') {
    yield call(reload);
  }
}
function* getPlanMappings(planId) {
  const url = `/smartCode/planingAreaMappings/${planId}`;
  const response = yield call(api, withUrl(url));
  if (response.isOk) {
    yield put(savePlanSmartCodeMappings(response.data))
  }
}

function getActivityMappedToSmartCodeMessage(activityPath, activity, customers, uoms, departments) {
  const parts = activityPath?.activityPath.split('\\');
  if (activity.departmentId !== undefined) {

    parts[0] = departments[activity.departmentId] === undefined ? 'All Departments' : departments[activity.departmentId];
  }
  if (activity.customerId !== undefined) {
    parts[1] = customers[activity.customerId] === undefined ? 'All' : customers[activity.customerId];
  }
  if (activity.uomId) {
    parts[3] = uoms[activity.uomId];
  }
  const newPath = parts.join('/');
  const oldPath = activityPath?.activityPath.replaceAll('\\', '/');
  if (newPath !== oldPath) {
    toast.error(
      `Activity ${oldPath} is mapped with job code ${activityPath.jobCode}
` +
        '\n' +
        `You changing this activity to ${newPath} which would require to update SmartCode mapping`,
    );
  }
}


function* omsChangeHistory(){
  const pa = yield select(selectPlanningAreaFromPa);
  const url = `/omsChangeHistory/byPlanParameterId/${pa.planningParameters.id}`;
  const response = yield call(api, withUrl(url));
  if (response.isOk) {
    yield put(saveomsChangeHistory(response.data));
  }
}
